import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Modal, message, Spin } from 'antd';
import { injectIntl } from 'react-intl';
import { getModalMessages, getClientMessages } from '../../../../constants/messages';
import { SaveClientRecurrentProducts } from '../../actions';
import RecurrentProductsForm from './Form';
import { applyObject } from '../../../../utilities/util';

let modalMessages = getModalMessages(),
	clientMessages = getClientMessages();

const messages = {
	...modalMessages,
	...clientMessages
};

class ClientRecurrentProductsModal extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			isSaving: false,
		};
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });
	setSaving = (isSaving) => this.setState({ isSaving });

	submit = () => {
		const { props, getIntl, setSaving, cancel } = this;
		const { form, clientId } = props;
		form.validateFields({ "force": true }, (err, values) => {
			if (!err) {
				const recurrentProducts = {
					newProducts: [],
					updateProducts: [],
					deletedProductIds: []
				};
				const apply = (key, product) => {
					const fieldSubstring = key.substring(0, 3);
					const { productId, quantityType, setQuantity, ruleId } = product;
					const parsedProduct = { productId, ruleId, setQuantity: quantityType === 0 ? -1 : setQuantity };
					if (fieldSubstring == "new") recurrentProducts.newProducts.push(parsedProduct);
					else if (fieldSubstring == "upd") recurrentProducts.updateProducts.push({ ...parsedProduct, id: parseInt(key.substr(3)) });
					else if (fieldSubstring == "del") recurrentProducts.deletedProductIds.push(parseInt(key.substr(3)));
				}
				applyObject(values, apply);
				setSaving(true);
				SaveClientRecurrentProducts(clientId, recurrentProducts)
					.then(() => {
						setSaving(false);
						message.success(getIntl("clientRecurrentProductsSaveSuccess"));
						cancel();
					})
					.catch(() => {
						setSaving(false);
						message.error(getIntl("clientRecurrentProductsSaveError"));
					});
			}
		});
	}

	cancel = () => {
		this.setState({ isLoading: false, isSaving: false });
		this.props.handleCancel();
	}

	renderedButtons = () => {
		const { state, submit, cancel, getIntl } = this;
		const { isLoading, isSaving } = state;
		return [
			<Button key="cancel" disabled={isSaving} onClick={cancel}>
				{getIntl("cancel")}
			</Button>,
			<Button key="submit" disabled={isLoading} loading={isSaving} type="primary" onClick={submit}>
				{getIntl("save")}
			</Button>
		];
	}

	render() {
		const { state, props, submit, cancel, renderedButtons, getIntl, setLoading } = this;
		const { isLoading, isSaving } = state;
		const { showModal, form, clientId } = props;
		return (
			<Modal
				open={showModal}
				title={getIntl("recurrentProducts")}
				onOk={submit}
				onCancel={cancel}
				destroyOnClose={true}
				footer={renderedButtons()}
				maskClosable={!isSaving}
				keyboard={!isSaving}
				closable={!isSaving}
			>
				<Spin spinning={isLoading}>
					<RecurrentProductsForm form={form} clientId={clientId} setLoading={setLoading} />
				</Spin>
			</Modal>
		);
	}
}

ClientRecurrentProductsModal.defaultProps = {
	clientId: 0
};

ClientRecurrentProductsModal.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,

	showModal: PropTypes.bool.isRequired,
	clientId: PropTypes.number.isRequired,
	handleCancel: PropTypes.func.isRequired,
};

export default injectIntl(Form.create()(ClientRecurrentProductsModal));