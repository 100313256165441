import React, { useState} from 'react';
import PropTypes from 'prop-types';
import { Radio, InputNumber, Form } from 'antd';
import { getFields, getEmployeeMessages, getPayrollMessages } from '../../../../constants/messages';
import { useIntl } from 'react-intl';
import {getNestedValue} from '../../../../utilities/util';
import SelectForm from './FormItemSelect';
import DatePickerForm from './DatePicker';
import MoneyInputNumber from './MoneyInputNumber';
import FormItemInput from './FormItemInput';
import { disabledNonPayDates } from '../../constants';
let moment = require('moment');

const FormItem = Form.Item;

let employeeMessages = getEmployeeMessages(), 
    fieldMessages = getFields(),
    payrollMessages = getPayrollMessages();

const messages = { 
    ...fieldMessages,
    ...employeeMessages,
    ...payrollMessages
};

const Deduction = ({ form, deduction, deductionOptions}) => {
    const intl = useIntl();
    const [calculated, setCalculated] = useState(false);

    const getIntl = (str) => {
		return intl.formatMessage({...messages[str]});
	}

    const getField = (field) => {
		return getNestedValue(field, deduction);
    }

    const switchType = (calculated) => {
        setCalculated(calculated);
        form.setFieldsValue({ amount: undefined });
        if (calculated) form.setFieldsValue({ finalDate: undefined });
    }

    const calculateAmount = (total, dueAmount) => {
        if (total === undefined || dueAmount === undefined) return;
        const amount = (total / dueAmount).toFixed(2);
        form.setFieldsValue({ amount });
    }

    const calculateAmountByTotal = (total) => {
        const dueAmount = form.getFieldValue('dueAmount');
        calculateAmount(total.target.value, dueAmount);
    }

    
    const calculateAmountByDueAmount = (dueAmount) => {  
        const total = form.getFieldValue('total');
        calculateAmount(total, dueAmount.target.value);
    }

    const calculateFinalDate = (initialDate, dueAmount, monthlyRecurrence) => {
        if (!initialDate || !dueAmount || monthlyRecurrence === undefined) return;
        const init = moment(initialDate).startOf('day');
        let amount = dueAmount - 1;
        let is15 = moment(initialDate).date() === 15;
        const isEven = (n) => n % 2 === 0;
        const setFinalDate = (finalDate) => {form.setFieldsValue({ finalDate })};
        const setToLastDay = (final) => {
            
            if (moment(final).month() === 1) setFinalDate(moment(final).endOf('month'));
            else setFinalDate(moment(final).set({ date: 30 }));
        }
        const setEvenOrMonthly = (final) => {
            
            if (is15 || moment(init).month() !== 1) setFinalDate(final);
            else setToLastDay(final);
        }
        if (monthlyRecurrence) setEvenOrMonthly(moment(init).add(amount, 'M'));
        else {
            if (isEven(amount)) setEvenOrMonthly(moment(init).add(amount / 2, 'M'));
            else {
                let final = moment(init).add((amount - 1) / 2, 'M');
                if (is15) setToLastDay(final);
                else setFinalDate(moment(final).add(1, 'M').set({ date: 15 }));
            }
        }
    }

    const calculateFinalDateByInitialDate = (initialDate) => {
        const dueAmount = form.getFieldValue('dueAmount');
        const monthlyRecurrence = form.getFieldValue('monthlyRecurrence');
        calculateFinalDate(initialDate, dueAmount, monthlyRecurrence);
    }
    
    const calculateFinalDateByDueAmount = (dueAmount) => {
        const initialDate = form.getFieldValue('initialDate');
        const monthlyRecurrence = form.getFieldValue('monthlyRecurrence');
        calculateFinalDate(initialDate, dueAmount.target.value, monthlyRecurrence);
    }

    const calculateFinalDateByRecurrence = (monthlyRecurrence) => {
        const initialDate = form.getFieldValue('initialDate');
        const dueAmount = form.getFieldValue('dueAmount');
        calculateFinalDate(initialDate, dueAmount, monthlyRecurrence);
    }
    const initialDateOnChange = (initialDate) => {
        if (calculated) calculateFinalDateByInitialDate(initialDate);
    }

    const dueAmountOnChange = (dueAmount) => {
        calculateAmountByDueAmount(dueAmount);
        calculateFinalDateByDueAmount(dueAmount);
    }

    const recurrenceOnChange = (monthlyRecurrence) => {
        if (calculated) calculateFinalDateByRecurrence(monthlyRecurrence);
    }
    
    const renderTypeRadio = () => {
        return (
            <Radio.Group buttonStyle="solid" value={calculated} onChange={(e) => switchType(e.target.value)}>
                <Radio.Button value={false}>{getIntl("fixedAmount")}</Radio.Button>
                <Radio.Button value={true}>{getIntl("calculatedAmount")}</Radio.Button>
            </Radio.Group>
        );
    }

    const renderDeductionField = () => {
		let deductionId = getField("deductionId");
        return (
            <SelectForm
                fieldName = {'deductionId'}
                label = {getIntl("deduction")}
                errorMessage = {getIntl("deductionError")}
                initialValue = {deductionId}
                options = {deductionOptions}
            />
        );
    }

    const renderAmountField = () => {
		let amount = getField("amount");
        return (
            <MoneyInputNumber
                fieldName = {`amount`}
                label = {getIntl("amount")}
                renderLabel = {getIntl("amount")}
                errorMessage = {getIntl("amountError")}
                initialValue = {amount}
                required={!calculated}
                disabled={calculated}
            />
        );
	}

    const renderInitialDateField = () => {
		let initialDate = getField("initialDate");
        return (
            <DatePickerForm
                fieldName = {`initialDate`}
                label = {getIntl("initialDate")}
                errorMessage = {getIntl("initialDateError")}
                initialValue = {initialDate}
				componentProps = {{ disabledDate: disabledNonPayDates, showToday: false }}
                onChange = {(date) => initialDateOnChange(date)}
            />
        );
	}

    const renderFinalDateField = () => {
		let finalDate = getField("finalDate");
        return (
            <DatePickerForm
                fieldName = {"finalDate"}
                label = {getIntl("finalDate")}
                errorMessage = {getIntl("finalDateError")}
                initialValue = {finalDate}
				componentProps = {{ disabledDate: disabledNonPayDates, showToday: false }}
                disabled={calculated}
                required={calculated}
            />
        );
	}

    const renderMonthlyRecurrenceField = () => {
        let monthlyRecurrence = getField("monthlyRecurrence");
        if (monthlyRecurrence === undefined) monthlyRecurrence = false;
        return (
            <FormItem
                label={getIntl("recurrence")}
                name = {'monthlyRecurrence'}
                initialValue={monthlyRecurrence}
                rules ={
                    [{
                        required: true,
                        message: getIntl("recurrenceError"), 
                    }]
                }

            >
                <Radio.Group 
                    buttonStyle="solid"
                    onChange= {(event) => recurrenceOnChange(event.target.value)}

                >
                    <Radio.Button value={false}>{getIntl("biweekly")}</Radio.Button>
                    <Radio.Button value={true}>{getIntl("monthly")}</Radio.Button>
                </Radio.Group>
            </FormItem>
        );
    }

    const renderTotalField = () => {
		let total = getField("total");
        return (
            <MoneyInputNumber
                fieldName = {`total`}
                label = {getIntl("total")}
                renderLabel = {getIntl("total")}
                errorMessage = {getIntl("totalError")}
                initialValue = {total}
                onChange = {(value) => calculateAmountByTotal(value)}
            />
        );
	}

    const renderDueAmountField = () => {
        let dueAmount = getField("dueAmount");
        return (
            <FormItem
                name={'dueAmount'}
                label={getIntl("dueAmount")}
                initialValue={dueAmount}
                onChange = {(value) => dueAmountOnChange(value)}
                rules = {[
                    {
                        required: true,
                        message: getIntl("dueAmountError")
                    }
                ]}
            >
                <InputNumber
                    className="job-partial-component"
                    placeholder={getIntl("dueAmount")}
                    min={0}
                />
            </FormItem>
        );
    }

       
    const renderCommentField = () => {
		let comment = getField("comment");
        return (
            <FormItemInput
                fieldName = {`comment`}
                label = {getIntl("comment")}
                initialValue = {comment}
                required = {false}
            />
        );
    }


  return (
    <Form layout={'vertical'} form={form} preserve={false}>
        {calculated ? (
            <div>
                {renderTypeRadio()}
                {renderDeductionField()}
                {renderTotalField()}
                {renderDueAmountField()}
                {renderAmountField()}
                {renderInitialDateField()}
                {renderFinalDateField()}
                {renderMonthlyRecurrenceField()}
                {renderCommentField()}
            </div>
        ) : (
            <div>
                {renderTypeRadio()}
                {renderDeductionField()}
                {renderAmountField()}
                {renderInitialDateField()}
                {renderFinalDateField()}
                {renderMonthlyRecurrenceField()}
                {renderCommentField()}
            </div>
        )}
    </Form>

  )
}

Deduction.defaultProps = { 
    deduction: {},
    deductionOptions: []
};

Deduction.propTypes = {
    form: PropTypes.object,
    deductionOptions: PropTypes.array.isRequired,
    deduction: PropTypes.object.isRequired
};

export default Deduction
