import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { getJobMessages } from '../../../../constants/messages';
import YesNoTemplate from './YesNoTemplate';

let jobMessages = getJobMessages();

const messages = {
	...jobMessages
};

class DeviceLease extends React.Component {

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});
    
	render() {
        const { props, getIntl } = this;
        const { form, deviceLease, disabled, onChange, disableYes, disableNo } = props;
        return (
            <YesNoTemplate
                form={form}
                initialValue={deviceLease}
                disabled={disabled}
                onChange={onChange}
                label={getIntl("deviceLeased")}
                fieldName={`deviceLease`}
                errorMessage={getIntl("deviceLeasedError")}
                disableNo={disableNo}
                disableYes={disableYes}
            />
        )
    }
}

DeviceLease.defaultProps = {
    disabled: false,
    disableYes: false,
    disableNo: false
};

DeviceLease.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    deviceLease: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    disableYes: PropTypes.bool,
    disableNo: PropTypes.bool
};

export default injectIntl(DeviceLease);