import React from 'react';
import PropTypes from 'prop-types';
import {
	injectIntl,
	FormattedMessage
} from 'react-intl';
import CommonTable from '../../components/CommonTable';
import DebitNotesFilter from './DebitNotesFilter';
import { connect } from 'react-redux';
import { GetDebitNotes, GetDebitNote, SearchDebitNotes, DownloadDebitNote, PreviewDebitNote, EmailDebitNote, LoadDebitNoteActivityLog } from './actions';
import { message, Badge } from 'antd'
import { roundN } from '../../utilities/util';
import { getDebitNoteMessages, getactionMessages, getPopConfirm, getInvoicingMessages, getFields } from '../../constants/messages';
import AddDebitNoteButton from './AddDebitNoteButton';
import ShowDebitNoteModal from './ShowDebitNoteModal';
import GeneralAddPaymentButton from '../Payments/GeneralAddPaymentButton';
import EmailSelector from '../../components/EmailSelector';
import { STATUSES } from './constants';
import { GetEmails } from '../Clients/actions';
import ActivityLogModal from '../CreditNotes/ActivityLogModal';

let moment = require('moment');

let debitNoteMessages = getDebitNoteMessages(),
	popMessages = getPopConfirm(),
	actionMessages = getactionMessages(),
	invoicingMessages = getInvoicingMessages(),
	fieldMessages = getFields();

const messages = {
	...debitNoteMessages,
	...actionMessages,
	...popMessages,
	...invoicingMessages,
	...fieldMessages
};

class DebitNotes extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			debitNote: {},
			isLoading: true,
			showModal: false,
			showEmailModal: false,
			currentDebitNoteId: 0,
			showActivityLogModal: false,
			currentActivityLog: [],
		};
	}

	componentDidMount() {
		this.props.getDebitNotes().then(() => {
			this.setState({
				isLoading: false,
			});
		}).catch(() => {
			message.error(`${this.props.intl.formatMessage({ ...messages.GetDebitNotesError })}`);
		});
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = isLoading => this.setState({ isLoading });

	search = (search) => {
		this.setState({
			isLoading: true
		});
		this.props.searchDebitNotes(search).then(() => {
			this.setState({
				isLoading: false
			});
		}).catch(() => {
			message.error(`${this.props.intl.formatMessage({ ...messages.GetDebitNotesError })}`);
		});
	}

	openModal = (id) => {
		this.props.getDebitNote(id).then(() => {
			this.setState({
			showModal: true
			});
		});
	}

	closeModal = () => {
		this.setState({
			showModal: false
		})
	}

	printDebitNote = (id) => {
		return this.props.downloadDebitNote(id).catch(() => {
			message.error(`${this.props.intl.formatMessage({ ...messages.PrintError })}`);
		});
	}

	openEmailModal = (debitNoteId, clientId) => {
		const { props, getIntl, setLoading } = this;
		const { getCientEmails } = props;
		setLoading(true);
		getCientEmails(clientId)
			.then(() => this.setState({ showEmailModal: true, currentDebitNoteId: debitNoteId, isLoading: false }))
			.catch(() => {
				message.error(getIntl("getClientEmailsError"));
				setLoading(false);
			});
	}

	closeEmailModal = () => this.setState({ showEmailModal: false, currentDebitNoteId: 0 });

	openActivityLogModal = debitNoteId => {
		const { setLoading, getIntl } = this;
		setLoading(true);
		LoadDebitNoteActivityLog(debitNoteId)
			.then(activityLog => this.setState({ isLoading: false, showActivityLogModal: true, currentActivityLog: activityLog }))
			.catch(() => {
				setLoading(false);
				message.error(getIntl("getActivityLogError"));
			});
	}

	closeActivityLogModal = () => this.setState({ showActivityLogModal: false, currentActivityLog: [] });

	render() {
		const { state, openActivityLogModal, closeActivityLogModal } = this;
		const { showActivityLogModal, currentActivityLog } = state;
		const {search}= this.props
		let columns = [
			{
				title: this.props.intl.formatMessage({ ...messages.DebitNoteNumber }),
				dataIndex: 'documentNumber',
				key: 'documentNumber',
				width: '15%',
				render: (documentNumber, debitNote) => {
					return (
						<a className="editable-add-btn" onClick={() => { this.openModal(debitNote.id); }}>
							{documentNumber}
						</a>
					);
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Client }),
				dataIndex: 'clientName',
				key: 'clientName',
				width: '15%'

			}, {
				title: this.props.intl.formatMessage({ ...messages.Status }),
				dataIndex: 'status',
				key: 'status',
				width: '10%',
				render: (status) => {
					let color = 'default';
					let text = this.props.intl.formatMessage({ ...messages.Pending });
					if (status === STATUSES.PAID) {
						color = 'success';
						text = this.props.intl.formatMessage({ ...messages.Paid });
					}
					return (
						<Badge status={color} text={text} />
					);
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Motive }),
				dataIndex: 'motive',
				key: 'motive',
				width: '30%'
			}, {
				title: 'Total',
				dataIndex: 'total',
				key: 'total',
				className: 'column-money',
				width: '10%',
				render: (total) => {
					return `L ${roundN(total, 2)}`;
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Date }),
				dataIndex: 'createdAt',
				key: 'createdAt',
				width: '10%',
				render: (date) => {
					return (moment(date).locale('es').format('L'));
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Actions }),
				dataIndex: 'id',
				key: 'actions',
				width: '10%',
				render: (id, debitNote) => {
					return (
						<span>
							<a className="editable-add-btn" onClick={() => { this.printDebitNote(id); }}>
								<FormattedMessage {...messages.Print} />
							</a>
							<span> | </span>
							<a className="editable-add-btn" onClick={() => { this.openEmailModal(id, debitNote.clientId); }}>
								<FormattedMessage {...messages.Emails} />
							</a>
							<span> | </span>
							<a className="editable-add-btn" onClick={() => { openActivityLogModal(id); }}>
								<FormattedMessage {...messages.activityLog} />
							</a>
						</span>

					);
				}
			}
		];
		return (
			<div className="account view">	
				<ActivityLogModal
					showModal={showActivityLogModal}
					handleCancel={closeActivityLogModal}
					activityLog={currentActivityLog}
				/>
				<EmailSelector
					addOrShow={false}
					showEmailModal={this.state.showEmailModal}
					closeModal={this.closeEmailModal}
					document={{ id: this.state.currentDebitNoteId }}
					emailDocument={this.props.emailDebitNote}
					downloadPreview={() => PreviewDebitNote(this.state.currentDebitNoteId)}
					downloadDocument={() => this.printDebitNote(this.state.currentDebitNoteId)}
				/>
				<CommonTable
					columns={columns}
					dataSource={this.props.debitNotes}
					loading={this.state.isLoading}
					Add={<AddDebitNoteButton />}
					recordCount={this.props.debitNotes.length}
					getRecords={() => { }}
					search={this.search}
					searchText={search}
					filterComponent={(<DebitNotesFilter />)}
					getCheckboxProps={(() => ({ disabled: false }))}
					hideCheckboxes
					extraComponents={[<GeneralAddPaymentButton isDebitNote={true} key={0} />]}
				/>
				<ShowDebitNoteModal
					showModal={this.state.showModal}
					closeModal={this.closeModal}
				/>
			</div>
		);
	}
}

DebitNotes.propTypes = {
	debitNotes: PropTypes.array.isRequired,
	downloadDebitNote: PropTypes.func.isRequired,
	emailDebitNote: PropTypes.func.isRequired,
	getCientEmails: PropTypes.func.isRequired,
	getDebitNote: PropTypes.func.isRequired,
	getDebitNotes: PropTypes.func.isRequired,
	intl: PropTypes.object.isRequired,
	searchDebitNotes: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	search: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		debitNotes: state.debitNote.debitNotes,
		search : state.debitNote.search
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getDebitNotes: () => {
			return dispatch(GetDebitNotes());
		},
		getDebitNote: (id) => {
			return dispatch(GetDebitNote(id));
		},
		searchDebitNotes: (search) => {
			return dispatch(SearchDebitNotes(search));
		},
		downloadDebitNote: (id) => {
			return dispatch(DownloadDebitNote(id));
		},
		emailDebitNote: (id, emails, replyTo, message) => dispatch(EmailDebitNote(id, emails, replyTo, message)),
		getCientEmails: clientId => dispatch(GetEmails(clientId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DebitNotes));
