import { connect } from 'react-redux';
import Commissions from './index';
import {
	GetCommissions,
	GetCommissionCount,
	LoadSalespersonPendingCommissions
} from './actions';

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		records: state.commissions.commissions,
		recordCount: state.commissions.count,
		isMaster: state.auth.isMaster
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getRecords: (searchObject, page, pageSize) => dispatch(GetCommissions(searchObject, page, pageSize)),
		getRecordCount: () => dispatch(GetCommissionCount()),
		getSalespersonRecords: (salesPersonId, page = 1, pageSize = 10) => dispatch(LoadSalespersonPendingCommissions(salesPersonId, page, pageSize))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Commissions);