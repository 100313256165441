import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import { Row, Col, Card, Dropdown, message, Popconfirm } from 'antd';
import { getactionMessages, getFields, getErrorMessages, getPopConfirm, getTableMessages, getJobMessages, getLanguageIntl } from '../../constants/messages';
import CommonTable from '../../components/CommonTable';
import MobileCommonTable from '../../components/MobileCommonTable';
import {isMobile, formatDateWithTime} from '../../utilities/util';
import AddJobButton from './AddUpdateJob/AddJobButton';
import ViewJobModal from './ViewJob/Modal';
import JobSearchDrawer from './JobSearchDrawer';
import { getJobStatus, getJobType, JOBTYPES, JOBSTATUS} from './constants';
import CircleBadge from '../GlobalComponents/CircleBadge';
import AddJobModal from './AddUpdateJob/Modal';
import { withRouter } from 'react-router-dom';
import { getSeletedJobs, InstantDeactivationJob, SaveSelectJobs } from './actions';
import { ROLES } from '../../constants/global';
import LinkJobModal from './AddUpdateJob/LinkJobModal';
import { GetClientsInvoice } from '../Invoices/actions'; 
const { INSTALLATION, SWAP, TRANSFER, RECONNECTION, UNINSTALLATION, REVISION, ACTIVATE, DEACTIVATE } = JOBTYPES;
const { DONE } = JOBSTATUS;

let errorMessages = getErrorMessages(),
	ActionMessages = getactionMessages(),
	tableMessages = getTableMessages(),
	PopConfirmMessages = getPopConfirm(),
	fieldMessages = getFields(),
	JobsMessages = getJobMessages(),
    intlMessages = getLanguageIntl();

const messages = {
	...ActionMessages,
	...fieldMessages,
	...errorMessages,
	...tableMessages,
	...PopConfirmMessages,
	...JobsMessages,
    ...intlMessages
};

class Job extends React.Component {
	constructor(props){
		super(props);
        this.state = {
            showViewModal: false,
            jobId: 0,
            showAddModal: false,
			duplicateJobId: 0,
			showAdvancedSearchDrawer: false,
			advancedSearchFilter: {},
			selectedJobs: [],
			showLinkModal: false,
			invoicesToLink: [],
			cleanSelectedRows: false,
        };
	}

	componentDidMount() {
		this.props.searchJobs();
	}

	componentDidUpdate(){
		const { props: { invoiceJobs, proformaJobs } } = this;
		if(invoiceJobs)
			this.props.history.push(`/invoices/new`);
		else if(proformaJobs)
			this.props.history.push(`/proformas/new`);	
	}

	renderViewButton = (id) => {
		return (
			<a onClick={() => this.setState({ jobId: id, showViewModal: true })}>
				<FormattedMessage {...messages.view} />
			</a>
		);
    };

	handleDeactivation = async (jobId) => {
		const { intl } = this.props;
		try {
			const responseStatus = await InstantDeactivationJob(jobId);
			if(responseStatus == 200){
				this.props.searchJobs();
			}
		} catch (error) {
			message.error(intl.formatMessage( {...messages.common} ));
		}
	};
	
	deactivateJobButton = (id) => {
		const { intl } = this.props;
		return (
			<Popconfirm
				title={intl.formatMessage( {...messages.instantDeactivation} )} 
				okText={intl.formatMessage( {...messages.yes} )} 
				cancelText={intl.formatMessage( {...messages.no} )}
				onConfirm={() => this.handleDeactivation(id)} 
			>
				<a> <FormattedMessage {...messages.deactivate} /> </a>
			</Popconfirm>
		);
    };

	renderDuplicateButton = (id) => {
		return (
			<a onClick={() => this.setState({ duplicateJobId: id, showAddModal: true })}>
				<FormattedMessage {...messages.duplicate} />
			</a>
		);
    };
	
	renderTechs = (techs = []) => techs.map((tech, index) => <div key={index}>{tech.name}</div>);

	renderInvoice = (invoiceInfo) => {
		if(invoiceInfo === undefined){
			return (
				'Trabajo sin costo' 
			)
		}else if(invoiceInfo.invoiceId === null && invoiceInfo.proformaId === null){
			return (
				'Sin facturar'
			)
		}else if(invoiceInfo.invoiceId){
			return (
				<a className="editable-add-btn" onClick={() => {this.props.history.push(`/invoices/${invoiceInfo.invoice.id}`);}}>
					{invoiceInfo.invoice.documentNumber}
				</a>
			);
		}else if(invoiceInfo.proformaId){
			return (
				<a className="editable-add-btn" onClick={() => {this.props.history.push(`/proformas/${invoiceInfo.proformaId}`);}}>
					{invoiceInfo.proformaId}
				</a>
			);
		}
	}

	renderJobType = (type) => {
		const { intl } = this.props;
		const TYPE = getJobType(type);
		return intl.formatMessage( {...messages[TYPE.intl]} );
	};
	
	getJobsSelected = async (selected, checkInType) => {
		const { props: { saveSelectJobs, intl } } = this;
		try {
			const jobs = await getSeletedJobs(selected);
			const result = await this.checkSelected(selected, jobs);
			if(!result.oneClient) return message.error(intl.formatMessage({...messages.multipleClientsError}))
			saveSelectJobs(selected, checkInType)
		} catch (error) {
			message.error(intl.formatMessage( {...messages.getJobInfoError}));
		}
	};

	checkSelected = (ids, jobs) => {
		let clientId = undefined;
			const oneClient = ids.every((id, index)=> { 
				const jobExist = jobs.find((job) => job.id == id);
				if(!jobExist) return false;
				if(index==0) clientId= jobExist.clientId;
				if(jobExist.clientId != clientId) return false;
				return true;
			});
			return {oneClient, clientId};
	};

	linkJobsSelected = async (ids) => {
		const { props: {intl, jobs} } = this;
		try {
			const result = await this.checkSelected(ids,jobs);
			if(!result.oneClient) return message.error(intl.formatMessage({...messages.multipleClientsError}))
			const invoicesToLink = await GetClientsInvoice(result.clientId);
			this.setState({	selectedJobs: ids,	showLinkModal: true, invoicesToLink });
		} catch (error) {
			message.error(intl.formatMessage({...messages.getJobInfoError}));
		}
	};

	clearSelectedRows = (value) => {
		this.setState({cleanSelectedRows: value})
	};

	render(){
		const { state, props } = this;
		const { showViewModal, jobId, showAddModal, showLinkModal, selectedJobs, duplicateJobId, showAdvancedSearchDrawer, invoicesToLink, cleanSelectedRows } = state;
		const {intl, jobs, recordCount, isLoading, userRoles,searchTable} = props;
		let columns = [
			{
				title: `${intl.formatMessage( {...messages.jobClient})}`,
				dataIndex: ['client','name'],
				key: 'client'
			}, {
				title: `${intl.formatMessage( {...messages.date})}`,
				dataIndex: 'programmedDate',
				key: 'programmedDate',
				render: (date) => {
					return(
						<span>
							{formatDateWithTime(date, intl.formatMessage({...messages.intl}),
													{weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'},
													{hour: '2-digit', minute:'2-digit', hour12: true})}
						</span>
					);
				}
			}, {
				title: `${intl.formatMessage( {...messages.user} )}`,
				dataIndex: ['creatingUser','name'],
				key: 'creatingUser'
			},{
				title: `${intl.formatMessage( {...messages.technicians} )}`,
				dataIndex: 'technicians',
				key: 'technicians',
				render: (technicians) => {
					return (this.renderTechs(technicians));
				}
			},{
				title: `${intl.formatMessage( {...messages.jobType} )}`,
				dataIndex: 'type',
				key: 'type',
				render: (type) => this.renderJobType(type)
			},{
				title: `${intl.formatMessage( {...messages.status} )}`,
				dataIndex: 'status',
				key: 'status',
				render: (status) => {
					let STATUS = getJobStatus(status);
					return (
                        <CircleBadge color={STATUS.iconColor} text={intl.formatMessage({...messages[STATUS.intl]})}/>
                    );
				}
			},{
				title: `${intl.formatMessage( {...messages.invoicing} )}`,
				dataIndex: 'invoiceInfo',
				key: 'invoiceInfo',
				render: (invoiceInfo) => {
					return (this.renderInvoice(invoiceInfo));
				}
			},{
				title:  `${intl.formatMessage( {...messages.actions} )}`,
				dataIndex: 'id',
				key: 'actions',
				render: (id, record) => {
					return (
						<span>
							{this.renderViewButton(id)}
							<span> | </span>
							{this.renderDuplicateButton(id)}
							{record.type === DEACTIVATE.id && record.status != DONE.id && (
								<React.Fragment>
									<span> | </span>
									{this.deactivateJobButton(id)}
								</React.Fragment>
							)}
						</span>
					);
				},
			}
		];

		const action = (record) => (
			<Dropdown menu={{ items: menu(record) }} trigger={['click']}>
				<a className="ant-dropdown-link" href="#">
					<FormattedMessage {...messages.actions} />
					<DownOutlined />
				</a>
			</Dropdown>
		);

		const typeToInvoice = {
			INSTALLATION: INSTALLATION.id,
			SWAP: SWAP.id,
			TRANSFER: TRANSFER.id,
			RECONNECTION: RECONNECTION.id,
			UNINSTALLATION: UNINSTALLATION.id,
			REVISION: REVISION.id,
			ACTIVATE: ACTIVATE.id
		};

		const menu = (record) => {
			const items = [
				{
					key: '1',
					label: this.renderViewButton(record.id)
				},
			]
			return items;
		};

		const card = (record) => {
			let STATUS = getJobStatus(record.status);
			let title = (
					<Row align="middle">
						<Col span={14}>
							<CircleBadge color={STATUS.iconColor} text={intl.formatMessage({...messages[STATUS.intl]})}/>
							<span> | </span>
							{record.client.name}
						</Col>
					</Row>
				);
			return (

				<Row key={record.id}  type="flex" justify="space-around" align="middle">
					<Col xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:24}}>
						<Card loading={isLoading} title={title} extra={action(record)} style={{ width: "100%" }}>
							<Row  type="flex" justify="center">
								<Col span={8}>
									<strong><FormattedMessage {...messages.date} />:</strong>
								</Col>
								<Col span={12}>
									{formatDateWithTime(record.programmedDate, intl.formatMessage({...messages.intl}),
                                                            {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'},
                                                            {hour: '2-digit', minute:'2-digit', hour12: true})}
								</Col>
							</Row>
							<Row  type="flex" justify="center">
								<Col span={8}>
									<strong><FormattedMessage {...messages.technicians} />:</strong>
								</Col>
								<Col span={12}>
									{this.renderTechs(record.technicians)}
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
				);
		};
		return (
			<div className="view">
                <AddJobModal
                    showModal={ showAddModal }
                    handleCancel={() => this.setState({ showAddModal: false, duplicateJobId: 0 })}
                    duplicateJobId={duplicateJobId}
				/>
                <ViewJobModal
                    showModal={ showViewModal }
                    handleCancel={() => this.setState({ showViewModal: false })}
                    jobId={jobId}
				/>
				<LinkJobModal
					showModal={showLinkModal}
                    handleCancel={() => this.setState({ showLinkModal: false })}
					selectedJobs={selectedJobs}
					invoicesToLink={invoicesToLink}
					clearSelectedRows={this.clearSelectedRows}
				/>
			
				{(!isMobile() ? 
					<CommonTable
						columns = {columns}
						dataSource = {jobs}
						search={(searchObject, page, pageSize) => this.props.searchJobs(searchObject, page, pageSize)}
						searchText={searchTable === "" ? undefined : searchTable}
						recordCount={recordCount}
						getRecords={(page, pageSize) => this.props.searchJobs({}, page, pageSize)}
						Add={(!userRoles.includes(ROLES.TECHSUPPORT) && <AddJobButton />)}
						loading={isLoading}
						getCheckboxProps={record => ({ disabled: (record.status != DONE.id || !Object.values(typeToInvoice).includes(record.type)) || record.invoiceInfo == null })}
						filterComponent = {(<JobSearchDrawer showDrawer={showAdvancedSearchDrawer} handleOnClose={() => this.setState({ showAdvancedSearchDrawer: false })}/>)}
						searchBarAddonAfterFunction={() => this.setState({ showAdvancedSearchDrawer: true })}
						searchBarAddonAfterTooltip={intl.formatMessage({...messages.advancedSearch})}
						disableSearchBar={userRoles.includes(ROLES.TECHSUPPORT) && true}
						getJobsSelected= {this.getJobsSelected}
						linkJobsSelected={this.linkJobsSelected}
						clearSelectedRows={this.clearSelectedRows}
						cleanSelectedRows={cleanSelectedRows}
						mutuallyExclusiveFilter={true}
						preserveSelectedRowKeys
						showCheckInBtn
					/> :
					<MobileCommonTable 
						dataSource = {jobs}
						Add={(!userRoles.includes(ROLES.TECHSUPPORT) && <AddJobButton />)}
						search={(searchObject, page, pageSize) => this.props.searchJobs(searchObject, page, pageSize)}
						searchText={searchTable === "" ? undefined : searchTable}
						recordCount={recordCount}
						loading={isLoading}
						card={card}
						getRecords={(page, pageSize) => this.props.searchJobs({}, page, pageSize)}
						filterComponent = {(<JobSearchDrawer showDrawer={showAdvancedSearchDrawer} handleOnClose={() => this.setState({ showAdvancedSearchDrawer: false })}/>)}
						searchBarAddonAfterFunction={() => this.setState({ showAdvancedSearchDrawer: true })}
						searchBarAddonAfterTooltip={intl.formatMessage({...messages.advancedSearch})}
						disableSearchBar={userRoles.includes(ROLES.TECHSUPPORT) && true}
						mutuallyExclusiveFilter={true}
					/>
				)}
			</div>	
		);
	}
}

Job.propTypes = {
	intl: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	jobs: PropTypes.array,
	searchJobs: PropTypes.func,
	recordCount: PropTypes.number,
	isLoading: PropTypes.bool,
	saveSelectJobs: PropTypes.func,
	invoiceJobs: PropTypes.bool,
	proformaJobs: PropTypes.bool,
	userRoles: PropTypes.array.isRequired,
	searchTable: PropTypes.string,
};

const mapStateToProps = (state) => {
	return {
		invoiceJobs: state.job.invoiceJobs,
		proformaJobs: state.job.proformaJobs,
		userRoles: state.auth.roles,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
        saveSelectJobs: (selected, checkInType) => {
			dispatch(SaveSelectJobs(selected, checkInType));
		},
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(Job)));
