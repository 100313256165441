import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Tooltip, Switch } from 'antd';
import { getLanguageIntl, getCalendarMessages, getactionMessages, getJobMessages } from '../../../constants/messages';
import { JOBSTATUS, getJobStatus } from  '../constants';
import { existsInArray, cleanArray, objectToArray } from '../../../utilities/util';
import { standardSwitchProps, getStatusIconColor } from  './Calendar';

let intlMessages = getLanguageIntl(),
    calendarMessages = getCalendarMessages(),
    actionMessages = getactionMessages(),
    JobsMessages = getJobMessages();

const messages = {
    ...intlMessages,
    ...calendarMessages,
    ...actionMessages,
	...JobsMessages
};

class StatusFilter extends React.Component {

    onChange = (checked, status) => {
        const {filterEvents} = this.props;
        const {showTheseStatus} = this.props;
        let showTheseNow = [];
        if (checked)
            showTheseNow = [...showTheseStatus].concat([status]);
        else
            showTheseNow = cleanArray(showTheseStatus, status);
        filterEvents(showTheseNow);
    }

    isSwitchChecked = (status) => {
        const {showTheseStatus} = this.props;
        if (existsInArray(status, showTheseStatus))
            return true;
        else
            return false;
    }

    renderSwitch = (status) => {
        const {isSwitchChecked} = this;
        const {intl} = this.props;
        let style = getStatusIconColor(status);
        let switchProps = {
            ...standardSwitchProps,
            style: {background:"#EEEEEE"},
            checkedChildren : React.cloneElement(standardSwitchProps.checkedChildren, {style:style}), 
            unCheckedChildren: React.cloneElement(standardSwitchProps.unCheckedChildren, {style:style})
        };
        return (
            <span key={status}>
                <Tooltip title={intl.formatMessage({...messages[getJobStatus(status).intl]})}  mouseEnterDelay={0}>
                    <Switch {...switchProps} checked={isSwitchChecked(status)} onChange={(checked) => this.onChange(checked, status)}/>
                </Tooltip>
                &nbsp;
            </span>
        );
    }

    renderStatusSwitches = () => {
        const {renderSwitch} = this;
        const statusList = objectToArray(JOBSTATUS).map(status => status.id);
        return (statusList.map(status => renderSwitch(status)));
    }

	render(){
		return (
            <span>
                {this.renderStatusSwitches()}
            </span>
		);
	}
}

StatusFilter.propTypes = {
	intl: PropTypes.object.isRequired,
	filterEvents: PropTypes.func.isRequired,
    showTheseStatus: PropTypes.array.isRequired,
};

export default injectIntl(StatusFilter);