import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Modal, Input, Row, Col, Checkbox, InputNumber, DatePicker } from 'antd';
import { injectIntl } from 'react-intl';
import { getReportMessages, getIncomeMessages } from '../../../constants/messages';
import React from 'react';
import { getCurrencyDisplayer } from '../../../utilities/util';
const moment = require('moment');

const FormItem = Form.Item;
const dateFormat = "DD-MM-YYYY";

let reportMessages = getReportMessages(),
    incomeMessages = getIncomeMessages();

const messages = {
    ...reportMessages,
    ...incomeMessages
};

class BankModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currencyDisplayer: getCurrencyDisplayer(this.props.bank.isDollar)
        }
    }

    componentDidMount() {
    }

	getIntl = (str) => {
		return this.props.intl.formatMessage({...messages[str]});
	}

    submit = () => {
        this.props.form.validateFields({ force: true }, (err, values) => {
            if (err)
                return;
            this.props.submit({ ...values, createdAt: moment(), initialDate: moment(values.initialDate).toISOString() }, this.props.bank.id);
            this.props.handleCancel();
        });
    }

    amountValidator = (rule, value, callback) => {
        if (!(value >= 0))
            callback(this.props.intl.formatMessage({ ...messages.invalidAmount }));
        callback();
    }

    canModifyAmount = () => {
        if (this.props.bank.entries.length > 0)
            return true
        return false
    }

    changeCurrency = (val) => {
        this.setState({
            currencyDisplayer: getCurrencyDisplayer(val.target.checked)
        });
    }

    disabledDate = (current) => {
        if (current) return current && current > moment().endOf('day')
    }

    renderInitialDate = () => {
		const { props, getIntl, disabledDate, canModifyAmount } = this;
        const { form } = props;
        const { getFieldDecorator } = form;
        const initialDate = props.bank.initialDate;
		let dateMoment = moment(initialDate).utc();
        return (
			<FormItem label={getIntl("initialDate")}>
			{
				getFieldDecorator(`initialDate`, 
					{
						initialValue: dateMoment.isValid() ? dateMoment : null,
						rules: [{
                            required: true,
                            message: getIntl("initialDateError"), 
						}]
					}
				)(
                    <DatePicker
                        style={{ width: '100%' }}
						placeholder={getIntl("initialDate")}
						format={dateFormat}
						disabledDate={disabledDate}
						disabled={canModifyAmount()}
						allowClear={false}
					/>
				)
			}
			</FormItem>
        );
    }

    render() {
        const { props, renderInitialDate } = this;
        const { getFieldDecorator } = props.form;
        return (
            <Modal
                title={this.props.intl.formatMessage({ ...messages.addCategory })}
                destroyOnClose
                open={this.props.showModal}
                maskClosable={false}
                onCancel={this.props.handleCancel}
                footer={
                    <div>
                        <Button key="cancel" type="default" size="default" onClick={this.props.handleCancel}>
                            {this.props.intl.formatMessage({ ...messages.cancel })}
                        </Button>
                        <Button key="submit" type="primary" size="default" onClick={this.submit}>
                            {this.props.intl.formatMessage({ ...messages.accept })}
                        </Button>
                    </div>
                }
            >
                <Form layout='vertical'>
                    <Row gutter={16} type="flex" align="middle">
                        <Col span={15}>
                            <Form.Item label={this.props.intl.formatMessage({ ...messages.name })}>
                                {getFieldDecorator('name', {
                                    rules: [{
                                        required: true, message: this.props.intl.formatMessage({ ...messages.name }),
                                    }],
                                    initialValue: this.props.bank.name
                                })(
                                    <Input placeholder={this.props.intl.formatMessage({ ...messages.name })} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={9} style={{ paddingLeft: "10px" }}>
                            <Form.Item label={this.props.intl.formatMessage({ ...messages.active })}>
                                {getFieldDecorator('active', {
                                    valuePropName: 'checked',
                                    initialValue: this.props.bank.active
                                })(
                                    <Checkbox >{this.props.intl.formatMessage({ ...messages.active })}</Checkbox>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} type="flex" align="middle">
                        <Col span={15}>
                            <Form.Item label={this.props.intl.formatMessage({ ...messages.initialBalance })}>
                                {getFieldDecorator('initialAmount', {
                                    rules: [{
                                        required: true, message: this.props.intl.formatMessage({ ...messages.initialBalanceError }),
                                    },
                                    {
                                        validator: this.amountValidator
                                    }],

                                    initialValue: this.props.bank.initialAmount,
                                })(
                                    <InputNumber
                                        placeholder={this.props.intl.formatMessage({ ...messages.initialBalance })}
                                        onChange={this.changeAmount}
                                        formatter={value => `${this.state.currencyDisplayer.symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(this.state.currencyDisplayer.regex, '')}
                                        size={"default"}
                                        style={{ width: '100%', 'textAlign': 'right' }}
                                        disabled={this.canModifyAmount()}
                                        min={0.00}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={9} style={{ paddingLeft: "10px" }}>
                            <Form.Item label={this.props.intl.formatMessage({ ...messages.accountInDollars })}>
                                {getFieldDecorator('isDollar', {
                                    valuePropName: 'checked',
                                    initialValue: this.props.bank.isDollar,
                                })(
                                    <Checkbox onChange={this.changeCurrency} >{this.props.intl.formatMessage({ ...messages.accountInDollars })}</Checkbox>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    {renderInitialDate()}
                </Form>
            </Modal>
        );
    }
}

BankModal.propTypes = {
    bank: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    handleCancel: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    showModal: PropTypes.bool.isRequired,
    submit: PropTypes.func.isRequired,
};

export default injectIntl(Form.create()(BankModal));