import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { Modal, Button, message } from 'antd';
import AddOrEditForm from './AddOrEditForm';
import { LoadingOutlined, SaveOutlined } from '@ant-design/icons';
import { getModalMessages, getUnitsMessages } from '../../constants/messages';
import { AddNewUnit, UpdateUnit } from './actions';


let ModalMessages = getModalMessages(),
    UnitsMessages = getUnitsMessages();

const messages = {
	...ModalMessages,
    ...UnitsMessages,
};

const AddOrEditModal = ({onCancel, title, visible, updateUnits, record, isEdit, models}) => {
    const intl = useIntl();
	const form = useRef();
    const dispatch = useDispatch();
    const [isSaving, setSaving] = useState(false);
   
    const closeModal = () => {
		onCancel();
	};

    const onOk = async () => {
        const units = [];
		try {
			setSaving(true);
            const values = await form.current.validateFields();		
            if(isEdit){
                const unit = await dispatch(UpdateUnit(values));
                units.push(unit);
            }else{
                const unit = await dispatch(AddNewUnit(values));
                units.push(unit);
            }
            updateUnits(units, false);
            message.success(intl.formatMessage(isEdit ? {...messages.editUnitSuccess }: {...messages.addUnitSuccess }));
            closeModal();
		} catch (error) {
            message.error(intl.formatMessage(isEdit ? {...messages.editUnitError }: {...messages.addUnitError }));
		} finally {
			setSaving(false);
		}
	};

    let buttonGroup = [
		<Button key="cancel" onClick={closeModal}>
			<FormattedMessage {...messages.cancel}  />
		</Button>,
		<Button type="primary" key="save" onClick={onOk}>
			{isSaving ? <LoadingOutlined /> : <SaveOutlined />}
			<FormattedMessage {...messages.save}  />
		</Button>,
	];

    return (
        <Modal
            open={visible}
            title={title}
            onOk={onOk}
            onCancel={closeModal}
            destroyOnClose={true}
            footer={buttonGroup}
        >
            <div>
                <AddOrEditForm form={form} record={record} models={models}/>
            </div>
        </Modal>
    );
};

AddOrEditModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string,
    visible: PropTypes.bool,
    updateUnits: PropTypes.func,
    isEdit: PropTypes.bool,
	models: PropTypes.array,
    record: PropTypes.object,
};

export default AddOrEditModal;
