import React, { useEffect, useState } from 'react'
import { GetMedicalDeductions } from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, message } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { getFields, getPayrollMessages, getactionMessages } from '../../../../../constants/messages';
import DeductionsTable from '../DeductionsTable';
import { MEDICALDEDUCTIONS, formatPrice } from '../../../constants';
import EditMedicalDeductionModal from './EditMedicalDeductionModal';

let PayrollsMessages = getPayrollMessages(),
	FieldsMessages = getFields(),
	ActionMessages = getactionMessages();

const messages = {
	...PayrollsMessages,
	...FieldsMessages,
	...ActionMessages,
};

const { MEDICAL, IHSS } = MEDICALDEDUCTIONS;

const MedicalDeductions = () => {	
	const intl = useIntl();
    const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [openEditModal, setOpenEditModal] = useState(false);
	const [recordToEdit, setRecordToEdit] = useState({});
	const medicalDeductions = useSelector((state) => state.payroll.medicalDeductions);

    useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);
				await dispatch(GetMedicalDeductions());
			} catch (error) {
				message.error(intl.formatMessage({ ...messages.getMedicalDeductionError }));
			} finally {
				setLoading(false);
			}
		};
		fetchData();
	}, []);

	
    const editRecord = (record) => {
		setRecordToEdit(record);
		setOpenEditModal(true);
	};

	const tableActions = (record) => {
		return (
				<Typography.Link
					className="editable-add-btn"
					onClick={() => editRecord(record)}
				>
					<span className="icon-pencil" />
					<FormattedMessage {...messages.edit} />
				</Typography.Link>
		);
	};

	const renderDescription = (name) => {
		if(name == IHSS.name){
			return intl.formatMessage({ ...messages.ihss })
		}else if (name == MEDICAL.name){
			return intl.formatMessage({ ...messages.medicalExpenses })
		}
	};

	const columns = [
		{
			title: intl.formatMessage(({ ...messages.description})),
			dataIndex: 'name',
			key: 'name',
			render: (id, record) =>	renderDescription(record.name),
		},
		{
			title: intl.formatMessage(({ ...messages.amount})),
			dataIndex: 'value',
			key: 'value',
			render: (id, record) => formatPrice(record.value)
		},
		{
			title: intl.formatMessage(({ ...messages.actions})),
			dataIndex: 'id',
			render: (id, record) => tableActions(record),
		},
	];

  return (
		<React.Fragment>
			<DeductionsTable
				dataSource={medicalDeductions}
				columns={columns}
				isLoading={loading}
				count={medicalDeductions.length}
				pageSize={medicalDeductions.length} 
			/>
			<EditMedicalDeductionModal
				open={openEditModal}
				record={recordToEdit}
				title={intl.formatMessage({ ...messages.edit })}
				closeModal={() => setOpenEditModal(false)} 
			/>
		</React.Fragment>
	)
}

export default MedicalDeductions
