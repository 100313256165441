import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Radio } from 'antd';
import { injectIntl } from 'react-intl';
import { getPopConfirm } from '../../../../constants/messages';

let popConfirmMessages = getPopConfirm();

const messages = {
	...popConfirmMessages
};

const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;
const FormItem = Form.Item;

class YesNoTemplate extends React.Component {

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    onChange = (value) => {
        const { props } = this;
        const { onChange } = props;
        if ('onChange' in props) onChange(value);
    }
    
	render() {
        const { props, getIntl, onChange } = this;
        const { form, initialValue, disabled, required, label, fieldName, errorMessage, disableYes, disableNo } = props;
        const { getFieldDecorator } = form;
        const options = [
            <RadioButton key={0} value={false} disabled={disableNo}>{getIntl("no")}</RadioButton>,
            <RadioButton key={1} value={true} disabled={disableYes}>{getIntl("yes")}</RadioButton>
        ];
        return (
            <FormItem label={label} >
            {
                getFieldDecorator(fieldName, 
                    {
                        initialValue,
                        rules: [{
                            required,
                            message: errorMessage 
                        }],
                        onChange: (event) => onChange(event.target.value)
                    }
                )(<RadioGroup disabled={disabled}>{options}</RadioGroup>)
            }
            </FormItem>
        );
    }
}

YesNoTemplate.defaultProps = {
    disabled: false,
    required: true,
    disableYes: false,
    disableNo: false
};

YesNoTemplate.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    initialValue: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    label: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    errorMessage: PropTypes.string,
    disableYes: PropTypes.bool,
    disableNo: PropTypes.bool
};

export default injectIntl(YesNoTemplate);