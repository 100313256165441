import React from 'react';
import PropTypes from 'prop-types';
import { Select, Spin, message, Skeleton, Collapse } from 'antd';
import { injectIntl } from 'react-intl';
import { getDeviceMessages} from '../../../constants/messages';
import {SearchSimCards, GetSimCardInformation} from '../actions';
import SimRender from './Renders/SimRender';
const { Panel } = Collapse;

const Option = Select.Option;

let deviceMessages = getDeviceMessages();

const messages = {
	...deviceMessages
};

class Sim extends React.Component  {

	constructor(props){
        super(props);
        this.state = {
            selectLoading: false,
            simCards: [],
            skeletonLoading: false,
            simInformation: {}
        };
    }

    handleSimSearch = (name) => {
        const {intl} = this.props;
        if (name.length <= 0 ) this.setState({simCards: []});
        else {
            this.setState({selectLoading: true});
            SearchSimCards(name.trim())
            .then((response) => this.setState({selectLoading: false, simCards: response}))
            .catch( () => {
                this.setState({selectLoading: false, simCards: []});
                message.error(intl.formatMessage({...messages.simSearchError}));
            });
        }
    }

    buildSimName = (simCard) => {
        let names = [];
        if (simCard.line !== undefined && simCard.line !== null) names.push(simCard.line);
        if (simCard.serial !== undefined && simCard.serial !== null) names.push(simCard.serial);
        return names.join(" - ");
    }

    buildOptions = () => {
        const {simCards} = this.state;
        return simCards.map((simCard, index) => <Option key={index} value={simCard.id}>{this.buildSimName(simCard)}</Option>)
    }

    handleSelect = (simid) => {
        const {intl} = this.props;
        if (!simid) this.setState({skeletonLoading: false, simInformation: {}});
        else {
            this.setState({skeletonLoading: true});
            GetSimCardInformation(simid)
            .then((response) => this.setState({skeletonLoading: false, simInformation: response}))
            .catch( () => {
                this.setState({skeletonLoading: false, simInformation: {}});
                message.error(intl.formatMessage({...messages.simInfoError}));
            });
        }
    }

    renderSimSelector = () => {
        const {selectLoading} = this.state;
        const {intl} = this.props;
        let simOptions = this.buildOptions();
		return (
            <Select
                className="job-full-component device-finder-select-padding"
                showSearch
                placeholder={intl.formatMessage( {...messages.simSearch} )}
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0}
                onSearch={this.handleSimSearch}
                notFoundContent={selectLoading ? <Spin size="small" /> : intl.formatMessage({...messages.notFound})}
                onChange={this.handleSelect}
            >
                {simOptions}
            </Select>
		);
    }

    renderSimInformation = () => {
        const {skeletonLoading, simInformation} = this.state;
        if (skeletonLoading)
            return (
                <div>
                    <Skeleton active={skeletonLoading}/>
                    <Skeleton active={skeletonLoading}/>
                </div>
            );
        else if (Object.keys(simInformation).length > 0 && simInformation.constructor === Object) {
            return (
                <SimRender sim={simInformation}/>
            );
        }
    }

	render(){
        const {skeletonLoading, simInformation} = this.state;
        const expand = skeletonLoading || (Object.keys(simInformation).length > 0 && simInformation.constructor === Object);
        return(
            <div>
                {this.renderSimSelector()}
                <Collapse className="job-view-reconnect-collapse" bordered={false} activeKey={expand ? 1 : 0}>
                    <Panel key={1} showArrow={false}>
                        {this.renderSimInformation()}
                    </Panel>
                </Collapse>
            </div>
        );
	}
}

Sim.propTypes = {
	intl: PropTypes.object.isRequired
};

export default injectIntl(Sim);