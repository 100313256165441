import PropTypes from 'prop-types';
import { DownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Col, Button, Row, message, Badge, Card, Dropdown, Popconfirm } from 'antd';
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from 'react-intl';
import React from 'react';
import { getReportMessages, getIncomeMessages } from '../../../constants/messages';
import {
    CreateCategory, CountAccountCategories,
    GetAccountCategories, UpdateCategory,
    SwitchCategoryStatus, FilterCategories,
    RestoreFilterValues, DeleteParent
} from '../actions';
import CategoryModal from './CategoryModal';
import CommonTable from '../../../components/CommonTable';
import Mayre from 'mayre';
import MobileCommonTable from '../../../components/MobileCommonTable';
import FilterButtons from './filterButtons';
import { isMobile, ACL4Component } from '../../../utilities/util';
import { ROLES } from '../../../constants/global';


let reportMessages = getReportMessages(),
    incomeMessages = getIncomeMessages()

const messages = {
    ...reportMessages,
    ...incomeMessages
};

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            showCategoryModal: false,
            selectedCategoryId: 0,
            completeSubmit: (() => { })
        }
    }

    componentDidMount() {
        this.loadFirstEntries();
        RestoreFilterValues();
    }

    loadFirstEntries = () => {
        this.props.countAccountCategories().then(() => {
            if(this.props.search ===""){
                this.props.getAccountCategories(1).then(() => {
                   
                });
            }

            this.setLoading(false);
           
        });
    }

    loadMoreEntries = (page) => {
        this.setLoading(true);
        this.props.getAccountCategories(page).then(() => {
            this.setLoading(false);
        });
    }

    setLoading = (val) => {
        this.setState({
            isLoading: val
        });
    }

    openCategoryModal = () => {
        this.setState({
            showCategoryModal: true,
            completeSubmit: this.submitCategory,
            selectedCategoryId: undefined
        });
    }

    closeCategoryModal = () => {
        this.setState({
            showCategoryModal: false
        });
    }

    submitCategory = (values) => {
        this.setLoading(true);
        this.props.createCategory(values).then(() => {
            this.setLoading(false);
        });
    }


    openEditCategoryModal = (id) => {
        this.setState({
            showCategoryModal: true,
            selectedCategoryId: id,
            completeSubmit: this.editCategory,
        });
    }

    closeEditCategoryModal = () => {
        this.setState({
            showCategoryModal: false
        });
    }

    editCategory = (values, id) => {
        this.setLoading(true);
        this.props.updateCategory(values, id).then(() => {
            this.setLoading(false);
            message.success(`${this.props.intl.formatMessage({ ...messages.categoryUpdateSuccesfull })}`)
        }).catch(() => {
            message.success(`${this.props.intl.formatMessage({ ...messages.categoryUpdateError })}`)
        });
    }

    badgeCreator = (active) => {
        const { intl } = this.props;
        let badgeProps = {
            status: "default",
            text: intl.formatMessage({ ...messages.disabled })
        };
        if (active)
            badgeProps = {
                status: "success",
                text: intl.formatMessage({ ...messages.enabled })
            };
        return (<Badge {...badgeProps} />);
    }

    badgeColor = (active) => {
        let badgeProps = {
            status: "default",
        };
        if (active)
            badgeProps = {
                status: "success",
            };
        return (<Badge {...badgeProps} />);
    }

    modButton = (category) => {
        let statusMessage = this.getStatusMessage(category);
        return (
            <a style={{ paddingLeft: "5px" }} onClick={() => this.updateStatus(category)}>
                <FormattedMessage {...statusMessage} />
            </a>)
    }


    getTableColumns = () => {
        let { intl, roles } = this.props;
        let columns = [
            {
                title: `${intl.formatMessage({ ...messages.name })}`,
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: `${intl.formatMessage({ ...messages.active })}`,
                dataIndex: 'active',
                render: (active) => {
                    return this.badgeCreator(active);
                }
            }
        ];
        let actionCol = {
            title: `${intl.formatMessage({ ...messages.actions })}`,
            render: (data) => {
                return (
                    <span>
                        <span key="0">
                            <a onClick={() => this.openEditCategoryModal(data.id)}>
                                <FormattedMessage {...messages.edit} />
                            </a>
                        </span> |
                        <span key="1">
                            {this.modButton(data)}
                        </span>
                    </span>
                );
            }
        }
        if (roles.includes(ROLES.INCOMESEXPENSES))
            columns.push(actionCol);
        return columns;
    }

    getCategoryAt = (id) => {
        const baseCategory = { name: "", active: true, id: undefined, parentId: null, subCategories: [] };
        if (!id)
            return baseCategory;

        let category = this.props.categories.find((value) => {
            return value.id === id;
        });

        if (category === undefined)
            return baseCategory
        return { ...category }
    }

    updateStatus(category) {
        this.setLoading(true);
        this.props.switchCategoryStatus(category).then(() => {
            this.setLoading(false);
            message.success(`${this.props.intl.formatMessage({ ...messages.categoryUpdateSuccesfull })}`)
        }).catch(() => {
            message.success(`${this.props.intl.formatMessage({ ...messages.categoryUpdateError })}`)
        });
    }

    filterCategories = (value, page, pageSize) => {
        const { intl, filterCategories } = this.props;
        this.setState({ isLoading: true });
        filterCategories(value, page, pageSize)
            .then(() => {
                this.setState({
                    isLoading: false
                });
            })
            .catch(() => {
                message.error(`${intl.formatMessage({ ...messages.categoryUpdateError })}`);
                this.setState({
                    isLoading: false
                });
            });
    }

    getStatusMessage = (category) => {
        let statusMessage = { ...messages.activate };
        if (category.active)
            statusMessage = { ...messages.deactivate };
        return statusMessage;
    }

    findChilds = (id) => {
        let childs = this.props.categories.filter((cat) => cat.parentId === id);
        return childs;
    }

    expandedRow = (category) => {
        return (
            <React.Fragment>
                <Row>
                    <Col span={4}>

                    </Col>
                    <Col span={8}>
                        <h4><FormattedMessage {...messages.subCategory} /></h4>
                    </Col>
                </Row>
                {category.subCategories.map((subCat, index) => {
                    return (
                        <Row key={index}>
                            <Col span={4}></Col>
                            <Col span={12}>
                                {subCat.name}
                            </Col>
                            <Col span={4}>
                                <Popconfirm
                                    onConfirm={() => this.deleteParent(subCat.id)}
                                    title={`${this.props.intl.formatMessage({ ...messages.removeWarning })}: ${subCat.name}`}
                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
                                    <a>
                                        <FormattedMessage {...messages.remove} />
                                    </a>
                                </Popconfirm>
                            </Col>

                        </Row>
                    );

                })}
            </React.Fragment>
        );
    }



    getCard = (record) => {
        const action = (record) => (
            <Dropdown menu={{ items: menu(record) }} trigger={['click']}>
                <a className="ant-dropdown-link" href="#">
                    <FormattedMessage {...messages.actions} />
                    <DownOutlined />
                </a>
            </Dropdown>
        );

        const menu = (record) => {
            let statusMessage = this.getStatusMessage(record);

            const items = [
                {
                    key: '1',
                    label: (
                        <a onClick={() => this.openEditCategoryModal(record.id)}>
                            <FormattedMessage {...messages.edit} />
                        </a>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <a onClick={() => this.updateStatus(record)}>
                            <FormattedMessage {...statusMessage} />
                        </a>
                    ),
                }
            ]
            return items;
        };

        const title = (
            <div>
                <Row align="middle">
                    <Col xs={{ span: 2 }} sm={{ span: 2 }} md={{ span: 2 }} lg={{ span: 2 }}>
                        {this.badgeColor(record.active)}
                    </Col>
                    <Col xs={{ span: 14 }} sm={{ span: 14 }} md={{ span: 14 }} lg={{ span: 14 }}>
                        {record.name}
                    </Col>
                    <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
                        {action(record)}
                    </Col>
                </Row>
            </div>
        );
        return (
            <Row key={record.id} type="flex" justify="space-around" align="middle">
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <Card
                        loading={this.state.isLoading}
                        style={{ width: "100%" }}
                    >
                        {title}
                    </Card>
                </Col>
            </Row>
        );
    };

    deleteParent = (id) => {
        this.props.deleteParent(id).then(() => {
            message.success(this.props.intl.formatMessage({ ...messages.removedSuccesfully }));
        }).catch(() => {
            message.error(this.props.intl.formatMessage({ ...messages.errorRemoving }));
        });
    }

    render() {
        let columns = this.getTableColumns();
        return (
            <Col className="category view" >
                <CategoryModal
                    handleCancel={this.closeCategoryModal}
                    showModal={this.state.showCategoryModal}
                    submitCategory={this.state.completeSubmit}
                    categoryData={this.getCategoryAt(this.state.selectedCategoryId)}
                />
                <Mayre
                    of={
                        <CommonTable
                            columns={columns}
                            dataSource={this.props.categories}
                            search={this.props.filterCategories}
                            searchText={this.props.search}
                            recordCount={this.props.categoriesCount}
                            getRecords={this.loadMoreEntries}
                            Add={this.addButton()}
                            expandedRow={this.expandedRow}
                            loading={this.state.isLoading}
                            getCheckboxProps={() => { }}
                            hideCheckboxes
                            filterComponent={(<FilterButtons />)}
                        />
                    }
                    or={
                        <MobileCommonTable
                            dataSource={this.props.categories}
                            search={this.props.filterCategories}
                            searchText={this.props.search}
                            recordCount={this.props.categoriesCount}
                            getRecords={this.loadMoreEntries}
                            Add={this.addButton()}
                            loading={this.state.isLoading}
                            getCheckboxProps={() => { }}
                            hideCheckboxes
                            filterComponent={(<FilterButtons />)}

                            card={this.getCard}
                        />
                    }
                    when={!isMobile()}
                />
            </Col>
        );
    }

    addButton = () => {
        return (
            ACL4Component(
                this.props.roles,
                <div style={{ paddingLeft: "22px" }}>
                    <Button type="primary" loading={this.state.isLoading} onClick={this.openCategoryModal} style={{ width: "257px" }}>
                        <FormattedMessage {...messages.addCategory} />
                    </Button>
                </div>,
                [
                    ROLES.INCOMESEXPENSES
                ]
            )
        )
    }
}

Category.propTypes = {
    intl: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
    createCategory: PropTypes.func.isRequired,
    updateCategory: PropTypes.func.isRequired,
    deleteParent: PropTypes.func.isRequired,
    filterCategories: PropTypes.func.isRequired,
    switchCategoryStatus: PropTypes.func.isRequired,
    countAccountCategories: PropTypes.func.isRequired,
    getAccountCategories: PropTypes.func.isRequired,
    categoriesCount: PropTypes.number.isRequired,
    search: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        categories: state.incomeReport.categories,
        categoriesCount: state.incomeReport.categoriesCount,
        roles: state.auth.roles,
        search: state.incomeReport.search,
    };
}

const mapDispatchToProps = (dispatch) => ({
    createCategory: (entryData) => dispatch(CreateCategory(entryData)),
    deleteParent: (id) => dispatch(DeleteParent(id)),
    updateCategory: (entryData, id) => dispatch(UpdateCategory(entryData, id)),
    filterCategories: (searchData, page, pageSize) => dispatch(FilterCategories(searchData, page, pageSize)),
    switchCategoryStatus: (category) => dispatch(SwitchCategoryStatus(category)),
    getAccountCategories: (page) => dispatch(GetAccountCategories(page)),
    countAccountCategories: () => dispatch(CountAccountCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Category));

