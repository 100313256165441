import PropTypes from 'prop-types';
import { List, Row, Col, Button, Modal, Tag, Popover } from 'antd';
import { injectIntl } from 'react-intl';
import { getReportMessages, getLanguageIntl } from '../../constants/messages';
import React from 'react';
import { roundN } from '../../utilities/util';


let reportMessages = getReportMessages(),
    languageMessages = getLanguageIntl();

const messages = {
    ...reportMessages,
    ...languageMessages,
};

class InvoicesModal extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    loadListIds(list) {
        return list.map(item => item.id);
    }

    emailReport = () => {
        let { invoicesId, debitNotesIds } = this.GetDocumentsIds();
        this.props.emailAccountState({
            clientId: this.props.client.id,
            invoicesIds: invoicesId,
            debitNotesIds: debitNotesIds,
            clientName: this.props.client.name
        });
    }

    printReport = () => {
        let { invoicesId, debitNotesIds } = this.GetDocumentsIds();
        this.props.printAccountState({
            clientId: this.props.client.id,
            invoicesIds: invoicesId,
            debitNotesIds: debitNotesIds,
            clientName: this.props.client.name
        });
    }

    GetDocumentsIds() {
        let invoicesId = this.loadListIds(this.props.client.invoices);
        let debitNotesIds = this.loadListIds(this.props.client.debitNotes);
        if(invoicesId === undefined)
            invoicesId = [];
        if(debitNotesIds === undefined)
            debitNotesIds = [];
        return { invoicesId, debitNotesIds };
    }


    DisplayDocItem(item, tagColor, icon) {
        return (
            <List.Item>
                <Col span={24}>
                    <Row>
                        <Col span={6}>
                            {new Date(item.createdAt).toLocaleDateString(this.props.intl.formatMessage({ ...messages.intl }), { year: 'numeric', month: 'numeric', day: 'numeric' })}
                        </Col>
                        <Col span={12}>
                            <a key="action" href={`/invoices/${item.id}`} target="_blank" rel="noopener noreferrer"> ({icon})  {item.documentNumber}  </a>
                        </Col>
                        <Col span={6}>
                            <Tag color={tagColor}> {`L. ${roundN(item.balance, 2)}`} </Tag>
                        </Col>
                    </Row>
                </Col>
            </List.Item>
        )
    }

    render() {
        if (this.props.client === undefined)
            return <div></div>;

        return (
            <Modal
                title={`${this.props.intl.formatMessage({ ...messages.accountStatement })}: ${this.props.client.name} `}
                destroyOnClose
                open={this.props.showModal}
                onCancel={this.props.handleOk}
                footer={
                    <div>
                        <Button key="print" type="primary" size="default" onClick={this.printReport} className="button-spacing">
                            {this.props.intl.formatMessage({ ...messages.download })}
                        </Button>
                        <Button key="email" type="primary" size="default" onClick={this.emailReport} className="button-spacing">
                            {this.props.intl.formatMessage({ ...messages.send })}
                        </Button>
                        <Button key="submit" type="default" size="default" onClick={this.props.handleOk} className="button-spacing">
                            {this.props.intl.formatMessage({ ...messages.accept })}
                        </Button>
                    </div>
                }
                className="invoicesModal"
            >
                <List
                    className="invoicesList"
                    dataSource={this.props.documents}
                    renderItem={(item) => {
                        let { productsTip, tagColor, icon } = GetItemAttributes(item, this.props.intl.formatMessage({ ...messages.noProducts }));
                        return !item.isDebitNote ? (
                            <Popover placement="right" content={productsTip}>
                                {this.DisplayDocItem(item, tagColor, icon)}
                            </Popover>) :
                            (this.DisplayDocItem(item, tagColor, icon))
                    }}
                />
            </Modal>
        );
    }

}

InvoicesModal.propTypes = {
    intl: PropTypes.object.isRequired,
    client: PropTypes.object,
    showModal: PropTypes.bool.isRequired,
    documents: PropTypes.array.isRequired,
    handleOk: PropTypes.func.isRequired,
    emailAccountState: PropTypes.func.isRequired,
    printAccountState: PropTypes.func.isRequired,
};

function GetItemAttributes(item, noProductMessage) {
    let tagColor = "yellow";
    let icon = "DN";
    let productsTip = <p>{noProductMessage}</p>;
    if (!item.isDebitNote) {
        tagColor = (item.status === 2) ? "red" : "blue";
        productsTip = item.invoiceProducts.map((product, index) => {
            return <p key={index}> {product.description} ({product.quantity}): $ {roundN(product.price, 2)} </p>;
        });
        icon = "F";
    }
    return { productsTip, tagColor, icon };
}

export default injectIntl(InvoicesModal);