import React from 'react';
import PropTypes from 'prop-types';
import {
    injectIntl,
    FormattedMessage
} from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Modal, Row, Col, DatePicker, Upload, Steps, notification, message, } from 'antd';
import {
    getModalMessages,
    getInvoicingMessages,
    getErrorMessages,
    getReportMessages,
    getLanguageIntl,
    getFields
} from '../../../constants/messages';
import { connect } from 'react-redux';
import { GetInvoices, GetTaxRetentionConstancy, AddTaxRetentionConstancy, GetTaxImage, UploadArchive} from '../actions';
import Mayre from 'mayre';
import moment from 'moment';

let modalMessages = getModalMessages(),
    invoicingMessages = getInvoicingMessages(),
    errorMessages = getErrorMessages(),
    reportMessages = getReportMessages(),
    intlMessages = getLanguageIntl(),
    fields = getFields();

const messages = {
    ...modalMessages,
    ...invoicingMessages,
    ...errorMessages,
    ...reportMessages,
    ...intlMessages,
    ...fields
};

const FormItem = Form.Item;

class TaxRetentionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            adding: false,
            currentStep:0,
            isUploading: false,
            newTaxRetentionConstancy: null,
            fileList: [],
            canUpload: false,

        };
    }

    componentDidMount() {
        this.props.getTaxRetentionConstancy().then(() => {
            this.props.getTaxImage(this.props.taxRetentionConstancy.id).then(() => {
                this.setState({
                    isLoading: false
                });
            }).catch(() => {
                this.setState({
                    isLoading: false
                });
            });
            if (moment().isBefore(moment(this.props.taxRetentionConstancy.validThrough), 'days') && moment().add(1, 'week').isAfter(moment(this.props.taxRetentionConstancy.validThrough), 'days')) {
                notification.open({
                    message: this.props.intl.formatMessage({ ...messages.TaxRetention }),
                    description: this.props.intl.formatMessage({ ...messages.TaxRetentionNotification }),
                });
            }
        }).catch(() => {
            this.setState({
                isLoading: false
            });
        });
    }

    disabledDate = (current) => {
        return current < moment().subtract(1, 'days');
    }

    handleOk = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {

                let newTaxRetentionConstancy = {
                    ...values,
                    createdAt: moment(),
                };
                this.setState({
                    newTaxRetentionConstancy : newTaxRetentionConstancy,
                    currentStep: 1
                });
               
            }
        });
    }

    
    handleFinish = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                
                this.setState({
                    isLoading: true
                });

                if(this.state.canUpload==false){
                   
                    message.error(this.props.intl.formatMessage(messages.sizeArchiveError))
                    this.setState({
                        isLoading: false
                    });
                    return;
                }    
  
                this.props.addTaxRetentionConstancy(this.state.newTaxRetentionConstancy).then(() => {
                    this.props.getTaxRetentionConstancy().then(() => {
                      
                        const name = `taxes_${this.props.taxRetentionConstancy === undefined ? 0 : this.props.taxRetentionConstancy.id}`
                        UploadArchive(values,name).then((response)=>{
                            if(response){
                                message.success(this.props.intl.formatMessage(messages.uploadSuccessMessage));
                                this.handleCancel()
                            }
                        })
                    }).catch(() => {
                        this.setState({
                            isLoading: false
                        });
                    });
                }).catch(() => {
                    this.setState({
                        isLoading: false
                    });
                });
            }
        });
    }

    handleCancel = () => {
        this.setState({
            adding: false,
            currentStep: 0,
            fileList: [],
            isLoading: false,
            isUploading:false,
        });
        this.props.closeTaxModal();
    }

    beforeUpload = (file) => {
        const isLt2M = file.size / 1024 / 1024 < 2;
        this.setState({
            isUploading: true,
            canUpload: isLt2M
        });
        return false
    };

    handleChange = (info) => {
        this.setState({
            fileList: [info.file]
        });
            
        if (info.file.status === 'done') {

            this.setState({
                isUploading: false
            });
        }
    };

    showForm = () => {
        const uploadProps = {
            showUploadList: true,
            beforeUpload: this.beforeUpload,
            onChange: this.handleChange,
            accept: "image/jpeg, image/png",
        };
        const { getFieldDecorator } = this.props.form;
        return (
            <Form layout='vertical' className="login-form col-8">
                <Mayre of={
                    <FormItem label={this.props.intl.formatMessage({ ...messages.ValidThrough })}>
                        {getFieldDecorator('validThrough', {
                            rules: [{
                                required: this.state.currentStep === 0, message: this.props.intl.formatMessage({ ...messages.validThroughError }),
                            }],
                        })(
                            <DatePicker disabledDate={this.disabledDate} />
                        )}
                    </FormItem>
                } when={this.state.currentStep === 0} />
                <Mayre of={
                    <FormItem label={this.props.intl.formatMessage({ ...messages.Constancy })}>
                        {getFieldDecorator('file', {
                            rules: [{
                                required: this.state.currentStep === 1, message: this.props.intl.formatMessage({ ...messages.taxImageError }),
                            }],
                        })(
                            <Upload {...uploadProps} fileList={this.state.fileList}>
                                <Button loading={this.state.isLoading}>
                                    <FormattedMessage {...messages.Upload} />
                                </Button>
                            </Upload>
                        )}
                    </FormItem>
                } when={this.state.currentStep === 1} />
            </Form>
        );
    }

    showImageDate = () => {
        if (this.props.taxRetentionConstancy === undefined) {
            return (<div style={{ textAlign: "center" }}>
                <FormattedMessage {...messages.noData} />
            </div>);
        }
        let formatedDate1 = new Date(this.props.taxRetentionConstancy.createdAt);
        formatedDate1.toLocaleDateString();

        let formatedDate2 = new Date(this.props.taxRetentionConstancy.validThrough);
        formatedDate2.toLocaleDateString();
        return (
            <React.Fragment>
                <Row>
                    <Col span={12}>
                        <h3><FormattedMessage {...messages.ValidSince} /></h3>
                        {formatedDate1.toLocaleDateString(this.props.intl.formatMessage({ ...messages.intl }), { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                    </Col>
                    <Col span={12}>
                        <h3><FormattedMessage {...messages.ValidThrough} /></h3>
                        {formatedDate2.toLocaleDateString(this.props.intl.formatMessage({ ...messages.intl }), { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                    </Col>
                </Row>
                <Row>
                    <h3><FormattedMessage {...messages.Constancy} /></h3>
                    <img src={this.props.taxRetentionImage} style={{ 'width': '475px' }} />
                </Row>
            </React.Fragment>
        );
    }

    setToAdd = () => {
        this.setState({
            adding: true
        });
    }

    render() {
        return (
            <Modal
                title={this.props.intl.formatMessage({ ...messages.TaxRetention })}
                destroyOnClose
                open={this.props.showTaxModal}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={
                    <div>
                        <Mayre of={
                            <Mayre of={
                                <Button type="primary" size="large" loading={this.state.isLoading} onClick={this.handleOk} className="button-spacing">
                                    <FormattedMessage {...messages.next} />
                                </Button>
                            } or={
                                <Button type="primary" size="large" loading={this.state.isLoading} onClick={this.handleFinish} className="button-spacing">
                                    <FormattedMessage {...messages.save} />
                                </Button>
                            } when={this.state.currentStep === 0} />
                        } or={
                            <Button type="primary" size="large" loading={this.state.isLoading} onClick={this.setToAdd} className="button-spacing">
                                <FormattedMessage {...messages.add} />
                            </Button>
                        } when={this.state.adding} />
                        <Button key="back" size="large" onClick={this.handleCancel}>
                            <FormattedMessage {...messages.cancel} />
                        </Button>
                    </div>
                }
            >
                <div>
                    <Mayre of={
                        <React.Fragment>
                            <Row>
                                <Col className="tax-steps" xs={{ span: 7 }} sm={{ span: 7 }} md={{ span: 7 }} lg={{ span: 7 }}>
                                    <Steps 
                                        progressDot 
                                        direction="vertical" 
                                        size="small" 
                                        current={this.state.currentStep}
                                        items={[
                                            {title: this.props.intl.formatMessage({ ...messages.Date })},
                                            {title: this.props.intl.formatMessage({ ...messages.Constancy })},
                                        ]}
                                    />
                                </Col>
                                <Col className="tax-add-content" xs={{ span: 17 }} sm={{ span: 17 }} md={{ span: 17 }} lg={{ span: 17 }}>
                                    {this.showForm()}
                                </Col>
                            </Row>
                        </React.Fragment>
                    } or={
                        this.showImageDate()
                    } when={this.state.adding} />
                </div>
            </Modal>
        );
    }

}

TaxRetentionModal.propTypes = {
    intl: PropTypes.object.isRequired,
    showTaxModal: PropTypes.bool.isRequired,
    form: PropTypes.object.isRequired,
    closeTaxModal: PropTypes.func.isRequired,
    getInvoices: PropTypes.func.isRequired,
    getTaxRetentionConstancy: PropTypes.func.isRequired,
    addTaxRetentionConstancy: PropTypes.func.isRequired,
    getTaxImage: PropTypes.func.isRequired,
    taxRetentionConstancy: PropTypes.object.isRequired,
    taxRetentionImage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
    return {
        taxRetentionConstancy: state.invoicing.taxRetentionConstancy,
        taxRetentionImage: state.invoicing.taxRetentionImage
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getInvoices: () => {
            return dispatch(GetInvoices());
        },
        getTaxRetentionConstancy: () => {
            return dispatch(GetTaxRetentionConstancy());
        },
        addTaxRetentionConstancy: (values) => {
            return dispatch(AddTaxRetentionConstancy(values));
        },
        getTaxImage: (id) => {
            return dispatch(GetTaxImage(id));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(TaxRetentionModal)));