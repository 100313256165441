import React from 'react'
import PropTypes from 'prop-types';
import { Form, Select, DatePicker } from 'antd';
import { useIntl } from 'react-intl';
import { getClientMessages, getFields, getModelsMessages, getReportMessages } from '../../../constants/messages';
import { searchInString } from '../../../utilities/util';
import moment from 'moment';
const FormItem = Form.Item;

let ModelsMessages = getModelsMessages(),
	FieldsMessages = getFields(),
    ReportMessages = getReportMessages(),
    ClientMessages = getClientMessages();

const messages = {
	...ModelsMessages,
    ...FieldsMessages,
    ...ReportMessages,
    ...ClientMessages,
};

const ClientDeactivationForm = ({form, data}) => {
    const intl = useIntl();

    return (
        <div>
            <Form
                ref={form}
                layout='vertical'
            >
                <FormItem
                    name="clientIds"
                    label={intl.formatMessage({...messages.clients})}
                    rules={[{ required: true }]}
                >
                    <Select
                        showSearch
                        mode="multiple"
                        optionFilterProp="children"
                        options={data.map((client) => ({ label: client.clientName, value: client.key }))}
                        allowClear
                        placeholder={intl.formatMessage({ ...messages.clientTier })}
                        filterOption={(input, { label }) => searchInString(input, label)}
                    />
                </FormItem>
                <FormItem
                    name="date"
                    label={intl.formatMessage({...messages.programmedDeactivationDate})}
                    rules={[{ required: true }]}
                >
                    <DatePicker
                        className="job-full-component"
                        allowClear
                        format={'DD-MM-YYYY'}
                        disabledDate={(current) => current < moment().startOf('day')}
                    />
                </FormItem>
            </Form>
        </div>
    )
}
  
ClientDeactivationForm.propTypes = {
    form: PropTypes.object.isRequired,
    data: PropTypes.array,
};
  
export default ClientDeactivationForm
  