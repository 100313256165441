export const STATUSES = {
	READY_FOR_INVOICING: 0,
	NEEDS_APPROVAL: 1,
	INVOICED: 2,
	DENIED: 3
};

export const TAX_TYPES = {
	NORMAL: 0,
	EXEMPTION: 1,
	EXONERATION: 2,
	SAG: 3
};