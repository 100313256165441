import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getAuthMessages, getModalMessages} from '../constants/messages';
import { UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Modal } from 'antd';
import {ResetRequest} from '../actions/AuthAction';
import {injectIntl, FormattedMessage} from 'react-intl';
import {REGEX} from '../constants/global';
const FormItem = Form.Item;
let authMessages = getAuthMessages(),
      modalMessages = getModalMessages();

const messages = {
	...authMessages,
	...modalMessages
};

const ForgetPassword = ({intl, form,  showModal, handleCancel, resetRequest}) => {
	const { getFieldDecorator } = form;
	const handleOk = () => {
		form.validateFields((err, values) => {
			if (!err) {
				resetRequest(values.email);
				handleCancel();
			}
		});
	};
	return (
        <Modal
			open={showModal}
			title={intl.formatMessage( {...messages.resetPassword} )} 
			onOk={handleOk}
			onCancel={handleCancel}
			destroyOnClose={true}
			footer={[
				<Button key="submit" type="primary" size="large" onClick={handleOk}>
					<FormattedMessage {...messages.send} />
				</Button>,
				<Button key="back" size="large" onClick={handleCancel}>
					<FormattedMessage {...messages.cancel} />
				</Button>,
			]}
			>
			<Form className="login-form col-8">
				<FormItem>
					{
						getFieldDecorator('email', 
							{
								rules: [{ required: true, message: ' ', pattern: REGEX.email }],
							}
						)(
							<Input prefix={<UserOutlined style={{ fontSize: 13 }} />} placeholder={intl.formatMessage({...messages.resetEmail} )}  />
						)
					}
				</FormItem>
			</Form>
		</Modal>
    );
};

ForgetPassword.propTypes = {
	intl:  PropTypes.object.isRequired,
	form:  PropTypes.object.isRequired,
	showModal:  PropTypes.bool.isRequired,
	handleCancel:  PropTypes.func.isRequired,
	resetRequest:  PropTypes.func.isRequired,
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		resetRequest: ( email ) => {
			dispatch(ResetRequest( email ));
		}
	};
};

export default  connect(null, mapDispatchToProps)(injectIntl(Form.create()(ForgetPassword)));