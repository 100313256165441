import React from 'react';
import PropTypes from 'prop-types';
import { LinkOutlined, SnippetsOutlined, UserAddOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Row, Col, Spin, Select, message, Input, Tooltip } from 'antd';
import { injectIntl } from 'react-intl';
import {
	getactionMessages, getAuthMessages, getModalMessages, getFields, getErrorMessages, getRegistrationMessages,
	getClientMessages, getLanguageIntl
} from '../../../constants/messages';
import { SearchAvailableOptimusLinkClients, SearchAvailableRastreoLinkClients } from '../actions';
import debounce from 'lodash/debounce';

let actionMessages = getactionMessages(),
	authMessages = getAuthMessages(),
	errorMessages = getErrorMessages(),
	fieldMessages = getFields(),
	modalMessages = getModalMessages(),
	RegistrationMessages = getRegistrationMessages(),
	ClientMessages = getClientMessages(),
	intlMessages = getLanguageIntl();

const messages = {
	...actionMessages,
	...authMessages,
	...modalMessages,
	...fieldMessages,
	...errorMessages,
	...RegistrationMessages,
	...ClientMessages,
	...intlMessages
};

const FormItem = Form.Item;
const Option = Select.Option;

class ApprovalForm extends React.Component {

	constructor(props) {
		super(props);
		this.searchOptimusClients = debounce(this.searchOptimusClients, 250);
		this.state = {
			isLoading: false,
			optimusClients: [],
			loadingOptimusClients: false,
			rastreoClients: [],
			loadingRastreoClients: false,
		};
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	askApprovalType = () => {
		const { props, getIntl } = this;
		const { setState: setModalState } = props;
		return (
            <Row>
				<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
					<Button className="almost-full-component" icon={<LinkOutlined />} onClick={() => setModalState({ linkToExternalDb: true, approvalTypeSet: true })}>
						{getIntl("linkClient")}
					</Button>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
					<Button className="almost-full-component" icon={<UserAddOutlined />} onClick={() => setModalState({ createNewClient: true, approvalTypeSet: true })}>
						{getIntl("createClient")}
					</Button>
				</Col>
			</Row>
        );
	}

	buildOptimusClientOptions = () => {
		const { optimusClients } = this.state;
		return optimusClients.map(({ id, name }) => <Option key={id} value={id}>{name}</Option>);
	}

	searchOptimusClients = (name) => {
		const { getIntl } = this;
		if (name.length <= 0) this.setState({ optimusClients: [] });
		else {
			this.setState({ loadingOptimusClients: true });
			SearchAvailableOptimusLinkClients(name)
				.then(optimusClients => this.setState({ loadingOptimusClients: false, optimusClients }))
				.catch(() => {
					this.setState({ loadingOptimusClients: false });
					message.error(getIntl("getOptimusLinkableClientsError"));
				});
		}
	}

	optimusClientPicker = () => {
		const { state, props, getIntl, searchOptimusClients, buildOptimusClientOptions } = this;
		const { form } = props;
		const { loadingOptimusClients } = state;
		const { getFieldDecorator } = form;
		return (
			<div>
				<FormItem label={getIntl("optimusClient")}>
					{
						getFieldDecorator('linkClient',
							{
								rules: [{
									required: true,
									message: getIntl("optimusClientRequiredError"),
								}]
							}
						)(
							<Select
								className="job-full-component"
								showSearch={true}
								optionFilterProp="children"
								filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								notFoundContent={loadingOptimusClients ? <Spin size="small" /> : getIntl("notFound")}
								onSearch={(value) => searchOptimusClients(value)}
							>
								{buildOptimusClientOptions()}
							</Select>
						)
					}
				</FormItem>
			</div>
		);
	}

	buildRastreoClientOptions = () => {
		const { rastreoClients } = this.state;
		return rastreoClients.map(({ ClientID, Name }) => <Option key={ClientID} value={ClientID}>{Name}</Option>);
	}

	searchRastreoClients = (name) => {
		const { getIntl } = this;
		if (name.length <= 0) this.setState({ rastreoClients: [] });
		else {
			this.setState({ loadingRastreoClients: true });
			SearchAvailableRastreoLinkClients(name)
				.then(rastreoClients => this.setState({ loadingRastreoClients: false, rastreoClients }))
				.catch(() => {
					this.setState({ loadingRastreoClients: false });
					message.error(getIntl("getRastreoLinkableClientsError"));
				});
		}
	}

	rastreoClientPicker = () => {
		const { state, props, getIntl, searchRastreoClients, buildRastreoClientOptions } = this;
		const { form } = props;
		const { loadingRastreoClients } = state;
		const { getFieldDecorator } = form;
		return (
			<div>
				<FormItem label={getIntl("rastreoClient")}>
					{
						getFieldDecorator('rastreoLinkClient')(
							<Select
								className="job-full-component"
								showSearch={true}
								optionFilterProp="children"
								filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								notFoundContent={loadingRastreoClients ? <Spin size="small" /> : getIntl("notFound")}
								onSearch={(value) => searchRastreoClients(value)}
							>
								{buildRastreoClientOptions()}
							</Select>
						)
					}
				</FormItem>
			</div>
		);
	}

	linkClientContent = () => {
		const { optimusClientPicker, rastreoClientPicker } = this;
		return (
			<div>
				{optimusClientPicker()}
				{rastreoClientPicker()}
			</div>
		);
	}

	paste = () => {
		const { props, getIntl } = this;
		const { setState: setModalState } = props;
		navigator.clipboard.readText()
			.then(text => setModalState({ code: text }))
			.catch(() => message.error(getIntl("pasteError")));
	}

	createClientContent = () => {
		const { props, getIntl, paste } = this;
		const { state: modalState, setState: setModalState } = props;
		const { code } = modalState;
		let button = (
			<Tooltip title={getIntl("paste")}>
				<SnippetsOutlined size="small" onClick={paste} />
			</Tooltip>
		);
		return (
			<div>
				<Input className="job-full-component"
					placeholder={getIntl("createClientCode")}
					value={code}
					onChange={(e) => setModalState({ code: e.target.value })}
					addonAfter={button}
				/>
			</div>
		);
	}

	fullRender = () => {
		const { props, askApprovalType, linkClientContent, createClientContent } = this;
		const { state: modalState } = props;
		const { linkToExternalDb, createNewClient } = modalState;
		if (!linkToExternalDb && !createNewClient) return askApprovalType();
		if (linkToExternalDb) return linkClientContent();
		if (createNewClient) return createClientContent();
	}

	render() {
		return this.fullRender();
	}
}

ApprovalForm.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	state: PropTypes.object.isRequired,
	setState: PropTypes.func.isRequired
};

export default injectIntl(ApprovalForm);