import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button, Modal, message, Spin } from 'antd';
import { getModalMessages, getInvoicingMessages, getCalendarMessages } from '../../constants/messages';
import { DownloadGeneralInvoiceReport, DownloadFilteredGeneralInvoiceReport } from './actions';

let modalMessages = getModalMessages(),
    invoicingMessages = getInvoicingMessages(),
    calendarMessages = getCalendarMessages();

const messages = {
	...modalMessages,
    ...invoicingMessages,
    ...calendarMessages
};

class GenerateGeneralReportModal extends React.Component  {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: false
        };
	}

    getIntl = (str, values = {}) => this.props.intl.formatMessage({...messages[str]}, values);
    setLoading = (isLoading) => this.setState({ isLoading });

	handleOk = () => {
        const { getIntl, setLoading, handleCancel } = this;
		setLoading(true);
		DownloadGeneralInvoiceReport()
		.then(() => handleCancel())
		.catch(() => {
			setLoading(false);
			message.error(getIntl("generateGeneralReportError"));
		});
	}

	handleDownloadFilteredGeneralReport = () => {
        const { getIntl, setLoading, handleCancel } = this;
		setLoading(true);
		DownloadFilteredGeneralInvoiceReport()
		.then(() => handleCancel())
		.catch(() => {
			setLoading(false);
			message.error(getIntl("generateGeneralReportError"));
		});
	}

	handleCancel = () => {
        const { props, setLoading } = this;
		const { handleCancel } = props;
        setLoading(false);
		handleCancel();
    }

	renderedButtons = () => {
        const { state, handleOk, handleCancel, getIntl, handleDownloadFilteredGeneralReport } = this;
		const { isLoading } = state;
        return [
            <Button key="cancel" disabled={isLoading} onClick={handleCancel}>{getIntl("cancel")}</Button>,
            <Button key="downloadFiltered" loading={isLoading} type="primary" onClick={handleDownloadFilteredGeneralReport}>{getIntl("generateFilterableReport")}</Button>,
            <Button key="submit" loading={isLoading} type="primary" onClick={handleOk}>{getIntl("generateReport")}</Button>
        ];
    }

	render(){
		const { state, props, getIntl, handleOk, handleCancel, renderedButtons } = this;
		const { isLoading } = state;
		const { showModal } = props;
		return (
			<Modal
				open={showModal}
				onOk={handleOk}
				onCancel={handleCancel}
				destroyOnClose={true}
				footer={renderedButtons()}
				maskClosable={!isLoading}
				keyboard={!isLoading}
				closable={!isLoading}
            >
				<Spin spinning={isLoading}>
					{getIntl("generateGeneralReportForAllPendingInvoices")}
				</Spin>
            </Modal>
		);
	}
}

GenerateGeneralReportModal.propTypes = {
	intl: PropTypes.object.isRequired,
	
	showModal:  PropTypes.bool.isRequired,
	handleCancel:  PropTypes.func.isRequired
};

export default injectIntl(GenerateGeneralReportModal);