import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
	injectIntl,
	FormattedMessage
} from 'react-intl';
import CommonTable from '../../components/CommonTable';
import CreditNotesFilter from './CreditNotesFilter';
import { connect } from 'react-redux';
import { GetCreditNotes, GetCreditNote, SearchCreditNotes, DownloadCreditNote, PreviewCreditNote, EmailCreditNote, LoadCreditNoteActivityLog, GetActivityIdCreditNote } from './actions';
import { message, Row, Col } from 'antd'
import { roundN } from '../../utilities/util';
import { getCreditNoteMessages, getactionMessages, getPopConfirm, getInvoicingMessages, getFields } from '../../constants/messages';
import AddCreditNoteButton from './AddCreditNoteButton';
import EmailSelector from '../../components/EmailSelector';
import { GetEmails } from '../Clients/actions';
import ActivityLogModal from './ActivityLogModal';
import AddCreditNoteModal from './AddCreditNoteModal';

let moment = require('moment');

let creditNoteMessages = getCreditNoteMessages(),
	popMessages = getPopConfirm(),
	actionMessages = getactionMessages(),
	invoicingMessages = getInvoicingMessages(),
	fieldMessages = getFields();

const messages = {
	...creditNoteMessages,
	...actionMessages,
	...popMessages,
	...invoicingMessages,
	...fieldMessages
};

class CreditNotes extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			showEditModal: false,
			showEmailModal: false,
			currentCreditNoteId: 0,
			showActivityLogModal: false,
			currentActivityLog: [],
			showModal: false,
			record: undefined,
			activityLogId: 0
		};
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = isLoading => this.setState({ isLoading });

	search = (search, page) => {
		const { searchCreditNotes, intl } = this.props;
		this.setState({
			isLoading: true
		});
		searchCreditNotes(search, page).then(() => {
			this.setState({
				isLoading: false
			});
		}).catch(() => {
			message.error(`${intl.formatMessage({ ...messages.GetCreditNotesError })}`);
		});
	}


	renderInvoices = (creditNoteInvoices) => {
		return creditNoteInvoices.map((object, index) => {
			return (
				<Row key={index}>
					<Col span={4}>

					</Col>
					<Col span={8}>
						<a className="editable-add-btn" onClick={() => { this.props.history.push(`/invoices/${object.invoice.id}`); }}>
							{object.invoice.documentNumber}
						</a>
					</Col>
					<Col span={6} style={{ 'textAlign': 'right' }}>
						<p>{`L ${roundN(object.amount, 2)}`}</p>
					</Col>
				</Row>
			);
		});
	}

	expandedRow = (creditNote) => {
		return (
			<React.Fragment>
				<Row>
					<Col span={4}>

					</Col>
					<Col span={8}>
						<h4><FormattedMessage {...messages.Invoices} /></h4>
					</Col>
					<Col span={6} style={{ 'textAlign': 'right' }}>
						<h4><FormattedMessage {...messages.Amount} /></h4>
					</Col>
				</Row>
				{this.renderInvoices(creditNote.creditNoteInvoices)}
			</React.Fragment>
		);
	}

	printCreditNote = (id) => {
		return this.props.downloadCreditNote(id).catch(() => {
			message.error(`${this.props.intl.formatMessage({ ...messages.PrintError })}`);
		});
	}

	openEmailModal = (creditNoteId, clientId) => {
		const { props, getIntl, setLoading } = this;
		const { getCientEmails } = props;
		setLoading(true);
		getCientEmails(clientId)
			.then(() => this.setState({ showEmailModal: true, currentCreditNoteId: creditNoteId, isLoading: false }))
			.catch(() => {
				message.error(getIntl("getClientEmailsError"));
				setLoading(false);
			});
	}

	closeEmailModal = () => this.setState({ showEmailModal: false, currentCreditNoteId: 0 });

	openActivityLogModal = creditNoteId => {
		const { setLoading, getIntl } = this;
		setLoading(true);
		LoadCreditNoteActivityLog(creditNoteId)
			.then(activityLog => this.setState({ isLoading: false, showActivityLogModal: true, currentActivityLog: activityLog }))
			.catch(() => {
				setLoading(false);
				message.error(getIntl("getActivityLogError"));
			});
	}

	closeActivityLogModal = () => this.setState({ showActivityLogModal: false, currentActivityLog: [] });

	openEditModal = async (id) => {
		const { props: { getCreditNote }, getIntl } = this;
		try {
			const record = await getCreditNote(id);
			this.setState({ showModal: true, record	});
			try {
				const activityId = await GetActivityIdCreditNote(id);
				this.setState({	activityLogId: activityId });
			} catch (error) {
				message.error(getIntl("getActivityLogError"));		
			}
		} catch(error){
			message.error(getIntl("GetCreditNotesError"));
		}
};

	closeModal = () => {
		this.setState({
			showModal: false
		});
	}

	render() {
		const { state, props, openActivityLogModal, closeActivityLogModal } = this;
		const { activityLogId, showActivityLogModal, currentActivityLog, record, showModal, showEmailModal, currentCreditNoteId, isLoading } = state;
		const { searchText, intl, emailCreditNote, creditNotes, currentPage } = props;
		let columns = [
			{
				title: intl.formatMessage({ ...messages.CreditNoteNumber }),
				dataIndex: 'documentNumber',
				key: 'documentNumber',
				width: '15%'
			}, {
				title: intl.formatMessage({ ...messages.Client }),
				dataIndex: 'clientName',
				key: 'clientName',
				width: '15%'
			}, {
				title: intl.formatMessage({ ...messages.Motive }),
				dataIndex: 'motive',
				key: 'motive',
				width: '30%'
			}, {
				title: 'Total',
				dataIndex: 'total',
				key: 'total',
				className: 'column-money',
				width: '12%',
				render: (total) => {
					return `L ${roundN(total, 2)}`;
				}
			}, {
				title: intl.formatMessage({ ...messages.Date }),
				dataIndex: 'createdAt',
				key: 'createdAt',
				width: '10%',
				render: (date) => {
					return (moment(date).locale('es').format('L'));
				}
			}, {
				title: intl.formatMessage({ ...messages.Actions }),
				dataIndex: 'id',
				key: 'actions',
				width: '18%',
				render: (id, { clientId }) => {
					return (
						<span>
							<a className="editable-add-btn" onClick={() => { this.openEditModal(id); }}>
								<FormattedMessage {...messages.Edit} />
							</a>
							<span> | </span>
							<a className="editable-add-btn" onClick={() => { this.printCreditNote(id); }}>
								<FormattedMessage {...messages.Print} />
							</a>
							<span> | </span>
							<a className="editable-add-btn" onClick={() => { this.openEmailModal(id, clientId); }}>
								<FormattedMessage {...messages.Emails} />
							</a>
							<span> | </span>
							<a className="editable-add-btn" onClick={() => { openActivityLogModal(id); }}>
								<FormattedMessage {...messages.activityLog} />
							</a>
						</span>
					);
				}
			}
		];
		return (
			<div className="account view">
				<AddCreditNoteModal
					showModal={showModal}
					handleCancel={this.closeModal}
					document={record}
					activityLogId={activityLogId.activityLogId}
				/>
				<ActivityLogModal
					showModal={showActivityLogModal}
					handleCancel={closeActivityLogModal}
					activityLog={currentActivityLog}
				/>
				<EmailSelector
					addOrShow={false}
					showEmailModal={showEmailModal}
					closeModal={this.closeEmailModal}
					document={{ id: currentCreditNoteId }}
					emailDocument={emailCreditNote}
					downloadPreview={() => PreviewCreditNote(currentCreditNoteId)}
					downloadDocument={() => this.printCreditNote(currentCreditNoteId)}
				/>
				<CommonTable
					columns={columns}
					dataSource={creditNotes}
					loading={isLoading}
					Add={<AddCreditNoteButton />}
					recordCount={creditNotes.length}
					getRecords={() => { }}
					search={this.search}
					preservePage={true}
					searchText={searchText}
					currentPage={currentPage}
					filterComponent={(<CreditNotesFilter />)}
					getCheckboxProps={(() => ({ disabled: false }))}
					expandedRow={this.expandedRow}
					hideCheckboxes
				/>
			</div>
		);
	}
}

CreditNotes.propTypes = {
	creditNotes: PropTypes.array.isRequired,
	downloadCreditNote: PropTypes.func.isRequired,
	emailCreditNote: PropTypes.func.isRequired,
	getCientEmails: PropTypes.func.isRequired,
	getCreditNotes: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	intl: PropTypes.object.isRequired,
	searchCreditNotes: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	searchText: PropTypes.string,
	getCreditNote: PropTypes.func,
	creditNote: PropTypes.object,
	currentPage: PropTypes.number
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		creditNotes: state.creditNote.creditNotes,
		searchText: state.creditNote.search,
		currentPage: state.creditNote.page,
		creditNote: state.creditNote.creditNote
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getCreditNote: (id) => {
			return dispatch(GetCreditNote(id));
		},
		getCreditNotes: () => {
			return dispatch(GetCreditNotes());
		},
		downloadCreditNote: (id) => {
			return dispatch(DownloadCreditNote(id));
		},
		searchCreditNotes: (search, page) => {
			return dispatch(SearchCreditNotes(search, page));
		},
		emailCreditNote: (id, emails, replyTo, message) => dispatch(EmailCreditNote(id, emails, replyTo, message)),
		getCientEmails: clientId => dispatch(GetEmails(clientId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(CreditNotes)));