import React from 'react';
import {getAuthMessages} from '../constants/messages';
import {injectIntl, FormattedMessage} from 'react-intl';
import ForgetPassword from '../components/ForgetPassword';

const messages = getAuthMessages();

 
class ForgotPasswordModal extends React.Component  {

	constructor(props) {
		super(props);
		this.state = {
			showModal: false
		};
	}

	triggleModal = () => {
		this.setState({
			showModal: true
		});
	};

	handleCancel = () => {
		this.setState({
			showModal: false
		});
	};

	render(){
		return (
			<span>
				<a className="login-form-forgot" onClick={this.triggleModal}>
					<FormattedMessage {...messages.forgot} />
				</a>
				<ForgetPassword showModal={this.state.showModal} handleCancel={this.handleCancel}  />
			</span>
		);
	}
}

export default injectIntl(ForgotPasswordModal);