import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';
import { Radio } from 'antd';
import { getJobMessages, getErrorMessages } from '../../../../constants/messages';
import { ActivateDeviceSearch, SearchAvailableSimCards } from '../../actions';
import ClientTemplate from '../CommonComponents/ClientTemplate';
import Device from '../CommonComponents/Device';
import Description from '../CommonComponents/Description';
import Sim from '../CommonComponents/Sim';
import { getNestedValue, existsInArray } from '../../../../utilities/util';
import {ROLES} from '../../../../constants/global';
const { GPSTRANSFERACTIVATION } = ROLES;

const FormItem = Form.Item;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

let jobMessages = getJobMessages(),
    errorMessages = getErrorMessages();

const messages = {
	...jobMessages,
    ...errorMessages
};

class Activate extends React.Component {

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    renderClient2 = () => {
        const { props, getIntl } = this;
        const { form, isMaster, userRoles, saveFields, getField } = props;
        const saveClient2 = client2 => saveFields({ client2 });
        const client2 = getField("client2");
        const clientId = getField("client.clientId");
        const isSCS = existsInArray(GPSTRANSFERACTIVATION, userRoles);
        if (isMaster || isSCS)
            return (
                <ClientTemplate
                    form={form}
                    client={client2}
                    onChange={saveClient2}
                    required={false}
                    fieldName={`clientId2`}
                    label={getIntl("optionalSecondClient")}
                    placeholder={getIntl("optionalSecondClientTooltip")}
                    disabled={clientId === undefined}
                />
            );
    }

    renderDeviceSelector = () => {
        const { form, saveFields, getField } = this.props;
        const { resetFields } = form;
        const saveDevice = gps => {
            const description = getNestedValue("description", gps);
            const sim = getNestedValue("sim", gps);
            saveFields({ gps, description, sim });
            resetFields(["description", "sim"]);
        }
        const gps = getField("gps");
        const clientId = getField("client.clientId");
        return (
            <Device
                form={form}
                search={(deviceName) => ActivateDeviceSearch(deviceName, clientId, 10)}
                device={gps}
                disabled={clientId === undefined}
                onChange={saveDevice}
            />
        );
    }

    renderDescription = () => {
        const { form, saveFields, getField } = this.props;
        const saveDescription = description => saveFields({ description });
        const description = getField("description");
        const clientId = getField("client.id");
        return (
            <Description
                form={form}
                description={description}
                disabled={clientId === undefined}
                onChange={saveDescription}
            />
        );
    }

    renderSimSelector = () => {
        const { form, saveFields, getField } = this.props;
        const saveSim = sim => saveFields({ sim });
        const sim = getField("sim");
        const clientId = getField("client.clientId");
        const assignedSim = getField("gps.sim");
        return (
            <Sim
                form={form}
                search={(simName) => SearchAvailableSimCards(simName, 10)}
                sim={sim}
                disabled={clientId === undefined}
                onChange={saveSim}
                otherIncludedSims={assignedSim}
            />
        );
    }

    renderCharge = () => {
        const { props: {form}, getIntl} = this;
        const { getFieldDecorator } = form;
        const options = [
            <RadioButton key={0} value={false} >{getIntl("no")}</RadioButton>,
            <RadioButton key={1} value={true} >{getIntl("yes")}</RadioButton>
        ];
        return (
            <FormItem label={getIntl("beChargedActivation")}>
                {
                    getFieldDecorator('beCharged', 
                        {
                            initialValue: false,
                            rules: [{
                                required: true,
                                message: getIntl("beChargedError"), 
                            }],
                        }
                    )( <RadioGroup defaultValue={false} >{options}</RadioGroup>)
                }
            </FormItem>
        );
    };

    fullRender = () => {
        const { renderClient2, renderDeviceSelector, renderDescription, renderSimSelector, renderCharge } = this;
        return (
            <div>
                {renderClient2()}
                {renderDeviceSelector()}
                {renderSimSelector()}
                {renderDescription()}
                {renderCharge()}
            </div>
        );
    }
    
	render() {
        return this.fullRender();
    }
}

Activate.propTypes = {
	intl: PropTypes.object.isRequired,
    isMaster: PropTypes.bool.isRequired,
    userRoles: PropTypes.array.isRequired,
    form: PropTypes.object.isRequired,
    saveFields: PropTypes.func.isRequired,
    getField: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {
		isMaster: state.auth.isMaster,
		userRoles: state.auth.roles
	};
};

export default connect(mapStateToProps)(injectIntl(Activate));