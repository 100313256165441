import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Modal, InputNumber, Input, Checkbox, Row, Col, Select, message } from 'antd';
import { getModalMessages, getPaymentMessages, getErrorMessages, getInvoicingMessages } from '../../constants/messages';
import { connect } from 'react-redux';
import { EmailReceipt, MakeReceipt, NotifyClientPayment } from './actions';
import { GetEmails } from '../Clients/actions';
import { GetInvoice } from '../Invoices/actions';
import EmailSelector from '../../components/EmailSelector';
import Mayre from 'mayre';
import { roundN, existsInArray } from '../../utilities/util';
import PaymentDatePicker from './PaymentDatePicker';
import DestinationAccountPicker from './DestinationAccountPicker';
import { getPaymentMethodsList, Banks, PaymentMethods, calculateTaxRetentionsAndFinalAmount } from './constants';
const { DEPOSIT, CHECK, CREDIT_CARD } = PaymentMethods;
import TaxRetentionPicker from './TaxRetentionComponents/TaxRetentionPicker';
import TaxRetentionTable from './TaxRetentionComponents/TaxRetentionTable';
import ProgrammedDeactivationChanger from '../Clients/ApprovedClients/ProgrammedDeactivations/AfterPaymentChanger/Modal';

const { TextArea } = Input;

let modalMessages = getModalMessages();
let paymentMessages = getPaymentMessages();
let errorMessages = getErrorMessages();
let invoiceMessages = getInvoicingMessages();
const messages = {
	...modalMessages,
	...paymentMessages,
	...errorMessages,
	...invoiceMessages
};

let moment = require('moment');
const FormItem = Form.Item;
const Option = Select.Option;

class AddPaymentModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isDollar: false,
			paymentMethod: 0,
			isLoading: false,
			showEmailModal: false,
			receiptInfo: {},
			defaultMessage: "",
			showProgrammedDeactivatorModal: false
		};
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	closeEmailModal = () => {
		this.setState({
			showEmailModal: false,
			isLoading: false
		});
		this.props.getInvoice(this.props.invoice.id).then(() => {

		});
	}

	closeProgrammedDeactivatorModal = () => {
		this.setState({
			showProgrammedDeactivatorModal: false,
			showEmailModal: true
		});
	}

	handleOk = () => {
		const { state, props } = this;
		const { isDollar } = state;
		const { intl, invoice, form } = props;
		const { documentNumber } = invoice;
		this.props.form.validateFields({ force: true }, (err, values) => {
			if (err) return;
			let invoiceToSend = { ...invoice, isvPreSelected: invoice.isvRetention, isrPreSelected: invoice.isrRetention };
			const { taxRetention, isvReceived, isrReceived } = values;

			values = {
				...values,
				invoiceId: invoice.id,
				createdAt: moment(),
				isDollar: this.state.isDollar,
				description: values.description !== undefined ? values.description : "",
				type: 1,
				paymentDate: moment(values.paymentDate).toISOString()
			};

			let description = "";

			if (this.state.isDollar) {
				description = "Pago de $" + roundN(values.amount, 2) + description + ". ";
				// values = {
				//     ...values,
				//     amount: values.amount * this.props.exchangeRate
				// };
			}

			if (values.paymentMethod === DEPOSIT.id) {
				description = description + "Depósito con número de referencia " + values.refNumber + ". ";
			}

			if (values.paymentMethod === CHECK.id) {
				description = description + "Pago por medio de cheque número " + values.checkNumber + " de " + values.bank + ". ";
			}

			if (values.paymentMethod === CREDIT_CARD.id) {
				description = description + "Pago por medio de tarjeta de crédito número ****-" + values.lastDigits + ". ";
			}

			if (taxRetention.length > 0) {
				const numRound = num => Math.round((num + Number.EPSILON) * 100) / 100;
				const { fifteenPercent, onePercentSubtotal, onePercentTotal, finalAmount } = calculateTaxRetentionsAndFinalAmount(form, invoice, isDollar);
				values.amount = numRound(finalAmount);
				invoiceToSend.isDollar = values.isDollar;
				if (existsInArray("fifteenPercent", taxRetention)) {
					invoiceToSend.fifteenPercentTaxOverride = numRound(fifteenPercent);
					invoiceToSend.isvRetention = true;
					invoiceToSend.isvPreSelected = false;
					invoiceToSend.isvReceived = isvReceived;
				}
				if (existsInArray("onePercentSubtotal", taxRetention)) {
					invoiceToSend.onePercentTaxOverride = numRound(onePercentSubtotal);
					invoiceToSend.isrRetention = true;
					invoiceToSend.isrPreSelected = false;
					invoiceToSend.isrReceived = isrReceived;
				}
				else if (existsInArray("onePercentTotal", taxRetention)) {
					invoiceToSend.onePercentTaxOverride = numRound(onePercentTotal);
					invoiceToSend.isrRetention = true;
					invoiceToSend.isrPreSelected = false;
					invoiceToSend.isrReceived = isrReceived;
				}
			}

			values = {
				...values,
				description: description + values.description
			};

			let paymentInfo = {
				clientId: invoice.clientId,
				date: values.createdAt,
				amount: values.amount
			}

			this.setState({ isLoading: true });
			this.props.makeReceipt(paymentInfo, [invoiceToSend], values).then((receiptResult) => {
				this.props.getEmails(invoice.clientId).then(() => {
					this.setState({
						receiptInfo: receiptResult,
						showProgrammedDeactivatorModal: true,
						defaultMessage: intl.formatMessage({ ...messages.afterPaymentDefaultMessage }, { quantity: `${isDollar ? '$' : 'L.'} ${roundN(values.amount, 2)}`, documentNumber })
					});
					this.props.closeModal();
				});
			}).catch(() => {
				message.error(`${this.props.intl.formatMessage({ ...messages.GetInvoiceError })}`);
				this.props.closeModal();
				this.setState({
					isLoading: false
				});
			});
		});
	}

	handleCancel = () => {
		this.setState({
			paymentMethod: 0
		});
		this.props.closeModal();
	}

	handleChangeCheckbox = (event) => {
		const { form, exchangeRate } = this.props;
		const { getFieldValue, setFieldsValue } = form;
		const checked = event.target.checked;
		const amount = getFieldValue('amount');
		const newAmount = checked ? amount / exchangeRate : amount * exchangeRate;
		this.setState({ isDollar: checked });
		setFieldsValue({ amount: newAmount });
	}

	handleChangeType = (value) => {
		this.setState({
			paymentMethod: value
		});
	}

	paymentMethod = () => {
		const { getIntl } = this;
		const options = getPaymentMethodsList().map(({ id, intl }) => <Option key={id} value={id}>{getIntl(intl)}</Option>);
		return (
			<Select className="job-full-component" onChange={(value) => { this.handleChangeType(value); }} >
				{options}
			</Select>
		);
	}

	banks = () => {
		const options = Banks.map((bank, index) => <Option key={index} value={bank}>{bank}</Option>);
		return (
			<Select className="job-full-component" >
				{options}
			</Select>
		);
	}

	renderCommentField = () => {
		const { props, getIntl } = this;
		const { getFieldDecorator } = props.form;
		const maxLength = 150;
		return (
			<FormItem label={getIntl("Comment")}>
				{
					getFieldDecorator('description', {
						rules: [
							{
								max: maxLength,
								message: getIntl("commentLengthError", { quantity: maxLength })
							}
						]
					})(<TextArea rows={4} />)
				}
			</FormItem>
		);
	}

	renderHeaderOptions = () => {
		const { state, props, getIntl, handleChangeCheckbox } = this;
		const { isDollar } = state;
		const { form } = props;
		const { getFieldDecorator } = form;
		return (
			<Row>
				<Col xs={{ span: 24 }} md={{ span: 8 }}>
					<FormItem>
						{
							getFieldDecorator('isDollar', {
								initialValue: isDollar,
								valuePropName: 'checked'
							})(<Checkbox onChange={handleChangeCheckbox}>{getIntl("Dollars")}</Checkbox>)
						}
					</FormItem>
				</Col>
				<Col xs={{ span: 24 }} md={{ span: 16 }}>
					<TaxRetentionPicker form={form} />
				</Col>
			</Row>
		);
	}

	negativeAmountValidator = (rule, value, callback) => {
		if (!value) return callback();
		const { state, props, getIntl } = this;
		const { isDollar } = state;
		const { form, invoice } = props;
		const { finalAmount } = calculateTaxRetentionsAndFinalAmount(form, invoice, isDollar);
		if (finalAmount < 0) return callback(getIntl("negativeFinalAmountError"));
		callback();
	}

	renderAmountInputs = () => {
		const { state, props, getIntl, negativeAmountValidator } = this;
		const { isDollar } = state;
		const { form, lastPayment, exchangeRate, invoice } = props;
		const { getFieldDecorator } = form;
		const amountParser = isDollar ? /\$\s?|(,*)/g : /L\s?|(,*)/g;
		const initialValue = lastPayment ? (isDollar ? lastPayment.balance / exchangeRate : lastPayment.balance) : 0.00;
		return (
            <div>
				<FormItem label={getIntl("totalAmount")}>
					{getFieldDecorator('amount', {
						initialValue: initialValue,
						rules: [
							{
								required: true,
								message: this.props.intl.formatMessage({ ...messages.paymentError }),
							},
							{
								validator: negativeAmountValidator
							}
						]
					})(
						<InputNumber
							className="full-component"
							formatter={value => `${isDollar ? "$" : "L"} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
							parser={value => value.replace(amountParser, '')}
							step={1}
							min={0}
							precision={2}
						/>
					)}
				</FormItem>
				<TaxRetentionTable form={form} invoice={invoice} isDollar={isDollar} />
			</div>
        );
	}

	renderForm = () => {
		const { props, renderHeaderOptions, renderAmountInputs } = this;
		const { form } = props;
		const { getFieldDecorator } = form;
		return (
			<div>
				<Form layout='vertical'>
					{renderHeaderOptions()}
					{renderAmountInputs()}
					<DestinationAccountPicker form={form} />
					<FormItem label={this.props.intl.formatMessage({ ...messages.TypeOfPayment })}>
						{getFieldDecorator('paymentMethod', {
							rules: [{
								required: true, message: this.props.intl.formatMessage({ ...messages.paymentTypeError }),
							}]
						})(
							this.paymentMethod()
						)}
					</FormItem>
					<Mayre
						of={
							<FormItem label={this.props.intl.formatMessage({ ...messages.RefNumber })}>
								{getFieldDecorator('refNumber', {
									rules: [{
										required: this.state.paymentMethod === DEPOSIT.id, whitespace: true, message: this.props.intl.formatMessage({ ...messages.paymentRefError }),
									}]
								})(
									<Input />
								)}
							</FormItem>
						} when={this.state.paymentMethod === DEPOSIT.id}
					/>
					<Mayre
						of={
							<Row gutter={16} type="flex" align="middle">
								<Col span={12}>
									<FormItem label={this.props.intl.formatMessage({ ...messages.CheckNumber })}>
										{getFieldDecorator('checkNumber', {
											rules: [{
												required: this.state.paymentMethod === CHECK.id, whitespace: true, message: this.props.intl.formatMessage({ ...messages.paymentCheckError }),
											}]
										})(
											<Input />
										)}
									</FormItem>
								</Col>
								<Col span={12}>
									<FormItem label={this.props.intl.formatMessage({ ...messages.Bank })}>
										{getFieldDecorator('bank', {
											rules: [{
												required: this.state.paymentMethod === CHECK.id, message: this.props.intl.formatMessage({ ...messages.paymentBankError }),
											}]
										})(
											this.banks()
										)}
									</FormItem>
								</Col>
							</Row>
						} when={this.state.paymentMethod === CHECK.id}
					/>
					<Mayre
						of={
							<span>
								<FormItem label={this.props.intl.formatMessage({ ...messages.LastDigits })}>
									{getFieldDecorator('lastDigits', {
										rules: [{
											required: this.state.paymentMethod === CREDIT_CARD.id,
											message: this.props.intl.formatMessage({ ...messages.paymentCardError }),
											whitespace: true,
											len: 4
										}]
									})(
										<Input />
									)}
								</FormItem>
							</span>
						} when={this.state.paymentMethod === CREDIT_CARD.id}
					/>
					<PaymentDatePicker form={form} />
					{this.renderCommentField()}
				</Form>
			</div>
		);
	}

	render() {
		const { state, props, closeProgrammedDeactivatorModal } = this;
		const { showProgrammedDeactivatorModal } = state;
		const { invoice } = props;
		return (
			<React.Fragment>
				<Modal
					title={this.props.intl.formatMessage({ ...messages.AddPayment })}
					destroyOnClose
					open={this.props.showModal}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					confirmLoading={this.state.isLoading}
					footer={
						<div>
							<Button type="primary" size="large" loading={this.state.isLoading} onClick={this.handleOk} className="button-spacing">
								<FormattedMessage {...messages.send} />
							</Button>
							<Button key="back" size="large" onClick={this.handleCancel}>
								<FormattedMessage {...messages.cancel} />
							</Button>
						</div>
					}
				>
					{this.renderForm()}
				</Modal>
				<EmailSelector
					addOrShow={false}
					emailDocument={this.props.notifyClientPayment}
					document={this.state.receiptInfo.receiptInfo}
					showEmailModal={this.state.showEmailModal}
					closeModal={this.closeEmailModal}
					defaultMessage={this.state.defaultMessage}
				/>
				<ProgrammedDeactivationChanger showModal={showProgrammedDeactivatorModal} handleCancel={closeProgrammedDeactivatorModal} clientId={invoice.clientId} />
			</React.Fragment>

		);
	}

}

AddPaymentModal.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	showModal: PropTypes.bool.isRequired,
	closeModal: PropTypes.func.isRequired,
	invoice: PropTypes.object.isRequired,
	lastPayment: PropTypes.object.isRequired,
	exchangeRate: PropTypes.number,
	notifyClientPayment: PropTypes.func.isRequired,
	getInvoice: PropTypes.func.isRequired,
	makeReceipt: PropTypes.func.isRequired,
	getEmails: PropTypes.func.isRequired,
};


const mapDispatchToProps = (dispatch) => {
	return {
		getInvoice: (id) => {
			return dispatch(GetInvoice(id));
		},
		emailReceipt: (receiptId, emails, respondTo, message) => {
			return dispatch(EmailReceipt(receiptId, emails, respondTo, message));
		},
		getEmails: (id) => {
			return dispatch(GetEmails(id));
		},
		makeReceipt: (receiptInfo, invoices, values) => {
			return dispatch(MakeReceipt(receiptInfo, invoices, values));
		},
		notifyClientPayment: (receiptId, emails, respondTo, message) => dispatch(NotifyClientPayment(receiptId, emails, respondTo, message))
	};
};

export default connect(null, mapDispatchToProps)(injectIntl(Form.create()(AddPaymentModal)));