import React from 'react';
import PropTypes from 'prop-types';
import { StopOutlined } from '@ant-design/icons';
import { Select, Spin, message, Collapse, Skeleton } from 'antd';
import { injectIntl } from 'react-intl';
import { getDeviceMessages } from '../../../constants/messages';
import {SearchClients, GetClientDevices, GetClientDeviceCount} from '../actions';
import ClientTable from './Renders/ClientTable';
import {getObjectInArray} from '../../../utilities/util';
const { Panel } = Collapse;

const { Option, OptGroup } = Select;

let deviceMessages = getDeviceMessages();

const messages = {
	...deviceMessages
};

class Client extends React.Component  {

	constructor(props){
        super(props);
        this.state = {
            selectLoading: false,
            clients: [],
            clientId: -1,
            tableLoading: false,
            page: 1,
            pageSize: 5,
            devices: [],
            count: 0
        };
    }

    handleClientSearch = (name) => {
        const {intl} = this.props;
        if (name.length <= 0 ) this.setState({clients: []});
        else {
            this.setState({selectLoading: true});
            SearchClients(name.trim())
            .then((response) => this.setState({selectLoading: false, clients: response}))
            .catch( () => {
                this.setState({selectLoading: false, clients: []});
                message.error(intl.formatMessage({...messages.clientSearchError}));
            });
        }
    }

    buildOptions = () => {
        const {clients} = this.state;
        const { intl } = this.props;
        let options = [];
        let enabled = [];
        let disabled = [];
        clients.forEach((client, index) => {
            if (client.isDisabled)
                disabled.push(<Option key={index} value={client.id}><StopOutlined style={{fontSize: 10}} /> {client.name}</Option>);
            else 
                enabled.push(<Option key={index} value={client.id}>{client.name}</Option>);
        });
        options.push(
            <OptGroup key="enabled" label={intl.formatMessage({...messages.enabled})}>
                {enabled}
            </OptGroup>
        );
        options.push(
            <OptGroup key="disabled" label={intl.formatMessage({...messages.disabled})}>
                {disabled}
            </OptGroup>
        );
        return options;
    }

    handleSelect = (id) => {
        const {page, pageSize, clients} = this.state;
        const {intl} = this.props;
        if (!id) this.setState({clientId: -1});
        else {
            this.setState({tableLoading: true});
            let client = getObjectInArray('id', parseInt(id), clients);
            let {clientId} = client;
            GetClientDeviceCount(clientId)
            .then((count) => {
                GetClientDevices(clientId, page, pageSize)
                .then((devices) => this.setState({tableLoading: false, clientId, devices, count}))
            })
            .catch(() => {
                this.setState({tableLoading: false, clientId: -1});
                message.error(intl.formatMessage({...messages.clientDevicesSearchError}));
            });
        }
    }

    pageDevices = (page, pageSize) => {
        const {clientId} = this.state;
		const {intl} = this.props;
        this.setState({tableLoading: true});
        GetClientDevices(clientId, page, pageSize)
        .then((devices) => this.setState({tableLoading: false, devices, page, pageSize}))
        .catch(() => {
            this.setState({tableLoading: false, devices: []});
            message.error(intl.formatMessage({...messages.clientDevicesSearchError}));
        });
    }

    renderClientSelector = () => {
        const {selectLoading} = this.state;
        const {intl} = this.props;
        let clientOptions = this.buildOptions();
		return (
            <Select
                className="job-full-component"
                showSearch
                placeholder={intl.formatMessage( {...messages.client} )}
                optionFilterProp="children"
                onSearch={this.handleClientSearch}
                notFoundContent={selectLoading ? <Spin size="small" /> : intl.formatMessage({...messages.notFound})}
                onChange={this.handleSelect}
            >
                {clientOptions}
            </Select>
		);
    }

    renderTable = () => {
        const {clientId, tableLoading, devices, count, page, pageSize} = this.state;
        if (clientId === -1)
            if(tableLoading) return <div><Skeleton active={tableLoading}/><Skeleton active={tableLoading}/></div>;
            else return null;
        return <ClientTable onPage={this.pageDevices} dataSource={devices} count={count} isLoading={tableLoading} page={page} pageSize={pageSize}/>;
    }

	render(){
        const {tableLoading, clientId} = this.state;
        const expand = (clientId === -1 && tableLoading) || clientId !== -1;
        return(
            <div>
                {this.renderClientSelector()}
                <Collapse className="job-view-reconnect-collapse" bordered={false} activeKey={expand ? 1 : 0}>
                    <Panel key={1} showArrow={false}>
                        {this.renderTable()}
                    </Panel>
                </Collapse>
            </div>
        );
	}
}

Client.propTypes = {
	intl: PropTypes.object.isRequired
};

export default injectIntl(Client);