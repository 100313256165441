import React from 'react'
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { PaymentMethods } from '../Payments/constants';
import { getPaymentMessages } from '../../constants/messages'
import { useIntl } from 'react-intl';

let PaymentMessage = getPaymentMessages();


const messages = {
	...PaymentMessage,
}
const Filters = ({onFilter}) => {

    const intl = useIntl();
    const handleChange = (value) => {
        onFilter({value});
        
    };

    const options = [
        {
            value: -1,
            label: intl.formatMessage({...messages.all })
        },
        {
            value: PaymentMethods.DEPOSIT.id,
            label: intl.formatMessage({...messages.deposit })
        },
        {
            value: PaymentMethods.CHECK.id,
            label: intl.formatMessage({...messages.check})
        },

        {
            value: PaymentMethods.CASH.id,
            label: intl.formatMessage({...messages.cash })
        },
        {
            value: PaymentMethods.CREDIT_CARD.id,
            label: intl.formatMessage({...messages.creditCard})
        },
        {
            value: PaymentMethods.AUTOMATIC_DEDUCTION.id,
            label: intl.formatMessage({...messages.automaticDeduction })
        },
       


    ];

    
  return (
    <React.Fragment>
         <Select
          defaultValue={-1}
          onChange={handleChange}
          style={{
            width: '200px',
          }}
          placement='bottomLeft'
          options={options}
        />
    </React.Fragment>
  )
}

Filters.propTypes = {
	onFilter: PropTypes.func,
};
export default Filters
