import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { Modal, message } from 'antd';
import AddOrEditForm from './AddOrEditForm';
import { SaveOutlined } from '@ant-design/icons';
import { getactionMessages, getInvoicingMessages, getModalMessages } from '../../../constants/messages';
import { SaveInvoiceGuatemala } from './actions';

let ModalMessages = getModalMessages(),
    ActionMessages = getactionMessages(),
    invoicingMessages = getInvoicingMessages();

const messages = {
    ...ActionMessages,
    ...ModalMessages,
	...invoicingMessages,
};

const AddOrEditModal = ({ record, onCancel, clients, title, showModal, updateTable}) => {
    const intl = useIntl();
	const form = useRef();
    const [isSaving, setSaving] = useState(false);
    const [isDollar, setIsDollar] = useState(false);
   
    const onOk = async () => {
		try {
			setSaving(true);
            const values = await form.current.validateFields();
            values.isDollar = isDollar;
			if (record) values.id = record.id;
			const invoice = await SaveInvoiceGuatemala(values);
            updateTable(invoice); 
            onCancel();
			message.success(intl.formatMessage({...messages.saveInvoiceSuccess }));
		} catch (error) {
            message.error(intl.formatMessage({...messages.saveInvoiceError }));
		} finally {
			setSaving(false);
		}
	};

    return (
        <Modal
            open={showModal}
            title={title}
            onOk={onOk}
            confirmLoading={isSaving}
			okText={
				<span>
					<SaveOutlined />
					<FormattedMessage {...messages.save}  />
				</span>
			}
            onCancel={onCancel}
			cancelText={intl.formatMessage({...messages.cancel})}
            destroyOnClose
        >
            <AddOrEditForm 
                form={form}  
                record={record} 
                clients={clients} 
                setIsDollar={setIsDollar}
            />
        </Modal>
    );
};

AddOrEditModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string,
    showModal: PropTypes.bool,
    updateTable: PropTypes.func,
    record: PropTypes.object,
	clients: PropTypes.array,
};

export default AddOrEditModal;
