import PropTypes from 'prop-types';
import { DatePicker, Button, Modal, Col, Row, message } from 'antd';
import { injectIntl } from 'react-intl';
import { getReportMessages, getLanguageIntl } from '../../constants/messages';
import React from 'react';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

let reportMessages = getReportMessages(),
    languageMessages = getLanguageIntl();

const messages = {
    ...reportMessages,
    ...languageMessages,
};

class ExportOptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            datesRange: [],
        }
    }

    componentDidMount() {

    }

    ValidateDates = (dates) => {
        if(dates[0] === undefined || dates[1] === undefined) {
            message.error(this.props.intl.formatMessage({ ...messages.invalidDate }));
            return false;
        }
        return true;
    }

    SubmitExportData = () => {
        if(!this.ValidateDates(this.state.datesRange))
            return;

        this.props.emailData({
            dateRange: this.state.datesRange,
        });
    }

    DownloadExportData = () => {
        if(!this.ValidateDates(this.state.datesRange))
            return;

        this.props.downloadData({
            dateRange: this.state.datesRange,
        });
    }

    datePickedChange = (dates) => {
        // console.log("Dates: ", dates);
        this.setState({
            datesRange: dates
        });
    }
 
    render() {
        return (
            <Modal
                title={`${this.props.intl.formatMessage({ ...messages.clientMovement })}: ${this.props.clientName}`}
                destroyOnClose
                open={this.props.showModal}
                onCancel={this.props.handleCancel}
                footer={
                    <div>
                        <Button key="download" type="primary" size="default" onClick={this.DownloadExportData} className="button-spacing">
                            {this.props.intl.formatMessage({ ...messages.download })}
                        </Button>
                        <Button key="submit" type="primary" size="default" onClick={this.SubmitExportData} className="button-spacing">
                            {this.props.intl.formatMessage({ ...messages.send })}
                        </Button>
                        <Button key="cancel" type="default" size="default" onClick={this.props.handleCancel} className="button-spacing">
                            {this.props.intl.formatMessage({ ...messages.cancel })}
                        </Button>
                    </div>
                }
                className="ExportOptions"
            >
                <Row className="ExportReportMenu">
                    <Col span={24} className="datePicker">
                        <RangePicker
                            format={dateFormat}
                            onChange={this.datePickedChange}
                        />
                    </Col>
                </Row>
            </Modal>
        );
    }
}

ExportOptions.propTypes = {
    intl: PropTypes.object.isRequired,
    showModal: PropTypes.bool.isRequired,
    clientName: PropTypes.string.isRequired,
    emailData: PropTypes.func.isRequired,
    downloadData: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
};


export default injectIntl(ExportOptions);