import React from 'react';
import PropTypes from 'prop-types';
import { DeleteOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { InputNumber, Select, Card, Radio } from 'antd';
import { getClientMessages } from '../../../../constants/messages';
import { injectIntl } from 'react-intl';

let clientMessages = getClientMessages();

const messages = {
	...clientMessages
};

const FormItem = Form.Item;
const { Option } = Select;

class ClientRecurrentProductEntry extends React.Component {

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	renderProductToChargeField = () => {
		const { props, getIntl } = this;
		const { form, product, nonServiceProducts } = props;
		const { getFieldDecorator } = form;
		const { uuid, productId } = product;
		const options = nonServiceProducts.map(({ id, code, description }) => <Option key={id} value={id}>{`${code} - ${description}`}</Option>);
		return (
			<FormItem label={getIntl("productToCharge")}>
				{
					getFieldDecorator(`${uuid}[productId]`,
						{
							initialValue: productId,
							rules: [{
								required: true,
								message: getIntl('productToChargeRequiredError')
							}]
						}
					)(
						<Select
							className="almost-full-component"
							showSearch
							optionFilterProp="children"
							filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							notFoundContent={getIntl("notFound")}
							dropdownMatchSelectWidth={false}
						>
							{options}
						</Select>
					)
				}
			</FormItem>
		);
	}

	renderQuantityTypeSelector = () => {
		const { props, getIntl } = this;
		const { form, product } = props;
		const { getFieldDecorator } = form;
		const { uuid, setQuantity } = product;
		return (
			<FormItem label={getIntl("quantityToCharge")} >
				{
					getFieldDecorator(`${uuid}[quantityType]`,
						{
							initialValue: setQuantity === -1 ? 0 : 1,
							rules: [{
								required: true,
								message: getIntl('quantityTypeRequiredError')
							}]
						}
					)(
						<Radio.Group>
							<Radio.Button value={0}>{getIntl("perDevice")}</Radio.Button>
							<Radio.Button value={1}>{getIntl("setQuantity")}</Radio.Button>
						</Radio.Group>
					)
				}
			</FormItem>
		);
	}

	renderQuantitySetNumberField = () => {
		const { props, getIntl } = this;
		const { form, product } = props;
		const { getFieldDecorator, getFieldValue } = form;
		const { uuid, setQuantity } = product;
		const quantityType = getFieldValue(`${uuid}[quantityType]`) || 0;
		return (
			<FormItem label={getIntl("setQuantity")}>
				{
					getFieldDecorator(`${uuid}[setQuantity]`,
						{
							initialValue: setQuantity > 0 ? setQuantity : 1,
							rules: [
								{
									required: quantityType === 1,
									message: getIntl("setQuantityRequiredError"),
								}
							]
						}
					)(
						<InputNumber
							min={1}
							precision={0}
						/>
					)
				}
			</FormItem>
		);
	}

	renderQuantityField = () => {
		const { props, renderQuantityTypeSelector, renderQuantitySetNumberField } = this;
		const { form, product } = props;
		const { getFieldValue } = form;
		const { uuid, setQuantity } = product;
		const quantityType = getFieldValue(`${uuid}[quantityType]`) || setQuantity || 0;
		return (
			<div>
				{renderQuantityTypeSelector()}
				{quantityType > 0 ? renderQuantitySetNumberField() : null}
			</div>
		);
	}

	renderRuleSelect = () => {
		const { props, getIntl } = this;
		const { form, product, clientRules } = props;
		const { getFieldDecorator } = form;
		const { uuid, ruleId } = product;
		const options = clientRules.map(({ id, alias, priority }) => <Option key={id} value={id}>{`${alias || getIntl("rule")} #${priority}`}</Option>);
		return (
			<FormItem label={getIntl("applyToSpecificRule")}>
				{
					getFieldDecorator(`${uuid}[ruleId]`,
						{
							initialValue: ruleId === null ? undefined : ruleId
						}
					)(
						<Select
							className="full-component"
							showSearch
							optionFilterProp="children"
							filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							notFoundContent={getIntl("notFound")}
							dropdownMatchSelectWidth={false}
							allowClear
							placeholder={getIntl("willOnlyBeAppliedToBaseClient")}
						>
							{options}
						</Select>
					)
				}
			</FormItem>
		);
	}


	renderDeleteButton = () => {
		const { removeFunction } = this.props;
		return <DeleteOutlined className="client-rules-icon" key="delete" onClick={removeFunction} />;
	}

	fullRender = () => {
		const { props, renderProductToChargeField, renderQuantityField, renderRuleSelect, renderDeleteButton } = this;
		const { uuid } = props.product;
		const fieldSubstring = uuid.substring(0, 3);
		return (
			<div className={fieldSubstring === 'del' ? 'hide-component' : ''}>
				<Card
					key={uuid}
					className='client-rules-card'
					title={<div className="client-remove-margin-bottom">{renderProductToChargeField()}</div>}
					extra={renderDeleteButton()}
				>
					{renderQuantityField()}
					{renderRuleSelect()}
				</Card>
			</div >
		);
	}

	render() {
		return this.fullRender();
	}

}

ClientRecurrentProductEntry.defaultProps = {
	product: {},
	nonServiceProducts: [],
	clientRules: [],
};

ClientRecurrentProductEntry.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	product: PropTypes.object.isRequired,
	removeFunction: PropTypes.func.isRequired,
	nonServiceProducts: PropTypes.array.isRequired,
	clientRules: PropTypes.array.isRequired,
};

export default injectIntl(ClientRecurrentProductEntry);