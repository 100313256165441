import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	injectIntl
} from 'react-intl';
// main layout imports
import { Layout, Row, Col, Popover } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
const { Header, Sider, Content } = Layout;
import Mayre from 'mayre';
import { FetchLanguage } from '../actions/Int18Actions';
// left side menu import
import SideMenu from '../features/Menu';
import TopMenu from '../components/TopMenu';
import MobileSideMenu from '../components/MobileSideMenu';
import Profile from '../components/Profile';
import Configuration from '../components/Configuration';
import { isTablet } from '../utilities/util';
import {
	CLOSE_PROFILE,
	CLOSE_CONFIGURATION,
} from '../constants/actionTypes';


const Main = ({ toggleVisible, fetchLanguage, languageId, visible,
	children,
	profileCancel,
	configurationCancel }) => {

	let istablet = isTablet();
	let [collapsed, setCollapsed] = useState(istablet);

	useEffect(() => {
		if (languageId == 2)
			fetchLanguage("es_ES");

	}, [fetchLanguage])

	const toggle = () => setCollapsed(istablet ? true : !collapsed);

	let classes = ["content"];
	if (istablet) classes.push("mobileContent");
	let MobileMenu = <MobileSideMenu collapse={toggleVisible} />;

	return (
        <Layout className="mainLayout">
			<Sider
				trigger={null}
				collapsible
				collapsed={collapsed}
				breakpoint={['xs', 'sm']} collapsedWidth="0"  >
				<div className="logo" />
				<SideMenu /*collapsed={collapsed}*/ />
			</Sider>
			<Layout>
				<Header className="header">
					<Row type="flex" align="middle">
						<Mayre
							of={
								<Col span={4}>
									{collapsed ? <MenuUnfoldOutlined className="trigger" onClick={toggle}/> : <MenuFoldOutlined  className="trigger" onClick={toggle}/>}
								</Col>
							}
							or={
								<Col span={4}>
									<Popover
										placement="bottomLeft"
										title={"Menu"}
										content={MobileMenu}
										trigger="click"
										open={visible}
										onOpenChange={toggleVisible}
									>
										{visible ? <MenuUnfoldOutlined className="trigger"/> : <MenuFoldOutlined  className="trigger"/>}
									</Popover>
								</Col>
							}
							when={!istablet}
						/>
						<TopMenu />
					</Row>
				</Header>
				<Content className={classes.join(" ")}>
					{children}
					<Profile
						handleCancel={profileCancel}
					/>
					<Configuration
						handleCancel={configurationCancel}
					/>
				</Content>
			</Layout>
		</Layout>
    );
}

Main.propTypes = {
	children: PropTypes.object.isRequired,
	toggleVisible: PropTypes.func.isRequired,
	fetchLanguage: PropTypes.func.isRequired,
	profileCancel: PropTypes.func.isRequired,
	configurationCancel: PropTypes.func.isRequired,
	visible: PropTypes.bool.isRequired,
	languageId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
	return {
		visible: state.menu.visible,
		languageId: state.auth.user.languageId,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleVisible: (visible) => {
			dispatch({ type: "TOGGLE_MENU", visible: visible });
		},
		profileCancel: () => {
			dispatch({ type: CLOSE_PROFILE });
		},
		configurationCancel: () => {
			dispatch({ type: CLOSE_CONFIGURATION });
		},
		fetchLanguage: (language) => {
			dispatch(FetchLanguage(language));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Main));