import {
	FETCH_EMPLOYEES,
    FETCH_EMPLOYEE_COUNT,
	SET_SEARCH_EMPLOYEE
	
} from './actionTypes';

import {
    CLEAR_SESSION,
	CLEAN_REDUCER
} from '../../constants/actionTypes';

const initState = {
    employees: [],
    count: 0,
	search: "",
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case FETCH_EMPLOYEES:
			return {
				...state,
				employees: action.employees
			};
        case FETCH_EMPLOYEE_COUNT:
            return {
                ...state,
                count: action.count
            };
		case SET_SEARCH_EMPLOYEE:
			return {
				...state,
				search:action.search
			}
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;