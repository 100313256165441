import React from 'react';
import PropTypes from 'prop-types';
import { Table, Checkbox } from 'antd';
import { injectIntl } from 'react-intl';
import { getInvoicingMessages } from '../../../constants/messages';
import { existsInArray, roundN } from '../../../utilities/util';
import { calculateTaxRetentionsAndFinalAmount } from '../constants';

const invoiceMessages = getInvoicingMessages();

const messages = {
	...invoiceMessages,
};

class TaxRetentionPicker extends React.Component {

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	buildData = () => {
		const { props, getIntl } = this;
		const { form, invoice, isDollar } = props;
		const { getFieldValue } = props.form;
		const { fifteenPercent, onePercentSubtotal, onePercentTotal, finalAmount } = calculateTaxRetentionsAndFinalAmount(form, invoice, isDollar);
		const taxRetention = getFieldValue('taxRetention') || [];
		const data = []
		if (existsInArray("fifteenPercent", taxRetention))
			data.push({
				key: "1",
				title: getIntl("taxRetentionAmount", { amount: 15 }),
				amount: fifteenPercent,
				retentionField: `isvReceived`
			});
		if (existsInArray("onePercentSubtotal", taxRetention))
			data.push({
				key: "2",
				title: getIntl("taxRetentionAmount", { amount: 1 }),
				amount: onePercentSubtotal,
				retentionField: `isrReceived`
			});
		else if (existsInArray("onePercentTotal", taxRetention))
			data.push({
				key: "2",
				title: getIntl("taxRetentionAmount", { amount: 1 }),
				amount: onePercentTotal,
				retentionField: `isrReceived`
			});
		data.push({ key: "3", title: getIntl("Amount"), amount: finalAmount });
		return data;
	}

	buildColumns = () => {
		const { props, getIntl } = this;
		const { isDollar } = props;
		const { getFieldDecorator } = props.form;
		return [
			{
				dataIndex: 'title',
				key: 'title'
			},
			{
				dataIndex: 'amount',
				key: 'amount',
				render: amount => `${isDollar ? "$" : "L"} ${roundN(amount, 2)}`
			},
			{
				title: getIntl("confirmRetention"),
				dataIndex: 'retentionField',
				key: 'retentionField',
				align: 'center',
				render: retentionField => {
					if (retentionField)
						return (
							getFieldDecorator(retentionField,
								{
									initialValue: false,
									valuePropName: 'checked'
								}
							)(<Checkbox />)
						);
					return null;
				}
			}
		];
	}

	fullRender = () => {
		const { props, buildColumns, buildData } = this;
		const { getFieldValue } = props.form;
		const taxRetention = getFieldValue('taxRetention') || [];
		const renderIt = taxRetention.length > 0;
		if (renderIt) {
			const data = buildData(), columns = buildColumns();
			return <Table columns={columns} dataSource={data} size="small" pagination={false} />;
		}
		return null;
	}

	render() {
		return this.fullRender();
	}
}

TaxRetentionPicker.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	invoice: PropTypes.object.isRequired,
	isDollar: PropTypes.bool.isRequired
};

export default injectIntl(TaxRetentionPicker);