import {
	combineReducers
} from 'redux';
import Int18Reducer from './Int18Reducer';
import HomePageReducer from './HomePageReducer';
import AccountReducer from './AccountReducer';
import LanguageReducer from './LanguageReducer';
import AuthReducer from './authReducer';
import RoleReducer from './RoleReducer';
import SessionReducer from './SessionReducer';
import MenuReducer from './MenuReducer';
import ProfileReducer from './ProfileReducer';
import ConfigurationReducer from './ConfigurationReducer';
import ClientReducer from '../features/Clients/reducer';
import CurrencyReducer from './CurrencyReducer';
import JobReducer from '../features/Jobs/reducer';
import InvoicingReducer from '../features/Invoices/reducer';
import FiscalDataReducer from '../features/FiscalData/reducer';
import ProductReducer from '../features/Products/reducer';
import PaymentReducer from '../features/Payments/reducer';
import ExchangeRateReducer from '../features/ExchangeRate/reducer';
import ProformaReducer from '../features/Proformas/reducer';
import QuotationReducer from '../features/Quotations/reducer';
import CreditNoteReducer from '../features/CreditNotes/reducer';
import DebitNoteReducer from '../features/DebitNotes/reducer';
import ReportReducer from '../features/Reports/reducer';
import IncomeReducer from '../features/IncomeReport/reducer';
import DepartmentReducer from '../features/Departments/reducer';
import EmployeeReducer from '../features/Employees/reducer';
import RequestsReducer from '../features/Requests/reducer';
import DashboardReducer from '../features/Dashboard/reducer';
import PayrollReducer from '../features/Payroll/reducer';
import CommissionReducer from '../features/Commissions/reducer';
import ModelReducer from '../features/Models/reducer';
import UnitReducer from '../features/Units/reducer';
import LotUnitsReducer from '../features/LotUnits/reducer';
import ContactsReducer from '../features/Contacts/reducer';
import OptimusGuatemalaReducer from '../features/OptimusGuatemala/reducer';
import ReceiptsReducer from '../features/Receipts/reducer';

export default () => combineReducers({
	int18: Int18Reducer,
	home: HomePageReducer,
	account: AccountReducer,
	language: LanguageReducer,
	auth: AuthReducer,
	session: SessionReducer,
	role: RoleReducer,
	menu: MenuReducer,
	configuration: ConfigurationReducer,
	profile: ProfileReducer,
	client: ClientReducer,
	currency: CurrencyReducer,
	job: JobReducer,
	fiscalData: FiscalDataReducer,
	invoicing: InvoicingReducer,
	product: ProductReducer,
	payment: PaymentReducer,
	exchangeRate: ExchangeRateReducer,
	proforma: ProformaReducer,
	quotation: QuotationReducer,
	creditNote: CreditNoteReducer,
	debitNote: DebitNoteReducer,
	report: ReportReducer,
	incomeReport: IncomeReducer,
	department: DepartmentReducer,
	employee: EmployeeReducer,
	request: RequestsReducer,
	dashboard: DashboardReducer,
	payroll: PayrollReducer,
	commissions: CommissionReducer,
	model: ModelReducer,
	unit: UnitReducer,
	lotUnit: LotUnitsReducer,
	contact: ContactsReducer,
	optimusGuatemala: OptimusGuatemalaReducer,
	receipts: ReceiptsReducer
});
