import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker, Button, Popconfirm } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { injectIntl } from 'react-intl';
import { getClientMessages, getCalendarMessages, getFields, getModalMessages, getPopConfirm } from '../../../../constants/messages';
const moment = require('moment');

const FormItem = Form.Item;

let clientMessages = getClientMessages(),
	calendarMessages = getCalendarMessages(),
	fieldMessages = getFields(),
	modalMessages = getModalMessages(),
	popConfirmMessages = getPopConfirm();

const messages = {
	...clientMessages,
	...calendarMessages,
	...fieldMessages,
	...modalMessages,
	...popConfirmMessages
};

const dateFormat = "DD-MM-YYYY";

class PDDatePicker extends React.Component {

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	disabledDates = (current) => {
		return current < moment().startOf('day');
	}

	formatPaidDateEventValue = (date) => {
		if (!date) return null;
		const momentAtTwelve = moment(date).set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
		return momentAtTwelve;
	}

	renderDatePickerField = () => {
		const { props, getIntl, formatPaidDateEventValue, disabledDates } = this;
		const { form, disableDate } = props;
		const { getFieldDecorator } = form;
		return (
			<FormItem label={getIntl("programmedDeactivationDate")} >
				{
					getFieldDecorator(`date`,
						{
							initialValue: disableDate ? moment(disableDate).utc() : undefined,
							getValueFromEvent: formatPaidDateEventValue,
							rules: [{
								required: false,
								message: getIntl("programmedDeactivationDateError"),
							}]
						}
					)(
						<DatePicker
							className="job-full-component"
							showTime={false}
							format={dateFormat}
							allowClear
							showToday={false}
							disabledDate={disabledDates}
						/>
					)
				}
			</FormItem>
		);
	}

	handleSave = () => {
		const { props } = this;
		const { form, onSave } = props;
		const { validateFields } = form;
		validateFields({ force: true }, (err, values) => {
			if (!err) {
				const { date } = values;
				const isoDate = moment(date).toISOString();
				onSave(isoDate);
			}
		});
	}

	renderSaveButton = () => {
		const { props, getIntl, handleSave } = this;
		const { isLoading, form, disableDate } = props;
		const { getFieldValue } = form;
		const currentDate = getFieldValue('date');
		const disabled = (!disableDate && !currentDate) || (disableDate && currentDate && moment(currentDate).utc().isSame(disableDate, 'date'));
		const buttonProps = {
			key: "save",
			style: { marginBottom: "10px" },
			type: "primary",
			loading: isLoading,
			block: true,
			disabled
		};
		if (!disableDate) buttonProps.onClick = handleSave;
		const saveButton = (
			<Button {...buttonProps}>
				 <SaveOutlined />
				{getIntl("save")}
			</Button>
		);
		if (disabled) return saveButton;
		if (disableDate) {
			return (
				<Popconfirm
					title={currentDate ? getIntl("programmedDeactivationDateChangeConfirm") : getIntl("programmedDeactivationDateClearConfirm")}
					onConfirm={handleSave}
					okText={getIntl("yes")}
					cancelText={getIntl("no")}
				>
					{saveButton}
				</Popconfirm>
			);
		}
		return saveButton;
	}

	fullRender = () => {
		const { renderDatePickerField, renderSaveButton } = this;
		return (
			<div>
				{renderDatePickerField()}
				{renderSaveButton()}
			</div>
		);
	}

	render() {
		return this.fullRender();
	}
}

PDDatePicker.defaultProps = {
	disableDate: undefined
};

PDDatePicker.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	disableDate: PropTypes.string,
	onSave: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

export default Form.create()(injectIntl(PDDatePicker));