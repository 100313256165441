import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Modal,
    InputNumber,
    Input,
    Checkbox,
    Row,
    Col,
    Select,
    Table,
    Badge,
    Tabs,
    message,
} from 'antd';
import { getModalMessages, getPaymentMessages, getErrorMessages, getInvoicingMessages, getDebitNoteMessages, getLanguageIntl, getClientMessages, getactionMessages } from '../../constants/messages';
import { connect } from 'react-redux';
import { AddPayment, EmailReceipt, MakeReceipt } from './actions';
import { GetClientsPendingInvoices, CleanPendingInvoices, InvoicePaid, GetInvoices, TaxRetention } from '../Invoices/actions';
import { GetClientsPendingDebitNotes, GetDebitNotes } from '../DebitNotes/actions';
import { GetEmails } from '../Clients/actions';
import { existsInArray, roundN } from '../../utilities/util';
import { VARIOUS_CLIENTS_CLIENT_ID } from '../../constants/global';
import Mayre from 'mayre';
import EmailSelector from '../../components/EmailSelector';
import ClientSelector from '../Invoices/clientSelector';
import PopoverProductsTable from '../Invoices/PopoverProductsTable';
import PaymentDatePicker from './PaymentDatePicker';
import DestinationAccountPicker from './DestinationAccountPicker';
import { getPaymentMethodsList, Banks, PaymentMethods, calculateTaxRetentionsAndFinalAmount } from './constants';
const { DEPOSIT, CHECK, CASH, CREDIT_CARD, AUTOMATIC_DEDUCTION } = PaymentMethods;
import TaxRetentionPicker from './TaxRetentionComponents/TaxRetentionPicker';
import ProgrammedDeactivationChanger from '../Clients/ApprovedClients/ProgrammedDeactivations/AfterPaymentChanger/Modal';

let modalMessages = getModalMessages(),
	paymentMessages = getPaymentMessages(),
	errorMessages = getErrorMessages(),
	invoiceMessages = getInvoicingMessages(),
	debitNoteMessages = getDebitNoteMessages(),
	languageMessages = getLanguageIntl(),
	clientMessages = getClientMessages(),
	actionMessages = getactionMessages();

const messages = {
	...modalMessages,
	...paymentMessages,
	...errorMessages,
	...invoiceMessages,
	...debitNoteMessages,
	...languageMessages,
	...clientMessages,
	...actionMessages
};

let moment = require('moment');
const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;

const numRound = num => Math.round((num + Number.EPSILON) * 100) / 100;

class GeneralAddPaymentModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isDollar: false,
			currentClientId: null,
			showEmailModal: false,
			receiptInfo: {},
			paymentMethod: 0,
			isLoading: false,
			clientsSelect: [],
			invoicesSelect: [],
			invoicesToPay: [],
			debitNotesSelect: [],
			debitNotesToPay: [],
			total: 0,
			tab: '1',
			showProgrammedDeactivatorModal: false
		};
	}

	closeEmailModal = () => {
		this.setState({
			showEmailModal: false
		});
	}

	closeProgrammedDeactivatorModal = () => {
		this.setState({
			showProgrammedDeactivatorModal: false,
			showEmailModal: true
		});
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });

	onChangeTab = (tab) => {
		this.setState({
			isDollar: false,
			tab: tab
		});
	}

	handleSelectInvoices = (selected) => {
		let promise = new Promise((resolve) => {
			this.setState({ invoicesToPay: selected });
			resolve();
		});
		promise.then(() => {
			this.setState({ total: this.calculateTotal() });
		});
	}

	handleSelectDebitNotes = (selected) => {
		let promise = new Promise((resolve) => {
			this.setState({ debitNotesToPay: selected });
			resolve();
		});
		promise.then(() => {
			this.setState({ total: this.calculateTotal() });
		});
	}

	handleChangeCheckbox = () => {
		let promise = new Promise((resolve) => {
			this.setState({ isDollar: !this.state.isDollar });
			resolve();
		});
		promise.then(() => {
			this.setState({ total: this.calculateTotal() });
		});
	}

	calculateTotal = () => {
		let totalInvoices = 0, totalDebitNotes = 0;
		this.state.invoicesToPay.forEach((index) => {
			totalInvoices += this.state.isDollar ? this.convertToDollars(this.state.invoicesSelect[index].balance, this.state.invoicesSelect[index].exchangeRate) : this.state.invoicesSelect[index].balance;
		});
		this.state.debitNotesToPay.forEach((index) => {
			totalDebitNotes += this.state.debitNotesSelect[index].balance
		});
		return totalInvoices + (!this.state.isDollar ? totalDebitNotes : 0);
	}

	getPaymentDescription(values) {
		let result = "";
		switch (values.paymentMethod) {
			case DEPOSIT.id:
				result = "Depósito con número de referencia " + values.refNumber + ". ";
				break;
			case CHECK.id:
				result = "Pago por medio de cheque número " + values.checkNumber + " de " + values.bank + ". ";
				break;
			case CASH.id:
				result = "Pago hecho en efectivo.";
				break;
			case CREDIT_CARD.id:
				result = "Pago por medio de tarjeta de crédito número ****-" + values.lastDigits + ". ";
				break;
			case AUTOMATIC_DEDUCTION.id:
				result = "Deduccion automatica";
				break;
			default:
				break;
		}
		return values.description !== undefined ? result + values.description : result;
	}

	handleOk = () => {
		const { state, props, setLoading } = this;
		const { isDollar } = state;
		const { form } = props;
		this.props.form.validateFieldsAndScroll({ force: true }, (err, values) => {
			if (err) return;
			const { taxRetention, isvReceived, isrReceived } = values;
			let payTotal = values.amount;
			values = {
				destinationId: values.destinationId,
				paymentMethod: values.paymentMethod,
				isDollar: this.state.isDollar,
				createdAt: moment(),
				description: this.getPaymentDescription(values),
				type: 1,
				createIncome: values.createIncome,
				paymentDate: moment(values.paymentDate).toISOString()
			};
			let paymentInfo = {
				clientId: this.state.currentClientId,
				date: values.createdAt,
				amount: payTotal
			};
			let invoicesIndex = this.state.invoicesToPay.sort(function (a, b) { return b - a });
			let paidInvoices;
			if (taxRetention.length > 0) {
				let finalAmount = paymentInfo.amount;
				paidInvoices = invoicesIndex.map(index => {
					const invoice = this.state.invoicesSelect[index];
					const { fifteenPercent, onePercentSubtotal, onePercentTotal } = calculateTaxRetentionsAndFinalAmount(form, invoice, isDollar);
					invoice.isDollar = this.state.isDollar;
					if (existsInArray("fifteenPercent", taxRetention)) {
						invoice.fifteenPercentTaxOverride = numRound(fifteenPercent);
						invoice.isvRetention = true;
						invoice.isvPreSelected = false;
						invoice.isvReceived = isvReceived;
						finalAmount -= invoice.fifteenPercentTaxOverride;
					}
					if (existsInArray("onePercentSubtotal", taxRetention)) {
						invoice.onePercentTaxOverride = numRound(onePercentSubtotal);
						invoice.isrRetention = true;
						invoice.isrPreSelected = false;
						invoice.isrReceived = isrReceived;
						finalAmount -= invoice.onePercentTaxOverride;
					}
					else if (existsInArray("onePercentTotal", taxRetention)) {
						invoice.onePercentTaxOverride = numRound(onePercentTotal);
						invoice.isrRetention = true;
						invoice.isrPreSelected = false;
						invoice.isrReceived = isrReceived;
						finalAmount -= invoice.onePercentTaxOverride;
					}
					return invoice;
				});
				paymentInfo.amount = finalAmount;
			} else paidInvoices = invoicesIndex.map(index => this.state.invoicesSelect[index]);
			let paidDebitNotes = this.state.debitNotesToPay.map(index => this.state.debitNotesSelect[index]);
			let result = paidDebitNotes.concat(paidInvoices);
			setLoading(true);
			this.props.makeReceipt(paymentInfo, result, values).then((receiptResult) => {
				this.setState({ receiptInfo: receiptResult });
				const updateTable = this.props.isDebitNote ?  this.props.getDebitNotes : this.props.getInvoices;
				updateTable().then(() => {
					this.handleCancel();
					this.props.getEmails(this.state.currentClientId).then(() => this.setState({ showProgrammedDeactivatorModal: true }));
				});
			}).catch(error => {
				message.error("Error: " + error);
				setLoading(false);
			});
		});
	}

	handleCancel = () => {
		this.setState({
			paymentMethod: 0,
			isDollar: false,
			isLoading: false,
			total: 0,
			invoicesToPay: [],
			invoicesSelect: [],
			debitNotesToPay: [],
			debitNotesSelect: []
		});
		this.props.cleanPendingInvoices();
		this.props.closePaymentModal();
	}


	handleChangeType = (value) => {
		this.setState({
			paymentMethod: value
		});
	}

	handleSelectClient = (clientId) => {
		this.setState({ currentClientId: clientId });
		if (clientId === VARIOUS_CLIENTS_CLIENT_ID) {
			this.setState({
				paymentMethod: 0,
				isDollar: false,
				isLoading: false,
				total: 0,
				invoicesToPay: [],
				invoicesSelect: [],
				debitNotesToPay: [],
				debitNotesSelect: []
			});
			this.props.cleanPendingInvoices();
		}
		this.loadSelectedClientInfo(clientId);
	}

	mapClients = () => {
		return (
			<ClientSelector
				selectClient={this.handleSelectClient}
				form={this.props.form}
				selectedClient={this.props.selectedClient}
			/>
		);
	}

	paymentMethod = () => {
		const { getIntl } = this;
		const options = getPaymentMethodsList().map(({ id, intl }) => <Option key={id} value={id}>{getIntl(intl)}</Option>);
		return (
			<Select className="job-full-component" onChange={(value) => { this.handleChangeType(value); }} >
				{options}
			</Select>
		);
	}

	banks = () => {
		const options = Banks.map((bank, index) => <Option key={index} value={bank}>{bank}</Option>);
		return (
			<Select className="job-full-component" >
				{options}
			</Select>
		);
	}

	convertToDollars = (value, exchangeRate) => {
		return value / exchangeRate;
	}

	convertToLps = (value, exchangeRate) => {
		return value * exchangeRate;
	}

	renderTable = (invoice) => {
		const { state, props, renderTableFooter } = this;
		const { isDollar } = state;
		const { form } = props;
		const { getFieldValue } = form;
		let rowSelection = {
			onChange: this.handleSelectInvoices,
			type: "checkbox",
			selectedRowKeys: this.state.invoicesToPay,
			getCheckboxProps: record => ({
				disabled: record.documentNumber === 'Total'
			}),
		};

		if (!invoice)
			rowSelection = {
				...rowSelection,
				onChange: this.handleSelectDebitNotes,
				selectedRowKeys: this.state.debitNotesToPay,
			}

		let currency = this.state.isDollar ? "$" : "L";
		let columns = [
			{
				title: this.props.intl.formatMessage(invoice ? { ...messages.InvoiceNumber } : { ...messages.DebitNoteNumber }),
				dataIndex: 'documentNumber',
				key: 'documentNumber',
				width: 50,
				render: (documentNumber, doc) => {
					if (invoice) {
						const { id: invoiceId, invoiceProducts = [] } = doc;
						return (
							<PopoverProductsTable products={invoiceProducts}>
								<a className="editable-add-btn" href={`/${'invoices'}/${invoiceId}`} target="_blank" rel="noopener noreferrer">
									{documentNumber}
								</a>
							</PopoverProductsTable>
						);
					}
					else return documentNumber;
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Status }),
				dataIndex: 'status',
				className: 'status',
				width: 25,
				render: (status) => {
					let color = 'default';
					let text = this.props.intl.formatMessage({ ...messages.Pending });
					if (status === 1) {
						color = 'success';
						text = this.props.intl.formatMessage({ ...messages.Paid });
					} else if (status === 2) {
						color = 'warning';
						text = this.props.intl.formatMessage({ ...messages.Overdue });
					} else if (status === 3) {
						color = 'error';
						text = this.props.intl.formatMessage({ ...messages.Annulled });
					} else if (status === 4) {
						return "";
					}
					return (
						<Badge status={color} text={text} />
					);
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Total }),
				dataIndex: 'total',
				key: 'total',
				className: 'column-money',
				width: 150,
				render: (total, doc) => {
					let value = !this.state.isDollar && invoice ? roundN(total * doc.exchangeRate, 2) : roundN(total, 2);
					return (
						`${currency} ${value}`
					);
				}
			}, {
				title: 'Balance',
				dataIndex: 'balance',
				key: 'balance',
				className: 'column-money',
				width: 150,
				render: (balance, doc) => {
					let value = this.state.isDollar ? roundN(this.convertToDollars(balance, doc.exchangeRate), 2) : roundN(balance, 2);
					return (
						`${currency} ${value}`
					);
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Date }),
				dataIndex: 'createdAt',
				key: 'createdAt',
				render: (date) => {
					let formatedDate = new Date(date);
					formatedDate.toLocaleDateString();
					return (
						formatedDate.toLocaleDateString(this.props.intl.formatMessage({ ...messages.intl }), { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
					);
				}
			}
		];
		const taxRetention = getFieldValue('taxRetention') || [];
		const showTaxRetentions = taxRetention.length > 0;
		if (invoice && showTaxRetentions) {
			const taxRetentionColumns = []
			if (existsInArray("fifteenPercent", taxRetention))
				taxRetentionColumns.push({
					title: '15% R.',
					key: "fifteenPercent",
					render: invoice => {
						const { fifteenPercent } = calculateTaxRetentionsAndFinalAmount(form, invoice, isDollar);
						return `${isDollar ? "$" : "L"} ${roundN(fifteenPercent, 2)}`
					}
				});
			if (existsInArray("onePercentSubtotal", taxRetention))
				taxRetentionColumns.push({
					title: '1% R.',
					key: "onePercentSubtotal",
					render: invoice => {
						const { onePercentSubtotal } = calculateTaxRetentionsAndFinalAmount(form, invoice, isDollar);
						return `${isDollar ? "$" : "L"} ${roundN(onePercentSubtotal, 2)}`
					}
				});
			else if (existsInArray("onePercentTotal", taxRetention))
				taxRetentionColumns.push({
					title: '1% R.',
					key: "onePercentTotal",
					render: invoice => {
						const { onePercentTotal } = calculateTaxRetentionsAndFinalAmount(form, invoice, isDollar);
						return `${isDollar ? "$" : "L"} ${roundN(onePercentTotal, 2)}`
					}
				});
			columns = columns.concat(taxRetentionColumns);
		}

		return (
			<Table
				dataSource={invoice ? this.state.invoicesSelect : this.state.debitNotesSelect}
				columns={columns}
				rowSelection={rowSelection}
				pagination={false}
				footer={renderTableFooter}
			/>
		);
	}

	renderTableFooter = () => {
		const { state, props, getIntl, getTaxRetentionTotal } = this;
		const { isDollar, total } = state;
		const { getFieldValue } = props.form;
		const currency = isDollar ? "$" : "L";
		const taxRetentionTotal = getTaxRetentionTotal();
		const taxRetention = getFieldValue('taxRetention') || [];
		if (taxRetention.length > 0)
			return (
				<Row gutter={16} type="flex" align="middle">
					<Col span={8}>{`${getIntl("totalAmount")}: ${currency} ${roundN(total, 2)}`}</Col>
					<Col span={8}>{`${getIntl("totalTaxRetention")}: ${currency} ${roundN(taxRetentionTotal, 2)}`}</Col>
					<Col span={8} style={{ textAlign: 'right' }}>{`${getIntl("totalReceived")}: ${currency} ${roundN(total - numRound(taxRetentionTotal), 2)}`}</Col>
				</Row>
			);
		return (
			<Row gutter={16} type="flex" align="middle">
				<Col span={12}>Total</Col>
				<Col span={12} style={{ textAlign: 'right' }}>{`${currency} ${roundN(total, 2)}`}</Col>
			</Row>
		);
	}

	renderCommentField = () => {
		const { props, getIntl } = this;
		const { getFieldDecorator } = props.form;
		const maxLength = 150;
		return (
			<FormItem label={getIntl("Comment")}>
				{
					getFieldDecorator('description', {
						rules: [
							{
								max: maxLength,
								message: getIntl("commentLengthError", { quantity: maxLength })
							}
						]
					})(<TextArea rows={4} />)
				}
			</FormItem>
		);
	}

	getPaidInvoices = () => {
		const { invoicesToPay, invoicesSelect } = this.state;
		const invoicesIndex = invoicesToPay.sort((a, b) => b - a);
		const paidInvoices = invoicesIndex.map(index => invoicesSelect[index]);
		return paidInvoices;
	}

	getTaxRetentionTotal = () => {
		const { state, props, getPaidInvoices } = this;
		const { isDollar } = state;
		const { form } = props;
		const { getFieldValue } = form;
		const paidInvoices = getPaidInvoices();
		const taxRetention = getFieldValue('taxRetention') || [];
		let finalTaxRetentionAmount = 0;
		paidInvoices.forEach(invoice => {
			const { fifteenPercent, onePercentSubtotal, onePercentTotal } = calculateTaxRetentionsAndFinalAmount(form, invoice, isDollar);
			if (existsInArray("fifteenPercent", taxRetention)) finalTaxRetentionAmount += numRound(fifteenPercent);
			if (existsInArray("onePercentSubtotal", taxRetention)) finalTaxRetentionAmount += numRound(onePercentSubtotal);
			else if (existsInArray("onePercentTotal", taxRetention)) finalTaxRetentionAmount += numRound(onePercentTotal);
		});
		return finalTaxRetentionAmount;
	}

	exactAmountValidator = (rule, value, callback) => {
		if (value === undefined || value === null) return callback();
		const { props, getIntl, calculateTotal } = this;
		const { getFieldValue } = props.form;
		const total = calculateTotal();
		const taxRetention = getFieldValue('taxRetention') || [];
		if (taxRetention.length > 0 && numRound(value) !== numRound(total)) return callback(getIntl("exactAmountOnTaxRetentionError"));
		callback();
	}

	renderForm = () => {
		const { state: { isDollar, tab, paymentMethod }, props: { form, intl, isDebitNote }, exactAmountValidator, renderTaxRetentionReceivedCheckboxes } = this;
		const { getFieldDecorator } = form;
		const amountParser = isDollar ? /\$\s?|(,*)/g : /L\s?|(,*)/g;

		const items = [
			{
				key: '1',
				label: intl.formatMessage({ ...messages.Invoices }),
				children: this.renderTable(true),
				disabled: isDebitNote
			},
			{
				key: '2',
				label: intl.formatMessage({ ...messages.DebitNotes }),
				children: this.renderTable(false),
				disabled: !isDebitNote
			},
		];

		return (
            <div>
				<Form layout='vertical'>
					<Row gutter={16} type="flex" align="bottom">
						<Col span={8}>
							{this.mapClients()}
						</Col>
						<Col span={8}>
							<FormItem label={intl.formatMessage({ ...messages.totalAmount })}>
								{getFieldDecorator('amount', {
									rules: [
										{
											required: true,
											message: intl.formatMessage({ ...messages.paymentError }),
										},
										{
											validator: exactAmountValidator
										}
									],
									initialValue: 0.00
								})(
									<InputNumber
										style={{ width: '95%', 'textAlign': 'right' }}
										formatter={value => `${isDollar ? "$" : "L"} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
										parser={value => value.replace(amountParser, '')}
										step={1}
										min={0}
										precision={2}
									/>
								)}
							</FormItem>
						</Col>
						<Col span={4}>
							<FormItem>
								{/* {getFieldDecorator('isDollar', {
									rules: [{
										required: true, message: intl.formatMessage({ ...messages.paymentError }),
									}],
									initialValue: false,
									valuePropName: 'checked'
								})(
									<Checkbox checked={isDollar} onChange={this.handleChangeCheckbox} disabled={tab === "2"}><FormattedMessage {...messages.Dollars} /></Checkbox>
								)} */}
								<Checkbox checked={isDollar} onChange={this.handleChangeCheckbox} disabled={tab === "2"}><FormattedMessage {...messages.Dollars} /></Checkbox>
							</FormItem>
						</Col>
						<Col span={4}>
							<FormItem>
								{getFieldDecorator('createIncome', {
									rules: [{
										required: true, message: intl.formatMessage({ ...messages.paymentError }),
									}],
									initialValue: false,
									valuePropName: 'checked'
								})(
									<Checkbox disabled>Crear Ingreso</Checkbox>
								)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={16} type="flex" align="middle">
						<Col span={12}>
							<DestinationAccountPicker form={form} />
						</Col>
						<Col span={12}>
							<FormItem label={intl.formatMessage({ ...messages.TypeOfPayment })}>
								{getFieldDecorator('paymentMethod', {
									rules: [{
										required: true, message: intl.formatMessage({ ...messages.paymentTypeError }),
									}]
								})(
									this.paymentMethod()
								)}
							</FormItem>
						</Col>
					</Row>
					<Mayre
						of={
							<FormItem label={intl.formatMessage({ ...messages.RefNumber })}>
								{getFieldDecorator('refNumber', {
									rules: [{
										required: paymentMethod === DEPOSIT.id, whitespace: true, message: intl.formatMessage({ ...messages.paymentRefError }),
									}]
								})(
									<Input />
								)}
							</FormItem>
						} when={paymentMethod === DEPOSIT.id}
					/>
					<Mayre
						of={
							<Row gutter={16} type="flex" align="middle">
								<Col span={12}>
									<FormItem label={intl.formatMessage({ ...messages.CheckNumber })}>
										{getFieldDecorator('checkNumber', {
											rules: [{
												required: paymentMethod === CHECK.id, whitespace: true, message: intl.formatMessage({ ...messages.paymentCheckError }),
											}]
										})(
											<Input />
										)}
									</FormItem>
								</Col>
								<Col span={12}>
									<FormItem label={intl.formatMessage({ ...messages.Bank })}>
										{getFieldDecorator('bank', {
											rules: [{
												required: paymentMethod === CHECK.id, message: intl.formatMessage({ ...messages.paymentBankError }),
											}]
										})(
											this.banks()
										)}
									</FormItem>
								</Col>
							</Row>
						} when={paymentMethod === CHECK.id}
					/>
					<Mayre
						of={
							<span>
								<FormItem label={intl.formatMessage({ ...messages.LastDigits })}>
									{getFieldDecorator('lastDigits', {
										rules: [{
											required: paymentMethod === CREDIT_CARD.id,
											message: intl.formatMessage({ ...messages.paymentCardError }),
											whitespace: true,
											len: 4
										}]
									})(
										<Input />
									)}
								</FormItem>
							</span>
						} when={paymentMethod === CREDIT_CARD.id}
					/>
					<Row gutter={16} type="flex" align="middle">
						<Col span={12}><PaymentDatePicker form={form} /></Col>
						<Col span={12}><TaxRetentionPicker form={form} includeLabel /></Col>
					</Row>
					{renderTaxRetentionReceivedCheckboxes()}
					<Tabs 
						defaultActiveKey={ isDebitNote ? "2" : "1" } 
						onChange={this.onChangeTab}
						items={items}
					/>
					{this.renderCommentField()}
				</Form>

			</div>
        );
	}

	loadSelectedClientInfo(clientId) {
		const { props, getIntl, setLoading } = this;
		const { getClientsPendingInvoices, getClientsPendingDebitNotes } = props;
		const promises = [
			new Promise((resolve, reject) => {
				getClientsPendingInvoices(clientId)
					.then(() => resolve(null))
					.catch(() => reject(0));
			}),
			new Promise((resolve, reject) => {
				getClientsPendingDebitNotes(clientId)
					.then(response => resolve(response.data))
					.catch(() => reject(1));
			})
		];
		setLoading(true);
		Promise.all(promises)
			.then(results => {
				const pendingDebitNotes = results[1];
				const invoicesSelect = this.props.pendingInvoices.map((invoice, key) => ({ ...invoice, key, isDebitNote: false, isrPreSelected: invoice.isrRetention, isvPreSelected: invoice.isvRetention }));
				const debitNotesSelect = pendingDebitNotes.map((debitNote, key) => ({ ...debitNote, key, isDebitNote: true }));
				this.setState({ invoicesSelect, invoicesToPay: [], total: 0, debitNotesSelect, debitNotesToPay: [], isLoading: false });
			})
			.catch(errorNumber => {
				setLoading(false);
				switch (errorNumber) {
					case 0:
						message.error(getIntl("loadClientUnpaidInvoicesError"));
						break;
					case 1:
						message.error(getIntl("loadClientUnpaidDebitNotesError"));
						break;
					default:
						message.error(getIntl("loadClientUnpaidInvoicesError"));
						break;
				}
			});
	}

	renderTaxRetentionReceivedCheckboxes = () => {
		const { props, getIntl } = this;
		const { getFieldDecorator, getFieldValue } = props.form;
		const taxRetention = getFieldValue('taxRetention') || [];
		if (taxRetention.length > 0)
			return (
				<Row gutter={16} type="flex" align="middle">
					<Col offset={12} span={4}>{getIntl("confirmRetention")}</Col>
					{
						existsInArray("fifteenPercent", taxRetention) ?
							<Col span={4}>
								{
									getFieldDecorator('isvReceived',
										{
											initialValue: false,
											valuePropName: 'checked'
										}
									)(<Checkbox>isv</Checkbox>)
								}
							</Col> : null
					}
					{
						existsInArray("onePercentSubtotal", taxRetention) || existsInArray("onePercentTotal", taxRetention) ?
							<Col span={4}>
								{
									getFieldDecorator('isrReceived',
										{
											initialValue: false,
											valuePropName: 'checked'
										}
									)(<Checkbox>isr</Checkbox>)
								}
							</Col> : null
					}
				</Row>
			);
		return null;
	}

	render() {
		const { state, closeProgrammedDeactivatorModal } = this;
		const { showProgrammedDeactivatorModal, currentClientId } = state;
		return (
			<React.Fragment>
				<Modal
					title={this.props.intl.formatMessage({ ...messages.AddPayment })}
					destroyOnClose
					open={this.props.showPaymentModal}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					confirmLoading={this.state.isLoading}
					width={1100}
					footer={
						<div>
							<Button type="primary" size="large" loading={this.state.isLoading} onClick={this.handleOk} className="button-spacing">
								<FormattedMessage {...messages.send} />
							</Button>
							<Button key="back" size="large" onClick={this.handleCancel}>
								<FormattedMessage {...messages.cancel} />
							</Button>
						</div>
					}
				>
					{this.renderForm()}
				</Modal>
				<EmailSelector
					addOrShow={false}
					emailDocument={this.props.emailReceipt}
					document={this.state.receiptInfo.receiptInfo}
					showEmailModal={this.state.showEmailModal}
					closeModal={this.closeEmailModal} />
				<ProgrammedDeactivationChanger showModal={showProgrammedDeactivatorModal} handleCancel={closeProgrammedDeactivatorModal} clientId={currentClientId} />
			</React.Fragment>
		);
	}

}

GeneralAddPaymentModal.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	pendingInvoices: PropTypes.array.isRequired,
	showPaymentModal: PropTypes.bool.isRequired,
	closePaymentModal: PropTypes.func.isRequired,
	selectedClient: PropTypes.object,
	getClientsPendingInvoices: PropTypes.func.isRequired,
	getInvoices: PropTypes.func.isRequired,
	getDebitNotes: PropTypes.func,
	makeReceipt: PropTypes.func.isRequired,
	getEmails: PropTypes.func.isRequired,
	cleanPendingInvoices: PropTypes.func.isRequired,
	getClientsPendingDebitNotes: PropTypes.func.isRequired,
	emailReceipt: PropTypes.func.isRequired,
	isDebitNote: PropTypes.bool
};

const mapStateToProps = (state) => {
	return {
		pendingInvoices: state.invoicing.pendingInvoices,
		clients: state.client.clients
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addPayment: (payment) => {
			return dispatch(AddPayment(payment));
		},
		getClientsPendingInvoices: (clientId) => {
			return dispatch(GetClientsPendingInvoices(clientId));
		},
		cleanPendingInvoices: () => {
			return dispatch(CleanPendingInvoices());
		},
		invoicePaid: (id) => {
			return dispatch(InvoicePaid(id));
		},
		getDebitNotes: () => {
			return dispatch(GetDebitNotes());
		},
		getInvoices: () => {
			return dispatch(GetInvoices());
		},
		taxRetention: (id, isrRetention, isvRetention) => {
			return dispatch(TaxRetention(id, isrRetention, isvRetention));
		},
		getClientsPendingDebitNotes: (clientId) => {
			return dispatch(GetClientsPendingDebitNotes(clientId));
		},
		emailReceipt: (receiptId, emails, respondTo, message) => {
			return dispatch(EmailReceipt(receiptId, emails, respondTo, message));
		},
		getEmails: (id) => {
			return dispatch(GetEmails(id));
		},
		makeReceipt: (receiptInfo, invoices, values) => {
			return dispatch(MakeReceipt(receiptInfo, invoices, values));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(GeneralAddPaymentModal)));