import React from 'react'
import { Form, Select, DatePicker, Button } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { searchInString } from '../../../utilities/util';
import { FormattedMessage, useIntl } from 'react-intl';
import { getClientMessages, getReportMessages } from '../../../constants/messages';
import moment from 'moment';

const FormItem = Form.Item;
const { RangePicker } = DatePicker;

let reportMessages = getReportMessages(),
    clientMessages = getClientMessages();

const messages = {
    ...reportMessages,
    ...clientMessages,
};

const ReportOptions = ({ onSubmit, exportButton }) => {
    const intl = useIntl();
	const [form] = Form.useForm();

    const clients = useSelector((state) => state.client.clients)
    const clientOptions = clients.map((client) => ({
		key: client.id, 
		value: client.id,
		label: client.name,
	}));

    const ranges = {
		[intl.formatMessage({...messages.lastMonth})]: [
			moment().subtract(1, 'month').startOf('month'),
			moment().subtract(1, 'month').endOf('month'),
		],
		[intl.formatMessage({...messages.thisMonth})]: [moment().startOf('month'), moment().endOf('day')],
	};

    return (
        <React.Fragment>
            <Form 
                form={form} 
                layout="inline"
                preserve={false}
                onFinish={onSubmit}
            >
                <FormItem
                    name="clientId"
                    label={intl.formatMessage({...messages.client})}
                    rules={[{ required: true }]}
                >
                    <Select
                        style={{width: 300}}
                        showSearch
                        options={[{ value: 0, label: 'Todos' }, ...clientOptions]}
                        optionFilterProp="children"
                        placeholder={intl.formatMessage({ ...messages.client })}
                        filterOption={(input, { label }) => searchInString(input, label)}
                    />
                </FormItem>
                <FormItem
                    name="dateRange"
                    label={intl.formatMessage({...messages.dateRange})}
                    rules={[{ required: true }]}
                >
                    <RangePicker
                        ranges={ranges}
                        showTime={true}
                        disabledDate={(current) => { return current && current > moment().endOf('day'); }}
                    />
                </FormItem>
                <FormItem >
                    <Button 
                        type="primary"
                        htmlType="submit"
                    >
                        <FormattedMessage {...messages.generateReport} />
                    </Button>
                </FormItem>
                {exportButton}
            </Form>
        </React.Fragment>
    )
}

ReportOptions.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	exportButton: PropTypes.object,
};

export default ReportOptions
