export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCT = 'GET_PRODUCT';
export const UPDATED_PRODUCT = 'UPDATED_PRODUCT';
export const GET_PRODUCTS_OF_INVOICE = 'GET_PRODUCTS_OF_INVOICE';
export const GET_PRODUCTS_OF_PROFORMA = 'GET_PRODUCTS_OF_PROFORMA';
export const GET_PRODUCTS_OF_QUOTATION = 'GET_PRODUCTS_OF_QUOTATION';
export const CLEAN_PRODUCTS = 'CLEAN_PRODUCTS';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const CLEAR_SESSION = 'CLEAR_SESSION';
export const CLEAN_REDUCER = 'CLEAN_REDUCER';
export const GET_PRODUCT_CONFIGURATIONS = 'GET_PRODUCT_CONFIGURATIONS';
export const SET_SEARCH_PRODUCTS = 'SET_SEARCH_PRODUCTS';