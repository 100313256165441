import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { injectIntl } from 'react-intl';
import { getClientMessages, getFields, getLanguageIntl } from '../../../../constants/messages';
import moment from 'moment';

let clientMessages = getClientMessages(),
	intlMessages = getLanguageIntl(),
	fieldMessages = getFields();

const messages = {
	...clientMessages,
	...intlMessages,
	...fieldMessages
};

class AccountTable extends React.Component {

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	formatFullDate = (unformatedDate) => {
		if (!unformatedDate) return undefined;
		const { getIntl } = this;
		const locale = getIntl("intl");
		return moment(unformatedDate).locale(locale).format('MMMM DD, YYYY, h:mm a');
	}

	renderTable = () => {
		const { props, getIntl, formatFullDate } = this;
		const { data } = props;
		const dataSource = data.map(account => ({ ...account, key: account.id }));
		const dateFormat = date => date ? formatFullDate(date) : <div>-</div>;
		const columns = [
			{
				title: getIntl("name"),
				dataIndex: 'name',
				key: 'name'
			},
			{
				title: getIntl("firstConfirmation"),
				dataIndex: 'firstDateConfirmation',
				key: 'firstDateConfirmation',
				render: dateFormat
			},
			{
				title: getIntl("dateBefore"),
				dataIndex: 'dateBeforeConfirmation',
				key: 'dateBeforeConfirmation',
				render: dateFormat
			},
			{
				title: getIntl("sameDate"),
				dataIndex: 'sameDateConfirmation',
				key: 'sameDateConfirmation',
				render: dateFormat
			},
		];
		return <Table dataSource={dataSource} columns={columns} pagination={false} size="small" />;
	}

	render() {
		return this.renderTable();
	}
}

AccountTable.defaultProps = {
    data: []
};

AccountTable.propTypes = {
	intl: PropTypes.object.isRequired,
	data: PropTypes.array.isRequired
};

export default injectIntl(AccountTable);