import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Form } from 'antd';

const FormItem = Form.Item;
const moment = require('moment');
const dateFormat = "DD-MM-YYYY";

const FormItemDatePicker = ({ fieldName, label, errorMessage, initialValue, onChange, required, rules, extraRules, componentProps, renderLabel, disabled }) => {

    let formRules = rules ? rules : [{ required: required, message: errorMessage }];
    if (extraRules) {
        formRules.push(extraRules);
    }
    let dateMoment = moment(initialValue).utc();

    return (
        <FormItem
            label={label && renderLabel ? label : null}
            name={fieldName}
            initialValue={dateMoment.isValid() ? dateMoment : null}
            rules={formRules}
        >
            <DatePicker
                onChange={onChange}
                style={{ width: '100%' }}
                placeholder={label ? label : null}
                format={dateFormat}
                disabled={disabled}
                {...componentProps}
            />
        </FormItem>
    );
};

FormItemDatePicker.defaultProps = {
    initialValue: "",
    required: true,
    componentProps: {},
    renderLabel: true
};

FormItemDatePicker.propTypes = {
    fieldName: PropTypes.string.isRequired,
    label: PropTypes.string,
    errorMessage: PropTypes.string,
    initialValue: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    rules: PropTypes.array,
    extraRules: PropTypes.array,
    componentProps: PropTypes.object,
    renderLabel: PropTypes.bool,
    disabled: PropTypes.bool
};

export default FormItemDatePicker;
