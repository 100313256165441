import React from 'react';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import AddAccountForm from '../components/AddAccountForm';
import {injectIntl, FormattedMessage} from 'react-intl';
import {getactionMessages} from '../constants/messages';
import {connect} from 'react-redux';
import {
	OPEN_ADD_MODAL_ACCOUNT,
	CLOSE_ADD_MODAL_ACCOUNT
} from '../constants/actionTypes';
let messages = getactionMessages();



class AddAccountButton extends React.Component  {

	constructor(props) {
		super(props);
	}

	render(){
		return (
            <span>
				<Button className="editable-add-btn" type="primary" onClick={this.props.triggleModal}>
					<PlusOutlined />
					<FormattedMessage {...messages.add} />
				</Button>
				<AddAccountForm
					showModal={this.props.showAddModal}
					handleCancel={this.props.handleCancel}
				/>
			</span>
        );
	}
}

AddAccountButton.propTypes = {
	showAddModal:  PropTypes.bool.isRequired,
	triggleModal:  PropTypes.func.isRequired,
	handleCancel:  PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	return {
		"showAddModal": state.account.showAddModal
	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		triggleModal: () => {
			dispatch({type:OPEN_ADD_MODAL_ACCOUNT});
		},
		handleCancel: () => {
			dispatch({type:CLOSE_ADD_MODAL_ACCOUNT});
		}
	};
};

export default  connect(mapStateToProps, mapDispatchToProps)( injectIntl(AddAccountButton));