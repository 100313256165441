import React from 'react'; 
import PropTypes from 'prop-types';
import {getClientMessages} from '../../../constants/messages';
import {injectIntl, FormattedMessage} from 'react-intl';
import CustomPrice from './DynamicComponents/CustomPrice';
import AddButton from './DynamicComponents/AddButton';
import { getObjectInArray, existsInArray } from '../../../utilities/util';
import { Select, Row, Col } from 'antd'; 
const Option = Select.Option;
let cloneDeepWith = require('lodash.clonedeepwith');
 
let ClientMessages = getClientMessages(); 
 
const messages = {
  ...ClientMessages 
};

const defaultCustomPrice = {
    productId: 0,
    price: 0
};

class CustomPrices extends React.Component {

    resetFields = () => {
        const {form} = this.props;
        form.resetFields();
    }
     
    addCustomPrice = () => {
        let { customPrices, setState } = this.props;
        let defaultClone = cloneDeepWith(defaultCustomPrice);
        customPrices.push(defaultClone);
        setState({customPrices});
        this.resetFields();
    }
   
    removeCustomPrice = (customPriceIndex) => {
        let { customPrices, setState } = this.props;
        customPrices.splice(customPriceIndex, 1);
        setState({customPrices});
        this.resetFields();
    }

    saveProduct = (productId, customPriceIndex) => {
        if (!productId) return;
        let { customPrices, setState, isLps, exchangeRate } = this.props;
        let product = this.productToObject(productId);
        customPrices[customPriceIndex].productId = productId;
        customPrices[customPriceIndex].price = isLps ? (product.price * exchangeRate).toFixed(4) : product.price;
        customPrices[customPriceIndex].product = product;
        this.resetFields();
        setState({customPrices});
    }

    savePrice = (price, customPriceIndex) => {
        let { customPrices, setState } = this.props;
        customPrices[customPriceIndex].price = price;
        setState({customPrices});
    }

    productToObject = (productId) => {
        const {products} = this.props;
        return getObjectInArray('id', parseInt(productId), products);
    }

    customPriceListRender = () => {
        const { form, customPrices, isLps, products } = this.props;
        const { saveProduct, removeCustomPrice, savePrice } = this;
        let productsAlreadyInUse = customPrices.map(cp => cp.productId);
        let productOptions = products.map(product => {
            if (!existsInArray(product.id, productsAlreadyInUse)) return (<Option key={product.id} value={product.id}>{product.code + " - " + product.description}</Option>);
        })
        let returnRenders = customPrices.map((customPrice, index) => {
            let productOptionsCopy = [...productOptions];
            if (customPrice.productId !== 0 && customPrice.product !== undefined)
                productOptionsCopy.push(<Option key={customPrice.productId} value={customPrice.productId}>{customPrice.product.code + " - " + customPrice.product.description}</Option>);
            return (
                <CustomPrice key={index} form={form} customPrice={customPrice} customPriceIndex={index} isLps={isLps} productOptions={productOptionsCopy} 
                    saveProductFunction={saveProduct} removeCustomPriceFunction={removeCustomPrice} savePriceFunction={savePrice}/>
            );
        })
        return (returnRenders);
    }

    renderHeaders = () => {
        if (this.props.customPrices.length > 0)
            return (
                <Row>
                <Col span={16}>
                    <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>
                        <FormattedMessage {...messages.clientProduct} />
                    </span>
                </Col>
                <Col span={7}>
                    <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>
                        <FormattedMessage {...messages.price} />
                    </span>
                </Col>
                </Row>
            );
    }

    fullRender = () => {
        const {intl} = this.props;
        const { renderHeaders, customPriceListRender, addCustomPrice } = this;
        return (
            <div>
                {renderHeaders()}
                {customPriceListRender()}
                <AddButton text={intl.formatMessage({...messages.addCustomPrice})} addFunction={addCustomPrice}/>
            </div>
        );
    }
     
    render() {
        return ( 
            this.fullRender()
        ); 
    } 

}
 
CustomPrices.defaultProps = { 
    customPrices: [],
    isLps: false,
    products: [],
    exchangeRate: 1
};
 
CustomPrices.propTypes = {
	intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    customPrices: PropTypes.array.isRequired,
    isLps: PropTypes.bool.isRequired,
    exchangeRate: PropTypes.number.isRequired,
    products: PropTypes.array.isRequired,
};

export default injectIntl(CustomPrices);