
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getProductMessages, getProformaMessages, getModalMessages, getactionMessages, getErrorMessages, getPaymentMessages, getClientMessages, getInvoicingMessages } from '../../constants/messages';
import { Spin, Badge, message, Button } from 'antd';
import { GetProductsOfProforma } from '../Products/actions';
import { GetEmails } from '../Clients/actions';
import { GetProforma, PrintProforma, EmailProforma, PreviewProforma } from './actions';
import { injectIntl, FormattedMessage } from 'react-intl';
import { arrayElementContainedInArray } from '../../utilities/util';
import EmailSelector from '../../components/EmailSelector';
import ShowInvoicingDocument from '../../components/ShowInvoicingDocument';
import { STATUSES } from './constants';
import { ROLES } from '../../constants/global';


let invoicingMessages = getInvoicingMessages(),
	proformaMessages = getProformaMessages(),
	errorMessages = getErrorMessages(),
	actionMessages = getactionMessages(),
	ProductMessages = getProductMessages(),
	modalMessages = getModalMessages(),
	paymentMessages = getPaymentMessages(),
	clientMessages = getClientMessages();

const messages = {
	...invoicingMessages,
	...proformaMessages,
	...modalMessages,
	...actionMessages,
	...ProductMessages,
	...errorMessages,
	...paymentMessages,
	...clientMessages
};

class ShowProforma extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			subtotal: 0,
			isLoading: true,
			showEmailModal: false,
		};
	}

	componentDidMount() {
		this.props.getProforma(this.props.match.params.id).then(() => {
			this.props.getProductsOfProforma(this.props.match.params.id).then(() => {
				this.setState({ isLoading: false });
			}).catch(() => {
				message.error(`${this.props.intl.formatMessage({ ...messages.GetProductsOfProformaError })}`);
				this.setState({ isLoading: false });
			});
			this.props.getEmails(this.props.proforma.clientId).catch(() => {
				message.error(`${this.props.intl.formatMessage({ ...messages.getClientEmailsError })}`);
				this.setState({ isLoading: false });
			});
		}).catch(() => {
			message.error(`${this.props.intl.formatMessage({ ...messages.GetProformaError })}`);
			this.setState({ isLoading: false });
		});
	}

	openEmailModal = () => {
		this.setState({
			showEmailModal: true
		});
	}

	closeEmailModal = () => {
		this.setState({
			showEmailModal: false
		});
	}

	cancelledReason = () => {
		if (this.props.proforma.status === STATUSES.DENIED) {
			return (
				<React.Fragment>
					<h3><FormattedMessage {...messages.Reason} /></h3>
					{this.props.proforma.reasonRejection}
				</React.Fragment>
			);
		}
	}

	renderStatus = (status) => {
		let color = 'processing';
		let text = this.props.intl.formatMessage({ ...messages.ReadyInvoicing });
		if (status === STATUSES.NEEDS_APPROVAL) {
			color = 'warning';
			text = this.props.intl.formatMessage({ ...messages.NeedsApproval });
		} else if (status === STATUSES.INVOICED) {
			color = 'success';
			text = this.props.intl.formatMessage({ ...messages.Invoiced });
		} else if (status === STATUSES.DENIED) {
			color = 'error';
			text = this.props.intl.formatMessage({ ...messages.Denied });
		}
		return (
			<h1><FormattedMessage {...messages.ProformaNum} /> {this.props.proforma.id} | <Badge status={color} text={text} /></h1>
		);
	}

	renderExtraButtons = () => {
		const { props } = this;
		const { proforma, userRoles } = props;
		const { id: proformaId, status = -1 } = proforma;
		const editableStatus = [STATUSES.READY_FOR_INVOICING];
		const allowedRoles = [ROLES.MASTER, ROLES.INVOICEEDITOR];
		const showEditButton = arrayElementContainedInArray(allowedRoles, userRoles) && editableStatus.includes(status)
		return showEditButton &&
			<Button key="edit" size="large" onClick={() => this.props.history.push(`/Proformas/${proformaId}/edit`)} className="button-spacing">
				{this.props.intl.formatMessage({ ...messages.edit })}
			</Button>
	}

	render() {
		return (
			<Spin spinning={this.state.isLoading}>
				<div style={{ padding: '25px' }}>
					<EmailSelector
						addOrShow={false}
						showEmailModal={this.state.showEmailModal}
						closeModal={this.closeEmailModal}
						document={this.props.proforma}
						emailDocument={this.props.emailProforma}
						downloadPreview={() => PreviewProforma(this.props.match.params.id)}
						downloadDocument={() => this.props.printProforma(this.props.match.params.id)}
					/>
					<ShowInvoicingDocument
						document={this.props.proforma}
						products={this.props.productsOfCurrentProforma}
						deniedStatuses={[1, 3]}
						renderStatus={this.renderStatus}
						cancelledReason={this.cancelledReason}
						openEmailModal={this.openEmailModal}
						extraButtons={this.renderExtraButtons()}
						printDocument={this.props.printProforma}
						goTo={() => this.props.history.push(`/proformas`)}
						messages={messages}
					/>
				</div>
			</Spin>
		);
	}
}

ShowProforma.propTypes = {
	intl: PropTypes.object.isRequired,
	productsOfCurrentProforma: PropTypes.array.isRequired,
	user: PropTypes.object.isRequired,
	proforma: PropTypes.object.isRequired,
	match: PropTypes.shape({
		params: PropTypes.object.isRequired
	}).isRequired,
	getProductsOfProforma: PropTypes.func.isRequired,
	getProforma: PropTypes.func.isRequired,
	printProforma: PropTypes.func.isRequired,
	getEmails: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	emailProforma: PropTypes.func.isRequired,
	userRoles: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		proforma: state.proforma.proforma,
		productsOfCurrentProforma: state.product.productsOfCurrentProforma,
		userRoles: state.auth.roles,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getProductsOfProforma: (proformaId) => {
			return dispatch(GetProductsOfProforma(proformaId));
		},
		getProforma: (id) => {
			return dispatch(GetProforma(id));
		},
		printProforma: (id) => {
			return dispatch(PrintProforma(id));
		},
		emailProforma: (id, emails, replyTo, message) => {
			return dispatch(EmailProforma(id, emails, replyTo, message));
		},
		getEmails: (id) => {
			return dispatch(GetEmails(id));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(ShowProforma)));
