import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Spin } from 'antd';
import { injectIntl } from 'react-intl';
import { getModalMessages, getClientMessages } from '../../../../constants/messages';
import LeasedDevicesForm from './Form';

let modalMessages = getModalMessages(),
    clientMessages = getClientMessages();

const messages = {
	...modalMessages,
	...clientMessages
};

class LeasedDevicesModal extends React.Component  {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: false
        };
	}

    setLoading = (isLoading) => {
        this.setState({ isLoading });
    }

	getIntl = (str) => {
		return this.props.intl.formatMessage({...messages[str]});
    }

	cancel = () => {
		const { handleCancel } = this.props;
		this.setState({ isLoading: false });
		handleCancel();
	}

	renderedButtons = () => {
        const { state, cancel, getIntl } = this;
		const { isLoading } = state;
        return [
            <Button key="close" loading={isLoading} onClick={cancel}>
                {getIntl("close")}
            </Button>
        ];
    }

	render(){
		const { state, props, cancel, renderedButtons, getIntl, setLoading } = this;
		const { isLoading } = state;
		const { showModal, clientId } = props;
		return (
			<Modal
				open={showModal}
				title={getIntl("devices")}
				onCancel={cancel}
				destroyOnClose={true}
				footer={renderedButtons()}
				maskClosable={false}
				keyboard={!isLoading}
				closable={!isLoading}
				width={900}
            >
				<Spin spinning={isLoading}>
					<LeasedDevicesForm clientId={clientId} setLoading={setLoading} errorClose={cancel}/>
				</Spin>
            </Modal>
		);
	}
}

LeasedDevicesModal.defaultProps = {
	clientId: 0
};

LeasedDevicesModal.propTypes = {
	intl: PropTypes.object.isRequired,
	
	showModal:  PropTypes.bool.isRequired,
	clientId: PropTypes.number.isRequired,
	handleCancel:  PropTypes.func.isRequired,
};

export default injectIntl(LeasedDevicesModal);