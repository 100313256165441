import React from 'react';
import PropTypes from 'prop-types';
import { message, Spin, Button, Row, Col } from 'antd';
import { injectIntl } from 'react-intl';
import { getClientMessages, getFields, getPopConfirm } from '../../../../../constants/messages';
import { CheckIfClientHasAssignedDeactivationDate } from '../../../actions';
import moment from 'moment';

let clientMessages = getClientMessages(),
	fieldMessages = getFields(),
	popConfirmMessages = getPopConfirm();

const messages = {
	...clientMessages,
	...fieldMessages,
	...popConfirmMessages
};

class GPDView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			showMessageDate: false
		};
	}

	componentDidMount() {
		const { props, getIntl, setLoading } = this;
		const { clientId, closeModal } = props;
		setLoading(true);
		CheckIfClientHasAssignedDeactivationDate(clientId)
			.then(showMessageDate => {
				if (showMessageDate) this.setState({ showMessageDate, isLoading: false });
				else closeModal();
			})
			.catch(() => {
				setLoading(false);
				message.error(getIntl("checkClientDeactivationDateError"));
				closeModal();
			});
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });

	renderQuestion = () => {
		const { state, getIntl } = this;
		const { showMessageDate } = state;
		return showMessageDate ? getIntl("checkClientDeactivationDateQuestion", { date: moment(showMessageDate).utc().format('DD-MM-YYYY') }) : null;
	}

	renderButtons = () => {
		const { props, getIntl } = this;
		const { closeModal, triggerDateChange } = props;
		return (
			<Row style={{ marginTop: "10px" }}>
				<Col span={11}><Button key="no" block onClick={closeModal}>{getIntl("no")}</Button></Col>
				<Col span={11} offset={2}><Button key="yes" type="primary" block onClick={triggerDateChange}>{getIntl("yes")}</Button></Col>
			</Row>
		)
	}

	fullRender = () => {
		const { state, getIntl, renderQuestion, renderButtons } = this;
		const { isLoading } = state;
		const spinProps = { spinning: isLoading };
		if (isLoading) spinProps.tip = getIntl("checkingProgrammedDeactivation");
		return (
			<Spin {...spinProps}>
				{isLoading ? <div></div> :
					<div>
						{renderQuestion()}
						{renderButtons()}
					</div>
				}
			</Spin>
		);
	}

	render() {
		return this.fullRender();
	}
}

GPDView.propTypes = {
	intl: PropTypes.object.isRequired,
	triggerDateChange: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
	clientId: PropTypes.number.isRequired
};

export default injectIntl(GPDView);