import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getProductMessages, getProformaMessages, getModalMessages, getactionMessages, getErrorMessages, getClientMessages } from '../../constants/messages';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Checkbox, message, DatePicker, Spin } from 'antd';
import EmailSelector from '../../components/EmailSelector';
import { UpdateProforma, AddProforma, PrintProforma, EmailProforma, PreviewUnregisteredProforma } from './actions';
import { injectIntl, FormattedMessage } from 'react-intl';
import { roundN, isEmpty, numToWords } from '../../utilities/util';
import AddInvoicingDocumentForm from '../../components/AddInvoicingDocumentForm';
import { GetProductsOfProforma } from '../Products/actions';
import { MarkJobsProforma } from '../Jobs/actions';
import Mayre from 'mayre';

const FormItem = Form.Item;
let moment = require('moment');
const { MonthPicker } = DatePicker;


let proformaMessages = getProformaMessages(),
	errorMessages = getErrorMessages(),
	ProductMessages = getProductMessages(),
	actionMessages = getactionMessages(),
	modalMessages = getModalMessages(),
	clientMessages = getClientMessages();

const messages = {
	...proformaMessages,
	...modalMessages,
	...ProductMessages,
	...actionMessages,
	...errorMessages,
	...clientMessages
};

class AddProformaForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			taxExemption: this.props.match.params.id ? this.props.proforma.taxExemption : false,
			showEmailModal: false,
			isProgrammed: this.props.match.params.id ? this.props.proforma.isProgrammed : false,
			proforma: {},
			products: [],
			addedProducts: [],
			deleteProducts: [],
			editedProducts: [],
		};
	}

	getProducts = async (id) => {
		try {
			const productsOfCurrentInvoice = await this.props.getProductsOfProforma(id);
			this.setState({ products: productsOfCurrentInvoice.map(product => ({ ...product, isNew: false })) });
			return this.state.products;
		} catch (error) {
			message.error(`${this.props.intl.formatMessage({ ...messages.getProformaProductsError })}`);
			this.setState({ isLoading: false });
			return [];
		}
	}

	checkEditProduct = (product) => {
		const { editedProducts, addedProducts } = this.state;
		const { index, isNew, key } = product;
		isNew ? this.editProduct(product, addedProducts, index, isNew) : this.editProduct(product, editedProducts, key, isNew);
	};

	editProduct = (product, products, value, isNew) => {
		if (products.length) {
			const productExists = products.find(x => (isNew ? x.index == value : x.key == value));
			if (productExists) {
				const productIndex = products.indexOf(productExists);
				products.splice(productIndex, 1, product);
			} else { products.push(product); }
		} else if (!isNew) { products.push(product) }
		isNew ? this.setState({ addedProducts: products }) : this.setState({ editedProducts: products });
	}

	getDeleteProduct = (product) => {
		const { editedProducts, addedProducts } = this.state;
		const { index, isNew, key } = product;
		if (isNew) {
			addedProducts.splice(index, 1);
			this.setState({ addedProducts });
			return;
		}
		const productExists = editedProducts.find(x => (x.key == key));
		if (productExists) {
			const productIndex = editedProducts.indexOf(productExists);
			editedProducts.splice(productIndex, 1);
			this.setState({ editedProducts });
		}
		const deleted = { ...product };
		this.setState(prevState => ({ deleteProducts: [...prevState.deleteProducts, deleted] }));
	};

	getAddedProducts = (product) => {
		const { addedProducts } = this.state;
		const index = addedProducts.length;
		const newProduct = { ...product, isNew: true, index };
		this.setState(prevState => ({ addedProducts: [...prevState.addedProducts, newProduct] }));
		return newProduct;
	};

	disabledDate = (current) => {
		return current < moment().startOf('month');
	}

	closeEmailModal = () => {
		this.setState({
			showEmailModal: false
		});
	}

	onChangeCheckBox = () => {
		this.setState({
			taxExemption: !this.state.taxExemption
		});
	}

	onChangeCheckBoxIsProgrammed = () => {
		this.setState({
			isProgrammed: !this.state.isProgrammed
		});
	}

	handleSubmit = (email, state) => {
		const { addedProducts, editedProducts, deleteProducts } = this.state;
		const proFormaId = this.props.match.params.id;
		this.props.form.validateFields((err, values) => {
			if (err) {
				return;
			}
			const { clientAlias } = values;

			let needsApproval = false;

			state.products.forEach((product) => {
				if (product.approvalNeeded) {
					needsApproval = true;
				}
			});

			values = {
				...values,
				accountId: this.props.user.id,
				status: needsApproval ? 1 : 0,
				total: this.state.taxExemption ? state.subtotal : state.total,
				subtotal: state.subtotal,
				createdAt: moment(),
				daysForPayment: state.isCredit ? values.daysForPayment : 0,
				products: state.products,
				needsApproval: needsApproval,
				observations: isEmpty(values.observations) ? "" : values.observations,
				taxExemption: this.state.taxExemption ? 1 : 0,
				isProgrammed: this.state.isProgrammed ? 1 : 0,
				ProgrammedDate: this.state.isProframmed ? moment().startOf('month') : moment(),
				clientName: !state.variousClients ? (clientAlias && clientAlias.length > 0 ? clientAlias : this.props.currentClientName) : values.clientName,
				clientRtn: !state.variousClients ? this.props.currentRTN : values.clientRtn,
				representative: !state.variousClients ? this.props.currentRepresentative : values.representative,
				address: !state.variousClients ? this.props.fullAddress.address : values.address,
				city: !state.variousClients ? this.props.fullAddress.city : values.city,
				state: !state.variousClients ? this.props.fullAddress.state : values.state,
				isv: this.props.isv
			};

			if (state.products.length === 0) {
				message.error(this.props.intl.formatMessage({ ...messages.emptyProducts }));
				return;
			}

			this.setState({ isLoading: true });

			if (proFormaId) {
				values = {
					...values,
					products: { newProducts: addedProducts, editProducts: editedProducts, deleteProducts },
				}
				UpdateProforma(proFormaId, values)
					.then(() => this.successUpdate())
					.catch(() => {
						message.error(`${this.props.intl.formatMessage({ ...messages.proformaUpdateError })}`);
					});
			} else {
				if (email) {
					if (values.needsApproval)
						message.warning(this.props.intl.formatMessage({ ...messages.CantEmail }));
					else {
						this.setState({
							showEmailModal: true,
							isLoading: false,
							proforma: { ...values }
						});
					}
				} else {
					this.setState({ isLoading: true });
					this.submitProforma(values).then(id => {
					if(state.checkInJobs) MarkJobsProforma(state.jobsId, id);
						this.props.history.push(`/proformas/${id}`);
					});
				}
			}
		});
	}

	successUpdate = () => {
		this.setState({ isLoading: false });
		message.success(`${this.props.intl.formatMessage({ ...messages.proformaUpdateSuccess })}`);
		this.props.history.push(`/proformas/${this.props.match.params.id}`);
	}

	submitProforma = (values) => {
		return this.props.addProforma(values).then(() => {
			if (!values.needsApproval)
				this.props.printProforma(this.props.proforma.id, this.props.proforma.id.toString(), numToWords(roundN(values.total * values.exchangeRate, 2))).catch(() => {
					message.error(`${this.props.intl.formatMessage({ ...messages.PrintError })}`);
				});
			return this.props.proforma.id;
		}).catch(() => {
			message.error(`${this.props.intl.formatMessage({ ...messages.AddProformaError })}`);
			this.setState({
				isLoading: false
			});
		});
	}

	handleCancel = () => {
		this.props.history.push(`/proformas`);
	}

	render() {
		const { taxExemption, isProgrammed } = this.state;
		const { match, proforma } = this.props;
		const { getFieldDecorator } = this.props.form;
		let extraFormItems = (
			<Row gutter={16} type="flex" align="middle" style={{ 'height': '100px' }}>
				<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }}>
					<Checkbox onChange={this.onChangeCheckBox} defaultChecked={taxExemption}><FormattedMessage {...messages.TaxExemption} /></Checkbox>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 3 }}>
					<Checkbox onChange={this.onChangeCheckBoxIsProgrammed} defaultChecked={isProgrammed} ><FormattedMessage {...messages.IsProgrammed} /></Checkbox>
				</Col>
				<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 3 }}>
					<Mayre
						of={
							<FormItem label={this.props.intl.formatMessage({ ...messages.DateProgrammed })}>
								{getFieldDecorator('programmedDate', {
									rules: [{
										required: this.state.isProgrammed, message: this.props.intl.formatMessage({ ...messages.dateEmptyError }),
									}],
									initialValue: match.params.id ? moment(proforma.programmedDate, 'YYYY-MM') : moment().add(1, 'months').startOf('month')
								})(
									<MonthPicker disabledDate={this.disabledDate} placeholder={this.props.intl.formatMessage({ ...messages.Date })} />
								)}
							</FormItem>
						} when={this.state.isProgrammed}
					/>
				</Col>
			</Row>
		);
		return (
			<Form>
				<EmailSelector
					addOrShow={true}
					showEmailModal={this.state.showEmailModal}
					closeModal={this.closeEmailModal}
					addDocument={this.submitProforma}
					document={this.state.proforma}
					goTo={(id) => { this.props.history.push(`/proformas/${id}`); }}
					emailDocument={this.props.emailProforma}
					downloadPreview={() => PreviewUnregisteredProforma(this.state.proforma)}
				/>
				<Spin spinning={this.state.isLoading}>
					<AddInvoicingDocumentForm
						document={match.params.id !== undefined ? proforma : undefined}
						extraFormItems={extraFormItems}
						handleSubmit={this.handleSubmit}
						handleCancel={this.handleCancel}
						getProducts={this.getProducts}
						editProduct={this.checkEditProduct}
						getDeleteProduct={this.getDeleteProduct}
						getAddedProducts={this.getAddedProducts}
						getFieldDecorator={this.props.form.getFieldDecorator}
						taxExemption={this.state.taxExemption}
						resetFields={this.props.form.resetFields}
					/>
				</Spin>
			</Form>
		);
	}
}

AddProformaForm.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	proforma: PropTypes.object.isRequired,
	currentClientName: PropTypes.string,
	currentRTN: PropTypes.string,
	currentRepresentative: PropTypes.string,
	customPrices: PropTypes.array,
	exchangeRate: PropTypes.object,
	fullAddress: PropTypes.object,
	currentIsLps: PropTypes.bool,
	addProforma: PropTypes.func.isRequired,
	printProforma: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	isv: PropTypes.number.isRequired,
	emailProforma: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.object.isRequired
	}).isRequired,
	getProductsOfProforma: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		proforma: state.proforma.proforma,
		currentClientName: state.client.currentClientName,
		currentRTN: state.client.currentRTN,
		currentRepresentative: state.client.currentRepresentative,
		exchangeRate: state.exchangeRate.exchangeRate,
		customPrices: state.client.customPrices,
		currentIsLps: state.client.currentIsLps,
		fullAddress: state.client.fullAddress,
		isv: state.invoicing.isv,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getProductsOfProforma: (proformaId) => {
			return dispatch(GetProductsOfProforma(proformaId));
		},
		addProforma: (newProforma) => {
			return dispatch(AddProforma(newProforma));
		},
		printProforma: (id, docNum, amountInWords) => {
			return dispatch(PrintProforma(id, docNum, amountInWords));
		},
		emailProforma: (id, emails, replyTo, message) => {
			return dispatch(EmailProforma(id, emails, replyTo, message));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(Form.create()(AddProformaForm))));
