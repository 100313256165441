import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import { getFields, getPayrollMessages } from '../../../../constants/messages';
import { injectIntl } from 'react-intl';

const FormItem = Form.Item;

let fieldMessages = getFields(),
    payrollMessages = getPayrollMessages();

const messages = {
    ...payrollMessages,
    ...fieldMessages
};

class Deduction extends React.Component {

	getIntl = (str) => {
		return this.props.intl.formatMessage({...messages[str]});
	}

	renderNameField = () => {
		const { props, getIntl } = this;
        const { form, name } = props;
		const { getFieldDecorator } = form;
		return (
            <FormItem label={getIntl("name")}>
            {
                getFieldDecorator('name', 
                    {
                        initialValue: name,
                        rules: [{
                            required: true,
                            message: getIntl("deductionNameError"), 
                            whitespace: true,
                        }]
                    }
                )(
                    <Input placeholder={getIntl("name")}/>
                )
            }
            </FormItem>
		);
	}

    render() {
        return this.renderNameField(); 
    }

}

Deduction.defaultProps = { 
    name: ""
};

Deduction.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired
};

export default injectIntl(Deduction);