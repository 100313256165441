import React, { useState } from 'react';
import {FormattedMessage} from 'react-intl';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AddRequestModal from './AddRequestModal';
import {getactionMessages} from '../../constants/messages';

let messages = getactionMessages();


const AddRequestButton = () => {
    const [openModal, setOpenModal] = useState(false);
	
	return (
		<React.Fragment>
			<Button 
				className="editable-add-btn" 
				type="primary" 
				onClick={() => setOpenModal(true)}
				icon={<PlusOutlined />}
			>
				<FormattedMessage {...messages.add} />
			</Button>
			<AddRequestModal
				visible={openModal}
				handleCancel={() => setOpenModal(false)}
			/>
		</React.Fragment>
	);
}

export default AddRequestButton