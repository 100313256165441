import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Modal, Col, Row, Form, Checkbox, Typography, Table, Card, Button, Steps, Select } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { getUnitsMessages, getactionMessages, getFields, getModalMessages, getErrorMessages, getModelsMessages } from '../../constants/messages';
import { CheckCircleFilled, FileExcelOutlined } from '@ant-design/icons';
import { DownloadNotAdded, DownloadUnitsTemplate, HandleUploadFile } from './actions';
import FormItemUpload from '../GlobalComponents/FormItemUpload';

const FormItem = Form.Item;
let UnitsMessages = getUnitsMessages(),
	ActionMessages = getactionMessages(),
	FieldMessages = getFields(),
	ModalMessages = getModalMessages(),
	ErrorMessages = getErrorMessages(),
	ModelMessages = getModelsMessages();

const { Paragraph } = Typography;
const Option = Select.Option;
 
const messages = {
	...UnitsMessages,
	...ActionMessages,
	...FieldMessages,
	...ModalMessages,
	...ErrorMessages,
	...ModelMessages,
}

const UploadFileModal = ({onCancel, showModal, updateUnits, models}) => {
	const intl = useIntl();
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [notAdded, setNotAdded] = useState([]);
	const [current, setCurrent] = useState(0);
    const [hasHeaders, setHasHeaders] = useState(true);
	const [duplicates, setDuplicates] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);

	const handleDownloadUnitsTemplate = () => dispatch(DownloadUnitsTemplate());
	const handledDownloadNotAdded = () => dispatch(DownloadNotAdded(notAdded));

	const displayPin = (text) => <Paragraph ellipsis={{ tooltip: true }} style={{ width: 15 }}>{`${text}`}</Paragraph>;
	const displayImei = (text) => (	<Paragraph ellipsis={{ tooltip: true }} style={{ width: 15 }}>	{text ? `${text}` : ''}	</Paragraph> );
	const displayError = (text) => ( <Paragraph ellipsis={{ tooltip: true }} style={{ width: 23 }}>{`${text}`}</Paragraph> );

	const modelsOptions = models.map((model) => (
        <Option key={model.id} value={model.id}>
            {model.model}
        </Option>
	));

	const next = () => setCurrent(current + 1);
 
	const onOk = async () => {
		try {
			const values = await form.validateFields();
			setConfirmLoading(true);
			const response = await dispatch(HandleUploadFile(values, updateUnits));
			next();
			setHasHeaders(true);
			setDuplicates(false);
			setNotAdded(response.notAdded);
		} catch (error) {
			setCurrent(0);
		} finally {
			setConfirmLoading(false);
		}
	};

	const columns = [
		{
			title: intl.formatMessage({ ...messages.description}),
			dataIndex: 'description',
			key: 'description',
		},
		{
			title: intl.formatMessage({ ...messages.pin}),
			dataIndex: 'pin',
			key: 'pin',
			render: displayPin,
		},
		{
			title: intl.formatMessage({ ...messages.imei}),
			dataIndex: 'imei',
			key: 'imei',
			render: displayImei,
		},
		{
			title: 'Error',
			dataIndex: 'error',
			key: 'error',
			render: displayError,
		},
	];

    const stepsContent = [
		{
            content: (
				<Form layout='vertical' form={form} preserve={false}>
					<Row type="flex" justify="space-between">
						<Col span={24}>
						<FormItem 
                            name="modelId"
                            label={intl.formatMessage({ ...messages.model })} 
                            rules={[
                                {
                                    required: true, 
                                    message: intl.formatMessage({...messages.modelError}) 
                                }
                            ]} 
                        >
                            <Select
                                showSearch
                                placeholder={intl.formatMessage({ ...messages.modelPlaceholder })}
                                optionFilterProp="children"
                            >
                                {modelsOptions}
                            </Select>
                        </FormItem>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<FormItemUpload
                                intl={intl}
								form={form}
								fieldName={'file'}
								label={intl.formatMessage({...messages.file})}
								required={true}
								accept={'.csv, .xlsx'}
								listType={'text'}
								dragger={true}
							/>
						</Col>
					</Row>
					<Row type="flex" justify="space-between">
						<Col span={9}>
							<FormItem name="hasHeaders" valuePropName="checked"	initialValue={hasHeaders}>
								<Checkbox checked={hasHeaders} onChange={(e) => setHasHeaders(e.target.checked)} >
									<FormattedMessage {...messages.hasHeaders} />
								</Checkbox>
							</FormItem>
						</Col>
						<Col span={9}>
							<FormItem name="hasDuplicates" valuePropName="checked" initialValue={duplicates}>
								<Checkbox checked={duplicates} onChange={(e) => setDuplicates(e.target.checked)} >
									<FormattedMessage {...messages.replaceDuplicates} />
								</Checkbox>
							</FormItem>
						</Col>
						<Col span={4}>
							<FormItem>
								<Typography.Link onClick={handleDownloadUnitsTemplate} >
									<FileExcelOutlined /> {intl.formatMessage({...messages.template})}
								</Typography.Link>
							</FormItem>
						</Col>
					</Row>
				</Form>
			),
			title: intl.formatMessage({...messages.upload}),
		},
		{
			content:
				notAdded.length !== 0 ? (
					<div>
						<div style={{ textAlign: 'right', paddingTop: 20 }}>
							<Typography.Link onClick={handledDownloadNotAdded}>
								<FileExcelOutlined /> {intl.formatMessage({...messages.download})}
							</Typography.Link>
						</div>
						<Table
							rowKey={(record) => record.key}
							dataSource={notAdded}
							columns={columns}
							size={'small'}
							pagination={{
								pageSize: 5,
								showTotal: (total) => intl.formatMessage({...messages.tableTotal}, {total}),
							}}
						/>
					</div>
				) : (
					<Card bordered={false}>
						<Row type="flex" justify="center">
							<Col span={24} className="uploadContent">
								<CheckCircleFilled className="uploadIcon" />
							</Col>
							<Col span={24} className="uploadContent">
								<h2>
									<FormattedMessage {...messages.uploadSuccess} />
								</h2>
								<p>
									<FormattedMessage {...messages.uploadSuccessMessage} />
								</p>
							</Col>
						</Row>
					</Card>
				),
			title: intl.formatMessage({...messages.uploadResult}),
		},
    ];

    const closeModal = () => {
		setCurrent(0);
		setNotAdded([]);
		onCancel();
	};

	let buttonGroup =
		current === 0 ? (
			<Button key="next" loading={confirmLoading} type="primary" onClick={onOk}>
				{intl.formatMessage({...messages.uploadFileButton})}{' '}
			</Button>
		) : (
			<Button key="done" type="primary" onClick={closeModal}>
				{intl.formatMessage({...messages.close})}
			</Button>
		);

    return (
        <Modal
            title={intl.formatMessage({...messages.upload})}
            open={showModal}
            onCancel={closeModal}
            destroyOnClose={true}
			confirmLoading={confirmLoading}
			footer={buttonGroup}
        >
			<Steps current={current} items={stepsContent} />
		    <div className="steps-content">{stepsContent[current].content}</div>
        </Modal>
    )
}

UploadFileModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
	showModal: PropTypes.bool,
	updateUnits: PropTypes.func,
	models: PropTypes.array
};

export default UploadFileModal