import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import { getPayrollMessages, getFields } from '../../../../constants/messages';
const FormItem = Form.Item;

let payrollMessages = getPayrollMessages(),
	fieldMessages = getFields();

const messages = {
	...payrollMessages,
	...fieldMessages
};

class EditableBonusNameField extends React.Component {

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	renderNameField = () => {
		const { props, getIntl } = this;
		const { form, fieldName } = props;
		const { getFieldDecorator } = form;
		const maxLength = 255;
		return (
			<FormItem className="job-full-component">
				{
					getFieldDecorator(fieldName,
						{
							rules: [
								{
									required: true,
									message: getIntl("bonusNameRequiredError"),
								},
								{
									max: maxLength,
									message: getIntl("bonusNameLengthError", { max: maxLength })
								}
							]
						}
					)(
						<Input placeholder={getIntl("bonusName")} />
					)
				}
			</FormItem>
		);
	}

	fullRender = () => {
		const { renderNameField } = this;
		return (
			<div className="payroll-remove-margin-bottom">
				{renderNameField()}
			</div>
		)
	}

	render() {
		return this.fullRender();
	}
}

EditableBonusNameField.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	fieldName: PropTypes.string.isRequired
};

export default injectIntl(EditableBonusNameField);