
import en_US from 'antd/lib/locale-provider/en_US'; //EN
import es_ES from 'antd/lib/locale-provider/es_ES'; //ES
import {en_messages, es_messages} from '../int18/';

// API URL
export const API_BASE_URL = process.env.API_BASE_URL;

// Amazon s3
export const S3_BUCKET = process.env.S3_BUCKET;

// One month in seconds
export const ONE_MONTH_IN_SECONDS = 2592000;

// Rabbit Server
export const ENVIRONMENT = process.env.ENVIRONMENT;

// regex
export const REGEX = {
	email:  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	username: /^[a-zA-Z]([._][A-Za-z0-9]|[A-Za-z0-9]){4,14}$/,
	number: /^[0-9]+$/,
	imei: /^[0-9]{15}$/,
	alphaNumeric: /^[a-zA-Z0-9]*$/,
	phone: /^(\+){0,1}[0-9\s]{1,20}$/,
	pin: /^[a-zA-Z0-9]+[-_a-zA-z0-9]+$/,
	password: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).+/,
	rtn: /^\d{14}$/,
	idRTN: /^\d{13,14}$/,
	cai: /^[A-Z0-9]{6}-[A-Z0-9]{6}-[A-Z0-9]{6}-[A-Z0-9]{6}-[A-Z0-9]{6}-[A-Z0-9]{2}$/,
	invoicePrefix: /^\d{3}-\d{3}-\d{2}$/,
	specialCharacters: /[&/\\#,+\-()$~%.'":*?<>{}[\]]/g,
	whitespace: /\S/
};

// Languages
export const locale = {
	"en_US": {
		'language': en_US,
		intl: "en",
		messages: en_messages
	},
	"es_ES": {
		'language': es_ES,
		intl: "es",
		messages: es_messages
	}
};

export const ROLES = {
	MASTER: 1,
	ADMINISTRATOR: 2,
	SALES: 3,
    TECHSUPPORT: 4,
	CUSTOMERSERVICE: 5,
	INCOMESEXPENSES:  6,
	GPSTRANSFERACTIVATION:  7,
	EMPLOYEES:  8,
	PAYROLLS:  9,
	INVOICEEDITOR:  10,
	INVENTORYASSISTANT: 11,
};

export const reportsEnum = {
	GENERAL: 0,
	CLIENTS: 1,
	DETAIL: 2,
};

export const IRONHIDE_SMOVILGPS_CLIENT_ID = 1;
export const OPTIMUS_SMOVILGPS_CLIENT_ID = 21;
export const OPTIMUS_SMOVILGPS_COMPANY_ID = 2;
export const VARIOUS_CLIENTS_CLIENT_ID = 2;
