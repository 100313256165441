import React from 'react';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import {
	connect
} from 'react-redux';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Row, Col, Upload, Spin, Button } from 'antd';
import {injectIntl, FormattedMessage} from 'react-intl';
import {getFields, getErrorMessages} from '../constants/messages';
import {REGEX} from '../constants/global';
import {EditProfile, UpdateProfileImage, UploadProfileImage} from '../actions/AccountAction';

const FormItem = Form.Item;

let fieldsMessages = getFields(),
	errorMessages = getErrorMessages();

let messages = {
	...fieldsMessages,
	...errorMessages
};

export class AcountProfile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			imageUrl: null,
			mimeType: null,
			submited: false,
			isUploading: false
		};
		this.generatePublicLink();
	}
	componentDidUpdate(){
		if(!this.props.loading && this.state.submited){
			this.back();
			this.submitedToFalse();
		}
	}
	generatePublicLink = () => {
		let token = cookie.load("image");
		if (!token || !token.length) {
			this.props.updateProfileImage();
		}
	};
	submitedToFalse = () =>{
		this.setState({
			submited: false
		});
	};
	handleSubmit = (e) => {
		const {
			form,
			editProfile
		} = this.props;

		e.preventDefault();
		
		form.validateFields((err, values) => {

			if (!err) {
				editProfile(values);
				this.setState({
					submited:true
				});
			}
		});
	};
	beforeUpload = (file) => {
		const isJPG = file.type === 'image/jpeg';
		const isPNG = file.type === 'image/png';

		this.setState({
			isUploading: true
		});
		if (!isJPG && !isPNG) {
			// console.log('You can only upload JPG or PNG files!');
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			// console.log('Image must smaller than 2MB!');
		}
		
		return (isJPG || isPNG) && isLt2M;
	};
	back = () => {
		const {
			form,
		} = this.props;
		document
			.getElementsByClassName("myProfile edit")[0]
			.classList
			.remove("edit");
		form.resetFields();
		
		this.setState({
			imageUrl: null,
			submited: false,
		});
	};
	edit = () => {
		document
			.getElementsByClassName("myProfile")[0]
			.classList
			.add("edit");
	};
	handleChange = (info) => {
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			this.props.updateProfileImage();

			this.setState({
				isUploading: false
			});
		}
	};
	checkPassword = (rule, value, callback) => {
		const {
			form,
			intl
		} = this.props;
		if (value && value !== form.getFieldValue('password')) {
			callback(`${intl.formatMessage( {...messages.confirmPassword} )}` );
		} else {
			callback();
		}
	};

	render() {
		const {
			form,
			intl,
			user,
			loading
		} = this.props;
		const {getFieldDecorator} = form;
		const imageUrl = user.url;
		
		const uploadProps = {
			className: "avatar-uploader",
			name: `user_${user.id}`,
			showUploadList: false,
			beforeUpload: this.beforeUpload,
			onChange: this.handleChange,
			customRequest: ({file, filename, onSuccess, onError}) => {
				UploadProfileImage(file, filename, "profile", onSuccess, onError);
			},
			accept: "image/jpeg, image/png",
		};
		return (
            <div className="myProfile">
                <div className="profileView">
                        <Row type="flex" justify="center"  align="bottom">
                                <Col span={10} className="profileUpload">
                                    <Spin spinning={this.state.isUploading}>
                                        <Upload
                                            {...uploadProps}
                                        >
                                            {
                                                <img src={imageUrl} alt="" className="avatar loaded-img" />
                                            }
                                        </Upload>
                                    </Spin>
                                </Col>
                            <Col span={2}>
                                <span className="side-icon icon-pencil" onClick={this.edit} />
                            </Col>
                        </Row>
                        <Row type="flex" justify="center"  align="bottom" className="center-text">
                            <Col span={24}>
                                <strong>
                                    <FormattedMessage {...messages.name} />
                                </strong>
                            </Col>
                            <Col span={24}>
                                {user.name}
                            </Col>
                        </Row>
                        <Row type="flex" justify="center"  align="bottom" className="center-text">
                            <Col span={24}>
                                <strong>
                                    <FormattedMessage {...messages.email} />
                                </strong>
                            </Col>
                            <Col span={24}>
                                {user.email}
                            </Col>
                        </Row>
                </div>
                <div className="profileForm">
                    <Spin spinning={loading}>
                        <Form className="col-8" layout='vertical'>
                            <Row type="flex" justify="center"  align="bottom">
                                <Col span={24}>
                                    <FormItem 
                                        label={intl.formatMessage( {...messages.name} )}  
                                    >
                                        {
                                            getFieldDecorator('name', 
                                                {
                                                    rules: [{
                                                        required: true,
                                                        whitespace: true,
                                                        message: intl.formatMessage( {...messages.nameError})
                                                    }],
                                                    initialValue: user.name

                                                }
                                            )(
                                                <Input placeholder={intl.formatMessage({...messages.usernamePlaceholder} )}  />
                                            )
                                        }
                                    </FormItem>
                                    <FormItem 
                                        label={intl.formatMessage( {...messages.email} )} 
                                    >
                                        {
                                            getFieldDecorator('email', 
                                                {
                                                    rules: [{
                                                        message: ' ',
                                                        pattern: REGEX.email
                                                    }],
                                                    initialValue: user.email
                                                }
                                            )(
                                                <Input placeholder={intl.formatMessage({...messages.emailPlaceholder} )}  />
                                            )
                                        }
                                    </FormItem>
                                </Col>
                            </Row>
                            <FormItem>
                                <Row className="editProfileAction" type="flex" justify="center"  align="bottom">
                                    <Col span={4}>
                                        <Button type="primary" shape="circle" icon={<CheckOutlined />} onClick={this.handleSubmit} />
                                    </Col>
                                    <Col span={4}>
                                        <Button shape="circle" onClick={this.back} icon={<CloseOutlined />} />
                                    </Col>
                                </Row>
                            </FormItem>
                        </Form>
                    </Spin>
                </div>
            </div>
        );
	}
}

AcountProfile.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	editProfile: PropTypes.func.isRequired,
	updateProfileImage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	return {
		"user": state.auth.user,
		"loading": state.profile.loading
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		editProfile: (newUser) => {
			dispatch(EditProfile(newUser));
		},
		updateProfileImage: () => {
			dispatch(UpdateProfileImage());
		}
	};
};
export default connect(mapStateToProps,mapDispatchToProps)(Form.create()(injectIntl(AcountProfile)));