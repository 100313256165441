import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Modal, message, Spin } from 'antd';
import { getModalMessages, getInvoicingMessages } from '../../constants/messages';
import { DownloadAutomaticInvoicePreview } from './actions';
import GenerateInvoicesButton from './GenerateInvoicesButton';

let modalMessages = getModalMessages(),
	invoicingMessages = getInvoicingMessages();

const messages = {
	...modalMessages,
	...invoicingMessages
};

class GenerateInvoicesModal extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: false
		};
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });

	handleDownload = () => {
		const { getIntl, setLoading } = this;
		setLoading(true);
		DownloadAutomaticInvoicePreview()
			.then(() => setLoading(false))
			.catch(() => {
				setLoading(false);
				message.error(getIntl("generatePreviewError"));
			});
	}

	handleCancel = () => {
		const { props, setLoading } = this;
		const { handleCancel } = props;
		setLoading(false);
		handleCancel();
	}

	renderedButtons = () => {
		const { state, handleDownload, handleCancel, getIntl, setLoading } = this;
		const { isLoading } = state;
		return [
			<Button key="cancel" disabled={isLoading} onClick={handleCancel}>{getIntl("cancel")}</Button>,
			<Button key="download" loading={isLoading} type="primary" onClick={handleDownload} icon={<DownloadOutlined />}>{getIntl("downloadPreview")}</Button>,
			<GenerateInvoicesButton key="generate" isLoading={isLoading} changeLoading={setLoading} displayButton />
		];
	}

	render() {
		const { state, props, handleCancel, renderedButtons, getIntl } = this;
		const { isLoading } = state;
		const { showModal } = props;
		return (
			<Modal
				open={showModal}
				onCancel={handleCancel}
				destroyOnClose={true}
				footer={renderedButtons()}
				maskClosable={!isLoading}
				keyboard={!isLoading}
				closable={!isLoading}
			>
				<Spin spinning={isLoading}>
					{getIntl("generateInvoicesDescription")}
				</Spin>
			</Modal>
		);
	}
}

GenerateInvoicesModal.propTypes = {
	intl: PropTypes.object.isRequired,

	showModal: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired
};

export default injectIntl(GenerateInvoicesModal);