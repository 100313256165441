import React from 'react';
import PropTypes from 'prop-types';
import { InputNumber } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { injectIntl } from 'react-intl';

const FormItem = Form.Item;
class EditableBonusAmountField extends React.Component {

	renderAmountField = () => {
		const { props } = this;
		const { form, amount, fieldName } = props;
		const { getFieldDecorator } = form;
		return (
			<FormItem>
            {
                getFieldDecorator(fieldName, 
                    {
						initialValue: amount,
                    }
                )(
                    <InputNumber
                        className="job-partial-component"
                        placeholder={fieldName}
                        min={0}
                        precision={2}
                        formatter={value => `L ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/L\s?|(,*)/g, '')}
                    />
                )
            }
            </FormItem>
		);
	}

	fullRender = () => {
		const { renderAmountField } = this;
		return (
			<div className="payroll-remove-margin-bottom">
				{renderAmountField()}
			</div>
		)
	}

	render() {
		return this.fullRender();
	}

}

EditableBonusAmountField.defaultProps = {
	amount: 0
};

EditableBonusAmountField.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	fieldName: PropTypes.string.isRequired,
	amount: PropTypes.number,
};

export default injectIntl(EditableBonusAmountField);