import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { injectIntl } from 'react-intl';
import { getPayrollMessages, getFields, getEmployeeMessages } from '../../../constants/messages';
import { formatPrice, columnTemplate, includeIHSS } from '../constants';
const moment = require('moment');

let payrollMessages = getPayrollMessages(),
	fieldMessages = getFields(),
	employeeMessages = getEmployeeMessages();

const messages = {
	...payrollMessages,
	...fieldMessages,
	...employeeMessages
};

class PayrollTable extends React.Component {

	getIntl = (str) => {
		return this.props.intl.formatMessage({ ...messages[str] });
	}

	setLoading = (isLoading) => {
		this.setState({ isLoading });
	}

	showIhssOrIsr = () => {
		const { getIntl, props } = this;
		const { date } = props;
		const dateMoment = moment(date).utc();
		if (dateMoment.date() !== 15) return [columnTemplate(getIntl("isr"), 'isr', (value) => value ? formatPrice(value) : "")];
		else if (includeIHSS) return [columnTemplate(getIntl("ihss"), 'ihss', (value) => value ? formatPrice(value) : "")];
		else return [];
	}

	getColumns = () => {
		const { getIntl, props, showIhssOrIsr } = this;
		const { deductionColumns, bonusColumns } = props;
		const ihssOrIsr = showIhssOrIsr();
		return [
			columnTemplate(getIntl("name"), 'name'),
			columnTemplate(getIntl("bankAccount"), 'bankAccount'),
			columnTemplate(getIntl("biweeklySalary"), 'salary', (value) => formatPrice(value)),
			...ihssOrIsr,
			...deductionColumns,
			...bonusColumns,
			columnTemplate(getIntl("accreditedSalary"), 'accreditedSalary', (value) => formatPrice(value))
		];
	}

	tableRender = () => {
		const { getColumns, props } = this;
		const { records } = props;
		const columns = getColumns();
		return (
			<Table
				bordered
				pagination={false}
				rowKey={record => record.id || record.employeeId}
				scroll={{ x: '100%' }}
				dataSource={records}
				columns={columns}
			/>
		);
	}

	render() {
		return this.tableRender();
	}
}

PayrollTable.defaultProps = {
	records: [],
	deductionColumns: [],
	bonusColumns: []
};

PayrollTable.propTypes = {
	intl: PropTypes.object.isRequired,
	records: PropTypes.array.isRequired,
	deductionColumns: PropTypes.array.isRequired,
	bonusColumns: PropTypes.array.isRequired,
	date: PropTypes.string.isRequired
};

export default injectIntl(PayrollTable);