import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getAuthMessages, getModalMessages, getFields, getErrorMessages} from '../constants/messages';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Select, Modal } from 'antd';
import {EditAccount} from '../actions/AccountAction';
import {injectIntl, FormattedMessage} from 'react-intl';
import {REGEX} from '../constants/global';
import {
	INIT_MODAL_REQUEST_ACCOUNT
} from '../constants/actionTypes';

const FormItem = Form.Item;
const Option = Select.Option;

let authMessages = getAuthMessages(),
	fieldMessages = getFields(),
	errorMessages = getErrorMessages(),
	modalMessages = getModalMessages();

const messages = {
	...authMessages,
	...modalMessages,
	...errorMessages,
	...fieldMessages
};

class  EditAccountForm  extends React.Component {

	constructor(props) {
		super(props);
	}
	componentDidUpdate(prevProps) {
		if (prevProps.editable !== this.props.editable) {
			prevProps.form.resetFields();
		}
	}
	handleOk = () => {
		const {
			form,
			editAccount,
			editable
		} = this.props;

		form.validateFields((err, values) => {
			if (!err) {
				editAccount(editable.id, values);
			}
		});

	};
	checkPassword = (rule, value, callback) => {
		const {
			form,
			intl
		} = this.props;
		if (value && value !== form.getFieldValue('password')) {
			callback(`${intl.formatMessage( {...messages.confirmPassword} )}` );
		} else {
			callback();
		}
	};

	render() {
		let {intl, form,  showModal, handleCancel, languageFilter, editable, confirmLoading} = this.props;
		const { getFieldDecorator } = form;

		const languageOptions = languageFilter.map(lang => <Option key={lang.id}>{lang.name}</Option>);

		return ( 
			<Modal
				open={showModal}
				title= {intl.formatMessage( {...messages.editAccountTitle} )} 
				onOk={this.handleOk}
				onCancel={handleCancel}
				confirmLoading = {
					confirmLoading
				}
				destroyOnClose={true}
				footer={[
					<Button key="submit" type="primary"  loading = {confirmLoading}  size="large" onClick={this.handleOk}>
						<FormattedMessage {...messages.send} />
					</Button>,
					<Button key="back" size="large" onClick={handleCancel}>
						<FormattedMessage {...messages.cancel} />
					</Button>,
				]}
			>
				<Form className="login-form col-8" layout='vertical'>
					<FormItem 
								label={intl.formatMessage( {...messages.name} )} 
							>
						{
							getFieldDecorator('name', 
								{
									rules: [{
										required: true,
										whitespace: true,
										message: intl.formatMessage( {...messages.nameError}),
										max: 45
									}],
									initialValue: editable.name
								}
							)(
								<Input placeholder={intl.formatMessage({...messages.usernamePlaceholder} )}  />
							)
						}
					</FormItem>
					<FormItem 
								label={intl.formatMessage( {...messages.username} )} 
							>
						{
							getFieldDecorator('username', 
								{
									rules: [{
										required: true,
										whitespace: true,
										message: intl.formatMessage( {...messages.usernameEmptyError})
									}, {
										pattern: REGEX.username,
										message: intl.formatMessage( {...messages.usernameInvalidError})
									}, {
										min: 5,
										message: intl.formatMessage( {...messages.usernameLengthError})
									}],
									initialValue: editable.username
								}
							)(
								<Input placeholder={intl.formatMessage({...messages.usernamePlaceholder} )}  />
							)
						}
					</FormItem>
					<FormItem 
								label={intl.formatMessage( {...messages.email} )} 
							>
						{
							getFieldDecorator('email', 
								{
									rules: [{
										required: true,
										whitespace: true,
										message: intl.formatMessage( {...messages.emailEmptyError})
									}, {
										pattern: REGEX.email,
										message: intl.formatMessage( {...messages.emailInvalidError})
									}],
									initialValue: editable.email
								}
							)(
								<Input placeholder={intl.formatMessage({...messages.emailPlaceholder} )}  />
							)
						}
					</FormItem>
					<FormItem 
								label={intl.formatMessage( {...messages.password} )} 
								extra={intl.formatMessage( {...messages.passwordNorify} )} 
							>
						{
							getFieldDecorator('password', 
									{
										rules: [
											{
												required: true,
												whitespace: true,
												message: intl.formatMessage( {...messages.passwordEmptyError})
											}, {
												pattern: REGEX.password,
												message: intl.formatMessage( {...messages.passwordInvalidError})
											}, {
												min: 6,
												message: intl.formatMessage( {...messages.passwordLengthError})
										}],
									}
							)( 
								<Input  type = "password" placeholder={intl.formatMessage({...messages.passwordPlaceholder})}/>
							)
						}
					</FormItem> 
					<FormItem 
								label={intl.formatMessage({...messages.passwordConfirm})} 
							>
						{
							getFieldDecorator('confirm', {
								rules: [
									{
										message: intl.formatMessage( {...messages.confirmPassword}),
									}, {
											validator: this.checkPassword,
										}
									],
								}
							)( 

								<Input  type = "password" placeholder={intl.formatMessage( {...messages.passwordConfirm} )} />
							)
						}
					</FormItem>
					<FormItem 
								label={intl.formatMessage( {...messages.languages} )} 
							>
						{
							getFieldDecorator('languageId', {
								
									rules: [{
										required: true
									}],
									initialValue:(editable.languageId)?editable.languageId.toString():undefined
								}
							)(
								<Select 
									placeholder={intl.formatMessage( {...messages.languagesPlaceholder} )} 
								>
									{languageOptions}
								</Select>
							)
						}
					</FormItem>
				</Form>
			</Modal>
		);
	}
}

EditAccountForm.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	showModal: PropTypes.bool.isRequired,
	confirmLoading: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired,
	editAccount: PropTypes.func.isRequired,
	languageFilter: PropTypes.array.isRequired,
	user: PropTypes.object.isRequired,
	editable: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		"user": state.auth.user,
		"accounts": state.account.accounts,
		"languageFilter": state.language.languageFilter,
		"confirmLoading": state.account.confirmLoading,
		"editable": state.account.editable
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		editAccount: (id, newUser) => {
			dispatch({
						type: INIT_MODAL_REQUEST_ACCOUNT
					});
			dispatch(EditAccount(id, newUser));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(EditAccountForm)));