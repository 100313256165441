import {
	CLEAN_REDUCER,
	CLEAR_SESSION,
	GET_PROFORMAS,
	FETCH_PROFORMA_COUNT,
	ADD_PROFORMA_SUCCESS,
	GET_PROFORMA,
	SET_PROFORMA_TO_ADD,
	DENY_PROFORMA_SUCCESS,
	PROFORMA_INVOICED_SUCCESS,
	APPROVE_PROFORMA_SUCCESS,
	STATUS_FILTER,
	UPDATE_SEARCH,
	UPDATE_PAGE
} from './actionTypes';

const initState = {
	proformas: [],
	proforma: {},
	statusFilter: 5,
	count: 0,
	search: "",
	page: 1
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case UPDATE_SEARCH:
			return {
				...state,
				search: action.search
			};
		case UPDATE_PAGE:
			return {
				...state,
				page: action.page
			}
		case GET_PROFORMAS:
			return {
				...state,
				proformas: action.proformas
			};
		case FETCH_PROFORMA_COUNT:
			return {
				...state,
				count: action.count
			};
		case ADD_PROFORMA_SUCCESS:
			return {
				...state,
				proforma: action.proforma
			};
		case STATUS_FILTER:
			return {
				...state,
				statusFilter: action.statusFilter
			};
		case GET_PROFORMA:
			return {
				...state,
				proforma: action.proforma
			};
		case SET_PROFORMA_TO_ADD:
			return {
				...state,
				proforma: action.proforma
			};
		case DENY_PROFORMA_SUCCESS:
			return state;
		case APPROVE_PROFORMA_SUCCESS:
			return state;
		case PROFORMA_INVOICED_SUCCESS:
			return state;
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;
