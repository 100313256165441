import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import { Modal, Spin, Table, message, Typography, Button  } from 'antd';
import { EmployeesbyDepartment } from "../actions";
import { getDepartmentMessages, getFields, getModalMessages, getErrorMessages} from '../../../constants/messages';
import { FormattedMessage, useIntl } from 'react-intl';

let departmentMessages = getDepartmentMessages(),
    fieldMessages = getFields(),
    modalMessages= getModalMessages(),
    errormessages= getErrorMessages();

const messages = {
    ...departmentMessages,
    ...fieldMessages,
    ...modalMessages,
    ...errormessages
};

const { Title } = Typography;

const ShowEmployeesModal = ({uids, showModal, closeModal}) => {
    const intl = useIntl();
    const [isLoading, setIsloading] = useState();
    const [employees, setEmployees] = useState([]);    

    const columns = [
        {
          title: intl.formatMessage({ ...messages.department }),
          dataIndex: 'department',
          key: "department.name",
        },
        {  title: intl.formatMessage( {...messages.name}),
            dataIndex: 'name',
            key: 'name'
        }
      ];
    useEffect(() => {
        const fetchData = async () => {
          try {
            setIsloading(true);
            const response = await EmployeesbyDepartment(uids);
            setEmployees(response);
          } catch (error) {
            message.error(intl.formatMessage(messages.commonError));
            closeModal();
          } finally {
            setIsloading(false);
          }
        };
        if(showModal === true){
            fetchData();
        }
        
      }, [showModal]);

    
  return (
    <div>
        <Modal
         open={showModal}
         onCancel={closeModal}
         footer={
            <Button  type="primary" onClick={closeModal}>
              <FormattedMessage {...messages.close}/>
            </Button>
         }
         title= {
          <Title type = {"danger"} level ={4}>
            <FormattedMessage {...messages.departmentEmployeesUnableToDelete}/>
          </Title>
          }

        >
          <Spin spinning={isLoading} size="large"> 
            <Table
              columns={columns}
              dataSource={employees}
              pagination={{
                pageSize: 5,
              }}
            />
          </Spin>
        </Modal>
    </div>
  )
}

ShowEmployeesModal.propTypes={
    uids: PropTypes.array,
    showModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired
}

export default ShowEmployeesModal
