import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getInvoicingMessages } from '../../constants/messages';
import AddDepositorModal from './AddDepositorModal';

let invoicingMessages = getInvoicingMessages();

let messages = {
	...invoicingMessages
};

class AddDepositorButton extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showModal: false
		};
	}

	openModal = () => {
		this.setState({
			showModal: true
		});
	}

	closeModal = () => {
		this.setState({
			showModal: false
		});
		this.props.handleAdd();
	}

	render() {
		return (
			<span className="indexButton">
			<AddDepositorModal
				handleComplete={this.closeModal}
				showModal={this.state.showModal}
			/>
				<Button onClick={this.openModal} >
					<FormattedMessage {...messages.addDepositor} />
				</Button>
			</span>
		);
	}
}

AddDepositorButton.propTypes = {
	intl: PropTypes.object.isRequired,
	handleAdd: PropTypes.func.isRequired
};



export default injectIntl(AddDepositorButton);