import { Badge, Button, Col, Popconfirm, Row, Table, Typography, message } from 'antd';
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import { getClientMessages, getErrorMessages, getFields, getInvoicingMessages, getJobMessages, getLanguageIntl, getReportMessages, getactionMessages } from '../../../constants/messages';
import { DownloadDisableClient, getOverdueInvoices } from '../actions';
import moment from 'moment';
import ReportOptions from './ReportOptions';
import { roundN } from '../../../utilities/util';
import { StopOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { DisableClients } from '../../Clients/actions';
import { DisableMessages } from '../../Clients/constants';
import PopoverProductsTable from '../../Invoices/PopoverProductsTable';
import ClientDeactivationButton from './ClientDeactivationButton';

let reportMessages = getReportMessages(),
    languageMessages = getLanguageIntl(),
    jobMessages = getJobMessages(),
    clientMessages = getClientMessages(),
    invoicingMessages = getInvoicingMessages(),
    fieldMessages = getFields(),
    errorMessages = getErrorMessages(),
    actionMessages = getactionMessages();

const messages = {
    ...reportMessages,
    ...languageMessages,
    ...jobMessages,
    ...clientMessages,
    ...invoicingMessages,
    ...errorMessages,
    ...fieldMessages,
    ...actionMessages,
};

const OverdueInvoicesReport = () => {
    const intl = useIntl();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [filterValue, setFilterValue] = useState({});
    const history = useHistory();

    const onSubmit = async (values) => {
        try {
            setLoading(true);
            const response = await getOverdueInvoices({...values});
            setFilterValue(values);
            setData(response);
            setDisabled(false);
        } catch (error) {
            message.error(intl.formatMessage({ ...messages.commonError }));        
        } finally {
            setLoading(false);
        }
    }

    const onInvoiceNumberClick = (e, invoiceId) => {
        e.preventDefault();
        history.push(`/invoices/${invoiceId}`);
    }

    const columns = [
		{
            title: intl.formatMessage({ ...messages.clientName }),
            dataIndex: 'clientName',
            key: 'clientName',
        },
        {
            title: intl.formatMessage({ ...messages.InvoiceNumber }),
            dataIndex: 'documentNumber',
            key: 'documentNumber',
            width: 200,
            render: (documentNumber, invoice) => {
                const docNumber  = (
                    <PopoverProductsTable products={invoice.invoiceProducts}>
                        <Typography.Link 
                            href={`/invoices/${invoice.id}`} 
                            onClick={(e) => onInvoiceNumberClick(e, invoice.id)}
                        >
                            {documentNumber}         
                        </Typography.Link>
                    </PopoverProductsTable>);
                return docNumber;
            }
        },
        {
            title: intl.formatMessage({ ...messages.Type }),
            dataIndex: 'type',
            key: 'type',
            width: 200,
            render: (type) => {
                if (type === 1) {
                    return intl.formatMessage({ ...messages.Cash });
                } else if (type === 2) {
                    return intl.formatMessage({ ...messages.Credit });
                }
            },
        },
        {
            title: intl.formatMessage({ ...messages.Status }),
            dataIndex: 'status',
            key: 'status',
            width: 200,
            render: (status) => {
                if (status){
                    const statusBadge = (<Badge status={'warning'} text={intl.formatMessage({ ...messages.Overdue })} />);
                    return statusBadge;
                }
            },
        },
        {
            title: intl.formatMessage({ ...messages.Total }),
            dataIndex: 'total',
            key: 'total',
            width: 200,
            render: (total, invoice) => {
                if (total >= 0 && invoice.exchangeRate) return (`L ${roundN(total * invoice.exchangeRate, 2)}`);
            },
        },
        {
            title: intl.formatMessage({ ...messages.balance }),
            dataIndex: 'balance',
            key: 'balance',
            width: 180,
            render: (balance) => {
                if (balance >= 0) return (`L ${roundN(balance, 2)}`);
            },
        },
        {
            title: intl.formatMessage({ ...messages.Date }),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => {
                if (createdAt) return (moment(createdAt).locale('es').format('L'));
            },
        },
        {
            title: intl.formatMessage({ ...messages.CreatedBy }),
            dataIndex: 'createdBy',
            key: 'createdBy',
        },
	];

    const disableClients = async (clientIds) => {
        try {
            setLoading(true);
            await DisableClients(clientIds, DisableMessages[1]);
            await DownloadDisableClient(clientIds);
            message.success(intl.formatMessage({ ...messages.clientDisableMessage }));
            setSelectedRowKeys([]);
            await onSubmit(filterValue);
        } catch (error) {
            message.error(intl.formatMessage({ ...messages.DisableClientError }))
        } finally {
            setLoading(false);
        }
    }
 
    const disableButton = () => {
		if (selectedRowKeys.length != 0) {
			return (
                <Popconfirm 
                    title={intl.formatMessage({ ...messages.disableConfirm })} 
                    okText={intl.formatMessage({ ...messages.yes })} 
                    cancelText={intl.formatMessage({ ...messages.no })}
                    onConfirm={() => disableClients(selectedRowKeys)} 
                >
                    <Button className="extra-action-btn" icon={<StopOutlined />}>
                        <FormattedMessage
                            {...messages.disableAmount}
                            values={{ amount: selectedRowKeys.length }}
                    />
                    </Button>
                </Popconfirm>
			);
		}
	}

    return (
        <Row className="report view">
            <Col span={24}>
                <h1 className="reportTitle">{ intl.formatMessage({...messages.overdueInvoicesReport}) }</h1>
            </Col>
            <Row className="toolbar" >
                <Col flex='auto'>
                    <Row>
                        <ReportOptions onSubmit={onSubmit} />
                        <ClientDeactivationButton data={data} disabled={disabled}/>
                        {disableButton()}
                    </Row>
                </Col>
            </Row>
            <Col span={24}>
                <Table
                    dataSource={data}
                    columns={columns}
                    loading={loading}
                    search={() => ({})}
                    rowSelection={{
                        selectedRowKeys,
                        checkStrictly: true,
                        onChange: (newSelectedRowKeys) => {setSelectedRowKeys(newSelectedRowKeys);},
                    }}

                />
            </Col>
        </Row>
    )
}

export default OverdueInvoicesReport
