import React from 'react';
import PropTypes from 'prop-types';
import '@ant-design/compatible/assets/index.css';
import { Modal } from 'antd';
import { injectIntl } from 'react-intl';
import { getInvoicingMessages, getModalMessages } from '../../../constants/messages';
import BatchSendForm from './Form';

let invoicingMessages = getInvoicingMessages(),
	modalMessages = getModalMessages();

const messages = {
	...invoicingMessages,
	...modalMessages
};

class BatchEmailSendModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isSending: false
		};
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setSending = (isSending) => this.setState({ isSending });

	handleCancel = () => {
		const { props, setSending } = this;
		setSending(false);
		props.handleCancel();
	}

	render() {
		const { state, props, getIntl, setSending, handleCancel } = this;
		const { isSending } = state;
		const { showModal } = props;
		return (
			<Modal
				open={showModal}
				title={getIntl("sendInvoicesInBatch")}
				onCancel={handleCancel}
				destroyOnClose={true}
				footer={null}
				width={1150}
				maskClosable={!isSending}
				keyboard={!isSending}
				closable={!isSending}
			>
				<BatchSendForm isSending={isSending} setSending={setSending} handleCancel={handleCancel}/>
			</Modal>
		);
	}
}

BatchEmailSendModal.propTypes = {
	intl: PropTypes.object.isRequired,
	showModal: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired
};

export default injectIntl(BatchEmailSendModal);