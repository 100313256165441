import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getInvoicingMessages, getModalMessages, getactionMessages, getErrorMessages, getFiscalDataMessages, getProductMessages, getClientMessages } from '../../constants/messages';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { notification, message, Spin } from 'antd';
import { GetProductsOfInvoice } from '../Products/actions';
import { GetFiscalData, EditFiscalData } from '../FiscalData/actions';
import { UpdateNextInvoiceMonth } from '../Clients/actions';
import { UpdateInvoice, GetDevicesForInvoice, AddInvoice, PrintInvoice, GetLatestPayment, EmailInvoice, GetClientsPendingInvoices, PreviewUnregisteredInvoice, AddInvoiceWithDevices, PreviewUnregisteredInvoiceDevicesDocument } from './actions';
import { injectIntl } from 'react-intl';
import AddInvoicingDocumentForm from '../../components/AddInvoicingDocumentForm';
import EmailSelector from '../../components/EmailSelector';
import { VARIOUS_CLIENTS_CLIENT_ID } from '../../constants/global';
import SendDeviceListCheckbox from './SendDeviceListCheckbox';
import { MarkJobsInvoiced } from '../Jobs/actions';

let moment = require('moment');

let invoicingMessages = getInvoicingMessages(),
	errorMessages = getErrorMessages(),
	ProductMessages = getProductMessages(),
	actionMessages = getactionMessages(),
	modalMessages = getModalMessages(),
	fiscalDataMessages = getFiscalDataMessages(),
	clientMessages = getClientMessages();

const messages = {
	...invoicingMessages,
	...modalMessages,
	...actionMessages,
	...errorMessages,
	...ProductMessages,
	...fiscalDataMessages,
	...clientMessages
};

class AddInvoiceForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			showEmailModal: false,
			invoice: {},
			sendDevicesWithEmail: false,
			devices: [],
			invoiceId: "",
			products: [],
			addedProducts: [],
			deleteProducts: [],
			editedProducts: [],
		};
	}

	getDevices = (id) => {
		GetDevicesForInvoice(id)
			.then(devices => this.setState({ devices }))
			.catch(() => {
				message.error(`${this.props.intl.formatMessage({ ...messages.getInvoiceProductsError })}`);
			});
	};

	getProducts = (id) => {
		this.getDevices(id);
		this.setState({ invoiceId: id });
		return this.props.getProductsOfInvoice(id)
			.then((productsOfCurrentInvoice) => {
				this.setState({ products: productsOfCurrentInvoice.map(product => ({ ...product, isNew: false })) });
				return this.state.products;
			})
			.catch(() => {
				message.error(`${this.props.intl.formatMessage({ ...messages.getInvoiceProductsError })}`);
				this.setState({ isLoading: false });
				return [];
			});
	}

	editProduct = (product) => {
		const { editedProducts, addedProducts } = this.state;
		const { index, isNew, key } = product;
		let products = isNew ? addedProducts : editedProducts;
		if (isNew) {
			if (products.length) {
				const productExists = products.find(x => (x.index == index));
				if (productExists) {
					const productIndex = products.indexOf(productExists);
					products.splice(productIndex, 1, product);
				} else { products.push(product); }
			}
			this.setState({ addedProducts: products }) 
		} else {
			if (products.length) {
				const productExists = products.find(x => (x.key == key));
				if (productExists) {
					const productIndex = products.indexOf(productExists);
					products.splice(productIndex, 1, product);
				} else { products.push(product); }
			} else { products.push(product); }
			this.setState({ editedProducts: products });
		}
	};

	getDeleteProduct = (product) => {
		const { editedProducts, addedProducts } = this.state;
		const { index, isNew, key } = product;
		if (isNew) {
			addedProducts.splice(index, 1);
			this.setState({ addedProducts });
			return;
		}
		const productExists = editedProducts.find(x => (x.key == key));
		if (productExists) {
			const productIndex = editedProducts.indexOf(productExists);
			editedProducts.splice(productIndex, 1);
			this.setState({ editedProducts });
		}
		const deleted = { ...product };
		this.setState(prevState => ({ deleteProducts: [...prevState.deleteProducts, deleted] }));
	};

	getAddedProducts = (product) => {
		const { addedProducts } = this.state;
		const index = addedProducts.length;
		const newProduct = { ...product, isNew: true, index };
		this.setState(prevState => ({ addedProducts: [...prevState.addedProducts, newProduct] }));
		return newProduct;
	};

	closeEmailModal = () => {
		this.setState({
			showEmailModal: false
		});
	}

	getDeletedDevices = (devicesSelected) => {
		const { devices } = this.state;
		const deviceIds= devices.filter(id => devicesSelected.indexOf(id) == -1);
		return deviceIds;
	};

	getAddedDevices = (devicesSelected) => {
		const { devices } = this.state;
		const deviceIds = devicesSelected.filter(id => devices.indexOf(id) == -1)
		return deviceIds;
	}

	handleSubmit = (email, state) => {
		const { match, invoice } = this.props;
		const { addedProducts, editedProducts, deleteProducts } = this.state;
		const { params } = match;
		this.props.form.validateFields((err, values) => {
			if (err) {
				return;
			}
			const { clientAlias } = values;
			values = {
				...values,
				accountId: this.props.user.id,
				status: 0,
				subtotal: state.subtotal,
				total: state.total,
				createdAt: moment(),
				paymentDate: state.isCredit ? moment().add(values.daysForPayment, 'days') : moment(),
				products: state.products,
				amount: 0,
				lastDocNum: "",
				clientName: !state.variousClients ? (clientAlias && clientAlias.length > 0 ? clientAlias : this.props.currentClientName) : values.clientName,
				clientRtn: !state.variousClients ? this.props.currentRTN : values.clientRtn,
				representative: !state.variousClients ? this.props.currentRepresentative : values.representative,
				address: !state.variousClients ? this.props.fullAddress.address : values.address,
				city: !state.variousClients ? this.props.fullAddress.city : values.city,
				state: !state.variousClients ? this.props.fullAddress.state : values.state,
				nextInvoiceMonth: state.nextInvoiceMonth,
				isv: this.props.isv
			};

			if (params.id !== undefined) {
				const { taxReferenceExemption, taxReferenceExoneration, taxReferenceSag, taxType, taxExemption } = invoice;
				values = { ...values, taxReferenceExemption, taxReferenceExoneration, taxReferenceSag, taxType, taxExemption }
			}

			if (state.products.length === 0) {
				message.error(this.props.intl.formatMessage({ ...messages.emptyProducts }));
				return;
			}

			this.setState({
				isLoading: true
			});

			if (this.props.match.params.id) {
				const deletedDevices = this.getDeletedDevices(values.deviceIds);
				const addedDevices = this.getAddedDevices(values.deviceIds);
				values = {
					...values,
					deviceIds: addedDevices,					
					products: { newProducts: addedProducts, editProducts: editedProducts, deleteProducts },
					deletedDevices,
				}
				if (values.nextInvoiceMonth) this.props.updateNextInvoiceMonth(this.props.invoice.clientId, values.nextInvoiceMonth);
				UpdateInvoice(this.state.invoiceId, values)
					.then(() => this.success() )
					.catch(() => {
						message.error(`${this.props.intl.formatMessage({ ...messages.invoiceUpdateError })}`);
					});				
			} else {
				this.props.getClientsPendingInvoices(values.clientId).then(() => {
					this.props.getLatestPayment(values.clientId).then(() => {
						if (email) {
							this.setState({
								showEmailModal: true,
								isLoading: false,
								sendDevicesWithEmail: false,
								invoice: { ...values }
							});
						} else {
							this.submitInvoice(values).then((id) => {
								if(state.checkInJobs) MarkJobsInvoiced(state.jobsId, id);
								this.props.history.push(`/invoices/${id}`);
							});
						}
					}).catch(() => {
						message.error(`${this.props.intl.formatMessage({ ...messages.GetLatestPaymentError })}`);
						this.setState({
							isLoading: false
						});
					});
				});
			}
		});
	}

	success = () => {
		this.setState({	isLoading: false });
		message.success(`${this.props.intl.formatMessage({ ...messages.invoiceUpdateSuccess })}`);
		this.props.history.push(`/invoices/${this.props.match.params.id}`);
	}


	submitInvoice = (values) => {
		return this.props.getFiscalData().then(() => {
			values = {
				...values,
				cai: this.props.fiscalData.invoice.cai,
				InitialRValue: this.props.fiscalData.invoice.initialRValue.toString(),
				FinalRValue: this.props.fiscalData.invoice.finalRValue.toString(),
				documentNumber: this.props.fiscalData.invoice.prefix + "-" + this.props.fiscalData.invoice.currentRValue.toString().padStart(8, "0"),
				fiscalExpiryDate: this.props.fiscalData.invoice.expiryDate
			};
			if (this.props.latestPayment.balance < 0 && values.clientId !== VARIOUS_CLIENTS_CLIENT_ID && this.props.pendingInvoices.length === 0) {
				let description = " Abonado a factura número " + values.documentNumber + ".";
				description = this.props.latestPayment.description === null ? description : this.props.latestPayment.description + description;

				values = {
					...values,
					amount: this.props.latestPayment.balance,
					newDesc: description,
					paymentId: this.props.latestPayment.id,
					lastDocNum: this.props.lastDocNum
				};
			}
			return this.props.addInvoiceWithDevices(values).then(() => {
				if (this.props.addedInvoice) {
					let fiscalData = {
						...this.props.fiscalData.invoice,
						currentRValue: this.props.fiscalData.invoice.currentRValue + 1
					};
					return this.props.editFiscalData(fiscalData).then(() => {
						this.props.printInvoice([this.props.invoice.id]).catch(() => {
							message.error(`${this.props.intl.formatMessage({ ...messages.PrintError })}`);
						});
						if (values.nextInvoiceMonth) this.props.updateNextInvoiceMonth(this.props.invoice.clientId, values.nextInvoiceMonth);
						notification.open({
							message: this.props.intl.formatMessage({ ...messages.Print }),
							description: this.props.intl.formatMessage({ ...messages.ReminderPrint }),
							duration: 0
						});
						return this.props.invoice.id;
					});
				}
			});
		}).catch(() => {
			message.error(`${this.props.intl.formatMessage({ ...messages.AddInvoiceError })}`);
			this.setState({ isLoading: false });
		});
	}

	handleCancel = () => {
		this.props.history.push(`/invoices`);
	}

	renderSendDeviceListField = () => {
		const { state, props } = this;
		const { sendDevicesWithEmail } = state;
		const { getFieldValue } = props.form;
		const toggleCheckbox = checked => this.setState({ sendDevicesWithEmail: checked });
		const deviceIds = getFieldValue("deviceIds") || [];
		const disabled = !deviceIds || (deviceIds && deviceIds.length <= 0)
		return (
			<SendDeviceListCheckbox
				disabled={disabled}
				checkboxState={sendDevicesWithEmail}
				triggerCheckbox={toggleCheckbox}
				showWarning={false}
			/>
		);
	}

	downloadPreview = () => {
		const { state, props } = this;
		const { sendDevicesWithEmail, invoice } = state;
		const { getFieldValue } = props.form;
		const deviceIds = getFieldValue("deviceIds") || [];
		if (!sendDevicesWithEmail) return () => PreviewUnregisteredInvoice(invoice);
		const promises = [
			new Promise((resolve, reject) => {
				PreviewUnregisteredInvoice(invoice)
					.then(invoicePreview => resolve(invoicePreview))
					.catch(() => reject());
			}),
			new Promise((resolve, reject) => {
				PreviewUnregisteredInvoiceDevicesDocument(deviceIds)
					.then(invoiceDevicesPreview => resolve(invoiceDevicesPreview))
					.catch(() => reject());
			})
		];
		return () => Promise.all(promises);
	}

	emailDocument = (id, emails, replyTo, message) => {
		const { state, props } = this;
		const { sendDevicesWithEmail } = state;
		const { emailInvoice } = props;
		return emailInvoice(id, emails, replyTo, message, sendDevicesWithEmail);
	}

	render() {
		const { renderSendDeviceListField, downloadPreview, emailDocument } = this;
		return (
			<Form >
				<EmailSelector
					addOrShow={true}
					showEmailModal={this.state.showEmailModal}
					closeModal={this.closeEmailModal}
					addDocument={this.submitInvoice}
					document={this.state.invoice}
					goTo={(id) => { this.props.history.push(`/invoices/${id}`); }}
					emailDocument={emailDocument}
					downloadPreview={downloadPreview()}
					extraComponents={renderSendDeviceListField()}
				/>
				<Spin spinning={this.state.isLoading}>
					<AddInvoicingDocumentForm
						handleSubmit={this.handleSubmit}
						handleCancel={this.handleCancel}
						getFieldDecorator={this.props.form.getFieldDecorator}
						document={this.props.match.params.id !== undefined ? this.props.invoice : undefined}
						getProducts={this.getProducts}
						devices={this.state.devices}
						editProduct={this.editProduct}
						getDeleteProduct={this.getDeleteProduct}
						getAddedProducts={this.getAddedProducts}
						isInvoice={true}
						taxExemption={this.props.match.params.id !== undefined ? this.props.invoice.taxExemption : false}
						resetFields={this.props.form.resetFields}
						parentForm={this.props.form}
					/>
				</Spin>
			</Form>
		);
	}
}

AddInvoiceForm.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	match: PropTypes.shape({
		params: PropTypes.object.isRequired
	}).isRequired,
	user: PropTypes.object.isRequired,
	lastDocNum: PropTypes.string.isRequired,
	invoice: PropTypes.object,
	latestPayment: PropTypes.object.isRequired,
	addedInvoice: PropTypes.bool.isRequired,
	fiscalData: PropTypes.object.isRequired,
	currentClientName: PropTypes.string,
	currentRTN: PropTypes.string,
	currentRepresentative: PropTypes.string,
	customPrices: PropTypes.array,
	fullAddress: PropTypes.object,
	currentIsLps: PropTypes.bool,
	productsOfCurrentInvoice: PropTypes.array,
	pendingInvoices: PropTypes.array.isRequired,
	getFiscalData: PropTypes.func.isRequired,
	editFiscalData: PropTypes.func.isRequired,
	addInvoice: PropTypes.func.isRequired,
	printInvoice: PropTypes.func.isRequired,
	emailInvoice: PropTypes.func.isRequired,
	getLatestPayment: PropTypes.func.isRequired,
	updateNextInvoiceMonth: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	getProductsOfInvoice: PropTypes.func.isRequired,
	getClientsPendingInvoices: PropTypes.func.isRequired,
	isv: PropTypes.number.isRequired,
	addInvoiceWithDevices: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	return {
		fiscalData: state.fiscalData.fiscalData,
		user: state.auth.user,
		addedInvoice: state.invoicing.addedInvoice,
		invoice: state.invoicing.invoice,
		latestPayment: state.invoicing.latestPayment,
		lastDocNum: state.invoicing.lastDocNum,
		currentClientName: state.client.currentClientName,
		currentRTN: state.client.currentRTN,
		currentRepresentative: state.client.currentRepresentative,
		customPrices: state.client.customPrices,
		currentIsLps: state.client.currentIsLps,
		fullAddress: state.client.fullAddress,
		productsOfCurrentInvoice: state.product.productsOfCurrentInvoice,
		pendingInvoices: state.invoicing.pendingInvoices,
		isv: state.invoicing.isv
	};
};

const mapDispatchToProps = (dispatch) => {
	return {

		getProductsOfInvoice: (invoiceId) => {
			return dispatch(GetProductsOfInvoice(invoiceId));
		},
		getFiscalData: () => {
			return dispatch(GetFiscalData());
		},
		editFiscalData: (fiscalData) => {
			return dispatch(EditFiscalData(fiscalData));
		},
		addInvoice: (newInvoice) => {
			return dispatch(AddInvoice(newInvoice));
		},
		printInvoice: (ids) => {
			return dispatch(PrintInvoice(ids));
		},
		emailInvoice: (id, emails, replyTo, message, includeDevices) => {
			return dispatch(EmailInvoice(id, emails, replyTo, message, includeDevices));
		},
		getClientsPendingInvoices: (clientId) => {
			return dispatch(GetClientsPendingInvoices(clientId));
		},
		getLatestPayment: (id) => {
			return dispatch(GetLatestPayment(id));
		},
		updateNextInvoiceMonth: (id, nextInvoiceMonth) => {
			return dispatch(UpdateNextInvoiceMonth(id, nextInvoiceMonth));
		},
		addInvoiceWithDevices: (newInvoice) => {
			return dispatch(AddInvoiceWithDevices(newInvoice));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(Form.create()(AddInvoiceForm))));
