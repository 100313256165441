import React from 'react';
import PropTypes from 'prop-types';
import { InputNumber, Form } from 'antd';

const FormItem = Form.Item;

const MoneyInputNumber = ({fieldName, label, errorMessage, initialValue, onChange, required, rules, extraRules, renderLabel, disabled }) => {
    let formRules = rules ? rules : [{required: required, message: errorMessage}];
	if(extraRules){
		formRules.push(extraRules)
	}

    return (
        <FormItem 
            label={ label && renderLabel ? label : null}
            name={fieldName}
            initialValue={initialValue}
            onChange = {onChange}
            rules={formRules}
        >
            <InputNumber
                style={{ width: '100%' }}
                placeholder={label? label : null}
                min={0}
                precision={2}
                formatter={value => `L ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/L\s?|(,*)/g, '')}
                disabled={disabled}
            />
        </FormItem>
    )
}

MoneyInputNumber.propTypes = {
    fieldName: PropTypes.string.isRequired,
    label: PropTypes.string,
    errorMessage: PropTypes.string,
	initialValue: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string
	]),
    onChange: PropTypes.func,
    required: PropTypes.bool,
    rules: PropTypes.array,
    extraRules: PropTypes.array,
    renderLabel: PropTypes.string,
    disabled: PropTypes.bool
};
export default MoneyInputNumber
