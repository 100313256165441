import Language from '../components/Language';
import { connect } from 'react-redux';
import { locale } from '../constants/global';

const mapStateToProps = (state) => {
	return {
		lang: state.int18.language || state.int18.defaultLanguage,
		locale
	};
};


export default connect(mapStateToProps)(Language);