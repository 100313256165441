import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getInvoicingMessages, getFiscalDataMessages, getactionMessages } from '../../constants/messages';
import { connect } from 'react-redux';
import { ROLES } from '../../constants/global';

let invoicingMessages = getInvoicingMessages(),
	fiscalDataMessages = getFiscalDataMessages(),
	actionMessages = getactionMessages();

let messages = {
	...invoicingMessages,
	...fiscalDataMessages,
	...actionMessages
};

class AddInvoiceButton extends React.Component {

	constructor(props) {
		super(props);
	}

	getIntl = (str) => {
		return this.props.intl.formatMessage({ ...messages[str] });
	}

	navigate = () => {
		const { props, getIntl } = this;
		const { fiscalData } = props;
		const { invoice } = fiscalData;
		const cantCreateNotify = () => notification.open({ message: getIntl("Invoice"), description: getIntl("cantCreateInvoice") });
		if (invoice === undefined) cantCreateNotify();
		else {
			let today = new Date();
			let expiryDate = new Date(invoice.expiryDate);
			if ((invoice.currentRValue > invoice.finalRValue) || (expiryDate < today.setHours(0, 0, 0, 0))) cantCreateNotify();
			else this.props.history.push(`/invoices/new`);
		}
	};

	render() {
		return (
			(this.props.roles.includes(ROLES.MASTER) || this.props.roles.includes(ROLES.ADMINISTRATOR)) && (
				<span>
					<Button 
						className="editable-add-btn" 
						type="primary" 
						icon={<PlusOutlined />}
						onClick={this.navigate} 
						style={{ 'marginRight': '8px' }}
					>
						<FormattedMessage {...messages.add} />
					</Button>
				</span>
			)
        );
	}
}

AddInvoiceButton.propTypes = {
	intl: PropTypes.object.isRequired,
	fiscalData: PropTypes.object.isRequired,	
	history: PropTypes.object.isRequired,
	roles: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
	return {
		fiscalData: state.fiscalData.fiscalData,
		roles: state.auth.roles,
	};
};

export default connect(mapStateToProps)(withRouter(injectIntl(AddInvoiceButton)));