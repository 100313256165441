import PropTypes from 'prop-types';
import { DatePicker, Row, Col, Button, Statistic, Spin } from 'antd';
import { connect } from "react-redux";
import {
    injectIntl,
    FormattedMessage
} from 'react-intl';
import { getReportMessages, getInvoicingMessages, getLanguageIntl, getClipboardMessages } from '../../../constants/messages';
import { GetProductReceipts } from '../actions';
import ProductList from './ProductList';
import React from 'react';

let reportMessages = getReportMessages(),
    languageMessages = getLanguageIntl(),
    clipboardMessages = getClipboardMessages(),
    invoicesMessages = getInvoicingMessages();

const messages = {
    ...reportMessages,
    ...languageMessages,
    ...clipboardMessages,
    ...invoicesMessages
};

const { RangePicker } = DatePicker;

class AccountEntriesReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            startDate: undefined,
            endDate: undefined,
            productReceipts: [],
            total: { product: 'Total', numInvoices: '', total: 0 }
        };
    }

    onChangeDate = (dates) => {
        this.setState({
            startDate: dates[0],
            endDate: dates[1]
        });
    }

    onSend = () => {
        this.setState({
            isLoading: true
        });
        this.props.getProductReceipts(this.state.startDate, this.state.endDate).then((productReceipts) => {
            this.setState({
                productReceipts: productReceipts,
                total: {
                    product: 'Total',
                    numInvoices: '',
                    total: productReceipts.reduce((accumulator, productReceipt) => accumulator + productReceipt.total, 0)
                },
                isLoading: false
            });
        });
    }

    render() {
        return (
            <div className="incomeReport view" >
                <Row className="generalReport" gutter={16} type="flex" align="bottom">
                    <Col span={6}>
                        <p><FormattedMessage {...messages.month} />:</p>
                        <RangePicker onChange={this.onChangeDate} />
                    </Col>
                    <Col>
                        <Button type="primary" onClick={this.onSend}><FormattedMessage {...messages.generateReport} /></Button>
                    </Col>
                </Row>
                <Spin spinning={this.state.isLoading}>
                    <Row type="flex" justify="center">
                        <Col xs={23} sm={16} md={16} lg={8} >
                            <div className='equipmentTotal'>
                                <Row>
                                    <Col span={15} style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                        {this.state.total.product}
                                    </Col>
                                    <Col span={7} >
                                        {<Statistic
                                            value={this.state.total.total}
                                            precision={2}
                                            valueStyle={{ color: '#87d068', fontSize: '20px' }}
                                            prefix={<p>L</p>}
                                        />}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row type="flex" justify="center">
                        <ProductList
                            products={this.state.productReceipts}
                        />
                    </Row>
                </Spin>
            </div>
        );
    }
}

AccountEntriesReport.propTypes = {
    intl: PropTypes.object.isRequired,
    getProductReceipts: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    getProductReceipts: (startDate, endDate) => dispatch(GetProductReceipts(startDate, endDate))
});

export default connect(null, mapDispatchToProps)(injectIntl(AccountEntriesReport));