import React, { useRef, useState } from 'react'
import { Modal, message } from 'antd'
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import EditMedicalDeductionForm from './EditMedicalDeductionForm';
import { EditMedicalDeduction } from '../../../actions';
import { LoadingOutlined, SaveOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { getModalMessages, getPayrollMessages } from '../../../../../constants/messages';

let ModalMessages = getModalMessages(),
    PayrollsMessages = getPayrollMessages();

const messages = {
    ...ModalMessages,
    ...PayrollsMessages,
};

const EditMedicalDeductionModal = ({open, closeModal, title, record}) => {
    const intl = useIntl();
    const form = useRef();
    const dispatch = useDispatch();
    const [isSaving, setSaving] = useState(false);

    const onOk = async () => {
        try {
            setSaving(true);
            const values = await form.current.validateFields();
            await dispatch(EditMedicalDeduction(values));
            closeModal();
        } catch (error) {
            message.error(intl.formatMessage({...messages.editMedicalDeductionError }));
        } finally {
            setSaving(false);
        }
    }

  return (
        <Modal
            open={open}
            title={title}
            onOk={onOk}
            okText={
                <span>
                    {isSaving ? <LoadingOutlined /> : <SaveOutlined />} {<FormattedMessage {...messages.save} />}
                </span>
            }
            onCancel={closeModal}
            destroyOnClose={true}
        >
            <EditMedicalDeductionForm form={form} record={record}/>
        </Modal>
    )
}

EditMedicalDeductionModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    title: PropTypes.string,
    open: PropTypes.bool,
    record: PropTypes.object,
};

export default EditMedicalDeductionModal
