import axios from 'axios';
import {
	GET_CLIENT_INVOICES,
	SET_CLIENT_AVAILABLE_DAYS,
	GET_TAX_TOTAL,
	GET_ACCOUNTS
} from './actionTypes';
import { GET_CLIENTS } from '../Clients/actionTypes';
import moment from 'moment';

export const GetClientInvoices = () => (dispatch) => {
	return axios.post(`/Clients/getClientsInvoices`)
		.then((response) => {
			dispatch({
				type: GET_CLIENT_INVOICES,
				clientInvoices: response.data.clientsInvoices,
			});
		});
};

export const MakeComment = (clientId, commentValue) => (dispatch, getState) => {
	let data = {
		clientId: clientId,
		comment: commentValue,
		userId: getState().auth.user.id
	};

	return axios.post(`/Clients/createClientComment`, data);
}

export const ModifyDaysAvailable = (clientId, days) => (dispatch) => {
	let data = {
		id: clientId,
		days: days,
	};

	return axios.patch(`/Clients/modifyDaysAvailable`, data).then(() => {
		dispatch({
			type: SET_CLIENT_AVAILABLE_DAYS,
			settedDays: days, clientId: clientId
		});
	});
}

export const ForwardEmail = (sender, clientEmails, message) => () => {
	let data = {
		sender: sender,
		clientsEmails: clientEmails,
		message: message,
	};
	return axios.post(`/Clients/forwardCommentEmail`, data);
}

export const GetAccounts = () => (dispatch) => {
	return axios.post(`/Clients/getAccountsEmails`).then((response) => {
		dispatch({
			type: GET_ACCOUNTS,
			accountsEmails: response.data.accountsData,
		});
	});
}

export const GetTaxTotal = (date) => (dispatch) => {
	return axios({
		url: `/Invoices/taxTotal`,
		method: 'GET',
		responseType: 'object',
		params: {
			date: date
		}
	}).then((response) => {
			dispatch({ type: GET_TAX_TOTAL, taxTotal: response.data });
		});
}

export const EmailClientMovements = (exportData, emailsData) => () => {
	let data = {
		exportData: exportData,
		emailsData: emailsData,
	};
	return axios.post(`/Clients/emailClientMovements`, data)
		.then((response) => {
			return response.data;
		});
}

export const DownloadClientMovements = (exportData) => () => {
	let data = {
		exportData: exportData
	};
	return axios({
		url: `/Clients/downloadClientMovements`,
		data: data,
		method: 'POST',
		responseType: 'blob'
	}).then((response) => {
		let filename = response.headers['cache-control'];
		let arr = filename.split(',');
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `Movimientos de Cliente: ${arr[arr.length - 1]}.pdf`);
		document.body.appendChild(link);
		link.click();
	});
}


export const EmailAccountStatement = (emails, respondTo, message, exportData) => () => {
	let data = {
		emails: emails,
		respondTo: respondTo,
		message: message,
		exportData: exportData
	};
	return axios.post(`/Clients/exportAccountStatement`, data)
		.then((response) => {
			return response.data;
		});
}


export const DownloadAccountStatement = (exportData) => () => {
	let data = {
		exportData: exportData
	};
	return axios({
		url: `/Clients/downloadAccountStatement`,
		data: data,
		method: 'POST',
		responseType: 'blob'
	}).then((response) => {
		let filename = response.headers['cache-control'];
		let arr = filename.split(',');
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `Estado de Cuenta: ${arr[arr.length - 1]}.pdf`);
		document.body.appendChild(link);
		link.click();
	});

}

const exportExcel = (url, params, name) => {
	return axios({
		url: url,
		method: 'POST',
		responseType: 'blob',
		data: params,
	}).then((response) => {
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `${name}-${moment().format('DD/MM/YYYY hh:mm A')}.xlsx`);
		document.body.appendChild(link);
		link.click();
	});
};

export const ExportJobsToChargeData = async (data) => {
	const params = { data };
	return exportExcel('JobToPays/ExportJobsToPay', params, 'JobsToChargeReport');
};

export const GetAccountsSummaries = (categories, startDate, endDate) => () => {
	return axios({
        url: `/AccountCategories/getAccountsSummaries`,
        method: 'GET',
		responseType: 'json',
		params: {
			categories: categories, 
			datesSearch: {startDate: startDate, endDate: endDate}
		}
	})
	.then((response) => {
		return response.data;
	});
};

export const GetProductReceipts = (startDate, endDate) => () => {
	return axios({
        url: `/ProductReceipts/getProductReceiptsSummary`,
        method: 'GET',
		responseType: 'json',
		params: {
			dates: {startDate: startDate, endDate: endDate}
		}
	})
	.then((response) => {
		return response.data;
	});
};

export const GetPendingJobs = (clientId, page=1, pageSize=10) => (dispatch) => {
	return axios({
		url: `/JobToPays/getReportInfo`,
        method: 'GET',
		responseType: 'json',
		params: {
			clientId: clientId,
			page: page,
			pageSize: pageSize
		}
	})
	.then((response) => {
		dispatch({type: GET_CLIENTS, clients: response.data.clients});
		return response.data;
	});
};

export const getJobsToCharge = async ({ clientId, dateRange }) => {
	const params = {
		clientId,
		startDate: moment(dateRange[0]).format('YYYY-MM-DD HH:mm:ss').toString(),
		endDate:  moment(dateRange[1]).format('YYYY-MM-DD HH:mm:ss').toString(),
	};
	const response = await axios.get(`/JobToPays/reportJobsToCharge`, { params });
	return response.data;
};

export const getOverdueInvoices = async ({ overdueDays, tierClients }) => {
	const params = { overdueDays, tierClients };
	const response = await axios.get(`/Invoices/reportOverdueInvoices`, { params });
	return response.data;
};

export const getClientLeasedDevicesService = async () => {
	const response = await axios.get(`/Clients/clientLeasedDevicesService`);
	return response.data;
};

export const getClientOwnDevicesService = async () => {
	const response = await axios.get(`/Clients/clientOwnDevicesService`);
	return response.data;
};

export const DownloadCustomPriceReport = (clients, productCode) => {
	let params = { clients };
	return axios({
		url: '/ClientProducts/DownloadCustomPriceReport',
		method: 'POST',
		responseType: 'blob',
		data: params,
	}).then((response) => {
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `Reporte de Precio Personalizado(${productCode})-${moment().format('DD/MM/YYYY hh:mm A')}.xlsx`);
		document.body.appendChild(link);
		link.click();
	});
};

export const DownloadDisableClient = (ids) => {
	return axios({
		url: `/Clients/DownloadDisableClient`,
		method: 'POST',
		responseType: 'blob',
		data: {
			ids: ids
		}
	})
		.then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Clientes Deshabilitados ${moment().format('DD/MM/YYYY hh:mm A')}.xlsx`);
			document.body.appendChild(link);
			link.click();
		});
};
