import React from "react";
import PropTypes from "prop-types";
import { getRegistrationMessages } from "../../../constants/messages";
import { Radio} from "antd";

import { FormattedMessage } from "react-intl";
import { STATUS } from "../constants";


let RegistrationMessages = getRegistrationMessages();

const messages = {
  ...RegistrationMessages,
};

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const ClientRadioButton = ({ onFilter }) => {
  
  const onChange = (e) => {
    onFilter({ status: e.target.value });
  };

  return (
    <div>
      <RadioGroup onChange={onChange} defaultValue={STATUS.PENDING}>
        <RadioButton value={0}>
          <FormattedMessage {...messages.all} />
        </RadioButton>
        <RadioButton value={STATUS.PENDING}>
          <FormattedMessage {...messages.pending} />
        </RadioButton>
        <RadioButton value={STATUS.REJECTED}>
          <FormattedMessage {...messages.rejected} />
        </RadioButton>
      </RadioGroup>
    </div>
  );
};
ClientRadioButton.propTypes = {
  onFilter: PropTypes.func,
};
export default ClientRadioButton;
