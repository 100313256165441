import axios from 'axios';
import {
	FETCH_PAYROLLS,
	FETCH_PAYROLL_COUNT,
	FETCH_GENERATE_PAYROLL_INFO,
	FETCH_IHSS_ROOFS,
	FETCH_ISR_ROOFS,
	FETCH_DEDUCTIONS,
	FETCH_PAYROLL_INFO,
	FETCH_MEDICAL_DEDUCTIONS
} from './actionTypes';
import { substituteObjectInArrayByValue } from '../../utilities/util';
import { MEDICALDEDUCTIONS } from './constants';

const moment = require('moment');
const { MEDICAL, IHSS } = MEDICALDEDUCTIONS;

export const GetPayrolls = (searchObject, page = 1, pageSize = 10) => (dispatch) => {
	const filter = {
		limit: pageSize,
		skip: (page - 1) * pageSize,
		order: "payrollDate DESC"
	};
	return axios.get(`/Payrolls?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then(response => dispatch({ type: FETCH_PAYROLLS, payrolls: response.data }));
};

export const GetPayrollCount = () => (dispatch) => {
	return axios.get(`/Payrolls/count`)
		.then(response => dispatch({ type: FETCH_PAYROLL_COUNT, count: response.data.count }));
};

export const GetPayrollInfo = (id) => (dispatch) => {
	return axios.get(`/Payrolls/${id}/getPayrollInfo`)
		.then(response => dispatch({ type: FETCH_PAYROLL_INFO, payrollInfo: response.data }));
};

export const GetIHSSRoofs = () => (dispatch) => {
	return axios.get(`/IHSSRoofs`)
		.then(response => dispatch({ type: FETCH_IHSS_ROOFS, ihssRoofs: response.data }));
};

export const GetISRRoofs = () => async (dispatch) => {
	const response = await axios.get(`/ISRRoofs`)
	dispatch({ type: FETCH_ISR_ROOFS, isrRoofs: response.data });
};

export const BeforeEditISRRoofs = (index, isr) => async (dispatch, getState) => {
	let state = getState();
	let isrRoofs = state.payroll.isrRoofs;
	
	try {
		const tasks = [];
		let isrBefore = isrRoofs[index - 1];
		let isrAfter = isrRoofs[index + 1];

		if(isrAfter){
			const isrA = {
				...isrAfter,
				minAmount: (isr.maxAmount + 0.01)
			}
			tasks.push(EditISRRoofs(isrA));
		}
		if(isrBefore){
			const isrB = {
				...isrBefore,
				maxAmount: (isr.minAmount - 0.01)
			}
			tasks.push(EditISRRoofs(isrB));
		}

		await Promise.all([ ...tasks, EditISRRoofs(isr) ]);
		await dispatch(GetISRRoofs());
	} catch (error) {
		return error;
	}
};

export const EditISRRoofs = async (isr) => {
	return await axios.patch(`/ISRRoofs/${isr.id}`, isr);
};

export const GetMedicalDeductions = () => async (dispatch) => {
	const filter = {
		where: {
			name: { inq: [IHSS.name, MEDICAL.name] }
		},
	};
	const response = await axios.get(`/ConfigurationTables?filter=${encodeURIComponent(JSON.stringify(filter))}`);
	dispatch({ type: FETCH_MEDICAL_DEDUCTIONS, medicalDeductions: response.data })
};

export const EditMedicalDeduction = (deduction) => async (dispatch, getState) => {
	let state = getState();
	let medicalDeductions = state.payroll.medicalDeductions;

	const response = await axios.put(`/ConfigurationTables/${deduction.id}`, deduction);
	dispatch({ type: FETCH_MEDICAL_DEDUCTIONS, medicalDeductions: substituteObjectInArrayByValue("id", deduction.id, medicalDeductions, response.data) });
};

export const GetDeductions = () => async (dispatch) => {
	const response = await axios.get(`/Deductions`)

	dispatch({ type: FETCH_DEDUCTIONS, deductions: response.data })
};

export const AddDeduction = (name) => (dispatch, getState) => {
	let state = getState();
	let deductions = state.payroll.deductions;
	return axios.post('/Deductions', { name })
		.then(response => dispatch({ type: FETCH_DEDUCTIONS, deductions: [response.data, ...deductions] }));
};

export const EditDeduction = (name, id) => (dispatch, getState) => {
	let state = getState();
	let deductions = state.payroll.deductions;

	return axios.patch(`/Deductions/${id}/updateName`, { name })
		.then((response) => dispatch({ type: FETCH_DEDUCTIONS, deductions: substituteObjectInArrayByValue("id", id, deductions, response.data) }));
};

export const GetGeneratePayrollInfo = (date) => (dispatch, getState) => {
	let state = getState();
	let medicalDeductions = state.payroll.medicalDeductions;

	return axios({
		url: `/Payrolls/getGenerateInfo`,
		method: 'GET',
		params: {
			date: moment(date).toISOString(),
			medicalDeductions
		}
	})
		.then(response => dispatch({ type: FETCH_GENERATE_PAYROLL_INFO, generateInfo: response.data }));
};

export const EditEmployeeDeduction = (id, amount) => (dispatch, getState) => {
	const state = getState();
	const generateInfo = state.payroll.generateInfo;
	return axios.patch(`/EmployeeDeductions/${id}`, { amount })
		.then((patchedDeduction) => {
			const { employeeId } = patchedDeduction.data;
			const generateInfoCopy = [...generateInfo];
			for (let employeeIndex = 0; employeeIndex < generateInfoCopy.length; employeeIndex++) {
				const employee = { ...generateInfoCopy[employeeIndex] };
				const currentDeductions = [...employee.currentDeductions];
				const emploId = employee.id;
				if (emploId === employeeId) {
					for (let deductionIndex = 0; deductionIndex < currentDeductions.length; deductionIndex++) {
						const deduction = { ...currentDeductions[deductionIndex] };
						const dedId = deduction.id;
						if (dedId === id) {
							deduction.amount = amount;
							currentDeductions[deductionIndex] = deduction;
							employee.currentDeductions = currentDeductions;
							generateInfoCopy[employeeIndex] = employee;
							break;
						}
					}
					break;
				}

			}
			dispatch({ type: FETCH_GENERATE_PAYROLL_INFO, generateInfo: generateInfoCopy });
		});
};

export const GeneratePayroll = (date, bonuses = [], medicalDeductions) => {
	return axios({
		url: `/Payrolls/generatePayroll`,
		method: 'POST',
		params: {
			bonuses,
			date: moment(date).toISOString(),
			medicalDeductions
		}
	});
};

export const LastPayrollDate = () => {
	const limit = 1;
	const order = "payrollDate DESC"
	const filter = { limit, order };
	return axios.get(`/Payrolls?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then(response => response.data.length > 0 ? response.data[0].payrollDate : "");
};

export const DownloadVouchers = (id) => () => {
	return axios({
		url: `/Payrolls/${id}/downloadVouchers`,
		method: 'GET',
		responseType: 'blob'
	})
		.then((response) => {
			const filename = response.headers['cache-control'];
			const arr = filename.split(',');
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', arr[arr.length - 1]);
			document.body.appendChild(link);
			link.click();
		});
};

export const DownloadExcel = (id) => () => {
	return axios({
		url: `/Payrolls/${id}/downloadExcel`,
		method: 'GET',
		responseType: 'blob'
	}).then((response) => {
		const filename = response.headers['cache-control'];
		const arr = filename.split(',');
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', arr[arr.length - 1]);
		document.body.appendChild(link);
		link.click();
	});
};