import { objectToArray, existsInArray, getObjectInObject } from "../../utilities/util";

export const TYPES = {
	INITIAL_BALANCE: {
		id: 0,
		intl: "InitialBalance"
	},
	PAYMENT: {
		id: 1,
		intl: "Payment"
	},
	CREDIT_NOTE: {
		id: 2,
		intl: "CreditNote"
	},
	TAX_EXEMPTION: {
		id: 3,
		intl: "TaxExemption"
	},
	SURPLUS: {
		id: 4,
		intl: "Surplus"
	},
};

export const getPaymenTypeList = () => {
	return objectToArray(TYPES);
}

export const PaymentMethods = {
	DEPOSIT: {
		id: 1,
		intl: "deposit"
	},
	CHECK: {
		id: 2,
		intl: "check"
	},
	CASH: {
		id: 3,
		intl: "cash"
	},
	CREDIT_CARD: {
		id: 4,
		intl: "creditCard"
	},
	AUTOMATIC_DEDUCTION: {
		id: 5,
		intl: "automaticDeduction"
	},
};

export function getPaymentMethod(id) {
    let TYPE = getObjectInObject("id", id, PaymentMethods);
    if (TYPE === undefined)
        return ({
            id: -1,
            intl: "typeDefault",
            textColor: "black",
            backgroundColor: "#cdcdcd"
        });
    return TYPE;
}

export const getPaymentMethodsList = () => {
	return objectToArray(PaymentMethods);
}

export const Banks = [
	"Banco Atlántida",
	"BAC Honduras",
	"Banco de Occidente",
	"Banco del Café",
	"Banco del País",
	"Banco Promérica",
	"Citi Bank",
	"BANRURAL",
	"Banco Ficensa",
	"Banco Ficohsa",
	"Banco Davivienda",
	"Banco Lafise",
	"Banco de los Trabajadores"
];

export const calculateTaxRetentionsAndFinalAmount = (form, invoice, isDollar = false) => {
	const { getFieldValue } = form;
	const { total, exchangeRate } = invoice;
	const taxRetention = getFieldValue('taxRetention') || [];
	const totalAmount = isDollar ? total : total * exchangeRate;
	const getAmount = () => getFieldValue('amount') || 0;
	const getSubtotal = () => totalAmount / 1.15;
	const getSubtotalIsv = () => getSubtotal() * 0.15;
	const getOnePercentSubtotal = () => getSubtotal() * 0.01;
	const getOnePercentTotal = () => totalAmount * 0.01;
	const getFinalAmount = () => {
		let final = getAmount();
		if (existsInArray("fifteenPercent", taxRetention)) final -= getSubtotalIsv();
		if (existsInArray("onePercentSubtotal", taxRetention)) final -= getOnePercentSubtotal();
		else if (existsInArray("onePercentTotal", taxRetention)) final -= getOnePercentTotal();
		return final;
	}
	return { fifteenPercent: getSubtotalIsv(), onePercentSubtotal: getOnePercentSubtotal(), onePercentTotal: getOnePercentTotal(), finalAmount: getFinalAmount() };
}