import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAuthMessages, getModalMessages, getFields, getErrorMessages, getRegistrationMessages, getClientMessages, getAddressMessages } from '../../../constants/messages';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Checkbox, Select, AutoComplete, Badge, Spin, DatePicker, Col } from 'antd';
import { injectIntl } from 'react-intl';
import { REGEX, ROLES } from '../../../constants/global';
import { states } from '../../../constants/address';
import { getObjectInArray, getNestedValue, arrayElementContainedInArray } from '../../../utilities/util';
import { CreateSearchClients } from '../actions';
import { STATUS } from '../constants';
import debounce from 'lodash/debounce';
import CircleBadge from '../../GlobalComponents/CircleBadge';
const moment = require('moment');

const { MonthPicker } = DatePicker;

const FormItem = Form.Item;
const Option = Select.Option;

let authMessages = getAuthMessages(),
	errorMessages = getErrorMessages(),
	fieldMessages = getFields(),
	modalMessages = getModalMessages(),
	RegistrationMessages = getRegistrationMessages(),
	ClientMessages = getClientMessages(),
	addressMessages = getAddressMessages();

const messages = {
	...authMessages,
	...modalMessages,
	...fieldMessages,
	...errorMessages,
	...RegistrationMessages,
	...ClientMessages,
	...addressMessages
};

const monthDateFormat = "MM-YYYY";

class BasicInfo extends React.Component {

	constructor(props) {
		super(props);
		this.handleClientSearch = debounce(this.handleClientSearch, 250);
		this.state = {
			loadingClients: false,
			clients: []
		};
	}

	getIntl = (str) => {
		return this.props.intl.formatMessage({ ...messages[str] });
	}

	getField = (field) => {
		const { client } = this.props;
		return getNestedValue(field, client);
	}

	handleFieldsSave = (nameAndValueArray) => {
		const { client, setState } = this.props;
		if (!Array.isArray(nameAndValueArray) || !nameAndValueArray.length)
			return;
		let clientCopy = { ...client };
		nameAndValueArray.forEach(element => {
			if (element.length > 1)
				clientCopy[element[0]] = element[1];
		});
		setState({ client: clientCopy });
	}

	handleClientNameSave = (name) => {
		const { client } = this.props;
		const { handleFieldsSave } = this;
		if (client.isIndividual) handleFieldsSave([["name", name], ["representative", name]]);
		else handleFieldsSave([["name", name]]);
	}

	handleIsIndividualSave = (checked) => {
		const { client } = this.props;
		const { handleFieldsSave } = this;
		const { name } = client;
		if (checked) handleFieldsSave([["isIndividual", checked], ["representative", name]]);
		else handleFieldsSave([["isIndividual", checked], ["representative", ""]]);
	}

	handleIsLpsSave = (checked) => {
		const { changeCurrency } = this.props;
		const { handleFieldsSave } = this;
		changeCurrency(checked);
		handleFieldsSave([["isLps", checked]]);
	}

	handleAutomaticInvoicingSave = (checked) => {
		const { props, handleFieldsSave } = this;
		const { resetFields } = props.form;
		if (checked) handleFieldsSave([["automaticInvoicing", checked]]);
		else {
			handleFieldsSave([["automaticInvoicing", checked], ["createProforma", checked], ["exonerated", checked]]);
			resetFields(["createProforma", "exonerated"]);
		}
	}

	handleCreateProformaSave = (checked) => {
		const { props, handleFieldsSave } = this;
		const { resetFields } = props.form;
		if (checked) handleFieldsSave([["createProforma", checked]]);
		else {
			handleFieldsSave([["createProforma", checked], ["exonerated", checked]]);
			resetFields(["exonerated"]);
		}
	}

	handleStateSave = (state) => {
		const { form, client } = this.props;
		let fullAddress = client.address || {};
		let newAddress = { ...fullAddress, state, city: undefined };
		this.handleFieldsSave([["address", newAddress]]);
		form.resetFields(["city"]);
	}

	handleCitySave = (city) => {
		const { client } = this.props;
		let fullAddress = client.address || {};
		let newAddress = { ...fullAddress, city };
		this.handleFieldsSave([["address", newAddress]]);
	}

	handleAddressSave = (address) => {
		const { client } = this.props;
		let fullAddress = client.address || {};
		let newAddress = { ...fullAddress, address };
		this.handleFieldsSave([["address", newAddress]]);
	}

	handleTierSave = (tierId) => {
		const { props, handleFieldsSave } = this;
		const { clientTiers } = props.state;
		const tier = getObjectInArray('id', parseInt(tierId), clientTiers);
		handleFieldsSave([["tierId", tierId], ["tier", tier]]);
	}

	renderTierPicker = () => {
		const { props, getIntl, getField, handleTierSave } = this;
		const { form, state } = props;
		const { getFieldDecorator } = form;
		const { clientTiers } = state;
		const tier = getField("tier");
		const tierId = tier ? tier.id : undefined;
		const options = clientTiers.map(({ id: ctId, name, badgeColor }) => <Option key={ctId} value={ctId}><CircleBadge color={badgeColor} text={name} /></Option>);
		return (
			<FormItem label={getIntl("clientTier")} >
				{
					getFieldDecorator(`tier`,
						{
							initialValue: tierId,
							rules: [{
								required: true,
								message: getIntl("clientTierRequiredError"),
							}],
							onChange: (value) => handleTierSave(value)
						}
					)(
						<Select
							className="job-full-component"
							placeholder={getIntl("clientTier")}
							dropdownMatchSelectWidth={false}
						>
							{options}
						</Select>
					)
				}
			</FormItem>
		);
	}

	renderRejectedMessage = () => {
		const { intl, rejected, rejectedMessage } = this.props;
		if ("rejected" in this.props && rejected) {
			return (
				<div className="client-activity-log-reject">
					{intl.formatMessage({ ...messages.rejectedForThisReason }) + rejectedMessage}
				</div>
			);
		}
	}

	renderIsIndividual = () => {
		const { props, getIntl, getField, handleIsIndividualSave } = this;
		const { form } = props;
		const { getFieldDecorator } = form;
		const isIndividual = getField("isIndividual");
		return (
			<FormItem>
				{
					getFieldDecorator('isIndividual',
						{
							initialValue: isIndividual,
							valuePropName: 'checked',
							onChange: (event) => handleIsIndividualSave(event.target.checked)
						}
					)(<Checkbox>{getIntl("individual")}</Checkbox>)
				}
			</FormItem>
		);
	}

	renderIsLps = () => {
		const { props, getIntl, getField, handleIsLpsSave } = this;
		const { form } = props;
		const { getFieldDecorator } = form;
		const isLps = getField("isLps");
		return (
			<FormItem>
				{
					getFieldDecorator('isLps',
						{
							initialValue: isLps,
							valuePropName: 'checked',
							onChange: (event) => handleIsLpsSave(event.target.checked)
						}
					)(<Checkbox>{getIntl("currency")}</Checkbox>)
				}
			</FormItem>
		);
	}

	renderAutomaticInvoicing = () => {
		const { props, getIntl, getField, handleAutomaticInvoicingSave } = this;
		const { form } = props;
		const { getFieldDecorator } = form;
		const automaticInvoicing = getField("automaticInvoicing");
		return (
			<FormItem>
				{
					getFieldDecorator('automaticInvoicing',
						{
							initialValue: automaticInvoicing,
							valuePropName: 'checked',
							onChange: (event) => handleAutomaticInvoicingSave(event.target.checked)
						}
					)(<Checkbox>{getIntl("automaticInvoicing")}</Checkbox>)
				}
			</FormItem>
		);
	}

	renderCreateProforma = () => {
		const { props, getIntl, getField, handleCreateProformaSave } = this;
		const { form } = props;
		const { getFieldDecorator } = form;
		const createProforma = getField("createProforma");
		const automaticInvoicing = getField("automaticInvoicing");
		return (
			<FormItem>
				{
					getFieldDecorator('createProforma',
						{
							initialValue: createProforma,
							valuePropName: 'checked',
							onChange: (event) => handleCreateProformaSave(event.target.checked)
						}
					)(<Checkbox disabled={!automaticInvoicing}>{getIntl("createProforma")}</Checkbox>)
				}
			</FormItem>
		);
	}

	handleNextInvoiceMonthSave = (date) => {
		const { handleFieldsSave } = this;
		if (!date) handleFieldsSave([["nextInvoiceMonth", ""]]);
		else {
			const m = moment(date).set({ date: 15, hour: 0, minute: 0, second: 0, millisecond: 0 });
			handleFieldsSave([["nextInvoiceMonth", m.toISOString()]]);
		}
	}

	disabledDate = (current) => {
		return moment(current).endOf('month') < moment().startOf('day');
	}

	renderInvoiceDatePicker = () => {
		const { props, getIntl, getField, handleNextInvoiceMonthSave, disabledDate } = this;
		const { form } = props;
		const { getFieldDecorator } = form;
		const nextInvoiceMonth = getField("nextInvoiceMonth");
		const automaticInvoicing = getField("automaticInvoicing");
		let dateMoment = moment(nextInvoiceMonth);
		return (
			<FormItem label={getIntl("nextInvoiceMonth")}>
				{
					getFieldDecorator(`nextInvoiceMonth`,
						{
							initialValue: dateMoment.isValid() ? dateMoment : null,
							rules: [{
								required: true,
								message: getIntl("nextInvoiceIsRequired"),
							}],
							onChange: handleNextInvoiceMonthSave
						}
					)(
						<MonthPicker
							className="job-partial-component"
							placeholder={getIntl("nextInvoiceMonth")}
							format={monthDateFormat}
							disabledDate={disabledDate}
							disabled={!automaticInvoicing}
							allowClear={false}
						/>
					)
				}
			</FormItem>
		);
	}

	renderExonerated = () => {
		const { props, getIntl, getField, handleFieldsSave } = this;
		const { form } = props;
		const { getFieldDecorator } = form;
		const exonerated = getField("exonerated");
		const createProforma = getField("createProforma");
		return (
			<FormItem>
				{
					getFieldDecorator('exonerated',
						{
							initialValue: exonerated,
							valuePropName: 'checked',
							onChange: (event) => handleFieldsSave([["exonerated", event.target.checked]])
						}
					)(<Checkbox disabled={!createProforma}>{getIntl("exonerated")}</Checkbox>)
				}
			</FormItem>
		);
	}

	renderAdminFields = () => {
		const { props, renderAutomaticInvoicing, renderCreateProforma, renderInvoiceDatePicker, renderExonerated, renderTierPicker } = this;
		const { userRoles, client } = props;
		const clientStatus = getNestedValue("status", client);
		const allowedRoles = [ROLES.MASTER, ROLES.ADMINISTRATOR];
		const isAllowed = arrayElementContainedInArray(allowedRoles, userRoles) || clientStatus !== STATUS.APPROVED;
		if (isAllowed) return (
			<div>
				<div className="client-basic-info-checkboxes">
					{renderAutomaticInvoicing()}
					<Col span={23} offset={1}>{renderCreateProforma()}</Col>
					<Col span={22} offset={2}>{renderExonerated()}</Col>
				</div>
				<Col span={23} offset={1}>{renderInvoiceDatePicker()}</Col>
				<Col span={24}>{renderTierPicker()}</Col>
			</div>
		);
	}

	renderCheckBoxes = () => {
		const { renderIsIndividual, renderIsLps } = this;
		return (
			<div className="client-basic-info-checkboxes">
				{renderIsIndividual()}
				{renderIsLps()}
			</div>
		);
	}

	handleClientSearch = (name) => {
		if (name.length <= 0) this.setState({ clients: [] });
		else {
			this.setState({ loadingClients: true, clients: [] });
			CreateSearchClients(name)
				.then((response) => this.setState({ loadingClients: false, clients: response }))
				.catch(() => this.setState({ loadingClients: false, clients: [] }));
		}
	}

	buildClientOptions = () => {
		const { state, getIntl } = this;
		const { clients } = state;
		if (clients.length < 1) return [];
		let options = [];
		let pending = [];
		let approved = [];
		let rejected = [];
		clients.forEach((client) => {
			const clientOption = { label: client.name, value: client.id.toString() };
			switch (client.status) {
				case STATUS.APPROVED:
					approved.push(clientOption);
					break;
				case STATUS.REJECTED:
					rejected.push(clientOption);
					break;
				case STATUS.PENDING:
				default:
					pending.push(clientOption);
					break;
			}
		});
		let addToOptions = (key, opt, color) => {
			if (opt.length > 0)
				options.push({ label: <span><Badge status={color} /> {getIntl(key)}</span>, options: opt });
		}
		if (approved.length > 0 || pending.length > 0 || rejected.length > 0) {
			addToOptions("approved", approved, "success");
			addToOptions("pending", pending, "warning");
			addToOptions("rejected", rejected, "error");
		}
		return options;
	}

	getSuggestedName = (id) => {
		let parsedId = parseInt(id);
		if (!id || isNaN(parsedId)) return id;
		const { clients } = this.state;
		let client = getObjectInArray("id", parsedId, clients);
		return client !== undefined ? client.name : id;
	}

	renderClient = () => {
		const { loadingClients } = this.state;
		const { intl, form, client } = this.props;
		const { getFieldDecorator } = form;
		const { name } = client;
		let options = this.buildClientOptions();
		return (
			<FormItem
				label={intl.formatMessage({ ...messages.clientName })}
			>
				{
					getFieldDecorator('name',
						{
							initialValue: name,
							trigger: 'onChange',
							getValueFromEvent: (value) => this.getSuggestedName(value),
							validateTrigger: ['onChange'],
							rules: [{
								required: true,
								message: intl.formatMessage({ ...messages.clientNameError }),
								whitespace: true,
							}],
							onChange: (value) => this.handleClientNameSave(value)
						}
					)(
						<AutoComplete
							className="job-full-component"
							dropdownMatchSelectWidth={false}
							options={options}
							placeholder={intl.formatMessage({ ...messages.clientName })}
							onSearch={this.handleClientSearch}
							notFoundContent={loadingClients ? <Spin size="small" /> : ""}
						/>
					)
				}
			</FormItem>
		);
	}

	renderRepresentative = () => {
		const { intl, form, client } = this.props;
		const { getFieldDecorator } = form;
		const { isIndividual, representative } = client;
		if (!isIndividual) {
			return (
				<FormItem
					label={intl.formatMessage({ ...messages.representative })}
				>
					{
						getFieldDecorator('representative',
							{
								initialValue: representative,
								trigger: 'onBlur',
								validateTrigger: ['onChange', 'onBlur'],
								rules: [{
									required: true,
									message: intl.formatMessage({ ...messages.representativeError }),
									whitespace: true,
								}],
								onBlur: (event) => this.handleFieldsSave([["representative", event.target.value]])
							}
						)(
							<Input placeholder={intl.formatMessage({ ...messages.representative })} />
						)
					}
				</FormItem>
			);
		}
	}

	renderRtnId = () => {
		const { intl, form, client } = this.props;
		const { getFieldDecorator } = form;
		const { isIndividual, rtn } = client;
		return (
			<FormItem
				label={isIndividual ? (intl.formatMessage({ ...messages.idWithRtn })) : (intl.formatMessage({ ...messages.rtn }))}
			>
				{
					getFieldDecorator('rtn',
						{
							initialValue: rtn,
							trigger: 'onBlur',
							validateTrigger: ['onChange', 'onBlur'],
							rules: [{
								required: true,
								whitespace: true,
								message: isIndividual ? (intl.formatMessage({ ...messages.idRtnEmptyError })) : (intl.formatMessage({ ...messages.rtnEmptyError }))
							}, {
								pattern: isIndividual ? (REGEX.idRTN) : (REGEX.rtn),
								message: isIndividual ? (intl.formatMessage({ ...messages.idRtnLengthError })) : (intl.formatMessage({ ...messages.rtnLengthError }))
							}],
							onBlur: (event) => this.handleFieldsSave([["rtn", event.target.value]])
						}
					)(
						<Input placeholder={isIndividual ? (intl.formatMessage({ ...messages.idWithRtn })) : (intl.formatMessage({ ...messages.rtn }))} />
					)
				}
			</FormItem>
		);
	}

	renderRtnCheckLink = () => {
		let { intl, client } = this.props;
		let { rtn } = client;
		let rtnPattern = (REGEX.rtn);
		if (rtnPattern.test(rtn)) {
			return (
				<a
					href={`https://placas.ip.gob.hn/enlace/consulta?Criterios=2&Identificacion=${rtn}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{intl.formatMessage({ ...messages.confirmRTN })}
				</a>
			);
		}
	}

	renderAddress = () => {
		const { intl, form, client } = this.props;
		const { getFieldDecorator } = form;
		let fullAddress = client.address || {};
		let { state, city, address } = fullAddress;
		let stateOptions = states.map((state, index) => {
			return (<Option key={index} value={state.name}>{state.name}</Option>);
		});
		let cityOptions = [];
		let stateObject = getObjectInArray("name", state, states);
		if (stateObject !== undefined && stateObject.cities !== undefined) {
			cityOptions = stateObject.cities.map((city, index) => {
				return (<Option key={index} value={city}>{city}</Option>);
			});
		}
		return (
			<div>
				<FormItem
					label={intl.formatMessage({ ...messages.state })} >
					{
						getFieldDecorator(`state`,
							{
								initialValue: state,
								trigger: 'onChange',
								validateTrigger: ['onChange'],
								rules: [{
									required: true,
									message: intl.formatMessage({ ...messages.stateError }),
								}],
								onChange: (value) => this.handleStateSave(value)
							}
						)(
							<Select
								className="job-full-component"
								showSearch
								placeholder={intl.formatMessage({ ...messages.state })}
								optionFilterProp="children"
								filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								dropdownMatchSelectWidth={false}
							>
								{stateOptions}
							</Select>
						)
					}
				</FormItem>
				<FormItem
					label={intl.formatMessage({ ...messages.city })} >
					{
						getFieldDecorator(`city`,
							{
								initialValue: city,
								trigger: 'onChange',
								validateTrigger: ['onChange'],
								rules: [{
									required: true,
									message: intl.formatMessage({ ...messages.cityError }),
								}],
								onChange: (value) => this.handleCitySave(value)
							}
						)(
							<Select
								className="job-full-component"
								showSearch
								placeholder={intl.formatMessage({ ...messages.city })}
								optionFilterProp="children"
								filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								dropdownMatchSelectWidth={false}
								disabled={state === undefined}
							>
								{cityOptions}
							</Select>
						)
					}
				</FormItem>
				<FormItem
					label={intl.formatMessage({ ...messages.address })}
				>
					{
						getFieldDecorator('address',
							{
								initialValue: address,
								trigger: 'onBlur',
								validateTrigger: ['onChange', 'onBlur'],
								rules: [{
									required: true,
									whitespace: true,
									message: intl.formatMessage({ ...messages.addressError })
								}, {
									max: 255,
									message: intl.formatMessage({ ...messages.addressLengthError })
								}],
								onBlur: (event) => this.handleAddressSave(event.target.value)
							}
						)(
							<Input placeholder={intl.formatMessage({ ...messages.address })} />
						)
					}
				</FormItem>
			</div>
		);

	}

	renderForm = () => {
		const { renderRejectedMessage, renderCheckBoxes, renderClient, renderRepresentative, renderRtnId, renderRtnCheckLink, renderAddress, renderAdminFields } = this;
		return (
			<Form layout='vertical'> 
				{renderRejectedMessage()}
				{renderCheckBoxes()}
				{renderClient()}
				{renderRepresentative()}
				{renderRtnId()}
				{renderRtnCheckLink()}
				{renderAddress()}
				{renderAdminFields()}
			</Form>
		);
	}

	render() {
		return (
			this.renderForm()
		);
	}
}

BasicInfo.defaultProps = {
	client: {},
	rejected: false
};

BasicInfo.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	state: PropTypes.object.isRequired,
	setState: PropTypes.func.isRequired,
	client: PropTypes.object.isRequired,
	rejected: PropTypes.bool.isRequired,
	rejectedMessage: PropTypes.string,
	changeCurrency: PropTypes.func.isRequired,
	userRoles: PropTypes.array.isRequired
};

const mapStateToProps = (state) => {
	return {
		userRoles: state.auth.roles
	};
};

export default connect(mapStateToProps)(injectIntl(BasicInfo));