import { ADD_UNIT_SUCCESS, EDIT_UNIT_SUCCESS, FETCH_UNIT, DELETE_UNIT_SUCCESS, SET_SEARCH_UNIT, CLEAR_SESSION } from './actionTypes';

const initState = {
	units: [],
    search: "",
};

const reducer = (state = initState, action) => {
	switch (action.type) {
        case FETCH_UNIT:
            return {
                ...state,
                units: action.units,
            };
		case ADD_UNIT_SUCCESS:
			return {
                ...state,
				units: [...state.units, action.units],
			};
        case EDIT_UNIT_SUCCESS:
            return {
                ...state,
                units: action.units,
            };
        case DELETE_UNIT_SUCCESS:
            return {
                ...state,
                units: action.units,
            };
        case SET_SEARCH_UNIT:
            return{
                ...state,
                search: action.search

            }
        case CLEAR_SESSION:
                return initState;
        default:
            return state;
    }
};
    
    export default reducer;
