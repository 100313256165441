import React from 'react';
import PropTypes from 'prop-types';
import { Card, Spin } from 'antd';
import { injectIntl } from 'react-intl';
import { getClientMessages } from '../../../../constants/messages';
import { getNestedValue } from '../../../../utilities/util';
import IconRender from '../../../Devices/Finder/Renders/IconRender';

let clientMessages = getClientMessages();

const messages = {
	...clientMessages
};

class LeasedDevicesEntry extends React.Component  {

	getIntl = (str) => {
		return this.props.intl.formatMessage({...messages[str]});
    }

	getField = (field) => {
        const { device } = this.props;
        return getNestedValue(field, device);
    }

    buildDeviceName = () => {
        const { device } = this.props;
        const pin = device.pin;
        const description = device.description || this.getIntl("noDescription");
        const icon = <IconRender icon={device.icon} renderName={false}/>;
        return (
            <div>
                <div className="client-dual-dragger-content">{icon}</div>
                <div className="client-dual-dragger-content">{pin}</div>
                <div className="client-dual-dragger-content">{description}</div>
            </div>
        );
    }

    renderCardBody = () => {
        const { buildDeviceName, getField } = this;
        const isLoading = getField("isLoading");
        return <Spin spinning={isLoading}>{buildDeviceName()}</Spin>
    }
    
    renderCard = () => {
        const { renderCardBody } = this;
        return (
            <Card className="client-dual-dragger-card" >
                {renderCardBody()}
            </Card>
        );
    }

	render(){
		return this.renderCard();
	}
}

LeasedDevicesEntry.defaultProps = {
    device: {}
};

LeasedDevicesEntry.propTypes = {
	intl: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
};

export default injectIntl(LeasedDevicesEntry);