import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Descriptions } from 'antd';
import { getDeviceMessages, getJobMessages } from '../../../../constants/messages';
import IconRender from '../../../Devices/Finder/Renders/IconRender';
import { getNestedValue } from '../../../../utilities/util';
import Embolden from '../../../GlobalComponents/Embolden';
const DescriptionItem = Descriptions.Item;

let deviceMessages = getDeviceMessages(),
    jobMessages = getJobMessages();

const messages = {
	...deviceMessages,
	...jobMessages
};
    
const isEmptyOrSpaces = (str) => str === null || str === undefined || str.match(/^ *$/) !== null;

class DeviceRender extends React.Component  {

    getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    embolden = (header, value) => <Embolden header={header} value={value} row/>;

	getField = (field) => {
        const { device } = this.props;
        return getNestedValue(field, device);
    }

    renderPin = () => {
        const { props, getIntl, getField, embolden } = this;
        const { hidePin } = props;
        if (hidePin) return null;
        const pin = getField("pin");
        return embolden(getIntl("pin"), pin);
    }

    renderImei = () => {
        const { props, getIntl, getField, embolden } = this;
        const { hideImei } = props;
        if (hideImei) return null;
        const imei = getField("imei");
        if (isEmptyOrSpaces(imei)) return embolden(getIntl("imei"), undefined);
        return embolden(getIntl("imei"), imei);
    }

    renderSim = () => {
        const { props, getIntl, getField, embolden } = this;
        const { hideSim } = props;
        if (hideSim) return null;
        const line = getField("sim.line");
        const serial = getField("sim.serial");
        if (isEmptyOrSpaces(line)) return embolden(getIntl("sim"), line);
        let str = `${getIntl("line")}: ${line}`;
        if (!isEmptyOrSpaces(serial)) str += `, ${getIntl("serial")}: ${serial}`;
        return embolden(getIntl("sim"), str);
    }

    renderIcon = () => {
        const { props, getIntl, getField, embolden } = this;
        const { hideIcon } = props;
        if (hideIcon) return null;
        const icon = getField("icon");
        const iconRender = <IconRender icon={icon}/>;
        return embolden(getIntl("icon"), iconRender);
    }

    renderDescription = () => {
        const { props, getIntl, getField, embolden } = this;
        const { hideDescription } = props;
        if (hideDescription) return null;
        const description = getField("description");
        return embolden(getIntl("vehicleDescription"), description);
    }

    renderAccesories = () => {
        const { props, getIntl, getField, embolden } = this;
        const { hideAccessories } = props;
        if (hideAccessories) return null;
        const hasPanicButton = getField("hasPanicButton");
        const canLockUnlockDoors = getField("canLockUnlockDoors");
        const canDisableEngine = getField("canDisableEngine");
        return (
            <React.Fragment>
                {embolden(getIntl("panicButton"), getIntl(hasPanicButton ? "yes" : "no"))}
                {embolden(getIntl("lockUnlockDoors"), getIntl(canLockUnlockDoors ? "yes" : "no"))}
                {embolden(getIntl("disableEngine"), getIntl(canDisableEngine ? "yes" : "no"))}
            </React.Fragment>
        );
    }

    fullRender = () => {
        const { props, getIntl, renderPin, renderImei, renderSim, renderIcon, renderDescription, renderAccesories } = this;
        const { header } = props;
        return (
            <Descriptions layout="vertical" size="small" bordered column={1}>
                <DescriptionItem label={header || getIntl("device")}>
                    {renderPin()}
                    {renderImei()}
                    {renderSim()}
                    {renderIcon()}
                    {renderDescription()}
                    {renderAccesories()}
                </DescriptionItem>
            </Descriptions>
        );
    }

	render(){
        return this.fullRender();
	}
}
 
DeviceRender.defaultProps = { 
    device: {},
	hidePin: false,
	hideImei: false,
	hideSim: false,
	hideIcon: false,
	hideDescription: false,
	hideAccessories: false
};

DeviceRender.propTypes = {
    intl: PropTypes.object.isRequired,
    device: PropTypes.object,
    header: PropTypes.string,
    hidePin: PropTypes.bool,
    hideImei: PropTypes.bool,
    hideSim: PropTypes.bool,
    hideIcon: PropTypes.bool,
    hideDescription: PropTypes.bool,
    hideAccessories: PropTypes.bool
};

export default injectIntl(DeviceRender);