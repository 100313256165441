import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { getactionMessages, getLotsMessages } from '../../constants/messages';
import AddOrEditModal from './AddOrEditModal';

let ActionMessages = getactionMessages(),
	LotMessages = getLotsMessages();

const messages = {
	...ActionMessages,
	...LotMessages,
};

const AddButton = () => {
	const intl = useIntl();
	const [visible, setVisible] = useState(false);

	const setModalVisibility = (visibility) => {
		setVisible(visibility);
	};

	return (
		<span>
			<Button
				className="editable-add-btn"
				type="primary"
				onClick={() => setModalVisibility(true)}
			>
				<PlusOutlined />
				{intl.formatMessage({...messages.add})}
			</Button>
			<AddOrEditModal
				title={intl.formatMessage({ ...messages.addUnitToLot})}
				visible={visible}
				onCancel={() => setModalVisibility(false)}
			/>
		</span>
	);
};

export default AddButton;
