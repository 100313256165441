import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getAuthMessages, getModalMessages, getFields, getErrorMessages} from '../constants/messages';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Select, Modal } from 'antd';
import {AddAccount} from '../actions/AccountAction';
import {injectIntl, FormattedMessage} from 'react-intl';
import {REGEX} from '../constants/global';
const FormItem = Form.Item;
const Option = Select.Option;
import {
	INIT_MODAL_REQUEST_ACCOUNT
} from '../constants/actionTypes';

let authMessages = getAuthMessages(),
	errorMessages = getErrorMessages(),
	fieldMessages = getFields(),
	modalMessages = getModalMessages();

const messages = {
	...authMessages,
	...modalMessages,
	...fieldMessages,
	...errorMessages
};

const AddAccountForm = ({intl, form,  showModal, handleCancel, addAccount, languageFilter, user, confirmLoading}) => {
	const { getFieldDecorator } = form;

	const languageOptions = languageFilter.map(lang => <Option key={lang.id}>{lang.name}</Option>);

	const handleOk = () => {
		form.validateFields((err, values) => {
			if (!err) {
				addAccount(values);
			}
		});

	};
	const checkPassword = (rule, value, callback) => {
		if (value && value !== form.getFieldValue('password')) {
			callback(`${intl.formatMessage( {...messages.confirmPassword} )}` );
		} else {
			callback();
		}
	};

	return ( 
		<Modal
			open={showModal}
			title= {intl.formatMessage( {...messages.addAccountTitle} )} 
			onOk={handleOk}
			onCancel={handleCancel}
			confirmLoading={confirmLoading}
			destroyOnClose={true}
			footer={[
				<Button key="submit" type="primary" size="large" loading={confirmLoading}  onClick={handleOk}>
					<FormattedMessage {...messages.send} />
				</Button>,
				<Button key="back" size="large" onClick={handleCancel}>
					<FormattedMessage {...messages.cancel} />
				</Button>,
			]}
			>
			<Form className="login-form col-8" layout='vertical'>
				{
						getFieldDecorator('realm', {
								rules: [{
									required: true,
									message: ' '
								}, ],
								initialValue: user.realm
							})(
								<Input type="hidden" />
							)
				}
				<FormItem 
								label={intl.formatMessage( {...messages.name} )} 
							>
					{
						getFieldDecorator('name', 
							{
								rules: [{
									required: true,
									whitespace: true,
									message: intl.formatMessage( {...messages.nameError}),
									max: 45
								}],
							}
						)(
							<Input placeholder={intl.formatMessage({...messages.namePlaceholder} )}  />
						)
					}
				</FormItem>
				<FormItem 
								label={intl.formatMessage( {...messages.username} )} 
							>
					{
						getFieldDecorator('username', 
							{
								rules: [{
									required: true,
									whitespace: true,
									message: intl.formatMessage( {...messages.usernameEmptyError})
								}, {
									pattern: REGEX.username,
									message: intl.formatMessage( {...messages.usernameInvalidError})
								}, {
									min: 5,
									message: intl.formatMessage( {...messages.usernameLengthError})
								}],
							}
						)(
							<Input placeholder={intl.formatMessage({...messages.usernamePlaceholder} )}  />
						)
					}
				</FormItem>
				<FormItem 
								label={intl.formatMessage( {...messages.email} )} 
							>
					{
						getFieldDecorator('email', 
							{
								rules: [{
									required: true,
									whitespace: true,
									message: intl.formatMessage( {...messages.emailEmptyError})
								}, {
									pattern: REGEX.email,
									message: intl.formatMessage( {...messages.emailInvalidError})
								}],
							}
						)(
							<Input placeholder={intl.formatMessage({...messages.emailPlaceholder} )}  />
						)
					}
				</FormItem>
				<FormItem 
								label={intl.formatMessage( {...messages.password} )} 
								extra={intl.formatMessage( {...messages.passwordNorify} )} 
							>
					{
						getFieldDecorator('password', 
							{
								rules: [
									{
										required: true,
										whitespace: true,
										message: intl.formatMessage( {...messages.passwordEmptyError})
									}, {
										pattern: REGEX.password,
										message: intl.formatMessage( {...messages.passwordInvalidError})
									}, {
										min: 6,
										message: intl.formatMessage( {...messages.passwordLengthError})
								}],
							}
						)( 
							<Input type = "password" placeholder={intl.formatMessage({...messages.passwordPlaceholder})}/>
						)
					}
				</FormItem> 
				<FormItem 
								label={intl.formatMessage( {...messages.passwordConfirm} )} 
							>
					{
						getFieldDecorator('confirm', {
							rules: [
									{
										required: true,
										message: intl.formatMessage( {...messages.confirmPassword}),
									}, {
										validator: checkPassword,
									}
								],
							}
						)( 

							<Input type = "password" placeholder={intl.formatMessage( {...messages.passwordConfirmPlaceholder} )} />
						)
					}
				</FormItem>
				<FormItem 
								label={intl.formatMessage( {...messages.languages} )} 
							>
					{
						getFieldDecorator('languageId', {
							rules: [
									{
										required: true
									}
								],
								initialValue:"1"
							}
						)(
							<Select 
								placeholder={intl.formatMessage( {...messages.languagesPlaceholder} )} 
							>
								{languageOptions}
							</Select>
						)
					}
				</FormItem>
			</Form>
		</Modal>
	);
};

AddAccountForm.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	showModal: PropTypes.bool.isRequired,
	confirmLoading: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired,
	addAccount: PropTypes.func.isRequired,
	languageFilter: PropTypes.array.isRequired,
	user: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
	return {
		"user": state.auth.user,
		"accounts": state.account.accounts,
		"languageFilter": state.language.languageFilter,
		"confirmLoading": state.account.confirmLoading
	};
};

const mapDispatchToProps = (dispatch) => {
		return {
			addAccount: (newAccount) => {
				dispatch({type:INIT_MODAL_REQUEST_ACCOUNT});
				dispatch(AddAccount(newAccount));
			}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(AddAccountForm)));