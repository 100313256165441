import React from 'react';
import PropTypes from 'prop-types';
import { DeleteOutlined, FilterOutlined, StopOutlined, AuditOutlined, ProfileOutlined, LinkOutlined } from '@ant-design/icons';
import { Table, Row, Col, Input, Popconfirm, Button, Tooltip, message, Select, Popover, Space, Typography, Dropdown } from 'antd';
import {
	injectIntl,
	FormattedMessage
} from 'react-intl';
import {
	getactionMessages,
	getFields,
	getErrorMessages,
	getPopConfirm,
	getTableMessages,
	getClientMessages,
	getModalMessages,
	getJobMessages
} from '../constants/messages';
import { objectIsEmpty } from '../utilities/util';
import 'rc-table/assets/index.css';
import 'rc-table/assets/animation.css';
import './CommonTable.css'
import { DisableMessages } from '../features/Clients/constants';
import { CHECKINTYPES } from '../features/Jobs/constants'

const Option = Select.Option;
const Search = Input.Search;

let errorMessages = getErrorMessages(),
	AccountMessages = getactionMessages(),
	PopConfirmMessages = getPopConfirm(),
	fieldMessages = getFields(),
	tableMessages = getTableMessages(),
	clientMessages = getClientMessages(),
	jobMessages = getJobMessages(),
	modalMessages = getModalMessages();

const messages = {
	...AccountMessages,
	...fieldMessages,
	...errorMessages,
	...PopConfirmMessages,
	...tableMessages,
	...modalMessages,
	...clientMessages,
	...jobMessages
};

class CommonTable extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedUsers: [],
			page: this.props.currentPage,
			pageSize: 10,
			pagination: {
				defaultPageSize: this.props.pageSize ? this.props.pageSize : 10,
				pageSize: this.props.pageSize ? this.props.pageSize : 10,
				total: this.props.recordCount,
				current: 1
			},
			searchText: this.props.searchText,
			extraFilter: {},
			reasonMessage: undefined,
			customMessage: false,
			order: 'ascend',
			orderBy: '',
		};
	}

	componentDidMount() {
		if ('getRecordCount' in this.props) this.props.getRecordCount();
		this.MySearchBarSearch(this.props.searchText);
	}

	componentDidUpdate(prevProps){
		if(prevProps.cleanSelectedRows != this.props.cleanSelectedRows && this.props.cleanSelectedRows){
			this.clearStates();
		}

		if(prevProps.searchText !== this.props.searchText){
			this.MySearchBarSearch(this.props.searchText);
			
			
		}
	}

	delete = () => {
		let len = this.state.selectedUsers.length;
		if (len <= 0) {
			message.warning(`${this.props.intl.formatMessage({ ...messages.deleteZero })}`);
			return;
		}
		if ('getRecordCount' in this.props) {
			this.props.delete(this.state.selectedUsers)
				.then(() => {
					const { page, pageSize, searchText, extraFilter } = this.state;
					const { getRecordCount, getRecords, search, recordCount } = this.props;
					let searchObject = (searchText.length > 0 || !objectIsEmpty(extraFilter)) ? { ...extraFilter, searchText } : {};
					getRecordCount(searchObject)
						.then(() => {
							let currentSize = recordCount % pageSize;
							let newPage = (currentSize - len) <= 0 ? page - 1 : page;
							objectIsEmpty(searchObject) ? getRecords(newPage, pageSize) : search(searchObject, newPage, pageSize);
							this.setState({ selectedUsers: [], page: newPage });
						});
				});
		} else {
			let selectedUsers = this.props.delete(this.state.selectedUsers);
			this.setState({ selectedUsers });

		}
	};

	searchTable = (value, extraFilter) => {
		const {	search, currentPage, preservePage, lowerCaseSearch} = this.props;
		let searchObject = {
			...extraFilter,
			searchText: value,
		};
		this.setState({
			searchText: value,
			extraFilter: extraFilter,
			page: currentPage ? currentPage : 1
		});
		if(preservePage){
			const paging = (value != "") ? currentPage : 1 ;
			paging == 1 ? this.setState({page: paging}) : null;
			search(searchObject, paging);
		}else if(lowerCaseSearch){
			searchObject = {...extraFilter,	searchText: value.trim().toLowerCase()};
			search(searchObject);  
		}else{
			search(searchObject);  
		}
	};

	onTableChange = (pagination, filters, sorter) => {
		const { props: {preserveFilter}, state: {search, extraFilter} } = this;
		const { current , pageSize } = pagination;
		let searchObject = {...extraFilter,	searchText: search };
		const order = sorter.order == undefined ? "ascend" : sorter.order;
		const orderBy = sorter.order == undefined ? "" : sorter.field;
		this.setState((prevState) => {
			if(prevState.pagination.current != pagination.current)
				return {pagination: {...prevState.pagination, ...pagination}};
			return false;
		});
		this.props.search(preserveFilter ? searchObject : search, current, pageSize, order, orderBy);
	};

	pagingOnChange = (page, pageSize) => {
		const {
			getRecords,
			search
		} = this.props;

		if (this.state.searchText.length > 0 || !objectIsEmpty(this.state.extraFilter)) {
			let searchObject = {
				...this.state.extraFilter,
				searchText: this.state.searchText
			};
			search(searchObject, page, pageSize);
		} else {
			getRecords(page, pageSize);
		}
		this.setState({
			page
		});
	};

	handleSelectUser = (selected) => {
		const { getRowSelected } = this.props;
		this.setState({
			selectedUsers: selected
		});
		if (getRowSelected)
			getRowSelected(selected);
	};

	clearStates = () => {
		const { props: { clearSelectedRows, getRecords }, state: { page, pageSize }} = this;
		this.setState({ selectedUsers: [] });
		clearSelectedRows(false);
		getRecords(page, pageSize);
	}

	onDisable = () => {
		const { onDisable } = this.props;
		onDisable();
		this.setState({ selectedUsers: [], reasonMessage: undefined, customMessage: false });
	};

	checkInJob = (checkInType) => {
		const { props: { getJobsSelected }, state: { selectedUsers }} = this
		getJobsSelected(selectedUsers, checkInType);
	};

	MyExtraFilterSearch = (value) => {
		let { mutuallyExclusiveFilter } = this.props;
		if (mutuallyExclusiveFilter !== undefined && mutuallyExclusiveFilter) this.searchTable("", value);
		else this.searchTable(this.state.searchText, value);
	}

	MySearchBarSearch = (value = "") => {
		let { mutuallyExclusiveFilter } = this.props;
		if (mutuallyExclusiveFilter !== undefined && mutuallyExclusiveFilter) this.searchTable(value, {});
		else this.searchTable(value, this.state.extraFilter);
	}

	renderExtraFilter = () => {
		let { filterComponent } = this.props;
		let clonedFilterComponentWithMoreProps = null;
		if (this.props.filterComponent)
			clonedFilterComponentWithMoreProps = React.cloneElement(
				filterComponent,
				{ onFilter: (value) => this.MyExtraFilterSearch(value) }
			);
		return (
			<div className="extraFilter">
				{clonedFilterComponentWithMoreProps}
			</div>
		);
	}

	renderSearchBar = () => {
		let { searchBarAddonAfterFunction, searchBarAddonAfterTooltip } = this.props;
		let searchComponent = (<Search
			placeholder={this.props.intl.formatMessage({ ...messages.searchPlaceholder })}
			onSearch={value => this.MySearchBarSearch(value)}
			onChange={value => this.setState({ searchText: value.target.value })}
			value={this.state.searchText}
			allowClear
		/>);
		if ('searchBarAddonAfterFunction' in this.props) {
			let icon = <FilterOutlined style={{ marginLeft: "10px", marginRight: "10px" }} onClick={searchBarAddonAfterFunction} />;
			if ('searchBarAddonAfterTooltip' in this.props) icon = (<Tooltip title={searchBarAddonAfterTooltip}>{icon}</Tooltip>);
			searchComponent = React.cloneElement(searchComponent, { addonAfter: icon });
		}
		return (<div className="search-addon-component">{searchComponent}</div>);
	}

	renderDeleteButton = () => {
		const { state: {selectedUsers}, props: { intl } } = this;
		if (selectedUsers.length != 0 && this.props.delete ) {
			return (
				<Col>
					<Popconfirm disabled={ this.props.disableDelete || false } title={intl.formatMessage({ ...messages.deleteConfirm })} onConfirm={this.delete} okText={this.props.intl.formatMessage({ ...messages.yes })} cancelText={intl.formatMessage({ ...messages.no })}>
						<Button disabled={ this.props.disableDelete || false } className="editable-add-btn">
							<DeleteOutlined />
							<FormattedMessage {...messages.deleteAmount} values={{ amount: selectedUsers.length }}/>
						</Button>
					</Popconfirm>
				</Col>
			);
		}
	}

	renderProformaButton = () => {
		return (
			<a key="jobsProforma" onClick={() => this.checkInJob(CHECKINTYPES.PROFORMA)}>
				<ProfileOutlined />
				<FormattedMessage {...messages.proforma} />
			</a>
		);
	};

	linkJobsButton = () => {
		const { props: { linkJobsSelected }, state: { selectedUsers }} = this;
		return (
			<a key="linkJobs" onClick={() => linkJobsSelected(selectedUsers)}>
				<LinkOutlined />
				<FormattedMessage {...messages.link} />
			</a>
		);
	};

	renderExtraComponents = () => {
		let { extraComponents } = this.props;
		if (extraComponents) {
			if (Array.isArray(extraComponents)) {
				let returnArray = [];
				extraComponents.forEach(component => {
					returnArray.push(
						<Col>
							{React.cloneElement(
								component,
								{ state: this.state }
							)}
						</Col>
					);
				});
				return returnArray;
			} else {
				return (
					<Col>
						{React.cloneElement(
							extraComponents,
							{ state: this.state }
						)}
					</Col>
				);
			}
		}
	}

	reasonDisableOnChange = (value) => {
		this.props.getReason(value);
		this.setState({ reasonMessage: value });
	};
	
	changeField = () => {
		this.setState({ reasonMessage: undefined });
		this.setState(prevState => ({ customMessage: !prevState.customMessage }) ) 
	}

	render() {
		const { state, props } = this;
		const { pagination, selectedUsers, reasonMessage, customMessage, pageSize, page} = state;
		const { preserveSelectedRowKeys, recordCount, getCheckboxProps, noDataPlaceholder, Add, disableSearchBar, columns, dataSource, hideCheckboxes,
			expandedRow, loading, rowClassName, showDisableBtn, showCheckInBtn, AssignTechnical } = props;
		const commonPagination = {
			'defaultPageSize': pageSize,
			'onChange': this.pagingOnChange,
			'total': recordCount,
			'current': page
		};
		const rowSelection = {
			onChange: this.handleSelectUser,
			type: "checkbox",
			selectedRowKeys: selectedUsers,
			getCheckboxProps: getCheckboxProps,
			preserveSelectedRowKeys: preserveSelectedRowKeys,
		};
		const options = DisableMessages.map((opt, index) => <Option key={index} value={opt}>{opt}</Option>);
		let message = customMessage ? "predeterminedMessage" : "customMessage";
		const contentPopover =
			<React.Fragment>
				<Col className="disabledPopOver">
					<Space >
						<Typography.Text type="danger" strong={true}>*</Typography.Text>
						<Typography.Text strong={true}> {this.props.intl.formatMessage({ ...messages.disableMessage })}:</Typography.Text>
						{
							!customMessage ?
								<Select
									placeholder={this.props.intl.formatMessage({ ...messages.disableMessage })}
									onChange={this.reasonDisableOnChange}
									dropdownMatchSelectWidth={false}
								>
									{options}
								</Select>
								:
								<Input
									placeholder={this.props.intl.formatMessage({ ...messages.disableMessage })}
									onChange={(event) => this.reasonDisableOnChange(event.target.value)}
								/>
						}
					</Space>
				</Col>
				<Col className="disabledPopOver">
					<Space direction="vertical">
						<a onClick={this.changeField}>{this.props.intl.formatMessage({ ...messages[message] })}</a>
						<Button
							type="primary"
							disabled={!reasonMessage}
							onClick={this.onDisable}
						>
							{this.props.intl.formatMessage({ ...messages.confirmDisable })}
						</Button>
					</Space>
				</Col>
			</React.Fragment>
		;
		const showAdd = Add && <Col>{Add}</Col>;
		const showAssignTechnical = AssignTechnical && <Col>{AssignTechnical}</Col>;

		const showSearchBar = !disableSearchBar && <Row><Col span={24}>{this.renderSearchBar()} </Col></Row>;
		const showDisable = showDisableBtn && selectedUsers.length ? (<Col>
			<Popover
				content={contentPopover}
				trigger="click"
			>
				<Button className="extraButtom">
					<StopOutlined /> 
					<FormattedMessage
						{...messages.disableAmount}
						values={{ amount: selectedUsers.length }}
					/>
				</Button>
			</Popover>
		
		</Col >) : null; 

		const items = [
			{
				key: '1',
				label: this.renderProformaButton()
			},
			{
				key: '2',
				label: this.linkJobsButton()
			}
		];

		const showCheckIn = showCheckInBtn && selectedUsers.length > 0 && (
			<Col>
				<Dropdown.Button 
					className="extraButtom" 
					onClick={() => this.checkInJob(CHECKINTYPES.INVOICE)}
					menu={{ items }}
				>
					<AuditOutlined />
					<FormattedMessage
						{...messages.invoice}
						values={{ amountJobs: selectedUsers.length }}
					/>
				</Dropdown.Button> 
			</Col>
		);

		let extraProps = {};
		if ('noDataPlaceholder' in this.props) extraProps.locale = { emptyText: noDataPlaceholder };
		return (
			<div className="table">
				<Row className="toolbar">
					<Col flex='auto'>
						<Row className="actions">
							{showAdd}
							{showAssignTechnical}
							{this.renderDeleteButton()}
							{this.renderExtraComponents()}
							{showDisable}
							{showCheckIn}
						</Row>
					</Col>
					<Col flex='auto'>
						<Row wrap={false} justify="end">
							<Col >
								{this.renderExtraFilter()}
							</Col>
							<Col span={10}>{showSearchBar}</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Table
							rowKey={record => record.id}
							columns={columns}
							dataSource={dataSource}
							pagination={this.props.pagination ? {...pagination, total: this.props.recordCount} : commonPagination}
							rowSelection={hideCheckboxes ? null : rowSelection}
							expandable={{
								expandedRowRender: (expandedRow),
							}}
							loading={loading}
							scroll={{ x: '100%' }}
							bordered
							rowClassName={rowClassName}
							onChange={this.props.pagination ? this.onTableChange : null}
							{...extraProps}
						/>
					</Col>
				</Row>
			</div>
		);
	}
}

CommonTable.defaultProps = {
	filterComponent: null,
	hideCheckboxes: false,
	rowClassName: "",
	expandedRow: undefined,
	disableSearchBar: false,
	showDisableBtn: false,
	preserveSelectedRowKeys: false,
	pagination: false,
	preservePage: false,
};

CommonTable.propTypes = {
	columns: PropTypes.array.isRequired,
	dataSource: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
	intl: PropTypes.object.isRequired,
	Add: PropTypes.object,
	AssignTechnical: PropTypes.object,
	expandedRow: PropTypes.func,
	delete: PropTypes.func,
	disableDelete: PropTypes.bool,
	getRecords: PropTypes.func.isRequired,
	search: PropTypes.func.isRequired,
	getCheckboxProps: PropTypes.func.isRequired,
	recordCount: PropTypes.number.isRequired,
	filterComponent: PropTypes.node,
	extraComponents: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object
	]),
	searchBarAddonAfterFunction: PropTypes.func,
	searchBarAddonAfterTooltip: PropTypes.string,
	noDataPlaceholder: PropTypes.string,
	mutuallyExclusiveFilter: PropTypes.bool,
	disableSearchBar: PropTypes.bool,
	hideCheckboxes: PropTypes.bool,
	rowClassName: PropTypes.string,
	getRecordCount: PropTypes.func,
	onDisable: PropTypes.func,
	showDisableBtn: PropTypes.bool,
	showCheckInBtn: PropTypes.bool,
	getRowSelected: PropTypes.func,
	getJobsSelected: PropTypes.func,
	linkJobsSelected: PropTypes.func,
	getReason: PropTypes.func,
	preserveSelectedRowKeys: PropTypes.bool,
	searchText: PropTypes.string,
	pageSize: PropTypes.number,
	pagination: PropTypes.bool.isRequired,
	currentPage: PropTypes.number,
	preservePage: PropTypes.bool,
	preserveFilter: PropTypes.bool,
	cleanSelectedRows: PropTypes.bool,
	clearSelectedRows: PropTypes.func,
	lowerCaseSearch: PropTypes.bool,
};

export default injectIntl(CommonTable);
