import { connect } from 'react-redux';
import Login from '../components/Login';
import { doLogin } from '../actions/AuthAction';

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		"hasError": state.auth.hasError,
		"error": state.auth.error
	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		login: ( user, password ) => {
			dispatch(doLogin( user, password ));
		},
	};
};

export default  connect(mapStateToProps, mapDispatchToProps)(Login);