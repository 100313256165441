import React, { useEffect, useState } from 'react'
import { Tabs, message } from 'antd';
import { DotChartOutlined, TableOutlined } from '@ant-design/icons';
import MonthlyServicePriceReport from './MonthlyServicePriceReport';
import { DownloadCustomPriceReport, getClientOwnDevicesService } from '../../actions';
import { useIntl } from 'react-intl';
import { getErrorMessages, getReportMessages } from '../../../../constants/messages';
import CustomPriceGraph from '../CustomPriceGraph';

let errorMessages = getErrorMessages(),
    reportMessages = getReportMessages();


const messages = {
    ...errorMessages,
    ...reportMessages,
}

const MonthlyServicePricePage = () => {
    const intl = useIntl();
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(false);
    const monthlyServiceCode = "S0014";

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const response = await getClientOwnDevicesService()
                setClients(response);
            } catch (error) {
                message.error(intl.formatMessage({ ...messages.commonError }));
            } finally {
                setLoading(false);
            }
        })();
    }, [])

    const downloadReport = () => {
        setLoading(true);
        DownloadCustomPriceReport(clients, monthlyServiceCode)
            .then(() => message.success(intl.formatMessage({...messages.exportSuccess})))
            .catch(() => message.error(intl.formatMessage({...messages.exportError})))
            .finally(() => setLoading(false));
    }

    const items = [
        {
            key: '1',
            label: <TableOutlined />,
            children: <MonthlyServicePriceReport  
                        clients={clients} 
                        loading={loading} 
                        downloadReport={downloadReport}
                    />
        },
        {
            key: '2',
            label: <DotChartOutlined />,
            children: <CustomPriceGraph clients={clients} />
        },
    ];

    return (
        <div className="view">
            <Tabs
                defaultActiveKey="1"
                items={items}
            />
        </div>
    )
}

export default MonthlyServicePricePage
