import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Descriptions } from 'antd';
import { getDeviceMessages } from '../../../../constants/messages';
import IconRender from './IconRender';
import { getNestedValue } from '../../../../utilities/util';
import Embolden from '../../../GlobalComponents/Embolden';
import SimRender from './SimRender';
import DescriptionsCombiner from '../../../Jobs/ViewJob/DefaultViews/DescriptionsCombiner';
const DescriptionItem = Descriptions.Item;

let deviceMessages = getDeviceMessages();

const messages = {
    ...deviceMessages
};

class DeviceRender extends React.Component {

    getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

    embolden = (header, value) => {
        const { getIntl } = this;
        return <Embolden header={getIntl(header)} value={value} row />;
    }

    renderClients = () => {
        const { props, getIntl, embolden } = this;
        const { device, hideClients } = props;
        const { client } = device;
        if (!hideClients)
            return (
                <DescriptionItem label={getIntl("client")}>
                    {embolden("clientNameIronhide", getNestedValue("ironhideClient.name", client))}
                    {embolden("clientNameOptimus", getNestedValue("name", client))}
                </DescriptionItem>
            );
        return null;
    }

    renderDeviceInfo = () => {
        const { props, getIntl, embolden } = this;
        const { device } = props;
        const { pin, imei, description, active, icon, modelProfile } = device;
        let act = active ? "true" : "false";
        return (
            <DescriptionItem label={getIntl("device")}>
                {embolden("pin", pin)}
                {embolden("imei", imei)}
                {embolden("description", description)}
                {embolden("active", getIntl(act))}
                {embolden("icon", <IconRender icon={icon} />)}
                {embolden("deviceModel", getNestedValue("deviceModel.description", modelProfile))}
                {embolden("modelProfile", getNestedValue("description", modelProfile))}
            </DescriptionItem>
        );
    }

    renderClientAndDeviceDescription = () => {
        const { props, renderClients, renderDeviceInfo } = this;
        const { bordered } = props;
        return (
            <Descriptions layout="vertical" size="small" bordered={bordered} column={1}>
                {renderClients()}
                {renderDeviceInfo()}
            </Descriptions>
        );
    }

    renderInfo = () => {
        const { props, renderClientAndDeviceDescription } = this;
        const { device, hideSimInfo, bordered } = props;
        if (!hideSimInfo && device.sim)
            return (
                <DescriptionsCombiner>
                    {renderClientAndDeviceDescription()}
                    <SimRender sim={device.sim} bordered={bordered} hideDeviceInfo />
                </DescriptionsCombiner>
            );
        return renderClientAndDeviceDescription();
    }

    render() {
        return (this.renderInfo());
    }
}

DeviceRender.defaultProps = {
    device: {},
    hideClients: false,
    bordered: true,
    hideSimInfo: false
};

DeviceRender.propTypes = {
    intl: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
    hideClients: PropTypes.bool.isRequired,
    bordered: PropTypes.bool.isRequired,
    hideSimInfo: PropTypes.bool.isRequired
};

export default injectIntl(DeviceRender);