import React from 'react'
import PropTypes from 'prop-types';
import { Form, Input } from 'antd'
import { useIntl } from 'react-intl';
import { getFields } from '../../../../../constants/messages';
const FormItem = Form.Item;

let FieldsMessages = getFields();

const messages = {
    ...FieldsMessages,
};

const EditMedicalDeductionForm = ({form, record}) => {
	const intl = useIntl();

    return (
        <Form
            ref={form}
            layout='vertical'
            initialValues={{  ...record, value: record ? record.value : null }}
        >
            <FormItem name="id" hidden={true}>
                <Input hidden={true} />
            </FormItem>
            <FormItem name="name" hidden={true}>
                <Input hidden={true} />
            </FormItem>
            <FormItem
                name="value"
                rules={[{ required: true }]}
                label={intl.formatMessage({...messages.amount})}
            >
                <Input placeholder={intl.formatMessage({...messages.amount})} />     
            </FormItem>
        </Form>
    )
}

EditMedicalDeductionForm.propTypes = {
    form: PropTypes.object.isRequired,
    record: PropTypes.object
};

export default EditMedicalDeductionForm
