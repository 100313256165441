import React from 'react';
import PropTypes from 'prop-types';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Col, Card, Dropdown, message, Button, Popover, DatePicker,Tooltip } from 'antd';
import { injectIntl } from 'react-intl';
import { getPayrollMessages, getactionMessages, getFields, getModalMessages, getLanguageIntl } from '../../constants/messages';
import { LastPayrollDate } from './actions';
import CommonTable from '../../components/CommonTable';
import MobileCommonTable from '../../components/MobileCommonTable';
import Mayre from 'mayre';
import { isMobile } from '../../utilities/util';
import { disabledNonPayDates } from '../Employees/constants';
import CircleBadge from '../GlobalComponents/CircleBadge';
import { withRouter } from 'react-router-dom';
const moment = require('moment');

let payrollMessages = getPayrollMessages(),
	actionMessages = getactionMessages(),
	fieldMessages = getFields(),
	modalMessages = getModalMessages(),
	intlMessages = getLanguageIntl();

const messages = {
	...payrollMessages,
	...actionMessages,
	...fieldMessages,
	...modalMessages,
	...intlMessages
};
const dateFormat = "DD-MM-YYYY";
const invertedDateFormat = "YYYY-MM-DD";

const enabledColor = "#1890ff";
const disabledColor = "#cdcdcd";
const lastPayrollDateColor = "#faad14";

class Employees extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			generateDate: "",
			lastPayrollDate: ""
		};
	}

	componentDidMount() {
		const { getInfo } = this;
		getInfo();
	}

	getInfo = () => {
		const { getIntl, setLoading } = this;
		setLoading(true);
		LastPayrollDate()
			.then((response) => {
				if (response.length) this.setState({ lastPayrollDate: response });
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
				message.error(getIntl("getLastPayrollDateError"));
			});
	}

	getIntl = (str) => {
		return this.props.intl.formatMessage({ ...messages[str] });
	}

	setLoading = (isLoading) => {
		this.setState({ isLoading });
	}

	getRecords = (search = {}, page, pageSize) => {
		const { getIntl, setLoading, props } = this;
		const { getRecords } = props;
		setLoading(true);
		getRecords(search, page, pageSize)
			.then(() => setLoading(false))
			.catch(() => {
				setLoading(false);
				message.error(getIntl("getPayrollsError"));
			});
	}

	renderViewButton = (id) => {
		const { getIntl } = this;
		return <a onClick={() => this.props.history.push(`payrolls/view/${id}`)}>{getIntl("view")}</a>;
	}

	customDateRender = (current) => {
		const { state, getIntl, disabledDateWithLastDay } = this;
		const { lastPayrollDate } = state;
		const lastMoment = moment(lastPayrollDate).utc();
		const isPayday = !disabledDateWithLastDay(current);
		let tooltipTitle = getIntl("datePickerDisabled");
		const style = {};
		if (isPayday) {
			tooltipTitle = getIntl("datePickerEnabled");
			style.border = `1px solid ${enabledColor}`;
		}
		if (lastMoment.isValid() && lastMoment.isSame(current, 'day')) {
			tooltipTitle = getIntl("datePickerLastPayrollDate");
			style.border = `1px solid ${lastPayrollDateColor}`;
		}
		const dateRender = (
			<div className="ant-calendar-date" style={style}>
				{current.date()}
			</div>
		);
		return <Tooltip title={tooltipTitle} mouseEnterDelay={1}>{dateRender}</Tooltip>;
	}

	renderDatePickerLegend = () => {
		const { getIntl } = this;
		return (
			<div>
				<div><CircleBadge color={enabledColor} text={getIntl("datePickerEnabled")} /></div>
				<div><CircleBadge color={lastPayrollDateColor} text={getIntl("datePickerLastPayrollDateShort")} /></div>
				<div><CircleBadge color={disabledColor} text={getIntl("datePickerDisabledShort")} /></div>
			</div>
		)
	}

	disabledDateWithLastDay = (current) => {
		const { state } = this;
		const { lastPayrollDate } = state;
		const lastMoment = moment(lastPayrollDate).utc();
		const isNotPayday = disabledNonPayDates(current);
		let isLastDayOrBefore = false;
		if (lastMoment.isValid() && (current.isSameOrBefore(lastMoment, 'day') || lastMoment.isSame(current, 'day'))) isLastDayOrBefore = true;
		return isNotPayday || isLastDayOrBefore;
	}

	renderDatePickerForm = () => {
		const { state, getIntl, customDateRender, renderDatePickerLegend, disabledDateWithLastDay } = this;
		const { generateDate } = state;
		let disabled = true;
		if (generateDate && generateDate.length > 0) disabled = false;
		return (
            <div>
				<div>
					<DatePicker
						format={dateFormat}
						disabledDate={disabledDateWithLastDay}
						showToday={false}
						onChange={(value) => this.setState({ generateDate: value ? value.format(invertedDateFormat) : "" })}
						dateRender={customDateRender}
						renderExtraFooter={renderDatePickerLegend}
					/>
				</div>
				<div style={{ paddingTop: "0.5em" }}>
					<Button type="primary" disabled={disabled} onClick={() => this.props.history.push(`payrolls/generatePayroll/${generateDate}`)}>
						<PlusOutlined /> {getIntl("accept")}
					</Button>
				</div>
			</div>
        );
	}

	renderAddButton = () => {
		const { getIntl, renderDatePickerForm } = this;
		const datePicker = renderDatePickerForm();
		return (
            <Popover key="generate_payroll" content={datePicker} placement="bottom" trigger="click">
				<Button type="primary">
					<PlusOutlined /> {getIntl("previewPayroll")}
				</Button>
			</Popover>
        );
	}

	renderDeductionsButton = () => {
		const { getIntl } = this;
		return <Button onClick={() => this.props.history.push(`payrolls/deductions`)}>{getIntl("deductions")}</Button>;
	}

	dateRender = (date) => {
		if (!date) return;
		const m = moment(date).utc();
		return new Date(m.year(), m.month(), m.date()).toLocaleDateString(this.getIntl("intl"));
	}

	getColumns = () => {
		const { getIntl, renderViewButton, dateRender } = this;
		return [
			{
				title: getIntl("payrollDate"),
				dataIndex: 'payrollDate',
				key: 'payrollDate',
				render: (date) => dateRender(date)
			}, {
				title: getIntl("creationDate"),
				dataIndex: 'creationDate',
				key: 'creationDate',
				render: (date) => dateRender(date)
			}, {
				title: getIntl("actions"),
				dataIndex: 'id',
				render: (id) => <span>{renderViewButton(id)}</span>
			}
		];
	}

	getCard = (record) => {
		const { getIntl, renderViewButton } = this;
		const { id, name } = record;
		let title = <Row align="middle"><Col span={14}>{name}</Col></Row>;
		const items = [
			{
				key: '1',
				label: renderViewButton(id)
			},
		];
	
		const action = (
			<Dropdown menu={{items}} trigger={['click']}>
				<a className="ant-dropdown-link" href="#">{getIntl("actions")}<DownOutlined /></a>
			</Dropdown>
		);

		return (
			<Card key={id} title={title} extra={action} style={{ width: "100%" }}>
				<Row type="flex" justify="center" align="middle">
					<Col span={8}><strong>Hello</strong></Col>
					<Col span={8}><strong>, is it me you&apos;re looking for</strong></Col>
				</Row>
			</Card>
		);
	}

	fullRender = () => {
		const { state, props, getColumns, getRecords, renderAddButton, getCard, renderDeductionsButton } = this;
		const { records, recordCount, getRecordCount } = props;
		const { isLoading } = state;
		return (
			<div className="view">
				<Mayre
					of={
						<CommonTable
							columns={getColumns()}
							dataSource={records}
							search={(search, page, pageSize) => getRecords(search, page, pageSize)}
							recordCount={recordCount}
							getRecords={(page, pageSize) => getRecords({}, page, pageSize)}
							loading={isLoading}
							getCheckboxProps={() => ({})}
							Add={renderAddButton()}
							getRecordCount={getRecordCount}
							hideCheckboxes
							disableSearchBar
							extraComponents={renderDeductionsButton()}
						/>
					}
					or={
						<MobileCommonTable
							dataSource={records}
							search={(search, page, pageSize) => getRecords(search, page, pageSize)}
							recordCount={recordCount}
							loading={isLoading}
							card={getCard}
							getRecords={(page, pageSize) => getRecords({}, page, pageSize)}
							Add={renderAddButton()}
							disableSearchBar
						/>
					}
					when={!isMobile()}
				/>
			</div>
		);
	}

	render() {
		return this.fullRender();
	}
}

Employees.propTypes = {
	intl: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	getRecords: PropTypes.func.isRequired,
	records: PropTypes.array.isRequired,
	getRecordCount: PropTypes.func.isRequired,
	recordCount: PropTypes.number.isRequired,
};

export default withRouter(injectIntl(Employees));