import React from 'react';
import PropTypes from 'prop-types';
import {AppContainer} from 'react-hot-loader';
import Root from './Root';

const Language = ({lang, store,persistor, locale}) => (
				<AppContainer>
					<Root 
						store={store} 
						persistor={persistor}
						locale={locale} 
						lang={lang}
					/>
				</AppContainer>
			);

Language.propTypes = {
	store: PropTypes.object.isRequired,
	persistor: PropTypes.object.isRequired,
	locale: PropTypes.object.isRequired,
	lang: PropTypes.string.isRequired
};

export default Language;