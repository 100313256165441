import { ADD_CONTACT_SUCCESS, FETCH_CONTACTS } from './actionTypes';

const initState = {
	contacts: [],
};

const reducer = (state = initState, action) => {
	switch (action.type) {
        case FETCH_CONTACTS:
            return {
                ...state,
                contacts: action.contacts,
            };
		case ADD_CONTACT_SUCCESS:
			return {
                ...state,
				contacts: [...state.contacts, action.contacts],
			};
        default:
            return state;
    }
};
    
export default reducer;
