import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import { injectIntl } from 'react-intl';
import { getErrorMessages, getJobMessages } from '../../../../constants/messages';

let jobMessages = getJobMessages(),
    errorMessages = getErrorMessages();

const messages = {
	...jobMessages,
	...errorMessages
};

const FormItem = Form.Item;

class MissingWON extends React.Component {

    getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    renderInfo = () => {
        const { renderInfo } = this.props;
        if (renderInfo) return renderInfo();
    }
    
    renderWONField = () => {
        const { props, getIntl } = this;
        const { form, jobId } = props;
		const { getFieldDecorator } = form;
		return (
            <FormItem label={getIntl("workOrderNumber")} >
            {
                getFieldDecorator('won',  {
                        rules: [{
                                required: true,
                                message: getIntl("wonNeeded")
                            }],
                        initialValue: jobId
                    })(
                    <Input disabled defaultValue={jobId} className="job-full-component" placeholder={getIntl("workOrderNumber")}/>
                )
            }
            </FormItem>
        );
    }

    fullRender = () => {
        const { renderInfo, renderWONField } = this;
        return (
            <div>
                {renderInfo()}
                {renderWONField()}
            </div>
        );
    }
    
	render() {
        return this.fullRender();
    }
}

MissingWON.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    jobId: PropTypes.number.isRequired,
	renderInfo: PropTypes.func,
};

export default injectIntl(MissingWON);