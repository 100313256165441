import {
	FETCH_LAST_ACTIVE_CLIENTS,
	FETCH_ACTIVE_CLIENT_COUNT,
	FETCH_LAST_ACTIVE_DEVICES,
	FETCH_ACTIVE_DEVICE_COUNT
} from './actionTypes';

import {
    CLEAR_SESSION,
	CLEAN_REDUCER
} from '../../constants/actionTypes';

const initState = {
    clients: [],
    clientCount: 0,
    devices: [],
    deviceCount: 0
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case FETCH_LAST_ACTIVE_CLIENTS:
			return {
				...state,
				clients: action.clients
			};
        case FETCH_ACTIVE_CLIENT_COUNT:
            return {
                ...state,
                clientCount: action.clientCount
            };
		case FETCH_LAST_ACTIVE_DEVICES:
            return {
                ...state,
                devices: action.devices
            };
        case FETCH_ACTIVE_DEVICE_COUNT:
            return {
                ...state,
                deviceCount: action.deviceCount
            };
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;