import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage }  from 'react-intl';
import { getFields, getErrorMessages, getAuthMessages } from '../constants/messages';
import { ChangePassword } from '../actions/AuthAction';
import { LockOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col } from 'antd';
import { REGEX } from '../constants/global';
const FormItem = Form.Item;


let fieldsMessages = getFields(),
	authMessages = getAuthMessages(),
	errorMessages = getErrorMessages();

let messages = {
	...fieldsMessages,
	...authMessages,
	...errorMessages
};


const ResetPasswordPage = ( {intl, form, changePassword} ) => {

	const { getFieldDecorator } = form;
	const handleSubmit = (e) => {
		e.preventDefault();
		form.validateFields((err, values) => {
			if (!err) {
				changePassword(values);
			}
		});
	};

	const checkPassword = (rule, value, callback) => {

		if (value && value !== form.getFieldValue('password')) {
			callback(`${intl.formatMessage( {...messages.confirmPassword} )}` );
		} else {
			callback();
		}
	};

	const parsedQuery = new URLSearchParams(location.search);
    const access_token = parsedQuery.get('access_token');

	return (
        <Row className="login">
			<Col span={4} offset={10}>
				<h1>
					<FormattedMessage {...messages.resetPassword} />
				</h1>
				<br/>
				<Form onSubmit={handleSubmit} className="login-form col-8">
					{
						getFieldDecorator('access_token', {
								rules: [{
									required: true,
									message: 'Access token missing!'
								}, ],
								initialValue: access_token
							})(
								<Input type="hidden" />
							)
					}
					<FormItem
								extra={intl.formatMessage( {...messages.passwordNorify} )} 
					>
					{
						getFieldDecorator('password', {
								rules: [{
									required: true,
									message: ' ',
									pattern: REGEX.password
								}],
							})(
								<Input prefix={<LockOutlined style={{fontSize: 13}} />} type="password" placeholder={intl.formatMessage( {...messages.passwordPlaceholder} )} />
							)
					}
					</FormItem>
					<FormItem>
					{
						getFieldDecorator('confirm', {
								rules: [{
									required: true,
									message: 'Please confirm your password!',
								}, {
									validator: checkPassword,
								}],
							})( 
								<Input prefix={<LockOutlined style={{fontSize: 13}} />} type="password" placeholder={intl.formatMessage( {...messages.passwordConfirmPlaceholder} )} />
							)
					} 
					</FormItem>
					<FormItem>
						<Button type="primary" htmlType="submit" className="login-form-button">
							<FormattedMessage {...messages.resetPassword} />
						</Button>
					</FormItem>
				</Form>
			</Col>
		</Row>
    );
};

ResetPasswordPage.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	changePassword: PropTypes.func.isRequired,
	location: PropTypes.object.isRequired,
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		changePassword: ( access_token, password, confirmPassword ) => {
			dispatch(ChangePassword( access_token, password, confirmPassword ));
		},
	};
};

export default  connect(null, mapDispatchToProps)(Form.create()(injectIntl(ResetPasswordPage)));
