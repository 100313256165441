export const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS";
export const EDIT_CLIENT_SUCCESS = "EDIT_CLIENT_SUCCESS";
export const FETCH_CLIENT = 'FETCH_CLIENT'; 
export const FETCH_CLIENT_COUNT = 'FETCH_CLIENT_COUNT';
export const FETCH_SELLER_CLIENTS = 'FETCH_SELLER_CLIENTS'; 
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const BASIC_CLIENT_INFO_SAVE_CLIENT_NAME = 'BASIC_CLIENT_INFO_SAVE_CLIENT_NAME';
export const BASIC_CLIENT_INFO_SAVE_REPRESENTATIVE = 'BASIC_CLIENT_INFO_SAVE_REPRESENTATIVE';
export const BASIC_CLIENT_INFO_SAVE_RTN = 'BASIC_CLIENT_INFO_SAVE_RTN';
export const BASIC_CLIENT_INFO_SAVE_IS_LPS = 'BASIC_CLIENT_INFO_SAVE_IS_LPS';
export const BASIC_CLIENT_INFO_SAVE_IS_INDIVIDUAL = 'BASIC_CLIENT_INFO_SAVE_IS_INDIVIDUAL';
export const FETCH_CLIENT_TAGS = 'FETCH_CLIENT_TAGS';
export const CLIENT_INFO_SAVE_TELEPHONES = 'CLIENT_INFO_SAVE_TELEPHONES';
export const CLIENT_INFO_SAVE_EMAILS = 'CLIENT_INFO_SAVE_EMAILS';
export const FETCH_CLIENT_PRODUCTS = 'FETCH_CLIENT_PRODUCTS';
export const CLIENT_INFO_SAVE_CUSTOM_PRICES = 'CLIENT_INFO_SAVE_CUSTOM_PRICES';
export const CLIENT_SET_CURRENT_CLIENT_ID = 'CLIENT_SET_CURRENT_CLIENT_ID';
export const FETCH_CLIENT_ACTIVITY_LOG = 'FETCH_CLIENT_ACTIVITY_LOG';
export const FETCH_CLIENT_STATUS = 'FETCH_CLIENT_STATUS';
export const GET_CLIENTS = 'GET_CLIENTS';
export const CLEAN_CLIENTS = 'CLEAN_CLIENTS'; 
export const CLIENT_INFO_SAVE_SALESPERSON = 'CLIENT_INFO_SAVE_SALESPERSON';
export const CLIENT_ADDRESS_SAVE = 'CLIENT_ADDRESS_SAVE';
export const SET_CLIENT_ADDRESSES = 'SET_CLIENT_ADDRESSES';
export const SEARCH_PREREGISTRATION = 'SEARCH_PREREGISTRATION';
export const SELECTED_ITEM_PREREGISTRATION = 'SELECTED_ITEM_PREREGISTRATION'
export const SEARCH_APPROVEDCLIENTS = 'SEARCH_APPROVEDCLIENTS';
export const SELECTED_ITEM_APPROVED = 'SELECTED_ITEM_APPROVED';