import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { message } from 'antd';
import { getDashboardMessages } from '../../../constants/messages';
import { GetClientsWidgetInfo } from '../actions';
import Widget from '../Components/DefaultWidget';
import { UserOutlined } from '@ant-design/icons';

let dashboardMessages = getDashboardMessages();

const messages = {
	...dashboardMessages
};

class ClientsWidget extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
            isLoading: false,
            activeClientCount: 0,
            latestMonthlyClients: []
        };
	}

	componentDidMount () {
        const { getIntl, setLoading } = this;
        setLoading(true);
        GetClientsWidgetInfo()
        .then(response => {
            const activeClientCount = response[0];
            const latestMonthlyClients = response[1] || [];
            const parsedData = latestMonthlyClients.map(entry => ({ date: entry.month, amount: entry.amount }));
            this.setState({ activeClientCount, latestMonthlyClients: parsedData, isLoading: false });
        })
        .catch(() => {
            setLoading(false);
            message.error(getIntl("getClientInfoError"));
        });
	}

    getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    setLoading = isLoading => this.setState({ isLoading });

	render() {
        const { state, getIntl } = this;
        const { isLoading, latestMonthlyClients, activeClientCount } = state;
        const lastValue = latestMonthlyClients.length > 0 ? latestMonthlyClients[(latestMonthlyClients.length - 1)].amount : 0;
        return (
            <Widget
                isLoading={isLoading}
                title={getIntl("activeClientsByMonth")}
                statisticTitle={getIntl("activeClients")}
                statisticLastValue={lastValue}
                statisticCurrentValue={activeClientCount}
                statisticIcon={<UserOutlined />}
                graphData={latestMonthlyClients}
                graphAmountHeader={getIntl("clients")}
                padGraph
            />
        );
    }
}

ClientsWidget.propTypes = {
	intl: PropTypes.object.isRequired
};

export default injectIntl(ClientsWidget);