import React from 'react';
import PropTypes from 'prop-types';
import { PhoneOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, Select, Tooltip } from 'antd';
import { getAuthMessages, getModalMessages, getFields, getErrorMessages, getRegistrationMessages, getClientMessages } from '../../../../constants/messages';
import { injectIntl } from 'react-intl';
import { REGEX } from '../../../../constants/global';
import RemoveButton from './RemoveButton';

let authMessages = getAuthMessages(),
	errorMessages = getErrorMessages(),
	fieldMessages = getFields(),
	modalMessages = getModalMessages(),
	RegistrationMessages = getRegistrationMessages(),
	ClientMessages = getClientMessages();

const messages = {
	...authMessages,
	...modalMessages,
	...fieldMessages,
	...errorMessages,
	...RegistrationMessages,
	...ClientMessages
};

const FormItem = Form.Item;
const Option = Select.Option;

class Telephone extends React.Component {

	renderExtensions = () => {
		const { intl, form, tags, telephone, telephoneIndex, saveExtensionFunction, saveTagsFunction, removeExtensionFunction, saveNameFunction } = this.props;
		const { getFieldDecorator } = form;
		const tagItems = tags.map(tag => <Option key={tag.id} value={tag.id}>{tag.name}</Option>);
		const whitespace = REGEX.whitespace;
		const extension = telephone.extension || [];
		return (
			extension.map((ext, extIndex) => {
				let single = ext.extension === "-1";
				let selectForm = (
					<FormItem>
						{
							getFieldDecorator(`telephone${telephoneIndex}_extension${extIndex}_tag`,
								{
									initialValue: (ext.tag !== undefined && ext.tag !== null) ? ext.tag.map(tag => tag.id) : undefined,
									trigger: 'onChange',
									validateTrigger: ['onChange'],
									rules: [{
										required: true,
										message: intl.formatMessage({ ...messages.tagError })
									}],
									onChange: (value) => saveTagsFunction(value, telephoneIndex, extIndex),
								}
							)(
								<Select
									mode="multiple"
									placeholder={intl.formatMessage({ ...messages.tags })}
									style={single ? { width: '100%' } : { width: '95%' }}
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								>
									{tagItems}
								</Select>
							)
						}
					</FormItem>
				);
				let nameForm = (
					<FormItem>
						{
							getFieldDecorator(`telephone${telephoneIndex}_extension${extIndex}_name`,
								{
									initialValue: (ext.name !== null && ext.name !== undefined && whitespace.test(ext.name)) ? ext.name : "",
									trigger: 'onChange',
									validateTrigger: ['onChange', 'onBlur'],
									onChange: (event) => saveNameFunction(event.target.value, telephoneIndex, extIndex),
								}
							)(
								<Input placeholder={single ? intl.formatMessage({ ...messages.telephoneOwner }) : intl.formatMessage({ ...messages.extensionOwner })} />
							)
						}
					</FormItem>
				);
				return (
					single ?
						<div key={`telephone${telephoneIndex}_info`}>
							{selectForm}
							{nameForm}
						</div> :
						<div key={`telephone${telephoneIndex}_extension[${extIndex}]`}>
							<Row>
								<Col span={8}>
									<FormItem>
										{
											getFieldDecorator(`telephone${telephoneIndex}_extension[${extIndex}]`,
												{
													initialValue: ext.extension,
													trigger: 'onBlur',
													validateTrigger: ['onChange', 'onBlur'],
													rules: [{
														required: true,
														message: intl.formatMessage({ ...messages.extensionError }),
														whitespace: true,
														pattern: REGEX.number
													}],
													onBlur: (event) => saveExtensionFunction(event.target.value, telephoneIndex, extIndex),
												}
											)(
												<Input placeholder={intl.formatMessage({ ...messages.extension })} style={{ width: '95%', marginRight: 8 }} />
											)
										}
									</FormItem>
								</Col>
								<Col span={15}>
									{selectForm}
								</Col>
								<Col span={1} style={{ paddingTop: "4px" }}>
									<RemoveButton removeFunction={() => removeExtensionFunction(telephoneIndex, extIndex)} toolTipText={intl.formatMessage({ ...messages.removeExtension })} />
								</Col>
							</Row>
							{nameForm}
						</div>
				);
			})
		);
	}

	renderTelephone = () => {
		const { intl, form, telephone, telephoneIndex, saveNumberFunction, addExtensionFunction, removeTelephoneFunction,telephonesCount } = this.props;
		const { getFieldDecorator } = form;
		return (
            <Row>
				<Col span={20}>
					<FormItem>
						{
							getFieldDecorator(`telephones[${telephoneIndex}]`,
								{
									initialValue: telephone.number,
									trigger: 'onBlur',
									validateTrigger: ['onChange', 'onBlur'],
									rules: [
										{
											required: true,
											message: intl.formatMessage({ ...messages.telephoneError }),
											whitespace: true
										},
										{
											pattern: REGEX.number,
											message: intl.formatMessage({ ...messages.telephoneInvalidError })
										}
									],
									onBlur: (event) => saveNumberFunction(event.target.value, telephoneIndex),
								}
							)(
								<Input placeholder={intl.formatMessage({ ...messages.telephonePlaceholder })} style={{ width: '95%', marginRight: 8 }} />
							)
						}
					</FormItem>
				</Col>
				<Col span={3} style={{ paddingTop: "4px" }}>
					<Tooltip title={intl.formatMessage({ ...messages.addExtension })}>
						<Button style={{ padding: "2px" }} onClick={() => addExtensionFunction(telephoneIndex)}>
							<PlusOutlined /><PhoneOutlined />
						</Button>
					</Tooltip>
				</Col>
				{telephonesCount > 1 && (
					<Col span={1} style={{ paddingTop: "4px" }}>
						<RemoveButton removeFunction={() => removeTelephoneFunction(telephoneIndex)} toolTipText={intl.formatMessage({ ...messages.removeTelephone })} />
					</Col>
				)}
			</Row>
        );
	}

	fullTelephoneRender = () => {
		return (
			<div>
				{this.renderTelephone()}
				{this.renderExtensions()}
			</div>
		);
	}

	render() {
		return (
			this.fullTelephoneRender()
		);
	}

}

Telephone.defaultProps = {
	telephone: {},
	tags: []
};

Telephone.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	telephone: PropTypes.object.isRequired,
	telephoneIndex: PropTypes.number.isRequired,
	tags: PropTypes.array.isRequired,
	saveNumberFunction: PropTypes.func.isRequired,
	removeTelephoneFunction: PropTypes.func.isRequired,
	saveExtensionFunction: PropTypes.func.isRequired,
	addExtensionFunction: PropTypes.func.isRequired,
	removeExtensionFunction: PropTypes.func.isRequired,
	saveTagsFunction: PropTypes.func.isRequired,
	saveNameFunction: PropTypes.func.isRequired,
	telephonesCount: PropTypes.number,
};

export default injectIntl(Telephone);