import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, message, Modal } from 'antd';
import AssignTechnicalForm from './AssignTechnicalForm';
import { getLotsMessages, getModalMessages, getUnitsMessages } from '../../constants/messages';
import { LoadingOutlined, SaveOutlined } from '@ant-design/icons';
import { AssignTechnical } from './actions';

const WORKROLES = { TECHNICAL: 1, TECHNICALCHIEF: 8 }

let ModalMessages = getModalMessages(),
    UnitsMessages = getUnitsMessages(),
    LotsMessages = getLotsMessages();

const messages = {
	...ModalMessages,
    ...LotsMessages,
    ...UnitsMessages,
};

const AssignTechnicalModal = ({ showModal, selectedUnit, employees, onCancel, title, clearSelectedRows }) => {
    const intl = useIntl();
	const form = useRef();
	const dispatch = useDispatch();
    const [isSaving, setSaving] = useState(false);
    const technicals = employees.filter((x) => x.workRoleId === WORKROLES.TECHNICAL || x.workRoleId === WORKROLES.TECHNICALCHIEF);

    const closeModal = () => {
		onCancel();
	};

    const onOk = async () => {
        try {
			setSaving(true);
            const values = await form.current.validateFields();	
            await dispatch(AssignTechnical(values, selectedUnit));
            clearSelectedRows(true);
            message.success(intl.formatMessage({...messages.assignTechnicalSuccess }));
            closeModal();
		} catch (error) {
            message.error(intl.formatMessage({...messages.assignTechnicalError })); 
		} finally {
			setSaving(false);
		}
    };

    let buttonGroup = [
		<Button key="cancel" onClick={closeModal}>
			<FormattedMessage {...messages.cancel}  />
		</Button>,
		<Button type="primary" key="save" onClick={onOk}>
			{isSaving ? <LoadingOutlined /> : <SaveOutlined />}
			<FormattedMessage {...messages.save}  />
		</Button>,
	];

    return (
        <Modal
            open={showModal}
            title={title}
            onOk={onOk}
            onCancel={closeModal}
            destroyOnClose={true}
            footer={buttonGroup}
        >
            <div>
                <AssignTechnicalForm 
                    form={form} 
                    technicals={technicals}
                />
            </div>
        </Modal>
    );
}

AssignTechnicalModal.propTypes = {
    title: PropTypes.string.isRequired,
    onCancel: PropTypes.func,
    employees: PropTypes.array,
    showModal: PropTypes.bool,
    selectedUnit: PropTypes.array,
    clearSelectedRows: PropTypes.func,
}

export default AssignTechnicalModal;
