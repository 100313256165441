import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Select, Input, Popover, message } from 'antd';
import { getactionMessages, getModalMessages, getInvoicingMessages, getErrorMessages } from '../../constants/messages';
import { AnnulInvoice, GetInvoices } from './actions';
import { AnnullmentReasons } from './constants';

const FormItem = Form.Item;
const Option = Select.Option;

let actionMessages = getactionMessages(),
    modalMessages = getModalMessages(),
    invoicingMessages = getInvoicingMessages(),
    errorMessages = getErrorMessages();

const messages = {
    ...modalMessages,
    ...invoicingMessages,
    ...errorMessages,
    ...actionMessages
};

class AnnulButton extends React.Component {
    
    constructor(props) {
		super(props);
		this.state = {
            customMessage: false
        };
    }

	getIntl = (str) => {
		return this.props.intl.formatMessage({...messages[str]});
    }

    annulInvoice = (reason) => {
        const { getIntl, props } = this;
        const { annulInvoice, getInvoices, invoiceId, setState } = props;
        annulInvoice(invoiceId, reason)
        .then(() => {
            getInvoices()
            .then(() => setState({ isLoading: false }))
            .catch(() => {
                message.error(getIntl("GetInvoicesError"));
                setState({ isLoading: false });
            });
        })
        .catch(() => {
            message.error(getIntl("AnnulInvoiceError"));
            setState({ isLoading: false });
        });
    }

    renderFormSelect = () => {
        const { props, getIntl } = this;
        const { form } = props;
        const { getFieldDecorator } = form;
        const label = getIntl("Reason");
        const options = AnnullmentReasons.map((opt, index) => <Option key={index} value={opt}>{opt}</Option>);
        return (
            <FormItem label={label} >
            {
                getFieldDecorator(`reason`, 
                    {
                        rules: [{
                            required: true,
                            message: getIntl("annulMessageError")
                        }]
                    }
                )(
                    <Select
                        className="job-full-component"
                        showSearch
                        placeholder={label}
                        optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        dropdownMatchSelectWidth={false}
                    >
                        {options}
                    </Select>
                )
            }
            </FormItem>
        );
    }

    renderFormInput = () => {
        const { props, getIntl, annulInvoice } = this;
        const { form } = props;
        const { getFieldDecorator } = form;
        const label = getIntl("Reason");
        return (
            <FormItem label={label} >
                {
                    getFieldDecorator('reason', 
                        {
                            rules: [{
                                required: true,
                                message: getIntl("annulMessageError"),
                                max: 100
                            }],
                        }
                    )(<Input className="job-full-component" placeholder={label} onPressEnter={(event) => annulInvoice(event.target.value)}/>)
                }
            </FormItem>
        );
    }

    renderField = () => {
        const { state, renderFormSelect, renderFormInput } = this;
        const { customMessage } = state;
        return customMessage ? renderFormInput() : renderFormSelect();
    }

    opposeField = () => {
        const { customMessage } = this.state;
        let opposite = !customMessage;
        this.setState({customMessage: opposite});
        this.props.form.resetFields(["reason"]);
    }

	renderChangeField = () => {
        const { state, getIntl, opposeField } = this;
        const { customMessage } = state;
        let message = customMessage ? "predeterminedMessage" : "customMessage";
		return (
            <a onClick={() => opposeField()}>{getIntl(message)}</a>
        );
    }

    renderConfirmButton = () => {
        const { props, getIntl, annulInvoice } = this;
        const { state, form } = props;
        const { isLoading } = state;
        const { getFieldValue } = form;
        let reason = getFieldValue("reason");
        reason = reason === undefined ? "" : reason;
        return (
            <FormItem>
                <Button
                    type="primary"
                    disabled={reason.length < 1}
                    onClick={() => annulInvoice(reason)}
                    loading={isLoading}
                >
                    {getIntl("confirmAnnul")}
                </Button>
            </FormItem>
        );
    }

    renderForm = () => {
        const { renderField, renderChangeField, renderConfirmButton } = this;
        return (
            <div>
                {renderField()}
                {renderChangeField()}
                {renderConfirmButton()}
            </div>
        )
    }

    renderMainButton = () => {
        const { getIntl, renderForm } = this;
        const form = renderForm();
        return (
            <Popover key="annul" content={form} trigger="click">
                <a className="editable-add-btn">
                    {getIntl("Annul")}
                </a>
            </Popover>
        )
    }

    render() {
        return this.renderMainButton(); 
    }

}

AnnulButton.propTypes = {
	intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    invoiceId: PropTypes.number.isRequired,
    annulInvoice: PropTypes.func.isRequired,
    getInvoices: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => {
	return {
        annulInvoice: (id, reason) => dispatch(AnnulInvoice(id, reason)),
        getInvoices: () => dispatch(GetInvoices())
	};
};

export default connect(null, mapDispatchToProps)(injectIntl(Form.create()(AnnulButton)));