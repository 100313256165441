import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Spin, DatePicker, message, Button, Empty } from 'antd';
import { getTechnicianReportMessages, getJobMessages, getReportMessages, getCalendarMessages } from '../../../constants/messages';
import { Chart, Axis, Interval, Tooltip, Legend, Coordinate, Interaction } from 'bizcharts';
import { objectToArray } from '../../../utilities/util';
import { JOBTYPES } from '../../Jobs/constants';
import { FetchTechnicianJobs } from './actions';
import Measure from 'react-measure';
import debounce from 'lodash/debounce';
const { RangePicker } = DatePicker;
const moment = require('moment');

let techMessages = getTechnicianReportMessages(),
    jobMessages = getJobMessages(),
    reportMessages = getReportMessages(),
    calendarMessages = getCalendarMessages();

const messages = {
    ...techMessages,
    ...jobMessages,
    ...reportMessages,
    ...calendarMessages
};

const minChartWidth = 800;

class Report extends React.Component {
	constructor(props){
        super(props);
		this.resizeCharts = debounce(this.resizeCharts, 250);
        this.state = {
            isLoading: false,
            typeNames: objectToArray(JOBTYPES).reduce((diff, type) => { return { ...diff, [type.id.toString()]: this.getIntl(type.intl) } }, {}),
            data: [],
            notEmpty: false,
            width: minChartWidth,
            dateString: []
        };
    }

    resizeCharts = (dimensions) => {
        if (!dimensions) return;
        let { width } = dimensions;
        width = width > minChartWidth ? width : minChartWidth;
        this.setState({ width });
    }

    getChartHeightAndWidth = () => {
        const { width } = this.state;
        return { height: width/3, width };
    }

    getTypeName = (typeId) => {
        const { state, getIntl } = this;
        const { typeNames } = state;
        const idStr = typeId.toString();
        return typeNames[idStr] || (getIntl("unknown") + " - " + idStr);
    }

	getIntl = (str) => {
		return this.props.intl.formatMessage({...messages[str]});
    }

    fetchTechnicianJobs = (start, end) => {
        const { getIntl, parseData } = this;
        this.setState({ isLoading: true });
        FetchTechnicianJobs(start, end)
        .then((jobs) => {
            let data = parseData(jobs);
            this.setState({ isLoading: false, data });
        })
        .catch(() => {
            message.error(getIntl("fetchTechJobsError"));
            this.setState({ isLoading: false, data: [] });
        });
    }

    parseData = (jobs) => {
        this.setState({ notEmpty: jobs.length > 0 });
        const { buildTechnicianTemplate } = this;
        let data = {};
        jobs.forEach(job => {
            const { type, technicians } = job;
            let typeKey = type.toString();
            technicians.forEach(tech => {
                let techKey = tech.id.toString();
                let techName = tech.name;
                if(data[techKey] === undefined) data[techKey] = buildTechnicianTemplate(techName);
                if(data[techKey].types[typeKey] === undefined) data[techKey].types[typeKey] = 0;
                data[techKey].types[typeKey] = data[techKey].types[typeKey] + 1;
                data[techKey].count = data[techKey].count + 1;
            });
        });
        return objectToArray(data);
    }

    buildTechnicianTemplate = (name) => {
        return {
            name: name,
            count: 0,
            types: {}
        };
    }

    rangeOnChange = (date, dateString) => {
        let ISOstringify = [moment(dateString[0]).startOf('day').toISOString(), moment(dateString[1]).endOf('day').toISOString()];
        this.setState({ dateString: ISOstringify });
    }

    generateReport = () => {
        const { state, fetchTechnicianJobs } = this;
        const { dateString } = state;
        if (dateString.length < 2) return;
        let start = new Date(dateString[0]);
        let end = new Date(dateString[1]);
        fetchTechnicianJobs(start, end);
    }

    renderRangePicker = () => {
        const { getIntl, rangeOnChange, state, generateReport } = this;
        const { dateString } = state;
        let ranges = {
            [getIntl("today")]: [moment().startOf('day'), moment().endOf('day')],
            [getIntl("thisWeek")]: [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
            [getIntl("thisMonth")]: [moment().startOf('month'), moment().endOf('month')]
        };
        return (
            <div>
                <div><b>{getIntl("dateRange")}</b></div>
                <RangePicker onChange={rangeOnChange} allowClear={false} ranges={ranges}/>
                <Button className="technician-report-range-button" type="primary" disabled={dateString.length < 2} onClick={generateReport}>{getIntl("generateReport")}</Button>
            </div>
        );
    }

    renderPieChart = () => {
        const { getIntl, state, getChartHeightAndWidth } = this;
        const { data, notEmpty } = state;
        const dimensions = getChartHeightAndWidth();
        let scale = {
            name: {
                alias: getIntl("technicians")
            },
            count: {
                alias:  getIntl("quantity")
            }
        };
        return (
        <Measure bounds onResize={(contentRect) => this.resizeCharts(contentRect.bounds)}>
            {
                ({ measureRef }) => (
                    <div ref={measureRef}>
                        <div className="technician-report-chart-header">{getIntl("pieChartTitle")}</div>
                        {
                            notEmpty ? 
                            <Chart data={data} scale={scale} {...dimensions}>
                                <Axis name="name"/>
                                <Axis name="count" visible={false}/>
                                <Coordinate type="theta" radius={0.75}/>
                                <Legend />
                                <Tooltip showTitle={false}/>
                                <Interval position="count" color={"name"} adjust={[{type: 'stack', marginRatio: 1/32}]} 
                                    label={["name", (name) => { return{ offset: 30, content: (data) => { return `${data.name}: ${data.count}`}, style:{ fill: name } }} ]}
                                />
                                <Interaction type= "element-single-selected"/> {/*Enables selecting the corresponding part of the piechart for each technician*/}
                            </Chart> :
                            <Empty />
                        }
                    </div>
                )
            }
        </Measure>
        );
    }

    parseBarChartData = () => {
        const { state, getTypeName } = this;
        const { data } = state;
        let barData = [];
        data.forEach(technicians => {
            const { name, types } = technicians;
            for(let t in types) {
                barData.push({ type: getTypeName(t), name, count: types[t] });
            }
        });
        return barData;
    }

    renderBarChart = () => {
        const { getIntl, parseBarChartData, getChartHeightAndWidth, state } = this;
        const { notEmpty } = state;
        const data = parseBarChartData();
        const dimensions = getChartHeightAndWidth();
        return (
            <div>
                <div className="technician-report-chart-header">{getIntl("barChartTitle")}</div>
                {
                    notEmpty ?
                    <Chart data={data} scale={{name: {type: 'cat', alias: getIntl("technicians")}, count: {alias: getIntl("quantity")}}} {...dimensions}>
                        <Axis title={true} name="name"/>
                        <Axis title={true} name="count"/>
                        <Legend />
                        <Tooltip showCrosshairs="true" type="y" shared/>
                        <Interval position="name*count" color={"type"} adjust={[{type:'dodge', marginRatio: 1/32}]}/>
                    </Chart> :
                    <Empty />
                }
            </div>
        );
    }

    renderCharts = () => {
        const { renderPieChart, renderBarChart } = this;
        return (
            <div className="technician-report-charts">
                {renderPieChart()}
                {renderBarChart()}
            </div>
        );
    }

    fullRender = () => {
        const { state, renderRangePicker, renderCharts } = this;
        const { isLoading } = state;
        return (
            <Spin spinning={isLoading}>
                <div className="view">
                    {renderRangePicker()}
                    {renderCharts()}
                </div>
            </Spin>
        );
    }

	render(){
		return this.fullRender();
	}
}

Report.propTypes = {
	intl: PropTypes.object.isRequired,
};

export default injectIntl(Report);