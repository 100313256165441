import React from 'react';
import PropTypes from 'prop-types';
import '@ant-design/compatible/assets/index.css';
import { Form } from '@ant-design/compatible';
import { message, Spin, Select } from 'antd';
import { injectIntl } from 'react-intl';
import { getInvoicingMessages, getModalMessages, getErrorMessages } from '../../../constants/messages';
import { SearchInvoiceDocumentNumbers } from '../actions';
const FormItem = Form.Item;
const { Option } = Select;

let invoicingMessages = getInvoicingMessages(),
	modalMessages = getModalMessages(),
	errorMessages = getErrorMessages();

const messages = {
	...invoicingMessages,
	...modalMessages,
	...errorMessages
};

class InvoiceSearchSelect extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			invoices: [],
		};
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });

	handleDocumentNumberSearch = (docNum) => {
		const { getIntl, setLoading } = this;
		SearchInvoiceDocumentNumbers(docNum)
			.then(invoices => {
				setLoading(false);
				this.setState({ invoices });
			})
			.catch(() => {
				setLoading(false);
				message.error(getIntl("searchInvoicesError"));
			});
	}

	renderInvoicePicker = () => {
		const { state, props, getIntl, handleDocumentNumberSearch } = this;
		const { isLoading, invoices } = state;
		const { form, label, fieldName, onSelect, disabled } = props;
		const { getFieldDecorator } = form;
		const options = invoices.map(({ id: invoiceId, documentNumber }) => <Option key={invoiceId} value={invoiceId}>{documentNumber}</Option>)
		return (
			<FormItem label={label}>
				{
					getFieldDecorator(fieldName)(
						<Select
							className="job-full-component"
							showSearch
							allowClear={false}
							onSearch={handleDocumentNumberSearch}
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							dropdownMatchSelectWidth={false}
							notFoundContent={isLoading ? <Spin size="small" /> : getIntl("notFound")}
							onSelect={onSelect}
							disabled={disabled}
						>
							{options}
						</Select>
					)
				}
			</FormItem>
		);
	}

	render() {
		return this.renderInvoicePicker();
	}
}

InvoiceSearchSelect.defaultProps = {
	disabled: false,
};

InvoiceSearchSelect.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	label: PropTypes.string.isRequired,
	fieldName: PropTypes.string.isRequired,
	onSelect: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

export default injectIntl(InvoiceSearchSelect);