import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { Modal, Button, message } from 'antd';
import AddOrEditForm from './AddOrEditForm';
import { LoadingOutlined, SaveOutlined } from '@ant-design/icons';
import { getactionMessages, getFields, getModalMessages, getModelsMessages } from '../../constants/messages';
import { AddUnitModel, UpdateUnitModel } from './actions';
import { useDispatch } from 'react-redux';

let ModelsMessages = getModelsMessages(),
    ModalMessages = getModalMessages(),
    ActionMessages = getactionMessages(),
	FieldsMessages = getFields();

const messages = {
	...ModelsMessages,
    ...ActionMessages,
    ...FieldsMessages,
    ...ModalMessages,
};

const AddOrEditModal = ({onCancel, title, visible, record, isEdit, updateModels}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
	const form = useRef();
    const [isSaving, setSaving] = useState(false);
   
    const closeModal = () => {
		onCancel();
	};

    const onOk = async () => {
        const models = [];
		try {
			setSaving(true);
            const values = await form.current.validateFields();	
            if(isEdit){
                const unit = await dispatch(UpdateUnitModel(values));
                models.push(unit);
            }else{
                const unit = await dispatch(AddUnitModel(values));
                models.push(unit);
            }
            updateModels(models, false);
            message.success(intl.formatMessage(isEdit ? {...messages.editModelSuccess }: {...messages.addModelSuccess }));
            closeModal();
		} catch (error) {
            message.error(intl.formatMessage(isEdit ? {...messages.editModelError }: {...messages.addModelError }));
		} finally {
			setSaving(false);
		}
	};

    let buttonGroup = [
		<Button key="cancel" onClick={closeModal}>
			<FormattedMessage {...messages.cancel}  />
		</Button>,
		<Button type="primary" key="save" onClick={onOk}>
			{isSaving ? <LoadingOutlined /> : <SaveOutlined />}
			<FormattedMessage {...messages.save}  />
		</Button>,
	];

    return (
        <Modal
            open={visible}
            title={title}
            onOk={onOk}
            onCancel={closeModal}
            destroyOnClose={true}
            footer={buttonGroup}
        >
            <div>
                <AddOrEditForm form={form} record={record} />
            </div>
        </Modal>
    );
};

AddOrEditModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string,
    visible: PropTypes.bool,
    updateModels: PropTypes.func,
    isEdit: PropTypes.bool,
    record: PropTypes.object,
};

export default AddOrEditModal;
