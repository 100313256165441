import axios from 'axios';
import {
    INIT_REQUEST_JOB,
    END_REQUEST_JOB,
    FETCH_JOB,
    FETCH_JOB_COUNT,
    FETCH_JOB_TECHNICIANS,
    FETCH_JOB_ICONS,
    JOB_UPDATE_CALENDAR_EVENTS,
    UPDATE_JOB_FILTER,
    FECTH_SELECT_JOBS,
    CHECK_IN_PROFORMA_JOBS,
    CHECK_IN_INVOICE_JOBS,
    SET_SEARCH_TABLE
} from './actionTypes';
import { ShowErrors, objectIsEmpty, substituteObjectInArrayByValue, objectOnlyHasTheseElements } from '../../utilities/util';
import { unit_icons, DEVICESTATUS, CHECKINTYPES } from  './constants';
import { STATUS } from  '../Clients/constants';
import { OPTIMUS_SMOVILGPS_CLIENT_ID, ROLES } from '../../constants/global';
let moment = require('moment');
const CancelToken = axios.CancelToken;
let cancelCurrentCalendarRequest = null;

const searchDeviceFilter = (name, limit = 10) => {
    const regx = "%" + name + "%";
    return {
        limit,
        where: {
			and: [
                {
                    or: [
                        { pin: { like: regx } },
                        { imei: { like: regx } },
                        { description: { like: regx } }
                    ]
                }
			]
        },
        include: ["sim", "icon", "addon", "disabledAddon", "deviceLease"]
    };
}

const JobEventAdds = (job, dispatch, getState) => {
    const state = getState();
    const count = state.job.count;
    const jobs = [...state.job.jobs];
    const calendarEvents = [...state.job.calendarEvents, job.calendarEvent];
    if (jobs.length >= 10) jobs.pop();
    jobs.unshift(job);
    dispatch({ type: FETCH_JOB_COUNT, count: count + 1});
    dispatch({ type: FETCH_JOB, jobs });
    dispatch({ type: JOB_UPDATE_CALENDAR_EVENTS, calendarEvents });
}

const JobEventUpdates = (job, dispatch, getState) => {
    const state = getState();
    const jobs = state.job.jobs;
    const calendarEvents = state.job.calendarEvents;
    dispatch({ type: FETCH_JOB, jobs: substituteObjectInArrayByValue("id", job.id, jobs, job)});
    dispatch({ type: JOB_UPDATE_CALENDAR_EVENTS, calendarEvents: substituteObjectInArrayByValue("id", job.calendarId, calendarEvents, job.calendarEvent) });
}

export const GetModelProfiles = () => {
    return axios.get(`/ModelProfiles`)
    .then(response => response.data);
};

export const CheckPinExistance = (pin = "") => {
    const filter = { pin };
    return axios.get(`/Devices/count?where=${encodeURIComponent(JSON.stringify(filter))}`)
    .then(response => response.data.count);
};

export const GetDeviceModels = () => {
    const filter = { where: { active: true } };
    return axios.get(`/DeviceModels?filter=${encodeURIComponent(JSON.stringify(filter))}`)
    .then(response => response.data);
};

export const SearchForClient = (clientName, limit = 10) => {
    if (clientName.length <= 0) return [];
    
    const regx = "%" + clientName + "%";
    const filter = {
        limit,
        where: {
			and:[
				{ name: { like: regx } },
                { status: STATUS.APPROVED },
                { inArrears: 0 }
			]
        }
    };

    return axios.get(`/Clients?filter=${encodeURIComponent(JSON.stringify(filter))}`)
    .then(response => response.data);
};

export const DeactivateDeviceSearch = (name, clientId, limit = 10) => {
    if (name.length <= 0) return [];
    const filter = searchDeviceFilter(name, limit);
    filter.where.and.push({ clientId });
    filter.where.and.push({
        or: [
            { active: true },
            {
                and: [
                    { active: false },
                    { deviceStatusId: DEVICESTATUS.HIDDEN }
                ]
            }
        ]
    });

    return axios.get(`/Devices?filter=${encodeURIComponent(JSON.stringify(filter))}`)
    .then(response => response.data);
};

export const ActivateDeviceSearch = (name, clientId, limit = 10) => {
    if (name.length <= 0) return [];
    const filter = searchDeviceFilter(name, limit);
    filter.where.and.push({ clientId });
    filter.where.and.push({ active: false });

    return axios.get(`/Devices?filter=${encodeURIComponent(JSON.stringify(filter))}`)
    .then(response => response.data);
};

export const SearchAvailableSimCards = (name, limit = 10) => {
    const regx = "%" + name + "%";
    const where = {
        or: [
            { line: { like: regx } },
            { serial: { like: regx } }
        ]
    };
    const filter = { limit, where };

    return axios.get(`/AvailableSimCards?filter=${encodeURIComponent(JSON.stringify(filter))}`)
    .then(response => response.data);
};

export const GetIcons = () => dispatch => {
    const where = { type: unit_icons };
    const filter = { where };

	return axios.get(`/Icons?filter=${encodeURIComponent(JSON.stringify(filter))}`)
	.then(response => dispatch({ type: FETCH_JOB_ICONS, icons: response.data }));
};

export const GetTechnicians = () => dispatch => {
	return axios.get(`/Accounts/getTechnicians`)
	.then(response => {
        dispatch({ type: FETCH_JOB_TECHNICIANS, technicianList: response.data});
	});
};

export const SearchJobs = (searchObject = {}, page = 1, pageSize = 10) => (dispatch, getState) => {
	const state = getState();
    const userId = state.auth.user.id;
    const userRoles = state.auth.roles;
    const messages = state.int18.messages;
    const searchFilter = state.job.searchFilter;
    const { searchText } = searchObject;
    dispatch({ type: SET_SEARCH_TABLE, searchTable: searchText});
    if(userRoles.includes(ROLES.TECHSUPPORT)){
        searchObject.matchAllCriteria = true;
        searchObject.programmedDateRange = [moment().startOf('day'), moment().endOf('day')]
        searchObject.technicians = [userId];
    }
    const initialFunc = endPointExec => {
        dispatch({type:INIT_REQUEST_JOB});
        endPointExec()
        .then(results => {
            const { filter, count, entries } = results;
            dispatch({ type: FETCH_JOB, jobs: entries });
            dispatch({ type: FETCH_JOB_COUNT, count: count });
            dispatch({ type: UPDATE_JOB_FILTER, searchFilter: filter });
            dispatch({ type: END_REQUEST_JOB });

        })
        .catch(error => {
            ShowErrors(error, dispatch, messages);
            dispatch({ type: END_REQUEST_JOB });
        });
    };

    const pagingFunc = () => {
        const filter = { ...searchFilter, page, pageSize, skip: (page - 1) * pageSize };
        dispatch({type:INIT_REQUEST_JOB});
        GetJobsWithFilter(filter)
        .then(response => {
            dispatch({ type: FETCH_JOB, jobs: response });
            dispatch({ type: END_REQUEST_JOB });
        })
        .catch(error => {
            ShowErrors(error, dispatch, messages);
            dispatch({ type: END_REQUEST_JOB });
        });
    }

    if (objectIsEmpty(searchObject) || (objectOnlyHasTheseElements('searchText', searchObject) && searchText !== undefined && searchText.length <= 0)) {
        // just regular get jobs for today
        if (page === 1) {
            // count and first jobs
            const filterValues = {
                pageSize,
                startDate: moment().startOf('day').toISOString(),
                endDate: moment().endOf('day').toISOString()
            };
            initialFunc(() => InitialDateRangeSearch(filterValues));
        } else {
            // only jobs for that page
            pagingFunc();
        }
    } else {
        if (searchText !== undefined && searchText.length > 0) {
            // search with searchbox
            if (page === 1) {
                // count and first jobs
                const filterValues = { pageSize, clientName: searchText };
                initialFunc(() => InitialClientNameTextSearch(filterValues));
            } else {
                // only jobs for that page
                pagingFunc();
            }
        } else {
            // search with drawer
            if (page === 1) {
                // count and first jobs
                searchObject.pageSize = pageSize;
                initialFunc(() => InitialAdvancedSearch(searchObject));
            } else {
                // only jobs for that page
                pagingFunc();
            }
        }
        
    }
    
};

export const FetchCalendarEvents = (timeMin = moment().startOf('month').toISOString(), timeMax = moment().endOf('month').toISOString()) => (dispatch, getState) => {
	const state = getState();
    const messages = state.int18.messages;
    const object = { params: { timeMin: timeMin, timeMax: timeMax } };

    if (cancelCurrentCalendarRequest !== null) cancelCurrentCalendarRequest();
    
    return axios.get(`/GoogleCalendars/listEvents?event=${encodeURIComponent(JSON.stringify(object))}`, 
    { cancelToken: new CancelToken(function executor(c) { cancelCurrentCalendarRequest = c; }) })
	.then(response => dispatch({ type: JOB_UPDATE_CALENDAR_EVENTS, calendarEvents: response.data }))
	.catch((error) => {
        if (!axios.isCancel(error)) ShowErrors(error, dispatch, messages);
    })
    .then(()=> cancelCurrentCalendarRequest = null);
};

export const CancelCalendarFetch = () => {
    if (cancelCurrentCalendarRequest !== null) cancelCurrentCalendarRequest();
    cancelCurrentCalendarRequest = null;
};

// Rework Start //

export const GetJobInfo = (jobId) => {
	return axios.get(`/Jobs/${jobId}/getInfo`)
	.then(response => response.data);
};

export const GetDuplicateJobInfo = (jobId) => {
    const filter = {
        fields: ["id", "type", "programmedDate", "duration", "clientId", "addressId"],
        include: [
            { relation: "client", scope: { fields: ["id", "name", "clientId"] } },
            { relation: "technicians", scope: { fields: ["id", "name"] } },
            { relation: "address", scope: { fields: ["id", "address", "city", "state"] } }
        ]
    };
    return axios.get(`/Jobs/${jobId}?filter=${encodeURIComponent(JSON.stringify(filter))}`)
	.then(response => response.data);
};

export const JobExists = (id) => {
    const filter = { id };
    return axios.get(`/Jobs/count?where=${encodeURIComponent(JSON.stringify(filter))}`)
    .then(response => {
        return response.data.count
    });
};

export const SaveSelectJobs = (selected, type) => (dispatch) => {
    let jobs = []
    selected.forEach(id => {
        GetJobInfo(id).then((response) => {
            const filteredData = { 
                description: response.description, 
                chargeType: response.invoiceInfo.chargeType,
                clientId: response.clientId,
                clientName: response.clientName,
                jobId: response.invoiceInfo.jobId,
                productId: response.invoiceInfo.productId,
            }
            jobs.push(filteredData);
            if(jobs.length === selected.length){
                dispatch({ type: FECTH_SELECT_JOBS, selectedJobs: jobs });
                if(type == CHECKINTYPES.INVOICE) 
                    dispatch({ type: CHECK_IN_INVOICE_JOBS, invoiceJobs: true});
                else
                    dispatch({ type: CHECK_IN_PROFORMA_JOBS, proformaJobs: true});
            }
        });
    });
};

export const ClearSelectedJobs = () => (dispatch) => {
    dispatch({ type: CHECK_IN_INVOICE_JOBS, invoiceJobs: false});
    dispatch({ type: CHECK_IN_PROFORMA_JOBS, proformaJobs: false});
};

export const getSeletedJobs = async (jobIds) => {
    let filter = {
        where: {
            id: { inq: jobIds },
        },
    };
    const response = await axios.get(`/Jobs/?filter=${encodeURIComponent(JSON.stringify(filter))}`);
    return response.data;
};

export const AddJob = (jobInfo) => (dispatch, getState) => {
	const state = getState();
    const creatingUserId = state.auth.user.id;
    const creationDate = new Date().toISOString();
    const job = { ...jobInfo, creatingUserId, creationDate };
	return axios.post(`/Jobs/addJob`, { job })
	.then(response => {
        const newJob = response.data;
        JobEventAdds(newJob, dispatch, getState);
        return newJob;
    });
};

export const AddComments = (jobId, comments) => (dispatch, getState) => {
	const state = getState();
    const userId = state.auth.user.id;
    const date = new Date().toISOString();
	return axios.post(`/Jobs/${jobId}/addComments`, { comments, userId, date })
	.then(response => response.data);
};

export const UpdateJob = (jobId, jobInfo) => (dispatch, getState) => {
	const state = getState();
    const userId = state.auth.user.id;
    const date = new Date().toISOString();
    const job = { ...jobInfo, userId, date };
	return axios.post(`/Jobs/${jobId}/updateJob`, { job })
	.then(response => {
        const updatedJob = response.data;
        JobEventUpdates(updatedJob, dispatch, getState);
        return updatedJob;
    });
};

export const CancelAnnulJob = (jobId, status, message) => (dispatch, getState) => {
	const state = getState();
    const userId = state.auth.user.id;
    const date = new Date().toISOString();
	return axios.post(`/Jobs/${jobId}/cancelAnnul`, { status, message, userId, date })
	.then(response => {
        const updatedJob = response.data;
        JobEventUpdates(updatedJob, dispatch, getState);
        return updatedJob;
    });
};

export const MoveToDoing = (jobId, comments) => (dispatch, getState) => {
	const state = getState();
    const userId = state.auth.user.id;
    const date = new Date().toISOString();
	return axios.post(`/Jobs/${jobId}/moveToDoing`, { comments, userId, date })
	.then(response => {
        const updatedJob = response.data;
        JobEventUpdates(updatedJob, dispatch, getState);
        return updatedJob;
    });
};

export const CompleteJob = (jobId, jobInfo) => (dispatch, getState) => {
	const state = getState();
    const userId = state.auth.user.id;
    const date = new Date().toISOString();
    const job = { ...jobInfo, userId, date };
	return axios.post(`/Jobs/${jobId}/completeJob`, { job })
	.then(response => {
        const updatedJob = response.data;
        JobEventUpdates(updatedJob, dispatch, getState);
        return updatedJob;
    });
};

export const AddWon = (jobId, jobInfo) => (dispatch, getState) => {
	const state = getState();
    const userId = state.auth.user.id;
    const date = new Date().toISOString();
    const job = { ...jobInfo, userId, date };
	return axios.post(`/Jobs/${jobId}/addWon`, { job })
	.then(response => {
        const updatedJob = response.data;
        JobEventUpdates(updatedJob, dispatch, getState);
        return updatedJob;
    });
};

export const ActiveDeviceSearch = (name, clientId, limit = 10) => {
    if (name.length <= 0) return [];
    const filter = searchDeviceFilter(name, limit);
    filter.where.and.push({ clientId });
    filter.where.and.push({ active: true });

    return axios.get(`/Devices?filter=${encodeURIComponent(JSON.stringify(filter))}`)
    .then(response => response.data);
};

export const SearchAvailableDevices = (name, limit = 10) => {
    if (name.length <= 0) return [];
    const filter = searchDeviceFilter(name, limit);
    filter.where.and.push({ clientId: OPTIMUS_SMOVILGPS_CLIENT_ID });

    return axios.get(`/Devices?filter=${encodeURIComponent(JSON.stringify(filter))}`)
    .then(response => response.data);
};

export const SearchReconnectionDevices = (name, limit = 10) => {
    if (name.length <= 0) return [];
    const filter = searchDeviceFilter(name, limit);
    filter.where.and.push({ active: false });

    return axios.get(`/Devices?filter=${encodeURIComponent(JSON.stringify(filter))}`)
    .then(response => response.data);
};

export const SearchSwappableDevices = (name, clientId, limit = 10) => {
    if (name.length <= 0) return [];
    const filter = searchDeviceFilter(name, limit);
    const extraFilter = {
        or: [
            { clientId: OPTIMUS_SMOVILGPS_CLIENT_ID },
            {
                and: [
                    { clientId },
                    { active: false }
                ]
            }
        ]
    };
    filter.where.and.push(extraFilter);
    
    return axios.get(`/Devices?filter=${encodeURIComponent(JSON.stringify(filter))}`)
    .then(response => response.data);
};

export const AdvancedSearchForClient = (clientName, limit = 10) => {
    if (clientName.length <= 0) return [];
    const filter = {
        limit,
        fields: ["id", "name", "isDisabled"],
        where: {
			and:[
				{ name: { like: "%" + clientName + "%" } },
                { status: STATUS.APPROVED }
			]
        }
    };
    return axios.get(`/Clients?filter=${encodeURIComponent(JSON.stringify(filter))}`)
    .then((response) => response.data);
};

export const AdvancedSearchForClientSeller = (clientName, clientIds, limit = 10) => {
    if (clientName.length <= 0) return [];
    const filter = {
        limit,
        fields: ["id", "name", "isDisabled"],
        where: {
			and:[
				{ name: { like: "%" + clientName + "%" } },
                { status: STATUS.APPROVED },
                { id: {inq: clientIds} }
			]
        }
    };
    return axios.get(`/Clients?filter=${encodeURIComponent(JSON.stringify(filter))}`)
    .then((response) => response.data);
};
export const AdvancedSearchForUser = () => {
    return axios.get(`/Accounts/getJobCreators`)
    .then((response) => response.data);
};

export const AdvancedSearchForDevice = (name, limit = 10) => {
    if (name.length <= 0) return [];
    const regx = "%" + name + "%";
    const filter = {
        limit,
        fields: ["id", "pin", "imei", "description", "active"],
        where: {
			and: [
                {
                    or: [
                        { pin: { like: regx } },
                        { imei: { like: regx } },
                        { description: { like: regx } }
                    ]
                }
			]
        }
    };
    
    return axios.get(`/Devices?filter=${encodeURIComponent(JSON.stringify(filter))}`)
    .then(response => response.data);
};

export const InitialAdvancedSearch = (filterValues) => {
	return axios.get(`/Jobs/initialAdvancedSearch?filterValues=${encodeURIComponent(JSON.stringify(filterValues))}`, { filterValues })
	.then(response =>  response.data);
};

export const InitialClientNameTextSearch = (filterValues) => {
    return axios.get(`/Jobs/initialClientNameTextSearch?filterValues=${encodeURIComponent(JSON.stringify(filterValues))}`, { filterValues })
    .then(response =>  response.data);
};

export const InitialDateRangeSearch = (filterValues) => {
	return axios.get(`/Jobs/initialDateRangeSearch?filterValues=${encodeURIComponent(JSON.stringify(filterValues))}`, { filterValues })
	.then(response =>  response.data);
};

export const GetJobsWithFilter = (filter) => {
    return axios.get(`/Jobs?filter=${encodeURIComponent(JSON.stringify(filter))}`)
	.then(response =>  response.data);
};

export const MarkJobsInvoiced = async (jobs, invoiceId) => {
	await axios.patch(`/JobToPays/markJobsInvoiced`, {jobs, invoiceId});
};

export const MarkJobsProforma = async (jobs, proformaId) => {
	await axios.patch(`/JobToPays/markJobsProforma`, {jobs,  proformaId});
};

export const InstantDeactivationJob = async (jobId) => {
    const response = await axios.patch(`Jobs/executeDeactivationJob`, {jobId})
    return response.status;
}

// Rework End //
