import React from 'react';
import PropTypes from 'prop-types';
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Card,
    Divider,
    Input,
    Radio,
    Select,
    Col,
    Typography,
    List,
    InputNumber,
    DatePicker,
} from 'antd';
import { injectIntl } from 'react-intl';
import { getClientMessages, getLanguageIntl } from '../../../../constants/messages';
import { getNestedValue, objectToArray, getObjectInArray } from '../../../../utilities/util';
import { RuleTypes, ProductRuleIds, getRuleType, getProductRuleId } from '../../constants';
import { DEVICESTATUS } from '../../../Jobs/constants';
import IconRender from '../../../Devices/Finder/Renders/IconRender';
const { MonthPicker } = DatePicker;
const moment = require('moment');
const monthDateFormat = "MM-YYYY";

const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;
const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { Paragraph, Text } = Typography;

let clientMessages = getClientMessages(),
    intlMessages = getLanguageIntl();

const messages = {
    ...clientMessages,
    ...intlMessages
};

class RuleEntry extends React.Component  {
    
    getIntl = (str, values = {}) => this.props.intl.formatMessage({...messages[str]}, values);

	getField = (field) => {
        const { entry } = this.props;
        return getNestedValue(field, entry);
    }

    getIsEditing = () => {
        const { index, editingIndex } = this.props;
        return index === editingIndex;
    }

    renderTitle = () => {
        const { props, getField, getIntl, getIsEditing } = this;
        const { saveAlias, index, form, clientName } = props;
        const { getFieldDecorator } = form;
        const alias = getField("alias");
        const priority = getField("priority");
        const isEditing = getIsEditing();
        const maxLength = 100;
        if (isEditing) 
        return (
            <FormItem className="job-partial-component">
            {
                getFieldDecorator(`alias[${index}]`, 
                    {
                        initialValue: alias || clientName,
                        rules: [
                            {
                                whitespace: true,
                                message: getIntl("aliasBlankError")
                            },
                            {
                                max: maxLength,
                                message: getIntl("aliasTooLongError", { maxLength })
                            }
                        ],
                        onChange: (event) => saveAlias(event.target.value),
                    }
                )(
                    <Input placeholder={getIntl("alias")}/>
                )
            }
            </FormItem>
        );
        if (alias === null || alias === undefined || alias.length < 1) return <span>{`[${priority}] `}<Text type="secondary">{clientName}</Text></span>;
        return <Text ellipsis style={{ display: "block" }}>{`[${priority}] ${alias}`}</Text>;
    }

    editClick = () => {
        const { setIsEditing, index } = this.props;
        setIsEditing(index);
    }

    saveClick = () => {
        const { props } = this;
        const { setIsEditing, form } = props;
        const { validateFields } = form;
        validateFields({ "force": true }, (err) => {
			if (!err) {
                setIsEditing(-1);
			}
		});
    }

    renderExtra = () => {
        const { props, editClick, saveClick } = this;
        const { index, editingIndex, remove } = props;
        if (editingIndex === -1)
            return [
                <EditOutlined className="client-rules-icon" key="edit" onClick={editClick} />,
                <Divider key="div1" type="vertical"/>,
                <DeleteOutlined className="client-rules-icon" key="delete" onClick={remove} />
            ];
        else if (editingIndex === index)
            return [
                <SaveOutlined className="client-rules-icon" key="save" onClick={saveClick} />,
                <Divider key="div1" type="vertical"/>,
                <DeleteOutlined className="client-rules-icon" key="delete" onClick={remove} />
            ];
    }

    renderType = () => {
        const { props, getField, getIntl, getIsEditing } = this;
        const { saveType, index, form } = props;
        const { getFieldDecorator } = form;
        const options = objectToArray(RuleTypes).map((type) => <RadioButton key={type.id} value={type.id}>{type.intl}</RadioButton>);
        const type = getField("type");
        const typeObject = getRuleType(type);
        const isEditing = getIsEditing();
        if (isEditing)
        return (
            <FormItem>
                {`${getIntl("ruleType")}: `}
                {
                    getFieldDecorator(`type[${index}]`, 
                        {
                            initialValue: parseInt(type),
                            rules: [{
                                required: true,
                                message: getIntl("ruleTypeError"), 
                            }],
                            onChange: (event) => saveType(event.target.value)
                        }
                    )(
                        <RadioGroup size="small">
                            {options}
                        </RadioGroup>
                    )
                }
            </FormItem>
        );
        return `${getIntl("ruleType")}: ${typeObject.intl}`;
    }

    renderProductPicker = () => {
        const { props, getField, getIntl } = this;
        const { saveProductId, index, form } = props;
        const { getFieldDecorator } = form;
        const options = objectToArray(ProductRuleIds).map((type) => <Option key={type.id} value={type.id}>{type.intl}</Option>);
        const productId = getField("productId");
        return (                 
            <FormItem>
                <Col span={4}>{`${getIntl("product")}: `}</Col>
                <Col span={20}>   
                    {
                        getFieldDecorator(`productId[${index}]`, 
                            {
                                initialValue: productId,
                                rules: [{
                                    required: true,
                                    message: getIntl("productError"), 
                                }],
                                onChange: (productId) => saveProductId(productId)
                            }
                        )(
                            <Select
                                className="job-full-component"
                                size="small"
                                showSearch
                                placeholder={getIntl("product")}
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                notFoundContent={getIntl("notFound")}
                            >
                                {options}
                            </Select>
                        )
                    }
                </Col>
            </FormItem>
        );
    }

    renderProductContent = () => {
        const { getField, getIntl, getIsEditing, renderProductPicker } = this;
        const productId = getField("productId");
        const productObject = getProductRuleId(productId);
        const isEditing = getIsEditing();
        if (isEditing) return renderProductPicker();
        return `${getIntl("product")}: ${productObject.intl}`;
    }

    buildDeviceName = (device) => {
        const pin = device.pin;
        const description = device.description || this.getIntl("noDescription");
        const icon = <IconRender icon={device.icon} renderName={false}/>;
        const text = `${pin} - ${description}`
        return <span>{icon} {text}</span>
    }

    handleDeviceFilter = (input, option) => {
        const { label, children } = option;
        if (label) return false;
        const lowerCase = (str) => str.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        return lowerCase(children.props.children[2]);
    }

    buildDeviceOptions = () => {
        const { props, getIntl, buildDeviceName } = this;
        const { allDevices } = props;
        const options = [], active = [], hidden = [], suspended = [], cancelled = [], others = [];
        allDevices.forEach(device => {
            const { deviceStatusId } = device;
            const option = <Option key={device.id} value={device.id}>{buildDeviceName(device)}</Option>;
            switch (deviceStatusId) {
                case DEVICESTATUS.ACTIVE:
                        active.push(option);
                    break;
                case DEVICESTATUS.HIDDEN:
                        hidden.push(option);
                    break;
                case DEVICESTATUS.SUSPENDED:
                        suspended.push(option);
                    break;
                case DEVICESTATUS.CANCELLED:
                        cancelled.push(option);
                    break;
                default:
                        others.push(option);
                    break;
            }
        });
        options.push(<OptGroup key="active" label={getIntl("active")}>{active}</OptGroup>);
        options.push(<OptGroup key="hidden" label={getIntl("hidden")}>{hidden}</OptGroup>);
        options.push(<OptGroup key="suspended" label={getIntl("suspended")}>{suspended}</OptGroup>);
        options.push(<OptGroup key="cancelled" label={getIntl("cancelled")}>{cancelled}</OptGroup>);
        options.push(<OptGroup key="others" label={getIntl("others")}>{others}</OptGroup>);
        return options;
    }

    renderDevicePicker = () => {
        const { props, getField, getIntl, handleDeviceFilter, buildDeviceOptions } = this;
        const { saveDevices, index, form } = props;
        const { getFieldDecorator } = form;
        const options = buildDeviceOptions();
        const devices = getField("devices");
        return (                 
            <FormItem>
                <Col span={5}>{`${getIntl("devices")}: `}</Col>
                <Col span={19}>
                    {
                        getFieldDecorator(`devices[${index}]`, 
                        {
                            initialValue: devices,
                            rules: [{
                                required: true,
                                message: getIntl("devicesError")
                            }],
                            onChange: newDevices => saveDevices(newDevices)
                        }
                        )(
                        <Select
                            className="job-full-component"
                            mode="multiple"
                            maxTagCount={3}
                            placeholder={getIntl("devices")}
                            showSearch
                            filterOption={handleDeviceFilter}
                            notFoundContent={getIntl("notFound")}
                            dropdownMatchSelectWidth={false}
                        >
                            {options}
                        </Select>
                        )
                    }
                </Col>
            </FormItem>
        );
    }

    renderExpandedDeviceList = () => {
        const { props, getField, getIntl, buildDeviceName } = this;
        const { allDevices, saveDevicesExpanded } = props;
        const devices = getField("devices");
        const deviceObjects = getObjectInArray("id", devices, allDevices);
        const title = `${getIntl("devices")} (${devices.length}): `;
        return (
            <div>
                <div>{title}</div>
                <List
                    dataSource={deviceObjects}
                    split={false}
                    renderItem={dev => <List.Item className="client-rules-device-padding">{buildDeviceName(dev)}</List.Item>}
                />
                <Button className="client-rules-device-padding" type="link" onClick={() => saveDevicesExpanded(false)}>{getIntl("collapse")}</Button>
            </div>
        );
    }

    renderEllipsisDeviceList = () => {
        const { props, getField, getIntl } = this;
        const { allDevices, saveDevicesExpanded } = props;
        const devices = getField("devices");
        const deviceObjects = getObjectInArray("id", devices, allDevices);
        const title = `${getIntl("devices")} (${devices.length}): `;
        return (
            <Paragraph ellipsis={{ expandable: true, onExpand: () => saveDevicesExpanded(true) }} >
                {`${title}${deviceObjects.map(dev => dev.description).join(" | ")}`}
            </Paragraph>
        );
    }

    renderDeviceList = () => {
        const { getField, renderExpandedDeviceList, renderEllipsisDeviceList } = this;
        const devicesExpanded = getField("devicesExpanded");
        if (devicesExpanded) return renderExpandedDeviceList();
        return renderEllipsisDeviceList();
    }

    renderDevices = () => {
        const { getIsEditing, renderDeviceList, renderDevicePicker } = this;
        const isEditing = getIsEditing();
        if (isEditing) return renderDevicePicker();
        return renderDeviceList();
    }

    renderRecurrence = () => {
        const { props, getField, getIntl, getIsEditing } = this;
        const { form, saveRecurrence , index} = props;
        const { getFieldDecorator } = form;
        const isEditing = getIsEditing();
        const recurrence = getField("recurrence");
        if (isEditing)
        return (
            <FormItem>
                {getIntl("recurrenceEvery")}
                {
                    getFieldDecorator(`recurrence[${index}]`,
                    {
                        initialValue: recurrence,
                        rules: [{
                            required: true,
                            message: getIntl("recurrenceRequiredError")
                        }],
                        onChange: recurrence => saveRecurrence(recurrence)
                    }
                    )(
                        <InputNumber
                            className="client-rules-device-input-number"
                            size="small"
                            min={1}
                            max={12}
                        />
                    )
                }
                {(recurrence && recurrence === 1) ? getIntl("month") : getIntl("months")}
            </FormItem>
        );
        return `${getIntl("recurrence")}: ${!recurrence ? getIntl("noRecurrence") : recurrence === 1 ? getIntl("everyMonth") : getIntl("everyXmonths", { recurrence })}`;
    }

	handleLastInvoicedMonthSave = (date) => {
		const { saveLastInvoiced } = this.props;
		if (!date) saveLastInvoiced(undefined);
		else {
			const m = moment(date).set({ date: 15, hour: 0, minute: 0, second: 0, millisecond: 0 });
			saveLastInvoiced(m.toISOString());
		}
	}

    disabledDate = (current) => {
        return moment(current).endOf('month') > moment().endOf('month');
    }

	renderLastInvoiced = () => {
        const { props, getField, getIntl, getIsEditing, handleLastInvoicedMonthSave, disabledDate } = this;
        const { form, index } = props;
		const { getFieldDecorator } = form;
        const isEditing = getIsEditing();
        const lastInvoiced = getField("lastInvoiced");
        const dateMoment = moment(lastInvoiced === undefined ? null : lastInvoiced);
        if (isEditing)
        return (
			<FormItem>
            {`${getIntl("lastInvoicedMonth")}: `}
			{
				getFieldDecorator(`lastInvoiced[${index}]`, 
					{
						initialValue: dateMoment.isValid() ? dateMoment : null,
						rules: [{
                            required: true,
                            message: getIntl("lastInvoicedMonthRequiredError"), 
						}],
						onChange: handleLastInvoicedMonthSave
					}
				)(
                    <MonthPicker
                        size="small"
						placeholder={getIntl("lastInvoicedMonth")}
						format={monthDateFormat}
						disabledDate={disabledDate}
						allowClear={false}
					/>
				)
			}
			</FormItem>
        );
        const locale = getIntl("intl");
        return `${getIntl("lastInvoicedMonth")}: ${dateMoment.isValid() ? dateMoment.locale(locale).format("MMMM YYYY") : getIntl("noLastInvoicedMonth")}`;
	}

    renderDeviceContent = () => {
        const { renderDevices, renderRecurrence, renderLastInvoiced } = this;
        return (
            <div>
                <div className="client-rules-form-padding client-remove-margin-bottom">{renderDevices()}</div>
                <div className="client-rules-form-padding client-remove-margin-bottom">{renderRecurrence()}</div>
                <div className="client-rules-form-padding client-remove-margin-bottom">{renderLastInvoiced()}</div>
            </div>
        );
    }

    renderActive = () => {
        const { props, getField, getIntl, getIsEditing } = this;
        const { saveActive, index, form } = props;
        const { getFieldDecorator } = form;
        const active = getField("active");
        const isEditing = getIsEditing();
        if (isEditing)
        return (
            <FormItem>
                {`${getIntl("status")}: `}
                {
                    getFieldDecorator(`active[${index}]`,
                    {
                        initialValue: active,
                        onChange: (event) => saveActive(event.target.value)
                    }
                    )(
                        <RadioGroup size="small">
                            <RadioButton value={true}>{getIntl("ruleActive")}</RadioButton>
                            <RadioButton value={false}>{getIntl("ruleInactive")}</RadioButton>
                        </RadioGroup>
                    )
                }
            </FormItem>
        );
        return `${getIntl("status")}: ${active ? getIntl("ruleActive") : getIntl("ruleInactive")}`;
    }

    renderTypeSpecificContent = () => {
        const { getField, renderProductContent, renderDeviceContent } = this;
        const type = getField("type");
        if (type === RuleTypes.producto.id) return renderProductContent();
        else if (type === RuleTypes.dispositivo.id) return renderDeviceContent();
    }

    renderForm = () => {
        const { renderType, renderTypeSpecificContent, renderActive } = this;
        return (
            <div>
                <div className="client-rules-form-padding client-remove-margin-bottom">{renderActive()}</div>
                <div className="client-rules-form-padding client-remove-margin-bottom">{renderType()}</div>
                <div className="client-remove-margin-bottom">{renderTypeSpecificContent()}</div>
            </div>
        );
    }
    
    renderCard = () => {
        const { props, renderTitle, renderExtra, renderForm } = this;
        const { editingIndex } = props;
        return (
            <Card
                className={`client-rules-card ${editingIndex === -1 ? "client-rules-card-grab" : ""}`}
                title={<div className="client-remove-margin-bottom">{renderTitle()}</div>}
                extra={renderExtra()}
            >
                {renderForm()}
            </Card>
        );
    }

	render(){
		return this.renderCard();
	}
}

RuleEntry.defaultProps = {
    entry: {},
    editingIndex: -1,
    allDevices: []
};

RuleEntry.propTypes = {
	intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    entry: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    editingIndex: PropTypes.number.isRequired,
	setIsEditing: PropTypes.func.isRequired,
	remove: PropTypes.func.isRequired,
	allDevices: PropTypes.array.isRequired,
	saveAlias: PropTypes.func.isRequired,
	saveType: PropTypes.func.isRequired,
	saveProductId: PropTypes.func.isRequired,
	saveDevices: PropTypes.func.isRequired,
	saveDevicesExpanded: PropTypes.func.isRequired,
	clientName: PropTypes.string.isRequired,
	saveActive: PropTypes.func.isRequired,
	saveRecurrence: PropTypes.func.isRequired,
	saveLastInvoiced: PropTypes.func.isRequired
};

export default injectIntl(RuleEntry);