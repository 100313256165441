import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Descriptions } from 'antd';
import { getJobMessages, getFields, getLanguageIntl } from '../../../../constants/messages';
import Embolden from '../../../GlobalComponents/Embolden';
import { getJobType, getJobStatus } from '../../constants';
import CircleBadge from '../../../GlobalComponents/CircleBadge';
const DescriptionItem = Descriptions.Item;
import moment from 'moment';

let jobMessages = getJobMessages(),
    fieldMessages = getFields(),
    intlMessages = getLanguageIntl();

const messages = {
    ...jobMessages,
    ...fieldMessages,
    ...intlMessages
};

class BasicInformation extends React.Component {

    getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    renderEmboldenIntl = (headerIntl, value) => {
        const { getIntl } = this;
        return <Embolden header={getIntl(headerIntl)} value={value} row/>;
    }

    renderClientName = () => {
        const { props, renderEmboldenIntl } = this;
        const { getField, hideClientName } = props;
        if (hideClientName) return null;
        const name = getField("client.name");
        return renderEmboldenIntl("name", name);
    }

    renderAddress = () => {
        const { props, renderEmboldenIntl } = this;
        const { getField, hideClientAddress } = props;
        if (hideClientAddress) return null;
        const clientAddress = getField("address");
        if (clientAddress) {
            const { address, city, state } = clientAddress;
            const addressStr = [address, city, state].join(", ");
            return renderEmboldenIntl("address", addressStr);
        }
        return renderEmboldenIntl("address", clientAddress);
    }

    formatDate = (unformatedDate) => {
        const { getIntl } = this;
        const date = new Date(unformatedDate);
        const locale = getIntl("intl");
        const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(locale, dateOptions);
    }

    renderProgrammedDate = () => {
        const { props, formatDate, renderEmboldenIntl } = this;
        const { getField, hideJobDate } = props;
        if (hideJobDate) return null;
        const programmedDate = getField("programmedDate");
        if (programmedDate) {
            const dateStr = formatDate(programmedDate);
            return renderEmboldenIntl("date", dateStr);
        }
        return renderEmboldenIntl("date", programmedDate);
    }

    formatHour = (unformatedDate) => {
        moment.locale('en'); 
        return moment(unformatedDate).format('LT');
    }

    renderProgrammedHour = () => {
        const { props, formatHour, renderEmboldenIntl } = this;
        const { getField, hideJobHour } = props;
        if (hideJobHour) return null;
        const programmedDate = getField("programmedDate");
        if (programmedDate) {
            const hourStr = formatHour(programmedDate);
            return renderEmboldenIntl("jobViewHour", hourStr);
        }
        return renderEmboldenIntl("jobViewHour", programmedDate);
    }

    formatDuration = (duration) => {
        const { getIntl } = this;
        const hours = Math.floor(duration / 60);
        const minutes = duration % 60;
        let returnStr = "";
        if (hours) returnStr += `${hours} ${getIntl("hours")}`;
        if (minutes) {
            if (hours) returnStr += ` ${getIntl("with")} `;
            returnStr += `${minutes} ${getIntl("minutes")}`;
        }
        return returnStr;
    }

    renderDuration = () => {
        const { props, formatDuration, renderEmboldenIntl } = this;
        const { getField, hideJobDuration } = props;
        if (hideJobDuration) return null;
        const duration = getField("duration");
        if (duration) {
            const durationStr = formatDuration(duration);
            return renderEmboldenIntl("jobViewDuration", durationStr);
        }
        return renderEmboldenIntl("jobViewDuration", duration);
    }

    renderTechnicians = () => {
        const { props, renderEmboldenIntl } = this;
        const { getField, hideJobTechnicians } = props;
        if (hideJobTechnicians) return null;
        const technicians = getField("technicians");
        if (technicians) {
            const techNames = technicians.map(tech => tech.name).join(", ");
            return renderEmboldenIntl("technicians", techNames);
        }
        return renderEmboldenIntl("technicians", technicians);
    }

    renderJobType = () => {
        const { props, getIntl, renderEmboldenIntl } = this;
        const { getField, hideJobType } = props;
        if (hideJobType) return null;
        const type = getField("type");
        if (type) {
            const TYPE = getJobType(type);
            const typeRender = <CircleBadge color={TYPE.backgroundColor} text={getIntl(TYPE.intl)}/>;
            return renderEmboldenIntl("jobViewType", typeRender);
        }
        return renderEmboldenIntl("jobViewType", type);
    }

    renderStatus = () => {
        const { props, getIntl, renderEmboldenIntl } = this;
        const { getField, hideJobStatus } = props;
        if (hideJobStatus) return null;
        const status = getField("status");
        if (status) {
            const STATUS = getJobStatus(status);
            const statusRender = <CircleBadge color={STATUS.iconColor} text={getIntl(STATUS.intl)}/>;
            return renderEmboldenIntl("status", statusRender);
        }
        return renderEmboldenIntl("status", status);
    }

    renderClientDescription = () => {
        const { getIntl, props, renderClientName, renderAddress } = this;
        const { hideClient } = props;
        if (hideClient) return null;
        return (
            <DescriptionItem label={getIntl("client")}>
                {renderClientName()}
                {renderAddress()}
            </DescriptionItem>
        );
    }

    renderCreatedBy = () => {
        const { props, renderEmboldenIntl } = this;
        const { getField, hideCreatedBy } = props;
        if (hideCreatedBy) return null;
        const createdBy = getField("creatingUser.name");
        return renderEmboldenIntl("creatingUser", createdBy);
    }

    renderJobDescription = () => {
        const { getIntl, props, renderProgrammedDate, renderProgrammedHour, renderDuration, renderTechnicians, renderJobType, renderStatus, renderCreatedBy } = this;
        const { hideJob } = props;
        if (hideJob) return null;
        return (
            <DescriptionItem label={getIntl("job")}>
                {renderProgrammedDate()}
                {renderProgrammedHour()}
                {renderDuration()}
                {renderTechnicians()}
                {renderJobType()}
                {renderStatus()}
                {renderCreatedBy()}
            </DescriptionItem>
        );
    }

    fullRender = () => {
        const { renderClientDescription, renderJobDescription } = this;
        const client = renderClientDescription();
        const job = renderJobDescription();
        return (
            <Descriptions layout="vertical" size="small" bordered column={1}>
                {client}
                {job}
            </Descriptions>
        );
    }
    
	render(){
        return this.fullRender();
	}
}

BasicInformation.defaultProps = {
	hideClient: false,
	hideJob: false,
	hideCreatedBy: false,
	hideClientName: false,
	hideClientAddress: false,
	hideJobDate: false,
	hideJobHour: false,
	hideJobDuration: false,
	hideJobTechnicians: false,
	hideJobType: false,
	hideJobStatus: false
};

BasicInformation.propTypes = {
    intl: PropTypes.object.isRequired,
    getField: PropTypes.func.isRequired,
    hideClient: PropTypes.bool.isRequired,
    hideJob: PropTypes.bool.isRequired,
    hideCreatedBy: PropTypes.bool.isRequired,
    hideClientName: PropTypes.bool.isRequired,
    hideClientAddress: PropTypes.bool.isRequired,
    hideJobDate: PropTypes.bool.isRequired,
    hideJobHour: PropTypes.bool.isRequired,
    hideJobDuration: PropTypes.bool.isRequired,
    hideJobTechnicians: PropTypes.bool.isRequired,
    hideJobType: PropTypes.bool.isRequired,
    hideJobStatus: PropTypes.bool.isRequired
};

export default injectIntl(BasicInformation);