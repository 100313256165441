import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Spin, message } from 'antd';
import { injectIntl } from 'react-intl';
import { getCommissionMessages, getactionMessages, getModalMessages } from '../../constants/messages';
import { SetCommissionConfigurations } from './actions';
import Configurations from './Configurations';

let commissionMessages = getCommissionMessages(),
	actionMessages = getactionMessages(),
	modalMessages = getModalMessages();

const messages = {
	...commissionMessages,
	...actionMessages,
	...modalMessages
};

class ConfigModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false
		};
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });

	handleCancel = () => {
		const { props, setLoading } = this;
		setLoading(false);
		props.handleCancel();
	}

	handleSave = () => {
		const { props, getIntl, setLoading, handleCancel } = this;
		const { validateFields } = props.form;
		validateFields({ force: true }, (err, values) => {
			if (!err) {
				const { commissionPercent, isCommissionable, commissionCustomPrice } = values;
				const configurations = { commissionPercent: commissionPercent / 100, isCommissionable, commissionCustomPrice };
				setLoading(true);
				SetCommissionConfigurations(configurations)
					.then(() => {
						message.success(getIntl("saveConfigurationsSuccess"));
						handleCancel();
					})
					.catch(() => {
						message.error(getIntl("saveConfigurationsError"));
						setLoading(false);
					});
			}
		});
	}

	renderButtons = () => {
		const { state, getIntl, handleCancel, handleSave } = this;
		const { isLoading } = state;
		return [
			<Button key="cancel" onClick={handleCancel} disabled={isLoading}>{getIntl("cancel")}</Button>,
			<Button key="save" type="primary" onClick={handleSave} loading={isLoading}>{getIntl("save")}</Button>
		];
	}

	render() {
		const { state, props, getIntl, renderButtons, setLoading } = this;
		const { isLoading } = state;
		const { form, showModal, handleCancel } = props;
		return (
			<Modal
				open={showModal}
				title={getIntl("commissionConfigurations")}
				onCancel={handleCancel}
				destroyOnClose={true}
				footer={renderButtons()}
			>
				<Form layout='vertical'>
					<Spin spinning={isLoading}>
						<Configurations form={form} setLoading={setLoading} />
					</Spin>
				</Form>
			</Modal>
		);
	}
}

ConfigModal.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,

	showModal: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired
};

export default injectIntl(Form.create()(ConfigModal));