import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Row, Col, Spin, Divider} from 'antd';
import LanguageControl from './LanguageControl';
import {getFields, getModalMessages, getConfigurationMessages, getErrorMessages} from '../constants/messages';
import { FormattedMessage} from 'react-intl';

let configurationMessages = getConfigurationMessages(),
	errorMessages = getErrorMessages(),
	fields = getFields(),
	modalMessages = getModalMessages();

const messages = {
	...modalMessages,
	...errorMessages,
	...configurationMessages,
	...fields
};

const MapConfig = ({loading}) => {

	return (
		<Row className="">
			<Spin spinning={loading}>
				<Divider><FormattedMessage {...messages.controls} /></Divider>
				<Col span={24}>
				<FormattedMessage {...messages.languages} />
				</Col>
				<Col span={24}>
					<LanguageControl size="small"/>
				</Col>
			</Spin>
		</Row>
	);
};

MapConfig.propTypes = {
	loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
	return {
		"loading": state.configuration.loading
	};
};


export default  connect(mapStateToProps)(MapConfig);
