import React from 'react';
import PropTypes from 'prop-types';
import {getRequestMessages} from '../../constants/messages';
import { DatePicker, Select } from 'antd';
import {injectIntl, FormattedMessage} from 'react-intl';

let requestMessages = getRequestMessages();

const messages = {
    ...requestMessages
};

const Option = Select.Option;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

class RequestsFilter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			status: 0,
			startDate: undefined,
			endDate: undefined
		};
	}
	
    onChangeSelect = (e) => {
		this.setState({
			status: e
		});
		let filter = {
			...this.state,
			status: e
		};
		this.props.onFilter(filter);
	}
	
	onChangeRange = (e) => {
		this.setState({
			startDate: e[0],
			endDate: e[1]
		});
		let filter = {
			...this.state,
			startDate: e[0],
			endDate: e[1]
		};
		this.props.onFilter(filter);
	}

	render(){
		return(
			<div style={{align: 'right'}}>
				<RangePicker format={dateFormat} onChange={this.onChangeRange} style={{width: '35%', 'marginRight': '6px'}}/>
				<Select onChange={this.onChangeSelect} defaultValue={0} style={{width: '25%', 'marginRight': '6px'}}>
					<Option key={0} value={0}><FormattedMessage {...messages.all} /></Option>
					<Option key={1} value={1}><FormattedMessage {...messages.finished} /></Option>
					<Option key={2} value={2}><FormattedMessage {...messages.unfinished} /></Option>
				</Select>
			</div>
			
		);
	}
}

RequestsFilter.propTypes = {
	onFilter: PropTypes.func,
};

export default injectIntl(RequestsFilter);