import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { CaretDownOutlined, CaretRightOutlined, SearchOutlined } from '@ant-design/icons';
import { Row, Col, Button, Collapse, Spin, Card, Pagination, Typography, Tooltip, message, Empty } from 'antd';
import { getJobMessages, getLanguageIntl, getFields } from '../../../../constants/messages';
import Client from './Components/Client';
import Device from './Components/Device';
import CreatingUser from './Components/CreatingUser';
import Technicians from './Components/Technicians';
import Type from './Components/Type';
import Status from './Components/Status';
import ProgrammedDate from './Components/ProgrammedDate';
import MatchAllCriteria from './Components/MatchAllCriteria';
import CircleBadge from '../../../GlobalComponents/CircleBadge';
import ViewJobModal from '../../ViewJob/Modal';
import { getJobType, getJobStatus } from '../../constants';
import { getNestedValue } from '../../../../utilities/util';
import { GetJobsWithFilter, InitialAdvancedSearch } from '../../actions';
const { Panel } = Collapse;
const { Paragraph } = Typography;

let jobMessages = getJobMessages(),
    fieldMessages = getFields(),
    intlMessages = getLanguageIntl();

const messages = {
    ...jobMessages,
    ...fieldMessages,
    ...intlMessages
};

const defaultPageSize = 5;

class AdvancedSearchForm extends React.Component  {

    constructor(props) {
		super(props);
		this.state = {
            isLoading: false,
            expandedFields: true,
            showViewModal: false,
            jobId: 0,
            jobData: [],
            jobCount: 0,
            currentPage: 1,
            expandJobData: false,
            filter: {}
        };
    }
	
    getIntl = (str, values = {}) => this.props.intl.formatMessage({...messages[str]}, values);
    setLoading = (isLoading) => this.setState({ isLoading });

    searchButtonOnClick = () => {
        const { props, getIntl, setLoading } = this;
        const { validateFieldsAndScroll } = props.form;
        validateFieldsAndScroll({ force: true }, (err, filter) => {
            if (!err) {
                filter.pageSize = defaultPageSize;
                setLoading(true);
                InitialAdvancedSearch(filter)
				.then((results) => {
                    setLoading(false);
                    const { filter, count, entries } = results;
                    this.setState({ filter, jobCount: count, jobData: entries, currentPage: 1, expandJobData: true });
                })
				.catch(() => {
					setLoading(false);
                    message.error(getIntl("jobSearchError"));
                });
            }
        });
    }

    renderSearchButton = () => {
        const { state, getIntl, searchButtonOnClick } = this;
        const { isLoading } = state;
        return [
            <Button style={{ marginBottom: "10px" }} key="search" type="primary" onClick={searchButtonOnClick} loading={isLoading} block ghost icon={<SearchOutlined />}>
                {getIntl("search")}
            </Button>
        ];
    }
    
    renderForm = () => {
        const { form } = this.props;
        return(
            <Row gutter={24}>
                <Col span={8}><Client form={form}/></Col>
                <Col span={8}><Device form={form}/></Col>
                <Col span={8}><CreatingUser form={form}/></Col>
                <Col span={8}><Technicians form={form}/></Col>
                <Col span={8}><Type form={form}/></Col>
                <Col span={8}><Status form={form}/></Col>
                <Col span={8}><ProgrammedDate form={form}/></Col>
                <Col span={8}><MatchAllCriteria form={form}/></Col>
            </Row>
        );
    }

    formatDate = (unformatedDate) => {
        const { getIntl } = this;
        const date = new Date(unformatedDate);
        const locale = getIntl("intl");
        return date.toLocaleDateString(locale);
    }


    formatHour = (unformatedDate) => {
        const { getIntl } = this;
        const date = new Date(unformatedDate);
        const locale = getIntl("intl");
        const timeOptions = { hour: '2-digit', minute:'2-digit', hour12: true };
        return date.toLocaleTimeString(locale, timeOptions);
    }

    buildCard = (jobData) => {
        const { getIntl, formatDate, formatHour } = this;
        const { id, programmedDate, technicians, status, type } = jobData;
        const clientName = getNestedValue("client.name", jobData);
        const userName = getNestedValue("creatingUser.name", jobData);
        const techNames = technicians.map(tech => tech.name).join(", ");
        const TYPE = getJobType(type);
        const STATUS = getJobStatus(status);
        const title = <CircleBadge color={STATUS.iconColor} text={`#${id}`}/>;
        const buildParagraph = (headerIntl, content) => <Tooltip title={`${getIntl(headerIntl)}: ${content}`}><Paragraph style={{ marginBottom: "0px" }} ellipsis={true}>{content}</Paragraph></Tooltip>;
        return (
            <Card 
                style={{ backgroundColor: TYPE.backgroundColor, color: TYPE.textColor }}
                title={title}
                onClick={() => this.setState({ showViewModal: true, jobId: id })}
                hoverable
            >
                {buildParagraph("jobClient", clientName)}
                {buildParagraph("date", formatDate(programmedDate))}
                {buildParagraph("jobViewHour", formatHour(programmedDate))}
                {buildParagraph("user", userName)}
                {buildParagraph("technicians", techNames)}
            </Card>
        );
    }

    renderJobData = () => {
        const { state, buildCard } = this;
        const { jobData } = state;
        if (jobData.length <= 0) return <Empty />;
        const cards = jobData.map((data, index) => <Col span={4} offset={index === 0 ? 0 : 1} key={index}>{buildCard(data)}</Col>);
        return <Row style={{ marginBottom: "10px" }}>{cards}</Row>;
    }

    renderExpandButton = () => {
        const { state, getIntl } = this;
        const { expandedFields } = state;
        return (
            <div className="job-comments-expand">
                <a onClick={() => this.setState({ expandedFields: !expandedFields })}>
                    {expandedFields ? <CaretDownOutlined /> : <CaretRightOutlined />}
                    {getIntl("searchFields")}
                </a>
            </div>
        );
    }

    pagingOnChange = (page, pageSize) => {
        const { state, getIntl, setLoading } = this;
        const filter = { ...state.filter, page, pageSize, skip: (page - 1) * pageSize };
        setLoading(true);
        GetJobsWithFilter(filter)
        .then(jobData => {
            setLoading(false);
            this.setState({ jobData, filter, currentPage: page });
        })
        .catch(() => {
            setLoading(false);
            message.error(getIntl("jobSearchError"));
        });
    }

    renderPagination = () => {
        const { state, pagingOnChange, getIntl } = this;
        const { currentPage, jobCount } = state;
        return (
            <Pagination
                style={{ textAlign: "right" }}
                defaultPageSize={defaultPageSize}
                current = {currentPage}
                onChange={pagingOnChange}
                total={jobCount}
                showTotal={count => getIntl("jobSearchResultCount", { count })}
            />
        );
    }

    fullRender = () => {
        const { state, renderForm, renderJobData, renderExpandButton, renderSearchButton, renderPagination } = this;
        const { expandedFields, isLoading, showViewModal, jobId, expandJobData } = state;
        const activeKey = [];
        if (expandedFields) activeKey.push(1);
        if (expandJobData) activeKey.push(2);
        return (
            <div>
                <ViewJobModal
                    showModal={ showViewModal }
                    handleCancel={() => this.setState({ showViewModal: false, jobId: 0 })}
                    jobId={jobId}
				/>
                <Spin spinning={isLoading}>
                    <Collapse className="job-view-reconnect-collapse" bordered={false} activeKey={activeKey}>
                        <Panel key={1} showArrow={false} header={renderExpandButton()}>
                            {renderForm()}
                            {renderSearchButton()}
                        </Panel>
                        <Panel key={2} showArrow={false}>
                            {renderJobData()}
                            {renderPagination()}
                        </Panel>
                    </Collapse>
                </Spin>
            </div>
        );
    }
    
	render(){
        return this.fullRender();
	}
}

AdvancedSearchForm.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired
};

export default injectIntl(AdvancedSearchForm);