import PropTypes from 'prop-types';
import { Col, Tag } from 'antd';
import { injectIntl } from 'react-intl';
import React from 'react';
const CheckableTag = Tag.CheckableTag;

class SelectableTags extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTags: this.props.defaultSelected,
        }
    }

    componentDidMount() {
    }

    selectTag(tag, checked) {
        if (this.props.selectedAvailable)
            return;

        const { selectedTags } = this.state;
        const nextSelectedTags = checked
            ? [...selectedTags, tag]
            : selectedTags.filter(t => t !== tag);
        this.setState({ selectedTags: nextSelectedTags });
        this.props.updateTags(nextSelectedTags);
    }

    render() {
        let { selectedTags } = this.state;
        let { tagsFromServer } = this.props;
        let realSpan = this.props.tagSpan ? this.props.tagSpan : 3;
        let className = this.props.className ? this.props.className : "";
        return (
            <Col span={20} className={className} >
                {tagsFromServer.map(tag => (
                    <Col key={tag} span={realSpan}>
                        <CheckableTag
                            checked={selectedTags.indexOf(tag) > -1}
                            onChange={checked => this.selectTag(tag, checked)}
                        >
                            {tag}
                        </CheckableTag>
                    </Col>
                ))}
            </Col>
        );
    }
}

SelectableTags.propTypes = {
    intl: PropTypes.object.isRequired,
    selectedAvailable: PropTypes.bool.isRequired,
    tagsFromServer: PropTypes.array.isRequired,
    tagSpan: PropTypes.number,
    className: PropTypes.string,
    defaultSelected: PropTypes.array.isRequired,
    updateTags: PropTypes.func,
};


export default injectIntl(SelectableTags);