import React from 'react';
import PropTypes from 'prop-types';
import { StopOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, Spin, message } from 'antd';
import { injectIntl } from 'react-intl';
import { getJobMessages, getErrorMessages, getPopConfirm, getFields, getClientMessages } from '../../../../constants/messages';
import { SearchForClient } from '../../actions';
import { getObjectInArray } from '../../../../utilities/util';
import debounce from 'lodash/debounce';

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

let jobMessages = getJobMessages(),
    errorMessages = getErrorMessages(),
	fieldMessages = getFields(),
    PopConfirmMessages = getPopConfirm(),
    clientMessages = getClientMessages();

const messages = {
    ...jobMessages,
    ...errorMessages,
    ...PopConfirmMessages,
    ...fieldMessages,
    ...clientMessages
};

class ClientTemplate extends React.Component {

    constructor(props) {
		super(props);
		this.handleClientSearch = debounce(this.handleClientSearch, 250);
		this.state = {
            isLoading: false,
            clients: []
        };
    }

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    setLoading = (isLoading) => this.setState({ isLoading });

    handleClientSearch = (name) => {
        const { getIntl, setLoading } = this;
        if (name.length <= 0 ) this.setState({ clients: [] });
        else {
            setLoading(true);
            SearchForClient(name)
            .then((response) => this.setState({ isLoading: false, clients: response }))
            .catch( () => {
                this.setState({ isLoading: false, clients: [] });
                message.error(getIntl("unableToSearchClients"));
            });
        }
    }

    onChange = (clientId) => {
        const { state, props } = this;
        const { clients } = state;
        const { onChange } = props;
        if ('onChange' in props) onChange(getObjectInArray('id', parseInt(clientId), clients));
        this.setState({ clients: [] });
    }

    buildOptions = () => {
        const { state, props, getIntl } = this;
        const { clients } = state;
        const { client } = props;
        let options = [];
        let enabled = [];
        let disabled = [];
        if (client.id !== undefined) options.push(<Option key={clients.length} value={client.id}>{client.name}</Option>);
        clients.forEach((client, index) => {
        if (client.isDisabled)
            disabled.push(<Option key={index} value={client.id} disabled><StopOutlined style={{fontSize: 10}} /> {client.name}</Option>);
        else
            enabled.push(<Option key={index} value={client.id}>{client.name}</Option>);
        });
        options.push(
            <OptGroup key="enabled" label={getIntl("enabled")}>
                {enabled}
            </OptGroup>
        );
        options.push(
            <OptGroup key="disabled" label={getIntl("disabled")}>
                {disabled}
            </OptGroup>
        );
        return options;
    }
    
	render() {
        const { state, props, getIntl, onChange, handleClientSearch, buildOptions } = this;
        const { isLoading } = state;
        const { form, disabled, client, required, fieldName } = props;
        const { id } = client;
        const { getFieldDecorator } = form;
        let clientOptions = buildOptions();
        const label = 'label' in props ? props.label : getIntl("client");
        const placeholder = 'placeholder' in props ? props.placeholder : label;
        return(
            <FormItem label={label} >
            {
                getFieldDecorator(fieldName, 
                    {
                        initialValue: id,
                        rules: [{
                            required,
                            message: getIntl("clientError"), 
                        }],
                        onChange: onChange
                    }
                )(
                    <Select
                        className="job-full-component"
                        showSearch
                        allowClear={!required}
                        disabled={disabled}
                        placeholder={placeholder}
                        optionFilterProp="children"
                        onSearch={(value) => handleClientSearch(value)}
                        notFoundContent={isLoading ? <Spin size="small" /> : getIntl("notFound")}
                    >
                        {clientOptions}
                    </Select>
                )
            }
            </FormItem>
        );
    }
}

ClientTemplate.defaultProps = {
    client: {},
    disabled: false,
    required: true,
    fieldName: "clientId"
};

ClientTemplate.propTypes = {
	intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    client: PropTypes.object,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    required: PropTypes.bool.isRequired,
    label: PropTypes.string,
    fieldName: PropTypes.string.isRequired,
    placeholder: PropTypes.string
};

export default injectIntl(ClientTemplate);