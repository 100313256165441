import axios from 'axios';
import moment from 'moment';
import { FETCH_UNIT, ADD_UNIT_SUCCESS, EDIT_UNIT_SUCCESS, DELETE_UNIT_SUCCESS, SET_SEARCH_UNIT } from './actionTypes';
import { UNITLOCATION } from '../Units/constants';

export const GetAllUnits = () => async (dispatch) => {
	let filter = { "order": "id ASC" }
    const response = await axios.get(`/Units?filter=${encodeURIComponent(JSON.stringify(filter))}`)
	dispatch({ type: FETCH_UNIT, units: response.data });
    return response.data;
};

export const AddNewUnit = (unit) => async (dispatch) => {
	const response = await axios.post(`/Units/`, unit);
	dispatch({ type: ADD_UNIT_SUCCESS, units: response.data });
	return response.data;
};

export const GetUnitsAvailable = async () => {
	const filter = {
		where: { location: UNITLOCATION.WAREHOUSE.id },
	};
	const response = await axios.get(`/Units?filter=${encodeURIComponent(JSON.stringify(filter))}`)
	return response.data;
}

export const UpdateUnit = (unit) => async (dispatch, getState) => { 
	let state = getState();
	const units = state.unit.units;

	const response = await axios.put(`/Units/${unit.id}`, unit);
	const editedUnits = units.map((item) => {
		if(item.id === response.data.id)
			return {
				...item, 
				description: response.data.description,
				pin: response.data.pin,
				imei: response.data.imei,
				modelId: response.data.modelId,
			}
		return item;
	})
	dispatch({ type: EDIT_UNIT_SUCCESS, units: editedUnits });
	return response.data;
};

export const DeleteUnit = (ids) => async (dispatch, getState) => {
	let state = getState();
	const units = state.unit.units;

	let options = { data: { ids: ids } };
	const response = await axios.delete(`/Units`, options);
	const result = units.filter(unit => !ids.includes(unit.id));
	dispatch({ type: DELETE_UNIT_SUCCESS, units: result });
	return response.data;
};

export const CheckPinExistance = async (pin) => {
	const filter = { pin };
	try {
		let response = await axios.get(`/Units/count?where=${encodeURIComponent(JSON.stringify(filter))}`);
		return response.data.count;
	} catch (err) {
		return 0;
	}
};

export const CheckImeiExistance = async (imei) => {
	const filter = { imei };
	try {
		let response = await axios.get(`/Units/count?where=${encodeURIComponent(JSON.stringify(filter))}`);
		return response.data.count;
	} catch (err) {
		return 0;
	}
};

export const DownloadNotAdded = (units) => () => {
	let params = { units: units };
	return exportExcel('ExportNotAddedUnits', params, 'NotAddedUnits');
};

export const DownloadUnitsTemplate = () => () => {
	let params = {};
	return exportExcel('ExportUnitsTemplate', params, 'UnitsTemplate');
};

const exportExcel = (url, params, name) => {
	return axios({
		url: '/Units/' + url,
		method: 'POST',
		responseType: 'blob',
		data: params,
	}).then((response) => {
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `${name}-${moment().format('DD/MM/YYYY hh:mm A')}.xlsx`);
		document.body.appendChild(link);
		link.click();
	});
};

export const HandleUploadFile = (values, updateUnits) => async (dispatch) =>{
	const formData = new FormData();
	formData.append('file', values.file);
	formData.append('modelId', values.modelId);
	formData.append('hasHeaders', values.hasHeaders);
	formData.append('hasDuplicates', values.hasDuplicates);

	const response = await axios.post(`/Units/UploadFile`, formData, { headers: { 'Content-Type': 'multipart/form-data' }, });
	if(response) {
		let uploadResponse = response.data;
		const allUnits = await dispatch(GetAllUnits());
		updateUnits(allUnits, true);
		return uploadResponse;
	}
};

export const setSearch =(value) => (dispatch) => {
	dispatch({type: SET_SEARCH_UNIT, search:value})
} 