import PropTypes from 'prop-types';
import { EditFilled } from '@ant-design/icons';
import { Form, Mention } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Button, Modal, Row, Tooltip } from 'antd';
import { connect } from "react-redux";
import { injectIntl } from 'react-intl';
import { getReportMessages, getLanguageIntl } from '../../constants/messages';
import React from 'react';
import CommentList from '../Clients/ViewClient/CommentList';
import { MakeComment, ForwardEmail } from './actions';
import FormItem from 'antd/lib/form/FormItem';
const { toContentState, getMentions, toString } = Mention;

let reportMessages = getReportMessages(),
    languageMessages = getLanguageIntl();

const messages = {
    ...reportMessages,
    ...languageMessages,
};

class CommentsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            validUsers: []
        };
    }

    componentDidMount() {
        this.setState({
            validUsers: this.props.accountsEmails.map(account => account.name)
        });
    }
    
    checkMention = () => {
        this.props.form.validateFields((err,values) => { 
            if(err )
                return;
            
            this.setState({ loading: true });
            this.submitComment(toString(values.mention));
            const mentions = getMentions(values.mention).map( (mention) => {return mention.slice(1, mention.length); });
            let addresses = this.getAddresses(mentions);
            let message =  this.formatEmailMessage(toString(values.mention));
            this.props.forwardEmail(this.props.user.name, addresses, message).
            then( () => {
                this.setState({ loading: false });
            });
        });
    }

    checkIfEmpty = (rule, value, callback) => {
        if(value === undefined || toString(value).trim()==='')
            callback(new Error(this.props.intl.formatMessage({ ...messages.invalidEmptyInput })));
        callback();
    }

    submitComment = (value) => {
        if (!value)
            return;
        this.props.makeComment(this.props.client.id, value).then(() => {
            this.props.getData();
            this.props.form.resetFields(["mention"]);
        });
    }

    getAddresses(mentions) {
        let {accountsEmails} = this.props;
        let addresses = mentions.map((mention) => { 
            let index = accountsEmails.findIndex( account => account.name === mention);
            if (index > -1) return accountsEmails[index];
        });
        return addresses.filter(function(elem, index, self) {
            return index === self.indexOf(elem) && elem !== undefined;
        });
    }

    formatEmailMessage(bodyContent) {
        let formatedDate = new Date();
        return  `<br></br> 
            <font size="3" color="black">
                <p> ${this.props.intl.formatMessage({ ...messages.commentsOf })} <b>${this.props.client.name}</b>: </p> 
            </font>    
            <br></br>
            <font size="3" color="black"> <em>${this.props.user.name} ${this.props.intl.formatMessage({ ...messages.says })}:</em> </font>
            <br></br>
            <font size="3" color="black">${bodyContent}</font>
            <br></br>
            <font size="2" color="black"> <b>${this.props.intl.formatMessage({ ...messages.date })}:</b> 
                <em> ${formatedDate.toLocaleDateString(this.props.intl.formatMessage(
                    { ...messages.intl }),
                    {
                        year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric',
                        minute: 'numeric', hour12: true
                    }
                )} </em> 
            </font>`;
    }

    CommitAccept = () => {
        this.props.handleOk();
    }

    render() {
        if (this.props.client === undefined)
            return <div></div>;
        return (
            <Modal
                title={`${this.props.intl.formatMessage({ ...messages.client })}: ${this.props.client.name} `}
                destroyOnClose
                open={this.props.showModal}
                onCancel={this.CommitAccept}
                loading={this.state.loading}
                footer={
                    <div>
                        <Button key="accept" type="primary" size="default" onClick={this.CommitAccept} loading={this.state.loading}  className="button-spacing">
                            {this.props.intl.formatMessage({ ...messages.accept })}
                        </Button>
                    </div>
                }
                className="invoicesModal"
            >
                <Form className="addCommentInput">
                    <Row>
                        <Col span={22}>
                            <FormItem>
                                {this.props.form.getFieldDecorator('mention', {
                                     rules: [
                                        { validator: this.checkIfEmpty },
                                      ],
                                      initialValue: toContentState(''),
                                })(
                                    <Mention
                                        placeholder={this.props.intl.formatMessage({ ...messages.comment })}
                                        suggestions={this.state.validUsers}
                                        notFoundContent={this.props.intl.formatMessage({ ...messages.userNotFound })}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={2} className="searchBarButton">
                            <FormItem>
                                <Tooltip placement="right" title={"Submit Comment"}> <Button shape="circle-outline" type="primary" onClick={this.checkMention}> <EditFilled /> </Button> </Tooltip>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
                <CommentList
                    messages={this.props.client.activityLog}                
                    loading={this.state.loading}
                />
            </Modal>
        );
    }
}

CommentsModal.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    showModal: PropTypes.bool.isRequired,
    accountsEmails: PropTypes.array,
    client: PropTypes.object,
    user: PropTypes.object,
    handleOk: PropTypes.func.isRequired,
    makeComment: PropTypes.func.isRequired,
    getData: PropTypes.func.isRequired,
    forwardEmail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        "user": state.auth.user,
    };
};

const mapDispatchToProps = (dispatch) => ({
    makeComment: (clientId, comment) => dispatch(MakeComment(clientId, comment)),
    forwardEmail: (sender, recievers, message) => dispatch(ForwardEmail(sender, recievers, message)),

});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(CommentsModal)));

