import PropTypes from 'prop-types';
import { MinusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, InputNumber, Row, Select, Input } from 'antd';
import { connect } from "react-redux";
import { injectIntl } from 'react-intl';
import { getReportMessages, getIncomeMessages } from '../../../constants/messages';
import React from 'react';
// import { roundN } from '../../utilities/util';
import { GetAccountCategories, FilterAllCategories } from '../actions';
const { TextArea } = Input;



let reportMessages = getReportMessages(),
    incomeMessages = getIncomeMessages();

const messages = {
    ...reportMessages,
    ...incomeMessages
};

class CategoryEntry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            CategoryEntrys: [],
            focused: false
        }
    }

    componentDidMount() {
        this.props.filterAllCategories({ status: 2, searchText: "" }, 1, 0);
    }

    amountValidator = (rule, value, callback) => {
        if (!value > 0)
            callback(this.props.intl.formatMessage({ ...messages.invalidAmount }));
        callback();
    }

    filterAllCategories = (input) => {
        this.props.filterAllCategories({ status: 2, searchText: input });
    }

    renderCategoryInput = () => {
        const { getFieldDecorator } = this.props.form;
        return (<Form.Item>
            {getFieldDecorator(`categories[${this.props.index}]`, {
                rules: [{
                    required: true, message: this.props.intl.formatMessage({ ...messages.categories }),
                }],
                initialValue: this.props.item.category
            })(
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder={`${this.props.intl.formatMessage({ ...messages.categories })}`}
                    optionFilterProp="children"
                    onSearch={this.filterAllCategories}
                  
                >
                    {this.props.categories.map((cat) =>
                        <Select.Option key={cat.id} value={cat.id}> {cat.name} </Select.Option>)}
                </Select>
            )
            }
        </Form.Item>);
    }

    formatPrice = (price) => {
        const { symbol } = this.props.currencyDisplayer;
        let currencySymbol = `${symbol} `;
        
        if (isNaN(parseFloat(price)))
            return currencySymbol;

        if (this.state.focused)
            return (currencySymbol + price);
        
        let formatedPrice = parseFloat(price).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 4
        });

        return (currencySymbol + formatedPrice);
    }

    renderAmountInput = () => {
        const { getFieldDecorator } = this.props.form;
        let str = `amounts[${this.props.index}]`;
        return (
            <Form.Item>
                {getFieldDecorator(str, {
                    rules: [{
                        required: true, message: this.props.intl.formatMessage({ ...messages.amount }),
                    },
                    {
                        validator: this.amountValidator
                    }],
                    initialValue: this.props.listAmount
                })(
                    <InputNumber
                        placeholder={this.props.intl.formatMessage({ ...messages.amount })}
                        formatter={value => this.formatPrice(value)}
                        parser={value => value.replace(/[^0-9.]/g, "")}
                        size={"default"}
                        style={{ width: '100%' }}
                        onChange={(amount) => { this.props.updateAmount(amount, this.props.index) }}
                        precision={4}
                        onFocus={() => this.setState({focused: true})}
                        onBlur={() => this.setState({focused: false})}
                    />
                )}
            </Form.Item>
        );
    }

    renderRemoveButton = () => {
        return (
            <div style={{ paddingTop: "8px" }}>
                <Button disabled={this.props.deleteDisabled} size="small" shape="circle-outline" icon={<MinusOutlined />} onClick={() => this.props.removeAt(this.props.index)} />
            </div>
        );
    }

    commentInput = () => {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form.Item>
                {getFieldDecorator(`comments[${this.props.index}]`, {
                    rules: [{}],
                    initialValue: this.props.item.description
                })(
                    <TextArea style={{ width: "100%" }} rows={1} placeholder={this.props.intl.formatMessage({ ...messages.comment })} />
                )}
            </Form.Item>
        );
    }

    render() {
        return (
            <Row >
                <Row type="flex" justify="center">
                    <Col span={10} style={{ margin: "0 8px" }}>
                        {this.renderCategoryInput()}
                    </Col>
                    <Col style={{ margin: "0 10px" }} span={10}>
                        {this.renderAmountInput()}
                    </Col>
                    <Col span={2}>
                        {this.renderRemoveButton()}
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ paddingLeft: "10px" }}>
                        {this.commentInput()}
                    </Col>
                </Row>
            </Row>
        );
    }
}

CategoryEntry.propTypes = {
    intl: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    form: PropTypes.object.isRequired,
    currencyDisplayer: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    listAmount: PropTypes.number.isRequired,
    deleteDisabled: PropTypes.bool.isRequired,
    removeAt: PropTypes.func.isRequired,
    filterAllCategories: PropTypes.func.isRequired,
    updateAmount: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    getAccountCategories: () => dispatch(GetAccountCategories()),
    filterAllCategories: (searchData, page, pageSize) => dispatch(FilterAllCategories(searchData, page, pageSize))
});

const mapStateToProps = (state) => {
    return {
        categories: state.incomeReport.categories,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(CategoryEntry)));