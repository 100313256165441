import React from 'react';
import Icon from '@ant-design/icons';

const LeasedSvg = () => (
	<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
		width="1.5em" height="1.5em" viewBox="0 0 64.000000 64.000000"
		preserveAspectRatio="xMidYMid meet">

		<g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
			fill="#000000" stroke="none">
			<path d="M171 481 c-25 -16 7 -81 40 -81 23 0 25 18 4 26 -8 4 -15 13 -15 21
0 12 8 14 39 9 82 -13 119 -43 74 -61 -16 -6 -16 -8 -3 -18 8 -7 22 -25 30
-40 20 -39 64 -60 112 -54 47 6 67 21 85 65 18 42 3 92 -34 122 -31 24 -99 27
-130 5 -20 -14 -28 -14 -82 0 -66 17 -100 19 -120 6z m304 -27 c16 -6 45 -54
45 -73 0 -6 -11 1 -25 15 -21 21 -28 23 -46 14 -24 -13 -22 -48 3 -64 13 -8
23 -8 38 2 26 16 25 8 -2 -18 -47 -44 -131 -6 -131 60 0 54 59 86 118 64z"/>
			<path d="M244 408 c-7 -11 13 -28 34 -28 6 0 12 6 12 14 0 17 -38 28 -46 14z" />
			<path d="M67 274 c-13 -14 6 -24 44 -24 l39 0 0 -64 0 -65 -42 -3 c-69 -5 -40
-23 37 -24 39 0 94 -8 124 -17 30 -9 67 -17 83 -17 31 0 196 79 216 103 14 17
7 43 -13 51 -19 7 -88 -17 -93 -32 -5 -15 18 -15 52 -2 14 5 26 6 26 2 0 -12
-168 -92 -192 -92 -12 0 -45 8 -75 17 l-53 17 0 54 0 55 36 -6 c20 -3 44 -13
54 -22 10 -9 35 -15 63 -15 72 0 56 -19 -18 -22 -89 -4 -88 -22 2 -26 64 -3
73 -1 83 18 19 36 -3 60 -57 60 -30 0 -53 6 -65 17 -12 10 -42 18 -79 21 -32
2 -62 8 -65 13 -6 10 -97 12 -107 3z"/>
		</g>
	</svg>
);

const LeasedIconBuilder = props => <Icon component={LeasedSvg} {...props} />;

class LeasedIcon extends React.Component {
	render() {
		return <LeasedIconBuilder />;
	}
}

export default LeasedIcon;