import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { getJobMessages } from '../../../../constants/messages';
import YesNoTemplate from './YesNoTemplate';

const jobMessages = getJobMessages();

const messages = {
	...jobMessages
};

class DeviceAccessories extends React.Component {

    getIntl = (str, values = {}) => this.props.intl.formatMessage({...messages[str]}, values);

    renderPanicButton = () => {
        const { props, getIntl } = this;
        const { form, disabled, hasPanicButton, onChangeHasPanicButton } = props;
        const onChange = hasPanicButton => ('onChangeHasPanicButton' in props) && onChangeHasPanicButton(hasPanicButton);
        return (
            <YesNoTemplate
                form={form}
                initialValue={hasPanicButton}
                disabled={disabled}
                onChange={onChange}
                label={getIntl("hasPanicButton")}
                fieldName={"hasPanicButton"}
                errorMessage={getIntl("hasPanicButtonError")}
            />
        );
    }

    renderCanLockUnlockDoors = () => {
        const { props, getIntl } = this;
        const { form, disabled, canLockUnlockDoors, onChangeCanLockUnlockDoors } = props;
        const onChange = canLockUnlockDoors => ('onChangeCanLockUnlockDoors' in props) && onChangeCanLockUnlockDoors(canLockUnlockDoors);
        return (
            <YesNoTemplate
                form={form}
                initialValue={canLockUnlockDoors}
                disabled={disabled}
                onChange={onChange}
                label={getIntl("canLockUnlockDoors")}
                fieldName={"canLockUnlockDoors"}
                errorMessage={getIntl("canLockUnlockDoorsError")}
            />
        );
    }

    renderCanDisableEngine = () => {
        const { props, getIntl } = this;
        const { form, disabled, canDisableEngine, onChangeCanDisableEngine } = props;
        const onChange = canDisableEngine => ('onChangeCanDisableEngine' in props) && onChangeCanDisableEngine(canDisableEngine);
        return (
            <YesNoTemplate
                form={form}
                initialValue={canDisableEngine}
                disabled={disabled}
                onChange={onChange}
                label={getIntl("canDisableEngine")}
                fieldName={"canDisableEngine"}
                errorMessage={getIntl("canDisableEngineError")}
            />
        );
    }
    
	render() {
        const { renderPanicButton, renderCanLockUnlockDoors, renderCanDisableEngine } = this;
        return (
            <div>
                {renderPanicButton()}
                {renderCanLockUnlockDoors()}
                {renderCanDisableEngine()}
            </div>
        );
    }
}

DeviceAccessories.defaultProps = {
    disabled: false
};

DeviceAccessories.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    hasPanicButton: PropTypes.bool,
    onChangeHasPanicButton: PropTypes.func,
    canLockUnlockDoors: PropTypes.bool,
    onChangeCanLockUnlockDoors: PropTypes.func,
    canDisableEngine: PropTypes.bool,
    onChangeCanDisableEngine: PropTypes.func
};

export default injectIntl(DeviceAccessories);