import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { getErrorMessages, getJobMessages } from '../../../../constants/messages';
import YesNoTemplate from './YesNoTemplate';

let jobMessages = getJobMessages(),
    errorMessages = getErrorMessages();

const messages = {
	...jobMessages,
	...errorMessages
};

class UninstallSim extends React.Component {

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});
    
	render() {
        const { props, getIntl } = this;
        const { form, uninstallSim, disabled, onChange, disableYes, disableNo } = props;
        return (
            <YesNoTemplate
                form={form}
                initialValue={uninstallSim}
                disabled={disabled}
                onChange={onChange}
                label={getIntl("uninstallSim")}
                fieldName={`uninstallSim`}
                errorMessage={getIntl("uninstallSimError")}
                disableNo={disableNo}
                disableYes={disableYes}
            />
        );
    }
}

UninstallSim.defaultProps = {
    disabled: false,
    disableYes: false,
    disableNo: false
};

UninstallSim.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    uninstallSim: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    disableYes: PropTypes.bool,
    disableNo: PropTypes.bool
};

export default injectIntl(UninstallSim);