import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, message, Spin } from 'antd';
import { getFields, getErrorMessages } from '../../../../../constants/messages';
import { GetTechnicians } from '../../../actions';

const FormItem = Form.Item;
const { Option } = Select;

let fieldMessages = getFields(),
    errorMessages = getErrorMessages();

const messages = {
	...fieldMessages,
	...errorMessages
};

class Technicians extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            isLoading: false
        };
    }

	componentDidMount () {
        const { props, getIntl, setLoading } = this;
        const { getTechnicians } = props;
        setLoading(true);
        getTechnicians()
        .then(() => setLoading(false))
        .catch(() => {
            setLoading(false);
            message.error(getIntl("techniciansLoadError"));
        })
    }

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});
    setLoading = (isLoading) => this.setState({ isLoading });

    buildOptions = () => {
        const { technicianList } = this.props;
        return technicianList.map(technician => {
            const { id, name } = technician;
            return <Option key={id} value={id}>{name}</Option>;
        });
    }
    
	render() {
        const { state, props, getIntl, buildOptions } = this;
        const { isLoading } = state;
        const { getFieldDecorator } = props.form;
        const options = buildOptions();
        return(
            <FormItem label={getIntl("technicians")} >
            {
                getFieldDecorator(`technicians`)(
                    <Select
                        className="job-full-component"
                        showSearch
                        mode={'multiple'}
                        placeholder={getIntl("technicians")}
                        optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        notFoundContent={isLoading ? <Spin size="small" /> : getIntl("notFound")}
                        dropdownMatchSelectWidth={false}
                    >
                        {options}
                    </Select>
                )
            }
            </FormItem>
        );
    }
}

Technicians.defaultProps = {
    technicians: []
};

Technicians.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    getTechnicians: PropTypes.func.isRequired,
	technicianList: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
	return {
        technicianList: state.job.technicianList,
	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		getTechnicians: () => dispatch(GetTechnicians())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl((Technicians)));