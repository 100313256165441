import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { injectIntl } from 'react-intl';
import { getInvoicingMessages } from '../constants/messages';
import { roundN } from '../utilities/util';

let invoicingMessages = getInvoicingMessages();

const messages = {
	...invoicingMessages
};

class InvoiceSummary extends React.Component {

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

	getSubtotal = () => {
		const { products, exchangeRate } = this.props;
		let lpsSubtotal = 0;
		let dollarSubtotal = 0;
		products.forEach(product => {
			const { quantity, price } = product;
			lpsSubtotal += price * exchangeRate * quantity;
			dollarSubtotal += price * quantity;
		});
		return { lpsSubtotal, dollarSubtotal };
	}

    fullRender = () => {
        const { props, getIntl, getSubtotal } = this;
        const { taxExemption, exchangeRate, isv } = props;
		const round = (amount) => roundN(amount, 2);
		let subtotal = getSubtotal();
		const { lpsSubtotal, dollarSubtotal } = subtotal;
		const lpsIsv = taxExemption ? 0 : lpsSubtotal * isv;
		const dollarsIsv = taxExemption ? 0 : dollarSubtotal * isv;
		let dataSource = [
			{
				title: getIntl("Subtotal"),
				value: `$ ${round(dollarSubtotal)}`,
				key: 'subtotal'
			}, {
				title: ` `,
				value: `L ${round(lpsSubtotal)}`,
				key: 'subtotalL'
			}, {
				title: getIntl("Isv"),
				value: `$ ${round(dollarsIsv)}`,
				key: 'isv'
			}, {
				title: ` `,
				value: `L ${round(lpsIsv)}`,
				key: 'isvL'
			}, {
				title: getIntl("Total"),
				value: `$ ${round(dollarSubtotal + dollarsIsv)}`,
				key: 'total'
			}, {
				title: ` `,
				value: `L ${round(lpsSubtotal + lpsIsv)}`,
				key: 'totalL'
			}, {
				title: getIntl("ExchangeRate"),
				value: exchangeRate,
				key: 'exchangeRate'
			}, 
		];
		let columns = [
			{
				dataIndex: 'title',
				key: 'title',
				
			}, {
				dataIndex: 'value',
				key: 'value',
				className: 'column-money',
				render: (value) => {
					return (value);
				}
			}
		];

		return (
			<Table dataSource={dataSource} columns={columns} pagination={false} size="small" showHeader={false}/>
		);
    }
    
	render() {
        return this.fullRender();
    }
}

InvoiceSummary.defaultProps = {
    taxExemption: false
};

InvoiceSummary.propTypes = {
	intl: PropTypes.object.isRequired,
	products: PropTypes.array.isRequired,
    taxExemption: PropTypes.bool.isRequired,
	exchangeRate: PropTypes.number.isRequired,
	isv: PropTypes.number.isRequired,
};

export default injectIntl(InvoiceSummary);
