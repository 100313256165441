import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {  } from 'antd';
import { getJobMessages } from '../../../../constants/messages';
import BasicInformation from '../DefaultViews/BasicInformation';
import { JOBSTATUS } from  '../../constants';
const { TODO, DOING, MISSINGWON, DONE, CANCELLED, ANNULLED } = JOBSTATUS;

let jobMessages = getJobMessages();

const messages = {
	...jobMessages
};

class AddOns extends React.Component  {

    getIntl = (str, values = {}) => this.props.intl.formatMessage({...messages[str]}, values);

    renderTodo = () => {
        const { getField } = this.props;
        return <BasicInformation getField={getField}/>;
    }

    renderBasedOnStatus = () => {
        const { props, getIntl, renderTodo } = this;
        const { getField } = props;
        const status = getField("status");
        switch (status) {
            case TODO.id: 
            case CANCELLED.id: 
            case ANNULLED.id: return renderTodo();
            case MISSINGWON.id: 
            case DONE.id: 
            case DOING.id: 
            default: return <div>{getIntl("noStatusFormForType")}</div>;
        }
    }
    
	render(){
        return this.renderBasedOnStatus();
	}
}

AddOns.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    saveFields: PropTypes.func.isRequired,
    getField: PropTypes.func.isRequired
};

export default injectIntl(AddOns);