import PropTypes from 'prop-types';
import { List, Col, Row } from 'antd';
import { injectIntl } from 'react-intl';
import { getReportMessages, getLanguageIntl } from '../../../constants/messages';
import React from 'react';
import Embolden from '../../GlobalComponents/Embolden';

let reportMessages = getReportMessages(),
	languageMessages = getLanguageIntl();

const messages = {
	...reportMessages,
	...languageMessages,
};

class CommentList extends React.Component {

	render() {
		return (
			<List
				className="scrollableList"
				dataSource={this.props.messages}
				loading={this.props.loading}
				renderItem={item => {
					const locale = this.props.intl.formatMessage({ ...messages.intl });
					let formatedDate = new Date(item.date).toLocaleDateString(locale,
						{
							year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric',
							minute: 'numeric', hour12: true
						}
					);
					let messageBody = `${this.props.intl.formatMessage({ ...messages.message })}`
					let byMessage = `${this.props.intl.formatMessage({ ...messages.by })}:`;
					let atMessage = `${this.props.intl.formatMessage({ ...messages.date })}:`;
					let commentFooter = (
						<div>
							<Embolden header={byMessage} value={item.user.name} />
                            &nbsp;&nbsp;
							<Embolden header={atMessage} value={formatedDate} />
						</div>
					);
					return (
						<List.Item>
							<Col span={24}>
								<Row><Embolden header={messageBody} value={item.message} /></Row>
								<List.Item.Meta description={commentFooter} />
							</Col>
						</List.Item>
					)
				}}
			/>
		);
	}
}

CommentList.defaultProps = {
	messages: [],
	loading: false
};

CommentList.propTypes = {
	intl: PropTypes.object.isRequired,
	messages: PropTypes.array,
	loading: PropTypes.bool
};

export default injectIntl(CommentList);