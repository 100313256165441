import {
    CLEAN_REDUCER,
	CLEAR_SESSION,
	GET_PRODUCTS,
	GET_PRODUCT,
	UPDATED_PRODUCT,
	GET_PRODUCTS_OF_INVOICE,
	GET_PRODUCTS_OF_PROFORMA,
	GET_PRODUCTS_OF_QUOTATION,
	ADD_PRODUCT,
	CLEAN_PRODUCTS,
	GET_PRODUCT_CONFIGURATIONS,
	SET_SEARCH_PRODUCTS
} from './actionTypes';

const initState = {
	products: [],
	product: {},
	productsOfCurrentInvoice: [],
	productsOfCurrentProforma: [],
	productsOfCurrentQuotation: [],
	productConfigurations: [],
	search: ""
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case GET_PRODUCT_CONFIGURATIONS:
			return {
				...state,
				productConfigurations: action.productConfigurations
			};
		case GET_PRODUCTS:
			return {
				...state,
				"products": action.products
			};
		case GET_PRODUCT:
			return {
				...state,
				"product": action.product
			};
		case UPDATED_PRODUCT:
			return state;
		case GET_PRODUCTS_OF_INVOICE:
			return {
				...state,
				"productsOfCurrentInvoice": action.products
			};
		case GET_PRODUCTS_OF_PROFORMA:
			return {
				...state,
				"productsOfCurrentProforma": action.products
			};
		case GET_PRODUCTS_OF_QUOTATION:
			return {
				...state,
				"productsOfCurrentQuotation": action.products
			};
		case CLEAN_PRODUCTS:
			return {
				...state,
				"products": []
			};
		case ADD_PRODUCT:
			return state;
		case SET_SEARCH_PRODUCTS: 
			return {
				...state,
				search:action.search
			}
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;