import axios from 'axios';
import {
    GET_FIRST_ENTRIES,
    GET_MORE_ENTRIES,
    GET_ENTRIES_COUNT,
    GET_CATEGORIES_COUNT,
    GET_CATEGORIES,
    GET_BANKS,
    GET_BANK,
    GET_BANKS_COUNT,
    CLEAR_ENTRIES,
    GET_SELECT_CATEGORIES,
    SET_SEARCH_CATEGORIES,
    SET_SEARCH_BANKS
} from './actionTypes';
import { substituteObjectInArrayByValue } from '../../utilities/util'
const moment = require('moment');

let globalStatus = 0;
let globalSearchText = "";
let globalCurrentPage = 1;

export const RestoreFilterValues = () => {
    globalStatus = 0;
    globalSearchText = "";
    globalCurrentPage = 1;
};

//#region entries
export const CreateEntry = (entryData) => () => {
    let data = {
        entryData: entryData
    };
    return axios.post(`/AccountEntries/createEntry`, data);
};

export const EditEntry = (entryData, previousData) => () => {
    return axios.patch(`/AccountEntries/editEntry`, {entryData, previousData});
};

export const ClearEntries = () => (dispatch) => {
    dispatch({ type: CLEAR_ENTRIES });
};

export const GetFirstEntries = (id) => (dispatch) => {
    let filter = BuildEntriesFilter(id);
    return axios.get(`/AccountEntries?filter=${encodeURIComponent(JSON.stringify(filter))}`)
        .then((response) => {
            dispatch({ type: GET_FIRST_ENTRIES, payload: response.data })
        });
};

export const GetAccountEntries = (id, page) => (dispatch) => {
    let filter = BuildEntriesFilter(id, page);
    return axios.get(`/AccountEntries?filter=${encodeURIComponent(JSON.stringify(filter))}`)
        .then((response) => {
            dispatch({ type: GET_MORE_ENTRIES, payload: response.data, page: page })
        });
};

export const CountAccountEntries = (id) => (dispatch) => {
    let filter = {
        "where": { "bankId": id }
    };
    return axios.get(`/AccountEntries/count?where=${encodeURIComponent(JSON.stringify(filter.where))}`)
        .then((response) => {
            dispatch({ type: GET_ENTRIES_COUNT, count: response.data.count })
        });
};

function BuildEntriesFilter(id, page = 1, pageSize = 10) {
    let filter = {
        "include": [
            { "EntryCategories": ["category"] }
        ],
        "order": ["date DESC", "id DESC"],
        "limit": 10,
        "skip": (page - 1) * pageSize,
        "where": { "bankId": id }
    };
    return filter;
}

export const GetEntry = (entryId) => () => {
    let filter = {
        "include": [{
            "relation": "EntryCategories",
            "scope": {
                "include": "category"
            }
        },
        {
            "relation": "bank"
        }]
    };
    return axios.get(`/AccountEntries/${entryId}?filter=${encodeURIComponent(JSON.stringify(filter))}`)
        .then((response) => {
            return response.data;
        });
};


//#endregion

//#region Categories

export const CreateCategory = (category) => (dispatch, getState) => {
    const state = getState();
    let categories = state.incomeReport.categories;

    return axios.post(`/AccountCategories`, category).then((response) => {
        let categoriesCopy = [...categories];
        if (categoriesCopy.length >= 10)
            categoriesCopy.pop();
        categoriesCopy.unshift(response.data);
        dispatch(FilterCategories({ searchText: globalSearchText, status: globalStatus }, globalCurrentPage))
    });
};

export const UpdateCategory = (category, id) => (dispatch) => {
    return axios.patch(`/AccountCategories/${id}`, category)
        .then(() => {
            dispatch(FilterCategories({ searchText: globalSearchText, status: globalStatus }, globalCurrentPage))
        });
};

export const DeleteParent = (id) => (dispatch) => {
    return axios.patch(`/AccountCategories/${id}`, { "parentId": null })
        .then(() => {
            dispatch(FilterCategories({ searchText: globalSearchText, status: globalStatus }, globalCurrentPage))
        });
};

export const SwitchCategoryStatus = (category) => (dispatch) => {
    let newCat = { name: category.name, active: !category.active }
    return axios.patch(`/AccountCategories/${category.id}`, newCat)
        .then(() => {
            dispatch(FilterCategories({ searchText: globalSearchText, status: globalStatus }, globalCurrentPage))
        });
};

export const CountAccountCategories = (filter) => (dispatch) => {
    if (!filter)
        filter = { where: {} }
    return axios.get(`/AccountCategories/count?where=${encodeURIComponent(JSON.stringify(filter.where))}`)
        .then((response) => {
            dispatch({ type: GET_CATEGORIES_COUNT, count: response.data.count })
        });
};

export const GetAccountCategories = (page, filter, dispatchType) => (dispatch) => {
    if (!filter)
        filter = BuildGetCategories(page);
    if (!dispatchType)
        dispatchType = GET_CATEGORIES;
    return axios.get(`/AccountCategories?filter=${encodeURIComponent(JSON.stringify(filter))}`)
        .then((response) => {
            dispatch({ type: dispatchType, payload: response.data })
        });
};

export const FilterAllCategories = (searchObject, page = 1, pageSize = 10) => (dispatch) => {
    let filter = getBaseFilter(searchObject, pageSize, page);
    addIncludeFilter(filter);
    dispatch(CountAccountCategories(filter))
    return dispatch(GetAccountCategories(page, filter));
};

export const FilterCategories = (searchObject, page = 1, pageSize = 10) => (dispatch) => {
    let filter = getBaseFilter(searchObject, pageSize, page);
    addParentFilter(filter);
    addIncludeFilter(filter);
    dispatch(CountAccountCategories(filter))
    dispatch({type:SET_SEARCH_CATEGORIES, search: searchObject.searchText});
    return dispatch(GetAccountCategories(page, filter));
};

export const FilterSelectCategories = (searchObject, page = 1, pageSize = 10) => (dispatch) => {
    let filter = getBaseFilter(searchObject, pageSize, page);
    addParentFilter(filter);
    addIncludeFilter(filter);
    return dispatch(GetAccountCategories(page, filter, GET_SELECT_CATEGORIES));
};

function addParentFilter(filter) {
    if (filter.where && filter.where.and)
        filter.where.and.push({ "parentId": null });
    else
        filter.where = { "and": [{ "parentId": null }] };
}

function addIncludeFilter(filter) {
    if (filter.include)
        filter.include.push({ "relation": "subCategories" });
    else
        filter.include = "subCategories";
}

function BuildGetCategories(page = 1, pageSize = 10) {
    let filter = {
        // "limit": 10,
        "skip": (page - 1) * pageSize,
        "where": { "parentId": null },
        "include": "subCategories"
    };
    if (pageSize !== 0)
        filter.limit = pageSize
    globalCurrentPage = page;
    return filter;
}
//#endregion

//#region Banks
export const FilterBanks = (searchObject, page = 1, pageSize = 10) => (dispatch) => {
    let filter = getBaseFilter(searchObject, pageSize, page);
    filter.include = banksIncludeFilter()
    dispatch(CountBanks(filter))
    dispatch({ type: SET_SEARCH_BANKS, searchBanks: searchObject.searchText })
    return dispatch(GetBanks(page, filter));
};

export const GetBank = (id) => (dispatch, getState) => {
    let filter = {
        "include": banksIncludeFilter()
    };
    let state = getState();
    return axios.get(`/Banks/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`)
        .then((response) => {
            let array = substituteObjectInArrayByValue('id', response.data.id, state.incomeReport.banks, response.data);
            dispatch({ type: GET_BANK, payload: array });
            return response.data;
        });
};

export const GetBanks = (page, filter) => (dispatch) => {
    if (!filter)
        filter = BuildGetBanks(page);
    return axios.get(`/Banks?filter=${encodeURIComponent(JSON.stringify(filter))}`)
        .then((response) => {
            dispatch({ type: GET_BANKS, payload: response.data })
        });
};

export const CountBanks = (filter) => (dispatch) => {
    if (!filter)
        filter = { where: {} }
    return axios.get(`/Banks/count?where=${encodeURIComponent(JSON.stringify(filter.where))}`)
        .then((response) => {
            dispatch({ type: GET_BANKS_COUNT, count: response.data.count })
        });
};

export const UpdateBank = (bank, id) => (dispatch) => {
    return axios.patch(`/Banks/${id}`, bank)
        .then(() => {
            dispatch(FilterBanks({ searchText: globalSearchText, status: globalStatus }, globalCurrentPage))
        });
};

export const CreateBank = (bank) => (dispatch, getState) => {
    const state = getState();
    let banks = state.incomeReport.banks;

    return axios.post(`/Banks`, bank).then((response) => {
        let banksCopy = [...banks];
        if (banksCopy.length >= 10)
            banksCopy.pop();
        banksCopy.unshift(response.data);
        dispatch(FilterBanks({ searchText: globalSearchText, status: globalStatus }, globalCurrentPage))
    });
};

function banksIncludeFilter() {
    return [
        {
            "relation": "entries",
            "scope": {
                "limit": 1,
                "order": "date DESC"
            }
        },
        {
            "relation": "balanceSheet",
            "scope": {
                "limit": 1,
                "order": "createdAt DESC"
            }
        }
    ];
}

function BuildGetBanks(page = 1, pageSize = 10) {
    let filter = {
        "limit": 10,
        "skip": (page - 1) * pageSize,
        "include": {
            "relation": "entries",
            "scope": {
                "limit": 1,
                "order": "date DESC"
            }
        }
    };
    globalCurrentPage = page;
    return filter;
}

//#endregion

//#region Closuress

export const GetGenerateBalanceSheetInfo = (bankId, balanceMonth) => () => {
    return axios({
        url: `/BalanceSheets/getGenerateBalanceSheetInfo`,
        method: 'GET',
		params: {
            bankId,
            balanceMonth: moment(balanceMonth).toISOString()
        }
    })
    .then(response => response.data);
};

export const GenerateBalanceSheet = (bankId, balanceMonth, accountId) => () => {
    return axios.post(`/BalanceSheets/generateBalanceSheet`, { bankId, balanceMonth: moment(balanceMonth).toISOString(), accountId });
};

export const GetNextBalanceMonth = (bankId) => () => {
    return axios({
        url: `/BalanceSheets/getNextBalanceMonth`,
        method: 'GET',
		params: { bankId }
    })
    .then(response => response.data);
};

//#endregion

//#region Extras
function getBaseFilter(searchObject, pageSize, page) {
    let { regx, extraFilter } = getSearchValues(searchObject);
    let filter = {
        "limit": pageSize,
        "skip": (page - 1) * pageSize,
    };
    globalCurrentPage = page;
    globalSearchText = searchObject.searchText;
    globalStatus = searchObject.status;

    let whereClause = undefined;
    if (searchObject.searchText.length > 0) {
        whereClause = { "and": [{ "name": { "like": regx } }] };
    }
    if (extraFilter != 0 && extraFilter != undefined) {
        if (whereClause)
            whereClause.and.push({ "active": extraFilter - 1 });
        else
            whereClause = { "and": [{ "active": extraFilter - 1 }] };
    }
    filter = {
        ...filter,
        where: !whereClause ? {} : whereClause
    };
    return filter;
}

function getSearchValues(searchObject) {
    let regx, extraFilter;
    if (searchObject) {
        regx = "%" + searchObject.searchText + "%";
        extraFilter = searchObject.status;
    }
    else {
        regx = "%" + globalSearchText + "%";
        extraFilter = searchObject.status;
    }
    return { regx, extraFilter };
}





//#endregion
