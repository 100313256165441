import { connect } from 'react-redux';
import Client from './PreRegistration';
import {
	GetPreRegisterClients,
	SearchPreRegisterClients,
	GetClientInfo,
} from '../actions';

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		clients: state.client.clients,
		recordCount: state.client.count,
		showEditModal: state.client.showEditModal,
		search: state.client.searchPreregistration,
		isMaster: state.auth.isMaster
	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		getClients: ( page, pageSize ) => {
			return dispatch(GetPreRegisterClients( page, pageSize ));
		},
		searchClients: (search , page, pageSize) => {
			return dispatch(SearchPreRegisterClients(search, page, pageSize));
		},
		getClientInfo: (id) =>  dispatch(GetClientInfo(id)),
	};
};

export default  connect(mapStateToProps, mapDispatchToProps)(Client);