import {SET_LANGUAGE, CLEAR_SESSION} from '../constants/actionTypes';
import {locale} from '../constants/global';

const initState = {
	defaultLanguage: 'en_US',
	language:  null,
	messages: locale['en_US'].messages,
	code: locale['en_US'].intl
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case SET_LANGUAGE:
		return {
			...state,
			language: action.language,
			messages: action.messages,
			code: locale[action.language].intl
		};
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;
