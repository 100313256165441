import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getSideMenu } from '../constants/messages';
import Dashboard from '../features/Dashboard/Dashboard';

let sideMenuMessages = getSideMenu();

const messages = {
	...sideMenuMessages
};

class HomePage extends React.Component {

	getIntl = (str) => {
		return this.props.intl.formatMessage({...messages[str]});
	}

	renderDashboard = () => {
		const { getIntl, props } = this;
		const { isMaster } = props;
		if (isMaster) return <Dashboard />;
		return <div>{getIntl("home")}</div>
	}

	render() {
		return this.renderDashboard();
	}
}

HomePage.propTypes = {
	intl: PropTypes.object.isRequired,
	isMaster: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
    isMaster: state.auth.isMaster
});

export default connect(mapStateToProps)(injectIntl(HomePage));