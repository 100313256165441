import axios from 'axios';
import {
	GET_PRODUCTS, GET_PRODUCT, UPDATED_PRODUCT, GET_PRODUCTS_OF_INVOICE, GET_PRODUCTS_OF_PROFORMA, GET_PRODUCTS_OF_QUOTATION, CLEAN_PRODUCTS, ADD_PRODUCT,
	GET_PRODUCT_CONFIGURATIONS,
	SET_SEARCH_PRODUCTS
} from './actionTypes';

export const GetProductConfigurations = () => (dispatch) => {
    return axios.get(`/ProductConfigurationTables`)
    .then(response => dispatch({ type: GET_PRODUCT_CONFIGURATIONS, productConfigurations: response.data }));
};

export const GetProducts = () => async (dispatch) => {
	const filter = { "order": "id DESC" };
	const response = await axios.get(`/Products?filter=${encodeURIComponent(JSON.stringify(filter))}`);
	dispatch({type: GET_PRODUCTS, products: response.data});
};

export const GetProduct = (id) => async (dispatch) => {
	const response = await axios.get(`/Products/${id}`);
	dispatch({type: GET_PRODUCT, product: response.data});
	return response.data;
}

export const GetProductJobs = (id) => {
	return axios.get(`/Products/${id}`).then((response)=>{
		return response.data;
	});
}

export const EditProduct = (id, updatedProduct) => async (dispatch) => {
	await axios.patch(`/Products/${id}`, updatedProduct)
	dispatch({type:UPDATED_PRODUCT});
};

export const GetFirstProducts = () => (dispatch) => {
	let filter = {
		"limit": 10
	};

	return axios.get(`/Products?filter=${encodeURIComponent(JSON.stringify(filter))}`)
	.then((response)=>{
		dispatch({type: GET_PRODUCTS, products: response.data});
	});
};

export const SearchProducts = (search) => async (dispatch) => {
	const filter = {
		"where": {
			"or": [
				{"code": {"like": "%" + search + "%"}},
				{"description": {"like": "%" + search + "%"}},
			]	
		}
	};



	const response = await axios.get(`/Products?filter=${encodeURIComponent(JSON.stringify(filter))}`);
	dispatch({type: GET_PRODUCTS, products: response.data});
	dispatch({type:SET_SEARCH_PRODUCTS, search:search});
};

export const GetProductsOfInvoice = (invoiceId) => (dispatch) => {
	let filter = {
		"where": {
			"invoiceId": invoiceId
		},
		"include": "product" 
	};
	return axios.get(`/InvoiceProducts?filter=${encodeURIComponent(JSON.stringify(filter))}`)
	.then((response)=>{
		dispatch({type: GET_PRODUCTS_OF_INVOICE, products: response.data});
		return response.data;
	});
};

export const GetProductsOfProforma = (proformaId) => (dispatch) => {
	let filter = {
		"where": {
			"proformaId": proformaId
		},
		"include": "product" 
	};
	return axios.get(`/ProformaProducts?filter=${encodeURIComponent(JSON.stringify(filter))}`)
	.then((response)=>{
		dispatch({type: GET_PRODUCTS_OF_PROFORMA, products: response.data});
		return response.data;
	});
};

export const GetProductsOfQuotation = (quotationId) => (dispatch) => {
	let filter = {
		"where": {
			"quotationId": quotationId
		},
		"include": "product" 
	};
	return axios.get(`/QuotationProducts?filter=${encodeURIComponent(JSON.stringify(filter))}`)
	.then((response)=>{
		dispatch({type: GET_PRODUCTS_OF_QUOTATION, products: response.data});
		return response.data;
	});
};

export const CleanProducts = () => (dispatch) => {
	dispatch({type: CLEAN_PRODUCTS});
};

export const AddProduct = (product) => async (dispatch) => {
	await axios.post(`/Products`, product)
	dispatch({type: ADD_PRODUCT});
};
