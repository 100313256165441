import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Spin, Divider } from 'antd';
import { injectIntl } from 'react-intl';
import { getJobMessages, getModalMessages } from '../../../constants/messages';
import JobForm from './Form';
import UpdateForm from '../AddUpdateJob/Form';
import Buttons from './Buttons';
import { getNestedValue, existsInArray } from '../../../utilities/util';
import { JOBSTATUS, pastFiveDays, EDITABLETYPES } from '../constants';
import { ROLES } from '../../../constants/global';
const { TODO, DOING, CANCELLED, ANNULLED } = JOBSTATUS;

let modalMessages = getModalMessages(),
    JobsMessages = getJobMessages();

const messages = {
	...modalMessages,
    ...JobsMessages
};

const defaultState = {
    isLoading: false,
    isUpdating: false,
    loadingKey: "next",
    job: { },
    updatingJob: { }
};

class JobModal extends React.Component  {
    constructor(props) {
		super(props);
		this.state = {...defaultState};
    }
    
    saveJob = (job) => this.setState({ job });
	saveUpdatingJob = (updatingJob) => this.setState({ updatingJob });
    getJob = () => this.state.job;
    getUpdatingJob = () => this.state.updatingJob;
    setLoading = (isLoading) => this.setState({ isLoading });
    setLoadingKey = (loadingKey) => this.setState({ loadingKey });
	setIsUpdating = (isUpdating) => this.setState({ isUpdating, updatingJob: { } });
	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

	getField = (field) => {
        const job = this.getJob();
        return getNestedValue(field, job);
    }

	handleCancel = () => {
        this.setState(defaultState);
		this.props.handleCancel();
    }

    jobIsCancelled = () => {
        const { getField } = this;
        const status = getField("status");
        return status === CANCELLED.id || status === ANNULLED.id;
    }

    jobIsBeforeFiveDaysOrMaster = () => {
        const { props, getField } = this;
        const { isMaster } = props;
        const programmedDate = getField("programmedDate");
        return !pastFiveDays(programmedDate) || isMaster;
    }

    jobIsEditableTypeOrStatus = () => {
        const { getField } = this;
        const status = getField("status");
        const type = getField("type");
        return existsInArray(type, EDITABLETYPES) || status === TODO.id || status === DOING.id;
    }

    renderEditButton = () => {
        const { getIntl, setIsUpdating, jobIsCancelled, jobIsBeforeFiveDaysOrMaster, jobIsEditableTypeOrStatus } = this;
        const isCancelled = jobIsCancelled();
        const isbeforeFiveDaysOrMaster = jobIsBeforeFiveDaysOrMaster();
        const isEditableTypeOrStatus = jobIsEditableTypeOrStatus();
        const isEditable = !isCancelled && isbeforeFiveDaysOrMaster && isEditableTypeOrStatus;
        if (isEditable)
            return (
                <span>
                    <Divider type="vertical" />
                    <a onClick={() => setIsUpdating(true)}>
                        {getIntl("edit")}
                    </a>
                </span>
            );
    }

    renderTitle = () => {
        const { state, props, getIntl, renderEditButton } = this;
        const { isUpdating } = state;
        const { jobId, userRoles } = props;
        if (isUpdating) return <span>{`${getIntl("editJob")} (#${jobId})`}</span>;
        return (
            <span>
                <span>{`${getIntl("jobView")} (#${jobId})`}</span>
                {!userRoles.includes(ROLES.TECHSUPPORT) && renderEditButton()}
            </span>
        );
    }

    renderButtons = () => {
        const { state, props, handleCancel, setLoading, setLoadingKey, saveJob, setIsUpdating, jobIsCancelled, jobIsBeforeFiveDaysOrMaster, getField } = this;
        const { isLoading, loadingKey, isUpdating } = state;
        const { form, jobId } = props;
        const status = getField("status");
        const type = getField("type");
        const isCancelled = jobIsCancelled();
        const isbeforeFiveDaysOrMaster = jobIsBeforeFiveDaysOrMaster();
        return (
            <Buttons
                form={form}
                handleCancel={handleCancel}
                setLoading={setLoading}
                isLoading={isLoading}
                setLoadingKey={setLoadingKey}
                loadingKey={loadingKey}
                saveJob={saveJob}
                setIsUpdating={setIsUpdating}
                isUpdating={isUpdating}
                jobId={jobId}
                closeOnly={isCancelled || !isbeforeFiveDaysOrMaster}
                status={status}
                type={type}
            />
        );
    }

    chooseForm = () => {
        const { state, props, getJob, saveJob, saveUpdatingJob, getUpdatingJob, setLoading, getField } = this;
        const { isUpdating } = state;
        const { form, jobId } = props;
        const job = getJob();
        const updatingJob = getUpdatingJob();
        const status = getField("status");
        if (isUpdating && (status === TODO.id || status === DOING.id)) return <UpdateForm form={form} jobId={jobId} saveJob={saveUpdatingJob} job={updatingJob} setLoading={setLoading}/>;
        return <JobForm form={form} jobId={jobId} saveJob={saveJob} job={job} setLoading={setLoading} isUpdating={isUpdating}/>;
    }

	render(){
        const { state, props, handleCancel, renderTitle, renderButtons, chooseForm } = this;
        const { isLoading } = state;
        const { showModal } = props;
		return (
            <Modal
                open={showModal}
                title={renderTitle()}
                onCancel={handleCancel}
                destroyOnClose={true}
				maskClosable={false}
				keyboard={!isLoading}
				closable={!isLoading}
                footer={renderButtons()}
            >
                <Spin spinning={isLoading}>
                    {chooseForm()}
                </Spin>
            </Modal>
		);
	}
}

JobModal.defaultProps = {
	jobId: 0
};

JobModal.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
    isMaster: PropTypes.bool.isRequired,
	showModal: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    jobId: PropTypes.number.isRequired,
    userRoles: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
	return {
		isMaster: state.auth.isMaster,
        userRoles: state.auth.roles
	};
};

export default connect(mapStateToProps)(injectIntl(Form.create()(JobModal)));
