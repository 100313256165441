import {
    SET_DOLLAR_EXCHANGE,
    CLEAN_REDUCER,
    CLEAR_SESSION
} from '../constants/actionTypes';

const initState = {
	dollarToLps: 23.75,
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case SET_DOLLAR_EXCHANGE:
			return {
				...state,
				dollarToLps: action.dollarToLps,
			};
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;