import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, Spin, message } from 'antd';
import { injectIntl } from 'react-intl';
import { getJobMessages, getErrorMessages } from '../../../../constants/messages';
import { getObjectInArray, existsInArray } from '../../../../utilities/util';
import debounce from 'lodash/debounce';

const FormItem = Form.Item;
const { Option } = Select;

let jobMessages = getJobMessages(),
    errorMessages = getErrorMessages();

const messages = {
	...jobMessages,
    ...errorMessages
};

class Sim extends React.Component {

    constructor(props) {
		super(props);
		this.handleSearch = debounce(this.handleSearch, 250);
		this.state = {
            isLoading: false,
            simCards: []
        };
    }

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    setLoading = (isLoading) => this.setState({ isLoading });

    handleSearch = (name) => {
        const { props, getIntl, setLoading } = this;
        const { search } = props;
        if (name.length <= 0 ) this.setState({ simCards: [] });
        else {
            setLoading(true);
            search(name)
            .then((response) => this.setState({ isLoading: false, simCards: response }))
            .catch( () => {
                this.setState({ isLoading: false, simCards: [] });
                message.error(getIntl("unableToSearchSimCards"));
            });
        }
    }

    onChange = (simId) => {
        const { state, props } = this;
        const { simCards } = state;
        const { onChange, otherIncludedSims } = props;
        let simArray = [...simCards ];
        if (Array.isArray(otherIncludedSims)) simArray = simArray.concat(otherIncludedSims);
        else if (otherIncludedSims.id) simArray.push(otherIncludedSims);
        if ('onChange' in props) onChange(getObjectInArray('id', parseInt(simId), simArray));
        this.setState({ simCards: [] });
    }

    buildOptionName = (sim) => {
        const { line, serial } = sim;
        const names = [];
        const checkAndAdd = (property) => { if (property !== undefined && property !== null) names.push(property); };
        checkAndAdd(line);
        checkAndAdd(serial);
        return names.join(" - ");
    }

    buildOptions = () => {
        const { state, props, buildOptionName } = this;
        const { simCards } = state;
        const { sim, otherIncludedSims } = props;
        const options = [];
        const option = s => <Option key={s.id} value={s.id}>{buildOptionName(s)}</Option>;
        const push = s => options.push(option(s));
        const skipIds = [];
        const skipAndPush = s => {
            if (!existsInArray(s.id, skipIds)) {
                skipIds.push(s.id);
                push(s);
            }
        }
        if (sim.id) skipAndPush(sim);
        if (Array.isArray(otherIncludedSims)) otherIncludedSims.forEach(s => skipAndPush(s));
        else if (otherIncludedSims.id) skipAndPush(otherIncludedSims);
        simCards.forEach(s => {
            if (!existsInArray(s.id, skipIds)) push(s);
        });
        return options;
    }
    
	render() {
        const { state, props, getIntl, buildOptions, onChange, handleSearch } = this;
        const { isLoading } = state;
        const { form, disabled, sim } = props;
        const { id } = sim;
        const { getFieldDecorator } = form;
        const label = 'label' in props ? props.label : getIntl("sim");
        let options = buildOptions();
        return(
            <FormItem label={label} >
            {
                getFieldDecorator(`simId`, 
                    {
                        initialValue: id,
                        rules: [{
                            required: true,
                            message: getIntl("simError"), 
                        }],
                        onChange: onChange
                    }
                )(
                    <Select
                        className="job-full-component"
                        showSearch
                        disabled={disabled}
                        placeholder={label}
                        optionFilterProp="children"
                        onSearch={handleSearch}
                        notFoundContent={isLoading ? <Spin size="small" /> : getIntl("notFound")}
                    >
                        {options}
                    </Select>
                )
            }
            </FormItem>
        );
    }
}

Sim.defaultProps = {
    sim: {},
    disabled: false,
    otherIncludedSims: {}
};

Sim.propTypes = {
	intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    search: PropTypes.func.isRequired,
    sim: PropTypes.object,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    otherIncludedSims: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]),
    label: PropTypes.string
};

export default injectIntl(Sim);