import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import { injectIntl } from 'react-intl';
import { getErrorMessages, getJobMessages } from '../../../../constants/messages';

let jobMessages = getJobMessages(),
    errorMessages = getErrorMessages();

const messages = {
	...jobMessages,
	...errorMessages
};

const FormItem = Form.Item;

class Description extends React.Component {

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    onChange = (description) => {
        const { props } = this;
        const { onChange } = props;
        if ('onChange' in props) onChange(description);
    }
    
	render() {
        const { props, getIntl, onChange } = this;
        const { form, description, required, disabled } = props;
		const { getFieldDecorator } = form;
        const label = 'label' in props ? props.label : getIntl("vehicleDescription");
        const placeholder = 'placeholder' in props ? props.placeholder : label;
		return (
            <FormItem label={label} >
            {
                getFieldDecorator('description', 
                    {
                        initialValue: description,
                        rules: [
                            {
                                required,
                                whitespace: true,
                                message: getIntl("vehicleDescriptionError")
                            },
                            {
                                max: 45,
                                message: getIntl("vehicleDescriptionLengthError")
                            }
                        ],
                        onChange: (event) => onChange(event.target.value)
                    }
                )(
                    <Input className="job-full-component" placeholder={placeholder} disabled={disabled}/>
                )
            }
            </FormItem>
        );
    }
}

Description.defaultProps = {
    disabled: false,
    required: true
};

Description.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    description: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    required: PropTypes.bool.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string
};

export default injectIntl(Description);