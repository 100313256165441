import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { StopOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, message, Spin } from 'antd';
import { getReportMessages, getPaymentMessages } from '../../../../constants/messages';
import { LoadAllBankAccounts } from '../../../Payments/actions';

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

let reportMessages = getReportMessages(),
	paymentMessages = getPaymentMessages();

const messages = {
	...reportMessages,
	...paymentMessages
};

class DestinationAccount extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			bankAccounts: []
		};
	}

	componentDidMount() {
		const { getIntl, setLoading } = this;
		setLoading(true);
		LoadAllBankAccounts()
			.then(bankAccounts => this.setState({ bankAccounts, isLoading: false }))
			.catch(() => {
				setLoading(false);
				message.error(getIntl("loadDestinationAccountsError"));
			});
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });

	buildOptions = () => {
		const { state, getIntl } = this;
		const { bankAccounts } = state;
		let options = [];
		let active = [];
		let inactive = [];
		bankAccounts.forEach(({ id: accountId, name, active: accountActive }) => {
			if (accountActive) active.push(<Option key={accountId} value={accountId}>{name}</Option>);
			else inactive.push(<Option key={accountId} value={accountId}><StopOutlined style={{ fontSize: 10 }} /> {name}</Option>);
		});
		options.push(<OptGroup key="active" label={getIntl("activeAccounts")}>{active}</OptGroup>);
		options.push(<OptGroup key="inactive" label={getIntl("inactiveAccounts")}>{inactive}</OptGroup>);
		return options;
	}

	render() {
		const { state, props, getIntl, buildOptions } = this;
		const { isLoading } = state;
		const { getFieldDecorator } = props.form;
		const options = buildOptions();
		return (
			<Spin spinning={isLoading}>
				<FormItem label={getIntl("DestinationAccount")}>
					{
						getFieldDecorator('destinationId')(
							<Select className="job-full-component" placeholder={getIntl("DestinationAccount")} dropdownMatchSelectWidth={false} allowClear>
								{options}
							</Select>
						)
					}
				</FormItem>
			</Spin>
		);
	}
}

DestinationAccount.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired
};

export default injectIntl(DestinationAccount);