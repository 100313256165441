import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select } from 'antd';
import { injectIntl } from 'react-intl';
import { getErrorMessages, getJobMessages } from '../../../../constants/messages';
import { JOBTYPES } from  '../../constants';
import { objectToArray } from '../../../../utilities/util';
import CircleBadge from '../../../GlobalComponents/CircleBadge';

let jobMessages = getJobMessages(),
    errorMessages = getErrorMessages();

const messages = {
	...jobMessages,
	...errorMessages
};
const FormItem = Form.Item;
const { Option } = Select;

class Type extends React.Component {

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    buildOptions = () => {
        const { getIntl } = this;
        return objectToArray(JOBTYPES).map(type => <Option key={type.id} value={type.id}>{getIntl(type.intl)} <CircleBadge color={type.backgroundColor}/></Option>);
    }

    onChange = (type) => {
        const { props } = this;
        const { onChange } = props;
        if (!('onChange' in props)) return;
        onChange(type);
    }
    
	render() {
        const { props, buildOptions, onChange, getIntl } = this;
        const { form, disabled, type } = props;
        const { getFieldDecorator } = form;
        const options = buildOptions();
        return(
            <FormItem label={getIntl("jobType")} >
            {
                getFieldDecorator(`type`, 
                    {
                        initialValue: type,
                        rules: [{
                            required: true,
                            message: getIntl("jobTypeError"), 
                        }],
                        onChange: type => onChange(type)
                    }
                )(
                    <Select
                        className="job-full-component"
                        showSearch
                        disabled={disabled}
                        placeholder={getIntl("jobType")}
                        optionFilterProp="children"
                        notFoundContent={getIntl("notFound")}
                    >
                        {options}
                    </Select>
                )
            }
            </FormItem>
        );
    }
}

Type.defaultProps = {
    disabled: false
};

Type.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    type: PropTypes.number,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
};

export default injectIntl(Type);