import { connect } from 'react-redux';
import GeneratePayrollPage from './GeneratePayroll';
import { GetGeneratePayrollInfo, EditEmployeeDeduction, GetMedicalDeductions } from '../actions';

const mapStateToProps = (state) => {
	return {
		generateInfo: state.payroll.generateInfo,
		medicalDeductions: state.payroll.medicalDeductions,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getGeneratePayrollInfo: (date) => dispatch(GetGeneratePayrollInfo(date)),
		editEmployeeDeduction: (id, amount) => dispatch(EditEmployeeDeduction(id, amount)),
		getMedicalDeductions: () => dispatch(GetMedicalDeductions()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneratePayrollPage);