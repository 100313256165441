import PropTypes from 'prop-types';
import { Col, List, Row, Statistic} from 'antd';
import { injectIntl } from 'react-intl';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import ProductCard from './ProductCard';

class ProductList extends React.Component {
    constructor(props) {
        super(props);
        
    }

    componentDidMount() {
    }

    displayCard = (item) => {
        return (
            <div>
                <Row>
                    <Col span={15}>
                        {item.product.code} - {item.product.description}
                    </Col>
                    <Col span={7} >
                        {<Statistic
                            value={item.total}
                            precision={2}
                            valueStyle={ {color: '#87d068', fontSize: '16px'} }
                            prefix={<p>L</p>}
                        /> }
                    </Col>
                </Row>
            </div>
        );
    }

    render() {
        return (
            <Col xs={23} sm={16} md={16} lg={8} >
                <div className="accountList">
                    <InfiniteScroll
                        initialLoad={false}
                        loadMore={() => {}}
                        hasMore={false}
                        useWindow={false}
                    >
                        <List
                            dataSource={this.props.products}
                            renderItem={(item) => {
                                return (
                                    <ProductCard
                                        product={item}
                                    />
                                );
                            }}
                        />
                    </InfiniteScroll>
                </div>
            </Col>
        );
    }
}

ProductList.propTypes = {
    intl: PropTypes.object.isRequired,
    products: PropTypes.array.isRequired,
};

export default injectIntl(ProductList);