import {
	GET_CLIENTS,
	CLEAN_CLIENTS,
    FETCH_CLIENT,
    FETCH_CLIENT_COUNT,
	DELETE_CLIENT_SUCCESS,
	BASIC_CLIENT_INFO_SAVE_CLIENT_NAME,
	BASIC_CLIENT_INFO_SAVE_REPRESENTATIVE,
	BASIC_CLIENT_INFO_SAVE_RTN,
	BASIC_CLIENT_INFO_SAVE_IS_LPS,
	BASIC_CLIENT_INFO_SAVE_IS_INDIVIDUAL,
	FETCH_CLIENT_TAGS,
	CLIENT_INFO_SAVE_TELEPHONES,
	CLIENT_INFO_SAVE_EMAILS,
	FETCH_CLIENT_PRODUCTS,
	CLIENT_INFO_SAVE_CUSTOM_PRICES,
	CLIENT_SET_CURRENT_CLIENT_ID,
	FETCH_CLIENT_ACTIVITY_LOG,
	FETCH_CLIENT_STATUS,
	CLIENT_INFO_SAVE_SALESPERSON,
	CLIENT_ADDRESS_SAVE,
	FETCH_SELLER_CLIENTS,
	SET_CLIENT_ADDRESSES,
	SEARCH_PREREGISTRATION,
	SEARCH_APPROVEDCLIENTS,
} from './actionTypes';

import {
    CLEAR_SESSION,
	CLEAN_REDUCER
} from '../../constants/actionTypes';

const initState = {
	clients: [],
	count: 0,
	searchPreregistration: "",
	selectedItemPreregistration: 1,
	selectedItemAproved: false,
	searchApproved: "",
	confirmLoading: false,
	showEditModal: false,
	showAddModal: false,
	showViewModal:false,
	loadingData: true,
	currentClient: 0,
	basicInfoComplete: false,
	currentClientName: "",
	currentRepresentative: "",
	currentRTN: "",
	currentIsIndividual: false,
	currentIsLps: false,
	currentStatus: 0,
	tags: [],
	telephones: [],
	emails: [],
	products: [],
	sellerClients: [],
	customPrices: [],
	activityLog: [],
	salesPerson: "",
	fullAddress: {},
	clientAddresses: []
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case SET_CLIENT_ADDRESSES:
			return {
				...state,
				clientAddresses: action.clientAddresses
			};
		case CLIENT_ADDRESS_SAVE:
			return {
				...state,
				"fullAddress": action.fullAddress
			};
		case GET_CLIENTS:
			return {
				...state,
				"clients": action.clients
			};
		case CLEAN_CLIENTS:
			return {
				...state,
				"clients": []
			};
		case FETCH_CLIENT_STATUS:
			return {
				...state,
				currentStatus: action.currentStatus,
			};
		case FETCH_CLIENT_ACTIVITY_LOG:
			return {
				...state,
				activityLog: action.activityLog,
			};
		case CLIENT_SET_CURRENT_CLIENT_ID:
			return {
				...state,
				currentClient: action.currentClient,
			};
		case CLIENT_INFO_SAVE_CUSTOM_PRICES:
			return {
				...state,
				customPrices: action.customPrices,
			};
		case FETCH_CLIENT_PRODUCTS:
			return {
				...state,
				products: action.products,
			};
		case FETCH_SELLER_CLIENTS:
			return {
				...state,
				sellerClients: action.sellerClients,
			};
		case CLIENT_INFO_SAVE_EMAILS:
			return {
				...state,
				emails: action.emails,
			};
		case CLIENT_INFO_SAVE_TELEPHONES:
			return {
				...state,
				telephones: action.telephones,
			};
		case FETCH_CLIENT_TAGS:
			return {
				...state,
				tags: action.tags,
			};
		case BASIC_CLIENT_INFO_SAVE_IS_LPS:
			return {
				...state,
				currentIsLps: action.isLps,
			};
		case BASIC_CLIENT_INFO_SAVE_IS_INDIVIDUAL:
			return {
				...state,
				currentIsIndividual: action.isIndividual,
			};
		case BASIC_CLIENT_INFO_SAVE_CLIENT_NAME:
			return {
				...state,
				currentClientName: action.clientName,
			};
		case BASIC_CLIENT_INFO_SAVE_REPRESENTATIVE:
			return {
				...state,
				currentRepresentative: action.representative,
			};
		case BASIC_CLIENT_INFO_SAVE_RTN:
			return {
				...state,
				currentRTN: action.rtn
			};
		case CLIENT_INFO_SAVE_SALESPERSON:
			return {
				...state,
				salesPerson: action.salesPerson
			};
        case DELETE_CLIENT_SUCCESS: {
            return {
                ...state
            };
		}
		case SEARCH_PREREGISTRATION: {
            return {
                ...state,
				searchPreregistration: action.searchPreregistration
            };
		}
		case SEARCH_APPROVEDCLIENTS: {
            return {
                ...state,
				searchApproved: action.searchApproved
            };
		}
        case FETCH_CLIENT:
			return {
				...state,
				clients: action.clients
			};
        case FETCH_CLIENT_COUNT:
            return {
                ...state,
                count: action.count
			};
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;
		