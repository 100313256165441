import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { DownOutlined, FileProtectOutlined, HistoryOutlined, MobileOutlined, StopOutlined, UserOutlined, EditOutlined, ExpandAltOutlined } from '@ant-design/icons';
import { Row, Col, Card, Dropdown, message, Badge, Divider, Typography, Button } from 'antd';
import {
	getClientMessages, getactionMessages, getFields, getErrorMessages, getPopConfirm, getTableMessages,
	getRegistrationMessages, getLanguageIntl
} from '../../../constants/messages';
import CommonTable from '../../../components/CommonTable';
import MobileCommonTable from '../../../components/MobileCommonTable';
import { isMobile, formatDate, getNestedValue, existsInArray } from '../../../utilities/util';
import ClientRadioButton from './filter';
import ViewClient from '../ViewClient/ViewModal';
import CheckExchangeRate from '../../ExchangeRate/CheckExchangeRate';
import AddUpdateModal from '../AddOrUpdate/FormModal';
import DisableButton from './DisableButton';
import RulesModal from './Rules/RulesModal';
import LeasedDevicesModal from './LeasedDevices/Modal';
import { ROLES } from '../../../constants/global';
import { validValue } from '../constants';
const { CUSTOMERSERVICE, GPSTRANSFERACTIVATION, MASTER, ADMINISTRATOR, SALES } = ROLES;
const { Text } = Typography;
import ClientTiersModal from '../Tiers/Modal';
import ClientAccountsModal from './Accounts/Modal';
import ProgrammedDeactivationsModal from './ProgrammedDeactivations/Modal';
import AllProgrammedDeactivationsModal from './ProgrammedDeactivations/GlobalViewer/Modal';
import ClientRecurrentProductsModal from './RecurrentProducts/Modal';
import { connect } from 'react-redux';
import { DisableApprovedClients } from '../actions';

let clientMessages = getClientMessages(),
	errorMessages = getErrorMessages(),
	actionMessages = getactionMessages(),
	tableMessages = getTableMessages(),
	PopConfirmMessages = getPopConfirm(),
	fieldMessages = getFields(),
	RegistrationMessages = getRegistrationMessages(),
	intlMessages = getLanguageIntl();

const messages = {
	...clientMessages,
	...actionMessages,
	...fieldMessages,
	...errorMessages,
	...tableMessages,
	...PopConfirmMessages,
	...RegistrationMessages,
	...intlMessages
};

class ApprovedClients extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedClients: [],
			reasonMessage: undefined,
			isLoading: false,
			clientId: 0,
			currentClient: {},
			showAddUpdateModal: false,
			showViewModal: false,
			showRulesModal: false,
			showLeasedDevicesModal: false,
			showTiersModal: false,
			showAccountsModal: false,
			showProgrammedDeactivationsModal: false,
			showAllProgrammedDeactivationsModal: false,
			showClientRecurrentProductsModal: false,
		};
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	badgeCreator = (isDisabled, noText = false) => {
		const { intl } = this.props;
		let status = "default";
		let text = intl.formatMessage({ ...messages.clientDisabled });
		if (!isDisabled) {
			status = "success";
			text = intl.formatMessage({ ...messages.clientEnabled });
		}
		return (<Badge status={status} text={noText ? undefined : text} />);
	}

	editRecord = (id) => {
		const { getClientInfo, intl } = this.props;
		this.setState({ isLoading: true });
		getClientInfo(id)
			.then((client) => {
				this.setState({
					clientId: id,
					isLoading: false,
					currentClient: client,
					showAddUpdateModal: true
				});
			})
			.catch(() => {
				message.error(`${intl.formatMessage({ ...messages.GetClientInfoError })}`);
				this.setState({ isLoading: false });
			});
	};

	viewRecord = (id) => {
		const { getClientInfo, intl } = this.props;
		this.setState({ isLoading: true });
		getClientInfo(id)
			.then((client) => {
				this.setState({
					clientId: id,
					isLoading: false,
					currentClient: client,
					showViewModal: true
				});
			})
			.catch(() => {
				message.error(`${intl.formatMessage({ ...messages.GetClientInfoError })}`);
				this.setState({ isLoading: false });
			});
	};

	editRules = (id) => {
		this.setState({
			clientId: id,
			showRulesModal: true
		});
	};

	editLeasedDevices = (id) => {
		this.setState({
			clientId: id,
			showLeasedDevicesModal: true
		});
	};

	viewClientAccounts = (clientId) => {
		this.setState({
			clientId,
			showAccountsModal: true
		});
	};

	viewProgrammedDeactivation = (clientId) => {
		this.setState({
			clientId,
			showProgrammedDeactivationsModal: true
		});
	};

	viewClientRecurrentProducts = (clientId) => {
		this.setState({
			clientId,
			showClientRecurrentProductsModal: true
		});
	};

	getClients = (page = 1, pageSize = 10) => {
		const { intl, getClients } = this.props;
		this.setState({ isLoading: true });
		getClients(page, pageSize)
			.then(() => {
				this.setState({
					isLoading: false
				});
			})
			.catch(() => {
				message.error(`${intl.formatMessage({ ...messages.GetClientsError })}`);
				this.setState({
					isLoading: false
				});
			});
		}
		
		searchClients = (search, page, pageSize, order = 'ascend', orderBy = '') => {
			const { intl, searchClients } = this.props;
			this.setState({ isLoading: true });
			searchClients(search, page, pageSize, order, orderBy)
			.then(() => {
				this.setState({
					isLoading: false
				});
			})
			.catch(() => {
				message.error(`${intl.formatMessage({ ...messages.GetClientsError })}`);
				this.setState({
					isLoading: false
				});
			});
	}


	renderViewButton = (id) => {
		return (
			<a key="view" onClick={() => this.viewRecord(id)}>
				<ExpandAltOutlined />
				<FormattedMessage {...messages.view} />
			</a>
		);
	}

	renderEditButton = (id) => {
		return (
			<a key="edit" onClick={() => this.editRecord(id)}>
				<span className="icon-pencil" />
				<FormattedMessage {...messages.edit} />
			</a>
		);
	}

	renderDisabledChangeButton = (id, isDisabled) => {
		return (
			<DisableButton key="disable" clientId={id} isDisabled={isDisabled}
				setState={
					partialState => this.setState(partialState)
				}
				state={this.state}

			/>
		);
	}

	renderRulesButton = (id) => {
		return (
			<a key="rules" onClick={() => this.editRules(id)}>
				<FileProtectOutlined />
				<FormattedMessage {...messages.rules} />
			</a>
		);
	}

	renderLeasedDevicesButton = (id) => {
		return (
			<a key="leasedDevices" onClick={() => this.editLeasedDevices(id)}>
				<MobileOutlined />
				<FormattedMessage {...messages.devices} />
			</a>
		);
	}

	renderAccountsButton = (clientId) => {
		return (
			<a key="accounts" onClick={() => this.viewClientAccounts(clientId)}>
				<UserOutlined />
				<FormattedMessage {...messages.accounts} />
			</a>
		);
	}

	renderProgrammedDeactivationButton = (clientId) => {
		return (
			<a key="programmedDeactivations" onClick={() => this.viewProgrammedDeactivation(clientId)}>
				<StopOutlined />
				<FormattedMessage {...messages.programmedDeactivation} />
			</a>
		);
	}

	renderRecurrentProductsButton = (clientId) => {
		return (
			<a key="recurrentProducts" onClick={() => this.viewClientRecurrentProducts(clientId)}>
				<HistoryOutlined />
				&nbsp;
				<FormattedMessage {...messages.recurrentProducts} />
			</a>
		);
	}

	renderClientName = (client) => {
		const { getIntl } = this;
		const { name, representative } = client;
		return (
			<div>
				<Text>{name}</Text>
				<br />&emsp;
				<Text type="secondary">{validValue(representative) ? representative : getIntl("noRepresentative")}</Text>
			</div>
		);
	}

	generateExtraComponents = () => {
		const { props, getIntl } = this;
		const { isMaster, userRoles } = props;
		const components = [];
		if (isMaster) {
			components.push(
				<Button key='tiersModal' type="primary" onClick={() => this.setState({ showTiersModal: true })}>
					{getIntl("clientTiers")}
				</Button>
			);
		}
		if (isMaster || existsInArray(ADMINISTRATOR, userRoles)) {
			components.push(
				<Button key='programmedDeactivations' type="primary" onClick={() => this.setState({ showAllProgrammedDeactivationsModal: true })}>
					{getIntl("programmedDeactivations")}
				</Button>
			);
		}

		return components;
	}

	closeModals = () => {
		this.setState({
			clientId: 0,
			currentClient: {},
			showAddUpdateModal: false,
			showViewModal: false,
			showRulesModal: false,
			showLeasedDevicesModal: false,
			showTiersModal: false,
			showAccountsModal: false,
			showProgrammedDeactivationsModal: false,
			showAllProgrammedDeactivationsModal: false,
			showClientRecurrentProductsModal: false,
		});
	}

	renderActions = (record) => {
		const {
			props,
			renderViewButton,
			renderEditButton,
			renderDisabledChangeButton,
			renderRulesButton,
			renderLeasedDevicesButton,
			renderAccountsButton,
			renderProgrammedDeactivationButton,
			renderRecurrentProductsButton
		} = this;
		const { userRoles } = props;
		const { id, isDisabled } = record;
		const view = renderViewButton(id);
		const edit = renderEditButton(id);
		const devices = renderLeasedDevicesButton(id);

		const items = [
			{
				key: '1',
				label: renderDisabledChangeButton(id, isDisabled)
			},
			{
				key: '2',
				label: renderViewButton(id)
			},
			{
				key: '3',
				label: renderRulesButton(id)
			},
			{
				key: '4',
				label: renderRecurrentProductsButton(id)
			},
			{
				key: '5',
				label: renderLeasedDevicesButton(id)
			},
			{
				key: '6',
				label: renderAccountsButton(id)
			},
			{
				key: '7',
				label: renderProgrammedDeactivationButton(id)
			}
		];

		if (existsInArray(MASTER, userRoles) || existsInArray(ADMINISTRATOR, userRoles))
			return (
				<span>
					<Dropdown.Button
						onClick={() => this.editRecord(id)}
						menu={{items}}
					>
						<EditOutlined />
						<FormattedMessage {...messages.edit} />
					</Dropdown.Button>
				</span>
			);
		if (existsInArray(GPSTRANSFERACTIVATION, userRoles))
			return (
				<span>
					{view}
					{existsInArray(SALES, userRoles) ? <span><Divider type="vertical" />{edit}</span> : null}
					<Divider type="vertical" />
					{devices}
				</span>
			);
		if (existsInArray(SALES, userRoles))
			return (
				<span>
					{view}
					<Divider type="vertical" />
					{edit}
				</span>
			);
		if (existsInArray(CUSTOMERSERVICE, userRoles)) return <span>{view}</span>
		return null;
	}

	getClientSelected = (selectedClients) => {
		this.setState({ selectedClients });
	};

	getReasonSelected = (reasonMessage) => {
		this.setState({ reasonMessage });
	};

	disable = ( ) => {
		const { selectedClients, reasonMessage } = this.state;
		const { getIntl } = this;
		const { disableClients } = this.props;
        const successMessage = getIntl("clientDisableMessage");
		const errorMessage = getIntl("DisableClientError");
		const len = selectedClients.length;
		if (len >= 1) {
			this.setState({isLoading: true}, () => {
				disableClients({clientIds: selectedClients, message: reasonMessage})
					.then(() => {
						message.success(successMessage); 
						this.setState({selectedClients : [], reasonMessage: undefined});
					})
					.catch(() => message.error(errorMessage))
					.finally(() => this.setState({isLoading: false}));	
			});
		}
	};

	render() {
		const { state, props, closeModals, renderClientName, generateExtraComponents, renderViewButton, renderEditButton, renderRulesButton, renderLeasedDevicesButton, renderAccountsButton, renderProgrammedDeactivationButton, renderRecurrentProductsButton } = this;
		const { showAddUpdateModal, currentClient, showViewModal, clientId, showRulesModal, showLeasedDevicesModal, showTiersModal, showAccountsModal, showProgrammedDeactivationsModal, showAllProgrammedDeactivationsModal, showClientRecurrentProductsModal } = state;
		const { intl, clients, recordCount, userRoles, search } = props;

		let columns = [
			{
				title: `${intl.formatMessage({ ...messages.name })}`,
				key: 'name',
				render: (record) => renderClientName(record)
			}, {
				title: `${intl.formatMessage({ ...messages.date })}`,
				dataIndex: 'date',
				render: (date) => {
					return (
						<span>
							{formatDate(date, intl.formatMessage({ ...messages.intl }),
								{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
						</span>
					);
				}
			}, {
				title: `${intl.formatMessage({ ...messages.salesperson })}`,
				dataIndex: ['salesPerson', 'name'],
				key: 'salesperson'
			}, {
				title: `${intl.formatMessage({ ...messages.nextInvoiceMonth })}`,
				dataIndex: 'nextInvoiceMonth',
				key: 'nextInvoiceMonth',
				sorter: () => {},
				sortDirections: ['ascend', 'descend'],
				render: (date) => {
					return (
						<span>
							{formatDate(date, intl.formatMessage({ ...messages.intl }),
								{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
						</span>
					);
				}
			}, {
				title: `${intl.formatMessage({ ...messages.activeDevices })}`,
				children: [
					{
						title: `${intl.formatMessage({ ...messages.monthly })}`,
						dataIndex: 'monthlyDevices',
						key: 'monthlyDevices'
					}, {
						title: `${intl.formatMessage({ ...messages.leased })}`,
						dataIndex: 'leasedDevices',
						key: 'leasedDevices'
					}, {
						title: `${intl.formatMessage({ ...messages.routes })}`,
						dataIndex: 'routeDevices',
						key: 'routeDevices'
					}, {
						title: `${intl.formatMessage({ ...messages.clientTotal })}`,
						dataIndex: 'deviceCount',
						key: 'deviceCount'
					}
				]
			}, {
				title: `${intl.formatMessage({ ...messages.active })}`,
				dataIndex: 'isDisabled',
				render: (isDisabled) => this.badgeCreator(isDisabled)
			}, {
				title: `${intl.formatMessage({ ...messages.actions })}`,
				render: (record) => this.renderActions(record)
			}
		];

		const actionMenu = (record) => {
			let recordId = record.id;
			let isDisabled = record.isDisabled;

			const items = [
				{
					key: '0',
					label: renderViewButton(recordId),
				},{
					key: '1',
					label: renderEditButton(recordId)
				},{
					key: '2',
					label: (
						<DisableButton
							state={this.state}
							setState={partialState => this.setState(partialState)}
							clientId={recordId}
							isDisabled={isDisabled}
							isMobile={true}
						/>
					),
				},{
					key: '3',
					label: renderRulesButton(recordId)
				},{
					key: '4',
					label: renderLeasedDevicesButton(recordId)
				},{
					key: '5',
					label: renderAccountsButton(recordId)
				},{
					key: '6',
					label: renderProgrammedDeactivationButton(recordId)
				},{
					key: '7',
					label: renderRecurrentProductsButton(recordId)
				}
			]
			if (existsInArray(MASTER, userRoles) || existsInArray(ADMINISTRATOR, userRoles)){
				return items;
			}
			if (existsInArray(GPSTRANSFERACTIVATION, userRoles)){
				if(existsInArray(SALES, userRoles)){
					return (
						[items[0], items[1], items[4]]
					);
				}
				return (
					[items[0], items[4]]
				);
			}
			if (existsInArray(SALES, userRoles)){
				return (
					[items[0], items[1]]
				);
			}
			if (existsInArray(CUSTOMERSERVICE, userRoles)) return [items[0]]
			return null;
		};


		const action = (record) => (
			<Dropdown menu={{ items: actionMenu(record) }} trigger={['click']}>
				<a className="ant-dropdown-link" href="#">
					<FormattedMessage {...messages.actions} />
					<DownOutlined />
				</a>
			</Dropdown>
		);

		const card = (record) => {
			let title = (
				<Row align="middle">
					<Col span={14}>
						{this.badgeCreator(record.isDisabled, true)}
						{record.name}
					</Col>
				</Row>
			);

			return (
				<Row key={record.id} type="flex" justify="space-around" align="middle">
					<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
						<Card loading={this.state.isLoading} title={title} extra={action(record)} style={{ width: "100%" }}>
							<Row type="flex" justify="center">
								<Col span={8}>
									<strong><FormattedMessage {...messages.date} />:</strong>
								</Col>
								<Col span={12}>
									{formatDate(record.date, intl.formatMessage({ ...messages.intl }),
										{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
								</Col>
							</Row>
							<Row type="flex" justify="center">
								<Col span={8}>
									<strong><FormattedMessage {...messages.salesperson} />:</strong>
								</Col>
								<Col span={12}>
									{getNestedValue("salesPerson.name", record)}
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
			);
		};
		return (
			<div className="client view">
				<CheckExchangeRate />
				<AddUpdateModal showModal={showAddUpdateModal} isUpdate={true} handleCancel={closeModals} clientInfo={currentClient} />
				<ViewClient showModal={showViewModal} handleCancel={closeModals} clientInfo={currentClient} />
				<RulesModal showModal={showRulesModal} handleCancel={closeModals} clientId={clientId} />
				<LeasedDevicesModal showModal={showLeasedDevicesModal} handleCancel={closeModals} clientId={clientId} />
				<ClientTiersModal showModal={showTiersModal} handleCancel={closeModals} />
				<ClientAccountsModal showModal={showAccountsModal} handleCancel={closeModals} clientId={clientId} />
				<ProgrammedDeactivationsModal showModal={showProgrammedDeactivationsModal} handleCancel={closeModals} clientId={clientId} />
				<AllProgrammedDeactivationsModal showModal={showAllProgrammedDeactivationsModal} handleCancel={closeModals} />
				<ClientRecurrentProductsModal showModal={showClientRecurrentProductsModal} handleCancel={closeModals} clientId={clientId} />
				{(!isMobile() ?
					<CommonTable
						columns={columns}
						dataSource={clients}
						search={(search, page, pageSize) => this.searchClients(search, page, pageSize)}
						searchText= {search === "" ? undefined : search}
						recordCount={recordCount}
						getRecords={(page, pageSize) => this.getClients(page, pageSize)}
						loading={this.state.isLoading}
						getCheckboxProps={record => ({ disabled: record.isDisabled })}
						filterComponent={(<ClientRadioButton />)}
						extraComponents={generateExtraComponents()}
						onDisable={this.disable}
						showDisableBtn = {true}
						getRowSelected= {this.getClientSelected}
						getReason= {this.getReasonSelected}
						preserveFilter
						preserveSelectedRowKeys
						pagination={true}
					/>
					:
					<MobileCommonTable
						dataSource={clients}
						search={(search, page, pageSize) => this.searchClients(search, page, pageSize)}
						searchText= {search === "" ? undefined : search}
						recordCount={recordCount}
						loading={this.state.isLoading}
						card={card}
						getRecords={(page, pageSize) => this.getClients(page, pageSize)}
						filterComponent={(<ClientRadioButton />)}
						extraComponents={generateExtraComponents()}
					/>
				)}
			</div>
		);
	}
}

ApprovedClients.propTypes = {
	intl: PropTypes.object.isRequired,
	clients: PropTypes.array,
	hasError: PropTypes.bool,
	error: PropTypes.object,
	user: PropTypes.object,
	getClients: PropTypes.func,
	searchClients: PropTypes.func,
	search: PropTypes.string,
	recordCount: PropTypes.number,
	handleCancel: PropTypes.func,
	showEditModal: PropTypes.bool,
	handleClose: PropTypes.func,
	isMaster: PropTypes.bool,
	getClientInfo: PropTypes.func,
	userRoles: PropTypes.array,
	disableClients: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
	return {
		disableClients: (props) => {
			return dispatch(DisableApprovedClients(props));
		}
	}
}

export default connect(null, mapDispatchToProps)(injectIntl(ApprovedClients));
