import React from 'react';
import PropTypes from 'prop-types';
import {
    injectIntl
} from 'react-intl';
import CommonTable from '../../components/CommonTable';
import {connect} from 'react-redux';
import {GetExchangeRates, SearchExchangeRates} from './actions'
import AddExchangeRateButton from './AddExchangeRateButton';
import { roundN } from '../../utilities/util';
import {getLanguageIntl, getExchangeRateMessages} from '../../constants/messages';
import ExchangeRateFilter from './ExchangeRateFilter';
import { message } from 'antd'

let exchangeRateMessages = getExchangeRateMessages(),
	languageMessages = getLanguageIntl();

const messages = {
	...exchangeRateMessages,
    ...languageMessages
};

class ExchangeRate extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: true
		};
	}

	componentDidMount() {
		this.props.getExchangeRates().then(() => {
			this.setState({
				isLoading: false
			});
		}).catch( () => {
			message.error( `${ this.props.intl.formatMessage( {...messages.errorObtainingExchangeRates}) }` );
		});
	}

	render() {
		let columns = [
            {
				title: this.props.intl.formatMessage({ ...messages.Rate }),
				dataIndex: 'rate',
                key: 'rate',
				width: 250,
				render: (rate) => {
					return (
							roundN(rate, 4)
						);
				}
			},
			{
				title: this.props.intl.formatMessage({ ...messages.Date }),
				dataIndex: 'createdAt',
                key: 'createdAt',
				width: 250,
				render: (createdAt) => {
                    let formatedDate = new Date(createdAt);
                    formatedDate.toLocaleDateString();
					return (
							formatedDate.toLocaleDateString(this.props.intl.formatMessage({ ...messages.intl }), {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})
						);
				}
			},
			{
				title: this.props.intl.formatMessage({ ...messages.CreatedBy }),
				dataIndex: 'account',
                key: 'account',
				width: 250,
				render: (account, exchangeRate) => {
					return (
							exchangeRate.account.name
						);
				}
			}
		];
		return ( 
			<div className="account view">
                {<CommonTable 
                    columns={columns}
                    dataSource={this.props.exchangeRates}
					loading={this.state.isLoading}
					Add={<AddExchangeRateButton />}
					recordCount={this.props.exchangeRates.length}
                    getRecords={() => {}}
					search={this.props.searchExchangeRates}
					filterComponent = {(<ExchangeRateFilter />)}
                    getCheckboxProps={(() => ({ disabled: false }))}
					disableSearchBar
					hideCheckboxes
                />}
            </div>
		);
	}
}

ExchangeRate.propTypes = {
	intl: PropTypes.object.isRequired,
	user:  PropTypes.object.isRequired,
	getExchangeRates: PropTypes.func.isRequired,
	searchExchangeRates: PropTypes.func.isRequired,
	exchangeRates: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
	return {
		"user": state.auth.user,
		"exchangeRates": state.exchangeRate.exchangeRates
	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		getExchangeRates: () => {
			return dispatch(GetExchangeRates());
		},
		searchExchangeRates: (search) => {
			return dispatch(SearchExchangeRates(search));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ExchangeRate));