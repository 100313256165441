import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Button, Col, message, Row, Select, Spin } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl';
import { getDeviceMessages, getModalMessages, getReportMessages } from '../../../constants/messages';
import { DownloadAllDevices, DownloadDevicesClient } from '../actions';
import { searchInString } from '../../../utilities/util';
import { DownloadOutlined } from '@ant-design/icons';
let deviceMessages = getDeviceMessages(),
	modalMessages = getModalMessages(),
    reportMessages = getReportMessages();

const messages = {
	...deviceMessages,
	...modalMessages,
    ...reportMessages,
};

const ExportDevices = () => {
	const intl = useIntl();
    const [client, setClient] = useState();
    const [loading, setLoading] = useState(false);

    const clients = useSelector((state) => state.client.clients)

    const clientOptions = clients.map((client) => ({
		key: client.clientId,
		value: client.clientId,
		label: client.name,
	}));

    const onExport = (clientName) => {
        setLoading(true);
        DownloadDevicesClient(clientName)
            .then(() => message.success(intl.formatMessage({...messages.exportDevicesSuccess})))
            .catch(() => message.error(intl.formatMessage({...messages.exportDevicesError})))
            .finally(() => setLoading(false));
    }

    const onExportAll = () => {
        setLoading(true);
        DownloadAllDevices()
            .then(() => message.success(intl.formatMessage({...messages.exportSuccess})))
            .catch(() => message.error(intl.formatMessage({...messages.exportError})))
            .finally(() => setLoading(false));
    }

    return (
        <Spin spinning={loading}>
            <Row justify="space-around" type="flex">
                <Col span={16}>
                    <Select
                        showSearch
                        className="job-full-component"
                        optionFilterProp="children"
                        placeholder={intl.formatMessage({ ...messages.client })}
                        filterOption={(input, { label }) => searchInString(input, label)}
                        onChange={(id, { label }) => setClient({id, name: label})}
                        options={clientOptions}
                        />
                </Col>

                <Col span={4}>
                    <Button type="primary" disabled={!client}  onClick={() => onExport(client)}>
                        <FormattedMessage {...messages.export} />
                    </Button>
                </Col>
                <Col span={2}>
                    <Button type="danger" disabled={client} onClick={() => onExportAll()} icon={<DownloadOutlined />}/>
                </Col>
            </Row>
        </Spin>
    )
}

export default ExportDevices;
