import React from 'react';
import PropTypes from 'prop-types';
import { List, Divider, Tag, Table, Typography } from 'antd';
import { injectIntl } from 'react-intl';
import {
	getactionMessages, getAuthMessages, getModalMessages, getFields, getErrorMessages, getRegistrationMessages,
	getClientMessages, getLanguageIntl, getProductMessages, getAddressMessages, getPopConfirm
} from '../../../constants/messages';
import CommentList from './CommentList';
import { REGEX } from '../../../constants/global';
import { getNestedValue } from '../../../utilities/util';
import Embolden from '../../GlobalComponents/Embolden';
import moment from 'moment';
import CircleBadge from '../../GlobalComponents/CircleBadge';

const { Text } = Typography;

let actionMessages = getactionMessages(),
	authMessages = getAuthMessages(),
	errorMessages = getErrorMessages(),
	fieldMessages = getFields(),
	modalMessages = getModalMessages(),
	RegistrationMessages = getRegistrationMessages(),
	ClientMessages = getClientMessages(),
	intlMessages = getLanguageIntl(),
	productMessages = getProductMessages(),
	addressMessages = getAddressMessages(),
	popConfirmMessages = getPopConfirm();

const messages = {
	...actionMessages,
	...authMessages,
	...modalMessages,
	...fieldMessages,
	...errorMessages,
	...RegistrationMessages,
	...ClientMessages,
	...intlMessages,
	...productMessages,
	...addressMessages,
	...popConfirmMessages
};

function priceFormatter(value) {
	let valueToFloat = parseFloat(value);
	let formatedPrice = valueToFloat.toLocaleString('en-US', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 4
	});
	return formatedPrice;
}

class ClientInfo extends React.Component {

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	getPriceWithPrefix = (price) => {
		if (price === undefined || price === null) return "";
		const { clientInfo } = this.props;
		const { isLps } = clientInfo;
		let formatedPrice = priceFormatter(price);
		if (isLps)
			return ("L " + formatedPrice);
		else
			return ("$ " + formatedPrice);
	}

	embolden = (header, value) => {
		return <Embolden header={header} value={value} row />;
	}

	getDeviceCount = () => {
		const { props, embolden } = this;
		const { intl, clientInfo } = props;
		const { deviceCount, clientId } = clientInfo;
		if (clientId) {
			return embolden(intl.formatMessage({ ...messages.activeDevices }), deviceCount);
		}
	}

    getClientBasicInfoContent = () => {
        const { props, getIntl, embolden, getDeviceCount } = this;
        const { clientInfo } = props;
        const getField = field => getNestedValue(field, clientInfo);
        const intlEmbolden = (intl, value) => embolden(getIntl(intl), value);
        const intlNestedEmbolden = (intl, nestedValue) => intlEmbolden(intl, getField(nestedValue));
		const tier = getField("tier");
        return(
            <div>
                {intlNestedEmbolden("clientName", "name")}
                {getField("isIndividual") ? null : intlNestedEmbolden("representative", "representative")}
                {intlNestedEmbolden("rtn", "rtn")}
                {intlNestedEmbolden("state", "address.state")}
                {intlNestedEmbolden("city", "address.city")}
                {intlNestedEmbolden("address", "address.address")}
                {intlEmbolden("currencyToCharge", getIntl(getField("isLps") ? "currencyLempira" : "currencyDollar"))}
                {intlEmbolden("automaticInvoicing", getIntl(getField("automaticInvoicing") ? "yes" : "no"))}
                {intlEmbolden("nextInvoiceMonth", moment(getField("nextInvoiceMonth")).locale(getIntl("intl")).format("MMMM YYYY"))}
                {intlEmbolden("clientTier", tier ? <CircleBadge color={tier.badgeColor} text={tier.name}/> : getIntl('clientHasNoTierAssigned'))}
                {getDeviceCount()}
            </div>
        );
    }

	renderTags = (tags) => {
		if (!tags || !Array.isArray(tags)) return null;
		return (
			tags.map((tag, tagIndex) => <Tag color="blue" className="client-view-tag" key={tagIndex}>{tag.name}</Tag>)
		);
	}

	renderExtension = (ext, extIndex) => {
		const { intl } = this.props;
		const { extension, tag, name } = ext;
		const whitespace = REGEX.whitespace;
		let single = extension === "-1";
		let extensionRender = single ? null : <div>{`Ext: ${extension}`}</div>;
		let tagRender = this.renderTags(tag);
		let nameRender = (name !== null && name !== undefined && whitespace.test(name)) ? (<div>{`${intl.formatMessage({ ...messages.owner })}: ${name}`}</div>) : null;
		return (
			<div className={single ? "client-view-single_tags" : "client-view-telephone-extension"} key={extIndex}>
				{extensionRender}
				{tagRender}
				{nameRender}
			</div>
		);
	}

	renderTelephone = (telephone) => {
		const { number, extension } = telephone;
		let phone = (
			<div className="client-view-telephone">
				<span className="icon-phone" />
				<Text strong>{` ${number}`}</Text>
			</div>
		);
		let extensions = extension.map((ext, extIndex) => this.renderExtension(ext, extIndex));
		return (
			<div>
				{phone}
				{extensions}
			</div>
		);
	}

	getListOfTelephones = () => {
		const { clientInfo } = this.props;
		const { telephones } = clientInfo;
		return (
			<List
				itemLayout="vertical"
				dataSource={telephones}
				renderItem={(telephone, telIndex) => (
					<List.Item key={telIndex}>
						{this.renderTelephone(telephone)}
					</List.Item>
				)}
			/>
		);
	}

	renderEmail = (Email) => {
		const { intl } = this.props;
		const { email, tag, name } = Email;
		const whitespace = REGEX.whitespace;
		let emailRender = (
			<div className="client-view-telephone">
				<span className="icon-envelop" />
				<Text strong>{` ${email}`}</Text>
			</div>
		);
		let tagRender = this.renderTags(tag);
		let nameRender = (name !== null && name !== undefined && whitespace.test(name)) ? (<div>{`${intl.formatMessage({ ...messages.owner })}: ${name}`}</div>) : null;
		return (
			<div>
				{emailRender}
				{tagRender}
				{nameRender}
			</div>
		);
	}

	getListOfEmails = () => {
		const { clientInfo } = this.props;
		const { clientEmails } = clientInfo;
		return (
			<List
				itemLayout="vertical"
				dataSource={clientEmails}
				renderItem={(email, emailIndex) => (
					<List.Item key={emailIndex}>
						{this.renderEmail(email)}
					</List.Item>
				)}
			/>
		);
	}

	getProductInfo = (productId) => {
		const { products } = this.props;
		let product = products.find(x => x.id == productId);
		if (product !== undefined)
			return (product.code + " - " + product.description);
		else
			return "";
	}

	getCustomPricesTable = () => {
		const { intl, clientInfo } = this.props;
		const { customPrices } = clientInfo;
		const dataSource = customPrices.map((cp, index) => {
			let product = cp.product;
			return (
				{
					key: index,
					product: product.code + " - " + product.description,
					price: this.getPriceWithPrefix(cp.price)
				}
			);
		});

		const columns = [{
			title: intl.formatMessage({ ...messages.clientProduct }),
			dataIndex: 'product'
		}, {
			title: intl.formatMessage({ ...messages.price }),
			dataIndex: 'price'
		}];
		return (
			<Table
				columns={columns}
				dataSource={dataSource}
				pagination={false}
				size="small"
			/>
		);
	}

	getActivityLogContent = () => {
		const { clientInfo } = this.props;
		const { activityLog } = clientInfo;
		return (<CommentList messages={activityLog} />)
	}

	getClientContent = () => {
		const { intl } = this.props;
		return (
			<div>
				<Divider>{intl.formatMessage({ ...messages.basicInfo })}</Divider>
				{this.getClientBasicInfoContent()}
				<Divider>{intl.formatMessage({ ...messages.clientTelephones })}</Divider>
				{this.getListOfTelephones()}
				<Divider>{intl.formatMessage({ ...messages.clientEmails })}</Divider>
				{this.getListOfEmails()}
				<Divider>{intl.formatMessage({ ...messages.customPrices })}</Divider>
				{this.getCustomPricesTable()}
				<Divider>{intl.formatMessage({ ...messages.activityLog })}</Divider>
				{this.getActivityLogContent()}
			</div>
		);
	}

	render() {
		return (
			<div>
				{this.getClientContent()}
			</div>
		);
	}
}

ClientInfo.defaultProps = {
	clientInfo: {}
};

ClientInfo.propTypes = {
	intl: PropTypes.object.isRequired,
	tags: PropTypes.array.isRequired,
	products: PropTypes.array.isRequired,
	clientInfo: PropTypes.object
};

export default injectIntl(ClientInfo);