import {
	FETCH_ACCOUNTS,
	CLEAR_SESSION,
	FETCH_ACCOUNTS_COUNT,
	FETCH_LANGUAGES,
	ADD_ACCOUNTS_SUCCESS,
	DELETE_ACCOUNTS_SUCCESS,
	DISABLE_ACCOUNTS_SUCCESS,
	EDIT_ACCOUNTS_SUCCESS,
	INIT_REQUEST_ACCOUNT,
	END_REQUEST_ACCOUNT,
	OPEN_ADD_MODAL_ACCOUNT,
	CLOSE_ADD_MODAL_ACCOUNT,
	OPEN_EDIT_MODAL_ACCOUNT,
	CLOSE_EDIT_MODAL_ACCOUNT,
	INIT_MODAL_REQUEST_ACCOUNT,
	END_MODAL_REQUEST_ACCOUNT,
	OPEN_ROLE_MODAL_ACCOUNT,
	CLOSE_ROLE_MODAL_ACCOUNT,
	OPEN_SESSIONS_MODAL_ACCOUNT,
	CLOSE_SESSIONS_MODAL_ACCOUNT,
	LOADED_DATA,
	LOAD_DATA,
	CLEAN_REDUCER,
	SET_SEARCH_ACCOUNT
} from '../constants/actionTypes';

const initState = {
	accounts: [],
	editable: {},
	search:"",
	isLoading: false,
	count: 0,
	languageFilter: [],
	confirmLoading: false,
	showEditModal: false,
	showAddModal: false,
	showRoleModal: false,
	showSessionModal: false,
	loadingData: true,
	roleUserId: 0,
	sessionUserId:0
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case LOADED_DATA:
			return {
				...state,
				"loadingData": false
			};
		case LOAD_DATA:
			return {
				...state,
				"loadingData": true
			};
		case OPEN_SESSIONS_MODAL_ACCOUNT:
			return {
				...state,
				"showSessionModal": true,
				"sessionUserId": action.sessionUserId
			};
		case CLOSE_SESSIONS_MODAL_ACCOUNT:
			return {
				...state,
				"showSessionModal": false
			};
		case OPEN_ROLE_MODAL_ACCOUNT:
			return {
				...state,
				"showRoleModal": true,
				"roleUserId": action.roleUserId
			};
		case CLOSE_ROLE_MODAL_ACCOUNT:
			return {
				...state,
				"showRoleModal": false
			};
		case OPEN_ADD_MODAL_ACCOUNT:
			return {
				...state,
				"showAddModal": true
			};
		case CLOSE_ADD_MODAL_ACCOUNT:
			return {
				...state,
				"showAddModal": false,
				"confirmLoading": false
			};
		case OPEN_EDIT_MODAL_ACCOUNT:
			return {
				...state,
				"showEditModal": true,
				"editable": action.editable
			};
		case CLOSE_EDIT_MODAL_ACCOUNT:
			return {
				...state,
				"showEditModal": false,
				"confirmLoading": false
			};
		case INIT_MODAL_REQUEST_ACCOUNT:
			return {
				...state,
				"confirmLoading": true
			};
		case END_MODAL_REQUEST_ACCOUNT:
			return {
				...state,
				"confirmLoading": false
			};
		case INIT_REQUEST_ACCOUNT:
			return {
				...state,
				"isLoading": true
			};
		case END_REQUEST_ACCOUNT:
			return {
				...state,
				"isLoading": false,
				"confirmLoading": false
			};
		case FETCH_ACCOUNTS:
			return {
				...state,
				accounts: action.accounts
			};
		case FETCH_ACCOUNTS_COUNT:
			return {
				...state,
				count: action.count
			};
		case FETCH_LANGUAGES:
			return {
				...state,
				languageFilter: action.languageFilter
			};
		case ADD_ACCOUNTS_SUCCESS: {
			let added = state.accounts.slice();
			if (state.count == 10)
				added.pop();
			added.unshift(action.newAccount);
			return {
				...state,
				accounts: added,
				count: state.count + 1,
				"showAddModal": false,
				"confirmLoading": false
			};
		}
		case DELETE_ACCOUNTS_SUCCESS: {
			return {
				...state
			};
		}
		case DISABLE_ACCOUNTS_SUCCESS:{
			let disable = state.accounts.map((element) => {
				if (element.id != action.id) {
					return {
						...element
					};
				}
				return {
					...element,
					active: !element.active
				};
			});
			return {
				...state,
				accounts: disable
			};
		}
		case EDIT_ACCOUNTS_SUCCESS: {
			let edit = state.accounts.map((element) => {
				if (element.id != action.editedAccount.id) {
					return {
						...element
					};
				}
				return {
					...element,
					...action.editedAccount
				};
			});
			return {
				...state,
				accounts: edit,
				"showEditModal": false,
				"confirmLoading": false
			};
		}
		case SET_SEARCH_ACCOUNT: {
			return{
				...state,
				search: action.search
			}
		}
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;