import React from 'react'
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Col, Row, InputNumber, Select, Divider, Space, Input, Button } from 'antd';
import { getRequestMessages, getactionMessages, getErrorMessages, getFields } from '../../constants/messages';
import { searchInString } from '../../utilities/util';
import AddContactButton from '../Contacts/AddContactButton';

let requestMessages = getRequestMessages(),
    actionMessages = getactionMessages(),
    fieldsMessages = getFields(),
    errorMessages = getErrorMessages();

const messages = {
    ...requestMessages,
    ...fieldsMessages,
    ...actionMessages,
    ...errorMessages,
};

const FormItem = Form.Item;
const { TextArea } = Input;

const AddRequestForm = ({ form }) => {
    const intl = useIntl();
    const clients = useSelector((state) => state.client.clients)
    const contacts = useSelector((state) => state.contact.contacts)

    const clientOptions = clients.map((client) => ({
		key: client.id, 
		value: client.id,
		label: client.name,
	}));
    
    const contactsOptions = contacts.map((contact) => ({
		key: contact.id, 
		value: contact.id,
		label: ( 
            <React.Fragment>
                <Col span={24}>
                    <Space direction="horizontal" size="large">
                        {contact.name}
                    </Space>
                </Col>
                <Col span={24}>
                    <Space
                        size={2}
                        direction="vertical"
                        style={{ marginLeft: '10px', fontSize: '12px', color: '#ACACAF' }}
                    >
                        {contact.cellphone}
                        {contact.email}
                    </Space>
                </Col>
                
			</React.Fragment>
        ),
	}));

    const contactSearch = (input, option) => {
		const primaryInfo = option[0].props.children.props.children;
		const secondaryInfo =  option[1].props.children.props.children;
		const clientName = primaryInfo;
		const clientEmail = secondaryInfo[0];
		const clientPhone = secondaryInfo[1];
        return searchInString(input, `${clientName}${clientEmail}${clientPhone}`);
	};

    return (
        <div>
            <Form ref={form} layout='vertical' >
                <Col span={24}>
                    <FormItem
                        label={intl.formatMessage({...messages.client})}
                        name="clientId"
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({ ...messages.clientError }),
                            },
                        ]}
                    >
                    <Select
                        showSearch
                        options={clientOptions}
                        optionFilterProp="children"
                        placeholder={intl.formatMessage({ ...messages.client })}
                        filterOption={(input, { label }) => searchInString(input, label)}
                    /> 
                    </FormItem>
                </Col>
                <Row gutter={8}>
                    <Col span={12}>
                        <FormItem
                            label={intl.formatMessage({...messages.boughtGPSs})}
                            name="bought"
                            initialValue={0}
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage({ ...messages.gpsCountError }),
                                },
                            ]}
                        >
                            <InputNumber min={0} style={{ width: '100%' }} />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            label={intl.formatMessage({...messages.leasedGPSs})}
                            name="leased"
                            initialValue={0}
                            rules={[
                                {
                                    required: true, 
                                    message: intl.formatMessage({ ...messages.gpsCountError }),
                                },
                            ]}
                        >
                            <InputNumber min={0} style={{ width: '100%' }} />
                        </FormItem>
                    </Col>
                </Row>
                <Divider type="horizontal">
                    <FormattedMessage {...messages.contactName} />
                </Divider>
                <Col span={24}>
                    <Row justify="space-between">
                        <Col span={19}>
                            <FormItem
                                label={intl.formatMessage({...messages.contactName})}
                                name="contactId"
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage({ ...messages.contactNameError }),
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    options={contactsOptions}
                                    optionFilterProp="children"
                                    placeholder={intl.formatMessage({ ...messages.contactNamePlaceholder })}
                                    filterOption={(input, option) => contactSearch(input, option.label.props.children)}
                                /> 
                            </FormItem>
                        </Col>
                        <Col span={5} style={{ paddingLeft: 5, paddingTop: 30 }}>
                            <AddContactButton />
                        </Col>
                    </Row>
                </Col>
                <Divider type="horizontal">
                    <FormattedMessage {...messages.details} />
                </Divider>
                <Form.List 	name={'vehicles'} >
                    {(fields, { add, remove }) => (
                        <React.Fragment>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                        key={key}
                                        style={{
                                            display: 'flex',
                                            marginBottom: 8,
                                        }}
                                        align="baseline"
                                    >
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'plate']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: intl.formatMessage({ ...messages.requiredPlateError }),
                                                },
                                            ]}
                                        >
                                            <Input placeholder={intl.formatMessage({ ...messages.platePlaceholder })}  />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'model']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: intl.formatMessage({ ...messages.requiredModelError }),
                                                },
                                            ]}
                                        >
                                            <Input placeholder={intl.formatMessage({ ...messages.modelPlaceholder })} />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'brand']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: intl.formatMessage({ ...messages.requiredBrandError }),
                                                },
                                            ]}
                                        >
                                            <Input placeholder={intl.formatMessage({ ...messages.brandPlaceholder })}  />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button 
                                        block 
                                        type="dashed" 
                                        onClick={() => add()} 
                                        icon={<PlusOutlined />}
                                    >
                                        <FormattedMessage {...messages.add} />
                                    </Button>
                                </Form.Item>
                        </React.Fragment>
                    )}
                </Form.List>
                <Col span={24}>
                    <FormItem
                        label={intl.formatMessage({ ...messages.observations })}
                        name="observations"
                    >
                        <TextArea 
                            showCount
                            maxLength={300} 
                        /> 
                    </FormItem>
                </Col>
            </Form>
        </div>
    )
}

AddRequestForm.propTypes = {
    form: PropTypes.object.isRequired,
};

export default AddRequestForm;
