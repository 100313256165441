import {
	LOG_IN,
	LOG_OUT,
	RESET_PASSWORD,
	RESET,
	CLEAR_SESSION,
	FETCH_USER_ROLES,
	IS_USER_MASTER,
	USER_PROFILE_IMAGE,
} from '../constants/actionTypes';

const initState = {
	"user": {},
	isAuthenticated: false,
	roles: [],
	isMaster: false
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case IS_USER_MASTER: 
			return {
				...state,
				isMaster: action.isMaster
			};
		case FETCH_USER_ROLES: 
			return {
				...state,
				roles: action.userRoles
			};
		case USER_PROFILE_IMAGE:
			return {
				...state,
				user: {
					...state.user,
					url: action.url
				}
			};
		case LOG_IN:
			return {
				...state,
				user: {
					...state.user,
					...action.user
				},
				isAuthenticated: true
			};
		case LOG_OUT:
			return {
				...state,
				isAuthenticated: false
			};
		case RESET_PASSWORD:
			return {
				...state
			};
		case RESET:
			return {
				...state
			};
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;