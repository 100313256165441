import React from 'react';
import PropTypes from 'prop-types';
import { Select, Spin, message, Skeleton, Typography, Collapse } from 'antd';
import { injectIntl } from 'react-intl';
import { getDeviceMessages} from '../../../constants/messages';
import { SearchDevices, GetDeviceInformation } from '../actions';
import DeviceRender from './Renders/DeviceRender';
import { getNestedValue, advancedGetNestedValue } from '../../../utilities/util';
import Embolden from '../../GlobalComponents/Embolden';
const { Text } = Typography;
const { Panel } = Collapse;

const Option = Select.Option;

let deviceMessages = getDeviceMessages();

const messages = {
	...deviceMessages
};

class Device extends React.Component  {

	constructor(props){
        super(props);
        this.state = {
            selectLoading: false,
            devices: [],
            skeletonLoading: false,
            deviceInformation: {},
            dropdownOpen: false
        };
    }

    getIntl = (str, values = {}) => this.props.intl.formatMessage({...messages[str]}, values);

    handleDeviceSearch = (name) => {
        const {intl} = this.props;
        if (name.length <= 0 ) this.setState({devices: []});
        else {
            this.setState({selectLoading: true});
            SearchDevices(name.trim())
            .then((response) => this.setState({selectLoading: false, devices: response}))
            .catch( () => {
                this.setState({selectLoading: false, devices: []});
                message.error(intl.formatMessage({...messages.deviceSearchError}));
            });
        }
    }

    buildOptions = () => {
        const { state, getIntl } = this;
        const { devices, dropdownOpen } = state;
        return devices.map((device, index) => {
            const isDisabled = getNestedValue("client.ironhideClient.isDisabled", device);
            const pin = getNestedValue("pin", device), imei = getNestedValue("imei", device), description = getNestedValue("description", device);
            let deviceName = pin;
            if (dropdownOpen)
                deviceName = (
                        <div>
                            <Text strong>{pin}</Text>
                            <div>&nbsp;&nbsp;&nbsp;<Embolden header={getIntl("description")} value={description}/></div>
                            <div>&nbsp;&nbsp;&nbsp;<Embolden header={getIntl("imei")} value={imei}/></div>
                            {isDisabled ? <div>&nbsp;&nbsp;&nbsp;<i>{getIntl("clientIsDisabled")}</i></div> : null}
                        </div>
                );
            return <Option key={index} value={device.id}>{deviceName}</Option>
        });
    }

    handleSelect = (deviceId) => {
        const {intl} = this.props;
        if (!deviceId) this.setState({skeletonLoading: false, deviceInformation: {}});
        else {
            this.setState({skeletonLoading: true});
            GetDeviceInformation(deviceId)
            .then((response) => this.setState({skeletonLoading: false, deviceInformation: response}))
            .catch( () => {
                this.setState({skeletonLoading: false, deviceInformation: {}});
                message.error(intl.formatMessage({...messages.deviceInfoError}));
            });
        }
    }

    handleFilter = (input, option) => {
        const children = option.children;
        const lowerCaseCompare = (str) => {
            if (str && typeof str === 'string') return str.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
            return false;
        }
        if (typeof children === 'object' && children !== null) {
            const pin = advancedGetNestedValue("props.children[0].props.children", children);
            const description = advancedGetNestedValue("props.children[1].props.children[1].props.value", children);
            const imei = advancedGetNestedValue("props.children[2].props.children[1].props.value", children);
            return lowerCaseCompare(pin) || lowerCaseCompare(description) || lowerCaseCompare(imei);
        }
        return lowerCaseCompare(children);
    }

    renderDeviceSelector = () => {
        const {selectLoading} = this.state;
        const {intl} = this.props;
        let deviceOptions = this.buildOptions();
		return (
            <Select
                className="job-full-component device-finder-select-padding"
                showSearch
                placeholder={intl.formatMessage( {...messages.deviceSearch} )}
                optionFilterProp="children"
                filterOption={this.handleFilter}
                onSearch={this.handleDeviceSearch}
                notFoundContent={selectLoading ? <Spin size="small" /> : intl.formatMessage({...messages.notFound})}
                onChange={this.handleSelect}
                onDropdownVisibleChange={(dropdownOpen) => this.setState({ dropdownOpen })}
            >
                {deviceOptions}
            </Select>
		);
    }

    renderDeviceInformation = () => {
        const {skeletonLoading, deviceInformation} = this.state;
        if (skeletonLoading)
            return (
                <div>
                    <Skeleton active={skeletonLoading}/>
                    <Skeleton active={skeletonLoading}/>
                </div>
            );
        else if (Object.keys(deviceInformation).length > 0 && deviceInformation.constructor === Object) {
            return (
                <DeviceRender device={deviceInformation}/>
            );
        }
    }

	render(){
        const {skeletonLoading, deviceInformation} = this.state;
        const expand = skeletonLoading || (Object.keys(deviceInformation).length > 0 && deviceInformation.constructor === Object);
        return(
            <div>
                {this.renderDeviceSelector()}
                <Collapse className="job-view-reconnect-collapse" bordered={false} activeKey={expand ? 1 : 0}>
                    <Panel key={1} showArrow={false}>
                        {this.renderDeviceInformation()}
                    </Panel>
                </Collapse>
            </div>
        );
	}
}

Device.propTypes = {
	intl: PropTypes.object.isRequired
};

export default injectIntl(Device);