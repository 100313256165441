import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select } from 'antd';
import { injectIntl } from 'react-intl';
import { getInvoicingMessages } from '../../../constants/messages';
import { existsInArray } from '../../../utilities/util';
const { Option } = Select;
const FormItem = Form.Item;

const invoiceMessages = getInvoicingMessages();

const messages = {
	...invoiceMessages,
};

class TaxRetentionPicker extends React.Component {

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	buildOptions = () => {
		const { getFieldValue } = this.props.form;
		const taxRetention = getFieldValue('taxRetention') || [];
		return [
			<Option key="0" value="fifteenPercent">15%</Option>,
			<Option key="1" value="onePercentSubtotal" disabled={existsInArray("onePercentTotal", taxRetention)}>1% (Subtotal)</Option>,
			<Option key="2" value="onePercentTotal" disabled={existsInArray("onePercentSubtotal", taxRetention)}>1% (Total)</Option>
		];
	}

	fullRender = () => {
		const { props, getIntl, buildOptions } = this;
		const { form, includeLabel } = props;
		const { getFieldDecorator } = form;
		const options = buildOptions();
		return (
			<FormItem label={includeLabel ? getIntl("paymentWithTaxRetention") : null}>
				{
					getFieldDecorator('taxRetention', {
						initialValue: []
					}
					)(
						<Select
							mode="multiple"
							placeholder={getIntl("paymentWithTaxRetention")}
						>
							{options}
						</Select>
					)
				}
			</FormItem>
		);
	}

	render() {
		return this.fullRender();
	}
}

TaxRetentionPicker.defaultProps = {
	includeLabel: false
};

TaxRetentionPicker.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	includeLabel: PropTypes.bool
};

export default injectIntl(TaxRetentionPicker);