import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { getLanguageIntl } from '../../../constants/messages';
import { Empty } from 'antd';
import { Axis, Chart, Interval, Legend, Tooltip } from 'bizcharts';
import Measure from 'react-measure';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { isMobile } from '../../../utilities/util';

let intlMessages = getLanguageIntl();

const messages = {
    ...intlMessages
};

const minChartWidth = 400;
const tooMuchDataMinChartWidth = 600;

class Graph extends React.Component {
	constructor(props){
        super(props);
		this.resizeChart = debounce(this.resizeChart, 250);
        this.state = {
            width: minChartWidth,
            tooMuchDataWidth: tooMuchDataMinChartWidth
        };
    }

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});
    
    dateStr = (date) => {
        const { props, getIntl } = this;
        const { dateFormat } = props;
        const locale = getIntl("intl");
        return moment(date).locale(locale).format(dateFormat);
    }

    resizeChart = (dimensions) => {
        if (!dimensions) return;
        let { width } = dimensions;
        const tooMuchDataWidth = width > tooMuchDataMinChartWidth ? width : tooMuchDataMinChartWidth;
        width = width > minChartWidth ? width : minChartWidth;
        this.setState({ width, tooMuchDataWidth });
    }

    getChartHeightAndWidth = () => {
        const { width, tooMuchDataWidth } = this.state;
        return { height: width/2, width, tooMuchDataWidth };
    }

    parseData = () => {
        const { props, dateStr } = this;
        const { data } = props;
        const parsedData = data.map(entry => {
            const { date } = entry;
            return { ...entry, date: dateStr(date) };
        });
        return parsedData;
    }

    monthLabelsFunction = () => {
        return {
            textAlign: 'top',
            fill: '#404040',
            rotate: 60,
            textBaseline: 'center'
        };
    }

    tooltipFormatter = () => {
        const { tooltipFormatter, amountHeader, amountFormatter } = this.props;
        if (tooltipFormatter) return tooltipFormatter();
        const format = (date, amount, key) => {
            return {
                name: amountHeader || key,
                title: date,
                value: amountFormatter ? amountFormatter(amount) : amount
            }
        };
        return ["date*amount*key", format];
    }

    renderBarChart = () => {
        const { props, getChartHeightAndWidth, parseData, monthLabelsFunction, resizeChart, tooltipFormatter } = this;
        const { comparisonBars, padGraph, itemTpl } = props;
        const dimensions = getChartHeightAndWidth();
        const { width, height, tooMuchDataWidth } = dimensions;
        const parsedData = parseData();
        const notEmpty = parsedData.length > 0;
        const isComparison = comparisonBars > 1;
        const dataLength = isComparison ? parsedData.length / comparisonBars : parsedData.length;
        const comparisonProps = isComparison ? { color: 'key', adjust: [{type: 'dodge', marginRatio: 1/32}] } : {};
        const tooMuchData = dataLength > 12 && width < tooMuchDataMinChartWidth;
        let chartClassName = tooMuchData || isMobile() ? "dashboard-scrollable-graph" : "";
        if (padGraph) chartClassName += " dashboard-graph-padding"
        const forceFit = tooMuchData ? false : true;
        const chartPadding = tooMuchData ? [5, 20, 20, 20] : 20;
        const usableWidth = tooMuchData ? tooMuchDataWidth : width;
        const tooltipProps = itemTpl ? { itemTpl } : { };
        return (
        <Measure bounds onResize={contentRect => resizeChart(contentRect.bounds)}>
            {
                ({ measureRef }) => (
                    <div className={chartClassName} ref={measureRef}>
                        {
                            notEmpty ?
                            <Chart data={parsedData} width={usableWidth} height={height} autoFit={forceFit} padding="auto" appendPadding={chartPadding}>
                                <Axis title={false} name="date" label={{ textStyle: monthLabelsFunction, autoRotate: false }}/>
                                <Axis title={false} name="amount"/>
                                <Legend />
                                <Tooltip showCrosshairs="true" type="y" {...tooltipProps} shared/>
                                <Interval position="date*amount" {...comparisonProps} tooltip={tooltipFormatter()}/>
                            </Chart> :
                            <Empty />
                        }
                    </div>
                )
            }
        </Measure>
        );
    }

	render() {
		return this.renderBarChart();
    }
    
}

Graph.defaultProps = {
    data: [],
    dateFormat: "MMM YY",
    comparisonBars: 1,
    padGraph: false
};

Graph.propTypes = {
    intl: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    dateFormat: PropTypes.string,
    comparisonBars: PropTypes.number,
    amountHeader: PropTypes.string,
    amountFormatter: PropTypes.func,
    padGraph: PropTypes.bool,
    tooltipFormatter: PropTypes.func,
    itemTpl: PropTypes.string
};

export default injectIntl(Graph);