import React from 'react'
import PropTypes from 'prop-types';
import { Form, Input, InputNumber } from 'antd';
import { useIntl } from 'react-intl';
import { getFields, getPayrollMessages } from '../../../../../constants/messages';
const FormItem = Form.Item;

let FieldsMessages = getFields(),
    PayrollsMessages = getPayrollMessages();

const messages = {
    ...FieldsMessages,
    ...PayrollsMessages,
};

const EditIsrForm = ({ form, record }) => {
    const intl = useIntl();

    return (
        <Form
            ref={form}
            layout='vertical'
            initialValues={{  
                ...record, 
                minAmount: record ? record.minAmount : null,
                maxAmount: record ? record.maxAmount : null, 
                percentage: record ? record.percentage : null, 
            }}
        >
            <FormItem name="id" hidden={true}>
                <Input hidden={true} />
            </FormItem>
            <FormItem 
                name="minAmount"
                rules={[{ required: true }]}
                label={intl.formatMessage({...messages.minAmount})}
            >
                <InputNumber 
                    placeholder={intl.formatMessage({...messages.minAmount})}
                    style={{ width: '100%' }}
                    disabled={record.minDisabled}
                />   
            </FormItem>
            <FormItem
                name="maxAmount"
                rules={[{ required: true }]}
                label={intl.formatMessage({...messages.maxAmount})}
                extra={record.maxDisabled && intl.formatMessage({...messages.extraMaxAmountMsg})}
            >
                <InputNumber 
                    placeholder={intl.formatMessage({...messages.maxAmount})}
                    style={{ width: '100%' }}
                    disabled={record.maxDisabled}
                />     
            </FormItem>
            <FormItem
                name="percentage"
                rules={[{ required: true }]}
                label={intl.formatMessage({...messages.percentage})}
            >
                <InputNumber 
                    placeholder={intl.formatMessage({...messages.percentage})}
                    style={{ width: '100%' }}
                    min={0}
                    max={100}
                    addonAfter="%"
                />     
            </FormItem>
        </Form>
    )
}

EditIsrForm.propTypes = {
    form: PropTypes.object.isRequired,
    record: PropTypes.object
};

export default EditIsrForm
