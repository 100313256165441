import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Select } from 'antd';
import { getDashboardMessages } from '../../../constants/messages';
import moment from 'moment';
const { Option } = Select;

let dashboardMessages = getDashboardMessages();

const messages = {
	...dashboardMessages
};

const globalDateCalculator = (initialDate = moment().set({date: 15, hour: 0}), quantity = 12, unitOfTimeSubtract = 'M', unitOfTimeLimit = 'month') => {
    const dates = [];
    for (let count = 0; count < quantity; count++) {
        const currentDate = moment(initialDate).subtract(count, unitOfTimeSubtract);
		const start = moment(currentDate).startOf(unitOfTimeLimit).toISOString();
        const end = moment(currentDate).endOf(unitOfTimeLimit).toISOString();
        dates.push({
            date: currentDate.toISOString(),
            datePair: [start, end]
        });
    }
    return dates;
}

const calculateLastXDays = (quantity) => globalDateCalculator(moment().set({hour: 12, minute: 0}), quantity, 'd', 'day');
const calculateLast12Months = () => globalDateCalculator(moment().set({date: 15, hour: 0}), 12, 'M', 'month');
const calculateThisWeek = () => globalDateCalculator(moment().set({hour: 12, minute: 0}).endOf('week'), 7, 'd', 'day');
const calculateThisMonth = () => {
    const endDate = moment().set({hour: 12, minute: 0}).endOf('month');
    return globalDateCalculator(endDate, endDate.date(), 'd', 'day');
}
const calculateThisYear = () => globalDateCalculator(moment().set({date: 15, hour: 0}).endOf('year'), 12, 'M', 'month');

class DateRangeSelect extends Component {

	constructor(props){
        super(props);
        this.state = {
            dropdownOpen: false
        };
    }

    getIntl = (str, values = {}) => this.props.intl.formatMessage({...messages[str]}, values);

	render() {
        const { state, props, getIntl } = this;
        const { dropdownOpen } = state;
        const { filterFunction } = props;
        const options = [
            { intl: "last7Days", datesToLookup: () => calculateLastXDays(7), format: "DD MMM"} ,
            { intl: "last30Days", datesToLookup: () => calculateLastXDays(30), format: "DD MMM" },
            { intl: "last12Months", datesToLookup: () => calculateLast12Months(), format: "MMM YY" },
            { intl: "thisWeek", datesToLookup: () => calculateThisWeek(), format: "DD MMM" },
            { intl: "thisMonth", datesToLookup: () => calculateThisMonth(), format: "DD MMM" },
            { intl: "thisYear", datesToLookup: () => calculateThisYear(), format: "MMM YY" }
        ];
        const onChange = (value) => filterFunction(options[value].datesToLookup(), options[value].format);
        const buildOption = (option, index) => {
            const { intl } = option;
            const text = dropdownOpen ? getIntl(intl) : getIntl("range", { range: getIntl(intl) });
            return <Option key={index} value={index}>{<div className="dashboard-range-picker-option">{text}</div>}</Option>;
        }
        return (
            <Select className="dashboard-date-range-select" onChange={onChange} defaultValue={2} onDropdownVisibleChange={dropdownOpen => this.setState({ dropdownOpen })}>
                {options.map(buildOption)}
            </Select>
        );
	}
}

DateRangeSelect.propTypes = {
    intl: PropTypes.object.isRequired,
    filterFunction: PropTypes.func.isRequired
};

export default injectIntl(DateRangeSelect);
