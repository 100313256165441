import React from 'react'
import PropTypes from 'prop-types';
import { Form, Input, Col, Row, Select, Spin} from 'antd';
import { useIntl } from 'react-intl';
import { getFields, getLotsMessages, getErrorMessages, getUnitsMessages, getEmployeeMessages } from '../../constants/messages';

const Option = Select.Option;
const FormItem = Form.Item;

let FieldsMessages = getFields(),
    ErrorMessages = getErrorMessages(),
    UnitsMessages = getUnitsMessages(),
    LotsMessages = getLotsMessages(),
    EmployeeMessages = getEmployeeMessages();

const messages = {
	...FieldsMessages,
    ...UnitsMessages,
    ...LotsMessages,
    ...ErrorMessages,
    ...EmployeeMessages,
};

const AddOrEditForm = ({ form, unitsAvailable, existingLots, loading }) => {
	const intl = useIntl();

    const unitsOption = unitsAvailable.map((unit) => (
        <Option key={unit.id} value={unit.id}>
            {unit.pin}
        </Option>
    ));

    const lotsOption = existingLots.map((lot) => (
        <Option key={lot.id} value={lot.id}>
            { intl.formatMessage({...messages.lotId}, {id: lot.lotNumber}) }
        </Option>
    ));

  return (
    <div>
            <Spin spinning={loading} >
            <Form
                ref={form}
                layout='vertical'
                >
                <FormItem name="id" hidden={true}>
                    <Input hidden={true} />
                </FormItem>
                <Row type="flex" justify="center" align="bottom">
                    <Col span={24}>
                        <FormItem 
                            name="unitsId"
                            label={intl.formatMessage({ ...messages.units })} 
                            rules={[
                                {
                                    required: true, 
                                    message: intl.formatMessage({...messages.UnitsError}) 
                                }
                            ]} 
                            >
                            <Select
                                mode="multiple"
                                placeholder={intl.formatMessage({ ...messages.pin })}
                                optionFilterProp="children"
                                >
                                {unitsOption}
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
                <Row type="flex" justify="center" align="bottom">
                    <Col span={24}>
                        <FormItem 
                            name="lotId"
                            label={intl.formatMessage({ ...messages.lots })} 
                            rules={[
                                {
                                    required: true, 
                                    message: intl.formatMessage({...messages.LotsError}) 
                                }
                            ]}
                            >
                            <Select
                                showSearch
                                placeholder={intl.formatMessage({ ...messages.lot })}
                                optionFilterProp="children"
                                >
                                {lotsOption}
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            </Spin>
    </div>
  )
}

AddOrEditForm.propTypes = {
    form: PropTypes.object.isRequired,
    unitsAvailable: PropTypes.array,
    existingLots: PropTypes.array,
    loading: PropTypes.bool
};

export default AddOrEditForm;
