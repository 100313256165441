import React from 'react';
import PropTypes from 'prop-types';
import { MinusOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';

class RemoveButton extends React.Component {

    render() {
        const {removeFunction, toolTipText} = this.props;
        if ('toolTipText' in this.props)
        return (
            <Tooltip title={toolTipText}>
                <Button shape="circle" icon={<MinusOutlined />} onClick={removeFunction}/>
            </Tooltip>
        );
        return <Button shape="circle" icon={<MinusOutlined />} onClick={removeFunction}/>; 
    }

}

RemoveButton.propTypes = {
    removeFunction: PropTypes.func.isRequired,
    toolTipText: PropTypes.string
};

export default RemoveButton;