import React from 'react';
import PropTypes from 'prop-types';
import DeviceAccessories from './DeviceAccessories';

class DeviceAccessoriesIntermediator extends React.Component {
    
	render() {
        const { form, isUpdate, getField, saveFields, gpsFieldName } = this.props;
        const clientId = getField("client.id");
        const hasPanicButton = isUpdate ? getField(`${gpsFieldName}.hasPanicButton`) : null;
        const canLockUnlockDoors = isUpdate ? getField(`${gpsFieldName}.canLockUnlockDoors`) : null;
        const canDisableEngine = isUpdate ? getField(`${gpsFieldName}.canDisableEngine`) : null;
        const save = (fieldName, value) => saveFields({ [fieldName]: value });
        const saveHasPanicButton = hasPanicButton => save('hasPanicButton', hasPanicButton);
        const saveCanLockUnlockDoors = canLockUnlockDoors => save('canLockUnlockDoors', canLockUnlockDoors);
        const saveCanDisableEngine = canDisableEngine => save('canDisableEngine', canDisableEngine);
        return (
            <DeviceAccessories
                form={form}
                disabled={clientId === undefined}
                hasPanicButton={hasPanicButton}
                onChangeHasPanicButton={saveHasPanicButton}
                canLockUnlockDoors={canLockUnlockDoors}
                onChangeCanLockUnlockDoors={saveCanLockUnlockDoors}
                canDisableEngine={canDisableEngine}
                onChangeCanDisableEngine={saveCanDisableEngine}
            />
        );
    }
}

DeviceAccessoriesIntermediator.defaultProps = {
    isUpdate: false,
    gpsFieldName: "gps"
};

DeviceAccessoriesIntermediator.propTypes = {
    form: PropTypes.object.isRequired,
    isUpdate: PropTypes.bool,
    getField: PropTypes.func.isRequired,
    saveFields: PropTypes.func.isRequired,
    gpsFieldName: PropTypes.string
};

export default DeviceAccessoriesIntermediator;