import React from 'react';
import PropTypes from 'prop-types';
import { List, Table } from 'antd';
import { injectIntl } from 'react-intl';
import { getLanguageIntl, getFields } from '../../constants/messages';
import { getNestedValue } from '../../utilities/util';
import Embolden from '../GlobalComponents/Embolden';
import moment from 'moment';

let fieldMessages = getFields(),
	intlMessages = getLanguageIntl();

const messages = {
	...fieldMessages,
	...intlMessages
};

class ActivityLog extends React.Component {

	getIntl = (str) => this.props.intl.formatMessage({ ...messages[str] });

	emboldenIntl = (headerIntl, value) => {
		const { getIntl } = this;
		return <div><Embolden header={getIntl(headerIntl)} value={value} /></div>;
	}

	formatDate = (unformatedDate) => {
		if (!unformatedDate) return undefined;
		const { getIntl } = this;
		const locale = getIntl("intl");
		return moment(unformatedDate).locale(locale).format('MMMM DD, YYYY, h:mm a');
	}

	renderList = () => {
		const { props, formatDate, emboldenIntl, getIntl } = this;
		const { activityLog } = props;
		const renderItem = log => {
			const { date, message, account } = log;
			return (
				<List.Item>
					<div>
						{emboldenIntl("date", formatDate(date))}
						{emboldenIntl("message", message)}
						{emboldenIntl("user", getNestedValue("name", account))}
					</div>
				</List.Item>
			);
		}
		return (
			<div>
				<h2>{getIntl("activityLog")}</h2>
				<List bordered={true} dataSource={activityLog} renderItem={renderItem} />
				<br />
			</div>
		);
	}

	renderTable = () => {
		const { props, formatDate, getIntl } = this;
		const { activityLog } = props;
		const dataSource = activityLog.map(log => ({ ...log, key: log.id }));
		const columns = [
			{
				title: getIntl("user"),
				dataIndex: 'account',
				key: 'account',
				width: 200,
				render: account => getNestedValue("name", account)
			},
			{
				title: getIntl("date"),
				dataIndex: 'date',
				key: 'date',
				width: 350,
				render: date => formatDate(date)
			},
			{
				title: getIntl("message"),
				dataIndex: 'message',
				key: 'message'
			},
		];
		return (
			<div>
				<h2>{getIntl("activityLog")}</h2>
				<Table dataSource={dataSource} columns={columns} pagination={false} size="small" />
				<br />
			</div>
		);
	}

	fullRender = () => {
		const { props, renderList, renderTable } = this;
		const { list } = props;
		if (list) return renderList();
		return renderTable();
	}

	render() {
		return this.fullRender();
	}
}

ActivityLog.defaultProps = {
	activityLog: [],
	list: false
};

ActivityLog.propTypes = {
	intl: PropTypes.object.isRequired,
	activityLog: PropTypes.array.isRequired,
	list: PropTypes.bool
};

export default injectIntl(ActivityLog);