import React, { useEffect, useState, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import CommonTable from '../../components/CommonTable';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, message, Popconfirm, Typography} from 'antd';
import { getFields, getLotsMessages, getactionMessages, getPopConfirm, getJobMessages } from '../../constants/messages';
import { DeleteOutlined, ToolOutlined } from '@ant-design/icons';
import { DeleteLotUnit, GetAllLotsUnits,setSearch } from './actions';
import AssignTechnicalModal from './AssignTechnicalModal';
import { GetEmployeesActive } from '../Employees/actions';
import AddButton from './AddButton';
import _ from 'lodash';
import { getJobType } from '../Jobs/constants';

let moment = require('moment');

let LotsMessages = getLotsMessages(),
	FieldsMessages = getFields(),
	ActionMessages = getactionMessages(),
	PopMessages = getPopConfirm(),
	JobMessages = getJobMessages();
 
const messages = {
	...LotsMessages,
	...FieldsMessages,
	...ActionMessages,
	...PopMessages,
	...JobMessages,
};

const LotUnits = () => {
    const intl = useIntl();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [employees, setEmployees] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState([]);
	const [filter, setFilter] = useState('');
	const [showAssignTech, setAssignTechModal] = useState(false);
	const [cleanSelectedRows, setcleanSelectedRows] = useState(false);
	const getUnitSelected = (selectedUnits) => setSelectedUnit(selectedUnits); 
	const lotUnits = useSelector((state) => state.lotUnit.lotUnits);
	const search = useSelector((state) => state.lotUnit.search);

	const clearSelectedRows = (value) => { 
		setSelectedUnit([]);
		setcleanSelectedRows(value);
	};

    useEffect(() => {
		(async () => {
			try {
				if(search === ''){
					await dispatch(GetAllLotsUnits());
				}
				const employees = await GetEmployeesActive();
				setEmployees(employees);
				setLoading(false);
			} catch (error) {
				message.error(intl.formatMessage({ ...messages.getLotsError }));
			}
		})();	
	}, []);

	const deleteUnitLot =  (unitSelected, ids) => {
		setLoading(true);
		dispatch(DeleteLotUnit(unitSelected, ids)).then(() => {
			setLoading(false);
			clearSelectedRows(true);
		});
		return [];
	};

	const renderJobType = (type) => {
		const TYPE = getJobType(type);
		return intl.formatMessage( {...messages[TYPE.intl]} );
	};

	const tableActions = (id, record) => {
		return (
			<span>
				<Popconfirm
					title={intl.formatMessage({...messages.deleteConfirm})}
					onConfirm={() => {
						deleteUnitLot([record], [id]);
						message.success(intl.formatMessage({...messages.deleteLotSuccess}));
					}}
					okText={intl.formatMessage({...messages.yes})}
					cancelText={intl.formatMessage({...messages.no})}
				>
					<Typography.Link>
						<span className="icon-bin" />
						<FormattedMessage {...messages.delete} />
					</Typography.Link>
				</Popconfirm>
			</span>
		);
	};

	const filteredLotUnits = useMemo(() => {
        const currentLotUnits = lotUnits.filter((lotUnit) => {
                if (filter.searchText === '') {
                    return true;
                }
				if (lotUnit.Lot.lotNumber.toLowerCase().includes(filter.searchText)) {
					return true;
				}
				if (lotUnit.Unit.UnitModel.model.toLowerCase().includes(filter.searchText)) {
					return true;
				}
				if (lotUnit.Unit.description.toLowerCase().includes(filter.searchText)) {
					return true;
				}
				if(lotUnit.Unit.imei){
					if (lotUnit.Unit.imei.toLowerCase().includes(filter.searchText)) 
						return true;
				}
				if (lotUnit.Unit.pin.toLowerCase().includes(filter.searchText)) {
					return true;
				}
				if (lotUnit.Lot.onCharge.toLowerCase().includes(filter.searchText)) {
					return true;
				}
				if (lotUnit.technical && lotUnit.technical.toLowerCase().includes(filter.searchText)) {
					return true;
				}
				if (lotUnit.client && lotUnit.client.toLowerCase().includes(filter.searchText)) {
					return true;
				}
				if(lotUnit.Job){
					const jobType = renderJobType(lotUnit.Job.type);
					const jobId = intl.formatMessage({...messages.orderId}, {id: lotUnit.Job.id});
					if (jobId.toLowerCase().includes(filter.searchText)) {
						return true;
					}
					if (jobType && jobType.toLowerCase().includes(filter.searchText)) {
						return true;
					}
				}
                return false;
            });
			dispatch(setSearch(filter.searchText));
            return _.sortBy(currentLotUnits, (lotUnit) => lotUnit.id);
    }, [lotUnits, filter]);

    const columns = useMemo(() => {
        return [
            {
                title: intl.formatMessage({ ...messages.lot}),
                dataIndex: 'lotNumber',
                key: 'lotNumber',
				render: (id, record) => {
					return `${record.Lot.lotNumber}`;
				}
            },
			{
                title: intl.formatMessage({ ...messages.unit}),
                dataIndex: 'description',
                key: 'description',
				render: (id, record) => {
					return `${record.Unit.description}`;
				},
			},
			{
				title: intl.formatMessage({ ...messages.pin}),
				dataIndex: 'pin',
				key: 'pin',
				render: (id, record) => {
					return `${record.Unit.pin}`;
				},
			},
			{
				title: intl.formatMessage({ ...messages.imei}),
				dataIndex: 'imei',
				key: 'imei',
				render: (id, record) => {
					return `${record.Unit.imei}`;
				},
			},
			{
				title: intl.formatMessage({ ...messages.model}),
				dataIndex: 'modelId',
				key: 'modelId',
				render: (id, record) => {
					return `${record.Unit.UnitModel.model}`;
				},
			},
			{
				title: intl.formatMessage({ ...messages.onCharge}),
				dataIndex: 'onCharge',
				key: 'onCharge',
				render: (id, record) => {
					return `${record.Lot.onCharge}`;
				},
			},
			{
				title: intl.formatMessage({ ...messages.technical}),
				dataIndex: 'technical',
				key: 'technical',
			},
			{
				title: intl.formatMessage({ ...messages.client}),
				dataIndex: 'client',
				key: 'client',
			},
			{
				title: intl.formatMessage({ ...messages.job}),
				dataIndex: 'jobId',
				key: 'jobId',
				render: (jobId) => {
					return jobId;
				},
			},
			{
				title: intl.formatMessage({ ...messages.jobType}),
				dataIndex: 'jobType',
				key: 'jobType',
				render: (id, record) => {
					if(record.Job) return renderJobType(record.Job.type);
					return;
				},
			},
			{
				title: intl.formatMessage({ ...messages.date}),
				dataIndex: 'date',
				key: 'date',
				render: (id, record) => {
					if (record.Job) return (moment(record.Job.programmedDate).locale('es').format('MMMM DD, YYYY'));
					return;
				}
			},
			{
				title: intl.formatMessage({ ...messages.actions}),
				dataIndex: 'id',
				render: (id, record) => tableActions(id, record),
			}
        ];
    }, []);

	const assignTechButton = () => (
		<a key="assignTech" onClick={() => setAssignTechModal(true)}>
			<ToolOutlined />
			<FormattedMessage {...messages.assignTechnical} />
		</a>
	);
	
	const items = [
		{
			key: '1',
			label: assignTechButton()
		},
	];

	const deleteBatch = (ids) => {
		const result = lotUnits.filter(unit => ids.includes(unit.id));
		deleteUnitLot(result, ids)
	}

	const extraComponents = selectedUnit.length ? 
		<Dropdown.Button 
			className="extraButtom" 
			menu={{items}}
		>
			<Popconfirm
				title={intl.formatMessage({...messages.deleteConfirm})}
				onConfirm={() => {
					deleteBatch(selectedUnit);
					message.success(intl.formatMessage({...messages.deleteLotSuccess}));
				}}
				okText={intl.formatMessage({...messages.yes})}
				cancelText={intl.formatMessage({...messages.no})}
			>
				<DeleteOutlined />
				<FormattedMessage {...messages.deleteAmount} values={{ amount: selectedUnit.length }}/>
			</Popconfirm>
		</Dropdown.Button> : null;

    return (
		<div className="account view">
			<CommonTable
				columns={columns}
				dataSource={filteredLotUnits}
				Add={<AddButton />}
				loading={loading}
				recordCount={filteredLotUnits.length}
				getRowSelected={getUnitSelected}
				// eslint-disable-next-line no-unused-vars
				search={(search, page, pageSize) => setFilter(search)}
				searchText={search}
				getRecords={() => { }}
				AssignTechnical={extraComponents}
				clearSelectedRows={clearSelectedRows}
				cleanSelectedRows={cleanSelectedRows}
				getCheckboxProps={(() => ({ disabled: false }))}
				lowerCaseSearch
				preserveSelectedRowKeys
			/>
			<AssignTechnicalModal
				showModal={showAssignTech}
				selectedUnit={selectedUnit}
				employees={employees}
				clearSelectedRows={clearSelectedRows}
				title={intl.formatMessage({...messages.assignTechnical})}
				onCancel={() => setAssignTechModal(false)}
			/>
        </div>
  )
}

export default LotUnits;
