import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Form, Radio } from 'antd';
import { getJobMessages } from '../../../../constants/messages';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

let jobMessages = getJobMessages();

const messages = {
	...jobMessages
};

const SendNotifyJob = ({ form, save, willSendEmail, options}) => {
	const intl = useIntl();
  const { getFieldDecorator } = form;

  const onChange = (event) => save(event.target.value);

  return (
      <FormItem label={intl.formatMessage({...messages.notifyJob})}>
        {
          getFieldDecorator(`willSendEmail`, 
              {
                  initialValue: willSendEmail,
                  onChange: onChange
              }
          )(
            <RadioGroup defaultValue={false}>
                {options}
            </RadioGroup>
          )
        }
      </FormItem>
  )
}


SendNotifyJob.propTypes = {
    form: PropTypes.object.isRequired,
    save: PropTypes.func,
    willSendEmail: PropTypes.number,
    options: PropTypes.array.isRequired
};

export default SendNotifyJob