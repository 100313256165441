import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
    injectIntl,
    FormattedMessage
} from 'react-intl';
import {
    Button,
    Modal,
    Badge,
    Row,
    Col,
    Table,
} from 'antd';
import {
    getModalMessages,
    getPaymentMessages,
    getDebitNoteMessages,
} from '../../constants/messages';
import { STATUSES } from './constants';
import { TYPES } from '../Payments/constants';
import { roundN } from '../../utilities/util';
import moment from 'moment';
import AddDebitNoteModal from './AddDebitNoteModal';
import { connect } from 'react-redux';

const { INITIAL_BALANCE, PAYMENT, CREDIT_NOTE, TAX_EXEMPTION, SURPLUS } = TYPES;

let modalMessages = getModalMessages(),
    paymentMessages = getPaymentMessages(),
    debitNoteMessages = getDebitNoteMessages();

const messages = {
    ...modalMessages,
    ...paymentMessages,
    ...debitNoteMessages
};

class ShowDebitNoteModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
			showEditModal: false,
        };
    }

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

    renderStatus = () => {
		let color = 'default';
		let text = this.props.intl.formatMessage({ ...messages.Pending });
		if(this.props.debitNote.status === STATUSES.PAID){
			color = 'success';
			text = this.props.intl.formatMessage({ ...messages.Paid });
		}
		return (
			<h1><FormattedMessage {...messages.Number} /> {this.props.debitNote.documentNumber} | <Badge status={color} text={text} /></h1>
		);
    }

    paymentTypesMessages = (type) => {
		const { getIntl } = this;
		switch (type) {
			case INITIAL_BALANCE.id:
				return getIntl(INITIAL_BALANCE.intl);
			case PAYMENT.id:
				return getIntl(PAYMENT.intl);
			case CREDIT_NOTE.id:
				return getIntl(CREDIT_NOTE.intl);
			case TAX_EXEMPTION.id:
				return getIntl(TAX_EXEMPTION.intl);
			case SURPLUS.id:
				return getIntl(SURPLUS.intl);
			default:
				return "";
		}
	}

	openEditModal = () => {	
		this.setState({
			showEditModal: true
		})
	}

	closeEditModal = () => {
		this.setState({
			showEditModal: false
		});
	}

	renderInvoices = (debitNoteInvoices) => {
		if(debitNoteInvoices !== undefined)
		return debitNoteInvoices.map((object, index) => {
			return( 
				<Row key={index}>
					<Col span={4}>
					
					</Col>
					<Col span={8}>
						<a className="editable-add-btn" onClick={() => {this.props.history.push(`/invoices/${object.invoice.id}`);}}>
							{object.invoice.documentNumber}
                        </a>
					</Col>
					<Col span={6} style={{'textAlign': 'right'}}>
						<p>{`L ${roundN(-object.amount, 2)}`}</p>
					</Col>
				</Row>
			);
		});
	}

    renderPaymentsTable = () => {
		let ds = this.props.debitNote.payments !== undefined? this.props.debitNote.payments : [];
		let dataSource = ds.map((object) => {
			return {
				...object,
				'key': ds.indexOf(object)
			};
		});
		let columns = [
			{
				title: this.props.intl.formatMessage({ ...messages.Type }),
				dataIndex: 'type',
				key: 'type',
				width: 150,
				render: (type) => {
					return (this.paymentTypesMessages(type));
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Amount }),
				dataIndex: 'amount',
				key: 'amount',
				className: 'column-money',
				width: 150,
				render: (amount) => {
					return (
							`L ${roundN(amount, 2)}`
						);
				}
			}, {
				title: 'Balance',
				dataIndex: 'balance',
				key: 'balance',
				className: 'column-money',
				width: 150,
				render: (balance) => {
					return (
							`L ${roundN(balance, 2)}`
						);
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Date }),
				dataIndex: 'createdAt',
				key: 'createdAt',
				width: 150,
				render: (date) => {
					return (
							moment(date).locale('es').format('l')
						);
				}				
			}, {
				title: this.props.intl.formatMessage({ ...messages.Description }),
				dataIndex: 'description',
				key: 'description',
				render: (description) => {
					return (
							description
						);
				}
			}
		];

		return (
			<div style={{clear: "both"}}>
				<br/>
				<h2><FormattedMessage {...messages.Movements} /></h2>
				<Table dataSource={dataSource} columns={columns} pagination={false} size="small"/>
			</div>	
		);
	}

    renderContent = () => {
        return (
            <React.Fragment>
                <Row gutter={16}>
                    <Col span={18}>
                        {this.renderStatus()}
                    </Col>
                    <Col span={6
                    } style={{'textAlign': 'right'}}>
                        <h1>{moment(this.props.debitNote.createdAt).locale('es').format('l')}</h1>
                    </Col>
                </Row>
				<Row gutter={16}>
					<Col span={16}>
						<h3 style={{'display': 'inline'}}><FormattedMessage {...messages.Client} /> </h3> 
						<p style={{'display': 'inline'}}>{this.props.debitNote.clientName}</p>
					</Col>
					<Col span={8}>
						<h3 style={{'display': 'inline'}}>RTN </h3>
						<p style={{'display': 'inline'}}>{this.props.debitNote.clientRtn}</p>
					</Col>
				</Row>
				<Row gutter={16}>
					<h3 style={{'display': 'inline'}}><FormattedMessage {...messages.Motive} /> </h3> 
					<p style={{'display': 'inline'}}>{this.props.debitNote.motive}</p>
				</Row>
				<Row gutter={16}>
					<h3 style={{'display': 'inline'}}><FormattedMessage {...messages.Description} /> </h3> 
					<p style={{'display': 'inline'}}>{this.props.debitNote.description}</p>
				</Row>
                {this.renderPaymentsTable()}
            </React.Fragment>
        );
    }

	handleCancel = () => {
		this.props.closeModal();
	}

	renderButtons = () => {
		const { props: { debitNote: {payments, id} } } = this;
		return <React.Fragment>
		{payments && payments.length == 0 && (
			<Button key="submit" type="primary" size="large" onClick={() => { this.openEditModal(id); } }>
				<FormattedMessage {...messages.Edit} />
			</Button>
		)},
		<Button key="back" size="large" onClick={this.handleCancel}><FormattedMessage {...messages.cancel} /></Button>
		</React.Fragment>
	} 

    render() {
        return (
			<React.Fragment>
				<AddDebitNoteModal
				showModal={this.state.showEditModal}
				handleCancel={this.closeEditModal}
				document={this.props.debitNote}
			/>
				<Modal
					title={this.props.intl.formatMessage({ ...messages.DebitNote })}
					destroyOnClose
					open={this.props.showModal}
					onOk={this.props.closeModal}
					onCancel={this.props.closeModal}
					confirmLoading={this.state.isLoading}
					width={800}
					footer={this.renderButtons()}
				>
					{this.renderContent()}
				</Modal></React.Fragment>
        );
    }

}

ShowDebitNoteModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
	debitNote: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    showModal: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
	return {
		debitNote: state.debitNote.debitNote
	};
};

export default connect(mapStateToProps, null)(withRouter(injectIntl(ShowDebitNoteModal)));
