import axios from 'axios';
import {
	ADD_CLIENT_SUCCESS,
	EDIT_CLIENT_SUCCESS,
	FETCH_CLIENT,
	FETCH_CLIENT_COUNT,
	DELETE_CLIENT_SUCCESS,
	FETCH_CLIENT_TAGS,
	GET_CLIENTS,
	CLEAN_CLIENTS,
	BASIC_CLIENT_INFO_SAVE_CLIENT_NAME,
	BASIC_CLIENT_INFO_SAVE_REPRESENTATIVE,
	BASIC_CLIENT_INFO_SAVE_RTN,
	BASIC_CLIENT_INFO_SAVE_IS_LPS,
	BASIC_CLIENT_INFO_SAVE_IS_INDIVIDUAL,
	CLIENT_INFO_SAVE_TELEPHONES,
	CLIENT_INFO_SAVE_EMAILS,
	CLIENT_INFO_SAVE_CUSTOM_PRICES,
	CLIENT_INFO_SAVE_SALESPERSON,
	CLIENT_SET_CURRENT_CLIENT_ID,
	FETCH_CLIENT_ACTIVITY_LOG,
	FETCH_CLIENT_STATUS,
	CLIENT_ADDRESS_SAVE,
	FETCH_SELLER_CLIENTS,
	SET_CLIENT_ADDRESSES,
	SEARCH_PREREGISTRATION,
	SEARCH_APPROVEDCLIENTS
} from './actionTypes';

import { notify, arrayElementContainedInArray, substituteObjectInArrayByValue, getObjectInArray } from '../../utilities/util';
import { STATUS } from './constants';
import { ROLES } from '../../constants/global';
let moment = require('moment');
let cloneDeepWith = require('lodash.clonedeepwith');
let globalStatus = 0;
let globalSearchText = "";
let globalCurrent = 1;
let globalDisabled = 0;
let preRegistrationStatus = [STATUS.PENDING, STATUS.REJECTED];
let approvedStatus = [STATUS.APPROVED];

export const CreateSearchClients = (name, limit = 10) => {
	let regx = "%" + name + "%";
	let filter = {
		limit: limit,
		where: { name: { like: regx } }
	};
	return axios.get(`/Clients?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			return response.data;
		});
};

export const GetClientOfSeller = () => (dispatch, getState) => {
	let state = getState();
	let accountId = state.auth.user.id;
	const filter = {
		fields: ["id"],
		where: {salesPersonId: accountId},
	};
	return axios.get(`/Clients?filter=${encodeURIComponent(JSON.stringify(filter))}`)
	.then(response => {
		const clientIds =  response.data.map(client => client.id);
		dispatch({ type: FETCH_SELLER_CLIENTS, sellerClients: clientIds });
	});
};

export const GetClientAddresses = (clientId) => (dispatch) => {
	return axios.get(`/Clients/${clientId}/addresses`)
		.then((response) => {
			dispatch({ type: SET_CLIENT_ADDRESSES, clientAddresses: response.data });
		});
};

export const SearchAvailableOptimusLinkClients = (clientName, limit = 10) => {
	return axios({
		url: `/OptimusClients/SearchAvailableLinkClients`,
		method: 'GET',
		responseType: 'json',
		params: { clientName, limit }
	})
		.then(response => response.data);
};

export const SearchAvailableRastreoLinkClients = (clientName) => {
	return axios({
		url: `/RastreoClients/searchAvailableLinkClients`,
		method: 'GET',
		responseType: 'json',
		params: { clientName }
	})
		.then(response => response.data);
};

export const ApproveAndLink = (clientId, optimusClientId, activityMessage) => (dispatch) => {
	const params = {
		message: activityMessage,
		optimusClientId: optimusClientId,
	};
	return axios({
		url: `/Clients/${clientId}/approveAndLink`,
		method: 'PATCH',
		params
	})
		.then(() => dispatch(SearchPreRegisterClients({ searchText: globalSearchText, status: globalStatus }, globalCurrent)));
};

export const ChangeClientDisable = (clientId, disabled, message) => (dispatch) => {
	const options = {
		id: clientId,
		disable: disabled,
		message: message
	};
	const filter = {clientId: clientId,status: 2,};
	

	return axios.patch(`/Clients/changeClientDisable`, options)
		.then(() => {
			dispatch(SearchApprovedClients({ searchText: globalSearchText, disabled: globalDisabled }, globalCurrent))
			return axios.get(`/Invoices/count?where=${encodeURIComponent(JSON.stringify(filter))}`)
			.then((response)=>{
				return response.data.count
			})
	
		}
	);
};


export const DisableApprovedClients = ({clientIds = [], message}) => (dispatch) => {
	const options = {
		clientIds,
		message
	};
	return axios.patch(`/Clients/disable/batch`, options)
		.then(() => dispatch(SearchApprovedClients({ searchText: globalSearchText, disabled: globalDisabled }, globalCurrent)));
};

export const DisableClients = async (clientIds = [], message) => {
	const options = {
		clientIds,
		message
	};
	return await axios.patch(`/Clients/disable/batch`, options);
};

export const ChangePriceCurrency = (isLps) => (dispatch, getState) => {
	let state = getState();
	let customPrices = state.client.customPrices;
	let dollarToLps = state.currency.dollarToLps;
	let convertedCustomPrices = [];

	customPrices.forEach(customPrice => {
		let convertedCustomPrice = {
			...customPrice,
			"price": isLps ? (customPrice.price * dollarToLps) : (customPrice.price / dollarToLps)
		};
		convertedCustomPrices.push(convertedCustomPrice);
	});

	dispatch({ type: CLIENT_INFO_SAVE_CUSTOM_PRICES, customPrices: convertedCustomPrices });
};

export const GetClientInfo = (clientId) => (dispatch) => {
	if (!clientId) return;
	dispatch({ type: CLIENT_SET_CURRENT_CLIENT_ID, currentClient: clientId });
	return axios.get(`/Clients/${clientId}/getClientInfo`)
		.then((response) => {
			let client = response.data;

			dispatch({ type: BASIC_CLIENT_INFO_SAVE_CLIENT_NAME, clientName: client.name });
			dispatch({ type: BASIC_CLIENT_INFO_SAVE_REPRESENTATIVE, representative: client.representative });
			dispatch({ type: BASIC_CLIENT_INFO_SAVE_RTN, rtn: client.rtn });
			dispatch({ type: BASIC_CLIENT_INFO_SAVE_IS_LPS, isLps: client.isLps });
			dispatch({ type: CLIENT_ADDRESS_SAVE, fullAddress: client.address });
			dispatch({ type: BASIC_CLIENT_INFO_SAVE_IS_INDIVIDUAL, isIndividual: client.isIndividual });
			dispatch({ type: FETCH_CLIENT_STATUS, currentStatus: client.status });

			dispatch({ type: CLIENT_INFO_SAVE_TELEPHONES, telephones: client.telephones });
			dispatch({ type: CLIENT_INFO_SAVE_EMAILS, emails: client.clientEmails });
			dispatch({ type: CLIENT_INFO_SAVE_CUSTOM_PRICES, customPrices: client.customPrices });
			dispatch({ type: FETCH_CLIENT_ACTIVITY_LOG, activityLog: client.activityLog });
			dispatch({ type: CLIENT_INFO_SAVE_SALESPERSON, salesPerson: client.salesPerson.name });

			//return client;
			return cloneDeepWith(client);
		});
};

export const GetTags = () => (dispatch) => {
	return axios.get(`/Tags`)
		.then((response) => {
			dispatch({ type: FETCH_CLIENT_TAGS, tags: response.data });
		});
};

export const GetClients = (page = 1, pageSize = 10) => (dispatch) => {
	let filter = {
		"limit": pageSize,
		"skip": (page - 1) * pageSize,
		"include": ["salesPerson"],
		"order": "name ASC",
		where: { "and": [{ "isDisabled": { neq: true } }] }
	};
	return dispatch(BaseGetClients(filter));
};

export const GetPreRegisterClients = (page = 1, pageSize = 10) => (dispatch) => {
	let filter = filterBuilder({ status: STATUS.PENDING }, page, pageSize);
	filter.where.and.push({ "isDisabled": { neq: true } }, { "status": { "inq": preRegistrationStatus } });
	return dispatch(BaseGetClients(filter));
};

export const GetApprovedClients = (page = 1, pageSize = 10) => (dispatch) => {
	let filter = filterBuilder(undefined, page, pageSize);
	filter.where.and.push({ "status": { "inq": approvedStatus } });
	return dispatch(BaseGetClients(filter, true));
};

export const BaseGetClients = (filter, getDeviceCount = false) => (dispatch, getState) => {
	let state = getState();
	let userId = state.auth.user.id;

	if (!(dispatch(ShowAllClients()))) {
		if (filter.where === undefined)
			filter.where = {};
		if (filter.where.and === undefined)
			filter.where.and = [];
		filter.where.and.push({ salesPersonId: userId });
	}
	let promises = [];
	promises.push(
		new Promise(function (resolve, reject) {
			axios.get(`/Clients/count?where=${encodeURIComponent(JSON.stringify(filter.where))}`)
				.then((response) => {
					dispatch({ type: FETCH_CLIENT_COUNT, count: response.data.count });
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		})
	);
	promises.push(
		new Promise(function (resolve, reject) {
			axios.get(`/Clients?filter=${encodeURIComponent(JSON.stringify(filter))}`)
				.then((response) => {
					let clients = response.data;
					if (getDeviceCount) {
						let deviceCountPromises = [];
						for (let index = 0; index < clients.length; index++) {
							const client = clients[index];
							const { clientId } = client;
							if (clientId) {
								deviceCountPromises.push(
									new Promise(function (resolve2, reject2) {
										GetClientDeviceCount(clientId)
											.then((counts) => {
												const { deviceCount, monthlyDevices, leasedDevices, routeDevices } = counts;
												clients[index].deviceCount = deviceCount;
												clients[index].monthlyDevices = monthlyDevices;
												clients[index].leasedDevices = leasedDevices;
												clients[index].routeDevices = routeDevices;
												resolve2();
											})
											.catch((error) => reject2(error));
									})
								);
							}
						}
						Promise.all(deviceCountPromises)
							.then(() => {
								dispatch({ type: FETCH_CLIENT, clients });
								resolve();
							})
							.catch((error) => reject(error));
					} else {
						dispatch({ type: FETCH_CLIENT, clients });
						resolve();
					}
				})
				.catch((error) => reject(error));
		})
	);
	return Promise.all(promises);
};

export const GetClientDeviceCount = (clientId) => {
	const filter = {
		where: { clientId, active: true },
		fields: ["id", "application"],
		include: ["deviceLease"]
	};
	return axios.get(`/Devices?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			const devices = response.data;
			const deviceStats = devices.reduce((acc, device) => {
				if (device.deviceLease) {
					acc.leasedDevices++;
				} else if (device.application === 'ROUTES') {
					acc.routeDevices++;
				} else {
					acc.monthlyDevices++;
				}
				return acc;
			}, {
				deviceCount: devices.length,
				monthlyDevices: 0,
				leasedDevices: 0,
				routeDevices: 0
			});  
			return deviceStats;
		});
};

export const ShowAllClients = () => (dispatch, getState) => {
	let state = getState();
	let userRoles = state.auth.roles;
	let allowedRoles = [ROLES.MASTER, ROLES.ADMINISTRATOR, ROLES.CUSTOMERSERVICE];
	return arrayElementContainedInArray(allowedRoles, userRoles);
};

export const SearchClients = (searchObject, page = 1, pageSize = 10) => (dispatch) => {
	let searchText = searchObject.searchText;
	let regx = "%" + searchText + "%";
	let extraFilter = searchObject.status;

	let filter = {
		"limit": pageSize,
		"skip": (page - 1) * pageSize,
		"include": ["salesPerson"],
		"order": "name ASC",
		where: { "and": [{ "isDisabled": { neq: true } }] }
	};
	if (searchText.length > 0) {
		filter.where.and.push({ "name": { "like": regx } });
		globalSearchText = searchText;
	}
	if (extraFilter != 0 && extraFilter != undefined) {
		filter.where.and.push({ "status": extraFilter });
		globalStatus = extraFilter;
	}
	globalCurrent = page;
	
	return dispatch(BaseGetClients(filter));
};

export const SearchPreRegisterClients = (searchObject, page = 1, pageSize = 10) => (dispatch) => {

	let filter = filterBuilder(searchObject, page, pageSize);
	filter.where.and.push({ "isDisabled": { neq: true } }, { "status": { "inq": preRegistrationStatus } });
	dispatch({type: SEARCH_PREREGISTRATION, searchPreregistration: searchObject.searchText})
	return dispatch(BaseGetClients(filter));
};

export const SearchApprovedClients = (searchObject, page = 1, pageSize = 10, order = 'ascend', orderBy = '') => (dispatch) => {
	let filter = filterBuilder(searchObject, page, pageSize, order, orderBy);
	filter.where.and.push({ "status": { "inq": approvedStatus } });
	dispatch({type: SEARCH_APPROVEDCLIENTS, searchApproved: searchObject.searchText})
	return dispatch(BaseGetClients(filter, true));
};

export const DeleteClient = (ids) => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;
	let options = {
		uids: ids
	};
	return axios.delete(`/Clients/removeClientsById`, options)
		.then(() => {
			dispatch({ type: DELETE_CLIENT_SUCCESS });
			notify(`${messages[DELETE_CLIENT_SUCCESS]}`);
			dispatch(SearchClients({ searchText: "", extraFilter: 0 }));
		});
};

export const AddClient = (client) => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;
	let clients = state.client.clients;
	let user = state.auth.user;
	let count = state.client.count;

	return axios.post(`/Clients/addClient`, { newClient: client })
		.then((response) => {
			let newClient = {
				...response.data,
				salesPerson: user
			};
			let clientsCopy = [...clients];
			if (clientsCopy.length >= 10)
				clientsCopy.pop();
			clientsCopy.unshift(newClient);
			dispatch({ type: FETCH_CLIENT, clients: clientsCopy });
			dispatch({ type: FETCH_CLIENT_COUNT, count: count + 1 });
			notify(`${messages[ADD_CLIENT_SUCCESS]}`);
		});
};

export const EditClient = (client, clientId) => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;
	let clients = state.client.clients;

	return axios.patch(`/Clients/${clientId}/editClient`, { editedClient: client })
		.then((response) => {
			let editedClient = response.data;
			let clientsCopy = [editedClient];
			for (let index = 0; index < clients.length; index++) {
				const cl = clients[index];
				if (cl.id !== clientId) clientsCopy.push(cl);
			}
			dispatch({ type: FETCH_CLIENT, clients: clientsCopy });
			notify(`${messages[EDIT_CLIENT_SUCCESS]}`);
		});
};

export const RejectClient = (clientId, activityMessage) => (dispatch, getState) => {
	let state = getState();
	let id = state.auth.user.id;
	let clients = state.client.clients;
	let data = {
		status: STATUS.REJECTED,
		message: activityMessage,
		date: new Date().toISOString(),
		userId: id,
		clientId: clientId
	};

	return axios.patch(`/Clients/${clientId}/statusUpdate`, { "info": data })
		.then(() => {
			let client = getObjectInArray("id", clientId, clients);
			client = { ...client };
			client.status = STATUS.REJECTED;
			dispatch({ type: FETCH_CLIENT, clients: substituteObjectInArrayByValue("id", clientId, clients, client) });
		});
};

export const GetClientsList = (searchText) => (dispatch) => {
	let filter = {
		"where": {
			"and": [
				{
					"name": {
						"like": "%" + searchText + "%"
					}
				},
				{
					"status": 2
				},
				{
					"isDisabled": false
				}
			]

		}
	};

	return axios.get(`/Clients?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			dispatch({ type: GET_CLIENTS, clients: response.data });
		});
};

export const GetAllClients = () => async (dispatch) => {
	let filter = {
		"where": {
			"and": [
				{ "status": 2 },
				{ "isDisabled": false }
			]
		},
	};
	try{
		const response = await axios.get(`/Clients?filter=${encodeURIComponent(JSON.stringify(filter))}`);
		dispatch({ type: GET_CLIENTS, clients: response.data });
	}catch(error){
		return error;
	}
};

export const CleanClients = () => (dispatch) => {
	dispatch({ type: CLEAN_CLIENTS });
};

export const GetEmails = (id) => (dispatch) => {
	return axios.get(`/Clients/${id}/clientEmails`)
		.then((response) => {
			dispatch({ type: CLIENT_INFO_SAVE_EMAILS, emails: response.data });
			return response.data;
		});
};

export const UpdateNextInvoiceMonth = (id, nextInvoiceMonth) => () => {
	const m = moment(nextInvoiceMonth).toISOString();
	return axios.patch(`/Clients/${id}`, { nextInvoiceMonth: m });
}

function filterBuilder(searchObject = {}, page = 1, pageSize = 10, order = 'ascend', orderBy = '') {
	let searchText = searchObject.searchText;
	let regx = "%" + searchText + "%";
	let extraFilter = searchObject.status;
	let disabled = searchObject.disabled;
	if(searchObject.disabled == false){
		orderBy = 'nextInvoiceMonth'; 
		order = 'DESC';
	} 
	let column = orderBy != '' ? orderBy : 'name';
	let filter = {
		limit: pageSize,
		skip: (page - 1) * pageSize,
		include: ["salesPerson"],
		order: `${column} ${order === 'ascend' ? 'ASC' : 'DESC'}`,
		where: { and: [] }
	};
	if (searchText !== undefined && searchText.length > 0) {
		filter.where.and.push({ or: [{ name: { like: regx } }, { representative: { like: regx } }] });
		globalSearchText = searchText;
	} else globalSearchText = "";
	if (extraFilter !== undefined && extraFilter !== 0) {
		filter.where.and.push({ "status": extraFilter });
		globalStatus = extraFilter;
	} else globalStatus = 0;
	if (disabled != undefined && disabled !== 0) {
		filter.where.and.push({ "isDisabled": disabled });
		globalDisabled = disabled;
	} else globalDisabled = 0;
	globalCurrent = page;
	return filter;
}

export const GetClientInvoiceRulesInfo = (id) => {
	const where = { id };
	const fields = ["id", "clientId", "client", "name", "rules"];
	const include = [
		{
			relation: "client",
			scope: {
				fields: ["id", "devices"],
				include: [
					{
						relation: "devices",
						scope: {
							fields: ["id", "pin", "imei", "description", "icon", "deviceStatusId"],
							include: ["icon"]
						}
					}
				]
			}
		},
		{
			relation: "rules",
			scope: {
				include: ["productRule", "deviceRules"],
				order: "priority ASC",
			}
		}
	]
	let filter = { where, fields, include };
	return axios.get(`/Clients?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			if (response.length === 0) return undefined;
			const client = response.data[0];
			const name = client.name;
			const devices = client.client.devices;
			const rules = client.rules.map(rule => {
				const { id, alias, type, priority, active, productRule, deviceRules, recurrence, nextInvoiceMonth } = rule;
				const productId = productRule ? productRule.productId : undefined;
				const devices = deviceRules ? deviceRules.map(device => device.deviceId) : [];
				const lastInvoiced = moment(nextInvoiceMonth).set({ date: 15, hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(recurrence, "M");
				return { id, alias, type, priority, active, productId, devices, recurrence, lastInvoiced };
			})
			return { name, devices, rules };
		});
};

export const SaveClientRules = (id, rules = []) => (dispatch, getState) => {
	const state = getState();
	const userId = state.auth.user.id;
	return axios({
		url: `/Clients/${id}/saveRules`,
		method: 'POST',
		params: { userId, rules }
	});
};

export const GetClientLeasedDevices = (id) => {
	const where = { id };
	const fields = ["id", "clientId", "client"];
	const include = [
		{
			relation: "client",
			scope: {
				fields: ["id", "devices"],
				include: [
					{
						relation: "devices",
						scope: {
							where: { active: true },
							fields: ["id", "pin", "description", "icon", "deviceLease", "application"],
							include: ["icon", "deviceLease"]
						}
					}
				]
			}
		}
	];
	let filter = { where, fields, include };
	return axios.get(`/Clients?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			if (response.length === 0) return undefined;
			return response.data[0];
		});
};

export const SwitchDeviceLease = (deviceId, shouldBeLeased) => {
	return axios({
		url: `/DeviceLeases/${deviceId}/checkAndSwitchLease`,
		method: 'POST',
		params: { shouldBeLeased }
	});
};

export const GetClientAliases = (clientId = -1) => {
	const filter = {
		fields: ['id', 'alias'],
		where: { alias: { neq: null } }
	};
	return axios.get(`/Clients/${clientId}/rules?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then(response => response.data.map(rule => rule.alias));
};

export const GetClientTiers = async () => {
	const response = await axios.get(`/ClientTiers`);
	return response.data;
};

export const SaveClientTiers = (tiers) => {
	return axios({
		url: `/ClientTiers/save`,
		method: 'POST',
		responseType: 'json',
		params: { tiers }
	})
		.then(response => response.data);
};

export const GetClientOptimusAccounts = (clientId) => {
	return axios.get(`/Clients/${clientId}/getOptimusAccounts`)
		.then(response => response.data);
};

export const GetClientProgrammedDeactivationData = (clientId) => {
	return axios.get(`/ClientDisableDates/${clientId}/getClientData`)
		.then(response => response.data);
};

export const SaveClientProgrammedDeactivationDate = (clientId, date) => {
	const isoMoment = moment(date).toISOString();
	return axios({
		url: `/ClientDisableDates/${clientId}/saveDate`,
		method: 'POST',
		responseType: 'json',
		params: { date: isoMoment }
	})
		.then(response => response.data);
};

export const SaveClientsProgrammedDeactivationDate = async ({clientIds, date}) => {
	const isoMoment = moment(date).toISOString();
	const response = await axios.post('/ClientDisableDates/clientsProgrammedDeactivation', { 
		clientIds,
		date: isoMoment 
	});
	return response.data;
};

export const ClearClientProgrammedDeactivation = (clientId) => {
	return axios.post(`/ClientDisableDates/${clientId}/clearClientData`)
		.then(response => response.data);
};

export const GetClientProgrammedDeactivationLogsCount = (clientId) => {
	const filter = { clientId };
	return axios.get(`/ClientDisableLogs/count?where=${encodeURIComponent(JSON.stringify(filter))}`)
		.then(response => response.data.count);
};

export const GetClientProgrammedDeactivationLogs = (clientId, page = 1, pageSize = 5) => {
	return axios({
		url: `/ClientDisableLogs/${clientId}/getClientData`,
		method: 'GET',
		responseType: 'json',
		params: { page, pageSize }
	})
		.then(response => response.data);
};

export const GetClientProgrammedDeactivationsCount = () => {
	return axios.get(`/ClientDisableDates/count`)
		.then(response => response.data.count);
};

export const GetClientProgrammedDeactivations = (page = 1, pageSize = 10) => {
	const filter = {
		limit: pageSize,
		skip: (page - 1) * pageSize,
		include: [
			{
				relation: 'client',
				scope: {
					fields: ['id'],
					include: [
						{
							relation: 'ironhideClient',
							scope: {
								fields: ['id', 'name']
							},
						},
					],
				},
			},
		],
		order: 'date ASC'
	};
	return axios.get(`/ClientDisableDates?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then(response => response.data);
};

export const CheckIfClientHasAssignedDeactivationDate = (clientId) => {
	return axios.get(`/ClientDisableDates/${clientId}/clientHasAssignedDisable`)
		.then(response => response.data);
};

export const LoadClientRecurrentProductsData = (clientId) => {
	return axios.get(`/Clients/${clientId}/getRecurrentProductsData`)
		.then(response => response.data);
};

export const SaveClientRecurrentProducts = (clientId, recurrentProducts) => {
	return axios.post(`/ClientRecurrentProducts/${clientId}/save`, { recurrentProducts })
		.then(response => response.data);
};
