import React from 'react';
import Icon from '@ant-design/icons';

const MaintenanceSvg = () => (
	<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
		width="1.5em" height="1.5em" viewBox="0 0 24.000000 24.000000"
		preserveAspectRatio="xMidYMid meet">

		<g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
			fill="#000000" stroke="none">
			<path d="M34 198 c-4 -7 8 -23 30 -41 l37 -30 -32 -34 c-31 -31 -32 -34 -16
-50 16 -16 18 -15 45 10 l28 27 27 -27 c25 -26 28 -26 44 -10 16 16 15 18 -11
46 l-28 29 28 14 c33 15 46 51 17 46 -13 -2 -17 2 -15 15 5 27 -30 18 -44 -11
-22 -44 -30 -45 -59 -8 -29 38 -39 43 -51 24z m151 -47 c-5 -2 -34 -26 -62
-54 -69 -67 -82 -52 -15 16 29 29 52 58 52 65 0 7 7 5 18 -5 10 -10 13 -20 7
-22z m-10 -71 c10 -11 15 -23 12 -27 -8 -7 -47 25 -47 39 0 14 16 9 35 -12z"/>
		</g>
	</svg>
);

const MaintenanceIconBuilder = props => <Icon component={MaintenanceSvg} {...props} />;

class MaintenanceIcon extends React.Component {
	render() {
		return <MaintenanceIconBuilder />;
	}
}

export default MaintenanceIcon;