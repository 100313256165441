import React from 'react';
import PropTypes from 'prop-types';
import {getRegistrationMessages} from '../../../constants/messages';
import { Radio } from 'antd';
import {FormattedMessage} from 'react-intl';


let regMessages = getRegistrationMessages();

const messages = {
	...regMessages
};

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;


const ClientRadioButton = ({onFilter}) => {

	const onChange = (e) => {
		onFilter({disabled: e.target.value});
	};

  return (
	<div>
		<RadioGroup onChange={onChange} defaultValue={0}>
				<RadioButton value={0}><FormattedMessage {...messages.all} /></RadioButton>
				<RadioButton value={false}><FormattedMessage {...messages.enabled} /></RadioButton>
				<RadioButton value={true}><FormattedMessage {...messages.disabled} /></RadioButton>
		</RadioGroup>
	</div>
  )
}

ClientRadioButton.propTypes = {
	onFilter: PropTypes.func,
};

export default ClientRadioButton
