import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker } from 'antd';
import { getPaymentMessages } from '../../constants/messages';
import moment from 'moment';

const FormItem = Form.Item;
const dateFormat = "DD-MM-YYYY";

let paymentMessages = getPaymentMessages();

const messages = {
	...paymentMessages
};

class PaymentDatePicker extends React.Component {

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	formatPaidDateEventValue = (date) => {
		if (!date) return null;
		const momentAtTwelve = moment(date).set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
		return momentAtTwelve;
	}

	render() {
		const { props, getIntl, formatPaidDateEventValue } = this;
		const { getFieldDecorator } = props.form;
		return (
			<FormItem label={getIntl("paymentDate")} >
				{
					getFieldDecorator(`paymentDate`,
						{
							getValueFromEvent: formatPaidDateEventValue,
							rules: [{
								required: true,
								message: getIntl("paymentDateRequiredError"),
							}]
						}
					)(
						<DatePicker
							className="job-full-component"
							showTime={false}
							format={dateFormat}
							allowClear={false}
							showToday={false}
						/>
					)
				}
			</FormItem>
		);
	}
}

PaymentDatePicker.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired
};

export default injectIntl(PaymentDatePicker);