import {
	CLEAN_REDUCER,
	CLEAR_SESSION,
	GET_QUOTATIONS,
	FETCH_QUOTATION_COUNT,
	ADD_QUOTATION_SUCCESS,
	GET_QUOTATION,
	SET_QUOTATION_TO_ADD,
	DENY_QUOTATION_SUCCESS,
	QUOTATION_INVOICED_SUCCESS,
	QUOTATION_PROFORMA_SUCCESS,
	APPROVE_QUOTATION_SUCCESS,
	QUOTATION_RENEWED,
	UPDATE_SEARCH,
	UPDATE_PAGE
} from './actionTypes';

const initState = {
	quotations: [],
	quotation: {},
	count: 0,
	search: "",
	page: 1
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case UPDATE_SEARCH:
			return {
				...state,
				search: action.search
			};
		case UPDATE_PAGE:
			return {
				...state,
				page: action.page
			}
		case GET_QUOTATIONS:
			return {
				...state,
				"quotations": action.quotations
			};
		case FETCH_QUOTATION_COUNT:
			return {
				...state,
				count: action.count
			};
		case ADD_QUOTATION_SUCCESS:
			return {
				...state,
				"quotation": action.quotation
			};
		case GET_QUOTATION:
			return {
				...state,
				"quotation": action.quotation
			};
		case SET_QUOTATION_TO_ADD:
			return {
				...state,
				"quotation": action.quotation
			};
		case DENY_QUOTATION_SUCCESS:
			return state;
		case APPROVE_QUOTATION_SUCCESS:
			return state;
		case QUOTATION_INVOICED_SUCCESS:
			return state;
		case QUOTATION_PROFORMA_SUCCESS:
			return state;
		case QUOTATION_RENEWED:
			return state;
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;