import {
    CLEAN_REDUCER,
	CLEAR_SESSION,
	GET_FISCAL_DATA,
	ADD_FISCAL_DATA,
	EDIT_FISCAL_DATA
} from './actionTypes';

const initState = {
	fiscalData: { 
		"invoice": {},
		"debit": {},
		"credit": {}
	}
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case GET_FISCAL_DATA:
			return {
				...state,
				"fiscalData": action.fiscalData
			};
		case ADD_FISCAL_DATA:
			return state;
		case EDIT_FISCAL_DATA:
			return state;
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;