import {
    CLEAN_REDUCER,
	CLEAR_SESSION,
	GET_CLIENT_INVOICES,
	SET_CLIENT_AVAILABLE_DAYS,
	GET_ACCOUNTS,
	GET_TAX_TOTAL,
	SET_SEARCH_REPORT_GENERAL
} from './actionTypes';
var cloneDeepWith = require('lodash.clonedeepwith');

const initState = {
	clientInvoices: [],
	accountsEmails: [],
	statusFilter: 5,
	taxTotal: {},
	searchReportGeneral: ""
};


const reducer = (state = initState, action) => {
	let clientsCopy = {};
	switch (action.type) {
		case GET_CLIENT_INVOICES:
			return {
				...state,
				clientInvoices: action.clientInvoices
			};
		case SET_CLIENT_AVAILABLE_DAYS:
			clientsCopy = cloneDeepWith(state.clientInvoices);
			clientsCopy[clientsCopy.findIndex( (c) => c.id === action.clientId)].daysAvailable = action.settedDays; 
			return {
				...state,
				clientInvoices: clientsCopy
			};
		case GET_ACCOUNTS:
			return {
				...state,
				accountsEmails: action.accountsEmails
			}
		case GET_TAX_TOTAL:
			return {
				...state,
				taxTotal: action.taxTotal
			};
		case SET_SEARCH_REPORT_GENERAL: 
			return {
				...state,
				searchReportGeneral: action.searchReportGeneral
			};
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;