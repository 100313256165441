import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Statistic, Row, Col, Card } from 'antd';
import { getIncomeMessages } from '../../../constants/messages';
import { ArrowDownOutlined, ArrowUpOutlined, SwapOutlined } from '@ant-design/icons';
const moment = require('moment');
const monthDateFormat = "MM-YYYY";

let incomeMessages = getIncomeMessages();

const messages = {
    ...incomeMessages
};

const negativeColor = '#cf1322';
const neutralColor = 'grey';
const positiveColor = '#3f8600';

class StatisticComparison extends React.Component {

	getIntl = (str) => {
		return this.props.intl.formatMessage({...messages[str]});
    }

    renderInitialBalanceMonth = () => {
        const { latestBalanceDay, selectedBalanceDay } = this.props;
        const latestM = moment(latestBalanceDay);
        const selectedM = moment(selectedBalanceDay);
        if (latestM.isValid() && selectedM.isValid() && !latestM.isSame(selectedM, 'M')) return ` (${latestM.format(monthDateFormat)})`;
        else return "";
    }
    
    renderInitialValue = () => {
        const { props, getIntl, renderInitialBalanceMonth } = this;
        const { initialValue } = props;
        return <Statistic
            title={`${getIntl("initialBalance")}${renderInitialBalanceMonth()}`}
            value={initialValue}
            precision={2}
        />;
    }
    
    renderFinalValue = () => {
        const { props, getIntl } = this;
        const { initialValue, finalValue } = props;
        const equal = initialValue === finalValue;
        const positive = finalValue > initialValue;
        const color = equal ? neutralColor : positive ? positiveColor : negativeColor;
        const prefix = equal ? <SwapOutlined rotate={90}/> : positive ? <ArrowUpOutlined /> : <ArrowDownOutlined />;
        return <Statistic
            title={getIntl("finalBalance")}
            value={finalValue}
            precision={2}
            valueStyle={{ color }}
            prefix={prefix}
        />;
    }

    fullRender = () => {
        const { renderInitialValue, renderFinalValue } = this;
        return (
            <Row gutter={16}>
                <Col span={12}><Card bordered={false}>{renderInitialValue()}</Card></Col>
                <Col span={12}><Card bordered={false}>{renderFinalValue()}</Card></Col>
            </Row>
        );
    }

	render() {
		return this.fullRender();
    }
    
}

StatisticComparison.defaultProps = {
    initialValue: 0,
    finalValue: 0
};

StatisticComparison.propTypes = {
    intl: PropTypes.object.isRequired,
    initialValue: PropTypes.number.isRequired,
    finalValue: PropTypes.number.isRequired,
    latestBalanceDay: PropTypes.oneOfType([
		PropTypes.object,
        PropTypes.string
    ]),
    selectedBalanceDay: PropTypes.oneOfType([
		PropTypes.object,
        PropTypes.string
    ])
};

export default injectIntl(StatisticComparison);