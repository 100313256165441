import PropTypes from 'prop-types';
import { DatePicker, Row, Col, Table, Tooltip, message, Spin, Button } from 'antd';
import { connect } from "react-redux";
import {
    injectIntl,
    FormattedMessage
} from 'react-intl';
import { GetTaxTotal } from '../actions';
import { getReportMessages, getInvoicingMessages, getLanguageIntl, getClipboardMessages } from '../../../constants/messages';
import { roundN } from '../../../utilities/util';
import React from 'react';
import copy from 'copy-to-clipboard';

let moment = require('moment');

let reportMessages = getReportMessages(),
    languageMessages = getLanguageIntl(),
    clipboardMessages = getClipboardMessages(),
    invoicesMessages = getInvoicingMessages();

const messages = {
    ...reportMessages,
    ...languageMessages,
    ...clipboardMessages,
    ...invoicesMessages
};

const {MonthPicker} = DatePicker;

class TaxReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            month: {},
            invoices: [],
            totalTaxes: 0,
            totalRetention: 0,
            date: {},
            buttonDisabled: true
        };
    }

    copyToClipBoard = () => {
        if(copy(`${this.props.intl.formatMessage({...messages.totalTax})} L ${roundN(this.state.totalTaxes, 2)}` + 
                `\n${this.props.intl.formatMessage({...messages.totalRetention})} L -${roundN(this.state.totalRetention, 2)}` + 
                `\n${this.props.intl.formatMessage({...messages.toPay})} L ${roundN(this.state.totalTaxes - this.state.totalRetention, 2)}`)){
			message.success(this.props.intl.formatMessage({...messages.copySuccess}));
		}else{
			message.error(this.props.intl.formatMessage({...messages.copyFail}));
		}
	};

    onChangeDate = (date) => {
        this.setState({ date, buttonDisabled: false }); 
    }

    onSend = () => {
        const { intl, fetchData, taxTotal } = this.props;
        const { date } = this.state;
        if (!date) return;
        this.setState({ isLoading: true });
        fetchData(moment(date).toDate())
        .then(() => {
            this.setState({
                invoices: [...taxTotal.invoices],
                totalTaxes: taxTotal.totalTaxes,
                totalRetention: taxTotal.totalRetention,
                month: date,
                isLoading: false
            });
        })
        .catch(() => {
            message.error(intl.formatMessage({...messages.generateReportError}));
            this.setState({ isLoading: false });
        });
    }

    render() {
        let dataSource = this.state.invoices.map((object) => {
			return {
				...object,
				'key': this.state.invoices.indexOf(object)
			};
		});
		let columns = [
			{
				title: this.props.intl.formatMessage({ ...messages.InvoiceNumber }),
				dataIndex: 'documentNumber',
				key: 'documentNumber',
			}, {
				title: this.props.intl.formatMessage({ ...messages.Total }),
				dataIndex: 'total',
                key: 'total',
                className: 'column-money',
                render: (total) => {
					return (
                            `L ${+(Math.round(total + "e+2")  + "e-2")}`
						);
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Subtotal }),
				dataIndex: 'subtotal',
                key: 'subtotal',
                className: 'column-money',
                render: (subtotal) => {
					return (
                            `L ${+(Math.round(subtotal + "e+2")  + "e-2")}`
						);
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Isv }),
				dataIndex: 'isv',
                key: 'isv',
                className: 'column-money',
                render: (isv, tax) => {
					return (
                            `L ${+(Math.round(+(Math.round(tax.total + "e+2")  + "e-2") - (+(Math.round(tax.subtotal + "e+2")  + "e-2")) + "e+2")  + "e-2")}`
						);
				}
			}
		];
        return (
            <Spin spinning={this.state.isLoading}>
                <div>
                    <div className="generalReport">
                        <p><FormattedMessage {...messages.month} />:</p>
                        <MonthPicker onChange={this.onChangeDate} allowClear={false} />
                        <Button className="technician-report-range-button" type="primary" 
                            onClick={this.onSend} disabled={this.state.buttonDisabled}><FormattedMessage {...messages.generateReport} /></Button>
                    </div>
                    <Table 
                        dataSource={dataSource}
                        columns={columns}
                        className="generalReport"
                        footer={() => 
                            <div>
                                <Row gutter={16} type="flex" align="middle">
                                    <Col span={20} style={{'textAlign': 'right'}}>
                                        <Tooltip title={this.props.intl.formatMessage({...messages.copyToClipboard})} placement={'topRight'}>
                                            <p onClick={this.copyToClipBoard}><FormattedMessage {...messages.totalTax} /></p>
                                        </Tooltip>
                                    </Col>
                                    <Col span={4} style={{'textAlign': 'right'}}>
                                        <Tooltip title={this.props.intl.formatMessage({...messages.copyToClipboard})} placement={'topRight'}>
                                            <p onClick={this.copyToClipBoard}>{`L ${roundN(this.state.totalTaxes, 2)}`}</p>
                                        </Tooltip>
                                    </Col>
                                </Row>
                                <Row gutter={16} type="flex" align="middle">
                                    <Col span={20} style={{'textAlign': 'right'}}>
                                        <Tooltip title={this.props.intl.formatMessage({...messages.copyToClipboard})} placement={'topRight'}>
                                            <p onClick={this.copyToClipBoard}><FormattedMessage {...messages.totalRetention} /></p>
                                        </Tooltip>
                                    </Col>
                                    <Col span={4} style={{'textAlign': 'right'}}>
                                        <Tooltip title={this.props.intl.formatMessage({...messages.copyToClipboard})} placement={'topRight'}>
                                            <p onClick={this.copyToClipBoard}>{`L -${roundN(this.state.totalRetention, 2)}`}</p>
                                        </Tooltip>
                                    </Col>
                                </Row>
                                <Row gutter={16} type="flex" align="middle">
                                    <Col span={20} style={{'textAlign': 'right'}}>
                                        <Tooltip title={this.props.intl.formatMessage({...messages.copyToClipboard})} placement={'topRight'}>
                                            <p onClick={this.copyToClipBoard}><FormattedMessage {...messages.toPay} /></p>
                                        </Tooltip>
                                    </Col>
                                    <Col span={4} style={{'textAlign': 'right'}}>
                                        <Tooltip title={this.props.intl.formatMessage({...messages.copyToClipboard})} placement={'topRight'}>
                                            <p onClick={this.copyToClipBoard}>{`L ${roundN(this.state.totalTaxes - this.state.totalRetention, 2)}`}</p>
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </div>
                        }
                    />
                </div>
            </Spin>
        );
    }
}

TaxReport.propTypes = {
    intl: PropTypes.object.isRequired,
    taxTotal: PropTypes.object,
    fetchData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    taxTotal: state.report.taxTotal,
});

const mapDispatchToProps = (dispatch) => ({
    fetchData: (date) => dispatch(GetTaxTotal(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TaxReport));