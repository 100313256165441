import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import CommonTable from '../../components/CommonTable';
import { GetProducts, SearchProducts } from './actions';
import AddProductButton from './AddProductButton';
import EditProductForm from './EditProductForm';
import { message, Typography } from 'antd'
import { roundN } from '../../utilities/util';
import { getProductMessages, getactionMessages, getPopConfirm } from '../../constants/messages';

let productMessages = getProductMessages(),
	popMessages = getPopConfirm(),
	actionMessages = getactionMessages();

const messages = {
	...productMessages,
	...actionMessages,
	...popMessages
};

const Products = () => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const [showEditModal, setShowEditModal] = useState(false);
	const [productToEdit, setProductToEdit] = useState({});
	const products = useSelector((state) => state.product.products);
	const searchText = useSelector((state)=>state.product.search)

	useEffect(() => {
		(async () => {
            try {
				if(searchText === "" || searchText === undefined){
                   await dispatch(GetProducts())
				}
            } catch (error) {
				message.error(intl.formatMessage({ ...messages.errorGetProducts }));
            } finally {
                setIsLoading(false);
            }
        })();
	}, []);
	
	const search = async (search) => {
		try {
			setIsLoading(true);
			await dispatch(SearchProducts(search.searchText));
		} catch (error) {
			message.error(intl.formatMessage({ ...messages.errorGetProducts }));
		} finally {
			setIsLoading(false);
		}
	}

	const openEditModal = (product) => {
		setProductToEdit(product);
		setShowEditModal(true);
	}

	const tableActions = (record) => {
        return (
            <span>
                <Typography.Link
                    className="editable-add-btn"
                    onClick={() => openEditModal(record)}
                >
                    <span className="icon-pencil" />
                    <FormattedMessage {...messages.edit} />
                </Typography.Link>
            </span>
        );
    };

	const columns = [
		{
			title: intl.formatMessage({ ...messages.Code }),
			dataIndex: 'code',
			key: 'code',
			width: 150
		}, {
			title: intl.formatMessage({ ...messages.Description }),
			dataIndex: 'description',
			key: 'description',
			width: 250
		}, {
			title: intl.formatMessage({ ...messages.Price }),
			dataIndex: 'price',
			key: 'price',
			className: 'column-money',
			width: 250,
			render: (price) => {
				return `$ ${roundN(price, 2)}`;
			}
		}, {
			title: intl.formatMessage({ ...messages.MinPrice }),
			dataIndex: 'minPrice',
			key: 'minPrice',
			className: 'column-money',
			width: 250,
			render: (minPrice) => {
				return `$ ${roundN(minPrice, 2)}`;
			}
		}, {
			title: intl.formatMessage({ ...messages.MaxQuantity }),
			dataIndex: 'maxQuantity',
			key: 'maxQuantity',
			width: 250,
			render: (maxQuantity) => {
				return maxQuantity === 0 ? intl.formatMessage({ ...messages.Unlimited }) : `${maxQuantity}`
			}
		}, {
			title: intl.formatMessage({ ...messages.IsAddon }),
			dataIndex: 'isAddon',
			key: 'isAddon',
			width: 250,
			render: (isAddon) => {
				return isAddon ? intl.formatMessage({ ...messages.yes }) : intl.formatMessage({ ...messages.no });
			}
		}, {
			title: intl.formatMessage({ ...messages.Actions }),
			dataIndex: 'id',
			render: (id, record) => tableActions(record),
		}
	];

	return (
		<div className="account view">
			<CommonTable
				columns={columns}
				dataSource={products}
				loading={isLoading}
				Add={<AddProductButton />}
				recordCount={products.length}
				getRecords={() => { }}
				search={search}
				searchText={searchText}
				getCheckboxProps={(() => ({ disabled: false }))}
				hideCheckboxes
			/>
			<EditProductForm
				showModal={showEditModal}
				handleCancel={() => setShowEditModal(false)}
				productToEdit={productToEdit}
			/>
		</div>
	)
}

export default Products;
