import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Input, Spin, Pagination, Button, Tooltip } from 'antd';
import { injectIntl } from 'react-intl';
import { getactionMessages, getFields, getErrorMessages, getPopConfirm } from '../constants/messages';
import { objectIsEmpty } from '../utilities/util';
import './CommonTable.css'
import { FilterOutlined } from '@ant-design/icons';

const Search = Input.Search;

let errorMessages = getErrorMessages(),
	AccountMessages = getactionMessages(),
	PopConfirmMessages = getPopConfirm(),
	fieldMessages = getFields();

const messages = {
	...AccountMessages,
	...fieldMessages,
	...errorMessages,
	...PopConfirmMessages
};

class MobileCommonTable extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			current: 1,
			searchText: "",
			extraFilter: {},
		};

		props.getRecords();
	}

	searchTable = (value, extraFilter) => {
		const {
			search
		} = this.props;
		let searchObject = {
			...extraFilter,
			searchText: value
		};
		this.setState({
			searchText: value,
			extraFilter: extraFilter,
			current: 1
		});
		search(searchObject);
	};

	pagingOnChange = (page, pageSize) => {
		const {
			getRecords,
			search
		} = this.props;

		if (this.state.searchText.length > 0 || !objectIsEmpty(this.state.extraFilter)) {
			let searchObject = {
				...this.state.extraFilter,
				searchText: this.state.searchText
			};
			search(searchObject, page, pageSize);
		} else {
			getRecords(page, pageSize);
		}
		this.setState({
			current: page
		});
	};

	MyExtraFilterSearch = (value) => {
		let { mutuallyExclusiveFilter } = this.props;
		if (mutuallyExclusiveFilter !== undefined && mutuallyExclusiveFilter) this.searchTable("", value);
		else this.searchTable(this.state.searchText, value);
	}

	MySearchBarSearch = (value) => {
		let { mutuallyExclusiveFilter } = this.props;
		if (mutuallyExclusiveFilter !== undefined && mutuallyExclusiveFilter) this.searchTable(value, {});
		else this.searchTable(value, this.state.extraFilter);
	}

	renderExtraFilter = () => {
		let { filterComponent } = this.props;
		let clonedFilterComponentWithMoreProps = null;
		if (this.props.filterComponent)
			clonedFilterComponentWithMoreProps = React.cloneElement(
				filterComponent,
				{ onFilter: (value) => this.MyExtraFilterSearch(value) }
			);
		return (
			<div className="extraFilter">
				{clonedFilterComponentWithMoreProps}
			</div>
		);
	}

	renderSearchBar = () => {
		let { searchBarAddonAfterFunction, searchBarAddonAfterTooltip } = this.props;
		let searchComponent = (<Search
			placeholder={this.props.intl.formatMessage({ ...messages.searchPlaceholder })}
			onSearch={value => this.MySearchBarSearch(value)}
		/>);
		if ('searchBarAddonAfterFunction' in this.props) {
			let button = <Button icon={<FilterOutlined />} size="small" onClick={searchBarAddonAfterFunction} />;
			if ('searchBarAddonAfterTooltip' in this.props) button = (<Tooltip title={searchBarAddonAfterTooltip}>{button}</Tooltip>);
			searchComponent = React.cloneElement(searchComponent, { addonAfter: button });
		}
		return (<div className="search-addon-component">{searchComponent}</div>);
	}

	renderExtraComponents = () => {
		let { extraComponents } = this.props;
		if (extraComponents && Array.isArray(extraComponents))
			return extraComponents.map((component, index) => <Col key={index} >{component}</Col>);
	}

	render() {
		const { dataSource, card, disableSearchBar, Add, loading, recordCount } = this.props;
		const list = dataSource.map(card);
		const showAdd = Add && <Col>{Add}</Col>;
		const showSearchBar = !disableSearchBar && <Row> <Col span={24}>{this.renderSearchBar()}</Col></Row>;

		return (
			<div className="MobileTable">
				<Row className="toolbar">
					<Col flex='auto'>
						<Row className="actions">
							{showAdd}
							{this.renderExtraComponents()}
						</Row>
					</Col>
					<Col flex='auto'>
						<Row wrap={false} justify="end">
							<Col>
								{this.renderExtraFilter()}
							</Col>
						</Row>
						{showSearchBar}
					</Col>
				</Row>
				<Spin spinning={loading}>
					{list}
				</Spin>
				<Row className="control" type="flex" justify="space-around" align="middle">
					<Col>
						<Pagination
							defaultPageSize={10}
							current={this.state.current}
							onChange={this.pagingOnChange}
							total={recordCount}
						/>
					</Col>
				</Row>
			</div>
		);
	}
}

MobileCommonTable.defaultProps = {
	filterComponent: null,
};

MobileCommonTable.propTypes = {
	dataSource: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
	intl: PropTypes.object.isRequired,
	Add: PropTypes.object,
	getRecords: PropTypes.func.isRequired,
	search: PropTypes.func.isRequired,
	recordCount: PropTypes.number.isRequired,
	card: PropTypes.func.isRequired,
	filterComponent: PropTypes.node,
	extraComponents: PropTypes.array,
	searchBarAddonAfterFunction: PropTypes.func,
	searchBarAddonAfterTooltip: PropTypes.string,
	mutuallyExclusiveFilter: PropTypes.bool,
	disableSearchBar: PropTypes.bool,
};

export default injectIntl(MobileCommonTable);