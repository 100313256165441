import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
const { Text } = Typography;

class Embolden extends React.Component  {
    
	render(){
        const { props } = this;
        const { header, value, row, type, strong } = props;
        let valueRender = <Text>{' - '}</Text>;
        if ('value' in props && value !== undefined && value !== null) {
            if (typeof value === "string") {
                if (/\S/.test(value)) valueRender = <Text type={type} strong={strong}>{value}</Text>;
            }
            else valueRender = value;
        }
        const info = <span><Text strong >{`${header}: `}</Text>{valueRender}</span>;
        if (row) return <div>{info}</div>
        return info;
	}
}

Embolden.defaultProps = {
	row: false
};

Embolden.propTypes = {
    header: PropTypes.string.isRequired,
    value: PropTypes.any,
    row: PropTypes.bool,
    type: PropTypes.string,
    strong: PropTypes.bool
};

export default Embolden;