import React from 'react';
import PropTypes from 'prop-types';
import { message, Divider } from 'antd';
import { injectIntl } from 'react-intl';
import { getClientMessages } from '../../../../constants/messages';
import { LoadClientRecurrentProductsData } from '../../actions';
import ProductEntry from './Entry';
import AddButton from '../../AddOrUpdate/DynamicComponents/AddButton';

let clientMessages = getClientMessages();

const messages = {
	...clientMessages
};

class ClientRecurrentProductsForm extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			newUuid: 1,
			recurrentProducts: [],
			nonServiceProducts: [],
			clientRules: [],
		};
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	componentDidMount() {
		const { props, getIntl } = this;
		const { setLoading, clientId } = props;
		setLoading(true);
		LoadClientRecurrentProductsData(clientId)
			.then(({ recurrentProducts, nonServiceProducts, clientRules }) => {
				setLoading(false);
				this.setState({ recurrentProducts: recurrentProducts.map(product => ({ ...product, uuid: `upd${product.id}` })), nonServiceProducts, clientRules });
			})
			.catch(() => {
				setLoading(false);
				message.error(getIntl("clientRecurrentProductsLoadError"));
			});
	}

	getNewUniqueIdentifier = () => {
		const uuid = this.state.newUuid;
		this.setState({ newUuid: uuid + 1 });
		return uuid;
	}

	addProduct = () => {
		const { state, getNewUniqueIdentifier } = this;
		const { recurrentProducts } = state;
		const dataCopy = Array.from(recurrentProducts);
		dataCopy.push({ uuid: `new${getNewUniqueIdentifier()}`, setQuantity: -1 });
		this.setState({ recurrentProducts: dataCopy });
	}

	deleteProduct = (deleteThisUuid) => {
		const { recurrentProducts } = this.state;
		const dataCopy = [];
		recurrentProducts.forEach(product => {
			const { uuid } = product;
			const fieldSubstring = uuid.substring(0, 3);
			if (uuid === deleteThisUuid && fieldSubstring == "upd") {
				const entryId = parseInt(uuid.substr(3));
				dataCopy.push({ ...product, uuid: `del${entryId}` });
			}
			else if (uuid !== deleteThisUuid) dataCopy.push(product);
		});
		this.setState({ recurrentProducts: dataCopy });
	}

	renderList = () => {
		const { state, props, deleteProduct } = this;
		const { recurrentProducts, nonServiceProducts, clientRules } = state;
		const { form } = props;
		const productList = [];
		recurrentProducts.forEach((product, index) => {
			const { uuid } = product;
			const fieldSubstring = uuid.substring(0, 3);
			productList.push(<ProductEntry key={uuid} form={form} product={product} removeFunction={() => deleteProduct(uuid)} nonServiceProducts={nonServiceProducts} clientRules={clientRules} />);
			if (fieldSubstring !== 'del' && index !== recurrentProducts.length - 1) productList.push(<Divider key={index} />);
		});
		return productList;
	}

	renderAddButton = () => {
		const { getIntl, addProduct } = this;
		return <AddButton text={getIntl("addRecurrentProduct")} addFunction={addProduct} />;
	}

	fullRender = () => {
		const { renderList, renderAddButton } = this;
		return (
			<div >
				{renderList()}
				{renderAddButton()}
			</div>
		);
	}

	render() {
		return this.fullRender();
	}
}

ClientRecurrentProductsForm.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	clientId: PropTypes.number.isRequired,
	setLoading: PropTypes.func.isRequired,
};

export default injectIntl(ClientRecurrentProductsForm);