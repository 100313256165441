import React from 'react'
import PropTypes from 'prop-types';
import { Form, Input, Col} from 'antd';
import { useIntl } from 'react-intl';
import { getFields, getModelsMessages } from '../../constants/messages';
const FormItem = Form.Item;

let ModelsMessages = getModelsMessages(),
	FieldsMessages = getFields();

const messages = {
	...ModelsMessages,
    ...FieldsMessages,
};

const AddOrEditForm = ({ form, record }) => {
	const intl = useIntl();

  return (
    <div>
            <Form
                ref={form}
                layout='vertical'
                initialValues={{
                    ...record,
                    model: record ? record.model : null,
                }}
            >
                <FormItem name="id" hidden={true}>
                    <Input hidden={true} />
                </FormItem>
                <Col span={24}>
                    <FormItem
                        label={intl.formatMessage({...messages.model})}
                        name="model"
                        rules={[{ required: true }]}
                    >
                       <Input
                            placeholder={intl.formatMessage({...messages.modelPlaceholder})}
                        />     
                    </FormItem>
                </Col>
            </Form>
    </div>
  )
}

AddOrEditForm.propTypes = {
    form: PropTypes.object.isRequired,
    record: PropTypes.object
};

export default AddOrEditForm;
