import {
	CLEAR_SESSION,
	SHOW_CONFIGURATION,
	CLOSE_CONFIGURATION,
	START_LOADING_CONFIGURATION,
	STOP_LOADING_CONFIGURATION,
} from '../constants/actionTypes';

const initState = {
	showConfiguration: false,
	loading: false,
	traffic: false,
	centerOnSelect: false,
	centerOnUpdate: false,
	showTrace: false,
	traceColor: '#00bcd4',
	movementColor: '#00bcd4',
	parckingColor: '#00bcd4',
	idleColor: '#00bcd4'
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case START_LOADING_CONFIGURATION: 
			return {
				...state,
				loading: true
			};
		case STOP_LOADING_CONFIGURATION: 
			return {
				...state,
				loading: false
			};
		case SHOW_CONFIGURATION: 
			return {
				...state,
				showConfiguration: true
			};
		case CLOSE_CONFIGURATION: 
			return {
				...state,
				showConfiguration: false,
				loading: false
			};
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;