import React, { useState, useMemo, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { getUnitsMessages, getDeviceMessages, getErrorMessages, getFields, getactionMessages, getPopConfirm, getLotsMessages } from '../../constants/messages';
import CommonTable from '../../components/CommonTable';
import AddButton from './AddButton';
import { DeleteUnit, GetAllUnits, setSearch } from './actions';
import { message, Button, Typography, Popconfirm } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import UploadFileModal from './UploadFileModal';
import AddOrEditModal from './AddOrEditModal';
import UnitRadioButton from './filter';
import { UNITLOCATION } from './constants';
import _ from 'lodash';

const { WAREHOUSE } = UNITLOCATION;

let UnitsMessages = getUnitsMessages(),
    devicesMessages = getDeviceMessages(),
	FieldsMessages = getFields(),
    ErrorMessages = getErrorMessages(),
	ActionMessages = getactionMessages(),
	PopMessages = getPopConfirm(),
	LotsMessages = getLotsMessages();

const messages = {
	...UnitsMessages,
	...devicesMessages,
	...ErrorMessages,
	...FieldsMessages,
	...ActionMessages,
	...PopMessages,
	...LotsMessages,
};

const Units = () => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [units, setUnits] = useState([]);
	const [filter, setFilter] = useState('');
	const [isVisible, setVisible] = useState(false);
	const [recordToEdit, setRecordToEdit] = useState({ id: 0 });
	const [showUploadModal, setShowUploadModal] = useState(false);
	const models = useSelector((state) => state.model.models);
	const search = useSelector((state)=> state.unit.search);

	useEffect(() => {
		(async () => {
			try {
			
				const units = await dispatch(GetAllUnits());
				setUnits(units);
				setLoading(false);
			} catch (error) {
				message.error(intl.formatMessage({ ...messages.getUnitsError }));
			}
		})();	
	}, []);

	const updateUnits = (newUnit, uploadFile) => {
		if(uploadFile) setUnits(newUnit);
		else{
			const unitsIds = newUnit.map((unit) => unit.id);
			const updatedUnits = units.filter((unit) => !unitsIds.includes(unit.id));
			setUnits([...updatedUnits, ...newUnit]);
		}	
	};

	const editRecord = (record) => {
		setRecordToEdit(record);
		setVisible(true);
	};

	const setEditModalVisibility = (visibility) => {
		setVisible(visibility);
	};

	const deleteUnit =  (unitSelected) => {
		setLoading(true);
		dispatch(DeleteUnit(unitSelected)).then(() => {
			setUnits((u) => u.filter((unit) => !unitSelected.includes(unit.id)));
			setLoading(false);
		});
		return [];
	};

	const tableActions = (record) => {
		return (
			<span>
				<Typography.Link
					className="editable-add-btn"
					onClick={() => editRecord(record)}
				>
					<span className="icon-pencil" />
					<FormattedMessage {...messages.edit} />
				</Typography.Link>
				<span> | </span>
				<Popconfirm
					title={intl.formatMessage({...messages.deleteConfirm})}
					onConfirm={() => {
						deleteUnit([record.id]);
						message.success(intl.formatMessage({...messages.deleteUnitSuccess}));
					}}
					okText={intl.formatMessage({...messages.yes})}
					cancelText={intl.formatMessage({...messages.no})}
				>
					<Typography.Link>
						<span className="icon-bin" />
						<FormattedMessage {...messages.delete} />
					</Typography.Link>
				</Popconfirm>
			</span>
		);
	};

	const filteredUnits = useMemo(() => {
			const currentUnits = units.filter((unit) => {
				const { description, pin, imei, location, modelId } = unit;
				const lotNumber = location === 0 ? intl.formatMessage({...messages.warehouse}) : intl.formatMessage({...messages.lotId}, {id: location});
				if(!filter.location || filter.location == 0 || filter.location == 'WAREHOUSE' && location == WAREHOUSE.id || filter.location == 'LOT' && location != WAREHOUSE.id){
					if (filter.searchText === '') {
						return true;
					}
					if (lotNumber.toLowerCase().includes(filter.searchText)) {
						return true;
					}
					if (description.toLowerCase().includes(filter.searchText)) {
						return true;
					}
					if (pin.toLowerCase().includes(filter.searchText)) {
						return true;
					}
					if(imei){
						if (imei.toLowerCase().includes(filter.searchText)) {
							return true;
						}
					}
					if (modelId){
						const unitInfo = models.find((model) => model.id === modelId);
						if (unitInfo.model.toLowerCase().includes(filter.searchText)) {
							return true;
						}
					}
				}
				return false;
			});
			dispatch(setSearch(filter.searchText) || "")
			return _.sortBy(currentUnits, (unit) => unit.id);
	}, [units, filter]);

	const columns = useMemo(() => {
			return [
				{
					title: intl.formatMessage({ ...messages.description}),
					dataIndex: 'description',
					key: 'description',
				},
				{
					title: intl.formatMessage({ ...messages.pin}),
					dataIndex: 'pin',
					key: 'pin',
				},
				{
					title: intl.formatMessage({ ...messages.imei}),
					dataIndex: 'imei',
					key: 'imei',
				},
				{
					title: intl.formatMessage({ ...messages.model}),
					dataIndex: 'modelId',
					key: 'modelId',
					render: (id, record) => {
						const unitInfo = models.find((d) => record.modelId === d.id);
						if (unitInfo && unitInfo.model) return <span>{unitInfo.model}</span>;
					},
				},
				{
					title: intl.formatMessage({ ...messages.location}),
					dataIndex: 'location',
					key: 'location',
					render: (location) => {
						return location == 0 ? intl.formatMessage({...messages.warehouse}): intl.formatMessage({...messages.lotId}, {id: location});
					}
				},
				{
					title: intl.formatMessage({ ...messages.actions}),
					dataIndex: 'id',
					render: (id, record) => tableActions(record),
				},
			];
	}, []);
		
	const extraComponents = (
		<Button 
			onClick={() => setShowUploadModal(true)}
		>
			<UploadOutlined />
				{intl.formatMessage({...messages.upload})}
		</Button>
	);
	return (
		<div className="account view">
			<CommonTable
				columns={columns}
				dataSource={filteredUnits}
				Add={<AddButton updateUnits={updateUnits} models={models} />}
				delete={deleteUnit}
				loading={loading}
				recordCount={filteredUnits.length}				
				search={(search) => setFilter(search)}
				searchText={search}
				getRecords={() => { }}
				getCheckboxProps={(() => ({ disabled: false }))}
				extraComponents={[extraComponents]}
				filterComponent = {(<UnitRadioButton />)}
				preserveSelectedRowKeys
				lowerCaseSearch
			/>
			<AddOrEditModal
				models={models} 
				title={intl.formatMessage({ ...messages.edit})}
				visible={isVisible}
				record={recordToEdit}
				onCancel={() => setEditModalVisibility(false)}
				updateUnits={updateUnits}
				isEdit
			/>

			<UploadFileModal
				models={models} 
				showModal={showUploadModal}
				onCancel={() => setShowUploadModal(false)}
				updateUnits={updateUnits}
			/>
		</div>
	)
}

export default Units;
