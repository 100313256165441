import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import ReactTable from 'react-table';
import moment from 'moment';
import ReportOptions from './ReportOptions';
import CircleBadge from '../../GlobalComponents/CircleBadge';
import { GetAllClients } from '../../Clients/actions';
import { FileExcelOutlined } from '@ant-design/icons';
import { Col, Row, message, Button} from 'antd';
import { ExportJobsToChargeData, getJobsToCharge } from '../actions';
import { getChargeType, getJobStatus, getJobType } from '../../Jobs/constants';
import { getClientMessages, getErrorMessages, getFields, getInvoicingMessages, getJobMessages, getLanguageIntl, getReportMessages } from '../../../constants/messages';

let reportMessages = getReportMessages(),
    languageMessages = getLanguageIntl(),
    jobMessages = getJobMessages(),
    clientMessages = getClientMessages(),
    invoicingMessages = getInvoicingMessages(),
    fieldMessages = getFields(),
    errorMessages = getErrorMessages();

const messages = {
    ...reportMessages,
    ...languageMessages,
    ...jobMessages,
    ...clientMessages,
    ...invoicingMessages,
    ...errorMessages,
    ...fieldMessages,
};

const JobsChargeReport = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
		(async () => {
			try {
				dispatch(GetAllClients());
			} catch (error) {
                message.error(intl.formatMessage({ ...messages.GetClientsError }));
			}
		})();
	}, []);

    const onSubmit = async (values) => {
        try {
            setLoading(true);
            const response = await getJobsToCharge({...values})
            setData(response);
        } catch (error) {
            message.error(intl.formatMessage({ ...messages.commonError }));        
        } finally {
            setLoading(false);
        }
    }

    const downloadReport = async () => {
        setLoading(true);
        try {
            await ExportJobsToChargeData(data);
            message.success(intl.formatMessage({...messages.exportSuccess}));
        } catch (error) {
            message.error(intl.formatMessage({...messages.exportError}));
        } finally {
            setLoading(false);
        }
    }

    const exportButton = data.length ? (
            <Button 
                onClick={() => downloadReport()}
                icon={<FileExcelOutlined />}
                loading={loading} 
                disabled={loading} 
            >
                {intl.formatMessage({...messages.exportReport})}
            </Button>
    ) : null;

    const columns = [
		{
            Header: intl.formatMessage({ ...messages.clientName }),
            id: 'clientName',
            width: 300,
            accessor: (record) => {
                return record.clientName;
            },
        },
        {
            Header: intl.formatMessage({ ...messages.job }),
            id: 'id',
            width: 150,
            accessor: (record) => {
                return record.id;
            },
            Aggregated: ' ',
        },
        {
            Header: intl.formatMessage({ ...messages.jobType }),
            id: 'jobType',
            width: 200,
            accessor: (record) => {
                const type = getJobType(record.type);
                const chargeType = getChargeType(record.chargeType, 'id');
                return (`${intl.formatMessage({ ...messages[type.intl] })} - ${intl.formatMessage({ ...messages[chargeType.intl] })}`);
            },
            Aggregated: ' ',
        },
        {
            Header: intl.formatMessage({ ...messages.technicians }),
            id: 'technicianName',
            width: 200,
            accessor: (record) => {
                return record.name;
            },
            Aggregated: ' ',
        },
        {
            Header: intl.formatMessage({ ...messages.date }),
            id: 'programmedDate',
            width: 200,
            accessor: (record) => {
                const language = intl.formatMessage({ ...messages.intl });
                return moment(record.programmedDate).locale(language).format('MMMM DD, YYYY hh:mm A');
            },
            Aggregated: ' ',
        },
        {
            Header: intl.formatMessage({ ...messages.status }),
            id: 'status',
            width: 180,
            accessor: (record) => {
                const status = getJobStatus(record.status);
                const statusBadge = (<CircleBadge color={status.iconColor} text={intl.formatMessage({ ...messages[status.intl] })} />);
                return statusBadge;
            },
            Aggregated: ' ',
        },
        {
            Header: intl.formatMessage({ ...messages.description }),
            id: 'description',
            accessor: (record) => {
                return record.description ? record.description : '';
            },
            Aggregated: ' ',
        },
	];

  return (
    <Row className="report view">
        <Col span={24}>
            <h1 className="reportTitle">{intl.formatMessage({...messages.jobsToChargeReport})}</h1>
        </Col>
        <Col span={24} style={{ paddingTop: '10px', paddingBottom: '20px', width: '60%'}}>
            <ReportOptions onSubmit={onSubmit} exportButton={exportButton} />
        </Col>
        <Col span={24}>
            <ReactTable
                data={data}
                totalCount={data.totalCount}
                columns={columns}
                pivotBy={['clientName']}
                loading={loading}
                className="-striped"
            />
        </Col>
    </Row>
  )
}

export default JobsChargeReport;
