import axios from 'axios';
import { ADD_CONTACT_SUCCESS, FETCH_CONTACTS } from './actionTypes';

export const AddContact = (contact) => async (dispatch) => {
	const response = await axios.post(`/Contacts/`, contact);
	dispatch({ type: ADD_CONTACT_SUCCESS, contacts: response.data });
};

export const GetAllContacts = () => async (dispatch) => {
	const response = await axios.get(`/Contacts/`);
	dispatch({ type: FETCH_CONTACTS, contacts: response.data });
	return response.data;
};