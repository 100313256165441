import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, message } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { getClientMessages, getModalMessages } from '../../constants/messages';
import AddClientContactForm from './AddClientContactForm';

let modalMessages = getModalMessages(),
    clientMessages = getClientMessages();


const messages = {
    ...modalMessages,
    ...clientMessages,
};

const AddClientContactModal = ({ visible, handleCancel, onOk }) => {
    const intl = useIntl();
	const form = useRef();
    const [isSaving, setIsSaving] = useState(false);

    const onSave = async () => {
        try {
			setIsSaving(true);
            const values = await form.current.validateFields();
            await onOk(values)
            message.success(intl.formatMessage({...messages.addClientContactSuccess }));
		} catch (error) {
            message.error(intl.formatMessage({...messages.addClientContactError}));
		} finally {
			setIsSaving(false);
		}
    }

    return (
        <Modal
            open={visible}
            title={intl.formatMessage({...messages.addClientContact})}
            destroyOnClose={true}
            confirmLoading={isSaving}
            onOk={onSave}
            okText={
                <span>
                    <SaveOutlined/>
                    <FormattedMessage {...messages.save} />
                </span>
            }
            onCancel={handleCancel}
            cancelText={intl.formatMessage({...messages.cancel})}
         >
            <AddClientContactForm form={form} />
        </Modal>
    )
}

AddClientContactModal.propTypes = {
    handleCancel: PropTypes.func.isRequired,
	onOk: PropTypes.func.isRequired,
    visible: PropTypes.bool,
};

export default AddClientContactModal;
