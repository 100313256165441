import React from 'react';
import PropTypes from 'prop-types';
import AddExchangeRateForm from './AddExchangeRateForm';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import { GetExchangeRate } from './actions';
import {getExchangeRateMessages} from '../../constants/messages';
import { message } from 'antd';


let exchangeRateMessages = getExchangeRateMessages();

const messages = {
	...exchangeRateMessages,
};
 
class CheckExchangeRate extends React.Component  {

	constructor(props) {
		super(props);
		
		this.state = {
			showExchangeRateModal: false
		}
	}

	componentDidMount(){
		this.props.getExchangeRate().then(() => {
			if(this.props.setExchangeRate !== undefined)
				this.props.setExchangeRate(this.props.exchangeRate.rate);
			if(this.props.exchangeRate !== undefined){
				let today = new Date();
				let createdAt = new Date(this.props.exchangeRate.createdAt);
				if(createdAt < today.setHours(0, 0, 0, 0)){
					this.setState({
						showExchangeRateModal: true
					});
				}
			}else{
				this.setState({
					showExchangeRateModal: true
				});
			}
		}).catch(() => {
			message.error( `${ this.props.intl.formatMessage( {...messages.errorObtainingExchangeRate}) }` );
		});
	}

	handleCancel = () => {
		this.setState({
			showExchangeRateModal: false
		});
	}

	render(){
		return (
			<AddExchangeRateForm
				showModal={this.state.showExchangeRateModal}
				handleCancel={this.handleCancel}
				setExchangeRate={this.props.setExchangeRate}
			/>
		);
	}
}

CheckExchangeRate.propTypes = {
	intl: PropTypes.object.isRequired,
	exchangeRate: PropTypes.object,
	getExchangeRate: PropTypes.func.isRequired,
	setExchangeRate: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		exchangeRate: state.exchangeRate.exchangeRate
	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		getExchangeRate: () => {
			return dispatch(GetExchangeRate());
		}
	};
};

export default  connect(mapStateToProps, mapDispatchToProps)( injectIntl(CheckExchangeRate));