import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';

const locationHelper = locationHelperBuilder({});

const userIsNotAuthenticatedDefaults = {
	// Want to redirect the user when they are done loading and authenticated
	authenticatedSelector: state => !state.auth.isAuthenticated,
	wrapperDisplayName: 'UserIsNotAuthenticated'
}
  
export const UserIsNotAuthenticated = connectedAuthWrapper(userIsNotAuthenticatedDefaults)
  
export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
	...userIsNotAuthenticatedDefaults,
	redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
	allowRedirectBack: false
})


const userIsAuthenticatedDefaults = {
	authenticatedSelector: state => state.auth.isAuthenticated,
	wrapperDisplayName: 'UserIsAuthenticated'
}
  
export const UserIsAuthenticated = connectedAuthWrapper(userIsAuthenticatedDefaults)
  
export const userIsAuthenticatedRedir = connectedRouterRedirect({
	...userIsAuthenticatedDefaults,
	redirectPath: '/login'
})

export const ACL = (view, roles = []) => {
	return connectedRouterRedirect({
		// The url to redirect user to if they fail
		redirectPath: '/',
		// Determine if the user is authenticated or not
		// authenticatedSelector: state => (state.configuration.mapView && state.auth.isMaster) || (state.configuration.mapView && state.auth.roles.indexOf(4) >= 0),
		authenticatedSelector: (state) => {
			for (let i = 0; i < roles.length; i++) {
				if (state.auth.roles.indexOf(roles[i]) >= 0)
					return true;
			}
			return false;
		},
		// This prevents us from adding the query parameter when we send the user away from the login page
		allowRedirectBack: false,
		// This should be a redux action creator
		// redirectAction: routerActions.replace,
		// A nice display name for this check
	})(view);
};