import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getQuotationMessages, getModalMessages, getactionMessages, getErrorMessages, getProductMessages, getClientMessages } from '../../constants/messages';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Checkbox, message, notification, Spin } from 'antd';
import { GetProductsOfQuotation } from '../Products/actions';
import EmailSelector from '../../components/EmailSelector';
import { AddQuotation, PrintQuotation, UpdateQuotation, EmailQuotation, PreviewUnregisteredQuotation } from './actions';
import { injectIntl, FormattedMessage } from 'react-intl';
import { roundN, isEmpty, numToWords } from '../../utilities/util';
import AddInvoicingDocumentForm from '../../components/AddInvoicingDocumentForm';

let moment = require('moment');

let quotationMessages = getQuotationMessages(),
	errorMessages = getErrorMessages(),
	productMessages = getProductMessages(),
	actionMessages = getactionMessages(),
	modalMessages = getModalMessages(),
	clientMessages = getClientMessages();

const messages = {
	...quotationMessages,
	...modalMessages,
	...actionMessages,
	...productMessages,
	...errorMessages,
	...clientMessages
};

class AddQuotationForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			taxExemption: this.props.match.params.id ? this.props.quotation.taxExemption : false,
			isLoading: false,
			showEmailModal: false,
			quotation: {},
			products: [],
			addedProducts: [],
			deleteProducts: [],
			editedProducts: [],
		};
	}

	getProducts = async (id) => {
		try {
			const productsOfCurrentInvoice = await this.props.getProductsOfQuotation(id);
			this.setState({ products: productsOfCurrentInvoice.map(product => ({ ...product, isNew: false })) });
			return this.state.products;
		} catch (error) {
			message.error(`${this.props.intl.formatMessage({ ...messages.getQuotationProductsError })}`);
			this.setState({ isLoading: false });
			return [];
		}
	};

	checkEditProduct = (product) => {
		const { editedProducts, addedProducts } = this.state;
		const { index, isNew, key } = product;
		isNew ? this.editProduct(product, addedProducts, index, isNew) : this.editProduct(product, editedProducts, key, isNew);
	};

	editProduct = (product, products, value, isNew) => {
		if (products.length) {
			const productExists = products.find(x => (isNew ? x.index == value : x.key == value));
			if (productExists) {
				const productIndex = products.indexOf(productExists);
				products.splice(productIndex, 1, product);
			} else { products.push(product); }
		} else if (!isNew) { products.push(product) }
		isNew ? this.setState({ addedProducts: products }) : this.setState({ editedProducts: products });
	};

	getDeleteProduct = (product) => {
		const { editedProducts, addedProducts } = this.state;
		const { index, isNew, key } = product;
		if (isNew) {
			addedProducts.splice(index, 1);
			this.setState({ addedProducts });
			return;
		}
		const productExists = editedProducts.find(x => (x.key == key));
		if (productExists) {
			const productIndex = editedProducts.indexOf(productExists);
			editedProducts.splice(productIndex, 1);
			this.setState({ editedProducts });
		}
		const deleted = { ...product };
		this.setState(prevState => ({ deleteProducts: [...prevState.deleteProducts, deleted] }));
	};

	getAddedProducts = (product) => {
		const { addedProducts } = this.state;
		const index = addedProducts.length;
		const newProduct = { ...product, isNew: true, index };
		this.setState(prevState => ({ addedProducts: [...prevState.addedProducts, newProduct] }));
		return newProduct;
	};

	closeEmailModal = () => {
		this.setState({
			showEmailModal: false
		});
	}

	onChangeTaxExemption = (checked) => {
		this.setState({ taxExemption: checked });
	}

	handleSubmit = (email, state) => {
		const { addedProducts, editedProducts, deleteProducts } = this.state;
		const quotationId = this.props.match.params.id;
		let needsApproval = false;

		this.props.form.validateFields((err, values) => {
			if (err) {
				return;
			}
			const { clientAlias } = values;

			state.products.forEach((product) => {
				if (product.approvalNeeded) {
					needsApproval = true;
				}
			});

			values = {
				...values,
				accountId: this.props.user.id,
				status: needsApproval ? 1 : 0,
				total: this.state.taxExemption ? state.subtotal : state.total,
				subtotal: state.subtotal,
				createdAt: this.props.match.params.id !== undefined ? this.props.quotation.createdAt : moment(),
				daysForPayment: state.isCredit ? values.daysForPayment : 0,
				products: state.products,
				needsApproval: needsApproval,
				taxExemption: this.state.taxExemption,
				observations: isEmpty(values.observations) ? "" : values.observations,
				clientName: !state.variousClients ? (clientAlias && clientAlias.length > 0 ? clientAlias : this.props.currentClientName) : values.clientName,
				clientRtn: !state.variousClients ? this.props.currentRTN : values.clientRtn,
				representative: !state.variousClients ? this.props.currentRepresentative : values.representative,
				address: !state.variousClients ? this.props.fullAddress.address : values.address,
				city: !state.variousClients ? this.props.fullAddress.city : values.city,
				state: !state.variousClients ? this.props.fullAddress.state : values.state,
				isv: this.props.isv
			};

			if (state.products.length === 0) {
				message.error(this.props.intl.formatMessage({ ...messages.emptyProducts }));
				return;
			}

			if (email) {
				if (values.needsApproval)
					message.warning(this.props.intl.formatMessage({ ...messages.CantEmail }));
				else {
					this.setState({
						showEmailModal: true,
						isLoading: false,
						quotation: { ...values }
					});
				}
			} else {
				this.setState({
					isLoading: true
				});
				if (quotationId) {
					values = {
						...values,
						products: { newProducts: addedProducts, editProducts: editedProducts, deleteProducts },
					}
					this.editQuotation(quotationId, values);
				} else {
					this.submitQuotation(values).then(id => {
						this.props.history.push(`/quotations/${id}`);
					});
				}
			}
		});
	}

	submitQuotation = (values) => {
		return this.props.addQuotation(values).then(() => {
			if (!values.needsApproval)
				this.props.printQuotation(this.props.quotation.id, this.props.quotation.id.toString(), numToWords(roundN(values.total * values.exchangeRate, 2))).catch(() => {
					message.error(`${this.props.intl.formatMessage({ ...messages.PrintError })}`);
				});
			if (values.needsApproval)
				notification.open({
					message: this.props.intl.formatMessage({ ...messages.NeedsApproval }),
					description: this.props.intl.formatMessage({ ...messages.ApprovalMessage }),
				});
			return this.props.quotation.id;
		}).catch(() => {
			message.error(`${this.props.intl.formatMessage({ ...messages.AddQuotationError })}`);
			this.setState({
				isLoading: false
			});
		});
	}

	editQuotation = (quotationId, values) => {
		UpdateQuotation(quotationId, values).then(() => {
			if (!values.needsApproval)
				this.props.printQuotation(this.props.quotation.id, this.props.quotation.id.toString(), numToWords(roundN(values.total * values.exchangeRate, 2))).catch(() => {
					message.error(`${this.props.intl.formatMessage({ ...messages.PrintError })}`);
				});
			if (values.needsApproval)
				notification.open({
					message: this.props.intl.formatMessage({ ...messages.NeedsApproval }),
					description: this.props.intl.formatMessage({ ...messages.ApprovalMessage }),
				});
			message.success(`${this.props.intl.formatMessage({ ...messages.UpdateQuotationSuccess })}`);
			this.setState({ isLoading: false })
			this.props.history.push(`/quotations/${quotationId}`);
		}).catch(() => {
			message.error(`${this.props.intl.formatMessage({ ...messages.UpdateQuotationError })}`);
			this.setState({ isLoading: false });
		});
	}

	handleCancel = () => {
		this.props.history.push(`/quotations`);
	}

	render() {
		const { taxExemption } = this.state;
		const extraFormItems = (
			<Row gutter={16} type="flex" align="middle" style={{ 'height': '100px' }}>
				<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }}>
					<Checkbox onChange={(event) => this.onChangeTaxExemption(event.target.checked)} defaultChecked={taxExemption}><FormattedMessage {...messages.TaxExemption} /></Checkbox>
				</Col>
			</Row>
		);
		return (
			<Form>
				<EmailSelector
					addOrShow={true}
					showEmailModal={this.state.showEmailModal}
					closeModal={this.closeEmailModal}
					addDocument={this.props.match.params.id !== undefined ? this.editQuotation : this.submitQuotation}
					document={this.state.quotation}
					goTo={(id) => { this.props.history.push(`/quotations/${id}`); }}
					emailDocument={this.props.emailQuotation}
					downloadPreview={() => PreviewUnregisteredQuotation(this.state.quotation)}
				/>
				<Spin spinning={this.state.isLoading}>
					<AddInvoicingDocumentForm
						handleSubmit={this.handleSubmit}
						handleCancel={this.handleCancel}
						extraFormItems={extraFormItems}
						getFieldDecorator={this.props.form.getFieldDecorator}
						document={this.props.match.params.id !== undefined ? this.props.quotation : undefined}
						getProducts={this.getProducts}
						editProduct={this.checkEditProduct}
						getDeleteProduct={this.getDeleteProduct}
						getAddedProducts={this.getAddedProducts}
						taxExemption={this.state.taxExemption}
						resetFields={this.props.form.resetFields}
					/>
				</Spin>
			</Form>
		);
	}
}

AddQuotationForm.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	match: PropTypes.shape({
		params: PropTypes.object.isRequired
	}).isRequired,
	user: PropTypes.object.isRequired,
	quotation: PropTypes.object.isRequired,
	currentClientName: PropTypes.string,
	currentRTN: PropTypes.string,
	currentRepresentative: PropTypes.string,
	customPrices: PropTypes.array,
	exchangeRate: PropTypes.object,
	currentIsLps: PropTypes.bool,
	fullAddress: PropTypes.object,
	productsOfCurrentQuotation: PropTypes.array.isRequired,
	addQuotation: PropTypes.func.isRequired,
	printQuotation: PropTypes.func.isRequired,
	emailQuotation: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	getProductsOfQuotation: PropTypes.func.isRequired,
	isv: PropTypes.number.isRequired
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		quotation: state.quotation.quotation,
		currentClientName: state.client.currentClientName,
		currentRTN: state.client.currentRTN,
		currentRepresentative: state.client.currentRepresentative,
		exchangeRate: state.exchangeRate.exchangeRate,
		customPrices: state.client.customPrices,
		currentIsLps: state.client.currentIsLps,
		fullAddress: state.client.fullAddress,
		productsOfCurrentQuotation: state.product.productsOfCurrentQuotation,
		isv: state.invoicing.isv
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getProductsOfQuotation: (quotationId) => {
			return dispatch(GetProductsOfQuotation(quotationId));
		},
		addQuotation: (newQuotation) => {
			return dispatch(AddQuotation(newQuotation));
		},
		printQuotation: (id, docNum, amountInWords) => {
			return dispatch(PrintQuotation(id, docNum, amountInWords));
		},
		emailQuotation: (id, emails, replyTo, message) => {
			return dispatch(EmailQuotation(id, emails, replyTo, message));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(Form.create()(AddQuotationForm))));
