import React from 'react';
import PropTypes from 'prop-types';
import { DeleteOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { InputNumber, Row, Col, Select, Input, Card } from 'antd';
import { getClientMessages } from '../../../constants/messages';
import { injectIntl } from 'react-intl';
import { TierBadgeColors } from '../constants';
import { existsInArray } from '../../../utilities/util';

let clientMessages = getClientMessages();

const messages = {
	...clientMessages
};

const FormItem = Form.Item;
const { Option } = Select;

class TierRender extends React.Component {

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	renderNameField = () => {
		const { props, getIntl } = this;
		const { form, tier } = props;
		const { getFieldDecorator } = form;
		const { uuid, name } = tier;
		const maxLength = 255;
		return (
			<FormItem className="job-partial-component" /*label={getIntl("name")}*/>
				{
					getFieldDecorator(`${uuid}[name]`,
						{
							initialValue: name,
							rules: [
								{
									required: true,
									message: getIntl("tierNameRequiredError"),
								},
								{
									max: maxLength,
									message: getIntl("tierNameLengthError", { max: maxLength })
								}
							]
						}
					)(
						<Input placeholder={getIntl("tierName")} />
					)
				}
			</FormItem>
		);
	}

	renderColorSquare = (color) => {
		const style = {
			width: '36px',
			height: '14px',
			borderRadius: '2px',
			background: color,
		};
		return <div style={style} />;
	}

	renderBadgeColorPicker = () => {
		const { props, getIntl, renderColorSquare } = this;
		const { form, tier } = props;
		const { getFieldDecorator } = form;
		const { uuid, badgeColor } = tier;
		const options = [];
		const pushColor = color => options.push(<Option key={color} value={color}>{renderColorSquare(color)}</Option>);
		if (badgeColor && !existsInArray(badgeColor, TierBadgeColors)) pushColor(badgeColor);
		TierBadgeColors.forEach(color => pushColor(color));
		return (
			<FormItem label={getIntl("badgeColor")}>
				{
					getFieldDecorator(`${uuid}[badgeColor]`,
						{
							initialValue: badgeColor,
							rules: [{
								required: true,
								message: getIntl("badgeColorRequiredError"),
							}]
						}
					)(
						<Select style={{ width: '100px' }} className='client-tiers-color-swatch' allowClear={false} >
							{options}
						</Select>
					)
				}
			</FormItem>
		);
	}

	renderGracePeriodDaysField = () => {
		const { props, getIntl } = this;
		const { form, tier } = props;
		const { getFieldDecorator } = form;
		const { uuid, gracePeriodDays } = tier;
		return (
			<FormItem label={getIntl("gracePeriodDays")}>
				{
					getFieldDecorator(`${uuid}[gracePeriodDays]`,
						{
							initialValue: gracePeriodDays,
							rules: [
								{
									required: true,
									message: getIntl("gracePeriodDaysRequiredError"),
								}
							]
						}
					)(
						<InputNumber
							min={0}
							precision={0}
							formatter={value => value == 0 || value == '∞' ? '∞' : value}
							parser={value => value == 0 || value == '∞' ? 0 : value}
						/>
					)
				}
			</FormItem>
		);
	}

	renderExtraDeleteButton = () => {
		const { removeFunction } = this.props;
		return <DeleteOutlined className="client-rules-icon" key="delete" onClick={removeFunction} />;
	}

	fullRender = () => {
		const { props, renderNameField, renderBadgeColorPicker, renderGracePeriodDaysField, renderExtraDeleteButton } = this;
		const { uuid } = props.tier;
		const fieldSubstring = uuid.substring(0, 3);
		return (
			<div className={fieldSubstring === 'del' ? 'hide-component' : ''}>
				<Card
					className='client-rules-card'
					title={<div className="client-remove-margin-bottom">{renderNameField()}</div>}
					extra={renderExtraDeleteButton()}
				>
					<Row gutter={16}>
						<Col span={12}>{renderGracePeriodDaysField()}</Col>
						<Col span={12}>{renderBadgeColorPicker()}</Col>
					</Row>
				</Card>
			</div>
		);
	}

	render() {
		return this.fullRender();
	}

}

TierRender.defaultProps = {
	tier: {}
};

TierRender.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	tier: PropTypes.object.isRequired,
	removeFunction: PropTypes.func.isRequired
};

export default injectIntl(TierRender);