import React from 'react';
import Icon from '@ant-design/icons';

const TankSvg = () => (
	<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
		width="1.5em" height="1.5em" viewBox="0 0 24.000000 24.000000"
		preserveAspectRatio="xMidYMid meet">

		<g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
			fill="#000000" stroke="none">
			<path d="M47 214 c-4 -4 -7 -45 -7 -91 0 -49 -4 -83 -10 -83 -5 0 -10 -4 -10
-10 0 -6 33 -10 80 -10 47 0 80 4 80 10 0 6 -4 10 -10 10 -20 0 -9 19 13 22
19 3 22 10 25 54 2 37 -1 54 -13 64 -21 18 -28 8 -15 -24 15 -40 13 -76 -5
-76 -11 0 -15 16 -17 68 l-3 67 -50 3 c-28 2 -54 0 -58 -4z m93 -39 c0 -22 -4
-25 -40 -25 -36 0 -40 3 -40 25 0 23 4 25 40 25 36 0 40 -2 40 -25z"/>
		</g>
	</svg>
);

const TankIconBuilder = props => <Icon component={TankSvg} {...props} />;

class TankIcon extends React.Component {
	render() {
		return <TankIconBuilder />;
	}
}

export default TankIcon;