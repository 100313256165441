export const states = [
    {
        name: "Atlántida",
        cities: [
            "La Ceiba",
            "Tela",
            "Jutiapa",
            "La Masica",
            "San Francisco",
            "Arizona",
            "Esparta",
            "El Porvenir"
        ]
    },
    {
        name: "Colón",
        cities: [
            "Trujillo",
            "Balfate",
            "Iriona",
            "Limón",
            "Sabá",
            "Santa Fe",
            "Santa Rosa de Aguán",
            "Sonaguera",
            "Tocoa",
            "Bonito Oriental"
        ]
    },
    {
        name: "Comayagua",
        cities: [
            "Comayagua",
            "Ajuterique",
            "El Rosario",
            "Esquías",
            "Humuya",
            "La libertad",
            "Lamaní",
            "La Trinidad",
            "Lejamani",
            "Meambar",
            "Minas de Oro",
            "Ojos de Agua",
            "San Jerónimo",
            "San José de Comayagua",
            "San José del Potrero",
            "San Luis",
            "San Sebastián",
            "Siguatepeque",
            "Villa de San Antonio",
            "Las Lajas",
            "Taulabé"
        ]
    },
    {
        name: "Copán",
        cities: [
            "Santa Rosa de Copán",
            "Cabañas",
            "Concepción",
            "Copán Ruinas",
            "Corquín",
            "Cucuyagua",
            "Dolores",
            "Dulce Nombre",
            "El Paraíso",
            "Florida",
            "La Jigua",
            "La Unión",
            "Nueva Arcadia",
            "San Agustín",
            "San Antonio",
            "San Jerónimo",
            "San José",
            "San Juan de Opoa",
            "San Nicolás",
            "San Pedro",
            "Santa Rita",
            "Trinidad de Copán",
            "Veracruz"
        ]
    },
    {
        name: "Cortés",
        cities: [
            "San Pedro Sula",
            "Chamelecón",
            "Choloma",
            "Omoa",
            "Pimienta",
            "Potrerillos",
            "Puerto Cortés",
            "San Antonio de Cortés",
            "San Francisco de Yojoa",
            "San Manuel",
            "Santa Cruz de Yojoa",
            "Villanueva",
            "La Lima"
        ]
    },
    {
        name: "Choluteca",
        cities: [
            "Choluteca",
            "Apacilagua",
            "Concepción de María",
            "Duyure",
            "El Corpus",
            "El Triunfo",
            "Marcovia",
            "Morolica",
            "Namasigue",
            "Orocuina",
            "Pespire",
            "San Antonio de Flores",
            "San Isidro",
            "San José",
            "San Marcos de Colón",
            "Santa Ana de Yusguare"
        ]
    },
    {
        name: "El Paraíso",
        cities: [
            "Yuscarán",
            "Alauca",
            "Danlí",
            "El Paraíso",
            "Güinope",
            "Jacaleapa",
            "Liure",
            "Morocelí",
            "Oropolí",
            "Potrerillos",
            "San Antonio de Flores",
            "San Lucas",
            "San Matías",
            "Soledad",
            "Teupasenti",
            "Texiguat",
            "Vado Ancho",
            "Yauyupe",
            "Trojes"
        ]
    },
    {
        name: "Francisco Morazán",
        cities: [
            "Tegucigalpa/Distrito Central",
            "Alubarén",
            "Cedros",
            "Curarén",
            "El Porvenir",
            "Guaimaca",
            "La Libertad",
            "La Venta",
            "Lepaterique",
            "Maraita",
            "Marale",
            "Nueva Armenia",
            "Ojojona",
            "Orica",
            "Reitoca",
            "Sabanagrande",
            "San Antonio de Oriente",
            "San Buenaventura",
            "San Ignacio",
            "San Juan de Flores",
            "San Miguelito",
            "Santa Ana",
            "Santa Lucía",
            "Talanga",
            "Tatumbla",
            "Valle de Ángeles",
            "Villa de San Francisco",
            "Vallecillo"
        ]
    },
    {
        name: "Gracias a Dios",
        cities: [
            "Puerto Lempira",
            "Brus Laguna",
            "Ahuas",
            "Juan Francisco Bulnes",
            "Ramón Villeda Morales",
            "Wampusirpe"
        ]
    },
    {
        name: "Intibucá",
        cities: [
            "La Esperanza",
            "Camasca",
            "Colomoncagua",
            "Concepción",
            "Dolores",
            "Intibucá",
            "Jesús de Otoro",
            "Magdalena",
            "Masaguara",
            "San Antonio",
            "San Isidro",
            "San Juan",
            "San Marcos de la Sierra",
            "San Miguel Guancapla",
            "Santa Lucía",
            "Yamaranguila",
            "San Francisco de Opalaca"
        ]
    },
    {
        name: "Islas de la Bahía",
        cities: [
            "Roatán",
            "Guanaja",
            "José Santos Guardiola",
            "Utila"
        ]
    },
    {
        name: "La Paz",
        cities: [
            "La Paz",
            "Aguanqueterique",
            "Cabañas",
            "Cane",
            "Chinacla",
            "Guajiquiro",
            "Lauterique",
            "Marcala",
            "Mercedes de Oriente",
            "Opatoro",
            "San Antonio del Norte",
            "San José",
            "San Juan",
            "San Pedro de Tutule",
            "Santa Ana",
            "Santa Elena",
            "Santa María",
            "Santiago de Puringla",
            "Yarula"
        ]
    },
    {
        name: "Lempira",
        cities: [
            "Gracias",
            "Belén",
            "Candelaria",
            "Cololaca",
            "Erandique",
            "Gualcince",
            "Guarita",
            "La Campa",
            "La Iguala",
            "Las Flores",
            "La Unión",
            "La Virtud",
            "Lepaera",
            "Mapulaca",
            "Piraera",
            "San Andrés",
            "San Francisco",
            "San Juan Guarita",
            "San Manuel Colohete",
            "San Rafael",
            "San Sebastián",
            "Santa Cruz",
            "Talgua",
            "Tambla",
            "Tomalá",
            "Valladolid",
            "Virginia",
            "San Marcos de Caiquín"
        ]
    },
    {
        name: "Ocotepeque",
        cities: [
            "Ocotepeque",
            "Belén Gualcho",
            "Concepción",
            "Dolores Merendón",
            "Fraternidad",
            "La Encarnación",
            "La Labor",
            "Lucerna",
            "Mercedes",
            "San Fernando",
            "San Francisco del Valle",
            "San Jorge",
            "San Marcos",
            "Santa Fe",
            "Sensenti",
            "Sinuapa"
        ]
    },
    {
        name: "Olancho",
        cities: [
            "Juticalpa",
            "Campamento",
            "Catacamas",
            "Concordia",
            "Dulce Nombre de Culmí",
            "El Rosario",
            "Esquipulas del Norte",
            "Gualaco",
            "Guarizama",
            "Guata",
            "Guayape",
            "Jano",
            "La Unión",
            "Mangulile",
            "Manto",
            "Salamá",
            "San Esteban",
            "San Francisco de Becerra",
            "San Francisco de la Paz",
            "Santa María del Real",
            "Silca",
            "Yocón",
            "Patuca"
        ]
    },
    {
        name: "Santa Bárbara",
        cities: [
            "Santa Bárbara",
            "Arada",
            "Atima",
            "Azacualpa",
            "Ceguaca",
            "Concepción del Norte",
            "Concepción del Sur",
            "Chinda",
            "El Níspero",
            "Gualala",
            "Ilama",
            "Las Vegas",
            "Macuelizo",
            "Naranjito",
            "Nuevo Celilac",
            "Nueva Frontera",
            "Petoa",
            "Protección",
            "Quimistán",
            "San Francisco de Ojuera",
            "San José de las Colinas",
            "San Luis",
            "San Marcos",
            "San Nicolás",
            "San Pedro Zacapa",
            "San Vicente Centenario",
            "Santa Rita",
            "Trinidad"
        ]
    },
    {
        name: "Valle",
        cities: [
            "Nacaome",
            "Alianza",
            "Amapala",
            "Aramecina",
            "Caridad",
            "Goascorán",
            "Langue",
            "San Francisco de Coray",
            "San Lorenzo"
        ]
    },
    {
        name: "Yoro",
        cities: [
            "Yoro",
            "Arenal",
            "El Negrito",
            "El Progreso",
            "Jocón",
            "Morazán",
            "Olanchito",
            "Santa Rita",
            "Sulaco",
            "Victoria",
            "Yorito"
        ]
    }
];