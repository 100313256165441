import React, { useState, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl';
import { Badge, message } from 'antd';
import { getClientMessages, getFields, } from '../../../constants/messages';
import CommonTable from '../../../components/CommonTable';
import { GetGuatemalaClientsUser } from './actions';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {SET_SEARCH_CLIENTS} from '../actionTypes';

let clientMessages = getClientMessages(),
    FieldsMessages = getFields();

const messages = {
    ...FieldsMessages,
    ...clientMessages,
};

const index = () => {
    const intl = useIntl();
    const [loading, setLoading] = useState(true);
	const [filter, setFilter] = useState('');
	const [clients, setClients] = useState([]);
    const searchText = useSelector((state)=>state.optimusGuatemala.searchClients);
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const guatemalaClients = await GetGuatemalaClientsUser();
                setClients(guatemalaClients);
            } catch (error) {
                message.error(intl.formatMessage({ ...messages.GetClientsError }));
            } finally {
                setLoading(false);
            }
        })();
    }, [])

    const filteredClients = useMemo(() => {
        const currentClients = clients.filter((client) => {
                if (filter.searchText === '') {
                    return true;
                }
                if (client.name.toLowerCase().includes(filter.searchText)) {
                    return true;
                }
                if (client.username.toLowerCase().includes(filter.searchText)) {
                    return true;
                }
                if (client.email.toLowerCase().includes(filter.searchText)) {
                    return true;
                }
                if(client.cellphone){
                    if (client.cellphone.toLowerCase().includes(filter.searchText)) {
                        return true;
                    }
                }
                return false;
            });
            
            dispatch({type:SET_SEARCH_CLIENTS, searchClients: filter.searchText})
            return _.sortBy(currentClients, (model) => model.id);
    }, [clients, filter]);

    const columns =  useMemo(() => {
        return [
            {
                title: intl.formatMessage({ ...messages.name}),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: intl.formatMessage({ ...messages.activeDevices}),
                dataIndex: 'deviceCount',
                key: 'deviceCount',
            },
            {
                title: intl.formatMessage({ ...messages.user}),
                children: [
                    {
                        title: intl.formatMessage({ ...messages.username}),
                        dataIndex: 'username',
                        key: 'username',
                    },
                    {
                        title: intl.formatMessage({ ...messages.clientTelephones}),
                        dataIndex: 'cellphone',
                        key: 'cellphone',
                    },
                    {
                        title: intl.formatMessage({ ...messages.email}),
                        dataIndex: 'email',
                        key: 'email',
                    },
                    {
                        title: intl.formatMessage({ ...messages.active}),
                        dataIndex: 'active',
                        key: 'active',
                        render: (active) => {
                            let status = active
                                ? { color: 'green', text: intl.formatMessage({ ...messages.clientEnabled }) }
                                : { color: 'red', text: intl.formatMessage({ ...messages.clientDisabled }) };
                            return (
                                <span>
                                    <Badge {...status} />
                                </span>
                            );
                        },
                    },
                ],
            }
        ];
    }, []);

  return (
    <div className="account view">
        <CommonTable
            columns={columns}
            dataSource={filteredClients}
            recordCount={filteredClients.length}
            searchText={searchText}
            loading={loading}
            // eslint-disable-next-line no-unused-vars
            search={(search, page, pageSize) => setFilter(search)}
            getCheckboxProps={() => ({})}
            getRecords={() => { }}
            lowerCaseSearch
            hideCheckboxes
        />
    </div>
  )
}

export default index;
