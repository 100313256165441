import { connect } from 'react-redux';
import Payroll from './Payroll';
import {
	GetPayrolls,
	GetPayrollCount
} from './actions';

const mapStateToProps = (state) => {
	return {
        user: state.auth.user,
        records: state.payroll.payrolls,
		recordCount: state.payroll.count
	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		getRecords: ( searchObject, page, pageSize ) => dispatch(GetPayrolls( searchObject, page, pageSize )),
		getRecordCount: ( searchObject ) => dispatch(GetPayrollCount( searchObject )),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Payroll);