import PropTypes from 'prop-types';
import React from 'react';
import Client from '../Jobs/Calendar/AdvancedSearch/Components/Client'

class ClientSelector extends React.Component {

	componentDidMount() {
		this.getInitialClientData(this.props.selectedClient);
	}

	getInitialClientData = (client) => {
		if (client !== undefined)
			this.handleSelectClient(client.id)
	}

	handleSelectClient = (selectedClient) => this.props.selectClient(selectedClient.id);

	render() {
		const { props, handleSelectClient } = this;
		const { form } = props;
		return <Client form={form} allowClear={false} onChange={handleSelectClient} />;
	}
}

ClientSelector.propTypes = {
	form: PropTypes.object.isRequired,
	selectedClient: PropTypes.object,
	selectClient: PropTypes.func.isRequired
};

export default ClientSelector;