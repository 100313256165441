import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { TimePicker } from 'antd';
import { injectIntl } from 'react-intl';
import { getErrorMessages, getJobMessages } from '../../../../constants/messages';
import { momentToDuration } from '../../constants';
const moment = require('moment');

const FormItem = Form.Item;
const format = 'HH:mm';

let jobMessages = getJobMessages(),
    errorMessages = getErrorMessages();

const messages = {
	...jobMessages,
	...errorMessages
};

class Duration extends React.Component {

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    durationMomentFormatter = (durationInMinutes) => moment().startOf('day').add(durationInMinutes || 30, 'm');

    getDisabledMinutes = (selectedHour) => {
        if (selectedHour === undefined || selectedHour === null) return;
        const minutes= [];
        if (selectedHour === 0) for(let i = 0; i < 30; i++) minutes.push(i);
        return minutes;
    }

    onChange = (duration) => {
        const { props } = this;
        const { onChange } = props;
        if ('onChange' in props) onChange(momentToDuration(duration));
    }
    
	render() {
        const { props, getIntl, onChange, getDisabledMinutes, durationMomentFormatter } = this;
        const { form, disabled, duration } = props;
        const { getFieldDecorator } = form;
        return (
            <FormItem label={getIntl("duration")} >
            {
                getFieldDecorator(`duration`,
                    {
                        initialValue: durationMomentFormatter(duration),
                        rules: [{
                            required: true,
                            message: getIntl("jobDurationError"), 
                        }],
                        onChange: onChange
                    }
                )(
                    <TimePicker
                        className="job-full-component"
                        format={format}
                        disabled={disabled}
                        minuteStep={10}
                        disabledMinutes={getDisabledMinutes}
                        placeholder={getIntl("duration")}
                        hideDisabledOptions={true}
                        allowClear={false}
                    />
                )
            }
            </FormItem>
        );
    }
}

Duration.defaultProps = {
    duration: null,
    disabled: false
};

Duration.propTypes = {
	intl: PropTypes.object.isRequired,
    
    form: PropTypes.object.isRequired,
    duration: PropTypes.number,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
};

export default injectIntl(Duration);