/* eslint-disable import/default */

import React from 'react';
import { render } from 'react-dom';
import axios from 'axios';
import { API_BASE_URL } from './constants/global';
import cookie from 'react-cookies';
import configureStore from './store/configureStore';
require('./favicon.ico'); // Tell webpack to load favicon.ico
import './styles/styles.scss'; // Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.
import 'ant-design-pro/dist/ant-design-pro.css';
import LanguagePage from './containers/languajesPage';
import { message } from 'antd'
import _ from 'lodash';
import compile from 'string-template/compile';
import { LOG_OUT } from './constants/actionTypes';

// store
const store = configureStore();

const getErrorCodeMessage = (code, options = {}) => {
	let errorCode = `error.${_.camelCase(code)}`
	let messages = store.store.getState().int18.messages;
	let message = messages[errorCode] || messages.commonError;
	let compiler = compile(message);
	return compiler(options)
}

axios.defaults.baseURL = API_BASE_URL;

let token = cookie.load("token");
if (token && token.length) {
	axios.defaults.headers.common['Authorization'] = token;
}

axios.interceptors.response.use(function (response) {
	return response;
}, function (error) {
	if (error.response) {
		switch (error.response.status) {
			case 401:
				cookie.remove('token');
				cookie.remove('image');
				cookie.remove('token-expire');

				if (window.client) window.client.messages.end();
				delete window.unitSystem;
				delete window.main;
				if (store.store.getState().auth.isAuthenticated) message.warn(getErrorCodeMessage('sessionExpired'));
				store.store.dispatch({ type: LOG_OUT });
				axios.defaults.headers.common['Authorization'] = null;
				return Promise.reject(error);
			case 403:
				//window.location = "/unauthorized";
				message.error(getErrorCodeMessage('unauthorizedAccess'));
				return Promise.reject(error);
			case 500:
				message.error(getErrorCodeMessage('commonError'));
				return Promise.reject(error);
			default: return Promise.reject(error);
		}
	}
	else return Promise.reject(error);
});

render(
	<LanguagePage store={store.store} persistor={store.persistor}/>
	, document.getElementById('app'));

