import React from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';
import {getRegistrationMessages, getUnitsMessages} from '../../constants/messages';
import {injectIntl, FormattedMessage} from 'react-intl';

let regMessages = getRegistrationMessages(),
    UnitsMessages = getUnitsMessages();

const messages = {
	...regMessages,
    ...UnitsMessages
};

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class UnitRadioButton extends React.Component {
    onChange = (e) => {
		this.props.onFilter({location: e.target.value});
    }

    render(){
        return(
            <RadioGroup onChange={this.onChange} defaultValue={0} >
                <RadioButton value={0}><FormattedMessage {...messages.all} /></RadioButton>
                <RadioButton value={'WAREHOUSE'}><FormattedMessage {...messages.available} /></RadioButton>
                <RadioButton value={'LOT'}><FormattedMessage {...messages.assigned} /></RadioButton>
            </RadioGroup>
        );
    }
}

UnitRadioButton.propTypes = {
	onFilter: PropTypes.func,
};

export default injectIntl(UnitRadioButton);
