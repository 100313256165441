import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';
import { Radio } from 'antd';
import { getJobMessages } from '../../../../constants/messages';
import DefaultForm from './DefaultForm';
import ChargeType from '../CommonComponents/ChargeType';

const FormItem = Form.Item;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

let jobMessages = getJobMessages();

const messages = {
	...jobMessages
};

class Reconnection extends React.Component  {
    constructor(props){
        super(props);
        this.state = {
            chargeType: 0
        };
    }
    getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    renderDefaultForm = () => {
        const { form, saveFields, getField, isUpdate } = this.props;
        return (
            <DefaultForm
                form={form}
                saveFields={saveFields}
                getField={getField}
                isUpdate={isUpdate}
            />
        );
    }

    renderChargeOption = () => {
        const { props, getIntl } = this;
        const { form, saveFields, getField, isUpdate } = props;
        const save = chargeType => {
            const invoiceInfo = getField("invoiceInfo");
            saveFields({ invoiceInfo: { ...invoiceInfo, chargeType } });
        }
        let chargeType = getField("invoiceInfo.chargeType");
        if (chargeType === undefined && isUpdate) chargeType = 0;
        const options = [
            <RadioButton key={4} value={4}>{getIntl("perCut")}</RadioButton>,
            <RadioButton key={5} value={5}>{getIntl("newClient")}</RadioButton>,
            <RadioButton key={7} value={7}>{getIntl("previousClient")}</RadioButton>
        ];
        return (
            <ChargeType
                form={form}
                chargeType={chargeType}
                onChange={save}
                options={options}
            />
        );
    }

    renderCharge = () => {
        const { props: {form, getField}, getIntl} = this;
        const { getFieldDecorator } = form;
        let chargeType = getField("invoiceInfo.chargeType");
        const options = [
            <RadioButton key={0} value={false} >{getIntl("no")}</RadioButton>,
            <RadioButton key={1} value={true} >{getIntl("yes")}</RadioButton>
        ];
        if(chargeType) 
            return (
                <FormItem label={getIntl("beChargedReconnection")}>
                    {
                        getFieldDecorator('beCharged', 
                            {
                                rules: [{
                                    required: true,
                                    message: getIntl("beChargedError"), 
                                }],
                            }
                        )( <RadioGroup>{options}</RadioGroup>)
                    }
                </FormItem>
            );
    }

    fullRender = () => {
        const { renderDefaultForm, renderChargeOption, renderCharge } = this;
        return (
            <div>
                {renderDefaultForm()}
                {renderChargeOption()}
                {renderCharge()}
            </div>
        );
    }
    
	render(){
        return this.fullRender();
	}
}

Reconnection.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    saveFields: PropTypes.func.isRequired,
    getField: PropTypes.func.isRequired,
    isUpdate: PropTypes.bool.isRequired
};

export default injectIntl(Reconnection);