import PropTypes from 'prop-types';
import { ArrowDownOutlined, ArrowUpOutlined, CaretDownOutlined, CaretLeftOutlined, CaretUpOutlined, EditOutlined } from '@ant-design/icons';
import { Col, Row, Skeleton, Avatar, List, Statistic } from 'antd';
import { injectIntl } from 'react-intl';
import React from 'react';
import { getReportMessages, getIncomeMessages } from '../../../constants/messages';
import moment from 'moment';
import { roundN } from '../../../utilities/util'
import Ellipsis from 'ant-design-pro/lib/Ellipsis';

let reportMessages = getReportMessages(),
    incomeMessages = getIncomeMessages()

const messages = {
    ...reportMessages,
    ...incomeMessages
};

class EntryCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            showComplete: false,
        }
    }

    componentDidMount() {
    }

    ShowMoreIcon = () => {
        if (this.state.showComplete)
            return <CaretDownOutlined onClick={this.changeCardDisplay} />
        return <CaretLeftOutlined onClick={this.changeCardDisplay}/>
    }

    changeCardDisplay = () => {
        this.setState({
            showComplete: !this.state.showComplete
        });
    }

    getIconData = (condition) => {
        if (condition) 
            return {
                icon: <ArrowUpOutlined />,
                moneyIcon: <CaretUpOutlined />,
                color: '#87d068'
            }
        return {
            icon: <ArrowDownOutlined />,
            moneyIcon: <CaretDownOutlined />,
            color: '#f4a442',
        }
    }

    openEdit = () => {
        this.props.openEditModal(this.props.entry, { id: this.props.entry.id, previousDate: this.props.entry.date});
    }

    displayTitle = (iconData) => {
        const { props: {entry, intl} } = this;
        let balanceIcon = this.getIconData(entry.balance > 0);
        return (
            <div>
                <Row>
                    <Col lg={9} xs={6} md={6} sm={6}>
                        <Ellipsis length={35} tooltip>{entry.description}</Ellipsis>
                    </Col>
                    <Col lg={2} xs={2} md={2} sm={2}>
                        {/* <Button onClick={this.openEdit} size="small" type="dashed" shape="circle" icon="edit" /> */}
                        <EditOutlined onClick={this.openEdit}></EditOutlined>
                    </Col>
                    <Col lg={5} xs={7} md={7} sm={5} className="entryCardTitle">
                        <Statistic
                            title="Cantidad"
                            value={intl.formatMessage({ ...messages.lempirasAmount }, {amount: roundN(entry.value, 2)})}
                            precision={2}
                            valueStyle={{ color: iconData.color, fontSize: "17px" }}
                            prefix={iconData.moneyIcon}
                        />
                    </Col>
                    <Col className="entryCardDescription" lg={5} xs={7} md={7} sm={5}>
                        <Statistic
                            title="Balance"
                            value={intl.formatMessage({ ...messages.lempirasAmount }, {amount: roundN(entry.balance, 2)})}
                            precision={2}
                            valueStyle={{ color: balanceIcon.color, fontSize: "17px" }}
                            prefix={balanceIcon.moneyIcon}
                        />
                    </Col>
                    <Col lg={2} xs={2} md={2} sm={2} style={{ paddingTop: "3px" }}>
                        <a>{this.ShowMoreIcon()}</a>
                    </Col>
                </Row>
            </div>
        );
    }

    displayCompleteData = () => {
        let formattedEntryDate = moment(this.props.entry.date).format('YYYY/MM/DD');
        if (this.state.showComplete)
            return (
                <div>
                    <Row>
                        <Col span={24}>
                            ({formattedEntryDate}): {this.props.entry.extraDetail}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            {this.props.intl.formatMessage({ ...messages.refNumber })}: {this.props.entry.referenceNumber}
                        </Col>
                    </Row>
                    <Row >
                        {this.displayDetails()}
                    </Row>
                </div>
            )
        return (<div>
            {formattedEntryDate}

        </div>)
    }

    displayDetails() {
        return (
            <List
                dataSource={this.props.entry.EntryCategories}
                renderItem={(item) => {
                    return (<div className="details">
                        <Row className="miniItemList">
                            <Col span={9}>
                                <Ellipsis length={18} tooltip>{`${item.category.name}`}</Ellipsis>
                            </Col>
                            <Col span={5}>
                                {this.props.currencyDisplayer.symbol} {roundN(item.amount, 2)}
                            </Col>
                            <Col span={8}>
                                <Ellipsis length={18} tooltip>{`${item.description}`}</Ellipsis>
                            </Col>
                        </Row>
                    </div>);
                }
                }
            />
        );
    }

    render() {
        let iconData = this.getIconData(this.props.entry.isIncome);
        return (
            <div className="entryCard">
                <List.Item onDoubleClick={this.changeCardDisplay}>
                    <Skeleton avatar title={false} loading={this.state.loading} active>
                        <List.Item.Meta
                            title={this.displayTitle(iconData)}
                            description={this.displayCompleteData(iconData)}
                            avatar={
                                <Avatar 
                                    icon={iconData.icon} 
                                    shape="circle" 
                                    style={{ backgroundColor: iconData.color }} 
                                />
                            }
                        />
                    </Skeleton>
                </List.Item>
            </div>
        );
    }
}

EntryCard.propTypes = {
    intl: PropTypes.object.isRequired,
    entry: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    currencyDisplayer: PropTypes.object.isRequired,
    openEditModal: PropTypes.func.isRequired,
};

export default injectIntl(EntryCard);