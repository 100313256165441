import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Popover, message, Input } from 'antd';
import { injectIntl } from 'react-intl';
import { getJobMessages, getModalMessages } from '../../../constants/messages';
import { parseJobValues, JOBSTATUS, JOBTYPES } from '../constants';
import { UpdateJob, CancelAnnulJob, MoveToDoing, CompleteJob, AddWon } from '../actions';
const { TODO, DOING, MISSINGWON, CANCELLED, ANNULLED } = JOBSTATUS;
const { DEACTIVATE } = JOBTYPES;

const FormItem = Form.Item;

let modalMessages = getModalMessages(),
    JobsMessages = getJobMessages();

const messages = {
	...modalMessages,
    ...JobsMessages
};

class Buttons extends React.Component  {

	constructor(props) {
        super(props);
        this.state = {
            cancelAnnulOpen: false
        };
	}
    
    getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    loadingButton = (key) => {
        const { isLoading, loadingKey } = this.props;
        return isLoading && loadingKey === key
    }

    disabledButton = (key) => {
        const { isLoading, loadingKey } = this.props;
        return isLoading && loadingKey !== key
    }
    
    // Functionality (start) //

    validateFieldsWithDispatch = (dispatchFunction, successMessage, errorMessage, fieldNames = undefined) => {
        const { form, jobId, setLoading, saveJob, setIsUpdating } = this.props;
        const { validateFieldsAndScroll } = form;   
        validateFieldsAndScroll(fieldNames, { force: true }, (err, values) => {
            if (!err && jobId) {
                setLoading(true);
                dispatchFunction(jobId, parseJobValues(values))
                .then(updatedJob => {
                    setLoading(false);
                    message.success(successMessage);
                    setIsUpdating(false);
                    saveJob(updatedJob);
                })
                .catch(() => {
                    setLoading(false);
                    message.error(errorMessage);
                });
            }
        });
    }

    standardValidate = (dispatchFunction, successIntl, errorIntl, loadingKey, fieldNames = undefined) => {
        const { props, getIntl, validateFieldsWithDispatch } = this;
        const { setLoadingKey } = props;
        const dispatch = props[dispatchFunction];
        setLoadingKey(loadingKey);
        validateFieldsWithDispatch(dispatch, getIntl(successIntl), getIntl(errorIntl), fieldNames);
    }

    updateJob = (key) => this.standardValidate("updateJob", "jobUpdateSuccess", "jobUpdateError", key);

    moveToDoing = (key) => this.standardValidate("moveToDoing", "jobStatusUpdateSuccess", "jobStatusUpdateError", key);

    completeJob = (key) => this.standardValidate("completeJob", "jobInfoSaveSuccess", "jobInfoSaveError", key);

    addWON = (key) => this.standardValidate("addWON", "jobAddWONSuccess", "jobAddWONError", key);

    cancelJob = (key) => this.standardValidate("cancelJob", "jobCancelSuccess", "jobCancelError", key, ['reason']);

    annulJob = (key) => this.standardValidate("annulJob", "jobAnnulSuccess", "jobAnnulError", key, ['reason']);

    // Functionality (end) //

    // Renders (start) //

    renderStandardButtonTemplate = (key, onClick, buttonIntl) => {
        const { props, getIntl } = this;
        const { isLoading } = props;
        return (
            <Button key={key} onClick={onClick} disabled={isLoading} >
                {getIntl(buttonIntl)}
            </Button>
        );
    }

    renderCloseButton = () => {
        const { props, renderStandardButtonTemplate } = this;
        const { handleCancel } = props;
        return renderStandardButtonTemplate("close", handleCancel, "close");
    }

    renderCancelEditButton = () => {
        const { props, renderStandardButtonTemplate } = this;
        const { setIsUpdating } = props;
        return renderStandardButtonTemplate("cancel-edit", () => setIsUpdating(false), "cancelEdit");
    }

    renderCancelAnnulForm = (labelIntl, onClick, buttonIntl, key) => {
        const { state, props, getIntl, loadingButton, disabledButton } = this;
        const { cancelAnnulOpen } = state;
        const { getFieldDecorator, getFieldValue } = props.form;
        return (
            <div>
                <FormItem label={getIntl(labelIntl)} >
                {
                    getFieldDecorator('reason',
                        {
                            rules: cancelAnnulOpen ? [
                                {
                                    required: true,
                                    message: getIntl("jobCancelAnnulRequiredError")
                                },
                                {
                                    max: 255,
                                    message: getIntl("jobCancelAnnulLengthError")
                                },
                                {
                                    whitespace: true,
                                    message: getIntl("jobCancelAnnulWhitespaceError")
                                }
                            ] : []
                        }
                    )(
                        <Input className="job-full-component" />
                    )
                }
                </FormItem>
                <Button type="primary" onClick={onClick} disabled={!getFieldValue('reason') || disabledButton(key)} loading={loadingButton(key)}>
                    {getIntl(buttonIntl)}
                </Button>
            </div>
        );
    }

    renderCancelAnnulButton = (key, formLabelIntl, formOnClick, formButtonIntl, buttonIntl) => {
        const { getIntl, renderCancelAnnulForm, loadingButton, disabledButton } = this;
        const form = renderCancelAnnulForm(formLabelIntl, formOnClick, formButtonIntl, key);
        return (
            <Popover key={key} content={form} trigger="click" onOpenChange={(cancelAnnulOpen) => this.setState({ cancelAnnulOpen })}>
                <Button type="danger" disabled={disabledButton(key)} loading={loadingButton(key)}>
                    {getIntl(buttonIntl)}
                </Button>
            </Popover>
        );
    }

    renderCancelButton = () => {
        const { cancelJob, renderCancelAnnulButton } = this;
        const key = "cancel";
        return renderCancelAnnulButton(key, "cancelReason", () => cancelJob(key), "confirmCancel", "cancel");
    }

    renderAnnulButton = () => {
        const { annulJob, renderCancelAnnulButton } = this;
        const key = "annul";
        return renderCancelAnnulButton(key, "annulReason", () => annulJob(key), "confirmAnnul", "annul");
    }

    renderPrimaryButtonTemplate = (key, onClick, buttonIntl) => {
        const { getIntl, loadingButton, disabledButton } = this;
        return (
            <Button key={key} type="primary" onClick={onClick} disabled={disabledButton(key)} loading={loadingButton(key)}>
                {getIntl(buttonIntl)}
            </Button>
        );
    }

    renderMoveToDoingButton = () => {
        const { renderPrimaryButtonTemplate, moveToDoing } = this;
        const key = "move-to-doing";
        return renderPrimaryButtonTemplate(key, () => moveToDoing(key), "moveToDoing");
    }

    renderCompleteJobButton = () => {
        const { renderPrimaryButtonTemplate, completeJob } = this;
        const key = "complete-job";
        return renderPrimaryButtonTemplate(key, () => completeJob(key), "save");
    }

    renderMoveToDoneButton = () => {
        const { renderPrimaryButtonTemplate, addWON } = this;
        const key = "move-to-done";
        return renderPrimaryButtonTemplate(key, () => addWON(key), "moveToDone");
    }

    renderUpdateButton = () => {
        const { updateJob, renderPrimaryButtonTemplate } = this;
        const key = "edit";
        return renderPrimaryButtonTemplate(key, () => updateJob(key), "edit");
    }

    // Renders (end) //

    fullRender = () => {
        const { props, renderCloseButton, renderCancelEditButton, renderCancelButton, renderAnnulButton, renderMoveToDoingButton, renderCompleteJobButton, renderMoveToDoneButton, renderUpdateButton } = this;
        const  { jobId, closeOnly, isUpdating, status, type } = props;

        const buttons = [renderCloseButton()];
        if (!jobId) return buttons;
        if (closeOnly) return buttons;
        if (isUpdating) {
            buttons.push(renderCancelEditButton(), renderUpdateButton());
            return buttons;
        }
        if (status === TODO.id || status === DOING.id) buttons.push(renderCancelButton(), renderAnnulButton());
        if (type === DEACTIVATE.id) return buttons;
        if (status === TODO.id) buttons.push(renderMoveToDoingButton());
        if (status === DOING.id) buttons.push(renderCompleteJobButton());
        if (status === MISSINGWON.id) buttons.push(renderMoveToDoneButton());
        return buttons;
    }
    
	render(){
        return this.fullRender();
	}
}

Buttons.defaultProps = {
    jobId: 0,
    isUpdating: false,
    status: 0,
    type: 0
};

Buttons.propTypes = {
	intl: PropTypes.object.isRequired,
    updateJob: PropTypes.func.isRequired,
    moveToDoing: PropTypes.func.isRequired,
    completeJob: PropTypes.func.isRequired,
    addWON: PropTypes.func.isRequired,
    cancelJob: PropTypes.func.isRequired,
    annulJob: PropTypes.func.isRequired,
    
	form: PropTypes.object.isRequired,
    handleCancel: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    setLoadingKey: PropTypes.func.isRequired,
    loadingKey: PropTypes.string.isRequired,
	saveJob:  PropTypes.func.isRequired,
    setIsUpdating: PropTypes.func.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    jobId: PropTypes.number.isRequired,

    closeOnly: PropTypes.bool.isRequired,
    status: PropTypes.number.isRequired,
    type: PropTypes.number.isRequired
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateJob: (jobId, job) => dispatch(UpdateJob(jobId, job)),
        moveToDoing: (jobId, job) => { const { comments } = job; return dispatch(MoveToDoing(jobId, comments)); },
		completeJob: (jobId, job) => dispatch(CompleteJob(jobId, job)),
		addWON: (jobId, job) => dispatch(AddWon(jobId, job)),
        cancelJob: (jobId, job) => { const { reason } = job; return dispatch(CancelAnnulJob(jobId, CANCELLED.id, reason)); },
        annulJob: (jobId, job) => { const { reason } = job; return dispatch(CancelAnnulJob(jobId, ANNULLED.id, reason)); }
	};
};

export default connect(null, mapDispatchToProps)(injectIntl(Form.create()(Buttons)));