import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Row, Col, Select } from 'antd';
import { getAuthMessages, getModalMessages, getFields, getErrorMessages, getRegistrationMessages, getClientMessages } from '../../../../constants/messages';
import { injectIntl } from 'react-intl';
import { REGEX } from '../../../../constants/global';
import RemoveButton from './RemoveButton';
import { CheckClientEmailExistance } from '../../../../actions/OptimusActions';
import debounce from 'lodash/debounce';

let authMessages = getAuthMessages(), 
  errorMessages = getErrorMessages(), 
  fieldMessages = getFields(), 
  modalMessages = getModalMessages(), 
  RegistrationMessages = getRegistrationMessages(), 
  ClientMessages = getClientMessages();

const messages = { 
  ...authMessages, 
  ...modalMessages, 
  ...fieldMessages, 
  ...errorMessages, 
  ...RegistrationMessages, 
  ...ClientMessages 
};

const FormItem = Form.Item;
const Option = Select.Option;

class Email extends React.Component {

	constructor(props) {
		super(props);
		this.checkIfEmailExists = debounce(this.checkIfEmailExists, 250);
		this.state = {
            validateStatus: undefined
        };
	}

    getIntl = (str, values = {}) => this.props.intl.formatMessage({...messages[str]}, values);

    renderName = () => {
        const {intl, form, email, emailIndex, saveNameFunction} = this.props;
        const { getFieldDecorator } = form;
        const whitespace = REGEX.whitespace;
        let name = (email.name !== null && email.name !== undefined && whitespace.test(email.name)) ? email.name : "";
        return (
			<FormItem>
			{
				getFieldDecorator(`name[${emailIndex}]`, 
					{
						initialValue: name,
						trigger: 'onChange',
						validateTrigger: ['onChange'],
						onChange: (event) => saveNameFunction(event.target.value, emailIndex),
					}
				)(
					<Input placeholder={intl.formatMessage({...messages.emailOwner} )}/>
				)
			}
			</FormItem>
        );
    }

    renderTags = () => {
        const {intl, form, tags, email, emailIndex, saveTagsFunction} = this.props;
        const { getFieldDecorator } = form; 
        const tagItems = tags.map(tag => <Option key={tag.id} value={tag.id}>{tag.name}</Option>);
        return (
            <FormItem>
                {
                getFieldDecorator(`tag[${emailIndex}]`, 
                    {
                        initialValue: (email.tag !== undefined && email.tag !== null) ? email.tag.map(tag => tag.id) : undefined,
                        trigger: 'onChange',
                        validateTrigger: ['onChange'],
                        rules: [{
                            required: true,
                            message: intl.formatMessage( {...messages.tagError} )
                        }],
                        onChange: (value) => saveTagsFunction(value, emailIndex),
                    }
                    )(
                    <Select
                        mode="multiple"
                        placeholder={intl.formatMessage( {...messages.tags} )}
                        style={{ width: '100%' }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {tagItems}
                    </Select>
                    )
                }
            </FormItem>
        );
    }

    checkIfEmailExists = (rule, email, callback) => {
        const { props, getIntl } = this;
        const { optimusClientId, saveIsUnique } = props;
        const setStateAndCallback = (validateStatus, errorMessage) => {
            this.setState({ validateStatus });
            callback(errorMessage);
        }
        const clear = () => {
            saveIsUnique(true);
            setStateAndCallback(undefined, undefined);
        }

        if (!email || email.length <= 0 ) clear();
        else {
            this.setState({ validateStatus: "validating" })
            CheckClientEmailExistance(email, optimusClientId)
            .then(count => {
                if (count) {
                    saveIsUnique(false);
                    setStateAndCallback("warning", undefined);
                }
                else clear();
            })
            .catch(() => setStateAndCallback(undefined, getIntl("checkEmailError")));
        }
    }

    renderEmail = () => {
        const { state, props, getIntl, checkIfEmailExists } = this;
        const { validateStatus } = state;
        const { intl, form, email, emailIndex, saveEmailFunction, removeEmailFunction,emailsCount } = props;
        const { getFieldDecorator } = form;
        const hasFeedback = validateStatus ? true : undefined;
        const help = validateStatus ? validateStatus === "warning" ? getIntl("notUniqueEmailError") : getIntl("validatingEmail") : undefined;
        return (
            <Row>
                <Col span={23}>
                    <FormItem validateStatus={validateStatus} hasFeedback={hasFeedback} help={help}>
                    {
                    getFieldDecorator(`emails[${emailIndex}]`, 
                        {
                            initialValue: email.email,
                            trigger: 'onChange',
                            validateTrigger: 'onChange',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: intl.formatMessage( {...messages.emailEmptyError} )
                                },
                                {
                                    pattern: REGEX.email,
                                    message: intl.formatMessage( {...messages.emailInvalidError})
                                },
                                {
                                    validator: checkIfEmailExists
                                }
                            ],
                            onChange: (event) => saveEmailFunction(event.target.value, emailIndex),
                        }
                        )(
                        <Input placeholder={intl.formatMessage( {...messages.emailPlaceholder} )} style={{ width: '95%' }}/>
                        )
                    }
                    </FormItem>
                </Col>
                
                {emailsCount > 1 && (
					<Col span={1} style={{ paddingTop: "4px" }}>
                     <RemoveButton removeFunction={() => removeEmailFunction(emailIndex)} toolTipText={intl.formatMessage({...messages.removeEmail})}/>
                    </Col>
				)}
            </Row>
        );
    }

    fullEmailRender = () => {
        return (
            <div>
                {this.renderEmail()}
                {this.renderTags()}
                {this.renderName()}
            </div>
        );
    }

    render() {
        return ( 
            this.fullEmailRender()
        ); 
    }

}

Email.defaultProps = { 
    email: {},
    tags: [],
    optimusClientId: -1
};

Email.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    email: PropTypes.object.isRequired,
    emailIndex: PropTypes.number.isRequired,
    tags: PropTypes.array.isRequired,
    saveEmailFunction: PropTypes.func.isRequired,
	removeEmailFunction: PropTypes.func.isRequired,
    saveTagsFunction: PropTypes.func.isRequired,
    saveNameFunction: PropTypes.func.isRequired,
    optimusClientId: PropTypes.number,
    saveIsUnique: PropTypes.func.isRequired,
    emailsCount: PropTypes.number
};

export default injectIntl(Email);