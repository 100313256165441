import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { getactionMessages } from '../../constants/messages';
import AddOrEditModal from './AddOrEditModal';

let ActionMessages = getactionMessages();

const messages = {
	...ActionMessages,
};

const AddButton = ({ updateUnits, models }) => {
	const intl = useIntl();
	const [visible, setVisible] = useState(false);

	const setModalVisibility = (visibility) => {
		setVisible(visibility);
	};
	return (
		<span>
			<Button
				className="editable-add-btn"
				type="primary"
				onClick={() => setModalVisibility(true)}
			>
				<PlusOutlined />
				{intl.formatMessage({...messages.add})}
			</Button>
			<AddOrEditModal
				title={intl.formatMessage({ ...messages.add})}
				visible={visible}
				onCancel={() => setModalVisibility(false)}
				updateUnits={updateUnits}
				models={models}
			/>
		</span>
	);
};

AddButton.propTypes = {
	updateUnits: PropTypes.func,
	models: PropTypes.array,
};

export default AddButton;
