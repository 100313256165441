import React from 'react'
import PropTypes from 'prop-types';
import { Col, Form, Row, Select } from 'antd';
import { useIntl } from 'react-intl';
import { getErrorMessages, getFields, getLotsMessages } from '../../constants/messages';

const Option = Select.Option;
const FormItem = Form.Item;

let FieldsMessages = getFields(),
    ErrorMessages = getErrorMessages(),
    LotsMessages = getLotsMessages();

const messages = {
	...FieldsMessages,
    ...LotsMessages,
    ...ErrorMessages,
};

const AssignTechnicalForm = ({ form, technicals }) => {
	const intl = useIntl();

  const technicalOptions = technicals.map((tech) => (
    <Option key={tech.id} value={tech.name}>
        {tech.name}
    </Option>
  ));

  return (
    <div>
        <Form
            ref={form}
            layout='vertical'
        >
            <Row type="flex" justify="center" align="bottom">
                <Col span={24}>
                    <FormItem 
                        name="technical"
                        label={intl.formatMessage({ ...messages.technicals })} 
                        rules={[
                            {
                                required: true, 
                                message: intl.formatMessage({...messages.technicalError}) 
                            }
                        ]} 
                    >
                        <Select
                            showSearch
                            placeholder={intl.formatMessage({ ...messages.technicalPlaceholder })}
                            optionFilterProp="children"
                        >
                            {technicalOptions}
                        </Select>
                    </FormItem>
                </Col>
            </Row>
        </Form>
    </div>
  )
}

AssignTechnicalForm.propTypes = {
  form: PropTypes.object.isRequired,
  technicals: PropTypes.array,
}

export default AssignTechnicalForm;
