import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getInvoicingMessages, getModalMessages, getactionMessages, getErrorMessages, getFiscalDataMessages, getProductMessages, getClientMessages } from '../constants/messages';
import { CloseCircleOutlined, MinusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
	Button,
	Input,
	Select,
	Spin,
	Row,
	Col,
	InputNumber,
	Table,
	Radio,
	message,
	DatePicker,
	Popconfirm,
	Divider
} from 'antd';
import { GetClientsList, CleanClients, GetAllClients, GetClientInfo, GetEmails, GetClientAliases } from '../features/Clients/actions';
import { SearchProducts, CleanProducts, GetFirstProducts, GetProductConfigurations, GetProductJobs } from '../features/Products/actions';
import { GetISV } from '../features/Invoices/actions';
import CheckExchangeRate from '../features/ExchangeRate/CheckExchangeRate';
import { injectIntl, FormattedMessage } from 'react-intl';
import { roundN, isEmpty, existsInArray, getNestedValue } from '../utilities/util';
import { VARIOUS_CLIENTS_CLIENT_ID, REGEX } from '../constants/global';
import debounce from 'lodash/debounce';
import { states } from '../constants/address';
import InvoiceSummary from './InvoiceSummary';
import InvoiceMonthChange from '../features/Invoices/InvoiceMonthChange';
import { INVOICE_TYPES } from '../features/Invoices/constants';
import InvoiceDevicesPicker from '../features/Invoices/InvoiceDevicesPicker';
import EditProductModal from './EditProductModal';
import { ClearSelectedJobs } from '../features/Jobs/actions';
const moment = require('moment');

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const { MonthPicker } = DatePicker;

let invoicingMessages = getInvoicingMessages(),
	errorMessages = getErrorMessages(),
	ProductMessages = getProductMessages(),
	actionMessages = getactionMessages(),
	modalMessages = getModalMessages(),
	fiscalDataMessages = getFiscalDataMessages(),
	clientMessages = getClientMessages();

const messages = {
	...invoicingMessages,
	...modalMessages,
	...actionMessages,
	...errorMessages,
	...ProductMessages,
	...fiscalDataMessages,
	...clientMessages
};

const monthDateFormat = "MM-YYYY";
const dateCreator = date => moment(date).set({ date: 15, hour: 0, minute: 0, second: 0, millisecond: 0 });

class AddInvoicingDocumentForm extends React.Component {
	constructor(props) {
		super(props);
		this.fetchProducts = debounce(this.fetchProducts, 100);

		this.state = {
			selectedProduct: { id: null },
			originalPrice: 0,
			price: 0,
			quantity: 1,
			products: [],
			jobsId: [],
			subtotal: 0,
			isCredit: true,
			clientsSelect: [],
			productsSelect: [],
			needsApproval: 0,
			variousClients: false,
			daysForPayment: 15,
			total: 0,
			isLoading: false,
			taxExemption: false,
			showEmailModal: false,
			showExchangeRateModal: false,
			exchangeRate: 0,
			observations: "",
			clientName: "",
			rtn: "",
			representative: "",
			city: "",
			address: "",
			cities: [],
			clientInfo: undefined,
			selectedProductConfigs: [],
			nextInvoiceMonth: undefined,
			monthPickerChanged: false,
			clientAliases: [],
			clientAlias: "",
			cachedPrice: 0,
			isDataLoading: true,
			devices: [],
			showEditProductModal: false,
			currentProduct: undefined,
			checkInJobs: false,
		};
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	setLoading = (isLoading) => {
		this.setState({ isLoading });
	}

	componentDidMount() {
		const { props, getIntl, handleSelectClient, getProductConfigurations, addDuplicatedProducts } = this;
		const { getAllClients, clients, getFirstProducts, products, getProducts, getEmails, getISV, selectedJobs, invoiceJobs, proformaJobs } = props;
		getProductConfigurations();
		getISV();
		getAllClients().then(() => {
			this.setState({
				clientsSelect: [...clients]
			});
		}).catch(() => {
			message.error(getIntl("GetClientsError"));
		});
		getFirstProducts().then(() => {
			this.setState({
				productsSelect: [...products]
			});
		}).catch(() => {
			message.error(getIntl("errorGetProducts"));
		});
		if (props.document !== undefined) {
			const { id, type, exchangeRate, daysForPayment, clientId, observations, clientName } = props.document;
			getProducts(id).then((products) => {
				let createdAt = moment(props.document.createdAt);
				let paymentDate = moment(props.document.paymentDate);
				this.setState({
					isCredit: type === INVOICE_TYPES.CREDIT,
					exchangeRate,
					daysForPayment: daysForPayment !== undefined ? daysForPayment : paymentDate.diff(createdAt, 'days'),
					observations
				});
				handleSelectClient(clientId, clientName);
				addDuplicatedProducts(products);
				getEmails(clientId).catch(() => {
					message.error(getIntl("GetClientInfoError"));
				});
			});
		}
		if(invoiceJobs || proformaJobs){
			handleSelectClient(selectedJobs[0].clientId, selectedJobs[0].clientName);
			this.addProductsJobs();
		}
	}

	componentWillUnmount() {
		this.setState({
			isCredit: undefined,
			exchangeRate: undefined,
			daysForPayment: undefined,
			observations: undefined,
			productsSelect: undefined,
			clientsSelect: undefined,
			products: undefined
		})
		this.props.clearSelectedJobs();
	}

	addProductsJobs = () => {
		const { selectedJobs, isv } = this.props;
		let products = [];
		let jobsId = [];
		let subtotal = 0;
		let indexProduct = -1;
		selectedJobs.forEach((jobs, index) => {
			GetProductJobs(jobs.productId).then((response) => {
				let productToAdd = {
					product: response,
					productName: response.code,
					description: response.description,
					price: response.price,
					quantity: 1,
					total: response.price,
					approvalNeeded: false,
					priceChangeReason: undefined
				};
				jobsId.push(jobs.jobId);
				subtotal +=  productToAdd.price;
				if(products.length === 0 || products.filter((x, index) => {
					if(x.productName === productToAdd.productName){
						indexProduct = index; 
						return x;
					}}) <= 0 )
					products.push(productToAdd);
				else
					products[indexProduct].quantity = products[indexProduct].quantity + 1;
				if(selectedJobs.length-1 === index)
					this.setState({ jobsId,	products, subtotal, total: subtotal + (subtotal * isv), checkInJobs: true });
			})
		});	
	};

	addDuplicatedProducts = (products) => {
		const { isv } = this.props;
		let subtotal = 0;
		const newProductList = products.map(product => {
			const { product: baseProduct, price, quantity } = product;
			const { code } = baseProduct;
			const total = price * quantity;
			subtotal += total;
			return {
				...product,
				total,
				productName: code,
				approvalNeeded: false,
				product: baseProduct,
			};
		});
		this.setState({
			subtotal,
			products: newProductList,
			total: subtotal + subtotal * isv
		});
	}

	getProductConfigurations = () => {
		const { props, getIntl, setLoading } = this;
		const { getProductConfigurations } = props;
		setLoading(true);
		getProductConfigurations()
			.then(() => setLoading(false))
			.catch(() => {
				setLoading(false);
				message.error(getIntl("getProductConfigurationsError"));
			});
	}

	openExchangeRateModal = () => {
		this.setState({
			showExchangeRateModal: true
		});
	}

	handleExchangeRateCancel = () => {
		this.setState({
			showExchangeRateModal: false
		});
	}

	setExchangeRate = (rate) => {
		this.setState({
			exchangeRate: rate
		});
	}

	padWithZeroes = (number) => {
		let my_string = '' + number;
		while (my_string.length < 8) {
			my_string = '0' + my_string;
		}

		return my_string;
	}

	getPrice = () => {
		const { state, disableProductSelections } = this;
		const { price } = state;
		return (
			<InputNumber
				className="full-component"
				disabled={disableProductSelections()}
				precision={2}
				formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
				parser={value => value.replace(/\$\s?|(,*)/g, '')}
				onChange={price => this.setState({ price })}
				value={price}
				min={0}
			/>
		);
	}

	renderChangedPriceComment = () => {
		const { state, props, getIntl } = this;
		const { price: p, cachedPrice: cp } = state;
		const { getFieldDecorator } = props.form;
		const price = roundN(p, 2);
		const cachedPrice = roundN(cp, 2);
		if (price != cachedPrice)
			return (
				<FormItem label={getIntl("priceChangeReason")} >
					{
						getFieldDecorator('priceChangeReason',
							{
								rules: [
									{
										required: price != cachedPrice,
										whitespace: true,
										message: getIntl("priceChangeReasonError")
									},
									{
										max: 255,
										message: getIntl("priceChangeReasonLengthError")
									}
								],
								onChange: event => this.setState({ priceChangeReason: event.target.value })
							}
						)(
							<Input className="full-component" placeholder={getIntl("priceChangeReason")} />
						)
					}
				</FormItem>
			);
	}

	getTotal = () => {
		const { state, disableProductSelections } = this;
		const { quantity, price } = state;
		return (
			<Input disabled={disableProductSelections()} readOnly value={`$ ${roundN(quantity * price, 2)}`} />
		);
	}

	onSelectProduct = (product) => {
		let selectedProduct;
		for (let i = 0; i < this.props.products.length; i++) {
			if (this.props.products[i].id === product) {
				selectedProduct = { ...this.props.products[i] };
				break;
			}
		}

		let customPrice;
		let found = false;
		this.props.customPrices.forEach((customProduct) => {
			if (customProduct.productId === product) {
				customPrice = customProduct.price;
				found = true;
			}
		});
		this.props.cleanProducts();
		if (this.props.currentIsLps) {
			customPrice = customPrice / this.state.exchangeRate;
		}
		this.props.getFirstProducts().then(() => {
			this.setState({
				productsSelect: [...this.props.products],
				selectedProduct: selectedProduct,
				price: found ? customPrice : selectedProduct.price,
				cachedPrice: found ? customPrice : selectedProduct.price
			});
		}).catch(() => {
			message.error(`${this.props.intl.formatMessage({ ...messages.errorGetProducts })}`);
		});
	}

	onChangeQuantity = (quantity) => {
		this.setState({
			quantity: quantity
		});
	}

	onChangeExchangeRate = (value) => {
		this.setState({
			exchangeRate: value
		});
	}

	onChangeObservations = (value) => {
		this.setState({
			observations: value.target.value
		});
	}

	onChangeClientName = (clientName) => {
		this.setState({ clientName });
	}

	onChangeClientAlias = (clientAlias) => {
		this.setState({ clientAlias });
		this.props.resetFields(['clientAlias']);
	}

	onChangeRtn = (value) => {
		this.setState({
			rtn: value.target.value
		});
	}

	onChangeRepresentative = (value) => {
		this.setState({
			representative: value.target.value
		});
	}

	onChangeCity = (value) => {
		this.setState({
			city: value
		});
	}

	onChangeAddress = (value) => {
		this.setState({
			address: value.target.value
		});
	}

	onChangeType = (invoiceType) => {
		if (invoiceType.target.value === INVOICE_TYPES.CREDIT) {
			this.setState({
				isCredit: true
			});
		} else {
			this.setState({
				isCredit: false
			});
		}
	}

	handleSelectState = (state, obj) => {
		this.setState({
			cities: states[obj.key].cities
		});
	}

	mapStates = () => {
		return (
			<Select
				showSearch
				optionFilterProp="children"
				filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
				dropdownMatchSelectWidth={false}
				onSelect={this.handleSelectState}
				notFoundContent={<FormattedMessage {...messages.notFound} />}
			>
				{
					states.map((state, index) => {
						return (<Option key={index} value={state.name}>{state.name}</Option>);
					})
				}
			</Select>
		);
	}

	mapCities = () => {
		return (
			<Select
				className="job-full-component"
				showSearch
				optionFilterProp="children"
				filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
				dropdownMatchSelectWidth={false}
				onSelect={this.onChangeCity}
			>
				{this.state.cities.map((city, index) => {
					return (<Option key={index} value={city}>{city}</Option>);
				})
				}
			</Select>
		);
	}

	stringifyMoment = (date) => {
		const m = moment(date).locale('es').format('MMMM YYYY');
		return m.charAt(0).toUpperCase() + m.slice(1);
	}

	addMonthStr = () => {
		const { props, stringifyMoment } = this;
		const { getFieldValue } = props.form;
		const monthToCharge = getFieldValue('monthToCharge');
		if (!monthToCharge) return "";
		return stringifyMoment(monthToCharge);
	}

	monthCheck = () => {
		const { props, isProductConfig } = this;
		if (!isProductConfig()) return true;
		const { getFieldValue } = props.form;
		const monthToCharge = getFieldValue('monthToCharge');
		return monthToCharge ? true : false;
	}

	priceChangeReasonCheck = () => {
		const { state, props } = this;
		const { price: p, cachedPrice: cp } = state;
		const { getFieldValue } = props.form;
		const price = roundN(p, 2);
		const cachedPrice = roundN(cp, 2);
		const whitespace = REGEX.whitespace;
		if (price != cachedPrice) {
			const priceChangeReason = getFieldValue('priceChangeReason');
			if (!priceChangeReason || priceChangeReason.length < 1 || !whitespace.test(priceChangeReason)) return false;
		}
		return true;
	}

	addProductChecks = () => {
		const { state, monthCheck, getIntl, priceChangeReasonCheck } = this;
		const { selectedProduct, price, quantity } = state;
		const warnUser = (intl) => { message.warning(getIntl(intl)); return false; }
		if (isEmpty(selectedProduct) || selectedProduct.id === null || selectedProduct.id === undefined) return warnUser("noProductWarning");
		else if (price <= 0) return warnUser("zeroPriceWarning");
		else if (quantity <= 0) return warnUser("zeroQuantityWarning");
		else if (!monthCheck()) return warnUser("noMonthWarning");
		else if (!priceChangeReasonCheck()) return warnUser("priceChangeReasonError");
		return true;
	}

	addToProducts = () => {
		const { state, props, isProductConfig, addMonthStr, addProductChecks, updateNextInvoiceMonthByProducts } = this;
		const { selectedProduct, price, quantity, products, subtotal, cachedPrice: cp } = state;
		const { isv, form, getAddedProducts, document } = props;
		const { getFieldValue } = form;
		const priceCheck = roundN(price, 2);
		const cachedPrice = roundN(cp, 2);
		if (addProductChecks()) {
			let approvalNeeded = price < selectedProduct.minPrice;
			const description = `${selectedProduct.description} ${isProductConfig() ? addMonthStr() : ""}`;
			let productToAdd = {
				product: selectedProduct,
				productName: selectedProduct.code,
				description: description,
				price: price,
				quantity: quantity,
				total: price * quantity,
				approvalNeeded: approvalNeeded,
				priceChangeReason: priceCheck != cachedPrice ? getFieldValue("priceChangeReason") : undefined
			};
			this.mapProducts(); // why is this here ??
			const newProduct = document ? getAddedProducts(productToAdd) : productToAdd;
			const newProducts = [...products, newProduct];
			this.setState({
				products: newProducts,
				subtotal: subtotal + productToAdd.total,
				total: (subtotal + productToAdd.total) * isv + subtotal + productToAdd.total,
			});
			updateNextInvoiceMonthByProducts(newProducts);
		}
	}

	updateNextInvoiceMonthByProducts = (products) => {
		const { state, props } = this;
		const { clientInfo, monthPickerChanged, variousClients } = state;
		const { productConfigurations } = props;
		if (monthPickerChanged || !clientInfo || variousClients) return;
		const { nextInvoiceMonth } = clientInfo;
		let newDate = dateCreator(nextInvoiceMonth);
		const productConfigIds = productConfigurations.map(pc => pc.productId);

		products.forEach(product => {
			if (existsInArray(product.product.id, productConfigIds)) {
				const strParts = product.description.split(" ");
				const monthStr = strParts[strParts.length - 2] + " " + strParts[strParts.length - 1];
				const productDate = dateCreator(moment(monthStr, 'MMMM YYYY', 'es'));
				if (productDate.isAfter(newDate, 'month') || productDate.isSame(newDate, 'month')) newDate = productDate.add(1, 'months');
			}
		});
		this.setState({ nextInvoiceMonth: newDate });
	}

	handleSelectClient = async (client, aliasCheck = "") => {
		this.setState({
			variousClients: client === VARIOUS_CLIENTS_CLIENT_ID,
		});
		if (client !== VARIOUS_CLIENTS_CLIENT_ID) {
			try {
				const clientInfo = await this.props.getClientInfo(client);
				const clientAliases = await GetClientAliases(client);
				this.props.resetFields(['clientAlias']);
				this.setState({
					clientsSelect: [clientInfo],
					clientInfo,
					nextInvoiceMonth: getNestedValue("nextInvoiceMonth", clientInfo),
					clientAliases,
					clientAlias: aliasCheck && aliasCheck != getNestedValue("name", clientInfo) ? aliasCheck : "",
					isDataLoading: false
				});
				try {
					await this.props.getAllClients();
				} catch (error) {
					message.error(`${this.props.intl.formatMessage({ ...messages.GetClientsError })}`);
				}
			} catch (error) {
				message.error(`${this.props.intl.formatMessage({ ...messages.GetClientInfoError })}`)
			}
		}
		this.setState({ isDataLoading: false });
	};

	mapClients = () => {
		return (
			<Select
				showSearch
				notFoundContent={this.state.isLoading ? <Spin size="small" /> : null}
				filterOption={(input, option) =>
					option.children.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0
				}
				onSelect={value => this.handleSelectClient(value)}
				style={{ width: '100%' }}
			>
				{
					this.state.clientsSelect.map(function (client, i) {
						return <Option key={i} value={client.id}>{client.name}</Option>;
					})
				}
			</Select>
		);
	}

	fetchProducts = (value) => {
		if (value.length > 0)
			this.setState({
				isLoading: true
			});
		this.props.searchProducts(value).then(() => {
			this.setState({
				productsSelect: [...this.props.products],
				isLoading: false
			});
		}).catch(() => {
			message.error(`${this.props.intl.formatMessage({ ...messages.errorGetProducts })}`);
		});
	}

	disableProductSelections = () => {
		const { clientInfo, variousClients } = this.state;
		return clientInfo === undefined && variousClients === false;
	}

	mapProducts = () => {
		const { state, disableProductSelections, fetchProducts, onSelectProduct } = this;
		const { isLoading, productsSelect } = state;
		return (
			<Select
				disabled={disableProductSelections()}
				showSearch
				notFoundContent={isLoading ? <Spin size="small" /> : null}
				filterOption={false}
				onSearch={fetchProducts}
				onSelect={onSelectProduct}
				style={{ width: '100%' }}
			>
				{
					productsSelect.map(function (product, i) {
						return <Option key={i} value={product.id}>{`${product.code} - ${product.description}`}</Option>;
					})
				}
			</Select>
		);
	}

	handleSubmit = (shouldEmail, updateNextInvoiceMonth = false) => {
		const { state, props: { handleSubmit } } = this;
		const stateCopy = { ...state };
		if (!updateNextInvoiceMonth) stateCopy.nextInvoiceMonth = undefined;
		handleSubmit(shouldEmail, stateCopy);
	}

	renderNextInvoiceMonthChange = () => {
		const { state, props } = this;
		const { clientInfo, nextInvoiceMonth } = state;
		const { form, isInvoice } = props;
		const onChange = (date) => this.setState({ monthPickerChanged: true, nextInvoiceMonth: dateCreator(date) });
		if (clientInfo && clientInfo.automaticInvoicing && isInvoice)
			return (
				<InvoiceMonthChange
					form={form}
					value={nextInvoiceMonth}
					onChange={onChange}
				/>
			);
	}

	renderButtons = () => {
		const { state, props, getIntl, handleSubmit, stringifyMoment } = this;
		const { isLoading, clientInfo, nextInvoiceMonth } = state;
		const { isInvoice, handleCancel } = props;
		if (clientInfo && clientInfo.automaticInvoicing && isInvoice) {
			const oldDate = dateCreator(clientInfo.nextInvoiceMonth);
			const newDate = dateCreator(nextInvoiceMonth);
			const updateNextInvoiceMonth = !newDate.isSame(oldDate, 'month');
			const title = updateNextInvoiceMonth ? getIntl("nextInvoiceMonthWillChange", { date: stringifyMoment(newDate) }) : getIntl("nextInvoiceMonthWillRemain", { date: stringifyMoment(oldDate) });
			return (
				<div style={{ float: 'right' }}>
					<Popconfirm title={title} onConfirm={() => handleSubmit(false, updateNextInvoiceMonth)}>
						<Button className="button-spacing" key="submit" size="large" type="primary" loading={isLoading}>{getIntl("Save")}</Button>
					</Popconfirm>
					<Popconfirm title={title} onConfirm={() => handleSubmit(true, updateNextInvoiceMonth)}>
						<Button className="button-spacing" key="emails" size="large" loading={isLoading}>{getIntl("SaveEmail")}</Button>
					</Popconfirm>
					<Button key="back" size="large" onClick={handleCancel}>{getIntl("cancel")}</Button>
				</div>
			);
		}
		return (
			<div style={{ float: 'right' }}>
				<Button className="button-spacing" key="submit" size="large" type="primary" loading={isLoading} onClick={() => handleSubmit(false)}>{getIntl("Save")}</Button>
				<Button className="button-spacing" key="emails" size="large" onClick={() => handleSubmit(true)}>{getIntl("SaveEmail")}</Button>
				<Button key="back" size="large" onClick={handleCancel}>{getIntl("cancel")}</Button>
			</div>
		);
	}

	saveEditModal = (newProductData) => {
		const { currentProduct, products, subtotal } = this.state;
		const { editProduct, isv } = this.props;
		const { key } = currentProduct;
		const { quantity, price, prevSubTotal } = newProductData;
		products[key] = newProductData;
		const subTotalProduct = quantity * price;
		const newSubTotal = (subtotal - prevSubTotal);
		this.setState({
			products,
			subtotal: newSubTotal + subTotalProduct,
			total: ((newSubTotal + subTotalProduct) * isv) + newSubTotal + subTotalProduct,
		});
		editProduct({ ...currentProduct, ...newProductData });
	};

	setEditProductModal = (product = undefined) => {
		if (product) this.setState({ currentProduct: product, showEditProductModal: true });
	};

	closeEditModal = () => {
		this.setState({ currentProduct: undefined, showEditProductModal: false });
	};

	deleteProducts = (product) => {
		const { isv, getDeleteProduct } = this.props;
		const productList = [...this.state.products];
		const erasedProduct = productList.splice(product.key, 1)[0];
		this.setState({
			products: [...productList],
			subtotal: this.state.subtotal - (erasedProduct.price * erasedProduct.quantity),
			total: (this.state.subtotal - (erasedProduct.price * erasedProduct.quantity)) * isv + (this.state.subtotal - (erasedProduct.price * erasedProduct.quantity))
		});
		this.updateNextInvoiceMonthByProducts(productList);
		this.props.document ? getDeleteProduct(product) : null;
	}

	columnsActions = (id, record) => {
		const editing = (
			<span>
				<a key="edit" onClick={() => this.setEditProductModal(record)}> <EditOutlined /> {this.getIntl("edit")}</a>
				<Divider type="vertical" />
				<a key="delete" onClick={() => this.deleteProducts(record)}> <DeleteOutlined />{this.getIntl("delete")}</a>
			</span>
		)
		const adding = (
			<span>
				<a className="editable-add-btn" onClick={() => this.deleteProducts(record)}>
					<CloseCircleOutlined />
				</a>
			</span>
		)
		return this.props.document ? editing : adding;
	}

	renderTable = () => {
		let dataSource = this.state.products.map((object) => {
			return {
				...object,
				'key': this.state.products.indexOf(object)
			};
		});
		let columns = [
			{
				title: this.props.intl.formatMessage({ ...messages.Product }),
				dataIndex: 'productName',
				key: 'productName'
			}, {
				title: this.props.intl.formatMessage({ ...messages.Description }),
				dataIndex: 'description',
				key: 'description'
			}, {
				title: this.props.intl.formatMessage({ ...messages.Quantity }),
				dataIndex: 'quantity',
				key: 'quantity'
			}, {
				title: this.props.intl.formatMessage({ ...messages.Price }),
				dataIndex: 'price',
				key: 'price',
				className: 'column-money',
				render: (price, product) => {
					const { priceChangeReason } = product;
					if (priceChangeReason)
						return (
							<div>
								<div>{`$ ${roundN(price, 2)}`}</div>
								<div>{priceChangeReason}</div>
							</div>
						);
					return `$ ${roundN(price, 2)}`;
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Total }),
				dataIndex: 'total',
				key: 'total',
				className: 'column-money',
				render: (total) => {
					return (
						`$ ${roundN(total, 2)}`
					);
				}
			},  {
				title: this.props.intl.formatMessage({ ...messages.Actions }),
				dataIndex: 'id',
				render: this.columnsActions
			}
		];
		return (
			<Table dataSource={dataSource} rowKey={(record) => record.id} columns={columns} pagination={false} />
		);
	}

	renderSummary = () => {
		const { state, props } = this;
		const { products, exchangeRate } = state;
		const { taxExemption, isv } = props;
		return <InvoiceSummary
			products={products}
			taxExemption={taxExemption}
			exchangeRate={exchangeRate}
			isv={isv}
		/>
	}

	renderExtraFormItems = () => {
		if ('extraFormItems' in this.props) return (this.props.extraFormItems);
	}

	renderFormMonthPicker = () => {
		const { props, getIntl, disableProductSelections } = this;
		const { getFieldDecorator } = props.form;
		return (
			<div>
				{
					getFieldDecorator(`monthToCharge`, {}
					)(
						<MonthPicker
							disabled={disableProductSelections()}
							className="job-partial-component"
							placeholder={getIntl("monthToCharge")}
							format={monthDateFormat}
							allowClear={false}
						/>
					)
				}
			</div>
		);
	}

	shouldRenderMonthPicker = () => {
		const { renderFormMonthPicker, isProductConfig } = this;
		if (isProductConfig()) return renderFormMonthPicker();
	}

	isProductConfig = () => {
		const { state, props } = this;
		const { selectedProduct } = state;
		const { productConfigurations } = props;
		const { id } = selectedProduct;
		const productConfigIds = productConfigurations.map(pc => pc.productId);
		return existsInArray(id, productConfigIds);
	}

	renderAliasSelect = () => {
		const { state, props, onChangeClientAlias, getIntl } = this;
		const { clientAliases, clientInfo, clientAlias } = state;
		const { getFieldDecorator } = props;
		const options = clientAliases.map((alias, index) => <Option key={index} value={alias}>{alias}</Option>);
		if (clientAliases.length > 0)
			return (
				<Row>
					<Col xs={{ span: 23 }} sm={{ span: 23 }} md={{ span: 6 }} lg={{ span: 6 }}>
						<FormItem label={getIntl("alias")}>
							{
								getFieldDecorator('clientAlias',
									{
										initialValue: clientAlias
									})(
										<Select
											style={{ width: '100%' }}
											filterOption={false}
											onSelect={onChangeClientAlias}
											disabled={clientInfo === undefined}
										>
											{options}
										</Select>
									)
							}
						</FormItem>
					</Col>
					<Col className="invoice-document-alias-remove-button" span={1}>
						<Button shape="circle" icon={<MinusOutlined />} onClick={() => onChangeClientAlias("")} size="small" />
					</Col>
				</Row>
			);
	}

	renderForm = () => {
		const { shouldRenderMonthPicker, disableProductSelections } = this;
		const mp = shouldRenderMonthPicker();
		return (
			<React.Fragment>
				<Row gutter={12}>
					<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
						<FormItem label={this.props.intl.formatMessage({ ...messages.Client })}>
							{this.props.getFieldDecorator('clientId', {
								rules: [{
									required: true, message: this.props.intl.formatMessage({ ...messages.clientError }),
								}],
								initialValue: (this.props.document && !this.state.isDataLoading) ? this.props.document.clientId 
												: (this.props.invoiceJobs || this.props.proformaJobs) ? this.props.selectedJobs[0].clientId : null
							})(
								this.mapClients()
							)}
						</FormItem>
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }}>
						<FormItem label={this.props.intl.formatMessage({ ...messages.ExchangeRate })}>
							{this.props.getFieldDecorator('exchangeRate', {
								rules: [{
									required: true, message: this.props.intl.formatMessage({ ...messages.exchangeRateError }),
								}],
								initialValue: this.state.exchangeRate
							})(
								<InputNumber min={0} step=".0001" style={{ width: '95%' }} onChange={this.onChangeExchangeRate} />
							)}
						</FormItem>
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 5 }}>
						<FormItem label={this.props.intl.formatMessage({ ...messages.Type })}>
							{this.props.getFieldDecorator('type', {
								rules: [{
									required: true, message: this.props.intl.formatMessage({ ...messages.invoiceTypeError }),
								}],
								initialValue: this.props.document !== undefined ? this.props.document.type : INVOICE_TYPES.CREDIT
							})(
								<Radio.Group onChange={this.onChangeType} buttonStyle="solid">
									<Radio.Button value={INVOICE_TYPES.CASH}><FormattedMessage {...messages.Cash} /></Radio.Button>
									<Radio.Button value={INVOICE_TYPES.CREDIT}><FormattedMessage {...messages.Credit} /></Radio.Button>
								</Radio.Group>
							)}
						</FormItem>
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 4 }}>
						{this.renderDaysForPayment()}
					</Col>
				</Row>
				{this.renderAliasSelect()}
				{this.renderExtraFormItems()}
				{this.renderFormVariousClients()}
				<Row gutter={16} type="flex" align="bottom">
					<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: mp ? 4 : 8 }} lg={{ span: mp ? 4 : 8 }}>
						<p><FormattedMessage {...messages.Product} />:</p>
						{this.mapProducts()}
					</Col>
					{this.renderMonthToCharge(mp)}
					<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }}>
						<p><FormattedMessage {...messages.Quantity} />:</p>
						<InputNumber disabled={disableProductSelections()} onChange={this.onChangeQuantity} value={this.state.quantity} onPressEnter={this.focuspriceInput} min={1} style={{ 'width': '100%' }} />
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }}>
						<p><FormattedMessage {...messages.Price} />:</p>
						{this.getPrice()}
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }}>
						<p><FormattedMessage {...messages.Total} />:</p>
						{this.getTotal()}
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }}>
						<Button disabled={disableProductSelections()} onClick={this.addToProducts} >
							<FormattedMessage {...messages.add} />
						</Button>
					</Col>
				</Row>
				{this.renderChangedPriceComment()}
			</React.Fragment>
		);
	}

	renderFormVariousClients = () => {
		const variousClients = this.state.variousClients && (
			<React.Fragment>
				<Row gutter={16}>
					<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
						<FormItem label={this.props.intl.formatMessage({ ...messages.Client })}>
							{this.props.getFieldDecorator('clientName', {
								rules: [{
									required: this.state.variousClients ? true : false, whitespace: true, message: this.props.intl.formatMessage({ ...messages.clientError }),
								}],
								initialValue: this.props.document !== undefined ? this.props.document.clientName : ""
							})(
								<Input onChange={event => this.onChangeClientName(event.target.value)} />
							)}
						</FormItem>
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
						<FormItem label="RTN">
							{this.props.getFieldDecorator('clientRtn', {
								rules: [{
									required: this.state.variousClients ? true : false, whitespace: true, message: this.props.intl.formatMessage({ ...messages.rtnEmptyError }),
								},
								{ pattern: REGEX.rtn, message: this.props.intl.formatMessage({ ...messages.rtnLengthError }) }
								],
								initialValue: this.props.document !== undefined ? this.props.document.clientRtn : ""
							})(
								<Input onChange={this.onChangeRtn} />
							)}
						</FormItem>
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
						<FormItem label={this.props.intl.formatMessage({ ...messages.representative })}>
							{this.props.getFieldDecorator('representative', {
								rules: [{
									required: this.state.variousClients ? true : false, whitespace: true, message: this.props.intl.formatMessage({ ...messages.invoiceTypeError }),
								}],
								initialValue: this.props.document !== undefined ? this.props.document.representative : ""
							})(
								<Input onChange={this.onChangeRepresentative} />
							)}
						</FormItem>

					</Col>
				</Row>
				<Row gutter={16}>
					<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
						<FormItem label={this.props.intl.formatMessage({ ...messages.State })}>
							{this.props.getFieldDecorator('state', {
								rules: [{
									required: this.state.variousClients ? true : false, whitespace: true, message: this.props.intl.formatMessage({ ...messages.stateError }),
								}],
								initialValue: this.props.document !== undefined ? this.props.document.state : ""
							})(
								this.mapStates()
							)}
						</FormItem>
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
						<FormItem label={this.props.intl.formatMessage({ ...messages.City })}>
							{this.props.getFieldDecorator('city', {
								rules: [{
									required: this.state.variousClients ? true : false, whitespace: true, message: this.props.intl.formatMessage({ ...messages.cityError }),
								}],
								initialValue: this.props.document !== undefined ? this.props.document.city : ""
							})(
								this.mapCities()
							)}
						</FormItem>
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }}>
						<FormItem label={this.props.intl.formatMessage({ ...messages.Address })}>
							{this.props.getFieldDecorator('address', {
								rules: [{
									required: this.state.variousClients ? true : false, whitespace: true, message: this.props.intl.formatMessage({ ...messages.addressError }),
								}],
								initialValue: this.props.document !== undefined ? this.props.document.address : ""
							})(
								<Input onChange={this.onChangeAddress} />
							)}
						</FormItem>
					</Col>
				</Row>
			</React.Fragment>
		);
		return variousClients;
	};

	renderDaysForPayment = () => {
		const daysForPayment = this.state.isCredit && (
			<FormItem label={this.props.intl.formatMessage({ ...messages.DaysPayment })}>
				{this.props.getFieldDecorator('daysForPayment', {
					rules: [{
						required: this.state.isCredit, message: this.props.intl.formatMessage({ ...messages.creditDaysError }),
					}],
					initialValue: this.state.daysForPayment
				})(
					<InputNumber min={0} />
				)}
			</FormItem>
		);
		return daysForPayment;
	};

	renderMonthToCharge = (mp) => {
		const monthToCharge = mp && (
			<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }}>
				<p><FormattedMessage {...messages.monthToCharge} />:</p>
				{mp}
			</Col>
		);
		return monthToCharge;
	};

	renderDevicePicker = () => {
		const { state, props } = this;
		const { clientInfo = {} } = state;
		const { parentForm, isInvoice, devices } = props;
		const { clientId } = clientInfo;

		if (isInvoice)
			return (
				<InvoiceDevicesPicker
					form={parentForm}
					clientId={clientId}
					devicesSelected={devices}
				/>
			);
	}

	deviceDescriptionsJobs = () => {
		const { selectedJobs } = this.props;
		let description = "";
		selectedJobs.forEach(job => {
			description =  `${description}, ${job.description}`;	
			if(selectedJobs.indexOf(job) == 0)
				description = job.description;
		});
		return (description);
	}

	render() {
		const { renderNextInvoiceMonthChange, renderButtons, getIntl, renderDevicePicker, state } = this;
		const { showEditProductModal, currentProduct } = state;
		const maxObservationsLength = 200;
		const addOrEdit = this.props.document ||(this.props.invoiceJobs || this.props.proformaJobs)? this.state.isDataLoading : false;	
		return (
			<React.Fragment>
				<Spin spinning={addOrEdit}>
					<div style={{ padding: '25px' }}>
						<CheckExchangeRate setExchangeRate={this.setExchangeRate} />
						{this.renderForm()}
						<br />
						{this.renderTable()}
						<br />
						<Row gutter={16}>
							<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 18 }}>
								<FormItem label={this.props.intl.formatMessage({ ...messages.Observations })}>
									{this.props.getFieldDecorator('observations', {
										rules: [
											{
												required: false, message: this.props.intl.formatMessage({ ...messages.add }),
											},
											{
												max: maxObservationsLength,
												message: getIntl("observationsLengthError", { max: maxObservationsLength })
											}
										],
										initialValue: (this.props.invoiceJobs || this.props.proformaJobs) ? this.deviceDescriptionsJobs() : state.observations 
									})(
										<TextArea style={{ width: '66%' }} onChange={this.onChangeObservations} />
									)}
								</FormItem>
								{renderNextInvoiceMonthChange()}
								{renderDevicePicker()}
							</Col>
							<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 14 }} lg={{ span: 6 }}>
								{this.renderSummary()}
								<br />
							</Col>
						</Row>
						{renderButtons()}
					</div>
				</Spin>

				<EditProductModal
					showModal={showEditProductModal}
					handleCancel={this.closeEditModal}
					handleSave={this.saveEditModal}
					product={currentProduct}
				/>
			</React.Fragment>
		);
	}
}

AddInvoicingDocumentForm.defaultProps = {
	isInvoice: false,
	taxExemption: false
};

AddInvoicingDocumentForm.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	clients: PropTypes.array.isRequired,
	products: PropTypes.array.isRequired,
	user: PropTypes.object.isRequired,
	extraFormItems: PropTypes.object,
	customPrices: PropTypes.array,
	document: PropTypes.object,
	getClientsList: PropTypes.func.isRequired,
	cleanClients: PropTypes.func.isRequired,
	searchProducts: PropTypes.func.isRequired,
	cleanProducts: PropTypes.func.isRequired,
	getFirstProducts: PropTypes.func.isRequired,
	getAllClients: PropTypes.func.isRequired,
	getClientInfo: PropTypes.func.isRequired,
	getEmails: PropTypes.func.isRequired,
	handleCancel: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	getFieldDecorator: PropTypes.func.isRequired,
	getProducts: PropTypes.func,
	getProductConfigurations: PropTypes.func.isRequired,
	productConfigurations: PropTypes.array.isRequired,
	isInvoice: PropTypes.bool,
	taxExemption: PropTypes.bool,
	getISV: PropTypes.func.isRequired,
	isv: PropTypes.number.isRequired,
	resetFields: PropTypes.func.isRequired,
	parentForm: PropTypes.object,
	currentIsLps: PropTypes.bool,
	devices: PropTypes.array,
	editProduct: PropTypes.func,
	getDeleteProduct: PropTypes.func,
	getAddedProducts: PropTypes.func,
	clearSelectedJobs: PropTypes.func,
	selectedJobs: PropTypes.array,
	invoiceJobs: PropTypes.bool,
	proformaJobs: PropTypes.bool,
};

const mapStateToProps = (state) => {
	return {
		clients: state.client.clients,
		products: state.product.products,
		user: state.auth.user,
		currentIsLps: state.client.currentIsLps,
		customPrices: state.client.customPrices,
		productConfigurations: state.product.productConfigurations,
		isv: state.invoicing.isv,
		selectedJobs: state.job.selectedJobs,
		invoiceJobs: state.job.invoiceJobs,
		proformaJobs: state.job.proformaJobs,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getAllClients: () => {
			return dispatch(GetAllClients());
		},
		getClientsList: (searchText) => {
			return dispatch(GetClientsList(searchText));
		},
		cleanClients: () => {
			return dispatch(CleanClients());
		},
		searchProducts: (searchText) => {
			return dispatch(SearchProducts(searchText));
		},
		getFirstProducts: () => {
			return dispatch(GetFirstProducts());
		},
		cleanProducts: () => {
			return dispatch(CleanProducts());
		},
		getClientInfo: (clientId) => {
			return dispatch(GetClientInfo(clientId));
		},
		getEmails: (clientId) => {
			return dispatch(GetEmails(clientId));
		},
		getProductConfigurations: () => dispatch(GetProductConfigurations()),
		getISV: () => dispatch(GetISV()),
		getClientAliases: () => dispatch(GetClientAliases()),
		clearSelectedJobs: () => {
			return dispatch(ClearSelectedJobs());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(AddInvoicingDocumentForm)));
