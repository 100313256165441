import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getInvoicingMessages } from '../../constants/messages';
import GeneralAddPaymentModal from './GeneralAddPaymentModal';
import SelectDepositor from './SelectDepositor';

let invoicingMessages = getInvoicingMessages();

let messages = {
	...invoicingMessages,
};

class DepositorPaymentButton extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showPaymentModal: false,
			showDepositorModal: false,
			selectedClient: {}
		};
	}

	openPaymentModal = () => {
		this.setState({
			showPaymentModal: true
		});
	}

	closePaymentModal = () => {
		this.setState({
			showPaymentModal: false,
		});
	}

	openDepositorModal = () => {
		this.setState({
			showDepositorModal: true
		});
	}

	closeDepositorModal = () => {
		this.setState({
			showDepositorModal: false,
		});
	}

	handleSelectDepositor = (depositor) => {
		this.setState({
			selectedClient: depositor.client
		});
		this.closeDepositorModal();
		this.openPaymentModal();
	}

	render() {
		return (
			<span>
				<GeneralAddPaymentModal showPaymentModal={this.state.showPaymentModal} closePaymentModal={this.closePaymentModal} selectedClient={this.state.selectedClient} />
				<SelectDepositor
					showDepositorModal={this.state.showDepositorModal}
					closeDepositorModal={this.closeDepositorModal}
					selectDepositor={this.handleSelectDepositor}
					fieldName="depositor"
				/>

				{this.displayButton()}
			</span>
		);
	}

	displayButton() {
		if (this.props.displayButton)
			return <Button className="editable-add-btn" onClick={this.openDepositorModal}>
				<FormattedMessage {...messages.searchDepositor} />
			</Button>;
		return <div> <a onClick={this.openDepositorModal}> {<FormattedMessage {...messages.searchDepositor} />} </a>  </div>
	}
}

DepositorPaymentButton.propTypes = {
	intl: PropTypes.object.isRequired,
	displayButton: PropTypes.bool,
};

export default injectIntl((DepositorPaymentButton));