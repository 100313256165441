import {
	CLEAN_REDUCER,
	CLEAR_SESSION,
	GET_CREDIT_NOTES,
	GET_CREDIT_NOTE,
	ADD_CREDIT_NOTE,
	UPDATE_SEARCH,
	UPDATE_PAGE
} from './actionTypes';

const initState = {
	creditNotes: [],
	creditNote: {},
	search: "",
	page: 1
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case UPDATE_SEARCH:
			return {
				...state,
				search: action.search
			}
		case UPDATE_PAGE:
			return {
				...state,
				page: action.page
			}
		case GET_CREDIT_NOTES:
			return {
				...state,
				creditNotes: action.creditNotes
			};
		case GET_CREDIT_NOTE:
			return {
				...state,
				creditNote: action.creditNote
			};
		case ADD_CREDIT_NOTE:
			return state;
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;