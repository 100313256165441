import PropTypes from 'prop-types';
import { Col, Row, Skeleton, List, Statistic } from 'antd';
import { CaretDownOutlined, CaretLeftOutlined, CaretUpOutlined } from '@ant-design/icons';
import { injectIntl } from 'react-intl';
import React from 'react';
import moment from 'moment';

class AccountCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            showComplete: false,
        }
    }

    componentDidMount() {
    }

    ShowMoreIcon = () => {
        if (this.state.showComplete)
            return <CaretDownOutlined onClick={this.changeCardDisplay} />
        return <CaretLeftOutlined onClick={this.changeCardDisplay}/>
    }

    changeCardDisplay = () => {
        this.setState({
            showComplete: !this.state.showComplete
        });
    }

    displayTitle = () => {
        let total = this.props.account.total;
        let style = { color: '#87d068', fontSize: '17px', moneyIcon: 'caret-up' };
        if(total < 0){
            total *= -1;
            style = { color: '#f4a442', fontSize: '17px', moneyIcon: 'caret-down' };
        }
        return (
            <div>
                <Row>
                    <Col span={15}>
                        {this.props.account.name}
                    </Col>
                    <Col span={7} >
                        {<Statistic
                            value={total}
                            precision={2}
                            valueStyle={style}
                            prefix={total > 0 ? <p><CaretUpOutlined />L</p> : <p><CaretDownOutlined />L</p>}
                            precisionStyle={{ color: style.color, fontSize: "8px" }}
                        /> }
                    </Col>
                    <Col span={1} style={{ paddingTop: "3px" }}>
                        <a>{this.ShowMoreIcon()}</a>
                    </Col>
                </Row>
            </div>
        );
    }

    displayCompleteData = () => {
        if (this.state.showComplete)
            return (
                <div>
                    <Row>
                        <List
                            dataSource={this.props.account.categoryEntries}
                            renderItem={(item) => {
                                return (
                                    <div className="details" onClick={() => this.props.showEntry(item.entryId)}>
                                        <Row>
                                            <Col span={12}>
                                                {item.entry.description}
                                            </Col>
                                            <Col span={12}>
                                                <Statistic
                                                    value={item.amount}
                                                    precision={2}
                                                    valueStyle={{ color: item.entry.isIncome ? '#87d068' : '#f4a442', fontSize: '14px'}}
                                                    prefix={item.entry.isIncome ? <p><CaretUpOutlined />L</p> : <p><CaretDownOutlined />L</p>}
                                                    precisionStyle={{ color: '#87d068', fontSize: '10px' }}
                                                /> 
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12} className="miniItemList">
                                                {moment(item.entry.date).format('YYYY/MM/DD')}
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            }}
                        />
                    </Row>
                </div>
            );
        return (<div>
        </div>)
    }


    render() {
        return (
            <List.Item onDoubleClick={this.changeCardDisplay}>
                <Skeleton avatar title={false} loading={this.state.loading} active>
                    <List.Item.Meta
                        title={this.displayTitle()}
                        description={this.displayCompleteData()}
                    />
                </Skeleton>
            </List.Item>
        )
    }
}

AccountCard.propTypes = {
    intl: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    showEntry: PropTypes.func.isRequired,
};

export default injectIntl(AccountCard);