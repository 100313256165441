import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { getactionMessages, getLotsMessages } from '../../constants/messages';
import AddOrEditModal from './AddOrEditModal';

let ActionMessages = getactionMessages(),
	LotMessages = getLotsMessages();

const messages = {
	...ActionMessages,
	...LotMessages,
};

const AddButton = ({isLoading, updateLots, employees, models, lots, units}) => {
	const intl = useIntl();
	const [visible, setVisible] = useState(false);

	return (
		<span>
			<Button
				className="editable-add-btn"
				type="primary"
				disabled={isLoading}
				onClick={() => setVisible(true)}
			>
				<PlusOutlined />
				{intl.formatMessage({...messages.add})}
			</Button>
			<AddOrEditModal
				title={intl.formatMessage({ ...messages.addLot})}
				visible={visible}
				onCancel={() => setVisible(false)}
				updateLots={updateLots}
				employees={employees}
				models={models}
				lots={lots}
				units={units}
			/>
		</span>
	);
};

AddButton.propTypes = {
	isLoading: PropTypes.bool,
	updateLots: PropTypes.func,
	models: PropTypes.array,
	employees: PropTypes.array,
	lots: PropTypes.array,
    units: PropTypes.array,
};

export default AddButton;