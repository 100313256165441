import React from 'react'
import PropTypes from 'prop-types';
import { Form, Input, Col, Row, Select } from 'antd';
import { useIntl } from 'react-intl';
import { getFields, getUnitsMessages, getErrorMessages, getModelsMessages } from '../../constants/messages';
import { REGEX } from '../../constants/global';
import debounce from '../../utilities/debounce';
import { CheckPinExistance, CheckImeiExistance } from './actions';
import { UNITLOCATION } from './constants';
const { WAREHOUSE } = UNITLOCATION;
import _ from 'lodash';

const Option = Select.Option;
const FormItem = Form.Item;

let FieldsMessages = getFields(),
    ErrorMessages = getErrorMessages(),
    UnitsMessages = getUnitsMessages(),
    ModelMessages = getModelsMessages();

const messages = {
	...FieldsMessages,
    ...UnitsMessages,
    ...ErrorMessages,
    ...ModelMessages,
};

const AddOrEditForm = ({ form, record, models }) => {
	const intl = useIntl();

    const modelsOptions = models.map((model) => (
        <Option key={model.id} value={model.id}>
            {model.model}
        </Option>
	));

    const pinExist = debounce(async (value) => {
        if (_.isEmpty(value)) return;
        if (record) return;
        const exist = await CheckPinExistance(value);
        if (exist) throw new Error(intl.formatMessage({...messages.pinExistError}));
    }, 300);

    const imeiExist = debounce(async (value) => {
        if (_.isEmpty(value)) return;
        if (record) return;
        const exist = await CheckImeiExistance(value);
        if (exist) throw new Error(intl.formatMessage({...messages.imeiExistError}));
    }, 300);

    const doesPinExist = (rule, value) => pinExist(value);
    const doesImeiExist = (rule, value) => imeiExist(value);

  return (
    <div>
            <Form
                ref={form}
                layout='vertical'
                initialValues={{
                    ...record,
                    description: record ? record.description : null,
                    pin: record ? record.pin : null,
                    imei: record ? record.imei : null,
                    location: record ? record.location : WAREHOUSE.id
                }}
            >
                <FormItem name="id" hidden={true}>
                    <Input hidden={true} />
                </FormItem>
                <Col span={24}>
                    <FormItem
                        label={intl.formatMessage({...messages.description})}
                        name="description"
                        rules={[{ required: true }]}
                    >
                       <Input
                            placeholder={intl.formatMessage({...messages.descriptionPlaceholder})}
                        />     
                    </FormItem>
                </Col>
                <Row gutter={8}>
                    <Col span={12}>
                        <FormItem
                            label={intl.formatMessage({...messages.pin})}
                            name="pin"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage({...messages.pinError}),
                                },
                                {
                                    pattern: REGEX.pin,
                                    message: intl.formatMessage({...messages.pinFormatError}),
                                },
                                { validator: doesPinExist },
                            ]}
                        >
                            <Input
                                placeholder={intl.formatMessage({...messages.pinPlaceholder})}
                                />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            label={intl.formatMessage({...messages.imei})}
                            name="imei"
                            hasFeedback
                            rules={[
                                {
                                    message: intl.formatMessage({...messages.imeiError}),
                                },
                                {
                                    pattern: REGEX.imei,
                                    message: intl.formatMessage({...messages.imeiFormatError}),
                                },
                                { validator: doesImeiExist },
                            ]}
                            >
                            <Input
                                placeholder={intl.formatMessage({...messages.imeiPlaceholder})}
                                />
                        </FormItem>
                        <FormItem name="location" hidden={true}>
                            <Input hidden={true} />
                        </FormItem>
                    </Col>
                </Row>
                <Row type="flex" justify="center" align="bottom">
                    <Col span={24}>
                        <FormItem 
                            name="modelId"
                            label={intl.formatMessage({ ...messages.model })} 
                            rules={[
                                {
                                    required: true, 
                                    message: intl.formatMessage({...messages.modelError}) 
                                }
                            ]} 
                        >
                            <Select
                                showSearch
                                placeholder={intl.formatMessage({ ...messages.modelPlaceholder })}
                                optionFilterProp="children"
                            >
                                {modelsOptions}
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
    </div>
  )
}

AddOrEditForm.propTypes = {
    form: PropTypes.object.isRequired,
    record: PropTypes.object,
	models: PropTypes.array,
};

export default AddOrEditForm;
