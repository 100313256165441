import React from 'react';
import PropTypes from 'prop-types';
import ClientTemplate from './ClientTemplate';

class Client extends React.Component {
    
	render() {
        const { form, client, disabled, onChange } = this.props;
        return (
            <ClientTemplate
                form={form}
                client={client}
                disabled={disabled}
                onChange={onChange}
            />
        );
    }
}

Client.defaultProps = {
    client: {},
    disabled: false
};

Client.propTypes = {
    form: PropTypes.object.isRequired,
    client: PropTypes.object,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
};

export default Client;