import {
	FETCH_PAYROLLS,
	FETCH_PAYROLL_COUNT,
	FETCH_GENERATE_PAYROLL_INFO,
    FETCH_IHSS_ROOFS,
	FETCH_ISR_ROOFS,
	FETCH_DEDUCTIONS,
	FETCH_PAYROLL_INFO,
	FETCH_MEDICAL_DEDUCTIONS
} from './actionTypes';

import {
    CLEAR_SESSION,
	CLEAN_REDUCER
} from '../../constants/actionTypes';

const initState = {
	payrolls: [],
	count: 0,
	generateInfo: [],
	ihssRoofs: [],
	isrRoofs: [],
	deductions: [],
	medicalDeductions: [],
	payrollInfo: {}
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case FETCH_MEDICAL_DEDUCTIONS:
			return {
				...state,
				medicalDeductions: action.medicalDeductions
			};
		case FETCH_PAYROLL_INFO:
			return {
				...state,
				payrollInfo: action.payrollInfo
			};
		case FETCH_PAYROLLS:
			return {
				...state,
				payrolls: action.payrolls
			};
		case FETCH_PAYROLL_COUNT:
			return {
				...state,
				count: action.count
			};
		case FETCH_GENERATE_PAYROLL_INFO:
			return {
				...state,
				generateInfo: action.generateInfo
			};
		case FETCH_IHSS_ROOFS:
			return {
				...state,
				ihssRoofs: action.ihssRoofs
			};
		case FETCH_ISR_ROOFS:
			return {
				...state,
				isrRoofs: action.isrRoofs
			};
		case FETCH_DEDUCTIONS:
			return {
				...state,
				deductions: action.deductions
			};
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;