import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { getJobMessages, getFields } from '../../../../../constants/messages';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select } from 'antd';
import CircleBadge from '../../../../GlobalComponents/CircleBadge';
import { JOBSTATUS } from  '../../../constants';
import { objectToArray } from '../../../../../utilities/util';
const { Option } = Select;

let jobMessages = getJobMessages(),
    fieldMessages = getFields();

const messages = {
    ...jobMessages,
    ...fieldMessages
};

const FormItem = Form.Item;

class Status extends React.Component {

    getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    buildOptions = () => {
        const { getIntl } = this;
        return objectToArray(JOBSTATUS).map(status => <Option key={status.id} value={status.id}>{getIntl(status.intl)} <CircleBadge color={status.iconColor}/></Option>);
    }
    
	render(){
        const { props, getIntl, buildOptions } = this;
        const { getFieldDecorator } = props.form;
        let options = buildOptions();
        return(
            <FormItem label={getIntl("status")} >
            {
                getFieldDecorator("status")(
                    <Select
                        className="job-full-component"
                        showSearch
                        allowClear
                        placeholder={getIntl("status")}
                        optionFilterProp="children"
                        notFoundContent={getIntl("notFound")}
                        dropdownMatchSelectWidth={false}
                    >
                        {options}
                    </Select>
                )
            }
            </FormItem>
        );
	}
}

Status.propTypes = {
	intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired
};

export default injectIntl(Status);