import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Popconfirm, message, Typography } from 'antd';
import { FormattedMessage, useIntl} from 'react-intl';
import { getRequestMessages, getLanguageIntl, getPopConfirm, getactionMessages } from '../../constants/messages';
import { GetRequests, SearchRequests, FinishRequest } from './actions';
import CommonTable from '../../components/CommonTable';
import RequestsFilter from './RequestsFilter';
import AddRequestButton from './AddRequestButton';
import {formatDate} from '../../utilities/util';
import ViewRequestModal from './ViewRequestModal';

let requestMessages = getRequestMessages(),
    intlMessages = getLanguageIntl(),
    popConfirmMessages = getPopConfirm(),
    actionMessages = getactionMessages();

const messages = {
    ...requestMessages,
    ...intlMessages,
    ...popConfirmMessages,
    ...actionMessages,
};

const Requests = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const requests = useSelector((state) => state.request.requests)
    const count = useSelector((state) => state.request.count)
    const search = useSelector((state) => state.request.search);
    const [isLoading, setIsLoading] = useState(false);
    const [viewRequest, setViewRequest] = useState({});
	const [isVisible, setVisible] = useState(false);

    const getRequests = (page, pageSize) => {
		setIsLoading(true);
		dispatch(GetRequests(page, pageSize)).then(() => {
            setIsLoading(false);
        }).catch(() => {
			message.error(intl.formatMessage( {...messages.getRequestsError}));
            setIsLoading(false);
		});
    }

    const searchRequests = (page, pageSize, search) => {
		setIsLoading(true);
		dispatch(SearchRequests(page, pageSize, search)).then(() => {
            setIsLoading(false);
		}).catch(() => {
			message.error(intl.formatMessage( {...messages.getRequestsError}));
            setIsLoading(false);
		});
    }

    const finishRequest = (id) => {
        setIsLoading(true);
        dispatch(FinishRequest(id)).then(() => {
            getRequests();
        }).catch(() => {
			message.error(intl.formatMessage( {...messages.finishRequestError}));
            setIsLoading(false);
		});
    }

    
	const showRecord = (record) => {
		setViewRequest(record);
		setVisible(true);
	};

    const tableActions = (id, record) => {
        return (
            <span>
                <Typography.Link
                    className="editable-add-btn"
                    onClick={() => showRecord(record)}
                >
                        <FormattedMessage {...messages.view} />
                </Typography.Link>
                {!record.finishedAt &&
                    <React.Fragment>
                        <span> | </span>
                        <Popconfirm
                            title={intl.formatMessage({ ...messages.finishQ })}
                            onConfirm={() => finishRequest(id)}
                            okText={intl.formatMessage({ ...messages.yes })}
                            cancelText={intl.formatMessage({ ...messages.no })}
                        >
                            <Typography.Link>
                                <FormattedMessage {...messages.markFinished} />
                            </Typography.Link>
                        </Popconfirm>
                    </React.Fragment>
                }
            </span>
        );
    };

    const columns = [
        {
            title: intl.formatMessage( {...messages.client}),
            dataIndex: 'client',
            key: 'client',
            width: '15%',
            render: (client) => client.name,
        },
        {
            title: intl.formatMessage( {...messages.details}),
            dataIndex: 'leased',
            key: 'leased',
            width: '20%',
            render: (leased, request) => {
                return (
                    `${intl.formatMessage( {...messages.boughtGPSs})}: ${request.bought}, 
                    ${intl.formatMessage( {...messages.leasedGPSs})}: ${leased}`
                );
            }
        },
        {
            title: intl.formatMessage( {...messages.requestedBy}),
            dataIndex: 'createdBy',
            key: 'createdBy',
            width: '10%',
            render: (account) => account.name,
        },
        {
            title: intl.formatMessage( {...messages.dateRequested}),
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '15%',
            render: (date) => {
                return (formatDate(date, intl.formatMessage({...messages.intl}),
                    {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}));
            }
        },
        {
            title: intl.formatMessage( {...messages.status}),
            dataIndex: 'finishedAt',
            key: 'finishedAt',
            width: '25%',
            render: (date, request) => {
                if(date === null){
                    return (intl.formatMessage({...messages.unfinished}));
                }else{
                    return (`
                        ${intl.formatMessage({...messages.finishedBy})} ${request.finishedBy.name} 
                        ${intl.formatMessage({...messages.on})} ${formatDate(date, intl.formatMessage({...messages.intl}), {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}  
                    `);
                }
            }
        },
        {
            title: intl.formatMessage( {...messages.actions}),
            dataIndex: 'id',
            key: 'id',
            width: '15%',
			render: (id, record) => tableActions(id, record),
        }
    ];

    return (     
        <div className="account view">
            <CommonTable
                columns={columns}
                dataSource={requests}
                search={(search, page, pageSize) => searchRequests(page, pageSize, search)}
                searchText= {search}
                recordCount={count}
                getRecords={(page, pageSize) => getRequests(page, pageSize)}
                Add={<AddRequestButton />}
                loading={isLoading}
                filterComponent={(<RequestsFilter />)}
                getCheckboxProps={(() => ({ disabled: false }))}
                hideCheckboxes
            />
            {isVisible && 
                <ViewRequestModal
                    openModal={isVisible}
                    request={viewRequest}
                    handleCancel={() => setVisible(false)}
                />
            }
        </div>
    );
};

export default Requests;
