import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Typography, Tooltip } from 'antd';
import { injectIntl } from 'react-intl';
import { getInvoicingMessages } from '../../constants/messages';
const { Text } = Typography;

let invoicingMessages = getInvoicingMessages();

const messages = {
	...invoicingMessages
};

class SendDeviceListCheckbox extends React.Component {

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	renderCheckbox = () => {
		const { props, getIntl } = this;
		const { checkboxState, triggerCheckbox, disabled } = props;
		return <Checkbox checked={checkboxState} onChange={e => triggerCheckbox(e.target.checked)} disabled={disabled}>{getIntl("includeDeviceListQuestion")}</Checkbox>;
	}

	renderWarning = () => {
		const { props, getIntl } = this;
		const { checkboxState, showWarning } = props;
		if (checkboxState && showWarning) return <div><Text type="warning">{getIntl("includeOutdatedDeviceInfoWarning")}</Text></div>;
	}

	renderCheckboxWithTooltip = () => {
		const { getIntl, renderCheckbox } = this;
		return (
			<Tooltip title={getIntl("noInvoiceDevicesAvailable")} mouseEnterDelay={0.5}>
				{renderCheckbox()}
			</Tooltip>
		);
	}

	fullRender = () => {
		const { props, renderCheckbox, renderWarning, renderCheckboxWithTooltip } = this;
		const { disabled } = props;
		if (disabled) return renderCheckboxWithTooltip();
		return (
			<div>
				{renderCheckbox()}
				{renderWarning()}
			</div>
		);
	}

	render() {
		return this.fullRender();
	}
}

SendDeviceListCheckbox.defaultProps = {
	showWarning: false,
	disabled: false
};

SendDeviceListCheckbox.propTypes = {
	intl: PropTypes.object.isRequired,
	checkboxState: PropTypes.bool.isRequired,
	triggerCheckbox: PropTypes.func.isRequired,
	showWarning: PropTypes.bool,
	disabled: PropTypes.bool,
};

export default injectIntl(SendDeviceListCheckbox);