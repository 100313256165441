import React from 'react';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { injectIntl } from 'react-intl';
import { getactionMessages } from '../../../constants/messages';
import AddOrUpdateJobForm from './Modal';

let actionMessages = getactionMessages();

const messages = {
	...actionMessages,
};

class AddJobButton extends React.Component {

	constructor(props) {
		super(props);
        this.state = {
            showAddModal: false
        };
	}

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});
	
	renderButton = () => {
        const { getIntl } = this;
        return (
            <Button className="editable-add-btn" type="primary" onClick={() => this.setState({ showAddModal: true })}>
				<PlusOutlined />
				{getIntl("add")}
			</Button>
        );
	}
	
	renderModal = () => {
		const { showAddModal } = this.state;
        return ( 
                <AddOrUpdateJobForm
                    showModal={ showAddModal }
                    handleCancel={() => this.setState({ showAddModal: false })}
				/>
            );
	}

	fullRender = () => {
		const { renderButton, renderModal } = this;
		return (
			<div>
				{renderButton()}
				{renderModal()}
			</div>
		);
	}
    
	render() {
		return this.fullRender();
	}
}

AddJobButton.propTypes = {
	intl: PropTypes.object.isRequired
};

export default injectIntl(AddJobButton);