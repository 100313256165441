import axios from 'axios';
import {
	notify
} from '../../utilities/util';
import {
	ADD_PAYMENT_SUCCESS,
	GET_DEPOSITORS
} from './actionTypes';


export const GetFirstDepositors = () => (dispatch) => {
	let filter = {
		"limit": 10,
		"include": "client"
	};

	return axios.get(`/Depositaries?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			dispatch({ type: GET_DEPOSITORS, depositors: response.data });
		});
};

export const GetDepositorsList = (searchText) => (dispatch) => {
	let filter = {
		"where": {
			"name": {
				"like": "%" + searchText + "%"
			}
		},
		"include": "client"
	};

	return axios.get(`/Depositaries?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			dispatch({ type: GET_DEPOSITORS, depositors: response.data });
		});
};

export const AddPayment = (data) => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;

	return axios.post(`/Payments/addPayment`, { data })
		.then((response) => {
			notify(`${messages[ADD_PAYMENT_SUCCESS]}`);
			dispatch({ type: ADD_PAYMENT_SUCCESS, payment: response.data });
		});
};

export const MakeReceipt = (receiptInfo, invoices, values) => (dispatch, getState) => {
	let data = {
		receiptInfo: receiptInfo,
		invoices: invoices,
		values: values,
	};
	let state = getState();
	let messages = state.int18.messages;
	return axios.post(`/Receipts/createReceipt`, data)
		.then((response) => {
			notify(`${messages[ADD_PAYMENT_SUCCESS]}`);
			return response.data;
		});
};

export const EmailReceipt = (receiptId, emails, respondTo, message) => () => {
	let data = {
		receiptId: receiptId,
		emails: emails,
		respondTo: respondTo,
		message: message,
	};
	return axios.post(`/Receipts/emailReceipt`, data)
		.then(() => {
		});
};

export const NotifyClientPayment = (receiptId, emails, respondTo, message) => () => {
	let data = {
		receiptId: receiptId,
		emails: emails,
		respondTo: respondTo,
		message: message,
	};
	return axios.post(`/Receipts/notifyClientPayment`, data);
};

export const GetClientReceipts = (clientId) => () => {
	let filter = {
		"where": {
			"clientId": clientId
		},
		"include": {
			"relation": "payments",
			"scope": {
				"include": "invoice"
			}
		},
		"order": "createdAt DESC"
	};

	return axios.get(`/Receipts?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			return response.data;
		});
};

export const LoadActiveBankAccounts = () => {
	const filter = { where: { active: true } };
	return axios.get(`/Banks?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then(response => response.data);
};

export const LoadAllBankAccounts = () => {
	return axios.get(`/Banks`)
		.then(response => response.data);
};