import React from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from 'antd';

const FormItem = Form.Item;

const FormItemInput = ({ fieldName, label, errorMessage, initialValue, onBlur, required, rules, extraRules, renderLabel }) => {
  
    let formRules = rules ? rules : [{ required: required, message: errorMessage }];
    if (extraRules) {
        formRules.push(extraRules);
    }
    return (
        <FormItem
            label={label && renderLabel ? label : null}
            name={fieldName}
            rules={formRules}
            initialValue={initialValue}
        >
            <Input onChange={onBlur} placeholder={label ? label : null} />
        </FormItem>
    );
};

FormItemInput.defaultProps = {
    required: true,
    renderLabel: true
};

FormItemInput.propTypes = {
    fieldName: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    label: PropTypes.string,
    errorMessage: PropTypes.string,
    initialValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    onBlur: PropTypes.func,
    required: PropTypes.bool,
    rules: PropTypes.array,
    extraRules: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]),
    renderLabel: PropTypes.bool
};

export default FormItemInput;
