import React, { useEffect, useState } from 'react'
import { Form, Select, Button, message } from 'antd';
import PropTypes from 'prop-types';
import { searchInString } from '../../../utilities/util';
import { FormattedMessage, useIntl } from 'react-intl';
import { getClientMessages, getInvoicingMessages, getReportMessages } from '../../../constants/messages';
import { GetClientTiers } from '../../Clients/actions';
import CircleBadge from '../../GlobalComponents/CircleBadge';

const FormItem = Form.Item;

let reportMessages = getReportMessages(),
    invoiceMessages = getInvoicingMessages(),
    clientMessages = getClientMessages();

const messages = {
    ...reportMessages,
    ...invoiceMessages,
    ...clientMessages,
};

const ReportOptions = ({ onSubmit }) => {
    const intl = useIntl();
	const [form] = Form.useForm();
    const [tiers, setTiers] = useState([]);

    useEffect(() => {
        const fetchClient = async () => {
            try {
                const tiers = await GetClientTiers(); 
                setTiers(tiers);
            } catch (error) {
                message.error(intl.formatMessage({ ...messages.commonError }));
            }
        }
        fetchClient();
    }, []);

    const deviceOptions = tiers.map((device) => {
        return {
            key: device.id,
            value: device.id,
            searchValue: device.name,
            label: (
                <CircleBadge color={device.badgeColor} text={device.name} /> 
            ),
        }
    });

    return (
        <React.Fragment>
            <Form 
                form={form} 
                layout="inline"
                preserve={false}
                onFinish={onSubmit}
            >
                <FormItem
                    name="overdueDays"
                    label={intl.formatMessage({...messages.expiredDays})}
                    rules={[{ required: true }]}
                >
                    <Select
                        showSearch
                        style={{width: 300}}
                        options={[{ key: 0, value: 30, label: '≥30 <60 ' }, { key: 1, value: 60, label: '≥60' }]}
                        optionFilterProp="children"
                        placeholder={intl.formatMessage({ ...messages.client })}
                        filterOption={(input, { label }) => searchInString(input, label)}
                    />
                </FormItem>
                <FormItem
                    name="tierClients"
                    label={intl.formatMessage({...messages.clientTier})}
                >
                    <Select
                        showSearch
                        style={{width: 150}}
                        optionFilterProp="children"
                        options={deviceOptions}
                        allowClear
                        placeholder={intl.formatMessage({ ...messages.clientTier })}
                        filterOption={(input, { label }) => searchInString(input, label)}
                    />
                </FormItem>
                <FormItem >
                    <Button 
                        type="primary"
                        htmlType="submit"
                    >
                        <FormattedMessage {...messages.generateReport} />
                    </Button>
                </FormItem>
            </Form>
        </React.Fragment>
    )
}

ReportOptions.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default ReportOptions
