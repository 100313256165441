import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { StopOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { getClientMessages, getactionMessages } from '../../../constants/messages';
import ClientDeactivationModal from './ClientDeactivationModal';

let ActionMessages = getactionMessages(),
    clientMessages = getClientMessages();

const messages = {
	...ActionMessages,
    ...clientMessages,
};

const ClientDeactivationButton = ({data, disabled}) => {
	const intl = useIntl();
	const [visible, setVisible] = useState(false);

	return (
		<React.Fragment>
            <Button
                type="primary"
                onClick={() => setVisible(true)}
                icon={<StopOutlined />}
                disabled={disabled}
            >
                {intl.formatMessage({ ...messages.programmedDeactivation })}
            </Button>
            <ClientDeactivationModal
                title={intl.formatMessage({ ...messages.programmedDeactivation })}
                visible={visible}
                onClose={() => setVisible(false)}
                data={data}
            />
        </React.Fragment>
	);
};

ClientDeactivationButton.propTypes = {
	updateModels: PropTypes.func,
    data: PropTypes.array,
    disabled: PropTypes.bool
};

export default ClientDeactivationButton;
