import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { Modal, message } from 'antd';
import AddOrEditForm from './AddOrEditForm';
import { LoadingOutlined, SaveOutlined } from '@ant-design/icons';
import { getModalMessages, getLotsMessages, getUnitsMessages } from '../../constants/messages';
import { AddLot } from './actions';
const moment = require('moment');

let ModalMessages = getModalMessages(),
    UnitsMessages = getUnitsMessages(),
    LotsMessages = getLotsMessages();

const messages = {
	...ModalMessages,
    ...LotsMessages,
    ...UnitsMessages,
};

const AddOrEditModal = ({onCancel, title, visible, updateLots, employees, models, lots, units}) => {
    const intl = useIntl();
	const form = useRef();
    const [isSaving, setSaving] = useState(false);
	const dispatch = useDispatch();

    const onOk = async () => {
        try {
            setSaving(true);
            const values = await form.current.validateFields();
            const nomenclatureLot = formatLotNumber(); 
            const lot = await dispatch(AddLot(values, nomenclatureLot));
            updateLots(lot)
            message.success(intl.formatMessage({...messages.addLotSuccess }));
            onCancel();
		} catch (error) {
            message.error(intl.formatMessage({...messages.addLotError }));
		} finally {
			setSaving(false);
		}
	};
    
    const formatLotNumber = () => {
        const initialLot = '001';
        const [lastLot] = lots.slice(-1);
        if (lastLot && lastLot.lotNumber) {
            const { lotNumber } = lastLot;
            let newLotNumber = (parseInt(lotNumber)+1).toString();
            const numberLot = newLotNumber.substring(newLotNumber.length - 3);
            const prevYear = lotNumber.substring(-1,2);
            const actualYear = moment().format("YY");
            newLotNumber = (actualYear === prevYear) ? moment().format("YYMM") + numberLot : moment().format("YYMM") + initialLot;
            return newLotNumber;
        } else {
            const newLotNumber = moment().format("YYMM") + initialLot;
            return newLotNumber;
        } 
    };
    
    return (
        <Modal
            open={visible}
            title={title}
            onOk={onOk}
            okText={
				<span>
					{isSaving ? <LoadingOutlined /> : <SaveOutlined />}
			        <FormattedMessage {...messages.save}  />
				</span>
			}
            cancelText={intl.formatMessage({...messages.cancel})}
            onCancel={onCancel}
            destroyOnClose
        >
            <AddOrEditForm 
                form={form} 
                unitsAvailable={units}
                employeesActive={employees}
                models={models}
            />
        </Modal>
    );
};

AddOrEditModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string,
    visible: PropTypes.bool,
	updateLots: PropTypes.func,
    employees: PropTypes.array,
	models: PropTypes.array,
	lots: PropTypes.array,
    units: PropTypes.array,
};

export default AddOrEditModal;
