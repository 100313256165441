import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getTopMenuMessages, getModalMessages, getFields, getConfigurationMessages} from '../constants/messages';
import { Tabs, Button, Modal } from 'antd';
import {injectIntl, FormattedMessage} from 'react-intl';
import GeneralConfig from './GeneralConfig';
import {isTablet} from '../utilities/util';

let fieldsMessages = getFields(),
	configurationMessages = getConfigurationMessages(),
	modalMessages = getModalMessages(),
	menuMessages = getTopMenuMessages();

const messages = {
	...modalMessages,
	...menuMessages,
	...fieldsMessages,
	...configurationMessages
};

const Configuration = ({intl, showModal, handleCancel}) => {

	const items = [
		{
			key: '1',
			label: intl.formatMessage({...messages.general}),
			children: <GeneralConfig />
		},
	];

	return ( 
		<Modal
			open={showModal}
			title= {intl.formatMessage( {...messages.config} )} 
			onCancel={handleCancel}
			destroyOnClose={true}
			footer={[
				<Button key="close" size="large" onClick={handleCancel}>
					<FormattedMessage {...messages.close} />
				</Button>
			]}
		>
			<Tabs
				defaultActiveKey="1"
				items={items}
				tabPosition={isTablet()?"top":"left"}
				className="profileModal"
			/>
		</Modal>
	);
};

Configuration.propTypes = {
	intl: PropTypes.object.isRequired,
	showModal: PropTypes.bool.isRequired,
	handleCancel:  PropTypes.func.isRequired
};
const mapStateToProps = (state) => {
	return {
		"showModal": state.configuration.showConfiguration
	};
};

export default connect(mapStateToProps)(injectIntl(Configuration));