import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Radio } from 'antd';
import { injectIntl } from 'react-intl';
import { getJobMessages, getErrorMessages } from '../../../../constants/messages';
import { DeactivateDeviceSearch } from '../../actions';
import Device from '../CommonComponents/Device';
import DatePicker from '../CommonComponents/Date';
import ClientKeepsDevice from '../CommonComponents/ClientKeepsDevice';
import UninstallSim from '../CommonComponents/UninstallSim';
import { DEVICESTATUS } from  '../../constants';
const moment = require('moment');

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

let jobMessages = getJobMessages(),
    errorMessages = getErrorMessages();

const messages = {
	...jobMessages,
    ...errorMessages
};

class Deactivate extends React.Component {

	componentDidMount () {
        this.checkDate();
    }

    checkDate = () => {
        const { props, handleDateSave } = this;
        const { getField } = props;
        const programmedDate = getField("programmedDate");
        handleDateSave(programmedDate);
    }

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    renderDeviceSelector = () => {
        const { form, saveFields, getField } = this.props;
        const { resetFields } = form;
        const save = gps => {
            saveFields({ gps, deviceStatus: undefined });
            resetFields(["deviceStatus"]);
        }
        const gps = getField("gps");
        const clientId = getField("client.clientId");
        return (
            <Device
                form={form}
                search={(deviceName) => DeactivateDeviceSearch(deviceName, clientId, 10)}
                device={gps}
                disabled={clientId === undefined}
                onChange={save}
            />
        );
    }

    customDateValidator = (rule, value, callback) => {
        const { getIntl } = this;
        const now = moment();
        const six = moment('18:00:00', 'hh:mm:ss');
        if (value < moment().startOf('day')) return callback(getIntl("invalidDateError"));
        if (value.isSame(now, 'day') && now > six) return callback(getIntl("deactivatePastSixError"));
		callback();
    }

    customDateGetValueFromEvent = (programmedDate) => {
        if (!event) return null;
        const momentAtSix = moment(programmedDate).set({ hour: 18, minute: 0, second: 0, millisecond: 0 });
        return momentAtSix;
    }

    handleDateSave = (programmedDate) => {
        const { saveFields } = this.props;
        if (!programmedDate) saveFields({ programmedDate: "" });
        else {
            const momentAtSix = moment(programmedDate).set({ hour: 18, minute: 0, second: 0, millisecond: 0 });
            saveFields({ programmedDate: momentAtSix.toISOString() });
        }
    }

    renderDate = () => {
        const { props, customDateValidator, handleDateSave, customDateGetValueFromEvent } = this;
        const { form, getField } = props;
        const programmedDate = getField("programmedDate");
        const clientId = getField("client.clientId");
        return (
            <DatePicker
                form={form}
                programmedDate={programmedDate}
                onChange={handleDateSave}
                showTime={false}
                customValidator={customDateValidator}
                disabled={clientId === undefined}
                getValueFromEvent={customDateGetValueFromEvent}
            />
        );
    }

    renderDeviceStatusPicker = () => {
        const { props, getIntl } = this;
        const { form, saveFields, getField } = props;
        const { getFieldDecorator, resetFields } = form;
        const save = deviceStatusId => {
            const deactivateData = getField("deactivateData");
            saveFields({ deactivateData: { ...deactivateData, deviceStatusId }, uninstallSim: undefined });
            resetFields(["uninstallSim"]);
        }
        const gpsActive = getField("gps.active");
        const deviceStatusId = getField("deactivateData.deviceStatusId");
        const clientId = getField("client.clientId");
        const disable = gpsActive === undefined;
        const options = [
            <RadioButton key={DEVICESTATUS.HIDDEN} value={DEVICESTATUS.HIDDEN} disabled={disable ? false : !gpsActive}>{getIntl("hide")}</RadioButton>,
            <RadioButton key={DEVICESTATUS.CANCELLED} value={DEVICESTATUS.CANCELLED}>{getIntl("cancel")}</RadioButton>
        ];
        return (
            <FormItem label={getIntl("deviceStatus")} >
            {
                getFieldDecorator(`deviceStatusId`, 
                    {
                        initialValue: deviceStatusId,
                        rules: [{
                            required: true,
                            message: getIntl("deviceStatusError"), 
                        }],
                        onChange: (event) => save(event.target.value)
                    }
                )(<RadioGroup disabled={disable || clientId === undefined}>{options}</RadioGroup>)
            }
            </FormItem>
        );
    }

    renderClientKeepsDevice = () => {
        const { form, saveFields, getField } = this.props;
        const save = clientKeepsDevice => {
            const deactivateData = getField("deactivateData");
            saveFields({ deactivateData: { ...deactivateData, clientKeepsDevice } });
        }
        const clientKeepsDevice = getField("deactivateData.clientKeepsDevice");
        const clientId = getField("client.clientId");
        return (
            <ClientKeepsDevice
                form={form}
                clientKeepsDevice={clientKeepsDevice}
                onChange={save}
                disabled={clientId === undefined}
            />
        );
    }

    renderUninstallSim = () => {
        const { form, saveFields, getField } = this.props;
        const save = uninstallSim => {
            const deactivateData = getField("deactivateData");
            saveFields({ deactivateData: { ...deactivateData, uninstallSim } });
        }
        const uninstallSim = getField("deactivateData.uninstallSim");
        const clientId = getField("client.clientId");
        const deviceStatusId = getField("deactivateData.deviceStatusId");
        return (
            <UninstallSim
                form={form}
                uninstallSim={uninstallSim}
                onChange={save}
                disabled={clientId === undefined}
                disableYes={deviceStatusId && deviceStatusId === DEVICESTATUS.HIDDEN}
            />
        );
    }

    fullRender = () => {
        const { renderDeviceSelector, renderDate, renderDeviceStatusPicker, renderClientKeepsDevice, renderUninstallSim } = this;
        return (
            <div>
                {renderDeviceSelector()}
                {renderDeviceStatusPicker()}
                {renderClientKeepsDevice()}
                {renderUninstallSim()}
                {renderDate()}
            </div>
        );
    }
    
	render() {
        return this.fullRender();
    }
}

Deactivate.defaultProps = {
    isUpdate: false
};

Deactivate.propTypes = {
	intl: PropTypes.object.isRequired,
    isMaster: PropTypes.bool.isRequired,
    
    form: PropTypes.object.isRequired,
    saveFields: PropTypes.func.isRequired,
    getField: PropTypes.func.isRequired,
    isUpdate: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
	return {
		isMaster: state.auth.isMaster
	};
};

export default connect(mapStateToProps)(injectIntl(Deactivate));