import React from 'react';
import PropTypes from 'prop-types';
import { List, Collapse } from 'antd';
import { injectIntl } from 'react-intl';
import { getJobMessages, getErrorMessages, getLanguageIntl, getFields } from '../../../../constants/messages';
import { getNestedValue } from '../../../../utilities/util';
import { getJobStatus } from '../../constants';
import CircleBadge from '../../../GlobalComponents/CircleBadge';
import Embolden from '../../../GlobalComponents/Embolden';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
const { Panel } = Collapse;

let jobMessages = getJobMessages(),
    errorMessages = getErrorMessages(),
	fieldMessages = getFields(),
    intlMessages = getLanguageIntl();

const messages = {
	...jobMessages,
    ...errorMessages,
	...fieldMessages,
    ...intlMessages
};

class ActivityLog extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            isExpanded: false
        };
    }

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    emboldenIntl = (headerIntl, value) => {
        const { getIntl } = this;
        return <div><Embolden header={getIntl(headerIntl)} value={value}/></div>;
    }

    renderCircleBadge = (status) => {
        const { getIntl } = this;
        const STATUS = getJobStatus(status);
        const { iconColor, intl } = STATUS;
        return <CircleBadge color={iconColor} text={getIntl(intl)}/>;
    }

    formatDate = (unformatedDate) => {
        const { getIntl } = this;
        const date = new Date(unformatedDate);
        const locale = getIntl("intl");
        const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const timeOptions = { hour: '2-digit', minute:'2-digit', hour12: true };
        return date.toLocaleDateString(locale, dateOptions) + ', ' + date.toLocaleTimeString(locale, timeOptions);
    }

    renderExpandButton = () => {
        const { state, getIntl } = this;
        const { isExpanded } = state;
        return (
            <div className="job-comments-expand">
                <a onClick={() => this.setState({ isExpanded: !isExpanded })}>
                    {isExpanded ? <CaretDownOutlined /> : <CaretRightOutlined />}
                    {getIntl("activityLog")}
                </a>
            </div>
        );
    }

    renderActivityLog = () => {
        const { props, emboldenIntl, renderCircleBadge, formatDate } = this;
        const { activityLog } = props;
        const render = (log) => {
            const { date, message, user, status } = log;
            return(
                <List.Item>
                    <div>
                        {emboldenIntl("date", formatDate(date))}
                        {emboldenIntl("message", message)}
                        {emboldenIntl("user", getNestedValue("name", user))}
                        {emboldenIntl("status", renderCircleBadge(status))}
                    </div>
                </List.Item>
            );
        }
        return (
            <List
                className={`job-scrollable-list`}
                bordered={true}
                dataSource={activityLog}
                renderItem={render}
            />
        );
    }

    fullRender = () => {
        const { state, props, renderExpandButton, renderActivityLog } = this;
        const { isExpanded } = state;
        const { activityLog } = props;
        if (activityLog.length > 0)
            return (
                <Collapse className="job-view-reconnect-collapse" bordered={false} activeKey={isExpanded ? 1 : 0}>
                    <Panel key={1} showArrow={false}  header={renderExpandButton()}>
                        {renderActivityLog()}
                    </Panel>
                </Collapse>
            );
        else return null;
    }
    
	render() {
        return this.fullRender();
    }
}

ActivityLog.defaultProps = {
    activityLog: []
};

ActivityLog.propTypes = {
	intl: PropTypes.object.isRequired,
	activityLog: PropTypes.array.isRequired
};

export default injectIntl(ActivityLog);