import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Modal, Input, DatePicker, Row, Col, InputNumber, Radio } from 'antd';
import { injectIntl } from 'react-intl';
import { getReportMessages, getIncomeMessages } from '../../../constants/messages';
import React from 'react';
import ItemListHandler from './itemListHandler';
import { getCurrencyDisplayer, getNestedValue } from '../../../utilities/util';

const moment = require('moment');

const { TextArea } = Input;
const dateFormat = 'YYYY/MM/DD';

let reportMessages = getReportMessages(),
    incomeMessages = getIncomeMessages();

const messages = {
    ...reportMessages,
    ...incomeMessages
};

class AddEntry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            details: [],
            focused: false,
            isLoading: false
        }
    }

    componentDidMount() {
    }

    getDetailsTotal(detailsAmount) {
        if (detailsAmount.length < 1)
            return 0;
        return detailsAmount.reduce((total, sum) => {
            if (sum !== undefined)
                return total + sum;
            return total;
        });
    }

    setLoading = (isLoading) => {
        this.setState({ isLoading });
    }

	getIntl = (str) => {
		return this.props.intl.formatMessage({...messages[str]});
    }

    getEditEntryField = (field) => {
        const { entryParams } = this.props;
        const editEntry = getNestedValue("editEntry", entryParams);
        return getNestedValue(field, editEntry);
    }

    submit = (entryObj) => {
        const { props, setLoading } = this;
        const { handleSubmit } = props;
        setLoading(true);
        handleSubmit(entryObj)
        .then(() => setLoading(false));
    }

    submitEntry = () => {
        const { submit } = this;
        this.props.form.validateFields({ force: true }, (err, values) => {
            if (err)
                return;
            let details = this.joinArrays(values.categories, values.amounts, values.comments);
            let entryObj = {
                date: values.date,
                referenceNumber: values.refNumber,
                description: values.description,
                value: values.total,
                isIncome: values.isIncome,
                bankId: this.props.entryParams.bank.id,
                details: details,
                balance: (this.props.entryParams.cardParams.id) ? this.props.entryParams.editEntry.balance : this.calculateBalance(values.total),
                extraDetail: values.extraDetail
            }
            submit(entryObj);
        });
    }

    amountValidator = (rule, value, callback) => {
        if (!(value > 0))
            callback(this.props.intl.formatMessage({ ...messages.invalidAmount }));

        let detailsTotal = this.getDetailsTotal(this.state.details.map(d => d.amount))
        if (detailsTotal !== value)
            callback(this.props.intl.formatMessage({ ...messages.totalInvalid }));
        callback();
    }

    getCurrentAmount = () => {
        let total = this.props.form.getFieldValue('total');
        let subs = this.state.details.map((item) => item.amount);
        if (subs === undefined || subs.length < 1)
            return total;
        let sub = this.getDetailsTotal(subs);
        let result = total - sub;
        return result;
    }


    joinArrays = (categories, amounts, comments) => {
        if (!categories)
            return [];
        let result = categories.map((cat, index) => {
            return { category: parseInt(cat), amount: amounts[index], description: comments[index] };
        });
        return result;
    }

    updateItems = (itemList) => {
        this.setState({
            details: itemList
        })
        this.props.form.validateFields(['total', 'amounts'], { force: true }, (err) => {
            if (err)
                return;
        });
    }

    closeModal = () => {
        this.props.handleCancel();
    }

    disabledDate = (current) => {
        const { entryParams } = this.props;
        const initialDate = (entryParams !== undefined && entryParams.bank !== undefined) ? entryParams.bank.initialDate : undefined;
        if (initialDate) {
            const init = moment(initialDate).utc().startOf('day');
            return current && (current > moment().endOf('day') || current < init);
        }
        else return current && current > moment().endOf('day');
    }

    calculateBalance(total) {
        let val = this.props.form.getFieldValue("isIncome") ? total : -total;
        val = this.getBankBalance(this.props.entryParams.bank) + val;
        return val;
    }

    getBankBalance(data) {
        let bal = data.initialAmount;
        if (data.entries.length > 0)
            bal = data.entries[0].balance;
        return bal;
    }

    formatPrice = (price) => {
        let currencyDisplayer = getCurrencyDisplayer(getNestedValue("bank.isDollar", this.props.entryParams));
        const { symbol } = currencyDisplayer;
        let currencySymbol = `${symbol} `;
        
        if (isNaN(parseFloat(price)))
            return currencySymbol;

        if (this.state.focused)
            return (currencySymbol + price);
        
        let formatedPrice = parseFloat(price).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 4
        });

        return (currencySymbol + formatedPrice);
    }

    renderTypeRadio = () => {
        const { props, getEditEntryField, getIntl } = this;
        const { form } = props;
        const { getFieldDecorator } = form;
        return (
            <Form.Item label={getIntl("entryType")}>
                {getFieldDecorator('isIncome', {
                    initialValue: getEditEntryField("isIncome"),
                })(
                    <Radio.Group buttonStyle="solid">
                        <Radio.Button value={true}>{getIntl("income")}</Radio.Button>
                        <Radio.Button value={false}>{getIntl("expense")}</Radio.Button>
                    </Radio.Group>
                )}
            </Form.Item>
        );
    }


    render() {
        const { state, props, getIntl, closeModal, isBankDefined, getModalTitle, submitEntry, disabledDate, amountValidator, formatPrice, renderTypeRadio,
            updateItems, joinArrays, getCurrentAmount, getEditEntryField } = this;
        const { isLoading, details } = state;
        const { form, entryParams, showModal } = props;
        if (!isBankDefined())
            return <div></div>;

        const { getFieldDecorator } = form;
        let currencyDisplayer = getCurrencyDisplayer(getNestedValue("bank.isDollar", entryParams));

        return (
            <Modal
                title={getModalTitle()}
                destroyOnClose
                open={showModal}
                maskClosable={false}
                onCancel={closeModal}
                footer={
                    <div>
                        <Button loading={isLoading} key="cancel" type="default" size="default" onClick={closeModal}>
                            {getIntl("cancel")}
                        </Button>
                        <Button loading={isLoading} key="submit" type="primary" size="default" onClick={submitEntry}>
                            {getIntl("accept")}
                        </Button>
                    </div>
                }
                className="addEntryModal"
            >
                <Form layout='vertical'>
                    <Row gutter={16} type="flex" align="middle">
                        <Col span={24}>
                            <Form.Item label={getIntl("description")}>
                                {getFieldDecorator('description', {
                                    rules: [{
                                        required: true, message: getIntl("description"),
                                    }],
                                    initialValue: getEditEntryField("description")
                                })(
                                    <TextArea 
                                        placeholder={getIntl("description")} autosize 
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} type="flex" align="middle">
                        <Col span={24}>
                            <Form.Item label={getIntl("otherDetails")}>
                                {getFieldDecorator('extraDetail', {
                                    rules: [{}],
                                    initialValue: getEditEntryField("extraDetail")
                                })(
                                    <TextArea 
                                        placeholder={getIntl("otherDetails")} autosize
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} type="flex" align="middle">
                        <Col span={12}>
                            <Form.Item label={getIntl("date")}>
                                {getFieldDecorator('date', {
                                    rules: [{
                                        required: true, message: getIntl("date"),
                                    }],
                                    initialValue: moment(getEditEntryField("date"))
                                })(
                                    <DatePicker 
                                        style={{ width: '100%' }}
                                        disabledDate={disabledDate} format={dateFormat} 
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12} className="numberInput">
                            <Form.Item label={getIntl("refNumber")}>
                                {getFieldDecorator('refNumber',
                                    {
                                        initialValue: getEditEntryField("referenceNumber")
                                    })(
                                        <Input
                                            placeholder={getIntl("refNumber")}
                                            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        />
                                    )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} type="flex" align="middle">
                        <Col span={12}>
                            <Form.Item label={getIntl("amount")}>
                                {getFieldDecorator('total', {
                                    rules: [{
                                        required: true, message: getIntl("amount"),
                                    },
                                    {
                                        validator: amountValidator
                                    }],
                                    initialValue: getEditEntryField("value")
                                })(
                                    <InputNumber
                                        placeholder={getIntl("amount")}
                                        formatter={value => formatPrice(value)}
                                        parser={value => value.replace(/[^0-9.]/g, "")}
                                        size={"default"}
                                        style={{ width: '100%', 'textAlign': 'right' }}
                                        precision={4}
                                        onFocus={() => this.setState({focused: true})}
                                        onBlur={() => this.setState({focused: false})}
                                        min={0.01}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{paddingLeft: "10px"}}>
                            {renderTypeRadio()}
                        </Col>
                    </Row>
                    <ItemListHandler
                        details={details}
                        joinArrays={joinArrays}
                        updateItems={updateItems}
                        form={form}
                        getCurrentAmount={getCurrentAmount}
                        currencyDisplayer={currencyDisplayer}
                        entryCategories={getEditEntryField("EntryCategories")}
                    />
                </Form>
            </Modal>
        );
    }

    getModalTitle = () => {
        let bankName = this.props.entryParams.bank ? `: ${this.props.entryParams.bank.name}` : "";
        return `${this.props.intl.formatMessage({ ...messages.addEntry })} ${bankName}`;
    }

    isBankDefined = () => {
        return this.props.entryParams !== undefined && this.props.entryParams.bank !== undefined;
    }
}

AddEntry.propTypes = {
    entryParams: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    showModal: PropTypes.bool.isRequired,
};


export default injectIntl(Form.create()(AddEntry));