import React from 'react';
import PropTypes from 'prop-types';
import { message, Spin, Button, Typography } from 'antd';
import { injectIntl } from 'react-intl';
import { getPayrollMessages, getModalMessages, getLanguageIntl } from '../../../constants/messages';
import { formatPrice, columnTemplate } from '../constants';
import { existsInArray } from '../../../utilities/util';
import PayrollTable from '../CommonRenders/PayrollTable';
import { withRouter } from 'react-router-dom';
const moment = require('moment');
const { Title } = Typography;

let payrollMessages = getPayrollMessages(),
	modalMessages = getModalMessages(),
	intlMessages = getLanguageIntl();

const messages = {
	...payrollMessages,
	...modalMessages,
	...intlMessages
};

class ViewPayroll extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: false
		};
	}

	componentDidMount() {
		const { getInfo } = this;
		getInfo();
	}

	getInfo = () => {
		const { props, getIntl, setLoading } = this;
		const { getPayrollInfo } = props;
		const { id } = props.match.params;
		setLoading(true);
		getPayrollInfo(id)
			.then(() => setLoading(false))
			.catch(() => {
				setLoading(false);
				message.error(getIntl("getPayrollInfoError"));
			});
	}

	getIntl = (str) => {
		return this.props.intl.formatMessage({ ...messages[str] });
	}

	setLoading = (isLoading) => {
		this.setState({ isLoading });
	}

	calculateDeductions = (deductions) => {
		return deductions.reduce((diff, deduction) => {
			const { name, amount } = deduction;
			return {
				...diff,
				[name]: amount
			}
		}, {});
	}

	calculateBonuses = (bonuses) => {
		return bonuses.reduce((diff, bonus) => {
			const { name, amount } = bonus;
			return {
				...diff,
				[`bonus${name.trim()}`]: { name, amount }
			}
		}, {});
	}

	getData = () => {
		const { props, calculateDeductions, calculateBonuses } = this;
		const { payrollInfo } = props;
		const employees = payrollInfo.employees || [];
		return employees.map(employee => {
			const { salary, deductions, bonuses = [] } = employee;
			const ded = calculateDeductions(deductions);
			const totalDeductions = Object.keys(ded).reduce((accumulated, key) => accumulated + ded[key], 0);
			const calculatedBonuses = calculateBonuses(bonuses);
			const totalBonuses = Object.keys(calculatedBonuses).reduce((accumulated, key) => accumulated + calculatedBonuses[key].amount, 0);
			const accreditedSalary = salary - totalDeductions + totalBonuses;
			return {
				...employee,
				...ded,
				...calculatedBonuses,
				accreditedSalary
			};
		});
	}

	getDeductionColumns = (data) => {
		const deductionNames = [];
		data.forEach(employee => {
			const deductions = employee.deductions || [];
			deductions.forEach(deduction => {
				const { name } = deduction;
				if (!existsInArray(name, deductionNames) && name !== "ihss" && name !== "isr") deductionNames.push(name);
			});
		});
		return deductionNames.map((name) => columnTemplate(name, name, (amount) => amount ? formatPrice(amount) : ""));
	}

	getBonusColumns = (data) => {

		const bonusNames = [];
		data.forEach(employee => {
			const { bonuses = [] } = employee;
			bonuses.forEach(bonus => {
				const { name } = bonus;
				if (!existsInArray(name, bonusNames)) {
					bonusNames.push(name);
				}
			});
		});

		return bonusNames.map((name, index) => columnTemplate(bonusNames[index], `bonus${name.trim()}`, (bonus) => {
			return bonus && bonus.amount ? formatPrice(bonus.amount) : "";
		}));
	}

	tableRender = () => {
		const { props, getData, getDeductionColumns, getBonusColumns } = this;
		const { payrollInfo } = props;
		const { payrollDate } = payrollInfo;
		const data = getData();
		const deductionColumns = getDeductionColumns(data);
		const bonusColumns = getBonusColumns(data);
		return (
			<PayrollTable
				records={data}
				deductionColumns={deductionColumns}
				bonusColumns={bonusColumns}
				date={moment(payrollDate).toISOString()}
			/>
		);
	}

	returnToPayrolls = () => {
		this.props.history.push("/payrolls");
	}

	downloadVouchers = () => {
		const { getIntl, props, setLoading } = this;
		const { downloadVouchers } = props;
		const { id } = props.match.params;
		setLoading(true);
		downloadVouchers(id)
			.then(() => setLoading(false))
			.catch(() => {
				setLoading(false);
				message.error(getIntl("downloadVouchersError"));
			});
	}

	downloadExcel = () => {
		const { getIntl, props, setLoading } = this;
		const { downloadExcel } = props;
		const { id } = props.match.params;
		setLoading(true);
		downloadExcel(id)
			.then(() => setLoading(false))
			.catch(() => {
				setLoading(false);
				message.error(getIntl("downloadExcelError"));
			});
	}

	renderButtons = () => {
		const { getIntl, state, returnToPayrolls, downloadVouchers, downloadExcel } = this;
		const { isLoading } = state;
		const margin = "0.5em";
		const returnButtons = [
			<Button
				style={{ marginTop: margin, marginRight: margin }}
				onClick={returnToPayrolls}
				key="return"
				loading={isLoading}
			>
				{getIntl("return")}
			</Button>,
			<Button
				style={{ marginTop: margin, marginRight: margin }}
				type="primary"
				onClick={downloadVouchers}
				key="download-vouchers"
				loading={isLoading}
			>
				{getIntl("downloadVouchers")}
			</Button>,
			<Button
				style={{ marginTop: margin }}
				type="primary"
				onClick={downloadExcel}
				key="download-excel"
				loading={isLoading}
			>
				{getIntl("downloadExcel")}
			</Button>
		];
		return (
			<div style={{ textAlign: 'right' }}>
				{returnButtons}
			</div>
		);
	}

	dateRender = (date) => {
		if (!date) return;
		const m = moment(date).utc();
		return new Date(m.year(), m.month(), m.date()).toLocaleDateString(this.getIntl("intl"));
	}

	renderDates = () => {
		const { getIntl, props, dateRender } = this;
		const { payrollInfo } = props;
		const { payrollDate } = payrollInfo;
		return <Title level={4}>{getIntl("payrollDate")}: {dateRender(payrollDate)}</Title>;
	}

	fullRender = () => {
		const { state, tableRender, renderButtons, renderDates } = this;
		const { isLoading } = state;
		return (
			<Spin spinning={isLoading}>
				<div className="view">
					{renderDates()}
					{tableRender()}
					{renderButtons()}
				</div>
			</Spin>
		);
	}

	render() {
		return this.fullRender();
	}
}

ViewPayroll.defaultProps = {
	payrollInfo: {}
};

ViewPayroll.propTypes = {
	intl: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	match: PropTypes.shape({
		params: PropTypes.object.isRequired
	}).isRequired,
	getPayrollInfo: PropTypes.func.isRequired,
	payrollInfo: PropTypes.object.isRequired,
	downloadVouchers: PropTypes.func.isRequired,
	downloadExcel: PropTypes.func.isRequired
};

export default withRouter(injectIntl(ViewPayroll));