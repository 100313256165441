import axios from 'axios';
import { FETCH_LOTUNIT, ADD_LOTUNIT_SUCCESS, EDIT_LOTUNIT_SUCCESS, DELETE_LOTUNIT_SUCCESS, SET_SEARCH_LOTUNITS} from './actionTypes';

export const AddLot = (values, lot) => async (dispatch) => {
	const response = await axios.post(`LotUnits/AddUnitLot`, { values, lot });
	dispatch({ type: ADD_LOTUNIT_SUCCESS, lotUnits: response.data });
	return response.data;
};

export const GetAllLotsUnits = () => async (dispatch) => {
	const response = await axios.get(`LotUnits/getLotUnits`);
	dispatch({ type: FETCH_LOTUNIT, lotUnits: response.data });
	return response.data;
};

export const DeleteLotUnit = (units, ids) => async (dispatch, getState) => {
	let state = getState();
	const lotUnits = state.lotUnit.lotUnits;

	const response = await axios.post(`LotUnits/DeleteLotUnit`, {units, ids});
	const result = lotUnits.filter(unit => !ids.includes(unit.id));
	dispatch({ type: DELETE_LOTUNIT_SUCCESS, lotUnits: result });
	return response.data;
};

export const AssignTechnical = (technical ,ids) => async (dispatch) => { 
	const response = await axios.post(`LotUnits/AssignTechnical`, {technical, ids})
	dispatch({ type: EDIT_LOTUNIT_SUCCESS, lotUnits: response.data });
	return response.data;
};

export const setSearch = (value) => (dispatch) => {
	dispatch({type:SET_SEARCH_LOTUNITS, search:value});
}