import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { CaretDownOutlined, CaretRightOutlined, MinusOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Row, Col, List, Input, Spin, message, Collapse } from 'antd';
import { injectIntl } from 'react-intl';
import { getactionMessages, getFields, getJobMessages, getModalMessages } from '../../../../constants/messages';
import { AddComments } from '../../actions';
const { Panel } = Collapse;

const ListItem = List.Item;
const ListItemMeta = List.Item.Meta;
const FormItem = Form.Item;

let actionMessages = getactionMessages(),
    fieldMessages = getFields(),
    jobMessages = getJobMessages(),
    modalMessages = getModalMessages();

const messages = {
    ...actionMessages,
    ...fieldMessages,
    ...jobMessages,
    ...modalMessages
};

const NewCommentText = forwardRef((props, ref) => {
    // eslint-disable-next-line react/prop-types
    return <div ref={ref}>{props.value}</div>
});
NewCommentText.displayName = "NewCommentText";

class Comments extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isExpanded: false
        };
    }

    getIntl = (str) => this.props.intl.formatMessage({ ...messages[str] });
    setLoading = (isLoading) => this.setState({ isLoading });

    add = (comment) => {
        if (comment.length <= 0) return;
        const { comments, onChange } = this.props;
        const copy = [...comments];
        copy.unshift({ comment });
        onChange(copy);
    }

    remove = (index) => {
        const { comments, onChange } = this.props;
        const copy = [...comments];
        copy.splice(index, 1);
        onChange(copy);
    }

    save = () => {
        const { props, setLoading, getIntl } = this;
        const { form, addComments, onChange, jobId } = props;
        const { validateFields } = form;
        validateFields(["comments"], { force: true }, (err, values) => {
            if (!err) {
                const { comments } = values;
                setLoading(true);
                addComments(jobId, comments)
                    .then(newComments => {
                        setLoading(false);
                        message.success(getIntl("jobCommentAddSuccess"));
                        onChange(newComments);
                    })
                    .catch(() => {
                        setLoading(false);
                        message.error(getIntl("jobCommentAddError"));
                    });
            }
        });
    }

    getAndAddComment = () => {
        const { props, add } = this;
        const { validateFieldsAndScroll, resetFields } = props.form;
        validateFieldsAndScroll(["comment"], { force: true }, (err, values) => {
            if (!err) {
                const { comment } = values;
                add(comment);
                resetFields(["comment"]);
            }
        });
    }

    renderCommentInput = () => {
        const { props, getIntl, getAndAddComment } = this;
        const { form, disabled } = props;
        const { getFieldDecorator } = form;
        return (
            <FormItem className="job-comment-adder" >
                {
                    getFieldDecorator('comment',
                        {
                            rules: [
                                {
                                    max: 255,
                                    message: getIntl("jobCommentLengthError")
                                },
                                {
                                    whitespace: true,
                                    message: getIntl("jobCommentWhitespaceError")
                                }
                            ]
                        }
                    )(
                        <Input
                            className="job-full-component"
                            placeholder={getIntl("comment")}
                            disabled={disabled}
                            onPressEnter={getAndAddComment}
                        />
                    )
                }
            </FormItem>
        );
    }

    renderAddButton = () => {
        const { props, getIntl, getAndAddComment } = this;
        const { disabled } = props;
        return (
            <Button className="job-full-component" disabled={disabled} onClick={getAndAddComment} >
                {getIntl("add")}
            </Button>
        );
    }

    renderFullCommentAdder = () => {
        const { renderCommentInput, renderAddButton } = this;
        return (
            <Row>
                <Col span={20}>{renderCommentInput()}</Col>
                <Col span={4}>{renderAddButton()}</Col>
            </Row>
        );
    }

    renderCommentItem = (com, index) => {
        const { props, remove } = this;
        const { disabled, form } = props;
        const { getFieldDecorator } = form;
        const { comment, id } = com;
        const actions = [];
        if (!id) actions.push(<Button disabled={disabled} shape="circle" icon={<MinusOutlined />} size="small" onClick={() => remove(index)} />);
        const title = id ? comment :
            (
                <div>
                    {
                        getFieldDecorator(`comments[${index}]`,
                            {
                                initialValue: comment
                            }
                        )(
                            <NewCommentText />
                        )
                    }
                </div>
            );
        return (
            <ListItem key={index} actions={actions}>
                <ListItemMeta title={title} />
            </ListItem>
        );
    }

    renderList = () => {
        const { props, renderCommentItem } = this;
        const { comments } = props;
        return (
            <List
                className="job-scrollable-list"
                size="small"
                bordered={true}
                dataSource={comments}
                renderItem={renderCommentItem}
            />
        );
    }

    renderSaveButton = () => {
        const { state, props, save, getIntl } = this;
        const { isLoading } = state;
        const { comments, independent, disabled } = props;
        const newComment = comments.some(comment => comment.id === undefined);
        if (independent)
            return (
                <Button type="primary" icon={<SaveOutlined />} loading={isLoading} disabled={!newComment || disabled} onClick={save}>
                    {getIntl("save")}
                </Button>
            );
    }

    renderExpandButton = () => {
        const { state, getIntl } = this;
        const { isExpanded } = state;
        return (
            <div className="job-comments-expand">
                <a onClick={() => this.setState({ isExpanded: !isExpanded })}>
                    {isExpanded ? <CaretDownOutlined /> : <CaretRightOutlined />}
                    {getIntl("comments")}
                </a>
            </div>
        );
    }

    renderComments = () => {
        const { state, renderFullCommentAdder, renderList, renderSaveButton } = this;
        const { isLoading } = state;
        return (
            <Spin spinning={isLoading}>
                {renderFullCommentAdder()}
                {renderList()}
                {renderSaveButton()}
            </Spin>
        );
    }

    fullRender = () => {
        const { state, renderExpandButton, renderComments } = this;
        const { isExpanded } = state;
        return (
            <Collapse className="job-view-reconnect-collapse" bordered={false} activeKey={isExpanded ? 1 : 0}>
                <Panel key={1} showArrow={false} header={renderExpandButton()}>
                    {renderComments()}
                </Panel>
            </Collapse>
        );
    }

    render() {
        return this.fullRender();
    }
}

Comments.defaultProps = {
    comments: [],
    disabled: false,
    independent: false,
    jobId: 0
};

Comments.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    comments: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    independent: PropTypes.bool.isRequired,
    addComments: PropTypes.func.isRequired,
    jobId: PropTypes.number.isRequired
};

const mapDispatchToProps = (dispatch) => {
    return {
        addComments: (jobId, comments) => dispatch(AddComments(jobId, comments))
    };
};

export default connect(null, mapDispatchToProps)(injectIntl(Comments));