import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { InputNumber, Row, Col, Select } from 'antd';
import {getAuthMessages, getModalMessages, getFields, getErrorMessages, getRegistrationMessages, getClientMessages} from '../../../../constants/messages';
import { injectIntl } from 'react-intl';
import RemoveButton from './RemoveButton';

let authMessages = getAuthMessages(), 
  errorMessages = getErrorMessages(), 
  fieldMessages = getFields(), 
  modalMessages = getModalMessages(), 
  RegistrationMessages = getRegistrationMessages(), 
  ClientMessages = getClientMessages();

const messages = { 
  ...authMessages, 
  ...modalMessages, 
  ...fieldMessages, 
  ...errorMessages, 
  ...RegistrationMessages, 
  ...ClientMessages 
};

const FormItem = Form.Item;

class CustomPrice extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
            focused: false
        };
	}

    formatPrice = (price) => {
        let currencySymbol = this.props.isLps ? "L " : "$ ";
        
        if (isNaN(parseFloat(price)))
            return "";

        if (this.state.focused)
            return (currencySymbol + price);
        
        let formatedPrice = parseFloat(price).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 4
        });

        return (currencySymbol + formatedPrice);
    }

    renderCustomPrice = () => {
        const {intl, form, productOptions, customPrice, customPriceIndex, saveProductFunction, savePriceFunction, removeCustomPriceFunction} = this.props;
        const { getFieldDecorator } = form;
        return (
            <Row>
                <Col span={16}>
                    <FormItem>
                    {
                        getFieldDecorator(`products[${customPriceIndex}]`, 
                            {
                                initialValue: customPrice.productId !== 0 ? customPrice.productId : "",
                                trigger: 'onChange',
                                validateTrigger: ['onChange'],
                                rules: [{
                                    required: true,
                                    message: intl.formatMessage( {...messages.productError} ), 
                                }],
                                onChange: (value) => saveProductFunction(value, customPriceIndex),
                            }
                        )(
                            <Select
                                showSearch
                                style={{ width: '95%', marginRight: 8 }}
                                placeholder={intl.formatMessage( {...messages.productPlaceholder} )}
                                optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                dropdownMatchSelectWidth={false}
                            >
                                {productOptions}
                            </Select>
                        )
                    }
                    </FormItem>
                </Col>
                <Col span={7}>
                    <FormItem>
                    {
                        getFieldDecorator(`prices[${customPriceIndex}]`, 
                            {
                                initialValue: customPrice.price,
                                validateTrigger: 'onChange',
                                rules: [{
                                    required: true,
                                    message: intl.formatMessage( {...messages.priceError} ),
                                }],
                                onChange: (value) => savePriceFunction(value, customPriceIndex),
                            }
                        )(
                            <InputNumber
                                min={0}
                                precision={4}
                                formatter={value => this.formatPrice(value)}
                                parser={value => value.replace(/[^0-9.]/g, "")}
                                style={{ width: '95%', marginRight: 8 }}
                                onFocus={() => this.setState({focused: true})}
                                onBlur={() => this.setState({focused: false})}
                            />
                        )
                    }
                    </FormItem>
                </Col>
                <Col span={1} style={{ paddingTop: "4px" }}>
                    <RemoveButton removeFunction={() => removeCustomPriceFunction(customPriceIndex)} toolTipText={intl.formatMessage({...messages.removeCustomPrice})}/>
                </Col>
            </Row>
        );
    }

    render() {
        return ( 
            this.renderCustomPrice()
        ); 
    }

}

CustomPrice.defaultProps = { 
    customPrice: {},
    isLps: false,
    productOptions: []
};

CustomPrice.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    isLps: PropTypes.bool.isRequired,
    customPrice: PropTypes.object.isRequired,
    customPriceIndex: PropTypes.number.isRequired,
    productOptions: PropTypes.array.isRequired,
    saveProductFunction: PropTypes.func.isRequired,
    savePriceFunction: PropTypes.func.isRequired,
	removeCustomPriceFunction: PropTypes.func.isRequired,
};

export default injectIntl(CustomPrice);