import React from 'react'
import { useIntl } from 'react-intl';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import CommonTable from '../../../../components/CommonTable';
import { getClientMessages, getReportMessages } from '../../../../constants/messages';

let clientMessages = getClientMessages(),
    reportMessages = getReportMessages();

const messages = {
    ...clientMessages,
    ...reportMessages,
};

const LeasedServicePriceReport = ({clients, loading, downloadReport}) => {
    const intl = useIntl();

    const columns = [
        {
            title: intl.formatMessage({ ...messages.clientName }),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: intl.formatMessage({ ...messages.service }),
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: intl.formatMessage({ ...messages.price }),
            dataIndex: 'price',
            key: 'price',
            render: (price) => {
                return `$ ${price}`
            }
        },
        {
            title: intl.formatMessage({ ...messages.leasedDevices }),
            dataIndex: 'devices',
            key: 'devices',
        },
    ];

    const extraComponents = (
		<Button
			onClick={() => downloadReport()}
            icon={<DownloadOutlined />}
		>
            {intl.formatMessage({...messages.exportReport})}
		</Button>
	);

    return (
        <div className="account view">
            <CommonTable
                columns={columns}
                dataSource={clients}
                recordCount={clients.length}
                loading={loading}
				extraComponents={[extraComponents]}
                search={() => ({})}
                getCheckboxProps={() => ({})}
                getRecords={() => { }}
                hideCheckboxes
                disableSearchBar
            />
        </div>
    )
}

LeasedServicePriceReport.propTypes = {
    clients: PropTypes.array.isRequired,
    downloadReport: PropTypes.func.isRequired,
    loading: PropTypes.bool
};

export default LeasedServicePriceReport
