import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { GetInvoicesByReceipt } from './actions';
import { getInvoicingMessages, getPaymentMessages } from '../../constants/messages';
import { Col, Row, Spin, Typography, message } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import PopoverProductsTable from '../Invoices/PopoverProductsTable';
import { roundN } from '../../utilities/util';
import { useHistory } from 'react-router';

let PaymentMessage = getPaymentMessages(),
    invoicingMessages = getInvoicingMessages();

const messages = {
	...PaymentMessage,
    ...invoicingMessages,
}

const InvoicesByReceipts = ({receiptId}) => {
    const [loading, setLoading] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const intl = useIntl();
    let history = useHistory();


    useEffect(() => {
		(async () => {
            try {
                setLoading(true)
                const response = await GetInvoicesByReceipt(receiptId);
                setInvoices(response);
            } catch (error) {
                message.error(intl.formatMessage({ ...messages.invoicesError }));   
            }finally{
                setLoading(false);
            }

        })();	
    }, []);

    const getProductList = (result) => {
        let productList = [];

        result.products.map((products)=>{
            productList.push(products);
        })

        return productList;
    }


    const renderInvoices = () => {

        if(invoices.length == 0) return null;

        return invoices.map((object) => {
            const productList = getProductList(object);
            return (
                <Row key={object.invoiceId}>
                    <Col span={8} offset={4}>
                        <PopoverProductsTable products={productList}>
                            <Typography.Link className="editable-add-btn" onClick={() => { history.push(`/invoices/${object.id}`); }}>
                                {object.documentNumber}
                            </Typography.Link>

                        </PopoverProductsTable>           
                    </Col>
                    <Col span={6} style={{ 'textAlign': 'right' }}>
                        <p>{`L ${roundN(object.total, 2)}`}</p>
                    </Col>
                </Row>
            );            
		});
	};

  return (
    <React.Fragment>
        <Spin spinning={loading}>
            <Row>
                <Col span={8} offset={4}>
                    <h4><FormattedMessage {...messages.Invoices} /></h4>
                </Col>
                <Col span={6} style={{ 'textAlign': 'right' }}>
                    <h4><FormattedMessage {...messages.Amount} /></h4>
                </Col>
            </Row>
            {renderInvoices()}
        </Spin>
	</React.Fragment>
  )
}
InvoicesByReceipts.propTypes = {
	receiptId: PropTypes.number,
};
export default InvoicesByReceipts
