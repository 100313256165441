import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { InputNumber, Input } from 'antd';
import { getInvoicingMessages, getErrorMessages, getProductMessages } from '../constants/messages';
const FormItem = Form.Item;

let invoicingMessages = getInvoicingMessages(),
	errorMessages = getErrorMessages(),
	productMessages = getProductMessages();

const messages = {
	...invoicingMessages,
	...errorMessages,
	...productMessages
};

class EditProductForm extends React.Component {

	constructor(props) {
		super(props);
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	renderProductField = () => {
		const { props: { form: { getFieldDecorator }, currentProduct: { product: { id, description } }}, getIntl } = this;
		return (
			<React.Fragment>
				<FormItem >
					{
						getFieldDecorator(`productId`, { initialValue: id }
						)(
							<Input hidden />
						)
					}
				</FormItem>
				<FormItem label={getIntl("Product")}>
					{
						getFieldDecorator(`description`,
							{
								initialValue: description,
								rules: [{
									required: true,
									message: getIntl('invoiceProductRequiredError')
								}],
							}
						)(
							<Input disabled />
						)
					}
				</FormItem>
			</React.Fragment>
		);
	}

	renderQuantityField = () => {
		const { props: {form: { getFieldDecorator }, currentProduct: { quantity }}, getIntl }  = this;
		return (
			<FormItem label={getIntl("Quantity")}>
				{
					getFieldDecorator(`quantity`,
						{
							initialValue: quantity,
						}
					)(
						<InputNumber
							className="full-component"
							min={1}
							precision={0}
						/>
					)
				}
			</FormItem>
		);
	}

	renderPriceField = () => {
		const { props: {form: { getFieldDecorator }, currentProduct: {price}}, getIntl } = this;
		return (
			<FormItem label={getIntl("Price")}>
				{
					getFieldDecorator(`price`,
						{
							initialValue: price
						}
					)(
						<InputNumber
							className="full-component"
							precision={2}
							formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
							parser={value => value.replace(/\$\s?|(,*)/g, '')}
							min={0}
						/>
					)
				}
			</FormItem>
		);
	}

	renderPriceChangeField = () => {
		const { props: {form: { getFieldDecorator, getFieldValue }, currentProduct: { price, priceChangeReason }}, getIntl } = this;
		const priceValue = getFieldValue('price');
		const showField = priceValue !== price || priceChangeReason != undefined ;
		if (showField)
			return (
				<FormItem label={getIntl("priceChangeReason")} >
					{
						getFieldDecorator('priceChangeReason',
							{
								initialValue: priceChangeReason,
								rules: [
									{
										required: showField,
										whitespace: true,
										message: getIntl("priceChangeReasonError")
									},
									{
										max: 255,
										message: getIntl("priceChangeReasonLengthError")
									}
								]
							}
						)(
							<Input className="full-component" />
						)
					}
				</FormItem>
			);
	}

	fullRender = () => {
		const { renderProductField, renderQuantityField, renderPriceField, renderPriceChangeField } = this;
		return (
			<div>
				{renderProductField()}
				{renderQuantityField()}
				{renderPriceField()}
				{renderPriceChangeField()}
			</div>
		);
	}

	render() {
		return this.fullRender();
	}
}

EditProductForm.defaultProps = {
	currentProduct: {}
};

EditProductForm.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	currentProduct: PropTypes.object,
};

export default injectIntl(EditProductForm);
