import React from 'react'
import { Tabs } from 'antd';
import UnitsLotPage from './';
import LotPage from './../Lots';
import { AppstoreAddOutlined, AppstoreOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { getLotsMessages } from '../../constants/messages';


let LotsMessages = getLotsMessages();

const messages = {
	...LotsMessages,
}

const LotUnitsPage = () => {
    const intl = useIntl();

    const items = [
        {
            key: '1',
            label: (
                <span>
                  <AppstoreOutlined />
                  {intl.formatMessage({...messages.unitsLot})}
                </span>
            ),
            children: <UnitsLotPage />
        },
        {
            key: '2',
            label: (
                <span>
                  <AppstoreAddOutlined />
                  {intl.formatMessage({...messages.lots})}
                </span>
            ),
            children: <LotPage />
        },
    ];

    return (
        <div className="view">
            <Tabs
                defaultActiveKey="1"
                items={items}
            />
        </div>
    )
}

export default LotUnitsPage