import React from 'react';
import PropTypes from 'prop-types';
import {getQuotationMessages} from '../../constants/messages';
import { DatePicker, Select, Checkbox } from 'antd';
import {injectIntl, FormattedMessage} from 'react-intl';
import { STATUSES } from './constants';
import moment from 'moment';

let quotationMessages = getQuotationMessages();

const messages = {
    ...quotationMessages
};

const Option = Select.Option;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

class QuotationFilter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			status: 6,
			startDate: undefined,
			endDate: undefined,
			totalRange: false
		};
	}
	
    onChangeSelect = (e) => {
		this.setState({
			status: e
		});
		let filter = {
			...this.state,
			status: e
		};
		this.props.onFilter(filter);
	}
	
	onChangeRange = (e) => {
		const startDate = e[0] ? moment(e[0]).startOf('day').toISOString() : undefined;
		const endDate = e[1] ? moment(e[1]).endOf('day').toISOString() : undefined;
		this.setState({ startDate, endDate });
		let filter = {
			...this.state,
			startDate,
			endDate
		};
		this.props.onFilter(filter);
	}

	onChangeCheckBox = () => {
		this.setState({
			totalRange: !this.state.totalRange
		});
		let filter = {
			...this.state,
			totalRange: !this.state.totalRange
		};
		this.props.onFilter(filter);
	}

	render(){
		return(
			<div style={{align: 'right'}}>
				<RangePicker format={dateFormat} onChange={this.onChangeRange} style={{width: '35%', 'marginRight': '8px'}}/>
				<Select onChange={this.onChangeSelect} defaultValue={6} style={{width: '25%', 'marginRight': '8px'}}>
					<Option value={6}><FormattedMessage {...messages.All} /></Option>
					<Option value={STATUSES.READY_FOR_INVOICING}><FormattedMessage {...messages.ReadyInvoicing} /></Option>
					<Option value={STATUSES.NEEDS_APPROVAL}><FormattedMessage {...messages.NeedsApproval} /></Option>
					<Option value={STATUSES.INVOICED}><FormattedMessage {...messages.Invoiced} /></Option>
					<Option value={STATUSES.DENIED}><FormattedMessage {...messages.Denied} /></Option>
					<Option value={STATUSES.CREATED_PROFORMA}><FormattedMessage {...messages.CreatedProforma} /></Option>
					<Option value={STATUSES.EXPIRED}><FormattedMessage {...messages.Expired} /></Option>
				</Select>
				<Checkbox onChange={this.onChangeCheckBox}><FormattedMessage {...messages.ExactTotal} /></Checkbox>
			</div>
			
		);
	}
}

QuotationFilter.propTypes = {
	onFilter: PropTypes.func,
};

export default injectIntl(QuotationFilter);