import PropTypes from 'prop-types';
import { Col, List } from 'antd';
import { injectIntl } from 'react-intl';
import React from 'react';
import AccountCard from './AccountCard';
import InfiniteScroll from 'react-infinite-scroller';

class AccountList extends React.Component {
    constructor(props) {
        super(props);
        
    }

    componentDidMount() {
    }

    render() {
        return (
            <Col span={22}>
                <div className="accountList">
                    <InfiniteScroll
                        initialLoad={false}
                        loadMore={() => {}}
                        hasMore={false}
                        useWindow={false}
                    >
                        <List
                            dataSource={this.props.accounts}
                            renderItem={(item) => {
                                return (
                                    <AccountCard
                                        account={item}
                                        showEntry={this.props.showEntry}
                                    />
                                )
                            }}
                        />
                    </InfiniteScroll>
                </div>
            </Col>
        );
    }
}

AccountList.propTypes = {
    intl: PropTypes.object.isRequired,
    accounts: PropTypes.array.isRequired,
    showEntry: PropTypes.func.isRequired,
};

export default injectIntl(AccountList);

