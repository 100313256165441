import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { injectIntl } from 'react-intl';

class DeductionsTable extends React.Component  {

    renderTable = () => {
        const { props } = this;
        const { isLoading, page, pageSize, dataSource, count, onPage, columns, expandedRowRender } = props;
        const allowPagination = count > pageSize && onPage;
		const pagination = allowPagination ? {
			current: page,
			defaultPageSize: pageSize,
			onChange: onPage,
			total: count
        } : false;
        return (
            <Table
                style={{ paddingTop:"10px" }}
                rowKey={record => record.id}
                columns = {columns}
                dataSource = {dataSource}
                pagination={pagination}
                loading={isLoading}
                expandedRowRender={expandedRowRender}
                scroll={{ x: '100%' }}
                bordered
            />
        );
    }

	render(){
        return(this.renderTable());
	}
}
 
DeductionsTable.defaultProps = {
    dataSource: [],
    columns: [],
    count: 0,
    isLoading: false,
    pageSize: 10,
    page: 1
};

DeductionsTable.propTypes = {
    onPage: PropTypes.func,
    dataSource: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    expandedRowRender: PropTypes.func
};

export default injectIntl(DeductionsTable);