import React from 'react';
import PropTypes from 'prop-types';
import { getIncomeMessages } from '../../../constants/messages';
import { Radio } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';

let incomeMessages = getIncomeMessages();

const messages = {
    ...incomeMessages
};

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class FilterButtons extends React.Component {
    onChange = (e) => {
        this.props.onFilter({ status: e.target.value });
    }

    render() {
        return (
            <RadioGroup onChange={this.onChange} defaultValue={0}>
                <RadioButton value={0}><FormattedMessage {...messages.all} /></RadioButton>
                <RadioButton value={1}><FormattedMessage {...messages.disabled} /></RadioButton>
                <RadioButton value={2}><FormattedMessage {...messages.enabled} /></RadioButton>
            </RadioGroup>
        );
    }
}

FilterButtons.propTypes = {
    onFilter: PropTypes.func,
};

export default injectIntl(FilterButtons);