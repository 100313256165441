import {
    AppstoreOutlined,
    BankOutlined,
    BarsOutlined,
    CalendarOutlined,
    CheckCircleOutlined,
    DollarOutlined,
    FileTextFilled,
    HomeFilled,
    PieChartOutlined,
    ProfileOutlined,
    ReadFilled,
    ScheduleOutlined,
    TableOutlined,
    TagsFilled,
    TeamOutlined,
    UserAddOutlined,
} from '@ant-design/icons';

import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { ROLES } from '../constants/global';
import { getSideMenu } from '../constants/messages';
import { ACL4Component } from '../utilities/util';
import FiscalData from '../features/FiscalData/FiscalData';
import TaxRetentionModal from '../features/Invoices/TaxRetention/TaxRetentionModal';

let sideMenuMessages = getSideMenu();
const messages = {
	...sideMenuMessages
};

const MobileSideMenu = ({ collapse }) => {
	let history = useHistory();
	let location = useLocation();
	const dispatch = useDispatch();
	var roles = useSelector(state => state.auth.roles)
	let [showFiscalDataModal, setShowFiscalDataModal] = useState(false);
	let [showTaxRetentionModal, setShowTaxRetentionModal] = useState(false);

	useEffect(() => {
		document
			.getElementsByClassName("mobileMenu")[0]
			.closest(".ant-popover-content")
			.classList
			.add("popMenu");
	})

	const isModal = (item) => {
		let modals = ["taxRetention", "fiscalData"];
		return modals.includes(item);
	}

	const closeFiscalDataModal = () => {
		setShowFiscalDataModal(false);
	}

	const openFiscalDataModal = () => {
		setShowFiscalDataModal(true);
		collapse(false);
	}

	const closeTaxRetentionModal = () => {
		setShowTaxRetentionModal(false);
	}

	const openTaxRetentionModal = () => {
		setShowTaxRetentionModal(true)
		collapse(false);
	}

	const showModal = (key) => {
		switch (key) {
			case "fiscalData":
				return openFiscalDataModal();
			case "taxRetention":
				return openTaxRetentionModal();
			default:
				break;
		}
	}

	const navigate = (item) => {
		if (isModal(item))
			return showModal(item);
		history.push(`/${item}`);
		dispatch({ type: "TOGGLE_MENU", visible: false });
	};

	var menu = [
		{
			path: "home",
			icon: <HomeFilled className="side-icon" />,
			text: <span className="nav-text">
				<FormattedMessage {...messages.home} />
			</span>
		},
		{
			path: "accounts",
			text: <span className="nav-text">
				<FormattedMessage {...messages.account} />
			</span>,
			icon: <span className="side-icon icon-user" />,
			roles: [ROLES.MASTER]
		},
		{
			path: "preRegistration",
			text: <span className="nav-text">
				<span className="nav-text"><FormattedMessage {...messages.clients} /></span>
				<span className="nav-text"> | </span>
				<span className="nav-text"><FormattedMessage {...messages.preRegistration} /></span>
			</span>,
			icon: <UserAddOutlined className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.ADMINISTRATOR, ROLES.SALES]
		},
		{
			path: "approved",
			text: <span className="nav-text">
				<span className="nav-text"><FormattedMessage {...messages.clients} /></span>
				<span className="nav-text"> | </span>
				<span className="nav-text"><FormattedMessage {...messages.approved} /></span>
			</span>,
			icon: <CheckCircleOutlined className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.ADMINISTRATOR, ROLES.SALES, ROLES.CUSTOMERSERVICE]
		},
		{
			path: "job-calendar",
			text: <span className="nav-text">
				<span className="nav-text"><FormattedMessage {...messages.job} /></span>
				<span className="nav-text"> | </span>
				<span className="nav-text"><FormattedMessage {...messages.calendar} /></span>
			</span>,
			icon: <CalendarOutlined className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.CUSTOMERSERVICE]
		},
		{
			path: "job-table",
			text: <span className="nav-text">
				<span className="nav-text"><FormattedMessage {...messages.job} /></span>
				<span className="nav-text"> | </span>
				<span className="nav-text"><FormattedMessage {...messages.table} /></span>
			</span>,
			icon: <TableOutlined className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.CUSTOMERSERVICE, ROLES.TECHSUPPORT]
		},
		{
			path: "requests",
			text: <span className="nav-text">
				<span className="nav-text"><FormattedMessage {...messages.job} /></span>
				<span className="nav-text"> | </span>
				<span className="nav-text"><FormattedMessage {...messages.requests} /></span>
			</span>,
			icon: <TableOutlined className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.CUSTOMERSERVICE, ROLES.SALES]
		},
		{
			path: "invoices",
			text: <span className="nav-text">
				<span className="nav-text"><FormattedMessage {...messages.invoicing} /></span>
				<span className="nav-text"> | </span>
				<span className="nav-text"><FormattedMessage {...messages.invoices} /></span>
			</span>,
			icon: <FileTextFilled className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.ADMINISTRATOR, ROLES.SALES]
		},
		{
			path: "proformas",
			text: <span className="nav-text">
				<span className="nav-text"><FormattedMessage {...messages.invoicing} /></span>
				<span className="nav-text"> | </span>
				<span className="nav-text"><FormattedMessage {...messages.proformas} /></span>
			</span>,
			icon: <FileTextFilled className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.ADMINISTRATOR]
		},
		{
			path: "quotations",
			text: <span className="nav-text">
				<span className="nav-text"><FormattedMessage {...messages.invoicing} /></span>
				<span className="nav-text"> | </span>
				<span className="nav-text"><FormattedMessage {...messages.quotations} /></span>
			</span>,
			icon: <FileTextFilled className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.ADMINISTRATOR, ROLES.SALES]
		},
		{
			path: "creditNotes",
			text: <span className="nav-text">
				<FormattedMessage {...messages.creditNotes} />
			</span>,
			icon: <TagsFilled className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.ADMINISTRATOR]
		},
		{
			path: "debitNotes",
			text: <span className="nav-text">
				<FormattedMessage {...messages.debitNotes} />
			</span>,
			icon: <TagsFilled className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.ADMINISTRATOR]
		},
		{
			path: "products",
			text: <span className="nav-text">
				<FormattedMessage {...messages.products} />
			</span>,
			icon: <TagsFilled className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.ADMINISTRATOR]
		},
		{
			path: "exchangeRate",
			text: <span className="nav-text">
				<FormattedMessage {...messages.exchangeRate} />
			</span>,
			icon: <DollarOutlined className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.ADMINISTRATOR]
		},
		{
			path: "generalReport",
			text: <span className="nav-text">
				<span className="nav-text"><FormattedMessage {...messages.reports} /></span>
				<span className="nav-text"> | </span>
				<span className="nav-text"><FormattedMessage {...messages.generalReport} /></span>
			</span>,
			icon: <BarsOutlined className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.ADMINISTRATOR]
		},
		{
			path: "taxesReport",
			text: <span className="nav-text">
				<span className="nav-text"><FormattedMessage {...messages.reports} /></span>
				<span className="nav-text"> | </span>
				<span className="nav-text"><FormattedMessage {...messages.taxReport} /></span>
			</span>,
			icon: <BarsOutlined className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.ADMINISTRATOR]
		},
		{
			path: "techReport",
			text: <span className="nav-text">
				<span className="nav-text"><FormattedMessage {...messages.reports} /></span>
				<span className="nav-text"> | </span>
				<span className="nav-text"><FormattedMessage {...messages.technicians} /></span>
			</span>,
			icon: <BarsOutlined className="side-icon" />,
			roles: [ROLES.MASTER]
		},
		{
			path: "paymentsReport",
			text: <span className="nav-text">
				<span className="nav-text"><FormattedMessage {...messages.reports} /></span>
				<span className="nav-text"> | </span>
				<span className="nav-text"><FormattedMessage {...messages.paymentsReport} /></span>
			</span>,
			icon: <BarsOutlined className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.ADMINISTRATOR]
		},
		{
			path: "incomeExpensesReport",
			text: <span className="nav-text">
				<span className="nav-text"><FormattedMessage {...messages.reports} /></span>
				<span className="nav-text"> | </span>
				<span className="nav-text"><FormattedMessage {...messages.incomeExpensesReport} /></span>
			</span>,
			icon: <BarsOutlined className="side-icon" />,
			roles: [ROLES.MASTER]
		},
		{
			path: "equipmentReport",
			text: <span className="nav-text">
				<span className="nav-text"><FormattedMessage {...messages.reports} /></span>
				<span className="nav-text"> | </span>
				<span className="nav-text"><FormattedMessage {...messages.equipmentMovementsReport} /></span>
			</span>,
			icon: <BarsOutlined className="side-icon" />,
			roles: [ROLES.MASTER]
		},
		{
			path: "IncomeReport",
			text: <span className="nav-text">
				<span className="nav-text"><FormattedMessage {...messages.incomeExpenses} /></span>
				<span className="nav-text"> | </span>
				<span className="nav-text"><FormattedMessage {...messages.incomeExpenses} /></span>
			</span>,
			icon: <PieChartOutlined className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.INCOMESEXPENSES]
		},
		{
			path: "Categories",
			text: <span className="nav-text">
				<span className="nav-text"><FormattedMessage {...messages.incomeExpenses} /></span>
				<span className="nav-text"> | </span>
				<span className="nav-text"><FormattedMessage {...messages.categories} /></span>
			</span>,
			icon: <ProfileOutlined className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.INCOMESEXPENSES]
		},
		{
			path: "Banks",
			text: <span className="nav-text">
				<span className="nav-text"><FormattedMessage {...messages.incomeExpenses} /></span>
				<span className="nav-text"> | </span>
				<span className="nav-text"><FormattedMessage {...messages.accounts} /></span>
			</span>,
			icon: <BankOutlined className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.INCOMESEXPENSES]
		},
		{
			path: "departments",
			text: <span className="nav-text">
				<FormattedMessage {...messages.departments} />
			</span>,
			icon: <AppstoreOutlined className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.EMPLOYEES]
		},
		{
			path: "employees",
			text: <span className="nav-text">
				<FormattedMessage {...messages.employees} />
			</span>,
			icon: <TeamOutlined className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.EMPLOYEES]
		},
		{
			path: "taxRetention",
			text: <span className="nav-text">
				<FormattedMessage {...messages.taxRetention} />
			</span>,
			icon: <ScheduleOutlined className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.ADMINISTRATOR]
		},
		{
			path: "fiscalData",
			text: <span className="nav-text">
				<FormattedMessage {...messages.fiscalData} />
			</span>,
			icon: <ReadFilled className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.ADMINISTRATOR]
		},
		{
			path: "payrolls",
			text: <span className="nav-text">
				<FormattedMessage {...messages.payrolls} />
			</span>,
			icon: <DollarOutlined className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.PAYROLLS]
		},
		{
			path: "commissions",
			text: <span className="nav-text">
				<FormattedMessage {...messages.commissions} />
			</span>,
			icon: <PieChartOutlined className="side-icon" />,
			roles: [ROLES.MASTER, ROLES.SALES]
		}
	];

	const createMenuItem = ({ text, icon, path }) => {
		let classes = ["navRow", path]
		let currentPath = location.pathname.split('/')[1];
		if (currentPath == path) classes.push("selected")
		return <Col key={path} span={24} className={classes.join(" ")} onClick={() => navigate(path)}>
			{icon} {text}
		</Col>
	}

	const menuItems = menu.map(menuOptions => {
		if (!menuOptions.roles || !menuOptions.roles.length) return createMenuItem(menuOptions);

		return ACL4Component(roles, createMenuItem(menuOptions), menuOptions.roles);
	})

	return (
		<div>
			<FiscalData handleCancel={closeFiscalDataModal} isVisible={showFiscalDataModal} />
			<TaxRetentionModal closeTaxModal={closeTaxRetentionModal} showTaxModal={showTaxRetentionModal} />
			<Row className="mobileMenu">
				{menuItems}
			</Row>
		</div>
	);
}

MobileSideMenu.propTypes = {
	collapse: PropTypes.func.isRequired
};

export default MobileSideMenu;