import es from './es';
import defaults from './default';

// Lenguaje por default en
export const en_messages = {
	...defaults,
};

export const es_messages = {
	...es,
};