import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Badge, notification, message, Button, Row, Popconfirm, Dropdown, Typography } from 'antd';
import { connect } from 'react-redux';
import AddInvoiceButton from './AddInvoiceButton';
import CommonTable from '../../components/CommonTable';
import InvoicingFilter from './InvoicesFilter';
import Mayre from 'mayre';
import { getInvoicingMessages, getFiscalDataMessages, getModalMessages, getactionMessages, getErrorMessages, getLanguageIntl, getPopConfirm } from '../../constants/messages';
import { roundN } from '../../utilities/util';
import { GetInvoices, GetInvoice, SearchInvoices, CheckOverdueInvoices, IsrRetentionLetterReceived, IsvRetentionLetterReceived, GetInvoicesBySeller, SearchInvoicesbySeller } from './actions';
import { GetFiscalData, SetAsRequested } from '../FiscalData/actions';
import { GetClientOfSeller } from '../Clients/actions';
import AnnulButton from './AnnulButton';
import GeneralAddPaymentButton from '../Payments/GeneralAddPaymentButton';
import EllipsisTooltip from '../../components/EllipsisTooltip';
import { STATUSES } from './constants';
import DepositorPaymentButton from '../Payments/depositorPaymentButton';
import GenerateGeneralReportModal from './GenerateGeneralReportModal';
import ClientInvoiceStatementModal from './ClientInvoiceStatementModal';
import PopoverProductsTable from './PopoverProductsTable';
import InvoiceMonthReportModal from './InvoiceMonthReportModal';
import GenerateInvoicesModal from './GenerateInvoicesModal';
import BatchSendInvoicesModal from './BatchEmailSend/Modal';
import { ROLES } from '../../constants/global';

let moment = require('moment');

let invoicingMessages = getInvoicingMessages(),
	fiscalDataMessages = getFiscalDataMessages(),
	errorMessages = getErrorMessages(),
	actionMessages = getactionMessages(),
	modalMessages = getModalMessages(),
	languageMessages = getLanguageIntl(),
	popconfimMessages = getPopConfirm();

const messages = {
	...invoicingMessages,
	...fiscalDataMessages,
	...modalMessages,
	...actionMessages,
	...errorMessages,
	...languageMessages,
	...popconfimMessages
};

class Invoicing extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showPaymentModal: false,
			isLoading: true,
			openGeneralReportModal: false,
			openClientInvoiceStatementModal: false,
			showMonthReportModal: false,
			showGenerateInvoicesModal: false,
			showBatchSendInvoicesModal: false,
		};
	}

	componentDidMount() {
		const { roles, getClientOfSeller, intl, getFiscalData } = this.props;
		const isSalesPerson = roles.includes(ROLES.SALES) && !(roles.includes(ROLES.ADMINISTRATOR) || roles.includes(ROLES.MASTER)); 
		if (isSalesPerson){
			getClientOfSeller();
		}
		getFiscalData().then(() => {
			this.checkFiscalDataLimits();
		}).catch(() => {
			message.error(intl.formatMessage({ ...messages.GetFiscalDataError }));
		});
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	changeLoading = (value) => {
		this.setState({
			isLoading: value
		});
	};

	getInvoices = (page, pageSize) => {
		const { getInvoicesBySeller, sellerClientIds, roles, intl, getInvoices } = this.props;
		const isSalesPerson = roles.includes(ROLES.SALES) && !(roles.includes(ROLES.ADMINISTRATOR) || roles.includes(ROLES.MASTER));
		this.setState({ isLoading: true });
		const action = isSalesPerson ? getInvoicesBySeller(page, pageSize, sellerClientIds) : getInvoices(page, pageSize, sellerClientIds);
		action.then(() => {
			this.setState({	isLoading: false });
		}).catch(() => {
			message.error(intl.formatMessage({ ...messages.GetInvoicesError }));
			this.setState({	isLoading: false });
		});
	}

	searchInvoices = (page, pageSize, search) => {
		const { searchInvoices, searchInvoicesbySeller, roles, intl } = this.props;
		this.setState({ isLoading: true });
		const isSalesPerson = roles.includes(ROLES.SALES) && !(roles.includes(ROLES.ADMINISTRATOR) || roles.includes(ROLES.MASTER));
		const action = isSalesPerson ? searchInvoicesbySeller(page, pageSize, search) : searchInvoices(page, pageSize, search);
		action.then(() => {
			this.setState({ isLoading: false });
		}).catch(() => {
			message.error(intl.formatMessage({ ...messages.GetInvoicesError }));
			this.setState({ isLoading: false });
		});
	}

	checkFiscalDataLimits = () => {
		if (this.props.fiscalData.invoice !== undefined && !this.props.fiscalData.invoice.nextRequested) {
			if (this.props.fiscalData.invoice.finalRValue - this.props.fiscalData.invoice.currentRValue <= 750) {
				const key = `open${Date.now()}`;
				const btn = (
					<Button type="primary" size="small" onClick={() => { this.props.setAsRequested(this.props.fiscalData.invoice.id); notification.close(key); }}>
						Ok
					</Button>
				);
				notification.open({
					message: this.props.intl.formatMessage({ ...messages.Invoice }),
					description: this.props.intl.formatMessage({ ...messages.invoiceRangeNotification }),
					btn,
					key,
					duration: 0
				});
			}
			if (moment(this.props.fiscalData.invoice.expiryDate).diff(moment(), 'months') <= 2) {
				const key = `open${Date.now()}`;
				const btn = (
					<Button type="primary" size="small" onClick={() => { this.props.setAsRequested(this.props.fiscalData.invoice.id); notification.close(key); }}>
						Ok
					</Button>
				);
				notification.open({
					message: this.props.intl.formatMessage({ ...messages.Invoice }),
					description: this.props.intl.formatMessage({ ...messages.invoiceDateNotification }),
					btn,
					key,
					duration: 0
				});
			}
		}
		if (this.props.fiscalData.credit !== undefined && !this.props.fiscalData.credit.nextRequested) {
			if (this.props.fiscalData.credit.finalRValue - this.props.fiscalData.credit.currentRValue <= 750) {
				const key = `open${Date.now()}`;
				const btn = (
					<Button type="primary" size="small" onClick={() => { this.props.setAsRequested(this.props.fiscalData.credit.id); notification.close(key); }}>
						Ok
					</Button>
				);
				notification.open({
					message: this.props.intl.formatMessage({ ...messages.CreditNote }),
					description: this.props.intl.formatMessage({ ...messages.creditRangeNotification }),
					btn,
					key,
					duration: 0
				});
			}
			if (moment(this.props.fiscalData.credit.expiryDate).diff(moment(), 'months') <= 2) {
				const key = `open${Date.now()}`;
				const btn = (
					<Button type="primary" size="small" onClick={() => { this.props.setAsRequested(this.props.fiscalData.credit.id); notification.close(key); }}>
						Ok
					</Button>
				);
				notification.open({
					message: this.props.intl.formatMessage({ ...messages.CreditNote }),
					description: this.props.intl.formatMessage({ ...messages.creditDateNotification }),
					btn,
					key,
					duration: 0
				});
			}
		}
		if (this.props.fiscalData.debit !== undefined && !this.props.fiscalData.debit.nextRequested) {
			if (this.props.fiscalData.debit.finalRValue - this.props.fiscalData.debit.currentRValue <= 750) {
				const key = `open${Date.now()}`;
				const btn = (
					<Button type="primary" size="small" onClick={() => { this.props.setAsRequested(this.props.fiscalData.debit.id); notification.close(key); }}>
						Ok
					</Button>
				);
				notification.open({
					message: this.props.intl.formatMessage({ ...messages.CashNote }),
					description: this.props.intl.formatMessage({ ...messages.debitRangeNotification }),
					btn,
					key,
					duration: 0
				});
			}
			if (moment(this.props.fiscalData.debit.expiryDate).diff(moment(), 'months') <= 2) {
				const key = `open${Date.now()}`;
				const btn = (
					<Button type="primary" size="small" onClick={() => { this.props.setAsRequested(this.props.fiscalData.debit.id); notification.close(key); }}>
						Ok
					</Button>
				);
				notification.open({
					message: this.props.intl.formatMessage({ ...messages.CashNote }),
					description: this.props.intl.formatMessage({ ...messages.debitDateNotification }),
					btn,
					key,
					duration: 0
				});
			}
		}
	}

	redoInvoice = (id) => {
		this.props.getInvoice(id).then(() => {
			this.props.history.push(`/invoices/${id}/newFromAnnulled`);
		}).catch(() => {
			message.error(`${this.props.intl.formatMessage({ ...messages.GetInvoiceError })}`);
		});
	}

	isrRetentionLetterReceived = (id) => {
		this.setState({
			isLoading: true
		});
		this.props.isrRetentionLetterReceived(id).then(() => {
			this.props.getInvoices().then(() => {
				this.setState({
					isLoading: false
				});
			}).catch(() => {
				message.error(`${this.props.intl.formatMessage({ ...messages.GetInvoicesError })}`);
				this.setState({
					isLoading: false
				});
			});
		}).catch(() => {
			message.error(`${this.props.intl.formatMessage({ ...messages.ConfirmRetentionError })}`);
			this.setState({
				isLoading: false
			});
		});
	}

	isvRetentionLetterReceived = (id) => {
		this.setState({
			isLoading: true
		});
		this.props.isvRetentionLetterReceived(id).then(() => {
			this.props.getInvoices().then(() => {
				this.setState({
					isLoading: false
				});
			}).catch(() => {
				message.error(`${this.props.intl.formatMessage({ ...messages.GetInvoicesError })}`);
				this.setState({
					isLoading: false
				});
			});
		}).catch(() => {
			message.error(`${this.props.intl.formatMessage({ ...messages.ConfirmRetentionError })}`);
			this.setState({
				isLoading: false
			});
		});
	}

	render() {
		const { state, getIntl } = this;
		const { openGeneralReportModal, openClientInvoiceStatementModal, showMonthReportModal, showGenerateInvoicesModal, showBatchSendInvoicesModal } = state;
		const { searchText, currentPage, roles } = this.props;
		const isSalesPerson = roles.includes(ROLES.SALES) && !(roles.includes(ROLES.ADMINISTRATOR) || roles.includes(ROLES.MASTER));
		const onInvoiceNumberClick = (e, invoiceId) => {
			e.preventDefault();
			this.props.history.push(`/invoices/${invoiceId}`);
		}

		let columns = [
			{
				title: this.props.intl.formatMessage({ ...messages.InvoiceNumber }),
				dataIndex: 'documentNumber',
				key: 'documentNumber',
				width: 200,
				render: (documentNumber, invoice) => {
					const { id: invoiceId, invoiceProducts = [] } = invoice;
					return (
						<PopoverProductsTable products={invoiceProducts}>
							<a className="editable-add-btn" href={`/invoices/${invoiceId}`} onClick={(e) => onInvoiceNumberClick(e, invoiceId)}>
								{documentNumber}
							</a>
						</PopoverProductsTable>
					);
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Client }),
				dataIndex: 'clientName',
				key: 'clientName',
				width: 200,
				onCell: () => {
					return {
						style: {
							whiteSpace: 'nowrap',
							maxWidth: 200,
						}
					}
				},
				render: (clientName) => {
					return (
						<EllipsisTooltip title={clientName}>{clientName}</EllipsisTooltip>
					);
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Type }),
				dataIndex: 'type',
				key: 'type',
				width: 100,
				render: (type) => {
					if (type === 1) {
						return this.props.intl.formatMessage({ ...messages.Cash });
					} else if (type === 2) {
						return this.props.intl.formatMessage({ ...messages.Credit });
					}
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Status }),
				dataIndex: 'status',
				className: 'status',
				width: 25,
				render: (status) => {
					let color = 'default';
					let text = this.props.intl.formatMessage({ ...messages.Active });
					if (status === STATUSES.PAID) {
						color = 'success';
						text = this.props.intl.formatMessage({ ...messages.Paid });
					} else if (status === STATUSES.OVERDUE) {
						color = 'warning';
						text = this.props.intl.formatMessage({ ...messages.Overdue });
					} else if (status === STATUSES.ANNULLED) {
						color = 'error';
						text = this.props.intl.formatMessage({ ...messages.Annulled });
					}
					return (
						<Badge status={color} text={text} />
					);
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Total }),
				dataIndex: 'total',
				key: 'total',
				className: 'column-money',
				width: 100,
				render: (total, invoice) => {
					return (
						`L ${roundN(total * invoice.exchangeRate, 2)}`
					);
				}
			}, {
				title: 'Balance',
				dataIndex: 'balance',
				key: 'balance',
				className: 'column-money',
				width: 100,
				render: (balance) => {
					return (
						`L ${roundN(balance, 2)}`
					);
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Date }),
				dataIndex: 'createdAt',
				key: 'createdAt',
				width: 50,
				render: (date) => {
					return (moment(date).locale('es').format('L'));
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.CreatedBy }),
				dataIndex: 'createdBy',
				key: 'createdBy',
				width: 100,
				render: (createdBy, invoice) => {
					return (invoice.account.name);
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Actions }),
				dataIndex: 'id',
				width: 225,
				render: (id, invoice) => {
					return ( !isSalesPerson &&
						<span>
							<Row>
								<Mayre
									of={
										<a className="editable-add-btn" onClick={() => { this.redoInvoice(id); }}>
											<FormattedMessage {...messages.Redo} />
										</a>
									} or={
										<AnnulButton invoiceId={id} setState={partialState => this.setState(partialState)} state={this.state} />
									} when={invoice.status === 3}
								/>
								<Mayre
									of={
										<React.Fragment>
											<span> | </span>
											<i><FormattedMessage {...messages.ConfirmRetention} /></i>
										</React.Fragment>
									} when={invoice.isrRetention === true && !invoice.isrReceived || invoice.isvRetention === true && !invoice.isvReceived}
								/>
								<Mayre
									of={
										<Popconfirm title={this.props.intl.formatMessage({ ...messages.ConfirmTaxRetentionQ })} onConfirm={() => { this.isrRetentionLetterReceived(id); }} okText={this.props.intl.formatMessage({ ...messages.yes })} cancelText={this.props.intl.formatMessage({ ...messages.no })}>
											<a href="#">1%</a>
										</Popconfirm>
									} when={invoice.isrRetention === true && !invoice.isrReceived}
								/>
								<Mayre
									of={
										<span> | </span>
									} when={invoice.isrRetention === true && !invoice.isrReceived && invoice.isvRetention === true && !invoice.isvReceived}
								/>
								<Mayre
									of={
										<Popconfirm title={this.props.intl.formatMessage({ ...messages.ConfirmTaxRetentionQ })} onConfirm={() => { this.isvRetentionLetterReceived(id); }} okText={this.props.intl.formatMessage({ ...messages.yes })} cancelText={this.props.intl.formatMessage({ ...messages.no })}>
											<a href="#">15%</a>
										</Popconfirm>
									} when={invoice.isvRetention === true && !invoice.isvReceived}
								/>
							</Row>
						</span>
					);
				}
			}
		];

		const items = [];
		if (this.props.roles.includes(ROLES.MASTER) || this.props.roles.includes(ROLES.ADMINISTRATOR)){
			items.push(
				{
					key: '1',
					label: (
						<Typography.Link 
							onClick={() => this.setState({ showGenerateInvoicesModal: true })}
						>
							{getIntl("GenerateInvoices")}
						</Typography.Link>
					),
				},
				{
					key: '2',
					label: (
						<GeneralAddPaymentButton displayButton={false} key={1} /> 
					),
				},
				{
					key: '3',
					label: (
						<DepositorPaymentButton displayButton={false} key={2} />
					),
				},
				{
					key: '4',
					label: (
						<Typography.Link 
							onClick={() => this.setState({ openGeneralReportModal: true })}
						>
							{getIntl("generateGeneralReport")}
						</Typography.Link>
					),
				},
				{
					key: '5',
					label: (
						<Typography.Link 
							onClick={() => this.setState({ openClientInvoiceStatementModal: true })}
						>
							{getIntl("invoiceStatements")}
						</Typography.Link>
					),
				},
				{
					key: '6',
					label: (
						<Typography.Link 
							onClick={() => this.setState({ showMonthReportModal: true })}
						>
							{getIntl("generateMonthlyReport")}
						</Typography.Link>
					),
				},
				{
					key: '7',
					label: (
						<Typography.Link 
							onClick={() => this.setState({ showBatchSendInvoicesModal: true })}
						>
							{getIntl("sendInvoicesInBatch")}
						</Typography.Link>
					),
				},
			);
		}else if (this.props.roles.includes(ROLES.SALES)) {
			items.push(
				{
					key: '5',
					label: (
						<Typography.Link 
							onClick={() => this.setState({ openClientInvoiceStatementModal: true })}
						>
							{getIntl("invoiceStatements")}
						</Typography.Link>
					),
				},
			);
		}

		const extraComponents = (
			<Dropdown menu={{items}} key={1}>
				<Button style={{ marginLeft: 8 }}>
					{this.props.intl.formatMessage({ ...messages.Actions })}  <DownOutlined />
				</Button>
			</Dropdown>
		);

		return (
			<div className="account view">
				<GenerateGeneralReportModal showModal={openGeneralReportModal} handleCancel={() => this.setState({ openGeneralReportModal: false })} />
				<ClientInvoiceStatementModal showModal={openClientInvoiceStatementModal} handleCancel={() => this.setState({ openClientInvoiceStatementModal: false })} />
				<InvoiceMonthReportModal showModal={showMonthReportModal} handleCancel={() => this.setState({ showMonthReportModal: false })} />
				<GenerateInvoicesModal showModal={showGenerateInvoicesModal} handleCancel={() => this.setState({ showGenerateInvoicesModal: false })} />
				<BatchSendInvoicesModal showModal={showBatchSendInvoicesModal} handleCancel={() => this.setState({ showBatchSendInvoicesModal: false })} />
				<CommonTable
					columns={columns}
					dataSource={this.props.invoices}
					search={(search, page, pageSize) => this.searchInvoices(page, pageSize, search)}
					searchText= {searchText}
					currentPage= {currentPage}
					recordCount={this.props.count}
					preservePage={true}
					getRecords={(page, pageSize) => this.getInvoices(page, pageSize)}
					Add={<AddInvoiceButton />}
					loading={this.state.isLoading}
					filterComponent= {(<InvoicingFilter />)}
					getCheckboxProps={(() => ({ disabled: false }))}
					hideCheckboxes
					extraComponents={[extraComponents]}
				/>
			</div>
		);
	}
}

Invoicing.propTypes = {
	intl: PropTypes.object.isRequired,
	fiscalData: PropTypes.object.isRequired,
	roles: PropTypes.array.isRequired,
	invoices: PropTypes.array.isRequired,
	count: PropTypes.number.isRequired,
	getInvoices: PropTypes.func.isRequired,
	getInvoicesBySeller: PropTypes.func,
	searchInvoices: PropTypes.func.isRequired,
	checkOverdueInvoices: PropTypes.func.isRequired,
	getClientOfSeller: PropTypes.func,
	getFiscalData: PropTypes.func.isRequired,
	setAsRequested: PropTypes.func.isRequired,
	getInvoice: PropTypes.func.isRequired,
	isrRetentionLetterReceived: PropTypes.func.isRequired,
	isvRetentionLetterReceived: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	searchText: PropTypes.string,
	currentPage: PropTypes.number,
	sellerClientIds: PropTypes.array,
	searchInvoicesbySeller: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		invoices: state.invoicing.invoices,
		searchText: state.invoicing.search, 
		currentPage: state.invoicing.page,
		count: state.invoicing.count,
		fiscalData: state.fiscalData.fiscalData,
		roles: state.auth.roles,
		sellerClientIds: state.client.sellerClients,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getInvoices: (page, pageSize) => {
			return dispatch(GetInvoices(page, pageSize));
		},
		getInvoice: (id) => {
			return dispatch(GetInvoice(id));
		},
		searchInvoices: (page, pageSize, search) => {
			return dispatch(SearchInvoices(page, pageSize, search));
		},
		searchInvoicesbySeller: (page, pageSize, search) => {
			return dispatch(SearchInvoicesbySeller(page, pageSize, search));
		},
		checkOverdueInvoices: () => {
			return dispatch(CheckOverdueInvoices());
		},
		getFiscalData: () => {
			return dispatch(GetFiscalData());
		},
		setAsRequested: (id) => {
			return dispatch(SetAsRequested(id));
		},
		isrRetentionLetterReceived: (id) => {
			return dispatch(IsrRetentionLetterReceived(id, moment()));
		},
		isvRetentionLetterReceived: (id) => {
			return dispatch(IsvRetentionLetterReceived(id, moment()));
		},
		getClientOfSeller: () => {
			return dispatch(GetClientOfSeller());
		},
		getInvoicesBySeller: (page, pageSize, clientIds) => {
			return dispatch(GetInvoicesBySeller(page, pageSize, clientIds))
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(Form.create()(Invoicing))));
