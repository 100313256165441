import React from 'react';
import PropTypes from 'prop-types';
import { CloseOutlined, FolderAddOutlined, UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Spin, Divider, Button, Typography } from 'antd';
import { injectIntl } from 'react-intl';
import { getPayrollMessages, getModalMessages, getLanguageIntl } from '../../../constants/messages';
import { formatPrice, columnTemplate } from '../constants';
import { applyObject, existsInArray } from '../../../utilities/util';
import EditDeductionModal from './EditDeductionModal';
import PayrollTable from '../CommonRenders/PayrollTable';
const moment = require('moment');
const cloneDeepWith = require('lodash.clonedeepwith');
import EditableBonusNameField from './AddBonus/EditableBonusNameField';
import EditableBonusAmountField from './AddBonus/EditableBonusAmountField';
import { GeneratePayroll as GeneratePayrollFunction } from '../actions';

const { Title } = Typography;

let payrollMessages = getPayrollMessages(),
	modalMessages = getModalMessages(),
	intlMessages = getLanguageIntl();

const messages = {
	...payrollMessages,
	...modalMessages,
	...intlMessages
};

class GeneratePayroll extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			showDeductionEditModal: false,
			deductionAmount: 0,
			handleDeductionSave: () => { },
			bonuses: [],
			newBonusUuid: 1,
		};
	}

	componentDidMount() {
		this.getInformationPayroll();
	}

	getInformationPayroll = () => {
		const { props, getIntl } = this;
		const { getGeneratePayrollInfo, getMedicalDeductions} = props;
		const { date } = props.match.params;
		this.setState({isLoading: true});
		getMedicalDeductions()
			.then(() => getGeneratePayrollInfo(date))
			.catch(() => message.error(getIntl("getGeneratePayrollInfoError")))
			.finally(() => this.setState({isLoading: false}));
		
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });

	getNewUniqueBonusIdentifier = () => {
		const uuid = this.state.newBonusUuid;
		this.setState({ newBonusUuid: uuid + 1 });
		return uuid;
	}

	parseInfo = () => {
		const { props } = this;
		const { generateInfo } = props;
		const parsedInfo = [];
		generateInfo.forEach(emp => {
			const duplicates = {};
			const employee = cloneDeepWith(emp);
			const currentDeductions = [...employee.currentDeductions];
			for (let index = 0; index < currentDeductions.length; index++) {
				const deduction = currentDeductions[index];
				const deductionId = deduction.deductionId.toString();
				if (duplicates[deductionId] === undefined) duplicates[deductionId] = 1;
				else {
					const count = duplicates[deductionId] + 1;
					const name = deduction.deduction.name;
					deduction.deduction.name = `${name} (${count})`;
					currentDeductions[index] = deduction;
					duplicates[deductionId] = count;
				}
			}
			employee.currentDeductions = currentDeductions;
			parsedInfo.push(employee);
		});
		return parsedInfo;
	}

	calculateDeductions = (deductions) => {
		return deductions.reduce((diff, ded) => {
			const { deduction, amount, deductionId, employeeId, id } = ded;
			const { name } = deduction;
			return {
				...diff,
				[name]: { amount, deductionId, employeeId, id }
			}
		}, {});
	}

	calculateEmployeeBonuses = (employeeId) => {
		const { state, props } = this;
		const { bonuses } = state;
		const { form } = props;
		const { getFieldsValue } = form;
		const deductions = {};
		const values = getFieldsValue();
		bonuses.forEach(bonusField => {
			const bonus = values[bonusField];
			if (bonus !== undefined) {
				const employeeBonusAmount = bonus[`employee${employeeId}`] || 0;
				deductions[bonusField] = { amount: employeeBonusAmount, employeeId, id: bonusField };
			}
		});
		return deductions;
	}

	getData = () => {
		const { calculateDeductions, parseInfo, calculateEmployeeBonuses } = this;
		const parsedInfo = parseInfo();
		return parsedInfo.map(employee => {
			const { salary, currentDeductions, ihss, isr, id: employeeId } = employee;
			const deductions = calculateDeductions(currentDeductions);
			const bonuses = calculateEmployeeBonuses(employeeId);
			const totalDeductions = Object.keys(deductions).reduce((accumulated, key) => accumulated + deductions[key].amount, 0);
			const totalBonuses = Object.keys(bonuses).reduce((accumulated, key) => accumulated + bonuses[key].amount, 0);
			const accreditedSalary = salary - (isr || 0) - (ihss || 0) - totalDeductions + totalBonuses;
			return {
				...employee,
				...deductions,
				...bonuses,
				accreditedSalary
			};
		});
	}

	editDeduction = (id, newAmount) => {
		const { setLoading, getIntl, props, closeModals } = this;
		const { editEmployeeDeduction } = props;
		setLoading(true);
		editEmployeeDeduction(id, newAmount)
			.then(() => {
				setLoading(false);
				closeModals();
			})
			.catch(() => {
				setLoading(false);
				message.error(getIntl("deductionUpdateError"));
			});
	}

	openEditDeductionModal = (amount, id) => {
		const { editDeduction } = this;
		this.setState({
			showDeductionEditModal: true,
			deductionAmount: amount,
			handleDeductionSave: (newAmount) => editDeduction(id, newAmount)
		});
	}

	closeModals = () => {
		this.setState({
			showDeductionEditModal: false,
			deductionAmount: 0,
			handleDeductionSave: () => { }
		});
	}

	editableDeduction = (deduction) => {
		const { openEditDeductionModal } = this;
		const { amount, id } = deduction;
		return (
			<span>
				{formatPrice(amount)}
				<Divider type="vertical" />
				<a onClick={() => openEditDeductionModal(amount, id)}><span className="icon-pencil" /></a>
			</span>
		);
	}

	getDeductionColumns = (data) => {
		const { editableDeduction } = this;
		const deductionNames = [];
		data.forEach(employee => {
			const currentDeductions = employee.currentDeductions || [];
			currentDeductions.forEach(deduction => {
				const { name } = deduction.deduction;
				if (!existsInArray(name, deductionNames)) deductionNames.push(name);
			});
		});
		return deductionNames.map((name) => columnTemplate(name, undefined, (record) => record[name] ? editableDeduction(record[name]) : ""));
	}

	getBonusColumns = () => {
		const { state, props } = this;
		const { bonuses } = state;
		const { form } = props;

		return bonuses.map(bonusField => {
			const bonusFieldName = `${bonusField}[name]`;
			const bonusFieldAmount = employeeId => `${bonusField}[employee${employeeId}]`;
			return columnTemplate(
				<EditableBonusNameField form={form} fieldName={bonusFieldName} />,
				undefined,
				({ id: employeeId }) => <EditableBonusAmountField form={form} fieldName={bonusFieldAmount(employeeId)} />
			);
		});
	}

	tableRender = () => {
		const { props, getData, getDeductionColumns, getBonusColumns } = this;
		const { date } = props.match.params;
		const data = getData();
		const deductionColumns = getDeductionColumns(data);
		const bonusColumns = getBonusColumns();
		return (
			<PayrollTable
				records={data}
				deductionColumns={deductionColumns}
				bonusColumns={bonusColumns}
				date={moment(date).toISOString()}
			/>
		);
	}

	editDeductionModalRender = () => {
		const { state, closeModals } = this;
		const { isLoading, showDeductionEditModal, deductionAmount, handleDeductionSave } = state;
		return (
			<EditDeductionModal
				isLoading={isLoading}
				showModal={showDeductionEditModal}
				amount={deductionAmount}
				handleSave={handleDeductionSave}
				handleCancel={closeModals}
			/>
		);
	}

	cancel = () => {
		this.props.history.push("/payrolls");
	}

	save = () => {
		const { state, props, getIntl, setLoading } = this;
		const { bonuses } = state;
		const { form, medicalDeductions } = props;
		const { validateFieldsAndScroll } = form;
		const { date } = props.match.params;
		validateFieldsAndScroll({ force: true }, (err, values) => {
			if (!err) {
				setLoading(true);
				const parsedBonuses = [];
				bonuses.forEach(bonusField => {
					const bonus = values[bonusField];
					if (bonus !== undefined) {
						const { name: bonusName } = bonus;
						const apply = (key, value) => {
							const fieldSubstring = key.substring(0, 8);
							if (fieldSubstring == "employee" && value) parsedBonuses.push({ employeeId: parseInt(key.substr(8)), amount: value, name: bonusName });
						}
						applyObject(bonus, apply);
					}
				});
				GeneratePayrollFunction(date, parsedBonuses, medicalDeductions)
					.then(() => {
						message.success(getIntl("generatePayrollSuccess"));
						this.props.history.push("/payrolls");
					})
					.catch(() => {
						setLoading(false);
						message.error(getIntl("generatePayrollError"));
					});
			}
		});
	}

	addBonus = () => {
		const { state, getNewUniqueBonusIdentifier } = this;
		const { bonuses } = state;
		const dataCopy = Array.from(bonuses);
		dataCopy.push(`bonus${getNewUniqueBonusIdentifier()}`);
		this.setState({ bonuses: dataCopy });
	}

	renderButtons = () => {
		const { getIntl, state, cancel, save, addBonus } = this;
		const { isLoading } = state;
		const margin = "0.5em";
		const buttons = [
			<Button
				style={{ marginTop: margin, marginRight: margin }}
				onClick={cancel}
				key="cancel"
				disabled={isLoading}
				icon={<CloseOutlined />}
			>
				{getIntl("cancel")}
			</Button>,
			<Button
				style={{ marginTop: margin, marginRight: margin }}
				onClick={addBonus}
				key="add-bonus"
				disabled={isLoading}
				icon={<FolderAddOutlined />}
			>
				{getIntl("addBonus")}
			</Button>,
			<Button
				style={{ marginTop: margin }}
				type="primary"
				onClick={save}
				key="save"
				loading={isLoading}
				icon={<UploadOutlined />}
			>
				{getIntl("generatePayroll")}
			</Button>
		];
		return (
			<div style={{ textAlign: 'right' }}>
				{buttons}
			</div>
		);
	}

	dateRender = (date) => {
		if (!date) return;
		const m = moment(date).utc();
		return new Date(m.year(), m.month(), m.date()).toLocaleDateString(this.getIntl("intl"));
	}

	renderDates = () => {
		const { getIntl, props, dateRender } = this;
		const { date } = props.match.params;
		return <Title level={4}>{getIntl("payrollDate")}: {dateRender(date)}</Title>;
	}

	fullRender = () => {
		const { state, tableRender, editDeductionModalRender, renderButtons, renderDates } = this;
		const { isLoading } = state;
		return (
			<Spin spinning={isLoading}>
				<div className="view">
					{editDeductionModalRender()}
					{renderDates()}
					{tableRender()}
					{renderButtons()}
				</div>
			</Spin>
		);
	}

	render() {
		return this.fullRender();
	}
}

GeneratePayroll.defaultProps = {
	generateInfo: []
};

GeneratePayroll.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	match: PropTypes.shape({
		params: PropTypes.object.isRequired
	}).isRequired,
	getGeneratePayrollInfo: PropTypes.func.isRequired,
	getMedicalDeductions: PropTypes.func.isRequired,
	generateInfo: PropTypes.array.isRequired,
	medicalDeductions: PropTypes.array.isRequired,
	editEmployeeDeduction: PropTypes.func.isRequired
};

export default injectIntl(Form.create()(GeneratePayroll));