import React, {useState} from 'react';
import { DownOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Row, Col, Card, Dropdown, message, Button, Badge, Tooltip, Space} from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { getEmployeeMessages, getactionMessages, getFields, getPopConfirm, getLanguageIntl } from '../../constants/messages';
import CommonTable from '../../components/CommonTable';
import MobileCommonTable from '../../components/MobileCommonTable';
import { isMobile } from '../../utilities/util';
import ViewModal from './View/ViewModal';
import moment from 'moment';
import {
	GetEmployeeInfo,
	GetEmployees,
	GetEmployeeCount,
	ChangeActive
} from './actions';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

let employeeMessages = getEmployeeMessages(),
actionMessages = getactionMessages(),
fieldMessages = getFields(),
popConfirmMessage = getPopConfirm(),
intlMessages = getLanguageIntl();

const messages = {
	...employeeMessages,
    ...actionMessages,
    ...fieldMessages,
    ...popConfirmMessage,
    ...intlMessages
};


const Employees = () => {
	const intl = useIntl()
	
	const [isLoading, setIsLoading] = useState(false);
	const [currentRecord, setCurrentRecord] = useState({});
	const [showViewModal, setShowViewModal] = useState(false);
	const records = useSelector(state => state.employee.employees);
	const recordCount = useSelector(state => state.employee.count);
	const search = useSelector(state => state.employee.search);
	const history = useHistory();
	const dispatch = useDispatch();

	const getIntl = (str) => {
		return intl.formatMessage({...messages[str]});
	}

	const changeActive = async (id, active) => {

		try {
			setIsLoading(true);
			await dispatch(ChangeActive(id, !active));
			message.success(active ? getIntl("deactivateEmployeeSuccess") : getIntl("activateEmployeeSuccess"));
		} catch (error) {
			message.error(active ? getIntl("deactivateEmployeeError") : getIntl("activateEmployeeError"));
		}finally{
			setIsLoading(false);
		}
	}

	const doPaging = async (search, page = 1, pageSize = 10) => {

		try {
			setIsLoading(true);
			await dispatch(GetEmployeeCount(search));
			await dispatch(GetEmployees(search, page, pageSize));
		} catch (error) {
			message.error(getIntl("getEmployeesError"));
		}finally{
			setIsLoading(false);
		}
	};

	const viewRecord = async (id) => {
		try {
			setIsLoading(true);
			const response = await GetEmployeeInfo(id);
			setCurrentRecord(response);
			setShowViewModal(true);

		} catch (error) {
			message.error(getIntl("getEmployeesError"));
		}finally{
			setIsLoading(false);
		}
	};

	const closeViewModal = () => {
		setCurrentRecord({});
		setShowViewModal(false);	
    }
	const tableActions = (record) => {

		const {id, active, licenseExpirationDate} = record;
		return (
			<span>
				<a onClick={() => viewRecord(id)}>
					{moment() > moment(licenseExpirationDate) && licenseExpirationDate !== null ? 
						
						<Tooltip title={getIntl("expiredLicense")}>
							<Space size={4}>
								<ExclamationCircleOutlined/> 
								<FormattedMessage {...messages.view}/> 	
							</Space>
									
						</Tooltip> : 
						<FormattedMessage {...messages.view}/> 
					}
					
            	</a>
				<span> | </span>
				<a onClick={() => history.push(`/employees/${id}/edit`)}>
					<span className="icon-pencil" />
					<FormattedMessage {...messages.edit}/>
				</a>
				<span> | </span>
				<a onClick={() => changeActive(id, active)}>
					{active ? <FormattedMessage {...messages.deactivate} /> : <FormattedMessage {...messages.activate} />}
				</a>

			</span>
		);
	};

	
	const renderAddButton = () => {
        return (
            <Button className="editable-add-btn" type="primary" onClick={() =>history.push("/employees/new")}>
				<PlusOutlined /> {getIntl("add")}
			</Button>
        );
    }

	const badgeCreator = (active) => {
		return <Badge status={active ? "success" : "default"} text={active ? getIntl("active") :getIntl("inactive")} />
	}

	const getColumns = () => {

        return [
			{
				title: getIntl("name"),
				dataIndex: 'name',
				key: 'name'
			}, {
				title: getIntl("hireDate"),
				dataIndex: 'hireDate',
				render: (hireDate) => new Date(hireDate).toLocaleDateString(getIntl("intl"),{weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})
			}, {
				title: getIntl("status"),
				dataIndex: 'active',
				render: (active) => badgeCreator(active)
			}, {
				title: getIntl("actions"),
				render: (id, record) => tableActions(record),
			}
		];
    }

	const getCard = (record) => {
        let title = (
			<Row align="middle">
				<Col span={14}>
					{badgeCreator(record.active)}
					<span> | </span>
					{record.name}
				</Col>
			</Row>
        );
        const action = (record) => (
			<Dropdown menu={{ items: menu(record) }} trigger={['click']}>
				<a className="ant-dropdown-link" href="#">
					<FormattedMessage {...messages.actions} />
					<DownOutlined />
				</a>
			</Dropdown>
		);
		const menu = (record) => {
			const {id, active} = record;

			const items = [
				{
					key: '1',
					label: 
						<a onClick={() => viewRecord(id)}>
							<FormattedMessage {...messages.view}/>
						</a>
				},
				{
					key: '2',
					label: 
						<a onClick={() => history.push(`/employees/${id}/edit`)}>
							<span className="icon-pencil" />
							<FormattedMessage {...messages.edit}/>
						</a>
				},
				{
					key: '3',
					label: 	
						<a onClick={() => changeActive(id, active)}>
							{active ? <FormattedMessage {...messages.deactivate} /> : <FormattedMessage {...messages.activate} />}
						</a>
				},
			]
			return items;
		};

        return (
            <Row key={record.id} type="flex" justify="space-around" align="middle">
                <Card loading={isLoading} title={title} extra={action(record)} style={{ width: "100%" }}>
					<Row  type="flex" justify="center">
						<Col span={8}><strong><FormattedMessage {...messages.hireDate} />:</strong></Col>
						<Col span={12}>{new Date(record.hireDate).toLocaleDateString(getIntl("intl"),{weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}</Col>
					</Row>
                </Card>
            </Row>
            );
    }

  return (
	<div className="employees view">
				<ViewModal showModal={showViewModal} handleCancel={closeViewModal} employee={currentRecord}/>
				
				{(!isMobile() ? 
					<CommonTable
						columns = {getColumns()}
						dataSource = {records}
						search={(search, page, pageSize) => doPaging(search, page, pageSize)}
						searchText= {search === "" ? undefined : search}
						recordCount={recordCount}
						getRecords={(page, pageSize) => doPaging({}, page, pageSize)}
						loading={isLoading}
						getCheckboxProps={() => ({})}
						Add={renderAddButton()}
						hideCheckboxes
					/>:
					<MobileCommonTable 
						dataSource = {records}
						search={(search, page, pageSize) => doPaging(search, page, pageSize)}
						recordCount={recordCount}
						searchText= {search === "" ? undefined : search}
						loading={isLoading}
						card={getCard}
						getRecords={(page, pageSize) => doPaging({}, page, pageSize)}
						Add={renderAddButton()}
					/>
				)}
				
			</div>	
  )
}

export default Employees
