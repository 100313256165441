import axios from 'axios';
import {
	FETCH_EMPLOYEES,
    FETCH_EMPLOYEE_COUNT,
    SET_SEARCH_EMPLOYEE
} from './actionTypes';
import {substituteObjectInArrayByValue, getObjectInArray, s3UploadLink} from '../../utilities/util';

export const GetEmployees = (searchObject = {}, page = 1, pageSize = 10) => async (dispatch) => {
    let name = searchObject.searchText !== undefined ? searchObject.searchText : "";
    let where = name.length > 0 ? {name: {like: "%" + name + "%"}} : {};
    let filter = {
		limit: pageSize,
		skip: (page - 1) * pageSize,
        where: where,
        order: ["active DESC", "name ASC"]
    };
    const response = await axios.get(`/Employees?filter=${encodeURIComponent(JSON.stringify(filter))}`)
    dispatch({ type: FETCH_EMPLOYEES, employees: response.data });
    dispatch({type: SET_SEARCH_EMPLOYEE, search: searchObject.searchText })
};

export const GetEmployeesActive = async () => {
	const filter = {
		where: { active: true },
		fields: ['id', 'name', 'active', 'workRoleId'],
	};
	const response = await axios.get(`/Employees?filter=${encodeURIComponent(JSON.stringify(filter))}`)
	return response.data;
}

export const GetEmployeeCount = (searchObject = {}) => async (dispatch) => {
    let name = searchObject.searchText !== undefined ? searchObject.searchText : "";
    let where = name.length > 0 ? {name: {like: "%" + name + "%"}} : {};
    
    const response = await axios.get(`/Employees/count?where=${encodeURIComponent(JSON.stringify(where))}`)
    dispatch({ type: FETCH_EMPLOYEE_COUNT, count: response.data.count });

};

export const GetEmployeeInfo = async (id) =>  {

    const response = await axios.get(`/Employees/${id}/getInfo`);
    let employee = response.data;
    employee.phone = employee.phones[0].number;
    employee.salary = employee.salaries[0].salary;
    let idUrl = employee.idUrl;
    let licenseUrl = employee.licenseUrl;
    if (idUrl) employee.idPhoto = {uid: 'identification', url: idUrl, status: 'done', old: true};
    if (licenseUrl) employee.licensePhoto = {uid: 'drivers_license', url: licenseUrl, status: 'done', old: true};
    return employee;
};

export const AddEmployee = async (employee) => {
    const { idPhoto, licensePhoto } = employee;

    const response = await axios({
        url: `/Employees/add`,
        method: 'POST',
		params: {
			newEmployee: employee
		}
    })
    await PhotoPromise(response.data.id, idPhoto, licensePhoto);
    return response.data;
};

export const EditEmployee = async (employee, id) => {
    const { idPhoto, licensePhoto } = employee;
    const response = await axios({
        url: `/Employees/${id}/edit`,
        method: 'PATCH',
        params: {
            updatedEmployee: employee
        }
    })
	await PhotoPromise(id, idPhoto, licensePhoto);
    return response.data;
};

export const ChangeActive = (id, active) => async (dispatch, getState) =>  {
    let state = getState();
	let employees = state.employee.employees;
    const response = await axios.patch(`/Employees/${id}`, {active});
    let employee = getObjectInArray("id", id, employees);
    employee = {...employee};
    employee.active = active;
    dispatch({ type: FETCH_EMPLOYEES, employees: substituteObjectInArrayByValue("id", id, employees, employee) });
	return response.data
};

export const UploadVarious = (fileArray) => {
    const formData = new FormData();
    fileArray.forEach(element => {
        formData.append(element[0], element[1]);
    });
    let url = s3UploadLink("employees");
    return axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
};

export const PhotoPromise = async (id, idPhoto, licensePhoto) => {
    let promises = [];
    
    const idPhotoU = Array.isArray(idPhoto) ? undefined : idPhoto;
    if (idPhotoU !== undefined ) {

        if(idPhoto.old === undefined && idPhoto){
            try {
                await AddIdPhoto(id, idPhoto);
                promises.push(Promise.resolve());
              } catch (error) {
                promises.push(Promise.reject(error));
            }

        }
      
    }
    const licensePhotoU = Array.isArray(licensePhoto) ? undefined : licensePhoto;
    if ( licensePhotoU !==undefined ) {
        if(licensePhoto.old === undefined && licensePhoto){
            try {
                await AddLicensePhoto(id, licensePhoto);
                promises.push(Promise.resolve());
            } catch (error) {
                promises.push(Promise.reject(error));
            }
        }
    }
    return Promise.all(promises);
  };

export const AddPhoto = (destination, name, photo) => {
    const formData = new FormData();
    formData.append("file", photo);
    formData.append("name", name);
    formData.append("directory", `employees/${destination}`);

    return axios.post(`/S3Buckets/profileUpload`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json",
        },
    })
};

export const AddIdPhoto = (id, photo) => AddPhoto("identification", `employees_id_${id}`, photo);

export const AddLicensePhoto = (id, photo) => AddPhoto("license", `employees_license_${id}`, photo);