import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, message } from 'antd';
import { getFields, getErrorMessages, getJobMessages } from '../../../../constants/messages';
import { GetIcons } from '../../actions';
import IconRender from '../../../Devices/Finder/Renders/IconRender';

const FormItem = Form.Item;
const { Option } = Select;

let fieldMessages = getFields(),
    jobMessages = getJobMessages(),
    errorMessages = getErrorMessages();

const messages = {
	...fieldMessages,
    ...jobMessages,
	...errorMessages
};

class Icon extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            isLoading: false
        };
    }

	componentDidMount () {
        const { props, getIntl, setLoading } = this;
        const { getIcons } = props;
        setLoading(true);
        getIcons()
        .then(() => setLoading(false))
        .catch(() => {
            setLoading(false);
            message.error(getIntl("iconsLoadError"));
        })
    }

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});
    setLoading = (isLoading) => this.setState({ isLoading });

    onChange = (icon) => {
        const { props } = this;
        const { onChange } = props;
        if ('onChange' in props) onChange(icon);
    }

    buildOptions = () => {
        const { icons } = this.props;
        return icons.map(icon => {
            const { id } = icon;
            return <Option key={id} value={id}><IconRender icon={icon}/></Option>;
        });
    }
    
	render() {
        const { state, props, getIntl, buildOptions, onChange } = this;
        const { isLoading } = state;
        const { form, icon, disabled } = props;
        const { getFieldDecorator } = form;
        const options = buildOptions();
        const label = 'label' in props ? props.label : getIntl("icon");
        const placeholder = 'placeholder' in props ? props.placeholder : label;
        return(
            <FormItem label={label} /*className="deviceIcon job-full-component"*/>
            {
                getFieldDecorator(`iconId`, 
                    {
                        initialValue: icon,
                        rules: [{
                            required: true,
                            message: getIntl("iconError"), 
                        }],
                        onChange: onChange
                    }
                )(
                    <Select
                        className="job-full-component"
                        disabled={disabled}
                        placeholder={placeholder}
                        filterOption={false}
                        showSearch={false}
                        dropdownMatchSelectWidth={false}
                        loading={isLoading}
                    >
                        {options}
                    </Select>
                )
            }
            </FormItem>
        );
    }
}

Icon.defaultProps = {
    icons: [],
    disabled: false
};

Icon.propTypes = {
    intl: PropTypes.object.isRequired,
    getIcons: PropTypes.func.isRequired,
	icons: PropTypes.array.isRequired,
    
    form: PropTypes.object.isRequired,
    icon: PropTypes.number,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.string,
    placeholder: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
        icons: state.job.icons
	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		getIcons: () => dispatch(GetIcons())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Icon));