import React,{ useRef, useState } from 'react';
import PropTypes from 'prop-types';
import '@ant-design/compatible/assets/index.css';
import { Modal, message } from 'antd';
import { useIntl } from 'react-intl';
import { getDepartmentMessages, getactionMessages, getModalMessages, getErrorMessages } from '../../../constants/messages';

let departmentMessages = getDepartmentMessages(),
  modalMessages = getModalMessages(),
  actionMessages = getactionMessages(),
  errorMessages = getErrorMessages();

const messages = {
  ...departmentMessages,
  ...modalMessages,
  ...actionMessages,
  ...errorMessages,
};

const CommonModal = ({showModal, handleCancel, record, renderFormFunc, save, title}) => {
    const intl = useIntl();
    const form = useRef();
    const [isLoading, setIsloading] = useState(false);

    const handleOk = async () => {
      try {
        setIsloading(true);
        const values = await form.current.validateFields();
        await save(values);
      } catch (error) {
        message.error(intl.formatMessage({ ...messages.commonError }));
      } finally {
        setIsloading(false);
      }
    };

  return (
    <Modal
      open={showModal}
      title={title}
      destroyOnClose
      onCancel={handleCancel}
      onOk={handleOk}
      okText={intl.formatMessage({ ...messages.save })}
      cancelText={intl.formatMessage({ ...messages.cancel })}
      confirmLoading={isLoading}
    >
      {renderFormFunc(form, record)}
    </Modal>
  );
}

CommonModal.defaultProps = {
  record: {},
};

CommonModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
  renderFormFunc: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default CommonModal
