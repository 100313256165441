import React, { useEffect, useState, useMemo }from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { getactionMessages, getFields, getLotsMessages, getModelsMessages, getPopConfirm } from '../../constants/messages';
import { Button, message, Popconfirm, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DeleteLot, GetLots, setSearch } from './actions';
import CommonTable from '../../components/CommonTable';
import UploadFileModal from './UploadFileModal';
import AddButton from './AddButton';
import _ from 'lodash';
import { GetEmployeesActive } from '../Employees/actions';
import { GetUnitsAvailable } from '../Units/actions';

let LotsMessages = getLotsMessages(),
	FieldsMessages = getFields(),
	ActionMessages = getactionMessages(),
	PopMessages = getPopConfirm(),
    ModelMessages = getModelsMessages();
 
const messages = {
	...LotsMessages,
	...FieldsMessages,
	...ActionMessages,
	...PopMessages,
	...ModelMessages,
};

const Lots = () => {
    const intl = useIntl();
	const dispatch = useDispatch();
    const [lots, setLots] = useState([]);
	const [loading, setLoading] = useState(true);
	const [filter, setFilter] = useState('');
	const [showUploadModal, setShowUploadModal] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [units, setUnits] = useState([]);

	const updateLots = (lots) => setLots(lots); 
	const models = useSelector((state) => state.model.models);
	const search = useSelector((state) => state.lotUnit.searchLots);
	useEffect(() => {
	const fetchData = async () => {
		try {
		if (search === '') {
			const response = await GetLots();
			setLots(response);
			const employees = await GetEmployeesActive();
			setEmployees(employees);
			const units = await GetUnitsAvailable();
			setUnits(units);
			setLoading(false);
		}
		setLoading(false);
		} catch (error) {
		message.error(intl.formatMessage({ ...messages.getLotsError }));
		}
	};
	
	fetchData();	
	}, [search]);


	const deleteLot =  (lotSelected, lotNumber) => {
		setLoading(true);
		dispatch(DeleteLot(lotSelected, lotNumber)).then(() => {
			setLots((l) => l.filter((lot) => !lotSelected.includes(lot.id)));
			setLoading(false);
		});
		return [];
	};

	const tableActions = (record) => {
		const {id, lotNumber} = record;
		return (
			<span>
				<Popconfirm
					title={intl.formatMessage({...messages.deleteConfirm})}
					onConfirm={() => {
						deleteLot([id], [lotNumber]);
						message.success(intl.formatMessage({...messages.deleteLotSuccess}));
					}}
					okText={intl.formatMessage({...messages.yes})}
					cancelText={intl.formatMessage({...messages.no})}
				>
					<Typography.Link>
						<span className="icon-bin" />
						<FormattedMessage {...messages.delete} />
					</Typography.Link>
				</Popconfirm>
			</span>
		);
	};

    const filteredLots = useMemo(() => {
        const currentLots = lots.filter((lot) => {
                if (filter.searchText === '') {
                    return true;
                }
				if (lot.lotNumber.toLowerCase().includes(filter.searchText)) {
					return true;
                }
				if (lot.model.toLowerCase().includes(filter.searchText)) {
					return true;
                }
				if (lot.onCharge.toLowerCase().includes(filter.searchText)) {
					return true;
				}
                return false;
            });
			dispatch(setSearch(filter.searchText))
            return _.sortBy(currentLots, (lot) => lot.id);
    }, [lots, filter]);

    const columns = useMemo(() => {
        return [
            {
                title: intl.formatMessage({ ...messages.lot}),
				dataIndex: 'lotNumber',
				key: 'lotNumber',
            },
			{
				title: intl.formatMessage({ ...messages.onCharge}),
				dataIndex: 'onCharge',
				key: 'onCharge',
				render: (id, record) => {
					return `${record.onCharge}`;
				},
			},
			{
				title: intl.formatMessage({ ...messages.model}),
				dataIndex: 'model',
				key: 'model',
			},
			{
				title: intl.formatMessage({ ...messages.actions}),
				dataIndex: 'id',
				render: (id, record) => tableActions(record),
			}
        ];
    }, []);
	
	const extraComponents = (
		<Button
			onClick={() => setShowUploadModal(true)}
			disabled={loading}
		>
			<UploadOutlined />
				{intl.formatMessage({...messages.upload})}
		</Button>
	);

    return (
		<div className="account view">
			<CommonTable
				columns={columns}
				dataSource={filteredLots}
				loading={loading}
				recordCount={filteredLots.length}
				Add={
					<AddButton 
						isLoading={loading}
						updateLots={updateLots} 
						employees={employees} 
						models={models} 
						lots={lots} 
						units={units} 
					/>
				}
				// eslint-disable-next-line no-unused-vars
				search={(search, page, pageSize) => setFilter(search)}
				searchText = {search}
				getRecords={() => { }}
				extraComponents={[extraComponents]}
				getCheckboxProps={(() => ({ disabled: false }))}
				lowerCaseSearch
				hideCheckboxes
			/>
			<UploadFileModal
				models={models}
				employees={employees}
				lots={lots}
				showModal={showUploadModal}
				updateLots={updateLots} 
				onCancel={() => setShowUploadModal(false)}
			/>
        </div>
  )
}

export default Lots;