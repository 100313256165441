import React from 'react'; 
import PropTypes from 'prop-types';
import {getClientMessages} from '../../../constants/messages';
import {injectIntl} from 'react-intl';
import Telephone from './DynamicComponents/Telephone';
import AddButton from './DynamicComponents/AddButton';
import { getObjectInArray } from '../../../utilities/util';
import { Divider } from 'antd'; 
let cloneDeepWith = require('lodash.clonedeepwith');
 
let ClientMessages = getClientMessages(); 
 
const messages = {
  ...ClientMessages 
};

const defaultTelephone = {
    number: "",
    extension: [{extension: "-1", tag: [], name: " "}]
};

class Telephones extends React.Component {

    resetFields = () => {
        const {form} = this.props;
        form.resetFields();
    }
    
    addTelephone = () => {
        let { telephones, setState } = this.props;
        let defaultClone = cloneDeepWith(defaultTelephone);
        telephones.push(defaultClone);
        setState({telephones});
        this.resetFields();
    }

    removeTelephone = (telIndex) => {
        let { telephones, setState } = this.props;
        telephones.splice(telIndex, 1);
        setState({telephones});
        this.resetFields();
    }

    saveNumber = (number, telIndex) => {
        let { telephones, setState } = this.props;
        telephones[telIndex].number = number;
        setState({telephones});
    }

    saveExtension = (ext, telIndex, extIndex) => {
        let { telephones, setState } = this.props;
        telephones[telIndex].extension[extIndex].extension = ext;
        setState({telephones});
    }

    addExtension = (telIndex) => {
        let { telephones, setState } = this.props;
        let extension = [];
        if (telephones[telIndex].extension[0].extension !== "-1") {
            extension = [...telephones[telIndex].extension];
        }
        let defaultClone = cloneDeepWith(defaultTelephone);
        extension.push({
            ...defaultClone.extension[0],
            "extension": ""
        });
        telephones[telIndex].extension = extension;
        setState({telephones});
        this.resetFields();
    }

    removeExtension = (telIndex, extIndex) => {
        let { telephones, setState } = this.props;
        let extension = telephones[telIndex].extension;
        extension.splice(extIndex, 1);
        let defaultClone = cloneDeepWith(defaultTelephone);
        if (extension.length <= 0) extension = defaultClone.extension;
        telephones[telIndex].extension = extension;
        setState({telephones});
        this.resetFields();
    }

    saveTags = (tags, telIndex, extIndex) => {
        let { telephones, setState } = this.props;
        telephones[telIndex].extension[extIndex].tag = this.tagToObject(tags);
        setState({telephones});
      
    }

    tagToObject = (tagArray) => {
        const {tags} = this.props;
        return (
            tagArray.map(tag => {
                return getObjectInArray('id', parseInt(tag), tags);
            })
        );
    }

    saveName = (name, telIndex, extIndex) => {
        let { telephones, setState } = this.props;
        telephones[telIndex].extension[extIndex].name = name;
        setState({telephones});
    }

    telephoneListRender = () => {
        const { form, telephones, tags } = this.props;
        const { saveNumber, removeTelephone, saveExtension, addExtension, removeExtension, saveTags, saveName } = this;
        let returnRenders = [];
        if(telephones.length>0){
            for (let index = 0; index < telephones.length; index++) {
                const tel = telephones[index];
                returnRenders.push(
                    <Telephone key={index} form={form} telephone={tel} telephoneIndex={index} tags={tags} 
                        saveNumberFunction={saveNumber} removeTelephoneFunction={removeTelephone} 
                        saveExtensionFunction={saveExtension} addExtensionFunction={addExtension} 
                        removeExtensionFunction={removeExtension} saveTagsFunction={saveTags}
                        saveNameFunction={saveName} telephonesCount= {telephones.length}/>
                );
                returnRenders.push(<Divider key={`Divider${index}`}/>);
            }
        }else{
         this.addTelephone();
        }
        return (returnRenders);
    }

    fullRender = () => {
        const {intl} = this.props;
        const { telephoneListRender, addTelephone } = this;
        return (
            <div>
                {telephoneListRender()}
                <AddButton text={intl.formatMessage({...messages.addTelephone})} addFunction={addTelephone}/>
            </div>
        );
    }
     
    render() {
        return ( 
            this.fullRender()
        ); 
    } 
} 
 
Telephones.defaultProps = { 
    telephones: [],
    tags: []
}; 
 
Telephones.propTypes = {
	intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    telephones: PropTypes.array.isRequired,
    tags: PropTypes.array.isRequired,
};

export default injectIntl(Telephones);