'use strict';

import iconHash from './units'; 
import compile from 'string-template/compile';

export default (() =>{
	let icon = {
		...iconHash
	};
	
	icon.svgToIcon = ({id="1", azimuth=0, color="#fafafafa"}) => {
		let vehicle = icon[id];
		if(vehicle == undefined)
			vehicle = icon["defaultEvent"];
		let svg = vehicle.svg;
		const orientationX= azimuth>180 && azimuth<360?-1:1;
		const orientationY= 1;
		let compiler = compile(svg.svg, );
		svg = compiler({
			color,
			orientationX,
			orientationY,
			...vehicle.svg.colors,
			azimuth: azimuth>180 && azimuth<360?azimuth*-1:azimuth
		},);
		return  {
			url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg),
			anchor: vehicle.svg.anchor,
			scaledSize: {width: vehicle.svg.width, height: vehicle.svg.height}
		};
	};
	return icon;
})();