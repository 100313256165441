import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
	injectIntl,
	FormattedMessage
} from 'react-intl';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Badge, Popconfirm, notification, message } from 'antd';
import { connect } from 'react-redux';
import CommonTable from '../../components/CommonTable';
import QuotationFilter from './QuotationFilter';
import Mayre from 'mayre';
import { getQuotationMessages, getModalMessages, getactionMessages, getErrorMessages, getLanguageIntl, getInvoicingMessages, getPopConfirm, getProformaMessages } from '../../constants/messages';
import { roundN } from '../../utilities/util';
import { GetQuotations, GetQuotation, SearchQuotations, ApproveQuotation, SetQuotationToInvoiced, SetQuotationToProforma, CheckExpiredQuotations, RenewQuotation } from './actions';
import { GetProductsOfQuotation } from '../Products/actions';
import { GetFiscalData, EditFiscalData } from '../FiscalData/actions';
import { AddInvoice, GetLatestPayment, PrintInvoice, GetClientsPendingInvoices } from '../Invoices/actions';
import { AddProforma, SetProformaToInvoiced } from '../Proformas/actions';
import DenyQuotationModal from './DenyQuotationModal';
import EllipsisTooltip from '../../components/EllipsisTooltip';
import PopoverProductsTable from '../Invoices/PopoverProductsTable';
import { STATUSES } from './constants';
import async from 'async';
import AddObservationsModal from '../Invoices/AddObservationsModal';
const { NEEDS_APPROVAL, INVOICED, DENIED, CREATED_PROFORMA, EXPIRED } = STATUSES;

let moment = require('moment');

let quotationMessages = getQuotationMessages(),
	errorMessages = getErrorMessages(),
	actionMessages = getactionMessages(),
	modalMessages = getModalMessages(),
	languageMessages = getLanguageIntl(),
	invoicingMessages = getInvoicingMessages(),
	proformaMessages = getProformaMessages(),
	popconfirmMessages = getPopConfirm();


const messages = {
	...quotationMessages,
	...modalMessages,
	...actionMessages,
	...errorMessages,
	...languageMessages,
	...invoicingMessages,
	...proformaMessages,
	...popconfirmMessages
};

const defaultAddObservationsProps = {
	showAddObservationsModal: false,
	addObservationsHandleSubmit: () => { },
	addObservationsTitle: "",
	addObservationsSaveButtonText: "",
	addObservationsCurrentObservations: ""
};

class Quotations extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			selectedQuotation: 0,
			showModal: false,
			...defaultAddObservationsProps
		};
	}

	componentDidMount() {
		this.props.getFiscalData();
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	getQuotations = (page, pageSize) => {
		this.setState({ isLoading: true });
		this.props.getQuotations(page, pageSize)
			.then(() => {
				this.setState({
					isLoading: false
				});
			})
			.catch(() => {
				message.error(`${this.props.intl.formatMessage({ ...messages.GetQuotationsError })}`);
				this.setState({
					isLoading: false
				});
			});
	}

	searchQuotations = (page, pageSize, search) => {
		this.setState({ isLoading: true });
		this.props.searchQuotations(page, pageSize, search)
			.then(() => {
				this.setState({ isLoading: false });
			})
			.catch(() => {
				message.error(`${this.props.intl.formatMessage({ ...messages.GetQuotationsError })}`);
				this.setState({ isLoading: false });
			});
	}

	padWithZeroes = (number) => {
		let my_string = '' + number;
		while (my_string.length < 8) {
			my_string = '0' + my_string;
		}

		return my_string;
	}

	navigate = () => {
		this.props.history.push(`/quotations/new`);
	};

	SearchQuotations = (search) => {
		this.setState({
			isLoading: true
		});
		this.props.searchQuotations(search).then(() => {
			this.setState({
				isLoading: false
			});
		}).catch(() => {
			message.error(`${this.props.intl.formatMessage({ ...messages.GetQuotationsError })}`);
			this.setState({
				isLoading: false
			});
		})
	};

	openDenyModal = (id) => {
		this.setState({
			selectedQuotation: id,
			showModal: true
		});
	}

	closeDenyModal = () => {
		this.setState({
			showModal: false
		});
	}

	approveQuotation = (id) => {
		this.props.approveQuotation(id).then(() => {
			this.props.getQuotations().catch(() => {
				message.error(`${this.props.intl.formatMessage({ ...messages.GetQuotationsError })}`);
			});
		}).catch(() => {
			message.error(`${this.props.intl.formatMessage({ ...messages.ApproveQuotationError })}`);
		});
	}

	renewQuotation = (id) => {
		this.props.renewQuotation(id).then(() => {
			this.props.getQuotations().catch(() => {
				message.error(`${this.props.intl.formatMessage({ ...messages.GetQuotationsError })}`);
			});
		}).catch(() => {
			message.error(`${this.props.intl.formatMessage({ ...messages.ApproveQuotationError })}`);
		});
	}

	createInvoice = (quotation) => {
		this.setState({ isLoading: true });
		const tasks = [
			cb => this.props.getProductsOfQuotation(quotation.id).then(() => cb(null)).catch(cb),
			cb => this.props.getFiscalData().then(() => cb(null)).catch(cb)
		];
		async.parallel(tasks, err => {
			if (err) {
				message.error(`${this.props.intl.formatMessage({ ...messages.AddInvoiceError })}`);
				this.setState({ isLoading: false });
				return;
			}
			let values = {
				clientId: quotation.clientId,
				accountId: this.props.user.id,
				cai: this.props.fiscalData.invoice.cai,
				InitialRValue: this.props.fiscalData.invoice.initialRValue.toString(),
				FinalRValue: this.props.fiscalData.invoice.finalRValue.toString(),
				documentNumber: this.props.fiscalData.invoice.prefix + "-" + this.padWithZeroes(this.props.fiscalData.invoice.currentRValue),
				fiscalExpiryDate: this.props.fiscalData.invoice.expiryDate,
				status: 0,
				total: quotation.total,
				createdAt: moment(),
				paymentDate: quotation.type === 2 ? moment().add(quotation.daysForPayment, 'days') : moment(),
				type: quotation.type,
				observations: quotation.observations,
				exchangeRate: quotation.exchangeRate,
				reasonAnullment: "",
				clientRtn: quotation.clientRtn,
				clientName: quotation.clientName,
				representative: quotation.representative,
				products: this.props.productsOfQuotation,
				amount: 0,
				lastDocNum: "",
				address: quotation.address,
				city: quotation.city,
				state: quotation.state
			};
			this.props.getClientsPendingInvoices(values.clientId).then(() => {
				this.props.getLatestPayment(values.clientId).then(() => {
					if (this.props.latestPayment.balance < 0 && values.clientId !== 1 && this.props.pendingInvoices.length === 0) {
						let description = this.props.latestPayment.description;
						if (description === null) {
							description = "Abonado a factura número " + values.documentNumber + ".";
						} else {
							description += " Abonado a factura número " + values.documentNumber + ".";
						}
						values = {
							...values,
							amount: this.props.latestPayment.balance,
							newDesc: description,
							paymentId: this.props.latestPayment.id,
							lastDocNum: this.props.lastDocNum
						};
					}
					this.props.addInvoice(values).then(() => {
						if (this.props.addedInvoice) {
							let fiscalData = {
								...this.props.fiscalData.invoice,
								currentRValue: this.props.fiscalData.invoice.currentRValue + 1
							};
							this.props.editFiscalData(fiscalData).then(() => {
								this.props.printInvoice([this.props.invoice.id]).catch(() => {
									message.error(`${this.props.intl.formatMessage({ ...messages.PrintError })}`);
								});
								let { id, ...proforma } = quotation;
								proforma = {
									...proforma,
									products: this.props.productsOfQuotation,
									status: 2,
									invoiceId: this.props.invoice.id,
									taxExemption: false,
									isProgrammed: false,
									dateProgrammed: moment(),
									quotationId: quotation.id
								};
								if (quotation.status !== 4) {
									this.props.addProforma(proforma).then(() => {
										this.props.setQuotationToInvoiced(id, this.props.invoice.id, this.props.proforma.id).catch(() => {
											message.error(`${this.props.intl.formatMessage({ ...messages.AddProformaError })}`);
										});
										notification.open({
											message: this.props.intl.formatMessage({ ...messages.Print }),
											description: this.props.intl.formatMessage({ ...messages.ReminderPrint }),
										});
										this.setState({
											isLoading: false
										});
										this.props.history.push(`/invoices/${this.props.invoice.id}`);
									}).catch(() => {
										message.error(`${this.props.intl.formatMessage({ ...messages.AddProformaError })}`);
										this.setState({
											isLoading: false
										});
									});
								} else {
									this.props.setProformaToInvoiced(quotation.proformaId, this.props.invoice.id).then(() => {
										this.props.setQuotationToInvoiced(id, this.props.invoice.id, this.props.proforma.id).catch(() => {
											message.error(`${this.props.intl.formatMessage({ ...messages.SetQuotationAsInvoicedError })}`);
										});
										notification.open({
											message: this.props.intl.formatMessage({ ...messages.Print }),
											description: this.props.intl.formatMessage({ ...messages.ReminderPrint }),
										});
										this.setState({
											isLoading: false
										});
										this.props.history.push(`/invoices/${this.props.invoice.id}`);
									}).catch(() => {
										message.error(`${this.props.intl.formatMessage({ ...messages.SetProformaAsInvoicedError })}`);
										this.setState({
											isLoading: false
										});
									});
								}
							});
						}
					}).catch(() => {
						message.error(`${this.props.intl.formatMessage({ ...messages.AddInvoiceError })}`);
						this.setState({
							isLoading: false
						});
					});
				}).catch(() => {
					message.error(`${this.props.intl.formatMessage({ ...messages.GetLatestPaymentError })}`);
					this.setState({
						isLoading: false
					});
				});
			});
		});
	}

	createProforma = (quotation) => {
		this.setState({
			isLoading: true
		});
		let { id, ...proforma } = quotation;
		this.props.getProductsOfQuotation(quotation.id).then(() => {
			proforma = {
				...proforma,
				products: this.props.productsOfQuotation,
				taxExemption: quotation.taxExemption,
				isProgrammed: false,
				dateProgrammed: moment(),
				quotationId: quotation.id
			};
			this.props.addProforma(proforma).then(() => {
				this.props.setQuotationToProforma(id, this.props.proforma.id).catch(() => {
					message.error(`${this.props.intl.formatMessage({ ...messages.SetQuotationToProformaError })}`);
				});
				this.setState({
					isLoading: false
				});
				this.props.history.push(`/proformas/${this.props.proforma.id}`);
			}).catch(() => {
				message.error(`${this.props.intl.formatMessage({ ...messages.AddProformaError })}`);
				this.setState({
					isLoading: false
				});
			});
		});
	}

	openAddObservationsModalProforma = (quotation) => {
		const { getIntl, createProforma } = this;
		const { observations } = quotation;
		const showAddObservationsModal = true;
		const addObservationsTitle = getIntl("ToProforma");
		const addObservationsSaveButtonText = getIntl("ToProforma");
		const addObservationsCurrentObservations = observations;
		const addObservationsHandleSubmit = newObservations => {
			const quotationCopy = {
				...quotation,
				observations: newObservations
			};
			createProforma(quotationCopy);
		};
		this.setState({ showAddObservationsModal, addObservationsTitle, addObservationsSaveButtonText, addObservationsCurrentObservations, addObservationsHandleSubmit });
	}

	openAddObservationsModalInvoice = (quotation) => {
		const { getIntl, createInvoice } = this;
		const { observations } = quotation;
		const showAddObservationsModal = true;
		const addObservationsTitle = getIntl("Invoice");
		const addObservationsSaveButtonText = getIntl("Invoice");
		const addObservationsCurrentObservations = observations;
		const addObservationsHandleSubmit = newObservations => {
			const quotationCopy = {
				...quotation,
				observations: newObservations
			};
			createInvoice(quotationCopy);
		};
		this.setState({ showAddObservationsModal, addObservationsTitle, addObservationsSaveButtonText, addObservationsCurrentObservations, addObservationsHandleSubmit });
	}

	closeAddObservationsModal = () => {
		this.setState({ ...defaultAddObservationsProps });
	}

	render() {
		const { state, closeAddObservationsModal, openAddObservationsModalProforma, openAddObservationsModalInvoice } = this;
		const { showAddObservationsModal, isLoading, addObservationsCurrentObservations, addObservationsHandleSubmit, addObservationsTitle, addObservationsSaveButtonText } = state;
		const { searchText, currentPage } = this.props;
		const onQuotationNumberClick = (e, quotationId) => {
			e.preventDefault();
			this.props.history.push(`/quotations/${quotationId}`);
		}
		let addQuotationButton = (<span>
			<Button className="editable-add-btn" type="primary" onClick={this.navigate}>
				<PlusOutlined />
				<FormattedMessage {...messages.add} />
			</Button>
		</span>);

		let columns = [
			{
				title: this.props.intl.formatMessage({ ...messages.QuotationNumber }),
				dataIndex: 'id',
				key: 'id',
				width: 200,
				render: (quotationId, quotation) => {
					const { quotationProducts = [] } = quotation;
					return (
						<PopoverProductsTable products={quotationProducts}>
							<a className="editable-add-btn" href={`/quotations/${quotationId}`} onClick={(e) => onQuotationNumberClick(e, quotationId)}>
								{quotationId}
							</a>
						</PopoverProductsTable>
					);
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Client }),
				dataIndex: 'clientName',
				key: 'clientName',
				width: 250,
				onCell: () => {
					return {
						style: {
							whiteSpace: 'nowrap',
							maxWidth: 250,
						}
					}
				},
				render: (clientName) => {
					return (
						<EllipsisTooltip title={clientName}>{clientName}</EllipsisTooltip>
					);
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Type }),
				dataIndex: 'type',
				key: 'type',
				width: 100,
				render: (type) => {
					if (type === 1) {
						return this.props.intl.formatMessage({ ...messages.Cash });
					} else if (type === 2) {
						return this.props.intl.formatMessage({ ...messages.Credit });
					}
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Status }),
				dataIndex: 'status',
				className: 'status',
				width: 150,
				render: (status) => {
					let color = 'processing';
					let text = this.props.intl.formatMessage({ ...messages.ReadyInvoicing });
					if (status === NEEDS_APPROVAL) {
						color = 'warning';
						text = this.props.intl.formatMessage({ ...messages.NeedsApproval });
					} else if (status === INVOICED) {
						color = 'success';
						text = this.props.intl.formatMessage({ ...messages.Invoiced });
					} else if (status === DENIED) {
						color = 'error';
						text = this.props.intl.formatMessage({ ...messages.Denied });
					} else if (status === CREATED_PROFORMA) {
						color = 'success';
						text = this.props.intl.formatMessage({ ...messages.CreatedProforma });
					} else if (status === EXPIRED) {
						color = 'error';
						text = this.props.intl.formatMessage({ ...messages.Expired });
					}
					return (
						<Badge status={color} text={text} />
					);
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Total }),
				dataIndex: 'total',
				key: 'total',
				className: 'column-money',
				width: 250,
				render: (total, quotation) => {
					return (
						`L ${roundN(total * quotation.exchangeRate, 2)}`
					);
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Date }),
				dataIndex: 'createdAt',
				key: 'createdAt',
				width: 250,
				render: (date) => {
					let formatedDate = new Date(date);
					formatedDate.toLocaleDateString();
					return (
						formatedDate.toLocaleDateString(this.props.intl.formatMessage({ ...messages.intl }), { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
					);
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Actions }),
				dataIndex: 'id',
				key: 'actions',
				render: (id, quotation) => {
					return (
						<span>
							<a className="editable-add-btn" onClick={() => { this.props.history.push(`/quotations/${id}`); }}>
								<FormattedMessage {...messages.View} />
							</a>
							<Mayre
								of={
									<span>
										<span> | </span>
										<Popconfirm title={this.props.intl.formatMessage({ ...messages.RenewQ })} onConfirm={() => { this.renewQuotation(id); }} okText={this.props.intl.formatMessage({ ...messages.yes })} cancelText={this.props.intl.formatMessage({ ...messages.no })}>
											<a href="#"><FormattedMessage {...messages.Renew} /></a>
										</Popconfirm>
									</span>
								} when={quotation.status === 5}
							/>
							<Mayre
								of={
									<span>
										<span> | </span>
										<Popconfirm title={this.props.intl.formatMessage({ ...messages.ApproveQ })} onConfirm={() => { this.approveQuotation(id); }} okText={this.props.intl.formatMessage({ ...messages.yes })} cancelText={this.props.intl.formatMessage({ ...messages.no })}>
											<a href="#"><FormattedMessage {...messages.Approve} /></a>
										</Popconfirm>
										<span> | </span>
										<a className="editable-add-btn" onClick={() => { this.openDenyModal(id); }}>
											<FormattedMessage {...messages.Deny} />
										</a>
									</span>
								} when={quotation.status === 1 && this.props.isMaster}
							/>
							<Mayre
								of={
									<span>
										<span> | </span>
										<a className="editable-add-btn" onClick={() => { openAddObservationsModalProforma(quotation); }}>
											<FormattedMessage {...messages.ToProforma} />
										</a>
										<span> | </span>
										<a className="editable-add-btn" onClick={() => { openAddObservationsModalInvoice(quotation); }}>
											<FormattedMessage {...messages.Invoice} />
										</a>
									</span>
								} when={quotation.status === 0 && (this.props.isMaster || this.props.roles.includes(2))}
							/>
							<Mayre
								of={
									<span>
										<span> | </span>
										<a className="editable-add-btn" onClick={() => { this.props.history.push(`/invoices/${quotation.invoiceId}`); }}>
											<FormattedMessage {...messages.ViewInvoice} />
										</a>
										<span> | </span>
										<a className="editable-add-btn" onClick={() => { this.props.history.push(`/proformas/${quotation.proformaId}`); }}>
											<FormattedMessage {...messages.ViewProforma} />
										</a>
									</span>
								} when={quotation.status === 2 && (this.props.isMaster || this.props.roles.includes(2))}
							/>
							<Mayre
								of={
									<span>
										<span> | </span>
										<a className="editable-add-btn" onClick={() => { this.props.history.push(`/proformas/${quotation.proformaId}`); }}>
											<FormattedMessage {...messages.ViewProforma} />
										</a>
										<span> | </span>
										<a className="editable-add-btn" onClick={() => { openAddObservationsModalInvoice(quotation); }}>
											<FormattedMessage {...messages.Invoice} />
										</a>
									</span>
								} when={quotation.status === 4 && (this.props.isMaster || this.props.roles.includes(2))}
							/>
						</span>
					);
				}
			}
		];

		return (
			<div className="account view">
				<DenyQuotationModal quotationId={this.state.selectedQuotation} showModal={this.state.showModal} closeDenyModal={this.closeDenyModal} />
				<AddObservationsModal
					showModal={showAddObservationsModal}
					isLoading={isLoading}
					handleSubmit={addObservationsHandleSubmit}
					handleCancel={closeAddObservationsModal}
					title={addObservationsTitle}
					saveButtonText={addObservationsSaveButtonText}
					currentObservations={addObservationsCurrentObservations}
				/>
					<CommonTable
						columns={columns}
						dataSource={this.props.quotations}
						search={(search, page, pageSize) => this.searchQuotations(page, pageSize, search)}
						searchText={searchText}
						currentPage={currentPage}
						recordCount={this.props.count}
						preservePage={true}
						getRecords={(page, pageSize) => this.getQuotations(page, pageSize)}
						Add={addQuotationButton}
						loading={this.state.isLoading}
						filterComponent={(<QuotationFilter />)}
						getCheckboxProps={(() => ({ disabled: false }))}
						hideCheckboxes
					/>
			</div>
		);
	}
}

Quotations.propTypes = {
	intl: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	isMaster: PropTypes.bool.isRequired,
	roles: PropTypes.array.isRequired,
	productsOfQuotation: PropTypes.array.isRequired,
	latestPayment: PropTypes.object.isRequired,
	invoice: PropTypes.object.isRequired,
	addedInvoice: PropTypes.bool.isRequired,
	lastDocNum: PropTypes.string.isRequired,
	quotations: PropTypes.array.isRequired,
	fiscalData: PropTypes.object.isRequired,
	proforma: PropTypes.object.isRequired,
	pendingInvoices: PropTypes.array.isRequired,
	getQuotations: PropTypes.func.isRequired,
	getQuotation: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	searchQuotations: PropTypes.func.isRequired,
	approveQuotation: PropTypes.func.isRequired,
	setQuotationToInvoiced: PropTypes.func.isRequired,
	setQuotationToProforma: PropTypes.func.isRequired,
	getProductsOfQuotation: PropTypes.func.isRequired,
	getFiscalData: PropTypes.func.isRequired,
	editFiscalData: PropTypes.func.isRequired,
	getLatestPayment: PropTypes.func.isRequired,
	addInvoice: PropTypes.func.isRequired,
	printInvoice: PropTypes.func.isRequired,
	addProforma: PropTypes.func.isRequired,
	getClientsPendingInvoices: PropTypes.func.isRequired,
	renewQuotation: PropTypes.func.isRequired,
	setProformaToInvoiced: PropTypes.func.isRequired,
	count: PropTypes.number.isRequired,
	searchText: PropTypes.string,
	currentPage: PropTypes.number
};

const mapStateToProps = (state) => {
	return {
		quotations: state.quotation.quotations,
		user: state.auth.user,
		isMaster: state.auth.isMaster,
		roles: state.auth.roles,
		productsOfQuotation: state.product.productsOfCurrentQuotation,
		fiscalData: state.fiscalData.fiscalData,
		latestPayment: state.invoicing.latestPayment,
		invoice: state.invoicing.invoice,
		addedInvoice: state.invoicing.addedInvoice,
		lastDocNum: state.invoicing.lastDocNum,
		proforma: state.proforma.proforma,
		pendingInvoices: state.invoicing.pendingInvoices,
		searchText: state.quotation.search, 
		count: state.quotation.count,
		currentPage: state.quotation.page
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getQuotations: (page, pageSize) => {
			return dispatch(GetQuotations(page, pageSize));
		},
		getQuotation: (id) => {
			return dispatch(GetQuotation(id));
		},
		searchQuotations: (page, pageSize, search) => {
			return dispatch(SearchQuotations(page, pageSize, search));
		},
		approveQuotation: (id) => {
			return dispatch(ApproveQuotation(id));
		},
		checkExpiredQuotations: () => {
			return dispatch(CheckExpiredQuotations());
		},
		renewQuotation: (id) => {
			return dispatch(RenewQuotation(id));
		},
		setQuotationToInvoiced: (id, invoiceId, proformaId) => {
			return dispatch(SetQuotationToInvoiced(id, invoiceId, proformaId));
		},
		setQuotationToProforma: (id, proformaId) => {
			return dispatch(SetQuotationToProforma(id, proformaId));
		},
		getProductsOfQuotation: (id) => {
			return dispatch(GetProductsOfQuotation(id));
		},
		getFiscalData: () => {
			return dispatch(GetFiscalData());
		},
		editFiscalData: (fiscalData) => {
			return dispatch(EditFiscalData(fiscalData));
		},
		getLatestPayment: (clientId) => {
			return dispatch(GetLatestPayment(clientId));
		},
		addInvoice: (newInvoice) => {
			return dispatch(AddInvoice(newInvoice));
		},
		printInvoice: (ids) => {
			return dispatch(PrintInvoice(ids));
		},
		getClientsPendingInvoices: (clientId) => {
			return dispatch(GetClientsPendingInvoices(clientId));
		},
		addProforma: (newProforma) => {
			return dispatch(AddProforma(newProforma));
		},
		setProformaToInvoiced: (id, invoiceId) => {
			return dispatch(SetProformaToInvoiced(id, invoiceId));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(Quotations)));
