import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Descriptions, Divider } from 'antd';
import { getDeviceMessages } from '../../../../constants/messages';
import { getNestedValue } from '../../../../utilities/util';
import Embolden from '../../../GlobalComponents/Embolden';
import DeviceRender from './DeviceRender';
import DescriptionsCombiner from '../../../Jobs/ViewJob/DefaultViews/DescriptionsCombiner';
const DescriptionItem = Descriptions.Item;

let deviceMessages = getDeviceMessages();

const messages = {
	...deviceMessages
};

class SimRender extends React.Component {

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	embolden = (header, value) => {
		const { getIntl } = this;
		return <Embolden header={getIntl(header)} value={value} row />;
	}

	renderSimInfo = () => {
		const { props, getIntl, embolden } = this;
		const { sim, bordered } = props;
		const { line, serial, carrier } = sim;
		return (
			<Descriptions layout="vertical" size="small" bordered={bordered} column={1}>
				<DescriptionItem label={getIntl("sim")}>
					{embolden("line", line)}
					{embolden("serial", serial)}
					{embolden("carrier", getNestedValue("description", carrier))}
				</DescriptionItem>
			</Descriptions>
		);
	}

	renderInfo = () => {
		const { props, renderSimInfo, getIntl } = this;
		const { sim, hideDeviceInfo, bordered } = props;
		if (!hideDeviceInfo && sim.devices && sim.devices.length > 0) {
			const { devices = [] } = sim;
			let deviceRenders;
			if (devices.length > 1) {
				deviceRenders = [];
				devices.forEach((device, index) => {
					deviceRenders.push(
						<Divider key={`Divider${index}`}>{getIntl("deviceNumber", { number: index + 1 })}</Divider>,
						<DeviceRender key={device.id} device={device} hideSimInfo bordered={bordered} />
					);

				});
				return (
					<div>
						{renderSimInfo()}
						{deviceRenders}
					</div>
				);
			} else {
				return (
					<DescriptionsCombiner>
						{renderSimInfo()}
						<DeviceRender device={devices[0]} hideSimInfo bordered={bordered} />
					</DescriptionsCombiner>
				);
			}
		}
		return renderSimInfo();
	}

	render() {
		return (this.renderInfo());
	}
}

SimRender.defaultProps = {
	sim: {},
	hideDeviceInfo: false,
	bordered: true
};

SimRender.propTypes = {
	intl: PropTypes.object.isRequired,
	sim: PropTypes.object.isRequired,
	hideDeviceInfo: PropTypes.bool.isRequired,
	bordered: PropTypes.bool.isRequired
};

export default injectIntl(SimRender);