import React from 'react';
import PropTypes from 'prop-types';
import {
	injectIntl,
	FormattedMessage
} from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Modal, InputNumber, Input, Row, Col, Select, Spin, message } from 'antd';
import {
	getModalMessages,
	getPaymentMessages,
	getDebitNoteMessages,
	getFiscalDataMessages,
	getErrorMessages,
	getInvoicingMessages,
	getLanguageIntl,
	getClientMessages,
	getactionMessages
} from '../../constants/messages';
import { connect } from 'react-redux';
import { AddDebitNote, GetDebitNotes, GetDebitNote, UpdateDebitNote } from './actions';
import { GetClientReceipts } from '../Payments/actions';
import { GetClientsList, GetAllClients, GetClientInfo } from '../Clients/actions';
import { GetFiscalData } from '../FiscalData/actions';
import { GetExchangeRate } from '../ExchangeRate/actions';
import { VARIOUS_CLIENTS_CLIENT_ID } from '../../constants/global';

import moment from 'moment';
const { TextArea } = Input;

let modalMessages = getModalMessages(),
	paymentMessages = getPaymentMessages(),
	debitNoteMessages = getDebitNoteMessages(),
	fiscalDataMessages = getFiscalDataMessages(),
	errorMessages = getErrorMessages(),
	invoiceMessages = getInvoicingMessages(),
	languageMessages = getLanguageIntl(),
	clientMessages = getClientMessages(),
	actionMessages = getactionMessages();

const messages = {
	...modalMessages,
	...paymentMessages,
	...debitNoteMessages,
	...fiscalDataMessages,
	...errorMessages,
	...invoiceMessages,
	...languageMessages,
	...clientMessages,
	...actionMessages
};
const FormItem = Form.Item;
const Option = Select.Option;

class AddDebitNoteModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			currentClient: null,
			isLoading: false,
			total: 0,
		};
	}

	componentDidMount() {
		const { getAllClients, intl } = this.props; 
		getAllClients().then(() => {
		}).catch(() => {
			message.error(`${intl.formatMessage({ ...messages.GetClientsError })}`);
		});
	}

	handleValues = (values) => {
		const { fiscalData: { debit: { cai, prefix, expiryDate, initialRValue, finalRValue, currentRValue } },
			currentClientName, currentRTN, fullAddress: { city } } = this.props;
		return {
			...values,
			createdAt: moment(),
			status: 0,
			cai: cai,
			documentNumber: `${prefix}-${currentRValue.toString().padStart(8, "0")}`,
			fiscalExpiryDate: expiryDate,
			initialRValue: initialRValue.toString(),
			finalRValue: finalRValue.toString(),
			clientName: currentClientName,
			clientRtn: currentRTN,
			clientCity: city,
		}
	}

	handleOk = () => {
		const { form, getFiscalData, getDebitNotes, getDebitNote, intl, addDebitNote, document, updateDebitNote } = this.props;
		form.validateFields({ force: true }, (err, values) => {
			if (err)
				return;
			this.setState({ isLoading: true });
			getFiscalData().then(() => {
				let debitNote = this.handleValues(values);
				const action = document ? updateDebitNote(document.id, debitNote) : addDebitNote(debitNote);
				action.then(() => {
					const msg = document ? messages.EditDebitNoteSuccess : messages.AddDebitNoteSuccess
					message.success(intl.formatMessage({ ...msg }))
					if(document){
						getDebitNote(document.id).then(() => {
						}).catch(() => {
							message.error(intl.formatMessage({ ...messages.GetDebitNotesError }));
						});
					}
					getDebitNotes().then(() => {
					}).catch(() => {
						message.error(intl.formatMessage({ ...messages.GetDebitNotesError }));
					});
				}).catch(() => {
					message.error(intl.formatMessage({ ...messages.AddDebitNoteError }));
				}).finally(()=> {
					this.setState({isLoading: false});
				});
			}).catch(() => {
				message.error(intl.formatMessage({ ...messages.GetFiscalDataError }));
			}).finally(() => {
				this.props.handleCancel();
			});
		});
	}

	handleCancel = () => {
		this.setState({
			isLoading: false,
		});
		this.props.handleCancel();
	}

	handleSelectClient = (client) => {
		const { getClientInfo, intl} = this.props;
		this.setState({ currentClient: client - 1 });
		if (client !== VARIOUS_CLIENTS_CLIENT_ID) {
			getClientInfo(client).catch(() => {
				message.error(`${intl.formatMessage({ ...messages.GetClientInfoError })}`);
			});
		}
	}

	renderForm = () => {
		const { intl, clients, document, form: { getFieldDecorator } } = this.props;
		const { isLoading } = this.state;
		return (
			<div>
				<Form layout='vertical'>
					<Row gutter={16} type="flex" align="middle">
						<Col span={12}>
							<FormItem label={intl.formatMessage({ ...messages.Client })}>
								{getFieldDecorator('clientId', {
									rules: [{
										required: true, message: intl.formatMessage({ ...messages.clientError }),
									}],
									initialValue: document ? document.clientId : undefined
								})(
								<Select
									showSearch
									notFoundContent={isLoading ? <Spin size="small" /> : null}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0
									}
									onSelect={this.handleSelectClient}
									style={{ width: '100%' }}
								>
									{
										clients.map(function (client, i) {
											return <Option key={i} value={client.id}>{client.name}</Option>;
										})
									}
								</Select>
								)}
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem label={'Total'}>
								{getFieldDecorator('total', {
									rules: [{
										required: true, message: intl.formatMessage({ ...messages.paymentError }),
									}],
									initialValue: document ? document.total : 0.00
								})(
									<InputNumber formatter={value => `L ${value}`} parser={value => value.substring(2)} step=".01" style={{ width: '100%', 'textAlign': 'right' }} />
								)}
							</FormItem>
						</Col>
					</Row>
					<Row gutter={16} type="flex" align="middle">
						<Col span={24}>
							<FormItem label={intl.formatMessage({ ...messages.Motive })}>
								{getFieldDecorator('motive', {
									rules: [{
										required: true, message: intl.formatMessage({ ...messages.motiveError }),
									}],
									initialValue: document ? document.motive : ""
								})(
									<Input />
								)}
							</FormItem>
						</Col>
					</Row>
					<FormItem label={intl.formatMessage({ ...messages.observations })}>
						{getFieldDecorator('description', {
							rules: [{
								required: false,
							}],
							initialValue: document ? document.description : ""
						})(
							<TextArea rows={6} />
						)}
					</FormItem>
				</Form>

			</div>
		);
	}

	render() {
		const { props: { showModal, intl, document }, state: { isLoading } } = this;
		return (
			<React.Fragment>
				<Modal
					title={intl.formatMessage(document ? { ...messages.EditDebitNote } : { ...messages.AddDebitNote })}
					destroyOnClose
					open={showModal}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					confirmLoading={isLoading}
					width={700}
					footer={
						<div>
							<Button type="primary" size="large" loading={isLoading} onClick={this.handleOk} className="button-spacing">
								<FormattedMessage {...messages.save} />
							</Button>
							<Button key="back" size="large" onClick={this.handleCancel}>
								<FormattedMessage {...messages.cancel} />
							</Button>
						</div>
					}
				>
					{this.renderForm()}
				</Modal>
			</React.Fragment>
		);
	}

}

AddDebitNoteModal.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	showModal: PropTypes.bool.isRequired,
	fiscalData: PropTypes.object.isRequired,
	handleCancel: PropTypes.func.isRequired,
	getClientReceipts: PropTypes.func.isRequired,
	getClientsList: PropTypes.func.isRequired,
	getAllClients: PropTypes.func.isRequired,
	getFiscalData: PropTypes.func.isRequired,
	addDebitNote: PropTypes.func.isRequired,
	getDebitNotes: PropTypes.func.isRequired,
	getDebitNote: PropTypes.func.isRequired,
	clients: PropTypes.array.isRequired,
	getExchangeRate: PropTypes.func.isRequired,
	currentClientName: PropTypes.string.isRequired,
	currentRTN: PropTypes.string.isRequired,
	fullAddress: PropTypes.object.isRequired,
	getClientInfo: PropTypes.func.isRequired,
	document: PropTypes.object,
	updateDebitNote: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	return {
		clients: state.client.clients,
		currentClientName: state.client.currentClientName,
		currentRTN: state.client.currentRTN,
		fullAddress: state.client.fullAddress,
		fiscalData: state.fiscalData.fiscalData,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateDebitNote: (debitNoteId, debitNote) => {
			return dispatch(UpdateDebitNote(debitNoteId, debitNote));
		},
		addDebitNote: (debitNote) => {
			return dispatch(AddDebitNote(debitNote));
		},
		getDebitNotes: () => {
			return dispatch(GetDebitNotes());
		},
		getDebitNote: (id) => {
			return dispatch(GetDebitNote(id));
		},
		getClientReceipts: (clientId) => {
			return dispatch(GetClientReceipts(clientId));
		},
		getClientsList: (searchText) => {
			return dispatch(GetClientsList(searchText));
		},
		getAllClients: () => {
			return dispatch(GetAllClients());
		},
		getClientInfo: (clientId) => {
			return dispatch(GetClientInfo(clientId));
		},
		getFiscalData: () => {
			return dispatch(GetFiscalData());
		},
		getExchangeRate: () => {
			return dispatch(GetExchangeRate());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(AddDebitNoteModal)));
