import React from 'react';
import PropTypes from 'prop-types';
import {
    injectIntl,
    FormattedMessage
} from 'react-intl';
import {
    getFiscalDataMessages,
    getModalMessages,
    getErrorMessages
} from '../../constants/messages';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Tabs,
    Row,
    Col,
    Modal,
    Input,
    DatePicker,
    InputNumber,
    Spin,
    message,
} from 'antd';
import { connect } from 'react-redux';
import { REGEX } from '../../constants/global';
import { GetFiscalData, AddFiscalData, EditFiscalData } from './actions';
import Mayre from 'mayre';

let fiscalDataMessages = getFiscalDataMessages();
let modalMessages = getModalMessages();
let errorMessages = getErrorMessages();
const messages = {
    ...fiscalDataMessages,
    ...modalMessages,
    ...errorMessages
};

let moment = require('moment');
const FormItem = Form.Item;

class FiscalData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            isVisible: false,
            activeTab: "1",
            buttons: [],
            isLoading: true
        };
    }

    componentDidMount() {
        this.props.getFiscalData().then(() => {
            this.changeButtons(this.state.activeTab, 0);
            this.setState({
                isLoading: false
            });
        }).catch(() => {
            message.error(`${this.props.intl.formatMessage({ ...messages.GetFiscalDataError })}`);
        });
    }

    disabledDate = (current) => {
        return current < moment().startOf('day');
    }

    dateValidator = (rule, value, callback) => {
        if (value < moment().startOf('day'));
        callback(this.props.intl.formatMessage({ ...messages.dateYesterdayError }));
        callback();
    }

    openModal = () => {
        this.setState({
            isVisible: true,
            isEditing: false,
            isAdding: false
        });
    }

    currentValue = () => {
        if (this.state.activeTab === "1") {
            return this.props.fiscalData.invoice;
        }
        if (this.state.activeTab === "2") {
            return this.props.fiscalData.credit;
        }
        if (this.state.activeTab === "3") {
            return this.props.fiscalData.debit;
        }
    }

    handleOk = () => {
        this.setState({
            isVisible: false,
        });
    }

    handleCancel = () => {
        this.setState({
            isVisible: false,
        });
    }

    handleNew = () => {
        this.changeButtons(this.state.activeTab, 2);
        this.setState({
            isEditing: false,
            isAdding: true
        });
    }

    handleEdit = () => {
        this.changeButtons(this.state.activeTab, 1);
        this.setState({
            isEditing: true,
            isAdding: false
        });
    }

    handleReturn = () => {
        this.changeButtons(this.state.activeTab, 0);
        this.setState({
            isEditing: false,
            isAdding: false
        });
    }

    handleCreate = () => {
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }
            let tempDate = values.expiry;
            values = {
                ...values,
                type: parseInt(this.state.activeTab, 10) - 1,
                expiry: tempDate.format()
            };
            this.setState({
                isLoading: true
            });
            this.changeButtons(this.state.activeTab, 3);
            if (this.state.isAdding) {
                this.props.addFiscalData(values).then(() => {
                    this.setState({
                        isEditing: false,
                        isAdding: false
                    });
                    this.props.getFiscalData().then(() => {
                        this.setState({
                            isLoading: false
                        });
                    }).catch(() => {
                        message.error(`${this.props.intl.formatMessage({ ...messages.GetFiscalDataError })}`);
                        this.setState({
                            isLoading: false
                        });
                    });
                    this.changeButtons(this.state.activeTab, 0);
                }).catch(() => {
                    message.error(`${this.props.intl.formatMessage({ ...messages.errorCreatingNewFiscalD })}`);
                    this.setState({
                        isLoading: false
                    });
                    this.changeButtons(this.state.activeTab, 4);
                });
            } else {
                let fiscalData = this.currentValue();
                values = {
                    ...values,
                    id: fiscalData.id,
                };
                this.props.editFiscalData(values).then(() => {
                    this.setState({
                        isEditing: false,
                        isAdding: false
                    });
                    this.props.getFiscalData().then(() => {
                        this.setState({
                            isLoading: false
                        });
                    }).catch(() => {
                        message.error(`${this.props.intl.formatMessage({ ...messages.GetFiscalDataError })}`);
                        this.setState({
                            isLoading: false
                        });
                    });
                    this.changeButtons(this.state.activeTab, 0);
                }).catch(() => {
                    message.error(`${this.props.intl.formatMessage({ ...messages.errorCreatingNewFiscalD })}`);
                    this.setState({
                        isLoading: false
                    });
                    this.changeButtons(this.state.activeTab, 4);
                });
            }
        });
    }

    onTabChange = (activeKey) => {
        this.changeButtons(activeKey, 0);
        this.setState({
            activeTab: activeKey
        });
    }

    padWithZeroes = (number) => {
        let my_string = '' + number;
        while (my_string.length < 8) {
            my_string = '0' + my_string;
        }

        return my_string;
    }

    changeButtons = (activeKey, caseNumber) => {
        switch (caseNumber) {
            case 0:
                this.setButtons(activeKey, false, false, false);
                break;
            case 1:
                this.setButtons(activeKey, true, false, false);
                break;
            case 2:
                this.setButtons(activeKey, false, true, false);
                break;
            case 3:
                this.setButtons(this.state.activeTab, this.state.isEditing, this.state.isAdding, true);
                break;
            case 4:
                this.setButtons(this.state.activeTab, this.state.isEditing, this.state.isAdding, false);
                break;
        }
    }

    setButtons = (activeKey, isEdit, isAdd, isLoading) => {
        if (isEdit || isAdd) {
            this.setState({
                buttons: [
                    <Button key="submit" type="primary" size="large" loading={isLoading} onClick={this.handleCreate} >
                        <FormattedMessage {...messages.save} />
                    </Button>,
                    <Button key="back" size="large" onClick={this.handleReturn}>
                        <FormattedMessage {...messages.cancel} />
                    </Button>,
                ]
            });
        } else {
            let buttons = [<Button key="new" type="primary" size="large" onClick={this.handleNew}>
                <FormattedMessage {...messages.add} />
            </Button>];
            if (activeKey === "1" && this.props.fiscalData.invoice !== undefined) {
                buttons.push(<Button key="edit" size="large" onClick={this.handleEdit}>
                    <FormattedMessage {...messages.edit} />
                </Button>);
            }
            if (activeKey === "2" && this.props.fiscalData.credit !== undefined) {
                buttons.push(<Button key="edit" size="large" onClick={this.handleEdit}>
                    <FormattedMessage {...messages.edit} />
                </Button>);
            }
            if (activeKey === "3" && this.props.fiscalData.debit !== undefined) {
                buttons.push(<Button key="edit" size="large" onClick={this.handleEdit}>
                    <FormattedMessage {...messages.edit} />
                </Button>);
            }
            buttons.push(<Button key="back" size="large" onClick={this.props.handleCancel}>
                <FormattedMessage {...messages.cancel} />
            </Button>);
            this.setState({
                buttons: buttons
            });
        }
    }

    showInfo = (fiscalData) => {
        if (fiscalData !== undefined) {
            return (
                <Row>
                    <Col span={12}>
                        <h3>CAI</h3>
                        <p>{fiscalData.cai}</p>

                        <h3><FormattedMessage {...messages.Range} /></h3>
                        <p>{this.padWithZeroes(fiscalData.initialRValue) + " - " + this.padWithZeroes(fiscalData.finalRValue)}</p>

                        <h3><FormattedMessage {...messages.Next} /></h3>
                        <p>{fiscalData.prefix + "-" + this.padWithZeroes(fiscalData.currentRValue)}</p>
                    </Col>
                    <Col span={12}>
                        <h3><FormattedMessage {...messages.Prefix} /></h3>
                        <p>{fiscalData.prefix}</p>

                        <h3><FormattedMessage {...messages.ExpiryDate} /></h3>
                        <p>{moment(fiscalData.expiryDate).locale('es').format('L')}</p>

                    </Col>
                </Row>
            );
        }
    }

    modalContent = () => {
        const { 
            props: { form, intl, fiscalData }, 
            state: { isAdding, isEditing, activeTab, isLoading }, 
            showInfo, onTabChange, disabledDate, currentValue 
        } = this;

        if (isAdding || isEditing) {
            const { getFieldDecorator } = form;
            const fiscalValue = currentValue();
            return (
                <Form>
                    <Row gutter={16}>
                        <Col span={12}>
                            <h3>CAI</h3>
                            <FormItem >
                                {getFieldDecorator('cai', {
                                    initialValue: isEditing ? fiscalValue.cai : null,
                                    rules: [{
                                        required: true, whitespace: true, message: intl.formatMessage({ ...messages.caiEmptyError }),
                                    },
                                    { pattern: REGEX.cai, message: intl.formatMessage({ ...messages.caiLengthError }) }
                                    ],
                                })(
                                    <Input className="form-col-width" />
                                )}
                            </FormItem>
                            <h3><FormattedMessage {...messages.Range} /></h3>
                            <div>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <FormItem>
                                            {getFieldDecorator('rangemin', {
                                                initialValue: isEditing ? fiscalValue.initialRValue : null,
                                                rules: [{
                                                    required: true, message: intl.formatMessage({ ...messages.minrangeError }),
                                                }],
                                            })(
                                                <InputNumber placeholder="Min." min={1} />
                                            )}
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem>
                                            {getFieldDecorator('rangemax', {
                                                initialValue: isEditing ? fiscalValue.finalRValue : null,
                                                rules: [{
                                                    required: true, message: intl.formatMessage({ ...messages.maxrangeError }),
                                                }],
                                            })(
                                                <InputNumber placeholder="Max." min={1} />
                                            )}
                                        </FormItem>
                                    </Col>
                                </Row>
                            </div>
                            <Mayre of={
                                <React.Fragment>
                                    <h3><FormattedMessage {...messages.Next} /></h3>
                                    <FormItem>
                                        {getFieldDecorator('currentRValue', {
                                            initialValue: isEditing ? fiscalValue.currentRValue : 0,
                                            rules: [{
                                                required: true, message: intl.formatMessage({ ...messages.maxrangeError }),
                                            }],
                                        })(
                                            <InputNumber min={isEditing ? fiscalValue.currentRValue : 0} />
                                        )}
                                    </FormItem>
                                </React.Fragment>
                            } when={isEditing} />
                        </Col>
                        <Col span={12} className="form-col-width">
                            <h3><FormattedMessage {...messages.Prefix} /></h3>
                            <FormItem >
                                {getFieldDecorator('prefix', {
                                    initialValue: isEditing ? fiscalValue.prefix : null,
                                    rules: [{
                                        required: true, message: intl.formatMessage({ ...messages.prefixEmptyError }),
                                    },
                                    { pattern: REGEX.invoicePrefix, message: intl.formatMessage({ ...messages.prefixFormatError }) }
                                    ],
                                })(
                                    <Input className="form-col-width" />
                                )}
                            </FormItem>

                            <h3><FormattedMessage {...messages.ExpiryDate} /></h3>
                            <FormItem >
                                {getFieldDecorator('expiry', {
                                    initialValue: isEditing ? moment(fiscalValue.expiryDate) : null,
                                    rules: [{
                                        required: true, message: intl.formatMessage({ ...messages.dateEmptyError }),
                                    }
                                    ],
                                })(
                                    <DatePicker className="form-col-width" disabledDate={disabledDate} />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            );
        } else {
            
            const items = [
                {
                    key: '1',
                    label: intl.formatMessage({...messages.Invoice}),
                    children: showInfo(fiscalData.invoice)
                },
                {
                    key: '2',
                    label: intl.formatMessage({...messages.CreditNote}),
                    children: showInfo(fiscalData.credit)
                },
                {
                    key: '3',
                    label: intl.formatMessage({...messages.CashNote}),
                    children: showInfo(fiscalData.debit)
                },
            ];

            return (
                <Spin spinning={isLoading}>
                    <Tabs 
                        defaultActiveKey={activeTab.toString()} 
                        onChange={(activeKey) => onTabChange(activeKey)}
                        items={items}
                    />
                </Spin>
            );
        }
    }

    render() {
        return (
            <React.Fragment>
                <Modal
                    title={this.props.intl.formatMessage({ ...messages.FiscalData })}
                    open={this.props.isVisible}
                    onCancel={this.props.handleCancel}
                    footer={this.state.buttons}
                    autofocusButton
                >
                    {this.modalContent()}
                </Modal>
            </React.Fragment>
        );
    }

}

FiscalData.propTypes = {
    intl: PropTypes.object.isRequired,
    fiscalData: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    isMaster: PropTypes.bool.isRequired,
    isVisible: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    getFiscalData: PropTypes.func.isRequired,
    addFiscalData: PropTypes.func.isRequired,
    editFiscalData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        fiscalData: state.fiscalData.fiscalData,
        isMaster: state.auth.isMaster,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFiscalData: () => dispatch(GetFiscalData()),
        addFiscalData: (newFiscalData) => dispatch(AddFiscalData(newFiscalData)),
        editFiscalData: (updatedFiscalData) => dispatch(EditFiscalData(updatedFiscalData))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(FiscalData)));