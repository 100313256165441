import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl, FormattedMessage} from 'react-intl';
import {Radio} from 'antd';
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

import {connect} from 'react-redux';
import {SetLanguage, FetchLanguage} from '../actions/Int18Actions';

import {getTopMenuMessages} from '../constants/messages';

const messages = getTopMenuMessages();

class LanguageControl extends Component {
	constructor(props) {
		super(props);
		let {
			fetchLanguage,
			user
		} = props;

		if(user.languageId == 2)
			fetchLanguage("es_ES");
	}
	changeLocale = (e) => {
		const localeValue = e.target.value;
		let {setLanguage} = this.props;

		setLanguage(localeValue);
	}
	render() {
		let {
			language,
			size
		} = this.props;

		return (
			<RadioGroup value={language} size={size?size:'large'} onChange={this.changeLocale}>
				<RadioButton value="en_US">
					<FormattedMessage {...messages.en} />
				</RadioButton>
				<RadioButton value="es_ES">
					<FormattedMessage {...messages.es} />
				</RadioButton>
			</RadioGroup>
		);
	}
}

LanguageControl.propTypes = {
	setLanguage: PropTypes.func.isRequired,
	fetchLanguage: PropTypes.func.isRequired,
	language: PropTypes.string.isRequired,
	size: PropTypes.string.isRequired,
	user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
	return {
		language: state.int18.language || state.int18.defaultLanguage,
		user: state.auth.user
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLanguage: (language) => {
			dispatch(SetLanguage(language));
		},
		fetchLanguage: (language) => {
			dispatch(FetchLanguage(language));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LanguageControl));