import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Button } from 'antd';
import { getJobMessages } from '../../constants/messages';

import Client from './Calendar/AdvancedSearch/Components/Client';
import Device from './Calendar/AdvancedSearch/Components/Device';
import CreatingUser from './Calendar/AdvancedSearch/Components/CreatingUser';
import Technicians from './Calendar/AdvancedSearch/Components/Technicians';
import Type from './Calendar/AdvancedSearch/Components/Type';
import Status from './Calendar/AdvancedSearch/Components/Status';
import ProgrammedDate from './Calendar/AdvancedSearch/Components/ProgrammedDate';
import MatchAllCriteria from './Calendar/AdvancedSearch/Components/MatchAllCriteria';

let JobMessages = getJobMessages();

const messages = {
    ...JobMessages,
};

class JobSearchDrawer extends React.Component {

    getIntl = (str, values = {}) => this.props.intl.formatMessage({...messages[str]}, values);

    handleSubmit = () => {
        const { onFilter, form, handleOnClose } = this.props;
        const { validateFieldsAndScroll } = form;
        validateFieldsAndScroll({ force: true }, (err, filterValues) => {
            if (!err) {
                onFilter(filterValues);
                handleOnClose();
            }
        });
    }

    renderSearchButton = () => {
        const { getIntl, handleSubmit } = this;
        return [
            <Button style={{ marginBottom: "10px" }} key="search" type="primary" onClick={handleSubmit} block ghost icon={<SearchOutlined />}>
                {getIntl("search")}
            </Button>
        ];
    }

    renderForm = () => {
        const { form } = this.props;
        return (
            <Form layout='vertical'>
                <Client form={form}/>
                <Device form={form}/>
                <CreatingUser form={form}/>
                <Technicians form={form}/>
                <Type form={form}/>
                <Status form={form}/>
                <ProgrammedDate form={form}/>
                <MatchAllCriteria form={form}/>
            </Form>
        );
    }

	render(){
        const { props, getIntl, renderForm, renderSearchButton } = this;
        const { showDrawer, handleOnClose } = props;
		return (
            <Drawer
                title={getIntl("advancedSearch")}
                placement="right"
                onClose={handleOnClose}
                open={showDrawer}
                destroyOnClose={false}
            >
                {renderForm()}
                {renderSearchButton()}
            </Drawer>
		);
	}
}

JobSearchDrawer.defaultProps = {
    showDrawer: false
};

JobSearchDrawer.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	showDrawer: PropTypes.bool.isRequired,
    handleOnClose: PropTypes.func.isRequired,
    onFilter: PropTypes.func.isRequired
};

export default injectIntl(Form.create()(JobSearchDrawer));