import React from 'react';
import Icon from '@ant-design/icons';

const CameraSvg = () => (
	<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
		width="1.5em" height="1.5em" viewBox="0 0 24.000000 24.000000"
		preserveAspectRatio="xMidYMid meet">

		<g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
			fill="#000000" stroke="none">
			<path d="M85 211 c-6 -5 -21 -11 -35 -13 -24 -3 -25 -6 -25 -78 l0 -75 95 0
95 0 0 75 c0 72 -1 75 -25 78 -14 2 -29 8 -35 13 -12 12 -58 12 -70 0z m70
-21 c3 -5 15 -10 26 -10 17 0 19 -7 19 -60 l0 -60 -80 0 -80 0 0 60 c0 57 1
60 25 60 14 0 25 5 25 10 0 6 13 10 29 10 17 0 33 -4 36 -10z"/>
			<path d="M86 154 c-31 -30 -9 -84 34 -84 24 0 50 26 50 50 0 24 -26 50 -50 50
-10 0 -26 -7 -34 -16z m59 -34 c0 -18 -6 -26 -23 -28 -24 -4 -38 18 -28 44 3
9 15 14 28 12 17 -2 23 -10 23 -28z"/>
		</g>
	</svg>
);

const CameraIconBuilder = props => <Icon component={CameraSvg} {...props} />;

class CameraIcon extends React.Component {
	render() {
		return <CameraIconBuilder />;
	}
}

export default CameraIcon;