import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Modal, Col, Row, Form, Checkbox, Typography, Table, Card, Button, Steps, Select } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { getUnitsMessages, getactionMessages, getFields, getModalMessages, getErrorMessages, getModelsMessages, getLotsMessages, getEmployeeMessages } from '../../constants/messages';
import { CheckCircleFilled, FileExcelOutlined } from '@ant-design/icons';
import { DownloadNotAdded, DownloadLotUnitsTemplate, HandleUploadFile } from '../Lots/actions';
import FormItemUpload from '../GlobalComponents/FormItemUpload';
import { searchInString } from '../../utilities/util';
import moment from 'moment';

const FormItem = Form.Item;
const { Paragraph } = Typography;

let UnitsMessages = getUnitsMessages(),
	ActionMessages = getactionMessages(),
	FieldMessages = getFields(),
	ModalMessages = getModalMessages(),
	ErrorMessages = getErrorMessages(),
	ModelMessages = getModelsMessages(),
    LotsMessages = getLotsMessages(),
    EmployeeMessages = getEmployeeMessages();
 
const messages = {
	...UnitsMessages,
	...ActionMessages,
	...FieldMessages,
	...ModalMessages,
	...ErrorMessages,
	...ModelMessages,
    ...LotsMessages,
    ...EmployeeMessages,
}

const UploadFileModal = ({onCancel, showModal, employees, lots, models, updateLots}) => {
	const intl = useIntl();
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [notAdded, setNotAdded] = useState([]);
	const [current, setCurrent] = useState(0);
    const [hasHeaders, setHasHeaders] = useState(true);
	const [confirmLoading, setConfirmLoading] = useState(false);

	const handleDownloadUnitsTemplate = () => dispatch(DownloadLotUnitsTemplate());
	const handledDownloadNotAdded = () => dispatch(DownloadNotAdded(notAdded));

	const displayPin = (text) => <Paragraph ellipsis={{ tooltip: true }} style={{ width: 150 }}>{`${text}`}</Paragraph>;
	const displayError = (text) => ( <Paragraph ellipsis={{ tooltip: true }} style={{ width: 225 }}>{`${text}`}</Paragraph> );

    const modelsOptions = models.map((model) => ({
		key: model.id,
		value: model.model,
		label: model.model,
	}));

    const employeesOptions = employees.map((employee) => ({
		key: employee.id,
		value: employee.name,
		label: employee.name,
	}));

	const next = () => setCurrent(current + 1);
 
	const onOk = async () => {
		try {
			const values = await form.validateFields();
			setConfirmLoading(true);
            const nomenclatureLot = formatLotNumber(); 
            values.lotNumber = nomenclatureLot;
			const response = await dispatch(HandleUploadFile(values, updateLots));
			next();
			setHasHeaders(true);
			setNotAdded(response.notAdded);
		} catch (error) {
			setCurrent(0);
		} finally {
			setConfirmLoading(false);
		}
	};

    const formatLotNumber = () => {
        const initialLot = '001';
        const [lastLot] = lots.slice(-1);
        if (lastLot && lastLot.lotNumber) {
            const { lotNumber } = lastLot;
            let newLotNumber = (parseInt(lotNumber)+1).toString();
            const numberLot = newLotNumber.substring(newLotNumber.length - 3);
            const prevYear = lotNumber.substring(-1,2);
            const actualYear = moment().format("YY");
            newLotNumber = (actualYear === prevYear) ? moment().format("YYMM") + numberLot : moment().format("YYMM") + initialLot;
            return newLotNumber;
        } else {
            const newLotNumber = moment().format("YYMM") + initialLot;
            return newLotNumber;
        } 
    };

	const columns = [
		{
			title: intl.formatMessage({ ...messages.pin}),
			dataIndex: 'pin',
			key: 'pin',
			render: displayPin,
		},
		{
			title: 'Error',
			dataIndex: 'error',
			key: 'error',
			render: displayError,
		},
	];

    const stepsContent = [
		{
            content: (
				<Form layout='vertical' form={form} preserve={false}>
					<Row type="flex" justify="space-between">
						<Col span={24}>
                            <FormItem 
                                name="model"
                                label={intl.formatMessage({ ...messages.model })} 
                                rules={[
                                    {
                                        required: true, 
                                        message: intl.formatMessage({...messages.modelError}) 
                                    }
                                ]} 
                            >
                                <Select
                                    showSearch
                                    placeholder={intl.formatMessage({ ...messages.modelPlaceholder })}
                                    filterOption={(input, option) => searchInString(input, option.label)}
                                    options={[
                                        {
                                          value: 'Varios',
                                          label: intl.formatMessage({ ...messages.several }),
                                        }, 
                                        ...modelsOptions
                                    ]}
                                />
                            </FormItem>
						</Col>
					</Row>
                    <Row type="flex" justify="center" align="bottom">
                        <Col span={24}>
                            <FormItem 
                                name="employee"
                                label={intl.formatMessage({ ...messages.onCharge })} 
                                rules={[
                                    {
                                        required: true, 
                                        message: intl.formatMessage({...messages.onChargeError}) 
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder={intl.formatMessage({ ...messages.employees })}
                                    filterOption={(input, option) => searchInString(input, option.label)}
                                    options={employeesOptions}
                                />
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <FormItemUpload
                                intl={intl}
                                form={form}
                                fieldName={'file'}
                                label={intl.formatMessage({...messages.file})}
                                required={true}
                                accept={'.csv, .xlsx'}
                                listType={'text'}
                                dragger={true}
                            />
                        </Col>
                    </Row>
                    <Row type="flex" justify="space-between">
                        <Col span={9}>
                            <FormItem name="hasHeaders" valuePropName="checked"	initialValue={hasHeaders}>
                                <Checkbox checked={hasHeaders} onChange={(e) => setHasHeaders(e.target.checked)} >
                                    <FormattedMessage {...messages.hasHeaders} />
                                </Checkbox>
                            </FormItem>
                        </Col>
                        <Col span={4}>
                            <FormItem>
                                <Typography.Link onClick={handleDownloadUnitsTemplate} >
                                    <FileExcelOutlined /> {intl.formatMessage({...messages.template})}
                                </Typography.Link>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
			),
			title: intl.formatMessage({...messages.upload}),
		},
		{
			content:
				notAdded.length !== 0 ? (
					<div>
						<div style={{ textAlign: 'right', paddingTop: 20 }}>
							<Typography.Link onClick={handledDownloadNotAdded}>
								<FileExcelOutlined /> {intl.formatMessage({...messages.download})}
							</Typography.Link>
						</div>
						<Table
							rowKey={(record) => record.key}
							dataSource={notAdded}
							columns={columns}
							size={'small'}
							pagination={{
								pageSize: 5,
								showTotal: (total) => intl.formatMessage({...messages.tableTotal}, {total}),
							}}
						/>
					</div>
				) : (
					<Card bordered={false}>
						<Row type="flex" justify="center">
							<Col span={24} className="uploadContent">
								<CheckCircleFilled className="uploadIcon" />
							</Col>
							<Col span={24} className="uploadContent">
								<h2>
									<FormattedMessage {...messages.uploadSuccess} />
								</h2>
								<p>
									<FormattedMessage {...messages.uploadSuccessMessage} />
								</p>
							</Col>
						</Row>
					</Card>
				),
			title: intl.formatMessage({...messages.uploadResult}),
		},
    ];

    const closeModal = () => {
		setCurrent(0);
		setNotAdded([]);
		onCancel();
	};

	let buttonGroup =
		current === 0 ? (
			<Button key="next" loading={confirmLoading} type="primary" onClick={onOk}>
				{intl.formatMessage({...messages.uploadFileButton})}{' '}
			</Button>
		) : (
			<Button key="done" type="primary" onClick={closeModal}>
				{intl.formatMessage({...messages.close})}
			</Button>
		);

    return (
        <Modal
            title={intl.formatMessage({...messages.upload})}
            open={showModal}
            onCancel={closeModal}
            destroyOnClose={true}
			confirmLoading={confirmLoading}
			footer={buttonGroup}
        >
			<Steps current={current} items={stepsContent} />
		    <div className="steps-content">{stepsContent[current].content}</div>
        </Modal>
    )
}

UploadFileModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
	showModal: PropTypes.bool,
	updateUnits: PropTypes.func,
	models: PropTypes.array,
    employees: PropTypes.array,
    lots: PropTypes.array,
	updateLots: PropTypes.func,
};

export default UploadFileModal
