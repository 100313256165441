import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { Modal, Select, Row, Col, message, Form } from 'antd';
import { LoadingOutlined, LinkOutlined } from '@ant-design/icons';
import { getJobMessages, getFields, getErrorMessages } from '../../../constants/messages';
import { MarkJobsInvoiced } from '../actions';

const FormItem = Form.Item;
const Option = Select.Option;

let JobsMessages = getJobMessages(),
    ErrorMessages = getErrorMessages(),
    fieldMessages = getFields();

const messages = {
	...JobsMessages,
	...fieldMessages,
    ...ErrorMessages,
};

const LinkJobModal = ({handleCancel, showModal, selectedJobs, invoicesToLink, clearSelectedRows}) => {
    const intl = useIntl();
	const [form] = Form.useForm();
    const [isSaving, setSaving] = useState(false);
   
    const handleOk = async () => {
        try {
            setSaving(true);
            const values = await form.validateFields();
            await MarkJobsInvoiced(selectedJobs, values.invoiceId)
            message.success(intl.formatMessage(({...messages.linkJobsSuccess})));
            handleCancel();
            clearSelectedRows(true)
        } catch (error) {
            message.error(intl.formatMessage(({...messages.linkJobsError})))
        } finally {
            setSaving(false);
        }
	};

    const invoicesOption = invoicesToLink.map((invoice) => (
        <Option key={invoice.id} value={invoice.id}>
            {invoice.documentNumber}
        </Option>
    ));

    return (
        <Modal
            open={showModal}
            title={intl.formatMessage( {...messages.linkJobs})}
            onOk={handleOk}
            onCancel={handleCancel}
            destroyOnClose={true}
            okText={
                <span>
                    {isSaving ? <LoadingOutlined /> : <LinkOutlined />} {<FormattedMessage {...messages.link} />}
                </span>
            }
        >
            <Form form={form} layout='vertical' className="col-8" preserve={false}>
                <Row type="flex" justify="center" align="bottom">
                    <Col span={24}>
                        <FormItem 
                            name="invoiceId" 
                            label={intl.formatMessage({ ...messages.invoices })} 
                            rules={[
                                {
                                    required: true, 
                                    message: intl.formatMessage({...messages.invoicedNumberError}) 
                                }
                            ]} 
                        >
                            <Select
                                showSearch
                                placeholder={intl.formatMessage({ ...messages.documentNumber })}
                                optionFilterProp="children"
                            >
                                {invoicesOption}
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

LinkJobModal.propTypes = {
    handleCancel: PropTypes.func.isRequired,
    showModal: PropTypes.bool,
    selectedJobs: PropTypes.array,
    invoicesToLink: PropTypes.array,
    clearSelectedRows: PropTypes.func,
};

export default LinkJobModal;
