import axios from 'axios';
import {
	isEmpty
} from '../../utilities/util';
import {
	GET_EXCHANGE_RATE, GET_EXCHANGE_RATES, ADD_EXCHANGE_RATE
} from './actionTypes';
let moment = require('moment');

export const GetExchangeRate = () => (dispatch) => {
	let filter = {
		"order": "createdAt DESC",
		"limit": 1
	};

	return axios.get(`/ExchangeRates?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			dispatch({ type: GET_EXCHANGE_RATE, exchangeRate: response.data[0] });
		});
};

export const ReturnExchangeRate = () => {
	const filter = {
		order: "createdAt DESC",
		limit: 1
	};

	return axios.get(`/ExchangeRates?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then(response => response.data[0].rate);
};

export const GetExchangeRates = () => (dispatch) => {
	let filter = {
		"order": "createdAt DESC",
		"include": "account"
	};

	return axios.get(`/ExchangeRates?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			dispatch({ type: GET_EXCHANGE_RATES, exchangeRates: response.data });
		});
};

export const AddExchangeRate = (exchangeRate) => (dispatch) => {
	return axios.post('/ExchangeRates', exchangeRate)
		.then(() => {
			dispatch({ type: ADD_EXCHANGE_RATE });
		});
};

export const AddExchangeRateLatest = (exchangeRate) => (dispatch) => {
	return axios.post('/ExchangeRates/reuseExchangeRate', { exchangeRate })
		.then(() => {
			dispatch({ type: ADD_EXCHANGE_RATE });
		});

};

export const SearchExchangeRates = (search) => (dispatch) => {

	let filter = {
		"order": "createdAt DESC",
		"include": "account"
	};

	if (!isEmpty(search.startDate)) {
		filter = {
			...filter,
			"where": {
				"createdAt": { "between": [moment(search.startDate).startOf('day').toISOString(), moment(search.endDate).endOf('day').toISOString()] }
			}
		};
	}

	return axios.get(`/ExchangeRates?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			dispatch({ type: GET_EXCHANGE_RATES, exchangeRates: response.data });
		});

};