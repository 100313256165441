import React from 'react';
import PropTypes from 'prop-types';
import { Table, Badge } from 'antd';
import { injectIntl } from 'react-intl';
import { getDeviceMessages} from '../../../../constants/messages';
import DeviceRender from './DeviceRender';

let deviceMessages = getDeviceMessages();

const messages = {
	...deviceMessages
};

class ClientTable extends React.Component  {

	badgeCreator = (active) => {
		const {intl} = this.props;
		let badgeProps = {
			status: "default",
			text: intl.formatMessage({...messages.inactive})
		};
		if (active)
			badgeProps = {
				status: "success",
				text: intl.formatMessage({...messages.active})
			};
		return (<Badge {...badgeProps} />);
	}

    renderExpandedRow = (record) => {
        return <DeviceRender device={record} hideClients bordered={false}/>;
    }

    renderTable = () => {
        const {isLoading, page, pageSize, dataSource, count, onPage} = this.props;
        const {intl} = this.props;
		const pagination = {
			current: page,
			defaultPageSize: pageSize,
			onChange: onPage,
			total: count
        };
        const columns = [
            {
                title: intl.formatMessage({...messages.pin}),
                dataIndex: 'pin',
                key: 'pin'
            },
            {
                title: intl.formatMessage({...messages.description}),
                dataIndex: 'description',
                key: 'description'
            },
            {
                title: intl.formatMessage({...messages.state}),
                dataIndex: 'active',
                key: 'active',
				render: (active) => this.badgeCreator(active)
            }
        ];
        return (
            <Table
                style={{paddingTop:"10px"}}
                rowKey={record => record.id}
                columns = {columns}
                dataSource = {dataSource}
                pagination={pagination}
                loading={isLoading}
                expandedRowRender={this.renderExpandedRow}
                scroll={{ x: '100%' }}
                bordered
            />
        );
    }

	render(){
        return(this.renderTable());
	}
}
 
ClientTable.defaultProps = { 
    dataSource: [],
    count: 0,
    isLoading: false
};

ClientTable.propTypes = {
    intl: PropTypes.object.isRequired,
    onPage: PropTypes.func.isRequired,
    dataSource: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
};

export default injectIntl(ClientTable);