import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { Button, List, Col, Divider, Row } from 'antd';
import { injectIntl } from 'react-intl';
import { getReportMessages, getIncomeMessages } from '../../../constants/messages';
import React from 'react';
import CategoryEntry from './CategoryEntry';


let reportMessages = getReportMessages(),
    incomeMessages = getIncomeMessages();

const messages = {
    ...reportMessages,
    ...incomeMessages
};

class ItemListHandler extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItems: [],
            deleteDisabled: true,
        }
    }

    componentDidMount() {
        let arrays = this.processArrays(this.props.entryCategories);
        this.props.form.getFieldDecorator('amount', { initialValue: arrays.amounts });
        let details = this.props.joinArrays(arrays.categories, arrays.amounts, arrays.comments);
        if (details.length < 1)
            details.push({ categoryId: null, amount: 1, comment: "" })
        this.UpdateItemList(details);
    }

    processArrays = (entryCategories) => {
        let categories = [];
        let amounts = [];
        let comments = [];

        if (entryCategories.length < 1)
            return [], [], [];
        entryCategories.forEach(entryCat => {
            categories.push(entryCat.categoryId);
            amounts.push(entryCat.amount);
            comments.push(entryCat.description);
        });
        return { categories, amounts, comments };
    }

    addItem = () => {
        let total = this.props.form.getFieldValue('total');
        if (total === undefined || total < 1)
            return;
        let currAmount = this.props.getCurrentAmount();
        currAmount = currAmount < 0 ? 0 : currAmount;
        let itemBack = { categoryId: null, amount: currAmount, comment: "" };
        let categories = this.state.selectedItems;
        categories.push(itemBack);
        this.UpdateItemList(categories);
    }

    updateAmount = (amount, index) => {
        let cats = this.state.selectedItems;
        cats[index].amount = amount;
        this.UpdateItemList(cats);
    }

    printHeaders = () => {
        return (
            <Row type="flex" justify="center">
                <Col span={12}>
                    {this.props.intl.formatMessage({ ...messages.category })}
                </Col>
                <Col span={12}>
                    {this.props.intl.formatMessage({ ...messages.amount })}
                </Col>
            </Row>
        )
    }

    removeAt = (index) => {
        this.remove(index, 'categories');
        this.remove(index, 'amounts');
        let categories = this.props.details.filter((key, i) => i !== index);
        this.UpdateItemList(categories);
    }

    remove = (k, field) => {
        const { form } = this.props;
        const keys = form.getFieldValue(field);
        if (keys.length === 1)
            return;

        form.setFieldsValue({
            [field]: keys.filter((key, index) => index !== k),
        });
    }

    UpdateItemList(categories) {
        this.setState({
            selectedItems: categories,
            deleteDisabled: categories.length <= 1
        });
        this.props.updateItems(categories);
    }

    render() {
        return (
            <div>
                <Divider>Registros</Divider>
                {this.printHeaders()}
                <Divider dashed />
                <Col span={24} style={{ paddingBottom: "10px" }}>
                    <List
                        dataSource={this.state.selectedItems}
                        className="itemListDisplay"
                        renderItem={(item, index) => {
                            return (<List.Item >
                                <CategoryEntry
                                    item={item}
                                    index={index}
                                    form={this.props.form}
                                    removeAt={this.removeAt}
                                    listAmount={parseFloat(item.amount)}
                                    updateAmount={this.updateAmount}
                                    deleteDisabled={this.state.deleteDisabled}
                                    filterCategories={this.filterCategories}
                                    currencyDisplayer={this.props.currencyDisplayer}
                                />
                            </List.Item>)
                        }}
                    />
                    <Button key="addItem" type="dashed" size="default" onClick={this.addItem} style={{ width: '100%' }}>
                        <PlusOutlined />
                        {this.props.intl.formatMessage({ ...messages.add })}
                    </Button>
                </Col>
            </div>
        );
    }

}

ItemListHandler.propTypes = {
    intl: PropTypes.object.isRequired,
    details: PropTypes.array.isRequired,
    entryCategories: PropTypes.array.isRequired,
    form: PropTypes.object.isRequired,
    currencyDisplayer: PropTypes.object.isRequired,
    updateItems: PropTypes.func.isRequired,
    joinArrays: PropTypes.func.isRequired,
    getCurrentAmount: PropTypes.func.isRequired,
};


export default injectIntl((ItemListHandler));