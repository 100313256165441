export const GET_PROFORMAS = 'GET_PROFORMAS';
export const FETCH_PROFORMA_COUNT = 'FETCH_PROFORMA_COUNT';
export const ADD_PROFORMA_SUCCESS = 'ADD_PROFORMA_SUCCESS';
export const GET_PROFORMA = 'GET_PROFORMA';
export const SET_PROFORMA_TO_ADD = 'SET_PROFORMA_TO_ADD';
export const APPROVE_PROFORMA_SUCCESS = 'APPROVE_PROFORMA_SUCCESS';
export const DENY_PROFORMA_SUCCESS = 'DENY_PROFORMA_SUCCESS';
export const PROFORMA_INVOICED_SUCCESS = 'PROFORMA_INVOICED_SUCCESS';
export const STATUS_FILTER = 'STATUS_FILTER';
export const CLEAR_SESSION = 'CLEAR_SESSION';
export const CLEAN_REDUCER = 'CLEAN_REDUCER';
export const UPDATE_SEARCH = 'UPDATE_SEARCH_PROFORMA';
export const UPDATE_PAGE = 'UPDATE_PAGE_PROFORMA';
