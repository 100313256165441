import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Statistic, Row, Col, Card, Popover } from 'antd';
import { getDashboardMessages } from '../../../constants/messages';
import { isMobile } from '../../../utilities/util';
import { ArrowDownOutlined, ArrowUpOutlined, SwapOutlined } from '@ant-design/icons';

let dashboardMessages = getDashboardMessages();

const messages = {
	...dashboardMessages
};

const negativeColor = '#cf1322';
const neutralColor = 'grey';
const positiveColor = '#3f8600';

const getPercentageChange = (oldNumber, newNumber) => {
    let decreaseValue = oldNumber - newNumber;
    let percentage = (decreaseValue / (oldNumber === 0 ? 1 : oldNumber)) * 100;
    return percentage * -1;
}

class StatisticComparison extends React.Component {

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});
    
    renderStatistic = () => {
        const { props } = this;
        const { title, currentValue, icon, currentValuePopover } = props;
        const renderMobile = isMobile();
        const prefix = 'icon' in props ? icon : undefined;
        const statistic = <Statistic title={title} value={currentValue} prefix={prefix} />;
        if (currentValuePopover) {
            const trigger = renderMobile ? "click" : "hover";
            const mouseEnterDelay = renderMobile ? 0 : 0.6;
            return <Popover content={currentValuePopover} trigger={trigger} mouseEnterDelay={mouseEnterDelay}><span>{statistic}</span></Popover>;
        }
        return statistic;
    }
    
    renderComparison = () => {
        const { props, getIntl } = this;
        const { lastValue, currentValue, reverseComparisonColors } = props;
        const percentage = getPercentageChange(lastValue, currentValue);
        const color = percentage === 0 ? neutralColor : percentage > 0 ? reverseComparisonColors ? negativeColor : positiveColor : reverseComparisonColors ? positiveColor : negativeColor;
        const prefix = percentage === 0 ? <SwapOutlined rotate={90}/> :percentage > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />;
        return (
            <Statistic
                title={getIntl("monthDifference")}
                value={percentage}
                precision={2}
                valueStyle={{ color }}
                prefix={prefix}
                suffix="%"
            />
        );
    }

    fullRender = () => {
        const { props, renderStatistic, renderComparison } = this;
        const { extraContent } = props;
        const renderMobile = isMobile();
        const cardSize = renderMobile ? "small" : "default";
        return (
            <div className="statistic-comparison-main-card">
                <Row gutter={2} type="flex">
                    <Col span={12}><Card className="dashboard-full-height" size={cardSize} bordered={false}>{renderStatistic()}</Card></Col>
                    <Col span={12}><Card className="dashboard-full-height" size={cardSize} bordered={false}>{renderComparison()}</Card></Col>
                    {extraContent ? <Col span={24}><Card className="dashboard-statistic-extra-content" size={cardSize} bordered={false}>{extraContent}</Card></Col> : null}
                </Row>
            </div>
        );
    }

	render() {
		return this.fullRender();
    }
    
}

StatisticComparison.defaultProps = {
    lastValue: 0,
    currentValue: 0,
    reverseComparisonColors: false
};

StatisticComparison.propTypes = {
    intl: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    lastValue: PropTypes.number.isRequired,
    currentValue: PropTypes.number.isRequired,
    icon: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object
    ]),
    currentValuePopover: PropTypes.object,
    extraContent: PropTypes.object,
    reverseComparisonColors: PropTypes.bool
};

export default injectIntl(StatisticComparison);