import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select } from 'antd';
import { injectIntl } from 'react-intl';
import { getDeviceMessages } from '../../constants/messages';
import { DEVICESTATUS } from '../Jobs/constants';
import { existsInArray } from '../../utilities/util';
import IconRender from './Finder/Renders/IconRender';
const { Option, OptGroup } = Select;
const FormItem = Form.Item;

let deviceMessages = getDeviceMessages();

const messages = {
	...deviceMessages
};

class DevicePicker extends React.Component {

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });

	buildDeviceName = (device) => {
		const { props, getIntl } = this;
		const { extraRender } = props;
		const pin = device.pin;
		const description = device.description || getIntl("noDescription");
		const icon = <IconRender icon={device.icon} renderName={false} />;
		const text = `${pin} - ${description}`
		return <span>{icon} {text} {extraRender(device)}</span>
	}

	handleDeviceFilter = (input, option) => {
		const { key, props } = option;
		const optionGroups = ["active", "hidden", "suspended", "cancelled", "others"];
		if (existsInArray(key, optionGroups)) return false;
		const children = props.children;
		const lowerCase = (str) => str.toLowerCase().indexOf(input.toLowerCase()) >= 0;
		return lowerCase(children.props.children[2]);
	}

	buildDeviceOptions = () => {
		const { props, getIntl, buildDeviceName } = this;
		const { devices } = props;
		const options = [], active = [], hidden = [], suspended = [], cancelled = [], others = [];
		devices.forEach(device => {
			const { deviceStatusId } = device;
			const option = <Option key={device.id} value={device.id}>{buildDeviceName(device)}</Option>;
			switch (deviceStatusId) {
				case DEVICESTATUS.ACTIVE:
					active.push(option);
					break;
				case DEVICESTATUS.HIDDEN:
					hidden.push(option);
					break;
				case DEVICESTATUS.SUSPENDED:
					suspended.push(option);
					break;
				case DEVICESTATUS.CANCELLED:
					cancelled.push(option);
					break;
				default:
					others.push(option);
					break;
			}
		});
		options.push(<OptGroup key="active" label={getIntl("active")}>{active}</OptGroup>);
		options.push(<OptGroup key="hidden" label={getIntl("hidden")}>{hidden}</OptGroup>);
		options.push(<OptGroup key="suspended" label={getIntl("suspended")}>{suspended}</OptGroup>);
		options.push(<OptGroup key="cancelled" label={getIntl("cancelled")}>{cancelled}</OptGroup>);
		options.push(<OptGroup key="others" label={getIntl("others")}>{others}</OptGroup>);
		return options;
	}

	renderDevicePicker = () => {
		const { props, getIntl, handleDeviceFilter, buildDeviceOptions } = this;
		const { form, fieldName, rules, maxTagCount, devicesSelected } = props;
		const { getFieldDecorator } = form;
		const options = buildDeviceOptions();
		return (
			<FormItem>
				{
					getFieldDecorator(fieldName,
						{
							initialValue: devicesSelected,
							rules
						}
					)(
						<Select
							className="job-full-component"
							mode="multiple"
							maxTagCount={maxTagCount}
							placeholder={getIntl("devices")}
							showSearch
							optionFilterProp="children"
							filterOption={handleDeviceFilter}
							notFoundContent={getIntl("notFound")}
							dropdownMatchSelectWidth={false}
						>
							{options}
						</Select>
					)
				}
			</FormItem>
		);
	}

	render() {
		return this.renderDevicePicker();
	}
}

DevicePicker.defaultProps = {
	devices: [],
	fieldName: "devices",
	rules: [],
	maxTagCount: 3,
	extraRender: () => {},
	devicesSelected: []
};

DevicePicker.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	devices: PropTypes.array,
	fieldName: PropTypes.string,
	rules: PropTypes.array,
	maxTagCount: PropTypes.number,
	extraRender: PropTypes.func,
	devicesSelected: PropTypes.array
};

export default injectIntl(DevicePicker);
