import React from "react";
import { message } from "antd";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { getDepartmentMessages, getFields } from "../../../constants/messages";
import {
  GetWorkRoles,
  GetWorkRoleCount,
  EditWorkRole,
  AddWorkRole,
  DeleteWorkRoles,
} from "../actions";
import CommonRender from "./CommonRender";
import WorkRoleForm from "./WorkRoleForm";

let departmentMessages = getDepartmentMessages(),
  fieldMessages = getFields();

const messages = {
  ...departmentMessages,
  ...fieldMessages,
};

const WorkRoleRender = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const workRoles = useSelector((state) => state.department.workRoles);
  const workRoleCount = useSelector((state) => state.department.workRoleCount);
  const search = useSelector((state) => state.department.searchTextWork);
   
    const columns =[
      {
        title: `${intl.formatMessage({ ...messages.name })}`,
        dataIndex: "name",
        key: "name",
      },
      {
        title: `${intl.formatMessage({ ...messages.department })}`,
        dataIndex: ["department", "name"],
        key: "department.name",
      },
    ];

  const deleteWorkRoles = async (ids) => {
    try {
      return await DeleteWorkRoles(ids);
    } catch (error) {
      message.error(intl.formatMessage({ ...messages.deleteWorkRoleError }));
    }
  };

  const addWorkRole = async (department) => {
    try {
      await dispatch(AddWorkRole(department));
    } catch (error) {
      message.error(intl.formatMessage({ ...messages.addWorkRoleError }));
    }
  };

  const editWorkRole = async (department, id) => {
    try {
      await dispatch(EditWorkRole(department, id));
    } catch (error) {
      message.error(intl.formatMessage({ ...messages.editWorkRoleError }));
    }
  };

  const getWorkRoles = async (search, page, pageSize) => {
    try {
      await dispatch(GetWorkRoles(search, page, pageSize));
    } catch (error) {
      message.error(intl.formatMessage({ ...messages.getWorkRolesError }));
    }
  };

  const getWorkRoleCount = async (search) => {
    try {
      await dispatch(GetWorkRoleCount(search));
    } catch (error) {
      message.error(intl.formatMessage({ ...messages.getWorkRoleCountError }));
    }
  };

  const CardBodyRender = (record) => {
    return (
      <div>
        <strong>{intl.formatMessage({ ...messages.department })} </strong>
        {record.department.name}
      </div>
    );
  };

  return (
    <CommonRender
      searchText={search}
      columns={columns}
      records={workRoles}
      getRecords={getWorkRoles}
      recordCount={workRoleCount}
      getRecordCount={getWorkRoleCount}
      deleteRecords={deleteWorkRoles}
      cardBodyRender={CardBodyRender}
      add={addWorkRole}
      update={editWorkRole}
      renderFormFunc={(form, record) => (
        <WorkRoleForm form={form} workRole={record} />
      )}
      addSuccessMessage={intl.formatMessage({ ...messages.addWorkRoleSuccess })}
      updateSuccessMessage={intl.formatMessage({
        ...messages.editWorkRoleSuccess,
      })}
    />
  );
};

export default WorkRoleRender;
