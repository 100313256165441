import {
    INIT_REQUEST_JOB,
    END_REQUEST_JOB,
    FETCH_JOB,
    FETCH_JOB_COUNT,
	FETCH_JOB_TECHNICIANS,
	FETCH_JOB_ICONS,
	JOB_UPDATE_CALENDAR_EVENTS,
	UPDATE_JOB_FILTER,
	FECTH_SELECT_JOBS,
	CHECK_IN_INVOICE_JOBS,
	CHECK_IN_PROFORMA_JOBS,
	SET_SEARCH_TABLE,
	
} from './actionTypes';

import {
    CLEAR_SESSION,
	CLEAN_REDUCER
} from '../../constants/actionTypes';

const initState = {
	jobs: [],
	isLoading: false,
	searchTable:"",
	count: 0,
	technicianList: [],
	icons: [],
	calendarEvents: [],
	searchFilter: {},
	selectedJobs: [],
	invoiceJobs: false,
	proformaJobs: false,
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case FECTH_SELECT_JOBS:
			return {
				...state,
				selectedJobs: [...action.selectedJobs],
			};
		case CHECK_IN_INVOICE_JOBS:
			return {
				...state,
				invoiceJobs: action.invoiceJobs,
			};
		case CHECK_IN_PROFORMA_JOBS:
			return {
				...state,
				proformaJobs: action.proformaJobs,
			};
		case UPDATE_JOB_FILTER:
			return {
				...state,
				searchFilter: action.searchFilter,
			};
		case JOB_UPDATE_CALENDAR_EVENTS:
			return {
				...state,
				calendarEvents: action.calendarEvents,
			};
		case FETCH_JOB_ICONS:
			return {
				...state,
				icons: action.icons,
			};
        case FETCH_JOB_TECHNICIANS:
			return {
				...state,
				technicianList: action.technicianList
			};
        case FETCH_JOB:
			return {
				...state,
				jobs: action.jobs
			};
        case FETCH_JOB_COUNT:
            return {
                ...state,
                count: action.count
			};
        case INIT_REQUEST_JOB:
			return {
				...state,
				isLoading: true
			};
		case END_REQUEST_JOB:
			return {
				...state,
				isLoading: false
            };
		case SET_SEARCH_TABLE:
			return{
				...state,
				searchTable:action.searchTable
			}
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;
