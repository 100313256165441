import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Button, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { getErrorMessages, getModalMessages } from '../../constants/messages';
import { AddContact } from './actions';
import AddClientContactModal from './AddClientContactModal';

let modalMessages = getModalMessages(),
    errorMessages = getErrorMessages();

const messages = {
    ...modalMessages,
    ...errorMessages,
};

const AddContactButton = () => {
    const intl = useIntl();
	const dispatch = useDispatch();
	const [isVisible, setIsVisible] = useState(false);

    const addContact = async (contact) => {
        try {
            await dispatch(AddContact(contact))
        } catch (error) {
			message.error(intl.formatMessage({...message.commonError}));
        } finally {
            setIsVisible(false);
        }
    }

    return (
        <React.Fragment>
            <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => setIsVisible(true)}
            >
                {intl.formatMessage({ ...messages.add })}
            </Button>
            <AddClientContactModal
                visible={isVisible}
                handleCancel={() => setIsVisible(false)} 
                onOk={(contact) => addContact(contact)}
            />
        </React.Fragment>

    )
}

export default AddContactButton;
