import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import { injectIntl } from 'react-intl';
import { getJobMessages } from '../../../../constants/messages';
import { getNestedValue } from '../../../../utilities/util';

let jobMessages = getJobMessages();

const messages = {
	...jobMessages
};

class LeasedDeviceWarning extends React.Component {

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    renderWarning = () => {
        const { props, getIntl } = this;
        const { device } = props;
        const deviceLease = getNestedValue("deviceLease", device) === undefined ? false : true;
        if (deviceLease) return <Alert className="job-leased-device-warning" type="warning" message={getIntl("leasedDevice")} description={getIntl("deviceReminder")} />;
        return null;
    }
    
	render() {
        return this.renderWarning();
    }
}

LeasedDeviceWarning.defaultProps = {
    device: {}
};

LeasedDeviceWarning.propTypes = {
    intl: PropTypes.object.isRequired,
    device: PropTypes.object
};

export default injectIntl(LeasedDeviceWarning);