import axios from 'axios';
import {
	SET_LANGUAGE,
	LOG_IN
} from '../constants/actionTypes';
import {locale} from '../constants/global';
import {
	ShowErrors
} from '../utilities/util';

export const FetchLanguage = (language) =>
	(dispatch) => {
		dispatch({
			type: SET_LANGUAGE,
			language: language,
			messages: locale[language].messages
		});
	};

export const SetLanguage = (language) => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;
	let user = {
		languageId: state.auth.user.languageId
	};
	let id = state.auth.user.id;
	let lang = state.language.languageFilter;

	if(language == "en_US")
		user.languageId = lang[0].id;
	else if(language == "es_ES")
		user.languageId = lang[1].id;

	dispatch(FetchLanguage(language));
	axios.patch(`Accounts/${id}`, user)
	.then(()=>{
		dispatch({
			type: LOG_IN,
			user: user
		});
		window.location.reload();
	})
	.catch( (error) => {
		if(language == "en_US"){
			dispatch(FetchLanguage("es_ES"));
		}else if(language == "es_ES"){
			dispatch(FetchLanguage("en_US"));
		}
		ShowErrors(error, dispatch, messages);
	});

};