import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { message, Descriptions, Table } from 'antd';
import { getCommissionMessages, getLanguageIntl, getFields, getInvoicingMessages } from '../../constants/messages';
import { LoadCommission } from './actions';
import { displayCurrency, getNestedValue } from '../../utilities/util';
import moment from 'moment';
import Embolden from '../GlobalComponents/Embolden';
import EllipsisTooltip from '../../components/EllipsisTooltip';
import CommissionBadge from './CommissionBadge';
import { STATUS } from './constants';
const { PENDING } = STATUS;
const DescriptionItem = Descriptions.Item;

let intlMessages = getLanguageIntl(),
	fieldMessages = getFields(),
	invoicingMessages = getInvoicingMessages(),
	commissionMessages = getCommissionMessages();

const messages = {
	...intlMessages,
	...fieldMessages,
	...commissionMessages,
	...invoicingMessages
};

class View extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			commission: {}
		};
	}

	componentDidMount() {
		const { props, getIntl } = this;
		const { commissionId, setLoading, setShowButtons, user } = props;
		if (commissionId) {
			setLoading(true);
			LoadCommission(commissionId)
				.then(commission => {
					setLoading(false);
					const { status, salesPersonId } = commission;
					const { id: userId } = user;
					setShowButtons(status === PENDING && salesPersonId === userId);
					this.setState({ commission });
				})
				.catch(() => {
					setLoading(false);
					message.error(getIntl("loadCommissionError"));
				});
		}
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	renderEmboldenIntl = (headerIntl, value) => {
		const { getIntl } = this;
		return <Embolden header={getIntl(headerIntl)} value={value} row />;
	}

	formatDate = (unformatedDate, utc = false) => {
		if (!unformatedDate) return undefined;
		const { getIntl } = this;
		const locale = getIntl("intl");
		if (utc) return moment(unformatedDate).utc().locale(locale).format('MMMM DD, YYYY');
		return moment(unformatedDate).locale(locale).format('MMMM DD, YYYY');
	}

	renderCommissionBasicInfo = () => {
		const { state, props, getIntl, formatDate, renderEmboldenIntl } = this;
		const { commission } = state;
		const { isMaster } = props;
		const salespersonName = getNestedValue("salesPerson.name", commission);
		const creatorName = getNestedValue("creatorAccount.name", commission);
		const creationDate = getNestedValue("creationDate", commission);
		const status = getNestedValue("status", commission);
		const paidDate = getNestedValue("paidDate", commission);
		const paidComment = getNestedValue("paidComment", commission);
		const editComment = getNestedValue("editComment", commission);
		const sourceAccount = getNestedValue("bankAccount.name", commission);
		return (
			<DescriptionItem label={getIntl("generalInfo")}>
				{renderEmboldenIntl("salesperson", salespersonName)}
				{renderEmboldenIntl("creationDate", formatDate(creationDate))}
				{renderEmboldenIntl("creator", creatorName)}
				{renderEmboldenIntl("status", <CommissionBadge status={status} />)}
				{isMaster ? (
					<div>
						{sourceAccount ? renderEmboldenIntl("sourceAccount", sourceAccount) : null}
						{paidDate ? renderEmboldenIntl("paidDate", formatDate(paidDate, true)) : null}
						{paidComment ? renderEmboldenIntl("bankReferenceComment", paidComment) : null}
						{editComment ? renderEmboldenIntl("editComment", editComment) : null}
					</div>
				) : null}
			</DescriptionItem>
		);
	}

	renderInvoiceEntries = () => {
		const { state, getIntl, formatDate } = this;
		const invoiceEntries = getNestedValue("commission.invoiceEntries", state) || [];
		const dataSource = invoiceEntries.map(entry => ({ ...entry, key: entry.invoiceId }));
		const columns = [
			{
				title: getIntl("InvoiceNumber"),
				dataIndex: ['invoice', 'documentNumber'],
				key: 'documentNumber'
			},
			{
				title: getIntl("Client"),
				dataIndex: ['invoice', 'clientName'],
				key: 'clientName',
				width: 200,
				onCell: () => {
					return {
						style: {
							whiteSpace: 'nowrap',
							maxWidth: 200,
						}
					}
				},
				render: (clientName) => {
					return (
						<EllipsisTooltip title={clientName}>{clientName}</EllipsisTooltip>
					);
				}
			},
			{
				title: getIntl("date"),
				dataIndex: ['invoice', 'createdAt'],
				key: 'createdAt',
				render: (date) => formatDate(date)
			},
			{
				title: getIntl("commission"),
				dataIndex: 'amount',
				key: 'amount',
				render: (amount) => displayCurrency(amount || 0)
			}
		];
		return (
			<DescriptionItem label={getIntl("invoices")}>
				<Table columns={columns} dataSource={dataSource} pagination={false} />
			</DescriptionItem>
		);
	}

	renderBonus = () => {
		const { state, getIntl, renderEmboldenIntl } = this;
		const { commission = {} } = state;
		const { bonusAmount = 0, bonusDescription = null } = commission;
		if (!bonusAmount && !bonusDescription) return null;
		return (
			<DescriptionItem label={getIntl("bonus")}>
				{bonusDescription ? renderEmboldenIntl("bonusDescription", bonusDescription) : null}
				{renderEmboldenIntl("bonus", displayCurrency(bonusAmount || 0))}
			</DescriptionItem>
		);
	}

	renderTotal = () => {
		const { state, getIntl } = this;
		const { commission = {} } = state;
		const { invoiceEntries = [], bonusAmount = 0 } = commission;
		let total = invoiceEntries.reduce((sum, { amount }) => sum + amount, 0) + bonusAmount;
		total = parseFloat(total.toFixed(2));
		return <DescriptionItem label={getIntl("total")}>{displayCurrency(total || 0)}</DescriptionItem>;
	}


	renderDescription = () => {
		const { renderCommissionBasicInfo, renderInvoiceEntries, renderBonus, renderTotal } = this;
		return (
			<Descriptions layout="vertical" size="small" bordered column={1} >
				{renderCommissionBasicInfo()}
				{renderInvoiceEntries()}
				{renderBonus()}
				{renderTotal()}
			</Descriptions>
		);
	}

	render() {
		return this.renderDescription();
	}
}

View.defaultProps = {
	commissionId: 0
};

View.propTypes = {
	intl: PropTypes.object.isRequired,
	commissionId: PropTypes.number.isRequired,
	setLoading: PropTypes.func.isRequired,
	setShowButtons: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	isMaster: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		isMaster: state.auth.isMaster
	};
};

export default connect(mapStateToProps)(injectIntl(View));