import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { List, Row, Col, Form } from 'antd';
import { useIntl } from 'react-intl';
import { getEmployeeMessages } from '../../../constants/messages';
import AddButton from '../../Clients/AddOrUpdate/DynamicComponents/AddButton';
import Phone from './DynamicComponents/EPhone';
import { cloneDeep } from 'lodash';
let employeeMessages = getEmployeeMessages();

const messages = {
    ...employeeMessages
};

const EmergencyPhones = ({form, savePhones, phones}) => {

    const intl = useIntl();
    
    useEffect(() => {
        form.resetFields();
    }, [phones]);

    const getIntl = (str) => {
		return intl.formatMessage({...messages[str]});
	}

    const resetFields = () => {
        form.resetFields();
    }

    const addTelephone = () => {
        let dataCopy = cloneDeep(phones)
        dataCopy = 
        [
            ...dataCopy,
            {
                phone: {
                    number: ""
                },
                name: "",
                relation: ""
            }
        ]
        savePhones(dataCopy);
        resetFields();
    }

    const removeTelephone = (index) => {
        let dataCopy = cloneDeep(phones);
        dataCopy.splice(index,1);
        savePhones(dataCopy);
        resetFields();
    }

    const saveNumber = (number, index) => {
        phones[index].phone.number = number;
        savePhones(phones);
    }

    const saveName = (name, index) => {
        phones[index].name = name;
        savePhones(phones);
    }

    const saveRelation = (relation, index) => {
        phones[index].relation = relation;
        savePhones(phones);
    }


   const renderHeaders = () => {
        if (phones.length > 0)
            return (
                <Row>
                    <Col span={8}>
                        <span>
                            {getIntl("name")}
                        </span>
                    </Col>
                    <Col span={8}>
                        <span>
                            {getIntl("phone")}
                        </span>
                    </Col>
                    <Col span={7}>
                        <span>
                            {getIntl("relation")}
                        </span>
                    </Col>
                </Row>
            );
    }

    const renderTitle = () => {
        return (
            <div>
                <b>
                    {getIntl("emergencyContacts")}
                </b>
                {renderHeaders()}
            </div>
        );
    }
    return (
        <div>
            <Form form={form}>
                <List
                    className="employee-scrollable-list"
                    bordered={true}
                    header={renderTitle()}
                    dataSource={phones}
                    renderItem={(phone, index) => 
                        <List.Item>
                            <Phone form={form} phone={phone} index={index}
                                remove={removeTelephone} saveName={saveName} saveNumber={saveNumber} saveRelation={saveRelation}
                            />
                        </List.Item>
                    }
                />
                <AddButton text={getIntl("addPhone")} addFunction={addTelephone}/>
            </Form>
        </div>
    )
}

EmergencyPhones.defaultProps = {
    phones: []
};

EmergencyPhones.propTypes = {
    form: PropTypes.object.isRequired,
    savePhones: PropTypes.func.isRequired,
    phones: PropTypes.array.isRequired
};

export default EmergencyPhones;
