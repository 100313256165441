import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { message, Collapse } from 'antd';
import { getJobMessages } from '../../../constants/messages';
import { JOBTYPES, pastDueDate } from  '../constants';
import { getNestedValue } from '../../../utilities/util';
import Type from './CommonComponents/Type';
import Client from './CommonComponents/Client';
import Comments from './CommonComponents/Comments';
import ActivityLog from './CommonComponents/ActivityLog';
import Activate from './JobTypes/Activate';
import Deactivate from './JobTypes/Deactivate';
import DefaultForm from './JobTypes/DefaultForm';
import Installation from './JobTypes/Installation';
import Swap from './JobTypes/Swap';
import Reconnection from './JobTypes/Reconnection';
import { GetJobInfo, GetDuplicateJobInfo } from '../actions';
const { INSTALLATION, REVISION, UNINSTALLATION, SWAP, ADDONS, SIMCHANGE, TRANSFER, RECONNECTION, DEACTIVATE, ACTIVATE } = JOBTYPES;
const { Panel } = Collapse;

let jobMessages = getJobMessages();

const messages = {
    ...jobMessages
};

class AddOrUpdateJobForm extends React.Component  {

	componentDidMount () {
        const { props, loadJobData } = this;
		const { saveJob, jobId, jobTemplate, duplicateJobId } = props;
		if (jobId) loadJobData(GetJobInfo, jobId);
		else if (duplicateJobId) loadJobData(GetDuplicateJobInfo, duplicateJobId);
		else saveJob(jobTemplate);
    }
    
    loadJobData = (loadFunction, jobId) => {
        const { props, getIntl } = this;
		const { saveJob, setLoading } = props;
        setLoading(true);
        loadFunction(jobId)
        .then(response => {
            setLoading(false);
            saveJob(response);
        })
        .catch(() => {
            setLoading(false);
            message.error(getIntl("getJobInfoError"));
        });
    }
	
    getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

	getField = (field) => {
        const { job } = this.props;
        return getNestedValue(field, job);
    }

    saveFields = (fieldObject) => {
        const { job, saveJob } = this.props;
        saveJob({ ...job, ...fieldObject });
    }

    jobIsUpdate = () => {
        const { jobId } = this.props;
        return jobId !== undefined && !!jobId;
    }

    renderJobTypeSelector = () => {
        const { props, getField, jobIsUpdate } = this;
        const { form, saveJob, job } = props;
        const { resetFields } = form;
        const saveType = type => {
            const { client, programmedDate, duration, comments } = job;
            saveJob({ type, client, programmedDate, duration, comments });
            resetFields();
        }
        const type = getField("type");
        const isUpdate = jobIsUpdate();
        return (
            <Type
                form={form}
                type={type}
                onChange={saveType}
                disabled={isUpdate}
            />
        );
    }

    renderClient = () => {
        const { props, getField } = this;
        const { form, saveJob, job, isMaster } = props;
        const { resetFields } = form;
        const saveClient = client => {
            const { type, programmedDate, duration, comments } = job;
            saveJob({ type, client, programmedDate, duration, comments });
            resetFields();
        }
        const client = getField("client");
        const programmedDate = getField("programmedDate");
        const disabled = pastDueDate(programmedDate) && !isMaster;
        return (
            <Client
                form={form}
                client={client}
                onChange={saveClient}
                disabled={disabled}
            />
        );
    }

    renderComments = () => {
        const { props, getField, saveFields, jobIsUpdate } = this;
        const { form, jobId } = props;
        const saveComments = comments => saveFields({ comments });
        const comments = getField("comments");
        const isUpdate = jobIsUpdate();
        return (
            <Comments
                form={form}
                comments={comments}
                onChange={saveComments}
                jobId={jobId}
                independent={isUpdate}
            />
        );
    }

    renderActivityLog = () => {
        const { getField } = this;
        const activityLog = getField("activityLog");
        return (
            <ActivityLog
                activityLog={activityLog}
            />
        );
    }

    renderAddForm = () => {
        const { props, getField, getIntl, saveFields, jobIsUpdate } = this;
        const { form } = props;
        const type = getField("type");
        const isUpdate = jobIsUpdate();
        switch (type) {
            case DEACTIVATE.id: return <Deactivate form={form} saveFields={saveFields} getField={getField} isUpdate={isUpdate}/>;
            case ACTIVATE.id: return <Activate form={form} saveFields={saveFields} getField={getField}/>;
            case INSTALLATION.id: return <Installation form={form} saveFields={saveFields} getField={getField} isUpdate={isUpdate}/>;
            case SWAP.id: return <Swap form={form} saveFields={saveFields} getField={getField} isUpdate={isUpdate}/>;
            case RECONNECTION.id: return <Reconnection form={form} saveFields={saveFields} getField={getField} isUpdate={isUpdate}/>;
            case REVISION.id:
            case UNINSTALLATION.id:
            case ADDONS.id:
            case SIMCHANGE.id:
            case TRANSFER.id: return <DefaultForm form={form} type={type} saveFields={saveFields} getField={getField} isUpdate={isUpdate}/>;
            default: return <div>{getIntl("noJobTypeForm")}</div>
        }
    }

    renderRestForm = () => {
        const { renderClient, renderAddForm, renderComments, renderActivityLog } = this;
        return (
            <div>
                {renderClient()}
                {renderAddForm()}
                {renderComments()}
                {renderActivityLog()}
            </div>
        );
    }

    fullRender = () => {
        const { getField, renderJobTypeSelector, renderRestForm } = this;
        const type = getField("type");
        return (
            <div>
                {renderJobTypeSelector()}
                <Collapse className="job-view-reconnect-collapse" bordered={false} activeKey={type ? 1 : 0}>
                    <Panel key={1} showArrow={false}>
                        {renderRestForm()}
                    </Panel>
                </Collapse>
            </div>
        );
    }
    
	render(){
        return this.fullRender();
	}
}

AddOrUpdateJobForm.defaultProps = {
	jobId: 0,
	jobTemplate: { },
	duplicateJobId: 0
};

AddOrUpdateJobForm.propTypes = {
	intl: PropTypes.object.isRequired,
    isMaster: PropTypes.bool.isRequired,

	form: PropTypes.object.isRequired,
	saveJob:  PropTypes.func.isRequired,
    jobId: PropTypes.number,
    jobTemplate: PropTypes.object,
    job: PropTypes.object.isRequired,
	setLoading:  PropTypes.func.isRequired,
    duplicateJobId: PropTypes.number
};

const mapStateToProps = (state) => {
	return {
		isMaster: state.auth.isMaster
	};
};

export default connect(mapStateToProps)(injectIntl(AddOrUpdateJobForm));