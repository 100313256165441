import axios from 'axios';
import { FETCH_MODEL, ADD_MODEL_SUCCESS, EDIT_MODEL_SUCCESS, DELETE_MODEL_SUCCESS,SET_SEARCH_MODEL } from './actionTypes';

export const GetAllUnitModels = () => async (dispatch) => {
	let filter = { "order": "id ASC" }
	const response = await axios.get(`/UnitModels?filter=${encodeURIComponent(JSON.stringify(filter))}`);
	dispatch({ type: FETCH_MODEL, models: response.data });
	return response.data;
};

export const AddUnitModel = (model) => async (dispatch) => {
	const response = await axios.post(`/UnitModels/`, model);
	dispatch({ type: ADD_MODEL_SUCCESS, models: response.data });
	return response.data;
};

export const UpdateUnitModel = (model) => async (dispatch, getState) => { 
	let state = getState();
	const models = state.model.models;

	const response = await axios.put(`/UnitModels/${model.id}`, model);
	const editedModels = models.map((item) => {
		if(item.id === response.data.id) return {...item, model: response.data.model}
		return item;
	})
	dispatch({ type: EDIT_MODEL_SUCCESS, models: editedModels });
	return response.data;
};

export const DeleteUnitModel = (ids) => async (dispatch, getState) => {
	let state = getState();
	const models = state.model.models;

	let options = { data: { ids: ids } };
	const response = await axios.delete(`/UnitModels`, options);
	const result = models.filter(model => !ids.includes(model.id));
	dispatch({ type: DELETE_MODEL_SUCCESS, models: result });
	return response.data;
};

export const setSearch = (value) => (dispatch) =>{
dispatch({type:SET_SEARCH_MODEL, search:value})
}