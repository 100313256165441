import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Form,Divider, Select, Row, Col, Button, message, Spin, Input} from 'antd'
import { SaveFilled } from '@ant-design/icons';
import { getEmployeeMessages, getDepartmentMessages, getModalMessages, getPayrollMessages, getFields } from '../../../constants/messages';
import FormItemInput from './DynamicComponents/FormItemInput';
import FormItemSelect from './DynamicComponents/FormItemSelect';
import { useIntl } from 'react-intl';
import { REGEX } from '../../../constants/global';
import { BLOODTYPES, GENDERS, MARITALSTATUS } from '../constants';
import { useSelector } from 'react-redux';
import InputNumber from './DynamicComponents/MoneyInputNumber';
import DatePickerForm from './DynamicComponents/DatePicker';
import Dependants from './Dependants';
import EmergencyPhones from './EmergencyPhones'
import Deductions from './Deductions'
import FormItemUpload from '../../GlobalComponents/FormItemUpload';
import { AddEmployee, EditEmployee } from '../actions';
import { useHistory } from 'react-router-dom';
let employeeMessages = getEmployeeMessages(),
	modalMessages = getModalMessages(),
	departmentMessages = getDepartmentMessages(),
	payrollMessages = getPayrollMessages(),
    fieldsMessage = getFields();

const messages = {
	...employeeMessages,
	...modalMessages,
	...departmentMessages,
	...payrollMessages,
    ...fieldsMessage,
};
const { Option, OptGroup } = Select;

const EmployeeAddEditForm = ({employeeData,saveEmployeeData, dependants, saveDependants, saveEmergencyPhones, emergencyPhones, deductions, saveDeductions}) => {

    const intl = useIntl();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const allDepartments = useSelector(state => state.department.allDepartments);
    const [form] = Form.useForm();
    const [formDependants] = Form.useForm();
    const [formEmergencyPhones] = Form.useForm();
    const columnOneSpan = 16;
    const span = (6*24)/columnOneSpan;
    const offset = (2*24)/columnOneSpan;
    let initialValues = {}
    if(Object.keys(employeeData).length !==0 ){
        initialValues = {
            name: employeeData.name,
            identification: employeeData.identification,
            phone: employeeData.phone,
            address: employeeData.address,
            email: employeeData.email,
            bloodType: employeeData.bloodType,
            gender: employeeData.gender === true ? 1 : 0,
            maritalStatus: employeeData.maritalStatus,
            workRoleId: employeeData.workRoleId,
            salary: employeeData.salary,
            bankAccount: employeeData.bankAccount,
            insurancePolicy: employeeData.insurancePolicy,
            insuranceCertificate: employeeData.insuranceCertificate,
        }

        if (employeeData.licensePhoto) {
            initialValues={...initialValues, licensePhoto:{
                old: employeeData.licensePhoto.old,
                uid: employeeData.licensePhoto.uid,
                name: employeeData.licensePhoto.uid,
                status: employeeData.licensePhoto.status,
                url: employeeData.licensePhoto.url
            }}
        }
        if (employeeData.idPhoto) {
            initialValues={...initialValues, idPhoto:{
                old: employeeData.idPhoto.old,
                uid: employeeData.idPhoto.uid,
                name: employeeData.idPhoto.uid,
                status:employeeData.idPhoto.status,
                url: employeeData.idPhoto.url
            }}

        }
    }
    const getIntl = (str) => {
		return intl.formatMessage({...messages[str]});
	}

    let bloodTypes = BLOODTYPES.map((type, index) => <Option key={index} value={type.id}>{type.symbol}</Option>);
    let genders = GENDERS.map((gender, index) => <Option key={index} value={gender.id}>{getIntl(gender.intl)}</Option>);
    let maritals = MARITALSTATUS.map((status, index) => <Option key={index} value={status.id}>{getIntl(status.intl)}</Option>);
    let workRolesOption = allDepartments.map(({id: departmentId, workRoles, name: departmentName}) => {
        let departmentWorkRoles = workRoles.map(({id: roleId, name: roleName}) => <Option key={roleId} value={roleId}>{roleName}</Option>);
        return <OptGroup key={`Department${departmentId}`} label={departmentName}>{departmentWorkRoles}</OptGroup>;
    });

    const photoSizeValidator = (rule, value, callback) => {
		if (value && value.size) {
			const { size } = value;
			if (!size || (size / 1024 / 1024) > 4) return callback(getIntl("4MbMaxPhotoSizeError"));
		}
		callback();
	}

    const cancel = () =>{
        saveDeductions([]);
        saveDependants([]);
        saveEmergencyPhones([]);
        saveEmployeeData({});
        history.push("/employees");
    }
    const save = async () => {
        try {

            const values = await form.validateFields();
            await formEmergencyPhones.validateFields();
            await formDependants.validateFields();
            setLoading(true);
            const employee = {...values, emergency: emergencyPhones, dependants, deductions};
            let successMessage, func;
            if (employeeData.id === undefined) {
                successMessage = getIntl("addEmployeeSuccess");
                func = AddEmployee;
            } else {
                successMessage = getIntl("editEmployeeSuccess");
                func = EditEmployee;
            }
            await func(employee, employeeData.id ? employeeData.id : null);
            message.success(successMessage)
            cancel();
        } catch (error) {
            message.error(employeeData.id  ?  getIntl("editEmployeeError") : getIntl("addEmployeeError") );
        }finally{
            setLoading(false);
        }
       
	}
    const renderButtons = () => {
		const margin = "0.5em";
        return (
            <div className="buttons-align-right">
                <Button
                    size={'large'}
                    style={{marginTop: margin, marginRight: margin}}
                    onClick={cancel}
                    key = "cancel"
                    loading={loading}
                >
                    {getIntl("cancel")}
                </Button>
                <Button
                    style={{marginTop: margin}}
                    type="primary"
                    onClick={save}
                    key = "save"
                    loading={loading}
                    size={'large'}
                    icon={<SaveFilled/>}
                >
                    {getIntl("save")}
                </Button>
            </div>
        );
        
    }

  return (
        <div style={{paddingLeft: "100px"}}>
            <Spin spinning={loading}>
                {renderButtons()}
                <Form form={form} initialValues={initialValues} layout='vertical'>
                    <Divider>{getIntl("personalInformation")}</Divider>
                    <Row>
                        <Col span={columnOneSpan}>
                            <Row>
                                <Col span={span}>
                                    <FormItemInput
                                        fieldName = {`name`}
                                        label = {getIntl("name")}
                                        errorMessage = {getIntl("nameError")}      
                                    />
                                </Col>
                                <Col span={span} offset={offset}>
                                    <FormItemInput
                                        fieldName = {`identification`}
                                        label = {getIntl("identification")}
                                        errorMessage = {getIntl("identificationError")}
                                        extraRules={{ validator: (_,value) => {
                                            if (!isNaN(value)) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject(getIntl("numberIdentificationisInValid"));
                                            }
                                            }}}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={span}>  
                                    <FormItemInput
                                        fieldName = {`address`}
                                        label = {getIntl("address")}
                                        errorMessage = {getIntl("addressError")}
                                    />
                                </Col>
                                <Col span={span} offset={offset}>
                                <Form.Item name={'email'} label={getIntl("email")} rules={[{ type: 'email' }]}>
                                    <Input />
                                </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={span}> 
                                    <FormItemInput
                                        fieldName = {`phone`}
                                        label = {getIntl("phone")}
                                        errorMessage = {getIntl("phoneError")}
                                        required={true}
                                        extraRules = {{message: intl.formatMessage({...messages.phoneNumberError}), pattern: REGEX.number}}
                                    />
                                </Col>
                                <Col span={span} offset={offset}>
                                    <FormItemSelect
                                        fieldName = {`bloodType`}
                                        label = {getIntl("bloodType")}
                                        errorMessage = {getIntl("bloodTypeError")}
                                        options={bloodTypes}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={span}>
                                    <DatePickerForm
                                        fieldName = {`birthdate`}
                                        label = {getIntl("birthdate")}
                                        errorMessage = {getIntl("birthdateError")}
                                        initialValue = {employeeData.birthdate}
                                        componentProps = {{showToday: false}}
                                    />
                                </Col>
                                <Col span={span} offset={offset}>{ 
                                    <FormItemSelect
                                        fieldName = {`gender`}
                                        label = {getIntl("gender")}
                                        errorMessage = {getIntl("genderError")}
                                        options={genders}
                                    />}
                                </Col>

                            </Row>
                            <Row>
                                
                                <Col span={span} >
                                    <FormItemSelect
                                        fieldName = {`maritalStatus`}
                                        label = {getIntl("maritalStatus")}
                                        errorMessage = {getIntl("maritalStatusError")}
                                        options={maritals}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24 - columnOneSpan - 2}>
                            <Row  gutter={[16, 16]}>
                                <Col span={24} style={{height: '116px'}} className="employee-picture-remove-margin">
                                    <FormItemUpload
                                        form={form}
                                        fieldName={'idPhoto'}
                                        label = {getIntl("identification")}
                                        listType={'picture-card'}
                                        dragger={false}
                                        validator={photoSizeValidator}
                                    />
                                </Col>
                                <Col span={32} style={{height: '116px'}} className="employee-picture-remove-margin">
                                    <FormItemUpload
                                        form={form}
                                        fieldName={'licensePhoto'}
                                        label = {getIntl("license")}
                                        listType={'picture-card'}
                                        dragger={false}
                                        validator={photoSizeValidator}
                                    />
                                </Col>
                                <Col span={24} className="employee-license-padding"> 
                                    <DatePickerForm
                                        fieldName = {`licenseExpirationDate`}
                                        label = {getIntl("licenseExpirationDate")}
                                        initialValue = {employeeData.licenseExpirationDate}
                                        componentProps = {{showToday: false}}
                                        required={false}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Divider>{getIntl("workInformation")}</Divider>
                    <Row>
                        <Col span={span}> 
                            <FormItemSelect
                                fieldName = {`workRoleId`}
                                label = {getIntl("workRole")}
                                errorMessage = {getIntl("workRoleError")}
                                options={workRolesOption}
                            />

                        <FormItemInput
                            fieldName = {`bankAccount`}
                            label = {getIntl("bankAccount")}
                            errorMessage = {getIntl("bankAccountError")}
                            />
                        </Col>
                        <Col span={span} offset={offset}>
                            <InputNumber
                                form={form}
                                fieldName = {`salary`}
                                label = {getIntl("salary")}
                                renderLabel = {getIntl("salary")}
                                required={true}
                                errorMessage = {getIntl("salaryError")}
                            />
                            <DatePickerForm
                                fieldName = {`hireDate`}
                                label = {getIntl("hireDate")}
                                errorMessage = {getIntl("hireDateError")}
                                initialValue={employeeData.hireDate}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={span}>
                            
                            <FormItemInput
                                fieldName = {`insuranceCertificate`}
                                label = {getIntl("insuranceCertificate")}
                                required={false}
                            />
                        </Col>
                        <Col span={span} offset={offset}>
                            <FormItemInput
                                fieldName = {`insurancePolicy`}
                                label = {getIntl("insurancePolicy")}
                                required={false}
                            />
                        </Col>
                    </Row>
                </Form>

                <Row>
                    <Col span={10}>{ <Dependants form={formDependants} saveDependants={saveDependants} dependants={dependants}/>}</Col>
                    <Col span={10} offset={2}>{<EmergencyPhones form={formEmergencyPhones} savePhones={saveEmergencyPhones} phones={emergencyPhones}/>}</Col>
                </Row>
                <Row>
                    <Col span={22}>{<Deductions saveDeductions={saveDeductions} deductions={deductions}/>}</Col>
                </Row>
            </Spin>
        </div>
    )
}
EmployeeAddEditForm.propTypes = {
	employeeData: PropTypes.object.isRequired,
    saveEmployeeData: PropTypes.func,
    dependants: PropTypes.array,
    saveDependants: PropTypes.func,
    emergencyPhones: PropTypes.array,
	saveEmergencyPhones: PropTypes.func,
    deductions: PropTypes.array,
	saveDeductions: PropTypes.func,
};
export default EmployeeAddEditForm
