import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Spin, message, Button } from 'antd';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getCommissionMessages, getactionMessages } from '../../constants/messages';
import View from './View';
import { RejectCommission, ApproveCommission } from './actions';

let commissionMessages = getCommissionMessages(),
	actionMessages = getactionMessages();

const messages = {
	...commissionMessages,
	...actionMessages
};

const defaultState = {
	isLoading: false,
	showButtons: false
};

class ViewModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { ...defaultState };
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });
	setShowButtons = (showButtons) => this.setState({ showButtons });

	handleCancel = () => {
		const { handleCancel } = this.props;
		this.setState(defaultState);
		handleCancel();
	}

	rejectCommission = () => {
		const { props, getIntl, setLoading, handleCancel } = this;
		const { rejectCommission, commissionId } = props;
		setLoading(true);
		rejectCommission(commissionId)
			.then(() => {
				handleCancel();
				message.success(getIntl("rejectCommissionSuccess"));
			})
			.catch(() => {
				setLoading(false);
				message.error(getIntl("rejectCommissionError"));
			});
	}

	approveCommission = () => {
		const { props, getIntl, setLoading, handleCancel } = this;
		const { approveCommission, commissionId } = props;
		setLoading(true);
		approveCommission(commissionId)
			.then(() => {
				handleCancel();
				message.success(getIntl("approveCommissionSuccess"));
			})
			.catch(() => {
				setLoading(false);
				message.error(getIntl("approveCommissionError"));
			});
	}

	renderButtons = () => {
		const { state, getIntl, rejectCommission, approveCommission } = this;
		const { isLoading, showButtons } = state;
		if (showButtons)
			return [
				<Button key="reject" type="danger" onClick={rejectCommission} loading={isLoading}>{getIntl("reject")}</Button>,
				<Button key="approve" type="primary" onClick={approveCommission} loading={isLoading}>{getIntl("approve")}</Button>
			];
		return null;
	}

	render() {
		const { state, props, getIntl, renderButtons, setLoading, setShowButtons, handleCancel } = this;
		const { isLoading } = state;
		const { showModal, commissionId } = props;
		return (
			<Modal
				open={showModal}
				title={getIntl("commissionView")}
				onCancel={handleCancel}
				destroyOnClose={true}
				footer={renderButtons()}
				width={1000}
			>
				<Spin spinning={isLoading}>
					<View commissionId={commissionId} setLoading={setLoading} setShowButtons={setShowButtons} />
				</Spin>
			</Modal>
		);
	}
}

ViewModal.defaultProps = {
	commissionId: 0
};

ViewModal.propTypes = {
	intl: PropTypes.object.isRequired,

	showModal: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired,
	commissionId: PropTypes.number.isRequired,
	rejectCommission: PropTypes.func.isRequired,
	approveCommission: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => {
	return {
		rejectCommission: (commissionId) => dispatch(RejectCommission(commissionId)),
		approveCommission: (commissionId) => dispatch(ApproveCommission(commissionId))
	};
};

export default connect(null, mapDispatchToProps)(injectIntl(ViewModal));