import axios from "axios";
import cookie from "react-cookies";
import {
  FETCH_ACCOUNTS,
  FETCH_ACCOUNTS_COUNT,
  ADD_ACCOUNTS_SUCCESS,
  DELETE_ACCOUNTS_SUCCESS,
  DISABLE_ACCOUNTS_SUCCESS,
  EDIT_ACCOUNTS_SUCCESS,
  END_REQUEST_ACCOUNT,
  FETCH_ROLES,
  FETCH_ROLES_BY_ID,
  INIT_MODAL_REQUEST_ACCOUNT,
  CLOSE_ROLE_MODAL_ACCOUNT,
  FETCH_SESSIONS,
  LOAD_DATA,
  LOADED_DATA,
  LOG_IN,
  START_LOADING_PROFILE,
  STOP_LOADING_PROFILE,
  USER_PROFILE_IMAGE,
  SET_SEARCH_ACCOUNT,
} from "../constants/actionTypes";
import { ShowErrors, notify, getS3PublicLink } from "../utilities/util";

export const GetAccounts = (page = 1, pageSize = 10) => (dispatch, getState) => {
    let state = getState();
    let messages = state.int18.messages;
    let id = state.auth.user.id;
    let filter = {
      limit: pageSize,
      skip: (page - 1) * pageSize,
      where: {
        id: {
          neq: id,
        },
      },
      order: 'active DESC',
    };

    axios
      .get(`/Accounts?filter=${encodeURIComponent(JSON.stringify(filter))}`)
      .then((response) => {
        dispatch({ type: FETCH_ACCOUNTS, accounts: response.data });
        dispatch({ type: END_REQUEST_ACCOUNT });
      })
      .catch((error) => {
        ShowErrors(error, dispatch, messages);
        dispatch({ type: END_REQUEST_ACCOUNT });
      });
  };

export const GetAccountsMetaData = () => (dispatch, getState) => {
  let state = getState();
  let id = state.auth.user.id;
  let filter = {
    id: {
      neq: id,
    },
  };

  let roles = [...state.auth.roles];
  roles.sort((a, b) => a - b);

  let roleId = roles[0];

  // fentch count
  axios
    .get(`/Accounts/count?where=${encodeURIComponent(JSON.stringify(filter))}`)
    .then((response) => {
      dispatch({ type: FETCH_ACCOUNTS_COUNT, count: response.data.count });
    })
    .catch(() => {
      dispatch({ type: END_REQUEST_ACCOUNT });
    });

  // fetch Roles
  axios
    .get(`/Accounts/Roles/${roleId}`)
    .then((response) => {
      dispatch({ type: FETCH_ROLES, roles: response.data.roles });
    })
    .catch(() => {
      dispatch({ type: END_REQUEST_ACCOUNT });
    });
};

export const SearchAccounts = (searchObject, page = 1, pageSize = 10) => (dispatch, getState) => {
    let state = getState();
    let messages = state.int18.messages;
    let id = state.auth.user.id;
    let searchText = searchObject.searchText;
    let regx = "%" + searchText + "%";

    let filter = {
      limit: pageSize,
      skip: (page - 1) * pageSize,
      where: {
        id: {
          neq: id,
        },
      },
      order: 'active DESC',
    };
    if (searchText.length > 0) {
      filter.where = {
        and: [
          {
            or: [
              { username: { like: regx } },
              { email: { like: regx } },
              { name: { like: regx } },
            ],
          },
          {
            id: {
              neq: id,
            },
          },
        ],
      };
    }

    // fentch count
    axios.get(`/Accounts/count?where=${encodeURIComponent(JSON.stringify(filter.where))}`)
      .then((response) => {
        dispatch({ type: FETCH_ACCOUNTS_COUNT, count: response.data.count });
        dispatch({type: SET_SEARCH_ACCOUNT, search: searchText })
      })
      .catch(() => {
        dispatch({ type: END_REQUEST_ACCOUNT });
      });

    // fetch filtered Accounts
    axios.get(`/Accounts?filter=${encodeURIComponent(JSON.stringify(filter))}`)
      .then((response) => {
        dispatch({ type: FETCH_ACCOUNTS, accounts: response.data });
        dispatch({ type: END_REQUEST_ACCOUNT });
      })
      .catch((error) => {
        ShowErrors(error, dispatch, messages);
        dispatch({ type: END_REQUEST_ACCOUNT });
      });
  };

export const AddAccount = (newUser) => (dispatch, getState) => {
  let state = getState();
  let messages = state.int18.messages;

  let options = {
    active: true,
    email: newUser.email,
    emailVerified: false,
    languageId: newUser.languageId,
    password: newUser.password,
    realm: newUser.realm,
    username: newUser.username,
    name: newUser.name,
    image: false,
  };

  axios
    .post("/Accounts", options)
    .then((response) => {
      let notify_options = {
        username: response.data.name,
      };
      let newAccount = {
        ...response.data,
      };
      dispatch({ type: ADD_ACCOUNTS_SUCCESS, newAccount: newAccount });
      notify(`${messages[ADD_ACCOUNTS_SUCCESS]}`, notify_options);
      dispatch({ type: END_REQUEST_ACCOUNT });
    })
    .catch((error) => {
      ShowErrors(error, dispatch, messages);
      dispatch({ type: END_REQUEST_ACCOUNT });
    });
};

export const DeleteAccount = (ids) => (dispatch, getState) => {
  let state = getState();
  let messages = state.int18.messages;
  let options = {
    data: {
      uids: ids,
    },
  };
  axios
    .delete(`/Accounts/removeAccountsById/`, options)
    .then(() => {
      dispatch({ type: DELETE_ACCOUNTS_SUCCESS });
      notify(`${messages[DELETE_ACCOUNTS_SUCCESS]}`);
      dispatch({ type: END_REQUEST_ACCOUNT });
      dispatch(SearchAccounts({ searchText: "" }));
    })
    .catch((error) => {
      ShowErrors(error, dispatch, messages);
      dispatch({ type: END_REQUEST_ACCOUNT });
    });
};

export const DisableAccount = (id, active) => (dispatch, getState) => {
  let state = getState();
  let messages = state.int18.messages;

  let options = {
    active: !active,
  };
  axios
    .patch(`/Accounts/${id}`, options)
    .then(() => {
      dispatch({ type: DISABLE_ACCOUNTS_SUCCESS, id });
      dispatch({ type: END_REQUEST_ACCOUNT });
    })
    .catch((error) => {
      ShowErrors(error, dispatch, messages);
      dispatch({ type: END_REQUEST_ACCOUNT });
    });
};

export const EditAccount = (id, newUser) => (dispatch, getState) => {
  let state = getState();
  let messages = state.int18.messages;
  let options = {
    email: newUser.email,
    languageId: newUser.languageId,
    username: newUser.username,
    name: newUser.name,
  };

  if (newUser.password) {
    options["password"] = newUser.password;
  }

  axios
    .patch(`/Accounts/${id}`, options)
    .then((response) => {
      let notify_options = {
        username: response.data.name,
      };
      dispatch({ type: EDIT_ACCOUNTS_SUCCESS, editedAccount: response.data });
      notify(`${messages[EDIT_ACCOUNTS_SUCCESS]}`, notify_options);
      dispatch({ type: END_REQUEST_ACCOUNT });
    })
    .catch((error) => {
      ShowErrors(error, dispatch, messages);
      dispatch({ type: END_REQUEST_ACCOUNT });
    });
};

export const UpdateRoles = (id, roles) => async (dispatch, getState) => {
  let state = getState();
  let messages = state.int18.messages;
  let options = {
    roles: roles,
  };
  dispatch({ type: INIT_MODAL_REQUEST_ACCOUNT });
  try {
    dispatch({ type: INIT_MODAL_REQUEST_ACCOUNT });
    const response = await axios.put(`/Accounts/${id}/Roles/`, options);
    dispatch({ type: FETCH_ROLES_BY_ID, editableRoles: response.data.roles });
    dispatch({ type: CLOSE_ROLE_MODAL_ACCOUNT });
    dispatch({ type: END_REQUEST_ACCOUNT });
  } catch (error) {
    ShowErrors(error, dispatch, messages);
    dispatch({ type: END_REQUEST_ACCOUNT });
  }
};

export const FetchRolesById = (id) => (dispatch, getState) => {
  let state = getState();
  let messages = state.int18.messages;

  dispatch({ type: LOAD_DATA });
  axios
    .get(`/Accounts/${id}/Roles/`)
    .then((response) => {
      dispatch({ type: FETCH_ROLES_BY_ID, editableRoles: response.data.roles });
      dispatch({ type: END_REQUEST_ACCOUNT });
      dispatch({ type: LOADED_DATA });
    })
    .catch((error) => {
      ShowErrors(error, dispatch, messages);
      dispatch({ type: END_REQUEST_ACCOUNT });
      dispatch({ type: LOADED_DATA });
    });
};

export const GetSessionsById =
  (id, page = 1, pageSize = 5) =>
  (dispatch, getState) => {
    let state = getState();
    let messages = state.int18.messages;

    let filter = {
      limit: pageSize,
      skip: (page - 1) * pageSize,
    };
    axios
      .get(
        `/Accounts/${id}/sessions/?filter=${encodeURIComponent(
          JSON.stringify(filter)
        )}`
      )
      .then((response) => {
        dispatch({
          type: FETCH_SESSIONS,
          sessions: response.data.data.sessions,
          count: response.data.data.count,
        });
        dispatch({ type: END_REQUEST_ACCOUNT });
      })
      .catch((error) => {
        ShowErrors(error, dispatch, messages);
        dispatch({ type: END_REQUEST_ACCOUNT });
      });
  };

export const RemoveSessions = (id, sessionDates) => (dispatch, getState) => {
  let state = getState();
  let messages = state.int18.messages;
  let options = {
    data: {
      sessionDates: sessionDates,
    },
  };

  axios
    .delete(`/Accounts/${id}/sessions/`, options)
    .then(() => {
      dispatch(GetSessionsById(id));
      dispatch({ type: END_REQUEST_ACCOUNT });
    })
    .catch((error) => {
      ShowErrors(error, dispatch, messages);
      dispatch({ type: END_REQUEST_ACCOUNT });
    });
};

export const EditProfile = (newUser) => (dispatch, getState) => {
  let state = getState();
  let messages = state.int18.messages;
  let id = state.auth.user.id;
  let options = {
    email: newUser.email,
    name: newUser.name,
  };

  if (newUser.password) {
    options["password"] = newUser.password;
  }

  dispatch({ type: START_LOADING_PROFILE });
  axios
    .patch(`/Accounts/${id}`, options)
    .then((response) => {
      let notify_options = {
        username: response.data.name,
      };

      let user = {
        ...state.auth.user,
        email: newUser.email,
        name: newUser.name,
      };
      dispatch({ type: LOG_IN, user: user });
      dispatch({ type: STOP_LOADING_PROFILE });
      notify(`${messages[EDIT_ACCOUNTS_SUCCESS]}`, notify_options);
    })
    .catch((error) => {
      ShowErrors(error, dispatch, messages);
    });
};

export const SimpleChangePassword = (newUser) => (dispatch, getState) => {
  let state = getState();
  let messages = state.int18.messages;
  let name = state.auth.user.name;
  let options = {
    oldPassword: newUser.oldPassword,
    newPassword: newUser.password,
  };

  dispatch({ type: START_LOADING_PROFILE });
  axios
    .post(`/Accounts/change-password`, options)
    .then(() => {
      let notify_options = {
        username: name,
      };

      dispatch({ type: STOP_LOADING_PROFILE });
      notify(`${messages[EDIT_ACCOUNTS_SUCCESS]}`, notify_options);
    })
    .catch((error) => {
      dispatch({ type: STOP_LOADING_PROFILE });
      ShowErrors(error, dispatch, messages);
    });
};

export const UpdateProfileImage = () => (dispatch, getState) => {
  let state = getState();
  let userId = state.auth.user.id;
  // fetch Image

  return axios
    .get(getS3PublicLink(`profile/user_${userId}`))
    .then((response) => {
      let expires = new Date(Date.now());
      expires = new Date(expires.getTime() + 900 * 1000);
      cookie.save("image", expires.getTime(), { expires });
      dispatch({ type: USER_PROFILE_IMAGE, url: response.data.url });
    })
    .catch(() => {});
};

export const UploadProfileImage = (file, filename, directory, onSuccess, onError) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("name", filename);
  formData.append("directory", directory);

  return axios.post(`/S3Buckets/profileUpload`, formData, {
      headers: {
          "Content-Type": "multipart/form-data",
          "Accept": "application/json",
      },
  })
  .then(response => {
      onSuccess(response.data);
  })
  .catch(error => {
      onError(error);
  });
};