import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { getJobMessages } from '../../../../constants/messages';
import BasicInformation from '../DefaultViews/BasicInformation';
import Device from '../../AddUpdateJob/CommonComponents/Device';
import Sim from '../../AddUpdateJob/CommonComponents/Sim';
import DeviceRender from '../DefaultViews/DeviceRender';
import DescriptionsCombiner from '../DefaultViews/DescriptionsCombiner';
import MissingWON from '../DefaultViews/MissingWON';
import { ActiveDeviceSearch, SearchAvailableSimCards } from '../../actions';
import { JOBSTATUS } from  '../../constants';
const { TODO, DOING, MISSINGWON, DONE, CANCELLED, ANNULLED } = JOBSTATUS;

let jobMessages = getJobMessages();

const messages = {
	...jobMessages
};

class SimChange extends React.Component  {

    getIntl = (str, values = {}) => this.props.intl.formatMessage({...messages[str]}, values);

    renderDeviceSelector = () => {
        const { form, saveFields, getField } = this.props;
        const save = gps => saveFields({ gps });
        const gps = getField("gps");
        const clientId = getField("client.clientId");
        return (
            <Device
                form={form}
                search={(deviceName) => ActiveDeviceSearch(deviceName, clientId, 10)}
                device={gps}
                disabled={clientId === undefined}
                onChange={save}
            />
        );
    }

    renderDevice = () => {
        const device = this.props.getField("gps");
        if (device) return <DeviceRender device={device}/>
    }

    renderSimSelector = () => {
        const { props, getIntl } = this;
        const { form, saveFields, getField } = props;
        const saveSim = sim => saveFields({ sim });
        const sim = getField("sim");
        const clientId = getField("client.clientId");
        return (
            <Sim
                form={form}
                search={(simName) => SearchAvailableSimCards(simName, 10)}
                sim={sim}
                disabled={clientId === undefined}
                onChange={saveSim}
                label={getIntl("newSimCard")}
            />
        );
    }

    renderDoing = () => {
        const { renderTodo, renderDeviceSelector, renderSimSelector } = this;
        return (
            <div>
                {renderTodo()}
                {renderDeviceSelector()}
                {renderSimSelector()}
            </div>
        )
    }

    renderDone = () => {
        const { renderTodo, renderDevice } = this;
        return (
            <DescriptionsCombiner>
                {renderTodo()}
                {renderDevice()}
            </DescriptionsCombiner>
        );
    }

    renderMissingWON = () => {
        const { props, renderDone } = this;
        const { form, jobId } = props;
        return <MissingWON form={form} jobId={jobId} renderInfo={renderDone}/>;
    }

    renderTodo = () => {
        const { getField } = this.props;
        return <BasicInformation getField={getField}/>;
    }

    renderBasedOnStatus = () => {
        const { props, getIntl, renderTodo, renderDoing, renderDone, renderMissingWON } = this;
        const { getField } = props;
        const status = getField("status");
        switch (status) {
            case TODO.id: 
            case CANCELLED.id: 
            case ANNULLED.id: return renderTodo();
            case DOING.id: return renderDoing();
            case DONE.id: return renderDone();
            case MISSINGWON.id: return renderMissingWON();
            default: return <div>{getIntl("noStatusFormForType")}</div>;
        }
    }
    
	render(){
        return this.renderBasedOnStatus();
	}
}

SimChange.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    saveFields: PropTypes.func.isRequired,
    getField: PropTypes.func.isRequired,
    jobId: PropTypes.number.isRequired,
};

export default injectIntl(SimChange);