import { connect } from 'react-redux';
import Job from './Job';
import { SearchJobs } from './actions';

const mapStateToProps = (state) => {
	return {
		jobs: state.job.jobs,
		recordCount: state.job.count,
		isLoading: state.job.isLoading,
		searchTable: state.job.searchTable
	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		searchJobs: (searchObject, page, pageSize) => dispatch(SearchJobs(searchObject, page, pageSize))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Job);