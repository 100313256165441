import React, { useState } from 'react';
import { Menu, Tooltip} from 'antd';
import { useHistory,useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import FiscalData from '../FiscalData/FiscalData';
import TaxRetentionModal from '../Invoices/TaxRetention/TaxRetentionModal';
import { ACL4Component } from '../../utilities/util';
import menuItems from './menuItems';

const SideMenu=()=>{
    const intl = useIntl();
    let history = useHistory();
	let location = useLocation();
    const roles= useSelector(state=> state.auth.roles);
    let [showFiscalDataModal,setShowFiscalDataModal] = useState(false);
	let [showTaxRetentionModal,setShowTaxRetentionModal] = useState(false);


    const isModal = (item) => {
		return  item.key === 'fiscalData' || item.key === 'taxRetention';
	}

	const closeFiscalDataModal = () => {
		setShowFiscalDataModal(false)
	}

	const openFiscalDataModal = () => {
		setShowFiscalDataModal(true)
	}

	const closeTaxRetentionModal = () => {
		setShowTaxRetentionModal(false)
	}

	const openTaxRetentionModal = () => {
		setShowTaxRetentionModal(true)
	}

	const showModal = (key) => {
		switch (key) {
			case "fiscalData":
				return openFiscalDataModal();
			case "taxRetention":
				return openTaxRetentionModal();
			default:
				break;
		}
	}

	const navigate = (item) => {
		if (isModal(item))
			return showModal(item.key);
		let view = (item.key == "home") ? "" : item.key;
		history.push(`/${view}`);
	};

    const generateMenu = (menuItems) => {
		return menuItems.map(({ title, key, icon, subMenu, requiredRoles }) => {
			const text= typeof title === 'string' ? title : intl.formatMessage(title);
			let option = {
				key,
				icon,
				label:<Tooltip placement='right' title= {text}>{text}</Tooltip>,
				'data-testid': `menu-${key}`,
			};
			if (subMenu) {
				option.children = generateMenu(subMenu).filter((menu) => !!menu);
				option['data-testid'] = `submenu-${key}`;
			}

			return ACL4Component(roles, option, requiredRoles);
		});
	};
    const menu = generateMenu(menuItems);
    
	return(

        <React.Fragment>
				<FiscalData 
					handleCancel={closeFiscalDataModal} 
					isVisible={showFiscalDataModal} 
				/>
				<TaxRetentionModal 
					closeTaxModal={closeTaxRetentionModal} 
					showTaxModal={showTaxRetentionModal} 
				/>
				<Menu
					theme="dark"
					mode="inline"
					defaultSelectedKeys={[location.pathname || "home"]}
					onClick={navigate}
					items={menu}
				/>
			</React.Fragment>

    )
}




export default SideMenu;

