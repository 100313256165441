import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, Spin, message } from 'antd';
import { injectIntl } from 'react-intl';
import { getJobMessages } from '../../../../../constants/messages';
import { AdvancedSearchForUser } from '../../../actions';

const FormItem = Form.Item;
const { Option } = Select;

let jobMessages = getJobMessages();

const messages = {
    ...jobMessages
};

class CreatingUser extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            isLoading: false,
            users: []
        };
    }

    componentDidMount () {
        const { getIntl, setLoading } = this;
        setLoading(true);
        AdvancedSearchForUser()
        .then(users => this.setState({ users }))
        .catch(() => {
            setLoading(false);
            message.error(getIntl("unableToLoadUsers"));
        })
    }

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    setLoading = (isLoading) => this.setState({ isLoading });

    buildOptions = () => {
        const { users } = this.state;
        return users.map(user => {
            const { id, name } = user;
            return <Option key={id} value={id}>{name}</Option>;
        });
    }
    
	render() {
        const { state, props, getIntl, buildOptions } = this;
        const { isLoading } = state;
        const { form } = props;
        const { getFieldDecorator } = form;
        let clientOptions = buildOptions();
        return(
            <FormItem label={getIntl("creatingUser")} >
            {
                getFieldDecorator("creatingUserId")(
                    <Select
                        className="job-full-component"
                        showSearch
                        allowClear
                        placeholder={getIntl("creatingUser")}
                        optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        notFoundContent={isLoading ? <Spin size="small" /> : getIntl("notFound")}
                        dropdownMatchSelectWidth={false}
                    >
                        {clientOptions}
                    </Select>
                )
            }
            </FormItem>
        );
    }
}

CreatingUser.propTypes = {
	intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired
};

export default injectIntl(CreatingUser);