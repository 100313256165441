import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Modal, message, Spin } from 'antd';
import { injectIntl } from 'react-intl';
import { getModalMessages, getClientMessages } from '../../../../constants/messages';
import { connect } from 'react-redux';
import { SaveClientRules } from '../../actions';
import RulesForm from './RulesForm';
import { getNestedValue, objectToArray } from '../../../../utilities/util';

let modalMessages = getModalMessages(),
    clientMessages = getClientMessages();

const messages = {
	...modalMessages,
	...clientMessages
};

class RulesModal extends React.Component  {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			isEditing: false
        };
        props.form.getFieldDecorator('data', { initialValue: [], preserve: true });
	}

    setLoading = (isLoading) => {
        this.setState({ isLoading });
    }

    setEditing = (isEditing) => {
        this.setState({ isEditing });
    }

	getIntl = (str) => {
		return this.props.intl.formatMessage({...messages[str]});
    }

	submit = () => {
        const { props, getIntl, setLoading, cancel } = this;
        const { form, clientId, saveClientRules } = props;
        form.validateFields({ "force": true }, (err, values) => {
			if (!err) {
				const valueArray = objectToArray(values);
				if (valueArray.length > 1) message.warning(getIntl("clientRulesSaveAllWarning"));
				else {
					const rules = values.data;
					setLoading(true);
					saveClientRules(clientId, rules)
					.then(() => {
						setLoading(false);
						message.success(getIntl("clientRulesSaveSuccess"));
						cancel();
					})
					.catch(err => {
						const errorNumber = getNestedValue("response.data.error.number", err);
						setLoading(false);
						if (errorNumber === 547) message.error(getIntl("deletedClientRuleHasRecurrentProductsError"));
						else message.error(getIntl("clientRulesSaveError"));
					})
				}
			}
		});
	}

	cancel = () => {
		const { form, handleCancel } = this.props;
		const { setFieldsValue } = form;
		this.setState({ isLoading: false, isEditing: false });
		setFieldsValue({ data: [] });
		handleCancel();
	}

	renderedButtons = () => {
        const { state, submit, cancel, getIntl } = this;
		const { isLoading, isEditing } = state;
        return [
            <Button key="cancel" loading={isLoading} onClick={cancel}>
                {getIntl("cancel")}
            </Button>,
            <Button key = "submit" disabled={isEditing} loading={isLoading} type="primary" onClick={submit}>
                {getIntl("save")}
            </Button>
        ];
    }

	render(){
		const { state, props, submit, cancel, renderedButtons, getIntl, setLoading, setEditing } = this;
		const { isLoading } = state;
		const { showModal, form, clientId } = props;
		return (
			<Modal
				open={showModal}
				title={getIntl("clientInvoiceRules")}
				onOk={submit}
				onCancel={cancel}
				destroyOnClose={true}
				footer={renderedButtons()}
				maskClosable={false}
				keyboard={!isLoading}
				closable={!isLoading}
            >
				<Spin spinning={isLoading}>
					<RulesForm form={form} clientId={clientId} setLoading={setLoading} setEditing={setEditing} errorClose={cancel}/>
				</Spin>
            </Modal>
		);
	}
}

RulesModal.defaultProps = {
	clientId: 0
};

RulesModal.propTypes = {
	intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
	saveClientRules:  PropTypes.func.isRequired,
	
	showModal:  PropTypes.bool.isRequired,
	clientId: PropTypes.number.isRequired,
	handleCancel:  PropTypes.func.isRequired,
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		saveClientRules: (id, rules) =>  dispatch(SaveClientRules(id, rules)),
	};
};

export default connect(null, mapDispatchToProps)(injectIntl(Form.create()(RulesModal)));