import {
	CLEAR_SESSION,
	FETCH_ROLES,
	FETCH_ROLES_BY_ID
} from '../constants/actionTypes';


const initState = {
	roles: [],
	editableRoles: []
};


const reducer = (state = initState, action) => {
	switch (action.type) {
		case FETCH_ROLES:
			return {
				...state,
				roles: action.roles
			};
		case FETCH_ROLES_BY_ID:
			return {
				...state,
				editableRoles: action.editableRoles
			};
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;