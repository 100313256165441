import axios from 'axios';
import moment from 'moment';

const commonFilter = {
    limit: 12,
    order: "month DESC",
};

export const GetClientsWidgetInfo = () => {
    const where = {
        isDisabled: false,
        status: 2
    };
    const promises = [
        new Promise((resolve, reject) => {
			axios.get(`/Clients/count?where=${encodeURIComponent(JSON.stringify(where))}`)
			.then(response => resolve(response.data.count))
			.catch(error => reject(error));
		}),
        new Promise((resolve, reject) => {
			axios.get(`/Analytics_ClientsPerMonths?filter=${encodeURIComponent(JSON.stringify(commonFilter))}`)
			.then(response => resolve(response.data.reverse()))
			.catch(error => reject(error));
        })
    ];
    return Promise.all(promises);
};

export const GetDevicesWidgetInfo = () => {
    const promises = [
        new Promise((resolve, reject) => {
			axios.get(`/Analytics_DevicesPerMonths/getCurrentCount`)
			.then(response => resolve(response.data))
			.catch(error => reject(error));
		}),
        new Promise((resolve, reject) => {
			axios.get(`/Analytics_DevicesPerMonths?filter=${encodeURIComponent(JSON.stringify(commonFilter))}`)
			.then(response => resolve(response.data.reverse()))
			.catch(error => reject(error));
        })
    ];
    return Promise.all(promises);
};

const GetLast12MonthDates = () => {
    const datesToLookup = [];
    for (let count = 0; count < 12; count++) {
        const month = moment().set({date: 15, hour: 0}).subtract(count, 'M');
		const startOfMonth = moment(month).startOf('month').toISOString();
        const endOfMonth = moment(month).endOf('month').toISOString();
        datesToLookup.push({
            date: month,
            datePair: [startOfMonth, endOfMonth]
        });
    }
    return datesToLookup;
}

export const FilterInvoicesWidgetInfo = (datesToLookup) => {
    return axios({
        url: `/Invoices/getFilteredInvoicedAmount`,
        method: 'GET',
		params: { datesToLookup }
	})
    .then(response => response.data.reverse());
};

export const GetInvoicesVsPaymentsWidgetInfo = () => FilterInvoicesWidgetInfo(GetLast12MonthDates());

export const GetInvoiceTaxesWidgetInfo = () => FilterInvoiceTaxesWidgetInfo(GetLast12MonthDates());

export const FilterInvoiceTaxesWidgetInfo = (datesToLookup) => {
    return axios({
        url: `/Invoices/getFilteredInArrearsAmount`,
        method: 'GET',
		params: { datesToLookup }
	})
    .then(response => response.data.reverse());
};