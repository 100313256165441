import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import ActivityLog from '../Invoices/ActivityLog';

class ActivityLogModal extends React.Component {

	render() {
		const { showModal, handleCancel, activityLog } = this.props;
		return (
			<Modal
				open={showModal}
				onCancel={handleCancel}
				destroyOnClose={true}
				footer={false}
			>
				<ActivityLog activityLog={activityLog} list />
			</Modal>
		);
	}
}

ActivityLogModal.defaultProps = {
	activityLog: []
};

ActivityLogModal.propTypes = {
	showModal: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired,
	activityLog: PropTypes.array.isRequired
};

export default ActivityLogModal;