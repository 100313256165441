import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Descriptions, Checkbox, Input, message, Select, Spin, Collapse, Divider } from 'antd';
import { getErrorMessages, getJobMessages } from '../../../../constants/messages';
import { SearchReconnectionDevices, SearchAvailableSimCards, CheckPinExistance, GetDeviceModels, GetModelProfiles } from '../../actions';
import BasicInformation from '../DefaultViews/BasicInformation';
import Embolden from '../../../GlobalComponents/Embolden';
import MissingWON from '../DefaultViews/MissingWON';
import DeviceRender from '../DefaultViews/DeviceRender';
import DescriptionsCombiner from '../DefaultViews/DescriptionsCombiner';
import Device from '../../AddUpdateJob/CommonComponents/Device';
import Sim from '../../AddUpdateJob/CommonComponents/Sim';
import Description from '../../AddUpdateJob/CommonComponents/Description';
import DeviceLease from '../../AddUpdateJob/CommonComponents/DeviceLease';
import Icon from '../../AddUpdateJob/CommonComponents/Icon';
import DeviceAccessoriesIntermediator from '../../AddUpdateJob/CommonComponents/DeviceAccessoriesIntermediator';
import { getNestedValue, getObjectInArray } from '../../../../utilities/util';
import { JOBTYPES, JOBSTATUS } from  '../../constants';
import debounce from 'lodash/debounce';
import { REGEX } from '../../../../constants/global';
const { TODO, DOING, MISSINGWON, DONE, CANCELLED, ANNULLED, PROFORMA, INVOICED } = JOBSTATUS;
const { Panel } = Collapse;
const DescriptionItem = Descriptions.Item;
const FormItem = Form.Item;
const Option = Select.Option;

let errorMessages = getErrorMessages(),
    jobMessages = getJobMessages();

const messages = {
	...errorMessages,
	...jobMessages
};

class Reconnection extends React.Component  {

	constructor(props){
        super(props);
		this.checkIfPinExists = debounce(this.checkIfPinExists, 250);
        this.state = {
            deviceModels: [],
            modelsLoading: false,
            deviceProfiles: [],
            profilesLoading: false
        };
    }

	componentDidMount () {
        const { loadDeviceModels, loadDeviceProfiles } = this;
        loadDeviceModels();
        loadDeviceProfiles();
    }

    componentDidUpdate(prevProps) {
        const { isUpdate, saveFields, getField } = this.props;
        if (isUpdate !== prevProps.isUpdate && isUpdate) {
            const description = getField("gps.description");
            const sim = getField("gps.sim");
            const icon = getField("gps.icon");
            const deviceLease = getField("gps.deviceLease") === undefined ? false : true;
            saveFields({ description, sim, icon, deviceLease });
        }
    }

    loadDeviceModels = () => {
        const { getIntl } = this;
        const setLoading = modelsLoading => this.setState({ modelsLoading });
        setLoading(true);
        GetDeviceModels()
        .then(response => this.setState({ modelsLoading: false, deviceModels: response }))
        .catch(() => {
            setLoading(false);
            message.error(getIntl("getDeviceModelsError"));
        });
    }

    loadDeviceProfiles = () => {
        const { getIntl } = this;
        const setLoading = profilesLoading => this.setState({ profilesLoading });
        setLoading(true);
        GetModelProfiles()
        .then(response => this.setState({ profilesLoading: false, deviceProfiles: response }))
        .catch(() => {
            setLoading(false);
            message.error(getIntl("getDeviceModelProfilesError"));
        });
    }

    getIntl = (str, values = {}) => this.props.intl.formatMessage({...messages[str]}, values);

    renderDefaultTodo = () => {
        const { getField } = this.props;
        return <BasicInformation getField={getField}/>;
    }

    renderChargeType = () => {
        const { props, getIntl } = this;
        const { getField } = props;
        const embolden = type => (
            <Descriptions layout="vertical" size="small" bordered column={1}>
                <DescriptionItem label={getIntl("jobTypeInformation", { type: getIntl(JOBTYPES.RECONNECTION.intl) })}>
                    <Embolden header={getIntl("chargeType")} value={getIntl(type)} row/>
                </DescriptionItem>
            </Descriptions>
        );
        const chargeType = getField("invoiceInfo.chargeType");
        switch (chargeType) {
            case 4: return embolden("perCut");
            case 5: return embolden("newClient");
            case 7: return embolden("previousClient");
            default: return embolden("doesNotHave");
        }
    }

    renderTodo = () => {
        const { renderDefaultTodo, renderChargeType } = this;
        return (
            <DescriptionsCombiner>
                {renderDefaultTodo()}
                {renderChargeType()}
            </DescriptionsCombiner>
        );
    }

    renderDoing = () => {
        const { renderTodo, renderCreateDeviceCheckbox, renderDeviceCollapse, renderLeasedOption, renderSimSelector, renderIconPicker, renderDescription, renderDeviceAccessories } = this;
        return (
            <div>
                {renderTodo()}
                {renderCreateDeviceCheckbox()}
                {renderDeviceCollapse()}
                {renderLeasedOption()}
                {renderSimSelector()}
                {renderIconPicker()}
                {renderDescription()}
                {renderDeviceAccessories()}
            </div>
        )
    }

    renderCreateDeviceCheckbox = () => {
		const { props, getIntl } = this;
        const { form, isUpdate, saveFields } = props;
        const { getFieldDecorator, resetFields } = form;
        const onChange = () => {
            let gps, description, sim, icon, pin, imei, dataport, firmwareVersion, deviceModelId, modelProfileId;
            saveFields({ gps, description, sim, icon, pin, imei, dataport, firmwareVersion, deviceModelId, modelProfileId });
            resetFields();
        }
        if (!isUpdate) return (
			<FormItem className="job-no-margin-border-padding">
				{
					getFieldDecorator('createDevice', 
						{
							initialValue: false,
                            valuePropName: 'checked',
                            onChange
						}
					)(<Checkbox>{getIntl("createDevice")}</Checkbox>)
				}
			</FormItem>
        );
    }

    renderDeviceCollapse = () => {
        const { props, renderDeviceForm, renderDeviceCreator } = this;
        const { getFieldValue } = props.form;
        const createDevice = getFieldValue('createDevice');
        return (
            <Collapse className="job-view-reconnect-collapse" bordered={false} activeKey={createDevice ? 2 : 1} destroyInactivePanel>
                {renderDeviceForm()}
                {renderDeviceCreator()}
            </Collapse>
        );
    }

    renderDeviceForm = () => {
        const { renderDeviceSelector } = this;
        return (
            <Panel showArrow={false} key={1}>
                {renderDeviceSelector()}
            </Panel>
        );
    }

    renderDeviceSelector = () => {
        const { form, saveFields, getField, isUpdate } = this.props;
        const { resetFields, getFieldValue } = form;
        const createDevice = getFieldValue('createDevice');
        const save = gps => {
            const description = getNestedValue("description", gps);
            const sim = getNestedValue("sim", gps);
            const icon = getNestedValue("icon", gps);
            saveFields({ gps, description, sim, icon });
            resetFields(["description", "sim", "icon"]);
        }
        const gps = getField("gps");
        const clientId = getField("client.clientId");
        return (
            <Device
                form={form}
                search={(deviceName) => SearchReconnectionDevices(deviceName, 10)}
                device={gps}
                disabled={clientId === undefined || isUpdate}
                onChange={save}
                required={createDevice ? false : true}
            />
        );
    }

    renderDeviceCreator = () => {
        const { renderPin, renderImei, renderDataport, renderFirmwareVersion, renderDeviceModelSelector, renderModelProfileSelector } = this;
        return (
            <Panel showArrow={false} key={2}>
                {renderPin()}
                {renderImei()}
                {renderDataport()}
                {renderFirmwareVersion()}
                {renderDeviceModelSelector()}
                {renderModelProfileSelector()}
                <Divider/>
            </Panel>
        );
    }

    checkIfPinExists = (rule, pin, callback) => {
        const { getIntl } = this;
        if (!pin || pin.length <= 0 ) callback();
        else {
            CheckPinExistance(pin)
            .then(count => {
                if (count) callback(getIntl("notUniquePinError"));
                else callback();
            })
            .catch(() => callback(getIntl("checkPinError")));
        }
    }

    renderPin = () => {
        const { props, getIntl, checkIfPinExists } = this;
        const { form, saveFields, getField } = props;
        const { getFieldDecorator, getFieldValue } = form;
        const createDevice = getFieldValue('createDevice');
        const save = pin => saveFields({ pin });
        const pin = getField("pin");
        return (
            <FormItem 
                label={getIntl("pin")}
                hasFeedback
            >
            {
                getFieldDecorator('pin', 
                    {
                        initialValue: pin,
                        rules: [
                            {
                                required: createDevice ? true : false,
                                whitespace: createDevice ? true : false,
                                message: getIntl("pinError")
                            },
                            {
                                max: 45,
                                message: getIntl("pinLengthError")
                            },
                            {
                                validator: checkIfPinExists
                            }
                        ],
                        onChange: (event) => save(event.target.value)
                    }
                )(
                    <Input className="job-full-component" placeholder={getIntl("pin")}/>
                )
            }
            </FormItem>
        );
    }

    renderImei = () => {
        const { props, getIntl } = this;
        const { form, saveFields, getField } = props;
        const { getFieldDecorator } = form;
        const save = imei => saveFields({ imei });
        const imei = getField("imei");
        return (
            <FormItem label={getIntl("imei")}>
            {
                getFieldDecorator('imei', 
                    {
                        initialValue: imei,
                        rules: [
                            {
                                whitespace: true,
                                pattern: REGEX.imei,
                                message: getIntl("imeiLengthDigits")
                            }
                        ],
                        onChange: (event) => save(event.target.value)
                    }
                )(
                    <Input className="job-full-component" placeholder={getIntl("imei")}/>
                )
            }
            </FormItem>
        );
    }

    renderDataport = () => {
        const { props, getIntl } = this;
        const { form, saveFields, getField } = props;
        const { getFieldDecorator, getFieldValue } = form;
        const createDevice = getFieldValue('createDevice');
        const save = dataPort => saveFields({ dataPort });
        const dataPort = getField("dataPort");
        return (
            <FormItem label={getIntl("dataport")}>
            {
                getFieldDecorator('dataPort', 
                    {
                        initialValue: dataPort,
                        rules: [
                            {
                                required: createDevice ? true : false,
                                whitespace: createDevice ? true : false,
                                message: getIntl("dataportError")
                            },
                            {
                                max: 45,
                                message: getIntl("dataportLengthError")
                            },
                            {
                                pattern: REGEX.number,
                                message: getIntl("dataportTypeError")
                            }
                        ],
                        onChange: (event) => save(event.target.value)
                    }
                )(
                    <Input className="job-full-component" placeholder={getIntl("dataport")}/>
                )
            }
            </FormItem>
        );
    }

    renderFirmwareVersion = () => {
        const { props, getIntl } = this;
        const { form, saveFields, getField } = props;
        const { getFieldDecorator } = form;
        const save = firmwareVersion => saveFields({ firmwareVersion });
        const firmwareVersion = getField("firmwareVersion");
        return (
            <FormItem label={getIntl("firmwareVersion")}>
            {
                getFieldDecorator('firmwareVersion', 
                    {
                        initialValue: firmwareVersion,
                        rules: [
                            {
                                max: 45,
                                message: getIntl("firmwareVersionLengthError")
                            }
                        ],
                        onChange: (event) => save(event.target.value)
                    }
                )(
                    <Input className="job-full-component" placeholder={getIntl("firmwareVersion")}/>
                )
            }
            </FormItem>
        );
    }

    renderDeviceModelSelector = () => {
        const { state, props, getIntl } = this;
        const { deviceModels, modelsLoading, deviceProfiles } = state;
        const { form, saveFields, getField } = props;
        const { getFieldDecorator, resetFields, getFieldValue } = form;
        const save = deviceModelId => {
            const deviceModelProfiles = [];
            deviceProfiles.forEach(profile => {
                if (profile.deviceModelId == deviceModelId) deviceModelProfiles.push(profile);
            });
            const defaultProfile = getObjectInArray('default', true, deviceModelProfiles);
            const modelProfileId = defaultProfile ? defaultProfile.id : undefined;
            saveFields({ deviceModelId, modelProfileId });
            resetFields(["modelProfileId"]);
        }
        const deviceModelId = getField("deviceModelId");
        const createDevice = getFieldValue('createDevice');
        let options = deviceModels.map((model, index) => <Option key={index} value={model.id}>{model.description}</Option>);
        return(
            <FormItem label={getIntl("deviceModel")}>
            {
                getFieldDecorator(`deviceModelId`, 
                    {
                        initialValue: deviceModelId,
                        rules: [{
                            required: createDevice ? true : false,
                            message: getIntl("deviceModelError")
                        }],
                        onChange: save
                    }
                )(
                    <Select
                        className="job-full-component"
                        showSearch
                        placeholder={getIntl("deviceModel")}
                        optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        notFoundContent={modelsLoading ? <Spin size="small" /> : getIntl("notFound")}
                    >
                        {options}
                    </Select>
                )
            }
            </FormItem>
        );
    }

    renderModelProfileSelector = () => {
        const { state, props, getIntl } = this;
        const { deviceProfiles, profilesLoading } = state;
        const { form, saveFields, getField } = props;
        const { getFieldDecorator, getFieldValue } = form;
        const save = modelProfileId => saveFields({ modelProfileId });
        const modelProfileId = getField("modelProfileId");
        const deviceModelId = getField("deviceModelId");
        const createDevice = getFieldValue('createDevice');
        const options = [];
        if (deviceModelId) {
            deviceProfiles.forEach((profile, index) => {
                const { id, description } = profile;
                if (profile.deviceModelId == deviceModelId) options.push(<Option key={index} value={id}>{description}</Option>);
            });
        }
        return(
            <FormItem label={getIntl("modelProfile")}>
            {
                getFieldDecorator(`modelProfileId`, 
                    {
                        initialValue: modelProfileId,
                        rules: [{
                            required: createDevice ? true : false,
                            message: getIntl("modelProfileError")
                        }],
                        onChange: save
                    }
                )(
                    <Select
                        className="job-full-component"
                        disabled={!deviceModelId}
                        showSearch
                        placeholder={getIntl("modelProfile")}
                        optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        notFoundContent={profilesLoading ? <Spin size="small" /> : getIntl("notFound")}
                    >
                        {options}
                    </Select>
                )
            }
            </FormItem>
        );
    }

    renderDevice = () => {
        const device = this.props.getField("gps");
        if (device) return <DeviceRender device={device}/>
    }

    renderLeasedOption = () => {
        const { form, saveFields, getField } = this.props;
        const save = deviceLease => saveFields({ deviceLease });
        const deviceLease = getField("deviceLease");
        const clientId = getField("client.clientId");
        return (
            <DeviceLease
                form={form}
                deviceLease={deviceLease}
                onChange={save}
                disabled={clientId === undefined}
            />
        );
    }

    renderSimSelector = () => {
        const { form, saveFields, getField } = this.props;
        const save = sim => saveFields({ sim });
        const sim = getField("sim");
        const clientId = getField("client.clientId");
        const assignedSim = getField("gps.sim");
        return (
            <Sim
                form={form}
                search={(simName) => SearchAvailableSimCards(simName, 10)}
                sim={sim}
                disabled={clientId === undefined}
                onChange={save}
                otherIncludedSims={assignedSim}
            />
        );
    }

    renderIconPicker = () => {
        const { form, saveFields, getField } = this.props;
        const save = icon => saveFields({ icon });
        const icon = getField("icon.id");
        const clientId = getField("client.id");
        return (
            <Icon
                form={form}
                icon={icon}
                disabled={clientId === undefined}
                onChange={save}
            />
        );
    }

    renderDescription = () => {
        const { form, saveFields, getField } = this.props;
        const save = description => saveFields({ description });
        const description = getField("description");
        const clientId = getField("client.id");
        return (
            <Description
                form={form}
                description={description}
                disabled={clientId === undefined}
                onChange={save}
            />
        );
    }

    renderDeviceAccessories = () => {
        const { form, saveFields, getField, isUpdate } = this.props;
        return (
            <DeviceAccessoriesIntermediator
                form={form}
                isUpdate={isUpdate}
                getField={getField}
                saveFields={saveFields}
            />
        );
    }

    renderMissingWON = () => {
        const { props, renderDone } = this;
        const { form, jobId } = props;
        return <MissingWON form={form} jobId={jobId} renderInfo={renderDone}/>;
    }

    renderDone = () => {
        const { renderDefaultTodo, renderChargeType, renderDevice } = this;
        return (
            <DescriptionsCombiner>
                {renderDefaultTodo()}
                {renderChargeType()}
                {renderDevice()}
            </DescriptionsCombiner>
        );
    }

    renderBasedOnStatus = () => {
        const { props, getIntl, renderTodo, renderMissingWON, renderDone, renderDoing } = this;
        const { getField, isUpdate } = props;
        if (isUpdate) return renderDoing(); 
        const status = getField("status");
        switch (status) {
            case TODO.id:
            case CANCELLED.id: 
            case ANNULLED.id: return renderTodo();
            case DOING.id: return renderDoing();
            case INVOICED.id:
            case PROFORMA.id:
            case DONE.id: return renderDone();
            case MISSINGWON.id: return renderMissingWON();
            default: return <div>{getIntl("noStatusFormForType")}</div>;
        }
    }
    
	render(){
        return this.renderBasedOnStatus();
	}
}

Reconnection.defaultProps = {
    isUpdate: false
};

Reconnection.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    saveFields: PropTypes.func.isRequired,
    getField: PropTypes.func.isRequired,
    isUpdate: PropTypes.bool.isRequired,
    jobId: PropTypes.number.isRequired,
};

export default injectIntl(Reconnection);