import React from 'react';
import PropTypes from 'prop-types';
import {
	injectIntl,
	FormattedMessage
} from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
	Button,
	Modal,
	InputNumber,
	Input,
	Checkbox,
	Row,
	Col,
	Select,
	Table,
	Badge,
	Spin,
	message,
} from 'antd';
import {
	getModalMessages,
	getPaymentMessages,
	getCreditNoteMessages,
	getFiscalDataMessages,
	getErrorMessages,
	getInvoicingMessages,
	getLanguageIntl,
	getClientMessages,
	getactionMessages
} from '../../constants/messages';
import { connect } from 'react-redux';
import { AddCreditNote, GetCreditNotes, UpdateCreditNote } from './actions';
import { GetClientsPendingInvoices, CleanPendingInvoices } from '../Invoices/actions';
import { GetClientsList, GetAllClients, GetClientInfo } from '../Clients/actions';
import { GetFiscalData } from '../FiscalData/actions';
import { GetExchangeRate } from '../ExchangeRate/actions';
import { roundN } from '../../utilities/util';
import { VARIOUS_CLIENTS_CLIENT_ID } from '../../constants/global';

import moment from 'moment';
const { TextArea } = Input;

let modalMessages = getModalMessages(),
	paymentMessages = getPaymentMessages(),
	creditNoteMessages = getCreditNoteMessages(),
	fiscalDataMessages = getFiscalDataMessages(),
	errorMessages = getErrorMessages(),
	invoiceMessages = getInvoicingMessages(),
	languageMessages = getLanguageIntl(),
	clientMessages = getClientMessages(),
	actionMessages = getactionMessages();

const messages = {
	...modalMessages,
	...paymentMessages,
	...creditNoteMessages,
	...fiscalDataMessages,
	...errorMessages,
	...invoiceMessages,
	...languageMessages,
	...clientMessages,
	...actionMessages
};
const FormItem = Form.Item;
const Option = Select.Option;

class AddCreditNoteModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isDollar: false,
			currentClient: null,
			isLoading: false,
			clientsSelect: [],
			invoicesSelect: [],
			invoicesToPay: [],
			total: 0,
		};
	}

	componentDidMount() {
		const { getAllClients, intl, clients, getExchangeRate } = this.props;
		getAllClients().then(() => {
			this.setState({
				clientsSelect: [...clients]
			});
		}).catch(() => {
			message.error(intl.formatMessage({ ...messages.GetClientsError }));
		});
		getExchangeRate().catch(() => {
			message.error(intl.formatMessage({ ...messages.errorObtainingExchangeRates }));
		});
	}

	componentDidUpdate(prevProps) {
		const { document, showModal } = this.props;
		if (showModal && prevProps.showModal != showModal && document) this.handleSelectClient(document.clientId);
	}

	amountValidator = (rule, value, callback) => {
		if (roundN(value, 2) !== roundN(this.state.total, 2))
			callback(this.props.intl.formatMessage({ ...messages.invoiceTotalError }));
		callback();
	}

	handleSelectInvoices = (selected) => {
		let promise = new Promise((resolve) => {
			this.setState({
				invoicesToPay: selected
			});
			resolve();
		})
		promise.then(() => {
			this.setState({ total: this.calculateTotal() });
			return this.state.total
		}).then(() => {
			this.props.form.validateFields({ force: true }, (err) => {
				if (err)
					return;
			});
		})
	}

	calculateTotal = () => {
		const { invoicesSelect, invoicesToPay } = this.state;
		return invoicesSelect.reduce((total, invoice) => invoicesToPay.includes(invoice.key) ? total + invoice.amount : total, 0);
	}

	handleChangeCheckbox = () => {
		let promise = new Promise((resolve) => {
			this.setState({
				isDollar: !this.state.isDollar
			});
			resolve();
		});
		promise.then(() => {
			this.setState({ total: this.calculateTotal() });
		});
	}

	amountForInvoice = (key, amount) => {
		let invoices = this.state.invoicesSelect;
		invoices[key].amount = amount;
		let promise = new Promise((resolve) => {
			this.setState({
				invoicesSelect: invoices,
				total: this.calculateTotal()
			});
			resolve();
		});
		promise.then(() => {
			this.props.form.validateFields({ force: true }, (err) => {
				if (err)
					return;
			});
		});
	}

	handleValues = (values, invoices) => {
		const { total } = values;
		const { isDollar } = this.state;
		const { fiscalData: { credit: { cai, prefix, currentRValue, initialRValue, finalRValue, expiryDate }},
		currentClientName, currentRTN, fullAddress: { city }, activityLogId, exchangeRate: { rate }} = this.props;
		return {
			...values,
			createdAt: moment(),
			total: isDollar ? total * rate : total,
			salesNumber: `${invoices[invoices.length - 1].documentNumber.slice(12, 19)} ${invoices.length > 1 ? "- " + invoices[0].documentNumber.slice(12, 19) : ""}`,
			salesCai: invoices[invoices.length - 1].cai,
			salesDate: invoices[invoices.length - 1].createdAt,
			cai: cai,
			documentNumber: `${prefix}-${currentRValue.toString().padStart(8, "0")}`,
			fiscalExpiryDate: expiryDate,
			initialRValue: initialRValue.toString(),
			finalRValue: finalRValue.toString(),
			clientName: currentClientName,
			clientRtn: currentRTN,
			clientCity: city,
			isDollar: isDollar,
			exchangeRate: rate,
			activityLogId: activityLogId
		}
	}

	handleOk = () => {
		const { document, addCreditNote, getCreditNotes, form, getFiscalData, intl } = this.props;
		const { invoicesToPay, invoicesSelect } = this.state;
		form.validateFields({ force: true }, (err, values) => {
			if (err)
				return;
			this.setState({ isLoading: true });
			const invoices = invoicesToPay.sort().map((index) => {
				return invoicesSelect[index];
			});
			getFiscalData().then(() => {
				const creditNote = this.handleValues(values, invoices);
				const action = document ? UpdateCreditNote(document.id, creditNote, invoices) : addCreditNote(creditNote, invoices);
				action.then(() => {
					getCreditNotes().then(() => {
						const msg = document ? messages.EditCreditNoteSuccess : messages.AddCreditNoteSuccess
						message.success(intl.formatMessage({ ...msg }))
						this.handleCancel();
					}).catch(() => {
						message.error(intl.formatMessage({ ...messages.GetCreditNotesError }));
					});
				}).catch(() => {
					message.error(intl.formatMessage({ ...messages.AddCreditNoteError }));
					this.setState({ isLoading: false });
				});
			}).catch(() => {
				message.error(intl.formatMessage({ ...messages.GetFiscalDataError }));
				this.setState({ isLoading: false });
			});
		});
	}

	handleCancel = () => {
		const { cleanPendingInvoices, handleCancel } = this.props;
		this.setState({
			isDollar: false,
			isLoading: false,
			total: 0,
			invoicesToPay: [],
			invoicesSelect: []
		});
		cleanPendingInvoices();
		handleCancel();
	}

	handleSelectClient = (client) => {
		const { total, isDollar } = this.state;
		const { document, exchangeRate, getClientsPendingInvoices, intl, getClientInfo } = this.props;
		this.setState({ currentClient: client - 1, isLoading: true });
		if (client !== VARIOUS_CLIENTS_CLIENT_ID) {
			getClientsPendingInvoices(client).then(() => {
				let dataSource = this.props.pendingInvoices.map((invoice, key) => {
					let amountOfInvoice = 0;
					let prevAmount = 0;
					if (document && invoice.clientId == document.clientId) {
						document.creditNoteInvoices.forEach((x) => {
							if (x.invoiceId == invoice.id) {
								if(document.isDollar) amountOfInvoice = x.amount / exchangeRate.rate 
								else amountOfInvoice = x.amount;
								prevAmount = x.amount;
							}
						});
					}
					if (amountOfInvoice != 0) this.setState(prevState => ({ invoicesToPay: [...prevState.invoicesToPay, key] }));
					return { ...invoice, key, isrPreSelected: invoice.isrRetention, isvPreSelected: invoice.isvRetention, amount: amountOfInvoice, prevAmount};
				});
				this.setState({
					invoicesSelect: dataSource,
					total: document ? document.isDollar ? document.total / exchangeRate.rate : document.total : total,
					isLoading: false,
					isDollar: document ? document.isDollar : isDollar,
				});
			});
			getClientInfo(client).catch(() => {
				message.error(intl.formatMessage({ ...messages.GetClientInfoError }));
			});
		}
	}

	mapClients = () => {
		return (
			<Select
				showSearch
				notFoundContent={this.state.isLoading ? <Spin size="small" /> : null}
				filterOption={(input, option) =>
					option.children.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0
				}
				onSelect={this.handleSelectClient}
				style={{ width: '100%' }}
			>
				{
					this.state.clientsSelect.map(function (client, i) {
						return <Option key={i} value={client.id}>{client.name}</Option>;
					})
				}
			</Select>
		);
	}

	convertToDollars = (value, exchangeRate) => {
		return value / exchangeRate;
	}

	convertToLps = (value, exchangeRate) => {
		return value * exchangeRate;
	}

	renderForm = () => {
		const { document, intl, form: { getFieldDecorator }, exchangeRate: { rate }} = this.props;
		const { isDollar, total, invoicesSelect, isLoading, invoicesToPay } = this.state;
		const rowSelection = {
			"onChange": this.handleSelectInvoices,
			"type": "checkbox",
			"selectedRowKeys": this.state.invoicesToPay,
			getCheckboxProps: record => ({
				disabled: record.documentNumber === 'Total'
			}),
		};
		const currency = isDollar ? "$" : "L";
		let columns = [
			{
				title: intl.formatMessage({ ...messages.InvoiceNumber }),
				dataIndex: 'documentNumber',
				key: 'documentNumber',
				width: 50,
				render: (documentNumber, invoice) => {
					return (
						<a className="editable-add-btn" href={`/invoices/${invoice.id}`} target="_blank" rel="noopener noreferrer">
							{documentNumber}
						</a>
					);
				}
			}, {
				title: intl.formatMessage({ ...messages.Status }),
				dataIndex: 'status',
				className: 'status',
				width: 25,
				render: (status) => {
					let color = 'default';
					let text = intl.formatMessage({ ...messages.Active });
					if (status === 1) {
						color = 'success';
						text = intl.formatMessage({ ...messages.Paid });
					} else if (status === 2) {
						color = 'warning';
						text = intl.formatMessage({ ...messages.Overdue });
					} else if (status === 3) {
						color = 'error';
						text = intl.formatMessage({ ...messages.Annulled });
					} else if (status === 4) {
						return "";
					}
					return (
						<Badge status={color} text={text} />
					);
				}
			}, {
				title: intl.formatMessage({ ...messages.Total }),
				dataIndex: 'total',
				key: 'total',
				className: 'column-money',
				width: 150,
				render: (total, invoice) => {
					const value = isDollar ? roundN(total, 2) : roundN(total * invoice.exchangeRate, 2);
					return (
						`${currency} ${value}`
					);
				}
			}, {
				title: 'Balance',
				dataIndex: 'balance',
				key: 'balance',
				className: 'column-money',
				width: 150,
				render: (balance, invoice) => {
					const value = isDollar ? roundN(this.convertToDollars(balance, invoice.exchangeRate), 2) : roundN(balance, 2);
					return (
						`${currency} ${value}`
					);
				}
			}, {
				title: intl.formatMessage({ ...messages.Date }),
				dataIndex: 'createdAt',
				key: 'createdAt',
				render: (date) => {
					let formatedDate = new Date(date);
					formatedDate.toLocaleDateString();
					return (
						formatedDate.toLocaleDateString(intl.formatMessage({ ...messages.intl }), { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
					);
				}
			}, {
				title: intl.formatMessage({ ...messages.Amount }),
				dataIndex: 'amount',
				key: 'amount',
				render: (amount, invoice) => {
					return (
						<InputNumber
							value={amount}
							disabled={!invoicesToPay.includes(invoice.key)}
							onChange={(value) => this.amountForInvoice(invoice.key, value)}
							formatter={value => isDollar ? `$ ${value}` : `L ${value}`}
							parser={value => value.substring(2)}
							step=".01"
							style={{ width: '95%', 'textAlign': 'right' }}
						/>
					);
				}
			}
		];

		return (
			<div>
				<Form layout='vertical'>
					<Row gutter={16} type="flex" align="middle">
						<Col span={12}>
							<FormItem label={intl.formatMessage({ ...messages.Client })}>
								{getFieldDecorator('clientId', {
									rules: [{
										required: true, message: intl.formatMessage({ ...messages.clientError }),
									}],
									initialValue: document ? document.clientId : undefined
								})(
									this.mapClients()
								)}
							</FormItem>
						</Col>
						<Col span={8}>
							<FormItem label={'Total'}>
								{getFieldDecorator('total', {
									rules: [{
										required: true, message: intl.formatMessage({ ...messages.paymentError }),
									}, {
										validator: this.amountValidator
									}],
									initialValue: document ? document.isDollar ? document.total / rate : document.total : total
								})(
									<InputNumber
										formatter={value => isDollar ? `$ ${value}` : `L ${value}`}
										parser={value => value.substring(2)}
										step=".01"
										style={{ width: '95%', 'textAlign': 'right' }}
									/>
								)}
							</FormItem>
						</Col>
						<Col span={4}>
							<Checkbox
								defaultChecked={document ? document.isDollar : false}
								onChange={this.handleChangeCheckbox}
							>
								<FormattedMessage {...messages.Dollars} />
							</Checkbox>
						</Col>
					</Row>
					<Row gutter={16} type="flex" align="middle">
						<Col span={24}>
							<FormItem label={intl.formatMessage({ ...messages.Motive })}>
								{getFieldDecorator('motive', {
									rules: [{
										required: true, message: intl.formatMessage({ ...messages.motiveError }),
									}],
									initialValue: document ? document.motive : ""
								})(
									<Input />
								)}
							</FormItem>
						</Col>
					</Row>
					<Table
						dataSource={invoicesSelect}
						columns={columns}
						rowSelection={rowSelection}
						pagination={false}
						loading={isLoading}
						footer={() =>
							<Row gutter={16} type="flex" align="middle">
								<Col span={12}>
									<FormattedMessage {...messages.total} />
								</Col>
								<Col span={12} style={{ 'textAlign': 'right' }}>
									{`${currency} ${roundN(total, 2)}`}
								</Col>
							</Row>
						}
					/>
					<FormItem label={intl.formatMessage({ ...messages.observations })}>
						{getFieldDecorator('description', {
							rules: [{
								required: false,
							}],
							initialValue: document ? document.description : ""
						})(
							<TextArea rows={4} />
						)}
					</FormItem>
				</Form>

			</div>
		);
	}

	render() {
		const { isLoading } = this.state;
		const { document, showModal, intl } = this.props;
		return (
			<React.Fragment>
				<Modal
					title={document ? intl.formatMessage({ ...messages.EditCreditNote }) : intl.formatMessage({ ...messages.AddCreditNote })}
					destroyOnClose
					open={showModal}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					confirmLoading={isLoading}
					width={1100}
					footer={
						<div>
							<Button type="primary" size="large" loading={isLoading} onClick={this.handleOk} className="button-spacing">
								<FormattedMessage {...messages.send} />
							</Button>
							<Button key="back" size="large" onClick={this.handleCancel}>
								<FormattedMessage {...messages.cancel} />
							</Button>
						</div>
					}
				>
					{this.renderForm()}
				</Modal>
			</React.Fragment>
		);
	}

}

AddCreditNoteModal.defaultProps = {
	activityLogId: 0
};

AddCreditNoteModal.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	pendingInvoices: PropTypes.array.isRequired,
	showModal: PropTypes.bool.isRequired,
	fiscalData: PropTypes.object.isRequired,
	exchangeRate: PropTypes.object.isRequired,
	handleCancel: PropTypes.func.isRequired,
	getClientsPendingInvoices: PropTypes.func.isRequired,
	getClientsList: PropTypes.func.isRequired,
	getAllClients: PropTypes.func.isRequired,
	getFiscalData: PropTypes.func.isRequired,
	addCreditNote: PropTypes.func.isRequired,
	getCreditNotes: PropTypes.func.isRequired,
	clients: PropTypes.array.isRequired,
	getExchangeRate: PropTypes.func.isRequired,
	currentClientName: PropTypes.string.isRequired,
	currentRTN: PropTypes.string.isRequired,
	fullAddress: PropTypes.object.isRequired,
	cleanPendingInvoices: PropTypes.func.isRequired,
	getClientInfo: PropTypes.func.isRequired,
	document: PropTypes.object,
	activityLogId: PropTypes.number,
};

const mapStateToProps = (state) => {
	return {
		pendingInvoices: state.invoicing.pendingInvoices,
		clients: state.client.clients,
		currentClientName: state.client.currentClientName,
		currentRTN: state.client.currentRTN,
		fullAddress: state.client.fullAddress,
		fiscalData: state.fiscalData.fiscalData,
		exchangeRate: state.exchangeRate.exchangeRate,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addCreditNote: (creditNote, invoices) => {
			return dispatch(AddCreditNote(creditNote, invoices));
		},
		getCreditNotes: () => {
			return dispatch(GetCreditNotes());
		},
		getClientsPendingInvoices: (clientId) => {
			return dispatch(GetClientsPendingInvoices(clientId));
		},
		cleanPendingInvoices: () => {
			return dispatch(CleanPendingInvoices());
		},
		getClientsList: (searchText) => {
			return dispatch(GetClientsList(searchText));
		},
		getAllClients: () => {
			return dispatch(GetAllClients());
		},
		getClientInfo: (clientId) => {
			return dispatch(GetClientInfo(clientId));
		},
		getFiscalData: () => {
			return dispatch(GetFiscalData());
		},
		getExchangeRate: () => {
			return dispatch(GetExchangeRate());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(AddCreditNoteModal)));
