import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'antd';
import { injectIntl } from 'react-intl';
import { getCommissionMessages } from '../../constants/messages';
import { STATUS } from './constants';
const { PENDING, APPROVED, REJECTED, PAID } = STATUS;

const commissionMessages = getCommissionMessages();
const messages = { ...commissionMessages };

class CommissionBadge extends React.Component {

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	render() {
		const { props, getIntl } = this;
		const { status } = props;
		let badgeStatus, text;
		switch (status) {
			case PENDING:
				badgeStatus = "warning";
				text = getIntl("pending");
				break;
			case APPROVED:
				badgeStatus = "processing";
				text = getIntl("approved");
				break;
			case REJECTED:
				badgeStatus = "error";
				text = getIntl("rejected");
				break;
			case PAID:
				badgeStatus = "success";
				text = getIntl("paid");
				break;
			default:
				badgeStatus = "default";
				text = getIntl("noStatus");
				break;
		}
		return <Badge status={badgeStatus} text={text} />;
	}

}

CommissionBadge.propTypes = {
	intl: PropTypes.object.isRequired,
	status: PropTypes.number
};

export default injectIntl(CommissionBadge);