import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getInvoicingMessages, getFiscalDataMessages, getactionMessages, getPaymentMessages } from '../../constants/messages';
import GeneralAddPaymentModal from './GeneralAddPaymentModal';

let invoicingMessages = getInvoicingMessages(),
	fiscalDataMessages = getFiscalDataMessages(),
	actionMessages = getactionMessages(),
	paymentMessages = getPaymentMessages();

let messages = {
	...invoicingMessages,
	...fiscalDataMessages,
	...actionMessages,
	...paymentMessages
};

class GeneralAddPaymentButton extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showPaymentModal: false
		};
	}

	openPaymentModal = () => {
		this.setState({
			showPaymentModal: true
		});
	}

	closePaymentModal = () => {
		this.setState({
			showPaymentModal: false
		});
	}

	render() {
		return (
			<span>
				<GeneralAddPaymentModal
					showPaymentModal={this.state.showPaymentModal}
					closePaymentModal={this.closePaymentModal}
					isDebitNote={this.props.isDebitNote}
				/>
				{this.displayButton()}
			</span>
		);
	}

	displayButton() {
		if (this.props.isDebitNote || this.props.displayButton)
			return <Button className="editable-add-btn" onClick={this.openPaymentModal} style={{'marginRight': '8px'}} type="primary" ghost>
				<FormattedMessage {...messages.AddPayment} />
			</Button>;
		return <div> <a onClick={this.openPaymentModal}> {<FormattedMessage {...messages.AddPayment} />} </a>  </div>
	}
}


GeneralAddPaymentButton.propTypes = {
	intl: PropTypes.object.isRequired,
	isDebitNote: PropTypes.bool,
	displayButton: PropTypes.bool,
};



export default injectIntl(GeneralAddPaymentButton);