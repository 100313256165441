import React from 'react';
import PropTypes from 'prop-types';
import { MinusOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const RemoveButton = ({removeFunction}) => {
    return <Button shape="circle" icon={<MinusOutlined />} size="small" onClick={removeFunction}/>; 
}

RemoveButton.propTypes = {
    removeFunction: PropTypes.func.isRequired
};
export default RemoveButton