import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import AddDebitNoteModal from './AddDebitNoteModal';
import {injectIntl, FormattedMessage} from 'react-intl';
import {getactionMessages} from '../../constants/messages';

let messages = getactionMessages();

class AddDebitNoteButton extends React.Component  {

	constructor(props) {
		super(props);
		this.state = {
			showModal: false
		};
	}

	openModal = () => {
		this.setState({
			showModal: true
		});
	}

	closeModal = () => {
		this.setState({
			showModal: false
		});
	}

	render(){
		return (
            <span>
				<Button className="editable-add-btn" type="primary" onClick={this.openModal} style={{'marginRight': '8px'}}>
					<PlusOutlined />
					<FormattedMessage {...messages.add} />
				</Button>
				<AddDebitNoteModal
					showModal={this.state.showModal}
					handleCancel={this.closeModal}
				/>
			</span>
        );
	}
}

export default ( injectIntl(AddDebitNoteButton));