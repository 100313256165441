import React, { useState} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Select, Table, Divider, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import { getEmployeeMessages, getPayrollMessages, getLanguageIntl, getFields, getactionMessages } from '../../../constants/messages';
import AddButton from '../../Clients/AddOrUpdate/DynamicComponents/AddButton';
import { formatPrice } from '../constants';
import DeductionModal from './DynamicComponents/DeductionModal';
import { cloneDeep } from 'lodash';


const moment = require('moment');

const Option = Select.Option;

let employeeMessages = getEmployeeMessages(),
    payrollMessages = getPayrollMessages(),
    intlMessages = getLanguageIntl(),
    fieldMessages = getFields(),
    actionMessages = getactionMessages();

const messages = {
    ...employeeMessages,
    ...payrollMessages,
    ...intlMessages,
    ...fieldMessages,
    ...actionMessages
};

const Deductions = ({saveDeductions, deductions}) => {

    const intl = useIntl();
    const [showModal, setShowModal] = useState(false);
    const [deduction, setDeduction] = useState({});
    const [isUpdate, setIsUpdate] = useState(false);
    const [deductionOptions, setDeductionOptions] = useState([]);
    const [index, setIndex] = useState(-1);
    const allDeductions = useSelector((state) => state.payroll.deductions)
    const dataSource = Array.isArray(deductions) ? deductions.map((deduction, index) =>{
        return ({ ...deduction, key: index })}) : deductions;

  
    const getIntl = (str) => {
        return intl.formatMessage({ ...messages[str] });
    }
    const title = getIntl("deductions");
    const dateRender = (date) => {
        if (!date) return;
        const m = moment(date).utc();
        return new Date(m.year(), m.month(), m.date()).toLocaleDateString(getIntl("intl"));
    }
    const openAddModal = () => {
        setShowModal(true);
        setDeduction({});
        setIsUpdate(false);
        setDeductionOptions(getDeductionOptions());
    }

    const openEditModal = (index) => {
        setDeduction({...dataSource[index]});
        setIndex(index);
        setIsUpdate(true);
        setDeductionOptions(getDeductionOptions());
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
        setDeduction({});
        setIsUpdate(false);
        setDeductionOptions([]);
    }

    const addDeduction = (deduction) => {
        let dataCopy = cloneDeep(deductions)
        dataCopy = [...dataCopy,deduction]
        saveDeductions(dataCopy);

    }

    const editDeduction = (deduction) => {
        let dataCopy = cloneDeep(deductions)
        dataCopy[index] = deduction;
        saveDeductions(dataCopy);
    }

    const remove = (index) => {
        let dataCopy = cloneDeep(deductions)
        dataCopy.splice(index, 1);
        saveDeductions(dataCopy);
    }


    const renderEditButton = (index) => {
        return (
            <a onClick={() => openEditModal(index)}>
                <span className="icon-pencil" />
                {getIntl("edit")}
            </a>
        );
    }

    const renderDeleteButton = (index) => {
        return (
            <a onClick={() => remove(index)}>
                <DeleteOutlined />
                {getIntl("delete")}
            </a>
        );
    }

    const getDeductionOptions = () => {
        const options = [];
        const pushDeduction = (deduction) => {
            const { id, name } = deduction;
            options.push(<Option key={id} value={id}>{name}</Option>);
        }
        allDeductions.forEach(deduction => pushDeduction(deduction));
        return options;
    }

    const deductionNameRender = (fullDeduction) => {
        if (fullDeduction.comment) return <span>{fullDeduction.deduction ? fullDeduction.deduction.name: ""} <Tooltip title={fullDeduction.comment}><QuestionCircleOutlined style={{ color: '#18a600' }} /></Tooltip></span>;
        return fullDeduction.deduction ?  fullDeduction.deduction.name: ""
    }

    const getColumns = () => {
        return (
            [
                {
                    title: getIntl("deduction"),
                    key: 'name',
                    render: (deduction) => deductionNameRender(deduction)
                },
                {
                    title: getIntl("amount"),
                    dataIndex: 'amount',
                    key: 'amount',
                    render: (amount) => formatPrice(amount)
                },
                {
                    title: getIntl("initialDate"),
                    dataIndex: 'initialDate',
                    key: 'initialDate',
                    render: (initialDate) => dateRender(initialDate)
                },
                {
                    title: getIntl("finalDate"),
                    dataIndex: 'finalDate',
                    key: 'finalDate',
                    render: (finalDate) => finalDate ? dateRender(finalDate) : getIntl("noFinalDate")
                },
                {
                    title: getIntl("recurrence"),
                    dataIndex: 'monthlyRecurrence',
                    key: 'monthlyRecurrence',
                    render: (monthlyRecurrence) => monthlyRecurrence ? getIntl("monthly") : getIntl("biweekly")
                },
                {
                    title: getIntl("actions"),
                    // eslint-disable-next-line react/display-name
                    render: (_, record) => {
                        return (
                            <span>
                                {renderEditButton(record.key)}
                                <Divider type="vertical" />
                                {renderDeleteButton(record.key)}
                            </span>
                        );
                    }
                }
            ]
        );
    }
   
  return (
    <div>
        <div>
            <DeductionModal
                isUpdate={isUpdate}
                setIsUpdate = {setIsUpdate}
                addDeduction={addDeduction}
                editDeduction = {editDeduction}
                showModal={showModal}
                deduction={deduction}
                deductionOptions={deductionOptions}
                allDeductions={allDeductions}
                handleCancel={closeModal}
            />
            <Divider>{title}</Divider>
            <Table
                columns={getColumns()}
                dataSource={[...dataSource]}
                pagination={false}
                scroll={{ x: '100%' }}
                bordered
            />
        </div>
        <div>
            <AddButton className="employee-deductions-button" text={getIntl("addDeduction")} addFunction={openAddModal} />
        </div>
    </div>
    
    
  )
}

Deductions.defaultProps = {
    deductions: [],
    allDeductions: []
};

Deductions.propTypes = {
    saveDeductions: PropTypes.func.isRequired,
    deductions: PropTypes.array.isRequired,
};
export default Deductions
