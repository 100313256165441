import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, message } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { getFields, getPayrollMessages, getactionMessages } from '../../../../../constants/messages';
import { GetISRRoofs } from '../../../actions';
import { formatPrice } from '../../../constants';
import DeductionsTable from '../DeductionsTable';
import EditIsrModal from './EditIsrModal';

let PayrollsMessages = getPayrollMessages(),
	FieldsMessages = getFields(),
	ActionMessages = getactionMessages();

const messages = {
	...PayrollsMessages,
	...FieldsMessages,
	...ActionMessages,
};

const Isr = () => {
	const intl = useIntl();
    const dispatch = useDispatch();
	const [isLoading, setLoading] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
	const [recordToEdit, setRecordToEdit] = useState({});
	const isrRoofs = useSelector((state) => state.payroll.isrRoofs);

    useEffect(() => {
        const fetchData = async () => {
			try {
				setLoading(true);
				await dispatch(GetISRRoofs());
			} catch (error) {
				message.error(intl.formatMessage({ ...messages.getISRRoofsError }));
			} finally {
				setLoading(false);
			}
		};
		fetchData();
    }, []);

    const editRecord = (record) => {
		setRecordToEdit(record);
		setOpenEditModal(true);
	};

	const tableActions = (record) => {
		return (
            <Typography.Link
                className="editable-add-btn"
                onClick={() => editRecord(record)}
            >
                <span className="icon-pencil" />
                <FormattedMessage {...messages.edit} />
            </Typography.Link>
		);
	};

    const columns = [
		{
			title: intl.formatMessage(({ ...messages.minAmount})),
            dataIndex: 'minAmount',
            key: 'minAmount',
            render: (minAmount) => formatPrice(minAmount)
		},
        {
            title: intl.formatMessage(({ ...messages.maxAmount})),
            dataIndex: 'maxAmount',
            key: 'maxAmount',
            render: (maxAmount) => maxAmount === -1 ? intl.formatMessage(({ ...messages.onwards})) : formatPrice(maxAmount)
        },
        {
            title: intl.formatMessage(({ ...messages.percentage})),
            dataIndex: 'percentage',
            key: 'percentage',
            render: (percentage) => `${percentage}%`
        },
		{
			title: intl.formatMessage(({ ...messages.actions})),
			dataIndex: 'id',
			render: (id, record, index) => tableActions({...record, index, minDisabled: index == 0, maxDisabled: index == isrRoofs.length-1})
		},
	];

    return (
        <React.Fragment>
            <DeductionsTable
                dataSource={isrRoofs}
                columns={columns}
                isLoading={isLoading}
                count={isrRoofs.length}
                pageSize={isrRoofs.length} 
            />
            <EditIsrModal 
                open={openEditModal}
                record={recordToEdit}
                title={intl.formatMessage({ ...messages.edit })}
                closeModal={() => setOpenEditModal(false)} 
            />
        </React.Fragment>
    )
}

export default Isr
