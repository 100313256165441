import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Modal, message, Spin, Table, Badge, Tooltip } from 'antd';
import { getModalMessages, getInvoicingMessages, getDebitNoteMessages } from '../../constants/messages';
import { LoadClientUnpaidInvoices, EmailAccountStatus, PreviewAccountStatus, DownloadAccountStatus } from './actions';
import { LoadClientUnpaidDebitNotes } from '../DebitNotes/actions';
import Client from '../Jobs/Calendar/AdvancedSearch/Components/Client';
import { roundN } from '../../utilities/util';
import moment from 'moment';
import { STATUSES } from './constants';
import EmailSelector from '../../components/EmailSelector';
import PopoverProductsTable from './PopoverProductsTable';
import EllipsisTooltip from '../../components/EllipsisTooltip';
import { GetEmails } from '../Clients/actions';

let modalMessages = getModalMessages(),
    invoicingMessages = getInvoicingMessages(),
    debitNoteMessages = getDebitNoteMessages();

const messages = {
    ...modalMessages,
    ...invoicingMessages,
    ...debitNoteMessages
};

const DOCUMENT_TYPE = {
    DEBITNOTE: 1,
    INVOICE: 2
};

const defualtState = {
    isLoading: false,
    clientId: 0,
    clientName: "",
    invoices: [],
    debitNotes: [],
    selectedInvoices: [],
    selectedDebitNotes: [],
    selectedRows: [],
    showEmailModal: false
};

class ClientInvoiceStatementModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ...defualtState
        };
    }

    getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
    setLoading = (isLoading) => this.setState({ isLoading });

    handleOk = () => {
        this.checkSelectedIds();
        this.setState({ showEmailModal: true });
    }

    handleCancel = () => {
        const { handleCancel } = this.props;
        this.setState({ ...defualtState });
        handleCancel();
    }

    renderInvoiceTable = () => {
        const { state, props, getIntl } = this;
        const { invoices, selectedRows, debitNotes, selectedInvoices, selectedDebitNotes } = state;
        const clientNameWidth = 200;
        const columns = [
            {
                title: getIntl("DocumentNumber"),
                dataIndex: 'documentNumber',
                key: 'documentNumber',
                render: (documentNumber, invoice) => {
                    const { id: invoiceId, invoiceProducts = [] } = invoice;
                    if (invoiceProducts.length > 1) {
                        const number = 'history' in props ? <a onClick={() => this.props.history.push(`/invoices/${invoiceId}`)}>{documentNumber}</a> : documentNumber;
                        return (
                            <PopoverProductsTable products={invoiceProducts}>
                                {number}
                            </PopoverProductsTable>
                        )
                    }
                    return documentNumber;
                }
            }, {
                title: getIntl("Type"),
                dataIndex: "type",
                key: "type",
                render: (type) => {
                    switch (type) {
                        case DOCUMENT_TYPE.DEBITNOTE:
                            return getIntl("DebitNote")
                        case DOCUMENT_TYPE.INVOICE:
                            return getIntl("Invoice")
                        default:
                            return ""
                    }
                }
            }, {
                title: getIntl("Client"),
                dataIndex: 'clientName',
                key: 'clientName',
                width: clientNameWidth,
                onCell: () => ({ style: { whiteSpace: 'nowrap', maxWidth: clientNameWidth } }),
                render: clientName => <EllipsisTooltip title={clientName}>{clientName}</EllipsisTooltip>
            }, {
                title: getIntl("Status"),
                dataIndex: 'status',
                key: 'status',
                className: 'status',
                render: status => {
                    let color = 'default';
                    if (status === STATUSES.PAID) color = 'success';
                    else if (status === STATUSES.OVERDUE) color = 'warning';
                    else if (status === STATUSES.ANNULLED) color = 'error';
                    return <Badge status={color} />;
                }
            }, {
                title: getIntl("Date"),
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: date => moment(date).locale('es').format('L')
            }, {
                title: getIntl("paymentDate"),
                dataIndex: 'paymentDate',
                key: 'paymentDate',
                render: date => moment(date).locale('es').format('L')
            }, {
                title: getIntl("expiredDays"),
                key: 'expiredDays',
                render: invoice => {
                    const { paymentDate } = invoice;
                    const today = moment();
                    const paymentMoment = moment(paymentDate);
                    const expiredDays = today.diff(paymentMoment, 'days');
                    return expiredDays < 0 ? 0 : expiredDays;
                }
            }, {
                title: getIntl("Total"),
                dataIndex: 'total',
                key: 'total',
                className: 'column-money',
                render: (total, invoice) => {
                    if (invoice.exchangeRate)
                        return `L ${roundN(total * invoice.exchangeRate, 2)}`
                    return `L ${total}`
                }
            }, {
                title: 'Balance',
                dataIndex: 'balance',
                key: 'balance',
                className: 'column-money',
                render: balance => `L ${roundN(balance, 2)}`
            }
        ];

        const dataDebitNotes = debitNotes.map(debitNote => ({ ...debitNote, key: debitNote.id, type: DOCUMENT_TYPE.DEBITNOTE }));
        const dataInvoices = invoices.map(invoice => ({ ...invoice, key: invoice.id, type: DOCUMENT_TYPE.INVOICE }));
        const dataSource = dataInvoices.concat(dataDebitNotes);
        const rowSelection = {
            selectedRowsKeys: selectedRows,
            onChange: (selectedRows) => {
                this.setState({ selectedRows })
                selectedRows.forEach(id => {
                    invoices.find(invoice => {
                        if (invoice.id == id) {
                            if (!selectedInvoices.includes(id)) 
                                this.setState(prevState => ({ selectedInvoices: [...prevState.selectedInvoices, id] }))
                        } else {
                            if (!selectedDebitNotes.includes(id))
                                this.setState(prevState => ({ selectedDebitNotes: [...prevState.selectedDebitNotes, id] }))
                        }
                    })
                })
            },
        };
        return <Table columns={columns} dataSource={dataSource} rowSelection={rowSelection} pagination={false} />;
    }

    onClientChange = async (newClient) => {
        const { props, getIntl, setLoading } = this;
        const { getEmails } = props;
        const { id: clientId, name: clientName } = newClient;
        this.setState({ clientId, clientName });
        setLoading(true);
        try{
            const invoices = await LoadClientUnpaidInvoices(clientId);
            try{
               const debitNotes = await LoadClientUnpaidDebitNotes(clientId);
                    this.setState({
                        invoices,
                        debitNotes,
                        isLoading: false,
                        selectedInvoices: [],
                        selectedDebitNotes: [],
                        selectedRows: []
                    });
            }catch(error){
                setLoading(false);
                message.error("error");
            }
        }catch(error){
            setLoading(false);
            message.error(getIntl("loadClientUnpaidInvoicesError"));
        }
        getEmails(clientId);
    }

    renderForm = () => {
        const { state, props, onClientChange, renderInvoiceTable } = this;
        const { isLoading } = state;
        const { form } = props;
        return (
            <Spin spinning={isLoading}>
                <Client form={form} onChange={onClientChange} allowClear={false} />
                {renderInvoiceTable()}
            </Spin>
        )
    }

    checkSelectedIds = () => {
        const { selectedRows, selectedInvoices, selectedDebitNotes } = this.state;
        const filterInvoices = selectedInvoices.filter((id) => selectedRows.includes(id));
        const filterDebitNotes = selectedDebitNotes.filter((id) => selectedRows.includes(id));
        this.setState({ selectedInvoices: filterInvoices, selectedDebitNotes: filterDebitNotes });
    }

    emailAccountStatus = (clientId, selectedEmails, userEmail, emailMessage) => {
        const { state, props } = this;
        const { exchangeRate: { rate } } = props;
        const { selectedInvoices, selectedDebitNotes } = state;
        return EmailAccountStatus(clientId, selectedInvoices, selectedDebitNotes, rate, selectedEmails, userEmail, emailMessage);
    }

    previewAccountStatus = () => {
        const { state, props } = this;
        const { exchangeRate: { rate } } = props;
        const { clientId, selectedInvoices, selectedDebitNotes } = state;
        return PreviewAccountStatus(clientId, selectedInvoices, selectedDebitNotes, rate);
    }

    downloadAccountStatus = () => {
        const { state, props } = this;
        const { exchangeRate: { rate } } = props;
        const { clientId, clientName, selectedInvoices, selectedDebitNotes } = state;
        return DownloadAccountStatus(clientId, selectedInvoices, clientName, selectedDebitNotes, rate);
    }

    setupEmailSelector = () => {
        const { state, emailAccountStatus, previewAccountStatus, downloadAccountStatus } = this;
        const { showEmailModal, clientId } = state;
        return (
            <EmailSelector
                addOrShow={false}
                showEmailModal={showEmailModal}
                closeModal={() => this.setState({ showEmailModal: false })}
                document={{ id: clientId }}
                emailDocument={emailAccountStatus}
                downloadPreview={() => previewAccountStatus()}
                downloadDocument={() => downloadAccountStatus()}
            />
        )
    }

    renderedButtons = () => {
        const { state, handleOk, handleCancel, getIntl } = this;
        const { isLoading, clientId, selectedRows } = state;
        const disableOk = clientId === 0 || selectedRows.length < 1 ;
        const okButton = <Button key="submit" disabled={disableOk} loading={isLoading} type="primary" onClick={handleOk}>{getIntl("sendInvoiceStatement")}</Button>;
        const buttons = [<Button key="cancel" disabled={isLoading} onClick={handleCancel}>{getIntl("cancel")}</Button>];
        if (disableOk) buttons.push(<Tooltip key="submit" title={getIntl("generateInvoiceStatementTooltip")}><span style={{ marginLeft: '8px' }}>{okButton}</span></Tooltip>);
        else buttons.push(okButton);
        return buttons;
    }

    render() {
        const { state, props, getIntl, handleOk, handleCancel, renderedButtons, renderForm, setupEmailSelector } = this;
        const { isLoading} = state;
        const { showModal } = props;
        return (
            <Modal
                open={showModal}
                title={getIntl("invoiceStatements")}
                onOk={handleOk}
                onCancel={handleCancel}
                destroyOnClose
                footer={renderedButtons()}
                maskClosable={!isLoading}
                keyboard={!isLoading}
                closable={!isLoading}
                width={1200}
            >
                {setupEmailSelector()}
                {renderForm()}
            </Modal>
        );
    }
}

ClientInvoiceStatementModal.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    getEmails: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    showModal: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    exchangeRate: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
	return {
		exchangeRate: state.exchangeRate.exchangeRate,
	};
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEmails: id => dispatch(GetEmails(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(Form.create()(ClientInvoiceStatementModal))));
