import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { injectIntl } from 'react-intl';
import { getClientMessages } from '../../../../constants/messages';
import DraggerList from '../../../GlobalComponents/DraggerList';
import RuleEntry from './RuleEntry';
import AddButton from '../../AddOrUpdate/DynamicComponents/AddButton';
const cloneDeepWith = require('lodash.clonedeepwith');
import { RuleTypes } from '../../constants';
import { GetClientInvoiceRulesInfo } from '../../actions';

let clientMessages = getClientMessages();

const messages = {
	...clientMessages
};

const defaultRule = {
    alias: undefined,
    type: RuleTypes.producto.id,
    priority: undefined,
    productId: undefined,
    devices: [],
    devicesExpanded: false,
    active: true,
    recurrence: 1
};

class RulesForm extends React.Component  {

	constructor(props) {
		super(props);
		this.state = {
            editingIndex: -1,
            allDevices: [],
            clientName: ""
        };
	}

	componentDidMount () {
        const { props, getIntl, setData } = this;
        const { setLoading, clientId, errorClose } = props;
        setLoading(true);
        GetClientInvoiceRulesInfo(clientId)
        .then((response) => {
            setLoading(false);
            if (response === undefined) {
                message.error(getIntl("rulesInfoLoadError"));
                errorClose();
            }
            else {
                const { name, devices, rules } = response;
                this.setState({ allDevices: devices || [], clientName: name });
                setData(rules);
            }
        })
        .catch(() => {
            setLoading(false);
            message.error(getIntl("rulesInfoLoadError"));
            errorClose();
        });
    }
    
    getData = () => {
        const { getFieldValue } = this.props.form;
        return getFieldValue('data');
    }

    setData = (data) => {
        const { setFieldsValue } = this.props.form;
        setFieldsValue({ data });
    }

	getIntl = (str) => {
		return this.props.intl.formatMessage({...messages[str]});
    }

    dataUpdate = (newData) => {
        const { setData } = this;
        const data = newData.map((rule, index) => ({ ...rule, priority: index  + 1 }));
        setData(data);
    }

    addRule = () => {
        const { state, props, getData, setData, getIntl } = this;
        const data = getData();
        const { editingIndex } = state;
        const { setEditing } = props;
        if (editingIndex === -1) {
            const dataCopy = Array.from(data);
            const defaultClone = cloneDeepWith(defaultRule);
            defaultClone.priority = dataCopy.length + 1;
            dataCopy.push(defaultClone);
            this.setState({ editingIndex: (dataCopy.length - 1) });
            setEditing(true);
            setData(dataCopy);
        } else message.warning(getIntl("clientRulesAddTooltip"));
    }
   
    removeRule = (index) => {
        const { props, getData, setData } = this;
        const { setEditing } = props;
        const data = getData();
        let dataCopy = Array.from(data);
        dataCopy.splice(index, 1);
        dataCopy = dataCopy.map((rule, index) => ({ ...rule, priority: index + 1 }));
        this.setState({ editingIndex: -1 });
        setEditing(false);
        setData(dataCopy);
    }

    saveField = (value, field, index) => {
        const { getData, setData } = this;
        const data = getData();
        const dataCopy = Array.from(data);
        dataCopy[index][field] = value;
        setData(dataCopy);
    }

    saveAlias = (value, index) => {
        const { state, saveField } = this;
        const { clientName } = state;
        if (value && value.length > 0 && value !== clientName)saveField(value, "alias", index);
        else saveField(undefined, "alias", index);
    }

    saveType = (value, index) => this.saveField(value, "type", index);
    saveProductId = (value, index) => this.saveField(value, "productId", index);
    saveDevices = (value, index) => this.saveField(value, "devices", index);
    saveDevicesExpanded = (value, index) => this.saveField(value, "devicesExpanded", index);
    saveActive = (value, index) => this.saveField(value, "active", index);
    saveRecurrence = (value, index) => this.saveField(value, "recurrence", index);
    saveLastInvoiced = (value, index) => this.saveField(value, "lastInvoiced", index);

    setIsEditing = (editing) => {
        const { setEditing } = this.props;
        this.setState({editingIndex: editing});
        setEditing(editing !== -1);
    }

    renderList = () => {
        const { state, props, saveAlias, removeRule, saveType, saveProductId, saveDevices, dataUpdate, getData, saveDevicesExpanded, setIsEditing, saveActive, saveRecurrence, saveLastInvoiced } = this;
        const { editingIndex, allDevices, clientName } = state;
        const { form } = props;
        const data = getData();
        return (
            <DraggerList
                data={data}
                entryRender={(entry, index) => <RuleEntry form={form} entry={entry} index={index} editingIndex={editingIndex}
                    setIsEditing={setIsEditing} remove={() => removeRule(index)} allDevices={allDevices}
                    saveDevicesExpanded={(value => saveDevicesExpanded(value, index))} clientName={clientName}
                    saveAlias={(value => saveAlias(value, index))} saveType={(value => saveType(value, index))}
                    saveProductId={(value => saveProductId(value, index))} saveDevices={(value => saveDevices(value, index))}
                    saveActive={(value => saveActive(value, index))} saveRecurrence={(value => saveRecurrence(value, index))} saveLastInvoiced={(value => saveLastInvoiced(value, index))}/>}
                dataUpdate={dataUpdate}
                draggable={editingIndex === -1}
            />
        );
    }

    renderAddButton = () => {
        const { getIntl, addRule } = this;
        return <AddButton text={getIntl("addRule")} addFunction={addRule}/>;
    }

    fullRender = () => {
        const { renderList, renderAddButton } = this;
        return (
            <div className="client-rules-dragger-list">
                {renderList()}
                {renderAddButton()}
            </div>
        );
    }

	render(){
		return this.fullRender();
	}
}

RulesForm.propTypes = {
	intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    clientId: PropTypes.number.isRequired,
	setLoading:  PropTypes.func.isRequired,
	setEditing:  PropTypes.func.isRequired,
	errorClose:  PropTypes.func.isRequired,
};

export default injectIntl(RulesForm);