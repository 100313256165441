import Exception from 'ant-design-pro/lib/Exception';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Countdown from 'react-countdown';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { ConfirmEmail } from '../actions/AuthAction';

class Confirm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            hasError: false,
            message: "...loading"
        };

        const params = new URLSearchParams(this.props.location.search);
        const uid = params.get('uid');
        const token = params.get('token');

        this.props.ConfirmEmail({ uid, token })
            .then((err) => {
                if (!err) return
                this.setState({
                    message: <span>
                        Your account has been activated! you will be redirected in <Countdown date={Date.now() + 10000} renderer={this.renderer} /> seconds.
                    </span>
                })
            }).catch((err) => {
                if (!err) return
                this.setState({
                    hasError: true,
                    message: "The link you are using is no longer valid!"
                })
            }).then(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    renderer = ({ seconds, completed }) => {
        if (completed) {
            this.props.history.push("/login")
        } else {
            return <span>{seconds}</span>;
        }
    }

    render() {
        let { hasError, message } = this.state;
        return (
            <Exception title="Account Confirmation" type={hasError ? "500" : "404"} desc={message} actions={[]} />
        )
    }
}

Confirm.propTypes = {
    ConfirmEmail: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
}

const mapDispatchToProps = dispatch => bindActionCreators({ ConfirmEmail, history }, dispatch);


export default connect(null, mapDispatchToProps)(withRouter(Confirm));