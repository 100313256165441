import PropTypes from 'prop-types';
import { ReconciliationOutlined } from '@ant-design/icons';
import { Col, Row, Button, message, Select, Empty, Divider, Popover } from 'antd';
import { connect } from "react-redux";
import { injectIntl } from 'react-intl';
import React from 'react';
import Mayre from 'mayre';
import { getReportMessages, getIncomeMessages } from '../../../constants/messages';
import {
    CountAccountEntries, CreateEntry,
    GetFirstEntries, FilterBanks,
    ClearEntries, GetBank,
    EditEntry, GenerateBalanceSheet
} from '../actions';
import { ROLES } from '../../../constants/global';
import AddEntry from './AddEntry';
import EntryList from './EntryList';
import { ACL4Component, getCurrencyDisplayer } from '../../../utilities/util';
import BalanceSheetModal from './balanceSheetModal';
var moment = require('moment');

let reportMessages = getReportMessages(),
    incomeMessages = getIncomeMessages()

const messages = {
    ...reportMessages,
    ...incomeMessages
};

class IncomeReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            hasMore: true,
            showEntryModal: false,
            showBalanceSheetModal: false,
            entryParams: {},
            selectedBank: undefined,
            submitAction: this.submitEntry
        }
    }

    componentDidMount() {
        this.props.filterBanks({ status: 2, searchText: "" }, 1).then(() => {
            // this.loadFirstEntries();
            this.props.clearEntries();
            this.setState({ isLoading: false });
        });
    }

    setLoading = (isLoading) => {
        this.setState({ isLoading });
    }

    openEditModal = (entry, cardParams) => {
        this.openEntryModal(entry, cardParams);
        this.setState({
            submitAction: this.handleEdit
        });
    }

    openEntryModal = (param, cardParams, isIncome) => {
        if (!param)
            param = this.getDefaultEntry(param, isIncome);
        let bank = this.findBank(this.state.selectedBank);
        this.setState({
            entryParams: {
                bank: bank,
                editEntry: param,
                cardParams: { ...cardParams, bankId: bank.id, bankInitial:  bank.initialAmount }
            },
            showEntryModal: true
        });
    }

    getDefaultEntry = (param, isIncome) => {
        param = {
            EntryCategories: [],
            balance: 1.00,
            bankId: this.state.selectedBank,
            createdAt: "",
            date: moment(),
            description: "",
            extraDetail: "",
            isIncome: isIncome,
            referenceNumber: "",
            value: 1.00,
        };
        return param;
    }

    closeEntryModal = () => {
        this.setState({
            showEntryModal: false
        });
    }

    openBalanceSheetModal = () => {
        if (this.state.selectedBank === undefined)
            return message.warning(`${this.props.intl.formatMessage({ ...messages.noBankSelected })}`);

        this.setState({
            showBalanceSheetModal: true
        });
    }

    closeBalanceSheetModal = () => {
        this.setState({
            showBalanceSheetModal: false
        });
    }

    submitEntry = (entryData) => {
        return this.props.createEntry(entryData).then(() => {
            this.completeSubmit();
            message.success(`${this.props.intl.formatMessage({ ...messages.entryCreateSucces })}`);
        }).catch(() => {
            message.error(`${this.props.intl.formatMessage({ ...messages.entryError })}`)
        });
    }

    handleEdit = (entryData) => {
        return this.props.editEntry(entryData, this.state.entryParams.cardParams).then(() => {
            this.completeSubmit();
            this.setState({
                submitAction: this.submitEntry
            });
            message.success(`${this.props.intl.formatMessage({ ...messages.entryCreateSucces })}`);

        }).catch(() => {
            message.error(`${this.props.intl.formatMessage({ ...messages.entryError })}`)
        });
    }

    findBank = (id) => {
        if (id === undefined)
            return {};
        let bank = this.props.banks.find((bank) => bank.id === id);
        return bank;
    }

    updateBank = () => {
        this.props.getBank(this.state.selectedBank).then((response) => {
            this.setState({
                entryParams: {
                    ...this.state.entryParams,
                    bank: response
                }
            });
            return response;
        });
    }

    completeSubmit = () => {
        this.closeEntryModal();
        this.setState({
            isLoading: true,
            hasMore: true
        });
        this.loadFirstEntries(this.state.selectedBank);
        this.updateBank();
    }

    loadFirstEntries(id) {
        this.props.countAccountEntries(id).then(() => {
            this.props.getFirstEntries(id).then(() => {
                this.setLoading(false);
            });
        });
    }

    setHasMore = (val) => {
        this.setState({
            hasMore: val
        });
    }

    handleSelect = (val) => {
        this.setState({
            selectedBank: val
        });
        this.loadFirstEntries(val);
    }

    filterBanks = (input) => {
        this.props.filterBanks({ status: 2, searchText: input });
    }

    addButtons = () => {
        const { state, props, openEntryModal } = this;
        const { isLoading } = state;
        const { intl, roles } = props;
        const getIntl = (str) => intl.formatMessage({ ...messages[str]});
        const buttonList = [];
        const addButton = (key, isIncome) => buttonList.push(
            <Col key={key} span={8}>
                <Button type="dashed" loading={isLoading} onClick={() => openEntryModal(undefined, undefined, isIncome)} style={{ width: "100%" }}>
                    {`${getIntl("add")} ${getIntl(key)}`}
                </Button>
            </Col>
        );
        addButton("income", true);
        addButton("expense", false);
        return ACL4Component(roles, <Row type="flex" justify="center" className="IncomeViewButtons">{buttonList}</Row>, [ROLES.INCOMESEXPENSES]);
    }

    submitBalanceSheet = (bankId, balanceMonth, accountId) => {
        return this.props.generateBalanceSheet(bankId, balanceMonth, accountId).then(() => {
            this.updateBank();
            message.success(`${this.props.intl.formatMessage({ ...messages.balanceSheetCreatedSuccessfully })}`);
        });
    }

    render() {
        return (
            <div className="incomeReport view" >
                <Row className="selectBar" type="flex" justify="center" >
                    <Col span={6} >
                        <Select
                            showSearch
                            style={{ width: "100%" }}
                            placeholder={`${this.props.intl.formatMessage({ ...messages.searchBank })}`}
                            optionFilterProp="children"
                            onChange={this.handleSelect}
                            onSearch={this.filterBanks}
                        >
                            {this.props.banks.map((bank) => <Select.Option key={bank.id} value={bank.id}> {bank.name} </Select.Option>)}
                        </Select>
                    </Col>
                    <Col span={2} style={{ paddingLeft: "4px" }}>
                        {ACL4Component(
                            this.props.roles,
                            <Popover content="Generar Cierre">
                                <Button type="primary" shape="circle" icon={<ReconciliationOutlined />} onClick={this.openBalanceSheetModal} />
                            </Popover>,
                            [ROLES.INCOMESEXPENSES]
                        )}
                    </Col>
                </Row>
                <Row type="flex" justify="center" >
                    <Col span={12}>
                        <Divider />
                    </Col>
                </Row>
                <Mayre
                    of={
                        <div>
                            {this.addButtons()}
                            <Row type="flex" justify="center">
                                <EntryList
                                    loading={this.state.isLoading}
                                    setLoading={this.setLoading}
                                    hasMore={this.state.hasMore}
                                    setHasMore={this.setHasMore}
                                    openEditModal={this.openEditModal}
                                    selectedBank={this.state.selectedBank}
                                    currencyDisplayer={getCurrencyDisplayer(this.findBank(this.state.selectedBank).isDollar)}
                                />
                            </Row>
                            <AddEntry
                                showModal={this.state.showEntryModal}
                                entryParams={this.state.entryParams}
                                handleCancel={this.closeEntryModal}
                                handleSubmit={this.state.submitAction}
                                selectedBank={this.state.selectedBank}
                            />
                            <BalanceSheetModal
                                bank={this.findBank(this.state.selectedBank)}
                                showModal={this.state.showBalanceSheetModal}
                                handleCancel={this.closeBalanceSheetModal}
                                handleSubmit={this.submitBalanceSheet}
                            />
                        </div>
                    }
                    or={<Empty />}
                    when={this.state.selectedBank !== undefined}
                />
            </div>
        );
    }
}

IncomeReport.propTypes = {
    banks: PropTypes.array,
    clearEntries: PropTypes.func.isRequired,
    countAccountEntries: PropTypes.func.isRequired,
    createEntry: PropTypes.func.isRequired,
    editEntry: PropTypes.func.isRequired,
    filterBanks: PropTypes.func.isRequired,
    generateBalanceSheet: PropTypes.func.isRequired,
    getBank: PropTypes.func.isRequired,
    getFirstEntries: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    roles: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
    return {
        banks: state.incomeReport.banks,
        roles: state.auth.roles,
    };
};


const mapDispatchToProps = (dispatch) => ({
    createEntry: (entryData) => dispatch(CreateEntry(entryData)),
    getFirstEntries: (page) => dispatch(GetFirstEntries(page)),
    editEntry: (entryData, id) => dispatch(EditEntry(entryData, id)),
    getBank: (id) => dispatch(GetBank(id)),
    generateBalanceSheet: (bankId, balanceMonth, accountId) => dispatch(GenerateBalanceSheet(bankId, balanceMonth, accountId)),
    countAccountEntries: (id) => dispatch(CountAccountEntries(id)),
    clearEntries: () => dispatch(ClearEntries()),
    filterBanks: (searchData, page, pageSize) => dispatch(FilterBanks(searchData, page, pageSize)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(IncomeReport));

