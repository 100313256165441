import React from 'react'
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Col, Form, Input } from 'antd';
import { getClientMessages, getFields } from '../../constants/messages';

const FormItem = Form.Item;
let fieldsMessages = getFields(),
    clientMessages = getClientMessages();

const messages = {
    ...fieldsMessages,
    ...clientMessages,
};

const AddClientContactForm = ({ form }) => {
    const intl = useIntl();

    return (
        <Form ref={form} layout='vertical' >
            <Col span={24}>
                <FormItem
                    label={intl.formatMessage({...messages.name})}
                    name="name"
                    rules={[{ required: true }]}
                >
                <Input
                        placeholder={intl.formatMessage({...messages.namePlaceholder})}
                    />     
                </FormItem>
            </Col>
            <Col span={24}>
                <FormItem
                    label={intl.formatMessage({...messages.clientTelephone})}
                    name="cellphone"
                    rules={[{ required: true }]}
                >
                <Input
                        placeholder={intl.formatMessage({...messages.telephonePlaceholder})}
                    />     
                </FormItem>
            </Col>
            <Col span={24}>
                <FormItem
                    label={intl.formatMessage({...messages.email})}
                    name="email"
                >
                <Input
                        placeholder={intl.formatMessage({...messages.emailPlaceholder})}
                    />     
                </FormItem>
            </Col>
        </Form>
    )
}

AddClientContactForm.propTypes = {
    form: PropTypes.object.isRequired,
};

export default AddClientContactForm