import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Form, Select, message } from 'antd';
import { getErrorMessages, getFields, getInvoicingMessages } from '../../../../constants/messages';
import { useDispatch } from 'react-redux';
import { GetEmails } from '../../../Clients/actions';
import { searchInString } from '../../../../utilities/util';
import { GetEmailSelectorExtraEmails } from '../../../Invoices/actions';

const FormItem = Form.Item;

let FieldsMessages = getFields(),
    ErrorMessages = getErrorMessages(),
    invoicingMessages = getInvoicingMessages();

const messages = {
	...FieldsMessages,
    ...ErrorMessages,
    ...invoicingMessages,
};

const EmailNotification = ({form, onChange, clientId}) => {
	const intl = useIntl();
	const dispatch = useDispatch();
    const [emails, setEmails] = useState([]);
    const [extraEmails, setExtraEmails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const { getFieldDecorator } = form;

    useEffect(() => {
        GetEmailSelectorExtraEmails()
			.then(extraEmails => {
                setExtraEmails(extraEmails)
            }).catch(() => {
				message.error(intl.formatMessage({ ...messages.getEmailSelectorExtraEmailsError}));
			})
    }, []);

    useEffect(()=> {
        if(clientId) {
            setIsLoading(true);
            dispatch(GetEmails(clientId))
                .then((response) => {
                    setEmails(response)
                    setIsLoading(false);
                })
                .catch(() => message.error(intl.formatMessage({ ...messages.getClientEmailsError })));
        }
    }, [clientId]);

    const emailsOptions = emails.map((email) => ({
		key: email.id,
		value: email.email,
		label: email.email,
	}));

    const emailsExtraOptions = extraEmails.map((email) => ({
		key: email.index,
		value: email,
		label: email,
	}));

    return (
        <FormItem label={intl.formatMessage({...messages.email})}>
            {
                getFieldDecorator('email', 
                    {
                        rules: [{
                            required: true,
                            message: intl.formatMessage({...messages.emailEmptyError}),
                        }],
                        onChange: onChange
                    }
                )(
                    <Select
                        mode="tags"
                        showSearch
                        disabled={!clientId}
                        tokenSeparators={[',']}
                        loading={isLoading}
                        placeholder={intl.formatMessage({...messages.email})}
                        filterOption={(input, { label }) => searchInString(input, label)}
                        options={[
                            {
                              label: intl.formatMessage({...messages.Client}),
                              options: emailsOptions,
                            },
                            {
                              label:  intl.formatMessage({...messages.extras}),
                              options: emailsExtraOptions,
                            },
                        ]}
                    />
                )
            }
        </FormItem>
    );
}

EmailNotification.propTypes = {
    form: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    clientId: PropTypes.number
};

export default EmailNotification
