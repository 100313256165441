import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Card, Spin } from 'antd';
import Mayre from 'mayre';
import { isMobile } from '../../../utilities/util';

class WidgetCard extends Component {

    renderCard = (additionalClassNames = "") => {
        const { isLoading, content } = this.props;
        return (
            <div className={"dashboard-widget-card" + additionalClassNames}>
                <Spin spinning={isLoading}>
                    <Card className="dashboard-full-height">
                        {content}
                    </Card>
                </Spin>
            </div>
        );
    }

    renderCardInColumn = () => {
        return (
            <Col className="dashboard-widget-padding" xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:12}}>
                {this.renderCard(" dashboard-full-height dashboard-widget-height")}
            </Col>
        );
    }

    fullRender = () => {
        const { renderCardInColumn, renderCard } = this;
        return (
            <Mayre
                of={renderCard()}
                or={renderCardInColumn()}
                when={isMobile()}
            />
        );
    }

	render() {
        return this.fullRender();
	}
}

WidgetCard.defaultProps = {
    isLoading: false
};

WidgetCard.propTypes = {
    isLoading: PropTypes.bool,
    content: PropTypes.object.isRequired
};

export default WidgetCard;
