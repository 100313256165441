import React from 'react'
import PropTypes from 'prop-types';
import { DatePicker, Form, Input, Select } from 'antd'
import { useIntl } from 'react-intl';
import { getErrorMessages, getInvoicingMessages, getPaymentMessages } from '../../../constants/messages';
import { searchInString } from '../../../utilities/util';
import { CheckDocumentNumberExistance } from './actions';
import debounce from '../../../utilities/debounce';
import _ from 'lodash';
import moment from 'moment';

const FormItem = Form.Item;
const currencys = [{prefix: 'Q', value: false}, {prefix: '$', value: true}]

let invoicingMessages = getInvoicingMessages(),
    errorMessages = getErrorMessages(),
    paymentMessages = getPaymentMessages();

const messages = {
	...invoicingMessages,
    ...errorMessages,
    ...paymentMessages,
};

const AddOrEditForm = ({record, form, clients, setIsDollar}) => {
	const intl = useIntl();

    const clientOptions = clients.map((client) => ({ label: client.name, value: client.clientId }));
    const currencysOptions = currencys.map((currency) => ({ label: currency.prefix, value: currency.value }));

    const documentNumberExist = debounce(async (value) => {
        if (_.isEmpty(value)) return;
        if (record) return;
        const exist = await CheckDocumentNumberExistance(value);
        if (exist) throw new Error(intl.formatMessage({...messages.invoiceNumberExistError}));
    }, 300);

    const doesDocumentNumberExist = (rule, value) => documentNumberExist(value);
    const isDollar = record ? record.isDollar : false;

  return (
    <Form 
        className="login-form col-8" 
        layout='vertical' 
        ref={form} 
        preserve={false}
        initialValues={{
            documentNumber: record ? record.documentNumber : null,
            clientId: record ? record.clientId : null,
            total: record ? record.total : null,
            createdAt: record ? (moment(record.createdAt)) : null,
        }}
    >
        <FormItem
            name="documentNumber"
            label={intl.formatMessage({ ...messages.InvoiceNumber })}
            hasFeedback
            rules={[
                {
                    required: true,
                    message: intl.formatMessage({ ...messages.invoicedNumberError }),
                },
                { validator: doesDocumentNumberExist },
            ]}
        >
            <Input placeholder={intl.formatMessage({ ...messages.InvoiceNumber })} />
        </FormItem>
        <FormItem
            name="clientId"
            label={intl.formatMessage({ ...messages.Client })}
            rules={[
                {
                    required: true,
                    message: intl.formatMessage({ ...messages.clientNameError }),
                },
            ]}
        >
            <Select
                showSearch
                placeholder={intl.formatMessage({ ...messages.Client })}
                optionFilterProp="children"
                filterOption={(input, option) => searchInString(input, option.label)}
                options={clientOptions}
            />
        </FormItem>
        <FormItem
            label={intl.formatMessage({ ...messages.Date })}
            name="createdAt"
            rules={[
                {
                    required: true,
                    message: intl.formatMessage({ ...messages.dateEmptyError }),
                },
            ]}
        >
          <DatePicker style={{width: '100%'}}/>
        </FormItem>
        <FormItem
            label={intl.formatMessage({ ...messages.Total })}
            name="total"
            rules={[
                {
                    required: true,
                    message: intl.formatMessage({ ...messages.totalRequiredError }),
                },
            ]}
        >
            <Input 
                addonBefore={ 
                    <Select 
                        defaultValue={isDollar} 
                        options={currencysOptions}
                        onChange={(e) => setIsDollar(e)}
                    />
                } 
                placeholder={intl.formatMessage({ ...messages.Total })}
            />
        </FormItem>
        
    </Form>
  )
}

AddOrEditForm.propTypes = {
	form: PropTypes.object,
	clients: PropTypes.array,
    record: PropTypes.object,
    setIsDollar: PropTypes.func,
};

export default AddOrEditForm;