import {
	CLEAR_SESSION,
	TOGGLE_MENU,
	CLEAN_REDUCER
} from '../constants/actionTypes';

const initState = {
	visible: false
};

const reducer = (state = initState, action) => {
            switch (action.type) {
		case TOGGLE_MENU:
			return {
				...state,
				visible: action.visible
			};
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;
