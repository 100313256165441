import axios from 'axios';
import {
	notify
} from '../../utilities/util';
import {
	GET_PROFORMAS, ADD_PROFORMA_SUCCESS, GET_PROFORMA, DENY_PROFORMA_SUCCESS, APPROVE_PROFORMA_SUCCESS, PROFORMA_INVOICED_SUCCESS, STATUS_FILTER, FETCH_PROFORMA_COUNT, UPDATE_SEARCH, UPDATE_PAGE
} from './actionTypes';


export const GetProformas = (page = 1, pageSize = 10) => (dispatch) => {
	let filter = {
		limit: pageSize,
		skip: (page - 1) * pageSize,
		include: ["proformaProducts"],
		order: ["createdAt DESC"],
		where: {}
	};

	return dispatch(BaseGetProformas(filter));
}

export const BaseGetProformas = (filter) => (dispatch) => {
	let promises = [];
	promises.push(
		new Promise(function (resolve, reject) {
			axios.get(`/Proformas/count?where=${encodeURIComponent(JSON.stringify(filter.where))}`)
				.then((response) => {
					dispatch({ type: FETCH_PROFORMA_COUNT, count: response.data.count });
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		})
	);
	promises.push(
		new Promise(function (resolve, reject) {
			axios.get(`/Proformas?filter=${encodeURIComponent(JSON.stringify(filter))}`)
				.then((response) => {
					dispatch({ type: GET_PROFORMAS, proformas: response.data });
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		})
	);
	return Promise.all(promises);
};

export const StatusFilterChange = (status) => (dispatch) => {
	dispatch({ type: STATUS_FILTER, statusFilter: status });
};

export const SearchProformas = (page, pageSize = 10, searchObject) => (dispatch) => {
	return axios({
		url: `/Proformas/search`,
		method: 'GET',
		responseType: 'json',
		params: {
			searchObject: searchObject,
			page: page,
			pageSize: pageSize
		}
	})
		.then((response) => {
			const { data } = response;
			const { proformas = [], count = 0 } = data;
			dispatch({ type: FETCH_PROFORMA_COUNT, count });
			dispatch({ type: GET_PROFORMAS, proformas });
			dispatch({ type: UPDATE_SEARCH, search: searchObject.searchText})
			dispatch({ type: UPDATE_PAGE, page: page});
		});
};

export const AddProforma = (proformaData) => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;

	return axios.post('/Proformas/addProforma', { proformaData })
		.then((response) => {
			notify(`${messages[ADD_PROFORMA_SUCCESS]}`);
			dispatch({ type: ADD_PROFORMA_SUCCESS, proforma: response.data.proforma });
		});
};

export const UpdateProforma = (proformaId, newData) => {
	return axios.post(`/Proformas/${proformaId}/edit`, { newData })
	.then(response => response.data);
};

export const GetProforma = (id) => (dispatch) => {
	let filter = {
		"include": [
			{ "client": "salesPerson" },
			{
				relation: "activityLog",
				scope: {
					include: [
						{
							relation: "account",
							scope: {
								fields: ["id", "name"]
							}
						}
					]
				}
			}
		]
	};
	return axios.get(`/Proformas/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			dispatch({ type: GET_PROFORMA, proforma: response.data });
		});
};

export const EmailProforma = (id, emails, replyTo, message) => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;

	return axios.post(`/Proformas/${id}/sendByEmail`, { id: id, emails: emails, replyTo: replyTo, message: message })
		.then(() => {
			notify(`${messages["EMAIL_SUCCESS"]}`);
		});
};

export const PrintProforma = (id) => () => {
	return axios({
		url: `/Proformas/${id}/print`,
		method: 'POST',
		responseType: 'blob',
		data: {}
	})
		.then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Pro forma ${id}.pdf`);
			document.body.appendChild(link);
			link.click();
		});
};

export const PreviewProforma = (id) => {
	return axios({
		url: `/Proformas/${id}/preview`,
		method: 'POST',
		responseType: 'blob'
	})
		.then(response => response.data);
};

export const PreviewUnregisteredProforma = (proformaData) => {
	return axios({
		url: `/Proformas/previewUnregisteredProforma`,
		method: 'GET',
		responseType: 'blob',
		params: { proformaData }
	})
		.then(response => response.data);
};

export const GenerateProforma = (client, proformaData, monthlyServiceProducts) => (dispatch, getState) => {
	const state = getState();
	const accountId = state.auth.user.id;
	return axios.post('/Proformas/generateProforma', { client, proformaData: { ...proformaData, accountId }, monthlyServiceProducts })
		.then((response) => response.data);
};

export const PrintProformas = (ids) => () => {
	if (!Array.isArray(ids)) ids = [ids];
	return axios({
		url: `/Proformas/print`,
		method: 'POST',
		responseType: 'blob',
		data: {
			ids: ids
		}
	})
		.then((response) => {
			let filename = response.headers['cache-control'];
			let arr = filename.split(',');
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Pro forma ${arr[arr.length - 1]}.pdf`);
			document.body.appendChild(link);
			link.click();
		});
};

export const DenyProforma = (id, reason) => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;

	let annullment = {
		status: 3,
		reasonRejection: reason
	};

	return axios.patch(`/Proformas/${id}`, annullment)
		.then(() => {
			notify(`${messages[DENY_PROFORMA_SUCCESS]}`);
			dispatch({ type: DENY_PROFORMA_SUCCESS });
		});
};

export const ApproveProforma = (id) => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;

	let annullment = {
		status: 0
	};

	return axios.patch(`/Proformas/${id}`, annullment)
		.then(() => {
			notify(`${messages[APPROVE_PROFORMA_SUCCESS]}`);
			dispatch({ type: APPROVE_PROFORMA_SUCCESS });
		});
};

export const SetProformaToInvoiced = (id, invoiceId) => (dispatch) => {
	let annullment = {
		status: 2,
		invoiceId: invoiceId
	};

	return axios.patch(`/Proformas/${id}`, annullment)
		.then(() => {
			dispatch({ type: PROFORMA_INVOICED_SUCCESS });
		});
};