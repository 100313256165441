import React from 'react';
import PropTypes from 'prop-types';
import { Button, message } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getInvoicingMessages, getFiscalDataMessages } from '../../constants/messages';
import { connect } from 'react-redux';
import { GetAutomaticInvoicesData, GenerateInvoice, GenerateProformas, GetInvoices, PrintInvoice } from './actions';
import { GetFiscalData } from '../FiscalData/actions';
import { GenerateProforma, PrintProformas } from '../Proformas/actions';
import async from 'async';

let invoicingMessages = getInvoicingMessages(),
	fiscalDataMessages = getFiscalDataMessages();

let messages = {
	...invoicingMessages,
	...fiscalDataMessages
};

class GenerateInvoicesButton extends React.Component {

	constructor(props) {
		super(props);
	}

	getIntl = (str) => {
		return this.props.intl.formatMessage({ ...messages[str] });
	}

	setLoading = (isLoading) => {
		this.props.changeLoading(isLoading);
	}

	generateInvoices = () => {
		const { props, getIntl, setLoading } = this;
		const { getAutomaticInvoicesData, generateInvoice, printInvoice, getInvoices, getFiscalData, generateProforma, printProformas } = props;
		setLoading(true);
		getAutomaticInvoicesData().then((data) => {
			const { clients, invoice, products } = data;
			async.reduce(clients, { invoiceIds: [], proformaIds: [] }, (ids, client, reduceCallback) => {
				const { createProforma } = client;
				const reduceThis = (func, push, errorMessage) => {
					func(client, invoice, products).then((idArray) => {
						if (Array.isArray(idArray)) {
							idArray.forEach(id => {
								if (id) push(id);
							});
						} else {
							if (idArray) push(idArray);
						}
						reduceCallback(null, ids);
					}).catch((error) => {
						message.error(errorMessage);
						setLoading(false);
						reduceCallback(error);
					});
				}
				if (createProforma) reduceThis(generateProforma, (id) => ids.proformaIds.push(id), getIntl("generateProformaError"));
				else reduceThis(generateInvoice, (id) => ids.invoiceIds.push(id), getIntl("generateInvoiceError"));

			}, (reduceError, ids) => {
				const { invoiceIds, proformaIds } = ids;
				getFiscalData().then((fiscalData) => {
					const { invoice: { currentRValue, finalRValue } } = fiscalData;
	
					const generatePromise = (func, errorMessage) => new Promise((resolve, reject) => {
						func().then(() => resolve()).catch(() => reject(getIntl(errorMessage)));
					});
					const promises = [
						generatePromise(() => getInvoices(), "GetInvoicesError")
					];
					if (invoiceIds.length > 0) promises.push(generatePromise(() => printInvoice(ids.invoiceIds), "invoicePrintError"));
					if (proformaIds.length > 0) promises.push(generatePromise(() => printProformas(ids.proformaIds), "proformaPrintError"));
					Promise.all(promises)
						.then(() => {
							if (currentRValue > finalRValue) {
								message.warning(getIntl("fiscalRangeExceededError"));
							}
							setLoading(false);
						})
						.catch((error) => {
							message.error(error);
							setLoading(false);
						});
				}).catch(() => {
					message.error(getIntl("GetFiscalDataError"));
					setLoading(false);
				});
			});
		}).catch(() => {
			message.error(getIntl("generateError"));
			setLoading(false);
		});
	}

	render() {
		return (
			<span>
				{this.displayButton()}
			</span>
		);
	}

	displayButton() {
		if (this.props.displayButton)
			return <Button type="primary" onClick={this.generateInvoices} loading={this.props.isLoading} style={{ 'marginLeft': '8px' }}>
				<FormattedMessage {...messages.GenerateInvoices} />
			</Button>;
		return <div> <a onClick={this.generateInvoices}> {<FormattedMessage {...messages.GenerateInvoices} />} </a>  </div>
	}
}

GenerateInvoicesButton.propTypes = {
	intl: PropTypes.object.isRequired,
	invoicesIds: PropTypes.array,
	displayButton: PropTypes.bool,
	changeLoading: PropTypes.func,
	isLoading: PropTypes.bool,
	getAutomaticInvoicesData: PropTypes.func,
	generateInvoice: PropTypes.func,
	printInvoice: PropTypes.func,
	getInvoices: PropTypes.func,
	getFiscalData: PropTypes.func,
	generateProforma: PropTypes.func,
	printProformas: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		invoicesIds: state.invoicing.invoicesIds,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getAutomaticInvoicesData: () => dispatch(GetAutomaticInvoicesData()),
		generateInvoice: (client, invoiceData, products) => dispatch(GenerateInvoice(client, invoiceData, products)),
		generateProforma: (client, invoiceData, products) => dispatch(GenerateProforma(client, invoiceData, products)),
		generateProformas: () => dispatch(GenerateProformas()),
		getInvoices: () => dispatch(GetInvoices()),
		printInvoice: (ids) => dispatch(PrintInvoice(ids)),
		printProformas: (ids) => dispatch(PrintProformas(ids)),
		getFiscalData: () => dispatch(GetFiscalData())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GenerateInvoicesButton));