import {
	CLEAR_SESSION,
	FETCH_SESSIONS
} from '../constants/actionTypes';


const initState = {
	sessions: [],
	count: 0,
	loading: false
};


const reducer = (state = initState, action) => {
	switch (action.type) {
		case FETCH_SESSIONS:
			return {
				...state,
				sessions: action.sessions,
				count: action.count
			};
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;