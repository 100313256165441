import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Modal, Spin, Input } from 'antd';
import { getModalMessages, getInvoicingMessages } from '../../constants/messages';
const { TextArea } = Input;
const FormItem = Form.Item;

let modalMessages = getModalMessages(),
	invoiceMessages = getInvoicingMessages();

const messages = {
	...modalMessages,
	...invoiceMessages
};

class AddObservationsModal extends React.Component {

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	handleSubmit = () => {
		const { form, handleSubmit } = this.props;
		const { validateFieldsAndScroll } = form;
		validateFieldsAndScroll({ force: true }, (err, values) => {
			if (!err) {
				const { observations } = values;
				handleSubmit(observations);
			}
		});
	}

	renderedButtons = () => {
		const { props, handleSubmit, getIntl } = this;
		const { saveButtonText, handleCancel, isLoading } = props;
		return [
			<Button key="cancel" disabled={isLoading} onClick={handleCancel}>{getIntl("cancel")}</Button>,
			<Button key="submit" loading={isLoading} type="primary" onClick={handleSubmit}>{saveButtonText}</Button>
		];
	}

	renderObservationsInput = () => {
		const { props, getIntl } = this;
		const { form, currentObservations } = props;
		const { getFieldDecorator } = form;
		const max = 200;
		return (
			<FormItem label={getIntl("observations")}>
				{
					getFieldDecorator('observations',
						{
							initialValue: currentObservations,
							rules: [
								{
									max,
									message: getIntl("observationsLengthError", { max })
								}
							]
						}
					)(
						<TextArea
							className="job-full-component"
							placeholder={getIntl("observations")}
							rows={4}
						/>
					)
				}
			</FormItem>
		);
	}

	render() {
		const { props, handleSubmit, renderedButtons, renderObservationsInput } = this;
		const { showModal, title, handleCancel, isLoading } = props;
		return (
			<Modal
				open={showModal}
				onOk={handleSubmit}
				onCancel={handleCancel}
				destroyOnClose={true}
				footer={renderedButtons()}
				maskClosable={!isLoading}
				keyboard={!isLoading}
				closable={!isLoading}
				title={title}
			>
				<Spin spinning={isLoading}>
					{renderObservationsInput()}
				</Spin>
			</Modal>
		);
	}
}

AddObservationsModal.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	showModal: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	handleCancel: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	saveButtonText: PropTypes.string.isRequired,
	currentObservations: PropTypes.string
};

export default injectIntl(Form.create()(AddObservationsModal));