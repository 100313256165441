import React from 'react'
import PropTypes from 'prop-types';
import { Form, Input, Col, Row, Select} from 'antd';
import { useIntl } from 'react-intl';
import { getFields, getLotsMessages, getErrorMessages, getUnitsMessages, getEmployeeMessages, getModelsMessages } from '../../constants/messages';
import { searchInString } from '../../utilities/util';

const FormItem = Form.Item;

let FieldsMessages = getFields(),
    ErrorMessages = getErrorMessages(),
    UnitsMessages = getUnitsMessages(),
    LotsMessages = getLotsMessages(),
    EmployeeMessages = getEmployeeMessages(),
    ModelMessages = getModelsMessages();

const messages = {
	...FieldsMessages,
    ...UnitsMessages,
    ...LotsMessages,
    ...ErrorMessages,
    ...EmployeeMessages,
    ...ModelMessages,
};

const AddOrEditForm = ({ form, unitsAvailable, employeesActive, models}) => {
	const intl = useIntl();

    const modelsOptions = models.map((model) => ({
		key: model.id,
		value: model.model,
		label: model.model,
	}));

    const employeesOptions = employeesActive.map((employee) => ({
		key: employee.id,
		value: employee.name,
		label: employee.name,
	}));

    const unitsOption = unitsAvailable.map((unit) => ({
		key: unit.id,
		value: unit.id,
		label: unit.pin,
	}));

  return (
    <div>
        <Form
            ref={form}
            layout='vertical'
        >
            <FormItem name="id" hidden={true}>
                <Input hidden={true} />
            </FormItem>
            <Row type="flex" justify="center" align="bottom">
                <Col span={24}>
                    <FormItem 
                        name="unitsId"
                        label={intl.formatMessage({ ...messages.units })} 
                        rules={[
                            {
                                required: true, 
                                message: intl.formatMessage({...messages.UnitsError}) 
                            }
                        ]} 
                    >
                        <Select
                            mode="multiple"
                            placeholder={intl.formatMessage({ ...messages.pin })}
                            filterOption={(input, option) => searchInString(input, option.label)}
                            options={unitsOption}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row type="flex" justify="center" align="bottom">
                <Col span={24}>
                    <FormItem 
                        name="employee"
                        label={intl.formatMessage({ ...messages.onCharge })} 
                        rules={[
                            {
                                required: true, 
                                message: intl.formatMessage({...messages.onChargeError}) 
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder={intl.formatMessage({ ...messages.employees })}
                            filterOption={(input, option) => searchInString(input, option.label)}
                            options={employeesOptions}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row type="flex" justify="center" align="bottom">
                <Col span={24}>
                    <FormItem 
                        name="model"
                        label={intl.formatMessage({ ...messages.model })} 
                        rules={[
                            {
                                required: true, 
                                message: intl.formatMessage({...messages.modelError}) 
                            }
                        ]} 
                    >
                        <Select
                            showSearch
                            placeholder={intl.formatMessage({ ...messages.modelPlaceholder })}
                            filterOption={(input, option) => searchInString(input, option.label)}
                            options={[
                                {
                                    value: 'Varios',
                                    label: intl.formatMessage({ ...messages.several }),
                                }, 
                                ...modelsOptions
                            ]}
                        />
                    </FormItem>
                </Col>
            </Row>
        </Form>
    </div>
  )
}

AddOrEditForm.propTypes = {
    form: PropTypes.object.isRequired,
    unitsAvailable: PropTypes.array,
    employeesActive: PropTypes.array,
	models: PropTypes.array,
};

export default AddOrEditForm;