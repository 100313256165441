import React from "react";
import { Tabs } from "antd";
import { useIntl } from "react-intl";
import { getDepartmentMessages } from "../../constants/messages";
import DepartmentRender from "./Renders/DepartmentRender";
import WorkRoleRender from "./Renders/WorkRoleRender";

let departmentMessages = getDepartmentMessages();

const messages = {
  ...departmentMessages,
};

const tabs = {
  departments: "1",
  workRoles: "2",
};

const Departments = () => {
  const intl = useIntl();
  const items = [
    {
      key: tabs.departments,
      label: intl.formatMessage({ ...messages.departments }),
      children: <DepartmentRender />,
    },
    {
      key: tabs.workRoles,
      label: intl.formatMessage({ ...messages.workRoles }),
      children: <WorkRoleRender />,
    },
  ];
  return <Tabs defaultActiveKey={tabs.departments} items={items} type="card" />;
};

export default Departments;
