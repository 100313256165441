import axios from "axios";
import {
  FETCH_DEPARTMENTS,
  FETCH_WORK_ROLES,
  FETCH_DEPARTMENT_COUNT,
  FETCH_WORK_ROLES_COUNT,
  FETCH_ALL_DEPARTMENTS,
  FETCH_ALL_WORK_ROLES,
  SET_SEARCH,
  SET_SEARCH_WORKROLES
} from "./actionTypes";
import {
  substituteObjectInArrayByValue,
  getObjectInArray,
} from "../../utilities/util";


export const GetDepartments = (searchObject = {}, page = 1, pageSize = 10) => async (dispatch) => {
    let filter = filterBuilder(searchObject, page, pageSize);
    const response = await axios.get(
      `/Departments?filter=${encodeURIComponent(JSON.stringify(filter))}`
    );
    dispatch({type:SET_SEARCH, searchText: searchObject.searchText })
    dispatch({ type: FETCH_DEPARTMENTS, departments: response.data });
  };

export const GetDepartmentCount =(searchObject = {}) =>async (dispatch) => {
    let name = searchObject.searchText !== undefined ? searchObject.searchText : "";
    let where = name.length > 0 ? { name: { like: "%" + name + "%" } } : {};
    const response = await axios.get(
      `/Departments/count?where=${encodeURIComponent(JSON.stringify(where))}`
    );
    dispatch({
      type: FETCH_DEPARTMENT_COUNT,
      departmentCount: response.data.count,
    });
  };

export const GetWorkRoles = (searchObject = {}, page = 1, pageSize = 10) =>
  async (dispatch) => {
    let filter = filterBuilder(searchObject, page, pageSize);
    filter.include = ["department"];
    const response = await axios.get(
      `/WorkRoles?filter=${encodeURIComponent(JSON.stringify(filter))}`
    );
    dispatch({type:SET_SEARCH_WORKROLES, searchTextWork: searchObject.searchText })
    dispatch({ type: FETCH_WORK_ROLES, workRoles: response.data });
  };

export const GetWorkRoleCount =(searchObject = {}) => async (dispatch) => {
    let name =searchObject.searchText !== undefined ? searchObject.searchText : "";
    let where = name.length > 0 ? { name: { like: "%" + name + "%" } } : {};

    const response = await axios.get(
      `/WorkRoles/count?where=${encodeURIComponent(JSON.stringify(where))}`
    );
    dispatch({
      type: FETCH_WORK_ROLES_COUNT,
      workRoleCount: response.data.count,
    });
  };

export const GetAllDepartments = () => async (dispatch) => {
  let filter = { include: ["workRoles"] };
  const response = await axios.get(
    `/Departments?filter=${encodeURIComponent(JSON.stringify(filter))}`
  );
  dispatch({ type: FETCH_ALL_DEPARTMENTS, allDepartments: response.data });
};

export const GetAllWorkRoles = () => (dispatch) => {
  return axios
    .get(`/WorkRoles`)
    .then((response) =>
      dispatch({ type: FETCH_ALL_WORK_ROLES, allWorkRoles: response.data })
    );
};

function filterBuilder(searchObject = {}, page = 1, pageSize = 10) {
  let name =
    searchObject.searchText !== undefined ? searchObject.searchText : "";
  let where = name.length > 0 ? { name: { like: "%" + name + "%" } } : {};
  let filter = {
    limit: pageSize,
    skip: (page - 1) * pageSize,
    where: where,
  };
  return filter;
}

export const AddDepartment = (department) => async (dispatch, getState) => {
  let state = getState();
  let departments = state.department.departments;
  let count = state.department.departmentCount;
  const response = await axios.post("/Departments", department);
  let copy = [...departments];
  if (copy.length >= 10) {
    copy.pop();
  }
  copy.unshift(response.data);
  dispatch({ type: FETCH_DEPARTMENTS, departments: copy });
  dispatch({ type: FETCH_DEPARTMENT_COUNT, departmentCount: count + 1 });
};

export const EditDepartment =
  (department, id) => async (dispatch, getState) => {
    let state = getState();
    let departments = state.department.departments;
    const response = await axios.patch(`/Departments/${id}`, department);
    dispatch({
      type: FETCH_DEPARTMENTS,
      departments: substituteObjectInArrayByValue(
        "id",
        id,
        departments,
        response.data
      ),
    });
  };

export const DeleteDepartments = async (ids) => {

  const response = await axios({
    url: `/Departments/deleteByIds`,
    method: "DELETE",
    params: {
      uids: ids,
    },

  });
  
  return response.data;
  
}

export const AddWorkRole = (workRole) => async (dispatch, getState) => {
  let state = getState();
  let workRoles = state.department.workRoles;
  let count = state.department.workRoleCount;
  let departments = state.department.allDepartments;
  const response = await axios.post("/WorkRoles", workRole);
  let newWorkRole = {
    ...response.data,
    department: getObjectInArray("id", workRole.departmentId, departments),
  };
  let copy = [...workRoles];
  if (copy.length >= 10) {
    copy.pop();
  }
  copy.unshift(newWorkRole);
  dispatch({ type: FETCH_WORK_ROLES, workRoles: copy });
  dispatch({ type: FETCH_WORK_ROLES_COUNT, workRoleCount: count + 1 });
};

export const EditWorkRole = (workRole, id) => async (dispatch, getState) => {
  let state = getState();
  let workRoles = state.department.workRoles;
  let departments = state.department.allDepartments;

  const response = await axios.patch(`/WorkRoles/${id}`, workRole);
  let editedWorkRole = {
    ...response.data,
    department: getObjectInArray("id", workRole.departmentId, departments),
  };
  dispatch({
    type: FETCH_WORK_ROLES,
    workRoles: substituteObjectInArrayByValue(
      "id",
      id,
      workRoles,
      editedWorkRole
    ),
  });

  return response.data;
};

export const DeleteWorkRoles = async (ids) => {
  const response = await axios({
    url: `/WorkRoles/deleteByIds`,
    method: "DELETE",
    params: {
      uids: ids,
    },
  });
  return response;
};

export const EmployeesbyDepartment = async(ids)=>{
  let filter = {
    include: [{
      relation: 'workRoles',
      scope: {
        include: {
          relation: 'employees',
          scope: {
          fields: ['name', 'id'],
          },
        },
      },
      }],
      where: {
        id: {
          inq: ids,
        },
    },
  }
  const response = await axios.get( `/Departments?filter=${encodeURIComponent(JSON.stringify(filter))}`);
  const array = [];
  response.data.map(department => {
    return {
      employees: department.workRoles.map(workRole => {
        return workRole.employees.map(employee => {
          array.push({  department: department.name, name: employee.name, id: employee.id})
        });
      }),
    };
    });
  return array;

}
