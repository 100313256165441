import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { message } from 'antd';
import { getDashboardMessages } from '../../../constants/messages';
import { GetDevicesWidgetInfo } from '../actions';
import Widget from '../Components/DefaultWidget';
import { MobileOutlined } from '@ant-design/icons';

let dashboardMessages = getDashboardMessages();

const messages = {
	...dashboardMessages
};

class DevicesWidget extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
            isLoading: false,
            activeDeviceCount: 0,
            latestMonthlyDevices: []
        };
	}

	componentDidMount () {
        const { getIntl, setLoading } = this;
        setLoading(true);
        GetDevicesWidgetInfo()
        .then(response => {
            const activeDeviceCount = response[0];
            const latestMonthlyDevices = response[1] || [];
            const parsedData = latestMonthlyDevices.map(entry => ({ date: entry.month, amount: entry.amount }));
            this.setState({ activeDeviceCount, latestMonthlyDevices: parsedData, isLoading: false });
        })
        .catch(() => {
            setLoading(false);
            message.error(getIntl("getDeviceInfoError"));
        });
	}

    getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    setLoading = isLoading => this.setState({ isLoading });

	render() {
        const { state, getIntl } = this;
        const { isLoading, latestMonthlyDevices, activeDeviceCount } = state;
        const lastValue = latestMonthlyDevices.length > 0 ? latestMonthlyDevices[(latestMonthlyDevices.length - 1)].amount : 0;
        return (
            <Widget
                isLoading={isLoading}
                title={getIntl("activeDevicesByMonth")}
                statisticTitle={getIntl("activeDevices")}
                statisticLastValue={lastValue}
                statisticCurrentValue={activeDeviceCount}
                statisticIcon={<MobileOutlined />}
                graphData={latestMonthlyDevices}
                graphAmountHeader={getIntl("devices")}
                padGraph
            />
        );
    }
}

DevicesWidget.propTypes = {
	intl: PropTypes.object.isRequired
};

export default injectIntl(DevicesWidget);