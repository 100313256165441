import PropTypes from 'prop-types';
import { Row, Col, Pagination, Spin, message, List } from 'antd';
import { connect } from "react-redux";
import { injectIntl } from 'react-intl';
import { GetClientInvoices, GetAccounts, DownloadClientMovements, EmailClientMovements, EmailAccountStatement, DownloadAccountStatement } from './actions';
import { getReportMessages, getInvoicingMessages, getLanguageIntl } from '../../constants/messages';
import EmailSelector from '../../components/EmailSelector';
import { GetEmails } from '../Clients/actions';
import { SET_SEARCH_REPORT_GENERAL } from './actionTypes';
import React from 'react';
import ClientCard from './ClientCard';
import slice from 'lodash.slice';
import InvoicesModal from './InvoicesModal';
import CommentsModal from './CommentsModal';
import Search from 'antd/lib/input/Search';
import ExportOptions from './ExportOptions';


let reportMessages = getReportMessages(),
    languageMessages = getLanguageIntl(),
    invoicesMessages = getInvoicingMessages();

const messages = {
    ...reportMessages,
    ...languageMessages,
    ...invoicesMessages
};

class GeneralReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            currentPage: 1,
            pageSize: 9,
            showInvoiceModal: false,
            showCommentsModal: false,
            showEmailModal: false,
            showExportModal: false,
            modalId: 0,
            exportData: {},
            exportFunction: (() => { })
        };
    }

    componentDidMount() {
        this.props.fetchData().then(() => {
            this.setState({ loading: false });
        });
        this.props.getAccounts();
    }

    changeLoading = (newState) => {
        this.setState({
            loading: newState
        });
    }

    onChange = (e) => {
        const {setSearch} = this.props
        setSearch(e.target.value);
    }

    loadInvoiceModal = (position, id) => {
        this.setState({
            showInvoiceModal: true,
            modalId: position,
            exportData: { id: id }
        });
    }

    loadCommentsModal = (position) => {
        this.setState({
            showCommentsModal: true,
            modalId: position
        });
    }

    closeInvoiceModal = () => {
        this.setState({
            showInvoiceModal: false,
        });
    }

    closeCommentsModal = () => {
        this.setState({
            showCommentsModal: false,
        });
    }

    closeEmailModal = () => {
        this.setState({
            showEmailModal: false
        });
    }

    openExportOptions = (id, position) => {
        this.setState({
            showExportModal: true,
            modalId: position,
            exportData: { id: id }
        });
    }

    closeExportModal = () => {
        this.setState({ showExportModal: false });
    }

    completeExportOptions = (exportParams) => {
        this.props.getEmails(this.state.exportData.id).then(() => {
            this.setState({
                showExportModal: false,
                showInvoiceModal: false,
                showEmailModal: true,
                exportData: {
                    ...this.state.exportData,
                    dateRange: exportParams.dateRange,
                },
                exportFunction: this.buildClientMovementsEmail
            });
        });
    }

    printClientMovements = (exportParams) => {
        let exportInfo = {
            ...this.state.exportData,
            dateRange: exportParams.dateRange,
            showExportModal: false 
        }
        this.props.downloadClientMovements(exportInfo);
    }

    completeAccountStateEmail = (exportParams) => {
        this.props.getEmails(exportParams.clientId).then(() => {
            this.setState({
                showInvoiceModal: false,
                showEmailModal: true,
                exportData: {
                    ...this.state.exportData,
                    invoicesIds: exportParams.invoicesIds,
                    debitNotesIds: exportParams.debitNotesIds,
                    clientId: exportParams.clientId,
                    clientName: exportParams.clientName
                },
                exportFunction: this.buildAccountStateEmail
            });
        });
    }

    buildClientMovementsEmail = (clientId, emails, respondTo, message) => {
        return new Promise((resolve, reject) => {
            this.props.emailClientMovements(this.state.exportData, { emails: emails, respondTo: respondTo, message: message }).then(() => {
                resolve();
            }).catch((error) => {
                message.error(this.props.intl.formatMessage({ ...messages.errorExportAccountStatement }));
                reject(error);
            });
        });
    }

    buildAccountStateEmail = (clientId, emails, respondTo, message) => {
        return new Promise((resolve, reject) => {
            this.props.emailAccountStatement(emails, respondTo, message, this.state.exportData).then(() => {
                resolve();
            }).catch((error) => {
                message.error(this.props.intl.formatMessage({ ...messages.errorExportAccountStatement }));
                reject(error);
            });
        });
    }

    printAccountStatement = (exportData) => {
        this.props.downloadAccountStatement(exportData).then(() => {
            this.closeInvoiceModal();
        }).catch(() => {
            message.error(this.props.intl.formatMessage({ ...messages.errorExportAccountStatement }));
        });
    }

    showTotal = (total) => {
        return `${this.props.intl.formatMessage({ ...messages.total })} ${total} ${this.props.intl.formatMessage({ ...messages.clients })}`;
    }

    onShowSizeChange = (current, size) => {
        this.setState({
            currentPage: current,
            pageSize: size
        });
    }

    FormatDocuments(client) {
        if(client === undefined)
            return [];
                
        let documents = client.invoices.concat(client.debitNotes);
        documents.sort(function (a, b) { return new Date(a.createdAt) - new Date(b.createdAt); });
        return documents;
    }

    render() {
        let { intl, searchReportGeneral } = this.props;
        let filteredClients = Object.values(this.props.clientInvoices).filter(client => {
            return client.name.toLowerCase().indexOf(searchReportGeneral.toLowerCase()) >= 0;
        });
        return (
            <div>
                <Row className="generalReport" gutter={16}>
                    <Search value={searchReportGeneral=== "" ? undefined : searchReportGeneral} onChange={this.onChange} placeholder={intl.formatMessage({ ...messages.search })}  allowClear />
                    <Spin spinning={this.state.loading}>
                        <Col span={24} className="searchBar">
                            <List
                                dataSource={slice(filteredClients, (this.state.currentPage - 1) * this.state.pageSize, this.state.currentPage * this.state.pageSize)}
                                renderItem={(client, index) => {
                                    return <ClientCard
                                        client={client}
                                        position={index}
                                        key={client.id}
                                        loading={this.state.loading}
                                        loadInvoiceModal={this.loadInvoiceModal}
                                        loadCommentsModal={this.loadCommentsModal}
                                        exportClientReport={this.openExportOptions}
                                    />
                                }}
                            />
                        </Col>
                    </Spin>
                    <Pagination
                        size="small"
                        total={filteredClients.length}
                        showSizeChanger
                        onChange={this.onShowSizeChange}
                        onShowSizeChange={this.onShowSizeChange}
                        showTotal={this.showTotal}
                        pageSizeOptions={['9', '15', '21', '27']}
                        defaultPageSize={this.state.pageSize}
                    />
                </Row>
                <InvoicesModal
                    client={filteredClients[this.state.modalId]}
                    showModal={this.state.showInvoiceModal}
                    handleOk={this.closeInvoiceModal}
                    printAccountState={this.printAccountStatement}
                    emailAccountState={this.completeAccountStateEmail}
                    documents={this.FormatDocuments(filteredClients[this.state.modalId])}
                />
                <CommentsModal
                    client={filteredClients[this.state.modalId]}
                    showModal={this.state.showCommentsModal}
                    handleOk={this.closeCommentsModal}
                    getData={this.props.fetchData}
                    accountsEmails={this.props.accountsEmails}
                />
                <ExportOptions
                    showModal={this.state.showExportModal}
                    emailData={this.completeExportOptions}
                    downloadData={this.printClientMovements}
                    handleCancel={this.closeExportModal}
                    clientName={filteredClients[this.state.modalId] === undefined ? "" : filteredClients[this.state.modalId].name}
                />
                <EmailSelector
                    addOrShow={false}
                    emailDocument={this.state.exportFunction}
                    document={filteredClients[this.state.modalId]}
                    showEmailModal={this.state.showEmailModal}
                    closeModal={this.closeEmailModal} />
            </div>
        );
    }
}

GeneralReport.propTypes = {
    intl: PropTypes.object.isRequired,
    clientInvoices: PropTypes.array,
    accountsEmails: PropTypes.array,
    fetchData: PropTypes.func.isRequired,
    emailClientMovements: PropTypes.func.isRequired,
    emailAccountStatement: PropTypes.func.isRequired,
    downloadAccountStatement: PropTypes.func.isRequired,
    downloadClientMovements: PropTypes.func.isRequired,
    getEmails: PropTypes.func.isRequired,
    getAccounts: PropTypes.func.isRequired,
    searchReportGeneral: PropTypes.string,
    setSearch: PropTypes.func
};

const mapStateToProps = (state) => ({
    clientInvoices: state.report.clientInvoices,
    accountsEmails: state.report.accountsEmails,
    searchReportGeneral: state.report.searchReportGeneral
});

const mapDispatchToProps = (dispatch) => ({
    fetchData: () => dispatch(GetClientInvoices()),
    getAccounts: () => dispatch(GetAccounts()),
    downloadClientMovements: (exportData) => dispatch(DownloadClientMovements(exportData)),
    emailClientMovements: (exportData, emailsData) => dispatch(EmailClientMovements(exportData, emailsData)),
    emailAccountStatement: (emails, respondTo, message, exportData) => dispatch(EmailAccountStatement(emails, respondTo, message, exportData)),
    downloadAccountStatement: (exportData) => dispatch(DownloadAccountStatement(exportData)),
    getEmails: (id) => dispatch(GetEmails(id)),
    setSearch: (value)=> dispatch({type:SET_SEARCH_REPORT_GENERAL, searchReportGeneral: value})
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GeneralReport));
