import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StatisticComparison from './StatisticComparison';
import Graph from './Graph';
import DateRangeSelect from './DateRangeSelect';
import WidgetCard from './WidgetCard';

class Widget extends Component {

	constructor(props) {
		super(props);
		this.state = {
            dateFormat: "MMM YY"
        };
	}

    renderDateRangeSelect = () => {
        const { props } = this;
        const { dateRangeSelectFunction } = props;
        const filterAndFormat = (datesToLookup, dateFormat) => {
            dateRangeSelectFunction(datesToLookup);
            this.setState({ dateFormat });
        }
        if ('dateRangeSelectFunction' in props) return (
            <div className="dashboard-range-picker-header">
                <DateRangeSelect filterFunction={filterAndFormat} />
            </div>
        );
    }

    renderStatisticComparison = () => {
        const { statisticTitle, statisticLastValue, statisticCurrentValue, statisticIcon, statisticCurrentValuePopover, statisticExtraContent, statisticReverseComparison } = this.props;
        if (statisticTitle && statisticTitle.length) return (
            <StatisticComparison
                title={statisticTitle}
                lastValue={statisticLastValue}
                currentValue={statisticCurrentValue}
                icon={statisticIcon}
                currentValuePopover={statisticCurrentValuePopover}
                extraContent={statisticExtraContent}
                reverseComparisonColors={statisticReverseComparison}
            />
        );
    }

    renderGraph = () => {
        const { state, props } = this;
        const { dateFormat } = state;
        const { graphData, graphComparisonBars, graphAmountHeader, graphAmountFormatter, padGraph, graphTooltipFormatter, graphItemTpl } = props;
        return (
            <Graph
                data={graphData}
                dateFormat={dateFormat}
                comparisonBars={graphComparisonBars}
                amountHeader={graphAmountHeader}
                amountFormatter={graphAmountFormatter}
                padGraph={padGraph}
                tooltipFormatter={graphTooltipFormatter}
                itemTpl={graphItemTpl}
            />
        );
    }

    renderTitle = () => {
        const { title } = this.props;
        return <div className="dashboard-chart-header">{title}</div>;
    }

    fullRender = () => {
        const { props, renderTitle, renderDateRangeSelect, renderStatisticComparison, renderGraph } = this;
        const { isLoading } = props;
        const content = (
            <React.Fragment>
                {renderTitle()}
                {renderStatisticComparison()}
                {renderDateRangeSelect()}
                {renderGraph()}
            </React.Fragment>
        )
        return <WidgetCard isLoading={isLoading} content={content}/>
    }

	render() {
        return this.fullRender();
	}
}

Widget.defaultProps = {
    isLoading: false,
    statisticLastValue: 0,
    statisticCurrentValue: 0,
    graphComparisonBars: 0,
    statisticReverseComparison: false
};

Widget.propTypes = {
    isLoading: PropTypes.bool,
    title: PropTypes.string.isRequired,
    statisticTitle: PropTypes.string,
    statisticLastValue: PropTypes.number,
    statisticCurrentValue: PropTypes.number,
    statisticIcon: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object
	]),
    statisticCurrentValuePopover: PropTypes.object,
    statisticExtraContent: PropTypes.object,
    statisticReverseComparison: PropTypes.bool,
    graphData: PropTypes.array.isRequired,
    dateRangeSelectFunction: PropTypes.func,
    graphComparisonBars: PropTypes.number,
    graphAmountHeader: PropTypes.string,
    graphAmountFormatter: PropTypes.func,
    padGraph: PropTypes.bool,
    graphTooltipFormatter: PropTypes.func,
    graphItemTpl: PropTypes.string
};

export default Widget;
