export const INIT_REQUEST_JOB = 'INIT_REQUEST_JOB';
export const END_REQUEST_JOB = 'END_REQUEST_JOB';
export const FETCH_JOB = 'FETCH_JOB';
export const FETCH_JOB_COUNT = 'FETCH_JOB_COUNT';
export const FETCH_JOB_TECHNICIANS = 'FETCH_JOB_TECHNICIANS';
export const FETCH_JOB_ICONS = 'FETCH_JOB_ICONS';
export const JOB_UPDATE_CALENDAR_EVENTS = 'JOB_UPDATE_CALENDAR_EVENTS';
export const UPDATE_JOB_FILTER = 'UPDATE_JOB_FILTER';
export const FECTH_SELECT_JOBS = 'FECTH_SELECT_JOBS';
export const CHECK_IN_INVOICE_JOBS = 'CHECK_IN_INVOICE_JOBS';
export const CHECK_IN_PROFORMA_JOBS = 'CHECK_IN_PROFORMA_JOBS';
export const SET_SEARCH_TABLE = 'SET_SEARCH_TABLE'

