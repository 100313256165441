import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import AddProductForm from './AddProductForm';
import {FormattedMessage} from 'react-intl';
import {getactionMessages} from '../../constants/messages';

let messages = getactionMessages();

const AddProductButton = () => {
	const [showModal, setShowModal] = useState(false);

	return (
		<span>
			<Button 
				className="editable-add-btn" 
				type="primary" 
				icon={<PlusOutlined />}
				onClick={() => setShowModal(true)}
			>
				<FormattedMessage {...messages.add} />
			</Button>
			<AddProductForm
				showModal={showModal}
				handleCancel={() => setShowModal(false)}
			/>
		</span>
	);
};

export default AddProductButton;
