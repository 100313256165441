import axios from 'axios';
import {
	notify
} from '../../utilities/util';
import {
	GET_QUOTATIONS, ADD_QUOTATION_SUCCESS, GET_QUOTATION, DENY_QUOTATION_SUCCESS, APPROVE_QUOTATION_SUCCESS, QUOTATION_INVOICED_SUCCESS, QUOTATION_PROFORMA_SUCCESS, QUOTATION_RENEWED, FETCH_QUOTATION_COUNT, UPDATE_SEARCH, UPDATE_PAGE
} from './actionTypes';

export const GetQuotations = (page = 1, pageSize = 10) => (dispatch) => {
	let filter = {
		limit: pageSize,
		skip: (page - 1) * pageSize,
		include: ["quotationProducts"],
		order: ["createdAt DESC"],
		where: {}
	};

	return dispatch(BaseGetQuotations(filter));
}

export const BaseGetQuotations = (filter) => (dispatch) => {
	let promises = [];
	promises.push(
		new Promise(function (resolve, reject) {
			axios.get(`/Quotations/count?where=${encodeURIComponent(JSON.stringify(filter.where))}`)
				.then((response) => {
					dispatch({ type: FETCH_QUOTATION_COUNT, count: response.data.count });
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		})
	);
	promises.push(
		new Promise(function (resolve, reject) {
			axios.get(`/Quotations?filter=${encodeURIComponent(JSON.stringify(filter))}`)
				.then((response) => {
					dispatch({ type: GET_QUOTATIONS, quotations: response.data });
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		})
	);
	return Promise.all(promises);
};

export const SearchQuotations = (page, pageSize = 10, searchObject) => (dispatch) => {
	return axios({
		url: `/Quotations/search`,
		method: 'GET',
		responseType: 'json',
		params: {
			searchObject: searchObject,
			page: page,
			pageSize: pageSize
		}
	})
		.then((response) => {
			const { data } = response;
			const { quotations = [], count = 0 } = data;
			dispatch({ type: FETCH_QUOTATION_COUNT, count });
			dispatch({ type: GET_QUOTATIONS, quotations });
			dispatch({ type: UPDATE_SEARCH, search: searchObject.searchText })
			dispatch({ type: UPDATE_PAGE, page: page});
		});
};

export const AddQuotation = (quotationData) => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;

	return axios.post('/Quotations/addQuotation', { quotationData })
		.then((response) => {
			notify(`${messages[ADD_QUOTATION_SUCCESS]}`);
			dispatch({ type: ADD_QUOTATION_SUCCESS, quotation: response.data.quotation });
		});
};

export const UpdateQuotation = (quotationId, newData) => {
	return axios.post(`/Quotations/${quotationId}/edit`, { newData })
		.then(response => response.data);
};

export const GetQuotation = (id) => (dispatch) => {
	let filter = {
		"include": [
			{ "client": "salesPerson" },
			{
				relation: "activityLog",
				scope: {
					include: [
						{
							relation: "account",
							scope: {
								fields: ["id", "name"]
							}
						}
					]
				}
			}
		]
	};
	return axios.get(`/Quotations/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			dispatch({ type: GET_QUOTATION, quotation: response.data });
		});
};

export const EmailQuotation = (id, emails, replyTo, message) => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;

	return axios.post(`/Quotations/${id}/sendByEmail`, { id: id, emails: emails, replyTo: replyTo, message: message })
		.then(() => {
			notify(`${messages["EMAIL_SUCCESS"]}`);
		});
};

export const PrintQuotation = (id) => () => {
	return axios({
		url: `/Quotations/${id}/print`,
		method: 'POST',
		responseType: 'blob',
		data: {}
	})
		.then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Cotización ${id}.pdf`);
			document.body.appendChild(link);
			link.click();
		});
};

export const PreviewQuotation = (id) => {
	return axios({
		url: `/Quotations/${id}/preview`,
		method: 'POST',
		responseType: 'blob'
	})
		.then(response => response.data);
};

export const PreviewUnregisteredQuotation = (quotationData) => {
	return axios({
		url: `/Quotations/previewUnregisteredQuotation`,
		method: 'GET',
		responseType: 'blob',
		params: { quotationData }
	})
		.then(response => response.data);
};

export const DenyQuotation = (id, reason) => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;
	return axios.patch(`/Quotations/${id}/deny`, { reason: reason })
		.then(() => {
			notify(`${messages[DENY_QUOTATION_SUCCESS]}`);
			dispatch({ type: DENY_QUOTATION_SUCCESS });
		});
};

export const ApproveQuotation = (id) => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;

	return axios.patch(`/Quotations/${id}/approve`)
		.then(() => {
			notify(`${messages[APPROVE_QUOTATION_SUCCESS]}`);
			dispatch({ type: APPROVE_QUOTATION_SUCCESS });
		});
};

export const SetQuotationToInvoiced = (id, invoiceId, proformaId) => (dispatch) => {
	let annullment = {
		status: 2,
		invoiceId: invoiceId,
		proformaId: proformaId
	};

	return axios.patch(`/Quotations/${id}`, annullment)
		.then(() => {
			dispatch({ type: QUOTATION_INVOICED_SUCCESS });
		});
};

export const SetQuotationToProforma = (id, proformaId) => (dispatch) => {
	let annullment = {
		status: 4,
		proformaId: proformaId
	};

	return axios.patch(`/Quotations/${id}`, annullment)
		.then(() => {
			dispatch({ type: QUOTATION_PROFORMA_SUCCESS });
		});
};

export const CheckExpiredQuotations = () => () => {
	return axios.patch(`/Quotations/expiredCheck`);
};

export const RenewQuotation = (id) => (dispatch) => {
	return axios.patch(`/Quotations/${id}/renew`).then(() => {
		dispatch({ type: QUOTATION_RENEWED });
	});
};
