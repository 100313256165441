import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Select,
    message,
    Spin,
    Table,
    Button,
    Radio,
    Collapse,
    Tooltip,
    Statistic,
    Col,
    Row,
    Input,
    InputNumber,
} from 'antd';
import { injectIntl } from 'react-intl';
import { getCommissionMessages, getFields, getLanguageIntl, getactionMessages, getErrorMessages, getInvoicingMessages, getModalMessages } from '../../../constants/messages';
import { LoadSalesmen, LoadCommissionForEdit, LoadInvoices, LoadCommissionPercent, AddCommission, LoadNoCommissionInvoices, GetNoCommissionInvoicesCount, UpdateCommission, LoadCommissionableProductIds } from '../actions';
import EllipsisTooltip from '../../../components/EllipsisTooltip';
import moment from 'moment';
import { getNestedValue, getObjectInArray, substituteObjectInArrayByValue, displayCurrency, applyObject, existsInArray } from '../../../utilities/util';
import { ReturnExchangeRate } from '../../ExchangeRate/actions';
import PopoverProductsTable from '../../Invoices/PopoverProductsTable';
import { STATUS } from '../constants';
import { withRouter } from 'react-router-dom';
const { PAID } = STATUS;

const FormItem = Form.Item;
const { Option } = Select;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { Panel } = Collapse;

let commissionMessages = getCommissionMessages(),
	fieldMessages = getFields(),
	intlMessages = getLanguageIntl(),
	actionMessages = getactionMessages(),
	errorMessages = getErrorMessages(),
	invoicingMessages = getInvoicingMessages(),
	modalMessages = getModalMessages();

const messages = {
	...commissionMessages,
	...fieldMessages,
	...intlMessages,
	...actionMessages,
	...errorMessages,
	...invoicingMessages,
	...modalMessages
};

class Generator extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			commission: {},
			salesmen: [],
			invoices: [],
			expandInvoices: false,
			isSaving: false,
			commissionPercent: 0,
			exchangeRate: 0,
			isEdit: false,
			noCommissionInvoices: [],
			noCommissionInvoicesCount: 0,
			noCommissionInvoicesCurrentPage: 1,
			initialPanel: props.match.params.id ? 4 : 2,
			commissionableProductIds: []
		};
	}

	componentDidMount() {
		const { props, setLoading, getIntl } = this;
		const { form } = props;
		const { id } = props.match.params;
		const { resetFields, setFieldsValue } = form;
		let isEdit = false;
		const promises = [
			new Promise((resolve, reject) => {
				LoadCommissionPercent()
					.then(response => resolve(response))
					.catch(() => reject(1));
			}),
			new Promise((resolve, reject) => {
				ReturnExchangeRate()
					.then(response => resolve(response))
					.catch(() => reject(2));
			}),
			new Promise((resolve, reject) => {
				LoadCommissionableProductIds()
					.then(response => resolve(response))
					.catch(() => reject(4));
			})
		];
		if (id) {
			isEdit = true;
			promises.push(
				new Promise((resolve, reject) => {
					LoadCommissionForEdit(id)
						.then(response => resolve(response))
						.catch(() => reject(3));
				})
			);
		} else {
			promises.push(
				new Promise((resolve, reject) => {
					LoadSalesmen()
						.then(response => resolve(response))
						.catch(() => reject(0));
				})
			);
		}
		setLoading(true);
		Promise.all(promises)
			.then(data => {
				const commissionPercent = data[0];
				const exchangeRate = data[1];
				const commissionableProductIds = data[2];
				if (isEdit) {
					const commission = data[3] || {};
					const { invoiceEntries: entries, noCommissionInvoices, noCommissionInvoicesCount, pendingInvoices: invoices, salesPersonId } = commission;
					const invoiceEntries = entries.map(({ invoice, amount }) => ({ ...invoice, amount, key: invoice.id }));
					this.setState({ commissionPercent, exchangeRate, isEdit, commission, invoices, invoiceEntries, noCommissionInvoices, noCommissionInvoicesCount, isLoading: false, expandInvoices: true, commissionableProductIds });
					resetFields();
					setFieldsValue({ salesPersonId });
				} else {
					const salesmen = data[3] || [];
					this.setState({ salesmen, commissionPercent, exchangeRate, isEdit, isLoading: false, commissionableProductIds });
				}
			})
			.catch(errorNumber => {
				setLoading(false);
				switch (errorNumber) {
					case 0:
						message.error(getIntl("loadSalesmenError"));
						break;
					case 1:
						message.error(getIntl("loadCommissionPercentError"));
						break;
					case 2:
						message.error(getIntl("loadExchangeRateError"));
						break;
					case 3:
						message.error(getIntl("loadCommissionError"));
						break;
					case 4:
						message.error(getIntl("loadCommissionableProductIdsError"));
						break;
					default:
						message.error(getIntl("common"));
						break;
				}
			});
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });
	setSaving = (isSaving) => this.setState({ isSaving });

	cancel = () => this.props.history.push("/commissions");

	save = () => {
		const { state, props, setSaving, getIntl, getCommissionTotal } = this;
		const { isEdit } = state;
		const { form } = props;
		const { validateFields } = form;
		const { id: commissionId = 0 } = props.match.params;
		validateFields({ force: true }, (err, values) => {
			if (!err) {
				const total = getCommissionTotal(false);
				if (total == 0) return message.warning(getIntl("saveZeroTotalCommissionError"));
				const { salesPersonId, bonus, editComment } = values;
				const parsedValues = {
					salesPersonId, bonus, editComment,
					newInvoiceEntries: [],
					updatedInvoiceEntries: [],
					noCommissionInvoiceEntries: [],
					markPending: [],
					markedNoCommission: []
				};
				const apply = (key, value) => {
					const fieldSubstring = key.substring(0, 3);
					if (existsInArray(fieldSubstring, ['upd', 'noc']) && value === false) parsedValues.markPending.push(parseInt(key.substr(3)));
					else if (existsInArray(fieldSubstring, ['new', 'upd']) && value === 0) parsedValues.markedNoCommission.push(parseInt(key.substr(3)));
					else {
						if (fieldSubstring === 'new' && value !== false) parsedValues.newInvoiceEntries.push({ amount: value, invoiceId: parseInt(key.substr(3)) });
						if (fieldSubstring === 'upd') parsedValues.updatedInvoiceEntries.push({ amount: value, invoiceId: parseInt(key.substr(3)) });
						if (fieldSubstring === 'noc' && value !== 0) parsedValues.noCommissionInvoiceEntries.push({ amount: value, invoiceId: parseInt(key.substr(3)) });
					}
				}
				applyObject(values, apply);
				const saveFunc = isEdit ? UpdateCommission : AddCommission;
				setSaving(true);
				saveFunc(parsedValues, commissionId)
					.then(() => {
						message.success(getIntl("saveCommissionSuccess"));
						this.props.history.push("/commissions");
					})
					.catch(() => {
						message.error(getIntl("saveCommissionError"));
						setSaving(false);
					});
			}
		});
	}

	renderButtons = () => {
		const { state, getIntl, cancel, save } = this;
		const { isLoading, isSaving, isEdit } = state;
		const margin = "0.5em";
		return (
			<div className="buttons-align-right">
				<Button
					style={{ marginTop: margin, marginRight: margin }}
					onClick={cancel}
					key="cancel"
					disabled={isLoading || isSaving}
				>
					{getIntl("cancel")}
				</Button>
				<Button
					style={{ marginTop: margin }}
					type="primary"
					onClick={save}
					key="save"
					loading={isSaving}
					disabled={isLoading}
				>
					{isEdit ? getIntl("save") : getIntl("generate")}
				</Button>
			</div>
		);
	}

	onSalespersonChange = (salesPersonId) => {
		const { props, setLoading, getIntl } = this;
		const { resetFields, setFieldsValue } = props.form;
		setLoading(true);
		const promises = [
			new Promise((resolve, reject) => {
				LoadInvoices(salesPersonId)
					.then(response => resolve(response))
					.catch(() => reject());
			}),
			new Promise((resolve, reject) => {
				LoadNoCommissionInvoices(salesPersonId)
					.then(response => resolve(response))
					.catch(() => reject());
			}),
			new Promise((resolve, reject) => {
				GetNoCommissionInvoicesCount(salesPersonId)
					.then(response => resolve(response))
					.catch(() => reject());
			})
		];
		Promise.all(promises)
			.then(data => {
				const invoices = data[0];
				const noCommissionInvoices = data[1];
				const noCommissionInvoicesCount = data[2];
				if (invoices.length < 1) message.warning(getIntl("salespersonHasNoInvoices"));
				this.setState({ invoices, noCommissionInvoices, expandInvoices: true, isLoading: false, noCommissionInvoicesCurrentPage: 1, noCommissionInvoicesCount });
				resetFields();
				setFieldsValue({ salesPersonId });
			})
			.catch(() => {
				setLoading(false);
				message.error(getIntl("getSalespersonInvoicesError"));
			});
	}

	renderSalespersonPicker = () => {
		const { state, props, getIntl, onSalespersonChange } = this;
		const { isLoading, salesmen, commission = {}, isEdit } = state;
		const { getFieldDecorator } = props.form;
		const salesPersonName = getNestedValue("salesPerson.name", commission);
		const salesPersonId = getNestedValue("salesPerson.id", commission);
		const options = isEdit ?
			[<Option key={salesPersonId} value={salesPersonId}>{salesPersonName}</Option>] :
			salesmen.map(salesman => <Option key={salesman.id} value={salesman.id}>{salesman.name}</Option>);
		return (
			<FormItem label={getIntl("salesperson")}>
				{
					getFieldDecorator('salesPersonId',
						{
							initialValue: isEdit ? salesPersonId : null,
							rules: [{
								required: true,
								message: getIntl("salespersonRequiredError"),
							}],
							onChange: onSalespersonChange
						}
					)(
						<Select
							className="job-full-component"
							showSearch
							allowClear={false}
							placeholder={getIntl("salesperson")}
							optionFilterProp="children"
							filterOption={(input, option) => !Array.isArray(option.props.children) && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							dropdownMatchSelectWidth={false}
							notFoundContent={isLoading ? <Spin size="small" /> : getIntl("notFound")}
							disabled={isEdit}
						>
							{options}
						</Select>
					)
				}
			</FormItem>
		);
	}

	formatDate = (unformatedDate) => {
		if (!unformatedDate) return undefined;
		const { getIntl } = this;
		const locale = getIntl("intl");
		return moment(unformatedDate).locale(locale).format('MMMM DD, YYYY');
	}

	renderCustomPriceButtons = (customPrices = [], isLps = false, productQuantity = 0) => {
		const { exchangeRate } = this.state;
		let buttonsData = [];
		customPrices.forEach(customPrice => {
			const { price } = customPrice;
			const description = getNestedValue("product.description", customPrice);
			let buttonData = getObjectInArray("price", price, buttonsData);
			if (buttonData) {
				buttonData = { ...buttonData, description: `${buttonData.description} / ${description}` }
				buttonsData = substituteObjectInArrayByValue("price", price, buttonsData, buttonData);
			} else {
				buttonData = { price, description };
				buttonsData.push(buttonData);
			}
		});
		const valueData = [];
		const buttons = buttonsData.map(data => {
			const { price, description } = data;
			const value = parseFloat(((isLps ? price : price * exchangeRate) * productQuantity).toFixed(2));
			valueData.push(value);
			return (
				<Tooltip key={price} title={description}>
					<RadioButton value={value}>{displayCurrency(price, isLps)}</RadioButton>
				</Tooltip>
			);
		});
		return { valueData, buttons };
	}

	calculateSubtotal = (invoiceProducts = [], isLps = false, exchangeRate) => {
		const { commissionableProductIds } = this.state;
		return invoiceProducts.reduce((sum, { price, quantity, productId }) => {
			if (existsInArray(productId, commissionableProductIds)) {
				if (isLps) return sum + (price * quantity);
				return sum + (price * exchangeRate * quantity);
			}
			else return sum;
		}, 0);
	}

	renderInvoiceActions = (invoice, fieldNomenclature, showEditOption = true, customInitialValue = false, defaultPending = false) => {
		const { state, props, getIntl, renderCustomPriceButtons, calculateSubtotal } = this;
		const { commissionPercent, isEdit, commissionableProductIds } = state;
		const { getFieldDecorator } = props.form;
		const { id: invoiceId, isLps: invoiceIsLps, invoiceProducts = [], exchangeRate, amount: editAmount = 0 } = invoice;
		const customPrices = getNestedValue("client.customPrices", invoice);
		const clientIsLps = getNestedValue("client.isLps", invoice);
		const productQuantity = invoiceProducts.reduce((sum, { quantity, productId }) => {
			if (existsInArray(productId, commissionableProductIds)) return sum + quantity;
			else return sum;
		}, 0);
		const { valueData = [], buttons: pricesButtons } = renderCustomPriceButtons(customPrices, clientIsLps, productQuantity);
		const percentageText = commissionPercent * 100;
		const subtotal = calculateSubtotal(invoiceProducts, invoiceIsLps, exchangeRate);
		const percentileValue = parseFloat((subtotal * commissionPercent).toFixed(2));
		const initialValuePriceCheckData = valueData.concat([percentileValue, 0]);
		const editOption = isEdit && showEditOption && !existsInArray(editAmount, initialValuePriceCheckData) ? <RadioButton value={editAmount}>{displayCurrency(editAmount)}</RadioButton> : null;
		return (
			<FormItem>
				{
					getFieldDecorator(`${fieldNomenclature}${invoiceId}`,
						{
							initialValue: defaultPending ? false : customInitialValue ? editAmount : percentileValue,
							preserve: fieldNomenclature === 'noc'
						}
					)(
						<RadioGroup>
							<RadioButton value={percentileValue}><Tooltip title={`${getIntl("subtotal")}: ${displayCurrency(parseFloat(subtotal).toFixed(2))}`}>{`${percentageText}%`}</Tooltip></RadioButton>
							{pricesButtons}
							<RadioButton value={0}>{getIntl("noCommission")}</RadioButton>
							{editOption}
							<RadioButton value={false}><Tooltip title={getIntl("pendingTooltip")}>{getIntl("pending")}</Tooltip></RadioButton>
						</RadioGroup>
					)
				}
			</FormItem>
		);
	}

	renderCommissionText = (invoiceId, fieldNomenclature) => {
		const commission = this.props.form.getFieldValue(`${fieldNomenclature}${invoiceId}`);
		return displayCurrency(commission || 0);
	}

	calculateInvoiceTotal = (invoice) => {
		const { calculateSubtotal } = this;
		const { isLps: invoiceIsLps, invoiceProducts, exchangeRate, taxExemption, isv } = invoice;
		const subtotal = calculateSubtotal(invoiceProducts, invoiceIsLps, exchangeRate);
		const total = taxExemption ? subtotal : subtotal + (subtotal * isv);
		return displayCurrency(total.toFixed(2));
	}

	renderInvoiceList = (invoiceListSource = [], fieldNomenclature = 'new', pagination = false, showEditOption = false, customInitialValue = false, defaultPending = false) => {
		const { getIntl, formatDate, renderInvoiceActions, renderCommissionText, calculateInvoiceTotal } = this;
		const dataSource = invoiceListSource.map(invoice => ({ ...invoice, key: invoice.id }));
		const columns = [
			{
				title: getIntl("InvoiceNumber"),
				dataIndex: 'documentNumber',
				key: 'documentNumber',
				render: (documentNumber, invoice) => {
					const { id: invoiceId, invoiceProducts = [] } = invoice;
					return (
						<PopoverProductsTable products={invoiceProducts}>
							<a href={`/invoices/${invoiceId}`} onClick={(e) => { e.preventDefault(); this.props.history.push(`/invoices/${invoiceId}`); }}>
								{documentNumber}
							</a>
						</PopoverProductsTable>
					);
				}
			},
			{
				title: getIntl("Client"),
				dataIndex: 'clientName',
				key: 'clientName',
				width: 200,
				onCell: () => {
					return {
						style: {
							whiteSpace: 'nowrap',
							maxWidth: 200,
						}
					}
				},
				render: (clientName) => {
					return (
						<EllipsisTooltip title={clientName}>{clientName}</EllipsisTooltip>
					);
				}
			},
			{
				title: getIntl("date"),
				dataIndex: 'createdAt',
				key: 'createdAt',
				render: (date) => formatDate(date)
			},
			{
				title: getIntl("total"),
				key: 'total',
				render: (invoice) => calculateInvoiceTotal(invoice)
			},
			{
				title: getIntl("typeOfCommission"),
				key: 'type',
				render: (invoice) => renderInvoiceActions(invoice, fieldNomenclature, showEditOption, customInitialValue, defaultPending)
			},
			{
				title: getIntl("commission"),
				dataIndex: 'id',
				key: 'id',
				render: (invoiceId) => renderCommissionText(invoiceId, fieldNomenclature)
			}
		];
		return <Table columns={columns} dataSource={dataSource} pagination={pagination} />;
	}

	renderPendingInvoiceList = () => {
		const { state, renderInvoiceList } = this;
		const { invoices, isEdit } = state;
		return renderInvoiceList(invoices, undefined, undefined, undefined, undefined, isEdit);
	}

	renderNoCommissionInvoiceList = () => {
		const { state, renderInvoiceList, pageNoCommissionInvoices } = this;
		const { noCommissionInvoices, noCommissionInvoicesCount, noCommissionInvoicesCurrentPage } = state;
		const pagination = {
			defaultPageSize: 5,
			onChange: (page, pageSize) => pageNoCommissionInvoices(page, pageSize),
			total: noCommissionInvoicesCount,
			current: noCommissionInvoicesCurrentPage
		};
		return renderInvoiceList(noCommissionInvoices, 'noc', pagination, false, true);
	}

	pageNoCommissionInvoices = (page = 1, pageSize = 5) => {
		const { state, props, setLoading, getIntl } = this;
		const { isEdit, commission } = state;
		const { getFieldValue } = props.form;
		const salesPersonId = isEdit ? getNestedValue("salesPersonId", commission) : getFieldValue('salesPersonId');
		setLoading(true);
		LoadNoCommissionInvoices(salesPersonId, page, pageSize)
			.then(noCommissionInvoices => this.setState({ noCommissionInvoices, isLoading: false, noCommissionInvoicesCurrentPage: page }))
			.catch(() => {
				message.error(getIntl("getSalespersonInvoicesError"));
				setLoading(false);
			});

	}

	renderBonusField = () => {
		const { state, props, getIntl } = this;
		const { isEdit, commission } = state;
		const { getFieldDecorator } = props.form;
		const { bonusDescription, bonusAmount } = commission;
		const descriptionMaxLength = 255;
		return (
            <div className="commission-generator-bonus">
				<Row>
					<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }}>
						<FormItem label={getIntl("description")}>
							{
								getFieldDecorator(`bonus[description]`,
									{
										initialValue: isEdit ? bonusDescription : null,
										rules: [
											{
												whitespace: true,
												message: getIntl("bonusDescriptionWhitespaceError")
											},
											{
												max: descriptionMaxLength,
												message: getIntl("bonusDescriptionLengthError", { maxLength: descriptionMaxLength })
											}
										]
									}
								)(
									<Input className="job-partial-component" placeholder={getIntl("bonusDescription")} />
								)
							}
						</FormItem>
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
						<FormItem label={getIntl("amount")}>
							{
								getFieldDecorator(`bonus[amount]`,
									{
										initialValue: isEdit ? bonusAmount : 0
									}
								)(
									<InputNumber
										className="job-full-component"
										min={0}
										precision={2}
										formatter={value => `L ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
										parser={value => value.replace(/L\s?|(,*)/g, '')}
									/>
								)
							}
						</FormItem>
					</Col>
				</Row>
			</div>
        );
	}

	getCommissionTotal = (includeBonus = true) => {
		const { props } = this;
		const { getFieldsValue, getFieldValue } = props.form;
		const values = getFieldsValue();
		let total = 0;
		const apply = (key, value) => {
			const fieldSubstring = key.substring(0, 3);
			if (existsInArray(fieldSubstring, ['new', 'upd', 'noc'])) total += value;
		}
		applyObject(values, apply);
		const bonus = getFieldValue(`bonus[amount]`);
		if (bonus && typeof bonus === "number" && includeBonus) total += bonus;
		return parseFloat(total.toFixed(2));
	}

	renderTotalStatistic = () => {
		const { getIntl, getCommissionTotal } = this;
		let total = getCommissionTotal();
		return <Statistic className="commission-generator-total-statistic" title={getIntl("total")} value={displayCurrency(total || 0)} />;
	}

	renderEditCommissionInvoiceEntries = () => {
		const { state, getIntl, renderInvoiceList } = this;
		const { isEdit, invoiceEntries } = state;
		if (!isEdit) return null;
		return (
			<Panel key={4} header={getIntl("invoicesForThisCommission")} forceRender>
				{renderInvoiceList(invoiceEntries, 'upd', false, true, true)}
			</Panel>
		);
	}

	renderEditPaidCommissionCommentField = () => {
		const { state, props, getIntl } = this;
		const { commission } = state;
		const { getFieldDecorator } = props.form;
		const { editComment = null } = commission;
		const maxLength = 255;
		return (
			<FormItem label={getIntl("editComment")}>
				{
					getFieldDecorator(`editComment`,
						{
							initialValue: editComment,
							rules: [
								{
									required: true,
									whitespace: true,
									message: getIntl("editCommentRequiredError")
								},
								{
									max: maxLength,
									message: getIntl("editCommentLengthError", { maxLength })
								}
							]
						}
					)(
						<Input className="job-full-component" placeholder={getIntl("editComment")} />
					)
				}
			</FormItem>
		);
	}

	renderEditPaidCommissionReason = () => {
		const { state, getIntl, renderEditPaidCommissionCommentField } = this;
		const { isEdit, commission } = state;
		const { status, editComment } = commission;
		if ((isEdit && status === PAID) || editComment)
			return (
				<Panel key={5} header={getIntl("editCommissionReason")} forceRender>
					{renderEditPaidCommissionCommentField()}
				</Panel>
			);
		return null;
	}

	renderForm = () => {
		const { state, getIntl, renderSalespersonPicker, renderPendingInvoiceList, renderTotalStatistic, renderBonusField, renderNoCommissionInvoiceList, renderEditCommissionInvoiceEntries, renderEditPaidCommissionReason } = this;
		const { expandInvoices, initialPanel } = state;
		return (
			<div>
				{renderSalespersonPicker()}
				<Collapse style={expandInvoices ? null : { display: "none" }} defaultActiveKey={initialPanel}>
					<Panel key={1} header={getIntl("noCommissionInvoices")} forceRender>
						{renderNoCommissionInvoiceList()}
					</Panel>
					<Panel key={2} header={getIntl("pendingInvoices")} forceRender>
						{renderPendingInvoiceList()}
					</Panel>
					<Panel key={3} header={getIntl("bonus")} forceRender>
						{renderBonusField()}
					</Panel>
					{renderEditCommissionInvoiceEntries()}
					{renderEditPaidCommissionReason()}
				</Collapse>
				{renderTotalStatistic()}
			</div>
		);
	}

	fullRender = () => {
		const { state, renderForm, renderButtons } = this;
		const { isLoading, isSaving } = state;
		return (
			<Spin spinning={isLoading || isSaving}>
				<div className="view">
					{renderForm()}
					{renderButtons()}
				</div>
			</Spin>
		);
	}

	render() {
		return this.fullRender();
	}
}

Generator.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	match: PropTypes.shape({
		params: PropTypes.object.isRequired
	}).isRequired,
	user: PropTypes.object.isRequired
};

export default withRouter(injectIntl(Form.create()(Generator)));