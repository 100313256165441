import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import { injectIntl } from 'react-intl';
import { getJobMessages } from '../../../../constants/messages';
import { hasHolidaysInTheFuture } from '../../constants';

let jobMessages = getJobMessages();

const messages = {
	...jobMessages
};

class HolidayWarning extends React.Component {

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    renderWarning = () => {
        const { props, getIntl } = this;
        const { programmedDate } = props;
        if(programmedDate && hasHolidaysInTheFuture(programmedDate)) return <Alert type="warning" message={getIntl("holidayWarning")} showIcon />;
        return null;
    }
    
	render() {
        return this.renderWarning();
    }
}

HolidayWarning.propTypes = {
    intl: PropTypes.object.isRequired,
    programmedDate: PropTypes.string
};

export default injectIntl(HolidayWarning);