import React, { useEffect, useState } from 'react';
import { getAuthMessages, getFields, getModalMessages,getTopMenuMessages } from '../constants/messages';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Checkbox, Row, Col, Form, Typography } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { doLogin } from '../actions/AuthAction';
import { FetchLanguage } from '../actions/Int18Actions';
import ForgotPasswordModal from '../containers/ForgotPasswordModal';

const { Link } = Typography;
const FormItem = Form.Item;

let authMessages = getAuthMessages(),
	fieldMessages = getFields(),
	modalMessages = getModalMessages(),
	languageMessages = getTopMenuMessages();

const messages = {
	...authMessages,
	...modalMessages,
	...fieldMessages,
	...languageMessages
};


const Login = () => {

	const intl = useIntl();
	const [language, setLanguage] = useState("");
	const dispatch= useDispatch();
	
	useEffect(() => {

		if(navigator.language.includes('es')){
			dispatch(FetchLanguage("es_ES"));
		}else{
			dispatch(FetchLanguage("en_US"));
		}
		

	}, []);

	useEffect(() => {

		if(language!==""){
			dispatch(FetchLanguage(language));
		}
		

	}, [language]);

	
	const handleFinish = (values) => {
		dispatch(doLogin(values))
	}

	const handleLanguage = () => {
		if(language === ""){
			if(navigator.language.includes('es')){
				setLanguage("en_US");
			}else{
				setLanguage("es_ES");
			}
		}

		if(language ==="en_US"){setLanguage("es_ES");}
		if(language ==="es_ES"){setLanguage("en_US");}
		
	}
	const LanguageText = () => {
		if(language === ""){
			if(navigator.language.includes('es')){
				return(<FormattedMessage {...messages.en}/>)
			}else{
				return(<FormattedMessage {...messages.es}/>)
			}
		}

		if(language ==="en_US"){return(<FormattedMessage {...messages.es}/>)}
		if(language ==="es_ES"){return(<FormattedMessage {...messages.en}/>)}
	}



  return (
	<div>
	   <Row className="login"  type="flex" justify="space-around" align="middle">
				
			    <Link 
					style={{ position: 'absolute', top: 20, right: 20 }}
					onClick={handleLanguage}
				>
					{LanguageText()}
				</Link>
				<Col xs={24} sm={8} md={8} lg={4}>
					<img src="../images/login.png" alt="Logo" className="imageLogo"/>
					<Form onFinish={(values) => handleFinish(values)} className="login-form">
						<FormItem
							name='userName'
							rules={[{required: true,  message: ""}]}
						>
							<Input prefix={<UserOutlined style={{fontSize: 13}} />} placeholder={intl.formatMessage({...messages.loginPlaceholder})}  />
						</FormItem>
						<FormItem
							name='password'
							rules={[{required: true,  message: ""}]}
						>
							<Input prefix={<LockOutlined style={{fontSize: 13}} />} type="password" placeholder={intl.formatMessage({...messages.passwordPlaceholder})} />
						</FormItem>
						<FormItem style={{ marginBottom: 0 }}>
							<FormItem
								name='remember'
								valuePropName="checked"
								initialValue={false}
								style={{ display: 'inline-block', width: '50%', marginBottom: '5px' }}
							>
								<Checkbox>
									<FormattedMessage {...messages.remember} />
								</Checkbox>	
							</FormItem>
							<FormItem
								style={{ display: 'inline-block', width: '50%', marginBottom: '5px'}}
							>
								<ForgotPasswordModal />
							</FormItem>
							<Button type="primary" htmlType="submit" className="login-form-button right">
								<FormattedMessage {...messages.login} />
							</Button>
						</FormItem>
					</Form>
				</Col>
			</Row>
	</div>
  )
}

export default Login


