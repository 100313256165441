import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { getJobMessages } from '../../../../../constants/messages';
import YesNoTemplate from '../../../AddUpdateJob/CommonComponents/YesNoTemplate';
import { Tooltip } from 'antd';

let jobMessages = getJobMessages();

const messages = {
	...jobMessages
};

class MatchAllCriteria extends React.Component {

	getIntl = (str) => this.props.intl.formatMessage({ ...messages[str] });

	render() {
		const { props, getIntl } = this;
		const { form, matchAllCriteriaTrueTooltip, matchAllCriteriaFalseTooltip, initialValue } = props;
		const matchAll = form.getFieldValue("matchAllCriteria");
		const matchAllTrueTitle = 'matchAllCriteriaTrueTooltip' in props ? matchAllCriteriaTrueTooltip : getIntl("matchAllCriteriaTrue");
		const matchAllFalseTitle = 'matchAllCriteriaFalseTooltip' in props ? matchAllCriteriaFalseTooltip : getIntl("matchAllCriteriaFalse");
		return (
			<Tooltip title={matchAll ? matchAllTrueTitle : matchAllFalseTitle} mouseEnterDelay={1}>
				<div>
					<YesNoTemplate
						form={form}
						label={getIntl("matchAllCriteria")}
						fieldName={`matchAllCriteria`}
						required={false}
						initialValue={initialValue}
					/>
				</div>
			</Tooltip>
		);
	}
}

MatchAllCriteria.defaultProps = {
	initialValue: false
};

MatchAllCriteria.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	matchAllCriteriaTrueTooltip: PropTypes.string,
	matchAllCriteriaFalseTooltip: PropTypes.string,
	initialValue: PropTypes.bool
};

export default injectIntl(MatchAllCriteria);