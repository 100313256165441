import axios from 'axios';
import { JOBSTATUS } from '../../Jobs/constants';
const { CANCELLED, ANNULLED } = JOBSTATUS;

export const FetchTechnicianJobs = (timeMin, timeMax) => {
    let fields = ["id", "type", "programmedDate", "status"]
    let where = {
        programmedDate: { between: [timeMin.toISOString(), timeMax.toISOString()] },
        status: { nin: [CANCELLED.id, ANNULLED.id] }
    };
    let include = [{ relation: "technicians", scope: { fields: ["id", "name"] } }];
    let order = "programmedDate ASC"
    let filter = { fields, where, include, order };
    return axios.get(`/Jobs?filter=${encodeURIComponent(JSON.stringify(filter))}`)
    .then(response => response.data);
};