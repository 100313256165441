import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { List, Row, Col, Form } from 'antd';
import { useIntl } from 'react-intl';
import { getEmployeeMessages } from '../../../constants/messages';
import AddButton from '../../Clients/AddOrUpdate/DynamicComponents/AddButton';
import Dependant from './DynamicComponents/Dependant';
import { cloneDeep } from 'lodash';

let employeeMessages = getEmployeeMessages();

const messages = {
    ...employeeMessages
};


const Dependants = ({form, saveDependants, dependants}) => {

    const intl = useIntl();

    useEffect(() => {
        form.resetFields();
    }, [dependants]);

    const getIntl = (str) => {
		return intl.formatMessage({...messages[str]});
	}

    const addDependants = () => {
        let dataCopy = cloneDeep(dependants)
        dataCopy = [...dataCopy,{name: "", relation: ""}]
        saveDependants(dataCopy);
        form.resetFields();
    }

    const remove = (index) => {
        let dataCopy = cloneDeep(dependants);
        dataCopy.splice(index,1);
        saveDependants(dataCopy);
        form.resetFields();
    }

    const saveName = (name, index) => {
        dependants[index].name = name;
        saveDependants(dependants);
    }

    const saveRelation = (relation, index) => {
        dependants[index].relation = relation;
        saveDependants(dependants);
    }
    const renderHeaders = () => {
        if (dependants.length > 0){
            return (
                <Row>
                    <Col span={12}>
                        <span>
                            {getIntl("name")}
                        </span>
                    </Col>
                    <Col span={11}>
                        <span>
                            {getIntl("relation")}
                        </span>
                    </Col>
                </Row>
            );
        }
    }

    const renderTitle = () => {
        return (
            <div>
                <b>
                    {getIntl("dependants")}
                </b>
                {renderHeaders()}
            </div>
        );
    }

    return (
        <Form form={form}>
            <List
                className="employee-scrollable-list"
                bordered={true}
                header={renderTitle()}
                dataSource={dependants}
                renderItem={(dependant, index) => 
                    <List.Item>
                        <Dependant form={form} dependant={dependant} index={index} remove={remove} saveName={saveName} saveRelation={saveRelation} />
                    </List.Item>
                }
            />
            <AddButton text={getIntl("addDependant")} addFunction={addDependants}/>
        </Form>
    )
}

Dependants.defaultProps = {
    dependants: []
};

Dependants.propTypes = {
    form: PropTypes.object.isRequired,
    saveDependants: PropTypes.func.isRequired,
    dependants: PropTypes.array.isRequired
};

export default Dependants
