import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getTopMenuMessages, getModalMessages, getFields} from '../constants/messages';
import { Tabs, Button, Modal } from 'antd';
import {injectIntl, FormattedMessage} from 'react-intl';
import AccountProfile from './AccountProfile';
import SessionProfile from './SessionProfile';
import ChangeProfilePassword from './ChangeProfilePassword';
import {isTablet} from '../utilities/util';

let fieldsMessages = getFields(),
	modalMessages = getModalMessages(),
	menuMessages = getTopMenuMessages();

const messages = {
	...modalMessages,
	...menuMessages,
	...fieldsMessages
};

const Profile = ({intl, showModal, handleCancel}) => {

	const items = [
		{
			key: '1',
			label: intl.formatMessage({...messages.profile}),
			children: <AccountProfile />
		},
		{
			key: '2',
			label: intl.formatMessage({...messages.password}),
			children: <ChangeProfilePassword />
		},
		{
			key: '3',
			label: intl.formatMessage({...messages.sessions}),
			children: <SessionProfile />
		},
	];

	return ( 
		<Modal
			open={showModal}
			title={intl.formatMessage( {...messages.profile} )} 
			onCancel={handleCancel}
			destroyOnClose={true}
			footer={[
				<Button key="close" size="large" onClick={handleCancel}>
					<FormattedMessage {...messages.close} />
				</Button>
			]}
		>
			<Tabs
				defaultActiveKey="1"
				tabPosition={isTablet()?"top":"left"}
				className="profileModal"
				items={items}
			/>
		</Modal>
	);
};

Profile.propTypes = {
	intl: PropTypes.object.isRequired,
	showModal: PropTypes.bool.isRequired,
	handleCancel:  PropTypes.func.isRequired
};
const mapStateToProps = (state) => {
	return {
		"showModal": state.profile.showProfile
	};
};

export default connect(mapStateToProps)(injectIntl(Profile));