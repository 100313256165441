import PropTypes from 'prop-types';
import { Col, Button, message, Badge } from 'antd';
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from 'react-intl';
import React from 'react';
import { getReportMessages, getIncomeMessages } from '../../../constants/messages';
import {
    CountBanks,
    GetBanks,
    FilterBanks,
    UpdateBank,
    CreateBank,
    RestoreFilterValues
} from '../actions';
import FilterButtons from '../Categories/filterButtons';
import CommonTable from '../../../components/CommonTable';
import { roundN, ACL4Component, getCurrencyDisplayer } from '../../../utilities/util';
import BanksModal from './BanksModal';
import { ROLES } from '../../../constants/global';


let reportMessages = getReportMessages(),
    incomeMessages = getIncomeMessages()

const messages = {
    ...reportMessages,
    ...incomeMessages
};

class Bank extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            showBankModal: false,
            selectedBankId: 0,
            completeSubmit: (() => { })
        }
    }

    componentDidMount() {
        this.loadFirstEntries();
        RestoreFilterValues();
    }

    setLoading = (val) => {
        this.setState({
            isLoading: val
        });
    }

    //#region data management
    loadFirstEntries = () => {
        const {searchBanks} = this.props
        if(searchBanks === ""){
            this.props.getBanks(1).then(() => {
                this.props.countBanks().then(() => {
                    
                });
            });
            
        }
        this.setLoading(false);
    }

    loadMoreEntries = (page) => {
        this.setLoading(true);
        this.props.getBanks(page).then(() => {
            this.setLoading(false);
        });
    }

    switchBankStatus = (bank) => {
        let newBank = { ...bank, active: !bank.active };
        this.editBank(newBank, bank.id);
    }

    editBank = (values, id) => {
        this.setLoading(true);
        this.props.updateBank(values, id).then(() => {
            this.setLoading(false);
            message.success(`${this.props.intl.formatMessage({ ...messages.categoryUpdateSuccesfull })}`)
        }).catch(() => {
            message.success(`${this.props.intl.formatMessage({ ...messages.categoryUpdateError })}`)
        });
    }

    submitBank = (values) => {
        this.setLoading(true);
        this.props.createBank(values).then(() => {
            this.setLoading(false);
        });
    }

    //#endregion

    //#region Modal managements
    openBankModal = () => {
        this.setState({
            showBankModal: true,
            completeSubmit: this.submitBank,
            selectedBankId: undefined,
        });
    }

    openEditBankModal = (id) => {
        this.setState({
            showBankModal: true,
            selectedBankId: id,
            completeSubmit: this.editBank,
        });
    }

    closeBankModal = () => {
        this.setState({
            showBankModal: false
        });
    }
    //#endregion

    //#region page management
    modButton = (bank) => {
        let statusMessage = this.getStatusMessage(bank);
        return (
            <a style={{ paddingLeft: "5px" }} onClick={() => this.switchBankStatus(bank)}>
                <FormattedMessage {...statusMessage} />
            </a>)
    }

    getStatusMessage = (bank) => {
        let statusMessage = { ...messages.activate };
        if (bank.active)
            statusMessage = { ...messages.deactivate };
        return statusMessage;
    }

    badgeCreator = (active) => {
        const { intl } = this.props;
        let badgeProps = {
            status: "default",
            text: intl.formatMessage({ ...messages.disabled })
        };
        if (active)
            badgeProps = {
                status: "success",
                text: intl.formatMessage({ ...messages.enabled })
            };
        return (<Badge {...badgeProps} />);
    }

    getTableColumns = () => {
        let { intl } = this.props;
        let columns = [
            {
                title: `${intl.formatMessage({ ...messages.name })}`,
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: `${intl.formatMessage({ ...messages.active })}`,
                dataIndex: 'active',
                render: (active) => {
                    return this.badgeCreator(active);
                }
            },
            {
                title: `${intl.formatMessage({ ...messages.balance })}`,
                render: (data) => {
                    let bal = this.getBalance(data);
                    let currencyDisplayer = getCurrencyDisplayer(data.isDollar);
                    return `${currencyDisplayer.symbol} ${roundN(bal, 2)}`;
                }
            }
        ];

        let actionCol = {
            title: `${intl.formatMessage({ ...messages.actions })}`,
            render: (data) => {
                return (
                    <span>
                        <span key="0">
                            <a onClick={() => this.openEditBankModal(data.id)}>
                                <FormattedMessage {...messages.edit} />
                            </a>
                        </span> |
                        <span key="1">
                            {this.modButton(data)}
                        </span>
                    </span>
                );
            }
        };
        if (this.props.roles.includes(ROLES.INCOMESEXPENSES))
            columns.push(actionCol);
        return columns;
    }


    getBankAt = (id) => {
        const newBank = { name: "", active: true, initialAmount: 0, entries: [] };
        if (!id)
            return newBank;

        let bank = this.props.banks.find((value) => {
            return value.id === id;
        });

        if (bank === undefined)
            return newBank;
        return { ...bank }
    }


    getBalance(data) {
        let bal = data.initialAmount;
        if (data.entries.length > 0)
            bal = data.entries[0].balance;
        return bal;
    }

    //#endregion

    render() {
        let columns = this.getTableColumns();
        const {searchBanks} = this.props;
        return (
            <Col className="category view" >
                <BanksModal
                    showModal={this.state.showBankModal}
                    handleCancel={this.closeBankModal}
                    submit={this.state.completeSubmit}
                    bank={this.getBankAt(this.state.selectedBankId)}
                />
                <CommonTable
                    columns={columns}
                    dataSource={this.props.banks}
                    search={this.props.filterBanks}
                    searchText={searchBanks}
                    recordCount={this.props.banksCount}
                    getRecords={this.loadMoreEntries}
                    Add={this.addButton()}
                    loading={this.state.isLoading}
                    getCheckboxProps={() => { }}
                    hideCheckboxes
                    filterComponent={(<FilterButtons />)}
                />
            </Col>
        );
    }

    addButton = () => {
        return (
            ACL4Component(
                this.props.roles,
                <div style={{ paddingLeft: "22px" }}>
                    <Button type="primary" loading={this.state.isLoading} onClick={this.openBankModal} style={{ width: "257px" }}>
                        <FormattedMessage {...messages.addAccount} />
                    </Button>
                </div>,
                [
                    ROLES.INCOMESEXPENSES
                ]
            )
        )
    }

}

Bank.propTypes = {
    banks: PropTypes.array.isRequired,
    banksCount: PropTypes.number.isRequired,
    countBanks: PropTypes.func.isRequired,
    createBank: PropTypes.func.isRequired,
    filterBanks: PropTypes.func.isRequired,
    getBanks: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    roles: PropTypes.array.isRequired,
    updateBank: PropTypes.func.isRequired,
    searchBanks: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        banks: state.incomeReport.banks,
        banksCount: state.incomeReport.banksCount,
        roles: state.auth.roles,
        searchBanks: state.incomeReport.searchBanks
    };
};

const mapDispatchToProps = (dispatch) => ({
    createBank: (entryData) => dispatch(CreateBank(entryData)),
    getBanks: (page) => dispatch(GetBanks(page)),
    countBanks: () => dispatch(CountBanks()),
    updateBank: (bank, id) => dispatch(UpdateBank(bank, id)),
    filterBanks: (searchData, page, pageSize) => dispatch(FilterBanks(searchData, page, pageSize)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Bank));

