import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "@ant-design/compatible/assets/index.css";
import { Input, Select, Spin, message, Form } from "antd";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { getDepartmentMessages } from "../../../constants/messages";
import { GetAllDepartments } from "../actions";
import { searchInString } from "../../../utilities/util";

let departmentMessages = getDepartmentMessages();

const messages = {
  ...departmentMessages,
};

const WorkRoleForm = ({ form, workRole }) => {
  const intl = useIntl();
  const [isLoading, setIsloading] = useState(false);
  const dispatch = useDispatch();

  const { name, departmentId } = workRole;
  const allDepartments = useSelector(
    (state) => state.department.allDepartments
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsloading(true);
        await dispatch(GetAllDepartments());
      } catch (error) {
        message.error(intl.formatMessage({ ...messages.getDepartmentsError }));
      } finally {
        setIsloading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <Form
      ref={form}
      layout="vertical"
      initialValues={{ name: name, departmentId: departmentId }}
    >
      <Form.Item
        name="name"
        label={intl.formatMessage({ ...messages.roleName })}
        rules={[
          {
            required: true,
            whitespace: true,
            message: intl.formatMessage({ ...messages.workRoleNameError }),
          },
        ]}
      >
        <Input placeholder={intl.formatMessage({ ...messages.roleName })} />
      </Form.Item>
      <Form.Item
        name="departmentId"
        label={intl.formatMessage({ ...messages.department })}
        rules={[
          {
            required: true,
            message: intl.formatMessage({ ...messages.departmentEmptyError }),
          },
        ]}
      >
        <Select
          className="job-full-component"
          showSearch
          placeholder={intl.formatMessage({ ...messages.department })}
          optionFilterProp="children"
          filterOption={(input, option) => searchInString(input, option.label)}
          notFoundContent={
            isLoading ? (
              <Spin size="small" />
            ) : (
              intl.formatMessage({ ...messages.notFound })
            )
          }
          options={allDepartments.map((deparment) => ({
            label: deparment.name,
            value: deparment.id,
          }))}
        />
      </Form.Item>
    </Form>
  );
};

WorkRoleForm.defaultProps = {
  workRole: {},
};

WorkRoleForm.propTypes = {
  form: PropTypes.object.isRequired,
  workRole: PropTypes.object,
};

export default WorkRoleForm;
