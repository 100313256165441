import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types';
import { Modal, message } from 'antd';
import ClientDeactivationForm from './ClientDeactivationForm';
import { SaveOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { getClientMessages, getErrorMessages, getModalMessages } from '../../../constants/messages';
import { SaveClientsProgrammedDeactivationDate } from '../../Clients/actions';

let modalMessages = getModalMessages(),
    errorMessages = getErrorMessages(),
    clientMessages = getClientMessages();

const messages = {
    ...modalMessages,
    ...errorMessages,
    ...clientMessages,
};

const ClientDeactivationModal = ({data, visible, onClose, title}) => {
    const intl = useIntl();
	const form = useRef();
    const [isSaving, setSaving] = useState(false);

    const submitDeactivaction = async () => {
        try{
            setSaving(true);
            const values = await form.current.validateFields();
            await SaveClientsProgrammedDeactivationDate({...values});
            onClose();
            message.success(intl.formatMessage({...messages.assignProgrammedDeactivations}));
        } catch (error) {
            message.error(intl.formatMessage({...messages.commonError }));
        } finally {
            setSaving(false);
        }
    };

    return (
        <Modal
            open={visible}
            title={title}
            destroyOnClose={true}
            confirmLoading={isSaving}
            onCancel={onClose}
            onOk={submitDeactivaction}
            okText={
                <span>
                    <SaveOutlined/>
                    <FormattedMessage {...messages.save} />
                </span>
            }
        >
            <ClientDeactivationForm
                form={form}
                data={data}
            />
        </Modal>
    );
}

ClientDeactivationModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    data: PropTypes.array,
    title: PropTypes.string,
    visible: PropTypes.bool,
};

export default ClientDeactivationModal