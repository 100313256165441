import React from 'react';
import PropTypes from 'prop-types';
import { message, Table, Spin } from 'antd';
import { injectIntl } from 'react-intl';
import { getClientMessages, getFields, getLanguageIntl } from '../../../../../constants/messages';
import { GetClientProgrammedDeactivationLogs, GetClientProgrammedDeactivationLogsCount } from '../../../actions';
import moment from 'moment';
import AccountTable from '../AccountTable';

let clientMessages = getClientMessages(),
	intlMessages = getLanguageIntl(),
	fieldMessages = getFields();

const messages = {
	...clientMessages,
	...intlMessages,
	...fieldMessages
};

class PDLogsView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			logs: [],
			page: 1,
			pageSize: 5,
			recordCount: 0
		};
	}

	componentDidMount() {
		const { state, props, getIntl, setLoading } = this;
		const { page, pageSize } = state;
		const { clientId } = props;
		const promises = [
			new Promise((resolve, reject) => {
				GetClientProgrammedDeactivationLogsCount(clientId)
					.then(response => resolve(response))
					.catch(() => reject(0));
			}),
			new Promise((resolve, reject) => {
				GetClientProgrammedDeactivationLogs(clientId, page, pageSize)
					.then(response => resolve(response))
					.catch(() => reject(1));
			})
		];
		setLoading(true);
		Promise.all(promises)
			.then(data => {
				const recordCount = data[0];
				const logs = data[1];
				this.setState({ logs, recordCount, isLoading: false });
			})
			.catch(() => {
				setLoading(false);
				message.error(getIntl("getClientDeactivationLogsError"));
			});
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });

	pagingOnChange = (page, pageSize) => {
		const { props, getIntl, setLoading } = this;
		const { clientId } = props;
		setLoading(true);
		GetClientProgrammedDeactivationLogs(clientId, page, pageSize)
			.then(logs => this.setState({ logs, page, pageSize, isLoading: false }))
			.catch(() => {
				setLoading(false);
				message.error(getIntl("getClientDeactivationLogsError"));
			});
	};

	formatDate = (unformatedDate) => {
		if (!unformatedDate) return undefined;
		const { getIntl } = this;
		const locale = getIntl("intl");
		return moment(unformatedDate).utc().locale(locale).format('MMMM DD, YYYY');
	}

	expandedRowRender = (entry) => {
		const { accountLogs = [] } = entry;
		const data = accountLogs.map(accountEntry => {
			const { accountId, account = {}, firstDateConfirmation, dateBeforeConfirmation, sameDateConfirmation } = accountEntry;
			const { name } = account;
			return { id: accountId, name, firstDateConfirmation, dateBeforeConfirmation, sameDateConfirmation };
		});
		return <AccountTable data={data} />;
	}

	renderTable = () => {
		const { state, getIntl, formatDate, pagingOnChange, expandedRowRender } = this;
		const { logs, page, pageSize, recordCount } = state;
		const dataSource = logs.map(log => ({ ...log, key: log.id }));
		const pagination = {
			defaultPageSize: pageSize,
			onChange: pagingOnChange,
			total: recordCount,
			current: page
		};
		const columns = [
			{
				title: getIntl("date"),
				dataIndex: 'date',
				key: 'date',
				render: formatDate
			}
		];
		return <Table dataSource={dataSource} columns={columns} pagination={pagination} size="small" expandedRowRender={expandedRowRender} />;
	}

	fullRender = () => {
		const { state, renderTable } = this;
		const { isLoading } = state;
		return (
			<Spin spinning={isLoading}>
				{renderTable()}
			</Spin>
		);
	}

	render() {
		return this.fullRender();
	}
}

PDLogsView.propTypes = {
	intl: PropTypes.object.isRequired,
	clientId: PropTypes.number.isRequired
};

export default injectIntl(PDLogsView);