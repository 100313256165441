import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, Spin, message } from 'antd';
import { injectIntl } from 'react-intl';
import { getJobMessages, getErrorMessages } from '../../../../../constants/messages';
import { getObjectInArray } from '../../../../../utilities/util';
import { AdvancedSearchForDevice } from '../../../actions';
import debounce from 'lodash/debounce';

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

let jobMessages = getJobMessages(),
    errorMessages = getErrorMessages();

const messages = {
	...jobMessages,
    ...errorMessages
};

class Device extends React.Component {

    constructor(props) {
		super(props);
		this.handleDeviceSearch = debounce(this.handleDeviceSearch, 250);
		this.state = {
            isLoading: false,
            devices: [],
            selectedDevice: {}
        };
    }

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    setLoading = (isLoading) => this.setState({ isLoading });

    handleDeviceSearch = (name) => {
        const { getIntl, setLoading } = this;
        if (name.length <= 0 ) this.setState({ devices: [] });
        else {
            setLoading(true);
            AdvancedSearchForDevice(name)
            .then(devices => this.setState({ isLoading: false, devices }))
            .catch( () => {
                this.setState({ isLoading: false, devices: [] });
                message.error(getIntl("unableToSearchDevices"));
            });
        }
    }

    onChange = (deviceId) => {
        const { devices } = this.state;
        const selectedDevice = getObjectInArray('id', parseInt(deviceId), devices);
        this.setState({ selectedDevice, devices: [] });
    }

    buildDeviceName = (device) => {
        const { pin, imei, description } = device;
        const names = [];
        const checkAndAdd = (property) => { if (property) names.push(property); };
        checkAndAdd(pin);
        checkAndAdd(imei);
        checkAndAdd(description);
        return names.join(" - ");
    }

    buildOptions = () => {
        const { state, getIntl, buildDeviceName } = this;
        const { devices, selectedDevice } = state;
        const option = (key, value, dv) => <Option key={key} value={value}>{buildDeviceName(dv)}</Option>;
        let options = [];
        let active = [];
        let inactive = [];
        if (selectedDevice && selectedDevice.id !== undefined) options.push(option(devices.length, selectedDevice.id, selectedDevice));
        devices.forEach((dv, index) => {
            if (dv.active) active.push(option(index, dv.id, dv));
            else inactive.push(option(index, dv.id, dv));
        });
        options.push(<OptGroup key="active" label={getIntl("active")}>{active}</OptGroup>);
        options.push(<OptGroup key="inactive" label={getIntl("inactive")}>{inactive}</OptGroup>);
        return options;
    }

    render() {
        const { state, props, getIntl, buildOptions, onChange, handleDeviceSearch } = this;
        const { isLoading } = state;
        const { getFieldDecorator } = props.form;
        let options = buildOptions();
        return(
            <FormItem label={getIntl("mainProduct")} >
            {
                getFieldDecorator("gpsId", 
                    {
                        onChange: onChange
                    }
                )(
                    <Select
                        className="job-full-component"
                        showSearch
                        allowClear
                        placeholder={getIntl("mainProduct")}
                        optionFilterProp="children"
                        onSearch={handleDeviceSearch}
                        notFoundContent={isLoading ? <Spin size="small" /> : getIntl("notFound")}
                        dropdownMatchSelectWidth={false}
                    >
                        {options}
                    </Select>
                )
            }
            </FormItem>
        );
    }
}

Device.propTypes = {
	intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired
};

export default injectIntl(Device);