import {
	FETCH_DEPARTMENTS,
    FETCH_WORK_ROLES,
    FETCH_DEPARTMENT_COUNT,
    FETCH_WORK_ROLES_COUNT,
    FETCH_ALL_DEPARTMENTS,
    FETCH_ALL_WORK_ROLES,
    SET_SEARCH,
    SET_SEARCH_WORKROLES
} from './actionTypes';

import {
    CLEAR_SESSION,
	CLEAN_REDUCER
} from '../../constants/actionTypes';

const initState = {
    departments: [],
    departmentCount: 0,
    workRoles: [],
    workRoleCount: 0,
    allDepartments: [],
    allWorkRoles: [],
    searchText:"",
    searchTextWork:""
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case FETCH_DEPARTMENTS:
			return {
				...state,
				departments: action.departments
			};
        case FETCH_WORK_ROLES:
            return {
                ...state,
                workRoles: action.workRoles
            };
        case FETCH_DEPARTMENT_COUNT:
            return {
                ...state,
                departmentCount: action.departmentCount
            };
        case FETCH_WORK_ROLES_COUNT:
            return {
                ...state,
                workRoleCount: action.workRoleCount
            };
        case FETCH_ALL_DEPARTMENTS:
            return {
                ...state,
                allDepartments: action.allDepartments
            };
        case FETCH_ALL_WORK_ROLES:
            return {
                ...state,
                allWorkRoles: action.allWorkRoles
            };
        case SET_SEARCH:
            return {
                ...state,
                searchText:action.searchText
            }
        case SET_SEARCH_WORKROLES:
            return{
                ...state,
                searchTextWork:action.searchTextWork
            }
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;