import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Descriptions, Tooltip } from 'antd';
import { getJobMessages } from '../../../../constants/messages';
import { ActiveDeviceSearch, SearchSwappableDevices, SearchAvailableSimCards } from '../../actions';
import BasicInformation from '../DefaultViews/BasicInformation';
import Embolden from '../../../GlobalComponents/Embolden';
import DescriptionsCombiner from '../DefaultViews/DescriptionsCombiner';
import MissingWON from '../DefaultViews/MissingWON';
import DeviceRender from '../DefaultViews/DeviceRender';
import Device from '../../AddUpdateJob/CommonComponents/Device';
import DeviceTemplate from '../../AddUpdateJob/CommonComponents/DeviceTemplate';
import Sim from '../../AddUpdateJob/CommonComponents/Sim';
import UninstallSim from '../../AddUpdateJob/CommonComponents/UninstallSim';
import ClientKeepsDevice from '../../AddUpdateJob/CommonComponents/ClientKeepsDevice';
import DeviceAccessoriesIntermediator from '../../AddUpdateJob/CommonComponents/DeviceAccessoriesIntermediator';
import { JOBTYPES, JOBSTATUS } from  '../../constants';
import { getNestedValue } from '../../../../utilities/util';
const { TODO, DOING, MISSINGWON, DONE, CANCELLED, ANNULLED, PROFORMA, INVOICED } = JOBSTATUS;
const DescriptionItem = Descriptions.Item;

let jobMessages = getJobMessages();

const messages = {
	...jobMessages
};

class Swap extends React.Component  {

    componentDidUpdate(prevProps) {
        const { isUpdate, saveFields, getField } = this.props;
        if (isUpdate !== prevProps.isUpdate && isUpdate) {
            const sim = getField("gps.sim");
            saveFields({ sim });
        }
    }

    getIntl = (str, values = {}) => this.props.intl.formatMessage({...messages[str]}, values);

    renderDefaultTodo = () => {
        const { getField } = this.props;
        return <BasicInformation getField={getField}/>;
    }

    renderChargeType = () => {
        const { props, getIntl } = this;
        const { getField } = props;
        const embolden = type => (
            <Descriptions layout="vertical" size="small" bordered column={1}>
                <DescriptionItem label={getIntl("jobTypeInformation", { type: getIntl(JOBTYPES.SWAP.intl) })}>
                    <Embolden header={getIntl("chargeType")} value={getIntl(type)} row/>
                </DescriptionItem>
            </Descriptions>
        );
        const chargeType = getField("invoiceInfo.chargeType");
        switch (chargeType) {
            case 3: return embolden("replacement");
            default: return embolden("doesNotHave");
        }
    }

    renderTodo = () => {
        const { renderDefaultTodo, renderChargeType } = this;
        return (
            <DescriptionsCombiner>
                {renderDefaultTodo()}
                {renderChargeType()}
            </DescriptionsCombiner>
        );
    }

    renderDoing = () => {
        const { renderTodo, renderDeviceToRemoveSelector, renderClientKeepsDevice, renderUninstallSim, renderDeviceToReplaceWith, renderDeviceToReplaceWithSimSelector, renderDeviceAccessories } = this;
        return (
            <div>
                {renderTodo()}
                {renderDeviceToRemoveSelector()}
                {renderClientKeepsDevice()}
                {renderUninstallSim()}
                {renderDeviceToReplaceWith()}
                {renderDeviceToReplaceWithSimSelector()}
                {renderDeviceAccessories()}
            </div>
        )
    }

    renderDeviceToRemoveSelector = () => {
        const { props, getIntl } = this;
        const { form, saveFields, getField, isUpdate } = props;
        const { resetFields } = form;
        const save = gps => {
            saveFields({ gps });
            resetFields(["hasPanicButton", "canLockUnlockDoors", "canDisableEngine"]);
        }
        const gps = getField("gps");
        const clientId = getField("client.clientId");
        return (
            <DeviceTemplate
                form={form}
                search={(deviceName) => ActiveDeviceSearch(deviceName, clientId, 10)}
                device={gps}
                disabled={clientId === undefined || isUpdate}
                onChange={save}
                displayLeasedWarning
                fieldName={'removedGpsId'}
                label={getIntl("gpsDeviceToRemove")}
            />
        );
    }

    renderClientKeepsDevice = () => {
        const { form, saveFields, getField } = this.props;
        const save = clientKeepsDevice => saveFields({ clientKeepsDevice });
        const clientKeepsDevice = getField("clientKeepsDevice");
        const clientId = getField("client.clientId");
        return (
            <ClientKeepsDevice
                form={form}
                clientKeepsDevice={clientKeepsDevice}
                onChange={save}
                disabled={clientId === undefined}
            />
        );
    }

    renderUninstallSim = () => {
        const { props, getIntl } = this;
        const { form, saveFields, getField } = props;
        const save = uninstallSim => saveFields({ uninstallSim });
        const uninstallSim = getField("uninstallSim");
        const clientId = getField("client.clientId");
        const selectedSim = getField("sim");
        const removedDeviceSim = getField("gps.sim");
        let disabled = false;
        if (selectedSim && selectedSim.id && removedDeviceSim && removedDeviceSim.id && selectedSim.id === removedDeviceSim.id) disabled = true; // if we're putting removed device sim card into new device
        const component = (
            <UninstallSim
                form={form}
                uninstallSim={uninstallSim}
                onChange={save}
                disabled={clientId === undefined || disabled}
            />
        );
        if (disabled)
            return (
                <Tooltip title={getIntl("swapSameSimTooltip")} mouseEnterDelay={1} placement="topLeft">
                    <div>
                        {component}
                    </div>
                </Tooltip>
            );
        return component;
    }

    renderDeviceToReplaceWith = () => {
        const { props, getIntl } = this;
        const { form, saveFields, getField, isUpdate } = props;
        const { resetFields } = form;
        const save = gps2 => {
            const sim = getNestedValue("sim", gps2);
            saveFields({ gps2, sim });
            resetFields(["sim"]);
        }
        const gps2 = getField("gps2");
        const clientId = getField("client.clientId");
        return (
            <DeviceTemplate
                form={form}
                search={(deviceName) => SearchSwappableDevices(deviceName, clientId, 10)}
                device={gps2}
                disabled={clientId === undefined || isUpdate}
                onChange={save}
                displayInfo={false}
                fieldName={'replacingGpsId'}
                label={getIntl("gpsDeviceToPut")}
            />
        );
    }

    renderDeviceToReplaceWithSimSelector = () => {
        const { form, saveFields, getField } = this.props;
        const { resetFields } = form;
        const sim = getField("sim");
        const clientId = getField("client.clientId");
        const otherIncludedSims = [];
        const assignedSim = getField("gps2.sim");
        const removedDeviceSim = getField("gps.sim");
        if (assignedSim) otherIncludedSims.push(assignedSim);
        if (removedDeviceSim) otherIncludedSims.push(removedDeviceSim);
        const save = sim => {
            if (removedDeviceSim && sim.id === removedDeviceSim.id) {
                saveFields({ sim, uninstallSim: true });
                resetFields(["uninstallSim"]);
            }
            else saveFields({ sim });
        }
        return (
            <Sim
                form={form}
                search={(simName) => SearchAvailableSimCards(simName, 10)}
                sim={sim}
                disabled={clientId === undefined}
                onChange={save}
                otherIncludedSims={otherIncludedSims}
            />
        );
    }

    renderDeviceAccessories = () => {
        const { form, saveFields, getField } = this.props;
        return (
            <DeviceAccessoriesIntermediator
                form={form}
                isUpdate
                getField={getField}
                saveFields={saveFields}
            />
        );
    }

    renderDeviceOnUpdate = () => {
        const { form, saveFields, getField, isUpdate } = this.props;
        const save = gps => saveFields({ gps });
        const gps = getField("gps");
        const clientId = getField("client.clientId");
        return (
            <Device
                form={form}
                search={(deviceName) => ActiveDeviceSearch(deviceName, clientId, 10)}
                device={gps}
                disabled={clientId === undefined || isUpdate}
                onChange={save}
            />
        );
    }

    renderSimOnUpdate = () => {
        const { form, saveFields, getField } = this.props;
        const save = sim => saveFields({ sim });
        const sim = getField("sim");
        const clientId = getField("client.clientId");
        const assignedSim = getField("gps.sim");
        return (
            <Sim
                form={form}
                search={(simName) => SearchAvailableSimCards(simName, 10)}
                sim={sim}
                disabled={clientId === undefined}
                onChange={save}
                otherIncludedSims={assignedSim}
            />
        );
    }

    renderDevice = () => {
        const { props, getIntl } = this;
        const device = props.getField("gps");
        if (device) return <DeviceRender device={device} header={getIntl("devicePutInPlace")}/>
    }

    renderRemovedDevice = () => {
        const { props, getIntl } = this;
        const device = props.getField("swapData.gps");
        if (device) return <DeviceRender device={device} header={getIntl("deviceRemoved")} hideIcon hideDescription hideAccessories/>
    }

    renderMissingWON = () => {
        const { props, renderDone } = this;
        const { form, jobId } = props;
        return <MissingWON form={form} jobId={jobId} renderInfo={renderDone}/>;
    }

    renderDone = () => {
        const { renderDefaultTodo, renderChargeType, renderDevice, renderRemovedDevice } = this;
        return (
            <DescriptionsCombiner>
                {renderDefaultTodo()}
                {renderChargeType()}
                {renderRemovedDevice()}
                {renderDevice()}
            </DescriptionsCombiner>
        );
    }

    renderIsUpdating = () => {
        const { renderDeviceOnUpdate, renderSimOnUpdate, renderDeviceAccessories } = this;
        return (
            <div>
                {renderDeviceOnUpdate()}
                {renderSimOnUpdate()}
                {renderDeviceAccessories()}
            </div>
        );
    }


    renderBasedOnStatus = () => {
        const { props, getIntl, renderTodo, renderDoing, renderMissingWON, renderDone, renderIsUpdating } = this;
        const { getField, isUpdate } = props;
        if (isUpdate) return renderIsUpdating(); 
        const status = getField("status");
        switch (status) {
            case TODO.id: 
            case CANCELLED.id: 
            case ANNULLED.id: return renderTodo();
            case DOING.id: return renderDoing();
            case INVOICED.id:
            case PROFORMA.id:
            case DONE.id: return renderDone();
            case MISSINGWON.id: return renderMissingWON();
            default: return <div>{getIntl("noStatusFormForType")}</div>;
        }
    }
    
	render(){
        return this.renderBasedOnStatus();
	}
}

Swap.defaultProps = {
    isUpdate: false
};

Swap.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    saveFields: PropTypes.func.isRequired,
    getField: PropTypes.func.isRequired,
    isUpdate: PropTypes.bool.isRequired,
    jobId: PropTypes.number.isRequired,
};

export default injectIntl(Swap);