import {
    CLEAN_REDUCER,
	CLEAR_SESSION,
	GET_EXCHANGE_RATE,
	GET_EXCHANGE_RATES,
	ADD_EXCHANGE_RATE,
} from './actionTypes';

const initState = {
	exchangeRate: {},
	exchangeRates: []
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case GET_EXCHANGE_RATE:
			return {
				...state,
				"exchangeRate": action.exchangeRate
			};
		case GET_EXCHANGE_RATES:
			return {
				...state,
				"exchangeRates": action.exchangeRates
			};
		case ADD_EXCHANGE_RATE:
			return state;
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;