import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker, Input, message } from 'antd';
import { getCommissionMessages, getLanguageIntl, getFields, getInvoicingMessages } from '../../constants/messages';
import { LoadCommission } from './actions';
import moment from 'moment';
import BankAccountPicker from './BankAccountPicker';

const FormItem = Form.Item;
const dateFormat = "DD-MM-YYYY";

let intlMessages = getLanguageIntl(),
	fieldMessages = getFields(),
	invoicingMessages = getInvoicingMessages(),
	commissionMessages = getCommissionMessages();

const messages = {
	...intlMessages,
	...fieldMessages,
	...commissionMessages,
	...invoicingMessages
};

class PayView extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			commission: {}
		};
	}

	componentDidMount() {
		const { props, getIntl } = this;
		const { commissionId, setLoading } = props;
		if (commissionId) {
			setLoading(true);
			LoadCommission(commissionId)
				.then(commission => {
					setLoading(false);
					this.setState({ commission });
				})
				.catch(() => {
					setLoading(false);
					message.error(getIntl("loadCommissionError"));
				});
		}
	}
	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	formatPaidDateEventValue = (date) => {
		if (!date) return null;
		const momentAtTwelve = moment(date).set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
		return momentAtTwelve;
	}

	renderPaidDatePickerField = () => {
		const { state, props, getIntl, formatPaidDateEventValue } = this;
		const { commission } = state;
		const { getFieldDecorator } = props.form;
		const { paidDate = null } = commission;
		return (
			<FormItem label={getIntl("paidDate")} >
				{
					getFieldDecorator(`paidDate`,
						{
							initialValue: paidDate ? moment(paidDate).utc() : moment().set({ hour: 12, minute: 0, second: 0, millisecond: 0 }),
							getValueFromEvent: formatPaidDateEventValue,
							rules: [{
								required: true,
								message: getIntl("paidDateIsRequired"),
							}]
						}
					)(
						<DatePicker
							className="job-full-component"
							showTime={false}
							format={dateFormat}
							allowClear={false}
							showToday={false}
						/>
					)
				}
			</FormItem>
		);
	}

	renderCommentField = () => {
		const { state, props, getIntl } = this;
		const { commission } = state;
		const { getFieldDecorator } = props.form;
		const { paidComment = null } = commission;
		const maxLength = 255;
		return (
			<FormItem label={getIntl("bankReferenceComment")} >
				{
					getFieldDecorator(`paidComment`,
						{
							initialValue: paidComment,
							rules: [{
								max: maxLength,
								message: getIntl("paidCommentLengthError", { maxLength }),
							}]
						}
					)(
						<Input
							className="job-full-component"
						/>
					)
				}
			</FormItem>
		);
	}

	renderDestinationAccountPicker = () => {
		const { state, props } = this;
		const { commission } = state;
		const { form } = props;
		const { bankAccount = {} } = commission;
		return <BankAccountPicker form={form} bankAccount={bankAccount}/>
	}


	fullRender = () => {
		const { renderPaidDatePickerField, renderCommentField, renderDestinationAccountPicker } = this;
		return (
			<div>
				{renderDestinationAccountPicker()}
				{renderPaidDatePickerField()}
				{renderCommentField()}
			</div>
		);
	}

	render() {
		return this.fullRender();
	}
}

PayView.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	commissionId: PropTypes.number.isRequired,
	setLoading: PropTypes.func.isRequired
};

export default injectIntl(PayView);