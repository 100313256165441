import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Button } from 'antd';
import { injectIntl } from 'react-intl';
import { getInvoicingMessages, getModalMessages } from '../constants/messages';
import EditProductForm from './EditProductForm';

let invoicingMessages = getInvoicingMessages(),
	modalMessages = getModalMessages();

const messages = {
	...invoicingMessages,
	...modalMessages
};

class EditProductModal extends React.Component {

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	handleSave = () => {
		const { form, handleSave, handleCancel, product } = this.props;
		const { validateFields } = form;
		const { total: prevSubTotal } = product;
		validateFields({ force: true }, (err, values) => {
			if (!err) {
				const { productId, description, quantity, price, priceChangeReason } = values;
				const total = quantity * price; 
				const newProductInfo = { ...product, total, prevSubTotal ,productId, description, quantity, price, priceChangeReason };
				handleSave(newProductInfo);
				handleCancel();
			}
		});
	}

	renderButtons = () => {
		const { props, getIntl, handleSave } = this;
		const { handleCancel } = props;
		return [
			<Button key="cancel" onClick={handleCancel} >{getIntl("cancel")}</Button>,
			<Button key="save" type="primary" onClick={handleSave} >{getIntl("edit")}</Button>
		];
	}

	render() {
		const { props, renderButtons } = this;
		const { form, showModal, handleCancel, product } = props;
		return (
			<Modal
				open={showModal}
                title={this.getIntl("editProduct")}
				onCancel={handleCancel}
				destroyOnClose={true}
				footer={renderButtons()}
			>
				<EditProductForm 
					form={form} 
					currentProduct={product} 
				/>
			</Modal>
		);
	}
}

EditProductModal.propTypes = {
	intl: PropTypes.object,
	form: PropTypes.object,
	showModal: PropTypes.bool,
	product: PropTypes.object,
	handleSave: PropTypes.func,
	handleCancel: PropTypes.func,
};

export default injectIntl(Form.create()(EditProductModal));
