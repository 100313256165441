import axios from 'axios';
import {
    GET_FISCAL_DATA, ADD_FISCAL_DATA, EDIT_FISCAL_DATA, UPDATED_DOCUMENT_NUMBER
} from './actionTypes';


export const GetFiscalData = () => (dispatch) => {
	return axios.get('/ActiveFiscalData')
	.then((response)=>{
		let fiscalData = {}; 
		response.data.map((item) => {
			if(item.type == 0){
				fiscalData = {...fiscalData, "invoice": item};
			}
			if(item.type == 1){
				fiscalData = {...fiscalData, "credit": item};
			}
			if(item.type == 2){
				fiscalData = {...fiscalData, "debit": item};
			}
		});
		dispatch({type: GET_FISCAL_DATA, fiscalData: fiscalData});
		return fiscalData;
	});
};

export const AddFiscalData = (newFiscalData) => (dispatch) => {
	let options = {
		cai: newFiscalData.cai,
		initialRValue: newFiscalData.rangemin,
		finalRValue: newFiscalData.rangemax,
		prefix: newFiscalData.prefix,
		currentRValue: newFiscalData.rangemin,
		type: newFiscalData.type,
		expiryDate: newFiscalData.expiry,
		nextRequested: false
	};

	return axios.post('/FiscalData', options)
	.then(()=>{
		dispatch({ type:ADD_FISCAL_DATA });
	});
};

export const EditFiscalData = (updatedFiscalData) => (dispatch) => {
	let options = {
		cai: updatedFiscalData.cai,
		initialRValue: updatedFiscalData.rangemin,
		finalRValue: updatedFiscalData.rangemax,
		prefix: updatedFiscalData.prefix,
		currentRValue: updatedFiscalData.currentRValue,
		type: updatedFiscalData.type,
		expiryDate: updatedFiscalData.expiry,
		nextRequested: updatedFiscalData.nextRequested
	};

	return axios.patch(`/FiscalData/${updatedFiscalData.id}`, options)
	.then(()=>{
		dispatch({type:EDIT_FISCAL_DATA});
		dispatch({type:UPDATED_DOCUMENT_NUMBER});
	});
};

export const SetAsRequested = (id) => (dispatch) => {
	let options = {
		nextRequested: true
	};

	return axios.patch(`/FiscalData/${id}`, options)
	.then(()=>{
		dispatch({type:EDIT_FISCAL_DATA});
	});
}