import compile from 'string-template/compile';
import {
	message
} from 'antd';
import {
	REGEX,
	S3_BUCKET,
	API_BASE_URL
} from '../constants/global';
import {
	CLEAR_SESSION
} from '../constants/actionTypes';
import history from '../history';
import MobileDetect from 'mobile-detect';
import cookie from 'react-cookies';

export const isEmail = (email) => {
	let re = REGEX.email;
	return re.test(email);
};
import {
	defineMessages
} from 'react-intl';
import defaults from '../int18/default';

export const ShowErrors = (error, dispatch, messages) => {
	//console.log("error: ", error);
	if (error.response) {
		let data = error.response.data.error;
		if (data.code) {
			if (data.code == "EREQUEST") {

				let field = errorFields(
					JSON.parse(error.config.data),
					data.message
				);
				let compiler = compile(messages[data.code]);
				message.error(compiler({
					field: messages[`field.${field}`]
				}), 5);
			} else {
				let compiler = compile(messages[data.code]);
				message.error(compiler({}), 5);
				if (data.statusCode == 401) {
					dispatch({
						type: CLEAR_SESSION
					});
				}
				if (data.statusCode == 403) {
					history.push("/");
				}
			}
		} else if (data.details) {
			if (data.details.codes) {
				let compiler;
				Object.keys(data.details.codes).forEach(function (element) {
					compiler = compile(messages[data.details.codes[element]]);

					message.error(compiler({
						field: messages[`field.${element}`]
					}), 5);
				});
			} else {
				message.error(messages["error.common"], 5);
			}
		}
	} else if (error.request) {
		// The request was made but no response was received

		// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
		// http.ClientRequest in node.js

		message.error(messages["error.server"], 5);
	} else {
		// Something happened in setting up the request that triggered an Error
		message.error(messages["error.common"], 5);
	}
};

export const notify = (message, options = {}) => {
	let compiler = compile(message);
	message.success(compiler(options), 5);
};

export const elementExist = (arr, key, value) => {
	if (!value)
		return false;
	for (let i = 0; i < arr.length; i++) {
		let element = arr[i];
		if (element[key] == value.id)
			return true;
	}
	return false;
};

export const getRowObject = (id, arr) => {
	let item = arr.filter((element) => {
		return element.id == id;
	});
	if (item.length > 0)
		return item[0];
	return {};
};

const errorFields = (record, message) => {
	let scapeRegex = new RegExp("([[|\\|^|$|.|||?|*|+|(|)|{|}|])", "g");
	let theKeys = Object.keys(record);
	for (let i = 0; i < theKeys.length; i++) {
		let element = theKeys[i];
		let reg = new RegExp("^.*\\(" + record[element].replace(scapeRegex, "\\$1") + "\\).*$");
		if (reg.test(message)) {
			return element;
		}
	}
};

export const isMobile = () => {
	let md = new MobileDetect(window.navigator.userAgent);
	let mobile = md.phone();

	return !!mobile;
};


export const isTablet = () => {
	let md = new MobileDetect(window.navigator.userAgent);
	let mobile = md.mobile();

	return !!mobile;
};

export const splitName = (name) => {
	let output = name.trim().split(" ")[0];
	if (output.length > 10)
		return `${output.substring(0, 7)}...`;
	return output;
};

export const commonUpload = (operation, directory) => {
	let token = cookie.load("token");
	let container = encodeURIComponent(`${S3_BUCKET}/${directory}`);
	let url = `${API_BASE_URL}/S3Buckets/${operation}/${container}?access_token=${token}`;
	return url;
};

export const s3UploadLink = (directory) => commonUpload("upload", directory);

export const getS3PublicLink = (directory) => {
	let container = encodeURIComponent(directory);
	return `/S3Buckets/getS3PublicLink/${container}`;
};

export const ACL4Component = (roles, MyComponent, permitedRoles) => {
	if (!permitedRoles) return MyComponent;
	let permited = false;
	for (let i = 0; i < permitedRoles.length; i++) {
		if (roles.indexOf(permitedRoles[i]) >= 0)
			permited = true;
	}

	return permited && MyComponent;
};

export const existsInArray = (value, array) => {
	return array.indexOf(value) > -1;
};

export const arrayElementContainedInArray = (baseArray, arrayToSearch) => {
	return baseArray.some(r => arrayToSearch.includes(r));
};

export const getObjectInArray = (elementName, value, array) => {
	if (array != undefined && Array.isArray(array) && elementName != undefined && value != undefined) {
		if (Array.isArray(value)) {
			let returnArray = [];
			for (let i = 0; i < value.length; i++) {
				returnArray.push(array.find(x => x[elementName] === value[i]));
			}
			return cleanArray(returnArray, undefined);
		}
		else
			return array.find(x => x[elementName] === value);
	}
	else
		return undefined;
};

export const objectContainsNestedValue = (elementName, value, object) => {
	if (elementName === undefined || object === undefined || typeof object !== 'object' || typeof elementName !== 'string')
		return false;
	let index = elementName.indexOf('.');
	if (index > -1)
		return objectContainsNestedValue(elementName.substr(index + 1), value, object[elementName.substring(0, index)]);
	if (Array.isArray(value)) {
		if (existsInArray(object[elementName], value))
			return true;
	}
	if (object[elementName] === value)
		return true;
	return false;
};

export const getObjectWithNestedValueFromArray = (elementName, value, array) => {
	if (array === undefined || !Array.isArray(array) || elementName === undefined || value === undefined)
		return undefined;
	if (Array.isArray(value)) {
		let returnArray = [];
		array.forEach(object => {
			if (objectContainsNestedValue(elementName, value, object))
				returnArray.push(object);
		});
		return returnArray;
	}
	let returnObject = undefined;
	array.forEach(object => {
		if (objectContainsNestedValue(elementName, value, object))
			returnObject = object;
	});
	return returnObject;
};

export const cleanArray = (array, deleteValue) => {
	if (array == undefined || !Array.isArray(array))
		return array;
	let returnArray = [];
	let valuesToDelete = (Array.isArray(deleteValue) ? deleteValue : [deleteValue]);
	for (let i = 0; i < array.length; i++) {
		if (!existsInArray(array[i], valuesToDelete)) {
			returnArray.push(array[i]);
		}
	}
	return returnArray;
};

export const formatDate = (unformatedDate, locale, options) => {
	let date = new Date(unformatedDate);
	let loc = locale != undefined ? locale : {};
	let opt = options != undefined ? options : {};
	return date.toLocaleDateString(loc, opt);
};

export const formatDateWithTime = (unformatedDate, locale, dateOptions, timeOptions) => {
	let date = new Date(unformatedDate);
	let loc = locale != undefined ? locale : {};
	let dateOpt = dateOptions != undefined ? dateOptions : {};
	let timeOpt = timeOptions != undefined ? timeOptions : {};
	return date.toLocaleDateString(loc, dateOpt) + ', ' + date.toLocaleTimeString(loc, timeOpt);
};

export const parseElementInArrayToString = (elementName, array) => {
	if (!Array.isArray(array))
		return [];
	let returnArray = [];
	for (let i = 0; i < array.length; i++) {
		if (array[i][elementName] != undefined)
			returnArray.push(array[i][elementName].toString());
	}
	return returnArray;
};

export const objectIsEmpty = (object) => {
	if (Object.keys(object).length === 0 && object.constructor === Object) return true;
	return false;
};

export const objectOnlyHasTheseElements = (elements, object) => {
	if (object === undefined || object.constructor !== Object || elements === undefined) return false;
	if (objectIsEmpty(object)) return false;
	const valuesToDetect = (Array.isArray(elements) ? [...elements] : [elements]);
	let foundCounter = 0;
	for (let elem in object) {
		if (!existsInArray(elem.toString(), valuesToDetect)) return false;
		else foundCounter++;
	}
	if (valuesToDetect.length !== foundCounter) return false;
	return true;
};

export function roundN(num, n) {
	if (num === 0) {
		let value = "0.";
		let a = 0;
		while (a < n) {
			value += "0";
			a++;
		}
		return value;
	}
	return parseFloat(Math.round(num * Math.pow(10, n)) / Math.pow(10, n)).toFixed(n).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export function getCurrencyDisplayer(currencyIsDollar) {
	if (currencyIsDollar)
		return { symbol: "$", regex: /\$\s?|(,*)/g }
	return { symbol: "L", regex: /L\s?|(,*)/g }
}

export function displayCurrency(amount, isLps = true) {
	if (typeof amount !== 'string' && typeof amount !== 'number') return undefined;
	return `${isLps ? 'L.' : '$'} ${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function isEmpty(obj) {
	if (obj == null) return true;

	if (obj.length > 0) return false;
	if (obj.length === 0) return true;

	if (typeof obj !== "object") return true;

	for (let key in obj) {
		if (hasOwnProperty.call(obj, key)) return false;
	}

	return true;
}

export const countDuplicatesInArray = (array) => {
	if (!Array.isArray(array))
		return [];
	let counter = {};
	array.forEach(function (value) {
		let key = JSON.stringify(value);
		if (counter[key] === undefined) counter[key] = { "count": 1, "value": value };
		else counter[key].count = counter[key].count + 1;
	});
	let returnArray = [];
	for (let elem in counter) {
		returnArray.push(counter[elem]);
	}
	return returnArray;
};

export function isANumber(str) {
	return !/\D/.test(str) || !isNaN(parseFloat(str));
}

let ones = ['', 'UNO', 'DOS', 'TRES', 'CUATRO', 'CINCO', 'SEIS', 'SIETE', 'OCHO', 'NUEVE'];
let tens = ['', '', 'VEINTI', 'TREINTA', 'CUARENTA', 'CINCUENTA', 'SESENTA', 'SETENTA', 'OCHENTA', 'NOVENTA'];
let teens = ['DIEZ', 'ONCE', 'DOCE', 'TRECE', 'CATORCE', 'QUINCE', 'DIECISEIS', 'DEICISIETE', 'DIECIOCHO', 'DIECINUEVE'];
let hundreds = ['', '', 'DOSC', 'TRESC', 'CUATROC', 'QUIN', 'SEISC', 'SETEC', 'OCHOC', 'NOVEC'];

function convert_thousands(num) {
	if (num >= 1000) {
		if (num < 2000) {
			return "MIL " + convert_hundreds(num % 1000);
		} else {
			return convert_hundreds(Math.floor(num / 1000)) + " MIL " + convert_hundreds(num % 1000);
		}

	}
	else {
		return convert_hundreds(num);
	}
}

function convert_hundreds(num) {
	if (num > 99) {
		if (num < 200) {
			if (num === 100) {
				return "CIEN";
			} else {
				return "CIENTO " + convert_tens(num % 100);
			}
		} else {
			return hundreds[Math.floor(num / 100)] + "IENTOS " + convert_tens(num % 100);
		}
	}
	else {
		return convert_tens(num);
	}
}

function convert_tens(num) {
	if (num < 10) return ones[num];
	else if (num >= 10 && num < 20) return teens[num - 10];
	else if (num >= 20 && num < 30) {
		if (num === 20) {
			return "VEINTE";
		} else {
			return "VEINTI" + ones[num % 10];
		}
	}
	else {
		if (num % 10 === 0) {
			return tens[Math.floor(num / 10)];
		} else {
			return tens[Math.floor(num / 10)] + " Y " + ones[num % 10];
		}
	}
}

export function numToWords(num) {
	let cents = roundN((num - Math.floor(num)) * 100, 0);
	let lemps = Math.floor(num);

	if (num == 0) {
		return "zero";
	} else {
		let amountInWords = convert_thousands(lemps) + " LEMPIRAS ";
		if (cents == 0) {
			return amountInWords + "EXACTOS";
		} else {
			return amountInWords + "CON " + cents.toString() + "/100 CENTAVOS";
		}
	}
}

export const substituteObjectInArrayByValue = (field, value, array, object) => {
	if (field === undefined || value === undefined || object === undefined || array === undefined || !Array.isArray(array))
		return array;
	let returnArray = [];
	for (let index = 0; index < array.length; index++) {
		const element = array[index];
		if (element[field] === value)
			returnArray.push(object);
		else
			returnArray.push(element);
	}
	return returnArray;
};

export function calculateDayDifference(startDate, endDate) {
	if (endDate < startDate)
		return 0;
	var timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
	return Math.ceil(timeDiff / (1000 * 3600 * 24));
}

export function calculateArrearValue(arrearDays, subTotal) {
	return arrearDays * (subTotal * 0.05);
}

export const objectToArray = (object) => {
	let returnArray = Object.keys(object).map(function (key) { return object[key]; })
	return returnArray;
};

export const getObjectInObject = (elementName, value, object) => {
	return getObjectInArray(elementName, value, objectToArray(object));
};

// only supports nested objects in other objects
export const getNestedValue = (value, object) => {
	if (value === undefined || object === undefined || typeof value !== 'string' || typeof object !== 'object')
		return undefined;
	const index = value.indexOf('.');
	if (index > -1)
		return getNestedValue(value.substr(index + 1), object[value.substring(0, index)]);
	else return object[value];
};

// only supports nested arrays in other arrays
export const getArrayNestedValue = (value, array) => {
	if (value === undefined || array === undefined || typeof value !== 'string' || !Array.isArray(array))
		return undefined;
	const endIndex = value.indexOf(']');
	if (value[0] !== '[' || endIndex === -1) return undefined;
	const numberStr = value.substring(1, endIndex);
	if (numberStr.length <= 0) return undefined;
	const accessPosition = Number(value.substring(1, endIndex));
	if (isNaN(accessPosition)) return undefined;
	const remainingValue = value.substr(endIndex + 1);
	if (remainingValue.length <= 0) return array[accessPosition];
	return getArrayNestedValue(remainingValue, array[accessPosition]);
};

// supports nested objects and arrays
export const advancedGetNestedValue = (value, objectOrArray) => {
	if (value === undefined || objectOrArray === undefined || typeof value !== 'string' || (typeof objectOrArray !== 'object' && !Array.isArray(objectOrArray)))
		return undefined;
	let currentNestedValue = objectOrArray;
	const splitValue = value.split('.');
	for (let index = 0; index < splitValue.length; index++) {
		const element = splitValue[index];
		if (element.indexOf('[') > -1) {
			// is array access or weird object element name
			const accessStartIndex = element.indexOf('[');
			if (accessStartIndex === 0) {
				const tempArrayCheck = getArrayNestedValue(element, currentNestedValue);
				if (tempArrayCheck !== undefined) currentNestedValue = tempArrayCheck;
				else currentNestedValue = getNestedValue(element, currentNestedValue);
			}
			else {
				const arrayName = element.substring(0, accessStartIndex);
				const arrayAccessStr = element.substr(accessStartIndex);
				const tempArrayCheck = getArrayNestedValue(arrayAccessStr, getNestedValue(arrayName, currentNestedValue));
				if (tempArrayCheck !== undefined) currentNestedValue = tempArrayCheck;
				else currentNestedValue = getNestedValue(element, currentNestedValue);
			}
		} else {
			// is object element access
			currentNestedValue = getNestedValue(element, currentNestedValue);
		}
	}
	return currentNestedValue;

};

export const int18Catch = (message) => {
	if (!message) {
		return defineMessages({
			id: 'error.noTranslation',
			defaultMessage: defaults['error.noTranslation'],
		});
	}
	return message;
};

export const applyObject = (object, apply) => {
	if (!object || typeof (object) !== 'object' || typeof (apply) !== 'function') return undefined;
	for (const [key, value] of Object.entries(object)) {
		apply(key, value);
	}
}

export const searchInString = (search, compare) => {
	if (compare && compare.length)
		return compare
			.toLowerCase()
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.includes(search.trim().toLowerCase());
	return false;
};
