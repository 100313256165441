import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Popover, Input, message } from 'antd';
import {
	getactionMessages, getAuthMessages, getModalMessages, getFields, getErrorMessages, getRegistrationMessages,
	getClientMessages, getLanguageIntl
} from '../../../constants/messages';
import { ApproveAndLink, RejectClient } from '../actions';
import RabbitConnect from './RabbitConnect';

const FormItem = Form.Item;

let actionMessages = getactionMessages(),
	authMessages = getAuthMessages(),
	errorMessages = getErrorMessages(),
	fieldMessages = getFields(),
	modalMessages = getModalMessages(),
	RegistrationMessages = getRegistrationMessages(),
	ClientMessages = getClientMessages(),
	intlMessages = getLanguageIntl();

const messages = {
	...actionMessages,
	...authMessages,
	...modalMessages,
	...fieldMessages,
	...errorMessages,
	...RegistrationMessages,
	...ClientMessages,
	...intlMessages
};

class Buttons extends React.Component {

	handleApprove = () => {
		const { setState } = this.props;
		setState({ approvingClient: true });
	}

	handleApproveAfterType = () => {
		const { state } = this.props;
		const { linkToExternalDb } = state;
		if (linkToExternalDb) this.linkClient();
	}

	linkClient = () => {
		const { form, approveAndLink, clientId, intl, setState, handleCancel } = this.props;
		const { validateFields } = form;
		validateFields((errors, values) => {
			if (!errors) {
				const { linkClient } = values;
				setState({ loading: true });
				const activityMessage = intl.formatMessage({ ...messages.clientApproved });
				approveAndLink(clientId, linkClient, activityMessage)
					.then(() => {
						message.success(intl.formatMessage({ ...messages.clientLinkSuccess }));
						handleCancel();
					})
					.catch(() => {
						setState({ loading: false });
						message.error(intl.formatMessage({ ...messages.cantLinkClients }));
					});
			}
		});
	}

	handleReject = (rejectedMessage) => {
		const { rejectClient, intl, setState, clientId, handleCancel } = this.props;
		setState({ loading: true });
		rejectClient(clientId, rejectedMessage)
			.then(() => {
				message.success(intl.formatMessage({ ...messages.clientRejectSuccess }));
				handleCancel();
			})
			.catch(() => {
				setState({ loading: false });
				message.error(intl.formatMessage({ ...messages.clientRejectError }));
				setState({ loading: false });
			});
	}

	handleRejectMessageValidation = (rule, value, callback) => {
		const { setState } = this.props;
		if (!value) {
			setState({ rejectedMessageSet: false });
		} else {
			setState({ rejectedMessageSet: true });
		}
		callback();
	}

	renderedButtons = () => {
		const { intl, canApproveReject, enableApprove, form, handleCancel, state, setState, clientId, clientEmails, clientName } = this.props;
		const { rejectedMessageSet, approvingClient, approvalTypeSet, createNewClient, code, loading } = state;
		let buttonArray = [];
		if (canApproveReject) {
			if (enableApprove) {
				buttonArray.push(<Button
					className="client-view-approve"
					onClick={() => this.handleApprove()}
					type="primary"
					key="approve"
					loading={loading}
				>
					{intl.formatMessage({ ...messages.approve })}
				</Button>);
			} else {
				buttonArray.push(<Popover key="approve" content={intl.formatMessage({ ...messages.clientApproveError })}>
					<Button
						className="client-view-approve"
						type="primary"
						disabled={true}
						style={{ marginRight: 8 }}
						loading={loading}
					>
						{intl.formatMessage({ ...messages.approve })}
					</Button>
				</Popover>);
			}

			const rejectForm = (
				<Form className="login-form col-8" layout='vertical'>
					<FormItem label={intl.formatMessage({ ...messages.rejectMessage })} >
						{
							form.getFieldDecorator('rejectedMessage',
								{
									rules: [{
										required: true,
										message: intl.formatMessage({ ...messages.rejectedMessageError }),
										max: 255
									}, {
										validator: (rule, value, callback) => this.handleRejectMessageValidation(rule, value, callback),
									}],
								}
							)(
								<Input />
							)
						}
					</FormItem>
					<FormItem>
						<Button
							type="primary"
							disabled={!rejectedMessageSet}
							onClick={() => this.handleReject(form.getFieldValue('rejectedMessage'))}
							loading={loading}
						>
							{intl.formatMessage({ ...messages.confirmReject })}
						</Button>
					</FormItem>
				</Form>
			);
			buttonArray.push(<Popover key="reject" content={rejectForm} trigger="click">
				<Button
					className="client-view-reject"
					type="danger"
					loading={loading}
				>
					{intl.formatMessage({ ...messages.reject })}
				</Button>
			</Popover>);
		}
		if (approvingClient && approvalTypeSet) {
			if (createNewClient) {
				buttonArray.push(<RabbitConnect className="client-view-approve" key="approve" code={code} state={state} setState={setState}
					clientId={clientId} clientName={clientName} emails={clientEmails} handleCancel={handleCancel}/>);
			} else {
				buttonArray.push(<Button
					className="client-view-approve"
					onClick={() => this.handleApproveAfterType()}
					type="primary"
					key="approve"
					loading={loading}
				>
					{intl.formatMessage({ ...messages.approve })}
				</Button>);
			}
		}
		buttonArray.push(<Button
			className="client-view-cancel"
			onClick={() => handleCancel()}
			key="cancel"
		>
			{intl.formatMessage({ ...messages.cancel })}
		</Button>);

		return buttonArray;
	}

	render() {
		return (
			<span>
				{this.renderedButtons()}
			</span>
		);
	}
}

Buttons.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	state: PropTypes.object.isRequired,
	setState: PropTypes.func.isRequired,
	handleCancel: PropTypes.func.isRequired,
	clientId: PropTypes.number.isRequired,
	approveAndLink: PropTypes.func.isRequired,
	rejectClient: PropTypes.func.isRequired,
	enableApprove: PropTypes.bool.isRequired,
	canApproveReject: PropTypes.bool.isRequired,
	clientName: PropTypes.string.isRequired,
	clientEmails: PropTypes.array.isRequired,
};

const mapDispatchToProps = (dispatch) => {
	return {
		approveAndLink: (clientId, linkedClientId, activityMessage) => dispatch(ApproveAndLink(clientId, linkedClientId, activityMessage)),
		rejectClient: (clientId, activityMessage) => dispatch(RejectClient(clientId, activityMessage)),
	};

};

export default connect(null, mapDispatchToProps)(injectIntl(Buttons));