import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Descriptions } from 'antd';
import { getJobMessages, getPopConfirm } from '../../../../constants/messages';
import BasicInformation from '../DefaultViews/BasicInformation';
import Embolden from '../../../GlobalComponents/Embolden';
import DescriptionsCombiner from '../DefaultViews/DescriptionsCombiner';
import DeviceRender from '../DefaultViews/DeviceRender';
import { JOBTYPES, JOBSTATUS, DEVICESTATUS } from  '../../constants';
const { TODO, DONE, CANCELLED, ANNULLED } = JOBSTATUS;
const DescriptionItem = Descriptions.Item;

let jobMessages = getJobMessages(),
    popConfirmMessages = getPopConfirm();

const messages = {
    ...jobMessages,
    ...popConfirmMessages
};

class Deactivate extends React.Component  {

    getIntl = (str, values = {}) => this.props.intl.formatMessage({...messages[str]}, values);

    renderDefaultTodo = () => {
        const { getField } = this.props;
        return <BasicInformation getField={getField} hideClientAddress hideJobDuration hideJobTechnicians/>;
    }

    renderTypeInfo = () => {
        const { props, getIntl } = this;
        const { getField } = props;
        const deviceStatusId = getField("deactivateData.deviceStatusId");
        const clientKeepsDevice = getField("deactivateData.clientKeepsDevice");
        const uninstallSim = getField("deactivateData.uninstallSim");
        const status = deviceStatus => deviceStatus === DEVICESTATUS.HIDDEN ? getIntl("hide") : getIntl("cancel");
        const yesNo = value => value ? getIntl("yes") : getIntl("no");
        return (
            <Descriptions layout="vertical" size="small" bordered column={1}>
                <DescriptionItem label={getIntl("jobTypeInformation", { type: getIntl(JOBTYPES.DEACTIVATE.intl) })}>
                    <Embolden header={getIntl("deviceStatus")} value={status(deviceStatusId)} row/>
                    <Embolden header={getIntl("clientKeepsDevice")} value={yesNo(clientKeepsDevice)} row/>
                    <Embolden header={getIntl("uninstallSim")} value={yesNo(uninstallSim)} row/>
                </DescriptionItem>
            </Descriptions>
        );
    }

    renderTodo = () => {
        const { renderDefaultTodo, renderTypeInfo, renderDevice } = this;
        return (
            <DescriptionsCombiner>
                {renderDefaultTodo()}
                {renderTypeInfo()}
                {renderDevice()}
            </DescriptionsCombiner>
        );
    }

    renderDevice = () => {
        const device = this.props.getField("gps");
        return <DeviceRender device={device}/>
    }

    renderBasedOnStatus = () => {
        const { props, getIntl, renderTodo } = this;
        const { getField } = props;
        const status = getField("status");
        switch (status) {
            case TODO.id: 
            case CANCELLED.id: 
            case ANNULLED.id:
            case DONE.id: return renderTodo();
            default: return <div>{getIntl("noStatusFormForType")}</div>;
        }
    }
    
	render(){
        return this.renderBasedOnStatus();
	}
}

Deactivate.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    saveFields: PropTypes.func.isRequired,
    getField: PropTypes.func.isRequired
};

export default injectIntl(Deactivate);