import React from 'react';
import PropTypes from 'prop-types';
import '@ant-design/compatible/assets/index.css';
import { Input, Form} from 'antd';
import { useIntl } from 'react-intl';
import { getDepartmentMessages } from '../../../constants/messages';

let departmentMessages = getDepartmentMessages();
const messages = {
	...departmentMessages
};

const DepartmentForm = ({ form, department }) => {
	const intl = useIntl();
	const { name } = department;

  return (
	<Form 
		ref={form} 
		layout='vertical'
		initialValues={{ name: name }}
	>
		<Form.Item
			name="name"
			label={intl.formatMessage({ ...messages.departmentName })}
			rules={[{
				required: true,
				whitespace: true,
				message: intl.formatMessage( {...messages.departmentNameError })
			}]}
		>
			<Input placeholder={intl.formatMessage({ ...messages.departmentName })}/>		
		</Form.Item>
	</Form>
  )
}

DepartmentForm.defaultProps = { 
    department: {}
};

DepartmentForm.propTypes = {
	form: PropTypes.object.isRequired,
	department: PropTypes.object
};

export default DepartmentForm
