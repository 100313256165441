import React from 'react';
import PropTypes from 'prop-types';
import {
	connect
} from 'react-redux';
import {
	getAuthMessages,
	getModalMessages,
	getFields,
	getErrorMessages,
	getPopConfirm,
	getactionMessages,
	getTableMessages
} from '../constants/messages';
import {
	INIT_REQUEST_ACCOUNT
} from '../constants/actionTypes';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Row, Col, Table, Popconfirm } from 'antd';
import {
	injectIntl,
	FormattedMessage,
	FormattedDate
} from 'react-intl';
import {
	GetSessionsById,
	RemoveSessions
} from '../actions/AccountAction';
import Moment from 'react-moment';
import {locale} from '../constants/global';
import 'rc-table/assets/index.css';
import 'rc-table/assets/animation.css';

let authMessages = getAuthMessages(),
	errorMessages = getErrorMessages(),
	fieldMessages = getFields(),
	PopConfirmMessages = getPopConfirm(),
	AccountMessages = getactionMessages(),
	tableMessages = getTableMessages(),
	modalMessages = getModalMessages();

const messages = {
	...authMessages,
	...modalMessages,
	...fieldMessages,
	...errorMessages,
	...AccountMessages,
	...PopConfirmMessages,
	...tableMessages
};

class SessionForm extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedUsers: [],
			search: "",
			current: 1
		};

		const {
			userId,
			getSessionsById
		} = props;

		getSessionsById(userId);
	}
	

	delete = () => {
		const {
			removeSessions,
			userId
		} = this.props;

		removeSessions(userId, this.state.selectedUsers);

		this.setState({
			selectedUsers: []
		});
	};
	pagingOnChange = (page, pageSize) => {
		const {
			getSessionsById,
			userId
		} = this.props;

		getSessionsById(userId, page, pageSize);

		this.setState({
			current: page
		});
	};

	handleSelectUser = (selected) => {
		this.setState({
			selectedUsers: selected
		});
	};

	showTotal = (total) => {

		return <FormattedMessage {...messages.total} values= {{name: total}} />;
	};

	render() {
	let {
		intl,
		dataSource,
		lang,
		isLoading
	} = this.props;

	const pagination = {
		'defaultPageSize': 5,
		'onChange': this.pagingOnChange,
		'total': this.props.count,
		'showTotal': this.showTotal
	};
	const rowSelection = {
		"onChange": this.handleSelectUser,
		"type": "checkbox",
		"selectedRowKeys": this.state.selectedUsers
	};
	const columns = [{
					title: `${intl.formatMessage( {...messages.ttl})}`,
					dataIndex: 'userId',
					render: (userId, record) => {
						return <span> <FormattedMessage {...messages.ttl} /> {((this.state.current - 1) * 5) + dataSource.indexOf(record) + 1}</span>;
					}
				},{
					title: `${intl.formatMessage( {...messages.die})}`,
					dataIndex: 'ttl',
					render: (ttl, record) => {
						let expires = new Date(record.id);
						expires = new Date(expires.getTime() + (record.ttl * 1000));
						return <span>{<Moment locale={locale[lang].intl} fromNow >{expires}</Moment>}</span>;
					}
				},{
					title: `${intl.formatMessage( {...messages.created})}`,
					dataIndex: 'id',
					render: (id) => {
						return <span> <FormattedDate value={new Date(id)} hour12={true} month="short" day="2-digit" /> </span>;
					}
				},
			];

	return (
        <div>
			<Row className="sessionControl">
				<Col span={6} className="action">
					<Popconfirm title={this.props.intl.formatMessage( {...messages.deleteSessionConfirm} )} onConfirm={this.delete} okText={this.props.intl.formatMessage( {...messages.yes} )} cancelText={this.props.intl.formatMessage( {...messages.no} )}>
						<Button className="editable-add-btn">
							<DeleteOutlined />
							<FormattedMessage {...messages.deleteSession} />
						</Button>
					</Popconfirm>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<Table
						rowKey={record => record.id}
						columns = {columns}
						dataSource = {this.props.dataSource}
						pagination={pagination}
						rowSelection={rowSelection}
						current = {this.state.current}
						loading={isLoading}
						size={"small"}
						bordered
					/>
				</Col>
			</Row>
		</div>
    );
	}
}

SessionForm.propTypes = {
	intl: PropTypes.object.isRequired,
	dataSource: PropTypes.array,
	userId: PropTypes.number,
	lang: PropTypes.string,
	isLoading: PropTypes.bool,
	count: PropTypes.number,
	getSessionsById: PropTypes.func,
	removeSessions: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		"dataSource": state.session.sessions,
		"userId": state.auth.user.id,
		"lang": state.int18.language || state.int18.defaultLanguage,
		"isLoading": state.account.isLoading,
		"count": state.session.count
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getSessionsById: (id, page, pageSize) => {
			dispatch({type:INIT_REQUEST_ACCOUNT});
			dispatch(GetSessionsById(id, page, pageSize));
		},
		removeSessions: (id, dates) => {
			dispatch({type:INIT_REQUEST_ACCOUNT});
			dispatch(RemoveSessions(id, dates));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SessionForm));