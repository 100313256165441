import React from 'react';
import PropTypes from 'prop-types';
 
class RabbitReconnectMessage extends React.Component {

	constructor(props) {
		super(props);
	}
	onclick = () => {
		this.props.initRabbit();
		this.props.hide();
	}
	render() {
		const {messages} = this.props;
		return (
			<div style={{display: 'inline'}}>
				<span>
					{messages["error.conectionError"]}
					<a onClick={this.onclick} style={{marginLeft: '2px'}}>
						{messages["error.tryAgain"]}
					</a>
				</span>
				<h6 style={{color: "red"}}>{messages["error.conectionError"]}</h6>
			</div>
		);
	}
}

RabbitReconnectMessage.propTypes = {
	initRabbit: PropTypes.func.isRequired,
	hide: PropTypes.func.isRequired,
	messages: PropTypes.object.isRequired
};

export default RabbitReconnectMessage;