import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import { useIntl } from 'react-intl';
import { getEmployeeMessages, getDepartmentMessages, getModalMessages } from '../../../constants/messages';
import Render from './Render';

let employeeMessages = getEmployeeMessages(),
	modalMessages = getModalMessages(),
	departmentMessages = getDepartmentMessages();

const messages = {
	...employeeMessages,
	...departmentMessages,
	...modalMessages
};

const ViewModal = ({showModal, employee, handleCancel}) => {
	const intl = useIntl();

	const getIntl = (str) => {
		return intl.formatMessage({...messages[str]});
	}
  return (
	<Modal
		open={showModal}
		title={getIntl("employeeView")}
		onCancel={handleCancel}
		destroyOnClose
		footer={<Button onClick={handleCancel}>{getIntl("close")}</Button>}
    >
		<Render employee={employee}/>
    </Modal>
  )
}

ViewModal.defaultProps = {
	employee: {}
};

ViewModal.propTypes = {
	showModal:  PropTypes.bool.isRequired,
	employee: PropTypes.object,
	handleCancel:  PropTypes.func.isRequired,
};

export default ViewModal
