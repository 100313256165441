import axios from 'axios';
import { GET_DEBIT_NOTES, ADD_DEBIT_NOTE, EDIT_DEBIT_NOTE, GET_DEBIT_NOTE, SET_SEARCH_DEBIT } from './actionTypes';
import { notify } from '../../utilities/util';
import { STATUSES } from './constants';

export const GetDebitNotes = () => (dispatch) => {
	let filter = {
		"order": "id DESC"
	};

	return axios.get(`/DebitNotes?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			dispatch({ type: GET_DEBIT_NOTES, debitNotes: response.data });
		});
};

export const GetDebitNote = (id) => (dispatch) => {
	let filter = {
		"include": [
			{
				"relation": "payments",
				"scope": {
					"order": "id DESC",
				}
			}
		]
	};
	return axios.get(`/DebitNotes/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`).then((response) => {
		dispatch({ type: GET_DEBIT_NOTE, debitNote: response.data });
	});
}

export const SearchDebitNotes = (searchObject) => (dispatch) => {
	return axios({
		url: `/DebitNotes/search`,
		method: 'GET',
		responseType: 'json',
		params: {
			searchObject: searchObject
		}
	})
		.then((response) => {
			dispatch({ type: GET_DEBIT_NOTES, debitNotes: response.data });
			dispatch({type:SET_SEARCH_DEBIT, search:searchObject.searchText})
		});
};

export const AddDebitNote = (debitNote) => (dispatch, getState) => {
	let state = getState();
	let accountId = state.auth.user.id;
	return axios.post(`/DebitNotes/addDebitNote`, { debitNote, accountId })
		.then(() => dispatch({ type: ADD_DEBIT_NOTE }));
};

export const UpdateDebitNote = (debitNoteId, debitNote) => async (dispatch) => {
	try {
		await axios.post(`/DebitNotes/${debitNoteId}/edit`, { debitNote });
		return dispatch({ type: EDIT_DEBIT_NOTE });
	} catch (error) {
		return error;
	}
};

export const GetClientsPendingDebitNotes = (clientId) => () => {
	let filter = {
		"include": [
			"client"
		],
		"order": "createdAt DESC",
		"where": {
			"and": [
				{ "clientId": clientId },
				{ "status": 0 }
			]
		}
	};

	return axios.get(`/DebitNotes?filter=${encodeURIComponent(JSON.stringify(filter))}`);
};

export const DownloadDebitNote = (id) => () => {
	return axios({
		url: `/DebitNotes/${id}/download`,
		method: 'GET',
		responseType: 'blob'
	})
		.then(response => {
			const arr = response.headers['cache-control'].split(',')
			const filename = arr[arr.length - 1];
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', filename);
			document.body.appendChild(link);
			link.click();
		});

};

export const PreviewDebitNote = (id) => {
	return axios({
		url: `/DebitNotes/${id}/preview`,
		method: 'GET',
		responseType: 'blob'
	})
		.then(response => response.data);
};

export const EmailDebitNote = (id, emails, replyTo, message) => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;

	return axios.post(`/DebitNotes/${id}/sendByEmail`, { id: id, emails: emails, replyTo: replyTo, message: message })
		.then(() => {
			notify(`${messages["EMAIL_SUCCESS"]}`);
		});
};

export const LoadClientUnpaidDebitNotes = (clientId) => {
	const filter = {
		order: "createdAt DESC",
		where: {
			clientId,
			status: { inq: [STATUSES.PENDING] }
		},
		fields: ['id', 'documentNumber', 'clientName', 'status', 'total', 'balance', 'createdAt'],
	};
	return axios.get(`/DebitNotes?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then(response => response.data);
};

export const LoadDebitNoteActivityLog = (debitNoteId) => {
	let filter = {
		fields: ["id"],
		include: [
			{
				relation: "activityLog",
				scope: {
					include: [
						{
							relation: "account",
							scope: {
								fields: ["id", "name"]
							}
						}
					]
				}
			}
		]
	};
	return axios.get(`/DebitNotes/${debitNoteId}?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then(response => response.data && response.data.activityLog ? response.data.activityLog : []);
};