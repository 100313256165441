import {
    SET_SEARCH_CLIENTS,
    SET_SEARCH_INVOICES,
    CLEAN_REDUCER,
    CLEAR_SESSION
} from './actionTypes'

const initState = {
    searchClients: "",
    searchInvoices: "",
}

const reducer = (state = initState, action) => {
	switch (action.type) {
		case SET_SEARCH_CLIENTS:
			return {
				...state,
                searchClients:action.searchClients
            };
		case SET_SEARCH_INVOICES:
			return{
				...state,
				searchInvoices:action.searchInvoices
			}
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;