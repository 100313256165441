import axios from 'axios';
import { GET_CREDIT_NOTES, GET_CREDIT_NOTE, ADD_CREDIT_NOTE, UPDATE_SEARCH, UPDATE_PAGE } from './actionTypes';
import { notify } from '../../utilities/util';

export const GetCreditNotes = () => (dispatch) => {
	let filter = {
		"order": "id DESC",
		"include": {
			"relation": "creditNoteInvoices",
			"scope": {
				"include": "invoice"
			}
		}
	};

	return axios.get(`/CreditNotes?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			dispatch({ type: GET_CREDIT_NOTES, creditNotes: response.data });
		});
};

export const GetCreditNote = (id) => async (dispatch) => {
	const filter = {
		"include": "creditNoteInvoices"
	}
	try {
		const response = await axios.get(`/CreditNotes/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
		dispatch({ type: GET_CREDIT_NOTE, creditNote: response.data });
		return response.data;
	} catch (error) {
		return error;
	}
}

export const GetActivityIdCreditNote = async (id) => {
	try {
		const ActivityId = await axios.get(`/CreditNoteActivityLogs/${id}`);
		return ActivityId.data;		
	} catch (error) {
		return error;
	}
};

export const SearchCreditNotes = (searchObject, page) => (dispatch) => {
	return axios({
		url: `/CreditNotes/search`,
		method: 'GET',
		responseType: 'json',
		params: {
			searchObject,
			page: page
		}
	})
		.then((response) => {
			const { data } = response;
			dispatch({ type: GET_CREDIT_NOTES, creditNotes: data });
			dispatch({ type: UPDATE_SEARCH, search: searchObject.searchText });
			dispatch({ type: UPDATE_PAGE, page: page});
		});
};

export const AddCreditNote = (creditNote, invoices) => (dispatch, getState) => {
	let state = getState();
	let accountId = state.auth.user.id;
	return axios.post(`/CreditNotes/addCreditNote`, { creditNote, invoices, accountId })
		.then(() => dispatch({ type: ADD_CREDIT_NOTE }));
};

export const UpdateCreditNote = async (creditNoteId ,creditNote, invoices ) => {
	try {
		const update = await axios.post(`/CreditNotes/${creditNoteId}/edit`, { creditNote, invoices });
		return update.data;
	}catch(error){
		return error;
	}
};

export const DownloadCreditNote = (id) => () => {
	return axios({
		url: `/CreditNotes/${id}/download`,
		method: 'GET',
		responseType: 'blob'
	})
		.then(response => {
			const arr = response.headers['cache-control'].split(',')
			const filename = arr[arr.length - 1];
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', filename);
			document.body.appendChild(link);
			link.click();
		});

};

export const PreviewCreditNote = (id) => {
	return axios({
		url: `/CreditNotes/${id}/preview`,
		method: 'GET',
		responseType: 'blob'
	})
		.then(response => response.data);
};

export const EmailCreditNote = (id, emails, replyTo, message) => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;

	return axios.post(`/CreditNotes/${id}/sendByEmail`, { id: id, emails: emails, replyTo: replyTo, message: message })
		.then(() => {
			notify(`${messages["EMAIL_SUCCESS"]}`);
		});
};

export const LoadCreditNoteActivityLog = (creditNoteId) => {
	let filter = {
		fields: ["id"],
		include: [
			{
				relation: "activityLog",
				scope: {
					include: [
						{
							relation: "account",
							scope: {
								fields: ["id", "name"]
							}
						}
					]
				}
			}
		]
	};
	return axios.get(`/CreditNotes/${creditNoteId}?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then(response => response.data && response.data.activityLog ? response.data.activityLog : []);
};
