
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getProductMessages, getQuotationMessages, getModalMessages, getactionMessages, getErrorMessages, getPaymentMessages, getClientMessages, getInvoicingMessages } from '../../constants/messages';
import { Spin, Badge, message, Button } from 'antd';
import { GetProductsOfQuotation } from '../Products/actions';
import { GetEmails } from '../Clients/actions';
import { GetQuotation, PrintQuotation, EmailQuotation, PreviewQuotation } from './actions';
import { injectIntl, FormattedMessage } from 'react-intl';
import EmailSelector from '../../components/EmailSelector';
import ShowInvoicingDocument from '../../components/ShowInvoicingDocument';
import { arrayElementContainedInArray } from '../../utilities/util';
import { STATUSES } from './constants';
import { ROLES } from '../../constants/global';

let invoicingMessages = getInvoicingMessages(),
	quotationMessages = getQuotationMessages(),
	errorMessages = getErrorMessages(),
	actionMessages = getactionMessages(),
	ProductMessages = getProductMessages(),
	modalMessages = getModalMessages(),
	paymentMessages = getPaymentMessages(),
	clientMessages = getClientMessages();

const messages = {
	...invoicingMessages,
	...quotationMessages,
	...modalMessages,
	...actionMessages,
	...ProductMessages,
	...errorMessages,
	...paymentMessages,
	...clientMessages
};

class ShowQuotation extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			subtotal: 0,
			isLoading: true,
			showEmailModal: false,
		};
	}

	componentDidMount() {
		this.props.getQuotation(this.props.match.params.id).then(() => {
			this.props.getProductsOfQuotation(this.props.match.params.id).then(() => {
				this.setState({ isLoading: false });
			}).catch(() => {
				message.error(`${this.props.intl.formatMessage({ ...messages.GetProductsOfQuotationError })}`);
				this.setState({ isLoading: false });
			});
			this.props.getEmails(this.props.quotation.clientId).catch(() => {
				message.error(`${this.props.intl.formatMessage({ ...messages.getClientEmailsError })}`);
				this.setState({ isLoading: false });
			});
		}).catch(() => {
			message.error(`${this.props.intl.formatMessage({ ...messages.GetQuotationError })}`);
			this.setState({ isLoading: false });
		});
	}

	openEmailModal = () => {
		this.setState({
			showEmailModal: true
		});
	}

	closeEmailModal = () => {
		this.setState({
			showEmailModal: false
		});
	}

	cancelledReason = () => {
		if (this.props.quotation.status === STATUSES.DENIED) {
			return (
				<React.Fragment>
					<h3><FormattedMessage {...messages.Reason} /></h3>
					{this.props.quotation.reasonRejection}
				</React.Fragment>
			);
		}
	}

	renderStatus = (status) => {
		let color = 'processing';
		let text = this.props.intl.formatMessage({ ...messages.ReadyInvoicing });
		if (status === STATUSES.NEEDS_APPROVAL) {
			color = 'warning';
			text = this.props.intl.formatMessage({ ...messages.NeedsApproval });
		} else if (status === STATUSES.INVOICED) {
			color = 'success';
			text = this.props.intl.formatMessage({ ...messages.Invoiced });
		} else if (status === STATUSES.DENIED) {
			color = 'error';
			text = this.props.intl.formatMessage({ ...messages.Denied });
		} else if (status === STATUSES.CREATED_PROFORMA) {
			color = 'success';
			text = this.props.intl.formatMessage({ ...messages.CreatedProforma });
		} else if (status === STATUSES.EXPIRED) {
			color = 'error';
			text = this.props.intl.formatMessage({ ...messages.Expired });
		}
		return (
			<h1><FormattedMessage {...messages.QuotationNum} /> {this.props.quotation.id} | <Badge status={color} text={text} /> </h1>
		);
	}

	renderExtraButtons = () => {
		const { props } = this;
		const { quotation, userRoles } = props;
		const { id: quoteId, status = -1 } = quotation;
		const editableStatus = [STATUSES.READY_FOR_INVOICING, STATUSES.NEEDS_APPROVAL, STATUSES.DENIED];
		const allowedRoles = [ROLES.MASTER, ROLES.INVOICEEDITOR, ROLES.SALES];
		const showEditButton = arrayElementContainedInArray(allowedRoles, userRoles) && editableStatus.includes(status)
		return showEditButton &&
			<Button key="edit" size="large" onClick={() => this.props.history.push(`/quotations/${quoteId}/edit`)} className="button-spacing">
				{this.props.intl.formatMessage({ ...messages.edit })}
			</Button>
	}

	render() {
		return (
			<Spin spinning={this.state.isLoading}>
				<div style={{ padding: '25px' }}>
					<EmailSelector
						addOrShow={false}
						showEmailModal={this.state.showEmailModal}
						closeModal={this.closeEmailModal}
						document={this.props.quotation}
						emailDocument={this.props.emailQuotation}
						downloadPreview={() => PreviewQuotation(this.props.match.params.id)}
						downloadDocument={() => this.props.printQuotation(this.props.match.params.id)}
					/>
					<ShowInvoicingDocument
						document={this.props.quotation}
						products={this.props.productsOfCurrentQuotation}
						deniedStatuses={[1, 3, 5]}
						renderStatus={this.renderStatus}
						cancelledReason={this.cancelledReason}
						openEmailModal={this.openEmailModal}
						extraButtons={this.renderExtraButtons()}
						printDocument={this.props.printQuotation}
						goTo={() => this.props.history.push(`/quotations`)}
						messages={messages}
					/>
				</div>
			</Spin>
		);
	}
}

ShowQuotation.propTypes = {
	intl: PropTypes.object.isRequired,
	productsOfCurrentQuotation: PropTypes.array.isRequired,
	user: PropTypes.object.isRequired,
	quotation: PropTypes.object.isRequired,
	match: PropTypes.shape({
		params: PropTypes.object.isRequired
	}).isRequired,
	getProductsOfQuotation: PropTypes.func.isRequired,
	getQuotation: PropTypes.func.isRequired,
	printQuotation: PropTypes.func.isRequired,
	getEmails: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	emailQuotation: PropTypes.func.isRequired,
	userRoles: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		quotation: state.quotation.quotation,
		productsOfCurrentQuotation: state.product.productsOfCurrentQuotation,
		userRoles: state.auth.roles,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getProductsOfQuotation: (quotationId) => {
			return dispatch(GetProductsOfQuotation(quotationId));
		},
		getQuotation: (id) => {
			return dispatch(GetQuotation(id));
		},
		printQuotation: (id) => {
			return dispatch(PrintQuotation(id));
		},
		emailQuotation: (id, emails, replyTo, message) => {
			return dispatch(EmailQuotation(id, emails, replyTo, message));
		},
		getEmails: (id) => {
			return dispatch(GetEmails(id));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(ShowQuotation)));