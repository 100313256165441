import axios from 'axios';
import { FETCH_LOTUNIT, ADD_LOTUNIT_SUCCESS, SET_SEARCH_LOTS} from '../LotUnits/actionTypes';
import { GetAllLotsUnits } from '../LotUnits/actions';
import moment from 'moment';

export const GetLots = async () => {
	const response = await axios.get(`/Lots`);
	return response.data;
};

export const DeleteLot = (ids, lotNumber) => async (dispatch) => {
	const response = await axios.post(`Lots/removeLot`, {ids, lotNumber});
	dispatch({ type: FETCH_LOTUNIT, lotUnits: response.data });
	return response.data;
};

export const AddLot = (values, nomenclatureLot) => async (dispatch) => {
	const response = await axios.post(`Lots/AddLot`, { values, nomenclatureLot });
	dispatch({ type: ADD_LOTUNIT_SUCCESS, lotUnits: response.data });
	const lots = await GetLots();
	return lots;
};

export const setSearch = (value) => (dispatch) => {
	dispatch({type: SET_SEARCH_LOTS, searchLots:value})
}

export const DownloadNotAdded = (units) => () => {
	let params = { units: units };
	return exportExcel('ExportNotAddedLotUnits', params, 'NotAddedLotUnits');
};

export const DownloadLotUnitsTemplate = () => () => {
	let params = {};
	return exportExcel('ExportLotUnitsTemplate', params, 'LotUnitsTemplate');
};

const exportExcel = (url, params, name) => {
	return axios({
		url: '/Lots/' + url,
		method: 'POST',
		responseType: 'blob',
		data: params,
	}).then((response) => {
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `${name}-${moment().format('DD/MM/YYYY hh:mm A')}.xlsx`);
		document.body.appendChild(link);
		link.click();
	});
};

export const HandleUploadFile = (values, updateLots) => async (dispatch) => {
	const formData = new FormData();
	formData.append('file', values.file);
	formData.append('model', values.model);
	formData.append('employee', values.employee);
	formData.append('lotNumber', values.lotNumber);
	formData.append('hasHeaders', values.hasHeaders);
	
	const response = await axios.post(`/Lots/UploadFileLot`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
	if(response) {
		let uploadResponse = response.data;
		await dispatch(GetAllLotsUnits());
		const lots = await GetLots();
		updateLots(lots);
		return uploadResponse;
	}
};