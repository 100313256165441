import { connect } from 'react-redux';
import ViewPayrollPage from './ViewPayroll';
import { GetPayrollInfo, DownloadVouchers, DownloadExcel } from '../actions';

const mapStateToProps = (state) => {
	return {
		payrollInfo: state.payroll.payrollInfo
	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		getPayrollInfo: ( id ) => dispatch(GetPayrollInfo( id )),
		downloadVouchers: ( id ) => dispatch(DownloadVouchers( id )),
		downloadExcel: ( id ) => dispatch(DownloadExcel( id ))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPayrollPage);