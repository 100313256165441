import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ConfirmEmail from './components/ConfirmEmail';
import HomePage from './components/HomePage';
import ResetPasswordPage from './components/ResetPasswordPage';
import { ROLES } from './constants/global';
import AccountPage from './containers/AccountPage';
import LoginPage from './containers/LoginPage';
import PreRegistrationPage from './features/Clients/PreRegistration/page';
import ApprovedClientsPage from './features/Clients/ApprovedClients/page';
import Commissions from './features/Commissions/page';
import CommissionGenerator from './features/Commissions/Generator/page';
import Employees from './features/Employees/Employees';
import EmployeeAddEdit from './features/Employees/AddOrUpdate/EmployeeAddEdit';
import AddInvoiceForm from './features/Invoices/AddInvoiceForm';
import Invoicing from './features/Invoices/Invoices';
import ShowInvoice from './features/Invoices/ShowInvoice';
import JobCalendarPage from './features/Jobs/Calendar/CalendarPage';
import JobPage from './features/Jobs/JobPage';
import Payrolls from './features/Payroll/page';
import Deductions from './features/Payroll/Deductions/page';
import GeneratePayroll from './features/Payroll/GeneratePayroll/page';
import ViewPayroll from './features/Payroll/View/page';
import Proformas from './features/Proformas/index';
import AddProformaForm from './features/Proformas/AddProformaForm';
import ShowProforma from './features/Proformas/ShowProforma';
import Quotations from './features/Quotations/index';
import AddQuotationForm from './features/Quotations/AddQuotationForm';
import ShowQuotation from './features/Quotations/ShowQuotation';
import Requests from './features/Requests/Requests';
import { ACL } from './utilities/ACL';
import CreditNotes from './features/CreditNotes/index';
import Receipts from './features/Receipts/index';
import DebitNotes from './features/DebitNotes/index';
import Products from './features/Products/index';
import ExchangeRate from './features/ExchangeRate/ExchangeRate';
import GeneralReport from './features/Reports/GeneralReport';
import TaxReport from './features/Reports/TaxReport/TaxReport';
import IncomeReport from './features/IncomeReport/Entries/IncomeReport';
import Category from './features/IncomeReport/Categories/Category';
import Banks from './features/IncomeReport/Banks/Banks';
import AccountEntriesReport from './features/Reports/AccountEntriesReport/AccountEntriesReport';
import EquipmentReport from './features/Reports/EquipmentReport/EquipmentReport';
import Departments from './features/Departments/page';
import TechReport from './features/Reports/TechnicianReport/page';
import PaymentsReport from './features/Reports/PaymentsReport/page';
import CustomPriceReport from './features/Reports/CustomPriceReport/CustomPriceReportPage'
import JobsChargeReport from './features/Reports/JobsChargeReport/index';
import OverdueInvoicesReport from './features/Reports/OverdueInvoicesReport';
import Units from './features/Units/index';
import Models from './features/Models/index';
import LotUnits from './features/LotUnits/LotUnitsPage';
import GuatemalaClients from './features/OptimusGuatemala/Clients/index';
import GuatemalaInvoices from './features/OptimusGuatemala/Invoices/index'
// Layouts
import MainLayout from './layouts/main';
import EmptyLayout from './layouts/empty';

export function Routes () {
	let isAuthenticated = useSelector(state => state.auth.isAuthenticated);
	let currentRoute = isAuthenticated ? mainRoute() : emptyRoute();
	return (
		currentRoute
	);
}

export const emptyRoute = () => {
	return (
	<EmptyLayout>
		<Switch>
			<Route path="/confirm" component={ConfirmEmail} />
			<Route path="/login" component={LoginPage} />
			<Route path="/reset-password" component={ResetPasswordPage} />
			<Redirect exact from='*' to="/login" />
		</Switch>
	</EmptyLayout>
	);
}

const SubRoutes = ({List, Create, GetById, EditById, GenByDate, GetDeduc, GetViewId})=>{
	let { path } = useRouteMatch();
	return (
		<Switch>
			{ List && <Route exact path={path} component={List} />}
			{ Create && <Route exact path={`${path}/new`} component={Create} />}
			{ EditById && <Route exact  path={`${path}/:id/edit`} component={EditById} />}
			{ GetById && <Route exact path={`${path}/:id`} component={GetById} />}
			{ GenByDate && <Route exact path={`${path}/generatePayroll/:date`} component={GenByDate} />}
			{ GetDeduc && <Route exact path={`${path}/deductions`} component={GetDeduc} />}
			{ GetViewId && <Route exact path={`${path}/view/:id`} component={GetViewId} />}
			<Redirect to="/home" />
		</Switch>
	)
}

SubRoutes.propTypes = {
	List: PropTypes.any,
	Create: PropTypes.any,
	GetById: PropTypes.any,
	EditById: PropTypes.any,
	GenByDate: PropTypes.any,
	GetDeduc: PropTypes.any,
	GetViewId: PropTypes.any
}

export const mainRoute = () => {
	return (
		<MainLayout>
			<Switch>
				<Route exact path="/" component={HomePage}/>
				<Route path="/accounts" component={ACL(AccountPage, [ROLES.MASTER])} />
				<Route path="/preRegistration" component={ACL(PreRegistrationPage, [ROLES.MASTER, ROLES.ADMINISTRATOR, ROLES.SALES])} />
				<Route path="/approved" component={ACL(ApprovedClientsPage, [ROLES.MASTER, ROLES.ADMINISTRATOR, ROLES.SALES, ROLES.CUSTOMERSERVICE])} />
				<Route path="/job-table" component={ACL(JobPage, [ROLES.MASTER, ROLES.CUSTOMERSERVICE, ROLES.TECHSUPPORT, ROLES.ADMINISTRATOR])} />
				<Route path="/job-calendar" component={ACL(JobCalendarPage, [ROLES.MASTER, ROLES.CUSTOMERSERVICE])} />
				<Route path="/requests" component={ACL(Requests, [ROLES.MASTER, ROLES.CUSTOMERSERVICE, ROLES.SALES])} />
			
				<Route path="/invoices" component={()=>
					<SubRoutes 
						List={ACL(Invoicing, [ROLES.MASTER, ROLES.ADMINISTRATOR, ROLES.SALES])}
						Create={ACL(AddInvoiceForm, [ROLES.MASTER, ROLES.ADMINISTRATOR])}
						EditById={ACL(AddInvoiceForm, [ROLES.MASTER, ROLES.ADMINISTRATOR])}
						GetById={ACL(ShowInvoice, [ROLES.MASTER, ROLES.ADMINISTRATOR, ROLES.SALES])}
					/>
				}/>

				<Route path="/proformas" component={()=>
					<SubRoutes 
						List={ACL(Proformas, [ROLES.MASTER, ROLES.ADMINISTRATOR])}
						Create={ACL(AddProformaForm, [ROLES.MASTER, ROLES.ADMINISTRATOR])}
						EditById={ACL(AddProformaForm, [ROLES.MASTER, ROLES.ADMINISTRATOR])}
						GetById={ACL(ShowProforma, [ROLES.MASTER, ROLES.ADMINISTRATOR])}
					/>
				}/>

				<Route path="/quotations" component={()=>
					<SubRoutes 
						List={ACL(Quotations, [ROLES.MASTER, ROLES.ADMINISTRATOR, ROLES.SALES])}
						Create={ACL(AddQuotationForm, [ROLES.MASTER, ROLES.ADMINISTRATOR, ROLES.SALES])}
						EditById={ACL(AddQuotationForm, [ROLES.MASTER, ROLES.ADMINISTRATOR, ROLES.SALES])}
						GetById={ACL(ShowQuotation, [ROLES.MASTER, ROLES.ADMINISTRATOR, ROLES.SALES])}
					/>
				}/>
				<Route path="/models" component={ACL(Models, [ROLES.MASTER, ROLES.INVENTORYASSISTANT])} />
				<Route path="/units" component={ACL(Units, [ROLES.MASTER, ROLES.INVENTORYASSISTANT])} />
				<Route path="/lots" component={ACL(LotUnits, [ROLES.MASTER, ROLES.INVENTORYASSISTANT])} />
				<Route path="/guatemalaClients" component={ACL(GuatemalaClients, [ROLES.MASTER, ROLES.ADMINISTRATOR])} />
				<Route path="/guatemalaInvoices" component={ACL(GuatemalaInvoices, [ROLES.MASTER, ROLES.ADMINISTRATOR])} />
				<Route path="/creditNotes" component={ACL(CreditNotes, [ROLES.MASTER, ROLES.ADMINISTRATOR])} />
				<Route path="/receipts" component={ACL(Receipts, [ROLES.MASTER, ROLES.ADMINISTRATOR])} />
				<Route path="/debitNotes" component={ACL(DebitNotes, [ROLES.MASTER, ROLES.ADMINISTRATOR])} />
				<Route path="/products" component={ACL(Products, [ROLES.MASTER, ROLES.ADMINISTRATOR])} />
				<Route path="/exchangeRate" component={ACL(ExchangeRate, [ROLES.MASTER, ROLES.ADMINISTRATOR])} />
				<Route path="/generalReport" component={ACL(GeneralReport, [ROLES.MASTER, ROLES.ADMINISTRATOR])} />
				<Route path="/taxesReport" component={ACL(TaxReport, [ROLES.MASTER, ROLES.ADMINISTRATOR])} />
				<Route path="/incomeExpensesReport" component={ACL(AccountEntriesReport, [ROLES.MASTER])} />
				<Route path="/equipmentReport" component={ACL(EquipmentReport, [ROLES.MASTER])} />
				<Route path="/techReport" component={ACL(TechReport, [ROLES.MASTER])} />
				<Route path="/paymentsReport" component={ACL(PaymentsReport, [ROLES.MASTER, ROLES.ADMINISTRATOR])} />
				<Route path="/customPriceReport" component={ACL(CustomPriceReport, [ROLES.MASTER, ROLES.ADMINISTRATOR])} />
				<Route path="/jobsToCharge" component={ACL(JobsChargeReport, [ROLES.MASTER, ROLES.ADMINISTRATOR])} />
				<Route path="/overdueInvoicesReport" component={ACL(OverdueInvoicesReport, [ROLES.MASTER, ROLES.ADMINISTRATOR])} />
				<Route path="/IncomeReport" component={ACL(IncomeReport, [ROLES.MASTER, ROLES.INCOMESEXPENSES])} />
				<Route path="/Categories" component={ACL(Category, [ROLES.MASTER, ROLES.INCOMESEXPENSES])} />
				<Route path="/Banks" component={ACL(Banks, [ROLES.MASTER, ROLES.INCOMESEXPENSES])} />
				<Route path="/departments" component={ACL(Departments, [ROLES.MASTER, ROLES.EMPLOYEES])} />

				<Route path="/employees" component={()=>
					<SubRoutes 
						List={ACL(Employees, [ROLES.MASTER, ROLES.EMPLOYEES])}
						Create={ACL(EmployeeAddEdit, [ROLES.MASTER, ROLES.EMPLOYEES])}
						EditById={ACL(EmployeeAddEdit, [ROLES.MASTER, ROLES.EMPLOYEES])}
					/>
				}/>

				<Route path="/payrolls" component={()=>
					<SubRoutes 
						List={ACL(Payrolls, [ROLES.MASTER, ROLES.PAYROLLS])}
						GenByDate={ACL(GeneratePayroll, [ROLES.MASTER, ROLES.PAYROLLS])}
						GetViewId={ACL(ViewPayroll, [ROLES.MASTER, ROLES.PAYROLLS])}
						GetDeduc={ACL(Deductions, [ROLES.MASTER, ROLES.PAYROLLS])}
					/>
				}/>

				<Route path="/commissions" component={()=>
					<SubRoutes 
						List={ACL(Commissions, [ROLES.MASTER, ROLES.SALES])}
						Create={ACL(CommissionGenerator, [ROLES.MASTER])}
						EditById={ACL(CommissionGenerator, [ROLES.MASTER])}
					/>
				}/>
								
				<Redirect exact from="*" to="/"  />
			</Switch>
		</MainLayout>
	);
}