import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getModalMessages, getErrorMessages, getProductMessages } from '../../constants/messages';
import { Button, Modal, Input, Row, Col, InputNumber, Checkbox, message, Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import {AddProduct, GetProducts} from './actions';

const FormItem = Form.Item;

let errorMessages = getErrorMessages(),
	modalMessages = getModalMessages(),
	productMessages = getProductMessages();

const messages = {
	...modalMessages,
	...errorMessages,
	...productMessages
};

const AddProductForm = ({showModal, handleCancel}) => {
	const intl = useIntl();
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	
	const handleOk = async () => {
		try {
			setIsLoading(true);
			const values = await form.validateFields();
			await dispatch(AddProduct(values));
			await dispatch(GetProducts());
			handleCancel();
		} catch (error) {
			message.error(intl.formatMessage( {...messages.errorGetProducts}));
		} finally {
			setIsLoading(false);
		}
	}

	const buttonGroup = [
		<Button key="submit" type="primary" size="large" loading={isLoading} onClick={handleOk} className="button-spacing">
			<FormattedMessage {...messages.save} />
		</Button>,
		<Button key="back" size="large" onClick={handleCancel} className="button-spacing">
			<FormattedMessage {...messages.cancel} />
		</Button>
	]

	return ( 
		<Modal
			open={showModal}
			title= {intl.formatMessage( {...messages.Product} )} 
			onOk={handleOk}
			onCancel={handleCancel}
			destroyOnClose={true}
			footer={buttonGroup}
		>
			<Form 
				form={form}
				preserve={false}
				layout='vertical'
				className="login-form col-8"
			>
				<FormItem
					name="code"
					label={intl.formatMessage({ ...messages.Code })}
					rules={
						[{
							required: true,
							whitespace: true, 
							message: intl.formatMessage({ ...messages.codeError }),
						}]
					}
				>
					<Input/>
				</FormItem>
				<FormItem
					name="description"
					label={intl.formatMessage({ ...messages.Description })}
					rules={
						[{
							required: true,
							whitespace: true,
							message: intl.formatMessage({ ...messages.descriptionError }),
						}]
					}
				>
					<Input/>
				</FormItem>
				<Row gutter={16} type="flex" align="middle">
					<Col span={6}>
						<FormItem
							name="price"
							label={intl.formatMessage({ ...messages.Price })}
							rules={
								[{
									required: true, 
									message: intl.formatMessage({ ...messages.priceError }),
								}]
							}
						>
							<InputNumber 
								formatter={value => `$ ${value}`} 
								parser={value => value.substring(2)} 
								min={0} 
								step="1.00" 
							/>
						</FormItem>
					</Col>
					<Col span={6}>
						<FormItem
							name="minPrice"
							label={intl.formatMessage({ ...messages.MinPrice })}
							rules={
								[{
									required: true, 
									message: intl.formatMessage({ ...messages.priceError }),
								}]
							}
						>
							<InputNumber 
								formatter={value => `$ ${value}`} 
								parser={value => value.substring(2)} 
								min={0} 
								step="1.00"
							/>
						</FormItem>
					</Col>
					<Col span={6}>
						<FormItem 
							name="maxQuantity"
							label={intl.formatMessage({ ...messages.MaxQuantity })}
							rules={
								[{
									required: true, 
									message: intl.formatMessage({ ...messages.quantityError }),
								}]
							}
						>
							<InputNumber min={0} />
						</FormItem>
					</Col>
					<Col span={6}>
						<FormItem
							name="isAddon"
							valuePropName="checked"
							initialValue={false}
							rules={
								[{
									required: true,
									message: intl.formatMessage({ ...messages.maxrangeError }),
								}]
							}	
						>
							<Checkbox>
								<FormattedMessage {...messages.IsAddon} />
							</Checkbox>
						</FormItem>
					</Col>
				</Row>	
			</Form>	
		</Modal>
	);
};

AddProductForm.propTypes = {
	showModal: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired,
};

export default AddProductForm;
