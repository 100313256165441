import { ROLES } from "../constants/global";
import {
  getSideMenu,
  getCommissionMessages,
  getEditRoleMessages,
} from "../constants/messages";

let getSideMenuMessages = getSideMenu(),
  getCommissionMessages1 = getCommissionMessages(),
  editRoleMessages = getEditRoleMessages();

const messages = {
  ...getSideMenuMessages,
  ...getCommissionMessages1,
  ...editRoleMessages,
};
const RoleAccessItems = [
  {
    key: ROLES.MASTER,
    content: [messages.clients],
  },
  {
    key: ROLES.ADMINISTRATOR,
    content: [
      messages.clients,
      messages.orderJobTable,
      messages.invoicing,
      messages.products,
      messages.exchangeRate,
      messages.generalReport,
      messages.taxReport,
      messages.paymentsReport,
      messages.jobsToCharge,
      messages.customPriceReport,
      messages.clientNameOptimusGuatemala,
    ],
  },
  {
    key: ROLES.SALES,
    content: [
      messages.clients,
      messages.orderJobsRequests,
      messages.invoices,
      messages.quotations,
      messages.commissions,
    ],
  },
  {
    key: ROLES.TECHSUPPORT,
    content: [messages.orderJobTable],
  },
  {
    key: ROLES.CUSTOMERSERVICE,
    content: [messages.approvedClients, messages.job],
  },
  {
    key: ROLES.INCOMESEXPENSES,
    content: [messages.incomeExpenses],
  },
  {
    key: ROLES.GPSTRANSFERACTIVATION,
    content: [messages.activateJobs, messages.dataclients],
  },
  {
    key: ROLES.EMPLOYEES,
    content: [messages.departments, messages.employees],
  },
  {
    key: ROLES.PAYROLLS,
    content: [messages.payrolls],
  },
  {
    key: ROLES.INVOICEEDITOR,
    content: [messages.editInvoice, messages.editProform, messages.editQuote],
  },
  {
    key: ROLES.INVENTORYASSISTANT,
    content: [messages.inventory],
  },
];

export default RoleAccessItems;
