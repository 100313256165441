import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Tooltip, Switch } from 'antd';
import { getLanguageIntl, getCalendarMessages, getactionMessages, getJobMessages } from '../../../constants/messages';
import { getJobTypeColor, standardSwitchProps } from  './Calendar';
import TypeFilter from './TypeFilter';
import StatusFilter from './StatusFilter';

let intlMessages = getLanguageIntl(),
    calendarMessages = getCalendarMessages(),
    actionMessages = getactionMessages(),
    JobsMessages = getJobMessages();

const messages = {
    ...intlMessages,
    ...calendarMessages,
    ...actionMessages,
	...JobsMessages
};

class Filter extends React.Component {
	constructor(props){
        super(props);
        this.state = {
            showTheseTypes: [],
            showTheseStatus: []
        };
    }

    componentDidMount(){
        this.saveProps();
    }

    saveProps = () => {
        const {showTheseTypes, showTheseStatus} = this.props;
        this.setState({
            showTheseTypes: showTheseTypes,
            showTheseStatus: showTheseStatus
        });
    }

    onChange = (checked, type) => {
        const {setState} = this.props;
        const {showTheseTypes, showTheseStatus} = this.state;
        switch (type) {
            case -2:
                setState({
                    showTheseTypes: showTheseTypes,
                    showTheseStatus: showTheseStatus,
                    showUndefined: true,
                    showAll: checked
                });
                break;
            case -1:
                setState({
                    showUndefined: checked,
                    showAll: false
                });
                break;
            default:
                break;
        }
    }

	render(){
        const {intl, setState, showTheseTypes, showTheseStatus, showAll, showUndefined} = this.props;
		return (
            <span>
                <Tooltip title={intl.formatMessage({...messages.showAllEvents})}  mouseEnterDelay={0}>
                    <Switch {...standardSwitchProps} disabled={showAll} checked={showAll} onChange={(checked) => this.onChange(checked, -2)}/>
                </Tooltip>
                <span> | </span>
                <TypeFilter filterEvents={(newTypes) => setState({showTheseTypes: newTypes, showAll: false})} showTheseTypes={showTheseTypes}/>
                <span> | </span>
                <StatusFilter filterEvents={(newStatus) => setState({showTheseStatus: newStatus, showAll: false})} showTheseStatus={showTheseStatus}/>
                <span> | </span>
                <Tooltip title={intl.formatMessage({...messages.undefinedEvents})}  mouseEnterDelay={0}>
                    <Switch {...standardSwitchProps} style={getJobTypeColor(-1)} checked={showUndefined} onChange={(checked) => this.onChange(checked, -1)}/>
                </Tooltip>
            </span>
		);
	}
}

Filter.propTypes = {
	intl: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    showTheseTypes: PropTypes.array.isRequired,
    showTheseStatus: PropTypes.array.isRequired,
    showAll: PropTypes.bool.isRequired,
    showUndefined: PropTypes.bool.isRequired,
};

export default injectIntl(Filter);