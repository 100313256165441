import { connect } from 'react-redux';
import ApprovedClients from './ApprovedClients';
import {
	GetApprovedClients,
	SearchApprovedClients,
	GetClientInfo,
} from '../actions';

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		clients: state.client.clients,
		recordCount: state.client.count,
		showEditModal: state.client.showEditModal,
		isMaster: state.auth.isMaster,
		userRoles: state.auth.roles,
		search: state.client.searchApproved,

	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		getClients: ( page, pageSize ) => {
			return dispatch(GetApprovedClients( page, pageSize ));
		},
		searchClients: (search, page, pageSize, order, orderBy) => {
			return dispatch(SearchApprovedClients(search, page, pageSize, order, orderBy));
		},
		getClientInfo: (id) =>  dispatch(GetClientInfo(id)),
	};
};

export default  connect(mapStateToProps, mapDispatchToProps)(ApprovedClients);