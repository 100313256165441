import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { StopOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, Spin, message } from 'antd';
import { injectIntl } from 'react-intl';
import { getJobMessages, getErrorMessages, getPopConfirm, getFields, getClientMessages } from '../../../../../constants/messages';
import { AdvancedSearchForClient, AdvancedSearchForClientSeller } from '../../../actions';
import { getObjectInArray } from '../../../../../utilities/util';
import debounce from 'lodash/debounce';
import { ROLES } from '../../../../../constants/global';

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

let jobMessages = getJobMessages(),
    errorMessages = getErrorMessages(),
	fieldMessages = getFields(),
    PopConfirmMessages = getPopConfirm(),
    clientMessages = getClientMessages();

const messages = {
    ...jobMessages,
    ...errorMessages,
    ...PopConfirmMessages,
    ...fieldMessages,
    ...clientMessages
};

class Client extends React.Component {

    constructor(props) {
		super(props);
		this.handleClientSearch = debounce(this.handleClientSearch, 250);
		this.state = {
            isLoading: false,
            clients: [],
            selectedClient: {}
        };
    }

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    setLoading = (isLoading) => this.setState({ isLoading });

    handleClientSearch = (name) => {
        const { getIntl, setLoading, props: { sellerClientIds, roles } } = this;
        if (name.length <= 0 ) this.setState({ clients: [] });
        else {
            setLoading(true);
            const isSalesPerson = roles.includes(ROLES.SALES) && !(roles.includes(ROLES.ADMINISTRATOR) || roles.includes(ROLES.MASTER) || roles.includes(ROLES.CUSTOMERSERVICE));
            const action = isSalesPerson ?  AdvancedSearchForClientSeller(name, sellerClientIds) : AdvancedSearchForClient(name);
            action.then(clients => this.setState({ isLoading: false, clients }))
            .catch( () => {
                this.setState({ isLoading: false, clients: [] });
                message.error(getIntl("unableToSearchClients"));
            });
        }
    }

    onChange = (clientId) => {
        const { state, props } = this;
        const { clients } = state;
        const { onChange } = props;
        const selectedClient = getObjectInArray('id', parseInt(clientId), clients);
        this.setState({ selectedClient, clients: [] });
        if ('onChange' in props) onChange(selectedClient);
    }

    buildOptions = () => {
        const { state, getIntl } = this;
        const { selectedClient, clients } = state;
        let options = [];
        let enabled = [];
        let disabled = [];
        if (selectedClient && selectedClient.id !== undefined) options.push(<Option key={clients.length} value={selectedClient.id}>{selectedClient.name}</Option>);
        clients.forEach((client, index) => {
            if (client.isDisabled) disabled.push(<Option key={index} value={client.id}><StopOutlined style={{fontSize: 10}} /> {client.name}</Option>);
            else enabled.push(<Option key={index} value={client.id}>{client.name}</Option>);
        });
        options.push(<OptGroup key="enabled" label={getIntl("enabled")}>{enabled}</OptGroup>);
        options.push(<OptGroup key="disabled" label={getIntl("disabled")}>{disabled}</OptGroup>);
        return options;
    }
    
	render() {
        const { state, props, getIntl, onChange, handleClientSearch, buildOptions } = this;
        const { isLoading } = state;
        const { form, allowClear } = props;
        const { getFieldDecorator } = form;
        let clientOptions = buildOptions();
        return(
            <FormItem label={getIntl("client")} >
            {
                getFieldDecorator("clientId", 
                    {
                        onChange: onChange
                    }
                )(
                    <Select
                        className="job-full-component"
                        showSearch
                        allowClear={allowClear}
                        placeholder={getIntl("client")}
                        optionFilterProp="children"
                        onSearch={handleClientSearch}
                        notFoundContent={isLoading ? <Spin size="small" /> : getIntl("notFound")}
                        dropdownMatchSelectWidth={false}
                    >
                        {clientOptions}
                    </Select>
                )
            }
            </FormItem>
        );
    }
}

Client.defaultProps = {
    allowClear: true
};

Client.propTypes = {
	intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
	roles: PropTypes.array.isRequired,
    allowClear: PropTypes.bool,
    onChange: PropTypes.func,
	sellerClientIds: PropTypes.array,
};

const mapStateToProps = (state) => {
	return {
		sellerClientIds: state.client.sellerClients,
		roles: state.auth.roles,
	};
};


export default connect(mapStateToProps)(injectIntl(Client));