
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Table, Row, Col, message } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import { roundN, getNestedValue } from '../utilities/util';
import moment from 'moment';
import InvoiceSummary from './InvoiceSummary';
import ActivityLog from '../features/Invoices/ActivityLog';
import { INVOICE_TYPES } from '../features/Invoices/constants';
import { getInvoicingMessages } from '../constants/messages';

let invoicingMessages = getInvoicingMessages();

const globalMessages = {
	...invoicingMessages
};

class ShowInvoicingDocument extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			subtotal: 0,
			isLoading: false,
			showEmailModal: false,
		};
	}

	getIntl = (str, values = {}) => {
		const { intl, messages } = this.props;
		const allMessages = { ...messages, ...globalMessages };
		return intl.formatMessage({ ...allMessages[str] }, values);
	}

	getCreditTime = () => {
		const { props, getIntl } = this;
		const { paymentDate, createdAt, type, daysForPayment } = props.document;
		const paymentMoment = moment(paymentDate);
		const createdAtMoment = moment(createdAt);
		if (type === INVOICE_TYPES.CASH) return <p style={{ 'display': 'inline' }}>{getIntl("typeCash")}</p>;
		if (type === INVOICE_TYPES.CREDIT) {
			const amount = daysForPayment !== undefined ? daysForPayment : paymentMoment.diff(createdAtMoment, 'days');
			return <p style={{ 'display': 'inline' }}>{getIntl("typeCredit", { amount })}</p>;
		}
	}


	renderExtraButtons = () => {
		if ('extraButtons' in this.props) {
			return (this.props.extraButtons)
		}
	}

	renderExtraInfo = () => {
		if ('extraInfo' in this.props) {
			return (this.props.extraInfo)
		}
	}

	print = () => {
		if (this.props.deniedStatuses.includes(this.props.document.status))
			message.warning(this.props.intl.formatMessage({ ...this.props.messages.CantPrint }));
		else {
			this.setState({
				isLoading: true
			});
			this.props.printDocument(this.props.document.id).then(() => {
				this.setState({
					isLoading: false
				});
			}).catch(() => {
				message.error(`${this.props.intl.formatMessage({ ...this.props.messages.PrintError })}`);
				this.setState({
					isLoading: false
				});
			});
		}
	}

	email = () => {
		if (this.props.deniedStatuses.includes(this.props.document.status))
			message.warning(this.props.intl.formatMessage({ ...this.props.messages.CantEmail }));
		else
			this.props.openEmailModal();
	}

	renderProductsTable = () => {
		let dataSource = this.props.products.map((object) => {
			return {
				...object,
				'key': this.props.products.indexOf(object)
			};
		});
		let columns = [
			{
				title: this.props.intl.formatMessage({ ...this.props.messages.Product }),
				dataIndex: 'product',
				key: 'productName',
				render: (product) => {
					return (
						product.code
					);
				}
			}, {
				title: this.props.intl.formatMessage({ ...this.props.messages.Description }),
				dataIndex: 'description',
				key: 'description',
				render: (description) => {
					return (
						description
					);
				}
			}, {
				title: this.props.intl.formatMessage({ ...this.props.messages.Quantity }),
				dataIndex: 'quantity',
				key: 'quantity'
			}, {
				title: this.props.intl.formatMessage({ ...this.props.messages.Price }),
				dataIndex: 'price',
				key: 'price',
				className: 'column-money',
				render: (price, product) => {
					const { priceChangeReason } = product;
					if (priceChangeReason)
						return (
							<div>
								<div>{`$ ${roundN(price, 2)}`}</div>
								<div>{priceChangeReason}</div>
							</div>
						);
					return `$ ${roundN(price, 2)}`;
				}
			}, {
				title: this.props.intl.formatMessage({ ...this.props.messages.Total }),
				dataIndex: 'total',
				key: 'total',
				className: 'column-money',
				render: (total, product) => {
					return (
						`$ ${roundN(product.quantity * product.price, 2)}`
					);
				}
			}
		];

		return (
			<Table dataSource={dataSource} columns={columns} pagination={false} size="small" />
		);
	}

	renderSummary = () => {
		const { props } = this;
		const taxExemption = getNestedValue("document.taxExemption", props) || false;
		const exchangeRate = getNestedValue("document.exchangeRate", props);
		const products = getNestedValue("products", props) || [];
		const isv = getNestedValue("document.isv", props);
		return <InvoiceSummary products={products} taxExemption={taxExemption} exchangeRate={exchangeRate} isv={isv} />;
	}

	renderActivityLog = () => {
		const activityLog = getNestedValue("document.activityLog", this.props);
		return <ActivityLog activityLog={activityLog} />;
	}

	render() {
		return (
			<React.Fragment>
				<Row gutter={16}>
					<Col span={16}>
						{this.props.renderStatus(this.props.document.status)}
					</Col>
					<Col span={8} style={{ 'textAlign': 'right' }}>
						<h1>{moment(this.props.document.createdAt).locale('es').format('l')}</h1>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={10}>
						<h3 style={{ 'display': 'inline' }}><FormattedMessage {...this.props.messages.Client} /> </h3>
						<p style={{ 'display': 'inline' }}>{this.props.document.client !== undefined ? this.props.document.clientName : " "}</p>
					</Col>
					<Col span={10}>
						<h3 style={{ 'display': 'inline' }}>RTN </h3>
						<p style={{ 'display': 'inline' }}>{this.props.document.client !== undefined ? this.props.document.clientRtn : " "}</p>
					</Col>
					<Col span={4}>
						<h3 style={{ 'display': 'inline' }}><FormattedMessage {...this.props.messages.Credit} /> </h3>
						{this.getCreditTime()}
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<h3 style={{ 'display': 'inline' }}><FormattedMessage {...this.props.messages.representative} /> </h3>
						<p style={{ 'display': 'inline' }}>{this.props.document.client !== undefined ? this.props.document.representative : " "}</p>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={18}>
						<h3 style={{ 'display': 'inline' }}><FormattedMessage {...this.props.messages.Address} /> </h3>
						<p style={{ 'display': 'inline' }}>{this.props.document.address}</p>
					</Col>
					<Col span={6}>
						<h3 style={{ 'display': 'inline' }}><FormattedMessage {...this.props.messages.PayDate} /> </h3>
						<p style={{ 'display': 'inline' }}>{this.props.document.daysForPayment == undefined ? moment(this.props.document.paymentDate).locale('es').format('l') : moment(this.props.document.createdAt).add(this.props.document.daysForPayment, 'days').locale('es').format('l')}</p>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<h3 style={{ 'display': 'inline' }}><FormattedMessage {...this.props.messages.City} /> </h3>
						<p style={{ 'display': 'inline' }}>{`${this.props.document.city}, ${this.props.document.state}`}</p>
					</Col>
				</Row>
				<div>
					<br />
					{this.renderProductsTable()}
					<br />
				</div>
				<Row gutter={16}>
					<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 18 }}>
						<h3><FormattedMessage {...this.props.messages.Observations} /></h3>
						<p>{this.props.document.observations}</p>
						<br />
						{this.props.cancelledReason()}
					</Col>
					<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 14 }} lg={{ span: 6 }}>
						<div>
							{this.renderSummary()}
							<br />
						</div>
					</Col>
				</Row>
				{this.renderExtraInfo()}
				<br />
				{this.renderActivityLog()}
				<div style={{ float: 'right' }}>
					<Button type="primary" size="large" onClick={() => { this.print(); }} className="button-spacing" loading={this.state.isLoading}>
						<FormattedMessage {...this.props.messages.Print} />
					</Button>
					<Button size="large" onClick={() => { this.email(); }} className="button-spacing">
						<FormattedMessage {...this.props.messages.Emails} />
					</Button>
					{this.renderExtraButtons()}
					<Button key="back" size="large" onClick={this.props.goTo}>
						<FormattedMessage {...this.props.messages.cancel} />
					</Button>
				</div>
			</React.Fragment>
		);
	}
}

ShowInvoicingDocument.defaultProps = {
	deniedStatuses: []
};

ShowInvoicingDocument.propTypes = {
	intl: PropTypes.object.isRequired,
	products: PropTypes.array.isRequired,
	document: PropTypes.object.isRequired,
	messages: PropTypes.object.isRequired,
	deniedStatuses: PropTypes.array.isRequired,
	extraButtons: PropTypes.oneOfType([
		PropTypes.object,
        PropTypes.array
	]),
	extraInfo: PropTypes.object,
	printDocument: PropTypes.func.isRequired,
	openEmailModal: PropTypes.func.isRequired,
	cancelledReason: PropTypes.func.isRequired,
	renderStatus: PropTypes.func.isRequired,
	goTo: PropTypes.func.isRequired
};

export default (injectIntl(ShowInvoicingDocument));