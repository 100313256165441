import {getObjectInArray} from '../../utilities/util';
const moment = require('moment');

export const BLOODTYPES = [
    {
        id: 1,
        symbol: "O-"
    },
    {
        id: 2,
        symbol: "O+"
    },
    {
        id: 3,
        symbol: "A-"
    },
    {
        id: 4,
        symbol: "A+"
    },
    {
        id: 5,
        symbol: "B-"
    },
    {
        id: 6,
        symbol: "B+"
    },
    {
        id: 7,
        symbol: "AB-"
    },
    {
        id: 8,
        symbol: "AB+"
    }
];

export function getBloodType(id) {
    let TYPE = getObjectInArray("id", id, BLOODTYPES);
    if (TYPE === undefined)
        return ({
            id: -1,
            symbol: "--"
        });
    return TYPE;
}

export const RELATIONS = [
    "spouse",
    "father",
    "mother",
    "child",
    "sibling",
    "friend"
];

export const GENDERS = [
    {
        id: 0,
        intl: "female"
    },
    {
        id: 1,
        intl: "male"
    }
];

export function getGender(genderBool) {
    return genderBool ? GENDERS[1] : GENDERS[0];
}

export const MARITALSTATUS = [
    {
        id: 0,
        intl: "single"
    },
    {
        id: 1,
        intl: "married"
    },
    {
        id: 2,
        intl: "divorced"
    },
    {
        id: 3,
        intl: "widow"
    },
    {
        id: 4,
        intl: "freeUnion"
    }
];

export function getMaritalStatus(id) {
    let STATUS = getObjectInArray("id", id, MARITALSTATUS);
    if (STATUS === undefined)
        return MARITALSTATUS[0];
    return STATUS;
}

export const formatPrice = (price) => {    
    if (isNaN(parseFloat(price)))
        return "NaN";
    
    let formatedPrice = parseFloat(price).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 4
    });

    return `L. ${formatedPrice}`;
}

export const disabledNonPayDates = (current) => {
    const beforeToday = current < moment().startOf('day');
    const isNot15 = current.date() !== 15;
    const isNot30 = current.date() !== 30;
    if (moment(current).month() === 1) {
        const febLastDate = moment(current).endOf('month').date();
        const isNotFebruaryLastDay = current.date() !== febLastDate;
        return beforeToday || (isNot15 && isNotFebruaryLastDay);
    }
    return beforeToday || (isNot15 && isNot30);
}