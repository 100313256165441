import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

class DraggerList extends React.Component {

    renderEntry = (entry, index) => {
        const { entryRender } = this.props;
        return <div key={index}>{entryRender(entry, index)}</div>;
    }

    renderDraggableEntry = (entry, index) => {
        const { entryRender } = this.props;
        return (
            <Draggable key={index} draggableId={index.toString()} index={index}>
                {provided => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        {entryRender(entry, index)}
                    </div>
                )}
            </Draggable>
        );
    }

    onDragEnd = (result) => {
        const { data, dataUpdate } = this.props;
        const { destination, source, draggableId } = result;
        if (!destination) return;
        if (source.droppableId === destination.droppableId && source.index === destination.index) return;
        const dataCopy = Array.from(data);
        dataCopy.splice(source.index, 1);
        dataCopy.splice(destination.index, 0, data[parseInt(draggableId)]);
        dataUpdate(dataCopy);
    }
    
	fullRender = () => {
        const { props, renderEntry, onDragEnd, renderDraggableEntry } = this;
        const { data, draggable } = props;
        if (!draggable) {
            const entries = data.map((entry, index) => renderEntry(entry, index));
            return (
                <div>
                    {entries}
                </div>
            )
        } else {  
            const entries = data.map((entry, index) => renderDraggableEntry(entry, index));
            return (
                <DragDropContext
                    onDragEnd={onDragEnd}
                >
                    <Droppable droppableId="droppable">
                        {provided => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {entries}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            );
        }
    }
    
	render(){
		return this.fullRender();
	}
}
 
DraggerList.defaultProps = {
    data: [],
    entryRender: (entry) => entry,
    dataUpdate: () => {},
    draggable: true
};

DraggerList.propTypes = {
    data: PropTypes.array.isRequired,
    entryRender:  PropTypes.func.isRequired,
    dataUpdate:  PropTypes.func.isRequired,
    draggable: PropTypes.bool.isRequired,
};

export default DraggerList;