import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import { injectIntl } from 'react-intl';
import { getClientMessages, getModalMessages } from '../../../../../constants/messages';
import LogsView from './View';

let clientMessages = getClientMessages(),
	modalMessages = getModalMessages();

const messages = {
	...clientMessages,
	...modalMessages
};

class PDLogsModal extends React.Component {

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	renderButtons = () => {
		const { props, getIntl } = this;
		const { handleCancel } = props;
		return [
			<Button key="close" onClick={handleCancel}>{getIntl("close")}</Button>
		];
	}

	render() {
		const { props, getIntl, renderButtons } = this;
		const { clientId, showModal, handleCancel } = props;
		return (
			<Modal
				open={showModal}
				title={getIntl("deactivationLog")}
				onCancel={handleCancel}
				destroyOnClose={true}
				footer={renderButtons()}
				width={1040}
			>
				<LogsView clientId={clientId} />
			</Modal>
		);
	}
}

PDLogsModal.propTypes = {
	intl: PropTypes.object.isRequired,
	showModal: PropTypes.bool.isRequired,
	clientId: PropTypes.number.isRequired,
	handleCancel: PropTypes.func.isRequired
};

export default injectIntl(PDLogsModal);