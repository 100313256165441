import axios from 'axios';

export const InitialSearch = (filterValues) => {
	return axios.get(`/Payments/initialSearch?filterValues=${encodeURIComponent(JSON.stringify(filterValues))}`, { filterValues })
		.then(response => response.data);
};

export const SearchWithFilter = (filter) => {
	return axios.get(`/Payments?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then(response => response.data);
}; 