import _ from 'lodash';

export default function debounce(fnc, wait) {
	const debounced = _.debounce(async (resolve, reject, value) => {
		try {
			resolve(fnc(value));
		} catch (error) {
			reject(error);
		}
	}, wait);

	return (value) => new Promise((resolve, reject) => debounced(resolve, reject, value));
}
