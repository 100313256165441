import React from 'react';
import PropTypes from 'prop-types';
import {
	injectIntl,
	FormattedMessage
} from 'react-intl';
import { DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { Row, Col, Card, Dropdown, Popconfirm, message } from 'antd';
import AddAccountButton from '../containers/AddAccountButton';
import {
	getactionMessages,
	getFields,
	getErrorMessages,
	getPopConfirm,
	getTableMessages
} from '../constants/messages';
import CommonTable from './CommonTable';
import MobileCommonTable from './MobileCommonTable';
import EditRolesForm from '../components/EditRolesForm';
import EditAccountForm from '../components/EditAccountForm';
import SessionForm from '../components/SessionForm';
import {isMobile} from '../utilities/util';

let errorMessages = getErrorMessages(),
	AccountMessages = getactionMessages(),
	tableMessages = getTableMessages(),
	PopConfirmMessages = getPopConfirm(),
	fieldMessages = getFields();

const messages = {
	...AccountMessages,
	...fieldMessages,
	...errorMessages,
	...tableMessages,
	...PopConfirmMessages
};

class Accounts extends React.Component  {

	constructor(props) {
		super(props);
		this.state = {
			userId: 0
		};
	}

	componentDidMount() {
		const {
			getAccountsMetaData
		} = this.props;

		getAccountsMetaData();
	}
	DeleteAccounts  = (selectedUsers) => {
		const {
			intl,
			deleteAccount
		} = this.props;

		if(selectedUsers.length > 0){
			deleteAccount(selectedUsers);
		}else{
			message.warning(`${intl.formatMessage( {...messages.deleteZero})}`);
		}
		return [];

	};
	editRecord = (record) => {
		const {
			triggleModal
		} = this.props;
		triggleModal(record);
	};
	editRoles = (id) => {
		const {
			triggleRoleModal
		} = this.props;
		triggleRoleModal(id);
	};
	editSessions = (id) => {
		const {
			triggleSessionModal
		} = this.props;
		triggleSessionModal(id);
	};
	render(){
		const {intl, accounts, recordCount, languageFilter, disableAccount, isLoading,search} = this.props;
		let columns = [
			/*{
				title: `${intl.formatMessage( {...messages.client})}`,
				dataIndex: 'client',
				render: (id, record) =>{
					return <span>{record.client.name}</span>;
				}
			}, */{
				title: `${intl.formatMessage( {...messages.name})}`,
				dataIndex: 'name',
				key: 'name'
			}, {
				title: `${intl.formatMessage( {...messages.username})}`,
				dataIndex: 'username',
				key: 'username'
			}, {
				title: `${intl.formatMessage( {...messages.email} )}`,
				dataIndex: 'email',
				key: 'email'
			}, {
				title: `${intl.formatMessage( {...messages.languages} )}`,
				dataIndex: 'languageId',
				render: (id) => {
					let myLang = languageFilter.filter((lang) => {
						return lang.id ==id;
					});
					if( myLang.length < 1)
						return "";
					return <span>{myLang[0].name}</span>;
				}
			},{
				title: `${intl.formatMessage( {...messages.active} )}`,
				dataIndex: 'active',
				className: 'status',
				render: (active) => {
					let style = {
						color: '#CCCCCC',
						"fontSize": "18px"
					};
					if(active)
						style.color = '#87D068';
					return (
							<span className="icon-circle"  style={style} />
						);
				}
			},{
				title:  `${intl.formatMessage( {...messages.actions} )}`,
				dataIndex: 'id',
				render: (id, record) => {
					let isActive = record.active;
					let disableText = `${intl.formatMessage( {...messages.disable} )}`;
					if(!isActive) {
						disableText = `${intl.formatMessage( {...messages.enable} )}`;
					}
					return (
						<span>
							
							<a className="editable-add-btn" onClick={() => this.editRecord(record)}>
								<span className="icon-pencil" />
								<FormattedMessage {...messages.edit} />
							</a>
							<span> | </span>
							<a onClick={() => disableAccount(id, isActive)}>
								{disableText}
							</a>
							<span> | </span>
							<a onClick={() => this.editRoles(id)}>
								<span className="icon-users" />
								<FormattedMessage {...messages.role} />
							</a>
							<span> | </span>
							<a onClick={() => this.editSessions(id)}>
								<FormattedMessage {...messages.sessions} />
							</a>
						</span>
					);
				}
			}
		];

		const disableText = (isActive) => {
			if(!isActive) {
				return (
					<div>
						<span className="icon-check" />
						{intl.formatMessage( {...messages.enable} )}
					</div>
				) 
			}
			return (
				<div>
					<span className="icon-ban" />
					{intl.formatMessage( {...messages.disable} )}
				</div>
			)
		};

		const action = (record) => {
			const { intl } = this.props;
			let id = record.id;
			let isActive = record.active;
		
			const items = [
				{
					key: '1',
					label: (
						<a className="editable-add-btn" onClick={() => this.editRecord(record)}>
							<span className="icon-pencil" />
							{intl.formatMessage( {...messages.edit} )}
						</a>
					),
				},
				{
					key: '2',
					label: (
						<a onClick={() => disableAccount(id, isActive)}>
							{disableText(isActive)}
						</a>
					),
				},
				{
					key: '3',
					label: (
						<a onClick={() => this.editRoles(id)}>
							<span className="icon-users" />
							{intl.formatMessage( {...messages.role} )}
						</a>
					),
				},
				{
					key: '4',
					label: (
						<a onClick={() => this.editSessions(id)}>
							<span className="icon-address-book" />
							{intl.formatMessage( {...messages.sessions} )}
						</a>
					),
				},
				{
					key: '5',
					label: (
						<Popconfirm 
							title={intl.formatMessage( {...messages.deleteConfirm} )} 
							onConfirm={() => this.DeleteAccounts([id])} 
							okText={intl.formatMessage( {...messages.yes} )} 
							cancelText={intl.formatMessage( {...messages.no} )}
						>
							<a>
								<DeleteOutlined />
								<FormattedMessage {...messages.delete} />
							</a>
						</Popconfirm>
					),
				},
			]

			return (
				<Dropdown menu={{ items }} trigger={['click']}>
					<a className="ant-dropdown-link" href="#">
						<FormattedMessage {...messages.actions} />
						<DownOutlined />
					</a>
				</Dropdown>
            );
		};

		const card = (record) => {
			let style = {
					color: '#CCCCCC',
					"fontSize": "16px",
					"paddingRight": "2px"
				};
			if (record.active)
				style.color = '#87D068';
			let title = (
					<Row align="middle">
						<Col span={14}>
							<span className="icon-circle" style = {style}/>
							{record.name}
						</Col>
					</Row>
				);
			return (

				<Row key={record.id}  type="flex" justify="space-around" align="middle">
					<Col xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:24}}>
						<Card loading={isLoading} title={title} extra={action(record)} style={{ width: "100%" }}>
							<Row  type="flex" justify="center">
								<Col span={8}>
									<strong>Username:</strong>
								</Col>
								<Col span={12}>
									{record.username}
								</Col>
							</Row>
							<Row  type="flex" justify="center">
								<Col span={8}>
									<strong>Email:</strong>
								</Col>
								<Col span={12}>
									{record.email}
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
				);
		};
		return (
			<div className="account view">
				{!isMobile() ? 
						<CommonTable
							columns = {columns}
							dataSource = {accounts}
							search={(search, page, pageSize) => this.props.searchAccounts(search, page, pageSize)}
							searchText={search}
							delete={this.DeleteAccounts}
							recordCount={recordCount}
							getRecords={(page, pageSize) => this.props.getAccounts(page, pageSize)}
							Add={(<AddAccountButton />)}
							loading={isLoading}
							getCheckboxProps={record => ({ disabled: !record.active })}
						/>
						:
						<MobileCommonTable 
							dataSource = {accounts}
							Add={(<AddAccountButton />)}
							search={(search, page, pageSize) => this.props.searchAccounts(search, page, pageSize)}
							searchText={search}
							recordCount={recordCount}
							loading={isLoading}
							card={card}
							getRecords={(page, pageSize) => this.props.getAccounts(page, pageSize)}
						/>
				}
				<EditAccountForm 
					showModal={this.props.showEditModal} 
					handleCancel={this.props.handleCancel}
				/>
				<EditRolesForm
					showModal={this.props.showRoleModal} 
					handleCancel={this.props.handleClose}
				/>
				<SessionForm 
					showModal={this.props.showSessionModal} 
					handleCancel={this.props.handleSessionClose}
				/>
			</div>	
		);
	}
}

Accounts.propTypes = {
	intl: PropTypes.object.isRequired,
	accounts: PropTypes.array,
	hasError: PropTypes.bool,
	error: PropTypes.object,
	user: PropTypes.object,
	languageFilter: PropTypes.array,
	getAccounts: PropTypes.func,
	deleteAccount: PropTypes.func,
	disableAccount: PropTypes.func,
	searchAccounts: PropTypes.func,
	getAccountsMetaData: PropTypes.func,
	recordCount: PropTypes.number,
	triggleModal: PropTypes.func,
	handleCancel: PropTypes.func,
	showEditModal: PropTypes.bool,
	isLoading: PropTypes.bool,
	handleClose: PropTypes.func,
	triggleRoleModal: PropTypes.func,
	triggleSessionModal: PropTypes.func,
	showSessionModal: PropTypes.bool,
	handleSessionClose: PropTypes.func,
	showRoleModal: PropTypes.bool,
	isMaster: PropTypes.bool,
	search:PropTypes.string,
};

export default injectIntl(Accounts);
