import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Tabs, Button, message } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { getDeviceMessages, getModalMessages} from '../../../constants/messages';
import Client from './Client';
import Device from './Device';
import Sim from './Sim';
import ExportDevices from './ExportDevices';
import { GetAllClients } from '../../Clients/actions';

let deviceMessages = getDeviceMessages(),
	modalMessages = getModalMessages();

const messages = {
	...deviceMessages,
	...modalMessages
};

const tabs = {
    client: "1",
    device: "2",
    sim: "3",
    exportDevices: "4",
}

const DeviceFinderModal = ({showModal, handleCancel}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    useEffect(() => {
		(async () => {
			try {
				dispatch(GetAllClients());
			} catch (error) {
                message.error(`${intl.formatMessage({ ...messages.GetClientsError })}`);
			}
		})();
	}, []);

    const items = [
        {
            key: tabs.client,
            label: intl.formatMessage({...messages.client}),
            children: <Client />
        },
        {
            key: tabs.device,
            label: intl.formatMessage({...messages.device}),
            children: <Device />
        },
        {
            key: tabs.sim,
            label: intl.formatMessage({...messages.sim}),
            children: <Sim />
        },
        {
            key: tabs.exportDevices,
            label: intl.formatMessage({...messages.export}),
            children: <ExportDevices />
        },
    ];

        return (
            <Modal
                open={showModal}
                title={intl.formatMessage({...messages.deviceFinder})}
                destroyOnClose={true}
                onCancel={handleCancel}
                footer={[
                    <Button key="close" size="large" onClick={handleCancel}>
                        <FormattedMessage {...messages.close} />
                    </Button>
                ]}
            >
                <Tabs defaultActiveKey={tabs.client} items={items} />
            </Modal> 
        );
}

DeviceFinderModal.propTypes = {
	showModal:  PropTypes.bool.isRequired,
	handleCancel:  PropTypes.func.isRequired
};

export default DeviceFinderModal
