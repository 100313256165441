import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { message, Typography, Radio } from 'antd';
import { getDashboardMessages, getPopConfirm } from '../../../constants/messages';
import { GetInvoiceTaxesWidgetInfo, FilterInvoiceTaxesWidgetInfo } from '../actions';
import Widget from '../Components/DefaultWidget';
import { displayCurrency } from '../../../utilities/util';
const { Text } = Typography;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

let dashboardMessages = getDashboardMessages(),
    popConfirmMessages = getPopConfirm();

const messages = {
	...dashboardMessages,
	...popConfirmMessages
};

class InvoiceTaxes extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
            isLoading: false,
            currentMonthTaxes: 0,
            lastMonthTaxes: 0,
            currentMonthOverdueTaxes: 0,
            lastMonthOverdueTaxes: 0,
            taxedAmounts: [],
            showActiveInvoices: false
        };
	}

	componentDidMount () {
        const { getIntl, setLoading } = this;
        setLoading(true);
        GetInvoiceTaxesWidgetInfo()
        .then(response => {
            const taxedAmounts = response || [];
            const currentMonth = taxedAmounts.length > 0 ? taxedAmounts[(taxedAmounts.length - 1)] : {};
            const lastMonth = taxedAmounts.length > 1 ? taxedAmounts[(taxedAmounts.length - 2)] : {};
            const { amount: currentMonthTaxes = 0, overdueTax: currentMonthOverdueTaxes = 0 } = currentMonth;
            const { amount: lastMonthTaxes = 0, overdueTax: lastMonthOverdueTaxes = 0 } = lastMonth;
            this.setState({ currentMonthTaxes, lastMonthTaxes, currentMonthOverdueTaxes, lastMonthOverdueTaxes, taxedAmounts, isLoading: false });
        })
        .catch(() => {
            setLoading(false);
            message.error(getIntl("getInvoicesTaxInfoError"));
        });
	}

    getIntl = (str, values = {}) => this.props.intl.formatMessage({...messages[str]}, values);

    setLoading = isLoading => this.setState({ isLoading });

    filterData = (datesToLookup = []) => {
        const { getIntl, setLoading } = this;
        setLoading(true);
        FilterInvoiceTaxesWidgetInfo(datesToLookup)
        .then(response => this.setState({ taxedAmounts: response || [], isLoading: false }))
        .catch(() => {
            setLoading(false);
            message.error(getIntl("getInvoicesTaxInfoError"));
        });
    }

    formatData = () => {
        const { taxedAmounts, showActiveInvoices } = this.state;
        const data = [];
        taxedAmounts.forEach(invoice => {
            if (showActiveInvoices) data.push(invoice);
            else data.push({ ...invoice, amount: invoice.overdueTax, count: invoice.overdueCount });
        });
        return data;
    }

    renderFilter = () => {
        const { state, getIntl } = this;
        const { showActiveInvoices } = state;
        const options = [
            <RadioButton key={0} value={false} >{getIntl("no")}</RadioButton>,
            <RadioButton key={1} value={true}>{getIntl("yes")}</RadioButton>
        ];
        const onChange = showActiveInvoices => this.setState({ showActiveInvoices });
        return (
            <span>
                <Text>{getIntl("showActiveInvoices")} </Text>
                <RadioGroup size="small" value={showActiveInvoices} onChange={event => onChange(event.target.value)}>{options}</RadioGroup>
            </span>
        );
    }

    graphTooltipFormatter = () => {
        const { getIntl } = this;
        const format = (date, amount, count) => {
            const data = {
                title: date,
                name: getIntl("invoicedTaxes"),
                value: `<tr><td class="dashboard-tooltip-format">${getIntl("total")}</td><td class="dashboard-invoice-payment-tooltip-table-value dashboard-tooltip-format">${displayCurrency(amount)}</td></tr>`
            };
            if (amount !== 0) data.count = `<tr><td class="dashboard-tooltip-format">${getIntl("invoices")}</td><td class="dashboard-invoice-payment-tooltip-table-value dashboard-tooltip-format">${count}</td></tr>`;
            return data;
        };
        return ["date*amount*count", format];
    }

    graphItemTplRender = () => {
        return (
            `<li>
                <span style="background-color:{color};" class="g2-tooltip-marker"></span>
                <span class="dashboard-invoice-payment-tooltip-title">{name}: </span>
                <table class="dashboard-invoice-payment-tooltip-table">
                    {value}
                    {count}
                </table>
            </li>`
        );
    }

	render() {
        const { state, getIntl, filterData, formatData, renderFilter, graphTooltipFormatter, graphItemTplRender } = this;
        const { isLoading, currentMonthTaxes, currentMonthOverdueTaxes, lastMonthTaxes, lastMonthOverdueTaxes, showActiveInvoices } = state;
        const data = formatData();
        return (
            <Widget
                isLoading={isLoading}
                title={getIntl("invoiceTaxes")}
                statisticTitle={getIntl("thisMonthsInArrearInvoices")}
                statisticLastValue={showActiveInvoices ? lastMonthTaxes : lastMonthOverdueTaxes}
                statisticCurrentValue={showActiveInvoices ? currentMonthTaxes : currentMonthOverdueTaxes}
                statisticIcon="L. "
                statisticExtraContent={renderFilter()}
                statisticReverseComparison
                graphData={data}
                dateRangeSelectFunction={filterData}
                graphTooltipFormatter={graphTooltipFormatter}
                graphItemTpl={graphItemTplRender()}
            />
        );
    }
}

InvoiceTaxes.propTypes = {
	intl: PropTypes.object.isRequired
};

export default injectIntl(InvoiceTaxes);