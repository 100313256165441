import React from 'react';
import PropTypes from 'prop-types';
import {getInvoicingMessages} from '../../constants/messages';
import { DatePicker, Checkbox } from 'antd';
import {injectIntl, FormattedMessage} from 'react-intl';

let InvoicingMessages = getInvoicingMessages();

const messages = {
    ...InvoicingMessages
};

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

class CreditNotesFilter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			startDate: undefined,
			endDate: undefined,
			totalRange: false
		};
	}

	onChangeRange = (e) => {
		this.setState({
			startDate: e[0],
			endDate: e[1]
		});
		let filter = {
			...this.state,
			startDate: e[0],
			endDate: e[1]
		};
		this.props.onFilter(filter);
	}

	onChangeCheckBox = () => {
		this.setState({
			totalRange: !this.state.totalRange
		});
		let filter = {
			...this.state,
			totalRange: !this.state.totalRange
		};
		this.props.onFilter(filter);
	}

	render(){
		return(
			<div style={{align: 'right'}}>
				<RangePicker format={dateFormat} onChange={this.onChangeRange} style={{width: '35%', 'marginRight': '8px'}}/>
				<Checkbox onChange={this.onChangeCheckBox}><FormattedMessage {...messages.ExactTotal} /></Checkbox>
			</div>
			
		);
	}
}

CreditNotesFilter.propTypes = {
	onFilter: PropTypes.func,
};

export default injectIntl(CreditNotesFilter);