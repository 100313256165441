import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage, IntlProvider } from 'react-intl';

import { CheckOutlined, CloseOutlined, FileSearchOutlined, MobileOutlined, MoreOutlined, ReloadOutlined } from '@ant-design/icons';
import { Tooltip, Row, Col, Button, message, Modal, Spin, Input, Dropdown } from 'antd';
import { Calendar as BigCalendar, momentLocalizer ,Views } from 'react-big-calendar';
import { getLanguageIntl, getCalendarMessages, getactionMessages, getJobMessages, getErrorMessages, getDeviceMessages } from '../../../constants/messages';
import moment from 'moment';
import style from 'react-big-calendar/lib/css/react-big-calendar.css';
import { JOBTYPES, JOBSTATUS, getJobType, getJobStatus, getYearHolidays } from '../constants';
import { existsInArray, objectToArray } from '../../../utilities/util';
import Filters from './Filters';
import DeviceFinder from '../../Devices/Finder/Modal';
import { CancelCalendarFetch, JobExists } from '../actions';
import CircleBadge from '../../GlobalComponents/CircleBadge';
import AddJobModal from '../AddUpdateJob/Modal';
import ViewJobModal from '../ViewJob/Modal';
import AdvancedSearchModal from './AdvancedSearch/Modal';
import Embolden from '../../GlobalComponents/Embolden';
const { Search } = Input;

let deviceMessages = getDeviceMessages(),
    intlMessages = getLanguageIntl(),
    calendarMessages = getCalendarMessages(),
    actionMessages = getactionMessages(),
    JobsMessages = getJobMessages(),
    errorMessages = getErrorMessages();

const messages = {
	...deviceMessages,
    ...intlMessages,
    ...calendarMessages,
    ...actionMessages,
    ...JobsMessages,
    ...errorMessages
};

export const standardSwitchProps = {
    size: "small",
    checkedChildren : <CheckOutlined />, 
    unCheckedChildren: <CloseOutlined />
};

const second = 1000;
const minute = second * 60;
const refreshIntervalInMinutes = 5;
const confirm = Modal.confirm;

export function getJobTypeColor(type) {
    if (type === -2)
    return ({
        backgroundColor: "#B60000",
        color: "white"
    });
    let TYPE = getJobType(type);
    let style = {
        backgroundColor: TYPE.backgroundColor || "#cdcdcd",
        color: TYPE.textColor || "black"
    };
	return style;
}

export function getStatusIconColor(status) {
    let STATUS = getJobStatus(status);
    let style = {color: STATUS.iconColor};
    return style;
}

class Calendar extends React.Component {
	constructor(props){
        super(props);
        this.state = {
            fetchingEvents: false,
            loadingJob: false,
            date: moment().toISOString(),
            view: "week",
            lastRefreshInMinutes: 0,
            showTheseTypes: objectToArray(JOBTYPES).map(type => type.id),
            showTheseStatus: objectToArray(JOBSTATUS).map(status => status.id),
            showUndefined: true,
            showAll: true,
            showDeviceFinder: false,
            holidays: [],
            showAddModal: false,
            addJob: { },
            showViewModal: false,
            jobId: 0,
            searchLoading: false,
            showAdvancedSearch: false
        };
    }

	componentDidMount () {
        const { props, initialEventFetch, startInterval } = this;
        const { intl } = props;
        initialEventFetch();
        startInterval();
        const thisYearHolidays = getYearHolidays();
        const parseHoliday = (holiday) => {
            return {
                start: new Date(holiday.start),
                end: new Date(holiday.end),
                title: holiday.name,
                status: { id : -2 },
                type: { id : -2 },
                technicians: [],
                description: intl.formatMessage({...messages.noDescription})
            };
        }
        if (Array.isArray(thisYearHolidays)) {
            const holidays = thisYearHolidays.map(holiday => parseHoliday(holiday));
            this.setState({ holidays });
        } else
            this.setState({ holidays: [parseHoliday(thisYearHolidays)] });
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
        CancelCalendarFetch();
    }

    getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    startInterval = () => {
        this.refreshInterval = setInterval(() => this.handleRefreshInterval(), minute);
    }

    resetInterval = () => {
        clearInterval(this.refreshInterval);
        this.setState({lastRefreshInMinutes: 0});
        this.startInterval();
    }

    refreshEvents = () => {
        let {date, view} = this.state;
        this.fetchEventsOnDateView(date, view);
    }

    handleRefreshInterval = () => {
        let {lastRefreshInMinutes} = this.state;
        let refresh = lastRefreshInMinutes + 1;
        if (refresh >= refreshIntervalInMinutes) {
            this.refreshEvents();
            this.setState({lastRefreshInMinutes: 0});
        } else
            this.setState({lastRefreshInMinutes: refresh});
    }

    resetAndRefresh = () => {
        this.resetInterval();
        this.refreshEvents();
    }

    resetAndFetchOnDateView = (date, view) => {
        this.resetInterval();
        this.fetchEventsOnDateView(date, view);
    }

    initialEventFetch = () => {
        let {intl} = this.props;
        let week = intl.formatMessage({...messages.intl}) === "es" ? "isoWeek" : "week";
        let date = moment().toISOString();
        let start = moment(date).startOf(week).toISOString();
        let end = moment(date).endOf(week).toISOString();
        this.setState({date});
        this.fetchCalendarEvents(start, end);
    }

    fetchCalendarEvents = (start, end) => {
        const {fetchCalendarEvents} = this.props;
        this.setState({fetchingEvents: true});
        fetchCalendarEvents(start, end)
        .then(() => this.setState({fetchingEvents: false}));
    }

    parseEvents = () => {
        let {showTheseTypes, showTheseStatus, showAll, showUndefined, holidays} = this.state;
        let {events, intl} = this.props;
        return (
            events.map(event => {
                let jobInfo = event.jobInfo || {};
                let TYPE = getJobType(jobInfo.type);
                let STATUS = getJobStatus(jobInfo.status);
                let undefinedEvent = TYPE.id === -1 || STATUS.id === -1;
                let technicians = jobInfo.technicians || [];
                let jobId = jobInfo.id;
                if (!showAll) {
                    if (undefinedEvent) {
                        if (!showUndefined) return;
                    }
                    else {
                        if (!existsInArray(TYPE.id, showTheseTypes) || !existsInArray(STATUS.id, showTheseStatus))
                            return;
                    }
                }
                let title = ( undefinedEvent ? event.summary :
                    (<span>
                        <CircleBadge color={STATUS.iconColor} />
                        <span>&nbsp;</span>
                        <span>{jobInfo.clientName}</span>
                    </span>)
                );
                return (
                    {
                        id: event.id,
                        start: new Date(event.start.date || event.start.dateTime),
                        end: new Date(event.end.date || event.end.dateTime),
                        title: title,
                        status: STATUS,
                        type: TYPE,
                        clientName: jobInfo.clientName || "",
                        description: event.description || intl.formatMessage({...messages.noDescription}),
                        technicians: technicians || [],
                        jobId: jobId
                    }
                );
            }).concat(holidays)
        );
    }

    fetchIntlMessages = () => {
        let {intl} = this.props;
        return ({
            allDay: intl.formatMessage({...messages.allDay}),
            previous: intl.formatMessage({...messages.previous}),
            next: intl.formatMessage({...messages.next}),
            today: intl.formatMessage({...messages.today}),
            month: intl.formatMessage({...messages.month}),
            week: intl.formatMessage({...messages.week}),
            day: intl.formatMessage({...messages.day}),
            agenda: intl.formatMessage({...messages.agenda}),
            date: intl.formatMessage({...messages.date}),
            time: intl.formatMessage({...messages.time}),
            event: intl.formatMessage({...messages.event})
        });
    }

    fetchEventsOnDateView = (date, view) => {
        let {intl} = this.props;
        let start, end = undefined;
        switch (view) {
            case "day":
                start = moment(date).startOf('day');
                end = moment(date).endOf('day');
                break;
            case "week":{
                let week = intl.formatMessage({...messages.intl}) === "es" ? "isoWeek" : "week";
                start = moment(date).startOf(week);
                end = moment(date).endOf(week);
                break;
            }
            case "agenda":
                start =  moment(date).startOf('day');
                end = moment(date).endOf('day').add(1, 'month');
                break;
            default:
                start = moment(date).startOf('month').subtract(7, 'days');
                end = moment(date).endOf('month').add(7, 'days');
                break;
        }
        this.fetchCalendarEvents(start.toISOString(), end.toISOString());
    }

    handleSelectSlot = (slotInfo) => {
        const { intl } = this.props;
        const start = moment(slotInfo.start);
        const end = moment(slotInfo.end);
        const current = new moment();
        if (start < current) return message.error(intl.formatMessage({...messages.invalidDateError}));
        const duration = moment.duration(end.diff(start)).asMinutes();
        const addJob = { programmedDate: start.toISOString(), duration: duration};
        this.setState({ addJob, showAddModal: true });
    }

    handleViewChange = (newView) => {
        this.setState({view: newView});
        this.resetAndFetchOnDateView(this.state.date, newView);
    }

    handleOnNavigate = (date, view) => {
        this.setState({date: moment(date).toISOString()});
        this.resetAndFetchOnDateView(date, view);
    }

    handleEventSelection = (event) => {
        const { props, getIntl } = this;
        const { intl } = props;
        const { type, status, title, description, jobId } = event;
        if (type.id <= -1 || status.id <= -1) {
            let content = (
                <IntlProvider locale={intl.locale} messages={intl.messages}>
                    <div>
                        <Embolden header={getIntl("summary")} value={title} row/>
                        <Embolden header={getIntl("description")} value={description} row/>
                    </div>
                </IntlProvider>
            );
            confirm({
                title: getIntl("eventNotCreatedInPlatform"),
                content: content,
                onOk: () => {},
                onCancel: () => {}
              });
        } else {
            this.setState({ showViewModal: true, jobId });
        }
    }

    eventStyleGetter = (event) => {
        let style = getJobTypeColor(event.type.id);
        return {style: style};
    }

    eventToolTipInfo = (event) => {
        const {intl} = this.props;
        const {type, status, clientName, technicians, jobId} = event;
        let techNames = technicians.map(tech => tech.name);
        let text = (type.id <= -1 || status.id <= -1) ? event.title: 
        `#${jobId} - ${clientName} - ${intl.formatMessage({...messages[type.intl]})} - ${intl.formatMessage({...messages[status.intl]})} - ${techNames.join(", ")}`;
        return (text);
    }

    searchJobId = (value) => {
        const { getIntl } = this;
        this.setState({ searchLoading: true });
        const jobId = parseInt(value);
        JobExists(jobId)
        .then(response => {
            this.setState({ searchLoading: false });
            if (response === 0) message.error(getIntl("jobFinderExistsError"));
            else this.setState({ showViewModal: true, jobId: jobId });
        })
        .catch(() => {
            this.setState({ searchLoading: false });
            message.error(getIntl("jobFinderError"));
        });
    }

    renderSearchButton = () => {
        const { state, getIntl, searchJobId } = this;
        const { searchLoading } = state;
        const items = [
            {
				key: '1',
				label: (
					<a onClick={() => this.setState({ showDeviceFinder: true })}>
						<MobileOutlined />
                        {getIntl("deviceFinder")}
					</a>
				),
			},
			{
				key: '2',
				label: (
					<a onClick={() => this.setState({ showAdvancedSearch: true })}>
						<FileSearchOutlined />
                        {getIntl("advancedSearch")}
					</a>
				),
			},
        ]

        const addonAfter = (
            <Dropdown menu={{items}} trigger={['click']} placement="bottomRight">
                <MoreOutlined />
            </Dropdown>
        );

        return (
            <Tooltip title={getIntl("jobFinderTooltip")}>
                <Search
                    style={{ width: '95%' }}
                    loading={searchLoading}
                    placeholder="#1234"
                    onSearch={searchJobId}
                    addonAfter={addonAfter}
                />
            </Tooltip>
        );
    }

	render(){
        const { state, props, renderSearchButton } = this;
        const { fetchingEvents, lastRefreshInMinutes, showTheseTypes, showTheseStatus, showAll, showUndefined, loadingJob, showDeviceFinder, showAddModal, addJob, showViewModal, jobId, showAdvancedSearch } = state;
        const { intl } = props;
        const locale = intl.formatMessage({...messages.intl});
        moment.locale(locale);
		return (
            <div className="view">
                <AddJobModal
                    showModal={ showAddModal }
                    handleCancel={() => this.setState({ showAddModal: false, addJob: { } })}
                    jobTemplate={addJob}
				/>
                <ViewJobModal
                    showModal={ showViewModal }
                    handleCancel={() => this.setState({ showViewModal: false, jobId: 0 })}
                    jobId={jobId}
				/>
                <DeviceFinder 
                    showModal={showDeviceFinder}
                    handleCancel={() => this.setState({showDeviceFinder: false})}
                />
                <AdvancedSearchModal
                    showModal={ showAdvancedSearch }
                    handleCancel={() => this.setState({ showAdvancedSearch: false })}
				/>
                <Row className="job-bottom-padding">
                    <Col span={4}>
                        {renderSearchButton()}
                    </Col>
                    <Col span={8}>
                        <Tooltip title={intl.formatMessage({...messages.refreshTooltip})} mouseEnterDelay={1}>
                            <Button type="primary" loading={fetchingEvents} onClick={this.resetAndRefresh} icon={<ReloadOutlined />}>
                                    <FormattedMessage {...messages.refresh}/>
                            </Button>
                        </Tooltip>
                        <span>{intl.formatMessage({...messages.lastRefreshed}) + moment.duration((lastRefreshInMinutes * -1), "minutes").humanize(true)}</span>
                    </Col>
                    <Col span={12} className="job-right-align">
                        <Filters 
                            setState={partialState => this.setState(partialState)}
                            showTheseTypes={showTheseTypes}
                            showTheseStatus={showTheseStatus}
                            showAll={showAll}
                            showUndefined={showUndefined}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Spin spinning={loadingJob}>
                            <BigCalendar
                                className="calendar-remove-time-stamps"
                                style={{...style, height: '100vh'}}
                                selectable
                                events={this.parseEvents()}
                                culture={intl.formatMessage({...messages.intl})}
                                messages={this.fetchIntlMessages()}
                                defaultDate={new Date()}
                                defaultView={Views.WEEK}
                                localizer={momentLocalizer(moment)}
                                onSelectEvent={this.handleEventSelection}
                                onSelectSlot={this.handleSelectSlot}
                                onNavigate={this.handleOnNavigate}
                                onView={this.handleViewChange}
                                eventPropGetter={(this.eventStyleGetter)}
                                tooltipAccessor={this.eventToolTipInfo}
                            />
                        </Spin>
                    </Col>
                </Row>
            </div>
        );
	}
}

Calendar.propTypes = {
	intl: PropTypes.object.isRequired,
    events: PropTypes.array.isRequired,
    fetchCalendarEvents: PropTypes.func.isRequired
};

export default injectIntl(Calendar);