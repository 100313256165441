import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker, message, Spin } from 'antd';
import { connect } from "react-redux";
import { injectIntl } from 'react-intl';
import React from 'react';
import { getReportMessages, getIncomeMessages, getLanguageIntl } from '../../../constants/messages';
import { GetNextBalanceMonth, GetGenerateBalanceSheetInfo } from '../actions';
import StatisticComparison from './StatisticComparison';
const moment = require('moment');

const { MonthPicker } = DatePicker;
const FormItem = Form.Item;
const monthDateFormat = "MM-YYYY";

let reportMessages = getReportMessages(),
    incomeMessages = getIncomeMessages(),
    intlMessages = getLanguageIntl();

const messages = {
    ...reportMessages,
    ...incomeMessages,
    ...intlMessages
};

class BalanceSheetForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            initialAmount: 0,
            finalAmount: 0,
            latestBalanceDay: undefined
        }
    }

    componentDidMount() {
        const { props, getIntl, setLoading, getBalanceInfo } = this;
        const { form, getNextBalanceMonth, bank } = props;
        const { setFieldsValue } = form;
        const bankdId = bank.id;
        setLoading(true);
        getNextBalanceMonth(bankdId)
        .then(date => {
            const balanceMonth = moment(date);
            getBalanceInfo(balanceMonth);
            setFieldsValue({ balanceMonth });
            this.setState({ latestBalanceDay: date });
        })
		.catch(() => {
            setLoading(false);
			message.error(getIntl("getLatestBalanceSheetMonthError"));
		});
    }

    getIntl = (str) => {
        return this.props.intl.formatMessage({ ...messages[str] });
    }

    setLoading = (isLoading) => {
        this.setState({ isLoading });
    }

    popconfirmCheck = (newMonth) => {
        const { state, props } = this;
        const { latestBalanceDay } = state;
        const { setPopconfirmTrigger, setDates } = props;
        const latestM = moment(latestBalanceDay);
        const selectedM = moment(newMonth);
        if (latestM.isValid() && selectedM.isValid()) {
            if (!latestM.isSame(selectedM, 'M')) {
                setDates(latestM.format(monthDateFormat), selectedM.format(monthDateFormat));
                setPopconfirmTrigger(true);
            }
            else setPopconfirmTrigger(false);
        }
    }

    getBalanceInfo = (balanceMonth) => {
        const { props, getIntl, setLoading } = this;
        const { bank, getGenerateBalanceSheetInfo } = props;
        const bankdId = bank.id;
        setLoading(true);
        getGenerateBalanceSheetInfo(bankdId, balanceMonth)
            .then(info => {
                setLoading(false);
                const { initialAmount, finalAmount } = info;
                this.setState({ initialAmount, finalAmount });
            })
            .catch(() => {
                setLoading(false);
                message.error(getIntl("getBalanceSheetInfoError"));
            });
    }

    renderStatistics = () => {
        const { state, props } = this;
        const { initialAmount, finalAmount, latestBalanceDay } = state;
        const { getFieldValue } = props.form;
        const selectedBalanceDay = getFieldValue('balanceMonth');
        return <StatisticComparison initialValue={initialAmount} finalValue={finalAmount} latestBalanceDay={latestBalanceDay} selectedBalanceDay={selectedBalanceDay}/>;
    }

    disabledDate = (current) => {
        const { latestBalanceDay } = this.state;
        if (latestBalanceDay) {
            const latestEnd = moment(latestBalanceDay).endOf('month');
            const currentEnd = moment(current).endOf('month');
            return currentEnd.isBefore(latestEnd, 'month');
        }
        return false;
    }

    renderMonthPicker = () => {
		const { props, getIntl, getBalanceInfo, disabledDate, popconfirmCheck } = this;
        const { getFieldDecorator } = props.form;
		return (
			<FormItem label={getIntl("monthToClose")}>
			{
				getFieldDecorator(`balanceMonth`, {
                    onChange: (newMonth) => { getBalanceInfo(newMonth); popconfirmCheck(newMonth); }
                }
				)(
                    <MonthPicker
						placeholder={getIntl("monthToClose")}
						format={monthDateFormat}
						allowClear={false}
						disabledDate={disabledDate}
					/>
				)
			}
			</FormItem>
		);
    }

    fullRender = () => {
        const { state, renderMonthPicker, renderStatistics } = this;
        const { isLoading } = state;
        return (
            <Spin spinning = {isLoading}>
                {renderMonthPicker()}
                {renderStatistics()}
            </Spin>
        );
    }

    render() {
        return this.fullRender();
    }
}

BalanceSheetForm.propTypes = {
    intl: PropTypes.object.isRequired,
    bank: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    getNextBalanceMonth: PropTypes.func.isRequired,
    getGenerateBalanceSheetInfo: PropTypes.func.isRequired,
    setPopconfirmTrigger: PropTypes.func.isRequired,
    setDates: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        accountId: state.auth.user.id,
    };
}

const mapDispatchToProps = (dispatch) => ({
    getNextBalanceMonth: (bankId) => dispatch(GetNextBalanceMonth(bankId)),
    getGenerateBalanceSheetInfo: (bankId, balanceMonth) => dispatch(GetGenerateBalanceSheetInfo(bankId, balanceMonth))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(BalanceSheetForm));

