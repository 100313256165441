import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Spin, message, Button } from 'antd';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getCommissionMessages, getactionMessages, getModalMessages } from '../../constants/messages';
import View from './PayView';
import { PayCommission } from './actions';
import moment from 'moment';

let commissionMessages = getCommissionMessages(),
	actionMessages = getactionMessages(),
	modalMessages = getModalMessages();

const messages = {
	...commissionMessages,
	...actionMessages,
	...modalMessages
};

const defaultState = {
	isLoading: false,
};

class PayModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { ...defaultState };
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });

	handleCancel = () => {
		const { handleCancel } = this.props;
		this.setState(defaultState);
		handleCancel();
	}

	handlePay = () => {
		const { props, getIntl, setLoading, handleCancel } = this;
		const { commissionId, form, payCommission } = props;
		const { validateFields } = form;
		setLoading(true);
		validateFields({ force: true }, (err, values) => {
			if (!err) {
				const { paidDate: date, paidComment, bankAccountId } = values;
				const paidDate = moment(date).toISOString();
				const data = { paidDate, paidComment, bankAccountId };
				payCommission(commissionId, data)
					.then(() => {
						handleCancel();
						message.success(getIntl("payCommissionSuccess"));
					})
					.catch(() => {
						setLoading(false);
						message.error(getIntl("payCommissionError"));
					});
			}
		});
	}

	renderButtons = () => {
		const { state, getIntl, handleCancel, handlePay } = this;
		const { isLoading } = state;
		return [
			<Button key="cancel" onClick={handleCancel} disabled={isLoading}>{getIntl("cancel")}</Button>,
			<Button key="pay" type="primary" onClick={handlePay} loading={isLoading}>{getIntl("pay")}</Button>
		];
	}

	render() {
		const { state, props, getIntl, renderButtons, handleCancel, setLoading } = this;
		const { isLoading } = state;
		const { showModal, commissionId, form } = props;
		return (
			<Modal
				open={showModal}
				title={getIntl("payCommission")}
				onCancel={handleCancel}
				destroyOnClose={true}
				footer={renderButtons()}
			>
				<Spin spinning={isLoading}>
					<View commissionId={commissionId} form={form} setLoading={setLoading}/>
				</Spin>
			</Modal>
		);
	}
}

PayModal.defaultProps = {
	commissionId: 0
};

PayModal.propTypes = {
	intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,

	showModal: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired,
	commissionId: PropTypes.number.isRequired,
	payCommission: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => {
	return {
		payCommission: (commissionId, data) => dispatch(PayCommission(commissionId, data))
	};
};

export default connect(null, mapDispatchToProps)(injectIntl(Form.create()(PayModal)));