import { connect } from 'react-redux';
import Account from '../components/Account';
import {
	GetAccounts,
	GetAccountsMetaData,
	SearchAccounts,
	DeleteAccount,
	DisableAccount
} from '../actions/AccountAction';
import {
	INIT_REQUEST_ACCOUNT,
	OPEN_EDIT_MODAL_ACCOUNT,
	CLOSE_EDIT_MODAL_ACCOUNT,
	CLOSE_ROLE_MODAL_ACCOUNT,
	OPEN_ROLE_MODAL_ACCOUNT,
	OPEN_SESSIONS_MODAL_ACCOUNT,
	CLOSE_SESSIONS_MODAL_ACCOUNT
} from '../constants/actionTypes';

const mapStateToProps = (state) => {
	return {
		"user": state.auth.user,
		"accounts": state.account.accounts,
		"languageFilter": state.language.languageFilter,
		"recordCount": state.account.count,
		"isLoading": state.account.isLoading,
		"showEditModal": state.account.showEditModal,
		"showRoleModal": state.account.showRoleModal,
		"showSessionModal": state.account.showSessionModal,
		"isMaster": state.auth.isMaster,
		search: state.account.search
	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		getAccounts: ( page, pageSize ) => {
			dispatch({type:INIT_REQUEST_ACCOUNT});
			dispatch(GetAccounts( page, pageSize ));
		},
		searchAccounts: (search , page, pageSize) => {
			dispatch({type:INIT_REQUEST_ACCOUNT});
			dispatch(SearchAccounts(search , page, pageSize));
		},
		getAccountsMetaData: () => {
			dispatch(GetAccountsMetaData());
		},
		deleteAccount: (id) => {
			dispatch({type:INIT_REQUEST_ACCOUNT});
			dispatch(DeleteAccount(id));
		},
		disableAccount: (id, active) => {
			dispatch({type:INIT_REQUEST_ACCOUNT});
			dispatch(DisableAccount(id, active));
		},
		triggleModal: (editable) => {
			dispatch({type:OPEN_EDIT_MODAL_ACCOUNT, editable:editable});
		},
		handleCancel: () => {
			dispatch({type:CLOSE_EDIT_MODAL_ACCOUNT});
		},
		triggleRoleModal: (id) => {
			dispatch({type:OPEN_ROLE_MODAL_ACCOUNT, roleUserId:id});
		},
		triggleSessionModal: (id) => {
			dispatch({type:OPEN_SESSIONS_MODAL_ACCOUNT, sessionUserId:id});
		},
		handleClose: () => {
			dispatch({type:CLOSE_ROLE_MODAL_ACCOUNT});
		},
		handleSessionClose: () => {
			dispatch({type:CLOSE_SESSIONS_MODAL_ACCOUNT});
		},

	};
};

export default  connect(mapStateToProps, mapDispatchToProps)(Account);