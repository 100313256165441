import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, Spin, message, Input } from 'antd';
import { getAddressMessages, getClientMessages, getErrorMessages } from '../../../../constants/messages';
import { GetClientAddresses } from '../../../Clients/actions';
import { states } from '../../../../constants/address';
import { getObjectInArray } from '../../../../utilities/util';

const FormItem = Form.Item;
const Option = Select.Option;

let addressMessages = getAddressMessages(),
    clientMessages = getClientMessages(),
    errorMessages = getErrorMessages();

const messages = {
    ...addressMessages,
    ...clientMessages,
    ...errorMessages
};

class Address extends React.Component {
    
    constructor(props) {
		super(props);
		this.state = {
            isLoading: false,
            createNew: false
        };
    }

	componentDidMount () {
        const { props, fetchAddresses } = this;
        const { clientId } = props;
        if (clientId) fetchAddresses();
    }

    componentDidUpdate(prevProps) {
        const { props, setCreateNew } = this;
        const { clientId } = props;
        if (clientId !== prevProps.clientId) {
            this.fetchAddresses();
            setCreateNew(false);
        }
    }
    
	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});
    setLoading = (isLoading) => this.setState({ isLoading });
    setCreateNew = (createNew) => this.setState({ createNew });

    fetchAddresses = () => {
        const { props, getIntl, setLoading } = this;
        const { clientId, getClientAddresses } = props;
        setLoading(true);
        getClientAddresses(clientId)
        .then(() => setLoading(false))
        .catch(() => {
            setLoading(false);
            message.error(getIntl("getClientAddressesError"));
        });
    }

    opposeCreateDevice = () => {
        const { props, setCreateNew } = this;
        const { createNew } = this.state;
        const { form, saveFields } = props;
        const { resetFields } = form;
        setCreateNew(!createNew);
        let addressId, address, city, state;
        saveFields({ addressId, address, city, state });
        resetFields(["addressId", "address", "city", "state"]);
    }

	renderCreateAddressButton = () => {
        const { state, opposeCreateDevice, getIntl } = this;
        const { createNew } = state;
        const message = createNew ? "chooseExistingAddress" : "createNewAddress";
		return (
            <a onClick={opposeCreateDevice}>
                {getIntl(message)}
            </a>
        );
    }

    buildAddressOptions = () => {
        const { clientAddresses } = this.props;
        return clientAddresses.map((addr, index) => {
            const { id, address, city, state } = addr;
            return <Option key={index} value={id}>{[address, city, state].join(", ")}</Option>;
        });
    }

    renderAddressSelect = () => {
        const { state, props, buildAddressOptions, getIntl } = this;
        const { isLoading } = state;
        const { form, clientId, saveFields, getField, disabled } = props;
        const { getFieldDecorator } = form;
        const save = id => saveFields({ address: { id } });
        const options = buildAddressOptions();
        const addressId = getField("address.id");
        return(
            <FormItem label={getIntl("address")} >
            {
                getFieldDecorator(`addressId`, 
                    {
                        initialValue: addressId,
                        rules: [{
                            required: true,
                            message: getIntl("addressError"), 
                        }],
                        onChange: save
                    }
                )(
                    <Select
                        className="job-full-component"
                        showSearch
                        disabled={!clientId || disabled}
                        placeholder={getIntl("address")}
                        optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        notFoundContent={isLoading ? <Spin size="small" /> : getIntl("notFound")}
                    >
                        {options}
                    </Select>
                )
            }
            </FormItem>
        );
    }

    saveAddressProperty = (property, value) => {
        const { saveFields, getField } = this.props;
        const state = getField("address.state");
        const city = getField("address.city");
        const address = getField("address.address");
        saveFields({ address: { state, city, address, [property]: value } });
    };

    renderNewAddressCreator = () => {
        const { saveAddressProperty, props, getIntl } = this;
		const { form, disabled, clientId, getField } = props;
		const { getFieldDecorator } = form;
        const state = getField("address.state");
        const city = getField("address.city");
        const address = getField("address.address");
		const stateOptions = states.map((state, index) => <Option key={index} value={state.name}>{state.name}</Option>);
		const stateObject = getObjectInArray("name", state, states);
        const cityOptions = (stateObject && stateObject.cities) ? stateObject.cities.map((city, index) => <Option key={index} value={city}>{city}</Option>) : [];
		return (
			<div>
				<FormItem label={getIntl("state")} >
				{
					getFieldDecorator(`state`, 
						{
							initialValue: state,
							rules: [{
								required: true,
								message: getIntl("stateError")
							}],
							onChange: (value) => saveAddressProperty(`state`, value)
						}
					)(
						<Select
							className="job-full-component"
							showSearch
							placeholder={getIntl("state")}
							optionFilterProp="children"
							filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							dropdownMatchSelectWidth={false}
                            disabled={!clientId || disabled}
						>
							{stateOptions}
						</Select>
					)
				}
				</FormItem>
				<FormItem label={getIntl("city")} >
				{
					getFieldDecorator(`city`, 
						{
							initialValue: city,
							rules: [{
								required: true,
								message: getIntl("cityError")
							}],
							onChange: (value) => saveAddressProperty(`city`, value)
						}
					)(
						<Select
							className="job-full-component"
							showSearch
							placeholder={getIntl("city")}
							optionFilterProp="children"
							filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							dropdownMatchSelectWidth={false}
							disabled={state === undefined || !clientId || disabled}
						>
							{cityOptions}
						</Select>
					)
				}
				</FormItem>
				<FormItem label={getIntl("address")} >
				{
					getFieldDecorator('address', 
						{
							initialValue: address,
							rules: [{
								required: true,
								whitespace: true,
								message: getIntl("addressError")
							},{
								max: 255,
								message: getIntl("addressLengthError")
							}],
							onChange: (event) => this.saveAddressProperty('address', event.target.value)
						}
					)(
						<Input placeholder={getIntl("address")} disabled={!clientId || disabled}/>
					)
				}
				</FormItem>
			</div>
		);
    }

    renderChoice = () => {
        const { state, renderNewAddressCreator, renderAddressSelect } = this;
        const { createNew } = state;
        if (createNew) return renderNewAddressCreator();
        return renderAddressSelect();
    }

    fullRender = () => {
        const { renderChoice, renderCreateAddressButton } = this;
        return (
            <div>
                {renderChoice()}
                {renderCreateAddressButton()}
            </div>
        );
    }
    
	render() {
        return this.fullRender();
    }
}

Address.defaultProps = {
    disabled: false,
    clientId: 0,
    clientAddresses: []
};

Address.propTypes = {
	intl: PropTypes.object.isRequired,
    clientAddresses: PropTypes.array.isRequired,
    getClientAddresses: PropTypes.func.isRequired,

    form: PropTypes.object.isRequired,
    saveFields: PropTypes.func.isRequired,
    getField: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    clientId: PropTypes.number
};

const mapStateToProps = (state) => {
	return {
        clientAddresses: state.client.clientAddresses
	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		getClientAddresses: (clientId) => dispatch(GetClientAddresses(clientId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Address));