import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal } from 'antd';
import { injectIntl } from 'react-intl';
import { getJobMessages } from '../../../../constants/messages';
import SearchForm from './Form';

let JobsMessages = getJobMessages();

const messages = {
    ...JobsMessages
};

class AdvancedSearchModal extends React.Component  {

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});
    
	render(){
        const { props, getIntl } = this;
        const { form, showModal, handleCancel } = props;
		return (
            <Modal
                open={showModal}
                title={getIntl("advancedSearch")}
                onCancel={handleCancel}
                destroyOnClose={true}
                footer={null}
                width={1040}
            >
                <Form layout='vertical'>
                    <SearchForm form={form}/>
                </Form>
            </Modal>
		);
	}
}

AdvancedSearchModal.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	showModal: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired
};

export default injectIntl(Form.create()(AdvancedSearchModal));