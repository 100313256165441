import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

class DualDragger extends React.Component {

    renderNonDraggableEntry = (entry, index) => {
        const { entryRender } = this.props;
        const { id } = entry;
        return <div key={id}>{entryRender(entry, index)}</div>;
    }

    renderDraggableEntry = (entry, index) => {
        const { entryRender } = this.props;
        const { id } = entry;
        return (
            <Draggable key={id} draggableId={id.toString()} index={index}>
                {provided => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        {entryRender(entry, index)}
                    </div>
                )}
            </Draggable>
        );
    }

    renderEntry = (entry, index, nonDraggable = false) => {
        const { renderNonDraggableEntry, renderDraggableEntry } = this;
        const { isLoading, show } = entry;
        if (show) {
            if (isLoading === undefined || isLoading || nonDraggable) return renderNonDraggableEntry(entry, index);
            else return renderDraggableEntry(entry, index);
        }
    }

    onDragEnd = (result) => {
        const { data, data2, dataUpdate, changeLease } = this.props;
        const { destination, source, draggableId } = result;
    
        if (!destination) return;
    
        const reorder = (list, startIndex, endIndex) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);
            return result;
        };
    
        const move = (sourceList, destinationList, startIndex, endIndex) => {
            const sourceClone = Array.from(sourceList);
            const destClone = Array.from(destinationList);
            const [movedItem] = sourceClone.splice(startIndex, 1);
            destClone.splice(endIndex, 0, { ...movedItem, isLoading: true });
            return [sourceClone, destClone];
        };
    
        const sourceList = source.droppableId === "droppable1" ? data : data2;
        const destinationList = destination.droppableId === "droppable1" ? data : data2;
    
        if (source.droppableId === destination.droppableId) {
            const reorderedList = reorder(sourceList, source.index, destination.index);
            if (source.droppableId === "droppable1") {
                dataUpdate(reorderedList, data2);
            } else {
                dataUpdate(data, reorderedList);
            }
        } else {
            const [newSourceList, newDestinationList] = move(sourceList, destinationList, source.index, destination.index);
            if (source.droppableId === "droppable1") {
                dataUpdate(newSourceList, newDestinationList);
            } else {
                dataUpdate(newDestinationList, newSourceList);
            }
            changeLease(parseInt(draggableId, 10), destination.droppableId === "droppable2");
        }
    };
    

    renderDroppableContainer = (droppableId, entries, isDropDisabled = false) => {
        return (
            <Droppable droppableId={droppableId} isDropDisabled={isDropDisabled} >
                {provided => (
                    <div
                        className="client-dual-dragger-droppable"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {entries}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        );
    }
    
    fullRender = () => {
        const { props, renderEntry, onDragEnd, renderDroppableContainer} = this;
        const { data, data2, data3 } = props;

        const entries1 = data.map((entry, index) => renderEntry(entry, index));
        const entries2 = data2.map((entry, index) => renderEntry(entry, index));
        const entries3 = data3.map((entry, index) => renderEntry(entry, index, true));

        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Row style={{ display: 'flex' }}>
                    <Col span={6} offset={1}>{renderDroppableContainer("droppable1", entries1)}</Col>
                    <Col span={6} offset={2}>{renderDroppableContainer("droppable2", entries2)}</Col>
                    <Col disabled span={6} offset={2}>{renderDroppableContainer("droppable3", entries3, true)}</Col>
                </Row>
            </DragDropContext>
        );
    }
    
    render(){
        return this.fullRender();
    }
}

DualDragger.defaultProps = {
    data: [],
    data2: [],
    data3: [],
    entryRender: (entry) => entry
};

DualDragger.propTypes = {
    data: PropTypes.array.isRequired,
    data2: PropTypes.array.isRequired,
    data3: PropTypes.array.isRequired,
    entryRender: PropTypes.func.isRequired,
    dataUpdate: PropTypes.func.isRequired,
    changeLease: PropTypes.func.isRequired
};


export default DualDragger;