import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Button } from 'antd';
import { injectIntl } from 'react-intl';
import { getPayrollMessages, getModalMessages } from '../../../constants/messages';
import Ihss from './Renders/Ihss';
import Isr from './Renders/ISR/Isr';
import DeductionNames from './Renders/DeductionNames';
import { includeIHSS } from '../constants';
import { withRouter } from 'react-router-dom';
import MedicalDeductions from './Renders/Medical/MedicalDeductions';

let payrollMessages = getPayrollMessages(),
	modalMessages = getModalMessages();

const messages = {
	...payrollMessages,
	...modalMessages
};

const tabs = {
    ihss: "1",
    isr: "2",
	deductions: "3",
	medicalDeductions: "4"
}

class Deductions extends React.Component  {

	getIntl = (str) => {
		return this.props.intl.formatMessage({...messages[str]});
	}

	includeIhss = () => {
		const { ihss } = tabs;
        const { getIntl } = this;
		if (includeIHSS) 
			return {
				key: ihss,
				label: getIntl("ihss"),
				children: <Ihss />
			}
	}
	
	renderTabs = () => {
		const { isr, deductions, medicalDeductions } = tabs;
        const { getIntl, includeIhss } = this;

		const items = [
			{
				key: deductions,
				label: getIntl("deductions"),
				children: <DeductionNames />
			},
			includeIhss(),
			{
				key: isr,
				label: getIntl("isr"),
				children: <Isr />
			},
			{
				key: medicalDeductions,
				label: getIntl("medicalDeductions"),
				children: <MedicalDeductions />
			},
		];

		return (
			<Tabs 
				items={items}
				defaultActiveKey={deductions} 
				type="card" 
			/>
		);
	}

	renderReturnButton = () => {
        const { getIntl } = this;
		const margin = "0.5em";
		return (
			<div style={{ textAlign: 'right' }}>
				<Button
					style={{ marginTop: margin }}
					onClick={() => this.props.history.push("/payrolls")}
				>
					{getIntl("return")}
				</Button>
			</div>
		);
	}

	fullRender = () => {
		const { renderTabs, renderReturnButton } = this;
		return (
			<div className="view">
				{renderTabs()}
				{renderReturnButton()}
			</div>
		);
	}
	
	render(){
		return this.fullRender();
	}
}

Deductions.propTypes = {
	intl: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(injectIntl(Deductions));