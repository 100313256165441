import React from 'react'; 
import PropTypes from 'prop-types';
import { Row, Col, Select } from 'antd'; 
import { getFields, getEmployeeMessages } from '../../../../constants/messages';
import { useIntl } from 'react-intl';
import {REGEX} from '../../../../constants/global';
import {getNestedValue} from '../../../../utilities/util';
import { RELATIONS } from '../../constants';
import RemoveButton from './RemoveButton';
import InputForm from './FormItemInput';
import SelectForm from './FormItemSelect';

const Option = Select.Option;

let employeeMessages = getEmployeeMessages(), 
    fieldMessages = getFields() 
 
const messages = { 
    ...fieldMessages,
    ...employeeMessages
};

const EPhone = ({phone, index, remove, saveName, saveNumber, saveRelation}) => {
    const intl = useIntl();

    const getField = (field) => {
		return getNestedValue(field, phone);
	}

    const name = getField("name");
    const phonefield = getField("phone.number");
    const relation = getField("relation");
    let options = RELATIONS.map((rel, index) => <Option key={index} value={intl.formatMessage({...messages[rel]})}>{intl.formatMessage({...messages[rel]})}</Option>);
    return (
        <Row style={{ width: '100%' }}>
            <Col span={8}>
                <InputForm
                    fieldName = {[`Phone${index}`,'emergencyName']}
                    label = {intl.formatMessage({...messages.name})}
                    errorMessage = {intl.formatMessage({...messages.nameError})}
                    required = {true}
                    initialValue = {name}
                    onBlur = {(event) => saveName(event.target.value, index)}
                    renderLabel = {false}
                />
            </Col>
            <Col span={8}>
                <InputForm
                    fieldName = {[`Phone${index}`,'emergencyPhone']}
                    label = {intl.formatMessage({...messages.phone})}
                    errorMessage = {intl.formatMessage({...messages.phoneError})}
                    required = {true}
                    initialValue = {phonefield}
                    onBlur = {(event) => saveNumber(event.target.value, index)}
                    extraRules = {{ message: intl.formatMessage({...messages.phoneNumberError}), pattern: REGEX.number}}
                    renderLabel = {false}
                />
            </Col>
            <Col span={7}> 
                <SelectForm
                    fieldName = {[`Phone${index}`,'emergencyRelation']}
                    label = {intl.formatMessage({...messages.relation})}
                    errorMessage = {intl.formatMessage({...messages.relationError})}
                    initialValue = {relation}
                    onChange = {(value) => saveRelation(value, index)}
                    options = {options}
                    renderLabel = {false}
                />
            </Col>
            <Col span={1} className="employee-list-remove-button-padding">
                <RemoveButton removeFunction={() => remove(index)}/>
            </Col>
        </Row>
    )
}

EPhone.defaultProps = { 
    phone: {}
};
 
EPhone.propTypes = {
    form: PropTypes.object.isRequired,
    phone: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
	remove: PropTypes.func.isRequired,
	saveName: PropTypes.func.isRequired,
	saveNumber: PropTypes.func.isRequired,
	saveRelation: PropTypes.func.isRequired
};
export default EPhone
