export const STATUSES = {
	ACTIVE: 0,
	PAID: 1,
	OVERDUE: 2,
	ANNULLED: 3
};

export const AnnullmentReasons = [
	"Problema con la impresión.",
	"Problema con el cliente.",
	"Datos erróneos."
];

export const INVOICE_TYPES = {
	CASH: 1,
	CREDIT: 2
};