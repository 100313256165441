import {
    CLEAN_REDUCER,
	CLEAR_SESSION,
	FETCH_REQUEST_COUNT,
	FETCH_REQUESTS,
	ADD_REQUEST_SUCCESS,
	SET_SEARCH_REQUESTS
} from './actionTypes';

const initState = {
	requests: [],
	count: 0,
	search: "",
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case FETCH_REQUESTS:
			return {
				...state,
				requests: action.requests
			};
		case FETCH_REQUEST_COUNT:
			return {
				...state,
				count: action.count
			};
		case ADD_REQUEST_SUCCESS:
			return initState;
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		case SET_SEARCH_REQUESTS:
			return {
				...state,
				search: action.search,
			}
		default:
			return state;
	}
};

export default reducer;