import {
    CLEAN_REDUCER,
	CLEAR_SESSION,
	GET_DEBIT_NOTES,
	GET_DEBIT_NOTE,
	ADD_DEBIT_NOTE,
	EDIT_DEBIT_NOTE,
	SET_SEARCH_DEBIT,
} from './actionTypes';

const initState = {
	debitNotes: [],
	debitNote: {},
	search: "",
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case GET_DEBIT_NOTES:
			return {
				...state,
				debitNotes: action.debitNotes
			};
		case GET_DEBIT_NOTE:
			return {
				...state,
				debitNote: action.debitNote
			};

		case SET_SEARCH_DEBIT:
			return{
				...state,
				search: action.search
			}
		case ADD_DEBIT_NOTE:
		case EDIT_DEBIT_NOTE:
			return state;
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;