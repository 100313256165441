import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Col, Card, Dropdown, message, Badge, Button, Divider, Typography } from 'antd';
import { getClientMessages, getactionMessages, getFields, getErrorMessages, getPopConfirm, getTableMessages, getRegistrationMessages, getLanguageIntl } from '../../../constants/messages';
import CommonTable from '../../../components/CommonTable';
import MobileCommonTable from '../../../components/MobileCommonTable';
import { isMobile, formatDate } from '../../../utilities/util';
import ClientRadioButton from './filter';
import ViewClient from '../ViewClient/ViewModal';
import CheckExchangeRate from '../../ExchangeRate/CheckExchangeRate';
import { STATUS, validValue } from  '../constants';
import AddUpdateModal from '../AddOrUpdate/FormModal';
const { Text } = Typography;

let clientMessages = getClientMessages(),
	errorMessages = getErrorMessages(),
	AccountMessages = getactionMessages(),
	tableMessages = getTableMessages(),
	PopConfirmMessages = getPopConfirm(),
	fieldMessages = getFields(),
	RegistrationMessages = getRegistrationMessages(),
    intlMessages = getLanguageIntl();

const messages = {
	...clientMessages,
	...AccountMessages,
	...fieldMessages,
	...errorMessages,
	...tableMessages,
	...PopConfirmMessages,
	...RegistrationMessages,
    ...intlMessages
};

class Client extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			isLoading: false,
			currentClient: {},
			showAddUpdateModal: false,
			isUpdate: false,
			showViewModal: false
		};
	}

    getIntl = (str, values = {}) => this.props.intl.formatMessage({...messages[str]}, values);

	badgeCreator = (clientStatus, noText = false) => {
		const {intl} = this.props;
		let status = "warning";
		let text = intl.formatMessage({...messages.pending});
		if (clientStatus === STATUS.REJECTED) {
			status = "error";
			text = intl.formatMessage({...messages.rejected});
		}
		return (<Badge status={status} text={noText ? undefined : text}/>);
	}

	editRecord = (id) => {
		const { getClientInfo, intl } = this.props;
		this.setState({isLoading: true});
		getClientInfo(id)
		.then((client) => {
			this.setState({
				isLoading: false,
				currentClient: client,
				showAddUpdateModal: true,
				isUpdate: true
			});
		})
		.catch(() => {
			message.error(`${intl.formatMessage( {...messages.GetClientInfoError})}`);
			this.setState({isLoading: false});
		});
	};

	viewRecord = (id) => {
		const { getClientInfo, intl } = this.props;
		this.setState({isLoading: true});
		getClientInfo(id)
		.then((client) => {
			this.setState({
				isLoading: false,
				currentClient: client,
				showViewModal: true
			});
		})
		.catch(() => {
			message.error(`${intl.formatMessage( {...messages.GetClientInfoError})}`);
			this.setState({isLoading: false});
		});
	};

	getClients = (page, pageSize) => {
		const {intl, getClients} = this.props;
		this.setState({isLoading: true});
		getClients(page, pageSize)
		.then(() => {
			this.setState({
				isLoading: false
			});
		})
		.catch(() => {
			message.error(`${intl.formatMessage( {...messages.GetClientsError})}`);
			this.setState({
				isLoading: false
			});
		});
	}

	searchClients = (search, page, pageSize) => {
		const {intl, searchClients} = this.props;
		this.setState({isLoading: true});
		searchClients(search, page, pageSize)
		.then(() => {
			this.setState({
				isLoading: false
			});
		})
		.catch(() => {
			message.error(`${intl.formatMessage( {...messages.GetClientsError})}`);
			this.setState({
				isLoading: false
			});
		});
	}

	renderEditButton = (id) => {
		return (
			<span>
				<Divider type="vertical" />
				<a onClick={() => this.editRecord(id)}>
					<span className="icon-pencil" />
					<FormattedMessage {...messages.edit} />
				</a>
			</span>
		);
	}

	closeAddUpdateModal = () => {
		this.setState({
			currentClient: {},
			showAddUpdateModal: false,
			isUpdate: false
		});
	}

	closeViewModal = () => {
		this.setState({
			currentClient: {},
			showViewModal: false
		});
	}

	addClient = () => {
		this.setState({
			currentClient: {},
			showAddUpdateModal: true,
			isUpdate: false
		});
	}
	
	addButton = () => {
        const { intl } = this.props;
        return (
            <Button className="editable-add-btn" type="primary" onClick={this.addClient}>
				<PlusOutlined /> {intl.formatMessage( {...messages.add} )}
			</Button>
        );
    }

	renderClientName = (client) => {
		const { getIntl } = this;
		const { name, representative } = client;
		return (
			<div>
				<Text>{name}</Text>
				<br />&emsp;
				<Text type="secondary">{validValue(representative) ? representative : getIntl("noRepresentative")}</Text>
			</div>
		);
	}

	render(){
		const { state, props, renderClientName} = this;
		const {intl, clients, recordCount, search} = props;
		const {showAddUpdateModal, isUpdate, currentClient, showViewModal} = state;
		let columns = [
			{
				title: `${intl.formatMessage( {...messages.name})}`,
				key: 'name',
				render: (record) => renderClientName(record)
			}, {
				title: `${intl.formatMessage( {...messages.date})}`,
				dataIndex: 'date',
				render: (date) => {
					return(
						<span>
							{formatDate(date, intl.formatMessage({...messages.intl}),
										{weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}
						</span>
					);
				}
			}, {
				title: `${intl.formatMessage( {...messages.salesperson} )}`,
				dataIndex: ['salesPerson', 'name'],
				key: 'salesperson'
			},{
				title: `${intl.formatMessage( {...messages.status} )}`,
				dataIndex: 'status',
				render: (status) => this.badgeCreator(status)
			},{
				title:  `${intl.formatMessage( {...messages.actions} )}`,
				dataIndex: 'id',
				render: (id) => {
					return (
						<span>
							<a onClick={() => this.viewRecord(id)}>
								<FormattedMessage {...messages.view} />
							</a>
							{this.renderEditButton(id)}
						</span>
					);
				}
			}
		];

		const action = (record) => (
			<Dropdown menu={{ items: menu(record) }} trigger={['click']}>
				<a className="ant-dropdown-link" href="#">
					<FormattedMessage {...messages.actions} />
					<DownOutlined />
				</a>
			</Dropdown>
		);

		const menu = (record) => {
			const items = [
				{
					key: '1',
					label: (
						<a onClick={() => this.viewRecord(record.id)}>
							{intl.formatMessage( {...messages.view} )}
						</a>
					),
				},
				{
					key: '2',
					label: (
						<a onClick={() => this.editRecord(record.id)}>
							{intl.formatMessage( {...messages.edit} )}
						</a>
					),
				},
			]
			return items;
		};

		const card = (record) => {
			let title = (
					<Row align="middle">
						<Col span={14}>
							{this.badgeCreator(record.status, true)}
							{record.name}
						</Col>
					</Row>
				);
			return (

				<Row key={record.id}  type="flex" justify="space-around" align="middle">
					<Col xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:24}}>
						<Card loading={this.state.isLoading} title={title} extra={action(record)} style={{ width: "100%" }}>
							<Row  type="flex" justify="center">
								<Col span={8}>
									<strong><FormattedMessage {...messages.date} />:</strong>
								</Col>
								<Col span={12}>
									{formatDate(record.date, intl.formatMessage({...messages.intl}),
                                                            {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}
								</Col>
							</Row>
							<Row  type="flex" justify="center">
								<Col span={8}>
									<strong><FormattedMessage {...messages.salesperson} />:</strong>
								</Col>
								<Col span={12}>
									{record.salesPerson.name}
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
				);
		};
		return (
			<div className="client view">
				<CheckExchangeRate />
				<AddUpdateModal showModal={showAddUpdateModal} isUpdate={isUpdate} handleCancel={this.closeAddUpdateModal} clientInfo={currentClient}/>
				<ViewClient showModal={showViewModal} handleCancel={this.closeViewModal} clientInfo={currentClient}/>
				

				{(!isMobile() ?
					<CommonTable
						columns = {columns}
						dataSource = {clients}
						searchText= {search === "" ? undefined : search}
						search={(search, page, pageSize) => this.searchClients(search = search.status || search.status == 0 ? search : {...search, status: 1}, page, pageSize)}
						recordCount={recordCount}
						getRecords={(page, pageSize) => this.getClients(page, pageSize)}
						Add={this.addButton()}
						loading={this.state.isLoading}
						getCheckboxProps={record => ({ disabled: record.active })}
						filterComponent = {(<ClientRadioButton />)}
						hideCheckboxes
					/>
					:
					<MobileCommonTable 
						dataSource = {clients}
						Add={this.addButton()}
						search={(search, page, pageSize) => this.searchClients(search = search.status || search.status == 0 ? search : {...search, status: 1}, page, pageSize)}
						searchText= {search === "" ? undefined : search}
						recordCount={recordCount}
						loading={this.state.isLoading}
						card={card}
						getRecords={(page, pageSize) => this.getClients(page, pageSize)}
						filterComponent = {(<ClientRadioButton />)}
					/>
				)}
			</div>	
		);
	}
}

Client.propTypes = {
	intl: PropTypes.object.isRequired,
	clients: PropTypes.array,
	hasError: PropTypes.bool,
	error: PropTypes.object,
	user: PropTypes.object,
	getClients: PropTypes.func,
	searchClients: PropTypes.func,
	recordCount: PropTypes.number,
	searchPre: PropTypes.object,
	UpdateSearch:PropTypes.func,
	handleCancel: PropTypes.func,
	showEditModal: PropTypes.bool,
	handleClose: PropTypes.func,
	isMaster: PropTypes.bool,
	getClientInfo: PropTypes.func,
	selectedItemPreregistration:PropTypes.number,
	search: PropTypes.string
};

export default injectIntl(Client);