import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    message,
    Spin,
    Row,
    Col,
    Button,
    Pagination,
    Card,
    Empty,
    Collapse,
    Tooltip,
    Typography,
} from 'antd';
import { getReportMessages, getPaymentMessages, getLanguageIntl, getInvoicingMessages } from '../../../constants/messages';
import { InitialSearch, SearchWithFilter } from './actions';
import Client from '../../Jobs/Calendar/AdvancedSearch/Components/Client'
import DatePicker from '../../Jobs/Calendar/AdvancedSearch/Components/ProgrammedDate'
import MatchAllCriteria from '../../Jobs/Calendar/AdvancedSearch/Components/MatchAllCriteria';
import PaymentMethod from './SearchFields/PaymentMethod';
import PaymentType from './SearchFields/Type';
import DestinationAccount from './SearchFields/DestinationAccount';
import { TYPES, PaymentMethods } from '../../Payments/constants';
import { getNestedValue, getObjectInObject, roundN } from '../../../utilities/util';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
const { Panel } = Collapse;
const { Paragraph } = Typography;

let reportMessages = getReportMessages(),
	paymentMessages = getPaymentMessages(),
	intlMessages = getLanguageIntl(),
	invoicingMessages = getInvoicingMessages();

const messages = {
	...reportMessages,
	...paymentMessages,
	...intlMessages,
	...invoicingMessages
};

const defaultPageSize = 12;
const shouldRenderTypePicker = false;

class Report extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			currentPage: 1,
			count: 0,
			filter: {},
			payments: [],
			expandData: false
		};
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });

	searchButtonOnClick = () => {
		const { props, getIntl, setLoading } = this;
		const { validateFieldsAndScroll } = props.form;
		validateFieldsAndScroll({ force: true }, (err, values) => {
			if (!err) {
				const { type = shouldRenderTypePicker ? undefined : TYPES.PAYMENT.id, clientId, paymentDate, paymentMethod, destinationId, matchAllCriteria } = values;
				const initialFilter = { pageSize: defaultPageSize, type, clientId, paymentMethod, destinationId, matchAllCriteria };
				if (paymentDate && paymentDate[0] && paymentDate[1]) {
					initialFilter.paymentDateStart = paymentDate[0].toISOString();
					initialFilter.paymentDateEnd = paymentDate[1].toISOString();
				}
				setLoading(true);
				InitialSearch(initialFilter)
					.then((results) => {
						const { filter, count, entries } = results;
						this.setState({ filter, count, payments: entries, currentPage: 1, expandData: true, isLoading: false });
					})
					.catch(() => {
						setLoading(false);
						message.error(getIntl("paymentsSearchError"));
					});
			}
		});
	}

	pagingOnChange = (page, pageSize) => {
		const { state, getIntl, setLoading } = this;
		const filter = { ...state.filter, page, pageSize, skip: (page - 1) * pageSize };
		setLoading(true);
		SearchWithFilter(filter)
			.then(payments => this.setState({ payments, filter, currentPage: page, isLoading: false }))
			.catch(() => {
				setLoading(false);
				message.error(getIntl("paymentsSearchError"));
			});
	}

	renderClientSelect = () => {
		const { form } = this.props;
		return <Client form={form} />;
	}

	renderPaymentDatePicker = () => {
		const { getIntl, props } = this;
		const { form } = props;
		return <DatePicker form={form} label={getIntl("paymentDate")} fieldName={`paymentDate`} />;
	}

	renderTypePicker = () => {
		const { form } = this.props;
		return <PaymentType form={form} />;
	}

	renderPaymentMethodPicker = () => {
		const { form } = this.props;
		return <PaymentMethod form={form} />;
	}

	renderDestinationAccountPicker = () => {
		const { form } = this.props;
		return <DestinationAccount form={form} />;
	}

	renderMatchAllCriteria = () => {
		const { getIntl, props } = this;
		const { form } = props;
		return <MatchAllCriteria form={form} matchAllCriteriaTrue={getIntl("paymentsMatchAllCriteriaTrue")} matchAllCriteriaFalse={getIntl("paymentsMatchAllCriteriaFalse")} />;
	}

	renderSearchButton = () => {
		const { state, getIntl, searchButtonOnClick } = this;
		const { isLoading } = state;
		return [
			<Button style={{ marginBottom: "10px" }} key="search" type="primary" onClick={searchButtonOnClick} loading={isLoading} block ghost icon={<SearchOutlined />}>
				{getIntl("search")}
			</Button>
		];
	}

	renderPagination = () => {
		const { state, pagingOnChange, getIntl } = this;
		const { currentPage, count } = state;
		return (
			<Pagination
				style={{ textAlign: "right" }}
				defaultPageSize={defaultPageSize}
				current={currentPage}
				onChange={pagingOnChange}
				total={count}
				showTotal={count => getIntl("resultsCount", { count })}
			/>
		);
	}

	formatDate = (unformatedDate) => {
		if (!unformatedDate) return undefined;
		const { getIntl } = this;
		const locale = getIntl("intl");
		return moment(unformatedDate).locale(locale).format('MMMM DD, YYYY');
	}

	invoiceDocumentNumberClick = (e, invoiceId) => {
		e.preventDefault();
		this.props.history.push(`/invoices/${invoiceId}`);
	}

	buildCard = (data) => {
		const { getIntl, invoiceDocumentNumberClick, formatDate } = this;
		const { id, amount, description, paymentMethod, paymentDate, invoiceId } = data;
		const invoiceClientName = getNestedValue("invoice.clientName", data);
		const documentNumber = getNestedValue("invoice.documentNumber", data);
		const clientName = getNestedValue("invoice.client.name", data);
		const bankAccount = getNestedValue("destination.name", data);
		const title = `#${id}`;
		const linkableDocumentNumber = <a className="editable-add-btn" href={`/invoices/${invoiceId}`} onClick={(e) => invoiceDocumentNumberClick(e, invoiceId)}>{documentNumber}</a>;
		const showableAmount = amount >= 0 ? amount : amount * -1;
		const paymentMethodObject = getObjectInObject("id", paymentMethod, PaymentMethods) || {};
		const { intl: paymentMethodIntl } = paymentMethodObject;
		const buildParagraph = (headerIntl, content) => {
			let contentRender = ' - ';
			if (content !== undefined && content !== null) {
				if (typeof content === "string" && /\S/.test(content)) contentRender = content;
				else contentRender = content;
			}
			return <Tooltip title={`${getIntl(headerIntl)}: ${contentRender}`}><Paragraph style={{ marginBottom: "0px" }} ellipsis={true}>{contentRender}</Paragraph></Tooltip>;
		}
		return (
			<Card title={title} >
				{buildParagraph("Amount", `L ${roundN(showableAmount, 2)}`)}
				{linkableDocumentNumber}
				{buildParagraph("Client", clientName)}
				{buildParagraph("clientNameOnInvoice", invoiceClientName)}
				{buildParagraph("description", description)}
				{buildParagraph("DestinationAccount", bankAccount)}
				{buildParagraph("TypeOfPayment", paymentMethodIntl ? getIntl(paymentMethodIntl) : undefined)}
				{buildParagraph("paymentDate", formatDate(paymentDate))}
			</Card>
		);
	}

	renderPaymentEntries = () => {
		const { state, buildCard } = this;
		const { payments } = state;
		if (payments.length <= 0) return <Empty />;
		const cards = payments.map((data, index) => <Col key={index} sm={24} md={12} lg={12} xl={6} >{buildCard(data)}</Col>);
		return <Row style={{ marginBottom: "10px" }} gutter={[16, 16]}>{cards}</Row>;
	}

	fullRender = () => {
		const { state, renderSearchButton, renderClientSelect, renderPaymentDatePicker, renderMatchAllCriteria, renderPaymentMethodPicker, renderTypePicker, renderDestinationAccountPicker, renderPaymentEntries, renderPagination } = this;
		const { isLoading, expandData } = state;
		return (
			<Spin spinning={isLoading}>
				<div className="view">
					<Row gutter={24}>
						<Col sm={24} md={12} lg={8} xl={6}>{renderClientSelect()}</Col>
						<Col sm={24} md={12} lg={8} xl={6}>{renderPaymentDatePicker()}</Col>
						{shouldRenderTypePicker ? <Col sm={24} md={12} lg={8} xl={6}>{renderTypePicker()}</Col> : null}
						<Col sm={24} md={12} lg={8} xl={6}>{renderPaymentMethodPicker()}</Col>
						<Col sm={24} md={12} lg={8} xl={6}>{renderDestinationAccountPicker()}</Col>
						<Col sm={24} md={12} lg={8} xl={6}>{renderMatchAllCriteria()}</Col>
					</Row>
					{renderSearchButton()}
					<Collapse className="job-view-reconnect-collapse" bordered={false} activeKey={expandData ? 1 : 0}>
						<Panel key={1} showArrow={false}>
							{renderPaymentEntries()}
							{renderPagination()}
						</Panel>
					</Collapse>
				</div>
			</Spin>
		);
	}

	render() {
		return this.fullRender();
	}
}

Report.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(injectIntl(Form.create()(Report)));