import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {getFields, getErrorMessages, getAuthMessages} from '../constants/messages';
import {SimpleChangePassword} from '../actions/AccountAction';
import { CheckOutlined, LockOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Col, Spin } from 'antd';
import {REGEX} from '../constants/global';
const FormItem = Form.Item;

let fieldsMessages = getFields(),
	authMessages = getAuthMessages(),
	errorMessages = getErrorMessages();

let messages = {
	...fieldsMessages,
	...authMessages,
	...errorMessages
};


const ChangeProfilePassword = ({intl, form, changePassword, loading}) => {

	const {getFieldDecorator} = form;

	const handleSubmit = (e) => {
		e.preventDefault();
		form.validateFields((err, values) => {
			if (!err) {
				changePassword(values);
				form.resetFields();
			}
		});
	};

	const checkPassword = (rule, value, callback) => {

		if (value && value !== form.getFieldValue('password')) {
			callback(`${intl.formatMessage( {...messages.confirmPassword} )}` );
		} else {
			callback();
		}
	};

	return (
        <Row className="login">
			<Spin spinning={loading}>
				<Col span={24}>
					<Form className="login-form col-8" layout='vertical'>
						<FormItem
							label={intl.formatMessage( {...messages.oldPassword} )}
						>
						{
							getFieldDecorator('oldPassword', {
									rules: [{
										required: true,
										message: intl.formatMessage( {...messages.passwordEmptyError}),
									}],
								})(
									<Input prefix={<LockOutlined style={{fontSize: 13}} />} type="password" placeholder={intl.formatMessage( {...messages.oldPasswordPlaceholder} )} />
								)
						}
						</FormItem>
						<FormItem
							label={intl.formatMessage( {...messages.newPassword} )}
							extra={intl.formatMessage( {...messages.passwordNorify} )} 
						>
						{
							getFieldDecorator('password', {
								rules: [
									{
										required: true,
										whitespace: true,
										message: intl.formatMessage( {...messages.passwordEmptyError})
									}, {
										pattern: REGEX.password,
										message: intl.formatMessage( {...messages.passwordInvalidError})
									}, {
										min: 6,
										message: intl.formatMessage( {...messages.passwordLengthError})
								}],
								})(
									<Input prefix={<LockOutlined style={{fontSize: 13}} />} type="password" placeholder={intl.formatMessage( {...messages.newPasswordPlaceholder} )} />
								)
						}
						</FormItem>
						<FormItem
							label={intl.formatMessage( {...messages.passwordConfirm} )} 
						>
						{
							getFieldDecorator('confirm', {
									rules: [{
										required: true,
										message: intl.formatMessage( {...messages.confirmPassword})
									}, {
										validator: checkPassword,
									}],
								})( 
									<Input prefix={<LockOutlined style={{fontSize: 13}} />} type="password" placeholder={intl.formatMessage( {...messages.passwordConfirmPlaceholder} )} />
								)
						} 
						</FormItem>
						<FormItem>
							<Row className="editProfileAction" type="flex" justify="center"  align="bottom">
								<Col span={4}>
									<Button type="primary" shape="circle" icon={<CheckOutlined />} onClick={handleSubmit} />
								</Col>
							</Row>
						</FormItem>
					</Form>
				</Col>
			</Spin>
		</Row>
    );
};

ChangeProfilePassword.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	changePassword: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
	return {
		"loading": state.profile.loading
	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		changePassword: (values) => {
			dispatch(SimpleChangePassword(values));
		},
	};
};

export default  connect(mapStateToProps, mapDispatchToProps)(Form.create()(injectIntl(ChangeProfilePassword)));
