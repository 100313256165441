import React from 'react'
import PropTypes from 'prop-types';
import { Modal, Descriptions, Col, List, Typography, Button } from 'antd';
import { getRequestMessages, getClientMessages, getactionMessages, getFields, getModalMessages } from '../../constants/messages';
import { FormattedMessage, useIntl } from 'react-intl';

let moment = require('moment');

let requestMessages = getRequestMessages(),
    clientMessages = getClientMessages(),
    fieldMessages = getFields(),
    actionMessages = getactionMessages(),
    modalMessages = getModalMessages();

const messages = {
    ...requestMessages,
    ...clientMessages,
    ...fieldMessages,
    ...actionMessages,
    ...modalMessages
};

const { Text } = Typography;

const ViewRequestModal = ({ request, openModal, handleCancel }) => {
    const intl = useIntl();
    const { contact: { name, email, cellphone }, client, createdAt, bought, leased, observations, vehicles, createdBy } = request;
    const vehiclesParse = JSON.parse(vehicles);

    return (
        <Modal 
            title={intl.formatMessage({ ...messages.viewRequest })}
            open={openModal}
            onCancel={handleCancel}
			footer={[
                <Button key="close" onClick={handleCancel}>
                    <FormattedMessage {...messages.close} />
                </Button>,
            ]}
        >
            <Descriptions layout="vertical" size="small" bordered column={1}>
                <Descriptions.Item label={intl.formatMessage({ ...messages.request })}>
                    <Col span={24}>
                        <Text strong>{`${intl.formatMessage({ ...messages.client })}: `}</Text> 
                        <Text >{client.name}</Text>
                    </Col>
                    <Col span={24}>
                        <Text strong>{`${intl.formatMessage({ ...messages.boughtGPSs })}: `}</Text> 
                        <Text >{bought}</Text>
                    </Col>
                    <Col span={24}>
                        <Text strong>{`${intl.formatMessage({ ...messages.leasedGPSs })}: `}</Text> 
                        <Text >{leased}</Text> 
                    </Col>
                    <Col span={24}>
                        <Text strong>{`${intl.formatMessage({ ...messages.requestedBy })}: `}</Text> 
                        <Text >{createdBy.name}</Text> 
                    </Col>
                    <Col span={24}>
                        <Text strong>{`${intl.formatMessage({ ...messages.dateRequested })}: `}</Text> 
                        <Text >{(moment(createdAt).locale('es').format('dddd, LL'))}</Text> 
                    </Col>
                </Descriptions.Item>
                <Descriptions.Item label={intl.formatMessage({ ...messages.contactName })}>
                    <Col span={24}>
                        <Text strong>{`${intl.formatMessage({ ...messages.name })}: `}</Text> 
                        <Text >{name}</Text> 
                    </Col>
                    <Col span={24}>
                        <Text strong>{`${intl.formatMessage({ ...messages.clientTelephone })}: `}</Text> 
                        <Text >{cellphone}</Text> 
                    </Col>
                    <Col span={24}>
                        <Text strong>{`${intl.formatMessage({ ...messages.email })}: `}</Text> 
                        <Text >{email ? email : ''}</Text> 
                    </Col>
                </Descriptions.Item>
                <Descriptions.Item label={intl.formatMessage({ ...messages.details })}>
                    <List
                        itemLayout="vertical"
                        dataSource={vehiclesParse}
                        renderItem={(vehicle) => (
                            <List.Item>
                                 <Col span={24}>
                                    <Text strong>{`${intl.formatMessage({ ...messages.platePlaceholder })}: `}</Text> 
                                    <Text >{vehicle.plate}</Text> 
                                 </Col>
                                 <Col span={24}>
                                    <Text strong>{`${intl.formatMessage({ ...messages.brandPlaceholder })}: `}</Text> 
                                    <Text >{vehicle.brand}</Text> 
                                 </Col>
                                 <Col span={24}>
                                    <Text strong>{`${intl.formatMessage({ ...messages.modelPlaceholder })}: `}</Text> 
                                    <Text >{vehicle.model}</Text> 
                                 </Col>
                            </List.Item>
                        )}
                    />
                </Descriptions.Item>
                <Descriptions.Item label={intl.formatMessage({ ...messages.observations })}>
                    <Col span={24}>
                        <Text >{observations ? observations : ''}</Text> 
                    </Col>
                </Descriptions.Item>
            </Descriptions>
        </Modal>
    );
};


ViewRequestModal.propTypes = {
    request: PropTypes.object.isRequired,
    openModal: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
};

export default ViewRequestModal;
