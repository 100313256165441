import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl';
import { Badge, message, Popconfirm, Typography } from 'antd';
import { getactionMessages, getErrorMessages, getFields, getInvoicingMessages, getPopConfirm } from '../../../constants/messages';
import CommonTable from '../../../components/CommonTable';
import { STATUSES } from '../../Invoices/constants';
import { roundN } from '../../../utilities/util';
import AddButton from './AddButton';
import { DeleteInvoicesGuatemala, GetCountInvoicesGuatemala, GetInvoicesGuatemala, PayInvoiceGuatemala, PendingInvoiceGuatemala } from './actions';
import { GetGuatemalaClientsUser } from '../Clients/actions';
import AddOrEditModal from './AddOrEditModal';
import moment from 'moment';
import DateFilter from './DateFilter';
import { SET_SEARCH_INVOICES } from '../actionTypes'
import { useDispatch, useSelector } from 'react-redux';

let actionMessages = getactionMessages(),
    invoicingMessages = getInvoicingMessages(),
    FieldsMessages = getFields(),
    popConfirmMessages = getPopConfirm(),
    errorMessages = getErrorMessages();

const messages = {
    ...popConfirmMessages,
    ...FieldsMessages,
	...invoicingMessages,
    ...actionMessages,
    ...errorMessages,
};

const index = () => {
    const intl = useIntl();
    const [loading, setLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [guatemalaInvoices, setGuatemalaInvoices] = useState([]);
	const [invoicesCount, setInvoicesCount] = useState(0);
	const [clients, setClients] = useState([]);
	const [recordToEdit, setRecordToEdit] = useState({});
    const searchText = useSelector((state) => state.optimusGuatemala.searchInvoices);

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const guatemalaClients = await GetGuatemalaClientsUser();
                setClients(guatemalaClients);
                await doPaging({searchText},1,10);
            } catch (error) {
                message.error(intl.formatMessage({ ...messages.GetInvoicesError }));
            }
        };
        fetchData();
    }, [])

    const updateTable = (invoice) => {
		const invoiceExists = guatemalaInvoices.find((ig) => ig.id === invoice.id);
		let invoices;
		if (invoiceExists) invoices = guatemalaInvoices.map((ig) => (invoice.id === ig.id ? invoice : ig));
		else invoices = [...guatemalaInvoices, invoice];
        setGuatemalaInvoices(invoices)
	};

	const doPaging = async (search, page = 1, pageSize = 10) => {
		try {
            setLoading(true);
            const count = await GetCountInvoicesGuatemala(search);
            setInvoicesCount(count);
			const data = await GetInvoicesGuatemala(search, page, pageSize);
			setGuatemalaInvoices(data);
            if(search !== undefined){
                dispatch({type: SET_SEARCH_INVOICES, searchInvoices: search.searchText});
            }
        
		} catch (error) {
            message.error(intl.formatMessage({ ...messages.searchInvoicesError }));
		} finally {
			setLoading(false);
		}
	};

    const editRecord = (record) => {
		setRecordToEdit(record);
		setShowModal(true);
	};

    const changeStatusInvoice = async (record) => {
        try {
            setLoading(true);
            const invoice = record.status ? await PendingInvoiceGuatemala(record.id) : await PayInvoiceGuatemala(record.id);
            updateTable(invoice);
        } catch (error) {
            message.error(intl.formatMessage({ ...messages.ChangeInvoiceError }));
        } finally {
            setLoading(false);
        }
    };

    const extraAction = (record) => {
        if(record.status != 0) return;
        return ( 
            <span>
                <span> | </span>
                <Typography.Link
                    onClick={() => editRecord(record)}
                >
                    <span className="icon-pencil" />
                    <FormattedMessage {...messages.edit} />
                </Typography.Link>
                <span> | </span>
                <Popconfirm
                    title={intl.formatMessage({...messages.deleteConfirm})}
                    onConfirm={() => {
                        deleteInvoiceGuatemala([record.id]);
                    }}
                    okText={intl.formatMessage({...messages.yes})}
                    cancelText={intl.formatMessage({...messages.no})}
                >
                    <Typography.Link>
                        <span className="icon-bin" />
                        <FormattedMessage {...messages.delete} />
                    </Typography.Link>
                </Popconfirm>
            </span>
        )
    }
    
    const tableActions = (record) => {
        return (
            <span>
                 <Typography.Link
                    onClick={() => changeStatusInvoice(record)}
                >
                    <span className="icon-check" />
                    { record.status 
                        ? <FormattedMessage {...messages.changeToPending} /> 
                        : <FormattedMessage {...messages.changeToPay} />
                    }
                </Typography.Link>
                {extraAction(record)}
            </span>
        );
    };

    const deleteInvoiceGuatemala = (selectedInvoiceGuatemala) => {
		try {
			DeleteInvoicesGuatemala(selectedInvoiceGuatemala).then(() => {
				message.success(intl.formatMessage({...messages.deleteInvoiceSuccess}));
				setGuatemalaInvoices((ig) =>
                    ig.filter((invoice) => !selectedInvoiceGuatemala.includes(invoice.id))
				);
			});
		} catch (error) {
			message.error(intl.formatMessage({...messages.commonError}));
		}
		return [];
	};

    const columns = [
        {
            title: intl.formatMessage({ ...messages.InvoiceNumber }),
            dataIndex: 'documentNumber',
            key: 'documentNumber',
        },
        {
            title: intl.formatMessage({ ...messages.Client }),
            dataIndex: 'clientName',
            key: 'clientName',
            render: (clientName, record) => {
                return record.client ? record.client.name : clientName;
            }
        },
        {
            title: intl.formatMessage({ ...messages.Date }),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date) => {
                return (moment(date).format('L'));
            }
        },
        {
            title: intl.formatMessage({ ...messages.Status }),
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                let color = 'default';
                let text = intl.formatMessage({ ...messages.Active });
                if (status === STATUSES.PAID) {
                    color = 'success';
                    text = intl.formatMessage({ ...messages.Paid });
                }
                return (
                    <Badge status={color} text={text} />
                );
            }
        },
        {
            title: intl.formatMessage({ ...messages.Total }),
            dataIndex: 'total',
            key: 'total',
            render: (total, record) => {
                return record.isDollar ? `$ ${roundN(total, 2)}` : `Q ${roundN(total, 2)}`
            }
        },
        {
            title: intl.formatMessage({ ...messages.actions}),
            dataIndex: 'id',
            render: (id, record) => tableActions(record),
        },
    ];

    return (
        <div className="account view">
            <CommonTable
                columns={columns}
                Add={<AddButton clients={clients} updateTable={updateTable}/>}
                dataSource={guatemalaInvoices}
                recordCount={invoicesCount}
                loading={loading}
                delete={deleteInvoiceGuatemala}
                search={(search, page, pageSize) => doPaging(search, page, pageSize)}
                searchText= {searchText === "" ? undefined : searchText}
                getCheckboxProps={record => ({ disabled: record.status })}
                filterComponent={(<DateFilter />)}
                getRecords={(page, pageSize) => doPaging({}, page, pageSize)}
            />
            <AddOrEditModal
                title={intl.formatMessage({ ...messages.edit})}
                showModal={showModal}
                record={recordToEdit}
                clients={clients}
                onCancel={() => setShowModal(false)}
                updateTable={updateTable}
            />
        </div>
    )
}

export default index;