import PropTypes from 'prop-types';
import { Col, message, Spin, List, Divider } from 'antd';
import { connect } from "react-redux";
import { injectIntl } from 'react-intl';
import React from 'react';
import { getReportMessages, getIncomeMessages, getLanguageIntl } from '../../../constants/messages';
import EntryCard from './EntryCard';
import InfiniteScroll from 'react-infinite-scroller';
import { GetAccountEntries } from '../actions';

var moment = require('moment');
let reportMessages = getReportMessages(),
    incomeMessages = getIncomeMessages(),
    intlMessages = getLanguageIntl();

const messages = {
    ...reportMessages,
    ...incomeMessages,
    ...intlMessages
};

class EntryList extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    getIntl = (str) => {
        return this.props.intl.formatMessage({ ...messages[str] });
    }


    handleInfiniteOnLoad = () => {
        if (!this.areThereMoreEntries()) {
            message.warning(this.props.intl.formatMessage({ ...messages.noMoreEntries }));
            this.props.setHasMore(false);
            return;
        }
        this.props.setLoading(true);
        this.props.getAccountEntries(this.props.selectedBank, this.props.pageIndex + 1).then(() => {
            this.props.setLoading(false);
        });

    }

    areThereMoreEntries() {
        return this.props.entries.length < this.props.entriesCount;
    }

    isDiffMonth = (entryIndex) => {
        if (entryIndex === 0)
            return true;
        let entry = this.props.entries[entryIndex].date;
        let lastEntry = this.props.entries[entryIndex - 1].date;
        return moment(lastEntry).isAfter(moment(entry), 'month');
    }

    getDivider = (index) => {
        if (this.isDiffMonth(index))
            return (
                <Divider dashed>
                    {new Date(this.props.entries[index].date).toLocaleDateString(this.getIntl("intl"), { month: 'long' })}
                </Divider>
            )
        return (<div></div>)
    }


    render() {
        return (
            <Col xs={23} sm={16} md={16} lg={10} >
                <Spin spinning={this.props.loading}>
                    <div className="entryList">
                        <InfiniteScroll
                            initialLoad={false}
                            loadMore={this.handleInfiniteOnLoad}
                            hasMore={!this.props.loading && this.props.hasMore}
                            useWindow={false}
                        >
                            <List
                                dataSource={this.props.entries}
                                renderItem={(item, index) => {
                                    let getDivider = this.getDivider(index);
                                    return (
                                        <Col span={24} >
                                            {getDivider}
                                            <EntryCard
                                                entry={item}
                                                loading={this.props.loading}
                                                currencyDisplayer={this.props.currencyDisplayer}
                                                openEditModal={this.props.openEditModal}
                                                index={index}
                                            />
                                        </Col>
                                    )
                                }}
                            />
                        </InfiniteScroll>
                    </div>
                </Spin>
            </Col>
        );
    }
}

EntryList.propTypes = {
    intl: PropTypes.object.isRequired,
    entries: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    hasMore: PropTypes.bool.isRequired,
    entriesCount: PropTypes.number,
    selectedBank: PropTypes.number,
    currencyDisplayer: PropTypes.object,
    pageIndex: PropTypes.number.isRequired,
    setLoading: PropTypes.func.isRequired,
    setHasMore: PropTypes.func.isRequired,
    openEditModal: PropTypes.func.isRequired,
    getAccountEntries: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        entries: state.incomeReport.entries.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
        }),
        entriesCount: state.incomeReport.entriesCount,
        pageIndex: state.incomeReport.pageIndex
    };
}

const mapDispatchToProps = (dispatch) => ({
    getAccountEntries: (id, page) => dispatch(GetAccountEntries(id, page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(EntryList));

