import React from 'react'
import { Tabs } from 'antd';
import { GroupOutlined, UngroupOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { getReportMessages } from '../../../constants/messages';
import MonthlyServicePricePage from './MonthlyService/MonthlyServicePricePage';
import LeasedServicePricePage from './LeasedService/LeasedServicePricePage';

let reportMessages = getReportMessages();

const messages = {
	...reportMessages,
}

const CustomPriceReportPage = () => {
    const intl = useIntl();

    const items = [
        {
            key: '1',
            label: (
                <span>
                  <GroupOutlined />
                  {intl.formatMessage({...messages.monthlyService})}
                </span>
            ),
            children: <MonthlyServicePricePage />
        },
        {
            key: '2',
            label: (
                <span>
                  <UngroupOutlined />
                  {intl.formatMessage({...messages.leasedService})}
                </span>
            ),
            children: <LeasedServicePricePage />
        },
    ];

    return (
        <div className="view">
            <Tabs
                defaultActiveKey="1"
                items={items}
                type="card"
            />
        </div>
    )
}

export default CustomPriceReportPage
