import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker } from 'antd';
import { injectIntl } from 'react-intl';
import { getInvoicingMessages, getClientMessages } from '../../constants/messages';
const { MonthPicker } = DatePicker;
const FormItem = Form.Item;
const moment = require('moment');
const monthDateFormat = "MMMM-YYYY";

let invoicingMessages = getInvoicingMessages(),
	clientMessages = getClientMessages();

const messages = {
	...invoicingMessages,
	...clientMessages
};

class InvoiceMonthChange extends React.Component  {

    getIntl = (str, values = {}) => this.props.intl.formatMessage({...messages[str]}, values);

    disabledDate = (current) => {
        return moment(current).endOf('month') < moment().startOf('day');
    }

	renderInvoiceDatePicker = () => {
		const { props, getIntl, disabledDate } = this;
		const { form, value, onChange } = props;
		const { getFieldDecorator } = form;
		const dateMoment = moment(value);
        return (
			<FormItem label={getIntl("nextInvoiceMonth")}>
			{
				getFieldDecorator(`nextInvoiceMonth`,
				{
					initialValue: dateMoment.isValid() ? dateMoment : null,
					onChange
				}
				)(
                    <MonthPicker
						format={monthDateFormat}
						disabledDate={disabledDate}
						allowClear={false}
					/>
				)
			}
			</FormItem>
        );
	}

	renderContent = () => {
		const { renderMonthStatus, renderInvoiceDatePicker } = this;
		return (
			<div>
				{renderMonthStatus()}
				{renderInvoiceDatePicker()}
			</div>
		);
	}

	render(){
		return this.renderInvoiceDatePicker();
	}
}

InvoiceMonthChange.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.object,
        PropTypes.string
	]).isRequired,
	onChange: PropTypes.func.isRequired
};

export default injectIntl(InvoiceMonthChange);