import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, message, Spin } from 'antd';
import { getCommissionMessages } from '../../constants/messages';
import { LoadActiveBankAccounts } from '../Payments/actions';

const FormItem = Form.Item;
const { Option } = Select;

let commissionMessages = getCommissionMessages();

const messages = {
	...commissionMessages
};

class BankAccountPicker extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			bankAccounts: []
		};
	}

	componentDidMount() {
		const { getIntl, setLoading } = this;
		setLoading(true);
		LoadActiveBankAccounts()
			.then(bankAccounts => this.setState({ bankAccounts, isLoading: false }))
			.catch(() => {
				setLoading(false);
				message.error(getIntl("loadAccountsError"));
			});
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });

	render() {
		const { state, props, getIntl } = this;
		const { isLoading, bankAccounts } = state;
		const { form, bankAccount = {} } = props;
		const { getFieldDecorator } = form;
		const { id: bankId, name: bankName } = bankAccount;
		const options = [];
		if (bankId) options.push(<Option key={bankId} value={bankId}>{bankName}</Option>);
		bankAccounts.forEach(({ id: accountId, name }) => {
			if (accountId !== bankId) options.push(<Option key={accountId} value={accountId}>{name}</Option>);
		});
		return (
			<Spin spinning={isLoading}>
				<FormItem label={getIntl("sourceAccount")}>
					{
						getFieldDecorator('bankAccountId',
							{
								initialValue: bankId,
								rules: [{
									required: true,
									message: getIntl("sourceAccountRequiredError"),
								}]
							}
						)(
							<Select
								className="job-full-component"
								allowClear={false}
							>
								{options}
							</Select>
						)
					}
				</FormItem>
			</Spin>
		);
	}
}

BankAccountPicker.defaultProps = {
	bankAccount: {}
};

BankAccountPicker.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	bankAccount: PropTypes.object
};

export default injectIntl(BankAccountPicker);