import axios from 'axios';

export const CheckUsernameExistence = (username = "") => () => {
    const where = { username };
    return axios.get(`/OptimusAccounts/count?where=${encodeURIComponent(JSON.stringify(where))}`)
    .then(response => response.data.count);
};

export const CheckEmailExistance = (email = "") => () => {
    const where = { email };
    return axios.get(`/OptimusAccounts/count?where=${encodeURIComponent(JSON.stringify(where))}`)
    .then(response => response.data.count);
};

export const CheckClientEmailExistance = (email = "", clientId = -1) => {
    const where = {
		email,
		clientId: { neq: clientId }
	};
    return axios.get(`/OptimusAccounts/count?where=${encodeURIComponent(JSON.stringify(where))}`)
    .then(response => response.data.count);
};