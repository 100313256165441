import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Radio } from 'antd';
import { getJobMessages } from '../../../../constants/messages';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

let jobMessages = getJobMessages();

const messages = {
	...jobMessages
};

class ChargeType extends React.Component {

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    onChange = (event) => {
        const { props } = this;
        const { onChange } = props;
        if ('onChange' in props) onChange(event.target.value);
    }
    
	render() {
        const { props, getIntl, onChange } = this;
        const { form, disabled, chargeType, options } = props;
        const { getFieldDecorator } = form;
        return (
            <FormItem label={getIntl("chargeType")} >
            {
                getFieldDecorator(`chargeType`, 
                    {
                        initialValue: chargeType,
                        rules: [{
                            required: true,
                            message: getIntl("chargeTypeError"), 
                        }],
                        onChange: onChange
                    }
                )(
                    <RadioGroup disabled={disabled}>
                        {options}
                    </RadioGroup>
                )
            }
            </FormItem>
        );
    }
}

ChargeType.defaultProps = {
    disabled: false,
    options: []
};

ChargeType.propTypes = {
	intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    chargeType: PropTypes.number,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    options: PropTypes.array.isRequired
};

export default injectIntl(ChargeType);