import React from 'react';
import Icon from '@ant-design/icons';

const JammingSvg = () => (
	<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
		width="1.5em" height="1.5em" viewBox="0 0 24.000000 24.000000"
		preserveAspectRatio="xMidYMid meet">

		<g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
			fill="#000000" stroke="none">
			<path d="M70 197 c0 -18 -5 -38 -11 -44 -5 -5 -9 -38 -7 -74 l3 -64 65 0 65 0
6 50 c5 34 2 60 -7 79 -8 16 -14 37 -14 48 0 10 -4 18 -10 18 -5 0 -10 -11
-10 -25 0 -14 -4 -25 -10 -25 -5 0 -10 14 -10 30 0 17 -4 30 -10 30 -5 0 -10
-13 -10 -30 0 -16 -4 -30 -10 -30 -5 0 -10 16 -10 35 0 19 -4 35 -10 35 -5 0
-10 -15 -10 -33z m100 -112 l0 -55 -50 0 -50 0 0 55 0 55 50 0 50 0 0 -55z"/>
			<path d="M90 110 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
			<path d="M90 70 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0 -10
-4 -10 -10z"/>
		</g>
	</svg>
);

const JammingIconBuilder = props => <Icon component={JammingSvg} {...props} />;

class JammingIcon extends React.Component {
	render() {
		return <JammingIconBuilder />;
	}
}

export default JammingIcon;