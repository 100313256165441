import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { List, Row, Col, Modal, Avatar, Divider, Tooltip  } from 'antd';
import { useIntl } from 'react-intl';
import { getEmployeeMessages, getDepartmentMessages, getModalMessages, getLanguageIntl, getPayrollMessages, getFields } from '../../../constants/messages';
import {getNestedValue} from '../../../utilities/util';
import {getBloodType, getGender, getMaritalStatus, formatPrice} from '../constants';
import moment from 'moment';
import Embolden from '../../GlobalComponents/Embolden';

let employeeMessages = getEmployeeMessages(),
	modalMessages = getModalMessages(),
    departmentMessages = getDepartmentMessages(),
    intlMessages = getLanguageIntl(),
    fieldMessages = getFields(),
	payrollMessages = getPayrollMessages();

const messages = {
    ...fieldMessages,
	...employeeMessages,
	...departmentMessages,
    ...modalMessages,
    ...intlMessages,
    ...payrollMessages
};


const Render = ({employee}) => {
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const intl = useIntl()
    const getIntl = (str) => {
		return intl.formatMessage({...messages[str]});
    }
    const embolden = (header, value, type = undefined, strong = false) => {
        const headerText = getIntl(header);
        return <Embolden header={headerText} value={value} row type={type} strong = {strong}/>;
    }
    const dateRender = (date) => {
		if (!date) return;
		const m = moment(date).utc();
        return new Date(m.year(), m.month(), m.date()).toLocaleDateString(getIntl("intl"));  
    }
    const titleDependants = <b>{getIntl("dependants")}</b>;
    const dataDependants = employee.dependants.map((dependant, index) => {
        const { name, relation } = dependant;
        return(
            <div key={index}>{embolden("name", name)}{embolden("relation", relation)}</div>
        );
    });
    moment.locale(getIntl("intl"));
    moment.relativeTimeThreshold('M', 12);
    const titleDeductions = <b>{getIntl("deductions")}</b>;
    const dataDeductions = employee.deductions.map((ded, index) => {
        const { deduction, amount, initialDate, finalDate, monthlyRecurrence, comment } = ded;
        return(
            <div key={index}>
                {embolden("deduction", deduction.name)}
                {embolden("amount", formatPrice(amount))}
                {embolden("initialDate", dateRender(initialDate))}
                {embolden("finalDate", finalDate ? dateRender(finalDate) : getIntl("noFinalDate"))}
                {embolden("recurrence", monthlyRecurrence ? getIntl("monthly") : getIntl("biweekly"))}
                {comment ? embolden("comment", comment) : null}
            </div>
        );
    });
    
    const renderPersonalInfo = () => {
        const { name, identification, phone, address, birthdate, bloodType, idUrl, licenseUrl, gender, maritalStatus, 
            licenseExpirationDate } = employee;
        const createAvatar = (photo, title) => {
            return ( photo ?
            <Tooltip title={title}>
                <Avatar 
                    className="employee-view-picture"
                    shape="square"
                    src={photo}
                    size = "large"
                    onClick={() => {setPreviewImage(photo); setPreviewVisible(true)}}
                />
            </Tooltip> : null
            );
        };
        return (
            <div>
                <Divider>{getIntl("personalInformation")}</Divider>
                <Row>
                    <Col span={22}>
                        {embolden("name", name)}
                        {embolden("identification", identification)}
                        {embolden("phone", phone)}
                        {embolden("address", address)}
                        {embolden("birthdate", dateRender(birthdate))}
                        {embolden("bloodType", getBloodType(bloodType).symbol)}
                        {embolden("gender", getIntl(getGender(gender).intl))}
                        {embolden("maritalStatus", getIntl(getMaritalStatus(maritalStatus).intl))}
                        {licenseExpirationDate ? 
                        (moment() > moment(licenseExpirationDate) && licenseExpirationDate !== null ? 
                            embolden("licenseExpirationDate", dateRender(licenseExpirationDate), "danger", true):embolden("licenseExpirationDate", dateRender(licenseExpirationDate))
                        ) : null}
                    </Col>
                    <Col span={2}>
                        <div>{createAvatar(idUrl, getIntl("identification"))}</div>
                        <div>{createAvatar(licenseUrl, getIntl("license"))}</div>
                    </Col>
                </Row>
            </div>
        );
    }

    const calculateAntiquity = (hireDate) => {
        const humanity = [];
        let hiredDate = moment(hireDate);
        const humanize = (number, measurement) => moment.duration(number, measurement).humanize();
        const diff = (measurement, addition, additionMeasurement) => {
            if (addition && additionMeasurement) hiredDate.add(addition, additionMeasurement);
            return moment().diff(hiredDate, measurement);
        }
        const years = diff("years");
        const months = diff("months", years, "years");
        if (years > 0) humanity.push(humanize(years, "years"))
        if (months > 0) humanity.push(humanize(months, "months"))
        if (humanity.length > 0) return humanity.join(", ");
        const days = diff("days", months, "months");
        if (days > 0) return humanize(days, "days");
        return moment(hireDate).fromNow(true);
    }


    const renderWorkerInfo = () => {
        const { workRole, bankAccount, hireDate, salary, insurancePolicy, insuranceCertificate } = employee;
        return (
            <div>
                <Divider>{getIntl("workInformation")}</Divider>
                {embolden("department", getNestedValue("department.name", workRole))}
                {embolden("workRole", getNestedValue("name", workRole))}
                {embolden("bankAccount", bankAccount)}
                {embolden("hireDate", dateRender(hireDate))}
                {embolden("antiquity", calculateAntiquity(hireDate))}
                {embolden("salary", `L ${salary}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','))}
                {embolden("insurancePolicy", insurancePolicy)}
                {embolden("insuranceCertificate", insuranceCertificate)}
            </div>
        );
    }

    const renderList = (title, data) => {
        return (
            <List
                className="employee-scrollable-list"
                bordered={true}
                header={title}
                dataSource={data}
                renderItem={item => <List.Item>{item}</List.Item>}
            />
        );
    }
  return (
    <div>
        {renderPersonalInfo()}
        {renderWorkerInfo()}
        {renderList(titleDependants, dataDependants)}
        {renderList(titleDeductions, dataDeductions)}

        <Modal open={previewVisible} footer={null} onCancel={() => {setPreviewVisible(false)}}>
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>

    </div>
  )
}

Render.defaultProps = {
	employee: {}
};

Render.propTypes = {
	employee: PropTypes.object
};
export default Render;
