import { message, notification } from 'antd';
import mqtt from 'mqtt';
import React from 'react';
import RabbitReconnectMessage from '../components/RabbitReconnectMessage';
import { RABBIT_CONECTED, RABBIT_DISCONECTED, RABBIT_MAIN_MESSAGE, RABBIT_MESSAGE } from '../constants/actionTypes';
import { ENVIRONMENT } from '../constants/global';
import Url from 'url'

notification.config({
	top: 75,
});
export const ConnectToRabbit = (retry = true, retryCount = 1) => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;
	var mqtt_url = Url.parse(process.env.REACT_APP_RABBIT_URL || 'ws://localhost:15675');
	var auth = (mqtt_url.auth || ':').split(':');
	var url = "mqtt://" + mqtt_url.host;
	const clientId = `web-app-ironhide.${new Date().getTime()}.${Math.random()
		.toString(16)
		.substring(2, 4)}.${ENVIRONMENT}.`;
	var options = {
		port: mqtt_url.port, //443
		username: auth[0],
		password: auth[1],
		protocol: mqtt_url.protocol, //wss
		path: mqtt_url.path, ///ws/mqtt
		clientId,
	};
	let client = mqtt.connect(url, options);
	window.client = client;

	client.on('connect', function () {
		dispatch({ type: RABBIT_CONECTED });
	});

	client.on('error', function (err) {
		dispatch(OnError(err, retry, retryCount, dispatch, messages))
	});

	client.convertTopic = function (topic) {
		if (topic == "#") return new RegExp('^.+$')
		/* eslint-disable-next-line */
		return new RegExp('^' + topic.replace(/\+/g, '[^\/]+') + '$');// eslint-disable-next-line
	};

	client.messages = {
		topics: [],
		end: () => {
			client.messages.topics.length = 0;
			client.end()
		},
		count: function (topic) {
			topic = `${topic}.${ENVIRONMENT}`;
			if (!(topic in client.messages.topics)) return 0
			return client.messages.topics[topic].length;
		},
		publish: (topic, message) => {
			topic = `${topic}.${ENVIRONMENT}`;
			client.publish(topic, message);
		},
		subscribe: function (topic, callback) {
			topic = `${topic}.${ENVIRONMENT}`;
			if (!(topic in client.messages.topics)) client.messages.topics[topic] = [];

			if (client.messages.count(topic) === 0) {
				client.subscribe(topic);
			}

			client.messages.topics[topic].push(callback);

			return client;
		},
		unsubscribe: function (topic, callback) {
			topic = `${topic}.${ENVIRONMENT}`;
			if (topic in client.messages.topics) {

				var index = client.messages.topics[topic].indexOf(callback);
				if (index > -1) {
					client.messages.topics[topic].splice(index, 1);
					if (client.messages.count(topic) === 0) client.unsubscribe(topic)
				}

			}

			return client;
		},
		unsubscribeAll: function (topic) {
			topic = `${topic}.${ENVIRONMENT}`;
			if (topic in client.messages.topics) client.messages.topics[topic].length = 0;
			client.unsubscribe(topic);
			return client;
		},
		trigger: function (rawTopic) {
			const topic = rawTopic.replace(/\//g, '.');
			var args = Array.prototype.slice.call(arguments, 1);
			if (topic in client.messages.topics) {
				client.messages.topics[topic].forEach(function (fn) {
					fn.apply(client, args);
				});
			}

			if ("#" in client.messages.topics) {
				client.messages.topics["#"].forEach(function (fn) {
					fn.apply(client, args);
				});
			}
		},
	};
	client.on('message', client.messages.trigger);
};

export const Publish = (routingKey, message) => {
	window.client.messages.publish(routingKey, message)
}
export const Subscribe = (routingKey, func) => {
	window.client.messages.subscribe(routingKey, func)
}
export const Unsubscribe = (routingKey, func) => {
	window.client.messages.unsubscribe(routingKey, func)
}

const OnError = (err, retry, retryCount, dispatch, messages) => {
	if (retry) {
		dispatch(ConnectToRabbit((retryCount > 1), retryCount - 1));
	} else {
		let hide = message.error(<RabbitReconnectMessage initRabbit={() => dispatch(ConnectToRabbit())} hide={() => dispatch(messageWillHide())} messages={messages} />, 0);
		dispatch(saveMessageHide(hide));
	}

	return {
		type: RABBIT_DISCONECTED
	};
};

const messageWillHide = () => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;
	state.connection.hideMainMessage();
	const hide = message.loading(messages["error.reconecting"], 0);
	dispatch({
		type: RABBIT_MESSAGE,
		callback: hide,
		connecting: true
	});
};

const saveMessageHide = (hideMessages) => (dispatch, getState) => {
	let state = getState();
	if (state.connection.hideMessage)
		state.connection.hideMessage();
	dispatch({
		type: RABBIT_MAIN_MESSAGE,
		callback: hideMessages
	});
};