import {
	defineMessages
} from 'react-intl';
import defaults from '../int18/default';

export const getUnitIcons = () => {
	return defineMessages({
		icon1: { id: 'icon1', defaultMessage: defaults['icon1'] },
		icon2: { id: 'icon2', defaultMessage: defaults['icon2'] },
		icon3: { id: 'icon3', defaultMessage: defaults['icon3'] },
		icon4: { id: 'icon4', defaultMessage: defaults['icon4'] },
		icon5: { id: 'icon5', defaultMessage: defaults['icon5'] },
		icon6: { id: 'icon6', defaultMessage: defaults['icon6'] },
		icon7: { id: 'icon7', defaultMessage: defaults['icon7'] },
		icon8: { id: 'icon8', defaultMessage: defaults['icon8'] },
		icon9: { id: 'icon9', defaultMessage: defaults['icon9'] },
		icon10: { id: 'icon10', defaultMessage: defaults['icon10'] },
		icon11: { id: 'icon11', defaultMessage: defaults['icon11'] },
		icon12: { id: 'icon12', defaultMessage: defaults['icon12'] },
		icon13: { id: 'icon13', defaultMessage: defaults['icon13'] },
		icon14: { id: 'icon14', defaultMessage: defaults['icon14'] },
		icon15: { id: 'icon15', defaultMessage: defaults['icon15'] },
		icon16: { id: 'icon16', defaultMessage: defaults['icon16'] },
		icon17: { id: 'icon17', defaultMessage: defaults['icon17'] },
		icon18: { id: 'icon18', defaultMessage: defaults['icon18'] },
		icon19: { id: 'icon19', defaultMessage: defaults['icon19'] },
		icon20: { id: 'icon20', defaultMessage: defaults['icon20'] },
		icon21: { id: 'icon21', defaultMessage: defaults['icon21'] },
		icon22: { id: 'icon22', defaultMessage: defaults['icon22'] },
		icon23: { id: 'icon23', defaultMessage: defaults['icon23'] },
		icon24: { id: 'icon24', defaultMessage: defaults['icon24'] },
		icon25: { id: 'icon25', defaultMessage: defaults['icon25'] },
		icon26: { id: 'icon26', defaultMessage: defaults['icon26'] },
		icon27: { id: 'icon27', defaultMessage: defaults['icon27'] },
		icon28: { id: 'icon28', defaultMessage: defaults['icon28'] },
		icon29: { id: 'icon29', defaultMessage: defaults['icon29'] },
		icon30: { id: 'icon30', defaultMessage: defaults['icon30'] },
		icon31: { id: 'icon31', defaultMessage: defaults['icon31'] },
		icon32: { id: 'icon32', defaultMessage: defaults['icon32'] },
		icon33: { id: 'icon33', defaultMessage: defaults['icon33'] },
		icon34: { id: 'icon34', defaultMessage: defaults['icon34'] },
		icon35: { id: 'icon35', defaultMessage: defaults['icon35'] },
	});
};

export const getLanguageIntl = () => {
	return defineMessages({
		intl: {
			id: 'language.intl',
			defaultMessage: defaults['language.intl'],
		},
	});
};

export const getDeviceMessages = () => {
	return defineMessages({
		deviceFinder: {
			id: 'device.deviceFinder',
			defaultMessage: defaults['device.deviceFinder'],
		},
		client: {
			id: 'device.client',
			defaultMessage: defaults['device.client'],
		},
		device: {
			id: 'device.device',
			defaultMessage: defaults['device.device'],
		},
		sim: {
			id: 'device.sim',
			defaultMessage: defaults['device.sim'],
		},
		clientSearchError: {
			id: 'device.clientSearchError',
			defaultMessage: defaults['device.clientSearchError'],
		},
		clientDevicesSearchError: {
			id: 'device.clientDevicesSearchError',
			defaultMessage: defaults['device.clientDevicesSearchError'],
		},
		deviceSearchError: {
			id: 'device.deviceSearchError',
			defaultMessage: defaults['device.deviceSearchError'],
		},
		simSearchError: {
			id: 'device.simSearchError',
			defaultMessage: defaults['device.simSearchError'],
		},
		deviceSearch: {
			id: 'device.deviceSearch',
			defaultMessage: defaults['device.deviceSearch'],
		},
		notFound: {
			id: 'device.notFound',
			defaultMessage: defaults['device.notFound'],
		},
		clientNameIronhide: {
			id: 'device.clientNameIronhide',
			defaultMessage: defaults['device.clientNameIronhide'],
		},
		clientNameOptimus: {
			id: 'device.clientNameOptimus',
			defaultMessage: defaults['device.clientNameOptimus'],
		},
		pin: {
			id: 'device.pin',
			defaultMessage: defaults['device.pin'],
		},
		imei: {
			id: 'device.imei',
			defaultMessage: defaults['device.imei'],
		},
		description: {
			id: 'device.description',
			defaultMessage: defaults['device.description'],
		},
		active: {
			id: 'device.active',
			defaultMessage: defaults['device.active'],
		},
		icon: {
			id: 'device.icon',
			defaultMessage: defaults['device.icon'],
		},
		doesNotHave: {
			id: 'device.doesNotHave',
			defaultMessage: defaults['device.doesNotHave'],
		},
		true: {
			id: 'device.true',
			defaultMessage: defaults['device.true'],
		},
		false: {
			id: 'device.false',
			defaultMessage: defaults['device.false'],
		},
		deviceInfoError: {
			id: 'device.deviceInfoError',
			defaultMessage: defaults['device.deviceInfoError'],
		},
		simInfoError: {
			id: 'device.simInfoError',
			defaultMessage: defaults['device.simInfoError'],
		},
		simSearch: {
			id: 'device.simSearch',
			defaultMessage: defaults['device.simSearch'],
		},
		line: {
			id: 'device.line',
			defaultMessage: defaults['device.line'],
		},
		serial: {
			id: 'device.serial',
			defaultMessage: defaults['device.serial'],
		},
		carrier: {
			id: 'device.carrier',
			defaultMessage: defaults['device.carrier'],
		},
		state: {
			id: 'device.state',
			defaultMessage: defaults['device.state'],
		},
		inactive: {
			id: 'device.inactive',
			defaultMessage: defaults['device.inactive'],
		},
		enabled: {
			id: 'device.enabled',
			defaultMessage: defaults['device.enabled'],
		},
		disabled: {
			id: 'device.disabled',
			defaultMessage: defaults['device.disabled'],
		},
		clientIsDisabled: {
			id: 'device.clientIsDisabled',
			defaultMessage: defaults['device.clientIsDisabled'],
		},
		deviceNumber: {
			id: 'device.deviceNumber',
			defaultMessage: defaults['device.deviceNumber'],
		},
		hidden: {
			id: 'device.hidden',
			defaultMessage: defaults['device.hidden'],
		},
		suspended: {
			id: 'device.suspended',
			defaultMessage: defaults['device.suspended'],
		},
		cancelled: {
			id: 'device.cancelled',
			defaultMessage: defaults['device.cancelled'],
		},
		others: {
			id: 'device.others',
			defaultMessage: defaults['device.others'],
		},
		noDescription: {
			id: 'device.noDescription',
			defaultMessage: defaults['device.noDescription'],
		},
		loadDevicesError: {
			id: 'device.loadDevicesError',
			defaultMessage: defaults['device.loadDevicesError'],
		},
		devices: {
			id: 'device.devices',
			defaultMessage: defaults['device.devices'],
		},
		deviceModel: {
			id: 'device.deviceModel',
			defaultMessage: defaults['device.deviceModel'],
		},
		modelProfile: {
			id: 'device.modelProfile',
			defaultMessage: defaults['device.modelProfile'],
		},
		exportDevicesError: {
			id: 'device.exportDevicesError',
			defaultMessage: defaults['device.exportDevicesError'],
		},
		exportDevicesSuccess: {
			id: 'device.exportDevicesSuccess',
			defaultMessage: defaults['device.exportDevicesSuccess'],
		},
	});
};

export const getClipboardMessages = () => {
	return defineMessages({
		copyToClipboard: {
			id: 'clipboard.copyToClipboard',
			defaultMessage: defaults['clipboard.copyToClipboard'],
		},
		copySuccess: {
			id: 'clipboard.copySuccess',
			defaultMessage: defaults['clipboard.copySuccess'],
		},
		copyFail: {
			id: 'clipboard.copyFail',
			defaultMessage: defaults['clipboard.copyFail'],
		},
	});
};

export const getCalendarMessages = () => {
	return defineMessages({
		allDay: {
			id: 'calendar.allDay',
			defaultMessage: defaults['calendar.allDay'],
		},
		previous: {
			id: 'calendar.previous',
			defaultMessage: defaults['calendar.previous'],
		},
		next: {
			id: 'calendar.next',
			defaultMessage: defaults['calendar.next'],
		},
		today: {
			id: 'calendar.today',
			defaultMessage: defaults['calendar.today'],
		},
		month: {
			id: 'calendar.month',
			defaultMessage: defaults['calendar.month'],
		},
		week: {
			id: 'calendar.week',
			defaultMessage: defaults['calendar.week'],
		},
		day: {
			id: 'calendar.day',
			defaultMessage: defaults['calendar.day'],
		},
		agenda: {
			id: 'calendar.agenda',
			defaultMessage: defaults['calendar.agenda'],
		},
		date: {
			id: 'calendar.date',
			defaultMessage: defaults['calendar.date'],
		},
		time: {
			id: 'calendar.time',
			defaultMessage: defaults['calendar.time'],
		},
		event: {
			id: 'calendar.event',
			defaultMessage: defaults['calendar.event'],
		},
		lastRefreshed: {
			id: 'calendar.lastRefreshed',
			defaultMessage: defaults['calendar.lastRefreshed'],
		},
		refreshTooltip: {
			id: 'calendar.refreshTooltip',
			defaultMessage: defaults['calendar.refreshTooltip'],
		},
		thisWeek: {
			id: 'calendar.thisWeek',
			defaultMessage: defaults['calendar.thisWeek'],
		},
		thisMonth: {
			id: 'calendar.thisMonth',
			defaultMessage: defaults['calendar.thisMonth'],
		},
		summary: {
			id: 'calendar.summary',
			defaultMessage: defaults['calendar.summary'],
		},
		description: {
			id: 'calendar.description',
			defaultMessage: defaults['calendar.description'],
		},
		noDescription: {
			id: 'calendar.noDescription',
			defaultMessage: defaults['calendar.noDescription'],
		},
	});
};

export const getErrorMessages = () => {
	return defineMessages({
		commonError: {
			id: 'error.commonError',
			defaultMessage: defaults['error.commonError'],
		},
		totalRequiredError: {
			id: 'error.totalRequiredError',
			defaultMessage: defaults['error.totalRequiredError'],
		},
		pinExistError: {
			id: 'error.pinExistError',
			defaultMessage: defaults['error.pinExistError'],
		},
		imeiExistError: {
			id: 'error.imeiExistError',
			defaultMessage: defaults['error.imeiExistError'],
		},
		invoiceNumberExistError: {
			id: 'error.invoiceNumberExistError',
			defaultMessage: defaults['error.invoiceNumberExistError'],
		},
		invoicedNumberError: {
			id: 'error.invoicedNumberError',
			defaultMessage: defaults['error.invoicedNumberError'],
		},
		UnitsError: {
			id: 'error.UnitsError',
			defaultMessage: defaults['error.UnitsError'],
		},
		LotsError: {
			id: 'error.LotsError',
			defaultMessage: defaults['error.LotsError'],
		},
		onChargeError: {
			id: 'error.onChargeError',
			defaultMessage: defaults['error.onChargeError'],
		},
		confirmPassword: {
			id: 'error.confirmPassword',
			defaultMessage: defaults['error.confirmPassword'],
		},
		server: {
			id: 'error.server',
			defaultMessage: defaults['error.server'],
		},
		common: {
			id: 'error.common',
			defaultMessage: defaults['error.common'],
		},
		nameError: {
			id: 'error.nameError',
			defaultMessage: defaults['error.nameError'],
		},
		usernameEmptyError: {
			id: 'error.usernameEmptyError',
			defaultMessage: defaults['error.usernameEmptyError'],
		},
		usernameLengthError: {
			id: 'error.usernameLengthError',
			defaultMessage: defaults['error.usernameLengthError'],
		},
		usernameInvalidError: {
			id: 'error.usernameInvalidError',
			defaultMessage: defaults['error.usernameInvalidError'],
		},
		emailEmptyError: {
			id: 'error.emailEmptyError',
			defaultMessage: defaults['error.emailEmptyError'],
		},
		emailInvalidError: {
			id: 'error.emailInvalidError',
			defaultMessage: defaults['error.emailInvalidError'],
		},
		passwordError: {
			id: 'error.passwordError',
			defaultMessage: defaults['error.passwordError'],
		},
		passwordEmptyError: {
			id: 'error.passwordEmptyError',
			defaultMessage: defaults['error.passwordEmptyError'],
		},
		passwordInvalidError: {
			id: 'error.passwordInvalidError',
			defaultMessage: defaults['error.passwordInvalidError'],
		},
		passwordLengthError: {
			id: 'error.passwordLengthError',
			defaultMessage: defaults['error.passwordLengthError'],
		},
		addressError: {
			id: 'error.addressError',
			defaultMessage: defaults['error.addressError'],
		},
		contactNameError: {
			id: 'error.contactNameError',
			defaultMessage: defaults['error.contactNameError'],
		},
		pinError: {
			id: 'error.pinError',
			defaultMessage: defaults['error.pinError'],
		},
		imeiError: {
			id: 'error.imeiError',
			defaultMessage: defaults['error.imeiError'],
		},
		modelError: {
			id: 'error.modelError',
			defaultMessage: defaults['error.modelError'],
		},
		technicalError: {
			id: 'error.technicalError',
			defaultMessage: defaults['error.technicalError'],
		},
		descriptionError: {
			id: 'error.descriptionError',
			defaultMessage: defaults['error.descriptionError'],
		},
		lineError: {
			id: 'error.lineError',
			defaultMessage: defaults['error.lineError'],
		},
		clientNameError: {
			id: 'error.clientNameError',
			defaultMessage: defaults['error.clientNameError'],
		},
		representativeError: {
			id: 'error.representativeError',
			defaultMessage: defaults['error.representativeError'],
		},
		rtnEmptyError: {
			id: 'error.rtnEmptyError',
			defaultMessage: defaults['error.rtnEmptyError'],
		},
		rtnLengthError: {
			id: 'error.rtnLengthError',
			defaultMessage: defaults['error.rtnLengthError'],
		},
		idRtnEmptyError: {
			id: 'error.idRtnEmptyError',
			defaultMessage: defaults['error.idRtnEmptyError'],
		},
		idRtnLengthError: {
			id: 'error.idRtnLengthError',
			defaultMessage: defaults['error.idRtnLengthError'],
		},
		telephoneError: {
			id: 'error.telephoneError',
			defaultMessage: defaults['error.telephoneError'],
		},
		telephoneInvalidError: {
			id: 'error.telephoneInvalidError',
			defaultMessage: defaults['error.telephoneInvalidError'],
		},
		productError: {
			id: 'error.productError',
			defaultMessage: defaults['error.productError'],
		},
		priceError: {
			id: 'error.priceError',
			defaultMessage: defaults['error.priceError'],
		},
		tagError: {
			id: 'error.tagError',
			defaultMessage: defaults['error.tagError'],
		},
		extensionError: {
			id: 'error.extensionError',
			defaultMessage: defaults['error.extensionError'],
		},
		defaultPriceError: {
			id: 'error.defaultPriceError',
			defaultMessage: defaults['error.defaultPriceError'],
		},
		rejectedMessageError: {
			id: 'error.rejectedMessageError',
			defaultMessage: defaults['error.rejectedMessageError'],
		},
		jobTypeError: {
			id: 'error.jobTypeError',
			defaultMessage: defaults['error.jobTypeError'],
		},
		collectionError: {
			id: 'error.collectionError',
			defaultMessage: defaults['error.collectionError'],
		},
		leasedError: {
			id: 'error.leasedError',
			defaultMessage: defaults['error.leasedError'],
		},
		mainProductError: {
			id: 'error.mainProductError',
			defaultMessage: defaults['error.mainProductError'],
		},
		clientError: {
			id: 'error.clientError',
			defaultMessage: defaults['error.clientError'],
		},
		techniciansError: {
			id: 'error.techniciansError',
			defaultMessage: defaults['error.techniciansError'],
		},
		dateEmptyError: {
			id: 'error.dateEmptyError',
			defaultMessage: defaults['error.dateEmptyError'],
		},
		dateYesterdayError: {
			id: 'error.dateYesterdayError',
			defaultMessage: defaults['error.dateYesterdayError'],
		},
		noJobsError: {
			id: 'error.noJobsError',
			defaultMessage: defaults['error.noJobsError'],
		},
		noJobClient: {
			id: 'error.noJobClient',
			defaultMessage: defaults['error.noJobClient'],
		},
		simError: {
			id: 'error.simError',
			defaultMessage: defaults['error.simError'],
		},
		iconError: {
			id: 'error.iconError',
			defaultMessage: defaults['error.iconError'],
		},
		vehicleDescriptionError: {
			id: 'error.vehicleDescriptionError',
			defaultMessage: defaults['error.vehicleDescriptionError'],
		},
		notFound: {
			id: 'error.notFound',
			defaultMessage: defaults['error.notFound'],
		},
		addonQuantityError: {
			id: 'error.addonQuantityError',
			defaultMessage: defaults['error.addonQuantityError'],
		},
		jobDurationError: {
			id: 'error.jobDurationError',
			defaultMessage: defaults['error.jobDurationError'],
		},
		caiEmptyError: {
			id: 'error.caiEmptyError',
			defaultMessage: defaults['error.caiEmptyError'],
		},
		caiLengthError: {
			id: 'error.caiLengthError',
			defaultMessage: defaults['error.caiLengthError'],
		},
		minrangeError: {
			id: 'error.minrangeError',
			defaultMessage: defaults['error.minrangeError'],
		},
		maxrangeError: {
			id: 'error.maxrangeError',
			defaultMessage: defaults['error.maxrangeError'],
		},
		prefixEmptyError: {
			id: 'error.prefixEmptyError',
			defaultMessage: defaults['error.prefixEmptyError'],
		},
		prefixFormatError: {
			id: 'error.prefixFormatError',
			defaultMessage: defaults['error.prefixFormatError'],
		},
		invoiceTypeError: {
			id: 'error.invoiceTypeError',
			defaultMessage: defaults['error.invoiceTypeError'],
		},
		creditDaysError: {
			id: 'error.creditDaysError',
			defaultMessage: defaults['error.creditDaysError'],
		},
		exchangeRateError: {
			id: 'error.exchangeRateError',
			defaultMessage: defaults['error.exchangeRateError'],
		},
		paymentError: {
			id: 'error.paymentError',
			defaultMessage: defaults['error.paymentError'],
		},
		cancelMessageError: {
			id: 'error.cancelMessageError',
			defaultMessage: defaults['error.cancelMessageError'],
		},
		emailRegexError: {
			id: 'error.emailRegexError',
			defaultMessage: defaults['error.emailRegexError'],
		},
		codeError: {
			id: 'error.codeError',
			defaultMessage: defaults['error.codeError'],
		},
		quantityError: {
			id: 'error.quantityError',
			defaultMessage: defaults['error.quantityError'],
		},
		invalidDateError: {
			id: 'error.invalidDateError',
			defaultMessage: defaults['error.invalidDateError'],
		},
		eventNotCreatedInPlatform: {
			id: 'error.eventNotCreatedInPlatform',
			defaultMessage: defaults['error.eventNotCreatedInPlatform'],
		},
		wonNeeded: {
			id: 'error.wonNeeded',
			defaultMessage: defaults['error.wonNeeded'],
		},
		paymentDestinationError: {
			id: 'error.paymentDestinationError',
			defaultMessage: defaults['error.paymentDestinationError'],
		},
		paymentTypeError: {
			id: 'error.paymentTypeError',
			defaultMessage: defaults['error.paymentTypeError'],
		},
		paymentBankError: {
			id: 'error.paymentBankError',
			defaultMessage: defaults['error.paymentBankError'],
		},
		paymentCheckError: {
			id: 'error.paymentCheckError',
			defaultMessage: defaults['error.paymentCheckError'],
		},
		paymentRefError: {
			id: 'error.paymentRefError',
			defaultMessage: defaults['error.paymentRefError'],
		},
		paymentCardError: {
			id: 'error.paymentCardError',
			defaultMessage: defaults['error.paymentCardError'],
		},
		pasteError: {
			id: 'error.pasteError',
			defaultMessage: defaults['error.pasteError'],
		},
		validThroughError: {
			id: 'error.validThroughError',
			defaultMessage: defaults['error.validThroughError'],
		},
		taxImageError: {
			id: 'error.taxImageError',
			defaultMessage: defaults['error.taxImageError'],
		},
		cityError: {
			id: 'error.cityError',
			defaultMessage: defaults['error.cityError'],
		},
		stateError: {
			id: 'error.stateError',
			defaultMessage: defaults['error.stateError'],
		},
		invoiceTotalError: {
			id: 'error.invoiceTotalError',
			defaultMessage: defaults['error.invoiceTotalError'],
		},
		motiveError: {
			id: 'error.motiveError',
			defaultMessage: defaults['error.motiveError'],
		},
		imeiLengthDigits: {
			id: 'error.imeiLengthDigits',
			defaultMessage: defaults['error.imeiLengthDigits'],
		},
		deleteRecordsError: {
			id: 'error.deleteRecordsError',
			defaultMessage: defaults['error.deleteRecordsError'],
		},
		gpsCountError: {
			id: 'error.gpsCountError',
			defaultMessage: defaults['error.gpsCountError'],
		},
		techniciansLoadError: {
			id: 'error.techniciansLoadError',
			defaultMessage: defaults['error.techniciansLoadError'],
		},
		loadExchangeRateError: {
			id: 'error.loadExchangeRateError',
			defaultMessage: defaults['error.loadExchangeRateError'],
		},
		sessionExpired: {
			id: 'error.sessionExpired',
			defaultMessage: defaults['error.sessionExpired'],
		},
		unauthorizedAccess: {
			id: 'error.unauthorizedAccess',
			defaultMessage: defaults['error.unauthorizedAccess'],
		},
		pinFormatError: {
			id: 'error.pinFormatError',
			defaultMessage: defaults['error.pinFormatError'],
		},
		imeiFormatError: {
			id: 'error.imeiFormatError',
			defaultMessage: defaults['error.imeiFormatError'],
		},
		requiredFileError: {
			id: 'error.requiredFileError',
			defaultMessage: defaults['error.requiredFileError'],
		},
		requiredBrandError: {
			id: 'error.requiredBrandError',
			defaultMessage: defaults['error.requiredBrandError'],
		},
		requiredPlateError: {
			id: 'error.requiredPlateError',
			defaultMessage: defaults['error.requiredPlateError'],
		},
		requiredModelError: {
			id: 'error.requiredModelError',
			defaultMessage: defaults['error.requiredModelError'],
		},
		telephonesRequired: {
			id:'error.telephonesRequired',
			defaultMessage: defaults['error.telephonesRequired']

		},
		emailsRequired:{
			id:'error.emailsRequired',
			defaultMessage: defaults['error.emailsRequired']
		}
		
	});
};

export const getPopConfirm = () => {
	return defineMessages({
		yes: {
			id: 'popConfirm.yes',
			defaultMessage: defaults['popConfirm.yes'],
		},
		no: {
			id: 'popConfirm.no',
			defaultMessage: defaults['popConfirm.no'],
		},
	});
};

export const getSideMenu = () => {
	return defineMessages({
		account: {
			id: 'sideMenu.account',
			defaultMessage: defaults['sideMenu.account'],
		},
		invoicing: {
			id: 'sideMenu.invoicing',
			defaultMessage: defaults['sideMenu.invoicing'],
		},
		home: {
			id: 'sideMenu.home',
			defaultMessage: defaults['sideMenu.home'],
		},
		preRegistration: {
			id: 'sideMenu.preRegistration',
			defaultMessage: defaults['sideMenu.preRegistration'],
		},
		job: {
			id: 'sideMenu.job',
			defaultMessage: defaults['sideMenu.job'],
		},
		exchangeRate: {
			id: 'sideMenu.exchangeRate',
			defaultMessage: defaults['sideMenu.exchangeRate'],
		},
		calendar: {
			id: 'sideMenu.calendar',
			defaultMessage: defaults['sideMenu.calendar'],
		},
		table: {
			id: 'sideMenu.table',
			defaultMessage: defaults['sideMenu.table'],
		},
		invoices: {
			id: 'sideMenu.invoices',
			defaultMessage: defaults['sideMenu.invoices'],
		},
		proformas: {
			id: 'sideMenu.proformas',
			defaultMessage: defaults['sideMenu.proformas'],
		},
		quotations: {
			id: 'sideMenu.quotations',
			defaultMessage: defaults['sideMenu.quotations'],
		},
		creditNotes: {
			id: 'sideMenu.creditNotes',
			defaultMessage: defaults['sideMenu.creditNotes'],
		},
		debitNotes: {
			id: 'sideMenu.debitNotes',
			defaultMessage: defaults['sideMenu.debitNotes'],
		},
		products: {
			id: 'sideMenu.products',
			defaultMessage: defaults['sideMenu.products'],
		},
		clients: {
			id: 'sideMenu.clients',
			defaultMessage: defaults['sideMenu.clients'],
		},
		approved: {
			id: 'sideMenu.approved',
			defaultMessage: defaults['sideMenu.approved'],
		},
		reports: {
			id: 'sideMenu.reports',
			defaultMessage: defaults['sideMenu.reports'],
		},
		generalReport: {
			id: 'sideMenu.generalReport',
			defaultMessage: defaults['sideMenu.generalReport'],
		},
		clientReport: {
			id: 'sideMenu.clientReport',
			defaultMessage: defaults['sideMenu.clientReport'],
		},
		detailReport: {
			id: 'sideMenu.detailReport',
			defaultMessage: defaults['sideMenu.detailReport'],
		},
		taxReport: {
			id: 'sideMenu.taxReport',
			defaultMessage: defaults['sideMenu.taxReport'],
		},
		incomeExpensesReport: {
			id: 'sideMenu.incomeExpensesReport',
			defaultMessage: defaults['sideMenu.incomeExpensesReport'],
		},
		equipmentMovementsReport: {
			id: 'sideMenu.equipmentMovementsReport',
			defaultMessage: defaults['sideMenu.equipmentMovementsReport'],
		},
		jobsToCharge: {
			id: 'sideMenu.jobsToCharge',
			defaultMessage: defaults['sideMenu.jobsToCharge'],
		},
		overdueInvoices: {
			id: 'sideMenu.overdueInvoices',
			defaultMessage: defaults['sideMenu.overdueInvoices'],
		},
		incomeExpenses: {
			id: 'sideMenu.incomeExpenses',
			defaultMessage: defaults['sideMenu.incomeExpenses'],
		},
		categories: {
			id: 'sideMenu.categories',
			defaultMessage: defaults['sideMenu.categories'],
		},
		fiscalData: {
			id: 'sideMenu.fiscalData',
			defaultMessage: defaults['sideMenu.fiscalData'],
		},
		taxRetention: {
			id: 'sideMenu.taxRetention',
			defaultMessage: defaults['sideMenu.taxRetention'],
		},
		extra: {
			id: 'sideMenu.extra',
			defaultMessage: defaults['sideMenu.extra'],
		},
		departments: {
			id: 'sideMenu.departments',
			defaultMessage: defaults['sideMenu.departments'],
		},
		employees: {
			id: 'sideMenu.employees',
			defaultMessage: defaults['sideMenu.employees'],
		},
		accounts: {
			id: 'sideMenu.accounts',
			defaultMessage: defaults['sideMenu.accounts'],
		},
		technicians: {
			id: 'sideMenu.technicians',
			defaultMessage: defaults['sideMenu.technicians'],
		},
		requests: {
			id: 'sideMenu.requests',
			defaultMessage: defaults['sideMenu.requests'],
		},
		payrolls: {
			id: 'sideMenu.payrolls',
			defaultMessage: defaults['sideMenu.payrolls'],
		},
		commissions: {
			id: 'sideMenu.commissions',
			defaultMessage: defaults['sideMenu.commissions'],
		},
		inventory: {
			id: 'sideMenu.inventory',
			defaultMessage: defaults['sideMenu.inventory'],
		},
		models: {
			id: 'sideMenu.models',
			defaultMessage: defaults['sideMenu.models'],
		},
		units: {
			id: 'sideMenu.units',
			defaultMessage: defaults['sideMenu.units'],
		},
		lots: {
			id: 'sideMenu.lots',
			defaultMessage: defaults['sideMenu.lots'],
		},
		paymentsReport: {
			id: 'sideMenu.paymentsReport',
			defaultMessage: defaults['sideMenu.paymentsReport'],
		},
		receipt: {
			id: 'sideMenu.receipt',
			defaultMessage: defaults['sideMenu.receipt'],
		},
		clientNameOptimusGuatemala: {
			id: 'sideMenu.clientNameOptimusGuatemala',
			defaultMessage: defaults['sideMenu.clientNameOptimusGuatemala'],
		},
		customPriceReport: {
			id: 'sideMenu.customPriceReport',
			defaultMessage: defaults['sideMenu.customPriceReport'],
		},
	});
};

export const getEditRoleMessages = () =>{
	return defineMessages({
		orderJobTable: {
			id: 'editRole.orderJobTable',
			defaultMessage: defaults['orderJobTable']
		},
		accessTo:{
			id:'editRole.accesTo',
			defaultMessage: defaults['accesTo']
		},

		orderJobsRequests: {
			id: 'editRole.orderJobsRequests',
			defaultMessage: defaults['orderJobsRequests']
		},
		
		approvedClients:{
			id: 'editRole.approvedClients',
			defaultMessage: defaults['approvedClients']
		},

		editInvoice:{
			id: 'editRole.editInvoice',
			defaultMessage: defaults['editInvoice']
		},
		editProform:{
			id: 'editRole.editProform',
			defaultMessage: defaults['editProform']
		},
		editQuote:{
			id: 'editRole.editQuote',
			defaultMessage: defaults['editQuote']
		},
		activateJobs:{
			id: 'editRole.activateJobs',
			defaultMessage: defaults['activateJobs']
		},
		dataclients:{
			id:'editRole.dataclients',
			defaultMessage:defaults['dataclients']
		},
	})

} 

export const getFields = () => {
	return defineMessages({
		name: {
			id: 'field.name',
			defaultMessage: defaults['field.name'],
		},
		namePlaceholder: {
			id: 'field.namePlaceholder',
			defaultMessage: defaults['field.namePlaceholder'],
		},
		username: {
			id: 'field.username',
			defaultMessage: defaults['field.username'],
		},
		usernamePlaceholder: {
			id: 'field.usernamePlaceholder',
			defaultMessage: defaults['field.usernamePlaceholder'],
		},
		email: {
			id: 'field.email',
			defaultMessage: defaults['field.email'],
		},
		emailPlaceholder: {
			id: 'field.emailPlaceholder',
			defaultMessage: defaults['field.emailPlaceholder'],
		},
		oldPassword: {
			id: 'field.oldPassword',
			defaultMessage: defaults['field.oldPassword'],
		},
		oldPasswordPlaceholder: {
			id: 'field.oldPasswordPlaceholder',
			defaultMessage: defaults['field.oldPasswordPlaceholder'],
		},
		newPassword: {
			id: 'field.newPassword',
			defaultMessage: defaults['field.newPassword'],
		},
		newPasswordPlaceholder: {
			id: 'field.newPasswordPlaceholder',
			defaultMessage: defaults['field.oldPasswordPlaceholder'],
		},
		password: {
			id: 'field.password',
			defaultMessage: defaults['field.password'],
		},
		passwordPlaceholder: {
			id: 'field.passwordPlaceholder',
			defaultMessage: defaults['field.passwordPlaceholder'],
		},
		passwordNorify: {
			id: 'field.passwordNorify',
			defaultMessage: defaults['field.passwordNorify'],
		},
		passwordConfirm: {
			id: 'field.passwordConfirm',
			defaultMessage: defaults['field.passwordConfirm'],
		},
		passwordConfirmPlaceholder: {
			id: 'field.passwordConfirmPlaceholder',
			defaultMessage: defaults['field.passwordConfirmPlaceholder'],
		},
		languages: {
			id: 'field.languages',
			defaultMessage: defaults['field.languages'],
		},
		languagesPlaceholder: {
			id: 'field.languagesPlaceholder',
			defaultMessage: defaults['field.languagesPlaceholder'],
		},
		active: {
			id: 'field.active',
			defaultMessage: defaults['field.active'],
		},
		actions: {
			id: 'field.actions',
			defaultMessage: defaults['field.actions'],
		},
		pin: {
			id: 'field.pin',
			defaultMessage: defaults['field.pin'],
		},
		pinPlaceholder: {
			id: 'field.pinPlaceholder',
			defaultMessage: defaults['field.pinPlaceholder'],
		},
		imei: {
			id: 'field.imei',
			defaultMessage: defaults['field.imei'],
		},
		imeiPlaceholder: {
			id: 'field.imeiPlaceholder',
			defaultMessage: defaults['field.imeiPlaceholder'],
		},
		description: {
			id: 'field.description',
			defaultMessage: defaults['field.description'],
		},
		descriptionPlaceholder: {
			id: 'field.descriptionPlaceholder',
			defaultMessage: defaults['field.descriptionPlaceholder'],
		},
		modelPlaceholder: {
			id: 'field.modelPlaceholder',
			defaultMessage: defaults['field.modelPlaceholder'],
		},
		technicalPlaceholder: {
			id: 'field.technicalPlaceholder',
			defaultMessage: defaults['field.technicalPlaceholder'],
		},
		contactName: {
			id: 'field.contactName',
			defaultMessage: defaults['field.contactName'],
		},
		contactNamePlaceholder: {
			id: 'field.contactNamePlaceholder',
			defaultMessage: defaults['field.contactNamePlaceholder'],
		},
		address: {
			id: 'field.address',
			defaultMessage: defaults['field.address'],
		},
		addressPlaceholder: {
			id: 'field.addressPlaceholder',
			defaultMessage: defaults['field.addressPlaceholder'],
		},
		accountName: {
			id: 'field.accountName',
			defaultMessage: defaults['field.accountName'],
		},
		accountNamePlaceholder: {
			id: 'field.accountNamePlaceholder',
			defaultMessage: defaults['field.accountNamePlaceholder'],
		},
		ttl: {
			id: 'field.ttl',
			defaultMessage: defaults['field.ttl'],
		},
		created: {
			id: 'field.created',
			defaultMessage: defaults['field.created'],
		},
		die: {
			id: 'field.die',
			defaultMessage: defaults['field.die'],
		},
		sessions: {
			id: 'field.sessions',
			defaultMessage: defaults['field.sessions'],
		},
		ckNewAccount: {
			id: 'field.ckNewAccount',
			defaultMessage: defaults['field.ckNewAccount'],
		},
		profilePassword: {
			id: 'field.profilePassword',
			defaultMessage: defaults['field.profilePassword'],
		},
		selectAll: {
			id: 'field.selectAll',
			defaultMessage: defaults['field.selectAll'],
		},
		date: {
			id: 'field.date',
			defaultMessage: defaults['field.date'],
		},
		message: {
			id: 'field.message',
			defaultMessage: defaults['field.message'],
		},
		user: {
			id: 'field.user',
			defaultMessage: defaults['field.user'],
		},
		technicians: {
			id: 'field.technicians',
			defaultMessage: defaults['field.technicians'],
		},
		status: {
			id: 'field.status',
			defaultMessage: defaults['field.status'],
		},
		client: {
			id: 'field.client',
			defaultMessage: defaults['field.client'],
		},
		jobs: {
			id: 'field.jobs',
			defaultMessage: defaults['field.jobs'],
		},
		comment: {
			id: 'field.comment',
			defaultMessage: defaults['field.comment'],
		},
		comments: {
			id: 'field.comments',
			defaultMessage: defaults['field.comments'],
		},
		calendar: {
			id: 'field.calendar',
			defaultMessage: defaults['field.calendar'],
		},
		technicianName: {
			id: 'field.technicianName',
			defaultMessage: defaults['field.technicianName'],
		},
		activityLog: {
			id: 'field.activityLog',
			defaultMessage: defaults['field.activityLog'],
		},
		creationDate: {
			id: 'field.creationDate',
			defaultMessage: defaults['field.creationDate'],
		},
		total: {
			id: 'field.total',
			defaultMessage: defaults['field.total'],
		},
		amount: {
			id: 'field.amount',
			defaultMessage: defaults['field.amount'],
		},
		documentNumber: {
			id: 'field.documentNumber',
			defaultMessage: defaults['field.documentNumber'],
		},
		uploadSuccess: {
			id: 'field.uploadSuccess',
			defaultMessage: defaults['field.uploadSuccess'],
		},
		uploadSuccessMessage: {
			id: 'field.uploadSuccessMessage',
			defaultMessage: defaults['field.uploadSuccessMessage'],
		},
		sizeArchiveError:{
			id: 'field.sizeArchiveError',
			defaultMessage: defaults['field.sizeArchiveError']
		},
		uploadResult: {
			id: 'field.uploadResult',
			defaultMessage: defaults['field.uploadResult'],
		},
		uploadFileButton: {
			id: 'field.uploadFileButton',
			defaultMessage: defaults['field.uploadFileButton'],
		},
		observations: {
			id: 'field.observations',
			defaultMessage: defaults['field.observations'],
		},
		brandPlaceholder: {
			id: 'field.brandPlaceholder',
			defaultMessage: defaults['field.brandPlaceholder'],
		},
		platePlaceholder: {
			id: 'field.platePlaceholder',
			defaultMessage: defaults['field.platePlaceholder'],
		},
	});
};

export const getactionMessages = () => {
	return defineMessages({
		download: {
			id: 'action.download',
			defaultMessage: defaults['action.download'],
		},
		disableConfirm: {
			id: 'action.disableConfirm',
			defaultMessage: defaults['action.disableConfirm'],
		},
		enableConfirm: {
			id: 'action.enableConfirm',
			defaultMessage: defaults['action.enableConfirm'],
		},
		edit: {
			id: 'action.edit',
			defaultMessage: defaults['action.edit'],
		},
		add: {
			id: 'action.add',
			defaultMessage: defaults['action.add'],
		},
		searchPlaceholder: {
			id: 'action.searchPlaceholder',
			defaultMessage: defaults['action.searchPlaceholder'],
		},
		disable: {
			id: 'action.disable',
			defaultMessage: defaults['action.disable'],
		},
		enable: {
			id: 'action.enable',
			defaultMessage: defaults['action.enable'],
		},
		delete: {
			id: 'action.delete',
			defaultMessage: defaults['action.delete'],
		},
		deleteAmount: {
			id: 'action.deleteAmount',
			defaultMessage: defaults['action.deleteAmount'],
		},
		deleteConfirm: {
			id: 'action.deleteConfirm',
			defaultMessage: defaults['action.deleteConfirm'],
		},
		deleteAll: {
			id: 'action.deleteAll',
			defaultMessage: defaults['action.deleteAll'],
		},
		deleteAllConfirm: {
			id: 'action.deleteAllConfirm',
			defaultMessage: defaults['action.deleteAllConfirm'],
		},
		deleteSession: {
			id: 'action.deleteSession',
			defaultMessage: defaults['action.deleteSession'],
		},
		deleteSessionConfirm: {
			id: 'action.deleteSessionConfirm',
			defaultMessage: defaults['action.deleteSessionConfirm'],
		},
		role: {
			id: 'action.role',
			defaultMessage: defaults['action.role'],
		},
		view: {
			id: 'action.view',
			defaultMessage: defaults['action.view'],
		},
		AnnulInvoiceConfirm: {
			id: 'action.AnnulInvoiceConfirm',
			defaultMessage: defaults['action.AnnulInvoiceConfirm'],
		},
		refresh: {
			id: 'action.refresh',
			defaultMessage: defaults['action.refresh'],
		},
		generate: {
			id: 'action.generate',
			defaultMessage: defaults['action.generate'],
		},
		open: {
			id: 'action.open',
			defaultMessage: defaults['action.open'],
		},
		disableAmount: {
			id: 'action.disableAmount',
			defaultMessage: defaults['action.disableAmount']
		},
	});
};

export const getModalMessages = () => {
	return defineMessages({
		Upload: {
			id: 'modal.upload',
			defaultMessage: defaults['modal.upload']
		},
		send: {
			id: 'modal.send',
			defaultMessage: defaults['modal.send'],
		},
		cancel: {
			id: 'modal.cancel',
			defaultMessage: defaults['modal.cancel'],
		},
		close: {
			id: 'modal.close',
			defaultMessage: defaults['modal.close'],
		},
		addInvoiceTitle: {
			id: 'modal.addInvoiceTitle',
			defaultMessage: defaults['modal.addInvoiceTitle'],
		},
		addAccountTitle: {
			id: 'modal.addAccountTitle',
			defaultMessage: defaults['modal.addAccountTitle'],
		},
		editAccountTitle: {
			id: 'modal.editAccountTitle',
			defaultMessage: defaults['modal.editAccountTitle'],
		},
		editRoleTitle: {
			id: 'modal.editRoleTitle',
			defaultMessage: defaults['modal.editRoleTitle'],
		},
		editSessionsTitle: {
			id: 'modal.editSessionsTitle',
			defaultMessage: defaults['modal.editSessionsTitle'],
		},
		next: {
			id: 'modal.next',
			defaultMessage: defaults['modal.next'],
		},
		previous: {
			id: 'modal.previous',
			defaultMessage: defaults['modal.previous'],
		},
		reject: {
			id: 'modal.reject',
			defaultMessage: defaults['modal.reject'],
		},
		approve: {
			id: 'modal.approve',
			defaultMessage: defaults['modal.approve'],
		},
		addPersist: {
			id: 'modal.addPersist',
			defaultMessage: defaults['modal.addPersist'],
		},
		addClose: {
			id: 'modal.addClose',
			defaultMessage: defaults['modal.addClose'],
		},
		clearAll: {
			id: 'modal.clearAll',
			defaultMessage: defaults['modal.clearAll'],
		},
		clearAdvancedSearch: {
			id: 'modal.clearAdvancedSearch',
			defaultMessage: defaults['modal.clearAdvancedSearch'],
		},
		add: {
			id: 'modal.add',
			defaultMessage: defaults['modal.add'],
		},
		edit: {
			id: 'modal.edit',
			defaultMessage: defaults['modal.edit'],
		},
		save: {
			id: 'modal.save',
			defaultMessage: defaults['modal.save'],
		},
		cancelEdit: {
			id: 'modal.cancelEdit',
			defaultMessage: defaults['modal.cancelEdit'],
		},
		connect: {
			id: 'modal.connect',
			defaultMessage: defaults['modal.connect'],
		},
		paste: {
			id: 'modal.paste',
			defaultMessage: defaults['modal.paste'],
		},
		printAndSend: {
			id: 'modal.printAndSend',
			defaultMessage: defaults['modal.printAndSend'],
		},
		accept: {
			id: 'modal.accept',
			defaultMessage: defaults['modal.accept'],
		},
		predeterminedMessage: {
			id: 'modal.predeterminedMessage',
			defaultMessage: defaults['modal.predeterminedMessage'],
		},
		customMessage: {
			id: 'modal.customMessage',
			defaultMessage: defaults['modal.customMessage'],
		},
		return: {
			id: 'modal.return',
			defaultMessage: defaults['modal.return'],
		},
		editProduct: {
			id: 'modal.editProduct',
			defaultMessage: defaults['modal.editProduct'],
		},
		total: {
			id: 'modal.total',
			defaultMessage: defaults['modal.total'],
		},
		export: {
			id: 'modal.export',
			defaultMessage: defaults['modal.export'],
		},
	});
};

export const getTopMenuMessages = () => {
	return defineMessages({
		es: {
			id: 'language.es',
			defaultMessage: defaults['language.es'],
		},
		en: {
			id: 'language.en',
			defaultMessage: defaults['language.en'],
		},
		logout: {
			id: 'auth.logout',
			defaultMessage: defaults['auth.logout'],
		},
		config: {
			id: 'menu.config',
			defaultMessage: defaults['menu.config'],
		},
		profile: {
			id: 'menu.profile',
			defaultMessage: defaults['menu.profile'],
		},
	});
};

export const getRoleMessages = () => {
	return defineMessages({
		Administrator: {
			id: 'role.Administrator',
			defaultMessage: defaults['role.Administrator'],
		},
		Sales: {
			id: 'role.Sales',
			defaultMessage: defaults['role.Sales'],
		},
		TechSupport: {
			id: 'role.TechSupport',
			defaultMessage: defaults['role.TechSupport'],
		},
		CustomerService: {
			id: 'role.CustomerService',
			defaultMessage: defaults['role.CustomerService'],
		},
		IncomeExpenses: {
			id: 'role.IncomeExpenses',
			defaultMessage: defaults['role.IncomeExpenses'],
		},
		GPSTransferActivation: {
			id: 'role.GPSTransferActivation',
			defaultMessage: defaults['role.GPSTransferActivation'],
		},
		Employees: {
			id: 'role.Employees',
			defaultMessage: defaults['role.Employees'],
		},
		Payrolls: {
			id: 'role.Payrolls',
			defaultMessage: defaults['role.Payrolls'],
		},
		InvoiceEditor: {
			id: 'role.InvoiceEditor',
			defaultMessage: defaults['role.InvoiceEditor'],
		},
		InventoryAssistant: {
			id: 'role.InventoryAssistant',
			defaultMessage: defaults['role.InventoryAssistant'],
		},
	});
};

export const getTableMessages = () => {
	return defineMessages({
		total: {
			id: 'table.total',
			defaultMessage: defaults['table.total'],
		},
		deleteZero: {
			id: 'table.deleteZero',
			defaultMessage: defaults['table.deleteZero'],
		},
		advancedSearch: {
			id: 'table.advancedSearch',
			defaultMessage: defaults['table.advancedSearch'],
		}
	});
};

export const getAuthMessages = () => {
	return defineMessages({
		login: {
			id: 'auth.login',
			defaultMessage: defaults['auth.login'],
		},
		forgot: {
			id: 'auth.forgot',
			defaultMessage: defaults['auth.forgot'],
		},
		remember: {
			id: 'auth.remember',
			defaultMessage: defaults['auth.remember'],
		},
		resetPassword: {
			id: 'auth.resetPassword',
			defaultMessage: defaults['auth.resetPassword'],
		},
		loginPlaceholder: {
			id: 'auth.loginPlaceholder',
			defaultMessage: defaults['auth.loginPlaceholder'],
		},
		resetEmail: {
			id: 'auth.resetEmail',
			defaultMessage: defaults['auth.resetEmail'],
		},
		usernameRequire: {
			id: 'auth.usernameRequire',
			defaultMessage: defaults['auth.usernameRequire'],
		},
		emailRequire: {
			id: 'auth.emailRequire',
			defaultMessage: defaults['auth.emailRequire'],
		},
		passwordRequire: {
			id: 'auth.passwordRequire',
			defaultMessage: defaults['auth.passwordRequire'],
		},
		passwordLength: {
			id: 'auth.passwordLength',
			defaultMessage: defaults['auth.passwordLength'],
		},
	});
};

export const getConfigurationMessages = () => {
	return defineMessages({
		general: {
			id: 'configuration.general',
			defaultMessage: defaults['configuration.general'],
		},
		controls: {
			id: 'configuration.controls',
			defaultMessage: defaults['configuration.controls'],
		}
	});
};

export const getRegistrationMessages = () => {
	return defineMessages({
		salesperson: {
			id: 'registration.salesperson',
			defaultMessage: defaults['registration.salesperson'],
		},
		pending: {
			id: 'registration.pending',
			defaultMessage: defaults['registration.pending'],
		},
		approved: {
			id: 'registration.approved',
			defaultMessage: defaults['registration.approved'],
		},
		rejected: {
			id: 'registration.rejected',
			defaultMessage: defaults['registration.rejected'],
		},
		all: {
			id: 'registration.all',
			defaultMessage: defaults['registration.all'],
		},
		addPreRegistration: {
			id: 'registration.addPreRegistration',
			defaultMessage: defaults['registration.addPreRegistration'],
		},
		editPreRegistration: {
			id: 'registration.editPreRegistration',
			defaultMessage: defaults['registration.editPreRegistration'],
		},
		enabled: {
			id: 'registration.enabled',
			defaultMessage: defaults['registration.enabled'],
		},
		disabled: {
			id: 'registration.disabled',
			defaultMessage: defaults['registration.disabled'],
		},
	});
};

export const getClientMessages = () => {
	return defineMessages({
		clientName: {
			id: 'client.clientName',
			defaultMessage: defaults['client.clientName'],
		},
		representative: {
			id: 'client.representative',
			defaultMessage: defaults['client.representative'],
		},
		clientHasOverdueInvoice: {
			id: 'client.clientHasOverdueInvoice',
			defaultMessage: defaults['client.clientHasOverdueInvoice'],
		},
		rtn: {
			id: 'client.rtn',
			defaultMessage: defaults['client.rtn'],
		},
		idWithRtn: {
			id: 'client.idWithRtn',
			defaultMessage: defaults['client.idWithRtn'],
		},
		clientTelephones: {
			id: 'client.clientTelephones',
			defaultMessage: defaults['client.clientTelephones'],
		},
		clientTelephone: {
			id: 'client.clientTelephone',
			defaultMessage: defaults['client.clientTelephone'],
		},
		clientEmails: {
			id: 'client.clientEmails',
			defaultMessage: defaults['client.clientEmails'],
		},
		telephonePlaceholder: {
			id: 'client.telephonePlaceholder',
			defaultMessage: defaults['client.telephonePlaceholder'],
		},
		emailPlaceholder: {
			id: 'client.emailPlaceholder',
			defaultMessage: defaults['client.emailPlaceholder'],
		},
		addTelephone: {
			id: 'client.addTelephone',
			defaultMessage: defaults['client.addTelephone'],
		},
		addEmail: {
			id: 'client.addEmail',
			defaultMessage: defaults['client.addEmail'],
		},
		basicInfo: {
			id: 'client.basicInfo',
			defaultMessage: defaults['client.basicInfo'],
		},
		customPrices: {
			id: 'client.customPrices',
			defaultMessage: defaults['client.customPrices'],
		},
		productPlaceholder: {
			id: 'client.productPlaceholder',
			defaultMessage: defaults['client.productPlaceholder'],
		},
		addCustomPrice: {
			id: 'client.addCustomPrice',
			defaultMessage: defaults['client.addCustomPrice'],
		},
		dollars: {
			id: 'client.dollars',
			defaultMessage: defaults['client.dollars'],
		},
		lempiras: {
			id: 'client.lempiras',
			defaultMessage: defaults['client.lempiras'],
		},
		clientProduct: {
			id: 'client.clientProduct',
			defaultMessage: defaults['client.clientProduct'],
		},
		price: {
			id: 'client.price',
			defaultMessage: defaults['client.price'],
		},
		individual: {
			id: 'client.individual',
			defaultMessage: defaults['client.individual'],
		},
		currency: {
			id: 'client.currency',
			defaultMessage: defaults['client.currency'],
		},
		tags: {
			id: 'client.tags',
			defaultMessage: defaults['client.tags'],
		},
		extension: {
			id: 'client.extension',
			defaultMessage: defaults['client.extension'],
		},
		pricePlaceholder: {
			id: 'client.pricePlaceholder',
			defaultMessage: defaults['client.pricePlaceholder'],
		},
		clientView: {
			id: 'client.clientView',
			defaultMessage: defaults['client.clientView'],
		},
		clientApproveError: {
			id: 'client.clientApproveError',
			defaultMessage: defaults['client.clientApproveError'],
		},
		confirmRTN: {
			id: 'client.confirmRTN',
			defaultMessage: defaults['client.confirmRTN'],
		},
		rejectMessage: {
			id: 'client.rejectMessage',
			defaultMessage: defaults['client.rejectMessage'],
		},
		confirmReject: {
			id: 'client.confirmReject',
			defaultMessage: defaults['client.confirmReject'],
		},
		rejectedForThisReason: {
			id: 'client.rejectedForThisReason',
			defaultMessage: defaults['client.rejectedForThisReason'],
		},
		activityLog: {
			id: 'client.activityLog',
			defaultMessage: defaults['client.activityLog'],
		},
		linkClient: {
			id: 'client.linkClient',
			defaultMessage: defaults['client.linkClient'],
		},
		createClient: {
			id: 'client.createClient',
			defaultMessage: defaults['client.createClient'],
		},
		clientApproved: {
			id: 'client.clientApproved',
			defaultMessage: defaults['client.clientApproved'],
		},
		cantLinkClients: {
			id: 'client.cantLinkClients',
			defaultMessage: defaults['client.cantLinkClients'],
		},
		DisableClientError: {
			id: 'client.DisableClientError',
			defaultMessage: defaults['client.DisableClientError'],
		},
		EnableClientError: {
			id: 'client.EnableClientError',
			defaultMessage: defaults['client.EnableClientError'],
		},
		GetClientInfoError: {
			id: 'client.GetClientInfoError',
			defaultMessage: defaults['client.GetClientInfoError'],
		},
		GetClientsMetaDataError: {
			id: 'client.GetClientsMetaDataError',
			defaultMessage: defaults['client.GetClientsMetaDataError'],
		},
		GetClientsError: {
			id: 'client.GetClientsError',
			defaultMessage: defaults['client.GetClientsError'],
		},
		GetTagsError: {
			id: 'client.GetTagsError',
			defaultMessage: defaults['client.GetTagsError'],
		},
		DeleteClientError: {
			id: 'client.DeleteClientError',
			defaultMessage: defaults['client.DeleteClientError'],
		},
		AddClientError: {
			id: 'client.AddClientError',
			defaultMessage: defaults['client.AddClientError'],
		},
		EditClientError: {
			id: 'client.EditClientError',
			defaultMessage: defaults['client.EditClientError'],
		},
		createClientCode: {
			id: 'client.createClientCode',
			defaultMessage: defaults['client.createClientCode'],
		},
		createClientError: {
			id: 'client.createClientError',
			defaultMessage: defaults['client.createClientError'],
		},
		createClientEmailMissing: {
			id: 'client.createClientEmailMissing',
			defaultMessage: defaults['client.createClientEmailMissing'],
		},
		connectExternalApp: {
			id: 'client.connectExternalApp',
			defaultMessage: defaults['client.connectExternalApp'],
		},
		createClientUsernameError: {
			id: 'client.createClientUsernameError',
			defaultMessage: defaults['client.createClientUsernameError'],
		},
		createClientEmailError: {
			id: 'client.createClientEmailError',
			defaultMessage: defaults['client.createClientEmailError'],
		},
		createClientDataError: {
			id: 'client.createClientDataError',
			defaultMessage: defaults['client.createClientDataError'],
		},
		clientLinkSuccess: {
			id: 'client.clientLinkSuccess',
			defaultMessage: defaults['client.clientLinkSuccess'],
		},
		clientRejectSuccess: {
			id: 'client.clientRejectSuccess',
			defaultMessage: defaults['client.clientRejectSuccess'],
		},
		clientRejectError: {
			id: 'client.clientRejectError',
			defaultMessage: defaults['client.clientRejectError'],
		},
		clientEnableMessage: {
			id: 'client.clientEnableMessage',
			defaultMessage: defaults['client.clientEnableMessage'],
		},
		clientDisableMessage: {
			id: 'client.clientDisableMessage',
			defaultMessage: defaults['client.clientDisableMessage'],
		},
		clientEnabled: {
			id: 'client.clientEnabled',
			defaultMessage: defaults['client.clientEnabled'],
		},
		clientDisabled: {
			id: 'client.clientDisabled',
			defaultMessage: defaults['client.clientDisabled'],
		},
		removeTelephone: {
			id: 'client.removeTelephone',
			defaultMessage: defaults['client.removeTelephone'],
		},
		addExtension: {
			id: 'client.addExtension',
			defaultMessage: defaults['client.addExtension'],
		},
		removeExtension: {
			id: 'client.removeExtension',
			defaultMessage: defaults['client.removeExtension'],
		},
		removeEmail: {
			id: 'client.removeEmail',
			defaultMessage: defaults['client.removeEmail'],
		},
		removeCustomPrice: {
			id: 'client.removeCustomPrice',
			defaultMessage: defaults['client.removeCustomPrice'],
		},
		emailOwner: {
			id: 'client.emailOwner',
			defaultMessage: defaults['client.emailOwner'],
		},
		telephoneOwner: {
			id: 'client.telephoneOwner',
			defaultMessage: defaults['client.telephoneOwner'],
		},
		extensionOwner: {
			id: 'client.extensionOwner',
			defaultMessage: defaults['client.extensionOwner'],
		},
		owner: {
			id: 'client.owner',
			defaultMessage: defaults['client.owner'],
		},
		automaticInvoicing: {
			id: 'client.automaticInvoicing',
			defaultMessage: defaults['client.automaticInvoicing'],
		},
		getClientAddressesError: {
			id: 'client.getClientAddressesError',
			defaultMessage: defaults['client.getClientAddressesError'],
		},
		pending: {
			id: 'client.pending',
			defaultMessage: defaults['client.pending'],
		},
		approved: {
			id: 'client.approved',
			defaultMessage: defaults['client.approved'],
		},
		rejected: {
			id: 'client.rejected',
			defaultMessage: defaults['client.rejected'],
		},
		existingClientSuggestions: {
			id: 'client.existingClientSuggestions',
			defaultMessage: defaults['client.existingClientSuggestions'],
		},
		disableMessage: {
			id: 'client.disableMessage',
			defaultMessage: defaults['client.disableMessage'],
		},
		disableMessageError: {
			id: 'client.disableMessageError',
			defaultMessage: defaults['client.disableMessageError'],
		},
		confirmDisable: {
			id: 'client.confirmDisable',
			defaultMessage: defaults['client.confirmDisable'],
		},
		activeDevices: {
			id: 'client.activeDevices',
			defaultMessage: defaults['client.activeDevices'],
		},
		createProforma: {
			id: 'client.createProforma',
			defaultMessage: defaults['client.createProforma'],
		},
		createDetail: {
			id: 'client.createDetail',
			defaultMessage: defaults['client.createDetail'],
		},
		nextInvoiceIsRequired: {
			id: 'client.nextInvoiceIsRequired',
			defaultMessage: defaults['client.nextInvoiceIsRequired'],
		},
		nextInvoiceMonth: {
			id: 'client.nextInvoiceMonth',
			defaultMessage: defaults['client.nextInvoiceMonth'],
		},
		exonerated: {
			id: 'client.exonerated',
			defaultMessage: defaults['client.exonerated'],
		},
		rules: {
			id: 'client.rules',
			defaultMessage: defaults['client.rules'],
		},
		clientInvoiceRules: {
			id: 'client.clientInvoiceRules',
			defaultMessage: defaults['client.clientInvoiceRules'],
		},
		ruleType: {
			id: 'client.ruleType',
			defaultMessage: defaults['client.ruleType'],
		},
		addRule: {
			id: 'client.addRule',
			defaultMessage: defaults['client.addRule'],
		},
		alias: {
			id: 'client.alias',
			defaultMessage: defaults['client.alias'],
		},
		aliasBlankError: {
			id: 'client.aliasBlankError',
			defaultMessage: defaults['client.aliasBlankError'],
		},
		aliasTooLongError: {
			id: 'client.aliasTooLongError',
			defaultMessage: defaults['client.aliasTooLongError'],
		},
		ruleTypeError: {
			id: 'client.ruleTypeError',
			defaultMessage: defaults['client.ruleTypeError'],
		},
		product: {
			id: 'client.product',
			defaultMessage: defaults['client.product'],
		},
		productError: {
			id: 'client.productError',
			defaultMessage: defaults['client.productError'],
		},
		notFound: {
			id: 'client.notFound',
			defaultMessage: defaults['client.notFound'],
		},
		clientRulesAddTooltip: {
			id: 'client.clientRulesAddTooltip',
			defaultMessage: defaults['client.clientRulesAddTooltip'],
		},
		devices: {
			id: 'client.devices',
			defaultMessage: defaults['client.devices'],
		},
		devicesError: {
			id: 'client.devicesError',
			defaultMessage: defaults['client.devicesError'],
		},
		rulesInfoLoadError: {
			id: 'client.rulesInfoLoadError',
			defaultMessage: defaults['client.rulesInfoLoadError'],
		},
		active: {
			id: 'client.active',
			defaultMessage: defaults['client.active'],
		},
		hidden: {
			id: 'client.hidden',
			defaultMessage: defaults['client.hidden'],
		},
		suspended: {
			id: 'client.suspended',
			defaultMessage: defaults['client.suspended'],
		},
		cancelled: {
			id: 'client.cancelled',
			defaultMessage: defaults['client.cancelled'],
		},
		others: {
			id: 'client.others',
			defaultMessage: defaults['client.others'],
		},
		noDescription: {
			id: 'client.noDescription',
			defaultMessage: defaults['client.noDescription'],
		},
		collapse: {
			id: 'client.collapse',
			defaultMessage: defaults['client.collapse'],
		},
		expand: {
			id: 'client.expand',
			defaultMessage: defaults['client.expand'],
		},
		clientRulesSaveAllWarning: {
			id: 'client.clientRulesSaveAllWarning',
			defaultMessage: defaults['client.clientRulesSaveAllWarning'],
		},
		clientRulesSaveSuccess: {
			id: 'client.clientRulesSaveSuccess',
			defaultMessage: defaults['client.clientRulesSaveSuccess'],
		},
		clientRulesSaveError: {
			id: 'client.clientRulesSaveError',
			defaultMessage: defaults['client.clientRulesSaveError'],
		},
		ruleIsActive: {
			id: 'client.ruleIsActive',
			defaultMessage: defaults['client.ruleIsActive'],
		},
		ruleIsInactive: {
			id: 'client.ruleIsInactive',
			defaultMessage: defaults['client.ruleIsInactive'],
		},
		ruleActive: {
			id: 'client.ruleActive',
			defaultMessage: defaults['client.ruleActive'],
		},
		ruleInactive: {
			id: 'client.ruleInactive',
			defaultMessage: defaults['client.ruleInactive'],
		},
		status: {
			id: 'client.status',
			defaultMessage: defaults['client.status'],
		},
		leasedDevices: {
			id: 'client.leasedDevices',
			defaultMessage: defaults['client.leasedDevices'],
		},
		leasedDevicesLoadError: {
			id: 'client.leasedDevicesLoadError',
			defaultMessage: defaults['client.leasedDevicesLoadError'],
		},
		leasedDevicesStateChangeError: {
			id: 'client.leasedDevicesStateChangeError',
			defaultMessage: defaults['client.leasedDevicesStateChangeError'],
		},
		leased: {
			id: 'client.leased',
			defaultMessage: defaults['client.leased'],
		},
		routes: {
			id: 'client.routes',
			defaultMessage: defaults['client.routes'],
		},
		deviceSearch: {
			id: 'client.deviceSearch',
			defaultMessage: defaults['client.deviceSearch'],
		},
		monthlyPayments: {
			id: 'client.monthlyPayments',
			defaultMessage: defaults['client.monthlyPayments'],
		},
		leases: {
			id: 'client.leases',
			defaultMessage: defaults['client.leases'],
		},
		monthlyDevices: {
			id: 'client.monthlyDevices',
			defaultMessage: defaults['client.monthlyDevices'],
		},
		clientTotal: {
			id: 'client.clientTotal',
			defaultMessage: defaults['client.clientTotal'],
		},
		monthly: {
			id: 'client.monthly',
			defaultMessage: defaults['client.monthly'],
		},
		everyMonth: {
			id: 'client.everyMonth',
			defaultMessage: defaults['client.everyMonth'],
		},
		everyXmonths: {
			id: 'client.everyXmonths',
			defaultMessage: defaults['client.everyXmonths'],
		},
		recurrence: {
			id: 'client.recurrence',
			defaultMessage: defaults['client.recurrence'],
		},
		recurrenceRequiredError: {
			id: 'client.recurrenceRequiredError',
			defaultMessage: defaults['client.recurrenceRequiredError'],
		},
		noRecurrence: {
			id: 'client.noRecurrence',
			defaultMessage: defaults['client.noRecurrence'],
		},
		monthlyRecurrence: {
			id: 'client.monthlyRecurrence',
			defaultMessage: defaults['client.monthlyRecurrence'],
		},
		lastInvoicedMonth: {
			id: 'client.lastInvoicedMonth',
			defaultMessage: defaults['client.lastInvoicedMonth'],
		},
		lastInvoicedMonthRequiredError: {
			id: 'client.lastInvoicedMonthRequiredError',
			defaultMessage: defaults['client.lastInvoicedMonthRequiredError'],
		},
		noLastInvoicedMonth: {
			id: 'client.noLastInvoicedMonth',
			defaultMessage: defaults['client.noLastInvoicedMonth'],
		},
		recurrenceEvery: {
			id: 'client.recurrenceEvery',
			defaultMessage: defaults['client.recurrenceEvery'],
		},
		month: {
			id: 'client.month',
			defaultMessage: defaults['client.month'],
		},
		months: {
			id: 'client.months',
			defaultMessage: defaults['client.months'],
		},
		noRepresentative: {
			id: 'client.noRepresentative',
			defaultMessage: defaults['client.noRepresentative'],
		},
		notUniqueEmailError: {
			id: 'client.notUniqueEmailError',
			defaultMessage: defaults['client.notUniqueEmailError'],
		},
		checkEmailError: {
			id: 'client.checkEmailError',
			defaultMessage: defaults['client.checkEmailError'],
		},
		validatingEmail: {
			id: 'client.validatingEmail',
			defaultMessage: defaults['client.validatingEmail'],
		},
		uniqueEmailNeeded: {
			id: 'client.uniqueEmailNeeded',
			defaultMessage: defaults['client.uniqueEmailNeeded'],
		},
		currencyToCharge: {
			id: 'client.currencyToCharge',
			defaultMessage: defaults['client.currencyToCharge'],
		},
		currencyDollar: {
			id: 'client.currencyDollar',
			defaultMessage: defaults['client.currencyDollar'],
		},
		currencyLempira: {
			id: 'client.currencyLempira',
			defaultMessage: defaults['client.currencyLempira'],
		},
		optimusClient: {
			id: 'client.optimusClient',
			defaultMessage: defaults['client.optimusClient'],
		},
		rastreoClient: {
			id: 'client.rastreoClient',
			defaultMessage: defaults['client.rastreoClient'],
		},
		optimusClientRequiredError: {
			id: 'client.optimusClientRequiredError',
			defaultMessage: defaults['client.optimusClientRequiredError'],
		},
		getOptimusLinkableClientsError: {
			id: 'client.getOptimusLinkableClientsError',
			defaultMessage: defaults['client.getOptimusLinkableClientsError'],
		},
		getRastreoLinkableClientsError: {
			id: 'client.getRastreoLinkableClientsError',
			defaultMessage: defaults['client.getRastreoLinkableClientsError'],
		},
		createRastreoClientError: {
			id: 'client.createRastreoClientError',
			defaultMessage: defaults['client.createRastreoClientError'],
		},
		tryingToCreateClientWithNoCodeError: {
			id: 'client.tryingToCreateClientWithNoCodeError',
			defaultMessage: defaults['client.tryingToCreateClientWithNoCodeError'],
		},
		clientTiers: {
			id: 'client.clientTiers',
			defaultMessage: defaults['client.clientTiers'],
		},
		clientTiersConfig: {
			id: 'client.clientTiersConfig',
			defaultMessage: defaults['client.clientTiersConfig'],
		},
		saveClientTiersSuccess: {
			id: 'client.saveClientTiersSuccess',
			defaultMessage: defaults['client.saveClientTiersSuccess'],
		},
		saveClientTiersError: {
			id: 'client.saveClientTiersError',
			defaultMessage: defaults['client.saveClientTiersError'],
		},
		loadClientTiersError: {
			id: 'client.loadClientTiersError',
			defaultMessage: defaults['client.loadClientTiersError'],
		},
		addTier: {
			id: 'client.addTier',
			defaultMessage: defaults['client.addTier'],
		},
		removeTier: {
			id: 'client.removeTier',
			defaultMessage: defaults['client.removeTier'],
		},
		badgeColor: {
			id: 'client.badgeColor',
			defaultMessage: defaults['client.badgeColor'],
		},
		badgeColorRequiredError: {
			id: 'client.badgeColorRequiredError',
			defaultMessage: defaults['client.badgeColorRequiredError'],
		},
		tierNameRequiredError: {
			id: 'client.tierNameRequiredError',
			defaultMessage: defaults['client.tierNameRequiredError'],
		},
		tierNameLengthError: {
			id: 'client.tierNameLengthError',
			defaultMessage: defaults['client.tierNameLengthError'],
		},
		gracePeriodDays: {
			id: 'client.gracePeriodDays',
			defaultMessage: defaults['client.gracePeriodDays'],
		},
		gracePeriodDaysRequiredError: {
			id: 'client.gracePeriodDaysRequiredError',
			defaultMessage: defaults['client.gracePeriodDaysRequiredError'],
		},
		tierName: {
			id: 'client.tierName',
			defaultMessage: defaults['client.tierName'],
		},
		clientTier: {
			id: 'client.clientTier',
			defaultMessage: defaults['client.clientTier'],
		},
		clientHasNoTierAssigned: {
			id: 'client.clientHasNoTierAssigned',
			defaultMessage: defaults['client.clientHasNoTierAssigned'],
		},
		clientsWithDeletedTiersError: {
			id: 'client.clientsWithDeletedTiersError',
			defaultMessage: defaults['client.clientsWithDeletedTiersError'],
		},
		clientTierRequiredError: {
			id: 'client.clientTierRequiredError',
			defaultMessage: defaults['client.clientTierRequiredError'],
		},
		accounts: {
			id: 'client.accounts',
			defaultMessage: defaults['client.accounts'],
		},
		clientAccounts: {
			id: 'client.clientAccounts',
			defaultMessage: defaults['client.clientAccounts'],
		},
		getClientAccountsError: {
			id: 'client.getClientAccountsError',
			defaultMessage: defaults['client.getClientAccountsError'],
		},
		showInactiveAccounts: {
			id: 'client.showInactiveAccounts',
			defaultMessage: defaults['client.showInactiveAccounts'],
		},
		hideInactiveAccounts: {
			id: 'client.hideInactiveAccounts',
			defaultMessage: defaults['client.hideInactiveAccounts'],
		},
		programmedDeactivation: {
			id: 'client.programmedDeactivation',
			defaultMessage: defaults['client.programmedDeactivation'],
		},
		getClientProgrammedDeactivationDataError: {
			id: 'client.getClientProgrammedDeactivationDataError',
			defaultMessage: defaults['client.getClientProgrammedDeactivationDataError'],
		},
		programmedDeactivationDate: {
			id: 'client.programmedDeactivationDate',
			defaultMessage: defaults['client.programmedDeactivationDate'],
		},
		programmedDeactivationDateError: {
			id: 'client.programmedDeactivationDateError',
			defaultMessage: defaults['client.programmedDeactivationDateError'],
		},
		programmedDeactivationDateChangeConfirm: {
			id: 'client.programmedDeactivationDateChangeConfirm',
			defaultMessage: defaults['client.programmedDeactivationDateChangeConfirm'],
		},
		programmedDeactivationDateClearConfirm: {
			id: 'client.programmedDeactivationDateClearConfirm',
			defaultMessage: defaults['client.programmedDeactivationDateClearConfirm'],
		},
		firstConfirmation: {
			id: 'client.firstConfirmation',
			defaultMessage: defaults['client.firstConfirmation'],
		},
		dateBefore: {
			id: 'client.dateBefore',
			defaultMessage: defaults['client.dateBefore'],
		},
		sameDate: {
			id: 'client.sameDate',
			defaultMessage: defaults['client.sameDate'],
		},
		deactivationLog: {
			id: 'client.deactivationLog',
			defaultMessage: defaults['client.deactivationLog'],
		},
		getClientDeactivationLogsError: {
			id: 'client.getClientDeactivationLogsError',
			defaultMessage: defaults['client.getClientDeactivationLogsError'],
		},
		programmedDeactivations: {
			id: 'client.programmedDeactivations',
			defaultMessage: defaults['client.programmedDeactivations'],
		},
		assignProgrammedDeactivations: {
			id: 'client.assignProgrammedDeactivations',
			defaultMessage: defaults['client.assignProgrammedDeactivations'],
		},
		getClientDeactivationsError: {
			id: 'client.getClientDeactivationsError',
			defaultMessage: defaults['client.getClientDeactivationsError'],
		},
		checkingProgrammedDeactivation: {
			id: 'client.checkingProgrammedDeactivation',
			defaultMessage: defaults['client.checkingProgrammedDeactivation'],
		},
		checkClientDeactivationDateError: {
			id: 'client.checkClientDeactivationDateError',
			defaultMessage: defaults['client.checkClientDeactivationDateError'],
		},
		checkClientDeactivationDateQuestion: {
			id: 'client.checkClientDeactivationDateQuestion',
			defaultMessage: defaults['client.checkClientDeactivationDateQuestion'],
		},
		recurrentProducts: {
			id: 'client.recurrentProducts',
			defaultMessage: defaults['client.recurrentProducts'],
		},
		clientRecurrentProductsSaveSuccess: {
			id: 'client.clientRecurrentProductsSaveSuccess',
			defaultMessage: defaults['client.clientRecurrentProductsSaveSuccess'],
		},
		clientRecurrentProductsSaveError: {
			id: 'client.clientRecurrentProductsSaveError',
			defaultMessage: defaults['client.clientRecurrentProductsSaveError'],
		},
		clientRecurrentProductsLoadError: {
			id: 'client.clientRecurrentProductsLoadError',
			defaultMessage: defaults['client.clientRecurrentProductsLoadError'],
		},
		addRecurrentProduct: {
			id: 'client.addRecurrentProduct',
			defaultMessage: defaults['client.addRecurrentProduct'],
		},
		productToCharge: {
			id: 'client.productToCharge',
			defaultMessage: defaults['client.productToCharge'],
		},
		productToChargeRequiredError: {
			id: 'client.productToChargeRequiredError',
			defaultMessage: defaults['client.productToChargeRequiredError'],
		},
		setQuantity: {
			id: 'client.setQuantity',
			defaultMessage: defaults['client.setQuantity'],
		},
		setQuantityRequiredError: {
			id: 'client.setQuantityRequiredError',
			defaultMessage: defaults['client.setQuantityRequiredError'],
		},
		quantityToCharge: {
			id: 'client.quantityToCharge',
			defaultMessage: defaults['client.quantityToCharge'],
		},
		rule: {
			id: 'client.rule',
			defaultMessage: defaults['client.rule'],
		},
		applyToSpecificRule: {
			id: 'client.applyToSpecificRule',
			defaultMessage: defaults['client.applyToSpecificRule'],
		},
		willOnlyBeAppliedToBaseClient: {
			id: 'client.willOnlyBeAppliedToBaseClient',
			defaultMessage: defaults['client.willOnlyBeAppliedToBaseClient'],
		},
		quantityTypeRequiredError: {
			id: 'client.quantityTypeRequiredError',
			defaultMessage: defaults['client.quantityTypeRequiredError'],
		},
		perDevice: {
			id: 'client.perDevice',
			defaultMessage: defaults['client.perDevice'],
		},
		deletedClientRuleHasRecurrentProductsError: {
			id: 'client.deletedClientRuleHasRecurrentProductsError',
			defaultMessage: defaults['client.deletedClientRuleHasRecurrentProductsError'],
		},
		service: {
			id: 'client.service',
			defaultMessage: defaults['client.service'],
		},
		addClientContact: {
			id: 'client.addClientContact',
			defaultMessage: defaults['client.addClientContact'],
		},
		addClientContactSuccess: {
			id: 'client.addClientContactSuccess',
			defaultMessage: defaults['client.addClientContactSuccess'],
		},
		addClientContactError: {
			id: 'client.addClientContactError',
			defaultMessage: defaults['client.addClientContactError'],
		},
		deleteDeactivationClientSuccess: {
			id: 'client.deleteDeactivationClientSuccess',
			defaultMessage: defaults['client.deleteDeactivationClientSuccess'],
		},
	});
};

export const getAddressMessages = () => {
	return defineMessages({
		address: {
			id: 'address.address',
			defaultMessage: defaults['address.address'],
		},
		city: {
			id: 'address.city',
			defaultMessage: defaults['address.city'],
		},
		state: {
			id: 'address.state',
			defaultMessage: defaults['address.state'],
		},
		addressError: {
			id: 'address.addressError',
			defaultMessage: defaults['address.addressError'],
		},
		cityError: {
			id: 'address.cityError',
			defaultMessage: defaults['address.cityError'],
		},
		stateError: {
			id: 'address.stateError',
			defaultMessage: defaults['address.stateError'],
		},
		addressLengthError: {
			id: 'address.addressLengthError',
			defaultMessage: defaults['address.addressLengthError'],
		},
		noAddressError: {
			id: 'address.noAddressError',
			defaultMessage: defaults['address.noAddressError'],
		},
		createNewAddress: {
			id: 'address.createNewAddress',
			defaultMessage: defaults['address.createNewAddress'],
		},
		chooseExistingAddress: {
			id: 'address.chooseExistingAddress',
			defaultMessage: defaults['address.chooseExistingAddress'],
		},
	});
};

export const getJobMessages = () => {
	return defineMessages({
		job: {
			id: 'job.job',
			defaultMessage: defaults['job.job'],
		},
		jobClient: {
			id: 'job.jobClient',
			defaultMessage: defaults['job.jobClient'],
		},
		todo: {
			id: 'job.todo',
			defaultMessage: defaults['job.todo'],
		},
		doing: {
			id: 'job.doing',
			defaultMessage: defaults['job.doing'],
		},
		missingWON: {
			id: 'job.missingWON',
			defaultMessage: defaults['job.missingWON'],
		},
		done: {
			id: 'job.done',
			defaultMessage: defaults['job.done'],
		},
		cancelled: {
			id: 'job.cancelled',
			defaultMessage: defaults['job.cancelled'],
		},
		annulled: {
			id: 'job.annulled',
			defaultMessage: defaults['job.annulled'],
		},
		addJob: {
			id: 'job.addJob',
			defaultMessage: defaults['job.addJob'],
		},
		editJob: {
			id: 'job.editJob',
			defaultMessage: defaults['job.editJob'],
		},
		jobType: {
			id: 'job.jobType',
			defaultMessage: defaults['job.jobType'],
		},
		mainProduct: {
			id: 'job.mainProduct',
			defaultMessage: defaults['job.mainProduct'],
		},
		installation: {
			id: 'job.installation',
			defaultMessage: defaults['job.installation'],
		},
		revision: {
			id: 'job.revision',
			defaultMessage: defaults['job.revision'],
		},
		uninstallation: {
			id: 'job.uninstallation',
			defaultMessage: defaults['job.uninstallation'],
		},
		swap: {
			id: 'job.swap',
			defaultMessage: defaults['job.swap'],
		},
		guarantee: {
			id: 'job.guarantee',
			defaultMessage: defaults['job.guarantee'],
		},
		addonsJobType: {
			id: 'job.addonsJobType',
			defaultMessage: defaults['job.addonsJobType'],
		},
		simChange: {
			id: 'job.simChange',
			defaultMessage: defaults['job.simChange'],
		},
		transfer: {
			id: 'job.transfer',
			defaultMessage: defaults['job.transfer'],
		},
		reconnection: {
			id: 'job.reconnection',
			defaultMessage: defaults['job.reconnection'],
		},
		deactivate: {
			id: 'job.deactivate',
			defaultMessage: defaults['job.deactivate'],
		},
		activate: {
			id: 'job.activate',
			defaultMessage: defaults['job.activate'],
		},
		programmedDate: {
			id: 'job.programmedDate',
			defaultMessage: defaults['job.programmedDate'],
		},
		sim: {
			id: 'job.sim',
			defaultMessage: defaults['job.sim'],
		},
		icon: {
			id: 'job.icon',
			defaultMessage: defaults['job.icon'],
		},
		vehicleDescription: {
			id: 'job.vehicleDescription',
			defaultMessage: defaults['job.vehicleDescription'],
		},
		addons: {
			id: 'job.addons',
			defaultMessage: defaults['job.addons'],
		},
		clientChangeModalContent: {
			id: 'job.clientChangeModalContent',
			defaultMessage: defaults['job.clientChangeModalContent'],
		},
		carIcon: {
			id: 'job.carIcon',
			defaultMessage: defaults['job.carIcon'],
		},
		motorBikeIcon: {
			id: 'job.motorBikeIcon',
			defaultMessage: defaults['job.motorBikeIcon'],
		},
		truckIcon: {
			id: 'job.truckIcon',
			defaultMessage: defaults['job.truckIcon'],
		},
		addToolTip: {
			id: 'job.addToolTip',
			defaultMessage: defaults['job.addToolTip'],
		},
		addCloseToolTip: {
			id: 'job.addCloseToolTip',
			defaultMessage: defaults['job.addCloseToolTip'],
		},
		editToolTip: {
			id: 'job.editToolTip',
			defaultMessage: defaults['job.editToolTip'],
		},
		duration: {
			id: 'job.duration',
			defaultMessage: defaults['job.duration'],
		},
		durationTooltip: {
			id: 'job.durationTooltip',
			defaultMessage: defaults['job.durationTooltip'],
		},
		jobView: {
			id: 'job.jobView',
			defaultMessage: defaults['job.jobView'],
		},
		deviceSearch: {
			id: 'job.deviceSearch',
			defaultMessage: defaults['job.deviceSearch'],
		},
		noJobsToday: {
			id: 'job.noJobsToday',
			defaultMessage: defaults['job.noJobsToday'],
		},
		durationInMinutes: {
			id: 'job.durationInMinutes',
			defaultMessage: defaults['job.durationInMinutes'],
		},
		cancelJob: {
			id: 'job.cancelJob',
			defaultMessage: defaults['job.cancelJob'],
		},
		cancelReason: {
			id: 'job.cancelReason',
			defaultMessage: defaults['job.cancelReason'],
		},
		confirmJobCancel: {
			id: 'job.confirmJobCancel',
			defaultMessage: defaults['job.confirmJobCancel'],
		},
		moveToDoing: {
			id: 'job.moveToDoing',
			defaultMessage: defaults['job.moveToDoing'],
		},
		moveToDone: {
			id: 'job.moveToDone',
			defaultMessage: defaults['job.moveToDone'],
		},
		duplicate: {
			id: 'job.duplicate',
			defaultMessage: defaults['job.duplicate'],
		},
		showAllEventTypes: {
			id: 'job.showAllEventTypes',
			defaultMessage: defaults['job.showAllEventTypes'],
		},
		showAllEventStatus: {
			id: 'job.showAllEventStatus',
			defaultMessage: defaults['job.showAllEventStatus'],
		},
		showAllEvents: {
			id: 'job.showAllEvents',
			defaultMessage: defaults['job.showAllEvents'],
		},
		undefinedTypeEvents: {
			id: 'job.undefinedTypeEvents',
			defaultMessage: defaults['job.undefinedTypeEvents'],
		},
		undefinedStatusEvents: {
			id: 'job.undefinedStatusEvents',
			defaultMessage: defaults['job.undefinedStatusEvents'],
		},
		undefinedEvents: {
			id: 'job.undefinedEvents',
			defaultMessage: defaults['job.undefinedEvents'],
		},
		hours: {
			id: 'job.hours',
			defaultMessage: defaults['job.hours'],
		},
		with: {
			id: 'job.with',
			defaultMessage: defaults['job.with'],
		},
		minutes: {
			id: 'job.minutes',
			defaultMessage: defaults['job.minutes'],
		},
		clientKeepsDevice: {
			id: 'job.clientKeepsDevice',
			defaultMessage: defaults['job.clientKeepsDevice'],
		},
		instantDeactivation: {
			id: 'job.instantDeactivation',
			defaultMessage: defaults['job.instantDeactivation'],
		},
		creatingUser: {
			id: 'job.creatingUser',
			defaultMessage: defaults['job.creatingUser'],
		},
		workOrderNumber: {
			id: 'job.workOrderNumber',
			defaultMessage: defaults['job.workOrderNumber'],
		},
		orderId: {
			id: 'job.orderId',
			defaultMessage: defaults['job.orderId'],
		},
		SearchGPSDevicesError: {
			id: 'job.SearchGPSDevicesError',
			defaultMessage: defaults['job.SearchGPSDevicesError'],
		},
		SearchSimCardsError: {
			id: 'job.SearchSimCardsError',
			defaultMessage: defaults['job.SearchSimCardsError'],
		},
		imei: {
			id: 'job.imei',
			defaultMessage: defaults['job.imei'],
		},
		pin: {
			id: 'job.pin',
			defaultMessage: defaults['job.pin'],
		},
		noImei: {
			id: 'job.noImei',
			defaultMessage: defaults['job.noImei'],
		},
		simLineSerial: {
			id: 'job.simLineSerial',
			defaultMessage: defaults['job.simLineSerial'],
		},
		typeDefault: {
			id: 'job.typeDefault',
			defaultMessage: defaults['job.typeDefault'],
		},
		statusDefault: {
			id: 'job.statusDefault',
			defaultMessage: defaults['job.statusDefault'],
		},
		createDevice: {
			id: 'job.createDevice',
			defaultMessage: defaults['job.createDevice'],
		},
		useExistingDevice: {
			id: 'job.useExistingDevice',
			defaultMessage: defaults['job.useExistingDevice'],
		},
		getDeviceModelsError: {
			id: 'job.getDeviceModelsError',
			defaultMessage: defaults['job.getDeviceModelsError'],
		},
		deviceModel: {
			id: 'job.deviceModel',
			defaultMessage: defaults['job.deviceModel'],
		},
		deviceModelError: {
			id: 'job.deviceModelError',
			defaultMessage: defaults['job.deviceModelError'],
		},
		dataport: {
			id: 'job.dataport',
			defaultMessage: defaults['job.dataport'],
		},
		dataportError: {
			id: 'job.dataportError',
			defaultMessage: defaults['job.dataportError'],
		},
		firmwareVersion: {
			id: 'job.firmwareVersion',
			defaultMessage: defaults['job.firmwareVersion'],
		},
		checkPinError: {
			id: 'job.checkPinError',
			defaultMessage: defaults['job.checkPinError'],
		},
		notUniquePinError: {
			id: 'job.notUniquePinError',
			defaultMessage: defaults['job.notUniquePinError'],
		},
		doesNotHave: {
			id: 'job.doesNotHave',
			defaultMessage: defaults['job.doesNotHave'],
		},
		confirmCancel: {
			id: 'job.confirmCancel',
			defaultMessage: defaults['job.confirmCancel'],
		},
		confirmAnnul: {
			id: 'job.confirmAnnul',
			defaultMessage: defaults['job.confirmAnnul'],
		},
		annulReason: {
			id: 'job.annulReason',
			defaultMessage: defaults['job.annulReason'],
		},
		annulMessageError: {
			id: 'job.annulMessageError',
			defaultMessage: defaults['job.annulMessageError'],
		},
		annul: {
			id: 'job.annul',
			defaultMessage: defaults['job.annul'],
		},
		getDeviceModelProfilesError: {
			id: 'job.getDeviceModelProfilesError',
			defaultMessage: defaults['job.getDeviceModelProfilesError'],
		},
		modelProfile: {
			id: 'job.modelProfile',
			defaultMessage: defaults['job.modelProfile'],
		},
		modelProfileError: {
			id: 'job.modelProfileError',
			defaultMessage: defaults['job.modelProfileError'],
		},
		expand: {
			id: 'job.expand',
			defaultMessage: defaults['job.expand'],
		},
		collapse: {
			id: 'job.collapse',
			defaultMessage: defaults['job.collapse'],
		},
		enabled: {
			id: 'job.enabled',
			defaultMessage: defaults['job.enabled'],
		},
		disabled: {
			id: 'job.disabled',
			defaultMessage: defaults['job.disabled'],
		},
		holidayWarning: {
			id: 'job.holidayWarning',
			defaultMessage: defaults['job.holidayWarning'],
		},
		bought: {
			id: 'job.bought',
			defaultMessage: defaults['job.bought'],
		},
		leased: {
			id: 'job.leased',
			defaultMessage: defaults['job.leased'],
		},
		debt: {
			id: 'job.debt',
			defaultMessage: defaults['job.debt'],
		},
		perCut: {
			id: 'job.perCut',
			defaultMessage: defaults['job.perCut'],
		},
		newClient: {
			id: 'job.newClient',
			defaultMessage: defaults['job.newClient'],
		},
		previousClient: {
			id: 'job.previousClient',
			defaultMessage: defaults['job.previousClient'],
		},
		replacement: {
			id: 'job.replacement',
			defaultMessage: defaults['job.replacement'],
		},
		invoicing: {
			id: 'job.invoicing',
			defaultMessage: defaults['job.invoicing'],
		},
		invoice: {
			id: 'job.invoice',
			defaultMessage: defaults['job.invoice'],
		},
		invoiced: {
			id: 'job.invoiced',
			defaultMessage: defaults['job.invoiced'],
		},
		proforma: {
			id: 'job.proforma',
			defaultMessage: defaults['job.proforma'],
		},
		link: {
			id: 'job.link',
			defaultMessage: defaults['job.link'],
		},
		linkJobs: {
			id: 'job.linkJobs',
			defaultMessage: defaults['job.linkJobs'],
		},
		invoices: {
			id: 'job.invoices',
			defaultMessage: defaults['job.invoices'],
		},
		leasedDevice: {
			id: 'job.leasedDevice',
			defaultMessage: defaults['job.leasedDevice'],
		},
		deviceReminder: {
			id: 'job.deviceReminder',
			defaultMessage: defaults['job.deviceReminder'],
		},
		unableToSearchClients: {
			id: 'job.unableToSearchClients',
			defaultMessage: defaults['job.unableToSearchClients'],
		},
		unableToSearchDevices: {
			id: 'job.unableToSearchDevices',
			defaultMessage: defaults['job.unableToSearchDevices'],
		},
		active: {
			id: 'job.active',
			defaultMessage: defaults['job.active'],
		},
		inactiveHidden: {
			id: 'job.inactiveHidden',
			defaultMessage: defaults['job.inactiveHidden'],
		},
		dateIsRequired: {
			id: 'job.dateIsRequired',
			defaultMessage: defaults['job.dateIsRequired'],
		},
		deviceStatus: {
			id: 'job.deviceStatus',
			defaultMessage: defaults['job.deviceStatus'],
		},
		deviceStatusError: {
			id: 'job.deviceStatusError',
			defaultMessage: defaults['job.deviceStatusError'],
		},
		hide: {
			id: 'job.hide',
			defaultMessage: defaults['job.hide'],
		},
		cancel: {
			id: 'job.cancel',
			defaultMessage: defaults['job.cancel'],
		},
		clientKeepsDeviceError: {
			id: 'job.clientKeepsDeviceError',
			defaultMessage: defaults['job.clientKeepsDeviceError'],
		},
		yes: {
			id: 'job.yes',
			defaultMessage: defaults['job.yes'],
		},
		no: {
			id: 'job.no',
			defaultMessage: defaults['job.no'],
		},
		deactivatePastSixError: {
			id: 'job.deactivatePastSixError',
			defaultMessage: defaults['job.deactivatePastSixError'],
		},
		noTechJob: {
			id: 'job.noTechJob',
			defaultMessage: defaults['job.noTechJob'],
		},
		vehicleDescriptionLengthError: {
			id: 'job.vehicleDescriptionLengthError',
			defaultMessage: defaults['job.vehicleDescriptionLengthError'],
		},
		optionalSecondClient: {
			id: 'job.optionalSecondClient',
			defaultMessage: defaults['job.optionalSecondClient'],
		},
		optionalSecondClientTooltip: {
			id: 'job.optionalSecondClientTooltip',
			defaultMessage: defaults['job.optionalSecondClientTooltip'],
		},
		inactive: {
			id: 'job.inactive',
			defaultMessage: defaults['job.inactive'],
		},
		unableToSearchSimCards: {
			id: 'job.unableToSearchSimCards',
			defaultMessage: defaults['job.unableToSearchSimCards'],
		},
		uninstallSim: {
			id: 'job.uninstallSim',
			defaultMessage: defaults['job.uninstallSim'],
		},
		uninstallSimError: {
			id: 'job.uninstallSimError',
			defaultMessage: defaults['job.uninstallSimError'],
		},
		jobAddSuccess: {
			id: 'job.jobAddSuccess',
			defaultMessage: defaults['job.jobAddSuccess'],
		},
		jobAddError: {
			id: 'job.jobAddError',
			defaultMessage: defaults['job.jobAddError'],
		},
		activityLog: {
			id: 'job.activityLog',
			defaultMessage: defaults['job.activityLog'],
		},
		noJobTypeForm: {
			id: 'job.noJobTypeForm',
			defaultMessage: defaults['job.noJobTypeForm'],
		},
		jobCommentLengthError: {
			id: 'job.jobCommentLengthError',
			defaultMessage: defaults['job.jobCommentLengthError'],
		},
		jobCommentWhitespaceError: {
			id: 'job.jobCommentWhitespaceError',
			defaultMessage: defaults['job.jobCommentWhitespaceError'],
		},
		jobCommentAddSuccess: {
			id: 'job.jobCommentAddSuccess',
			defaultMessage: defaults['job.jobCommentAddSuccess'],
		},
		jobCommentAddError: {
			id: 'job.jobCommentAddError',
			defaultMessage: defaults['job.jobCommentAddError'],
		},
		jobUpdateSuccess: {
			id: 'job.jobUpdateSuccess',
			defaultMessage: defaults['job.jobUpdateSuccess'],
		},
		jobUpdateError: {
			id: 'job.jobUpdateError',
			defaultMessage: defaults['job.jobUpdateError'],
		},
		jobStatusUpdateSuccess: {
			id: 'job.jobStatusUpdateSuccess',
			defaultMessage: defaults['job.jobStatusUpdateSuccess'],
		},
		jobStatusUpdateError: {
			id: 'job.jobStatusUpdateError',
			defaultMessage: defaults['job.jobStatusUpdateError'],
		},
		jobAddWONSuccess: {
			id: 'job.jobAddWONSuccess',
			defaultMessage: defaults['job.jobAddWONSuccess'],
		},
		jobAddWONError: {
			id: 'job.jobAddWONError',
			defaultMessage: defaults['job.jobAddWONError'],
		},
		jobInfoSaveSuccess: {
			id: 'job.jobInfoSaveSuccess',
			defaultMessage: defaults['job.jobInfoSaveSuccess'],
		},
		jobInfoSaveError: {
			id: 'job.jobInfoSaveError',
			defaultMessage: defaults['job.jobInfoSaveError'],
		},
		jobCancelSuccess: {
			id: 'job.jobCancelSuccess',
			defaultMessage: defaults['job.jobCancelSuccess'],
		},
		jobCancelError: {
			id: 'job.jobCancelError',
			defaultMessage: defaults['job.jobCancelError'],
		},
		jobAnnulSuccess: {
			id: 'job.jobAnnulSuccess',
			defaultMessage: defaults['job.jobAnnulSuccess'],
		},
		jobAnnulError: {
			id: 'job.jobAnnulError',
			defaultMessage: defaults['job.jobAnnulError'],
		},
		jobCancelAnnulLengthError: {
			id: 'job.jobCancelAnnulLengthError',
			defaultMessage: defaults['job.jobCancelAnnulLengthError'],
		},
		jobCancelAnnulWhitespaceError: {
			id: 'job.jobCancelAnnulWhitespaceError',
			defaultMessage: defaults['job.jobCancelAnnulWhitespaceError'],
		},
		jobCancelAnnulRequiredError: {
			id: 'job.jobCancelAnnulRequiredError',
			defaultMessage: defaults['job.jobCancelAnnulRequiredError'],
		},
		chargeType: {
			id: 'job.chargeType',
			defaultMessage: defaults['job.chargeType'],
		},
		chargeTypeError: {
			id: 'job.chargeTypeError',
			defaultMessage: defaults['job.chargeTypeError'],
		},
		getJobInfoError: {
			id: 'job.getJobInfoError',
			defaultMessage: defaults['job.getJobInfoError'],
		},
		noStatusFormForType: {
			id: 'job.noStatusFormForType',
			defaultMessage: defaults['job.noStatusFormForType'],
		},
		wonLengthError: {
			id: 'job.wonLengthError',
			defaultMessage: defaults['job.wonLengthError'],
		},
		wonWhitespaceError: {
			id: 'job.wonWhitespaceError',
			defaultMessage: defaults['job.wonWhitespaceError'],
		},
		expandInformation: {
			id: 'job.expandInformation',
			defaultMessage: defaults['job.expandInformation'],
		},
		collapseInformation: {
			id: 'job.collapseInformation',
			defaultMessage: defaults['job.collapseInformation'],
		},
		jobTypeInformation: {
			id: 'job.jobTypeInformation',
			defaultMessage: defaults['job.jobTypeInformation'],
		},
		jobViewDuration: {
			id: 'job.jobViewDuration',
			defaultMessage: defaults['job.jobViewDuration'],
		},
		jobViewType: {
			id: 'job.jobViewType',
			defaultMessage: defaults['job.jobViewType'],
		},
		jobViewHour: {
			id: 'job.jobViewHour',
			defaultMessage: defaults['job.jobViewHour'],
		},
		newSimCard: {
			id: 'job.newSimCard',
			defaultMessage: defaults['job.newSimCard'],
		},
		deviceLeased: {
			id: 'job.deviceLeased',
			defaultMessage: defaults['job.deviceLeased'],
		},
		deviceLeasedError: {
			id: 'job.deviceLeasedError',
			defaultMessage: defaults['job.deviceLeasedError'],
		},
		iconsLoadError: {
			id: 'job.iconsLoadError',
			defaultMessage: defaults['job.iconsLoadError'],
		},
		pinLengthError: {
			id: 'job.pinLengthError',
			defaultMessage: defaults['job.pinLengthError'],
		},
		dataportTypeError: {
			id: 'job.dataportTypeError',
			defaultMessage: defaults['job.dataportTypeError'],
		},
		dataportLengthError: {
			id: 'job.dataportLengthError',
			defaultMessage: defaults['job.dataportLengthError'],
		},
		firmwareVersionLengthError: {
			id: 'job.firmwareVersionLengthError',
			defaultMessage: defaults['job.firmwareVersionLengthError'],
		},
		gpsDeviceToRemove: {
			id: 'job.gpsDeviceToRemove',
			defaultMessage: defaults['job.gpsDeviceToRemove'],
		},
		gpsDeviceToPut: {
			id: 'job.gpsDeviceToPut',
			defaultMessage: defaults['job.gpsDeviceToPut'],
		},
		devicePutInPlace: {
			id: 'job.devicePutInPlace',
			defaultMessage: defaults['job.devicePutInPlace'],
		},
		deviceRemoved: {
			id: 'job.deviceRemoved',
			defaultMessage: defaults['job.deviceRemoved'],
		},
		oldDeviceInfo: {
			id: 'job.oldDeviceInfo',
			defaultMessage: defaults['job.oldDeviceInfo'],
		},
		currentDeviceInfo: {
			id: 'job.currentDeviceInfo',
			defaultMessage: defaults['job.currentDeviceInfo'],
		},
		jobFinder: {
			id: 'job.jobFinder',
			defaultMessage: defaults['job.jobFinder'],
		},
		jobFinderError: {
			id: 'job.jobFinderError',
			defaultMessage: defaults['job.jobFinderError'],
		},
		jobFinderExistsError: {
			id: 'job.jobFinderExistsError',
			defaultMessage: defaults['job.jobFinderExistsError'],
		},
		jobFinderTooltip: {
			id: 'job.jobFinderTooltip',
			defaultMessage: defaults['job.jobFinderTooltip'],
		},
		swapSameSimTooltip: {
			id: 'job.swapSameSimTooltip',
			defaultMessage: defaults['job.swapSameSimTooltip'],
		},
		advancedSearch: {
			id: 'job.advancedSearch',
			defaultMessage: defaults['job.advancedSearch'],
		},
		unableToLoadUsers: {
			id: 'job.unableToLoadUsers',
			defaultMessage: defaults['job.unableToLoadUsers'],
		},
		notFound: {
			id: 'job.notFound',
			defaultMessage: defaults['job.notFound'],
		},
		searchFields: {
			id: 'job.searchFields',
			defaultMessage: defaults['job.searchFields'],
		},
		matchAllCriteria: {
			id: 'job.matchAllCriteria',
			defaultMessage: defaults['job.matchAllCriteria'],
		},
		matchAllCriteriaTrue: {
			id: 'job.matchAllCriteriaTrue',
			defaultMessage: defaults['job.matchAllCriteriaTrue'],
		},
		matchAllCriteriaFalse: {
			id: 'job.matchAllCriteriaFalse',
			defaultMessage: defaults['job.matchAllCriteriaFalse'],
		},
		search: {
			id: 'job.search',
			defaultMessage: defaults['job.search'],
		},
		jobSearchError: {
			id: 'job.jobSearchError',
			defaultMessage: defaults['job.jobSearchError'],
		},
		jobSearchResultCount: {
			id: 'job.jobSearchResultCount',
			defaultMessage: defaults['job.jobSearchResultCount'],
		},
		hasPanicButton: {
			id: 'job.hasPanicButton',
			defaultMessage: defaults['job.hasPanicButton'],
		},
		hasPanicButtonError: {
			id: 'job.hasPanicButtonError',
			defaultMessage: defaults['job.hasPanicButtonError'],
		},
		canLockUnlockDoors: {
			id: 'job.canLockUnlockDoors',
			defaultMessage: defaults['job.canLockUnlockDoors'],
		},
		canLockUnlockDoorsError: {
			id: 'job.canLockUnlockDoorsError',
			defaultMessage: defaults['job.canLockUnlockDoorsError'],
		},
		canDisableEngine: {
			id: 'job.canDisableEngine',
			defaultMessage: defaults['job.canDisableEngine'],
		},
		canDisableEngineError: {
			id: 'job.canDisableEngineError',
			defaultMessage: defaults['job.canDisableEngineError'],
		},
		panicButton: {
			id: 'job.panicButton',
			defaultMessage: defaults['job.panicButton'],
		},
		lockUnlockDoors: {
			id: 'job.lockUnlockDoors',
			defaultMessage: defaults['job.lockUnlockDoors'],
		},
		disableEngine: {
			id: 'job.disableEngine',
			defaultMessage: defaults['job.disableEngine'],
		},
		beChargedError: {
			id: 'job.beChargedError',
			defaultMessage: defaults['job.beChargedError'],
		},
		beCharged: {
			id: 'job.beCharged',
			defaultMessage: defaults['job.beCharged'],
		},
		beChargedReconnection: {
			id: 'job.beChargedReconnection',
			defaultMessage: defaults['job.beChargedReconnection'],
		},
		beChargedSwap: {
			id: 'job.beChargedSwap',
			defaultMessage: defaults['job.beChargedSwap'],
		},
		beChargedActivation: {
			id: 'job.beChargedActivation',
			defaultMessage: defaults['job.beChargedActivation'],
		},
		beChargedInstallation: {
			id: 'job.beChargedInstallation',
			defaultMessage: defaults['job.beChargedInstallation'],
		},
		linkJobsSuccess: {
			id: 'job.linkJobsSuccess',
			defaultMessage: defaults['job.linkJobsSuccess'],
		},
		linkJobsError: {
			id: 'job.linkJobsError',
			defaultMessage: defaults['job.linkJobsError'],
		},
		multipleClientsError: {
			id: 'job.multipleClientsError',
			defaultMessage: defaults['job.multipleClientsError'],
		},
		carPlate: {
			id: 'job.carPlate',
			defaultMessage: defaults['job.carPlate'],
		},
		carPlateError: {
			id: 'job.carPlateError',
			defaultMessage: defaults['job.carPlateError'],
		},
		notifyJob: {
			id: 'job.notifyJob',
			defaultMessage: defaults['job.notifyJob'],
		},
		jobsToChargeReport: {
			id: 'job.jobsToChargeReport',
			defaultMessage: defaults['job.jobsToChargeReport'],
		},

	});
};

export const getInvoicingMessages = () => {
	return defineMessages({
		FiscalData: {
			id: 'invoicing.FiscalData',
			defaultMessage: defaults['invoicing.FiscalData'],
		},
		Client: {
			id: 'invoicing.Client',
			defaultMessage: defaults['invoicing.Client'],
		},
		Product: {
			id: 'invoicing.Product',
			defaultMessage: defaults['invoicing.Product'],
		},
		Description: {
			id: 'invoicing.Description',
			defaultMessage: defaults['invoicing.Description'],
		},
		Quantity: {
			id: 'invoicing.Quantity',
			defaultMessage: defaults['invoicing.Quantity'],
		},
		Price: {
			id: 'invoicing.Price',
			defaultMessage: defaults['invoicing.Price'],
		},
		Total: {
			id: 'invoicing.Total',
			defaultMessage: defaults['invoicing.Total'],
		},
		Subtotal: {
			id: 'invoicing.Subtotal',
			defaultMessage: defaults['invoicing.Subtotal'],
		},
		Isv: {
			id: 'invoicing.Isv',
			defaultMessage: defaults['invoicing.Isv'],
		},
		InvoiceNumber: {
			id: 'invoicing.InvoiceNumber',
			defaultMessage: defaults['invoicing.InvoiceNumber'],
		},
		DocumentNumber: {
			id: 'invoicing.DocumentNumber',
			defaultMessage: defaults['invoicing.DocumentNumber'],
		},
		Status: {
			id: 'invoicing.Status',
			defaultMessage: defaults['invoicing.Status'],
		},
		Date: {
			id: 'invoicing.Date',
			defaultMessage: defaults['invoicing.Date'],
		},
		Cash: {
			id: 'invoicing.Cash',
			defaultMessage: defaults['invoicing.Cash'],
		},
		Credit: {
			id: 'invoicing.Credit',
			defaultMessage: defaults['invoicing.Credit'],
		},
		SavePrint: {
			id: 'invoicing.SavePrint',
			defaultMessage: defaults['invoicing.SavePrint'],
		},
		Print: {
			id: 'invoicing.Print',
			defaultMessage: defaults['invoicing.Print'],
		},
		Salesperson: {
			id: 'invoicing.Salesperson',
			defaultMessage: defaults['invoicing.Salesperson'],
		},
		PayDate: {
			id: 'invoicing.PayDate',
			defaultMessage: defaults['invoicing.PayDate'],
		},
		View: {
			id: 'invoicing.View',
			defaultMessage: defaults['invoicing.View'],
		},
		Actions: {
			id: 'invoicing.Actions',
			defaultMessage: defaults['invoicing.Actions'],
		},
		Type: {
			id: 'invoicing.Type',
			defaultMessage: defaults['invoicing.Type'],
		},
		DaysPayment: {
			id: 'invoicing.DaysPayment',
			defaultMessage: defaults['invoicing.DaysPayment'],
		},
		InvoiceNum: {
			id: 'invoicing.InvoiceNum',
			defaultMessage: defaults['invoicing.InvoiceNum'],
		},
		Annul: {
			id: 'invoicing.Annul',
			defaultMessage: defaults['invoicing.Annul'],
		},
		Amount: {
			id: 'invoicing.Amount',
			defaultMessage: defaults['invoicing.Amount'],
		},
		Observations: {
			id: 'invoicing.Observations',
			defaultMessage: defaults['invoicing.Observations'],
		},
		ExchangeRate: {
			id: 'invoicing.ExchangeRate',
			defaultMessage: defaults['invoicing.ExchangeRate'],
		},
		All: {
			id: 'invoicing.All',
			defaultMessage: defaults['invoicing.All'],
		},
		Active: {
			id: 'invoicing.Active',
			defaultMessage: defaults['invoicing.Active'],
		},
		Overdue: {
			id: 'invoicing.Overdue',
			defaultMessage: defaults['invoicing.Overdue'],
		},
		Paid: {
			id: 'invoicing.Paid',
			defaultMessage: defaults['invoicing.Paid'],
		},
		Annulled: {
			id: 'invoicing.Annulled',
			defaultMessage: defaults['invoicing.Annulled'],
		},
		Redo: {
			id: 'invoicing.Redo',
			defaultMessage: defaults['invoicing.Redo'],
		},
		EmailsToSend: {
			id: 'invoicing.EmailsToSend',
			defaultMessage: defaults['invoicing.EmailsToSend'],
		},
		Emails: {
			id: 'invoicing.Emails',
			defaultMessage: defaults['invoicing.Emails'],
		},
		ExactTotal: {
			id: 'invoicing.ExactTotal',
			defaultMessage: defaults['invoicing.ExactTotal'],
		},
		Save: {
			id: 'invoicing.Save',
			defaultMessage: defaults['invoicing.Save'],
		},
		SaveEmail: {
			id: 'invoicing.SaveEmail',
			defaultMessage: defaults['invoicing.SaveEmail'],
		},
		Reason: {
			id: 'invoicing.Reason',
			defaultMessage: defaults['invoicing.Reason'],
		},
		TaxExemption: {
			id: 'invoicing.TaxExemption',
			defaultMessage: defaults['invoicing.TaxExemption'],
		},
		TaxExoneration: {
			id: 'invoicing.TaxExoneration',
			defaultMessage: defaults['invoicing.TaxExoneration'],
		},
		TaxSAG: {
			id: 'invoicing.TaxSAG',
			defaultMessage: defaults['proforma.TaxSAG'],
		},
		ReminderPrint: {
			id: 'invoicing.ReminderPrint',
			defaultMessage: defaults['invoicing.ReminderPrint'],
		},
		emptyProducts: {
			id: 'invoicing.emptyProducts',
			defaultMessage: defaults['invoicing.emptyProducts'],
		},
		GetInvoicesError: {
			id: 'invoicing.GetInvoicesError',
			defaultMessage: defaults['invoicing.GetInvoicesError'],
		},
		GetInvoiceError: {
			id: 'invoicing.GetInvoiceError',
			defaultMessage: defaults['invoicing.GetInvoiceError'],
		},
		AddInvoiceError: {
			id: 'invoicing.AddInvoiceError',
			defaultMessage: defaults['invoicing.AddInvoiceError'],
		},
		GetLatestPaymentError: {
			id: 'invoicing.GetLatestPaymentError',
			defaultMessage: defaults['invoicing.GetLatestPaymentError'],
		},
		PrintError: {
			id: 'invoicing.PrintError',
			defaultMessage: defaults['invoicing.PrintError'],
		},
		CantPrint: {
			id: 'invoicing.CantPrint',
			defaultMessage: defaults['invoicing.CantPrint'],
		},
		CantEmail: {
			id: 'invoicing.CantEmail',
			defaultMessage: defaults['invoicing.CantEmail'],
		},
		EmailInvoiceError: {
			id: 'invoicing.EmailInvoiceError',
			defaultMessage: defaults['invoicing.EmailInvoiceError'],
		},
		AnnulInvoiceError: {
			id: 'invoicing.AnnulInvoiceError',
			defaultMessage: defaults['invoicing.AnnulInvoiceError'],
		},
		ChangeInvoiceError: {
			id: 'invoicing.ChangeInvoiceError',
			defaultMessage: defaults['invoicing.ChangeInvoiceError'],
		},
		OverdueCheckError: {
			id: 'invoicing.OverdueCheckError',
			defaultMessage: defaults['invoicing.OverdueCheckError'],
		},
		GenerateInvoices: {
			id: 'invoicing.GenerateInvoices',
			defaultMessage: defaults['invoicing.GenerateInvoices'],
		},
		Invoice: {
			id: 'invoicing.Invoice',
			defaultMessage: defaults['invoicing.Invoice'],
		},
		Invoices: {
			id: 'invoicing.Invoices',
			defaultMessage: defaults['invoicing.Invoices'],
		},
		ConfirmRetentionSearch: {
			id: 'invoicing.ConfirmRetentionSearch',
			defaultMessage: defaults['invoicing.ConfirmRetentionSearch'],
		},
		ConfirmRetention: {
			id: 'invoicing.ConfirmRetention',
			defaultMessage: defaults['invoicing.ConfirmRetention'],
		},
		ConfirmRetentionError: {
			id: 'invoicing.ConfirmRetentionError',
			defaultMessage: defaults['invoicing.ConfirmRetentionError'],
		},
		TaxRetention: {
			id: 'invoicing.TaxRetention',
			defaultMessage: defaults['invoicing.TaxRetention'],
		},
		ValidSince: {
			id: 'invoicing.ValidSince',
			defaultMessage: defaults['invoicing.ValidSince'],
		},
		ValidThrough: {
			id: 'invoicing.ValidThrough',
			defaultMessage: defaults['invoicing.ValidThrough'],
		},
		Upload: {
			id: 'invoicing.Upload',
			defaultMessage: defaults['invoicing.Upload'],
		},
		TaxRetentionNotification: {
			id: 'invoicing.TaxRetentionNotification',
			defaultMessage: defaults['invoicing.TaxRetentionNotification'],
		},
		Constancy: {
			id: 'invoicing.Constancy',
			defaultMessage: defaults['invoicing.Constancy'],
		},
		Address: {
			id: 'invoicing.Address',
			defaultMessage: defaults['invoicing.Address'],
		},
		City: {
			id: 'invoicing.City',
			defaultMessage: defaults['invoicing.City'],
		},
		State: {
			id: 'invoicing.State',
			defaultMessage: defaults['invoicing.State'],
		},
		Message: {
			id: 'invoicing.Message',
			defaultMessage: defaults['invoicing.Message'],
		},
		CreatedBy: {
			id: 'invoicing.CreatedBy',
			defaultMessage: defaults['invoicing.CreatedBy'],
		},
		ConfirmTaxRetentionQ: {
			id: 'invoicing.ConfirmTaxRetentionQ',
			defaultMessage: defaults['invoicing.ConfirmTaxRetentionQ'],
		},
		addDepositor: {
			id: 'invoicing.addDepositor',
			defaultMessage: defaults['invoicing.addDepositor'],
		},
		depositor: {
			id: 'invoicing.depositor',
			defaultMessage: defaults['invoicing.depositor'],
		},
		createdDepositor: {
			id: 'invoicing.createdDepositor',
			defaultMessage: defaults['invoicing.createdDepositor'],
		},
		createDepositorError: {
			id: 'invoicing.createDepositorError',
			defaultMessage: defaults['invoicing.createDepositorError'],
		},
		searchDepositor: {
			id: 'invoicing.searchDepositor',
			defaultMessage: defaults['invoicing.searchDepositor'],
		},
		depositorError: {
			id: 'invoicing.depositorError',
			defaultMessage: defaults['invoicing.depositorError'],
		},
		annulMessageError: {
			id: 'invoicing.annulMessageError',
			defaultMessage: defaults['invoicing.annulMessageError'],
		},
		confirmAnnul: {
			id: 'invoicing.confirmAnnul',
			defaultMessage: defaults['invoicing.confirmAnnul'],
		},
		generateInvoiceError: {
			id: 'invoicing.generateInvoiceError',
			defaultMessage: defaults['invoicing.generateInvoiceError'],
		},
		fiscalRangeExceededError: {
			id: 'invoicing.fiscalRangeExceededError',
			defaultMessage: defaults['invoicing.fiscalRangeExceededError'],
		},
		generateProformaError: {
			id: 'invoicing.generateProformaError',
			defaultMessage: defaults['invoicing.generateProformaError'],
		},
		invoicePrintError: {
			id: 'invoicing.invoicePrintError',
			defaultMessage: defaults['invoicing.invoicePrintError'],
		},
		proformaPrintError: {
			id: 'invoicing.proformaPrintError',
			defaultMessage: defaults['invoicing.proformaPrintError'],
		},
		generateError: {
			id: 'invoicing.generateError',
			defaultMessage: defaults['invoicing.generateError'],
		},
		monthToCharge: {
			id: 'invoicing.monthToCharge',
			defaultMessage: defaults['invoicing.monthToCharge'],
		},
		nextInvoiceMonthWillRemain: {
			id: 'invoicing.nextInvoiceMonthWillRemain',
			defaultMessage: defaults['invoicing.nextInvoiceMonthWillRemain'],
		},
		nextInvoiceMonthWillChange: {
			id: 'invoicing.nextInvoiceMonthWillChange',
			defaultMessage: defaults['invoicing.nextInvoiceMonthWillChange'],
		},
		noProductWarning: {
			id: 'invoicing.noProductWarning',
			defaultMessage: defaults['invoicing.noProductWarning'],
		},
		zeroPriceWarning: {
			id: 'invoicing.zeroPriceWarning',
			defaultMessage: defaults['invoicing.zeroPriceWarning'],
		},
		zeroQuantityWarning: {
			id: 'invoicing.zeroQuantityWarning',
			defaultMessage: defaults['invoicing.zeroQuantityWarning'],
		},
		noMonthWarning: {
			id: 'invoicing.noMonthWarning',
			defaultMessage: defaults['invoicing.noMonthWarning'],
		},
		afterPaymentDefaultMessage: {
			id: 'invoicing.afterPaymentDefaultMessage',
			defaultMessage: defaults['invoicing.afterPaymentDefaultMessage'],
		},
		priceChangeReason: {
			id: 'invoicing.priceChangeReason',
			defaultMessage: defaults['invoicing.priceChangeReason'],
		},
		priceChangeReasonError: {
			id: 'invoicing.priceChangeReasonError',
			defaultMessage: defaults['invoicing.priceChangeReasonError'],
		},
		priceChangeReasonLengthError: {
			id: 'invoicing.priceChangeReasonLengthError',
			defaultMessage: defaults['invoicing.priceChangeReasonLengthError'],
		},
		getClientEmailsError: {
			id: 'invoicing.getClientEmailsError',
			defaultMessage: defaults['invoicing.getClientEmailsError'],
		},
		getEmailSelectorExtraEmailsError: {
			id: 'invoicing.getEmailSelectorExtraEmailsError',
			defaultMessage: defaults['invoicing.getEmailSelectorExtraEmailsError'],
		},
		extras: {
			id: 'invoicing.extras',
			defaultMessage: defaults['invoicing.extras'],
		},
		preview: {
			id: 'invoicing.preview',
			defaultMessage: defaults['invoicing.preview'],
		},
		download: {
			id: 'invoicing.download',
			defaultMessage: defaults['invoicing.download'],
		},
		invoiceDocumentPreviewError: {
			id: 'invoicing.invoiceDocumentPreviewError',
			defaultMessage: defaults['invoicing.invoiceDocumentPreviewError'],
		},
		invoiceDocumentDownloadError: {
			id: 'invoicing.invoiceDocumentDownloadError',
			defaultMessage: defaults['invoicing.invoiceDocumentDownloadError'],
		},
		paymentWithTaxRetention: {
			id: 'invoicing.paymentWithTaxRetention',
			defaultMessage: defaults['invoicing.paymentWithTaxRetention'],
		},
		taxRetentionAmountNecessary: {
			id: 'invoicing.taxRetentionAmountNecessary',
			defaultMessage: defaults['invoicing.taxRetentionAmountNecessary'],
		},
		totalAmount: {
			id: 'invoicing.totalAmount',
			defaultMessage: defaults['invoicing.totalAmount'],
		},
		taxRetentionAmount: {
			id: 'invoicing.taxRetentionAmount',
			defaultMessage: defaults['invoicing.taxRetentionAmount'],
		},
		generateGeneralReport: {
			id: 'invoicing.generateGeneralReport',
			defaultMessage: defaults['invoicing.generateGeneralReport'],
		},
		overdueInvoicesReport: {
			id: 'invoicing.overdueInvoicesReport',
			defaultMessage: defaults['invoicing.overdueInvoicesReport'],
		},
		generalReportDateRange: {
			id: 'invoicing.generalReportDateRange',
			defaultMessage: defaults['invoicing.generalReportDateRange'],
		},
		generateReport: {
			id: 'invoicing.generateReport',
			defaultMessage: defaults['invoicing.generateReport'],
		},
		generalReportDateRangeRequired: {
			id: 'invoicing.generalReportDateRangeRequired',
			defaultMessage: defaults['invoicing.generalReportDateRangeRequired'],
		},
		invoiceStatements: {
			id: 'invoicing.invoiceStatements',
			defaultMessage: defaults['invoicing.invoiceStatements'],
		},
		paymentDate: {
			id: 'invoicing.paymentDate',
			defaultMessage: defaults['invoicing.paymentDate'],
		},
		expiredDays: {
			id: 'invoicing.expiredDays',
			defaultMessage: defaults['invoicing.expiredDays'],
		},
		sendInvoiceStatement: {
			id: 'invoicing.sendInvoiceStatement',
			defaultMessage: defaults['invoicing.sendInvoiceStatement'],
		},
		generateInvoiceStatementTooltip: {
			id: 'invoicing.generateInvoiceStatementTooltip',
			defaultMessage: defaults['invoicing.generateInvoiceStatementTooltip'],
		},
		generateGeneralReportError: {
			id: 'invoicing.generateGeneralReportError',
			defaultMessage: defaults['invoicing.generateGeneralReportError'],
		},
		loadClientUnpaidInvoicesError: {
			id: 'invoicing.loadClientUnpaidInvoicesError',
			defaultMessage: defaults['invoicing.loadClientUnpaidInvoicesError'],
		},
		loadClientUnpaidDebitNotesError: {
			id: 'invoicing.loadClientUnpaidDebitNotesError',
			defaultMessage: defaults['invoicing.loadClientUnpaidDebitNotesError'],
		},
		generateGeneralReportForAllPendingInvoices: {
			id: 'invoicing.generateGeneralReportForAllPendingInvoices',
			defaultMessage: defaults['invoicing.generateGeneralReportForAllPendingInvoices'],
		},
		confirmRetention: {
			id: 'invoicing.confirmRetention',
			defaultMessage: defaults['invoicing.confirmRetention'],
		},
		typeCash: {
			id: 'invoicing.typeCash',
			defaultMessage: defaults['invoicing.typeCash'],
		},
		typeCredit: {
			id: 'invoicing.typeCredit',
			defaultMessage: defaults['invoicing.typeCredit'],
		},
		observations: {
			id: 'invoicing.observations',
			defaultMessage: defaults['invoicing.observations'],
		},
		observationsLengthError: {
			id: 'invoicing.observationsLengthError',
			defaultMessage: defaults['invoicing.observationsLengthError'],
		},
		generateMonthlyReport: {
			id: 'invoicing.generateMonthlyReport',
			defaultMessage: defaults['invoicing.generateMonthlyReport'],
		},
		generateMonthlyReportDescription: {
			id: 'invoicing.generateMonthlyReportDescription',
			defaultMessage: defaults['invoicing.generateMonthlyReportDescription'],
		},
		month: {
			id: 'invoicing.month',
			defaultMessage: defaults['invoicing.month'],
		},
		monthToGenerateForIsRequired: {
			id: 'invoicing.monthToGenerateForIsRequired',
			defaultMessage: defaults['invoicing.monthToGenerateForIsRequired'],
		},
		generateReportError: {
			id: 'invoicing.generateReportError',
			defaultMessage: defaults['invoicing.generateReportError'],
		},
		generatePreviewError: {
			id: 'invoicing.generatePreviewError',
			defaultMessage: defaults['invoicing.generatePreviewError'],
		},
		downloadPreview: {
			id: 'invoicing.downloadPreview',
			defaultMessage: defaults['invoicing.downloadPreview'],
		},
		generateInvoicesDescription: {
			id: 'invoicing.generateInvoicesDescription',
			defaultMessage: defaults['invoicing.generateInvoicesDescription'],
		},
		totalTaxRetention: {
			id: 'invoicing.totalTaxRetention',
			defaultMessage: defaults['invoicing.totalTaxRetention'],
		},
		exactAmountOnTaxRetentionError: {
			id: 'invoicing.exactAmountOnTaxRetentionError',
			defaultMessage: defaults['invoicing.exactAmountOnTaxRetentionError'],
		},
		totalReceived: {
			id: 'invoicing.totalReceived',
			defaultMessage: defaults['invoicing.totalReceived'],
		},
		generateFilterableReport: {
			id: 'invoicing.generateFilterableReport',
			defaultMessage: defaults['invoicing.generateFilterableReport'],
		},
		includeDeviceListQuestion: {
			id: 'invoicing.includeDeviceListQuestion',
			defaultMessage: defaults['invoicing.includeDeviceListQuestion'],
		},
		includeOutdatedDeviceInfoWarning: {
			id: 'invoicing.includeOutdatedDeviceInfoWarning',
			defaultMessage: defaults['invoicing.includeOutdatedDeviceInfoWarning'],
		},
		noInvoiceDevicesAvailable: {
			id: 'invoicing.noInvoiceDevicesAvailable',
			defaultMessage: defaults['invoicing.noInvoiceDevicesAvailable'],
		},
		loadInvoiceDevicesInfoError: {
			id: 'invoicing.loadInvoiceDevicesInfoError',
			defaultMessage: defaults['invoicing.loadInvoiceDevicesInfoError'],
		},
		getInvoiceProductsError: {
			id: 'invoicing.getInvoiceProductsError',
			defaultMessage: defaults['invoicing.getInvoiceProductsError'],
		},
		addProduct: {
			id: 'invoicing.addProduct',
			defaultMessage: defaults['invoicing.addProduct'],
		},
		updateProduct: {
			id: 'invoicing.updateProduct',
			defaultMessage: defaults['invoicing.updateProduct'],
		},
		invoiceProductRequiredError: {
			id: 'invoicing.invoiceProductRequiredError',
			defaultMessage: defaults['invoicing.invoiceProductRequiredError'],
		},
		invoiceEditNoProductsError: {
			id: 'invoicing.invoiceEditNoProductsError',
			defaultMessage: defaults['invoicing.invoiceEditNoProductsError'],
		},
		invoiceUpdateError: {
			id: 'invoicing.invoiceUpdateError',
			defaultMessage: defaults['invoicing.invoiceUpdateError'],
		},
		sendInvoicesInBatch: {
			id: 'invoicing.sendInvoicesInBatch',
			defaultMessage: defaults['invoicing.sendInvoicesInBatch'],
		},
		searchInvoicesError: {
			id: 'invoicing.searchInvoicesError',
			defaultMessage: defaults['invoicing.searchInvoicesError'],
		},
		fromThisInvoice: {
			id: 'invoicing.fromThisInvoice',
			defaultMessage: defaults['invoicing.fromThisInvoice'],
		},
		toThisInvoice: {
			id: 'invoicing.toThisInvoice',
			defaultMessage: defaults['invoicing.toThisInvoice'],
		},
		fromInvoiceOlderThanToInvoiceError: {
			id: 'invoicing.fromInvoiceOlderThanToInvoiceError',
			defaultMessage: defaults['invoicing.fromInvoiceOlderThanToInvoiceError'],
		},
		invoiceReadyToBeSent: {
			id: 'invoicing.invoiceReadyToBeSent',
			defaultMessage: defaults['invoicing.invoiceReadyToBeSent'],
		},
		emailStatus: {
			id: 'invoicing.emailStatus',
			defaultMessage: defaults['invoicing.emailStatus'],
		},
		invoiceSent: {
			id: 'invoicing.invoiceSent',
			defaultMessage: defaults['invoicing.invoiceSent'],
		},
		sendingInvoice: {
			id: 'invoicing.sendingInvoice',
			defaultMessage: defaults['invoicing.sendingInvoice'],
		},
		monthlyInvoiceMessage: {
			id: 'invoicing.monthlyInvoiceMessage',
			defaultMessage: defaults['invoicing.monthlyInvoiceMessage'],
		},
		invoiceSendError: {
			id: 'invoicing.invoiceSendError',
			defaultMessage: defaults['invoicing.invoiceSendError'],
		},
		invoiceSendNoClientEmailsError: {
			id: 'invoicing.invoiceSendNoClientEmailsError',
			defaultMessage: defaults['invoicing.invoiceSendNoClientEmailsError'],
		},
		batchInvoicesAbove500Error: {
			id: 'invoicing.batchInvoicesAbove500Error',
			defaultMessage: defaults['invoicing.batchInvoicesAbove500Error'],
		},
		batchInvoiceWarning: {
			id: 'invoicing.batchInvoiceWarning',
			defaultMessage: defaults['invoicing.batchInvoiceWarning'],
		},
		downloadAllLogs: {
			id: 'invoicing.downloadAllLogs',
			defaultMessage: defaults['invoicing.downloadAllLogs'],
		},
		downloadErrorLogs: {
			id: 'invoicing.downloadErrorLogs',
			defaultMessage: defaults['invoicing.downloadErrorLogs'],
		},
		getDevicesError: {
			id: 'invoicing.getDevicesError',
			defaultMessage: defaults['invoicing.getDevicesError'],
		},
		invoiceUpdateSuccess: {
			id: 'invoicing.invoiceUpdateSuccess',
			defaultMessage: defaults['invoicing.invoiceUpdateSuccess'],
		},
		changeToPay: {
			id: 'invoicing.changeToPay',
			defaultMessage: defaults['invoicing.changeToPay'],
		},
		changeToPending: {
			id: 'invoicing.changeToPending',
			defaultMessage: defaults['invoicing.changeToPending'],
		},
		deleteInvoiceSuccess: {
			id: 'invoicing.deleteInvoiceSuccess',
			defaultMessage: defaults['invoicing.deleteInvoiceSuccess'],
		},
		saveInvoiceSuccess: {
			id: 'invoicing.saveInvoiceSuccess',
			defaultMessage: defaults['invoicing.saveInvoiceSuccess'],
		},
		saveInvoiceError: {
			id: 'invoicing.saveInvoiceError',
			defaultMessage: defaults['invoicing.saveInvoiceError'],
		},
	});
};

export const getFiscalDataMessages = () => {
	return defineMessages({
		Invoice: {
			id: 'fiscalData.Invoice',
			defaultMessage: defaults['fiscalData.Invoice'],
		},
		CreditNote: {
			id: 'fiscalData.CreditNote',
			defaultMessage: defaults['fiscalData.CreditNote'],
		},
		CashNote: {
			id: 'fiscalData.CashNote',
			defaultMessage: defaults['fiscalData.CashNote'],
		},
		FiscalData: {
			id: 'fiscalData.FiscalData',
			defaultMessage: defaults['fiscalData.FiscalData'],
		},
		Prefix: {
			id: 'fiscalData.Prefix',
			defaultMessage: defaults['fiscalData.Prefix'],
		},
		Range: {
			id: 'fiscalData.Range',
			defaultMessage: defaults['fiscalData.Range'],
		},
		Next: {
			id: 'fiscalData.Next',
			defaultMessage: defaults['fiscalData.Next'],
		},
		ExpiryDate: {
			id: 'fiscalData.ExpiryDate',
			defaultMessage: defaults['fiscalData.ExpiryDate'],
		},
		invoiceDateNotification: {
			id: 'fiscalData.invoiceDateNotification',
			defaultMessage: defaults['fiscalData.invoiceDateNotification'],
		},
		creditDateNotification: {
			id: 'fiscalData.creditDateNotification',
			defaultMessage: defaults['fiscalData.creditDateNotification'],
		},
		debitDateNotification: {
			id: 'fiscalData.debitDateNotification',
			defaultMessage: defaults['fiscalData.debitDateNotification'],
		},
		invoiceRangeNotification: {
			id: 'fiscalData.invoiceRangeNotification',
			defaultMessage: defaults['fiscalData.invoiceRangeNotification'],
		},
		creditRangeNotification: {
			id: 'fiscalData.creditRangeNotification',
			defaultMessage: defaults['fiscalData.creditRangeNotification'],
		},
		debitRangeNotification: {
			id: 'fiscalData.debitRangeNotification',
			defaultMessage: defaults['fiscalData.debitRangeNotification'],
		},
		cantCreateInvoice: {
			id: 'fiscalData.cantCreateInvoice',
			defaultMessage: defaults['fiscalData.cantCreateInvoice'],
		},
		GetFiscalDataError: {
			id: 'fiscalData.GetFiscalDataError',
			defaultMessage: defaults['fiscalData.GetFiscalDataError'],
		},
		errorMountingWithFiscalData: {
			id: 'fiscalData.errorMountingWithFiscalData',
			defaultMessage: defaults['fiscalData.errorMountingWithFiscalData'],
		},
		errorCreatingNewFiscalD: {
			id: 'fiscalData.errorCreatingNewFiscalD',
			defaultMessage: defaults['fiscalData.errorCreatingNewFiscalD'],
		},
		SetRequested: {
			id: 'fiscalData.SetRequested',
			defaultMessage: defaults['fiscalData.SetRequested'],
		}

	});
};

export const getPaymentMessages = () => {
	return defineMessages({
		Movements: {
			id: 'payment.Movements',
			defaultMessage: defaults['payment.Movements'],
		},
		AddPayment: {
			id: 'payment.AddPayment',
			defaultMessage: defaults['payment.AddPayment'],
		},
		Amount: {
			id: 'payment.Amount',
			defaultMessage: defaults['payment.Amount'],
		},
		Comment: {
			id: 'payment.Comment',
			defaultMessage: defaults['payment.Comment'],
		},
		Dollars: {
			id: 'payment.Dollars',
			defaultMessage: defaults['payment.Dollars'],
		},
		DestinationAccount: {
			id: 'payment.DestinationAccount',
			defaultMessage: defaults['payment.DestinationAccount'],
		},
		TypeOfPayment: {
			id: 'payment.TypeOfPayment',
			defaultMessage: defaults['payment.TypeOfPayment'],
		},
		CheckNumber: {
			id: 'payment.CheckNumber',
			defaultMessage: defaults['payment.CheckNumber'],
		},
		Bank: {
			id: 'payment.Bank',
			defaultMessage: defaults['payment.Bank'],
		},
		LastDigits: {
			id: 'payment.LastDigits',
			defaultMessage: defaults['payment.LastDigits'],
		},
		RefNumber: {
			id: 'payment.RefNumber',
			defaultMessage: defaults['payment.RefNumber'],
		},
		AddPaymentError: {
			id: 'payment.AddPaymentError',
			defaultMessage: defaults['payment.AddPaymentError'],
		},
		Type: {
			id: 'payment.Type',
			defaultMessage: defaults['payment.Type'],
		},
		InitialBalance: {
			id: 'payment.InitialBalance',
			defaultMessage: defaults['payment.InitialBalance'],
		},
		Payment: {
			id: 'payment.Payment',
			defaultMessage: defaults['payment.Payment'],
		},
		CreditNote: {
			id: 'payment.CreditNote',
			defaultMessage: defaults['payment.CreditNote'],
		},
		TaxExemption: {
			id: 'payment.TaxExemption',
			defaultMessage: defaults['payment.TaxExemption'],
		},
		Surplus: {
			id: 'payment.Surplus',
			defaultMessage: defaults['payment.Surplus'],
		},
		commentLengthError: {
			id: 'payment.commentLengthError',
			defaultMessage: defaults['payment.commentLengthError'],
		},
		observations: {
			id: 'payment.observations',
			defaultMessage: defaults['payment.observations'],
		},
		paymentDate: {
			id: 'payment.paymentDate',
			defaultMessage: defaults['payment.paymentDate'],
		},
		paymentDateRequiredError: {
			id: 'payment.paymentDateRequiredError',
			defaultMessage: defaults['payment.paymentDateRequiredError'],
		},
		loadDestinationAccountsError: {
			id: 'payment.loadDestinationAccountsError',
			defaultMessage: defaults['payment.loadDestinationAccountsError'],
		},
		destinationAccountsRequiredError: {
			id: 'payment.destinationAccountsRequiredError',
			defaultMessage: defaults['payment.destinationAccountsRequiredError'],
		},
		all: {
			id: 'payment.all',
			defaultMessage: defaults['payment.all'],
		},
		deposit: {
			id: 'payment.deposit',
			defaultMessage: defaults['payment.deposit'],
		},
		check: {
			id: 'payment.check',
			defaultMessage: defaults['payment.check'],
		},
		cash: {
			id: 'payment.cash',
			defaultMessage: defaults['payment.cash'],
		},
		creditCard: {
			id: 'payment.creditCard',
			defaultMessage: defaults['payment.creditCard'],
		},
		automaticDeduction: {
			id: 'payment.automaticDeduction',
			defaultMessage: defaults['payment.automaticDeduction'],
		},
		negativeFinalAmountError: {
			id: 'payment.negativeFinalAmountError',
			defaultMessage: defaults['payment.negativeFinalAmountError'],
		},
		receiptNumber: {
			id: 'payment.receiptNumber',
			defaultMessage: defaults['payment.receiptNumber'],
		},
		actions: {
			id: 'payment.actions',
			defaultMessage: defaults['payment.actions'],
		},
		receiptError: {
			id: 'payment.receiptError',
			defaultMessage: defaults['payment.receiptError'],
		},
		invoicesError:{
			id: 'payment.invoicesError',
			defaultMessage: defaults['payment.invoicesError'],
		},
		receiptId: {
			id: 'payment.receiptId',
			defaultMessage: defaults['payment.receiptId'],
		},
	});
};

export const getExchangeRateMessages = () => {
	return defineMessages({
		ExchangeRate: {
			id: 'exchangeRate.ExchangeRate',
			defaultMessage: defaults['exchangeRate.ExchangeRate'],
		},
		UseLast: {
			id: 'exchangeRate.UseLast',
			defaultMessage: defaults['exchangeRate.UseLast'],
		},
		Rate: {
			id: 'exchangeRate.Rate',
			defaultMessage: defaults['exchangeRate.Rate'],
		},
		NewExchangeRate: {
			id: 'exchangeRate.NewExchangeRate',
			defaultMessage: defaults['exchangeRate.NewExchangeRate'],
		},
		Date: {
			id: 'exchangeRate.Date',
			defaultMessage: defaults['exchangeRate.Date'],
		},
		CreatedBy: {
			id: 'exchangeRate.CreatedBy',
			defaultMessage: defaults['exchangeRate.CreatedBy'],
		},
		CheckExchangeRate: {
			id: 'exchangeRate.CheckExchangeRate',
			defaultMessage: defaults['exchangeRate.CheckExchangeRate'],
		},
		errorObtainingExchangeRate: {
			id: 'exchangeRate.errorObtainingExchangeRate',
			defaultMessage: defaults['exchangeRate.errorObtainingExchangeRate'],
		},
		errorObtainingExchangeRates: {
			id: 'exchangeRate.errorObtainingExchangeRates',
			defaultMessage: defaults['exchangeRate.errorObtainingExchangeRates'],
		},
		errorNewExchangeRate: {
			id: 'exchangeRate.errorNewExchangeRate',
			defaultMessage: defaults['exchangeRate.errorNewExchangeRate'],
		}
	});
};

export const getProformaMessages = () => {
	return defineMessages({
		Client: {
			id: 'proforma.Client',
			defaultMessage: defaults['proforma.Client'],
		},
		Product: {
			id: 'proforma.Product',
			defaultMessage: defaults['proforma.Product'],
		},
		Description: {
			id: 'proforma.Description',
			defaultMessage: defaults['proforma.Description'],
		},
		Quantity: {
			id: 'proforma.Quantity',
			defaultMessage: defaults['proforma.Quantity'],
		},
		Price: {
			id: 'proforma.Price',
			defaultMessage: defaults['proforma.Price'],
		},
		Total: {
			id: 'proforma.Total',
			defaultMessage: defaults['proforma.Total'],
		},
		Subtotal: {
			id: 'proforma.Subtotal',
			defaultMessage: defaults['proforma.Subtotal'],
		},
		Isv: {
			id: 'proforma.Isv',
			defaultMessage: defaults['proforma.Isv'],
		},
		ProFormaNumber: {
			id: 'proforma.ProFormaNumber',
			defaultMessage: defaults['proforma.ProFormaNumber'],
		},
		Status: {
			id: 'proforma.Status',
			defaultMessage: defaults['proforma.Status'],
		},
		Date: {
			id: 'proforma.Date',
			defaultMessage: defaults['proforma.Date'],
		},
		Cash: {
			id: 'proforma.Cash',
			defaultMessage: defaults['proforma.Cash'],
		},
		Credit: {
			id: 'proforma.Credit',
			defaultMessage: defaults['proforma.Credit'],
		},
		SavePrint: {
			id: 'proforma.SavePrint',
			defaultMessage: defaults['proforma.SavePrint'],
		},
		Print: {
			id: 'proforma.Print',
			defaultMessage: defaults['proforma.Print'],
		},
		Salesperson: {
			id: 'proforma.Salesperson',
			defaultMessage: defaults['proforma.Salesperson'],
		},
		PayDate: {
			id: 'proforma.PayDate',
			defaultMessage: defaults['proforma.PayDate'],
		},
		View: {
			id: 'proforma.View',
			defaultMessage: defaults['proforma.View'],
		},
		Actions: {
			id: 'proforma.Actions',
			defaultMessage: defaults['proforma.Actions'],
		},
		Type: {
			id: 'proforma.Type',
			defaultMessage: defaults['proforma.Type'],
		},
		DaysPayment: {
			id: 'proforma.DaysPayment',
			defaultMessage: defaults['proforma.DaysPayment'],
		},
		ProformaNum: {
			id: 'proforma.ProformaNum',
			defaultMessage: defaults['proforma.ProformaNum'],
		},
		Amount: {
			id: 'proforma.Amount',
			defaultMessage: defaults['proforma.Amount'],
		},
		Observations: {
			id: 'proforma.Observations',
			defaultMessage: defaults['proforma.Observations'],
		},
		ExchangeRate: {
			id: 'proforma.ExchangeRate',
			defaultMessage: defaults['proforma.ExchangeRate'],
		},
		All: {
			id: 'proforma.All',
			defaultMessage: defaults['proforma.All'],
		},
		Invoiced: {
			id: 'proforma.Invoiced',
			defaultMessage: defaults['proforma.Invoiced'],
		},
		NeedsApproval: {
			id: 'proforma.NeedsApproval',
			defaultMessage: defaults['proforma.NeedsApproval'],
		},
		ReadyInvoicing: {
			id: 'proforma.ReadyInvoicing',
			defaultMessage: defaults['proforma.ReadyInvoicing'],
		},
		Denied: {
			id: 'proforma.Denied',
			defaultMessage: defaults['proforma.Denied'],
		},
		Redo: {
			id: 'proforma.Redo',
			defaultMessage: defaults['proforma.Redo'],
		},
		EmailsToSend: {
			id: 'proforma.EmailsToSend',
			defaultMessage: defaults['proforma.EmailsToSend'],
		},
		Emails: {
			id: 'proforma.Emails',
			defaultMessage: defaults['proforma.Emails'],
		},
		ExactTotal: {
			id: 'proforma.ExactTotal',
			defaultMessage: defaults['proforma.ExactTotal'],
		},
		Save: {
			id: 'proforma.Save',
			defaultMessage: defaults['proforma.Save'],
		},
		SaveEmail: {
			id: 'proforma.SaveEmail',
			defaultMessage: defaults['proforma.SaveEmail'],
		},
		Reason: {
			id: 'proforma.Reason',
			defaultMessage: defaults['proforma.Reason'],
		},
		Approve: {
			id: 'proforma.Approve',
			defaultMessage: defaults['proforma.Approve'],
		},
		Deny: {
			id: 'proforma.Deny',
			defaultMessage: defaults['proforma.Deny'],
		},
		ApproveQ: {
			id: 'proforma.ApproveQ',
			defaultMessage: defaults['proforma.ApproveQ'],
		},
		Invoice: {
			id: 'proforma.Invoice',
			defaultMessage: defaults['proforma.Invoice'],
		},
		ViewInvoice: {
			id: 'proforma.ViewInvoice',
			defaultMessage: defaults['proforma.ViewInvoice'],
		},
		InvoiceQ: {
			id: 'proforma.InvoiceQ',
			defaultMessage: defaults['proforma.InvoiceQ'],
		},
		CantPrint: {
			id: 'proforma.CantPrint',
			defaultMessage: defaults['proforma.CantPrint'],
		},
		CantEmail: {
			id: 'proforma.CantEmail',
			defaultMessage: defaults['proforma.CantEmail'],
		},
		TaxExemption: {
			id: 'proforma.TaxExemption',
			defaultMessage: defaults['proforma.TaxExemption'],
		},
		TaxExoneration: {
			id: 'proforma.TaxExoneration',
			defaultMessage: defaults['proforma.TaxExoneration'],
		},
		TaxSAG: {
			id: 'proforma.TaxSAG',
			defaultMessage: defaults['proforma.TaxSAG'],
		},
		TaxReferenceNumber: {
			id: 'proforma.TaxReferenceNumber',
			defaultMessage: defaults['proforma.TaxReferenceNumber'],
		},
		emptyProducts: {
			id: 'proforma.emptyProducts',
			defaultMessage: defaults['proforma.emptyProducts'],
		},
		Programmed: {
			id: 'proforma.Programmed',
			defaultMessage: defaults['proforma.Programmed'],
		},
		IsProgrammed: {
			id: 'proforma.IsProgrammed',
			defaultMessage: defaults['proforma.IsProgrammed'],
		},
		DateProgrammed: {
			id: 'proforma.DateProgrammed',
			defaultMessage: defaults['proforma.DateProgrammed'],
		},
		PendingProformas: {
			id: 'proforma.PendingProformas',
			defaultMessage: defaults['proforma.PendingProformas'],
		},
		TheresProformas: {
			id: 'proforma.TheresProformas',
			defaultMessage: defaults['proforma.TheresProformas'],
		},
		Pending: {
			id: 'proforma.Pending',
			defaultMessage: defaults['proforma.Pending'],
		},
		GetProformaError: {
			id: 'proforma.GetProformaError',
			defaultMessage: defaults['proforma.GetProformaError'],
		},
		GetProformasError: {
			id: 'proforma.GetProformasError',
			defaultMessage: defaults['proforma.GetProformasError'],
		},
		AddProformaError: {
			id: 'proforma.AddProformaError',
			defaultMessage: defaults['proforma.AddProformaError'],
		},
		EmailProformaError: {
			id: 'proforma.EmailProformaError',
			defaultMessage: defaults['proforma.EmailProformaError'],
		},
		PrintError: {
			id: 'proforma.PrintError',
			defaultMessage: defaults['proforma.PrintError'],
		},
		DenyProformaError: {
			id: 'proforma.DenyProformaError',
			defaultMessage: defaults['proforma.DenyProformaError'],
		},
		ApproveProformaError: {
			id: 'proforma.ApproveProformaError',
			defaultMessage: defaults['proforma.ApproveProformaError'],
		},
		SetProformaAsInvoicedError: {
			id: 'proforma.SetProformaAsInvoicedError',
			defaultMessage: defaults['proforma.SetProformaAsInvoicedError'],
		},
		MonthInvoicedError: {
			id: 'proforma.MonthInvoicedError',
			defaultMessage: defaults['proforma.MonthInvoicedError'],
		},
		Address: {
			id: 'proforma.Address',
			defaultMessage: defaults['proforma.Address'],
		},
		City: {
			id: 'proforma.City',
			defaultMessage: defaults['proforma.City'],
		},
		State: {
			id: 'proforma.State',
			defaultMessage: defaults['proforma.State'],
		},
		taxType: {
			id: 'proforma.taxType',
			defaultMessage: defaults['proforma.taxType'],
		},
		taxTypeError: {
			id: 'proforma.taxTypeError',
			defaultMessage: defaults['proforma.taxTypeError'],
		},
		taxReferenceExemption: {
			id: 'proforma.taxReferenceExemption',
			defaultMessage: defaults['proforma.taxReferenceExemption'],
		},
		taxReferenceExoneration: {
			id: 'proforma.taxReferenceExoneration',
			defaultMessage: defaults['proforma.taxReferenceExoneration'],
		},
		taxReferenceSag: {
			id: 'proforma.taxReferenceSag',
			defaultMessage: defaults['proforma.taxReferenceSag'],
		},
		proformaUpdateSuccess: {
			id: 'proforma.proformaUpdateSuccess',
			defaultMessage: defaults['proforma.proformaUpdateSuccess'],
		},
		proformaUpdateError: {
			id: 'proforma.proformaUpdateError',
			defaultMessage: defaults['proforma.proformaUpdateError'],
		},
		getProformaProductsError: {
			id: 'proforma.getProformaProductsError',
			defaultMessage: defaults['proforma.getProformaProductsError'],
		},
	});
};

export const getQuotationMessages = () => {
	return defineMessages({
		Client: {
			id: 'quotation.Client',
			defaultMessage: defaults['quotation.Client'],
		},
		Product: {
			id: 'quotation.Product',
			defaultMessage: defaults['quotation.Product'],
		},
		Description: {
			id: 'quotation.Description',
			defaultMessage: defaults['quotation.Description'],
		},
		Quantity: {
			id: 'quotation.Quantity',
			defaultMessage: defaults['quotation.Quantity'],
		},
		Price: {
			id: 'quotation.Price',
			defaultMessage: defaults['quotation.Price'],
		},
		Total: {
			id: 'quotation.Total',
			defaultMessage: defaults['quotation.Total'],
		},
		Subtotal: {
			id: 'quotation.Subtotal',
			defaultMessage: defaults['quotation.Subtotal'],
		},
		Isv: {
			id: 'quotation.Isv',
			defaultMessage: defaults['quotation.Isv'],
		},
		QuotationNumber: {
			id: 'quotation.QuotationNumber',
			defaultMessage: defaults['quotation.QuotationNumber'],
		},
		Status: {
			id: 'quotation.Status',
			defaultMessage: defaults['quotation.Status'],
		},
		Date: {
			id: 'quotation.Date',
			defaultMessage: defaults['quotation.Date'],
		},
		Cash: {
			id: 'quotation.Cash',
			defaultMessage: defaults['quotation.Cash'],
		},
		Credit: {
			id: 'quotation.Credit',
			defaultMessage: defaults['quotation.Credit'],
		},
		SavePrint: {
			id: 'quotation.SavePrint',
			defaultMessage: defaults['quotation.SavePrint'],
		},
		Print: {
			id: 'quotation.Print',
			defaultMessage: defaults['quotation.Print'],
		},
		Salesperson: {
			id: 'quotation.Salesperson',
			defaultMessage: defaults['quotation.Salesperson'],
		},
		PayDate: {
			id: 'quotation.PayDate',
			defaultMessage: defaults['quotation.PayDate'],
		},
		View: {
			id: 'quotation.View',
			defaultMessage: defaults['quotation.View'],
		},
		Actions: {
			id: 'quotation.Actions',
			defaultMessage: defaults['quotation.Actions'],
		},
		Type: {
			id: 'quotation.Type',
			defaultMessage: defaults['quotation.Type'],
		},
		DaysPayment: {
			id: 'quotation.DaysPayment',
			defaultMessage: defaults['quotation.DaysPayment'],
		},
		QuotationNum: {
			id: 'quotation.QuotationNum',
			defaultMessage: defaults['quotation.QuotationNum'],
		},
		Amount: {
			id: 'quotation.Amount',
			defaultMessage: defaults['quotation.Amount'],
		},
		Observations: {
			id: 'quotation.Observations',
			defaultMessage: defaults['quotation.Observations'],
		},
		ExchangeRate: {
			id: 'quotation.ExchangeRate',
			defaultMessage: defaults['quotation.ExchangeRate'],
		},
		All: {
			id: 'quotation.All',
			defaultMessage: defaults['quotation.All'],
		},
		Invoiced: {
			id: 'quotation.Invoiced',
			defaultMessage: defaults['quotation.Invoiced'],
		},
		NeedsApproval: {
			id: 'quotation.NeedsApproval',
			defaultMessage: defaults['quotation.NeedsApproval'],
		},
		ReadyInvoicing: {
			id: 'quotation.ReadyInvoicing',
			defaultMessage: defaults['quotation.ReadyInvoicing'],
		},
		Denied: {
			id: 'quotation.Denied',
			defaultMessage: defaults['quotation.Denied'],
		},
		Expired: {
			id: 'quotation.Expired',
			defaultMessage: defaults['quotation.Expired'],
		},
		Redo: {
			id: 'quotation.Redo',
			defaultMessage: defaults['quotation.Redo'],
		},
		EmailsToSend: {
			id: 'quotation.EmailsToSend',
			defaultMessage: defaults['quotation.EmailsToSend'],
		},
		Emails: {
			id: 'quotation.Emails',
			defaultMessage: defaults['quotation.Emails'],
		},
		ExactTotal: {
			id: 'quotation.ExactTotal',
			defaultMessage: defaults['quotation.ExactTotal'],
		},
		Save: {
			id: 'quotation.Save',
			defaultMessage: defaults['quotation.Save'],
		},
		SaveEmail: {
			id: 'quotation.SaveEmail',
			defaultMessage: defaults['quotation.SaveEmail'],
		},
		Reason: {
			id: 'quotation.Reason',
			defaultMessage: defaults['quotation.Reason'],
		},
		Approve: {
			id: 'quotation.Approve',
			defaultMessage: defaults['quotation.Approve'],
		},
		Deny: {
			id: 'quotation.Deny',
			defaultMessage: defaults['quotation.Deny'],
		},
		Renew: {
			id: 'quotation.Renew',
			defaultMessage: defaults['quotation.Renew'],
		},
		ApproveQ: {
			id: 'quotation.ApproveQ',
			defaultMessage: defaults['quotation.ApproveQ'],
		},
		RenewQ: {
			id: 'quotation.RenewQ',
			defaultMessage: defaults['quotation.RenewQ'],
		},
		Invoice: {
			id: 'quotation.Invoice',
			defaultMessage: defaults['quotation.Invoice'],
		},
		ViewInvoice: {
			id: 'quotation.ViewInvoice',
			defaultMessage: defaults['quotation.ViewInvoice'],
		},
		InvoiceQ: {
			id: 'quotation.InvoiceQ',
			defaultMessage: defaults['quotation.InvoiceQ'],
		},
		ToProforma: {
			id: 'quotation.ToProforma',
			defaultMessage: defaults['quotation.ToProforma'],
		},
		CreatedProforma: {
			id: 'quotation.CreatedProforma',
			defaultMessage: defaults['quotation.CreatedProforma'],
		},
		ViewProforma: {
			id: 'quotation.ViewProforma',
			defaultMessage: defaults['quotation.ViewProforma'],
		},
		ProformaQ: {
			id: 'quotation.ProformaQ',
			defaultMessage: defaults['quotation.ProformaQ'],
		},
		CantPrint: {
			id: 'quotation.CantPrint',
			defaultMessage: defaults['quotation.CantPrint'],
		},
		CantEmail: {
			id: 'quotation.CantEmail',
			defaultMessage: defaults['quotation.CantEmail'],
		},
		ApprovalMessage: {
			id: 'quotation.ApprovalMessage',
			defaultMessage: defaults['quotation.ApprovalMessage'],
		},
		emptyProducts: {
			id: 'quotation.emptyProducts',
			defaultMessage: defaults['quotation.emptyProducts'],
		},
		GetQuotationError: {
			id: 'quotation.GetQuotationError',
			defaultMessage: defaults['quotation.GetQuotationError'],
		},
		GetQuotationsError: {
			id: 'quotation.GetQuotationsError',
			defaultMessage: defaults['quotation.GetQuotationsError'],
		},
		AddQuotationError: {
			id: 'quotation.AddQuotationError',
			defaultMessage: defaults['quotation.AddQuotationError'],
		},
		UpdateQuotationError: {
			id: 'quotation.UpdateQuotationError',
			defaultMessage: defaults['quotation.UpdateQuotationError'],
		},
		UpdateQuotationSuccess: {
			id: 'quotation.UpdateQuotationSuccess',
			defaultMessage: defaults['quotation.UpdateQuotationSuccess'],
		},
		EmailQuotationError: {
			id: 'quotation.EmailQuotationError',
			defaultMessage: defaults['quotation.EmailQuotationError'],
		},
		PrintError: {
			id: 'quotation.PrintError',
			defaultMessage: defaults['quotation.PrintError'],
		},
		DenyQuotationError: {
			id: 'quotation.DenyQuotationError',
			defaultMessage: defaults['quotation.DenyQuotationError'],
		},
		ApproveQuotationError: {
			id: 'quotation.ApproveQuotationError',
			defaultMessage: defaults['quotation.ApproveQuotationError'],
		},
		SetQuotationAsInvoicedError: {
			id: 'quotation.SetQuotationAsInvoicedError',
			defaultMessage: defaults['quotation.SetQuotationAsInvoicedError'],
		},
		SetQuotationToProformaError: {
			id: 'quotation.SetQuotationToProformaError',
			defaultMessage: defaults['quotation.SetQuotationToProformaError'],
		},
		Address: {
			id: 'quotation.Address',
			defaultMessage: defaults['quotation.Address'],
		},
		City: {
			id: 'quotation.City',
			defaultMessage: defaults['quotation.City'],
		},
		State: {
			id: 'quotation.State',
			defaultMessage: defaults['quotation.State'],
		},
		Edit: {
			id: 'quotation.Edit',
			defaultMessage: defaults['quotation.Edit'],
		},
		getQuotationProductsError: {
			id: 'quotation.getQuotationProductsError',
			defaultMessage: defaults['quotation.getQuotationProductsError'],
		},
		TaxExemption: {
			id: 'quotation.TaxExemption',
			defaultMessage: defaults['quotation.TaxExemption'],
		},
	});
};

export const getCreditNoteMessages = () => {
	return defineMessages({
		CreditNote: {
			id: 'creditNote.CreditNote',
			defaultMessage: defaults['creditNote.CreditNote'],
		},
		AddCreditNote: {
			id: 'creditNote.AddCreditNote',
			defaultMessage: defaults['creditNote.AddCreditNote'],
		},
		EditCreditNote: {
			id: 'creditNote.EditCreditNote',
			defaultMessage: defaults['creditNote.EditCreditNote'],
		},
		CreditNoteNumber: {
			id: 'creditNote.CreditNoteNumber',
			defaultMessage: defaults['creditNote.CreditNoteNumber'],
		},
		Client: {
			id: 'creditNote.Client',
			defaultMessage: defaults['creditNote.Client'],
		},
		Date: {
			id: 'creditNote.Date',
			defaultMessage: defaults['creditNote.Date'],
		},
		Actions: {
			id: 'creditNote.Actions',
			defaultMessage: defaults['creditNote.Actions'],
		},
		Motive: {
			id: 'creditNote.Motive',
			defaultMessage: defaults['creditNote.Motive'],
		},
		Amount: {
			id: 'creditNote.Amount',
			defaultMessage: defaults['creditNote.Amount'],
		},
		Invoices: {
			id: 'creditNote.Invoices',
			defaultMessage: defaults['creditNote.Invoices'],
		},
		Print: {
			id: 'creditNote.Print',
			defaultMessage: defaults['creditNote.Print'],
		},
		GetCreditNotesError: {
			id: 'creditNote.GetCreditNotesError',
			defaultMessage: defaults['creditNote.GetCreditNotesError'],
		},
		AddCreditNoteError: {
			id: 'creditNote.AddCreditNoteError',
			defaultMessage: defaults['creditNote.AddCreditNoteError'],
		},
		PrintError: {
			id: 'creditNote.PrintError',
			defaultMessage: defaults['creditNote.PrintError'],
		},
		getActivityLogError: {
			id: 'creditNote.getActivityLogError',
			defaultMessage: defaults['creditNote.getActivityLogError'],
		}, 
		Edit: {
			id: 'creditNote.Edit',
			defaultMessage: defaults['creditNote.Edit'],
		},
		AddCreditNoteSuccess: {
			id: 'creditNote.AddCreditNoteSuccess',
			defaultMessage: defaults['creditNote.AddCreditNoteSuccess'],
		},
		EditCreditNoteSuccess: {
			id: 'creditNote.EditCreditNoteSuccess',
			defaultMessage: defaults['creditNote.EditCreditNoteSuccess'],
		},
	});
};

export const getDebitNoteMessages = () => {
	return defineMessages({
		DebitNote: {
			id: 'debitNote.DebitNote',
			defaultMessage: defaults['debitNote.DebitNote'],
		},
		DebitNotes: {
			id: 'debitNote.DebitNotes',
			defaultMessage: defaults['debitNote.DebitNotes'],
		},
		AddDebitNote: {
			id: 'debitNote.AddDebitNote',
			defaultMessage: defaults['debitNote.AddDebitNote'],
		},
		DebitNoteNumber: {
			id: 'debitNote.DebitNoteNumber',
			defaultMessage: defaults['debitNote.DebitNoteNumber'],
		},
		Number: {
			id: 'debitNote.Number',
			defaultMessage: defaults['debitNote.Number'],
		},
		Client: {
			id: 'debitNote.Client',
			defaultMessage: defaults['debitNote.Client'],
		},
		Date: {
			id: 'debitNote.Date',
			defaultMessage: defaults['debitNote.Date'],
		},
		Actions: {
			id: 'debitNote.Actions',
			defaultMessage: defaults['debitNote.Actions'],
		},
		Motive: {
			id: 'debitNote.Motive',
			defaultMessage: defaults['debitNote.Motive'],
		},
		Amount: {
			id: 'debitNote.Amount',
			defaultMessage: defaults['debitNote.Amount'],
		},
		Invoices: {
			id: 'debitNote.Invoices',
			defaultMessage: defaults['debitNote.Invoices'],
		},
		Print: {
			id: 'debitNote.Print',
			defaultMessage: defaults['debitNote.Print'],
		},
		Pending: {
			id: 'debitNote.Pending',
			defaultMessage: defaults['debitNote.Pending'],
		},
		Paid: {
			id: 'debitNote.Paid',
			defaultMessage: defaults['debitNote.Paid'],
		},
		Status: {
			id: 'debitNote.Status',
			defaultMessage: defaults['debitNote.Status'],
		},
		Description: {
			id: 'debitNote.Description',
			defaultMessage: defaults['debitNote.Description'],
		},
		GetDebitNotesError: {
			id: 'debitNote.GetDebitNotesError',
			defaultMessage: defaults['debitNote.GetDebitNotesError'],
		},
		AddDebitNoteError: {
			id: 'debitNote.AddDebitNoteError',
			defaultMessage: defaults['debitNote.AddDebitNoteError'],
		},
		PrintError: {
			id: 'debitNote.PrintError',
			defaultMessage: defaults['debitNote.PrintError'],
		},
		getActivityLogError: {
			id: 'debitNote.getActivityLogError',
			defaultMessage: defaults['debitNote.getActivityLogError'],
		},
		AddDebitNoteSuccess: {
			id: 'debitNote.AddDebitNoteSuccess',
			defaultMessage: defaults['debitNote.AddDebitNoteSuccess'],
		},
		EditDebitNoteSuccess: {
			id: 'debitNote.EditDebitNoteSuccess',
			defaultMessage: defaults['debitNote.EditDebitNoteSuccess'],
		},
		EditDebitNote: {
			id: 'debitNote.EditDebitNote',
			defaultMessage: defaults['debitNote.EditDebitNote'],
		},
		Edit: {
			id: 'debitNote.Edit',
			defaultMessage: defaults['debitNote.Edit'],
		},
	});
};

export const getProductMessages = () => {
	return defineMessages({
		Product: {
			id: 'product.Product',
			defaultMessage: defaults['product.Product'],
		},
		Code: {
			id: 'product.Code',
			defaultMessage: defaults['product.Code'],
		},
		Description: {
			id: 'product.Description',
			defaultMessage: defaults['product.Description'],
		},
		Price: {
			id: 'product.Price',
			defaultMessage: defaults['product.Price'],
		},
		MinPrice: {
			id: 'product.MinPrice',
			defaultMessage: defaults['product.MinPrice'],
		},
		MaxQuantity: {
			id: 'product.MaxQuantity',
			defaultMessage: defaults['product.MaxQuantity'],
		},
		IsAddon: {
			id: 'product.IsAddon',
			defaultMessage: defaults['product.IsAddon'],
		},
		Actions: {
			id: 'product.Actions',
			defaultMessage: defaults['product.Actions'],
		},
		Unlimited: {
			id: 'product.Unlimited',
			defaultMessage: defaults['product.Unlimited'],
		},
		GetProductsOfInvoiceError: {
			id: 'product.GetProductsOfInvoiceError',
			defaultMessage: defaults['product.GetProductsOfInvoiceError'],
		},
		GetProductsOfProformaError: {
			id: 'product.GetProductsOfProformaError',
			defaultMessage: defaults['product.GetProductsOfProformaError'],
		},
		GetProductsOfQuotationError: {
			id: 'product.GetProductsOfQuotationError',
			defaultMessage: defaults['product.GetProductsOfQuotationError'],
		},
		errorGetProducts: {
			id: 'product.errorGetProducts',
			defaultMessage: defaults['product.errorGetProducts'],
		},
		errorEditProduct: {
			id: 'product.errorEditProduct',
			defaultMessage: defaults['product.errorEditProduct'],
		},
		errorAddProduct: {
			id: 'product.errorAddProduct',
			defaultMessage: defaults['product.errorAddProduct'],
		},
		getProductConfigurationsError: {
			id: 'product.getProductConfigurationsError',
			defaultMessage: defaults['product.getProductConfigurationsError'],
		},
	});
};

export const getReportMessages = () => {
	return defineMessages({
		reports: {
			id: 'report.reports',
			defaultMessage: defaults['report.reports'],
		},
		today: {
			id: 'report.today',
			defaultMessage: defaults['report.today'],
		},
		thisWeek: {
			id: 'report.thisWeek',
			defaultMessage: defaults['report.thisWeek'],
		},
		lastWeek: {
			id: 'report.lastWeek',
			defaultMessage: defaults['report.lastWeek'],
		},
		thisMonth: {
			id: 'report.thisMonth',
			defaultMessage: defaults['report.thisMonth'],
		},
		lastMonth: {
			id: 'report.lastMonth',
			defaultMessage: defaults['report.lastMonth'],
		},
		dateRange: {
			id: 'report.dateRange',
			defaultMessage: defaults['report.dateRange'],
		},
		selectRangeDate: {
			id: 'report.selectRangeDate',
			defaultMessage: defaults['report.selectRangeDate'],
		},
		generateReport: {
			id: 'report.generateReport',
			defaultMessage: defaults['report.generateReport'],
		},
		date: {
			id: 'report.date',
			defaultMessage: defaults['report.date'],
		},
		loading: {
			id: 'report.loading',
			defaultMessage: defaults['report.loading'],
		},
		noData: {
			id: 'report.noData',
			defaultMessage: defaults['report.noData'],
		},
		clients: {
			id: 'report.clients',
			defaultMessage: defaults['report.clients'],
		},
		selectClients: {
			id: 'report.selectClients',
			defaultMessage: defaults['report.selectClients'],
		},
		arrears: {
			id: 'report.arrears',
			defaultMessage: defaults['report.arrears'],
		},
		arrearDays: {
			id: 'report.arrearDays',
			defaultMessage: defaults['report.arrearDays'],
		},
		dollars: {
			id: 'report.dollars',
			defaultMessage: defaults['report.dollars'],
		},
		lempiras: {
			id: 'report.lempiras',
			defaultMessage: defaults['report.lempiras'],
		},
		lempirasAmount: {
			id: 'report.lempirasAmount',
			defaultMessage: defaults['report.lempirasAmount'],
		},
		balance: {
			id: 'report.balance',
			defaultMessage: defaults['report.balance'],
		},
		paid: {
			id: 'report.paid',
			defaultMessage: defaults['report.paid'],
		},
		clientReport: {
			id: 'report.clientReport',
			defaultMessage: defaults['report.clientReport'],
		},
		detailReport: {
			id: 'report.detailReport',
			defaultMessage: defaults['report.detailReport'],
		},
		cancel: {
			id: 'report.cancel',
			defaultMessage: defaults['report.cancel'],
		},
		download: {
			id: 'report.download',
			defaultMessage: defaults['report.download'],
		},
		icon: {
			id: 'report.icon',
			defaultMessage: defaults['report.icon'],
		},
		InvoiceInfo: {
			id: 'report.InvoiceInfo',
			defaultMessage: defaults['report.InvoiceInfo'],
		},
		subtotal: {
			id: 'report.subtotal',
			defaultMessage: defaults['report.subtotal'],
		},
		total: {
			id: 'report.total',
			defaultMessage: defaults['report.total'],
		},
		createdAt: {
			id: 'report.createdAt',
			defaultMessage: defaults['report.createdAt'],
		},
		clientName: {
			id: 'report.clientName',
			defaultMessage: defaults['report.clientName'],
		},
		documentNumber: {
			id: 'report.documentNumber',
			defaultMessage: defaults['report.documentNumber'],
		},
		isv: {
			id: 'report.isv',
			defaultMessage: defaults['report.isv'],
		},
		totals: {
			id: 'report.totals',
			defaultMessage: defaults['report.totals'],
		},
		emptyReport: {
			id: 'report.emptyReport',
			defaultMessage: defaults['report.emptyReport'],
		},
		reportHasNoResult: {
			id: 'report.reportHasNoResult',
			defaultMessage: defaults['report.reportHasNoResult'],
		},
		lps: {
			id: 'report.lps',
			defaultMessage: defaults['report.lps'],
		},
		currency: {
			id: 'report.currency',
			defaultMessage: defaults['report.currency'],
		},
		invalidCurrency: {
			id: 'report.invalidCurrency',
			defaultMessage: defaults['report.invalidCurrency'],
		},
		dollarName: {
			id: 'report.dollarName',
			defaultMessage: defaults['report.dollarName'],
		},
		totalSum: {
			id: 'report.totalSum',
			defaultMessage: defaults['report.totalSum'],
		},
		search: {
			id: 'report.search',
			defaultMessage: defaults['report.search']
		},
		monday: {
			id: 'report.monday',
			defaultMessage: defaults['report.monday']
		},
		tuesday: {
			id: 'report.tuesday',
			defaultMessage: defaults['report.tuesday']
		},
		wednesday: {
			id: 'report.wednesday',
			defaultMessage: defaults['report.wednesday']
		},
		thursday: {
			id: 'report.thursday',
			defaultMessage: defaults['report.thursday']
		},
		friday: {
			id: 'report.friday',
			defaultMessage: defaults['report.friday']
		},
		saturday: {
			id: 'report.saturday',
			defaultMessage: defaults['report.saturday']
		},
		sunday: {
			id: 'report.sunday',
			defaultMessage: defaults['report.sunday']
		},
		noExtensions: {
			id: 'report.noExtensions',
			defaultMessage: defaults['report.noExtensions']
		},
		telephone: {
			id: 'report.telephone',
			defaultMessage: defaults['report.telephone']
		},
		extension: {
			id: 'report.extension',
			defaultMessage: defaults['report.extension']
		},
		accept: {
			id: 'report.accept',
			defaultMessage: defaults['report.accept']
		},
		client: {
			id: 'report.client',
			defaultMessage: defaults['report.client']
		},
		add: {
			id: 'report.add',
			defaultMessage: defaults['report.add']
		},
		comment: {
			id: 'report.comment',
			defaultMessage: defaults['report.comment']
		},
		by: {
			id: 'report.by',
			defaultMessage: defaults['report.by']
		},
		at: {
			id: 'report.at',
			defaultMessage: defaults['report.at']
		},
		telephones: {
			id: 'report.telephones',
			defaultMessage: defaults['report.telephones']
		},
		totalTax: {
			id: 'report.totalTax',
			defaultMessage: defaults['report.totalTax']
		},
		totalRetention: {
			id: 'report.totalRetention',
			defaultMessage: defaults['report.totalRetention']
		},
		toPay: {
			id: 'report.toPay',
			defaultMessage: defaults['report.toPay']
		},
		month: {
			id: 'report.month',
			defaultMessage: defaults['report.month']
		},
		userNotFound: {
			id: 'report.userNotFound',
			defaultMessage: defaults['report.userNotFound']
		},
		commentsOf: {
			id: 'report.commentsOf',
			defaultMessage: defaults['report.commentsOf']
		},
		says: {
			id: 'report.says',
			defaultMessage: defaults['report.says']
		},
		message: {
			id: 'report.message',
			defaultMessage: defaults['report.message']
		},
		invalidEmptyInput: {
			id: 'report.invalidEmptyInput',
			defaultMessage: defaults['report.invalidEmptyInput']
		},
		Active: {
			id: 'report.Active',
			defaultMessage: defaults['report.Active'],
		},
		Overdue: {
			id: 'report.Overdue',
			defaultMessage: defaults['report.Overdue'],
		},
		Paid: {
			id: 'report.Paid',
			defaultMessage: defaults['report.Paid'],
		},
		Annulled: {
			id: 'report.Annulled',
			defaultMessage: defaults['report.Annulled'],
		},
		InvoiceStatus: {
			id: 'report.InvoiceStatus',
			defaultMessage: defaults['report.InvoiceStatus'],
		},
		exportReport: {
			id: 'report.exportReport',
			defaultMessage: defaults['report.exportReport'],
		},
		accountStatement: {
			id: 'report.accountStatement',
			defaultMessage: defaults['report.accountStatement'],
		},
		errorExportAccountStatement: {
			id: 'report.errorExportAccountStatement',
			defaultMessage: defaults['report.errorExportAccountStatement'],
		},
		print: {
			id: 'report.print',
			defaultMessage: defaults['report.print'],
		},
		invalidDate: {
			id: 'report.invalidDate',
			defaultMessage: defaults['report.invalidDate'],
		},
		send: {
			id: 'report.send',
			defaultMessage: defaults['report.send'],
		},
		clientMovement: {
			id: 'report.clientMovement',
			defaultMessage: defaults['report.clientMovement'],
		},
		noProducts: {
			id: 'report.noProducts',
			defaultMessage: defaults['report.noProducts'],
		},
		all: {
			id: 'report.all',
			defaultMessage: defaults['report.all'],
		},
		accounts: {
			id: 'report.accounts',
			defaultMessage: defaults['report.accounts'],
		},
		generateReportError: {
			id: 'report.generateReportError',
			defaultMessage: defaults['report.generateReportError'],
		},
		jobType: {
			id: 'report.jobType',
			defaultMessage: defaults['report.jobType'],
		},
		status: {
			id: 'report.status',
			defaultMessage: defaults['report.status'],
		},
		description: {
			id: 'report.description',
			defaultMessage: defaults['report.description'],
		},
		assignInvoice: {
			id: 'report.assignInvoice',
			defaultMessage: defaults['report.assignInvoice'],
		},
		cantLink: {
			id: 'report.cantLink',
			defaultMessage: defaults['report.cantLink'],
		},
		toThisInvoice: {
			id: 'report.toThisInvoice',
			defaultMessage: defaults['report.toThisInvoice'],
		},
		paymentsMatchAllCriteriaTrue: {
			id: 'report.paymentsMatchAllCriteriaTrue',
			defaultMessage: defaults['report.paymentsMatchAllCriteriaTrue'],
		},
		paymentsMatchAllCriteriaFalse: {
			id: 'report.paymentsMatchAllCriteriaFalse',
			defaultMessage: defaults['report.paymentsMatchAllCriteriaFalse'],
		},
		typeOfPayment: {
			id: 'report.typeOfPayment',
			defaultMessage: defaults['report.typeOfPayment'],
		},
		activeAccounts: {
			id: 'report.activeAccounts',
			defaultMessage: defaults['report.activeAccounts'],
		},
		inactiveAccounts: {
			id: 'report.inactiveAccounts',
			defaultMessage: defaults['report.inactiveAccounts'],
		},
		resultsCount: {
			id: 'report.resultsCount',
			defaultMessage: defaults['report.resultsCount'],
		},
		paymentsSearchError: {
			id: 'report.paymentsSearchError',
			defaultMessage: defaults['report.paymentsSearchError'],
		},
		clientNameOnInvoice: {
			id: 'report.clientNameOnInvoice',
			defaultMessage: defaults['report.clientNameOnInvoice'],
		},
		monthlyService: {
			id: 'report.monthlyService',
			defaultMessage: defaults['report.monthlyService'],
		},
		leasedService: {
			id: 'report.leasedService',
			defaultMessage: defaults['report.leasedService'],
		},
		exportSuccess: {
			id: 'report.exportSuccess',
			defaultMessage: defaults['report.exportSuccess'],
		},
		exportError: {
			id: 'report.exportError',
			defaultMessage: defaults['report.exportError'],
		},
	});
}

export const getIncomeMessages = () => {
	return defineMessages({
		income: {
			id: 'income.income',
			defaultMessage: defaults['income.income'],
		},
		expense: {
			id: 'income.expense',
			defaultMessage: defaults['income.expense'],
		},
		description: {
			id: 'income.description',
			defaultMessage: defaults['income.description'],
		},
		refNumber: {
			id: 'income.refNumber',
			defaultMessage: defaults['income.refNumber'],
		},
		amount: {
			id: 'income.amount',
			defaultMessage: defaults['income.amount'],
		},
		categories: {
			id: 'income.categories',
			defaultMessage: defaults['income.categories'],
		},
		category: {
			id: 'income.category',
			defaultMessage: defaults['income.category'],
		},
		add: {
			id: 'income.add',
			defaultMessage: defaults['income.add'],
		},
		totalInvalid: {
			id: 'income.totalInvalid',
			defaultMessage: defaults['income.totalInvalid'],
		},
		entryCreateSucces: {
			id: 'income.entryCreateSucces',
			defaultMessage: defaults['income.entryCreateSucces'],
		},
		noMoreEntries: {
			id: 'income.noMoreEntries',
			defaultMessage: defaults['income.noMoreEntries'],
		},
		entryError: {
			id: 'income.entryError',
			defaultMessage: defaults['income.entryError'],
		},
		invalidAmount: {
			id: 'income.invalidAmount',
			defaultMessage: defaults['income.invalidAmount'],
		},
		addCategory: {
			id: 'income.addCategory',
			defaultMessage: defaults['income.addCategory'],
		},
		name: {
			id: 'income.name',
			defaultMessage: defaults['income.name'],
		},
		active: {
			id: 'income.active',
			defaultMessage: defaults['income.active'],
		},
		actions: {
			id: 'income.actions',
			defaultMessage: defaults['income.actions'],
		},
		enabled: {
			id: 'income.enabled',
			defaultMessage: defaults['income.enabled'],
		},
		disabled: {
			id: 'income.disabled',
			defaultMessage: defaults['income.disabled'],
		},
		edit: {
			id: 'income.edit',
			defaultMessage: defaults['income.edit'],
		},
		deactivate: {
			id: 'income.deactivate',
			defaultMessage: defaults['income.deactivate'],
		},
		activate: {
			id: 'income.activate',
			defaultMessage: defaults['income.activate'],
		},
		categoryUpdateSuccesfull: {
			id: 'income.categoryUpdateSuccesfull',
			defaultMessage: defaults['income.categoryUpdateSuccesfull'],
		},
		categoryUpdateError: {
			id: 'income.categoryUpdateError',
			defaultMessage: defaults['income.categoryUpdateError'],
		},
		all: {
			id: 'income.all',
			defaultMessage: defaults['income.all'],
		},
		addAccount: {
			id: 'income.addAccount',
			defaultMessage: defaults['income.addAccount'],
		},
		balance: {
			id: 'income.balance',
			defaultMessage: defaults['income.balance'],
		},
		searchBank: {
			id: 'income.searchBank',
			defaultMessage: defaults['income.searchBank'],
		},
		selectBank: {
			id: 'income.selectBank',
			defaultMessage: defaults['income.selectBank'],
		},
		noBankSelected: {
			id: 'income.noBankSelected',
			defaultMessage: defaults['income.noBankSelected'],
		},
		monthBalanceSheet: {
			id: 'income.monthBalanceSheet',
			defaultMessage: defaults['income.monthBalanceSheet'],
		},
		balanceSheetCreatedSuccessfully: {
			id: 'income.balanceSheetCreatedSuccessfully',
			defaultMessage: defaults['income.balanceSheetCreatedSuccessfully'],
		},
		accountInDollars: {
			id: 'income.accountInDollars',
			defaultMessage: defaults['income.accountInDollars'],
		},
		otherDetails: {
			id: 'income.otherDetails',
			defaultMessage: defaults['income.otherDetails'],
		},
		parent: {
			id: 'income.parent',
			defaultMessage: defaults['income.parent'],
		},
		isSubCategory: {
			id: 'income.isSubCategory',
			defaultMessage: defaults['income.isSubCategory'],
		},
		subCategory: {
			id: 'income.subCategory',
			defaultMessage: defaults['income.subCategory'],
		},
		remove: {
			id: 'income.remove',
			defaultMessage: defaults['income.remove'],
		},
		removeWarning: {
			id: 'income.removeWarning',
			defaultMessage: defaults['income.removeWarning'],
		},
		removedSuccesfully: {
			id: 'income.removedSuccesfully',
			defaultMessage: defaults['income.removedSuccesfully'],
		},
		errorRemoving: {
			id: 'income.errorRemoving',
			defaultMessage: defaults['income.errorRemoving'],
		},
		addEntry: {
			id: 'income.addEntry',
			defaultMessage: defaults['income.addEntry'],
		},
		entryType: {
			id: 'income.entryType',
			defaultMessage: defaults['income.entryType'],
		},
		initialBalance: {
			id: 'income.initialBalance',
			defaultMessage: defaults['income.initialBalance'],
		},
		initialBalanceError: {
			id: 'income.initialBalanceError',
			defaultMessage: defaults['income.initialBalanceError'],
		},
		finalBalance: {
			id: 'income.finalBalance',
			defaultMessage: defaults['income.finalBalance'],
		},
		balanceSheetMonth: {
			id: 'income.balanceSheetMonth',
			defaultMessage: defaults['income.balanceSheetMonth'],
		},
		getLatestBalanceSheetMonthError: {
			id: 'income.getLatestBalanceSheetMonthError',
			defaultMessage: defaults['income.getLatestBalanceSheetMonthError'],
		},
		getBalanceSheetInfoError: {
			id: 'income.getBalanceSheetInfoError',
			defaultMessage: defaults['income.getBalanceSheetInfoError'],
		},
		generateBalanceSheetError: {
			id: 'income.generateBalanceSheetError',
			defaultMessage: defaults['income.generateBalanceSheetError'],
		},
		initialDate: {
			id: 'income.initialDate',
			defaultMessage: defaults['income.initialDate'],
		},
		initialDateError: {
			id: 'income.initialDateError',
			defaultMessage: defaults['income.initialDateError'],
		},
		monthToClose: {
			id: 'income.monthToClose',
			defaultMessage: defaults['income.monthToClose'],
		},
		balanceSheetAutogenerateRange: {
			id: 'income.balanceSheetAutogenerateRange',
			defaultMessage: defaults['income.balanceSheetAutogenerateRange'],
		},
	})
}

export const getDepartmentMessages = () => {
	return defineMessages({
		departmentEmployeesUnableToDelete: {
			id: 'department.departmentEmployeesUnableToDelete',
			defaultMessage: defaults['department.departmentEmployeesUnableToDelete'],
		},
		departments: {
			id: 'department.departments',
			defaultMessage: defaults['department.departments'],
		},
		workRoles: {
			id: 'department.workRoles',
			defaultMessage: defaults['department.workRoles'],
		},
		getDepartmentsError: {
			id: 'department.getDepartmentsError',
			defaultMessage: defaults['department.getDepartmentsError'],
		},
		getWorkRolesError: {
			id: 'department.getWorkRolesError',
			defaultMessage: defaults['department.getWorkRolesError'],
		},
		department: {
			id: 'department.department',
			defaultMessage: defaults['department.department'],
		},
		workRole: {
			id: 'department.workRole',
			defaultMessage: defaults['department.workRole'],
		},
		departmentName: {
			id: 'department.departmentName',
			defaultMessage: defaults['department.departmentName'],
		},
		departmentNameError: {
			id: 'department.departmentNameError',
			defaultMessage: defaults['department.departmentNameError'],
		},
		deleteDepartmentError: {
			id: 'department.deleteDepartmentError',
			defaultMessage: defaults['department.deleteDepartmentError'],
		},
		addDepartmentSuccess: {
			id: 'department.addDepartmentSuccess',
			defaultMessage: defaults['department.addDepartmentSuccess'],
		},
		addDepartmentError: {
			id: 'department.addDepartmentError',
			defaultMessage: defaults['department.addDepartmentError'],
		},
		editDepartmentSuccess: {
			id: 'department.editDepartmentSuccess',
			defaultMessage: defaults['department.editDepartmentSuccess'],
		},
		editDepartmentError: {
			id: 'department.editDepartmentError',
			defaultMessage: defaults['department.editDepartmentError'],
		},
		getDepartmentCountError: {
			id: 'department.getDepartmentCountError',
			defaultMessage: defaults['department.getDepartmentCountError'],
		},
		roleName: {
			id: 'department.roleName',
			defaultMessage: defaults['department.roleName'],
		},
		getWorkRoleCountError: {
			id: 'department.getWorkRoleCountError',
			defaultMessage: defaults['department.getWorkRoleCountError'],
		},
		workRoleNameError: {
			id: 'department.workRoleNameError',
			defaultMessage: defaults['department.workRoleNameError'],
		},
		deleteWorkRoleError: {
			id: 'department.deleteWorkRoleError',
			defaultMessage: defaults['department.deleteWorkRoleError'],
		},
		addWorkRoleSuccess: {
			id: 'department.addWorkRoleSuccess',
			defaultMessage: defaults['department.addWorkRoleSuccess'],
		},
		addWorkRoleError: {
			id: 'department.addWorkRoleError',
			defaultMessage: defaults['department.addWorkRoleError'],
		},
		editWorkRoleSuccess: {
			id: 'department.editWorkRoleSuccess',
			defaultMessage: defaults['department.editWorkRoleSuccess'],
		},
		editWorkRoleError: {
			id: 'department.editWorkRoleError',
			defaultMessage: defaults['department.editWorkRoleError'],
		},
		departmentEmptyError: {
			id: 'department.departmentEmptyError',
			defaultMessage: defaults['department.departmentEmptyError'],
		},
		notFound: {
			id: 'department.notFound',
			defaultMessage: defaults['department.notFound'],
		},
	});
};

export const getEmployeeMessages = () => {
	return defineMessages({
		numberIdentificationisInValid:{
			id: 'employee.numberIdentificationisInValid',
			defaultMessage: defaults['employee.numberIdentificationisInValid'],
		},
		employees: {
			id: 'employee.employees',
			defaultMessage: defaults['employee.employees'],
		},
		getEmployeeInfoError: {
			id: 'employee.getEmployeeInfoError',
			defaultMessage: defaults['employee.getEmployeeInfoError'],
		},
		getEmployeesError: {
			id: 'employee.getEmployeesError',
			defaultMessage: defaults['employee.getEmployeesError'],
		},
		getEmployeeCountError: {
			id: 'employee.getEmployeeCountError',
			defaultMessage: defaults['employee.getEmployeeCountError'],
		},
		activateEmployeeSuccess: {
			id: 'employee.activateEmployeeSuccess',
			defaultMessage: defaults['employee.activateEmployeeSuccess'],
		},
		deactivateEmployeeSuccess: {
			id: 'employee.deactivateEmployeeSuccess',
			defaultMessage: defaults['employee.deactivateEmployeeSuccess'],
		},
		activateEmployeeError: {
			id: 'employee.activateEmployeeError',
			defaultMessage: defaults['employee.activateEmployeeError'],
		},
		deactivateEmployeeError: {
			id: 'employee.deactivateEmployeeError',
			defaultMessage: defaults['employee.deactivateEmployeeError'],
		},
		activate: {
			id: 'employee.activate',
			defaultMessage: defaults['employee.activate'],
		},
		deactivate: {
			id: 'employee.deactivate',
			defaultMessage: defaults['employee.deactivate'],
		},
		active: {
			id: 'employee.active',
			defaultMessage: defaults['employee.active'],
		},
		inactive: {
			id: 'employee.inactive',
			defaultMessage: defaults['employee.inactive'],
		},
		status: {
			id: 'employee.status',
			defaultMessage: defaults['employee.status'],
		},
		nameError: {
			id: 'employee.nameError',
			defaultMessage: defaults['employee.nameError'],
		},
		identificationError: {
			id: 'employee.identificationError',
			defaultMessage: defaults['employee.identificationError'],
		},
		identification: {
			id: 'employee.identification',
			defaultMessage: defaults['employee.identification'],
		},
		hireDate: {
			id: 'employee.hireDate',
			defaultMessage: defaults['employee.hireDate'],
		},
		hireDateError: {
			id: 'employee.hireDateError',
			defaultMessage: defaults['employee.hireDateError'],
		},
		birthdate: {
			id: 'employee.birthdate',
			defaultMessage: defaults['employee.birthdate'],
		},
		birthdateError: {
			id: 'employee.birthdateError',
			defaultMessage: defaults['employee.birthdateError'],
		},
		bloodType: {
			id: 'employee.bloodType',
			defaultMessage: defaults['employee.bloodType'],
		},
		bloodTypeError: {
			id: 'employee.bloodTypeError',
			defaultMessage: defaults['employee.bloodTypeError'],
		},
		bankAccount: {
			id: 'employee.bankAccount',
			defaultMessage: defaults['employee.bankAccount'],
		},
		bankAccountError: {
			id: 'employee.bankAccountError',
			defaultMessage: defaults['employee.bankAccountError'],
		},
		address: {
			id: 'employee.address',
			defaultMessage: defaults['employee.address'],
		},
		addressError: {
			id: 'employee.addressError',
			defaultMessage: defaults['employee.addressError'],
		},
		workRole: {
			id: 'employee.workRole',
			defaultMessage: defaults['employee.workRole'],
		},
		workRoleError: {
			id: 'employee.workRoleError',
			defaultMessage: defaults['employee.workRoleError'],
		},
		phone: {
			id: 'employee.phone',
			defaultMessage: defaults['employee.phone'],
		},
		phoneError: {
			id: 'employee.phoneError',
			defaultMessage: defaults['employee.phoneError'],
		},
		phoneNumberError: {
			id: 'employee.phoneNumberError',
			defaultMessage: defaults['employee.phoneNumberError'],
		},
		relation: {
			id: 'employee.relation',
			defaultMessage: defaults['employee.relation'],
		},
		relationError: {
			id: 'employee.relationError',
			defaultMessage: defaults['employee.relationError'],
		},
		spouse: {
			id: 'employee.spouse',
			defaultMessage: defaults['employee.spouse'],
		},
		father: {
			id: 'employee.father',
			defaultMessage: defaults['employee.father'],
		},
		mother: {
			id: 'employee.mother',
			defaultMessage: defaults['employee.mother'],
		},
		child: {
			id: 'employee.child',
			defaultMessage: defaults['employee.child'],
		},
		sibling: {
			id: 'employee.sibling',
			defaultMessage: defaults['employee.sibling'],
		},
		addPhone: {
			id: 'employee.addPhone',
			defaultMessage: defaults['employee.addPhone'],
		},
		friend: {
			id: 'employee.friend',
			defaultMessage: defaults['employee.friend'],
		},
		emergencyContacts: {
			id: 'employee.emergencyContacts',
			defaultMessage: defaults['employee.emergencyContacts'],
		},
		addDependant: {
			id: 'employee.addDependant',
			defaultMessage: defaults['employee.addDependant'],
		},
		dependants: {
			id: 'employee.dependants',
			defaultMessage: defaults['employee.dependants'],
		},
		name: {
			id: 'employee.name',
			defaultMessage: defaults['employee.name'],
		},
		salary: {
			id: 'employee.salary',
			defaultMessage: defaults['employee.salary'],
		},
		salaryError: {
			id: 'employee.salaryError',
			defaultMessage: defaults['employee.salaryError'],
		},
		addEmployeeSuccess: {
			id: 'employee.addEmployeeSuccess',
			defaultMessage: defaults['employee.addEmployeeSuccess'],
		},
		addEmployeeError: {
			id: 'employee.addEmployeeError',
			defaultMessage: defaults['employee.addEmployeeError'],
		},
		editEmployeeSuccess: {
			id: 'employee.editEmployeeSuccess',
			defaultMessage: defaults['employee.editEmployeeSuccess'],
		},
		editEmployeeError: {
			id: 'employee.editEmployeeError',
			defaultMessage: defaults['employee.editEmployeeError'],
		},
		employeeView: {
			id: 'employee.employeeView',
			defaultMessage: defaults['employee.employeeView'],
		},
		doesNotHave: {
			id: 'employee.doesNotHave',
			defaultMessage: defaults['employee.doesNotHave'],
		},
		idPhoto: {
			id: 'employee.idPhoto',
			defaultMessage: defaults['employee.idPhoto'],
		},
		licensePhoto: {
			id: 'employee.licensePhoto',
			defaultMessage: defaults['employee.licensePhoto'],
		},
		maritalStatus: {
			id: 'employee.maritalStatus',
			defaultMessage: defaults['employee.maritalStatus'],
		},
		maritalStatusError: {
			id: 'employee.maritalStatusError',
			defaultMessage: defaults['employee.maritalStatusError'],
		},
		gender: {
			id: 'employee.gender',
			defaultMessage: defaults['employee.gender'],
		},
		genderError: {
			id: 'employee.genderError',
			defaultMessage: defaults['employee.genderError'],
		},
		licenseExpirationDate: {
			id: 'employee.licenseExpirationDate',
			defaultMessage: defaults['employee.licenseExpirationDate'],
		},
		insurancePolicy: {
			id: 'employee.insurancePolicy',
			defaultMessage: defaults['employee.insurancePolicy'],
		},
		insuranceCertificate: {
			id: 'employee.insuranceCertificate',
			defaultMessage: defaults['employee.insuranceCertificate'],
		},
		female: {
			id: 'employee.female',
			defaultMessage: defaults['employee.female'],
		},
		male: {
			id: 'employee.male',
			defaultMessage: defaults['employee.male'],
		},
		single: {
			id: 'employee.single',
			defaultMessage: defaults['employee.single'],
		},
		married: {
			id: 'employee.married',
			defaultMessage: defaults['employee.married'],
		},
		divorced: {
			id: 'employee.divorced',
			defaultMessage: defaults['employee.divorced'],
		},
		widow: {
			id: 'employee.widow',
			defaultMessage: defaults['employee.widow'],
		},
		freeUnion: {
			id: 'employee.freeUnion',
			defaultMessage: defaults['employee.freeUnion'],
		},
		"4MbMaxPhotoSizeError": {
			id: 'employee.4MbMaxPhotoSizeError',
			defaultMessage: defaults['employee.4MbMaxPhotoSizeError'],
		},
		"noIdPhotoAvailable": {
			id: 'employee.noIdPhotoAvailable',
			defaultMessage: defaults['employee.noIdPhotoAvailable'],
		},
		"noLicensePhotoAvailable": {
			id: 'employee.noLicensePhotoAvailable',
			defaultMessage: defaults['employee.noLicensePhotoAvailable'],
		},
		"license": {
			id: 'employee.license',
			defaultMessage: defaults['employee.license'],
		},
		expiredLicense:{
			id: 'employee.expiredLicense',
			defaultMessage: defaults['employee.expiredLicense'],
		},
		"personalInformation": {
			id: 'employee.personalInformation',
			defaultMessage: defaults['employee.personalInformation'],
		},
		"workInformation": {
			id: 'employee.workInformation',
			defaultMessage: defaults['employee.workInformation'],
		},
		"antiquity": {
			id: 'employee.antiquity',
			defaultMessage: defaults['employee.antiquity'],
		},
		"addDeduction": {
			id: 'employee.addDeduction',
			defaultMessage: defaults['employee.addDeduction'],
		},
		"editDeduction": {
			id: 'employee.editDeduction',
			defaultMessage: defaults['employee.editDeduction'],
		},
		"fixedAmount": {
			id: 'employee.fixedAmount',
			defaultMessage: defaults['employee.fixedAmount'],
		},
		"calculatedAmount": {
			id: 'employee.calculatedAmount',
			defaultMessage: defaults['employee.calculatedAmount'],
		},
		"biweekly": {
			id: 'employee.biweekly',
			defaultMessage: defaults['employee.biweekly'],
		},
		"monthly": {
			id: 'employee.monthly',
			defaultMessage: defaults['employee.monthly'],
		},
		"deduction": {
			id: 'employee.deduction',
			defaultMessage: defaults['employee.deduction'],
		},
		"deductionError": {
			id: 'employee.deductionError',
			defaultMessage: defaults['employee.deductionError'],
		},
		"amount": {
			id: 'employee.amount',
			defaultMessage: defaults['employee.amount'],
		},
		"amountError": {
			id: 'employee.amountError',
			defaultMessage: defaults['employee.amountError'],
		},
		"initialDate": {
			id: 'employee.initialDate',
			defaultMessage: defaults['employee.initialDate'],
		},
		"initialDateError": {
			id: 'employee.initialDateError',
			defaultMessage: defaults['employee.initialDateError'],
		},
		"finalDate": {
			id: 'employee.finalDate',
			defaultMessage: defaults['employee.finalDate'],
		},
		"finalDateError": {
			id: 'employee.finalDateError',
			defaultMessage: defaults['employee.finalDateError'],
		},
		"recurrence": {
			id: 'employee.recurrence',
			defaultMessage: defaults['employee.recurrence'],
		},
		"recurrenceError": {
			id: 'employee.recurrenceError',
			defaultMessage: defaults['employee.recurrenceError'],
		},
		"total": {
			id: 'employee.total',
			defaultMessage: defaults['employee.total'],
		},
		"totalError": {
			id: 'employee.totalError',
			defaultMessage: defaults['employee.totalError'],
		},
		"dueAmount": {
			id: 'employee.dueAmount',
			defaultMessage: defaults['employee.dueAmount'],
		},
		"dueAmountError": {
			id: 'employee.dueAmountError',
			defaultMessage: defaults['employee.dueAmountError'],
		},
		"noFinalDate": {
			id: 'employee.noFinalDate',
			defaultMessage: defaults['employee.noFinalDate'],
		},
	});
};

export const getRequestMessages = () => {
	return defineMessages({
		requests: {
			id: 'request.requests',
			defaultMessage: defaults['request.requests'],
		},
		request: {
			id: 'request.request',
			defaultMessage: defaults['request.request'],
		},
		client: {
			id: 'request.client',
			defaultMessage: defaults['request.client'],
		},
		requestedBy: {
			id: 'request.requestedBy',
			defaultMessage: defaults['request.requestedBy'],
		},
		dateRequested: {
			id: 'request.dateRequested',
			defaultMessage: defaults['request.dateRequested'],
		},
		details: {
			id: 'request.details',
			defaultMessage: defaults['request.details'],
		},
		actions: {
			id: 'request.actions',
			defaultMessage: defaults['request.actions'],
		},
		status: {
			id: 'request.status',
			defaultMessage: defaults['request.status'],
		},
		all: {
			id: 'request.all',
			defaultMessage: defaults['request.all'],
		},
		finished: {
			id: 'request.finished',
			defaultMessage: defaults['request.finished'],
		},
		unfinished: {
			id: 'request.unfinished',
			defaultMessage: defaults['request.unfinished'],
		},
		finishedBy: {
			id: 'request.finishedBy',
			defaultMessage: defaults['request.finishedBy'],
		},
		on: {
			id: 'request.on',
			defaultMessage: defaults['request.on'],
		},
		addRequest: {
			id: 'request.addRequest',
			defaultMessage: defaults['request.addRequest'],
		},
		leasedGPSs: {
			id: 'request.leasedGPSs',
			defaultMessage: defaults['request.leasedGPSs'],
		},
		boughtGPSs: {
			id: 'request.boughtGPSs',
			defaultMessage: defaults['request.boughtGPSs'],
		},
		markFinished: {
			id: 'request.markFinished',
			defaultMessage: defaults['request.markFinished'],
		},
		finishQ: {
			id: 'request.finishQ',
			defaultMessage: defaults['request.finishQ'],
		},
		getRequestsError: {
			id: 'request.getRequestsError',
			defaultMessage: defaults['request.getRequestsError'],
		},
		addRequestSuccess: {
			id: 'request.addRequestSuccess',
			defaultMessage: defaults['request.addRequestSuccess'],
		},
		addRequestError: {
			id: 'request.addRequestError',
			defaultMessage: defaults['request.addRequestError'],
		},
		finishRequestError: {
			id: 'request.finishRequestError',
			defaultMessage: defaults['request.finishRequestError'],
		},
		viewRequest: {
			id: 'request.viewRequest',
			defaultMessage: defaults['request.viewRequest'],
		},
	});
};

export const getGlobalComponentMessages = () => {
	return defineMessages({
		add: {
			id: 'globalComponent.add',
			defaultMessage: defaults['globalComponent.add'],
		},
		previewLoadError: {
			id: 'globalComponent.previewLoadError',
			defaultMessage: defaults['globalComponent.previewLoadError'],
		},
		maxFilesSelectedError: {
			id: 'globalComponent.maxFilesSelectedError',
			defaultMessage: defaults['globalComponent.maxFilesSelectedError'],
		},
		dragAdd: {
			id: 'globalComponent.dragAdd',
			defaultMessage: defaults['globalComponent.dragAdd'],
		},
		invalidUploadTypeError: {
			id: 'globalComponent.invalidUploadTypeError',
			defaultMessage: defaults['globalComponent.invalidUploadTypeError'],
		},
		doesNotHave: {
			id: 'globalComponent.doesNotHave',
			defaultMessage: defaults['globalComponent.doesNotHave'],
		},
	});
};

export const getTechnicianReportMessages = () => {
	return defineMessages({
		dateRange: {
			id: 'techReport.dateRange',
			defaultMessage: defaults['techReport.dateRange'],
		},
		fetchTechJobsError: {
			id: 'techReport.fetchTechJobsError',
			defaultMessage: defaults['techReport.fetchTechJobsError'],
		},
		fetchTechniciansError: {
			id: 'techReport.fetchTechniciansError',
			defaultMessage: defaults['techReport.fetchTechniciansError'],
		},
		quantity: {
			id: 'techReport.quantity',
			defaultMessage: defaults['techReport.quantity'],
		},
		technicians: {
			id: 'techReport.technicians',
			defaultMessage: defaults['techReport.technicians'],
		},
		pieChartTitle: {
			id: 'techReport.pieChartTitle',
			defaultMessage: defaults['techReport.pieChartTitle'],
		},
		barChartTitle: {
			id: 'techReport.barChartTitle',
			defaultMessage: defaults['techReport.barChartTitle'],
		},
		unknown: {
			id: 'techReport.unknown',
			defaultMessage: defaults['techReport.unknown'],
		},
	});
};

export const getDashboardMessages = () => {
	return defineMessages({
		monthDifference: {
			id: 'dashboard.monthDifference',
			defaultMessage: defaults['dashboard.monthDifference'],
		},
		month: {
			id: 'dashboard.month',
			defaultMessage: defaults['dashboard.month'],
		},
		quantity: {
			id: 'dashboard.quantity',
			defaultMessage: defaults['dashboard.quantity'],
		},
		activeClients: {
			id: 'dashboard.activeClients',
			defaultMessage: defaults['dashboard.activeClients'],
		},
		activeClientsByMonth: {
			id: 'dashboard.activeClientsByMonth',
			defaultMessage: defaults['dashboard.activeClientsByMonth'],
		},
		getClientInfoError: {
			id: 'dashboard.getClientInfoError',
			defaultMessage: defaults['dashboard.getClientInfoError'],
		},
		activeDevices: {
			id: 'dashboard.activeDevices',
			defaultMessage: defaults['dashboard.activeDevices'],
		},
		activeDevicesByMonth: {
			id: 'dashboard.activeDevicesByMonth',
			defaultMessage: defaults['dashboard.activeDevicesByMonth'],
		},
		getDeviceInfoError: {
			id: 'dashboard.getDeviceInfoError',
			defaultMessage: defaults['dashboard.getDeviceInfoError'],
		},
		thisMonthsInvoicing: {
			id: 'dashboard.thisMonthsInvoicing',
			defaultMessage: defaults['dashboard.thisMonthsInvoicing'],
		},
		invoicedSubtotal: {
			id: 'dashboard.invoicedSubtotal',
			defaultMessage: defaults['dashboard.invoicedSubtotal'],
		},
		invoicedTaxes: {
			id: 'dashboard.invoicedTaxes',
			defaultMessage: defaults['dashboard.invoicedTaxes'],
		},
		last7Days: {
			id: 'dashboard.last7Days',
			defaultMessage: defaults['dashboard.last7Days'],
		},
		last30Days: {
			id: 'dashboard.last30Days',
			defaultMessage: defaults['dashboard.last30Days'],
		},
		last12Months: {
			id: 'dashboard.last12Months',
			defaultMessage: defaults['dashboard.last12Months'],
		},
		thisWeek: {
			id: 'dashboard.thisWeek',
			defaultMessage: defaults['dashboard.thisWeek'],
		},
		thisMonth: {
			id: 'dashboard.thisMonth',
			defaultMessage: defaults['dashboard.thisMonth'],
		},
		thisYear: {
			id: 'dashboard.thisYear',
			defaultMessage: defaults['dashboard.thisYear'],
		},
		invoicingVsPayments: {
			id: 'dashboard.invoicingVsPayments',
			defaultMessage: defaults['dashboard.invoicingVsPayments'],
		},
		getInvoicingVsPaymentsInfoError: {
			id: 'dashboard.getInvoicingVsPaymentsInfoError',
			defaultMessage: defaults['dashboard.getInvoicingVsPaymentsInfoError'],
		},
		invoices: {
			id: 'dashboard.invoices',
			defaultMessage: defaults['dashboard.invoices'],
		},
		invoicesWithType: {
			id: 'dashboard.invoicesWithType',
			defaultMessage: defaults['dashboard.invoicesWithType'],
		},
		payments: {
			id: 'dashboard.payments',
			defaultMessage: defaults['dashboard.payments'],
		},
		clients: {
			id: 'dashboard.clients',
			defaultMessage: defaults['dashboard.clients'],
		},
		devices: {
			id: 'dashboard.devices',
			defaultMessage: defaults['dashboard.devices'],
		},
		total: {
			id: 'dashboard.total',
			defaultMessage: defaults['dashboard.total'],
		},
		range: {
			id: 'dashboard.range',
			defaultMessage: defaults['dashboard.range'],
		},
		showCreditNotes: {
			id: 'dashboard.showCreditNotes',
			defaultMessage: defaults['dashboard.showCreditNotes'],
		},
		invoicesCount: {
			id: 'dashboard.invoicesCount',
			defaultMessage: defaults['dashboard.invoicesCount'],
		},
		invoiceTaxes: {
			id: 'dashboard.invoiceTaxes',
			defaultMessage: defaults['dashboard.invoiceTaxes'],
		},
		showActiveInvoices: {
			id: 'dashboard.showActiveInvoices',
			defaultMessage: defaults['dashboard.showActiveInvoices'],
		},
		thisMonthsInArrearInvoices: {
			id: 'dashboard.thisMonthsInArrearInvoices',
			defaultMessage: defaults['dashboard.thisMonthsInArrearInvoices'],
		},
		getInvoicesTaxInfoError: {
			id: 'dashboard.getInvoicesTaxInfoError',
			defaultMessage: defaults['dashboard.getInvoicesTaxInfoError'],
		},
		invoiceTypesToShow: {
			id: 'dashboard.invoiceTypesToShow',
			defaultMessage: defaults['dashboard.invoiceTypesToShow'],
		},
		allTogether: {
			id: 'dashboard.allTogether',
			defaultMessage: defaults['dashboard.allTogether'],
		},
		servicio: {
			id: 'dashboard.servicio',
			defaultMessage: defaults['dashboard.servicio'],
		},
		product: {
			id: 'dashboard.product',
			defaultMessage: defaults['dashboard.product'],
		},
		mixed: {
			id: 'dashboard.mixed',
			defaultMessage: defaults['dashboard.mixed'],
		},
	});
};

export const getPayrollMessages = () => {
	return defineMessages({
		getPayrollsError: {
			id: 'payroll.getPayrollsError',
			defaultMessage: defaults['payroll.getPayrollsError'],
		},
		getPayrollInfoError: {
			id: 'payroll.getPayrollInfoError',
			defaultMessage: defaults['payroll.getPayrollInfoError'],
		},
		payrollView: {
			id: 'payroll.payrollView',
			defaultMessage: defaults['payroll.payrollView'],
		},
		doesNotHave: {
			id: 'payroll.doesNotHave',
			defaultMessage: defaults['payroll.doesNotHave'],
		},
		previewPayroll: {
			id: 'payroll.previewPayroll',
			defaultMessage: defaults['payroll.previewPayroll'],
		},
		generatePayroll: {
			id: 'payroll.generatePayroll',
			defaultMessage: defaults['payroll.generatePayroll'],
		},
		getGeneratePayrollInfoError: {
			id: 'payroll.getGeneratePayrollInfoError',
			defaultMessage: defaults['payroll.getGeneratePayrollInfoError'],
		},
		accreditedSalary: {
			id: 'payroll.accreditedSalary',
			defaultMessage: defaults['payroll.accreditedSalary'],
		},
		medicalInsurance: {
			id: 'payroll.medicalInsurance',
			defaultMessage: defaults['payroll.medicalInsurance'],
		},
		phone: {
			id: 'payroll.phone',
			defaultMessage: defaults['payroll.phone'],
		},
		cooperative: {
			id: 'payroll.cooperative',
			defaultMessage: defaults['payroll.cooperative'],
		},
		deductions: {
			id: 'payroll.deductions',
			defaultMessage: defaults['payroll.deductions'],
		},
		ihss: {
			id: 'payroll.ihss',
			defaultMessage: defaults['payroll.ihss'],
		},
		isr: {
			id: 'payroll.isr',
			defaultMessage: defaults['payroll.isr'],
		},
		name: {
			id: 'payroll.name',
			defaultMessage: defaults['payroll.name'],
		},
		roof: {
			id: 'payroll.roof',
			defaultMessage: defaults['payroll.roof'],
		},
		percentage: {
			id: 'payroll.percentage',
			defaultMessage: defaults['payroll.percentage'],
		},
		getIHSSRoofsError: {
			id: 'payroll.getIHSSRoofsError',
			defaultMessage: defaults['payroll.getIHSSRoofsError'],
		},
		getISRRoofsError: {
			id: 'payroll.getISRRoofsError',
			defaultMessage: defaults['payroll.getISRRoofsError'],
		},
		editISRRoofsError: {
			id: 'payroll.editISRRoofsError',
			defaultMessage: defaults['payroll.editISRRoofsError'],
		},
		extraMaxAmountMsg: {
			id: 'payroll.extraMaxAmountMsg',
			defaultMessage: defaults['payroll.extraMaxAmountMsg'],
		},
		getMedicalDeductionError: {
			id: 'payroll.getMedicalDeductionError',
			defaultMessage: defaults['payroll.getMedicalDeductionError'],
		},
		medicalExpenses: {
			id: 'payroll.medicalExpenses',
			defaultMessage: defaults['payroll.medicalExpenses'],
		},
		medicalDeductions: {
			id: 'payroll.medicalDeductions',
			defaultMessage: defaults['payroll.medicalDeductions'],
		},
		editMedicalDeductionError: {
			id: 'payroll.editMedicalDeductionError',
			defaultMessage: defaults['payroll.editMedicalDeductionError'],
		},
		minAmount: {
			id: 'payroll.minAmount',
			defaultMessage: defaults['payroll.minAmount'],
		},
		maxAmount: {
			id: 'payroll.maxAmount',
			defaultMessage: defaults['payroll.maxAmount'],
		},
		onwards: {
			id: 'payroll.onwards',
			defaultMessage: defaults['payroll.onwards'],
		},
		getDeductionsError: {
			id: 'payroll.getDeductionsError',
			defaultMessage: defaults['payroll.getDeductionsError'],
		},
		baseSalary: {
			id: 'payroll.baseSalary',
			defaultMessage: defaults['payroll.baseSalary'],
		},
		doesNotPay: {
			id: 'payroll.doesNotPay',
			defaultMessage: defaults['payroll.doesNotPay'],
		},
		deductionUpdateError: {
			id: 'payroll.deductionUpdateError',
			defaultMessage: defaults['payroll.deductionUpdateError'],
		},
		generatePayrollSuccess: {
			id: 'payroll.generatePayrollSuccess',
			defaultMessage: defaults['payroll.generatePayrollSuccess'],
		},
		generatePayrollError: {
			id: 'payroll.generatePayrollError',
			defaultMessage: defaults['payroll.generatePayrollError'],
		},
		payrollDate: {
			id: 'payroll.payrollDate',
			defaultMessage: defaults['payroll.payrollDate'],
		},
		creationDate: {
			id: 'payroll.creationDate',
			defaultMessage: defaults['payroll.creationDate'],
		},
		datePickerEnabled: {
			id: 'payroll.datePickerEnabled',
			defaultMessage: defaults['payroll.datePickerEnabled'],
		},
		datePickerDisabled: {
			id: 'payroll.datePickerDisabled',
			defaultMessage: defaults['payroll.datePickerDisabled'],
		},
		datePickerLastPayrollDate: {
			id: 'payroll.datePickerLastPayrollDate',
			defaultMessage: defaults['payroll.datePickerLastPayrollDate'],
		},
		datePickerDisabledShort: {
			id: 'payroll.datePickerDisabledShort',
			defaultMessage: defaults['payroll.datePickerDisabledShort'],
		},
		datePickerLastPayrollDateShort: {
			id: 'payroll.datePickerLastPayrollDateShort',
			defaultMessage: defaults['payroll.datePickerLastPayrollDateShort'],
		},
		getLastPayrollDateError: {
			id: 'payroll.getLastPayrollDateError',
			defaultMessage: defaults['payroll.getLastPayrollDateError'],
		},
		downloadVouchers: {
			id: 'payroll.downloadVouchers',
			defaultMessage: defaults['payroll.downloadVouchers'],
		},
		downloadVouchersError: {
			id: 'payroll.downloadVouchersError',
			defaultMessage: defaults['payroll.downloadVouchersError'],
		},
		biweeklySalary: {
			id: 'payroll.biweeklySalary',
			defaultMessage: defaults['payroll.biweeklySalary'],
		},
		downloadExcel: {
			id: 'payroll.downloadExcel',
			defaultMessage: defaults['payroll.downloadExcel'],
		},
		downloadExcelError: {
			id: 'payroll.downloadExcelError',
			defaultMessage: defaults['payroll.downloadExcelError'],
		},
		deductionNameError: {
			id: 'payroll.deductionNameError',
			defaultMessage: defaults['payroll.deductionNameError'],
		},
		addDeduction: {
			id: 'payroll.addDeduction',
			defaultMessage: defaults['payroll.addDeduction'],
		},
		editDeduction: {
			id: 'payroll.editDeduction',
			defaultMessage: defaults['payroll.editDeduction'],
		},
		addDeductionSuccess: {
			id: 'payroll.addDeductionSuccess',
			defaultMessage: defaults['payroll.addDeductionSuccess'],
		},
		editDeductionSuccess: {
			id: 'payroll.editDeductionSuccess',
			defaultMessage: defaults['payroll.editDeductionSuccess'],
		},
		addDeductionError: {
			id: 'payroll.addDeductionError',
			defaultMessage: defaults['payroll.addDeductionError'],
		},
		editDeductionError: {
			id: 'payroll.editDeductionError',
			defaultMessage: defaults['payroll.editDeductionError'],
		},
		deductionNameExistsError: {
			id: 'payroll.deductionNameExistsError',
			defaultMessage: defaults['payroll.deductionNameExistsError'],
		},
		addBonus: {
			id: 'payroll.addBonus',
			defaultMessage: defaults['payroll.addBonus'],
		},
		bonusName: {
			id: 'payroll.bonusName',
			defaultMessage: defaults['payroll.bonusName'],
		},
		bonusNameRequiredError: {
			id: 'payroll.bonusNameRequiredError',
			defaultMessage: defaults['payroll.bonusNameRequiredError'],
		},
		bonusNameLengthError: {
			id: 'payroll.bonusNameLengthError',
			defaultMessage: defaults['payroll.bonusNameLengthError'],
		},
	});
};

export const getCommissionMessages = () => {
	return defineMessages({
		commission: {
			id: 'commission.commission',
			defaultMessage: defaults['commission.commission'],
		},
		commissions: {
			id: 'commission.commissions',
			defaultMessage: defaults['commission.commissions'],
		},
		getCommissionsError: {
			id: 'commission.getCommissionsError',
			defaultMessage: defaults['commission.getCommissionsError'],
		},
		generateCommission: {
			id: 'commission.generateCommission',
			defaultMessage: defaults['commission.generateCommission'],
		},
		loadCommissionError: {
			id: 'commission.loadCommissionError',
			defaultMessage: defaults['commission.loadCommissionError'],
		},
		loadSalesmenError: {
			id: 'commission.loadSalesmenError',
			defaultMessage: defaults['commission.loadSalesmenError'],
		},
		salesperson: {
			id: 'commission.salesperson',
			defaultMessage: defaults['commission.salesperson'],
		},
		salespersonRequiredError: {
			id: 'commission.salespersonRequiredError',
			defaultMessage: defaults['commission.salespersonRequiredError'],
		},
		getSalespersonInvoicesError: {
			id: 'commission.getSalespersonInvoicesError',
			defaultMessage: defaults['commission.getSalespersonInvoicesError'],
		},
		salespersonHasNoInvoices: {
			id: 'commission.salespersonHasNoInvoices',
			defaultMessage: defaults['commission.salespersonHasNoInvoices'],
		},
		loadCommissionPercentError: {
			id: 'commission.loadCommissionPercentError',
			defaultMessage: defaults['commission.loadCommissionPercentError'],
		},
		typeOfCommission: {
			id: 'commission.typeOfCommission',
			defaultMessage: defaults['commission.typeOfCommission'],
		},
		noCommission: {
			id: 'commission.noCommission',
			defaultMessage: defaults['commission.noCommission'],
		},
		bonus: {
			id: 'commission.bonus',
			defaultMessage: defaults['commission.bonus'],
		},
		bonusDescription: {
			id: 'commission.bonusDescription',
			defaultMessage: defaults['commission.bonusDescription'],
		},
		bonusDescriptionWhitespaceError: {
			id: 'commission.bonusDescriptionWhitespaceError',
			defaultMessage: defaults['commission.bonusDescriptionWhitespaceError'],
		},
		bonusDescriptionLengthError: {
			id: 'commission.bonusDescriptionLengthError',
			defaultMessage: defaults['commission.bonusDescriptionLengthError'],
		},
		saveCommissionSuccess: {
			id: 'commission.saveCommissionSuccess',
			defaultMessage: defaults['commission.saveCommissionSuccess'],
		},
		saveCommissionError: {
			id: 'commission.saveCommissionError',
			defaultMessage: defaults['commission.saveCommissionError'],
		},
		commissionView: {
			id: 'commission.commissionView',
			defaultMessage: defaults['commission.commissionView'],
		},
		creator: {
			id: 'commission.creator',
			defaultMessage: defaults['commission.creator'],
		},
		generalInfo: {
			id: 'commission.generalInfo',
			defaultMessage: defaults['commission.generalInfo'],
		},
		invoices: {
			id: 'commission.invoices',
			defaultMessage: defaults['commission.invoices'],
		},
		noCommissionInvoices: {
			id: 'commission.noCommissionInvoices',
			defaultMessage: defaults['commission.noCommissionInvoices'],
		},
		pendingInvoices: {
			id: 'commission.pendingInvoices',
			defaultMessage: defaults['commission.pendingInvoices'],
		},
		invoicesForThisCommission: {
			id: 'commission.invoicesForThisCommission',
			defaultMessage: defaults['commission.invoicesForThisCommission'],
		},
		pending: {
			id: 'commission.pending',
			defaultMessage: defaults['commission.pending'],
		},
		pendingTooltip: {
			id: 'commission.pendingTooltip',
			defaultMessage: defaults['commission.pendingTooltip'],
		},
		commissionNumber: {
			id: 'commission.commissionNumber',
			defaultMessage: defaults['commission.commissionNumber'],
		},
		configurations: {
			id: 'commission.configurations',
			defaultMessage: defaults['commission.configurations'],
		},
		commissionConfigurations: {
			id: 'commission.commissionConfigurations',
			defaultMessage: defaults['commission.commissionConfigurations'],
		},
		loadCommissionProductInfoError: {
			id: 'commission.loadCommissionProductInfoError',
			defaultMessage: defaults['commission.loadCommissionProductInfoError'],
		},
		commissionableProducts: {
			id: 'commission.commissionableProducts',
			defaultMessage: defaults['commission.commissionableProducts'],
		},
		commissionCustomPriceProducts: {
			id: 'commission.commissionCustomPriceProducts',
			defaultMessage: defaults['commission.commissionCustomPriceProducts'],
		},
		notFound: {
			id: 'commission.notFound',
			defaultMessage: defaults['commission.notFound'],
		},
		commissionPercent: {
			id: 'commission.commissionPercent',
			defaultMessage: defaults['commission.commissionPercent'],
		},
		saveConfigurationsSuccess: {
			id: 'commission.saveConfigurationsSuccess',
			defaultMessage: defaults['commission.saveConfigurationsSuccess'],
		},
		saveConfigurationsError: {
			id: 'commission.saveConfigurationsError',
			defaultMessage: defaults['commission.saveConfigurationsError'],
		},
		loadCommissionableProductIdsError: {
			id: 'commission.loadCommissionableProductIdsError',
			defaultMessage: defaults['commission.loadCommissionableProductIdsError'],
		},
		approved: {
			id: 'commission.approved',
			defaultMessage: defaults['commission.approved'],
		},
		rejected: {
			id: 'commission.rejected',
			defaultMessage: defaults['commission.rejected'],
		},
		paid: {
			id: 'commission.paid',
			defaultMessage: defaults['commission.paid'],
		},
		noStatus: {
			id: 'commission.noStatus',
			defaultMessage: defaults['commission.noStatus'],
		},
		payCommissionSuccess: {
			id: 'commission.payCommissionSuccess',
			defaultMessage: defaults['commission.payCommissionSuccess'],
		},
		payCommissionError: {
			id: 'commission.payCommissionError',
			defaultMessage: defaults['commission.payCommissionError'],
		},
		approve: {
			id: 'commission.approve',
			defaultMessage: defaults['commission.approve'],
		},
		reject: {
			id: 'commission.reject',
			defaultMessage: defaults['commission.reject'],
		},
		rejectCommissionError: {
			id: 'commission.rejectCommissionError',
			defaultMessage: defaults['commission.rejectCommissionError'],
		},
		approveCommissionError: {
			id: 'commission.approveCommissionError',
			defaultMessage: defaults['commission.approveCommissionError'],
		},
		pay: {
			id: 'commission.pay',
			defaultMessage: defaults['commission.pay'],
		},
		rejectCommissionSuccess: {
			id: 'commission.rejectCommissionSuccess',
			defaultMessage: defaults['commission.rejectCommissionSuccess'],
		},
		approveCommissionSuccess: {
			id: 'commission.approveCommissionSuccess',
			defaultMessage: defaults['commission.approveCommissionSuccess'],
		},
		payCommission: {
			id: 'commission.payCommission',
			defaultMessage: defaults['commission.payCommission'],
		},
		paidDate: {
			id: 'commission.paidDate',
			defaultMessage: defaults['commission.paidDate'],
		},
		paidDateIsRequired: {
			id: 'commission.paidDateIsRequired',
			defaultMessage: defaults['commission.paidDateIsRequired'],
		},
		bankReferenceComment: {
			id: 'commission.bankReferenceComment',
			defaultMessage: defaults['commission.bankReferenceComment'],
		},
		paidCommentLengthError: {
			id: 'commission.paidCommentLengthError',
			defaultMessage: defaults['commission.paidCommentLengthError'],
		},
		editComment: {
			id: 'commission.editComment',
			defaultMessage: defaults['commission.editComment'],
		},
		editCommissionReason: {
			id: 'commission.editCommissionReason',
			defaultMessage: defaults['commission.editCommissionReason'],
		},
		editCommentRequiredError: {
			id: 'commission.editCommentRequiredError',
			defaultMessage: defaults['commission.editCommentRequiredError'],
		},
		editCommentLengthError: {
			id: 'commission.editCommentLengthError',
			defaultMessage: defaults['commission.editCommentLengthError'],
		},
		saveZeroTotalCommissionError: {
			id: 'commission.saveZeroTotalCommissionError',
			defaultMessage: defaults['commission.saveZeroTotalCommissionError'],
		},
		subtotal: {
			id: 'commission.subtotal',
			defaultMessage: defaults['commission.subtotal'],
		},
		loadAccountsError: {
			id: 'commission.loadAccountsError',
			defaultMessage: defaults['commission.loadAccountsError'],
		},
		sourceAccount: {
			id: 'commission.sourceAccount',
			defaultMessage: defaults['commission.sourceAccount'],
		},
		sourceAccountRequiredError: {
			id: 'commission.sourceAccountRequiredError',
			defaultMessage: defaults['commission.sourceAccountRequiredError'],
		},
	});
};
export const getModelsMessages = () => {
	return defineMessages({
		model: {
			id: 'models.model',
			defaultMessage: defaults['models.model'],
		},
		models: {
			id: 'models.models',
			defaultMessage: defaults['models.models'],
		},
		addModelSuccess: {
			id: 'models.addModelSuccess',
			defaultMessage: defaults['models.addModelSuccess'],
		},
		editModelSuccess: {
			id: 'models.editModelSuccess',
			defaultMessage: defaults['models.editModelSuccess'],
		},
		getModelsError: {
			id: 'models.getModelsError',
			defaultMessage: defaults['models.getModelsError'],
		},
		editModelError: {
			id: 'models.editModelError',
			defaultMessage: defaults['models.editModelError'],
		},
		deleteModelSuccess: {
			id: 'models.deleteModelSuccess',
			defaultMessage: defaults['models.deleteModelSuccess'],
		},
		addModelError: {
			id: 'models.addModelError',
			defaultMessage: defaults['models.addModelError'],
		},
	});
};

export const getUnitsMessages = () => {
	return defineMessages({
		units: {
			id: 'units.units',
			defaultMessage: defaults['units.units'],
		},
		addUnitSuccess: {
			id: 'units.addUnitSuccess',
			defaultMessage: defaults['units.addUnitSuccess'],
		},
		editUnitSuccess: {
			id: 'units.editUnitSuccess',
			defaultMessage: defaults['units.editUnitSuccess'],
		},
		getUnitsError: {
			id: 'units.getUnitsError',
			defaultMessage: defaults['units.getUnitsError'],
		},
		editUnitError: {
			id: 'units.editUnitError',
			defaultMessage: defaults['units.editUnitError'],
		},
		deleteUnitSuccess: {
			id: 'units.deleteUnitSuccess',
			defaultMessage: defaults['units.deleteUnitSuccess'],
		},
		addUnitError: {
			id: 'units.addUnitError',
			defaultMessage: defaults['units.addUnitError'],
		},
		upload: {
			id: 'units.upload',
			defaultMessage: defaults['units.upload'],
		},
		hasHeaders: {
			id: 'units.hasHeaders',
			defaultMessage: defaults['units.hasHeaders'],
		},
		replaceDuplicates: {
			id: 'units.replaceDuplicates',
			defaultMessage: defaults['units.replaceDuplicates'],
		},
		template: {
			id: 'units.template',
			defaultMessage: defaults['units.template'],
		},
		file: {
			id: 'units.file',
			defaultMessage: defaults['units.file'],
		},
		tableTotal: {
			id: 'units.tableTotal',
			defaultMessage: defaults['units.tableTotal'],
		},	
		location: {
			id: 'units.location',
			defaultMessage: defaults['units.location'],
		},
		warehouse: {
			id: 'units.warehouse',
			defaultMessage: defaults['units.warehouse'],
		},
		lot: {
			id: 'units.lot',
			defaultMessage: defaults['units.lot'],
		},
		model: {
			id: 'units.model',
			defaultMessage: defaults['units.model'],
		},
		available: {
			id: 'units.available',
			defaultMessage: defaults['units.available'],
		},
		assigned: {
			id: 'units.assigned',
			defaultMessage: defaults['units.assigned'],
		},
	});
};

export const getLotsMessages = () => {
	return defineMessages({
		lot: {
			id: 'lots.lot',
			defaultMessage: defaults['lots.lot'],
		},
		lots: {
			id: 'lots.lots',
			defaultMessage: defaults['lots.lots'],
		},
		unitsLot : {
			id: 'lots.unitsLot',
			defaultMessage: defaults['lots.unitsLot'],
		},
		lotId: {
			id: 'lots.lotId',
			defaultMessage: defaults['lots.lotId'],
		},
		getLotsError: {
			id: 'lots.getLotsError',
			defaultMessage: defaults['lots.getLotsError'],
		},
		addLotSuccess: {
			id: 'lots.addLotSuccess',
			defaultMessage: defaults['lots.addLotSuccess'],
		},
		addUnitLotSuccess: {
			id: 'lots.addUnitLotSuccess',
			defaultMessage: defaults['lots.addUnitLotSuccess'],
		},
		addUnitLotError: {
			id: 'lots.addUnitLotError',
			defaultMessage: defaults['lots.addUnitLotError'],
		},
		editLotSuccess: {
			id: 'lots.editLotSuccess',
			defaultMessage: defaults['lots.editLotSuccess'],
		},
		deleteLotSuccess: {
			id: 'lots.deleteLotSuccess',
			defaultMessage: defaults['lots.deleteLotSuccess'],
		},
		onCharge: {
			id: 'lots.onCharge',
			defaultMessage: defaults['lots.onCharge'],
		},
		addLotError: {
			id: 'lots.addLotError',
			defaultMessage: defaults['lots.addLotError'],
		},
		editLotError: {
			id: 'lots.editLotError',
			defaultMessage: defaults['lots.editLotError'],
		},
		unit: {
			id: 'lots.unit',
			defaultMessage: defaults['lots.unit'],
		},
		pin: {
			id: 'lots.pin',
			defaultMessage: defaults['lots.pin'],
		},
		imei: {
			id: 'lots.imei',
			defaultMessage: defaults['lots.imei'],
		},
		model: {
			id: 'lots.model',
			defaultMessage: defaults['lots.model'],
		},
		technical: {
			id: 'lots.technical',
			defaultMessage: defaults['lots.technical'],
		},
		client: {
			id: 'lots.client',
			defaultMessage: defaults['lots.client'],
		},
		installedAt: {
			id: 'lots.installedAt',
			defaultMessage: defaults['lots.installedAt'],
		},
		assignTechnical: {
			id: 'lots.assignTechnical',
			defaultMessage: defaults['lots.assignTechnical'],
		},
		technicals: {
			id: 'lots.technicals',
			defaultMessage: defaults['lots.technicals'],
		},
		assignTechnicalSuccess: {
			id: 'lots.assignTechnicalSuccess',
			defaultMessage: defaults['lots.assignTechnicalSuccess'],
		},
		assignTechnicalError: {
			id: 'lots.assignTechnicalError',
			defaultMessage: defaults['lots.assignTechnicalError'],
		},
		addUnitToLot: {
			id: 'lots.addUnitToLot',
			defaultMessage: defaults['lots.addUnitToLot'],
		},
		addLot: {
			id: 'lots.addLot',
			defaultMessage: defaults['lots.addLot'],
		},
		several: {
			id: 'lots.several',
			defaultMessage: defaults['lots.several'],
		},
		upload: {
			id: 'lots.upload',
			defaultMessage: defaults['lots.upload'],
		},
	});
};