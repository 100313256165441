export const ICON_IDS={
	PERSON_ICON_ID:24,
	GPS_ID:29,
	MARKER_ID:30,
	FARMTRUCK: 32,
	FLATBEDTRUCK: 33,
	STATIONARYTANK: 34,
	INDUSTRIALMACHINE: 35,
}
export const ICON_IDS_WITH_NO_AZIMUTH=[
	ICON_IDS.PERSON_ICON_ID,
	ICON_IDS.GPS_ID,
	ICON_IDS.MARKER_ID,
	ICON_IDS.FARMTRUCK,
	ICON_IDS.FLATBEDTRUCK,
	ICON_IDS.STATIONARYTANK,
	ICON_IDS.INDUSTRIALMACHINE,
]