import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Select, Input, Popover, message, Modal } from 'antd';
import { getClientMessages, getactionMessages, getModalMessages } from '../../../constants/messages';
import { ChangeClientDisable } from '../actions';
import { DisableMessages } from '../constants';

const FormItem = Form.Item;
const Option = Select.Option;

let clientMessages = getClientMessages(),
    actionMessages = getactionMessages(),
    modalMessages = getModalMessages();

const messages = {
    ...clientMessages,
    ...actionMessages,
    ...modalMessages
};

class DisableButton extends React.Component {
    
    constructor(props) {
		super(props);
		this.state = {
            customMessage: false,
            showModal: false
        };
    }

	getIntl = (str) => {
		return this.props.intl.formatMessage({...messages[str]});
    }

	changeDisable = (disableMessage) => {
        const { getIntl, props } = this;
        const { changeClientDisable, setState, clientId, isDisabled } = props;
        const successMessage = isDisabled ? getIntl("clientEnableMessage") : getIntl("clientDisableMessage");
        const activityLogMessage = `${successMessage}${disableMessage ? ` ${disableMessage}` : ""}`;
		setState({isLoading: true});
		changeClientDisable(clientId, !isDisabled, activityLogMessage)
        .then((response) => {
            const overdueInvoiceCount = response
            setState({ isLoading: false });
            this.setState({ showModal: false });
            message.success(successMessage);
            if(overdueInvoiceCount > 0  && isDisabled === true) {message.warning({content: getIntl("clientHasOverdueInvoice"), duration: 8 });}
        })
        .catch(() => {
            setState({ isLoading: false });
            message.error(isDisabled ? getIntl("EnableClientError") : getIntl("DisableClientError"));
        });
	}

    renderFormSelect = () => {
        const { props, getIntl } = this;
        const { form } = props;
        const { getFieldDecorator } = form;
        const label = getIntl("disableMessage");
        const options = DisableMessages.map((opt, index) => <Option key={index} value={opt}>{opt}</Option>);
        return (
            <FormItem label={label} >
            {
                getFieldDecorator(`disableMessage`, 
                    {
                        rules: [{
                            required: true,
                            message: getIntl("disableMessageError")
                        }]
                    }
                )(
                    <Select
                        className="job-full-component"
                        showSearch
                        placeholder={label}
                        optionFilterProp="children"
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        dropdownMatchSelectWidth={false}
                    >
                        {options}
                    </Select>
                )
            }
            </FormItem>
        );
    }

    renderFormInput = () => {
        const { props, getIntl, changeDisable } = this;
        const { form } = props;
        const { getFieldDecorator } = form;
        const label = getIntl("disableMessage");
        return (
            <FormItem label={label} >
                {
                    getFieldDecorator('disableMessage', 
                        {
                            rules: [{
                                required: true,
                                message: getIntl("disableMessageError"),
                                max: 100
                            }],
                        }
                    )(<Input className="job-full-component" placeholder={label} onPressEnter={(event) => changeDisable(event.target.value)}/>)
                }
            </FormItem>
        );
    }

    renderField = () => {
        const { state, renderFormSelect, renderFormInput } = this;
        const { customMessage } = state;
        return customMessage ? renderFormInput() : renderFormSelect();
    }

    opposeField = () => {
        const { customMessage } = this.state;
        let opposite = !customMessage;
        this.setState({customMessage: opposite});
        this.props.form.resetFields(["disableMessage"]);
    }

	renderChangeField = () => {
        const { state, getIntl, opposeField } = this;
        const { customMessage } = state;
        let message = customMessage ? "predeterminedMessage" : "customMessage";
		return (
            <a onClick={() => opposeField()}>{getIntl(message)}</a>
        );
    }

    renderConfirmButton = () => {
        const { props, getIntl, changeDisable } = this;
        const { state, form } = props;
        const { isLoading } = state;
        const { getFieldValue } = form;
        let activityLogMessage = getFieldValue("disableMessage");
        activityLogMessage = activityLogMessage === undefined ? "" : activityLogMessage;
        return (
            <FormItem>
                <Button
                    type="primary"
                    disabled={activityLogMessage.length < 1}
                    onClick={() => changeDisable(activityLogMessage)}
                    loading={isLoading}
                >
                    {getIntl("confirmDisable")}
                </Button>
            </FormItem>
        );
    }

    renderForm = () => {
        const { renderField, renderChangeField, renderConfirmButton } = this;
        return (
            <div>
                {renderField()}
                {renderChangeField()}
                {renderConfirmButton()}
            </div>
        )
    }

    renderModal = () => {
        const { state, getIntl, renderForm } = this;
        const { showModal } = state;
        const form = renderForm();
        return (
			<Modal
                open={showModal}
				title={getIntl("disable")}
				onCancel={() => this.setState({ showModal: false })}
				destroyOnClose={true}
				footer={null}
            >
				{form}
            </Modal>
        );
    }

    renderMainButton = () => {
        const { props, getIntl, changeDisable, renderForm } = this;
        const { isDisabled, isMobile } = props;
        const onClick = isDisabled ? () => changeDisable() : isMobile ? () => this.setState({ showModal: true }) : undefined;
        const text = isDisabled ? getIntl("enable") : getIntl("disable");
        const button = <a className={isMobile ? "client-disable-button" : ""} onClick={onClick}>{text}</a>;
        const form = renderForm();
        const pop = <Popover key="disable" content={form} trigger="click">{button}</Popover>
        return (isDisabled || isMobile ? button : pop);
    }

    checkMobile = () => {
        const { props, renderMainButton, renderModal } = this;
        const { isMobile } = props;
        if (isMobile) 
            return (
                <div>
                    {renderModal()}
                    {renderMainButton()}
                </div>
            );
        return renderMainButton(); 
    }

    render() {
        return this.checkMobile(); 
    }

}

DisableButton.defaultProps = {
	isMobile: false
};

DisableButton.propTypes = {
	intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    clientId: PropTypes.number.isRequired,
	isDisabled: PropTypes.bool.isRequired,
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    changeClientDisable: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired
};

const mapDispatchToProps = (dispatch) => {
	return {
		changeClientDisable: (clientId, disabled, message) => dispatch(ChangeClientDisable(clientId, disabled, message))
	};
};

export default connect(null, mapDispatchToProps)(injectIntl(Form.create()(DisableButton)));