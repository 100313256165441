import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Popover, Table } from 'antd';
import { roundN } from '../../utilities/util';
import { getInvoicingMessages } from '../../constants/messages';

const invoicingMessages = getInvoicingMessages();

const messages = {
	...invoicingMessages
};

class PopoverProductsTable extends React.Component  {

    getIntl = (str, values = {}) => this.props.intl.formatMessage({...messages[str]}, values);
    
	render(){
        const { props, getIntl } = this;
        const { products, children } = props;
        const productColumns = [
            {
                title: getIntl("Description"),
                dataIndex: 'description',
                key: 'description'
            },
            {
                title: getIntl("Quantity"),
                dataIndex: 'quantity',
                key: 'quantity'
            },
            {
                title: getIntl("Price"),
                dataIndex: 'price',
                key: 'price',
                className: 'column-money',
                render: (price) => `$ ${roundN(price, 2)}`
            },
            {
                title: getIntl("Total"),
                key: 'total',
                className: 'column-money',
                render: (product) => `$ ${roundN(product.quantity * product.price, 2)}`
            }
        ];
        const dataSource = products.map((product, index) => ({...product, key: index}));
        const popoverContent = <Table columns={productColumns} dataSource={dataSource} pagination={false} size="small" />;
        return (
            <Popover content={popoverContent} mouseEnterDelay={0.6}>
                {children}
            </Popover>
            
        );
	}
}

PopoverProductsTable.defaultProps = {
    products: []
};

PopoverProductsTable.propTypes = {
    intl: PropTypes.object.isRequired,
    children: PropTypes.any.isRequired,
    products: PropTypes.array
};

export default injectIntl(PopoverProductsTable);