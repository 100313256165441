import React from 'react';
import {
	AppstoreOutlined,
	AppstoreAddOutlined,
    BankOutlined,
	BranchesOutlined,
    BookFilled,
	CarOutlined,
    CalendarOutlined,
    CheckCircleOutlined,
    CheckSquareFilled,
    DiffFilled,
    DollarCircleFilled,
	DropboxOutlined,
    DollarOutlined,
    FileTextFilled,
    FundFilled,
    HomeFilled,
    IdcardOutlined,
    PieChartOutlined,
    ProfileOutlined,
    ReadOutlined,
	ReconciliationOutlined,
    ReconciliationFilled,
    ScheduleOutlined,
    SolutionOutlined,
    TableOutlined,
    TagsFilled,
    TeamOutlined,
    UserAddOutlined,
	UsbOutlined,
    FileExclamationFilled,
} from '@ant-design/icons';
import { ROLES } from '../../constants/global';
import { getSideMenu } from '../../constants/messages';


let sideMenuMessages = getSideMenu();

const messages = {
	...sideMenuMessages
};
const menuItems = [
    {
        key: "home",
        title: ({...messages.home}),
        icon: <HomeFilled />,
        required:[...ROLES]
    },
    {
        key: "accounts",
        title: ({...messages.account}),
        icon: <span className="icon-user" />,
        requiredRoles: [ROLES.MASTER],
    },
    {
        key: "clients",
        title: ({...messages.clients}),
        icon: <SolutionOutlined />,
        requiredRoles: [
            ROLES.MASTER,
            ROLES.ADMINISTRATOR,
            ROLES.SALES,
            ROLES.CUSTOMERSERVICE,
        ],
        subMenu: [
            {
                key: "preRegistration",
                title: ({...messages.preRegistration}),
                icon: <UserAddOutlined />,
                requiredRoles: [ROLES.MASTER, ROLES.ADMINISTRATOR, ROLES.SALES],
            },
            {
                key: "approved",
                title: ({...messages.approved}),
                icon: <CheckCircleOutlined />,
                requiredRoles: [
                    ROLES.MASTER,
                    ROLES.ADMINISTRATOR,
                    ROLES.SALES,
                    ROLES.CUSTOMERSERVICE,
                ],
            },
        ],
    },
    {
        key: "job",
        title: ({...messages.job}),
        icon: <FundFilled />,
        requiredRoles: [
            ROLES.MASTER,
            ROLES.CUSTOMERSERVICE,
            ROLES.SALES,
            ROLES.TECHSUPPORT,
            ROLES.ADMINISTRATOR,
        ],
        subMenu: [
            {
                key: "job-calendar",
                title: ({...messages.calendar}),
                icon: <CalendarOutlined />,
                requiredRoles: [ROLES.MASTER, ROLES.CUSTOMERSERVICE],
            },
            {
                key: "job-table",
                title: ({...messages.table}),
                icon: <TableOutlined />,
                requiredRoles: [
                    ROLES.MASTER,
                    ROLES.CUSTOMERSERVICE,
                    ROLES.TECHSUPPORT,
                    ROLES.ADMINISTRATOR,
                ],
            },
            {
                key: "requests",
                title: ({...messages.requests}),
                icon: <CheckCircleOutlined />,
                requiredRoles: [ROLES.MASTER, ROLES.CUSTOMERSERVICE, ROLES.SALES],
            },
        ],
    },
    {
        key: 'invoicing',
        title: ({...messages.invoicing}),
        icon: <FileTextFilled />,
        requiredRoles: [
            ROLES.MASTER,
            ROLES.ADMINISTRATOR,
            ROLES.SALES,
        ],
        subMenu: [
            {
                key: 'invoices',
                title: ({...messages.invoices}),
                icon: <FileTextFilled />,
                requiredRoles:[ROLES.MASTER, ROLES.ADMINISTRATOR, ROLES.SALES]
            },
            {
                key: 'proformas',
                title: ({...messages.proformas}),
                icon: <FileTextFilled />,
                requiredRoles:[ROLES.MASTER, ROLES.ADMINISTRATOR]
            },
            {
                key: 'quotations',
                title: ({...messages.quotations}),
                icon: <FileTextFilled />,
                requiredRoles: [
                    ROLES.MASTER,
                    ROLES.ADMINISTRATOR,
                    ROLES.SALES,
                ],
            },
            {
                key: 'creditNotes',
                title:({...messages.creditNotes}),
                icon: <FileTextFilled />,
                requiredRoles: [ROLES.MASTER, ROLES.ADMINISTRATOR]
            },
            {
                key: 'debitNotes',
                title:({...messages.debitNotes}),
                icon: <FileTextFilled />,
                requiredRoles: [ROLES.MASTER, ROLES.ADMINISTRATOR]
            },
            {
                key: 'receipts',
                title:({...messages.receipt}),
                icon: <ReconciliationOutlined />,
                requiredRoles: [ROLES.MASTER, ROLES.ADMINISTRATOR]
            },
            {
                key: 'fiscalData',
                title: ({...messages.fiscalData}),
                icon: <ReadOutlined />,
                requiredRoles: [ROLES.MASTER, ROLES.ADMINISTRATOR]
            },
            {
                key: 'taxRetention',
                title: ({...messages.taxRetention}),
                icon: <ScheduleOutlined />,
                requiredRoles: [ROLES.MASTER, ROLES.ADMINISTRATOR]
            },
        ],
    },
    {
        key: 'products',
        title: ({...messages.products}),
        icon: <TagsFilled />,
        requiredRoles:[ROLES.MASTER, ROLES.ADMINISTRATOR]
    },
    {
        key: 'exchangeRate',
        title: ({...messages.exchangeRate}),
        icon: <DollarOutlined />,
        requiredRoles:[ROLES.MASTER, ROLES.ADMINISTRATOR,]
    },
    {
        key: 'reports',
        title: ({...messages.reports}),
        icon: <ReconciliationFilled />,
        requiredRoles:[ROLES.MASTER, ROLES.ADMINISTRATOR],
        subMenu: [
            {
                key: 'generalReport',
                title: ({...messages.generalReport}),
                icon: <IdcardOutlined />,
                requiredRoles: [ROLES.MASTER, ROLES.ADMINISTRATOR],
            },
            {
                key: 'taxesReport',
                title:({...messages.taxReport }),         
                icon: <SolutionOutlined />,
                requiredRoles : [ROLES.MASTER, ROLES.ADMINISTRATOR],
            },
            {
                key: 'incomeExpensesReport',
                title: ({...messages.incomeExpensesReport}),
                icon: <BookFilled />,
                requiredRoles: [ROLES.MASTER],
            },
            {
                key: 'equipmentReport',
                title:({...messages.equipmentMovementsReport}),
                icon: <DiffFilled />,
                requiredRoles:[ROLES.MASTER],
            },
            {
                key: 'techReport',
                title: ({...messages.technicians}),
                icon: <FundFilled />,
                requiredRoles:[ROLES.MASTER],
            },
            {
                key: 'paymentsReport',
                title: ({...messages.paymentsReport}),
                icon: <DollarCircleFilled />,
                requiredRoles: [ROLES.MASTER, ROLES.ADMINISTRATOR],
            },
            {
                key: 'overdueInvoicesReport',
                title: ({...messages.overdueInvoices}),
                icon: <FileExclamationFilled />,
                requiredRoles: [ROLES.MASTER, ROLES.ADMINISTRATOR],
            },
            {
                key: 'jobsToCharge',
                title:({...messages.jobsToCharge}),
                icon: <CheckSquareFilled />,
                requiredRoles: [ROLES.MASTER, ROLES.ADMINISTRATOR],
            },
            {
                key: 'customPriceReport',
                title: ({...messages.customPriceReport}),
                icon: <DollarCircleFilled />,
                requiredRoles: [ROLES.MASTER, ROLES.ADMINISTRATOR],
            },
        ]
    },
    {
        key: 'IncomeAndExpenses',
        title: ({...messages.incomeExpenses}),
        icon: <BookFilled />,
        requiredRoles: [ROLES.MASTER, ROLES.INCOMESEXPENSES],
        subMenu: [
            {
                key: 'IncomeReport',
                title: ({...messages.incomeExpenses}),
                icon: <PieChartOutlined />,
                requiredRoles: [ROLES.MASTER, ROLES.INCOMESEXPENSES],
            },
            {
                key: 'Categories',
                title: ({...messages.categories}),
                icon: <ProfileOutlined />,
                requiredRoles: [ROLES.MASTER, ROLES.INCOMESEXPENSES],
            },
            {
                key: 'Banks',
                title: ({...messages.accounts}),
                icon: <BankOutlined />,
                requiredRoles: [ROLES.MASTER, ROLES.INCOMESEXPENSES],
            },
        ],
    },
    {
        key: 'departments',
        title:({...messages.departments}),
        icon: <AppstoreOutlined />,
        requiredRoles: [ROLES.MASTER, ROLES.EMPLOYEES]
    },
    {
        key: 'employees',
        title: ({...messages.employees}),
        icon: <TeamOutlined />,
        requiredRoles: [ROLES.MASTER, ROLES.EMPLOYEES]
    },
    {
        key: 'payrolls',
        title: ({...messages.payrolls}),
        icon: <DollarOutlined />,
        requiredRoles: [ROLES.MASTER, ROLES.PAYROLLS]
    },
    {
        key: 'commissions',
        title: ({...messages.commissions}),
        icon: <PieChartOutlined />,
        requiredRoles: [ROLES.MASTER, ROLES.SALES]
    },
    {
        key: 'inventory',
        title: ({...messages.inventory}),
        icon: <DropboxOutlined />,
        requiredRoles: [ROLES.MASTER, ROLES.INVENTORYASSISTANT],
        subMenu: [
            {
                key: 'models',
                title: ({...messages.models}),
                icon: <UsbOutlined />,
                requiredRoles: [ROLES.MASTER, ROLES.INVENTORYASSISTANT],
            },
            {
                key: 'units',
                title: ({...messages.units}),
                icon: <CarOutlined />,
                requiredRoles: [ROLES.MASTER, ROLES.INVENTORYASSISTANT],
            },
            {
                key: 'lots',
                title: ({...messages.lots}),
                icon: <AppstoreAddOutlined />,
                requiredRoles: [ROLES.MASTER, ROLES.INVENTORYASSISTANT],
            },
        ],
    },
    {
        key: 'OptimusGuatemala',
        title: ({...messages.clientNameOptimusGuatemala}),
        icon: <BranchesOutlined />,
        requiredRoles: [ROLES.MASTER,ROLES.ADMINISTRATOR],
        subMenu: [
            {
                key: 'guatemalaClients',
                title:({...messages.clients}),
                icon: <SolutionOutlined />,
                requiredRoles: [ROLES.MASTER,ROLES.ADMINISTRATOR],
            },
            {
                key: 'guatemalaInvoices',
                title: ({...messages.invoices}),
                icon: <FileTextFilled />,
                requiredRoles: [ROLES.MASTER,ROLES.ADMINISTRATOR],
            },
        ],
    },


];

export default menuItems;