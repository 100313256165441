import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { injectIntl } from 'react-intl';
import { getClientMessages, getModalMessages } from '../../../../../constants/messages';
import AfterPaymentView from './View';
import ProgrammedDeactivationsModal from '../Modal';

let clientMessages = getClientMessages(),
	modalMessages = getModalMessages();

const messages = {
	...clientMessages,
	...modalMessages
};

const initialState = {
	showProgrammedDeactivationsModal: false
};

class GPDModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { ...initialState };
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setShowProgrammedDeactivationsModal = (showProgrammedDeactivationsModal) => this.setState({ showProgrammedDeactivationsModal });

	handleCancel = () => {
		this.setState({ ...initialState });
		this.props.handleCancel();
	}

	render() {
		const { state, props, setShowProgrammedDeactivationsModal, handleCancel } = this;
		const { showProgrammedDeactivationsModal } = state;
		const { showModal, clientId } = props;
		return (
			<Modal
				open={showModal}
				onCancel={handleCancel}
				destroyOnClose={true}
				maskClosable={false}
				keyboard={false}
				closable={false}
				footer={null}
			>
				<ProgrammedDeactivationsModal showModal={showProgrammedDeactivationsModal} handleCancel={handleCancel} clientId={clientId} />
				<AfterPaymentView closeModal={handleCancel} triggerDateChange={() => setShowProgrammedDeactivationsModal(true)} clientId={clientId}/>
			</Modal>
		);
	}
}

GPDModal.propTypes = {
	intl: PropTypes.object.isRequired,
	showModal: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired,
	clientId: PropTypes.number.isRequired
};

export default injectIntl(GPDModal);