import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, message } from 'antd';
import { getModalMessages, getRequestMessages } from '../../constants/messages';
import { GetAllClients } from '../Clients/actions';
import { SaveOutlined } from '@ant-design/icons';
import AddRequestForm from './AddRequestForm';
import { GetAllContacts } from '../Contacts/actions';
import { AddRequest, GetRequests, notifySlack } from './actions';
import moment from 'moment';

let modalMessages = getModalMessages(),
    requestMessages = getRequestMessages();

const messages = {
    ...modalMessages,
    ...requestMessages,
};

const AddRequestModal = ({ handleCancel, visible }) => {
    const intl = useIntl();
	const form = useRef();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user)
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
		(async () => {
            try {
                await dispatch(GetAllClients());
                await dispatch(GetAllContacts());
            } catch (error) {
                message.error(intl.formatMessage({ ...messages.GetClientsError }));
            }
        })();	
    }, []);

    const onOk = async () => {
		try {
			setIsSaving(true);
            const values = await form.current.validateFields();
			values.vehicles = JSON.stringify(values.vehicles);
            values.createdAt = moment(),
            values.createdBy = user.id
            const request = await dispatch(AddRequest(values));
            await notifySlack(request.id);
            await dispatch(GetRequests());
            message.success(intl.formatMessage({...messages.addRequestSuccess }));
            handleCancel();
		} catch (error) {
            message.error(intl.formatMessage({...messages.addRequestError}));
		} finally {
			setIsSaving(false);
		}
	};

  return (
        <Modal
            open={visible}
            title={intl.formatMessage({...messages.addRequest})}
            destroyOnClose={true}
            confirmLoading={isSaving}
            onOk={onOk}
            okText={
				<span>
					<SaveOutlined />
					<FormattedMessage {...messages.save} />
				</span>
			}
            onCancel={handleCancel}
			cancelText={intl.formatMessage({...messages.cancel})}
        >
            <div>
                <AddRequestForm form={form} />
            </div>
        </Modal>
  );
}

AddRequestModal.propTypes = {
    handleCancel: PropTypes.func.isRequired,
    visible: PropTypes.bool,
};

export default AddRequestModal;
