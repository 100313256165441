import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker } from 'antd';
import { injectIntl } from 'react-intl';
import { getJobMessages, getCalendarMessages } from '../../../../../constants/messages';
const moment = require('moment');

const FormItem = Form.Item;
const RangePicker = DatePicker.RangePicker;

let jobMessages = getJobMessages(),
    calendarMessages = getCalendarMessages();

const messages = {
    ...jobMessages,
    ...calendarMessages
};

const dateFormat = "DD-MM-YYYY";

class ProgrammedDate extends React.Component {

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    buildRanges = () => {
        const { getIntl } = this;
        const ranges = {};
        ranges[getIntl("today")] = [moment().startOf('day'), moment().endOf('day')];
        ranges[getIntl("thisWeek")] = [moment().startOf('week'), moment().endOf('week')];
        ranges[getIntl("thisMonth")] = [moment().startOf('month'), moment().endOf('month')];
        return ranges;
    }

    getValueFromEvent = (dates) => {
        if (!dates) return undefined;
        const initialDate = dates[0];
        const finalDate = dates[1];
        if (!initialDate || !finalDate) return dates;
        const startOfInitialDate = moment(initialDate).startOf('day');
        const endOfFinalDate = moment(finalDate).endOf('day');
        return [startOfInitialDate, endOfFinalDate];
    }
    
	render() {
        const { props, getIntl, buildRanges, getValueFromEvent } = this;
		const { form, label, fieldName } = props;
        const { getFieldDecorator } = form;
        const ranges = buildRanges();
		const labelAndPlaceholder = 'label' in props ? label : getIntl("programmedDate");
        return(
            <FormItem label={labelAndPlaceholder} >
            {
                getFieldDecorator(fieldName,
                {
                    getValueFromEvent: getValueFromEvent
                })(
                    <RangePicker
                        className="job-full-component"
                        placeholder={labelAndPlaceholder}
                        showTime={false}
                        format={dateFormat}
                        ranges={ranges}
                    />
                )
            }
            </FormItem>
        );
    }
}

ProgrammedDate.defaultProps = {
    fieldName: `programmedDateRange`
};

ProgrammedDate.propTypes = {
	intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    label: PropTypes.string,
    fieldName: PropTypes.string
};

export default injectIntl(ProgrammedDate);