import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, message, Spin } from 'antd';
import { getPaymentMessages } from '../../constants/messages';
import { LoadActiveBankAccounts } from './actions';

const FormItem = Form.Item;
const { Option } = Select;

let paymentMessages = getPaymentMessages();

const messages = {
	...paymentMessages
};

class DestinationAccountPicker extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			bankAccounts: []
		};
	}

	componentDidMount() {
		const { getIntl, setLoading } = this;
		setLoading(true);
		LoadActiveBankAccounts()
			.then(bankAccounts => this.setState({ bankAccounts, isLoading: false }))
			.catch(() => {
				setLoading(false);
				message.error(getIntl("loadDestinationAccountsError"));
			});
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });

	render() {
		const { state, props, getIntl } = this;
		const { isLoading, bankAccounts } = state;
		const { getFieldDecorator } = props.form;
		const options = bankAccounts.map(({ id: accountId, name }) => <Option key={accountId} value={accountId}>{name}</Option>);
		return (
			<Spin spinning={isLoading}>
				<FormItem label={getIntl("DestinationAccount")}>
					{
						getFieldDecorator('destinationId',
							{
								rules: [{
									required: true,
									message: getIntl("destinationAccountsRequiredError"),
								}]
							}
						)(
							<Select
								className="job-full-component"
								allowClear={false}
							>
								{options}
							</Select>
						)
					}
				</FormItem>
			</Spin>
		);
	}
}

DestinationAccountPicker.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired
};

export default injectIntl(DestinationAccountPicker);