import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

class EllipsisTooltip extends React.Component {
  
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        }
    }

    handleVisibleChange = (visible) => {
        if (this.container.clientWidth < this.container.scrollWidth) {
            this.setState({
                visible: visible
            })
        }
    }
    
    render() {
        return (
            <Tooltip open={this.state.visible} onOpenChange={this.handleVisibleChange} title={this.props.title}>
                <div 
                    ref={node => this.container = node} 
                    style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                    }}
                >
                    {this.props.children}
                </div>
            </Tooltip>
        )
    }   
}

EllipsisTooltip.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired
};

export default EllipsisTooltip;