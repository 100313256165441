import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Modal, Radio, Input, Form, message } from 'antd';
import { getProformaMessages, getModalMessages, getInvoicingMessages, getErrorMessages } from '../../constants/messages';
import { TAX_TYPES } from './constants';
const { TextArea } = Input;

let proformaMessages = getProformaMessages(),
	modalMessages = getModalMessages(),
	invoiceMessages = getInvoicingMessages(),
	errorMessages = getErrorMessages();

const messages = {
	...proformaMessages,
	...modalMessages,
	...invoiceMessages,
	...errorMessages,
};

const RadioGroup = Radio.Group;

const TaxExemptModal = ({ closeModal, isLoading, showModal, currentObservations, createInvoice }) => {
	const intl = useIntl();
	const [form] = Form.useForm();

	const onSend = async ()  => {
		try {
            const values = await form.validateFields();
			createInvoice(values)
        } catch (error) {
            message.error(intl.formatMessage(({...messages.commonError})))
        }
	}

	const taxOptions = [
		{ label: intl.formatMessage( {...messages.TaxExemption} ), value: TAX_TYPES.EXEMPTION },
		{ label: intl.formatMessage( {...messages.TaxExoneration} ), value: TAX_TYPES.EXONERATION },
		{ label: intl.formatMessage( {...messages.TaxSAG} ), value: TAX_TYPES.SAG },
	];

	return (
		<Modal
			title={intl.formatMessage( {...messages.TaxExemption})}
			open={showModal}
			onCancel={closeModal}
			cancelText={intl.formatMessage( {...messages.cancel} )}
			onOk={onSend}
			okText={intl.formatMessage( {...messages.send} )} 
			maskClosable={!isLoading}
			keyboard={!isLoading}
			closable={!isLoading}
			confirmLoading={isLoading}
			destroyOnClose
		>
			<Form 
				form={form}
				preserve={false}
				layout={'vertical' }
				className="login-form col-8"
			>
				<Form.Item
					name="taxType"
					label={intl.formatMessage( {...messages.taxType} )}
					rules={[
						{
							required: true,
							message: intl.formatMessage( {...messages.taxTypeError} ),
						},
					]}
				>
					<RadioGroup 
						options={taxOptions}
						buttonStyle="solid" 
					/>
				</Form.Item>
				<Form.Item
					name="taxReferenceExemption"
					label={intl.formatMessage( {...messages.taxReferenceExemption} )}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="taxReferenceExoneration"
					label={intl.formatMessage( {...messages.taxReferenceExoneration} )}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="taxReferenceSag"
					label={intl.formatMessage( {...messages.taxReferenceSag} )}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="observations"
					label={intl.formatMessage( {...messages.observations} )}
					initialValue={currentObservations}
				>
					<TextArea
						showCount
						className="job-full-component"
						placeholder={intl.formatMessage( {...messages.observations} )}
						rows={4}
						maxLength={200}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
}

TaxExemptModal.defaultProps = {
	showModal: false,
	isLoading: false
};

TaxExemptModal.propTypes = {
	showModal: PropTypes.bool,
	isLoading: PropTypes.bool,
	currentObservations: PropTypes.string,
	createInvoice: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
};

export default TaxExemptModal
