// Language
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const FETCH_LANGUAGES = 'FETCH_LANGUAGES'; 

//Global
export const CLEAN_REDUCER = 'CLEAN_REDUCER';
export const CLEAR_SESSION = 'CLEAR_SESSION';

// Auth
export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET = 'RESET';
export const AUTHORIZATION_REQUIRED = 'AUTHORIZATION_REQUIRED';
export const LOGIN_FAILED_EMAIL_NOT_VERIFIED = 'LOGIN_FAILED_EMAIL_NOT_VERIFIED';
export const FETCH_USER_ROLES = "FETCH_USER_ROLES";
export const IS_USER_MASTER = "IS_USER_MASTER";
export const USER_PROFILE_IMAGE = "USER_PROFILE_IMAGE";
export const SESSION_REVOKED = "SESSION_REVOKED";

// Account 
export const OPEN_ADD_MODAL_ACCOUNT = 'OPEN_ADD_MODAL_ACCOUNT';
export const CLOSE_ADD_MODAL_ACCOUNT = 'CLOSE_ADD_MODAL_ACCOUNT';
export const OPEN_EDIT_MODAL_ACCOUNT = 'OPEN_EDIT_MODAL_ACCOUNT';
export const CLOSE_EDIT_MODAL_ACCOUNT = 'CLOSE_EDIT_MODAL_ACCOUNT';
export const INIT_MODAL_REQUEST_ACCOUNT = 'INIT_MODAL_REQUEST_ACCOUNT'; 
export const END_MODAL_REQUEST_ACCOUNT = 'END_MODAL_REQUEST_ACCOUNT'; 
export const INIT_REQUEST_ACCOUNT = 'INIT_REQUEST_ACCOUNT'; 
export const END_REQUEST_ACCOUNT = 'END_REQUEST_ACCOUNT';
export const FETCH_ACCOUNTS = 'FETCH_ACCOUNTS'; 
export const FETCH_ACCOUNTS_COUNT = 'FETCH_ACCOUNTS_COUNT'; 
export const ADD_ACCOUNTS_SUCCESS = "ADD_ACCOUNTS_SUCCESS";
export const DELETE_ACCOUNTS_SUCCESS = "DELETE_ACCOUNTS_SUCCESS";
export const DISABLE_ACCOUNTS_SUCCESS = "DISABLE_ACCOUNTS_SUCCESS";
export const EDIT_ACCOUNTS_SUCCESS = "EDIT_ACCOUNTS_SUCCESS";
export const EDIT_LANGUAGE_SUCCESS = "EDIT_LANGUAGE_SUCCESS";
export const LOADED_DATA = "LOADED_DATA";
export const LOAD_DATA = "LOAD_DATA";

// Roles
export const FETCH_ROLES = "FETCH_ROLES";
export const FETCH_ROLES_BY_ID = "FETCH_ROLES_BY_ID";
export const OPEN_ROLE_MODAL_ACCOUNT = "OPEN_ROLE_MODAL_ACCOUNT";
export const CLOSE_ROLE_MODAL_ACCOUNT = "CLOSE_ROLE_MODAL_ACCOUNT";
export const ROLES_UPDATE = "ROLES_UPDATE";

// Tocken Access
export const FETCH_SESSIONS = "FETCH_SESSIONS";
export const OPEN_SESSIONS_MODAL_ACCOUNT = "OPEN_SESSIONS_MODAL_ACCOUNT";
export const CLOSE_SESSIONS_MODAL_ACCOUNT = "CLOSE_SESSIONS_MODAL_ACCOUNT";

// Menu
export const TOGGLE_MENU = "TOGGLE_MENU";

// Profile
export const START_LOADING_PROFILE = "START_LOADING_PROFILE";
export const STOP_LOADING_PROFILE = "STOP_LOADING_PROFILE";
export const SHOW_PROFILE = "SHOW_PROFILE";
export const CLOSE_PROFILE = "CLOSE_PROFILE";

// Configuration
export const START_LOADING_CONFIGURATION = "START_LOADING_CONFIGURATION";
export const STOP_LOADING_CONFIGURATION = "STOP_LOADING_CONFIGURATION";
export const SHOW_CONFIGURATION = "SHOW_CONFIGURATION";
export const CLOSE_CONFIGURATION = "CLOSE_CONFIGURATION";

// Currency
export const SET_DOLLAR_EXCHANGE = 'SET_DOLLAR_EXCHANGE';

// Rabbit 
export const RABBIT_DISCONECTED = "RABBIT_DISCONECTED";
export const RABBIT_CONECTED = "RABBIT_CONECTED";
export const RABBIT_MESSAGE = "RABBIT_MESSAGE";
export const RABBIT_MAIN_MESSAGE = "RABBIT_MAIN_MESSAGE";

// Alerts
export const FEATCH_ALERTS = "FEATCH_ALERTS";
export const NEW_ALERT = "NEW_ALERT";
export const CLEAR_ALERTS = "CLEAR_ALERTS";
export const STOP_ALERT = "STOP_ALERT";

//SEARCH
export const SET_SEARCH_ACCOUNT='SET_SEARCH_ACCOUNT'