import {
    GET_RECEIPTS,
    FETCH_RECEIPT_COUNT,
    CLEAR_SESSION,
    CLEAN_REDUCER,
    UPDATE_SEARCH,
    UPDATE_PAGE
} from './actionTypes';

const initState = {
	receipts: [],
	count: 0,
	search: "",
	page: 1
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case UPDATE_SEARCH:
			return {
				...state,
				search: action.search
			};
		case UPDATE_PAGE:
			return {
				...state,
				page: action.page
			}
		case GET_RECEIPTS:
			return {
				...state,
				receipts: action.receipts
			};
		case FETCH_RECEIPT_COUNT:
			return {
				...state,
				count: action.count
			};
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;
