import PropTypes from 'prop-types';
import { Col, Row, Skeleton, List, Statistic } from 'antd';
import { injectIntl } from 'react-intl';
import React from 'react';

class AccountCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            showComplete: false,
        }
    }

    componentDidMount() {
    }

    displayTitle = () => {
        return (
            <div>
                <Row>
                    <Col span={15}>
                        {this.props.product.product}
                    </Col>
                    <Col span={7} >
                        {<Statistic
                            value={this.props.product.total}
                            precision={2}
                            valueStyle={ {color: '#87d068', fontSize: '16px'} }
                            prefix={<p>L</p>}
                        /> }
                    </Col>
                </Row>
            </div>
        );
    }

    render() {
        return (
            <List.Item>
                <Skeleton avatar title={false} loading={this.state.loading} active>
                    <List.Item.Meta
                        title={this.displayTitle()}
                        description={this.props.product.numInvoices}
                    />
                </Skeleton>
            </List.Item>
        )
    }
}

AccountCard.propTypes = {
    intl: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
};

export default injectIntl(AccountCard);