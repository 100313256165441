import {
	CLEAR_SESSION,
	SHOW_PROFILE,
	CLOSE_PROFILE,
	START_LOADING_PROFILE,
	STOP_LOADING_PROFILE
} from '../constants/actionTypes';

const initState = {
	showProfile: false,
	loading: false
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case START_LOADING_PROFILE: 
			return {
				...state,
				loading: true
			};
		case STOP_LOADING_PROFILE: 
			return {
				...state,
				loading: false
			};
		case SHOW_PROFILE: 
			return {
				...state,
				showProfile: true
			};
		case CLOSE_PROFILE: 
			return {
				...state,
				showProfile: false,
				loading: false
			};
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;