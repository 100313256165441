/*--------------------------

Initial value (single file or array of files) require a property 'url' or 'thumbUrl' in order to render preview image
Returns a single file or an array of files (if selected), depending on max quantity (default 1)

--------------------------*/

import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, message, Modal, Spin, Upload } from 'antd';
import PropTypes from 'prop-types';
import { getactionMessages, getGlobalComponentMessages, getUnitsMessages } from '../../constants/messages';

let UnitsMessages = getUnitsMessages(),
    ActionsMessages = getactionMessages(),
    GlobalMessages = getGlobalComponentMessages();
    
const messages = {
	...UnitsMessages,
    ...ActionsMessages,
    ...GlobalMessages,
}

const FormItemUpload = ({
	fieldName,
	showPreviewIcon,
	onChange,
	maxQuantity,
	addButtonText,
	addButton,
	listType,
	dragger,
	accept,
	className,
	showRemoveIcon,
	multiple,
	label,
	errorMessage,
	required,
	initialValue,
	validator,
}) => {
	const intl = useIntl();
	const form = Form.useFormInstance();
	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const [imageLoading, setImageLoading] = useState(false);
	const [urlList, setUrlList] = useState([]);

	const copyFile = (file) => {
		const currentDate = new Date();
		return new File([file.originFileObj || file], file.name, {
			type: file.type,
			lastModified: file.lastModified || currentDate,
		});
	};

	const getFileListFromForm = () => {
		const value = form.getFieldValue(fieldName);
		if (!value) return [];
		return Array.isArray(value) ? value : [value];
	};

	const getFileListKey = () => {
		const fileList = getFileListFromForm();
		return fileList.length;
	};

	const handlePreview = (file) => {
		if (!showPreviewIcon) return;
		const previewImage = file.url || file.thumbUrl || urlList[file.uid];
		const img = new Image();
		img.onerror = () => message.error(intl.formatMessage({...messages.previewLoadError}));
		img.onload = () => {
			setPreviewImage(previewImage);
			setPreviewVisible(true);
		};
		img.src = previewImage;
	};

	const onRemove = (file) => {
		const fileList = getFileListFromForm();
		const urlListCopy = [...urlList];
		fileList.splice(file.uid, 1);
		urlListCopy.splice(file.uid, 1);
		setUrlList(urlListCopy);
		let setThis = fileList.slice();
		if (maxQuantity <= 1 && fileList.length < 1) setThis = undefined;
		if (maxQuantity <= 1 && fileList.length === 1) setThis = fileList[0];
		if (onChange) onChange(setThis);
		form.setFieldsValue({ [fieldName]: setThis });
		form.validateFields([fieldName], () => {});
	};

	const beforeUpload = (file) => {
		const key = getFileListKey();
		setImageLoading(true);
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = () => {
			const urlListCopy = [...urlList];
			urlListCopy[key] = reader.result;
			setImageLoading(false);
			setUrlList(urlListCopy);
		};
		return false;
	};

	const renderAddButton = () => {
		if (addButton) return addButton;
		if (listType === 'text' && !dragger)
			return  
                    <Button> {addButtonText || <FormattedMessage {...messages.add} />} </Button> 
            
		const icon = dragger ? <InboxOutlined /> : <PlusOutlined />;
		const text = (
			<div>
				{addButtonText ||
					(dragger ? (
						<FormattedMessage {...messages.dragAdd} />
					) : (
						<FormattedMessage {...messages.add} />
					))}
			</div>
		);
		return (
            <Spin spinning={imageLoading}>
                {icon}
                {text}
            </Spin>
		);
	};

	const renderUpload = () => {
		const fileList = getFileListFromForm().map((file, index) => {
			const copy = copyFile(file);
			copy.url = file.url || file.thumbUrl || urlList[index];
			copy.uid = index;
			return copy;
		});
		const uploadButton = renderAddButton();
		const uploadProps = {
			className: className,
			listType: listType,
			accept: accept,
			onRemove: onRemove,
			beforeUpload: beforeUpload,
			onPreview: handlePreview,
			fileList: fileList,
			showUploadList: { showPreviewIcon, showRemoveIcon },
			multiple: multiple && maxQuantity > 1,
			'data-testid': 'field-upload',
		};
		const length = fileList.length;
		return dragger ? (
			<Upload.Dragger
				{...uploadProps}
				className={length >= maxQuantity ? `${className} upload-drag-remove` : className}
			>
				{length >= maxQuantity ? null : uploadButton}
			</Upload.Dragger>
		) : (
			<Upload {...uploadProps}>{length >= maxQuantity ? null : uploadButton}</Upload>
		);
	};

	const getValuesFromEvent = (event) => {
		if (!event) return undefined;
		const { file, fileList } = event;
		const formFileList = getFileListFromForm();
		if (file.status === 'removed') return formFileList;
		if (fileList && Array.isArray(fileList) && fileList.length > 1) {
			if (!file) return formFileList;
			formFileList.push(file);
			return formFileList;
		}
		if (maxQuantity <= 1) {
			if (!file) return undefined;
			return file;
		}
		if (!file) return formFileList;
		formFileList.push(file);
		return formFileList;
	};

	const validateQuantity = (rule, value, callback) => {
		let quantity = Array.isArray(value) ? value.length : 1;
		if (quantity > maxQuantity)
			callback(
				intl.formatMessage({...messages.maxFilesSelectedError}, {
					quantity: maxQuantity,
				})
			);
		callback();
	};

	return (
        <React.Fragment>
            <Form.Item
                label={label || null}
                name={fieldName}
                getValueFromEvent={getValuesFromEvent}
                rules={[
                    {
                        required: required,
                        message: errorMessage,
                    },
                    {
                        validator: validateQuantity,
                    },
                    {
                        validator: validator,
                    },
                ]}
                initialValue={initialValue}
                onChange={(event) => onChange(getValuesFromEvent(event))}
                style={{ marginBottom: 0 }}
            >
                {renderUpload()}
            </Form.Item>
            <Modal open={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </React.Fragment>
	);
};

FormItemUpload.defaultProps = {
	required: false,
	accept: '.png, .jpg, .jpeg, .gif, .bmp',
	listType: 'picture-card',
	maxQuantity: 1,
	showPreviewIcon: true,
	showRemoveIcon: true,
	multiple: false,
	dragger: false,
	validator: (rule, value, callback) => callback(),
	onChange: () => {},
};

FormItemUpload.propTypes = {
	fieldName: PropTypes.string.isRequired,
	label: PropTypes.string,
	errorMessage: PropTypes.string,
	initialValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	onChange: PropTypes.func,
	required: PropTypes.bool,
	accept: PropTypes.string,
	addButtonText: PropTypes.string,
	className: PropTypes.string,
	listType: PropTypes.string,
	addButton: PropTypes.object,
	maxQuantity: PropTypes.number,
	showPreviewIcon: PropTypes.bool,
	showRemoveIcon: PropTypes.bool,
	multiple: PropTypes.bool,
	dragger: PropTypes.bool,
	validator: PropTypes.func,
};

export default FormItemUpload;
