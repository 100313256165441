import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Modal, message } from 'antd';
import { injectIntl } from 'react-intl';
import { getModalMessages, getPayrollMessages } from '../../../../constants/messages';
import { AddDeduction, EditDeduction } from '../../actions'
import DeductionsForm from './DeductionsForm';
import { getNestedValue } from '../../../../utilities/util';

let modalMessages = getModalMessages(),
    payrollMessages = getPayrollMessages();

const messages = {
	...modalMessages,
	...payrollMessages
};

class DeductionsModal extends React.Component  {

	getIntl = (str) => {
		return this.props.intl.formatMessage({...messages[str]});
	}

	handleOk = () => {
        const { props, getIntl } = this;
		const { form, deduction, addDeduction, editDeduction, setLoading, handleCancel } = props;
		const { validateFields } = form;
		const { id } = deduction;
        validateFields({"force": true}, (err, values) => {
			if (!err) {
				const { name } = values;
				const exec = (func, successMessage, errorMessages) => {
					setLoading(true);
					func(name, id)
					.then(() => {
						setLoading(false);
						message.success(successMessage);
						handleCancel();
					})
					.catch((error) => {
						const status = getNestedValue("response.status", error);
						setLoading(false);
						message.error(status === 504 ? getIntl("deductionNameExistsError") : errorMessages);
					});
				}
				if (id) exec(editDeduction, getIntl("editDeductionSuccess"), getIntl("editDeductionError"));
                else exec(addDeduction, getIntl("addDeductionSuccess"), getIntl("addDeductionError"));
			}
		});
	}

	renderedButtons = () => {
        const { props, getIntl, handleOk } = this;
        const { handleCancel, isLoading } = props;
		let buttonArray = [];
		buttonArray.push(
            <Button 
                style={{ marginLeft: 8 }}
                key = "cancel"
                onClick={handleCancel}
                loading={isLoading}
            >
                {getIntl("cancel")}
            </Button>
        );
        buttonArray.push(
            <Button 
                type="primary"
                key = "submit"
                onClick={handleOk}
                loading={isLoading}
            >
                {getIntl("save")}
            </Button>
        );
		return buttonArray;
    }

	render(){
		const { props, getIntl, handleOk, renderedButtons } = this;
		const { form, showModal, deduction, handleCancel } = props;
		const { name, id } = deduction;
		let title = id ? getIntl("editDeduction") : getIntl("addDeduction");
		return (
			<Modal
				open={showModal}
				title={title}
				onOk={handleOk}
				onCancel={handleCancel}
				destroyOnClose={true}
				footer={renderedButtons()}
				maskClosable={false}
            >
				<Form layout='vertical'>
					<DeductionsForm form={form} name={name} />
				</Form>
            </Modal>
		);
	}
}

DeductionsModal.defaultProps = {
	showModal: false,
	isLoading: false,
	deduction: {}
};

DeductionsModal.propTypes = {
	intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,

    isLoading: PropTypes.bool.isRequired,
	showModal: PropTypes.bool.isRequired,
	deduction: PropTypes.object.isRequired,
	handleCancel: PropTypes.func.isRequired,
	addDeduction: PropTypes.func.isRequired,
	editDeduction: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		addDeduction: (name) => dispatch( AddDeduction(name) ),
		editDeduction: (id, name) => dispatch( EditDeduction(id, name) )
	};
};

export default connect(null, mapDispatchToProps)(injectIntl(Form.create()(DeductionsModal)));