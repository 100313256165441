import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker } from 'antd';
import { injectIntl } from 'react-intl';
import { getErrorMessages, getJobMessages } from '../../../../constants/messages';
const moment = require('moment');

const FormItem = Form.Item;

let jobMessages = getJobMessages(),
    errorMessages = getErrorMessages();

const messages = {
	...jobMessages,
	...errorMessages
};

const dateFormat = "DD-MM-YYYY";
const dateTimeFormat = "DD-MM-YYYY hh:mm a";
const timeFormat = "hh:mm";

class JobDate extends React.Component {

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    disabledDate = (current) => {
        return current < moment().startOf('day');
    }

    disabledTime = (current) => {
        let now = moment();
        if (current=== undefined || !moment(current).isValid() || !(current.isSame(now, 'day'))) {
            return;
        }
        let disabledHours = [];
        for(let i = 0; i < now.hour(); i++){
            disabledHours.push(i);
        }
        let disabledMinutes = [];
        if (current.hour() < now.hour()) {
            for(let i = 0; i < 61; i++){
                disabledMinutes.push(i);
            }
        }
        else if (current.hour() === now.hour()) {
            for(let i = 0; i < now.minute(); i++){
                disabledMinutes.push(i);
            }
        }
        return {
            disabledHours: () => disabledHours,
            disabledMinutes: () => disabledMinutes
        };
    }

	dateValidator = (rule, value, callback) => {
        const { props, getIntl } = this;
        const { showTime, customValidator, disabled, isUpdate } = props;
        if (disabled && isUpdate) callback();
        if ('customValidator' in props) return customValidator(rule, value, callback);
        if (showTime && value < moment()) return callback(getIntl("invalidDateError"));
        if (!showTime && value < moment().startOf('day')) return callback(getIntl("invalidDateError"));
        callback();
	}

    onChange = (momentDate) => {
        const { props } = this;
        const { onChange } = props;
        if ('onChange' in props) onChange(momentDate);
    }
    
	render() {
        const { props, getIntl, onChange, dateValidator, disabledDate, disabledTime } = this;
        const { form, disabled, programmedDate, showTime, getValueFromEvent } = props;
        const { getFieldDecorator } = form;
        const dateMoment = moment(programmedDate);
        const extraProps = 'getValueFromEvent' in props ? { getValueFromEvent } : { };
        return(
            <FormItem label={getIntl("programmedDate")} >
            {
                getFieldDecorator(`programmedDate`,
                    {
                        ...extraProps,
                        initialValue: dateMoment.isValid() ? dateMoment : null,
                        rules: [{
                            required: true,
                            message: getIntl("dateIsRequired"), 
                        }, {
                            validator: dateValidator
                        }],
                        onChange: onChange
                    }
                )(
                    <DatePicker
                        className="job-full-component"
                        showTime={showTime ? { format: timeFormat, use12Hours: true, minuteStep: 10 } : false}
                        format={showTime ? dateTimeFormat : dateFormat}
                        placeholder={getIntl("programmedDate")}
                        disabled={disabled}
                        showToday={false}
                        disabledDate={disabledDate}
                        disabledTime={disabledTime}
                        allowClear={false}
                    />
                )
            }
            </FormItem>
        );
    }
}

JobDate.defaultProps = {
    programmedDate: "",
    disabled: false,
    showTime: true,
    isUpdate: false
};

JobDate.propTypes = {
	intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    programmedDate: PropTypes.string,
    onChange: PropTypes.func,
    showTime: PropTypes.bool,
    customValidator: PropTypes.func,
    getValueFromEvent: PropTypes.func,
    isUpdate: PropTypes.bool
};

export default injectIntl(JobDate);