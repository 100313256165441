import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types';
import { getModalMessages, getPayrollMessages } from '../../../../../constants/messages';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Modal, message } from 'antd';
import { LoadingOutlined, SaveOutlined } from '@ant-design/icons';
import EditIsrForm from './EditIsrForm';
import { BeforeEditISRRoofs } from '../../../actions';

let ModalMessages = getModalMessages(),
    PayrollsMessages = getPayrollMessages();

const messages = {
    ...ModalMessages,
    ...PayrollsMessages,
};

const EditIsrModal = ({open, closeModal, title, record}) => {
    const intl = useIntl();
    const form = useRef();
    const dispatch = useDispatch();
    const [isSaving, setSaving] = useState(false);

    const onOk = async () => {
        try {
            setSaving(true);
            const values = await form.current.validateFields();
            await dispatch(BeforeEditISRRoofs(record.index, values));
            closeModal();
        } catch (error) {
            message.error(intl.formatMessage({...messages.editISRRoofsError }));
        } finally {
            setSaving(false);
        }
    }
    
    return (
        <Modal
            open={open}
            title={title}
            onOk={onOk}
            okText={
                <span>
                    {isSaving ? <LoadingOutlined /> : <SaveOutlined />} {<FormattedMessage {...messages.save} />}
                </span>
            }
            onCancel={closeModal}
            destroyOnClose={true}
        >
            <EditIsrForm form={form} record={record}/>
        </Modal>
    )
}

EditIsrModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    title: PropTypes.string,
    open: PropTypes.bool,
    record: PropTypes.object,
};

export default EditIsrModal
