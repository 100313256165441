import {
	GET_FIRST_ENTRIES,
	GET_MORE_ENTRIES,
	GET_ENTRIES_COUNT,
	GET_CATEGORIES_COUNT,
	GET_CATEGORIES,
	GET_BANKS_COUNT,
	GET_BANKS,
	GET_BANK,
	CLEAR_ENTRIES,
	GET_SELECT_CATEGORIES,
	SET_SEARCH_CATEGORIES,
	SET_SEARCH_BANKS
} from './actionTypes';

import {
    CLEAR_SESSION,
	CLEAN_REDUCER
} from '../../constants/actionTypes';

const initState = {
	entries: [],
	categories: [],
	selectCats: [],
	banks: [],
	pageIndex: 0,
	entriesCount: 0,
	categoriesCount: 0,
	banksCount: 0,
	search: '',
	searchBanks:''
};


const reducer = (state = initState, action) => {
	switch (action.type) {
		case GET_FIRST_ENTRIES:
			return { ...state, entries: action.payload, pageIndex: 1 };
		case CLEAR_ENTRIES:
			return { ...state, entries: [] };
		case GET_ENTRIES_COUNT:
			return { ...state, entriesCount: action.count };
		case GET_MORE_ENTRIES:
			return { ...state, entries: [...action.payload, ...state.entries], pageIndex: action.page };
		case GET_CATEGORIES_COUNT:
			return { ...state, categoriesCount: action.count };
		case GET_CATEGORIES:
			return { ...state, categories: action.payload };
		case GET_BANKS_COUNT:
			return { ...state, banksCount: action.count };
		case GET_BANKS:
			return { ...state, banks: action.payload };
		case GET_BANK:
			return { ...state, banks: action.payload };
		case GET_SELECT_CATEGORIES:
			return { ...state, selectCats: action.payload };
		case SET_SEARCH_CATEGORIES:
			return { ...state, search: action.search };
		case SET_SEARCH_BANKS:
			return{ ...state, searchBanks:action.searchBanks }
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;