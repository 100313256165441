import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Modal, message, Spin, DatePicker } from 'antd';
import { getModalMessages, getInvoicingMessages } from '../../constants/messages';
import { DownloadMonthlyInvoiceReport } from './actions';
import moment from 'moment';
const { MonthPicker } = DatePicker;
const FormItem = Form.Item;

let modalMessages = getModalMessages(),
	invoicingMessages = getInvoicingMessages();

const messages = {
	...modalMessages,
	...invoicingMessages
};

const monthDateFormat = "MM-YYYY";

class InvoiceMonthReportModal extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: false
		};
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });

	handleOk = () => {
		const { props, getIntl, setLoading } = this;
		const { validateFields } = props.form;
		validateFields({ force: true }, (err, values) => {
			if (!err) {
				setLoading(true);
				DownloadMonthlyInvoiceReport(values.month)
					.then(() => setLoading(false))
					.catch(() => {
						setLoading(false);
						message.error(getIntl("generateReportError"));
					});
			}
		});
	}

	handleCancel = () => {
		const { props, setLoading } = this;
		const { handleCancel } = props;
		setLoading(false);
		handleCancel();
	}

	renderedButtons = () => {
		const { state, handleOk, handleCancel, getIntl } = this;
		const { isLoading } = state;
		return [
			<Button key="cancel" disabled={isLoading} onClick={handleCancel}>{getIntl("cancel")}</Button>,
			<Button key="submit" loading={isLoading} type="primary" onClick={handleOk}>{getIntl("generateReport")}</Button>
		];
	}

	formatPaidDateEventValue = (date) => {
		if (!date) return null;
		const middleOfTheMonth = moment(date).set({ date: 15, hour: 12, minute: 0, second: 0, millisecond: 0 });
		return middleOfTheMonth;
	}

	renderMonthPicker = () => {
		const { props, getIntl, formatPaidDateEventValue } = this;
		const { form } = props;
		const { getFieldDecorator } = form;
		return (
			<FormItem label={getIntl("month")}>
				{
					getFieldDecorator(`month`,
						{
							initialValue: moment().set({ date: 15, hour: 12, minute: 0, second: 0, millisecond: 0 }),
							getValueFromEvent: formatPaidDateEventValue,
							rules: [{
								required: true,
								message: getIntl("monthToGenerateForIsRequired"),
							}]
						}
					)(
						<MonthPicker
							className="job-full-component"
							format={monthDateFormat}
							allowClear={false}
						/>
					)
				}
			</FormItem>
		);
	}

	renderForm = () => {
		const { getIntl, renderMonthPicker } = this;
		return (
			<div>
				{getIntl("generateMonthlyReportDescription")}
				{renderMonthPicker()}
			</div>
		)
	}

	render() {
		const { state, props, handleOk, handleCancel, renderedButtons, renderForm } = this;
		const { isLoading } = state;
		const { showModal } = props;
		return (
			<Modal
				open={showModal}
				onOk={handleOk}
				onCancel={handleCancel}
				destroyOnClose={true}
				footer={renderedButtons()}
				maskClosable={!isLoading}
				keyboard={!isLoading}
				closable={!isLoading}
			>
				<Spin spinning={isLoading}>
					{renderForm()}
				</Spin>
			</Modal>
		);
	}
}

InvoiceMonthReportModal.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,

	showModal: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired
};

export default injectIntl(Form.create()(InvoiceMonthReportModal));