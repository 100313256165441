export const GET_FIRST_ENTRIES = 'GET_FIRST_ENTRIES';
export const GET_MORE_ENTRIES = 'GET_MORE_ENTRIES';
export const GET_ENTRIES_COUNT = 'GET_ENTRIES_COUNT';
export const GET_CATEGORIES_COUNT = 'GET_CATEGORIES_COUNT';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_BANKS_COUNT = 'GET_BANKS_COUNT';
export const GET_BANKS = 'GET_BANKS';
export const CLEAR_ENTRIES = 'CLEAR_ENTRIES';
export const GET_BANK = 'GET_BANK';
export const GET_SELECT_CATEGORIES = 'GET_SELECT_CATEGORIES';
export const SET_SEARCH_CATEGORIES = 'SET_SEARCH_CATEGORIES';
export const SET_SEARCH_BANKS = 'SET_SEARCH_BANKS';