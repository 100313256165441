import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import { injectIntl } from 'react-intl';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

class ExchangeRateFilter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			startDate: {},
			endDate: {},
		};
	}
	
	onChangeRange = (e) => {
		this.setState({
			startDate: e[0],
			endDate: e[1]
		});
		let filter = {
			...this.state,
			startDate: e[0],
			endDate: e[1]
		};
		this.props.onFilter(filter);
    }

	render(){
		return(
			<span style={{align: 'right'}}>
				<RangePicker format={dateFormat} onChange={this.onChangeRange}/>
			</span>
		);
	}
}

ExchangeRateFilter.propTypes = {
	onFilter: PropTypes.func,
};

export default injectIntl(ExchangeRateFilter);