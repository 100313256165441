import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import CommonTable from '../../components/CommonTable';
import { message, Typography, Popconfirm } from 'antd';
import { getactionMessages, getFields, getModelsMessages, getPopConfirm } from '../../constants/messages';
import AddButton from './AddButton';
import { DeleteUnitModel, GetAllUnitModels, setSearch} from './actions';
import AddOrEditModal from './AddOrEditModal';
import _ from 'lodash';

let ModelsMessages = getModelsMessages(),
    PopMessages = getPopConfirm(),
    ActionMessages = getactionMessages(),
	FieldsMessages = getFields();

const messages = {
	...ModelsMessages,
    ...ActionMessages,
    ...FieldsMessages,
    ...PopMessages,
};

const Models = () => {
    const intl = useIntl();
	const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
	const [filter, setFilter] = useState('');
    const [models, setModels] = useState([]);
	const [isVisible, setVisible] = useState(false);
	const [recordToEdit, setRecordToEdit] = useState({ id: 0 });
    const search = useSelector((state) => state.model.search);

    useEffect(() => {
		(async () => {
            try {
                const response = await dispatch(GetAllUnitModels());
                setModels(response);
                setLoading(false);
            } catch (error) {
                message.error(intl.formatMessage({ ...messages.getModelsError }));
            }
        })();	
    }, []);

    const deleteModel = (modelSelected) => {
		setLoading(true);
		dispatch(DeleteUnitModel(modelSelected)).then(() => {
			setModels((models) => models.filter((model) => !modelSelected.includes(model.id)));
			setLoading(false);
		});
		return [];
	};

    const setEditModalVisibility = (visibility) => {
		setVisible(visibility);
	};

	const updateModels = (newModel) => {
        const modelsIds = newModel.map((model) => model.id);
        const updatedModels = models.filter((model) => !modelsIds.includes(model.id));
        setModels([...updatedModels, ...newModel]);
	};

    const editRecord = (record) => {
		setRecordToEdit(record);
		setVisible(true);
	};

    const tableActions = (record) => {
        return (
            <span>
                <Typography.Link
                    className="editable-add-btn"
                    onClick={() => editRecord(record)}
                >
                    <span className="icon-pencil" />
                    <FormattedMessage {...messages.edit} />
                </Typography.Link>
                <span> | </span>
                <Popconfirm
                    title={intl.formatMessage({...messages.deleteConfirm})}
                    onConfirm={() => {
                        deleteModel([record.id]);
                        message.success(intl.formatMessage({...messages.deleteModelSuccess}));
                    }}
                    okText={intl.formatMessage({...messages.yes})}
                    cancelText={intl.formatMessage({...messages.no})}
                >
                    <Typography.Link>
                        <span className="icon-bin" />
                        <FormattedMessage {...messages.delete} />
                    </Typography.Link>
                </Popconfirm>
            </span>
        );
    };
    
	const filteredModels = useMemo(() => {
        const currentModels = models.filter((model) => {
                if (filter.searchText === '') {
                    return true;
                }
                if (model.model.toLowerCase().includes(filter.searchText)) {
                    return true;
                }
                return false;
            });
            dispatch(setSearch(filter.searchText || ""));
            return _.sortBy(currentModels, (model) => model.id);
    }, [models, filter]);

    const columns = useMemo(() => {
        return [
            {
                title: intl.formatMessage({ ...messages.model}),
                dataIndex: 'model',
                key: 'model',
            },
            {
                title: intl.formatMessage({ ...messages.actions}),
                dataIndex: 'id',
                render: (id, record) => tableActions(record),
            },
        ];
    }, []);

    return (
        <div className="account view">
            <CommonTable
                columns={columns}
                dataSource={filteredModels}
                Add={<AddButton updateModels={updateModels} />}
                delete={deleteModel}
                loading={loading}
                recordCount={filteredModels.length}
                // eslint-disable-next-line no-unused-vars
                search={(search, page, pageSize) => setFilter(search)}
                searchText={search}
                getRecords={() => { }}
                getCheckboxProps={(() => ({ disabled: false }))}
                lowerCaseSearch
                preserveSelectedRowKeys
            />
            <AddOrEditModal
                title={intl.formatMessage({ ...messages.edit})}
                visible={isVisible}
                record={recordToEdit}
                onCancel={() => setEditModalVisibility(false)}
                updateModels={updateModels}
                isEdit
            />
        </div>
    )
}

export default Models;
