import {
	CLEAR_SESSION,
	FETCH_LANGUAGES
} from '../constants/actionTypes';


const initState = {
	languageFilter: []
};


const reducer = (state = initState, action) => {
	switch (action.type) {
		case FETCH_LANGUAGES:
			return {
				...state,
				languageFilter: action.languageFilter
			};
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;