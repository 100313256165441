import React from 'react';
import PropTypes from 'prop-types';

class DescriptionsCombiner extends React.Component {
    
	render(){
        const children = this.props.children;
        if (Array.isArray(children) && children.length > 1) {
            const lastIndex = children.length - 1;
            return (
                <div>
                    {children.map((child, index) => {
                        let className = `job-descriptions-no-round-corners`;
                        if (index < lastIndex) className += ` job-descriptions-no-bottom-border`;
                        if (index === 0) className += ` job-descriptions-round-top-corner`;
                        if (index === lastIndex) className += ` job-descriptions-round-bottom-corner`;
                        return <div key={index} className={className}>{child}</div>;
                    })}
                </div>
            );
        }
        return (
            <div>
                {this.props.children}
            </div>
        );
	}
}

DescriptionsCombiner.propTypes = {
    children: PropTypes.any.isRequired
};

export default DescriptionsCombiner;