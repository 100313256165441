import React from 'react'; 
import PropTypes from 'prop-types';
import { getFields, getEmployeeMessages, getPayrollMessages } from '../../../constants/messages';
import { injectIntl } from 'react-intl';
import MoneyInputNumber from '../../Employees/AddOrUpdate/DynamicComponents/MoneyInputNumber';

let employeeMessages = getEmployeeMessages(), 
    fieldMessages = getFields(),
    payrollMessages = getPayrollMessages();
 
const messages = { 
    ...fieldMessages,
    ...employeeMessages,
    ...payrollMessages
};

class Deduction extends React.Component {

	getIntl = (str) => {
		return this.props.intl.formatMessage({...messages[str]});
	}

	renderAmountField = () => {
		const { getIntl, props } = this;
		const { amount } = props;
        return (
            <MoneyInputNumber
                fieldName = {`amount`}
                label = {getIntl("amount")}
                errorMessage = {getIntl("amountError")}
                initialValue = {amount}
            />
        );
	}

    fullRender = () => {
        const { renderAmountField } = this;
        return renderAmountField();
    }

    render() {
        return this.fullRender(); 
    }

}
 
Deduction.defaultProps = { 
    amount: 0
};
 
Deduction.propTypes = {
    intl: PropTypes.object.isRequired,
    amount: PropTypes.number.isRequired
};

export default injectIntl(Deduction);