import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { ConfigProvider  } from 'antd';
import { PersistGate } from 'redux-persist/integration/react';
import { Routes } from '../routes'
import history from '../history';

class Root extends Component {
	render() {
		const { store, locale, lang, persistor} = this.props;
		return (
			<Provider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<ConfigProvider locale={locale[lang].language}>
							<IntlProvider locale={locale[lang].intl} messages={locale[lang].messages}>
								<Router history={history}>
									<Routes />
								</Router>
							</IntlProvider>
						</ConfigProvider>
					</PersistGate>
			</Provider>
		);
	}
}

Root.propTypes = {
	store: PropTypes.object.isRequired,
	locale: PropTypes.object.isRequired,
	lang: PropTypes.string.isRequired,
	persistor: PropTypes.object.isRequired
};


export default Root;