import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { message, Divider } from 'antd';
import { getClientMessages, getFields } from '../../../constants/messages';
import { GetClientTiers } from '../actions';
import TierRender from './TierRender';
import AddButton from '../AddOrUpdate/DynamicComponents/AddButton';

let clientMessages = getClientMessages(),
	fieldMessages = getFields();

const messages = {
	...clientMessages,
	...fieldMessages
};

class TiersForm extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			tiers: [],
			newUuid: 1
		};
	}

	componentDidMount() {
		const { props, getIntl } = this;
		const { setLoading } = props;
		setLoading(true);
		GetClientTiers()
			.then(tiers => {
				setLoading(false);
				this.setState({ tiers: tiers.map(tier => ({ ...tier, uuid: `upd${tier.id}` })) });
			})
			.catch(() => {
				setLoading(false);
				message.error(getIntl("loadClientTiersError"));
			});
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	getNewUniqueIdentifier = () => {
		const uuid = this.state.newUuid;
		this.setState({ newUuid: uuid + 1 });
		return uuid;
	}

	addTier = () => {
		const { state, getNewUniqueIdentifier } = this;
		const { tiers } = state;
		const dataCopy = Array.from(tiers);
		dataCopy.push({ uuid: `new${getNewUniqueIdentifier()}` });
		this.setState({ tiers: dataCopy });
	}

	deleteTier = (deleteThisUuid) => {
		const { tiers } = this.state;
		const dataCopy = [];
		tiers.forEach(tier => {
			const { uuid } = tier;
			const fieldSubstring = uuid.substring(0, 3);
			if (uuid === deleteThisUuid && fieldSubstring == "upd") {
				const tierId = parseInt(uuid.substr(3));
				dataCopy.push({ ...tier, uuid: `del${tierId}` });
			}
			else if (uuid !== deleteThisUuid) dataCopy.push(tier);
		});
		this.setState({ tiers: dataCopy });
	}

	renderTiers = () => {
		const { state, props, deleteTier } = this;
		const { tiers } = state;
		const { form } = props;
		const dividedTiers = [];
		tiers.forEach((tier, index) => {
			const { uuid } = tier;
			const fieldSubstring = uuid.substring(0, 3);
			dividedTiers.push(<TierRender key={uuid} tier={tier} form={form} removeFunction={() => deleteTier(uuid)} />);
			if (fieldSubstring !== 'del' && index !== tiers.length - 1) dividedTiers.push(<Divider key={index} />);
		});
		return dividedTiers;
	}

	renderAddButton = () => {
		const { getIntl, addTier } = this;
		return <AddButton text={getIntl("addTier")} addFunction={addTier} />;
	}

	fullRender = () => {
		const { renderTiers, renderAddButton } = this;
		return (
			<div>
				{renderTiers()}
				{renderAddButton()}
			</div>
		);
	}

	render() {
		return this.fullRender();
	}
}

TiersForm.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	setLoading: PropTypes.func.isRequired
};

export default injectIntl(TiersForm);