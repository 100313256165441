export const GET_INVOICES = 'GET_INVOICES';
export const GET_INVOICE = 'GET_INVOICE';
export const ADD_INVOICE_SUCCESS = 'ADD_INVOICE_SUCCESS';
export const GENERATED_INVOICES_SUCCESS = 'GENERATED_INVOICES_SUCCESS';
export const CREATE_PDF = 'CREATE_PDF';
export const LATEST_PAYMENT = 'LATEST_PAYMENT';
export const DISABLE_INVOICE = 'DISABLE_INVOICE';
export const DISABLE_INVOICE_SUCCESS = 'DISABLE_INVOICE_SUCCESS';
export const PAID_INVOICE_SUCCESS = 'PAID_INVOICE_SUCCESS';
export const OVERDUE_INVOICE_SUCCESS = 'OVERDUE_INVOICE_SUCCESS';
export const SELECT_EMAILS_INVOICE = 'SELECT_EMAILS_INVOICE';
export const SET_INVOICE_TO_ADD = 'SET_INVOICE_TO_ADD';
export const GET_CLIENTS_PENDING_INVOICES = 'GET_CLIENTS_PENDING_INVOICES';
export const CLEAN_CLIENTS_PENDING_INVOICES = 'CLEAN_CLIENTS_PENDING_INVOICES';
export const TAX_RETENTION = 'TAX_RETENTION';
export const GET_TAX_RETENTION = 'GET_TAX_RETENTION';
export const TAX_RETENTION_IMAGE = 'TAX_RETENTION_IMAGE';
export const FETCH_INVOICE_COUNT = 'FETCH_INVOICE_COUNT';
export const CLEAR_SESSION = 'CLEAR_SESSION';
export const CLEAN_REDUCER = 'CLEAN_REDUCER';
export const SAVE_ISV = 'SAVE_ISV';
export const UPDATE_SEARCH = 'UPDATE_SEARCH_INVOICES';
export const UPDATE_PAGE = 'UPDATE_PAGE_INVOICES';
