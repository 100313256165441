import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { getErrorMessages, getJobMessages } from '../../../../constants/messages';
import YesNoTemplate from './YesNoTemplate';

let jobMessages = getJobMessages(),
    errorMessages = getErrorMessages();

const messages = {
	...jobMessages,
	...errorMessages
};

class ClientKeepsDevice extends React.Component {

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});
    
	render() {
        const { props, getIntl } = this;
        const { form, clientKeepsDevice, disabled, onChange } = props;
        return (
            <YesNoTemplate
                form={form}
                initialValue={clientKeepsDevice}
                disabled={disabled}
                onChange={onChange}
                label={getIntl("clientKeepsDevice")}
                fieldName={`clientKeepsDevice`}
                errorMessage={getIntl("clientKeepsDeviceError")}
            />
        )
    }
}

ClientKeepsDevice.defaultProps = {
    disabled: false
};

ClientKeepsDevice.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    clientKeepsDevice: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
};

export default injectIntl(ClientKeepsDevice);