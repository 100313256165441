import { getObjectInObject } from '../../utilities/util';

export const STATUS = {
	PENDING: 1,
	APPROVED: 2,
	REJECTED: 3
};

export const DisableMessages = [
	"Ya no será cliente.",
	"Ya no será cliente; en mora."
];

export const RuleTypes = {
    producto: {
        id: 1,
        intl: "Producto"
    },
    dispositivo: {
        id: 2,
        intl: "Dispositivo"
    }
};

export const ProductRuleIds = {
    mensual: {
        id: 81,
        intl: "Servicio Mensual"
    },
    arrendado: {
        id: 86,
        intl: "Unidad de Servicio GPS arrendado"
    },
    rutas: {
        id: 143,
        intl: "Servicio Mensual Rutas"
    }
};

export function getRuleType(id) {
    let TYPE = getObjectInObject("id", id, RuleTypes);
    if (TYPE === undefined)
        return ({
            id: -1,
            intl: "No se ha seleccionado un tipo."
        });
    return TYPE;
}

export function getProductRuleId(id) {
    let PRODUCT = getObjectInObject("id", id, ProductRuleIds);
    if (PRODUCT === undefined)
        return ({
            id: -1,
            intl: "No se ha seleccionado un producto."
        });
    return PRODUCT;
}

export const validValue = (value) => value && (/\S/.test(value));

export const TierBadgeColors = [
	'#cd7f32', //bronze
	'#c0c0c0', // silver
	'#ffd700', // gold
	'#e5e4e2', // platinum
	'#b7efff',
	'#ffd0a8',
	'#ffb1b1',
	'#b19cd9',
	'#99ffa0',
	'#8a8583',
	'#cca483',
	'#41AAC4',
	'#eed7a1',
];