import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
	getAuthMessages,
	getModalMessages,
	getFields,
	getErrorMessages,
	getRoleMessages,
	getSideMenu,
	getEditRoleMessages,
} from '../constants/messages';
import {
	Checkbox,
	Button,
	Modal,
	Col,
	Row,
	Popover,
} from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { UpdateRoles, FetchRolesById } from '../actions/AccountAction';
import RoleAccessItems from './AccessItems';

let authMessages = getAuthMessages(),
	errorMessages = getErrorMessages(),
	fieldMessages = getFields(),
	modalMessages = getModalMessages(),
	sideMenuMessages = getSideMenu(),
	roleMessages = getRoleMessages(),
	editRoleMessages = getEditRoleMessages();
const messages = {
	...authMessages,
	...modalMessages,
	...fieldMessages,
	...errorMessages,
	...roleMessages,
	...sideMenuMessages,
	...editRoleMessages
};

const EditRolesForm = ({showModal, handleCancel}) => {
	
	const intl = useIntl();
	const [editableRoles, setEditableRoles] = useState([]);
	const userId = useSelector((state) => state.account.roleUserId);
	const roles = useSelector((state) => state.role.roles);
	const loadingData = useSelector((state) => state.account.loadingData);
	const confirmLoading = useSelector((state) => state.account.confirmLoading);
	const editableRolesRedux = useSelector((state) => state.role.editableRoles);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(FetchRolesById(userId));
		cleanEditableRoles(); 
	}, [userId]);

	useEffect(() => {
		updateEditableRoles();
	}, [editableRolesRedux]);
	
	const cleanEditableRoles = () => {setEditableRoles([]);};

	const updateEditableRoles = () => {
		setEditableRoles(editableRolesRedux);
	};

	const onChange = (checkedValues) => {
		setEditableRoles(checkedValues);
	};

	const updateroles = async () => {
		await dispatch(UpdateRoles(userId, editableRoles));
	};

	const closeModal = () => {
		handleCancel();
		setEditableRoles(editableRolesRedux);
	}

	const plainOptions = roles.map((role) => {
    const roleAccessItem = RoleAccessItems.find((item) => item.key === role.id);
    return {
      label: intl.formatMessage({ ...messages[role.name] }),
      value: role.id,
      access: roleAccessItem
        ? roleAccessItem.content.map((element, index) => (
            <p key={index}>
				<FormattedMessage {...element}/>
			</p>
          ))
        : null,
    };
  });

  return (
    <Modal
      open={showModal}
      title={intl.formatMessage({ ...messages.editRoleTitle })}
      onOk={updateroles}
      confirmLoading={confirmLoading}
      onCancel={closeModal}
      destroyOnClose
      footer={[
        <Button
          key="submit"
          type="primary"
          loading={confirmLoading}
          size="large"
          onClick={updateroles}
        >
          <FormattedMessage {...messages.send} />
        </Button>,
        <Button key="back" size="large" onClick={closeModal}>
          <FormattedMessage {...messages.cancel} />
        </Button>,
      ]}
    >
      {!loadingData ? (
        <Checkbox.Group value={editableRoles} onChange={onChange}>
          <Row>
            {plainOptions.map((option, index) => (
              <Col span= {12}  style={{ paddingBottom: "7px" }} key={index} >
					<Checkbox value={option.value}> 
						<Popover content={option.access} title={intl.formatMessage({...messages.accessTo})}>
							{option.label}
						</Popover>
					</Checkbox>	
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      ) : null}
    </Modal>
  );
}

EditRolesForm.propTypes = {
	showModal: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired,
};

export default EditRolesForm