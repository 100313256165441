import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, message, Spin, Modal, Button, Row } from 'antd';
import { connect } from "react-redux";
import { injectIntl } from 'react-intl';
import { getInvoicingMessages, getModalMessages } from '../../constants/messages';
import React from 'react';
import { GetDepositorsList, GetFirstDepositors } from './actions';
import AddDepositorButton from './AddDepositorButton';

let invoicingMessages = getInvoicingMessages(),
    modalMessages = getModalMessages();


const messages = {
    ...invoicingMessages,
    ...modalMessages
};

class SelectDepositor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            clients: []
        }
    }

    componentDidMount() {
        this.getFirstDepositors();
    }

    fetchDepositors = (value) => {
        if (value.length > 0)
            this.setState({
                isLoading: true
            });
        this.props.getDepositorsList(value).then(() => {
            this.setState({
                isLoading: false
            });
        }).catch(() => {
            message.error(`${this.props.intl.formatMessage({ ...messages.depositorError })}`);
        });
    }

    handleSelectDepositor = () => {
        this.props.form.validateFields((err, values) => {
            if(err)
                message.error("Invalid form value");
            if ('selectDepositor' in this.props)
                this.props.selectDepositor(this.props.depositors[values[this.props.fieldName]]);
        });
    }

    buildOptions = () => {
        const { depositors } = this.props;
        if(depositors === undefined)
            return [];
        return depositors.map((depositor, index) => {
            let depOpt =
                <div>
                    {depositor.name}
                    <div><i>&nbsp;&nbsp;&nbsp;{depositor.client.name}</i></div>
                </div>;
            return <Select.Option key={index} value={index} name={`${depositor.name} - ${depositor.client.name}`}> {depOpt}</Select.Option>
        });
    }

    getFirstDepositors = () => {
        this.props.getFirstDepositors().catch(() => {
            message.error(`${this.props.intl.formatMessage({ ...messages.depositorError })}`);
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        let options = this.buildOptions();
        return (
            <Modal
                title={this.props.intl.formatMessage({ ...messages.searchDepositor })}
                destroyOnClose
                open={this.props.showDepositorModal}
                maskClosable={false}
                onCancel={this.props.closeDepositorModal}
                footer={
                    <div>
                        <AddDepositorButton key="add" handleAdd={this.getFirstDepositors}/>
                        <Button key="cancel" type="default" size="default" onClick={this.props.closeDepositorModal}>
                            {this.props.intl.formatMessage({ ...messages.cancel })}
                        </Button>
                        <Button key="submit" type="primary" size="default" onClick={this.handleSelectDepositor}>
                            {this.props.intl.formatMessage({ ...messages.accept })}
                        </Button>
                    </div>
                }
                className="addEntryModal"
            >
                <Form>
                    <Row>
                        {getFieldDecorator(`${this.props.fieldName}`, {
                            rules: [{
                                required: true, message: this.props.intl.formatMessage({ ...messages.Client }),
                            }],
                        })(
                            <Select
                                showSearch
                                notFoundContent={this.state.isLoading ? <Spin size="small" /> : null}
                                onSearch={this.fetchDepositors}
                                style={{ width: '100%' }}
                                optionLabelProp="name"
                            >
                                {options}
                            </Select>
                        )}
                    </Row>
                </Form>
            </Modal>
        )
    }
}

SelectDepositor.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    depositors: PropTypes.array.isRequired,
    showDepositorModal: PropTypes.bool.isRequired,
    fieldName: PropTypes.string.isRequired,
    selectDepositor: PropTypes.func.isRequired,
    closeDepositorModal: PropTypes.func.isRequired,
    getFirstDepositors: PropTypes.func.isRequired,
    getDepositorsList: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    getDepositorsList: (searchText) => {
        return dispatch(GetDepositorsList(searchText));
    },
    getFirstDepositors: () => {
        return dispatch(GetFirstDepositors());
    },
});


const mapStateToProps = (state) => {
    return {
        depositors: state.payment.depositors
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(SelectDepositor)));