import axios from 'axios';
import cookie from 'react-cookies';
import {
	LOG_IN,
	LOG_OUT,
	CLEAR_SESSION,
	RESET_PASSWORD,
	RESET,
	FETCH_LANGUAGES,
	FETCH_USER_ROLES,
	IS_USER_MASTER,
	USER_PROFILE_IMAGE
} from '../constants/actionTypes';
import {
	isEmail,
	notify,
	getS3PublicLink
} from '../utilities/util';
import {ShowErrors} from './ErrorAction';
import {locale, ONE_MONTH_IN_SECONDS} from '../constants/global';
import qs from 'qs';
import history from '../history';

export const doLogin = (auth) => dispatch => dispatch(LogIn(auth))
	.then(user => dispatch(GetUserDataAfterLogin(user)))
	.catch( (error) =>{ 
			dispatch(ShowErrors(error));
		}
	);

export const LogIn = auth => () => {
	let options = {
		"password": auth.password
	};

	if( isEmail( auth.userName ) ){
		options.email = auth.userName;
	}else{
		options.username = auth.userName;
	}

	if( auth.remember )
		options.ttl = ONE_MONTH_IN_SECONDS;

	return axios.post(`/Accounts/login?include=User`, options)
	.then(response => {
		let expires = new Date(response.data.created);
		expires = new Date( expires.getTime() +  (response.data.ttl * 1000) );
		cookie.save( 'token', response.data.id, { expires });
		cookie.save( 'token-expire', expires.getTime(), { expires });
		axios.defaults.headers.common['Authorization'] = response.data.id;
		let user = response.data.user;

		return user;
	});
};

export const GetUserRoles = (userId) => () => {
		if(!userId) return;

		return axios.get(`/Accounts/${userId}/Roles/`)
		.then((response)=>{
			return response.data.roles;
		});
	};

export const GetLanguages = () => () => {
		return axios.get(`/Languages`)
		.then( (response) => {
			return response.data;
		});
	};

export const GetUserProfilePicture = (user) => () => {
	
		if(!user || !user.image) return;

		return axios.get(getS3PublicLink(`profile/user_${user.id}`))
		.then((response)=>{
			return response.data.url;
		});
	};

function WelcomeUser(user) {
	let notify_options = {
		username: user.name
	};

	let messages = locale['en_US'].messages;

	if (user.languageId == 2 ){
		messages = locale['es_ES'].messages;
	}

	notify(`${messages[LOG_IN]}`, notify_options);
}
const GetUserDataAfterLogin = (user) => (dispatch) => {
	if(!user) return;

	return axios.all([
		dispatch(GetUserRoles(user.id)),
		dispatch(GetLanguages()),
		dispatch(GetUserProfilePicture(user))
	])
	.then(axios.spread((Roles, Languages, UserProfileUrl) => {

		dispatch({ type: FETCH_USER_ROLES, userRoles: Roles});
		let isMaster = Roles.indexOf(1) >= 0;
		dispatch({ type: IS_USER_MASTER, isMaster: isMaster});

		dispatch({ type: FETCH_LANGUAGES, languageFilter: Languages});

		let expires = new Date(Date.now());
		expires = new Date( expires.getTime() + (900 * 1000) );
		cookie.save( 'image', expires.getTime(), { expires });
		dispatch({ type: USER_PROFILE_IMAGE, url: UserProfileUrl});

		dispatch({ type: LOG_IN, user: user});
		WelcomeUser(user);
	}));
};

// TO-DO:Check auth token to decide if request must be done
export const LogOut = () => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;
	axios.post(`/Accounts/logout`)
	.then(function () {
		cookie.remove('token');
		cookie.remove('image');
		cookie.remove('token-expire');
		axios.defaults.headers.common['Authorization'] = null;
		dispatch({ type: CLEAR_SESSION });
		notify(`${messages[LOG_OUT]}`);
	})
	.catch( (error) => {
		dispatch(ShowErrors(error));
		dispatch({ type: CLEAR_SESSION });
	});
};

export const ResetRequest = (email) => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;
	let options = {
		"email": email
	};

	axios.post(`/Accounts/reset`,options)
	.then(function () {
		let notify_options = {
			email
		};
		dispatch({type: RESET});
		notify(`${messages[RESET]}`, notify_options);
	})
	.catch( (error) => {
		dispatch(ShowErrors(error));
	});
};

export const ChangePassword = (auth) => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;
	let options = {
		"newPassword": auth.password
	};

	axios.post(`/Accounts/reset-password?access_token=${auth.access_token}`,options)
	.then(function () {
		dispatch({type: RESET_PASSWORD});
		history.push('/login');
		notify(`${messages[RESET_PASSWORD]}`);
	})
	.catch( (error) => {
		dispatch(ShowErrors(error));
	});
};

export const ConfirmEmail = ({uid,token}) => () => {
	var params = {uid,token};
	return axios.get(`/Accounts/confirm?${qs.stringify(params)}`);
};