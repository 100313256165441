import React from 'react'; 
import PropTypes from 'prop-types';
import {getClientMessages} from '../../../constants/messages';
import {injectIntl} from 'react-intl';
import Email from './DynamicComponents/Email';
import AddButton from './DynamicComponents/AddButton';
import { getObjectInArray } from '../../../utilities/util';
import { Divider } from 'antd'; 
let cloneDeepWith = require('lodash.clonedeepwith');
 
let ClientMessages = getClientMessages(); 
 
const messages = {
  ...ClientMessages 
};

const defaultEmail = {
    email: "",
    tag: [],
    name: " "
};

class Emails extends React.Component {

    resetFields = () => {
        const {form} = this.props;
        form.resetFields();
    }
     
    addEmail = () => {
        let { emails, setState } = this.props;
        let defaultClone = cloneDeepWith(defaultEmail);
        emails.push(defaultClone);
        setState({emails});
        this.resetFields();
    }
   
    removeEmail = (emailIndex) => {
        let { emails, setState } = this.props;
        emails.splice(emailIndex, 1);
        setState({emails});
        this.resetFields();
    }

    saveEmail = (email, emailIndex) => {
        let { emails, setState } = this.props;
        emails[emailIndex].email = email;
        setState({emails});
    }

    saveTags = (tags, emailIndex) => {
        let { emails, setState } = this.props;
        emails[emailIndex].tag = this.tagToObject(tags);
        setState({emails});
    }

    tagToObject = (tagArray) => {
        const {tags} = this.props;
        return (
            tagArray.map(tag => {
                return getObjectInArray('id', parseInt(tag), tags);
            })
        );
    }

    saveName = (name, emailIndex) => {
        let { emails, setState } = this.props;
        emails[emailIndex].name = name;
        setState({emails});
    }

    saveIsUnique = (isUnique, emailIndex) => {
        const { emails, setState } = this.props;
        emails[emailIndex].isUnique = isUnique;
        setState({emails});
    }

    emailListRender = () => {
        const { props, saveEmail, removeEmail, saveTags, saveName, saveIsUnique } = this;
        const { form, emails, tags, optimusClientId } = props;
        let returnRenders = [];

        if(emails.length>0){
            for (let index = 0; index < emails.length; index++) {
                const email = emails[index];
                returnRenders.push(
                    <Email key={index} form={form} email={email} emailIndex={index} tags={tags} optimusClientId={optimusClientId}
                        saveEmailFunction={saveEmail} removeEmailFunction={removeEmail} saveTagsFunction={saveTags} saveNameFunction={saveName}
                        saveIsUnique={isUnique => saveIsUnique(isUnique, index)} emailsCount = {emails.length}/>
                );
                returnRenders.push(<Divider key={`Divider${index}`}/>);
            }
        }else{
            this.addEmail();
        }
        return (returnRenders);
    }

    fullRender = () => {
        const {intl} = this.props;
        const { emailListRender, addEmail } = this;
        return (
            <div>
                {emailListRender()}
                <AddButton text={intl.formatMessage({...messages.addEmail})} addFunction={addEmail}/>
            </div>
        );
    }
     
    render() {
        return ( 
            this.fullRender()
        ); 
    } 

}
 
Emails.defaultProps = { 
    emails: [],
    tags: [],
    optimusClientId: -1
};
 
Emails.propTypes = {
	intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    emails: PropTypes.array.isRequired,
    tags: PropTypes.array.isRequired,
    optimusClientId: PropTypes.number
};

export default injectIntl(Emails);