import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { getModalMessages, getErrorMessages, getInvoicingMessages, getProformaMessages, getQuotationMessages } from '../constants/messages';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Modal, Select, message, Input, Pagination } from 'antd';
import { isEmpty, isEmail } from '../utilities/util';
import { connect } from 'react-redux';
import { EditFiscalData } from '../features/FiscalData/actions';
import async from 'async';
import { GetEmailSelectorExtraEmails } from '../features/Invoices/actions';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

let modalMessages = getModalMessages(),
	errorMessages = getErrorMessages(),
	invoicingMessages = getInvoicingMessages(),
	proformaMessages = getProformaMessages(),
	quotationMessages = getQuotationMessages();

const messages = {
	...modalMessages,
	...errorMessages,
	...invoicingMessages,
	...proformaMessages,
	...quotationMessages
};

const { TextArea } = Input;
const { Option, OptGroup } = Select;

class EmailSelector extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedEmails: [],
			isLoading: false,
			message: "",
			extraEmails: [],
			showPreview: false,
			previewLoading: false,
			previewFile: '',
			documentDownloading: false,
			numPages: null,
			pageNumber: 1,
			currentPreviewFile: 1
		};
	}

	componentDidMount() {
		this.setState({ message: this.props.defaultMessage });
		this.loadExtraEmails();
	}
	
	componentDidUpdate(prevProps) {
		const { defaultMessage } = this.props;
		if (defaultMessage !== prevProps.defaultMessage) {
			this.setState({ message: defaultMessage });
		}
	}

	getIntl = (str) => this.props.intl.formatMessage({ ...messages[str] });

	setLoading = (isLoading) => this.setState({ isLoading });

	loadExtraEmails = () => {
		const { getIntl, setLoading } = this;
		setLoading(true);
		GetEmailSelectorExtraEmails()
			.then(extraEmails => this.setState({ extraEmails, isLoading: false }))
			.catch(() => {
				setLoading(false);
				message.error(getIntl("getEmailSelectorExtraEmailsError"));
			});
	}

	handleOk = () => {
		this.setState({
			isLoading: true
		});
		async.waterfall([
			(callback) => {
				let id = this.props.document.id;
				if (this.props.addOrShow)
					this.props.addDocument(this.props.document).then(documentId => {
						callback(null, documentId);
					});
				else
					callback(null, id);
			},
			(id, callback) => {
				this.props.emailDocument(id, this.state.selectedEmails, this.props.user.email, this.state.message)
					.then(() => {
						this.setState({ isLoading: false });
						if (this.props.addOrShow) this.props.goTo(id);
						this.props.closeModal();
						if (this.props.addOrShow) this.props.goTo(id);
						callback(null);
					}).catch((error) => {
						this.setState({ isLoading: false });
						callback(error);
						message.error(error);
					});
			}
		], function () {

		});
	}

	handleCancel = () => {
		this.setState({	selectedEmails: [] });
		this.props.closeModal();
	}

	handleChange = (value) => {
		if (this.state.selectedEmails.length < value.length) {
			let newEmail = value[value.length - 1];
			if (!isEmail(newEmail)) {
				value.splice(value.length - 1, 1);
				message.error(this.props.intl.formatMessage({ ...messages.emailRegexError }));
			}
		}
		this.setState({selectedEmails: [...value]});
	}

	mapEmails = () => {
		const { state: { extraEmails, selectedEmails }, props: { emails }, getIntl, handleChange } = this;
		let defaultValues = [];
		if (!isEmpty(emails)) defaultValues.push(emails[0].email);
		let emailIndex = 0;
		const options = [
			<OptGroup key="clientEmails" label={getIntl("Client")}>
				{emails.map(email => <Option key={++emailIndex} value={email.email}>{email.email}</Option>)}
			</OptGroup>,
			<OptGroup key="extraEmails" label={getIntl("extras")}>
				{extraEmails.map(email => <Option key={++emailIndex} value={email}>{email}</Option>)}
			</OptGroup>
		];
		return (
			<Select
				mode="tags"
				style={{ width: '100%' }}
				onChange={handleChange}
				tokenSeparators={[',', ' ']}
				defaultValue={defaultValues}
				value={selectedEmails}
			>
				{options}
			</Select>
		);
	}

	onChangeMessage = (message) => this.setState({message: message});

	showPreview = () => {
		const { props: { downloadPreview }, getIntl } = this;
		this.setState({ previewLoading: true });
		downloadPreview()
			.then(previewFile => this.setState({ previewFile, showPreview: true, previewLoading: false, currentPreviewFile: 1 }))
			.catch(() => {
				this.setState({ previewLoading: false });
				message.error(getIntl("invoiceDocumentPreviewError"));
			})
	}

	downloadDocument = () => {
		const { props: { downloadDocument }, getIntl } = this;
		this.setState({ documentDownloading: true });
		downloadDocument()
			.then(() => this.setState({ documentDownloading: false }))
			.catch(() => {
				this.setState({ documentDownloading: false });
				message.error(getIntl("invoiceDocumentDownloadError"));
			})
	}

	renderPreviewPagination = () => {
		const { numPages, pageNumber } = this.state;
		if (numPages && pageNumber && numPages > 1)
			return (
				<Pagination
					current={pageNumber}
					total={numPages}
					onChange={page => this.setState({ pageNumber: page })}
					pageSize={1}
					simple
				/>
			);
	}

	onPreviewDocumentLoadSuccess = ({ numPages }) => this.setState({ numPages, pageNumber: 1 });

	renderFilesPagination = () => {
		const { previewFile, currentPreviewFile } = this.state;
		if (Array.isArray(previewFile))
			return (
				<Pagination
					key="files-pagination"
					current={currentPreviewFile}
					total={previewFile.length}
					onChange={page => this.setState({ currentPreviewFile: page, pageNumber: 1 })}
					pageSize={1}
				/>
			);
	}

	renderPreviewModal = () => {
		const { state, props, getIntl, downloadDocument, onPreviewDocumentLoadSuccess, renderPreviewPagination, renderFilesPagination } = this;
		const { showPreview, previewFile, documentDownloading, pageNumber, currentPreviewFile } = state;
		const closePreview = () => this.setState({ showPreview: false });
		const width = 600;
		const scale = 1.5;
		const buttons = [];
		const file = Array.isArray(previewFile) ? previewFile[currentPreviewFile - 1] : previewFile;
		if (Array.isArray(previewFile)) buttons.push(renderFilesPagination());
		if ('downloadDocument' in props)
			buttons.push(<Button className="button-spacing" key="download" size="large" loading={documentDownloading} onClick={downloadDocument} icon={<DownloadOutlined />}>{getIntl("download")}</Button>);
		buttons.push(<Button key="close" size="large" onClick={closePreview}>{getIntl("close")}</Button>);
		return (
			<Modal className="invoice-preview-modal" open={showPreview} onCancel={closePreview} width={width * scale} footer={buttons}>
				<div>
					<Document file={file} onLoadSuccess={onPreviewDocumentLoadSuccess}>
						<Page pageNumber={pageNumber} scale={scale} width={width} />
					</Document>
					{renderPreviewPagination()}
				</div>
			</Modal>
		);
	}

	renderButtons = () => {
		const { state: { isLoading, previewLoading }, props, getIntl, handleOk, handleCancel, showPreview } = this;
		const { addOrShow } = props;
		const primaryButtonText = addOrShow ? getIntl("Save") : getIntl("send");
		const buttons = [<Button className="button-spacing" key="submit" type="primary" size="large" loading={isLoading} onClick={handleOk}>{primaryButtonText}</Button>];
		if ('downloadPreview' in props)
			buttons.push(<Button className="button-spacing" key="preview" size="large" disabled={isLoading} loading={previewLoading} onClick={showPreview} icon={<EyeOutlined />}>{getIntl("preview")}</Button>);
		buttons.push(<Button key="back" size="large" disabled={isLoading || previewLoading} onClick={handleCancel}>{getIntl("cancel")}</Button>);
		return buttons;
	}

	renderExtraComponents = () => {
		const { extraComponents } = this.props;
		if (extraComponents) return (
			<React.Fragment>
				<br />
				{extraComponents}
			</React.Fragment>
		);
	}

	render() {
		const { state: {message, isLoading, previewLoading }, props: {showEmailModal}, getIntl, 
				renderButtons, mapEmails, onChangeMessage, handleCancel, handleOk, renderExtraComponents } = this;
		return (
			<Modal
				title={getIntl("EmailsToSend")}
				open={showEmailModal}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={renderButtons()}
				maskClosable={!isLoading && !previewLoading}
				keyboard={!isLoading && !previewLoading}
				closable={!isLoading && !previewLoading}
				afterClose={() => {	this.setState({selectedEmails: [], message: "" }) }}
			>
				<React.Fragment>
					{this.renderPreviewModal()}
					<div>
						<p>{getIntl("EmailsToSend")}:</p>
						{mapEmails()}
					</div>
					<br />
					<div>
						<p>{getIntl("Message")}:</p>
						<TextArea value={message} onChange={e => onChangeMessage(e.target.value)} />
					</div>
					{renderExtraComponents()}
				</React.Fragment>
			</Modal>
		);
	}
}

EmailSelector.defaultProps = {
	defaultMessage: ""
};

EmailSelector.propTypes = {
	intl: PropTypes.object.isRequired,
	addOrShow: PropTypes.bool.isRequired,
	emails: PropTypes.array.isRequired,
	showEmailModal: PropTypes.bool.isRequired,
	document: PropTypes.object,
	user: PropTypes.object.isRequired,
	closeModal: PropTypes.func.isRequired,
	emailDocument: PropTypes.func.isRequired,
	addDocument: PropTypes.func,
	goTo: PropTypes.func,
	defaultMessage: PropTypes.string,
	downloadPreview: PropTypes.func,
	downloadDocument: PropTypes.func,
	extraComponents: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object
	]),
};

const mapStateToProps = (state) => {
	return {
		emails: state.client.emails,
		user: state.auth.user
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		editFiscalData: (fiscalData) => {
			return dispatch(EditFiscalData(fiscalData));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(EmailSelector));
