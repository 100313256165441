import React, { useMemo, useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { message, Typography } from 'antd';
import { GetEmails } from '../Clients/actions';
import { EmailReceipt } from '../Payments/actions';
import { DownloadReceipt, GetReceiptPayment, PreviewReceipt, SearchReceiptPayment } from './actions';
import { roundN } from '../../utilities/util';
import { getInvoicingMessages, getPaymentMessages } from '../../constants/messages';
import { getPaymentMethod } from '../Payments/constants';
import CommonTable from '../../components/CommonTable';
import EmailSelector from '../../components/EmailSelector';
import Filters from './Filters';
import InvoicesByReceipts from './invoicesByReceipts';
let moment = require('moment');

let PaymentMessage = getPaymentMessages(),
    invoicingMessages = getInvoicingMessages();

const messages = {
	...PaymentMessage,
    ...invoicingMessages,
}

const Receipts = () => {
    const intl = useIntl();
	const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [currentId, setCurrentId] = useState();
    const allReceipts = useSelector((state) => state.receipts.receipts);
    const countReceipt = useSelector((state) => state.receipts.count);
    useEffect(() => {
		(async () => {
            try {
                setLoading(true);
                await dispatch(GetReceiptPayment());

               
            } catch (error) {
                message.error(intl.formatMessage({ ...messages.receiptError }));
            }finally{
                setLoading(false);
            }
        })();	
    }, []);

    const openEmailModal = (id, clientId) => {
		setLoading(true);
		dispatch(GetEmails(clientId)).then(() => {
            setLoading(false);
            setCurrentId(id);
            setShowEmailModal(true);
        }).catch(() => {
            message.error(intl.formatMessage({ ...messages.getClientEmailsError }));
            setLoading(false);
		});
	};

	const closeEmailModal = () => setShowEmailModal(false);


    const expandedRow = (receipt) => {
		return (
			<InvoicesByReceipts receiptId={receipt.receiptId}/>
		);
	};

    const renderPaymentMethod = (type) => {
		const TYPE = getPaymentMethod(type);
        return intl.formatMessage( {...messages[TYPE.intl]} );
	};

    const emailReceipt = (id, emails, replyTo, message) => dispatch(EmailReceipt(id, emails, replyTo, message));

    const printReceipt = (id) => {
        return dispatch(DownloadReceipt(id)).catch(() => {
            message.error(intl.formatMessage({ ...messages.PrintError }));
        });
    };

    const tableActions = (id, record) => {
        return (
            <span>
                <Typography.Link 
                    className="editable-add-btn" 
                    onClick={() => { openEmailModal(id, record.clientId) }}
                >
                    <FormattedMessage {...messages.Emails} />
                </Typography.Link>
            </span>
        );
    };

    const columns = useMemo(() => {
        return [
            {
                title: intl.formatMessage({ ...messages.receiptNumber}),
                dataIndex: 'receiptId',
                key: 'receiptId',
                render: (id, record) => {
					return intl.formatMessage({...messages.receiptId}, {id: record.receiptId});
				}
            },
            {
                title: intl.formatMessage({ ...messages.Client}),
                dataIndex: 'client',
                key: 'client',
                render: (id, record) => {
					return  `${record.name}`;
				}
            },
            {
                title: intl.formatMessage({ ...messages.Bank}),
                dataIndex: 'bank',
                key: 'bank',
                render: (id, record) => {
					return  `${record.destinationName}`;
				}
            },
            {
                title: intl.formatMessage({ ...messages.TypeOfPayment}),
                dataIndex: 'paymentMethod',
                key: 'paymentMethod',
                render: (id, record) => {
                    return renderPaymentMethod(record.paymentMethod);
                }
            },
            {
                title: intl.formatMessage({ ...messages.Amount}),
                dataIndex: 'amount',
                key: 'amount',
                render: (id, record) => {
                    let totalPaid = roundN(record.totalPaid, 2);
                    return  `${totalPaid.toLocaleString('en-US')}`;
				}
            },
            {
                title: intl.formatMessage({ ...messages.paymentDate}),
                dataIndex: 'paymentDate',
                key: 'paymentDate',
                render: (id, record) => {
					if (record.paymentDate) return (moment(record.paymentDate).locale('es').format('MMMM DD, YYYY'));
					return;
				}
            },
            {
                title: intl.formatMessage({ ...messages.actions}),
                dataIndex: 'receiptId',
                render: (id, record) => tableActions(id, record),
            },
        ];
    }, []);

    const searchReceipts = async (search, page = 1, pageSize=10) => {
        try {
            setLoading(true);
            await dispatch(SearchReceiptPayment(search, page, pageSize));

        } catch (error) {
            message.error(intl.formatMessage({ ...messages.receiptError }));
        }finally{
            setLoading(false);
        }
	}
    const getReceipts = async (page, pageSize) => {
        try {
            setLoading(true);
            await dispatch(GetReceiptPayment(page, pageSize));
        } catch (error) {
            message.error(intl.formatMessage({ ...messages.receiptError }));
        }finally{
            setLoading(false)
        }
	}


  return (
    <div className="account view">
        <CommonTable
            columns={columns}
            dataSource={Array.from(allReceipts)}
            loading={loading ===true ? true :false}
            recordCount={countReceipt}
            // eslint-disable-next-line no-unused-vars
            expandedRow={expandedRow}
            search={(search, page, pageSize) => searchReceipts(search, page, pageSize)}
            filterComponent= {(<Filters />)}
            getRecords={(page, pageSize) => getReceipts(page, pageSize)}
            getCheckboxProps={(() => ({ disabled: false }))}
            lowerCaseSearch
            hideCheckboxes
        />
        <EmailSelector
            addOrShow={false}
            showEmailModal={showEmailModal}
            closeModal={closeEmailModal}
            document={{ id: currentId }}
            emailDocument={emailReceipt}
            downloadPreview={() => PreviewReceipt(currentId)}
            downloadDocument={() => printReceipt(currentId)}
        />
    </div>
  )
}

export default Receipts;
