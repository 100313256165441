import React from 'react';
import Icon from '@ant-design/icons';

const ThermometerSvg = () => (
	<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
		width="1.5em" height="1.5em" viewBox="0 0 24.000000 24.000000"
		preserveAspectRatio="xMidYMid meet">

		<g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
			fill="#000000" stroke="none">
			<path d="M97 213 c-4 -3 -7 -27 -7 -53 0 -25 -5 -51 -11 -57 -16 -16 -3 -61
21 -68 46 -15 89 35 61 69 -9 11 -9 15 1 19 10 4 10 8 -2 16 -13 10 -13 12 0
21 10 7 11 12 4 18 -6 4 -13 15 -17 25 -6 17 -37 23 -50 10z m33 -23 c0 -5 -4
-10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m0 -50 c0
-11 5 -29 12 -39 15 -24 4 -51 -22 -51 -26 0 -37 27 -22 51 7 10 12 28 12 39
0 11 5 20 10 20 6 0 10 -9 10 -20z"/>
		</g>
	</svg>
);

const ThermometerIconBuilder = props => <Icon component={ThermometerSvg} {...props} />;

class ThermometerIcon extends React.Component {
	render() {
		return <ThermometerIconBuilder />;
	}
}

export default ThermometerIcon;