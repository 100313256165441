import compile from 'string-template/compile';
import {
	message
} from 'antd';
import {
	CLEAR_SESSION
} from '../constants/actionTypes';
import history from '../history';

export const ShowErrors = (error) =>
	(dispatch, getState) => {
		let state = getState();
		let messages = state.int18.messages;

		if (error.response) {
			let data = error.response.data.error;
			if (data.code) {
				if (data.code == "EREQUEST") {
	
					let field = errorFields(
							JSON.parse(error.config.data),
							data.message
						);
					let compiler = compile(messages[data.code], );
					message.error(compiler({
						field:  messages[`field.${field}`]
					}), 5);
				} else if (data.code == "INACTIVE_ACCOUNT") {
					let compiler = compile(messages[data.code], );
					message.error(compiler({}), 5);
					dispatch({
							type: CLEAR_SESSION
						});
				} else {
					let compiler = compile(messages[data.code], );
					message.error(compiler({}), 5);
					if(data.statusCode == 401){
						dispatch({
							type: CLEAR_SESSION
						});
					}
					if(data.statusCode == 403){
						history.push("/");
					}
				}
			} else if (data.details) {
				if (data.details.codes) {
					let compiler;
					Object.keys(data.details.codes).forEach(function(element) {
						compiler = compile(messages[data.details.codes[element]], );
	
						message.error(compiler({
							field: messages[`field.${element}`]
						}), 5);
					});
				} else {
					message.error(messages["error.common"], 5);
				}
			}
		} else if (error.request) {
				// The request was made but no response was received
	
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
	
			message.error(messages["error.server"], 5);
		} else {
			// Something happened in setting up the request that triggered an Error
			message.error(messages["error.common"], 5);
		}
	};

const errorFields = (record, message) => {
	let scapeRegex = new RegExp("([[|\\|^|$|.|||?|*|+|(|)|{|}|])", "g");
	let theKeys = Object.keys(record);
	for (let i = 0; i < theKeys.length; i++) {
		let element = theKeys[i];
		let reg = new RegExp("^.*\\("+record[element].replace(scapeRegex,"\\$1")+"\\).*$");
		if (reg.test(message)) {
			return element;
		}
	}
};