import axios from 'axios'; 
import { STATUSES } from '../../Invoices/constants';
import qs from 'qs';

export const SaveInvoiceGuatemala = async (values) => {
	try {
		const upsertGuatemalaInvoice = async (response) => {
			return await GetInvoiceGuatemalaById(response.data.id);
		};
		if (values.id) {
			const response = await axios.patch(`/InvoiceGuatemalas/${values.id}`, values);
			return await upsertGuatemalaInvoice(response);
		}
		const response = await axios.post(`/InvoiceGuatemalas`, values);
		return await upsertGuatemalaInvoice(response);
	} catch (err) {
		return err;
	}
};

export const GetInvoicesGuatemala = async (searchObject = {}, page = 1, pageSize = 10) => {
	const params = {
		searchObject,
		limit: pageSize,
		skip: (page - 1) * pageSize,
	};
	const response = await axios.get(`/InvoiceGuatemalas/search`, { params });
	return response.data;
};

export const GetCountInvoicesGuatemala = async (searchObject) => {
	const countParams = {
		searchObject,
	};

	const response = await axios.get(`/InvoiceGuatemalas/countInvoices?${qs.stringify(countParams)}`);
	return response.data;
}

export const GetInvoiceGuatemalaById = async (id) => {
	const filter = {
		include: ['client'],
	};
	const response = await axios.get(`/InvoiceGuatemalas/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
	return response.data;
};

export const DeleteInvoicesGuatemala  = async (invoiceGuatemalaIds) => {
	const options = { data: { ids: invoiceGuatemalaIds } };
	return await axios.delete('/InvoiceGuatemalas/', options);
};

export const PayInvoiceGuatemala  = async (id) => {
	const response = await axios.patch(`/InvoiceGuatemalas/${id}`, {status: STATUSES.PAID});
	return GetInvoiceGuatemalaById(response.data.id);
};

export const PendingInvoiceGuatemala  = async (id) => {
	const response = await axios.patch(`/InvoiceGuatemalas/${id}`, {status: STATUSES.ACTIVE});
	return GetInvoiceGuatemalaById(response.data.id);
};

export const CheckDocumentNumberExistance = async (documentNumber) => {
	const filter = { documentNumber };
	try {
		let response = await axios.get(`/InvoiceGuatemalas/count?where=${encodeURIComponent(JSON.stringify(filter))}`);
		return response.data.count;
	} catch (err) {
		return 0;
	}
};