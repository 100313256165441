import axios from 'axios';
import { substituteObjectInArrayByValue } from '../../utilities/util';
import {
	FETCH_COMMISSIONS,
	FETCH_COMMISSION_COUNT
} from './actionTypes';

export const GetCommissions = (searchObject, page = 1, pageSize = 10) => (dispatch) => {
	const filter = {
		limit: pageSize,
		skip: (page - 1) * pageSize,
		order: "creationDate DESC",
		include: [{ relation: 'salesPerson', scope: { fields: ['id', 'name'] } }]
	};
	return axios.get(`/Commissions?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then(response => dispatch({ type: FETCH_COMMISSIONS, commissions: response.data }));
};

export const GetCommissionCount = () => (dispatch) => {
	return axios.get(`/Commissions/count`)
		.then(response => dispatch({ type: FETCH_COMMISSION_COUNT, count: response.data.count }));
};

export const LoadSalesmen = () => {
	return axios.get(`/Commissions/getSalesmen`)
		.then(response => response.data);
};

export const LoadCommission = (commissionId) => {
	return axios.get(`/Commissions/${commissionId}/getInfo`)
		.then(response => response.data);
};

export const LoadCommissionForEdit = (commissionId) => {
	return axios.get(`/Commissions/${commissionId}/getInfoForEdit`)
		.then(response => response.data);
};

export const LoadInvoices = (salesPersonId) => {
	return axios.get(`/Commissions/getInvoices/${salesPersonId}`)
		.then(response => response.data);
};

export const LoadCommissionPercent = () => {
	return axios.get(`/Commissions/getCommissionPercent`)
		.then(response => response.data);
};

export const AddCommission = (commissionInfo) => {
	return axios.post('/Commissions/addCommission', { commissionInfo })
		.then(response => response.data);
};

export const GetNoCommissionInvoicesCount = (salesPersonId) => {
	return axios.get(`/Commissions/getNoCommissionInvoicesCount/${salesPersonId}`)
		.then(response => response.data);
};

export const LoadNoCommissionInvoices = (salesPersonId, page = 1, pageSize = 5) => {
	return axios({
		url: `/Commissions/getNoCommissionInvoices`,
		method: 'GET',
		responseType: 'json',
		params: { salesPersonId, page, pageSize }
	})
		.then(response => response.data);
};

export const UpdateCommission = (commissionInfo, commissionId) => {
	return axios({
		url: `/Commissions/updateCommission`,
		method: 'POST',
		responseType: 'json',
		params: { commissionId, commissionInfo }
	})
		.then(response => response.data);
};

export const LoadCommissionProductInfo = () => {
	return axios.get(`/Commissions/getCommissionProductInfo`)
		.then(response => response.data);
};

export const SetCommissionConfigurations = (configurations) => {
	return axios({
		url: `/Commissions/setConfigurations`,
		method: 'POST',
		responseType: 'json',
		params: { configurations }
	})
		.then(response => response.data);
};

export const LoadCommissionableProductIds = () => {
	return axios.get(`/Commissions/getCommissionableProductIds`)
		.then(response => response.data);
};

export const CheckCommissionInvoiceEntryExistance = (invoiceId) => {
	const filter = {
		fields: ['invoiceId', 'commissionId'],
		where: { invoiceId }
	};
	return axios.get(`/CommissionInvoiceEntries?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then(response => response.data);
};

export const LoadSalespersonPendingCommissions = (salesPersonId, page = 1, pageSize = 10) => (dispatch) => {
	return axios({
		url: `/Commissions/getSalespersonPendingCommissions`,
		method: 'GET',
		responseType: 'json',
		params: { salesPersonId, page, pageSize }
	})
		.then(response => dispatch({ type: FETCH_COMMISSIONS, commissions: response.data }));
};

const updateSingleCommission = (updatedCommission, dispatch, getState) => {
	const state = getState();
	const commissions = state.commissions.commissions;
	dispatch({ type: FETCH_COMMISSIONS, commissions: substituteObjectInArrayByValue("id", updatedCommission.id, commissions, updatedCommission) });
}

const removeSingleCommission = (commissionId, dispatch, getState) => {
	const state = getState();
	const commissions = state.commissions.commissions;
	const newCommissions = [];
	commissions.forEach(commission => commission.id !== commissionId ? newCommissions.push(commission) : null);
	dispatch({ type: FETCH_COMMISSIONS, commissions: newCommissions });
}

export const RejectCommission = (commissionId) => (dispatch, getState) => {
	return axios.post(`/Commissions/${commissionId}/reject`)
		.then(() => removeSingleCommission(commissionId, dispatch, getState));
};

export const ApproveCommission = (commissionId) => (dispatch, getState) => {
	return axios.post(`/Commissions/${commissionId}/approve`)
		.then(() => removeSingleCommission(commissionId, dispatch, getState));
};

export const PayCommission = (commissionId, data) => (dispatch, getState) => {
	return axios({
		url: `/Commissions/${commissionId}/pay`,
		method: 'POST',
		responseType: 'json',
		params: { commissionId, data }
	})
		.then(response => updateSingleCommission(response.data, dispatch, getState));
};