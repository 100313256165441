import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Spin, message } from 'antd';
import { injectIntl } from 'react-intl';
import { getClientMessages, getModalMessages } from '../../../constants/messages';
import { SaveClientTiers } from '../actions';
import ClientTiersForm from './Form';
import { applyObject, getNestedValue } from '../../../utilities/util';

let clientMessages = getClientMessages(),
	modalMessages = getModalMessages();

const messages = {
	...clientMessages,
	...modalMessages
};

class TiersModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false
		};
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });

	handleCancel = () => {
		const { props, setLoading } = this;
		setLoading(false);
		props.handleCancel();
	}

	handleSave = () => {
		const { props, getIntl, setLoading, handleCancel } = this;
		const { validateFields } = props.form;
		validateFields({ force: true }, (err, values) => {
			if (!err) {
				const tiers = {
					newTiers: [],
					updateTiers: [],
					deletedTiersIds: []
				};
				const apply = (key, value) => {
					const fieldSubstring = key.substring(0, 3);
					if (fieldSubstring == "new") tiers.newTiers.push(value);
					else if (fieldSubstring == "upd") tiers.updateTiers.push({ ...value, id: parseInt(key.substr(3)) });
					else if (fieldSubstring == "del") tiers.deletedTiersIds.push(parseInt(key.substr(3)));
				}
				applyObject(values, apply);
				setLoading(true);
				SaveClientTiers(tiers)
					.then(() => {
						message.success(getIntl("saveClientTiersSuccess"));
						handleCancel();
					})
					.catch(error => {
						setLoading(false);
						const statusCode = getNestedValue("response.data.error.statusCode", error);
						if (statusCode === 409) message.error(getIntl("clientsWithDeletedTiersError"), 10);
						else message.error(getIntl("saveClientTiersError"));
					});
			}
		});
	}

	renderButtons = () => {
		const { state, getIntl, handleCancel, handleSave } = this;
		const { isLoading } = state;
		return [
			<Button key="cancel" onClick={handleCancel} disabled={isLoading}>{getIntl("cancel")}</Button>,
			<Button key="save" type="primary" onClick={handleSave} loading={isLoading}>{getIntl("save")}</Button>
		];
	}

	render() {
		const { state, props, getIntl, renderButtons, setLoading, handleCancel } = this;
		const { isLoading } = state;
		const { form, showModal } = props;
		return (
			<Modal
				open={showModal}
				title={getIntl("clientTiersConfig")}
				onCancel={handleCancel}
				destroyOnClose={true}
				footer={renderButtons()}
			>
				<Spin spinning={isLoading}>
					<ClientTiersForm form={form} setLoading={setLoading} />
				</Spin>
			</Modal>
		);
	}
}

TiersModal.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,

	showModal: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired
};

export default injectIntl(Form.create()(TiersModal));