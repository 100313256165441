import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getDeviceMessages, getUnitIcons} from '../../../../constants/messages';
import iconRender from '../../../../lib/icon';
import {getNestedValue, int18Catch} from '../../../../utilities/util';
import { ICON_IDS_WITH_NO_AZIMUTH } from '../../constants';

let iconMessages = getUnitIcons(),
    deviceMessages = getDeviceMessages();

const messages = {
    ...iconMessages,
	...deviceMessages
};

class IconRender extends React.Component  {

    renderIconName = () => {
        const { icon, renderName } = this.props;
        let id = getNestedValue("id", icon);
        let name = `icon${id}`;
        if (renderName) return <FormattedMessage {...int18Catch(messages[name])} />;
    }

    renderIcon = () => {
        const { icon } = this.props;
        let id = getNestedValue("id", icon);
        return <img 
            src={
                iconRender.svgToIcon({
                    id: `units${id}`,
                    azimuth: ICON_IDS_WITH_NO_AZIMUTH.includes(id) ? 0 : 90,
                }).url
            } 
            alt="" 
            className="svgIcon"
        />;
    }

    fullRender = () => {
        const { props, renderIcon, renderIconName } = this;
        const { icon } = props;
        const id = getNestedValue("id", icon);
        if (id === undefined) return <FormattedMessage {...messages.doesNotHave}/>;
        return (
            <span className="deviceIcon">
                {renderIcon()}
                {renderIconName()}
            </span>
        );
    }

	render(){
        return this.fullRender();
	}
}
 
IconRender.defaultProps = { 
    icon: {},
    renderName: true
};

IconRender.propTypes = {
    intl: PropTypes.object.isRequired,
    icon: PropTypes.object.isRequired,
    renderName: PropTypes.bool.isRequired
};

export default injectIntl(IconRender);