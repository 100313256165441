import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { message } from 'antd';
import { getJobMessages } from '../../../constants/messages';
import { getNestedValue } from '../../../utilities/util';
import { GetJobInfo } from '../actions';
import Comments from '../AddUpdateJob/CommonComponents/Comments';
import ActivityLog from '../AddUpdateJob/CommonComponents/ActivityLog';
import Installation from './JobTypes/Installation';
import Revision from './JobTypes/Revision';
import Uninstallation from './JobTypes/Uninstallation';
import Swap from './JobTypes/Swap';
import AddOns from './JobTypes/AddOns';
import SimChange from './JobTypes/SimChange';
import Transfer from './JobTypes/Transfer';
import Reconnection from './JobTypes/Reconnection';
import Deactivate from './JobTypes/Deactivate';
import Activate from './JobTypes/Activate';
import { JOBTYPES } from  '../constants';
const { INSTALLATION, REVISION, UNINSTALLATION, SWAP, ADDONS, SIMCHANGE, TRANSFER, RECONNECTION, DEACTIVATE, ACTIVATE } = JOBTYPES;

let jobMessages = getJobMessages();

const messages = {
    ...jobMessages
};

class NewForm extends React.Component  {

	componentDidMount () {
        const { props, getIntl } = this;
		const { saveJob, jobId, setLoading } = props;
		if (jobId) {
            setLoading(true);
            GetJobInfo(jobId)
            .then(response => {
                setLoading(false);
                saveJob(response);
            })
            .catch(() => {
                setLoading(false);
                message.error(getIntl("getJobInfoError"));
            });
		}
	}
	
    getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

	getField = (field) => {
        const { job } = this.props;
        return getNestedValue(field, job);
    }

    saveFields = (fieldObject) => {
        const { job, saveJob } = this.props;
        saveJob({ ...job, ...fieldObject });
    }

    renderComments = () => {
        const { getField, saveFields } = this;
        const { form, jobId } = this.props;
        const saveComments = comments => saveFields({ comments });
        const comments = getField("comments");
        return (
            <Comments
                form={form}
                comments={comments}
                onChange={saveComments}
                jobId={jobId}
                independent
            />
        );
    }

    renderActivityLog = () => {
        const { getField } = this;
        const activityLog = getField("activityLog");
        return (
            <ActivityLog
                activityLog={activityLog}
            />
        );
    }

    renderForm = () => {
        const { props, getField, getIntl, saveFields } = this;
        const { form, isUpdating, jobId } = props;
        const type = getField("type");
        switch (type) {
            case INSTALLATION.id: return <Installation form={form} jobId={jobId} saveFields={saveFields} getField={getField} isUpdate={isUpdating}/>;
            case REVISION.id: return <Revision form={form} jobId={jobId} saveFields={saveFields} getField={getField}/>;
            case UNINSTALLATION.id: return <Uninstallation form={form} jobId={jobId} saveFields={saveFields} getField={getField}/>;
            case SWAP.id: return <Swap form={form} jobId={jobId} saveFields={saveFields} getField={getField} isUpdate={isUpdating}/>;
            case ADDONS.id: return <AddOns form={form} saveFields={saveFields} getField={getField}/>;
            case SIMCHANGE.id: return <SimChange form={form} jobId={jobId} saveFields={saveFields} getField={getField}/>;
            case TRANSFER.id: return <Transfer form={form} jobId={jobId} saveFields={saveFields} getField={getField} isUpdate={isUpdating}/>;
            case RECONNECTION.id: return <Reconnection form={form} jobId={jobId} saveFields={saveFields} getField={getField} isUpdate={isUpdating}/>;
            case DEACTIVATE.id: return <Deactivate form={form} saveFields={saveFields} getField={getField}/>;
            case ACTIVATE.id: return <Activate form={form} saveFields={saveFields} getField={getField}/>;
            default: return <div>{getIntl("noJobTypeForm")}</div>;
        }
    }

    fullRender = () => {
        const { props, renderForm, renderComments, renderActivityLog } = this;
        const { jobId } = props;
        if (jobId)
            return (
                <div>
                    {renderForm()}
                    {renderComments()}
                    {renderActivityLog()}
                </div>
            );
        return renderForm();
    }
    
	render(){
        return this.fullRender();
	}
}

NewForm.defaultProps = {
	jobId: 0
};

NewForm.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	saveJob: PropTypes.func.isRequired,
    jobId: PropTypes.number.isRequired,
    job: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
    isUpdating: PropTypes.bool.isRequired
};

export default injectIntl(NewForm);