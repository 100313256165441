import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Tooltip, Switch } from 'antd';
import { getLanguageIntl, getCalendarMessages, getactionMessages, getJobMessages } from '../../../constants/messages';
import { JOBTYPES, getJobType } from  '../constants';
import { existsInArray, cleanArray, objectToArray } from '../../../utilities/util';
import { getJobTypeColor, standardSwitchProps } from  './Calendar';

let intlMessages = getLanguageIntl(),
    calendarMessages = getCalendarMessages(),
    actionMessages = getactionMessages(),
    JobsMessages = getJobMessages();

const messages = {
    ...intlMessages,
    ...calendarMessages,
    ...actionMessages,
	...JobsMessages
};

class TypeFilter extends React.Component {

    onChange = (checked, type) => {
        const {filterEvents} = this.props;
        const {showTheseTypes} = this.props;
        let showTheseNow = [];
        if (checked)
            showTheseNow = [...showTheseTypes].concat([type]);
        else
            showTheseNow = cleanArray(showTheseTypes, type);
        filterEvents(showTheseNow);
    }

    isSwitchChecked = (type) => {
        const {showTheseTypes} = this.props;
        if (existsInArray(type, showTheseTypes))
            return true;
        else
            return false;
    }

    renderSwitch = (type) => {
        const {isSwitchChecked} = this;
        const {intl} = this.props;
        return (
            <span key={type}>
                <Tooltip title={intl.formatMessage({...messages[getJobType(type).intl]})}  mouseEnterDelay={0}>
                    <Switch {...standardSwitchProps} style={getJobTypeColor(type)} checked={isSwitchChecked(type)} onChange={(checked) => this.onChange(checked, type)}/>
                </Tooltip>
                &nbsp;
            </span>
        );
    }

    renderTypeSwitches = () => {
        const {renderSwitch} = this;
        const types = objectToArray(JOBTYPES).map(type => type.id);
        return (types.map(type => renderSwitch(type)));
    }

	render(){
		return (
            <span>
                {this.renderTypeSwitches()}
            </span>
		);
	}
}

TypeFilter.propTypes = {
	intl: PropTypes.object.isRequired,
	filterEvents: PropTypes.func.isRequired,
    showTheseTypes: PropTypes.array.isRequired,
};

export default injectIntl(TypeFilter);