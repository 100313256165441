import React from 'react'; 
import PropTypes from 'prop-types';
import { Row, Col, Select } from 'antd'; 
import { getFields, getEmployeeMessages } from '../../../../constants/messages';
import { useIntl } from 'react-intl';
import {getNestedValue} from '../../../../utilities/util';
import { RELATIONS } from '../../constants';
import RemoveButton from './RemoveButton';
import InputForm from './FormItemInput';
import SelectForm from './FormItemSelect';

const Option = Select.Option;

let employeeMessages = getEmployeeMessages(), 
    fieldMessages = getFields() 
 
const messages = { 
    ...fieldMessages,
    ...employeeMessages
};

const Dependant = ({dependant, index, remove, saveName, saveRelation}) => {
    const intl = useIntl();
    
    const getField = (field) => {
		return getNestedValue(field, dependant);
	}
    const name = getField("name");
    const relation = getField("relation");
    
    let options = RELATIONS.map((rel, index) => <Option key={index} value={intl.formatMessage({...messages[rel]})}>{intl.formatMessage({...messages[rel]})}</Option>);
    return (
        <Row style={{ width: '100%' }}>
            <Col span={12}>
            <InputForm
                    fieldName = {[`dependants${index}`,'dependantName']}
                    label = {intl.formatMessage({...messages.name})}
                    errorMessage = {intl.formatMessage({...messages.nameError})}
                    initialValue = {name}
                    onBlur = {(event) => saveName(event.target.value, index)}
                    renderLabel = {false}
                />
            </Col>
            <Col span={11}>
                <SelectForm
                    fieldName = {[`dependants${index}`,'dependantRelation']}
                    label = {intl.formatMessage({...messages.relation})}
                    errorMessage = {intl.formatMessage({...messages.relationError})}
                    initialValue = {relation}
                    onChange = {(value) => saveRelation(value, index)}
                    options = {options}
                    renderLabel = {false}
                />
            </Col>
            <Col span={1} className="employee-list-remove-button-padding">
                <RemoveButton removeFunction={() => remove(index)}/>
            </Col>
        </Row>
    )
}

Dependant.defaultProps = { 
    dependant: {}
};
 
Dependant.propTypes = {
    form: PropTypes.object.isRequired,
    dependant: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
	remove: PropTypes.func.isRequired,
	saveName: PropTypes.func.isRequired,
	saveRelation: PropTypes.func.isRequired
};

export default Dependant