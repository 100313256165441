import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Modal, Tooltip, message, Spin } from 'antd';
import { injectIntl } from 'react-intl';
import { getJobMessages, getModalMessages } from '../../../constants/messages';
import JobForm from './Form';
import { parseJobValues } from '../constants';
import { AddJob } from '../actions';

let modalMessages = getModalMessages(),
    JobsMessages = getJobMessages();

const messages = {
	...modalMessages,
    ...JobsMessages
};

const defaultState = {
    isLoading: false,
    loadingKey: "add",
    job: { }
};

class JobModal extends React.Component  {

    constructor(props) {
		super(props);
		this.state = {...defaultState};
    }
    
    saveJob = (job) => this.setState({ job });
    getJob = () => this.state.job;
    setLoading = (isLoading) => this.setState({ isLoading });
    setLoadingKey = (loadingKey) => this.setState({ loadingKey });
	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

	handleCancel = () => {
        this.setState(defaultState);
		this.props.handleCancel();
    }

    defaultAdd = (callback) => {
        const { props, setLoading, getIntl, getJob } = this;
        const { form, addJob } = props;
        const { validateFieldsAndScroll } = form;   
        validateFieldsAndScroll({ force: true }, (err, values) => {
            if (!err) {
                const job = getJob();
                setLoading(true);
                addJob(parseJobValues(values))
                .then(() => {
                    setLoading(false);
                    message.success(getIntl("jobAddSuccess"));
                    callback(job);
                })
                .catch(() => {
                    setLoading(false);
                    message.error(getIntl("jobAddError"));
                });
            }
        });
    }

    handleAdd = () => {
        const { defaultAdd, handleCancel, setLoadingKey } = this;
        setLoadingKey("add");
        defaultAdd(handleCancel); 
    }

    handleAddAndPersist = () => {
        const { props, defaultAdd, saveJob, setLoadingKey } = this;
        const { resetFields } = props.form;
        const persistFunc = job => {
            const { type, client, programmedDate } = job;
            saveJob({ type, client, programmedDate });
            resetFields();
        }
        setLoadingKey("addPersist");
        defaultAdd(persistFunc);
    }

	renderButtons = () => {
        const { state, getIntl, handleCancel, handleAdd, handleAddAndPersist } = this;
        const { isLoading, loadingKey } = state;
        const generatePrimaryButton = (key, tooltip, onClick, text) => {
            return (
                <Tooltip key={key} placement="top" title={tooltip}>
                    <Button type="primary" onClick={onClick} loading={isLoading && loadingKey === key} disabled={isLoading && loadingKey !== key}>
                        {text}
                    </Button>
                </Tooltip>
            );
        }
        return [
            <Button key="cancel" onClick={handleCancel} disabled={isLoading}>{getIntl("cancel")}</Button>,
            generatePrimaryButton("addPersist", getIntl("addToolTip"), handleAddAndPersist, getIntl("addPersist")),
            generatePrimaryButton("add", getIntl("addCloseToolTip"), handleAdd, getIntl("addClose"))
        ];
    }
    
	render(){
        const { state, props, saveJob, renderButtons, handleCancel, getJob, setLoading, getIntl } = this;
        const { isLoading} = state;
        const { form, showModal, jobTemplate, duplicateJobId } = props;
        const job = getJob();
		return (
            <Modal
                open={showModal}
                title={getIntl("addJob")}
                onCancel={handleCancel}
                destroyOnClose={true}
                footer={renderButtons()}
				maskClosable={false}
				keyboard={!isLoading}
				closable={!isLoading}
            >
                <Spin spinning={isLoading}>
                    <JobForm form={form} jobTemplate={jobTemplate} saveJob={saveJob} job={job} setLoading={setLoading} duplicateJobId={duplicateJobId}/>
                </Spin>
            </Modal>
		);
	}
}

JobModal.defaultProps = {
    jobTemplate: { },
    duplicateJobId: 0
};

JobModal.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
    addJob: PropTypes.func.isRequired,
    
	showModal: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    jobTemplate: PropTypes.object,
    duplicateJobId: PropTypes.number
};

const mapDispatchToProps = (dispatch) => {
	return {
		addJob: (job) => dispatch(AddJob(job))
	};
};

export default connect(null, mapDispatchToProps)(injectIntl(Form.create()(JobModal)));
