import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import DescriptionsCombiner from '../DefaultViews/DescriptionsCombiner';
import { getJobMessages } from '../../../../constants/messages';
import BasicInformation from '../DefaultViews/BasicInformation';
import DeviceRender from '../DefaultViews/DeviceRender';
import { JOBSTATUS } from  '../../constants';
const { DONE, PROFORMA, INVOICED } = JOBSTATUS;

let jobMessages = getJobMessages();

const messages = {
	...jobMessages
};

class Activate extends React.Component  {

    getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    renderBasicInformation = () => {
        const { getField } = this.props;
        return <BasicInformation getField={getField} hideClientAddress hideJobDuration hideJobTechnicians/>;
    }

    renderDevice = () => {
        const device = this.props.getField("gps");
        return <DeviceRender device={device}/>
    }

    renderDone = () => {
        const { renderBasicInformation, renderDevice } = this;
        return (
            <DescriptionsCombiner>
                {renderBasicInformation()}
                {renderDevice()}
            </DescriptionsCombiner>
        );
    }

    renderBasedOnStatus = () => {
        const { props, getIntl, renderDone } = this;
        const { getField } = props;
        const status = getField("status");
        switch (status) {
            case INVOICED.id:
            case PROFORMA.id:
            case DONE.id: return renderDone();
            default: return <div>{getIntl("noStatusFormForType")}</div>;
        }
    }
    
	render(){
        return this.renderBasedOnStatus();
	}
}

Activate.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    saveFields: PropTypes.func.isRequired,
    getField: PropTypes.func.isRequired
};

export default injectIntl(Activate);