import axios from 'axios';
import { FETCH_RECEIPT_COUNT, GET_RECEIPTS } from './actionTypes';

export const GetReceiptPayment = (page = 1, pageSize= 10) => async (dispatch) =>{

	let response = await axios.get(`Payments/getReceiptPayment`, {
		params: {
			limit: pageSize,
			skip: (page - 1) * pageSize,
		}
	});
	const receipts = organizeReceipts(response.data);
	dispatch({ type: GET_RECEIPTS, receipts: receipts });
	await dispatch(getReceiptCount());
	return response.data;
};

const organizeReceipts = (receipts) => {
	const uniqueIds = new Set();
	const uniqueReceipts = receipts.filter(receipt => {
		const isDuplicate = uniqueIds.has(receipt.receiptId);
		uniqueIds.add(receipt.receiptId);
		if (!isDuplicate) return true;
		return false;
	});
	return uniqueReceipts;
};

const getReceiptCount = (paymentMethod = -1, searchText = '')=>async(dispatch)=>{

	let response = await axios.get(`Payments/getReceiptPaymentCount`,{
		params:{
			paymentMethod:paymentMethod,
			search: searchText
		}
	});
	dispatch({ type: FETCH_RECEIPT_COUNT, count: response.data[0].count });
}

export const SearchReceiptPayment = (searchObject = {}, page, pageSize) => async (dispatch) =>{
	const searchText = searchObject.searchText;
	const paymentMethod = searchObject.value;
	let response = await axios.get(`Payments/getReceiptPayment`, {
		params: {
			limit: pageSize,
			skip: (page - 1) * pageSize,
			paymentMethod: paymentMethod,
			search: searchText
		}
	});

	const receipts = organizeReceipts(response.data);
	await dispatch(getReceiptCount(paymentMethod,searchText))
	dispatch({ type: GET_RECEIPTS, receipts: receipts });
	return receipts;
};
export const PreviewReceipt = (id) => {
	return axios({
		url: `/Receipts/${id}/preview`,
		method: 'GET',
		responseType: 'blob'
	})
		.then(response => response.data);
};

export const DownloadReceipt = (id) => () => {
	return axios({
		url: `/Receipts/${id}/download`,
		method: 'GET',
		responseType: 'blob'
	})
		.then(response => {
			const arr = response.headers['cache-control'].split(',')
			const filename = arr[arr.length - 1];
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', filename);
			document.body.appendChild(link);
			link.click();
		});
};

export const GetInvoicesByReceipt = async(ReceiptId) => {

	const response = await axios.get(`Payments/getInvoicesByReceipt`, {
		params: {
			receiptId: ReceiptId
		}
	});

	return response.data;
}