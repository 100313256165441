import {
	CLEAN_REDUCER,
	CLEAR_SESSION,
	GET_INVOICES,
	GET_INVOICE,
	ADD_INVOICE_SUCCESS,
	GENERATED_INVOICES_SUCCESS,
	CREATE_PDF,
	LATEST_PAYMENT,
	DISABLE_INVOICE_SUCCESS,
	PAID_INVOICE_SUCCESS,
	OVERDUE_INVOICE_SUCCESS,
	SELECT_EMAILS_INVOICE,
	SET_INVOICE_TO_ADD,
	GET_CLIENTS_PENDING_INVOICES,
	CLEAN_CLIENTS_PENDING_INVOICES,
	TAX_RETENTION,
	GET_TAX_RETENTION,
	FETCH_INVOICE_COUNT,
	TAX_RETENTION_IMAGE,
	SAVE_ISV,
	UPDATE_SEARCH,
	UPDATE_PAGE
} from './actionTypes';

import { UPDATED_DOCUMENT_NUMBER } from '../FiscalData/actionTypes'

import { GET_EXCHANGE_RATE } from '../ExchangeRate/actionTypes';

const initState = {
	invoices: [],
	count: 0,
	invoicesIds: [],
	invoice: {},
	pendingInvoices: [],
	addedInvoice: false,
	latestPayment: {},
	lastDocNum: "",
	rate: 0,
	showEmailModal: false,
	emailsToSend: [],
	taxRetentionConstancy: {},
	taxRetentionImage: "",
	isv: 0.15,
	search: "",
	page: 1
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case UPDATE_SEARCH:
			return {
				...state,
				search: action.search
			}
		case UPDATE_PAGE:
			return {
				...state,
				page: action.page
			}
		case SAVE_ISV:
			return {
				...state,
				isv: action.isv
			};
		case GET_INVOICES:
			return {
				...state,
				invoices: action.invoices
			};
		case GET_INVOICE:
			return {
				...state,
				invoice: action.invoice
			};
		case CREATE_PDF:
			return {
				...state
			};
		case DISABLE_INVOICE_SUCCESS:
			return {
				...state
			};
		case PAID_INVOICE_SUCCESS:
			return {
				...state
			};
		case OVERDUE_INVOICE_SUCCESS:
			return {
				...state
			};
		case GET_EXCHANGE_RATE:
			return {
				...state,
				rate: action.rate
			};
		case LATEST_PAYMENT:
			return {
				...state,
				latestPayment: action.payment,
				lastDocNum: action.docNum
			};
		case ADD_INVOICE_SUCCESS:
			return {
				...state,
				addedInvoice: true,
				invoice: action.invoice
			};
		case GENERATED_INVOICES_SUCCESS:
			return {
				...state,
				invoicesIds: action.invoicesIds
			};	
		case UPDATED_DOCUMENT_NUMBER:
			return {
				...state,
				addedInvoice: false
			};	
		case SELECT_EMAILS_INVOICE:
			return {
				...state,
				emailsToSend: action.emails
			};
		case SET_INVOICE_TO_ADD:
			return {
				...state,
				invoice: action.invoice
			};	
		case GET_CLIENTS_PENDING_INVOICES:
			return {
				...state,
				pendingInvoices: action.invoices
			};	
		case CLEAN_CLIENTS_PENDING_INVOICES:
			return {
				...state,
				pendingInvoices: []
			};	
		case TAX_RETENTION:
			return {
				...state
			};
		case GET_TAX_RETENTION:
			return {
				...state,
				taxRetentionConstancy: action.taxRetentionConstancy
			};
		case TAX_RETENTION_IMAGE:
			return {
				...state,
				taxRetentionImage: action.url
			};
		case FETCH_INVOICE_COUNT:
			return {
				...state,
				count: action.count
			};
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;
