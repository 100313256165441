import React from 'react';
import PropTypes from 'prop-types';
import { message, Table, Spin, Popconfirm, Typography } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getClientMessages, getErrorMessages, getFields, getLanguageIntl, getPopConfirm, getactionMessages } from '../../../../../constants/messages';
import { GetClientProgrammedDeactivationsCount, GetClientProgrammedDeactivations, ClearClientProgrammedDeactivation } from '../../../actions';
import moment from 'moment';
import { getNestedValue } from '../../../../../utilities/util';

let clientMessages = getClientMessages(),
	intlMessages = getLanguageIntl(),
	fieldMessages = getFields(),
	actionMessages = getactionMessages(),
	popConfirmMessages = getPopConfirm(),
	errorMessages = getErrorMessages();

const messages = {
	...clientMessages,
	...intlMessages,
	...fieldMessages,
	...actionMessages,
	...popConfirmMessages,
	...errorMessages,
};

class GPDView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			disableDates: [],
			page: 1,
			pageSize: 10,
			recordCount: 0
		};
	}

	componentDidMount() {
		this.getProgrammedDeactivations();
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });

	getProgrammedDeactivations = () => {
		const { state, getIntl, setLoading } = this;
		const { page, pageSize } = state;
		const promises = [
			new Promise((resolve, reject) => {
				GetClientProgrammedDeactivationsCount()
					.then(response => resolve(response))
					.catch(() => reject(0));
			}),
			new Promise((resolve, reject) => {
				GetClientProgrammedDeactivations(page, pageSize)
					.then(response => resolve(response))
					.catch(() => reject(1));
			})
		];
		setLoading(true);
		Promise.all(promises)
			.then(data => {
				const recordCount = data[0];
				const disableDates = data[1];
				this.setState({ disableDates, recordCount, isLoading: false });
			})
			.catch(() => {
				setLoading(false);
				message.error(getIntl("getClientDeactivationsError"));
			});
	};
	
	pagingOnChange = (page, pageSize) => {
		const { getIntl, setLoading } = this;
		setLoading(true);
		GetClientProgrammedDeactivations(page, pageSize)
			.then(disableDates => this.setState({ disableDates, page, pageSize, isLoading: false }))
			.catch(() => {
				setLoading(false);
				message.error(getIntl("getClientDeactivationsError"));
			});
	};

	formatDate = (unformatedDate) => {
		if (!unformatedDate) return undefined;
		const { getIntl } = this;
		const locale = getIntl("intl");
		return moment(unformatedDate).utc().locale(locale).format('MMMM DD, YYYY');
	};

	clearClientDeactivation = (id) => {
		const { state: {disableDates}, props: {intl}, getProgrammedDeactivations } = this;
		const ClientDeactivation = disableDates.find((x) => x.clientId === id);
		const { client: {ironhideClient} } = ClientDeactivation;
		ClearClientProgrammedDeactivation(ironhideClient.id)
		.then(() => {
			getProgrammedDeactivations();
			message.success(intl.formatMessage({ ...messages.deleteDeactivationClientSuccess }));
		})
		.catch(() => {
			message.error(intl.formatMessage({ ...messages.commonError }));
		});
	};

	renderTable = () => {
		const { state, props, getIntl, formatDate, pagingOnChange, clearClientDeactivation } = this;
		const { disableDates, page, pageSize, recordCount } = state;
		const { intl } = props;
		const dataSource = disableDates.map(clientDisable => {
			const { clientId, date } = clientDisable;
			const name = getNestedValue("client.ironhideClient.name", clientDisable);
			return { key: clientId, name, date };
		});
		const pagination = {
			defaultPageSize: pageSize,
			onChange: pagingOnChange,
			total: recordCount,
			current: page
		};
		const columns = [
			{
				title: getIntl("name"),
				dataIndex: 'name',
				key: 'name'
			},
			{
				title: getIntl("date"),
				dataIndex: 'date',
				key: 'date',
				render: formatDate
			},
			{
				title: getIntl("actions"),
				dataIndex: 'key',
				render: (key) => {
					return (
						<Popconfirm
							title={intl.formatMessage({...messages.programmedDeactivationDateClearConfirm})}
							okText={intl.formatMessage({...messages.yes})}
							cancelText={intl.formatMessage({...messages.no})}
							onConfirm={() => clearClientDeactivation(key)}
						>
							<Typography.Link>
								<span className="icon-bin" />
								<FormattedMessage {...messages.delete} />
							</Typography.Link>
						</Popconfirm>
					);
				}
			}
		];
		return (
			<Table 
				dataSource={dataSource} 
				columns={columns} 
				pagination={pagination} 
				size="small" 
			/>
		)
	}

	fullRender = () => {
		const { state, renderTable } = this;
		const { isLoading } = state;
		return (
			<Spin spinning={isLoading}>
				{renderTable()}
			</Spin>
		);
	}

	render() {
		return this.fullRender();
	}
}

GPDView.propTypes = {
	intl: PropTypes.object.isRequired
};

export default injectIntl(GPDView);