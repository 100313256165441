import React, {useState} from 'react';
import { message } from 'antd';
import { useIntl} from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { getDepartmentMessages, getFields} from '../../../constants/messages';
import {GetDepartments,GetDepartmentCount,DeleteDepartments, AddDepartment, EditDepartment} from '../actions';
import CommonRender from './CommonRender';
import DepartmentForm from './DepartmentForm';
import ShowEmployeesModal from './ShowEmployeesModal';

let departmentMessages = getDepartmentMessages(),
    fieldMessages = getFields();

const messages = {
    ...departmentMessages,
    ...fieldMessages
};

const DepartmentRender = () => {
    const intl = useIntl();
    const deparments = useSelector((state)=>state.department.departments);
    const departmentCount = useSelector((state)=>state.department.departmentCount);
    const [uids,setUids] = useState([]);
    const [showEmployeesModal, setShowEmployeesModal] = useState(false);
    const search = useSelector((state) => state.department.searchText);
    const dispatch = useDispatch();
    const columns = [{
        title: intl.formatMessage( {...messages.name}),
        dataIndex: 'name',
        key: 'name'
    }]

    
    
   const deleteDepartments = async (ids) => {
        try {
            setUids(ids);
           await DeleteDepartments(ids);
        } catch (error) {
            setShowEmployeesModal(true);
            message.error(intl.formatMessage( {...messages.deleteDepartmentError}));
        }

    }

    const addDepartment = async (department) => {
        try {
            await dispatch(AddDepartment( department ));
        } catch (error) {
            message.error(intl.formatMessage( {...messages.addDepartmentError}));
        }
    }

    const editDepartment = async (department, id) => {
        try {
            await dispatch(EditDepartment( department, id ));
        } catch (error) {
            return message.error(intl.formatMessage( {...messages.editDepartmentError}));
        }
    }

   const getDepartments = async (search, page, pageSize) => {
        try {
        
            await dispatch( GetDepartments( search, page, pageSize ));
        } catch (error) {
            return message.error(intl.formatMessage( {...messages.getDepartmentsError}));
        }
    }

    const getDepartmentCount = async (search) => {
        try {
            await dispatch(GetDepartmentCount( search ));
        } catch (error) {
            return message.error(intl.formatMessage( {...messages.getDepartmentCountError}));
        }
       
    }
    const handleCancel = () => {
        setUids([]);
        setShowEmployeesModal(false);
      };
    
   return (
        <div>
            <CommonRender
                records={deparments}
                getRecords={getDepartments}
                recordCount={departmentCount}
                getRecordCount={getDepartmentCount}
                deleteRecords={deleteDepartments}
                columns={columns}
                cardBodyRender={() => { } }
                add={addDepartment}
                update={editDepartment}
                renderFormFunc={(form, record) => <DepartmentForm form={form} department={record} />}
                addSuccessMessage={intl.formatMessage({ ...messages.addDepartmentSuccess })}
                updateSuccessMessage={intl.formatMessage({ ...messages.editDepartmentSuccess })}
                searchText={search} 
           />

           <ShowEmployeesModal
               uids={uids}
               showModal={showEmployeesModal}
               closeModal={handleCancel} 
            />
        </div>

        );
}
export default DepartmentRender
