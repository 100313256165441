import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, message } from 'antd';
import { injectIntl } from 'react-intl';
import {
	getactionMessages, getAuthMessages, getModalMessages, getFields, getErrorMessages, getRegistrationMessages,
	getClientMessages, getLanguageIntl, getProductMessages
} from '../../../constants/messages';
import { connect } from 'react-redux';
import Buttons from './Buttons';
import ClientInfo from './ClientInfo';
import ApprovalForm from './ApprovalForm';
import { GetTags } from '../actions';
import { GetProducts } from '../../Products/actions';
import { ROLES } from '../../../constants/global';
import { STATUS } from '../constants';

let actionMessages = getactionMessages(),
	authMessages = getAuthMessages(),
	errorMessages = getErrorMessages(),
	fieldMessages = getFields(),
	modalMessages = getModalMessages(),
	RegistrationMessages = getRegistrationMessages(),
	ClientMessages = getClientMessages(),
	intlMessages = getLanguageIntl(),
	productMessages = getProductMessages();

const messages = {
	...actionMessages,
	...authMessages,
	...modalMessages,
	...fieldMessages,
	...errorMessages,
	...RegistrationMessages,
	...ClientMessages,
	...intlMessages,
	...productMessages
};

export const initialState = {
	rejectedMessageSet: false,
	approvingClient: false,
	approvalTypeSet: false,
	linkToExternalDb: false,
	createNewClient: false,
	loading: false,
	code: "",
};

class ViewModal extends React.Component {

	constructor(props) {
		super(props);
		this.state = { ...initialState };
	}

	componentDidMount() {
		const { intl, getTags, getProducts } = this.props;
		getTags().catch(() => {
			message.error(`${intl.formatMessage({ ...messages.GetTagsError })}`);
		});
		getProducts().catch(() => {
			message.error(`${intl.formatMessage({ ...messages.errorGetProducts })}`);
		});
	}

	handleCancel = () => {
		this.props.handleCancel();
		this.setState({ ...initialState });
	}

	getModalContent = () => {
		const { approvingClient } = this.state;
		const { form, clientInfo, tags, products } = this.props;

		if (approvingClient) return (<ApprovalForm form={form} state={this.state} setState={partialState => this.setState(partialState)} />);
		return <ClientInfo clientInfo={clientInfo} tags={tags} products={products} />;
	}

	render() {
		const { intl, form, showModal, clientInfo, userRoles } = this.props;
		const { loading, approvingClient } = this.state;
		let clientId = clientInfo.id || 0;
		let clientName = clientInfo.name || "";
		let telephones = clientInfo.telephones || [];
		let clientEmails = clientInfo.clientEmails || [];
		let status = clientInfo.status !== undefined ? clientInfo.status : STATUS.PENDING;
		let enableApprove = telephones.length > 0 && clientEmails.length > 0;
		let roles = [ROLES.MASTER, ROLES.ADMINISTRATOR];
		let isMasterAdmin = roles.some(r => userRoles.includes(r));
		let canApproveReject = status === STATUS.PENDING && isMasterAdmin && !approvingClient;
		return (
			<span>
				<Modal
					open={showModal}
					title={intl.formatMessage({ ...messages.clientView })}
					onCancel={this.handleCancel}
					destroyOnClose={true}
					loading={loading}
					footer={<Buttons form={form} state={this.state} setState={partialState => this.setState(partialState)} handleCancel={this.handleCancel}
						clientId={clientId} enableApprove={enableApprove} canApproveReject={canApproveReject} clientName={clientName} clientEmails={clientEmails} />}
				>
					{this.getModalContent()}
				</Modal>
			</span>
		);
	}
}

ViewModal.defaultProps = {
	clientInfo: {},
	tags: [],
	products: []
};

ViewModal.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	tags: PropTypes.array.isRequired,
	products: PropTypes.array.isRequired,
	getTags: PropTypes.func.isRequired,
	getProducts: PropTypes.func.isRequired,
	userRoles: PropTypes.array.isRequired,

	showModal: PropTypes.bool.isRequired,
	clientInfo: PropTypes.object,
	handleCancel: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		userRoles: state.auth.roles,
		tags: state.client.tags,
		products: state.product.products
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getTags: () => dispatch(GetTags()),
		getProducts: () => dispatch(GetProducts()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(ViewModal)));