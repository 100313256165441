import {
	CLEAN_REDUCER,
	CLEAR_SESSION,
	ADD_PAYMENT_SUCCESS,
	GET_DEPOSITORS
} from './actionTypes';

const initState = {
	payment: {},
	depositors: []
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case ADD_PAYMENT_SUCCESS:
			return {
				...state,
				payment: action.payment
			};
		case GET_DEPOSITORS:
			return { ...state, depositors: action.depositors};
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;