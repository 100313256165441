import PropTypes from 'prop-types';
import { DatePicker, Row, Col, Select, Spin, Button } from 'antd';
import { connect } from "react-redux";
import {
    injectIntl,
    FormattedMessage
} from 'react-intl';
import { getReportMessages, getInvoicingMessages, getLanguageIntl, getClipboardMessages } from '../../../constants/messages';
import { GetAccountCategories, GetEntry, FilterAllCategories } from '../../IncomeReport/actions';
import { GetAccountsSummaries } from '../actions';
import AccountList from './AccountList';
import EntryCard from '../../IncomeReport/Entries/EntryCard';
import React from 'react';
import { getCurrencyDisplayer } from '../../../utilities/util';

let reportMessages = getReportMessages(),
    languageMessages = getLanguageIntl(),
    clipboardMessages = getClipboardMessages(),
    invoicesMessages = getInvoicingMessages();

const messages = {
    ...reportMessages,
    ...languageMessages,
    ...clipboardMessages,
    ...invoicesMessages
};

const { RangePicker } = DatePicker;
const { Option } = Select;

class AccountEntriesReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingAccounts: false,
            isLoadingEntry: false,
            startDate: undefined,
            endDate: undefined,
            selectedAccounts: [0],
            categoryItems: [],
            accounts: [],
            showEntry: false,
            entry: undefined
        };
    }

    componentDidMount() {
        this.filterAllCategories("");
    }

    onChangeDate = (dates) => {
        this.setState({
            startDate: dates[0],
            endDate: dates[1]
        });
    }

    onChangeAccounts = (accounts) => {
        if (accounts.length > 1) {
            if (accounts[0] === 0)
                accounts.shift();
            if (accounts[accounts.length - 1] === 0)
                accounts = [0];
        }
        this.setState({
            selectedAccounts: accounts
        });
    }

    onSend = () => {
        this.setState({
            isLoadingAccounts: true
        });
        this.props.getAccountsSummaries(this.state.selectedAccounts, this.state.startDate, this.state.endDate).then((accountCategories) => {
            this.setState({
                accounts: accountCategories,
                isLoadingAccounts: false
            });
        });
    }

    showEntry = (entryId) => {
        if (this.state.showEntry && entryId === this.state.entry.id) {
            this.setState({
                showEntry: false,
                entry: undefined
            });
        } else {
            this.setState({
                isLoadingEntry: true
            });
            this.props.getEntry(entryId).then((entry) => {
                this.setState({
                    entry: { ...entry },
                    showEntry: true,
                    isLoadingEntry: false
                });
            });
        }
    }

    renderEntry = () => {
        if (this.state.entry === undefined)
            return <div></div>;
        let curDis = getCurrencyDisplayer(this.state.entry.bank.isDollar)
        if (this.state.showEntry && this.state.entry != undefined) {
            return (
                <div className="incomeReport">
                    <div className="entryList">
                        <EntryCard
                            entry={this.state.entry}
                            loading={this.state.isLoading}
                            currencyDisplayer={curDis}
                        />
                    </div>
                </div>
            );
        }
    }

    filterAllCategories = (input) => {
        this.props.filterAllCategories({ status: 2, searchText: input });
    }

    selectAccounts = () => {
        return (
            <Select
                showSearch
                mode="multiple"
                optionFilterProp="children"
                style={{ width: '100%' }}
                value={this.state.selectedAccounts}
                onChange={this.onChangeAccounts}
                onSearch={this.filterAllCategories}
            >
                <Option key={0} value={0}><FormattedMessage {...messages.all} /></Option>
                {
                    this.props.categories.map((cat) =>
                        <Option key={cat.id} value={cat.id}> {cat.name} </Option>
                    )
                }
            </Select>
        );
    }

    render() {
        return (
            <div className="incomeReport view" >
                <Row className="generalReport" gutter={16} type="flex" align="bottom">
                    <Col span={6}>
                        <p><FormattedMessage {...messages.month} />:</p>
                        <RangePicker onChange={this.onChangeDate} />
                    </Col>
                    <Col span={6}>
                        <p><FormattedMessage {...messages.accounts} />:</p>
                        {this.selectAccounts()}
                    </Col>
                    <Col>
                        <Button type="primary" onClick={this.onSend}><FormattedMessage {...messages.generateReport} /></Button>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Spin spinning={this.state.isLoadingAccounts}>
                            <Row type="flex" justify="center">
                                <AccountList accounts={this.state.accounts} showEntry={this.showEntry} />
                            </Row>
                        </Spin>
                    </Col>
                    <Col span={12}>
                        <Spin spinning={this.state.isLoadingEntry}>
                            {this.renderEntry()}
                        </Spin>
                    </Col>
                </Row>

            </div>
        );
    }
}

AccountEntriesReport.propTypes = {
    intl: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    getAccountCategories: PropTypes.func.isRequired,
    getAccountsSummaries: PropTypes.func.isRequired,
    getEntry: PropTypes.func.isRequired,
    filterAllCategories: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        categories: state.incomeReport.categories,
    };
}

const mapDispatchToProps = (dispatch) => ({
    getAccountCategories: () => dispatch(GetAccountCategories()),
    getAccountsSummaries: (categories, startDate, endDate) => dispatch(GetAccountsSummaries(categories, startDate, endDate)),
    getEntry: (entryId) => dispatch(GetEntry(entryId)),
    filterAllCategories: (searchData, page, pageSize) => dispatch(FilterAllCategories(searchData, page, pageSize)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AccountEntriesReport));