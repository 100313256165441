import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { getJobMessages } from '../../../../constants/messages';
import BasicInformation from '../DefaultViews/BasicInformation';
import Device from '../../AddUpdateJob/CommonComponents/Device';
import ClientKeepsDevice from '../../AddUpdateJob/CommonComponents/ClientKeepsDevice';
import UninstallSim from '../../AddUpdateJob/CommonComponents/UninstallSim';
import DeviceRender from '../DefaultViews/DeviceRender';
import DescriptionsCombiner from '../DefaultViews/DescriptionsCombiner';
import MissingWON from '../DefaultViews/MissingWON';
import { ActiveDeviceSearch } from '../../actions';
import { JOBSTATUS } from  '../../constants';
const { TODO, DOING, MISSINGWON, DONE, CANCELLED, ANNULLED, PROFORMA, INVOICED } = JOBSTATUS;

let jobMessages = getJobMessages();

const messages = {
	...jobMessages
};

class Uninstallation extends React.Component  {

    getIntl = (str, values = {}) => this.props.intl.formatMessage({...messages[str]}, values);

    renderDeviceSelector = () => {
        const { form, saveFields, getField } = this.props;
        const save = gps => saveFields({ gps });
        const gps = getField("gps");
        const clientId = getField("client.clientId");
        return (
            <Device
                form={form}
                search={(deviceName) => ActiveDeviceSearch(deviceName, clientId, 10)}
                device={gps}
                disabled={clientId === undefined}
                onChange={save}
                displayLeasedWarning
            />
        );
    }

    renderClientKeepsDevice = () => {
        const { form, saveFields, getField } = this.props;
        const save = clientKeepsDevice => saveFields({ clientKeepsDevice });
        const clientKeepsDevice = getField("clientKeepsDevice");
        const clientId = getField("client.clientId");
        return (
            <ClientKeepsDevice
                form={form}
                clientKeepsDevice={clientKeepsDevice}
                onChange={save}
                disabled={clientId === undefined}
            />
        );
    }

    renderUninstallSim = () => {
        const { form, saveFields, getField } = this.props;
        const save = uninstallSim => saveFields({ uninstallSim });
        const uninstallSim = getField("uninstallSim");
        const clientId = getField("client.clientId");
        return (
            <UninstallSim
                form={form}
                uninstallSim={uninstallSim}
                onChange={save}
                disabled={clientId === undefined}
            />
        );
    }

    renderDevice = () => {
        const device = this.props.getField("gps");
        if (device) return <DeviceRender device={device}/>
    }

    renderDoing = () => {
        const { renderTodo, renderDeviceSelector, renderClientKeepsDevice, renderUninstallSim } = this;
        return (
            <div>
                {renderTodo()}
                {renderDeviceSelector()}
                {renderClientKeepsDevice()}
                {renderUninstallSim()}
            </div>
        )
    }

    renderDone = () => {
        const { renderTodo, renderDevice } = this;
        return (
            <DescriptionsCombiner>
                {renderTodo()}
                {renderDevice()}
            </DescriptionsCombiner>
        );
    }

    renderMissingWON = () => {
        const { props, renderDone } = this;
        const { form, jobId } = props;
        return <MissingWON form={form} jobId={jobId} renderInfo={renderDone}/>;
    }

    renderTodo = () => {
        const { getField } = this.props;
        return <BasicInformation getField={getField}/>;
    }

    renderBasedOnStatus = () => {
        const { props, getIntl, renderTodo, renderDoing, renderDone, renderMissingWON } = this;
        const { getField } = props;
        const status = getField("status");
        switch (status) {
            case TODO.id: 
            case CANCELLED.id: 
            case ANNULLED.id: return renderTodo();
            case DOING.id: return renderDoing();
            case INVOICED.id:
            case PROFORMA.id:
            case DONE.id: return renderDone();
            case MISSINGWON.id: return renderMissingWON();
            default: return <div>{getIntl("noStatusFormForType")}</div>;
        }
    }
    
	render(){
        return this.renderBasedOnStatus();
	}
}

Uninstallation.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    saveFields: PropTypes.func.isRequired,
    getField: PropTypes.func.isRequired,
    jobId: PropTypes.number.isRequired,
};

export default injectIntl(Uninstallation);