import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Row, Col, Radio, Input } from 'antd';
import { Form } from '@ant-design/compatible';
import { getJobMessages, getErrorMessages } from '../../../../constants/messages';
import Address from '../CommonComponents/Address';
import Technicians from '../CommonComponents/Technicians';
import JobDate from '../CommonComponents/Date';
import Duration from '../CommonComponents/Duration';
import HolidayWarning from '../CommonComponents/HolidayWarning';
import { pastDueDate, JOBTYPES } from '../../constants';
import EmailNotification from '../CommonComponents/EmailNotification';
import SendNotifyJob from '../CommonComponents/SendNotifyJob';
const { UNINSTALLATION, REVISION } = JOBTYPES;
const moment = require('moment');

const FormItem = Form.Item;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

let jobMessages = getJobMessages(),
    errorMessages = getErrorMessages();

const messages = {
	...jobMessages,
    ...errorMessages
};

class DefaultForm extends React.Component {

    getIntl = (str) => this.props.intl.formatMessage({...messages[str]});
    
    renderAddress = () => {
        const { form, saveFields, getField } = this.props;
        const clientId = getField("client.id");
        return (
            <Address
                form={form}
                saveFields={saveFields}
                getField={getField}
                clientId={clientId}
            />
        );
    }
    
    renderTechnicians = () => {
        const { form, saveFields, getField } = this.props;
        const save = technicians => saveFields({ technicians });
        const technicians = getField("technicians");
        return (
            <Technicians
                form={form}
                technicians={technicians}
                onChange={save}
            />
        );
    }

    renderEmailNotification = () => {
        const { form, saveFields, getField } = this.props;
        const clientId = getField("client.id");
        const save = email => saveFields({ email });
        let willSendEmail = getField("willSendEmail");
        if(willSendEmail)
            return (
                <EmailNotification
                    form={form}
                    onChange={save}
                    clientId={clientId}
                />
            );
    }

    renderCarPlate = () => {
        const { props: { form, getField }, getIntl} = this;
        const { getFieldDecorator } = form;
        let willSendEmail = getField("willSendEmail");
        if(willSendEmail)
            return (
                <FormItem label={getIntl("carPlate")}>
                    {
                        getFieldDecorator('carPlate', 
                            {
                                rules: [{
                                    required: true,
                                    message: getIntl("carPlateError"), 
                                }],
                            }
                        )( <Input placeholder={getIntl("carPlate")}/>)
                    }
                </FormItem>
            );
    }

    renderWillSendEmail = () => {
        const { props: { form, saveFields, getField }, getIntl } = this;
        const save = willSendEmail => saveFields({ willSendEmail }); 
        const willSendEmail = getField("willSendEmail");
        const options = [
            <RadioButton key={0} value={false} >{getIntl("no")}</RadioButton>,
            <RadioButton key={1} value={true} >{getIntl("yes")}</RadioButton>
        ];
        return (
            <SendNotifyJob
                form={form}
                willSendEmail={willSendEmail}
                save={save}
                options={options}
            />
        );
    };
            
    renderDate = () => {
        const { form, saveFields, getField, isUpdate } = this.props;
        const save = programmedDate => saveFields({ programmedDate: programmedDate ? moment(programmedDate).toISOString() : "" });
        const programmedDate = getField("programmedDate");
        const disabled = isUpdate && pastDueDate(programmedDate);
        return (
            <JobDate
                form={form}
                programmedDate={programmedDate}
                onChange={save}
                disabled={disabled}
                isUpdate={isUpdate}
            />
        );
    }

    renderDuration = () => {
        const { form, saveFields, getField } = this.props;
        const save = duration => saveFields({ duration });
        const duration = getField("duration");
        return (
            <Duration
                form={form}
                duration={duration}
                onChange={save}
            />
        );
    }
    
    renderHolidayWarning = () => {
        const programmedDate = this.props.getField("programmedDate");
        return <HolidayWarning programmedDate={programmedDate} />;
    }

    renderCharge = () => {
        const { props: {form, type}, getIntl} = this;
        const { getFieldDecorator } = form;
        const typeToInvoice = { UNINSTALLATION: UNINSTALLATION.id, REVISION: REVISION.id };
        const options = [
            <RadioButton key={0} value={false} >{getIntl("no")}</RadioButton>,
            <RadioButton key={1} value={true} >{getIntl("yes")}</RadioButton>
        ];
        if(Object.values(typeToInvoice).includes(type))
            return (
                <FormItem label={getIntl("beCharged")}>
                    {
                        getFieldDecorator('beCharged', 
                            {
                                initialValue: false,
                                rules: [{
                                    required: true,
                                    message: getIntl("beChargedError"), 
                                }],
                            }
                        )( <RadioGroup defaultValue={false} >{options}</RadioGroup>)
                    }
                </FormItem>
            );
    };

    fullRender = () => {
        const { renderAddress, renderTechnicians, renderDate, renderDuration, renderHolidayWarning, renderCharge, renderCarPlate, renderWillSendEmail, renderEmailNotification } = this;
        return (
            <div>
                {renderAddress()}
                {renderTechnicians()}
                {renderWillSendEmail()}
                {renderEmailNotification()}
                {renderCarPlate()}
                <Row>
                    <Col xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}}>{renderDate()}</Col>
                    <Col xs={{span:24}} sm={{span:24}} md={{span:11,offset:1}} lg={{span:11,offset:1}}>{renderDuration()}</Col>
                </Row>
                {renderCharge()}
                {renderHolidayWarning()}
            </div>
        );
    }
    
	render() {
        return this.fullRender();
    }
}

DefaultForm.defaultProps = {
    isUpdate: false
};

DefaultForm.propTypes = {
	intl: PropTypes.object.isRequired,
    type: PropTypes.number,
    form: PropTypes.object.isRequired,
    saveFields: PropTypes.func.isRequired,
    getField: PropTypes.func.isRequired,
    isUpdate: PropTypes.bool.isRequired
};

export default injectIntl(DefaultForm);