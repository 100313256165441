import React from 'react';
import PropTypes from 'prop-types';
import { Select, Form } from 'antd';
import { searchInString } from '../../../../utilities/util.js';

const FormItem = Form.Item;

const FormItemSelect = ({ fieldName, label, errorMessage, initialValue, onChange, required, rules, extraRules, options, renderLabel }) => {
    const formRules = rules ? rules : [{ required: required, message: errorMessage }];
    
    if (extraRules) {
        formRules.push(extraRules);
    }
   
    return (
        <FormItem
            label={label && renderLabel ? label : null}
            name={fieldName}
            validateTrigger={['onChange']}
            initialValue={initialValue}
            rules={formRules}
        >
            <Select
                showSearch
                onChange={onChange}
                placeholder={label ? label : null}
                optionFilterProp="children"
                filterOption={(input, { label }) => searchInString(input, label)}
                dropdownMatchSelectWidth={false}
            >
                {options}
            </Select>
        </FormItem>
    );
};

FormItemSelect.defaultProps = {
    required: true,
    renderLabel: true
};

FormItemSelect.propTypes = {
    fieldName: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    label: PropTypes.string,
    errorMessage: PropTypes.string,
    initialValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    onChange: PropTypes.func,
    required: PropTypes.bool,
    rules: PropTypes.array,
    extraRules: PropTypes.array,
    options: PropTypes.array,
    renderLabel: PropTypes.bool
};

export default FormItemSelect;
