import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getAuthMessages, getModalMessages, getFields, getErrorMessages, getExchangeRateMessages} from '../../constants/messages';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Modal, InputNumber, message } from 'antd';
import {AddExchangeRate, GetExchangeRates, GetExchangeRate, AddExchangeRateLatest} from './actions';
import {injectIntl, FormattedMessage} from 'react-intl';
import {isEmpty} from '../../utilities/util';
import moment from 'moment';

const FormItem = Form.Item;

let authMessages = getAuthMessages(),
	errorMessages = getErrorMessages(),
	fieldMessages = getFields(),
	exchangeRateMessages = getExchangeRateMessages(),
	modalMessages = getModalMessages();

const messages = {
	...authMessages,
	...modalMessages,
	...fieldMessages,
	...exchangeRateMessages,
	...errorMessages
};

class AddExchangeRateForm extends React.Component  {
	constructor(props) {
		super(props);

		this.state = {
			hideInput: true,
			isLoading: false
		};
	}

	handleOk = () => {
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.setState({
					isLoading: true
				});
				let newExchangeRate = {
					...values,
					createdAt: moment(),
					accountId: this.props.user.id
				};
				this.props.addExchangeRate(newExchangeRate).then(() => {
					this.props.getExchangeRates().then(() => {
						this.props.getExchangeRate().then(() => {
							this.props.handleCancel();
							if(this.props.setExchangeRate !== undefined)
								this.props.setExchangeRate(this.props.exchangeRate.rate);
							this.setState({
								hideInput: true,
								isLoading: false
							});
						}).catch( () => {
							message.error( `${ this.props.intl.formatMessage( {...messages.errorObtainingExchangeRates}) }` );
						});
					}).catch(() => {
						message.error( `${ this.props.intl.formatMessage( {...messages.errorObtainingExchangeRates}) }` );
					});
				}).catch(() => {
					message.error( `${ this.props.intl.formatMessage( {...messages.errorNewExchangeRate}) }` );
				});
			}
		});
	};

	handleOkLatest = () => {
		this.setState({
			isLoading: true
		});

		let newExchangeRate = {
			rate: this.props.exchangeRate !== undefined ? this.props.exchangeRate.rate : 0.0000,
			createdAt: moment(),
			accountId: this.props.user.id
		};
		this.props.addExchangeRate(newExchangeRate).then(() => {
			this.props.getExchangeRates().then(() => {
				this.props.getExchangeRate().then(() => {
					this.props.handleCancel();
					if(this.props.setExchangeRate !== undefined)
						this.props.setExchangeRate(this.props.exchangeRate.rate);
					this.setState({
						hideInput: true,
						isLoading: false
					});
				}).catch( () => {
					message.error( `${ this.props.intl.formatMessage( {...messages.errorObtainingExchangeRates}) }` );
				});
			}).catch( () => {
				message.error( `${ this.props.intl.formatMessage( {...messages.errorObtainingExchangeRates}) }` );
			});
		}).catch( () => {
			message.error( `${ this.props.intl.formatMessage( {...messages.errorNewExchangeRate}) }` );
		});
	};

	beforeCancel = () => {
		this.setState({
			hideInput: true
		});
		this.props.handleCancel();
	}

	checkEmpty = () => {
		return isEmpty(this.props.exchangeRate);
	}

	buttons = () => {
		if(this.state.hideInput){
			return (
				<div>
					<Button key="submit" type="primary" size="large" loading={(this.state.isLoading)}  onClick={() => {this.setState({hideInput: false});}} className="button-spacing">
						<FormattedMessage {...messages.NewExchangeRate} />
					</Button>
					<Button key="lastsubmit" size="large" loading={this.state.isLoading} disabled={this.checkEmpty()} onClick={this.handleOkLatest} className="button-spacing">
						<FormattedMessage {...messages.UseLast} />
					</Button>
					<Button key="check" size="large" href="https://www.baccredomatic.com/es-hn" target="_blank" className="button-spacing">
						<FormattedMessage {...messages.CheckExchangeRate} />
					</Button>
					<Button key="back" size="large" onClick={this.beforeCancel}>
						<FormattedMessage {...messages.cancel} />
					</Button>
				</div>
			);
		}else{
			return (
				<div>
					<Button key="submit" type="primary" size="large" loading={this.state.isLoading} onClick={this.handleOk} className="button-spacing">
						<FormattedMessage {...messages.save} />
					</Button>
					<Button key="check" size="large" href="https://www.baccredomatic.com/es-hn" target="_blank" className="button-spacing">
						<FormattedMessage {...messages.CheckExchangeRate} />
					</Button>
					<Button key="back" size="large" onClick={this.beforeCancel} className="button-spacing">
						<FormattedMessage {...messages.cancel} />
					</Button>
				</div>
			);
		}
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		return ( 
			<Modal
				open={this.props.showModal}
				title= {this.props.intl.formatMessage( {...messages.ExchangeRate} )} 
				onOk={this.handleOk}
				onCancel={this.beforeCancel}
				confirmLoading={this.state.isLoading}
				destroyOnClose={true}
				footer={this.buttons()}
				width={700}
			>
				{!this.state.hideInput &&
					<Form layout='vertical' className="login-form col-8">
						<FormItem label={this.props.intl.formatMessage({ ...messages.Rate })}>
							{getFieldDecorator('rate', {
								rules: [{
								required: true, message: this.props.intl.formatMessage({ ...messages.exchangeRateError }),
								}],
								initialValue: this.props.exchangeRate !== undefined ? this.props.exchangeRate.rate : 0.0000
							})(
								<InputNumber step=".0001" style={{'width': '100%'}}/>
							)}
						</FormItem>
					</Form>
				}
			</Modal>
		);
	}
}

AddExchangeRateForm.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	exchangeRate: PropTypes.object,
	showModal: PropTypes.bool.isRequired,
	handleCancel: PropTypes.func.isRequired,
	addExchangeRate: PropTypes.func.isRequired,
	addExchangeRateLatest: PropTypes.func.isRequired,
	getExchangeRates: PropTypes.func.isRequired,
	getExchangeRate: PropTypes.func.isRequired,
	setExchangeRate: PropTypes.func,
	user: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
	return {
		"user": state.auth.user,
		"accounts": state.account.accounts,
		"exchangeRate": state.exchangeRate.exchangeRate,
	};
};

const mapDispatchToProps = (dispatch) => {
		return {
			addExchangeRate: (newExchangeRate) => {
				return dispatch(AddExchangeRate(newExchangeRate));
			},
			addExchangeRateLatest: (newExchangeRate) => {
				return dispatch(AddExchangeRateLatest(newExchangeRate));
			},
			getExchangeRates: () => {
				return dispatch(GetExchangeRates());
			},
			getExchangeRate: () => {
				return dispatch(GetExchangeRate());
			}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(AddExchangeRateForm)));