import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { message, Typography, Radio, Divider, Switch } from 'antd';
import { getDashboardMessages, getPopConfirm } from '../../../constants/messages';
import { GetInvoicesVsPaymentsWidgetInfo, FilterInvoicesWidgetInfo } from '../actions';
import Widget from '../Components/DefaultWidget';
import { cleanArray, displayCurrency, objectToArray, existsInArray } from '../../../utilities/util';
import Embolden from '../../GlobalComponents/Embolden';
const { Text } = Typography;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

let dashboardMessages = getDashboardMessages(),
	popConfirmMessages = getPopConfirm();

const messages = {
	...dashboardMessages,
	...popConfirmMessages
};

const InvoiceTypes = {
	ALL: {
		id: 1,
		intl: "allTogether"
	},
	SERVICE: {
		id: 2,
		intl: "servicio"
	},
	PRODUCT: {
		id: 3,
		intl: "product"
	},
	MIXED: {
		id: 4,
		intl: "mixed"
	}
};

class InvoicesVsPayments extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			currentMonthInvoicedAmount: 0,
			currentInvoicedNoTax: 0,
			currentTaxedAmount: 0,
			lastMonthInvoicedAmount: 0,
			invoicedAmounts: [],
			showCreditNotes: false,
			invoiceTypesToShow: [InvoiceTypes.ALL.id]
		};
	}

	componentDidMount() {
		const { getIntl, setLoading } = this;
		setLoading(true);
		GetInvoicesVsPaymentsWidgetInfo()
			.then(invoicedAmounts => {
				const currentMonth = invoicedAmounts.length > 0 ? invoicedAmounts[(invoicedAmounts.length - 1)] : {};
				const { allData } = currentMonth;
				const { amount: currentMonthInvoicedAmount = 0, subtotal: currentInvoicedNoTax = 0, tax: currentTaxedAmount = 0 } = allData;
				const lastMonthInvoicedAmount = invoicedAmounts.length > 1 ? invoicedAmounts[(invoicedAmounts.length - 2)].allData.amount : 0;
				this.setState({ currentMonthInvoicedAmount, currentInvoicedNoTax, currentTaxedAmount, lastMonthInvoicedAmount, invoicedAmounts, isLoading: false });
			})
			.catch(() => {
				setLoading(false);
				message.error(getIntl("getInvoicingVsPaymentsInfoError"));
			});
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	setLoading = isLoading => this.setState({ isLoading });

	filterData = (datesToLookup = []) => {
		const { getIntl, setLoading } = this;
		setLoading(true);
		FilterInvoicesWidgetInfo(datesToLookup)
			.then(invoicedAmounts => this.setState({ invoicedAmounts, isLoading: false }))
			.catch(() => {
				setLoading(false);
				message.error(getIntl("getInvoicingVsPaymentsInfoError"));
			});
	}

	formatData = () => {
		const { state, getIntl } = this;
		const { invoicedAmounts, showCreditNotes, invoiceTypesToShow } = state;
		const data = [];
		const paymentsData = [];
		const pushData = (structure, invoiceKey, date) => {
			const { amount, subtotal, tax, count, payments, paymentsAndCreditNotes } = structure;
			data.push({ amount, subtotal, tax, count, date, key: invoiceKey });
			if (showCreditNotes) paymentsData.push({ ...paymentsAndCreditNotes, date, key: getIntl("payments") });
			else paymentsData.push({ ...payments, date, key: getIntl("payments") });
		};
		if (existsInArray(InvoiceTypes.ALL.id, invoiceTypesToShow)) invoicedAmounts.forEach(({ allData, date }) => pushData(allData, getIntl("invoices"), date));
		else {
			if (existsInArray(InvoiceTypes.SERVICE.id, invoiceTypesToShow)) invoicedAmounts.forEach(({ serviceData, date }) => pushData(serviceData, getIntl("invoicesWithType", { type: getIntl("servicio") }), date));
			if (existsInArray(InvoiceTypes.PRODUCT.id, invoiceTypesToShow)) invoicedAmounts.forEach(({ productData, date }) => pushData(productData, getIntl("invoicesWithType", { type: getIntl("product") }), date));
			if (existsInArray(InvoiceTypes.MIXED.id, invoiceTypesToShow)) invoicedAmounts.forEach(({ mixedData, date }) => pushData(mixedData, getIntl("invoicesWithType", { type: getIntl("mixed") }), date));
		}
		data.push(...paymentsData);
		return data;
	}

	currentMonthPopover = () => {
		const { state, getIntl } = this;
		const { currentInvoicedNoTax, currentTaxedAmount } = state;
		return (
			<div>
				<Embolden header={getIntl("invoicedSubtotal")} value={displayCurrency(currentInvoicedNoTax)} row />
				<Embolden header={getIntl("invoicedTaxes")} value={displayCurrency(currentTaxedAmount)} row />
			</div>
		);
	}

	renderCreditNotesFilter = () => {
		const { state, getIntl } = this;
		const { showCreditNotes } = state;
		const options = [
			<RadioButton key={0} value={false} >{getIntl("no")}</RadioButton>,
			<RadioButton key={1} value={true}>{getIntl("yes")}</RadioButton>
		];
		const onChange = showCreditNotes => this.setState({ showCreditNotes });
		return (
			<span>
				<Text>{getIntl("showCreditNotes")} </Text>
				<RadioGroup size="small" value={showCreditNotes} onChange={event => onChange(event.target.value)}>{options}</RadioGroup>
			</span>
		);
	}

	onSwitchChange = (checked, typeId) => {
		const { invoiceTypesToShow } = this.state;
		const setInvoiceTypesToShow = newInvoiceTypesToShow => this.setState({ invoiceTypesToShow: newInvoiceTypesToShow });
		if ((typeId === InvoiceTypes.ALL.id && checked) || (!checked && invoiceTypesToShow.length <= 1)) setInvoiceTypesToShow([InvoiceTypes.ALL.id]);
		else if (checked) setInvoiceTypesToShow(cleanArray([...invoiceTypesToShow].concat([typeId]), InvoiceTypes.ALL.id));
		else setInvoiceTypesToShow(cleanArray(invoiceTypesToShow, typeId));
	}

	renderSwitch = ({ id: typeId, intl }) => {
		const { state, getIntl, onSwitchChange } = this;
		const { invoiceTypesToShow } = state;
		const isSwitchChecked = existsInArray(typeId, invoiceTypesToShow);
		return (
			<span key={typeId} style={{ paddingRight: '4px' }}>
				<Switch
					size="small"
					checkedChildren={getIntl(intl)}
					unCheckedChildren={getIntl(intl)}
					disabled={typeId === InvoiceTypes.ALL.id && isSwitchChecked}
					checked={isSwitchChecked}
					onChange={checked => onSwitchChange(checked, typeId)}
				/>
			</span>
		);
	}

	renderInvoiceTypeFilter = () => {
		const { getIntl, renderSwitch } = this;
		const options = objectToArray(InvoiceTypes).map(type => renderSwitch(type));
		options.splice(1, 0, <Divider key="divider" type="vertical" />);
		return (
			<span>
				<Text>{getIntl("invoiceTypesToShow")} </Text>
				{options}
			</span>
		);
	}

	renderFilters = () => {
		const { renderCreditNotesFilter, renderInvoiceTypeFilter } = this;
		return (
			<React.Fragment>
				<div>{renderCreditNotesFilter()}</div>
				<div style={{ paddingTop: "8px" }}>{renderInvoiceTypeFilter()}</div>
			</React.Fragment>
		);
	}

	graphTooltipFormatter = () => {
		const { getIntl } = this;
		const format = (date, amount, key, count, subtotal, tax) => {
			const data = {
				name: key,
				title: date,
				value: `<tr><td class="dashboard-tooltip-format">${getIntl("total")}</td><td class="dashboard-invoice-payment-tooltip-table-value dashboard-tooltip-format">${displayCurrency(amount)}</td></tr>`
			};
			if (amount !== 0) {
				data.count = `<tr><td class="dashboard-tooltip-format">${getIntl("invoices")}</td><td class="dashboard-invoice-payment-tooltip-table-value dashboard-tooltip-format">${count}</td></tr>`;
				data.subtotal = `<tr><td class="dashboard-tooltip-format">${getIntl("invoicedSubtotal")}</td><td class="dashboard-invoice-payment-tooltip-table-value dashboard-tooltip-format">${displayCurrency(subtotal)}</td></tr>`;
				data.tax = `<tr><td class="dashboard-tooltip-format">${getIntl("invoicedTaxes")}</td><td class="dashboard-invoice-payment-tooltip-table-value dashboard-tooltip-format">${displayCurrency(tax)}</td></tr>`;
			}
			return data;
		};
		return ["date*amount*key*count*subtotal*tax", format];
	}

	graphItemTplRender = () => {
		return (
			`<li>
                <span style="background-color:{color};" class="g2-tooltip-marker"></span>
                <span class="dashboard-invoice-payment-tooltip-title">{name}: </span>
                <table class="dashboard-invoice-payment-tooltip-table">
                    {subtotal}
                    {tax}
                    {value}
                    {count}
                </table>
            </li>`
		);
	}

	render() {
		const { state, getIntl, filterData, formatData, currentMonthPopover, renderFilters, graphTooltipFormatter, graphItemTplRender } = this;
		const { isLoading, currentMonthInvoicedAmount, lastMonthInvoicedAmount } = state;
		const data = formatData();
		return (
			<Widget
				isLoading={isLoading}
				title={getIntl("invoicingVsPayments")}
				statisticTitle={getIntl("thisMonthsInvoicing")}
				statisticLastValue={lastMonthInvoicedAmount}
				statisticCurrentValue={currentMonthInvoicedAmount}
				statisticIcon="L. "
				statisticCurrentValuePopover={currentMonthPopover()}
				statisticExtraContent={renderFilters()}
				graphData={data}
				dateRangeSelectFunction={filterData}
				graphComparisonBars={2}
				graphTooltipFormatter={graphTooltipFormatter}
				graphItemTpl={graphItemTplRender()}
			/>
		);
	}
}

InvoicesVsPayments.propTypes = {
	intl: PropTypes.object.isRequired
};

export default injectIntl(InvoicesVsPayments);