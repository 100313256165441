import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Badge, Popconfirm, notification, message } from 'antd';
import { connect } from 'react-redux';
import CommonTable from '../../components/CommonTable';
import ProformaFilter from './ProformaFilter';
import Mayre from 'mayre';
import { getProformaMessages, getModalMessages, getactionMessages, getErrorMessages, getLanguageIntl, getInvoicingMessages, getPopConfirm, getQuotationMessages } from '../../constants/messages';
import { roundN, existsInArray } from '../../utilities/util';
import { GetProformas, SearchProformas, ApproveProforma, SetProformaToInvoiced } from './actions';
import { GetProductsOfProforma, GetProductConfigurations } from '../Products/actions';
import { SetQuotationToInvoiced } from '../Quotations/actions';
import { GetClientInfo, UpdateNextInvoiceMonth } from '../Clients/actions';
import { GetFiscalData, EditFiscalData } from '../FiscalData/actions';
import { AddInvoice, GetLatestPayment, PrintInvoice, GetClientsPendingInvoices } from '../Invoices/actions';
import DenyProformaModal from './DenyProformaModal';
import EllipsisTooltip from '../../components/EllipsisTooltip';
import { STATUSES, TAX_TYPES } from './constants';
import TaxExemptModal from './TaxExemptModal';
import PopoverProductsTable from '../Invoices/PopoverProductsTable';
import async from 'async';
import AddObservationsModal from '../Invoices/AddObservationsModal';

let moment = require('moment');

let proformaMessages = getProformaMessages(),
	errorMessages = getErrorMessages(),
	actionMessages = getactionMessages(),
	modalMessages = getModalMessages(),
	languageMessages = getLanguageIntl(),
	invoicingMessages = getInvoicingMessages(),
	quotationMessages = getQuotationMessages(),
	popconfirmMessages = getPopConfirm();

const messages = {
	...proformaMessages,
	...modalMessages,
	...actionMessages,
	...errorMessages,
	...languageMessages,
	...invoicingMessages,
	...quotationMessages,
	...popconfirmMessages
};

const defaultAddObservationsProps = {
	showAddObservationsModal: false,
	addObservationsHandleSubmit: () => { },
	addObservationsCurrentObservations: ""
};

class Proformas extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			selectedProforma: 0,
			taxExemptionModal: false,
			proforma: {},
			showModal: false,
			...defaultAddObservationsProps
		};
	}

	componentDidMount() {
		const { props, getProgrammedProformas } = this;
		const { getFiscalData, getProductConfigurations } = props;
		getFiscalData()
			.then(() => {
				getProgrammedProformas();
				getProductConfigurations();
			});
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	getProformas = (page, pageSize) => {
		this.setState({ isLoading: true });
		this.props.getProformas(page, pageSize)
			.then(() => {
				this.setState({
					isLoading: false
				});
			})
			.catch(() => {
				message.error(`${this.props.intl.formatMessage({ ...messages.GetProformasError })}`);
				this.setState({
					isLoading: false
				});
			});
	}

	searchProformas = (page, pageSize, search) => {
		this.setState({ isLoading: true });
		this.props.searchProformas(page, pageSize, search)
			.then(() => {
				this.setState({ isLoading: false });
			})
			.catch(() => {
				message.error(`${this.props.intl.formatMessage({ ...messages.GetProformasError })}`);
				this.setState({ isLoading: false });
			});
	}

	getProgrammedProformas = () => {
		let pendingProformas = 0;
		this.props.proformas.forEach((proforma) => {
			let isWithinRange = parseInt(moment(proforma.programmedDate).format('M')) <= parseInt(moment().format('M'));
			if (proforma.status === 0 && ((proforma.isProgrammed && isWithinRange) || !proforma.isProgrammed)) {
				pendingProformas++;
			}
		})
		if (pendingProformas > 0) {
			notification.open({
				message: this.props.intl.formatMessage({ ...messages.PendingProformas }),
				description: `${this.props.intl.formatMessage({ ...messages.TheresProformas })} ${pendingProformas} ${this.props.intl.formatMessage({ ...messages.Pending })}`
			});
		}

	}

	padWithZeroes = (number) => {
		let my_string = '' + number;
		while (my_string.length < 8) {
			my_string = '0' + my_string;
		}

		return my_string;
	}

	navigate = () => {
		this.props.history.push(`/proformas/new`);
	};

	openDenyModal = (id) => {
		this.setState({
			selectedProforma: id,
			showModal: true
		});
	}

	closeDenyModal = () => {
		this.setState({
			showModal: false
		});
	}

	approveProforma = (id) => {
		this.props.approveProforma(id).then(() => {
			this.setState({
				isLoading: true
			});
			this.props.getProformas().then(() => {
				this.setState({
					isLoading: false
				});
			}).catch(() => {
				messages.error(`${this.props.intl.formatMessage({ ...messages.GetProformasError })}`);
				this.setState({
					isLoading: false
				});
			});
		}).catch(() => {
			messages.error(`${this.props.intl.formatMessage({ ...messages.GetProformasError })}`);
		});
	}

	closeModal = () => {
		this.setState({
			taxExemptionModal: false,
			isLoading: false,
			...defaultAddObservationsProps
		});
	}

	handleCreateInvoice = (proforma) => {
		const { createInvoice } = this;
		const { observations } = proforma;
		const addObservationsCurrentObservations = observations;
		if (proforma.taxExemption) {
			this.setState({
				taxExemptionModal: true,
				proforma: { ...proforma },
				addObservationsCurrentObservations
			});
		} else {
			const showAddObservationsModal = true;
			const addObservationsHandleSubmit = newObservations => {
				const proformaCopy = {
					...proforma,
					observations: newObservations
				};
				createInvoice(proformaCopy);
			};
			this.setState({ showAddObservationsModal, addObservationsCurrentObservations, addObservationsHandleSubmit });
		}
	}

	afterCreate = (proforma, month) => {
		let fiscalData = { ...this.props.fiscalData.invoice, currentRValue: this.props.fiscalData.invoice.currentRValue + 1 };
		if (proforma.quotationId !== null) {
			this.props.setQuotationToInvoiced(proforma.quotationId, this.props.invoice.id, proforma.id).catch(() => {
				message.error(`${this.props.intl.formatMessage({ ...messages.SetQuotationAsInvoicedError })}`);
			});
		}
		this.props.updateNextInvoiceMonth(proforma.clientId, month);
		this.props.editFiscalData(fiscalData).then(() => {
			this.props.printInvoice([this.props.invoice.id]).catch(() => {
				message.error(`${this.props.intl.formatMessage({ ...messages.PrintError })}`);
			});
			this.props.setProformaToInvoiced(proforma.id, this.props.invoice.id).catch(() => {
				message.error(`${this.props.intl.formatMessage({ ...messages.SetProformaAsInvoicedError })}`);
			});
			notification.open({
				message: this.props.intl.formatMessage({ ...messages.Print }),
				description: this.props.intl.formatMessage({ ...messages.ReminderPrint }),
			});
			this.setState({
				taxExemptionModal: false,
				proforma: {},
				isLoading: false
			});
			this.props.history.push(`/invoices/${this.props.invoice.id}`);
		});
	}

	checkIfMonthlyService = (products, clientId) => {
		const { productConfigurations } = this.props;
		const productConfigIds = productConfigurations.map(pc => pc.productId);
		return new Promise((resolve, reject) => {
			this.props.getClientInfo(clientId).then((info) => {
				const { nextInvoiceMonth } = info;
				let newDate = moment(nextInvoiceMonth).set({ date: 15, hour: 0, minute: 0, second: 0, millisecond: 0 });
				products.forEach(product => {
					if (existsInArray(product.productId, productConfigIds)) {
						const strParts = product.description.split(" ");
						const monthStr = strParts[strParts.length - 2] + " " + strParts[strParts.length - 1];
						const productDate = moment(monthStr, 'MMMM YYYY', 'es').set({ date: 15, hour: 0, minute: 0, second: 0, millisecond: 0 });
						if (productDate.isAfter(newDate, 'month')) newDate = productDate.add(1, 'months');
					}
				});
				resolve(newDate);
			})
				.catch(() => reject(Error(`Can't invoice`)));
		});
	}

	taxExempCreateInvoice = (values) => {
		const { state, createInvoice } = this;
		const proforma = { ...state.proforma, ...values };
		createInvoice(proforma);
	}

	createInvoice = (proforma) => {
		this.setState({ isLoading: true });
		const tasks = [
			cb => this.props.getProductsofProforma(proforma.id).then(() => cb(null)).catch(cb),
			cb => this.props.getFiscalData().then(() => cb(null)).catch(cb)
		];
		async.parallel(tasks, err => {
			if (err) {
				message.error(`${this.props.intl.formatMessage({ ...messages.AddInvoiceError })}`);
				this.setState({ isLoading: false });
				return;
			}
			const { taxReferenceExemption = "", taxReferenceExoneration = "", taxReferenceSag = "", taxType = TAX_TYPES.NORMAL } = proforma;
			let values = {
				clientId: proforma.clientId,
				accountId: this.props.user.id,
				cai: this.props.fiscalData.invoice.cai,
				InitialRValue: this.props.fiscalData.invoice.initialRValue.toString(),
				FinalRValue: this.props.fiscalData.invoice.finalRValue.toString(),
				documentNumber: this.props.fiscalData.invoice.prefix + "-" + this.padWithZeroes(this.props.fiscalData.invoice.currentRValue),
				fiscalExpiryDate: this.props.fiscalData.invoice.expiryDate,
				status: 0,
				total: proforma.total,
				createdAt: moment(),
				paymentDate: proforma.type === 2 ? moment().add(proforma.daysForPayment, 'days') : moment(),
				type: proforma.type,
				observations: proforma.observations,
				exchangeRate: proforma.exchangeRate,
				reasonAnullment: "",
				clientRtn: proforma.clientRtn,
				clientName: proforma.clientName,
				representative: proforma.representative,
				products: this.props.productsOfProforma,
				amount: 0,
				lastDocNum: "",
				address: proforma.address,
				city: proforma.city,
				state: proforma.state,
				taxReferenceExemption, taxReferenceExoneration, taxReferenceSag, taxType,
				taxExemption: proforma.taxExemption || false,
				isv: proforma.isv,
				isLps: proforma.isLps
			};
			this.props.getClientsPendingInvoices(values.clientId).then(() => {
				this.props.getLatestPayment(values.clientId).then(() => {
					if (this.props.latestPayment.balance < 0 && values.clientId !== 1 && this.props.pendingInvoices.length === 0) {
						let description = this.props.latestPayment.description === null ? "" : this.props.latestPayment;
						description += " Abonado a factura número " + values.documentNumber + ".";
						values = {
							...values,
							amount: this.props.latestPayment.balance,
							newDesc: description,
							paymentId: this.props.latestPayment.id,
							lastDocNum: this.props.lastDocNum
						};
					}
					this.checkIfMonthlyService(values.products, values.clientId).then((newMonth) => {
						this.props.addInvoice(values).then(() => {
							this.afterCreate(proforma, newMonth);
						}).catch(() => {
							message.error(`${this.props.intl.formatMessage({ ...messages.AddInvoiceError })}`);
							this.setState({
								isLoading: false
							});
						});
					}).catch(() => {
						message.error(`${this.props.intl.formatMessage({ ...messages.MonthInvoicedError })}`);
						this.setState({
							isLoading: false
						});
					})
				}).catch(() => {
					message.error(`${this.props.intl.formatMessage({ ...messages.GetLatestPaymentError })}`);
					this.setState({
						isLoading: false
					});
				});
			});
		});
	}

	render() {
		const { state, getIntl, closeModal } = this;
		const { showAddObservationsModal, isLoading, addObservationsCurrentObservations, addObservationsHandleSubmit } = state;
		const { searchText, currentPage } = this.props;
		const onProformaNumberClick = (e, proformaId) => {
			e.preventDefault();
			this.props.history.push(`/proformas/${proformaId}`);
		}
		let addProformaButton = (<span>
			<Button className="editable-add-btn" type="primary" onClick={this.navigate}>
				<PlusOutlined />
				<FormattedMessage {...messages.add} />
			</Button>
		</span>);
		let columns = [
			{
				title: this.props.intl.formatMessage({ ...messages.ProFormaNumber }),
				dataIndex: 'id',
				key: 'id',
				width: 200,
				render: (proformaId, proforma) => {
					const { proformaProducts = [] } = proforma;
					return (
						<PopoverProductsTable products={proformaProducts}>
							<a className="editable-add-btn" href={`/proformas/${proformaId}`} onClick={(e) => onProformaNumberClick(e, proformaId)}>
								{proformaId}
							</a>
						</PopoverProductsTable>
					);
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Client }),
				dataIndex: 'clientName',
				key: 'clientName',
				width: 200,
				onCell: () => {
					return {
						style: {
							whiteSpace: 'nowrap',
							maxWidth: 200,
						}
					}
				},
				render: (clientName) => {
					return (
						<EllipsisTooltip title={clientName}>{clientName}</EllipsisTooltip>
					);
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Type }),
				dataIndex: 'type',
				key: 'type',
				render: (type) => {
					if (type === 1) {
						return this.props.intl.formatMessage({ ...messages.Cash });
					} else if (type === 2) {
						return this.props.intl.formatMessage({ ...messages.Credit });
					}
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Status }),
				dataIndex: 'status',
				className: 'status',
				width: 25,
				render: (status) => {
					let color = 'processing';
					let text = this.props.intl.formatMessage({ ...messages.ReadyInvoicing });
					if (status === STATUSES.NEEDS_APPROVAL) {
						color = 'warning';
						text = this.props.intl.formatMessage({ ...messages.NeedsApproval });
					} else if (status === STATUSES.INVOICED) {
						color = 'success';
						text = this.props.intl.formatMessage({ ...messages.Invoiced });
					} else if (status === STATUSES.DENIED) {
						color = 'error';
						text = this.props.intl.formatMessage({ ...messages.Denied });
					}
					return (
						<Badge status={color} text={text} />
					);
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Total }),
				dataIndex: 'total',
				key: 'total',
				className: 'column-money',
				width: 200,
				render: (total, proforma) => {
					return (
						`L ${roundN(total * proforma.exchangeRate, 2)}`
					);
				}
			}, {
				title: this.props.statusFilter === 4 ? this.props.intl.formatMessage({ ...messages.DateProgrammed }) : this.props.intl.formatMessage({ ...messages.Date }),
				dataIndex: 'createdAt',
				key: 'createdAt',
				width: 200,
				render: (date, proforma) => {
					let formatedDate;
					if (this.props.statusFilter === 4)
						formatedDate = new Date(proforma.programmedDate);
					else
						formatedDate = new Date(date);
					formatedDate.toLocaleDateString();
					return (
						formatedDate.toLocaleDateString(this.props.intl.formatMessage({ ...messages.intl }), { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
					);
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Actions }),
				dataIndex: 'id',
				key: 'actions',
				render: (id, proforma) => {
					return (
						<span>
							<a className="editable-add-btn" onClick={() => { this.props.history.push(`/proformas/${id}`); }}>
								<FormattedMessage {...messages.View} />
							</a>
							<Mayre
								of={
									<span>
										<span> | </span>
										<Popconfirm title={this.props.intl.formatMessage({ ...messages.ApproveQ })} onConfirm={() => { this.approveProforma(id); }} okText={this.props.intl.formatMessage({ ...messages.yes })} cancelText={this.props.intl.formatMessage({ ...messages.no })}>
											<a href="#"><FormattedMessage {...messages.Approve} /></a>
										</Popconfirm>
										<span> | </span>
										<a className="editable-add-btn" onClick={() => { this.openDenyModal(id); }}>
											<FormattedMessage {...messages.Deny} />
										</a>
									</span>
								} when={proforma.status === 1 && this.props.isMaster}
							/>
							<Mayre
								of={
									<span>
										<span> | </span>
										<a className="editable-add-btn" onClick={() => { this.handleCreateInvoice(proforma); }}>
											<FormattedMessage {...messages.Invoice} />
										</a>
									</span>
								} when={proforma.status === 0}
							/>
							<Mayre
								of={
									<span>
										<span> | </span>
										<a className="editable-add-btn" onClick={() => { this.props.history.push(`/invoices/${proforma.invoiceId}`); }}>
											<FormattedMessage {...messages.ViewInvoice} />
										</a>
									</span>
								} when={proforma.status === 2}
							/>
						</span>
					);
				}
			}
		];

		return (
			<div className="account view">
				<TaxExemptModal
					showModal={this.state.taxExemptionModal}
					isLoading={this.state.isLoading}
					createInvoice={this.taxExempCreateInvoice}
					closeModal={this.closeModal}
					currentObservations={addObservationsCurrentObservations}
				/>
				<AddObservationsModal
					showModal={showAddObservationsModal}
					isLoading={isLoading}
					handleSubmit={addObservationsHandleSubmit}
					handleCancel={closeModal}
					title={getIntl("Invoice")}
					saveButtonText={getIntl("Invoice")}
					currentObservations={addObservationsCurrentObservations}
				/>
				<DenyProformaModal proformaId={this.state.selectedProforma} showModal={this.state.showModal} closeDenyModal={this.closeDenyModal} />
				<CommonTable
					columns={columns}
					dataSource={this.props.proformas}
					search={(search, page, pageSize) => this.searchProformas(page, pageSize, search)}
					searchText= {searchText}
					currentPage= {currentPage}
					recordCount={this.props.count}
					preservePage={true}
					getRecords={(page, pageSize) => this.getProformas(page, pageSize)}
					Add={addProformaButton}
					loading={this.state.isLoading}
					filterComponent={(<ProformaFilter />)}
					getCheckboxProps={(() => ({ disabled: false }))}
					hideCheckboxes
				/>
			</div>
		);
	}
}

Proformas.propTypes = {
	intl: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	isMaster: PropTypes.bool.isRequired,
	productsOfProforma: PropTypes.array.isRequired,
	latestPayment: PropTypes.object.isRequired,
	invoice: PropTypes.object.isRequired,
	addedInvoice: PropTypes.bool.isRequired,
	lastDocNum: PropTypes.string.isRequired,
	proformas: PropTypes.array.isRequired,
	fiscalData: PropTypes.object.isRequired,
	statusFilter: PropTypes.number.isRequired,
	pendingInvoices: PropTypes.array.isRequired,
	getProformas: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	searchProformas: PropTypes.func.isRequired,
	approveProforma: PropTypes.func.isRequired,
	setProformaToInvoiced: PropTypes.func.isRequired,
	getProductsofProforma: PropTypes.func.isRequired,
	getFiscalData: PropTypes.func.isRequired,
	editFiscalData: PropTypes.func.isRequired,
	getLatestPayment: PropTypes.func.isRequired,
	addInvoice: PropTypes.func.isRequired,
	printInvoice: PropTypes.func.isRequired,
	getClientsPendingInvoices: PropTypes.func.isRequired,
	getProductConfigurations: PropTypes.func.isRequired,
	productConfigurations: PropTypes.array.isRequired,
	setQuotationToInvoiced: PropTypes.func.isRequired,
	updateNextInvoiceMonth: PropTypes.func.isRequired,
	getClientInfo: PropTypes.func.isRequired,
	count: PropTypes.number.isRequired,
	searchText: PropTypes.string,
	currentPage: PropTypes.number
};

const mapStateToProps = (state) => {
	return {
		proformas: state.proforma.proformas,
		user: state.auth.user,
		isMaster: state.auth.isMaster,
		productsOfProforma: state.product.productsOfCurrentProforma,
		fiscalData: state.fiscalData.fiscalData,
		latestPayment: state.invoicing.latestPayment,
		invoice: state.invoicing.invoice,
		addedInvoice: state.invoicing.addedInvoice,
		lastDocNum: state.invoicing.lastDocNum,
		statusFilter: state.proforma.statusFilter,
		pendingInvoices: state.invoicing.pendingInvoices,
		count: state.proforma.count,
		productConfigurations: state.product.productConfigurations,
		searchText: state.proforma.search,
		currentPage: state.proforma.page,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getProformas: (page, pageSize) => {
			return dispatch(GetProformas(page, pageSize));
		},
		searchProformas: (page, pageSize, search) => {
			return dispatch(SearchProformas(page, pageSize, search));
		},
		approveProforma: (id) => {
			return dispatch(ApproveProforma(id));
		},
		setProformaToInvoiced: (id, invoiceId) => {
			return dispatch(SetProformaToInvoiced(id, invoiceId));
		},
		setQuotationToInvoiced: (id, invoiceId, proformaId) => {
			return dispatch(SetQuotationToInvoiced(id, invoiceId, proformaId));
		},
		getProductsofProforma: (id) => {
			return dispatch(GetProductsOfProforma(id));
		},
		getFiscalData: () => {
			return dispatch(GetFiscalData());
		},
		editFiscalData: (fiscalData) => {
			return dispatch(EditFiscalData(fiscalData));
		},
		getLatestPayment: (clientId) => {
			return dispatch(GetLatestPayment(clientId));
		},
		addInvoice: (newInvoice) => {
			return dispatch(AddInvoice(newInvoice));
		},
		printInvoice: (ids) => {
			return dispatch(PrintInvoice(ids));
		},
		getClientsPendingInvoices: (clientId) => {
			return dispatch(GetClientsPendingInvoices(clientId));
		},
		updateNextInvoiceMonth: (id, nextInvoiceMonth) => {
			return dispatch(UpdateNextInvoiceMonth(id, nextInvoiceMonth));
		},
		getClientInfo: (clientId) => {
			return dispatch(GetClientInfo(clientId));
		},
		getProductConfigurations: () => dispatch(GetProductConfigurations())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(Form.create()(Proformas))));
