import { ADD_MODEL_SUCCESS, EDIT_MODEL_SUCCESS, FETCH_MODEL, DELETE_MODEL_SUCCESS,SET_SEARCH_MODEL, CLEAR_SESSION } from './actionTypes';

const initState = {
	models: [],
    search: "",
};

const reducer = (state = initState, action) => {
	switch (action.type) {
        case FETCH_MODEL:
            return {
                ...state,
                models: action.models,
            };
		case ADD_MODEL_SUCCESS:
			return {
                ...state,
				models: [...state.models, action.models],
			};
        case EDIT_MODEL_SUCCESS:
            return {
                ...state,
                models: action.models,
            };
        case DELETE_MODEL_SUCCESS:
            return {
                ...state,
                models: action.models,
            };
        case SET_SEARCH_MODEL:
            return {
                ...state,
                search: action.search
            }
        case CLEAR_SESSION:
            return initState
        default:
            return state;
        }
    };
    
    export default reducer;
    