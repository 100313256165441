import React from 'react';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';

class AddButton extends React.Component {

    render() {
        const { text, addFunction, className, disabled } = this.props;
        let extraProps = {};
        if ('className' in this.props) extraProps.className = className;
        return (
            <Button {...extraProps} type="dashed" onClick={addFunction} style={{ width: '100%' }} disabled={disabled}>
                <PlusOutlined />{text}
            </Button>
        ); 
    }

}

AddButton.defaultProps = {
    disabled: false
};

AddButton.propTypes = {
    className: PropTypes.string,
	text: PropTypes.string.isRequired,
	addFunction: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
};

export default AddButton;