
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getProductMessages, getInvoicingMessages, getModalMessages, getactionMessages, getErrorMessages, getPaymentMessages, getClientMessages, getCommissionMessages } from '../../constants/messages';
import { Badge, Button, Table, Spin, message } from 'antd';
import { GetProductsOfInvoice } from '../Products/actions';
import { GetEmails } from '../Clients/actions';
import { GetInvoice, PrintInvoice, EmailInvoice, PreviewInvoice, PrintEmailedInvoice, InvoiceDevicesAreAvailableCheck, InvoiceDevicesAllHaveDescriptionsCheck, PreviewInvoiceDevicesDocument, DownloadInvoiceAndDevicesDocuments } from './actions';
import { injectIntl, FormattedMessage } from 'react-intl';
import AddPaymentModal from '../Payments/AddPaymentModal';
import EmailSelector from '../../components/EmailSelector';
import ShowInvoicingDocument from '../../components/ShowInvoicingDocument';
import { arrayElementContainedInArray, roundN } from '../../utilities/util';
import { STATUSES } from './constants';
import { TYPES } from '../Payments/constants';
import moment from 'moment';
import CommissionViewModal from '../Commissions/ViewModal';
import { CheckCommissionInvoiceEntryExistance } from '../Commissions/actions';
import SendDeviceListCheckbox from './SendDeviceListCheckbox';
import { ROLES } from '../../constants/global';
const { INITIAL_BALANCE, PAYMENT, CREDIT_NOTE, TAX_EXEMPTION, SURPLUS } = TYPES;

let invoicingMessages = getInvoicingMessages(),
	errorMessages = getErrorMessages(),
	actionMessages = getactionMessages(),
	modalMessages = getModalMessages(),
	productMessages = getProductMessages(),
	paymentMessages = getPaymentMessages(),
	clientMessages = getClientMessages(),
	commissionMessages = getCommissionMessages();

const messages = {
	...clientMessages,
	...invoicingMessages,
	...modalMessages,
	...actionMessages,
	...errorMessages,
	...productMessages,
	...paymentMessages,
	...commissionMessages
};

class ShowInvoice extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			subtotal: 0,
			showPaymentModal: false,
			showEmailModal: false,
			isLoading: false,
			showCommissionModal: false,
			currentCommissionId: 0,
			sendDevicesWithEmail: false,
			sendDevicesDisabled: false,
			showDevicesWarning: false,
		};
	}

	componentDidMount() {
		const { props, getIntl } = this;
		const { getInvoice, getProductsOfInvoice, getEmails } = props;
		const { params } = props.match;
		const promises = [
			new Promise((resolve, reject) => {
				getInvoice(params.id)
					.then(() => resolve())
					.catch(() => reject(0));
			}),
			new Promise((resolve, reject) => {
				getProductsOfInvoice(params.id)
					.then(() => resolve())
					.catch(() => reject(1));
			}),
			new Promise((resolve, reject) => {
				CheckCommissionInvoiceEntryExistance(params.id)
					.then(invoiceEntries => {
						const entry = invoiceEntries && invoiceEntries[0] ? invoiceEntries[0] : {};
						const { commissionId: currentCommissionId = 0 } = entry;
						this.setState({ currentCommissionId });
						resolve();
					})
					.catch(() => reject(2));
			}),
			new Promise((resolve, reject) => {
				InvoiceDevicesAreAvailableCheck(params.id)
					.then(areAvailable => {
						this.setState({ sendDevicesDisabled: !areAvailable });
						resolve();
					})
					.catch(() => reject(3));
			}),
			new Promise((resolve, reject) => {
				InvoiceDevicesAllHaveDescriptionsCheck(params.id)
					.then(allDescriptionsAvailable => {
						this.setState({ showDevicesWarning: !allDescriptionsAvailable });
						resolve();
					})
					.catch(() => reject(3));
			})
		];
		this.setState({ isLoading: true });
		Promise.all(promises)
			.then(() => {
				getEmails(this.props.invoice.clientId)
					.then(() => this.setState({ isLoading: false }))
					.catch(() => {
						message.error(getIntl("getClientEmailsError"));
						this.setState({ isLoading: false });
					});
			})
			.catch(errorNumber => {
				this.setState({ isLoading: false });
				switch (errorNumber) {
					case 0:
						message.error(getIntl("GetInvoiceError"));
						break;
					case 1:
						message.error(getIntl("GetProductsOfInvoiceError"));
						break;
					case 2:
						message.error(getIntl("loadCommissionError"));
						break;
					case 3:
						message.error(getIntl("loadInvoiceDevicesInfoError"));
						break;
					default:
						message.error(getIntl("common"));
						break;
				}
			});
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	openPaymentModal = () => {
		this.setState({
			showPaymentModal: true
		});
	}

	closePaymentModal = () => {
		this.setState({
			showPaymentModal: false
		});
	}

	openEmailModal = () => {
		this.setState({
			showEmailModal: true
		});
	}

	closeEmailModal = () => {
		this.setState({
			showEmailModal: false
		});
	}

	paymentTypesMessages = (type) => {
		const { getIntl } = this;
		switch (type) {
			case INITIAL_BALANCE.id:
				return getIntl(INITIAL_BALANCE.intl);
			case PAYMENT.id:
				return getIntl(PAYMENT.intl);
			case CREDIT_NOTE.id:
				return getIntl(CREDIT_NOTE.intl);
			case TAX_EXEMPTION.id:
				return getIntl(TAX_EXEMPTION.intl);
			case SURPLUS.id:
				return getIntl(SURPLUS.intl);
			default:
				return "";
		}
	}

	renderPaymentsTable = () => {
		let ds = this.props.invoice.payments !== undefined ? this.props.invoice.payments : [];
		let dataSource = ds.map((object) => {
			return {
				...object,
				'key': ds.indexOf(object)
			};
		});
		let columns = [
			{
				title: this.props.intl.formatMessage({ ...messages.Type }),
				dataIndex: 'type',
				key: 'type',
				width: 150,
				render: (type) => {
					return (this.paymentTypesMessages(type));
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Amount }),
				dataIndex: 'amount',
				key: 'amount',
				className: 'column-money',
				width: 150,
				render: (amount) => {
					return (
						`L ${roundN(amount, 2)}`
					);
				}
			}, {
				title: 'Balance',
				dataIndex: 'balance',
				key: 'balance',
				className: 'column-money',
				width: 150,
				render: (balance) => {
					return (
						`L ${roundN(balance, 2)}`
					);
				}
			}, {
				title: this.props.intl.formatMessage({ ...messages.Date }),
				dataIndex: 'createdAt',
				key: 'createdAt',
				width: 150,
				render: (date) => {
					return (
						moment(date).locale('es').format('l')
					);
				}

			}, 
			{
				title: this.props.intl.formatMessage({ ...messages.DestinationAccount }),
				dataIndex: 'destination',
				key: 'destination',
				width: 200,
				render: (destination) => {
					if(destination){
						return destination.name;
					}
				}
			},
			{
				title: this.props.intl.formatMessage({ ...messages.Description }),
				dataIndex: 'description',
				key: 'description',
				render: (description) => {
					return (
						description
					);
				}
			}
		];

		return (
			<div style={{ clear: "both" }}>
				<br />
				<h2><FormattedMessage {...messages.Movements} /></h2>
				<Table dataSource={dataSource} columns={columns} pagination={false} size="small" />
			</div>
		);
	}

	cancelledReason = () => {
		if (this.props.invoice.status === STATUSES.ANNULLED) {
			return (
				<React.Fragment>
					<h3><FormattedMessage {...messages.Reason} /></h3>
					{this.props.invoice.reasonAnnullment}
				</React.Fragment>
			);
		}
	}

	renderStatus = (status) => {
		const { state, props, getIntl } = this;
		const { currentCommissionId } = state;
		const { isMaster, intl, invoice } = props;
		let color = 'default';
		let text = intl.formatMessage({ ...messages.Active });
		if (status === STATUSES.PAID) {
			color = 'success';
			text = intl.formatMessage({ ...messages.Paid });
		} else if (status === STATUSES.OVERDUE) {
			color = 'warning';
			text = intl.formatMessage({ ...messages.Overdue });
		} else if (status === STATUSES.ANNULLED) {
			color = 'error';
			text = intl.formatMessage({ ...messages.Annulled });
		}
		if (currentCommissionId) return (
			<h1>
				<FormattedMessage {...messages.InvoiceNum} /> {invoice.documentNumber} | <Badge status={color} text={text} /> |&nbsp;
				{isMaster ?
					<a onClick={() => this.setState({ showCommissionModal: true })}>
						{`${getIntl("commission")}: ${currentCommissionId}`}
					</a> :
					`${getIntl("commission")}: ${currentCommissionId}`
				}

			</h1>
		);
		return <h1><FormattedMessage {...messages.InvoiceNum} /> {invoice.documentNumber} | <Badge status={color} text={text} /></h1>;
	}

	renderSendDeviceListField = () => {
		const { state } = this;
		const { sendDevicesWithEmail, showDevicesWarning, sendDevicesDisabled } = state;
		const toggleCheckbox = checked => this.setState({ sendDevicesWithEmail: checked });
		return (
			<SendDeviceListCheckbox
				disabled={sendDevicesDisabled}
				checkboxState={sendDevicesWithEmail}
				triggerCheckbox={toggleCheckbox}
				showWarning={showDevicesWarning}
			/>
		);
	}

	downloadPreview = () => {
		const { state, props } = this;
		const { sendDevicesWithEmail } = state;
		const { id: invoiceId } = props.match.params;
		if (!sendDevicesWithEmail) return () => PreviewInvoice(invoiceId);
		const promises = [
			new Promise((resolve, reject) => {
				PreviewInvoice(invoiceId)
					.then(invoicePreview => resolve(invoicePreview))
					.catch(() => reject());
			}),
			new Promise((resolve, reject) => {
				PreviewInvoiceDevicesDocument(invoiceId)
					.then(invoiceDevicesPreview => resolve(invoiceDevicesPreview))
					.catch(() => reject());
			})
		];
		return () => Promise.all(promises);
	}

	downloadDocument = () => {
		const { state, props } = this;
		const { sendDevicesWithEmail } = state;
		const { id: invoiceId } = props.match.params;
		if (!sendDevicesWithEmail) return () => PrintEmailedInvoice(invoiceId);
		return () => DownloadInvoiceAndDevicesDocuments(invoiceId);
	}

	emailDocument = (id, emails, replyTo, message) => {
		const { state, props } = this;
		const { sendDevicesWithEmail } = state;
		const { emailInvoice } = props;
		return emailInvoice(id, emails, replyTo, message, sendDevicesWithEmail);
	}

	renderExtraButtons = () => {
		const { props, getIntl, openPaymentModal } = this;
		const { invoice = {}, userRoles } = props;
		const { payments = [], id: invoiceId, status = -1 } = invoice;
		const editableStatus = [STATUSES.ACTIVE, STATUSES.OVERDUE];
		const allowedRoles = [ROLES.MASTER, ROLES.INVOICEEDITOR];
		const showEditButton = arrayElementContainedInArray(allowedRoles, userRoles) && payments.length === 1 && editableStatus.includes(status)
		const extraButtons = []
		if ((userRoles.includes(ROLES.ADMINISTRATOR) || userRoles.includes(ROLES.MASTER)) && status != STATUSES.PAID) {
			extraButtons.push(
				<Button key="Open" size="large" onClick={openPaymentModal} className="button-spacing">
					{getIntl("AddPayment")}
				</Button>
			);
		}
		
		if (showEditButton){
			extraButtons.push(
				<Button 
					key="edit" 
					size="large" 
					onClick={() => this.props.history.push(`/invoices/${invoiceId}/edit`)} 
					className="button-spacing"
				>
					{getIntl("edit")}
				</Button>
			);
		} 
		return extraButtons;
	}

	render() {
		const { state, renderSendDeviceListField, downloadPreview, downloadDocument, emailDocument, renderExtraButtons } = this;
		const { currentCommissionId, showCommissionModal } = state;
		let lastPayment = this.props.invoice.payments !== undefined ? this.props.invoice.payments[this.props.invoice.payments.length - 1] : { balance: 0 };
		let extraButtons = renderExtraButtons();
		let extraInfo = this.renderPaymentsTable();
		return (
			<Spin spinning={this.state.isLoading}>
				<div style={{ padding: '25px' }}>
					<CommissionViewModal commissionId={currentCommissionId} showModal={showCommissionModal} handleCancel={() => this.setState({ showCommissionModal: false })} />
					<EmailSelector
						addOrShow={false}
						showEmailModal={this.state.showEmailModal}
						closeModal={this.closeEmailModal}
						document={this.props.invoice}
						emailDocument={emailDocument}
						downloadPreview={downloadPreview()}
						downloadDocument={downloadDocument()}
						extraComponents={renderSendDeviceListField()}
					/>
					<AddPaymentModal
						invoice={this.props.invoice}
						lastPayment={lastPayment}
						exchangeRate={this.props.invoice.exchangeRate}
						showModal={this.state.showPaymentModal}
						closeModal={this.closePaymentModal}
					/>
					<ShowInvoicingDocument
						document={this.props.invoice}
						products={this.props.productsOfCurrentInvoice}
						// deniedStatuses={[3]}
						renderStatus={this.renderStatus}
						cancelledReason={this.cancelledReason}
						openEmailModal={this.openEmailModal}
						printDocument={this.props.printInvoice}
						goTo={() => this.props.history.push(`/invoices`)}
						extraButtons={extraButtons}
						extraInfo={extraInfo}
						messages={messages}
					/>
				</div>
			</Spin>
		);
	}
}

ShowInvoice.propTypes = {
	intl: PropTypes.object.isRequired,
	productsOfCurrentInvoice: PropTypes.array.isRequired,
	user: PropTypes.object.isRequired,
	invoice: PropTypes.object.isRequired,
	match: PropTypes.shape({
		params: PropTypes.object.isRequired
	}).isRequired,
	getProductsOfInvoice: PropTypes.func.isRequired,
	getInvoice: PropTypes.func.isRequired,
	printInvoice: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	getEmails: PropTypes.func.isRequired,
	isMaster: PropTypes.bool.isRequired,
	userRoles: PropTypes.array.isRequired,
	emailInvoice: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		invoice: state.invoicing.invoice,
		productsOfCurrentInvoice: state.product.productsOfCurrentInvoice,
		isMaster: state.auth.isMaster,
		userRoles: state.auth.roles
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getProductsOfInvoice: (invoiceId) => {
			return dispatch(GetProductsOfInvoice(invoiceId));
		},
		getInvoice: (id) => {
			return dispatch(GetInvoice(id));
		},
		printInvoice: (ids) => {
			return dispatch(PrintInvoice(ids));
		},
		emailInvoice: (id, emails, replyTo, message, includeDevices) => {
			return dispatch(EmailInvoice(id, emails, replyTo, message, includeDevices));
		},
		getEmails: (id) => {
			return dispatch(GetEmails(id));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(ShowInvoice)));