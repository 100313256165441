import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { getJobMessages } from '../../../../constants/messages';
import { ActiveDeviceSearch, SearchAvailableSimCards } from '../../actions';
import BasicInformation from '../DefaultViews/BasicInformation';
import MissingWON from '../DefaultViews/MissingWON';
import DeviceRender from '../DefaultViews/DeviceRender';
import DescriptionsCombiner from '../DefaultViews/DescriptionsCombiner';
import Device from '../../AddUpdateJob/CommonComponents/Device';
import Sim from '../../AddUpdateJob/CommonComponents/Sim';
import Description from '../../AddUpdateJob/CommonComponents/Description';
import Icon from '../../AddUpdateJob/CommonComponents/Icon';
import DeviceAccessoriesIntermediator from '../../AddUpdateJob/CommonComponents/DeviceAccessoriesIntermediator';
import { getNestedValue } from '../../../../utilities/util';
import { JOBSTATUS } from  '../../constants';
const { TODO, DOING, MISSINGWON, DONE, CANCELLED, ANNULLED, PROFORMA, INVOICED } = JOBSTATUS;

let jobMessages = getJobMessages();

const messages = {
	...jobMessages
};

class Transfer extends React.Component  {

    componentDidUpdate(prevProps) {
        const { isUpdate, saveFields, getField } = this.props;
        if (isUpdate !== prevProps.isUpdate && isUpdate) {
            const description = getField("swapData.gps.description");
            const sim = getField("swapData.gps.sim");
            const icon = getField("swapData.gps.icon");
            saveFields({ description, sim, icon });
        }
    }

    getIntl = (str, values = {}) => this.props.intl.formatMessage({...messages[str]}, values);

    renderTodo = () => {
        const { getField } = this.props;
        return <BasicInformation getField={getField}/>;
    }

    renderDeviceSelector = () => {
        const { form, saveFields, getField, isUpdate } = this.props;
        const { resetFields } = form;
        const save = gps => {
            const description = getNestedValue("description", gps);
            const sim = getNestedValue("sim", gps);
            const icon = getNestedValue("icon", gps);
            saveFields({ gps, description, sim, icon });
            resetFields(["description", "sim", "icon"]);
        }
        const gps = getField(isUpdate ? "swapData.gps" : "gps");
        const clientId = getField("client.clientId");
        return (
            <Device
                form={form}
                search={(deviceName) => ActiveDeviceSearch(deviceName, clientId, 10)}
                device={gps}
                disabled={clientId === undefined || isUpdate}
                onChange={save}
            />
        );
    }

    renderSimSelector = () => {
        const { form, saveFields, getField, isUpdate } = this.props;
        const save = sim => saveFields({ sim });
        const sim = getField("sim");
        const clientId = getField("client.clientId");
        const assignedSim = getField(isUpdate ? "swapData.gps.sim" : "gps.sim");
        return (
            <Sim
                form={form}
                search={(simName) => SearchAvailableSimCards(simName, 10)}
                sim={sim}
                disabled={clientId === undefined}
                onChange={save}
                otherIncludedSims={assignedSim}
            />
        );
    }

    renderIconPicker = () => {
        const { form, saveFields, getField } = this.props;
        const save = icon => saveFields({ icon });
        const icon = getField("icon.id");
        const clientId = getField("client.id");
        return (
            <Icon
                form={form}
                icon={icon}
                disabled={clientId === undefined}
                onChange={save}
            />
        );
    }

    renderDescription = () => {
        const { form, saveFields, getField } = this.props;
        const save = description => saveFields({ description });
        const description = getField("description");
        const clientId = getField("client.id");
        return (
            <Description
                form={form}
                description={description}
                disabled={clientId === undefined}
                onChange={save}
            />
        );
    }

    renderDeviceAccessories = () => {
        const { form, saveFields, getField, isUpdate } = this.props;
        return (
            <DeviceAccessoriesIntermediator
                form={form}
                isUpdate={isUpdate}
                getField={getField}
                saveFields={saveFields}
                gpsFieldName={isUpdate ? "swapData.gps" : "gps"}
            />
        );
    }

    renderDoing = () => {
        const { renderTodo, renderDeviceSelector, renderSimSelector, renderIconPicker, renderDescription, renderDeviceAccessories } = this;
        return (
            <div>
                {renderTodo()}
                {renderDeviceSelector()}
                {renderSimSelector()}
                {renderIconPicker()}
                {renderDescription()}
                {renderDeviceAccessories()}
            </div>
        )
    }

    renderMissingWON = () => {
        const { props, renderDone } = this;
        const { form, jobId } = props;
        return <MissingWON form={form} jobId={jobId} renderInfo={renderDone}/>;
    }

    renderOldDeviceInfo = () => {
        const { props, getIntl } = this;
        const device = props.getField("gps");
        if (device) return <DeviceRender device={device} header={getIntl("oldDeviceInfo")} hideImei hideSim hideAccessories/>
    }

    renderCurrentDeviceInfo = () => {
        const { props, getIntl } = this;
        const device = props.getField("swapData.gps");
        if (device) return <DeviceRender device={device} header={getIntl("currentDeviceInfo")}/>
    }

    renderDone = () => {
        const { renderTodo, renderOldDeviceInfo, renderCurrentDeviceInfo } = this;
        return (
            <DescriptionsCombiner>
                {renderTodo()}
                {renderOldDeviceInfo()}
                {renderCurrentDeviceInfo()}
            </DescriptionsCombiner>
        );
    }

    renderBasedOnStatus = () => {
        const { props, getIntl, renderTodo, renderMissingWON, renderDone, renderDoing } = this;
        const { getField, isUpdate } = props;
        if (isUpdate) return renderDoing(); 
        const status = getField("status");
        switch (status) {
            case TODO.id: 
            case CANCELLED.id: 
            case ANNULLED.id: return renderTodo();
            case DOING.id: return renderDoing();
            case INVOICED.id:
            case PROFORMA.id:
            case DONE.id: return renderDone();
            case MISSINGWON.id: return renderMissingWON();
            default: return <div>{getIntl("noStatusFormForType")}</div>;
        }
    }
    
	render(){
        return this.renderBasedOnStatus();
	}
}

Transfer.defaultProps = {
    isUpdate: false
};

Transfer.propTypes = {
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    saveFields: PropTypes.func.isRequired,
    getField: PropTypes.func.isRequired,
    isUpdate: PropTypes.bool.isRequired,
    jobId: PropTypes.number.isRequired,
};

export default injectIntl(Transfer);