import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { getReportMessages, getPaymentMessages } from '../../../../constants/messages';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select } from 'antd';
import { getPaymentMethodsList } from '../../../Payments/constants';
const { Option } = Select;

let reportMessages = getReportMessages(),
	paymentMessages = getPaymentMessages();

const messages = {
	...reportMessages,
	...paymentMessages
};

const FormItem = Form.Item;

class PaymentMethod extends React.Component {

	getIntl = (str) => this.props.intl.formatMessage({ ...messages[str] });

	render() {
		const { props, getIntl } = this;
		const { getFieldDecorator } = props.form;
		const options = getPaymentMethodsList().map(({ id, intl }) => <Option key={id} value={id}>{getIntl(intl)}</Option>);
		return (
			<FormItem label={getIntl("TypeOfPayment")} >
				{
					getFieldDecorator("paymentMethod")(
						<Select className="job-full-component" placeholder={getIntl("TypeOfPayment")} dropdownMatchSelectWidth={false} allowClear>
							{options}
						</Select>
					)
				}
			</FormItem>
		);
	}
}

PaymentMethod.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired
};

export default injectIntl(PaymentMethod);