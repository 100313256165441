import axios from 'axios';
import moment from 'moment';
import { STATUS } from '../Clients/constants';

export const SearchDevices = (deviceName, limit = 10) => {
	let regx = "%" + deviceName + "%";
	let filter = {
		limit: limit,
		fields: ["id", "clientId", "pin", "imei", "description"],
		include: [{ 
			relation: "client", 
			scope: { 
				fields: ["id"], 
				include: [{ 
					relation: "ironhideClient", 
					scope: { 
						fields: ["isDisabled"] 
					} 
				}] 
			} 
		}],
		where: {
			and: [
				{
					or: [
						{ pin: { like: regx } },
						{ imei: { like: regx } },
						{ description: { like: regx } }
					]
				}
			]
		}
	};
	return axios.get(`/Devices?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			return response.data;
		});
};

export const GetDeviceInformation = (deviceId) => {
	let filter = {
		fields: ["id", "pin", "description", "imei", "active", "clientId", "iconId", "simId", "modelProfileId"],
		include: [
			"icon",
			{
				relation: "client",
				scope: {
					fields: ["id", "name"],
					include: [{
						relation: "ironhideClient",
						scope: { fields: ["id", "name", "isDisabled"] }
					}]
				}
			},
			{
				relation: "sim",
				scope: {
					fields: ["id", "line", "serial", "carrierId"],
					include: [{
						relation: "carrier",
						scope: { fields: ["id", "description"] }
					}]
				}
			},
			{
				relation: "modelProfile",
				scope: {
					fields: ["id", "description", "deviceModelId"],
					include: [{
						relation: "deviceModel",
						scope: { fields: ["id", "description"] }
					}]
				}
			}
		]
	};
	return axios.get(`/Devices/${deviceId}?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			return response.data;
		});
};

export const SearchSimCards = (simName, limit = 10) => {
	let regx = "%" + simName + "%";
	let filter = {
		limit: limit,
		fields: ["id", "line"],
		where: { line: { like: regx } }
	};
	return axios.get(`/Sims?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			return response.data;
		});
};

export const GetSimCardInformation = (simId) => {
	let filter = {
		fields: ["id", "line", "serial", "carrierId"],
		include: [
			{
				relation: "devices",
				scope: {
					fields: ["id", "pin", "description", "imei", "active", "iconId", "simId", "clientId", "modelProfileId"],
					include: [
						{
							relation: "icon"
						},
						{
							relation: "client",
							scope: {
								fields: ["id", "name"],
								include: [{
									relation: "ironhideClient",
									scope: { fields: ["id", "name"] }
								}]
							}
						},
						{
							relation: "modelProfile",
							scope: {
								fields: ["id", "description", "deviceModelId"],
								include: [{
									relation: "deviceModel",
									scope: { fields: ["id", "description"] }
								}]
							}
						}
					]
				}
			},
			{
				relation: "carrier",
				scope: { fields: ["id", "description"] }
			}
		]
	};
	return axios.get(`/Sims/${simId}?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			return response.data;
		});
};

export const SearchClients = (clientName, limit = 10) => {
	let regx = "%" + clientName + "%";
	let filter = {
		limit: limit,
		fields: ["id", "name", "isDisabled", "clientId"],
		where: {
			and: [
				{ name: { like: regx } },
				{ status: STATUS.APPROVED }
			]
		}
	};
	return axios.get(`/Clients?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			return response.data;
		});
};

export const GetClientDevices = (clientId, page = 1, pageSize = 10) => {
	let filter = {
		limit: pageSize,
		skip: (page - 1) * pageSize,
		where: { clientId },
		fields: ["id", "pin", "description", "imei", "active", "clientId", "iconId", "simId"],
		include: [
			"icon",
			{
				relation: "sim",
				scope: {
					fields: ["id", "line", "serial", "carrierId"],
					include: [{
						relation: "carrier",
						scope: { fields: ["id", "description"] }
					}]
				}
			}
		]
	};
	return axios.get(`/Devices?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			return response.data;
		});
};

export const GetClientDeviceCount = (clientId) => {
	let where = { clientId };
	return axios.get(`/Devices/count?where=${encodeURIComponent(JSON.stringify(where))}`)
		.then((response) => {
			return response.data.count;
		});
};

export const DownloadDevicesClient = (client) => {
	let params = { clientId: client.id };
	return axios({
		url: '/Devices/DownloadDevicesClient',
		method: 'POST',
		responseType: 'blob',
		data: params,
	}).then((response) => {
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `Dispositivos de ${client.name}-${moment().format('DD/MM/YYYY hh:mm A')}.xlsx`);
		document.body.appendChild(link);
		link.click();
	});
};

export const DownloadAllDevices = () => {
	return axios({
		url: '/Devices/DownloadAllDevices',
		method: 'POST',
		responseType: 'blob',
	}).then((response) => {
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `Reporte de Dispositivos -${moment().format('DD/MM/YYYY hh:mm A')}.xlsx`);
		document.body.appendChild(link);
		link.click();
	});
};
