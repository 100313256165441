import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Modal, message, Popconfirm } from 'antd';
import { connect } from "react-redux";
import { injectIntl } from 'react-intl';
import React from 'react';
import { getReportMessages, getIncomeMessages, getPopConfirm } from '../../../constants/messages';
import { GetAccountEntries } from '../actions';
import BalanceSheetForm from './balanceSheetForm';

var moment = require('moment');
let reportMessages = getReportMessages(),
    incomeMessages = getIncomeMessages(),
    popConfirmMessages = getPopConfirm();

const messages = {
    ...reportMessages,
    ...incomeMessages,
    ...popConfirmMessages
};

class BalanceSheetModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            triggerPopconfirm: false,
            visiblePopconfirm: false,
            date1: undefined,
            date2: undefined
        }
    }

    componentDidMount() {
    }

    setLoading = (isLoading) => {
        this.setState({ isLoading });
    }

	getIntl = (str) => {
		return this.props.intl.formatMessage({...messages[str]});
    }
    
    getReplacedIntl = (str, values) => {
		return this.props.intl.formatMessage({...messages[str]}, values);
    }

    getModalTitle = () => {
        if (this.props.bank === undefined)
            return `${this.props.intl.formatMessage({ ...messages.balanceSheetMonth })}`;
        let bankName = this.props.bank ? `: ${this.props.bank.name}` : "";
        return `${this.props.intl.formatMessage({ ...messages.balanceSheetMonth })} ${bankName}`;
    }

    amountValidator = (rule, value, callback) => {
        if (!value > 0)
            callback(this.props.intl.formatMessage({ ...messages.invalidAmount }));
        callback();
    }

    disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }

    submit = () => {
        const { props, setLoading, getIntl, cancel } = this;
        const { handleSubmit, bank, accountId, form } = props;
        form.validateFields({"force":true},(err, values) => {
			if (!err) {
                const { balanceMonth } = values;
                setLoading(true);
                handleSubmit(bank.id, balanceMonth, accountId)
                .then(() => cancel())
                .catch(() => {
                    setLoading(false);
                    message.error(getIntl("generateBalanceSheetError"));
                });
			}
		});
    }

    cancel = () => {
        this.setState({ 
            isLoading: false,
            triggerPopconfirm: false,
            visiblePopconfirm: false,
            date1: undefined,
            date2: undefined
        });
        this.props.handleCancel();
    }

    popconfirmVisible = (visiblePopconfirm) => {
        this.setState({ visiblePopconfirm });
    }

    handleVisibleChange = (visible) => {
        const { state, popconfirmVisible, submit } = this;
        const { triggerPopconfirm } = state;
        if (!visible) { popconfirmVisible(visible); return; }
        if (triggerPopconfirm) popconfirmVisible(visible);
        else submit();
    }

    renderButtons = () => {
        const { state, getIntl, submit, handleVisibleChange, popconfirmVisible, cancel, getReplacedIntl } = this;
        const { isLoading, visiblePopconfirm, date1, date2 } = state;
        const saveButton = (
            <Popconfirm
                key="submit"
                title={getReplacedIntl("balanceSheetAutogenerateRange", { date1, date2 })}
                open={visiblePopconfirm}
                onOpenChange={handleVisibleChange}
                onConfirm={submit}
                onCancel={() => popconfirmVisible(false)} 
                okText={getIntl("accept")}
                cancelText={getIntl("cancel")}
            >
                <Button type="primary" size="default" loading={isLoading}>{getIntl("accept")}</Button>
            </Popconfirm>
        );
        return [
            <Button loading={isLoading} key="cancel" type="default" size="default" onClick={cancel}>
                {getIntl("cancel")}
            </Button>,
            saveButton
        ];
    }

    render() {
        const { props, getModalTitle, renderButtons, cancel } = this;
        const { showModal, form, bank } = props;
        return (
            <Modal
                title={getModalTitle()}
                destroyOnClose
                open={showModal}
                maskClosable={false}
                onCancel={cancel}
                footer={renderButtons()}
            >
                <BalanceSheetForm
                    bank={bank}
                    form={form}
                    setPopconfirmTrigger={(triggerPopconfirm) => this.setState({ triggerPopconfirm })}
                    setDates={(date1, date2) => this.setState({ date1, date2 })}
                />
            </Modal>
        );
    }
}

BalanceSheetModal.propTypes = {
    intl: PropTypes.object.isRequired,
    bank: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    showModal: PropTypes.bool.isRequired,
    accountId: PropTypes.number.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        accountId: state.auth.user.id,
    };
}

const mapDispatchToProps = (dispatch) => ({
    getAccountEntries: (id, page) => dispatch(GetAccountEntries(id, page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(BalanceSheetModal)));

