import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Select, InputNumber } from 'antd';
import { getCommissionMessages, getLanguageIntl, getFields, getInvoicingMessages } from '../../constants/messages';
import { LoadCommissionPercent, LoadCommissionProductInfo } from './actions';

const FormItem = Form.Item;
const { Option } = Select;

let intlMessages = getLanguageIntl(),
	fieldMessages = getFields(),
	invoicingMessages = getInvoicingMessages(),
	commissionMessages = getCommissionMessages();

const messages = {
	...intlMessages,
	...fieldMessages,
	...commissionMessages,
	...invoicingMessages
};

class Configurations extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			commissionPercent: 0,
			products: []
		};
	}

	componentDidMount() {
		const { props, getIntl } = this;
		const { setLoading } = props;
		const promises = [
			new Promise((resolve, reject) => {
				LoadCommissionPercent()
					.then(response => resolve(response))
					.catch(() => reject(0));
			}),
			new Promise((resolve, reject) => {
				LoadCommissionProductInfo()
					.then(response => resolve(response))
					.catch(() => reject(1));
			})
		];
		setLoading(true);
		Promise.all(promises)
			.then(data => {
				const commissionPercent = data[0];
				const products = data[1];
				this.setState({ commissionPercent, products });
				setLoading(false);
			})
			.catch(errorNumber => {
				setLoading(false);
				switch (errorNumber) {
					case 0:
						message.error(getIntl("loadCommissionPercentError"));
						break;
					case 1:
						message.error(getIntl("loadCommissionProductInfoError"));
						break;
					default:
						message.error(getIntl("common"));
						break;
				}
			});
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	renderPercentInputNumber = () => {
		const { state, props, getIntl } = this;
		const { commissionPercent } = state;
		const { getFieldDecorator } = props.form;
		const initialValue = commissionPercent * 100;
		return (
			<FormItem label={getIntl("commissionPercent")} >
				{
					getFieldDecorator(`commissionPercent`,
						{
							initialValue,

						}
					)(
						<InputNumber
							className="job-full-component"
							min={0}
							max={100}
							precision={0}
							step={1}
							formatter={value => `${value}%`}
							parser={value => value.replace('%', '')}
						/>
					)
				}
			</FormItem>
		);
	}

	renderCommissionableProductPicker = () => {
		const { state, props, getIntl } = this;
		const { products } = state;
		const { getFieldDecorator } = props.form;
		const options = products.map(({ id: productId, code, description }) => <Option key={productId} value={productId}>{`${code} - ${description}`}</Option>);
		const initialValue = [];
		products.forEach(product => {
			const { id: productId, isCommissionable } = product;
			if (isCommissionable) initialValue.push(productId);
		});
		return (
			<FormItem label={getIntl("commissionableProducts")}>
				{
					getFieldDecorator(`isCommissionable`,
						{
							initialValue
						}
					)(
						<Select
							className="job-full-component"
							mode="multiple"
							maxTagCount={3}
							showSearch
							optionFilterProp="children"
							filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							notFoundContent={getIntl("notFound")}
							dropdownMatchSelectWidth={false}
						>
							{options}
						</Select>
					)
				}
			</FormItem>
		);
	}

	renderCommissionCustomPriceProductPicker = () => {
		const { state, props, getIntl } = this;
		const { products } = state;
		const { getFieldDecorator } = props.form;
		const options = products.map(({ id: productId, code, description }) => <Option key={productId} value={productId}>{`${code} - ${description}`}</Option>);
		const initialValue = [];
		products.forEach(product => {
			const { id: productId, commissionCustomPrice } = product;
			if (commissionCustomPrice) initialValue.push(productId);
		});
		return (
			<FormItem label={getIntl("commissionCustomPriceProducts")}>
				{
					getFieldDecorator(`commissionCustomPrice`,
						{
							initialValue
						}
					)(
						<Select
							className="job-full-component"
							mode="multiple"
							maxTagCount={3}
							showSearch
							optionFilterProp="children"
							filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							notFoundContent={getIntl("notFound")}
							dropdownMatchSelectWidth={false}
						>
							{options}
						</Select>
					)
				}
			</FormItem>
		);
	}

	fullRender = () => {
		const { renderPercentInputNumber, renderCommissionableProductPicker, renderCommissionCustomPriceProductPicker } = this;
		return (
			<div>
				{renderPercentInputNumber()}
				{renderCommissionableProductPicker()}
				{renderCommissionCustomPriceProductPicker()}
			</div>
		);
	}

	render() {
		return this.fullRender();
	}
}

Configurations.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	setLoading: PropTypes.func.isRequired
};

export default injectIntl(Configurations);