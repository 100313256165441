import React from 'react';
import PropTypes from 'prop-types';
import '@ant-design/compatible/assets/index.css';
import { Form } from '@ant-design/compatible';
import { message, Table, Button, Progress, Col, Row, Badge, Spin, Input, Checkbox, Modal } from 'antd';
import { WarningOutlined, DownloadOutlined } from '@ant-design/icons';
import { injectIntl } from 'react-intl';
import { getInvoicingMessages, getModalMessages, getLanguageIntl } from '../../../constants/messages';
import InvoiceSearchSelect from './InvoiceSearchSelect';
import { GetInvoiceRange, SendInvoiceToClient } from '../actions';
import { getNestedValue, roundN, substituteObjectInArrayByValue } from '../../../utilities/util';
import moment from 'moment';
import { STATUSES } from '../constants';
import EllipsisTooltip from '../../../components/EllipsisTooltip';
import async from 'async';
import XLSX from 'xlsx'
const FormItem = Form.Item;
const { TextArea } = Input;

let invoicingMessages = getInvoicingMessages(),
	modalMessages = getModalMessages(),
	languageMessages = getLanguageIntl();

const messages = {
	...invoicingMessages,
	...modalMessages,
	...languageMessages
};

class BatchEmailSendForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			invoices: [],
			sentEmails: 0,
			showWarningModal: false,
		};
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });
	addSentEmail = () => this.setState({ sentEmails: this.state.sentEmails + 1 });

	getInvoiceRange = () => {
		const { props, getIntl, setLoading } = this;
		const { getFieldValue } = props.form;
		const fromInvoice = getFieldValue('fromInvoice');
		const toInvoice = getFieldValue('toInvoice');
		if (!fromInvoice || !toInvoice) return;
		if (fromInvoice > toInvoice) {
			this.setState({ invoices: [] });
			message.error(getIntl("fromInvoiceOlderThanToInvoiceError"));
		}
		setLoading(true);
		GetInvoiceRange(fromInvoice, toInvoice)
			.then(invoices => {
				setLoading(false);
				const invoiceSetup = invoices.map(invoice => ({ ...invoice, key: invoice.id, emailStatus: 'default', emailMessage: getIntl("invoiceReadyToBeSent") }))
				this.setState({ invoices: invoiceSetup, sentEmails: 0 });
				if (invoiceSetup.length >= 500) message.warning(getIntl("batchInvoicesAbove500Error"));
			})
			.catch(() => {
				setLoading(false);
				message.error(getIntl("searchInvoicesError"));
			});
	}

	handleSend = () => {
		const { state, props, getIntl, addSentEmail } = this;
		const { invoices } = state;
		const { form, setSending } = props;
		const { getFieldValue } = form;
		const emailMessage = getFieldValue('message');
		const includeDevices = getFieldValue('includeDevices');
		this.setState({ sentEmails: 0 });
		const dataCopy = Array.from(invoices);
		setSending(true);
		const tasks = [];
		dataCopy.forEach((invoice, index) => {
			const { id: invoiceId } = invoice;
			tasks.push(cb => {
				this.setState({ invoices: substituteObjectInArrayByValue("id", invoiceId, this.state.invoices, { ...invoice, emailStatus: 'processing', emailMessage: getIntl("sendingInvoice") }) });
				SendInvoiceToClient(invoiceId, emailMessage, includeDevices)
					.then(() => {
						this.setState({ invoices: substituteObjectInArrayByValue("id", invoiceId, this.state.invoices, { ...invoice, emailStatus: 'success', emailMessage: getIntl("invoiceSent") }) });
					})
					.catch(error => {
						const statusCode = getNestedValue("response.data.error.statusCode", error)
						if (statusCode === 404) this.setState({ invoices: substituteObjectInArrayByValue("id", invoiceId, this.state.invoices, { ...invoice, emailStatus: 'error', emailMessage: getIntl("invoiceSendNoClientEmailsError") }) });
						else this.setState({ invoices: substituteObjectInArrayByValue("id", invoiceId, this.state.invoices, { ...invoice, emailStatus: 'error', emailMessage: getIntl("invoiceSendError") }) });
					})
					.finally(() => {
						addSentEmail();
						cb(null);
					});
			});
			if (index < dataCopy.length - 1) {
				const randomDelay = Math.random() * 2000; // random 0 - 2 second delay
				tasks.push(cb => {
					setTimeout(function () {
						cb(null);
					}, randomDelay);
				});
			}
		});
		async.series(tasks, () => setSending(false));
	}

	downloadLogs = (downloadAll = false) => {
		const { invoices } = this.state;
		const logs = [];
		invoices.forEach(invoice => {
			const { id: invoiceId, documentNumber, clientName, emailStatus, emailMessage } = invoice;
			const log = {
				'Factura ID': invoiceId,
				'Factura Núm.': documentNumber,
				'Cliente': clientName,
				'Estado del correo': emailMessage
			};
			if (downloadAll) logs.push(log);
			else if (emailStatus === 'error') logs.push(log);
		});
		const fileName = 'Registros de envío de correo electrónico por lotes';
		const wb = XLSX.utils.book_new();
		wb.Props = { Title: fileName };
		wb.SheetNames.push("Registros");
		const ws = XLSX.utils.json_to_sheet(logs);
		wb.Sheets["Registros"] = ws;
		var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
		function s2ab(s) {
			var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
			var view = new Uint8Array(buf);  //create uint8array as viewer
			for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
			return buf;
		}
		const url = window.URL.createObjectURL(new Blob([s2ab(wbout)], { type: "application/octet-stream" }));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `${fileName}.xlsx`);
		document.body.appendChild(link);
		link.click();
	}

	renderButtons = () => {
		const { state, props, getIntl, downloadLogs } = this;
		const { invoices, sentEmails } = state;
		const { isSending, handleCancel } = props;
		const margin = "0.5em";
		return (
			<div className="buttons-align-right">
				<Button
					className={sentEmails <= 0 || isSending ? 'hide-component' : ''}
					style={{ marginTop: margin, marginRight: margin }}
					onClick={() => downloadLogs(true)}
					key="download-all"
					disabled={isSending}
					icon={<DownloadOutlined />}
				>
					{getIntl("downloadAllLogs")}
				</Button>
				<Button
					className={sentEmails <= 0 || isSending ? 'hide-component' : ''}
					style={{ marginTop: margin, marginRight: margin }}
					onClick={() => downloadLogs(false)}
					key="download-errors"
					disabled={isSending}
					icon={<DownloadOutlined />}
				>
					{getIntl("downloadErrorLogs")}
				</Button>
				<Button
					style={{ marginTop: margin, marginRight: margin }}
					onClick={handleCancel}
					key="cancel"
					disabled={isSending}
				>
					{getIntl("cancel")}
				</Button>
				<Button
					style={{ marginTop: margin }}
					type="primary"
					onClick={() => this.setState({ showWarningModal: true })}
					key="send"
					loading={isSending}
					disabled={invoices.length < 1}
				>
					{getIntl("send")}
				</Button>
			</div>
		);
	}

	renderFromInvoice = () => {
		const { props, getIntl, getInvoiceRange } = this;
		const { form, isSending } = props;
		return <InvoiceSearchSelect form={form} label={getIntl('fromThisInvoice')} fieldName='fromInvoice' onSelect={getInvoiceRange} disabled={isSending} />
	}

	renderToInvoice = () => {
		const { props, getIntl, getInvoiceRange } = this;
		const { form, isSending } = props;
		return <InvoiceSearchSelect form={form} label={getIntl('toThisInvoice')} fieldName='toInvoice' onSelect={getInvoiceRange} disabled={isSending} />
	}

	renderInvoiceSelectors = () => {
		const { renderFromInvoice, renderToInvoice } = this;
		return (
			<Row gutter={16}>
				<Col span={12}>{renderFromInvoice()}</Col>
				<Col span={12}>{renderToInvoice()}</Col>
			</Row>
		);
	}

	renderInvoiceTable = () => {
		const { state, getIntl } = this;
		const { invoices, isLoading } = state;
		const dataSource = invoices.map(invoice => ({ ...invoice, key: invoice.id }))
		const columns = [
			{
				title: getIntl("InvoiceNumber"),
				dataIndex: 'documentNumber',
				width: 200,
			},
			{
				title: getIntl("Client"),
				dataIndex: 'clientName',
				width: 200,
				onCell: () => ({
					style: {
						whiteSpace: 'nowrap',
						maxWidth: 200,
					}
				}),
				render: (clientName) => <EllipsisTooltip title={clientName}>{clientName}</EllipsisTooltip>
			},
			{
				title: getIntl("Status"),
				dataIndex: 'status',
				width: 100,
				render: (status) => {
					let color = 'default';
					let text = this.props.intl.formatMessage({ ...messages.Active });
					if (status === STATUSES.PAID) {
						color = 'success';
						text = this.props.intl.formatMessage({ ...messages.Paid });
					} else if (status === STATUSES.OVERDUE) {
						color = 'warning';
						text = this.props.intl.formatMessage({ ...messages.Overdue });
					} else if (status === STATUSES.ANNULLED) {
						color = 'error';
						text = this.props.intl.formatMessage({ ...messages.Annulled });
					}
					return (
						<Badge status={color} text={text} />
					);
				}
			},
			{
				title: getIntl("Date"),
				dataIndex: 'createdAt',
				width: 100,
				render: date => moment(date).locale('es').format('L')
			},
			{
				title: getIntl("Total"),
				width: 125,
				render: ({ total, exchangeRate }) => `L ${roundN(total * exchangeRate, 2)}`
			},
			{
				title: getIntl("emailStatus"),
				render: ({ emailStatus, emailMessage }) => {
					if (emailStatus === 'processing') return <span><Badge status={emailStatus} text={emailMessage} /> <Spin size="small" /></span>;
					return <Badge status={emailStatus} text={emailMessage} />;
				}
			},
		];
		return <Table columns={columns} dataSource={dataSource} pagination={false} scroll={{ y: 400 }} loading={isLoading} />;
	}

	renderProgressBar = () => {
		const { invoices, sentEmails } = this.state;
		const percent = Math.round(((100 * sentEmails) / invoices.length + Number.EPSILON) * 100) / 100;
		return (
			<div style={{ paddingTop: '8px', paddingBottom: '8px' }}>
				<Progress percent={percent} status={sentEmails && invoices.length === sentEmails ? "success" : "active"} />
			</div>
		);
	}

	renderMessageInput = () => {
		const { props, getIntl } = this;
		const { form, isSending } = props;
		const { getFieldDecorator } = form;
		return (
			<FormItem label={getIntl('Message')}>
				{
					getFieldDecorator('message',
						{
							initialValue: getIntl("monthlyInvoiceMessage", { date: moment().locale(getIntl("intl")).format("MMMM YYYY") }),
						}
					)(
						<TextArea disabled={isSending} />
					)
				}
			</FormItem>
		);
	}

	renderIncludeDevicesCheckbox = () => {
		const { props, getIntl } = this;
		const { form, isSending } = props;
		const { getFieldDecorator } = form;
		return (
			<FormItem>
				{
					getFieldDecorator('includeDevices',
						{
							initialValue: true,
							valuePropName: 'checked',
						}
					)(
						<Checkbox disabled={isSending}>{getIntl("includeDeviceListQuestion")}</Checkbox>
					)
				}
			</FormItem>
		);
	}

	renderMessageAndDeviceCheckbox = () => {
		const { renderMessageInput, renderIncludeDevicesCheckbox } = this;
		return (
			<Row gutter={16}>
				<Col span={16}>{renderMessageInput()}</Col>
				<Col span={8}>{renderIncludeDevicesCheckbox()}</Col>
			</Row>
		);
	}

	renderWarningModal = () => {
		const { state, getIntl, handleSend } = this;
		const { showWarningModal } = state;
		const onCancel = () => this.setState({ showWarningModal: false });
		const onConfirm = () => {
			onCancel();
			handleSend();
		}
		const footer = [
			<Button key="cancel" onClick={onCancel}>{getIntl("cancel")}</Button>,
			<Button key="send" type="primary" onClick={onConfirm}>{getIntl("send")}</Button>
		];
		return (
			<Modal
				open={showWarningModal}
				title={null}
				onCancel={onCancel}
				footer={footer}
			>
				<Row gutter={16}>
					<Col span={5}><WarningOutlined style={{ fontSize: '75px' }} /></Col>
					<Col span={19}>{getIntl("batchInvoiceWarning")}</Col>
				</Row>
			</Modal>
		)
	}

	fullRender = () => {
		const { renderButtons, renderInvoiceSelectors, renderInvoiceTable, renderProgressBar, renderMessageAndDeviceCheckbox, renderWarningModal } = this;
		return (
			<div>
				{renderWarningModal()}
				{renderInvoiceSelectors()}
				{renderMessageAndDeviceCheckbox()}
				{renderProgressBar()}
				{renderInvoiceTable()}
				{renderButtons()}
			</div>
		);
	}

	render() {
		return this.fullRender();
	}
}

BatchEmailSendForm.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	isSending: PropTypes.bool.isRequired,
	setSending: PropTypes.func.isRequired,
	handleCancel: PropTypes.func.isRequired,
};

export default injectIntl(Form.create()(BatchEmailSendForm));