import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, message } from 'antd';
import { useIntl } from 'react-intl';
import { getModalMessages, getEmployeeMessages } from '../../../../constants/messages';
import Deduction from './DeductionForm';
import { getObjectInArray } from '../../../../utilities/util';


let modalMessages = getModalMessages(),
    employeeMessages = getEmployeeMessages();

const messages = {
	...modalMessages,
	...employeeMessages
};

const DeductionModal = ({isUpdate, addDeduction, editDeduction, showModal, deduction, deductionOptions, allDeductions, handleCancel}) => {
    const intl = useIntl();
    const [form] = Form.useForm();

	const handleOk = async () => {
        try {
            const values = await form.validateFields(); 
            const { deductionId, initialDate, finalDate } = values;
            const deduction = getObjectInArray('id', parseInt(deductionId), allDeductions);
            const newDeduction = { 
                ...values,
                deduction,
                initialDate: initialDate.toISOString(),
                finalDate: finalDate ? finalDate.toISOString() : null
            };
            isUpdate === false ? addDeduction(newDeduction) : editDeduction(newDeduction);
            handleCancel();
        } catch (error) {
            message.error(intl.formatMessage({...messages.deductionError}));
        }
	}

  return (
    <Modal
        open={showModal}
        title={isUpdate ? intl.formatMessage({...messages.editDeduction}) : intl.formatMessage({...messages.addDeduction})}
        onOk={handleOk}
        okText={intl.formatMessage({...messages.save})}
        onCancel={handleCancel}
        cancelText={intl.formatMessage({...messages.cancel})}
        destroyOnClose={true}
    >
        <Deduction form={form} deduction={deduction} deductionOptions={deductionOptions} />
    </Modal>
  )
};

DeductionModal.defaultProps = {
	isUpdate: false,
	showModal: false,
	deduction: {},
    deductionOptions: []
};

DeductionModal.propTypes = {
	isUpdate: PropTypes.bool.isRequired,
    addDeduction: PropTypes.func.isRequired,
    editDeduction:PropTypes.func.isRequired,
	showModal: PropTypes.bool.isRequired,
	deduction: PropTypes.object.isRequired,
    deductionOptions: PropTypes.array.isRequired,
    allDeductions: PropTypes.array.isRequired,
	handleCancel: PropTypes.func.isRequired
};

export default DeductionModal
