import React from 'react';
import PropTypes from 'prop-types';
import {getInvoicingMessages} from '../../constants/messages';
import { DatePicker, Select, Checkbox } from 'antd';
import {injectIntl, FormattedMessage} from 'react-intl';
import { STATUSES } from './constants';

let InvoicingMessages = getInvoicingMessages();

const messages = {
    ...InvoicingMessages
};

const Option = Select.Option;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

class DebitNotesFilter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			status: 2,
			startDate: undefined,
			endDate: undefined,
			totalRange: false
		};
	}
	
    onChangeSelect = (e) => {
		this.setState({
			status: e
		});
		let filter = {
			...this.state,
			status: e
		};
		this.props.onFilter(filter);
	}
	
	onChangeRange = (e) => {
		this.setState({
			startDate: e[0],
			endDate: e[1]
		});
		let filter = {
			...this.state,
			startDate: e[0],
			endDate: e[1]
		};
		this.props.onFilter(filter);
	}

	onChangeCheckBox = () => {
		this.setState({
			totalRange: !this.state.totalRange
		});
		let filter = {
			...this.state,
			totalRange: !this.state.totalRange
		};
		this.props.onFilter(filter);
	}

	render(){
		return(
			<div style={{align: 'right'}}>
				<RangePicker format={dateFormat} onChange={this.onChangeRange} style={{width: '35%', 'marginRight': '6px'}}/>
				<Select onChange={this.onChangeSelect} defaultValue={2} style={{width: '25%', 'marginRight': '6px'}}>
					<Option key={2} value={2}><FormattedMessage {...messages.All} /></Option>
					<Option key={STATUSES.PENDING} value={STATUSES.PENDING}><FormattedMessage {...messages.Active} /></Option>
					<Option key={STATUSES.PAID} value={STATUSES.PAID}><FormattedMessage {...messages.Paid} /></Option>
				</Select>
				<Checkbox onChange={this.onChangeCheckBox}><FormattedMessage {...messages.ExactTotal} /></Checkbox>
			</div>
			
		);
	}
}

DebitNotesFilter.propTypes = {
	onFilter: PropTypes.func,
};

export default injectIntl(DebitNotesFilter);
