import {
	FETCH_COMMISSIONS,
	FETCH_COMMISSION_COUNT
} from './actionTypes';

import {
	CLEAR_SESSION,
	CLEAN_REDUCER
} from '../../constants/actionTypes';

const initState = {
	commissions: [],
	count: 0
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case FETCH_COMMISSIONS:
			return {
				...state,
				commissions: action.commissions
			};
		case FETCH_COMMISSION_COUNT:
			return {
				...state,
				count: action.count
			};
		case CLEAN_REDUCER:
			return initState;
		case CLEAR_SESSION:
			return initState;
		default:
			return state;
	}
};

export default reducer;