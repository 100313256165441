import React from 'react';
import PropTypes from 'prop-types';
import { message, Typography , Col, Row, Input} from 'antd';
import { injectIntl } from 'react-intl';
import { getClientMessages } from '../../../../constants/messages';
import Entry from './Entry';
import { GetClientLeasedDevices, SwitchDeviceLease } from '../../actions';
import DualDragger from './DualDragger';

const { Title } = Typography;
const { Search } = Input;

let clientMessages = getClientMessages();

const messages = {
	...clientMessages
};

class LeasedDevicesForm extends React.Component  {

	constructor(props) {
		super(props);
		this.state = {
			monthlyDevices: [],
            leasedDevices: [],
            routeDevices: [],
            reserves1: [],
            reserves2: [],
            reserves3: []
        };
	}

	componentDidMount () {
        const { props, getIntl, setData } = this;
        const { setLoading, clientId, errorClose } = props;
        const forceClose = () => {
            setLoading(false);
            message.error(getIntl("leasedDevicesLoadError"));
            errorClose();
        }
        setLoading(true);
        GetClientLeasedDevices(clientId)
        .then((response) => {
            setLoading(false);
            if (response === undefined) forceClose();
            else {
                const { devices } = response.client;
                const monthlyDevices = [], leasedDevices = [], routeDevices = [];
                devices.forEach(device => {
                    const copy = { ...device, show: true, isLoading: false };
                    if (device.deviceLease) {
                        leasedDevices.push(copy);
                    } else if (device.application === 'ROUTES') {
                        routeDevices.push(copy);
                    } else {
                        monthlyDevices.push(copy);
                    }
                });
                setData(monthlyDevices, leasedDevices, routeDevices);
            }
        })
        .catch(() => forceClose());
    }

	getIntl = (str) => {
		return this.props.intl.formatMessage({...messages[str]});
    }
    
    getData = () => {
        const { monthlyDevices, leasedDevices, routeDevices } = this.state;
        return { monthlyDevices, leasedDevices, routeDevices };
    }

    setData = (monthlyDevices, leasedDevices, routeDevices) => {
        this.setState({
            monthlyDevices: monthlyDevices || this.state.monthlyDevices,
            leasedDevices: leasedDevices || this.state.leasedDevices,
            routeDevices: routeDevices || this.state.routeDevices
        });
    }
    
    getReserves = () => {
        const { reserves1, reserves2, reserves3 } = this.state;
        return { reserves1, reserves2, reserves3 };
    }

    setReserves = (reserves1, reserves2, reserves3) => {
        this.setState({
            reserves1: reserves1 || this.state.reserves1,
            reserves2: reserves2 || this.state.reserves2,
            reserves3: reserves3 || this.state.reserves3
        });
    }

    changeLease = (deviceId, shouldBeLeased) => {
        const { getIntl, setData, getData } = this;
        const switchDeviceLoading = (isLoading) => {
            const allData = getData();
            const { monthlyDevices, leasedDevices } = allData;
            const copy = Array.from(shouldBeLeased ? leasedDevices : monthlyDevices);
            for (let index = 0; index < copy.length; index++) {
                const dev = copy[index];
                if (dev.id === deviceId) copy[index].isLoading = isLoading;
            }
            if (shouldBeLeased) setData(null, copy);
            else setData(copy, null);
        }
        const restoreDevice = () => {
            const allData = getData();
            const { monthlyDevices, leasedDevices } = allData;
            const reverseIt = (source, destination, update) => {
                const sCopy = Array.from(source);
                const dCopy = Array.from(destination);
                for (let index = 0; index < source.length; index++) {
                    const dev = {...source[index], isLoading: false};
                    if (dev.id === deviceId) {
                        dCopy.push(dev);
                        sCopy.splice(index, 1);
                    }
                }
                update(sCopy, dCopy);
            }
            if (shouldBeLeased) reverseIt(leasedDevices, monthlyDevices, (copy1, copy2) => setData(copy2, copy1));
            else reverseIt(monthlyDevices, leasedDevices, (copy1, copy2) => setData(copy1, copy2));
        }
        SwitchDeviceLease(deviceId, shouldBeLeased)
        .then(() => switchDeviceLoading(false))
        .catch(() => {
            message.error(getIntl("leasedDevicesStateChangeError"));
            restoreDevice();
        });
    }

    renderDualDragger = () => {
        const { getData, setData, changeLease } = this;
        const allData = getData();
        const { monthlyDevices, leasedDevices, routeDevices } = allData;
        return (
            <DualDragger
                data={monthlyDevices}
                data2={leasedDevices}
                data3={routeDevices}
                dataUpdate={setData}
                changeLease={changeLease}
                entryRender={(device, index) => <Entry device={device} index={index}/>}
            />
        );
    }

    renderTitles = () => {
        const { state, getIntl } = this;
        const { monthlyDevices, leasedDevices, routeDevices } = state;
        return (
            <Row>
                <Col span={6} offset={1}>
                    <Title className="client-dual-dragger-header" level={4}>
                        {getIntl("monthlyPayments")}{` (${monthlyDevices.length})`}
                    </Title>
                </Col>
                <Col span={6} offset={2}>
                    <Title className="client-dual-dragger-header" level={4}>
                        {getIntl("leases")}{` (${leasedDevices.length})`}
                    </Title>
                </Col>
                <Col span={6} offset={2}>
                    <Title className="client-dual-dragger-header" level={4}>
                        {getIntl("routes")}{` (${routeDevices.length})`}
                    </Title>
                </Col>
            </Row>
        )
    }

    search = (arr, value, update) => {
        const regex = new RegExp(value, 'i');
        const copy = [];
        const reserves = [];
        for (let index = 0; index < arr.length; index++) {
            const device = arr[index];
            const { pin, description } = device;
            if (regex.test(pin)) copy.push(device);
            else if (regex.test(description)) copy.push(device);
            else reserves.push(device);
        }
        update(copy, reserves);
    }

    searchNonLeased = (value) => {
        const { setData, getData, search, getReserves, setReserves } = this;
        const allData = getData();
        const allReserves = getReserves();
        const { monthlyDevices } = allData;
        const { reserves1 } = allReserves;
        const combined = [...monthlyDevices, ...reserves1];
        search(combined, value, (copy, reserves) => { 
            setData(copy, null, null); 
            setReserves(reserves, null, null);
        });
    }
    
    searchLeased = (value) => {
        const { setData, getData, search, getReserves, setReserves } = this;
        const allData = getData();
        const allReserves = getReserves();
        const { leasedDevices } = allData;
        const { reserves2 } = allReserves;
        const combined = [...leasedDevices, ...reserves2];
        search(combined, value, (copy, reserves) => { 
            setData(null, copy, null); 
            setReserves(null, reserves, null); 
        });
    }

    searchRoute = (value) => {
        const { getData, search, getReserves, setReserves } = this;
        const allData = getData();
        const allReserves = getReserves();
        const { routeDevices } = allData;
        const { reserves3 } = allReserves;
        const combined = [...routeDevices, ...reserves3];
        search(combined, value, (copy, reserves) => { 
            this.setData(null, null, copy); 
            setReserves(null, null, reserves); 
        });
    }

    renderSearchBoxes = () => {
        const { getIntl, searchLeased, searchNonLeased, searchRoute } = this;
        return (
            <Row>
                <Col span={6} offset={1}>
                    <Search 
                        className="client-dual-dragger-search" 
                        placeholder={getIntl("deviceSearch")} 
                        onSearch={searchNonLeased}
                    />
                </Col>
                <Col span={6} offset={2}>
                    <Search 
                        className="client-dual-dragger-search" 
                        placeholder={getIntl("deviceSearch")} 
                        onSearch={searchLeased}
                    />
                </Col>
                <Col span={6} offset={2}>
                    <Search 
                        className="client-dual-dragger-search" 
                        placeholder={getIntl("deviceSearch")} 
                        onSearch={searchRoute}
                    />
                </Col>
            </Row>
        )
    }

    fullRender = () => {
        const { renderTitles, renderSearchBoxes, renderDualDragger } = this;
        return (
            <div>
                {renderTitles()}
                {renderSearchBoxes()}
                {renderDualDragger()}
            </div>
        );
    }

	render(){
		return this.fullRender();
	}
}

LeasedDevicesForm.propTypes = {
	intl: PropTypes.object.isRequired,
    clientId: PropTypes.number.isRequired,
	setLoading:  PropTypes.func.isRequired,
	errorClose:  PropTypes.func.isRequired,
};

export default injectIntl(LeasedDevicesForm);