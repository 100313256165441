import React from 'react';
import PropTypes from 'prop-types';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Dropdown, message, Button, Divider } from 'antd';
import { injectIntl } from 'react-intl';
import { getCommissionMessages, getFields, getLanguageIntl, getactionMessages } from '../../constants/messages';
import CommonTable from '../../components/CommonTable';
import MobileCommonTable from '../../components/MobileCommonTable';
import Mayre from 'mayre';
import { isMobile } from '../../utilities/util';
import moment from 'moment';
import ViewModal from './ViewModal';
import Embolden from '../GlobalComponents/Embolden';
import ConfigModal from './ConfigModal';
import { STATUS } from './constants';
const { APPROVED } = STATUS;
import CommissionBadge from './CommissionBadge';
import PayModal from './PayModal';

let commissionMessages = getCommissionMessages(),
	fieldMessages = getFields(),
	intlMessages = getLanguageIntl(),
	actionMessages = getactionMessages();

const messages = {
	...commissionMessages,
	...fieldMessages,
	...intlMessages,
	...actionMessages
};

class Commissions extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			currentCommissionId: 0,
			showViewModal: false,
			showConfigurationsModal: false,
			showPayModal: false
		};
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });
	setShowViewModal = showViewModal => this.setState({ showViewModal });

	getRecords = (search = {}, page, pageSize) => {
		const { getIntl, setLoading, props } = this;
		const { getRecords, getSalespersonRecords, isMaster, user } = props;
		const { id: salesPersonId } = user;
		const searchFunc = isMaster ? () => getRecords(search, page, pageSize) : () => getSalespersonRecords(salesPersonId, page, pageSize)
		setLoading(true);
		searchFunc()
			.then(() => setLoading(false))
			.catch(() => {
				setLoading(false);
				message.error(getIntl("getCommissionsError"));
			});
	}

	renderAddButton = () => {
		const { props, getIntl } = this;
		const { isMaster } = props;
		if (isMaster)
			return (
                <Button type="primary" onClick={() => this.props.history.push("/commissions/new")}>
					<PlusOutlined /> {getIntl("generateCommission")}
				</Button>
            );
		return null;
	}

	renderViewButton = (commissionId) => {
		return (
			<a key="view" onClick={() => this.setState({ showViewModal: true, currentCommissionId: commissionId })}>
				{this.getIntl("view")}
			</a>
		);
	}

	closeViewModal = () => this.setState({ showViewModal: false, currentCommissionId: 0 });

	renderEditButton = (id) => {
		const { getIntl } = this;
		return <a key="edit" onClick={() => this.props.history.push(`commissions/${id}/edit`)}>{getIntl("edit")}</a>;
	}

	renderPayButton = (commissionId) => {
		const { getIntl } = this;
		return <a key="pay" onClick={() => this.setState({ showPayModal: true, currentCommissionId: commissionId })}>{getIntl("pay")}</a>;
	}

	closePayModal = () => this.setState({ showPayModal: false, currentCommissionId: 0 });

	formatDate = (unformatedDate) => {
		if (!unformatedDate) return undefined;
		const { getIntl } = this;
		const locale = getIntl("intl");
		return moment(unformatedDate).locale(locale).format('MMMM DD, YYYY');
	}

	renderActions = (commission) => {
		const { props, renderViewButton, renderEditButton, renderPayButton } = this;
		const { isMaster } = props;
		const { id: commissionId, status } = commission;
		if (isMaster) return (
			<span>
				{renderViewButton(commissionId)}
				<Divider type="vertical" />
				{renderEditButton(commissionId)}
				{status === APPROVED ? <span><Divider type="vertical" />{renderPayButton(commissionId)}</span> : null}
			</span>
		);
		else return renderViewButton(commissionId);
	}

	getColumns = () => {
		const { getIntl, formatDate, renderActions } = this;
		return [
			{
				title: getIntl("commissionNumber"),
				dataIndex: 'id',
				key: 'id'
			},
			{
				title: getIntl("creationDate"),
				dataIndex: 'creationDate',
				key: 'creationDate',
				render: (date) => formatDate(date)
			},
			{
				title: getIntl("salesperson"),
				dataIndex: ['salesPerson','name'],
				key: 'salesPerson'
			},
			{
				title: getIntl("status"),
				dataIndex: 'status',
				key: 'status',
				render: (status) => <CommissionBadge status={status} />
			},
			{
				title: getIntl("actions"),
				key: 'actions',
				render: (commission) => renderActions(commission)
			}
		];
	}

	renderCardMenu = (commissionId) => {
		const { props: { isMaster }, renderViewButton, renderEditButton } = this;

		const items = [
			{
				key: '1',
				label: renderViewButton(commissionId)
			},
			{
				key: '2',
				label: renderEditButton(commissionId)
			},
		]

		if (isMaster) return items;
		else return [items[0]];
	}

	getCard = (record) => {
		const { getIntl, renderCardMenu, formatDate } = this;
		const { id: commissionId, creationDate, salesPerson = {} } = record;
		const { name = "" } = salesPerson;
		const menu = renderCardMenu(commissionId);
		const action = (
			<Dropdown overlay={menu} trigger={['click']}>
				<a className="ant-dropdown-link" href="#">{getIntl("actions")}<DownOutlined /></a>
			</Dropdown>
		);
		return (
			<Card key={commissionId} title={<Embolden header={getIntl("commissionNumber")} value={commissionId} row />} extra={action} style={{ width: "100%" }}>
				<Embolden header={getIntl("creationDate")} value={formatDate(creationDate)} row />
				<Embolden header={getIntl("salesperson")} value={name} row />
			</Card>
		);
	}

	renderConfigButton = () => {
		const { props, getIntl } = this;
		const { isMaster } = props;
		if (isMaster) return (
			<Button onClick={() => this.setState({ showConfigurationsModal: true })}>
				{getIntl("configurations")}
			</Button>
		);
		return null;
	}

	fullRender = () => {
		const { state, props, getColumns, getRecords, renderAddButton, getCard, renderConfigButton, closeViewModal, closePayModal } = this;
		const { isLoading, currentCommissionId, showViewModal, showConfigurationsModal, showPayModal } = state;
		const { records, recordCount, getRecordCount } = props;
		return (
			<div className="view">
				<ViewModal commissionId={currentCommissionId} showModal={showViewModal} handleCancel={closeViewModal} />
				<ConfigModal showModal={showConfigurationsModal} handleCancel={() => this.setState({ showConfigurationsModal: false })} />
				<PayModal commissionId={currentCommissionId} showModal={showPayModal} handleCancel={closePayModal} />
				<Mayre
					of={
						<CommonTable
							columns={getColumns()}
							dataSource={records}
							search={(search, page, pageSize) => getRecords(search, page, pageSize)}
							recordCount={recordCount}
							getRecords={(page, pageSize) => getRecords({}, page, pageSize)}
							loading={isLoading}
							getCheckboxProps={() => ({})}
							Add={renderAddButton()}
							getRecordCount={getRecordCount}
							hideCheckboxes
							disableSearchBar
							extraComponents={renderConfigButton()}
						/>
					}
					or={
						<MobileCommonTable
							dataSource={records}
							search={(search, page, pageSize) => getRecords(search, page, pageSize)}
							recordCount={recordCount}
							loading={isLoading}
							card={getCard}
							getRecords={(page, pageSize) => getRecords({}, page, pageSize)}
							Add={renderAddButton()}
							disableSearchBar
						/>
					}
					when={!isMobile()}
				/>
			</div>
		);
	}

	render() {
		return this.fullRender();
	}
}

Commissions.propTypes = {
	intl: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	getRecords: PropTypes.func.isRequired,
	records: PropTypes.array.isRequired,
	getRecordCount: PropTypes.func.isRequired,
	recordCount: PropTypes.number.isRequired,
	getSalespersonRecords: PropTypes.func.isRequired,
	isMaster: PropTypes.bool.isRequired
};

export default injectIntl(Commissions);