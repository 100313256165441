import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Modal, Input, Row, Col, Checkbox, Select } from 'antd';
import { connect } from "react-redux";
import { injectIntl } from 'react-intl';
import { getReportMessages, getIncomeMessages } from '../../../constants/messages';
import React from 'react';
import { FilterSelectCategories } from '../actions';

let reportMessages = getReportMessages(),
    incomeMessages = getIncomeMessages();

const messages = {
    ...reportMessages,
    ...incomeMessages
};

class CategoryModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isChild: false
        }
    }

    componentDidMount() {
        this.setState({
            isChild: false
        })
        this.filterSelectCategories("");
    }

    submit = () => {
        this.props.form.validateFields({ force: true }, (err, values) => {
            if (err)
                return;
            values.parentId = values.parentId ? parseInt(values.parentId) : null;
            this.props.submitCategory(values, this.props.categoryData.id);
            this.closeModal();
        });
    }

    closeModal = () => {
        this.setState({
            isChild: false
        });
        this.props.handleCancel();
    }

    filterSelectCategories = (input) => {
        this.props.filterSelectCategories({ status: 2, searchText: input });
    }

    switchChild = (e) => {
        this.setState({
            isChild: e.target.checked
        });
    }

    isParentCategory = () => {
        return this.props.categoryData.subCategories.length > 0;
    }

    renderCategoryInput = () => {
        if (!this.state.isChild)
            return <div></div>;
        const { getFieldDecorator } = this.props.form;
        let options = [];
        if (this.props.categories)
            options = this.props.categories.map((cat) =>
                <Select.Option key={cat.id} value={cat.id.toString()}> {cat.name} </Select.Option>);
        return (<Form.Item label={this.props.intl.formatMessage({ ...messages.parent })}>
            {getFieldDecorator(`parentId`, {
                rules: [{
                }],
            })(
                <Select
                    showSearch
                    placeholder={`${this.props.intl.formatMessage({ ...messages.categories })}`}
                    optionFilterProp="children"
                    onSearch={this.filterSelectCategories}
                    disabled={!this.state.isChild}
                >
                    {options}
                </Select>
            )}
        </Form.Item>);
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Modal
                title={this.props.intl.formatMessage({ ...messages.addCategory })}
                destroyOnClose
                open={this.props.showModal}
                maskClosable={false}
                onCancel={this.closeModal}
                footer={
                    <div>
                        <Button key="cancel" type="default" size="default" onClick={this.closeModal}>
                            {this.props.intl.formatMessage({ ...messages.cancel })}
                        </Button>
                        <Button key="submit" type="primary" size="default" onClick={this.submit}>
                            {this.props.intl.formatMessage({ ...messages.accept })}
                        </Button>
                    </div>
                }
                className="AddCategoryModalModal"
            >
                <Form layout='vertical'>
                    <Row gutter={16} type="flex" align="middle">
                        <Col sm={{ span: 16 }} xs={{ span: 16 }} md={{ span: 16 }} lg={{ span: 16 }}>
                            <Form.Item label={this.props.intl.formatMessage({ ...messages.name })}>
                                {getFieldDecorator('name', {
                                    rules: [{
                                        required: true, message: this.props.intl.formatMessage({ ...messages.name }),
                                    }],
                                    initialValue: this.props.categoryData.name
                                })(
                                    <Input placeholder={this.props.intl.formatMessage({ ...messages.name })} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col sm={{ span: 6 }} xs={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }} style={{ paddingLeft: "10px" }}>
                            <Form.Item label={this.props.intl.formatMessage({ ...messages.active })}>
                                {getFieldDecorator('active', {
                                    valuePropName: 'checked',
                                    initialValue: this.props.categoryData.active
                                })(
                                    <Checkbox >{this.props.intl.formatMessage({ ...messages.active })}</Checkbox>
                                )}
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row type="flex" justify="start">
                        <Col span={8} style={{ paddingTop: "35px", paddingLeft: "10px" }}>
                            <Checkbox disabled={this.isParentCategory()} onChange={this.switchChild}> {this.props.intl.formatMessage({ ...messages.isSubCategory })} </Checkbox>
                        </Col>
                        <Col span={16}>
                            {this.renderCategoryInput()}
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

CategoryModal.propTypes = {
    categories: PropTypes.array.isRequired,
    categoryData: PropTypes.object.isRequired,
    filterSelectCategories: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    handleCancel: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    showModal: PropTypes.bool.isRequired,
    submitCategory: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    filterSelectCategories: (searchData, page, pageSize) => dispatch(FilterSelectCategories(searchData, page, pageSize)),
});

const mapStateToProps = (state) => {
    return {
        categories: state.incomeReport.selectCats,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(CategoryModal)));