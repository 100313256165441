import React from 'react';
import PropTypes from 'prop-types';
import DeviceTemplate from './DeviceTemplate';

class Device extends React.Component {
    
	render() {
        const { form, search, device, disabled, onChange, required, displayInfo, displayLeasedWarning } = this.props;
        return (
            <DeviceTemplate
                form={form}
                search={search}
                device={device}
                disabled={disabled}
                onChange={onChange}
                required={required}
                displayInfo={displayInfo}
                displayLeasedWarning={displayLeasedWarning}
            />
        );
    }
}

Device.defaultProps = {
    device: {},
    disabled: false,
    required: true,
    displayInfo: true,
    displayLeasedWarning: false
};

Device.propTypes = {
    form: PropTypes.object.isRequired,
    search: PropTypes.func.isRequired,
    device: PropTypes.object,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    displayInfo: PropTypes.bool.isRequired,
    displayLeasedWarning: PropTypes.bool.isRequired
};

export default Device;