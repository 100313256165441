import { connect } from 'react-redux';
import Calendar from './Calendar';
import { FetchCalendarEvents } from '../actions';

const mapStateToProps = (state) => {
	return {
		events: state.job.calendarEvents
	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		fetchCalendarEvents: (timeMin, timeMax) => dispatch(FetchCalendarEvents(timeMin, timeMax))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);