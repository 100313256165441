import { ADD_LOTUNIT_SUCCESS, EDIT_LOTUNIT_SUCCESS, FETCH_LOTUNIT, DELETE_LOTUNIT_SUCCESS, SET_SEARCH_LOTUNITS, CLEAR_SESSION, SET_SEARCH_LOTS} from './actionTypes';

const initState = {
	lotUnits: [],
    search: '',
    searchLots: '',
};

const reducer = (state = initState, action) => {
	switch (action.type) {
        case FETCH_LOTUNIT:
            return {
                ...state,
                lotUnits: action.lotUnits,
            };
		case ADD_LOTUNIT_SUCCESS:
			return {
                ...state,
				lotUnits: action.lotUnits,
			};
        case EDIT_LOTUNIT_SUCCESS:
            return {
                ...state,
                lotUnits: action.lotUnits,
            };
        case DELETE_LOTUNIT_SUCCESS:
            return {
                ...state,
                lotUnits: action.lotUnits,
            };
        case SET_SEARCH_LOTUNITS:
            return {
                ...state,
                search: action.search
            }
        case   SET_SEARCH_LOTS:
            return {
                ...state,
                searchLots: action.searchLots
            }

        case CLEAR_SESSION:
            return initState;
        
        default:
            return state;
    }
};
    
    export default reducer;
