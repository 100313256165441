import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { DeleteOutlined, DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Col, Card, Button, Dropdown, Popconfirm, message } from 'antd';
import { getDepartmentMessages, getactionMessages, getFields, getPopConfirm } from '../../../constants/messages';
import CommonTable from '../../../components/CommonTable';
import MobileCommonTable from '../../../components/MobileCommonTable';
import {isMobile, getObjectInArray} from '../../../utilities/util';
import CommonModal from './CommonModal';

let departmentMessages = getDepartmentMessages(),
  actionMessages = getactionMessages(),
	fieldMessages = getFields(),
  popConfirmMessage = getPopConfirm();


const messages = {
    ...departmentMessages,
    ...actionMessages,
    ...fieldMessages,
    ...popConfirmMessage
};

const CommonRender = ({searchText,records, getRecords, recordCount, getRecordCount, deleteRecords, columns, cardBodyRender, add, update, renderFormFunc, addSuccessMessage, updateSuccessMessage}) => {
    const intl = useIntl();
    const [isLoading, setIsloading] = useState(false);
    const [currentRecord, SetCurrentRecord] = useState({});
    const [showAddUpdateModal, setshowAddUpdateModal] = useState(false);
    const [isUpdate, setisUpdate] = useState(false);
    const search = useSelector((state) => state.department.searchText);

  
    const deleteRecord = async (id) => {
      try {
        setIsloading(true);
        await deleteRecords(id);
      } catch (error) {
        setIsloading(false);
      } finally {
        await getRecords();
        setIsloading(false);

      }
    };

    const editRecord = (id) => {
      try {
        let record = getObjectInArray("id", parseInt(id), records);
        SetCurrentRecord(record);
        setshowAddUpdateModal(true);
        setisUpdate(true);
      } catch (error) {
        setshowAddUpdateModal(false);
        setisUpdate(false);
      }
    };

    const getRecord = async (search = {}, page, pageSize) => {
      try {
        setIsloading(true);
        await getRecords(search, page, pageSize);
        await getRecordCount(search);
      } catch (error) {
        setIsloading(false);
      } finally {
        setIsloading(false);
      }
    };

    const closeAddUpdateModal = () => {
      SetCurrentRecord({});
      setshowAddUpdateModal(false);
      setisUpdate(false);
    };

    const addRecord = () => {
      SetCurrentRecord({});
      setshowAddUpdateModal(true);
      setisUpdate(false);
    };

    const renderEdit = (id) => {
      return (
        <a onClick={() => editRecord(id)}>
          <span className="icon-pencil" />
          <FormattedMessage {...messages.edit} />
        </a>
      );
    };

    const renderAddButton = () => {
      return (
        <Button
          className="editable-add-btn"
          type="primary"
          onClick={addRecord}
          icon={<PlusOutlined />}
        >
          <FormattedMessage {...messages.add} />
        </Button>
      );
    };

    const getColumns = () => {

      const actionColumnIndex = columns.findIndex(column => column.key === 'action');
      if(actionColumnIndex===-1){
        columns.push({
          key: 'action',
          title: intl.formatMessage({ ...messages.actions }),
          render: (record) => renderEdit(record.id),
        });
      }
    
     return columns
    };
    const getCard = (record) => {
      let title = (
        <Row align="middle">
          <Col span={14}>{record.name}</Col>
        </Row>
      );

      const action = (record) => (
        <Dropdown menu={{ items: menu(record) }} trigger={["click"]}>
          <a className="ant-dropdown-link" href="#">
            <FormattedMessage {...messages.actions} />
            <DownOutlined />
          </a>
        </Dropdown>
      );

      const menu = (record) => {
        const { id } = record;

        const items = [
          {
            key: "1",
            label: renderEdit(id),
          },
          {
            key: "2",
            label: (
              <Popconfirm
                title={intl.formatMessage({ ...messages.deleteConfirm })}
                onConfirm={() => deleteRecord(id)}
                okText={intl.formatMessage({ ...messages.yes })}
                cancelText={intl.formatMessage({ ...messages.no })}
              >
                <a>
                  <DeleteOutlined />
                  <FormattedMessage {...messages.delete} />
                </a>
              </Popconfirm>
            ),
          },
        ];
        return items;
      };

      return (
        <Row key={record.id} type="flex" justify="space-around" align="middle">
          <Card
            loading={isLoading}
            title={title}
            extra={action(record)}
            style={{ width: "100%" }}
          >
            {cardBodyRender(record)}
          </Card>
        </Row>
      );
    };

    const adds = async (values) => {
      try {
        await add(values);
        message.success(addSuccessMessage);
        closeAddUpdateModal();
      } catch (error) {
        closeAddUpdateModal();
      }
    };

    const updates = async (values) => {
      try {
        await update(values, currentRecord.id);
        message.success(updateSuccessMessage);
        closeAddUpdateModal();
      } catch (error) {
        closeAddUpdateModal();
      }
    };
    return (
      <div className="client view">
        <CommonModal
          showModal={showAddUpdateModal}
          isUpdate={isUpdate}
          handleCancel={closeAddUpdateModal}
          record={currentRecord}
          save={isUpdate ? updates : adds}
          title={intl.formatMessage({ ...messages[isUpdate ? "edit" : "add"] })}
          renderFormFunc={renderFormFunc}
        />

        {!isMobile() ? (
          <CommonTable
            columns={getColumns()}
            dataSource={records}
            search={(search, page, pageSize) =>
              getRecord(search, page, pageSize)
            }
            searchText={searchText}
            recordCount={recordCount}
            getRecords={(page, pageSize) => getRecord(search, page, pageSize)}
            loading={isLoading}
            getCheckboxProps={() => ({})} // what ??
            Add={renderAddButton()}
            delete={deleteRecord}
            disableDelete={false}
            getRecordCount={getRecordCount}
            
          />
        ) : (
          <MobileCommonTable
            dataSource={records}
            search={(search, page, pageSize) =>
              getRecord(search, page, pageSize)
            }
            recordCount={recordCount}
            loading={isLoading}
            card={getCard}
            getRecords={(page, pageSize) => getRecord({}, page, pageSize)}
            Add={renderAddButton()}
          />
        )}
      </div>
    );
};

CommonRender.defaultProps = {
	searchText: ""
};

CommonRender.propTypes = {
  records: PropTypes.array.isRequired,
  getRecords: PropTypes.func.isRequired,
  recordCount: PropTypes.number.isRequired,
  getRecordCount: PropTypes.func.isRequired,
  deleteRecords: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  cardBodyRender: PropTypes.func.isRequired,
	add: PropTypes.func.isRequired,
	update: PropTypes.func.isRequired,
  renderFormFunc: PropTypes.func.isRequired,
  addSuccessMessage: PropTypes.string.isRequired,
  updateSuccessMessage: PropTypes.string.isRequired,
  searchText: PropTypes.string,
};

export default CommonRender
