import React, {
	Component
} from 'react';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import { injectIntl } from 'react-intl';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Row, Avatar, Dropdown } from 'antd';
import {
	getTopMenuMessages
} from '../constants/messages';
import {
	UpdateProfileImage,
} from '../actions/AccountAction';
import {
	connect
} from 'react-redux';
import {
	LogOut
} from '../actions/AuthAction';
import {
	SHOW_PROFILE,
	SHOW_CONFIGURATION,
} from '../constants/actionTypes';
import {
	splitName
} from '../utilities/util';

let topMenuMessages = getTopMenuMessages();

const messages = {
	...topMenuMessages
};
class TopMenu extends Component {
	constructor(props) {
		super(props);
		this.generatePublicLink();
	}
	
	generatePublicLink = () => {
		let token = cookie.load("image");
		if (!token || !token.length) {
			this.props.updateProfileImage();
		}
	};
	
	render() {
		let {
			user,
			logOut,
			openProfile,
			openSettings,
			intl
		} = this.props;

		let UserAvatar =<Avatar  shape="square" icon={<UserOutlined />}/>;

		if( user.url ){
			UserAvatar = <Avatar src={user.url} className="profilePicture"/>;
		}
		
		const items = [
			{
				key: '1',
				label: (
					<a onClick={openProfile}>
						{intl.formatMessage( {...messages.profile} )}
					</a>
				),
			},
			{
				key: '2',
				label: (
					<a onClick={openSettings}>
						{intl.formatMessage( {...messages.config} )}
					</a>
				),
			},
			{
				key: '3',
				label: (
					<a onClick={logOut}>
						{intl.formatMessage( {...messages.logout} )}
					</a>
				),
			},
		]
		
		return (
            <Col span={20}>
				<Row  type="flex" justify="end">
					<Col span={12} className="text-right">
						<Dropdown menu={{items}} className="trigger">
							<a className="">
								{UserAvatar}
								<font >
									<span>{splitName(user.name)}</span>
								</font>
								<DownOutlined />
							</a>
						</Dropdown>	
					</Col>
				</Row>		
			</Col>
        );
	}
}

TopMenu.propTypes = {
	logOut: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	openProfile: PropTypes.func.isRequired,
	openSettings: PropTypes.func.isRequired,
	updateProfileImage: PropTypes.func.isRequired,
	roles: PropTypes.array.isRequired,
	intl: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		"roles": state.auth.roles
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		logOut: () => {
			dispatch(LogOut());
		},
		openProfile: () => {
			dispatch({type: SHOW_PROFILE});
		},
		openSettings: () => {
			dispatch({type: SHOW_CONFIGURATION});
		},
		updateProfileImage: () => {
			dispatch(UpdateProfileImage());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TopMenu));



// 