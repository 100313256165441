export const GET_QUOTATIONS = 'GET_QUOTATIONS';
export const FETCH_QUOTATION_COUNT = 'FETCH_QUOTATION_COUNT';
export const ADD_QUOTATION_SUCCESS = 'ADD_QUOTATION_SUCCESS';
export const GET_QUOTATION = 'GET_QUOTATION';
export const SET_QUOTATION_TO_ADD = 'SET_QUOTATION_TO_ADD';
export const APPROVE_QUOTATION_SUCCESS = 'APPROVE_QUOTATION_SUCCESS';
export const DENY_QUOTATION_SUCCESS = 'DENY_QUOTATION_SUCCESS';
export const QUOTATION_INVOICED_SUCCESS = 'QUOTATION_INVOICED_SUCCESS';
export const QUOTATION_PROFORMA_SUCCESS = 'QUOTATION_PROFORMA_SUCCESS';
export const GET_QUOTATION_PRODUCTS = 'GET_QUOTATION_PRODUCTS';
export const QUOTATION_RENEWED = 'QUOTATION_RENEWED';
export const CLEAR_SESSION = 'CLEAR_SESSION';
export const CLEAN_REDUCER = 'CLEAN_REDUCER';
export const UPDATE_SEARCH = 'UPDATE_SEARCH_QUOTATIONS';
export const UPDATE_PAGE = 'UPDATE_PAGE_QUOTATIONS';
