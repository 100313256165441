import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Modal, message } from 'antd';
import { injectIntl } from 'react-intl';
import {
	getactionMessages, getAuthMessages, getModalMessages, getFields, getErrorMessages,
	getRegistrationMessages, getClientMessages, getProductMessages
} from '../../../constants/messages';
import { connect } from 'react-redux';
import { GetTags, AddClient, EditClient } from '../actions';
import { GetProducts } from '../../Products/actions';
import ParentForm from './ParentForm';

let actionMessages = getactionMessages(),
	authMessages = getAuthMessages(),
	errorMessages = getErrorMessages(),
	fieldMessages = getFields(),
	modalMessages = getModalMessages(),
	RegistrationMessages = getRegistrationMessages(),
	productMessages = getProductMessages(),
	ClientMessages = getClientMessages();

const messages = {
	...actionMessages,
	...authMessages,
	...modalMessages,
	...fieldMessages,
	...errorMessages,
	...RegistrationMessages,
	...ClientMessages,
	...productMessages
};

export const steps = {
	BASICINFO: 0,
	TELEPHONES: 1,
	EMAILS: 2,
	CUSTOMPRICES: 3
};

const initialState = {
	currentStep: steps.BASICINFO,
	isLoading: false,
	client: {},
	telephones: [],
	clientEmails: [],
	customPrices: [],
	clientTiers: []
};

class FormModal extends React.Component {

	constructor(props) {
		super(props);
		this.state = { ...initialState };
	}

	componentDidMount() {
		const { props, getIntl, setLoading } = this;
		const { getTags, getProducts } = props;
		const promises = [
			new Promise((resolve, reject) => {
				getTags()
					.then(response => resolve(response))
					.catch(() => reject(0));
			}),
			new Promise((resolve, reject) => {
				getProducts()
					.then(response => resolve(response))
					.catch(() => reject(1));
			})
		];
		setLoading(true);
		Promise.all(promises)
			.then(() => setLoading(false))
			.catch(errorNumber => {
				setLoading(false);
				switch (errorNumber) {
					case 0:
						message.error(getIntl("GetTagsError"));
						break;
					case 1:
						message.error(getIntl("errorGetProducts"));
						break;
					default:
						message.error(getIntl("common"));
						break;
				}
			});
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });

	nextStep = () => {
		const { state, validateAndMoveStep } = this;
		const currentStep = state.currentStep + 1;
		validateAndMoveStep(currentStep);
	}

	previousStep = () => {
		const { state,validateAndMoveStep} = this;
		const currentStep = state.currentStep - 1;
		validateAndMoveStep(currentStep);
	}

	handleOk = () => {
		const { state, props, getIntl, handleCancel, setLoading } = this;
		const { form, isUpdate, addClient, editClient, user } = props;
		const { client, telephones, clientEmails, customPrices } = state;
		
		if(telephones.length<=0 || clientEmails.length <=0 ){

			if( telephones.length<=0  ){
	
				message.error(getIntl("telephonesRequired"))

			}
			if( clientEmails.length<=0 ){
				message.error(getIntl("emailsRequired"))
	
			}
			return;
		}
		
		form.validateFields({ "force": true }, (err) => {
			if (!err) {
				let clientData = { client, telephones, clientEmails, customPrices };
				let func;
				
				if (isUpdate) {
					func = editClient;
					clientData.userId = user.id;
				} else {
					func = addClient;
					clientData.client.salesPersonId = user.id;
				}
				setLoading(true);
				func(clientData, client.id)
					.then(() => handleCancel())
					.catch(() => {
						setLoading(false);
						message.error(isUpdate ? getIntl("EditClientError") : getIntl("AddClientError"));
					});
			}
		});
	}

	handleCancel = () => {
		this.setState({ ...initialState });
		this.props.handleCancel();
	}

	validateAndMoveStep = (step) => {
		const { state, props, getIntl } = this;
		const { currentStep, clientEmails } = state;
		if (currentStep === steps.EMAILS) {
			props.form.validateFields({ force: true }, err => {
				if (!err) {
					const foundUniqueEmail = clientEmails.some(email => email.isUnique);
					if (foundUniqueEmail || clientEmails.length === 0) this.setState({ currentStep: step });
					else message.error(getIntl("uniqueEmailNeeded"));
				}
			});
		}
		else{props.form.validateFields({ force: true }, err => !err && this.setState({ currentStep: step }))}
	}

	renderedButtons = () => {
		const { isLoading, currentStep } = this.state;
		const { intl } = this.props;
		let buttonArray = [];
		if (currentStep > steps.BASICINFO) {
			buttonArray.push(<Button
				style={{ marginLeft: 8 }}
				onClick={() => this.previousStep()}
				key="previous"
				loading={isLoading}
			>
				{intl.formatMessage({ ...messages.previous })}
			</Button>);
		}
		if (currentStep < steps.CUSTOMPRICES) {
			buttonArray.push(<Button
				type="primary"
				onClick={() => this.nextStep()}
				key="next"
				loading={isLoading}
			>
				{intl.formatMessage({ ...messages.next })}
			</Button>);
		}
		if (currentStep === steps.CUSTOMPRICES) {
			buttonArray.push(<Button
				type="primary"
				onClick={() => this.handleOk()}
				key="submit"
				loading={isLoading}
			>
				{intl.formatMessage({ ...messages.send })}
			</Button>);
		}
		return buttonArray;
	}

	render() {
		const { state, props, validateAndMoveStep, handleOk, handleCancel, renderedButtons } = this;
		const { intl, form, showModal, clientInfo, tags, products, isUpdate, exchangeRate } = props;
		const { currentStep } = state;
		let title = isUpdate ? intl.formatMessage({ ...messages.edit }) : intl.formatMessage({ ...messages.addPreRegistration });
		return (
			<Modal
				open={showModal}
				title={title}
				onOk={handleOk}
				onCancel={handleCancel}
				destroyOnClose={true}
				footer={renderedButtons()}
				maskClosable={false}
			>
				<ParentForm form={form} state={state} setState={partialState => this.setState(partialState)}
					currentStep={currentStep} clientInfo={clientInfo} tags={tags} products={products} exchangeRate={exchangeRate} moveToStep={validateAndMoveStep}
				/>
			</Modal>
		);
	}
}

FormModal.defaultProps = {
	isUpdate: false,
	exchangeRate: 1
};

FormModal.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	tags: PropTypes.array.isRequired,
	products: PropTypes.array.isRequired,
	addClient: PropTypes.func.isRequired,
	editClient: PropTypes.func.isRequired,
	getTags: PropTypes.func.isRequired,
	getProducts: PropTypes.func.isRequired,
	exchangeRate: PropTypes.number.isRequired,

	showModal: PropTypes.bool.isRequired,
	isUpdate: PropTypes.bool.isRequired,
	clientInfo: PropTypes.object,
	handleCancel: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
		tags: state.client.tags,
		products: state.product.products,
		exchangeRate: state.exchangeRate.exchangeRate.rate,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addClient: (newClient) => dispatch(AddClient(newClient)),
		editClient: (editedClient, clientId) => dispatch(EditClient(editedClient, clientId)),
		getTags: () => dispatch(GetTags()),
		getProducts: () => dispatch(GetProducts()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(FormModal)));