import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Spin } from 'antd';
import { injectIntl } from 'react-intl';
import { getClientMessages, getModalMessages } from '../../../../constants/messages';
import ProgrammedDeactivationsView from './View';
import ProgrammedDeactivationsLogModal from './LogViewer/Modal';

let clientMessages = getClientMessages(),
	modalMessages = getModalMessages();

const messages = {
	...clientMessages,
	...modalMessages
};

const initialState = {
	isLoading: false,
	isSavingNewDate: false,
	showDeactivationLog: false
};

class PDModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { ...initialState };
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });
	setSaving = (isSavingNewDate) => this.setState({ isSavingNewDate });
	setShowDeactivationLog = (showDeactivationLog) => this.setState({ showDeactivationLog });

	handleCancel = () => {
		this.setState({ ...initialState });
		this.props.handleCancel();
	}

	renderButtons = () => {
		const { state, getIntl, handleCancel, setShowDeactivationLog } = this;
		const { isSavingNewDate } = state;
		return [
			<Button key="deactivationLog" type="primary" onClick={() => setShowDeactivationLog(true)} disabled={isSavingNewDate}>{getIntl("deactivationLog")}</Button>,
			<Button key="close" onClick={handleCancel} disabled={isSavingNewDate}>{getIntl("close")}</Button>
		];
	}

	render() {
		const { state, props, getIntl, renderButtons, setLoading, setSaving, handleCancel, setShowDeactivationLog } = this;
		const { isLoading, isSavingNewDate, showDeactivationLog } = state;
		const { clientId, showModal } = props;
		return (
			<Modal
				open={showModal}
				title={getIntl("programmedDeactivation")}
				onCancel={handleCancel}
				destroyOnClose={true}
				footer={renderButtons()}
				maskClosable={!isSavingNewDate}
				keyboard={!isSavingNewDate}
				closable={!isSavingNewDate}
				width={1040}
			>
				<Spin spinning={isLoading}>
					<ProgrammedDeactivationsLogModal showModal={showDeactivationLog} handleCancel={() => setShowDeactivationLog(false)} clientId={clientId} />
					<ProgrammedDeactivationsView clientId={clientId} setLoading={setLoading} setSaving={setSaving} isLoading={isSavingNewDate || isLoading} />
				</Spin>
			</Modal>
		);
	}
}

PDModal.propTypes = {
	intl: PropTypes.object.isRequired,
	showModal: PropTypes.bool.isRequired,
	clientId: PropTypes.number.isRequired,
	handleCancel: PropTypes.func.isRequired
};

export default injectIntl(PDModal);