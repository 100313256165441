import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, Spin, message, Collapse } from 'antd';
import { injectIntl } from 'react-intl';
import { getJobMessages, getErrorMessages } from '../../../../constants/messages';
import { getObjectInArray } from '../../../../utilities/util';
import debounce from 'lodash/debounce';
import DeviceRender from '../../ViewJob/DefaultViews/DeviceRender';
import LeasedDeviceWarning from './LeasedDeviceWarning';
const { Panel } = Collapse;

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

let jobMessages = getJobMessages(),
    errorMessages = getErrorMessages();

const messages = {
	...jobMessages,
    ...errorMessages
};

class DeviceTemplate extends React.Component {

    constructor(props) {
		super(props);
		this.handleDeviceSearch = debounce(this.handleDeviceSearch, 250);
		this.state = {
            isLoading: false,
            devices: []
        };
    }

	getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    setLoading = (isLoading) => this.setState({ isLoading });

    handleDeviceSearch = (name) => {
        const { props, getIntl, setLoading } = this;
        const { search } = props;
        if (name.length <= 0 ) this.setState({ devices: [] });
        else {
            setLoading(true);
            search(name)
            .then((response) => this.setState({ isLoading: false, devices: response }))
            .catch( () => {
                this.setState({ isLoading: false, devices: [] });
                message.error(getIntl("unableToSearchDevices"));
            });
        }
    }

    onChange = (deviceId) => {
        const { state, props } = this;
        const { devices } = state;
        const { onChange } = props;
        if ('onChange' in props) onChange(getObjectInArray('id', parseInt(deviceId), devices));
        this.setState({ devices: [] });
    }

    buildDeviceName = (device) => {
        const { pin, imei, description } = device;
        const names = [];
        const checkAndAdd = (property) => { if (property !== undefined && property !== null) names.push(property); };
        checkAndAdd(pin);
        checkAndAdd(imei);
        checkAndAdd(description);
        return names.join(" - ");
    }

    buildOptions = () => {
        const { state, props, getIntl, buildDeviceName } = this;
        const { devices } = state;
        const { device } = props;
        const option = (key, value, dv) => <Option key={key} value={value}>{buildDeviceName(dv)}</Option>;
        let options = [];
        let active = [];
        let inactive = [];
        if (device.id !== undefined) options.push(option(devices.length, device.id, device));
        devices.forEach((dv, index) => {
            if (dv.active) active.push(option(index, dv.id, dv));
            else inactive.push(option(index, dv.id, dv));
        });
        options.push(<OptGroup key="active" label={getIntl("active")}>{active}</OptGroup>);
        options.push(<OptGroup key="inactive" label={getIntl("inactive")}>{inactive}</OptGroup>);
        return options;
    }

    renderForm = () => {
        const { state, props, getIntl, buildOptions, onChange, handleDeviceSearch } = this;
        const { isLoading } = state;
        const { form, disabled, device, required, fieldName } = props;
        const { id } = device;
        const { getFieldDecorator } = form;
        let options = buildOptions();
        const label = 'label' in props ? props.label : getIntl("mainProduct");
        const placeholder = 'placeholder' in props ? props.placeholder : label;
        return(
            <FormItem label={label} >
            {
                getFieldDecorator(fieldName, 
                    {
                        initialValue: id,
                        rules: [{
                            required: required,
                            message: getIntl("mainProductError"), 
                        }],
                        onChange: onChange
                    }
                )(
                    <Select
                        className="job-full-component"
                        showSearch
                        allowClear={!required}
                        disabled={disabled}
                        placeholder={placeholder}
                        optionFilterProp="children"
                        onSearch={(value) => handleDeviceSearch(value)}
                        notFoundContent={isLoading ? <Spin size="small" /> : getIntl("notFound")}
                    >
                        {options}
                    </Select>
                )
            }
            </FormItem>
        );
    }

    renderDeviceInfo = () => {
        const { displayInfo, device } = this.props;
        if (displayInfo) return <DeviceRender device={device}/>
    }

    renderLeasedDeviceWarning = () => {
        const { displayLeasedWarning, device } = this.props;
        if (displayLeasedWarning) return <LeasedDeviceWarning device={device}/>
    }

    fullRender = () => {
        const { props, renderForm, renderDeviceInfo, renderLeasedDeviceWarning } = this;
        const { form, displayInfo, displayLeasedWarning, fieldName } = props;
        const { getFieldValue } = form;
        const selected = getFieldValue(fieldName);
        if (displayInfo || displayLeasedWarning)
            return (
                <div>
                    {renderForm()}
                    <Collapse className="job-view-reconnect-collapse" bordered={false} activeKey={selected ? 1 : 0}>
                        <Panel key={1} showArrow={false}>
                            {renderDeviceInfo()}
                            {renderLeasedDeviceWarning()}
                        </Panel>
                    </Collapse>
                </div>
            );
        return renderForm();
    }
    
	render() {
        return this.fullRender();
    }
}

DeviceTemplate.defaultProps = {
    device: {},
    disabled: false,
    required: true,
    fieldName: "gpsId",
    displayInfo: true,
    displayLeasedWarning: false
};

DeviceTemplate.propTypes = {
	intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    search: PropTypes.func.isRequired,
    device: PropTypes.object,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    required: PropTypes.bool.isRequired,
    label: PropTypes.string,
    fieldName: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    displayInfo: PropTypes.bool.isRequired,
    displayLeasedWarning: PropTypes.bool.isRequired
};

export default injectIntl(DeviceTemplate);