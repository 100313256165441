import React from 'react'
import PropTypes from 'prop-types';
import { Card } from 'antd';

import {  Axis, Chart, Coordinate, Interaction, Interval, Tooltip } from 'bizcharts';

const CustomPriceGraph = ({clients}) => {
    const cols = {
		price: {
			formatter: (value) => {
				value = `$ ${value}`;
				return value;
			},
		},
	};
    
	return (        
        <Card 
            style={{ width: '100%' }} 
            loading={clients.length !== 0 ? false : true} 
            bordered={false}
        >
            <Chart 
                data={clients} 
                height={800} 
                scale={cols} 
                interactions={['element-active']} 
                autoFit
            >
                <Coordinate type={'theta'} radius={0.75} innerRadius={0.5} />
                <Tooltip showTitle={false} />
                <Axis visible={false} />
                <Interval
                    label={['name', {
                        layout: { type: 'limit-in-plot', cfg: { action: 'ellipsis' } },
                        content: (data) => {
                            if(clients.length <= 80)
                                return `${data.name}: $${data.price}`;
                            return;
                        },
                    }]}
                    adjust="stack"
                    type="interval"
                    position="price"
                    color="name"
                    style={{ lineWidth: 1, stroke: '#fff' }}
                />
                <Interaction type="element-single-selected" />
            </Chart>
        </Card>
	);
}

CustomPriceGraph.propTypes = {
    clients: PropTypes.array.isRequired,
};

export default CustomPriceGraph
