import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    DownloadOutlined,
    InboxOutlined,
    MessageOutlined,
    PhoneOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { List, Skeleton, Card, Col, Avatar, Tag, Row, Switch, Divider } from 'antd';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux'
import { roundN, ACL4Component } from '../../utilities/util';
import { getReportMessages, getLanguageIntl } from '../../constants/messages';
import { ModifyDaysAvailable } from './actions';
import SelectableTags from './SelectableTags';
import { ROLES } from '../../constants/global';
import some from 'async/some';

let reportMessages = getReportMessages(),
    languageMessages = getLanguageIntl();

const messages = {
    ...reportMessages,
    ...languageMessages,
};

class ClientCard extends Component {
    constructor(props) {
        super(props);
        let tagColor;
        some(this.props.client.invoices, (invoice, callback) => {
            callback(null, invoice.status === 2)
        }, (error, result) => {
            tagColor = (result) ? "red" : "blue";
        });

        this.state = {
            selectedTags: [],
            selectedAvailable: true,
            tagsFromServer: [
                this.props.intl.formatMessage({ ...messages.sunday }),
                this.props.intl.formatMessage({ ...messages.monday }),
                this.props.intl.formatMessage({ ...messages.tuesday }),
                this.props.intl.formatMessage({ ...messages.wednesday }),
                this.props.intl.formatMessage({ ...messages.thursday }),
                this.props.intl.formatMessage({ ...messages.friday }),
                this.props.intl.formatMessage({ ...messages.saturday }),
            ],
            tagColor: tagColor,
        }
    }

    setSelectedDays() {
        let choosenOnes = [];
        this.props.client.daysAvailable.split(",").forEach((val) => {
            choosenOnes.push(this.state.tagsFromServer[parseInt(val)]);
        });
        this.setState({
            selectedTags: choosenOnes
        });
    }

    componentDidMount() {
        this.setSelectedDays();
    }

    GetDayValue(day) {
        let dayNumber = this.state.tagsFromServer.indexOf(day);
        return dayNumber;
    }

    GetTagsString(tags) {
        let res = [];
        tags.forEach((val) => {
            if (val !== undefined)
                res.push(this.GetDayValue(val));
        });
        if (res.length === 0)
            return "";
        return res.join(",");
    }

    updateSelectedTags = (tags) => {
        tags = tags.filter(t => t !== undefined);
        this.setState({ selectedTags: tags });
        let days = this.GetTagsString(tags);
        this.props.modifyDaysAvailable(this.props.client.id, days);
    }

    switchChange = (checked) => {
        this.setState({ selectedAvailable: !checked });
    }

    render() {
        return (
            <Col xs={{ span: 24 }} sm={{ span: 10 }} md={{ span: 12 }} lg={{ span: 10 }} xl={{ span: 8 }} key={this.props.position} className="cardWrapper">
                <Card
                    actions={[
                        <a key="action" onClick={() => { return this.props.loadCommentsModal(this.props.position) }} > <MessageOutlined /></a>,
                        <a key="action" onClick={() => { return this.props.loadInvoiceModal(this.props.position) }} >  <InboxOutlined /></a>,
                        <a key="action" onClick={() => { return this.props.exportClientReport(this.props.client.id, this.props.position) }} >  <DownloadOutlined /></a>
                    ]}
                    extra={
                        <div>
                            <Tag color={this.state.tagColor}  onClick={() => { return this.props.loadInvoiceModal(this.props.position) }} > {`L. ${roundN(this.props.client.balance, 2)}`} </Tag>
                        </div>
                    }
                    title={
                        <span className="cardHeader">
                            <Row>
                                <Avatar style={{ background: "#1890ff" }} icon={<UserOutlined />} />&nbsp;&nbsp;
                                    {this.props.client.name}
                            </Row>
                        </span>
                    }
                >
                    <Col span={24}>
                        <Skeleton loading={this.props.loading} avatar active >
                            <Col span={24} className="tagsRow">
                                <SelectableTags
                                    selectedAvailable={this.state.selectedAvailable}
                                    tagsFromServer={this.state.tagsFromServer}
                                    updateTags={this.updateSelectedTags}
                                    defaultSelected={this.state.selectedTags}
                                />
                                {ACL4Component(
                                    this.props.roles,
                                    <Col span={3}>
                                        <Switch checked={!this.state.selectedAvailable} size="small" onChange={this.switchChange} />
                                    </Col>,
                                    [
                                        ROLES.MASTER,
                                        ROLES.ADMINISTRATOR
                                    ]
                                )}
                            </Col>
                            <Divider> {this.props.intl.formatMessage({ ...messages.telephones })} </Divider>
                            <Col span={24}>
                                <List
                                    className="telephonesList"
                                    dataSource={this.props.client.telephones}
                                    renderItem={item => {
                                        let tags = [];
                                        let extension = item.extension.map((ext, index) => {
                                            tags = [...tags, ...ext.tag];
                                            if (index < item.extension.length - 1)
                                                return `${ext.extension}, `;
                                            else if (index === item.extension.length - 1) return ext.extension;
                                        });
                                        // console.log("index: ", extension);
                                        let extensionShow = extension.length === 1 && extension[0] === "-1" ?
                                            <div> </div> : (<div> <strong>  {this.props.intl.formatMessage({ ...messages.extension })} </strong> {extension} </div>);
                                        let showTags = tags.map((ts, index) => <Tag color="blue" key={index}>{ts.name}</Tag>);
                                        return (
                                            <List.Item>
                                                <Col span={24}>
                                                    <Col span={24}>
                                                        <PhoneOutlined />{item.number}
                                                    </Col>
                                                    <Col span={24}>
                                                        {extensionShow}
                                                    </Col>
                                                    <Col span={24}>
                                                        {showTags}
                                                    </Col>
                                                </Col>
                                            </List.Item>
                                        );
                                    }}
                                >
                                </List>
                            </Col>
                        </Skeleton>
                    </Col>
                </Card>
            </Col>
        );
    }
}

ClientCard.propTypes = {
    client: PropTypes.object.isRequired,
    exportClientReport: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    loadCommentsModal: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadInvoiceModal: PropTypes.func.isRequired,
    modifyDaysAvailable: PropTypes.func.isRequired,
    position: PropTypes.number.isRequired,
    roles: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
    return {
        "roles": state.auth.roles,
    };
};

const mapDispatchToProps = (dispatch) => ({
    modifyDaysAvailable: (id, days) => dispatch(ModifyDaysAvailable(id, days))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ClientCard));
