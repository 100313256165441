import axios from 'axios';
import { notify, getS3PublicLink, advancedGetNestedValue } from '../../utilities/util';
import cookie from 'react-cookies';
import {
	GET_INVOICES, ADD_INVOICE_SUCCESS, GET_INVOICE, LATEST_PAYMENT, DISABLE_INVOICE_SUCCESS, PAID_INVOICE_SUCCESS, OVERDUE_INVOICE_SUCCESS, GENERATED_INVOICES_SUCCESS,
	GET_CLIENTS_PENDING_INVOICES, CLEAN_CLIENTS_PENDING_INVOICES, TAX_RETENTION, GET_TAX_RETENTION, TAX_RETENTION_IMAGE, FETCH_INVOICE_COUNT, SAVE_ISV, UPDATE_SEARCH, UPDATE_PAGE
} from './actionTypes';
import { INVOICE_PAID_FALSE } from '../Payments/actionTypes';
import { STATUSES } from './constants';
import moment from 'moment';

export const GetInvoices = (page = 1, pageSize = 10) => (dispatch) => {
	let filter = {
		limit: pageSize,
		skip: (page - 1) * pageSize,
		include: ["client", "account", "invoiceProducts"],
		order: ["createdAt DESC", "documentNumber DESC"],
		where: {}
	};

	return dispatch(BaseGetInvoices(filter));
}

export const GetInvoicesBySeller = (page = 1, pageSize = 10, clientIds) => (dispatch) => {
	let filter = {
		limit: pageSize,
		skip: (page - 1) * pageSize,
		include: ["client", "account", "invoiceProducts"],
		order: ["createdAt DESC", "documentNumber DESC"],
		where: { clientId: {inq: clientIds} }
	};
	return dispatch(BaseGetInvoices(filter));
}

export const BaseGetInvoices = (filter) => (dispatch) => {
	let promises = [];
	promises.push(
		new Promise(function (resolve, reject) {
			axios.get(`/Invoices/count?where=${encodeURIComponent(JSON.stringify(filter.where))}`)
				.then((response) => {
					dispatch({ type: FETCH_INVOICE_COUNT, count: response.data.count });
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		})
	);
	promises.push(
		new Promise(function (resolve, reject) {
			axios.get(`/Invoices?filter=${encodeURIComponent(JSON.stringify(filter))}`)
				.then((response) => {
					dispatch({ type: GET_INVOICES, invoices: response.data });
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		})
	);
	return Promise.all(promises);
};

export const GetInvoice = (id) => (dispatch) => {
	let filter = {
		include: [
			{ client: "salesPerson" },
			{
				relation: "payments",
				scope: {
					include: [
						{
							relation: "destination",
							scope: {
								fields: ["id", "name"]
							}
						}
					]
				}
			},
			{
				relation: "activityLog",
				scope: {
					include: [
						{
							relation: "account",
							scope: {
								fields: ["id", "name"]
							}
						}
					]
				}
			}
		]
	};
	return axios.get(`/Invoices/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then(response => dispatch({ type: GET_INVOICE, invoice: response.data }));
};

export const AddInvoice = (invoiceData) => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;

	return axios.post('/Invoices/addInvoice', { invoiceData })
		.then((response) => {
			notify(`${messages[ADD_INVOICE_SUCCESS]}`);
			dispatch({ type: ADD_INVOICE_SUCCESS, invoice: response.data.invoice });
		});
};

export const AddInvoiceWithDevices = (invoiceData) => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;

	return axios.post('/Invoices/addInvoiceWithDevices', { invoiceData })
		.then((response) => {
			notify(`${messages[ADD_INVOICE_SUCCESS]}`);
			dispatch({ type: ADD_INVOICE_SUCCESS, invoice: response.data.invoice });
		});
};

export const GetAutomaticInvoicesData = () => () => {
	return axios.get('/Invoices/getAutomaticInvoicesData')
		.then((response) => {
			return response.data;
		});
};

export const GenerateInvoice = (client, invoiceData, monthlyServiceProducts) => (dispatch, getState) => {
	const state = getState();
	const accountId = state.auth.user.id;
	return axios.post('/Invoices/generateInvoice', { client, invoiceData: { ...invoiceData, accountId }, monthlyServiceProducts })
		.then((response) => {
			return response.data;
		});
};

export const GenerateProformas = () => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;

	return axios.post('/Proformas/generateInvoices')
		.then(() => {
			notify(`${messages[GENERATED_INVOICES_SUCCESS]}`);
			dispatch({ type: GENERATED_INVOICES_SUCCESS });
		});
};

export const GetLatestPayment = (clientId) => (dispatch) => {
	let filter = {
		"where": {
			"clientId": clientId
		},
		"include": [
			"invoice",
			"payment"
		]
	};

	return axios.get(`/LatestPayments?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			dispatch({ type: LATEST_PAYMENT, payment: response.data.length === 0 ? { balance: 0 } : response.data[0].payment, docNum: response.data.length === 0 ? "" : response.data[0].invoice.documentNumber });
		});
}

export const GetISV = () => (dispatch) => {
	const ISV = "ISV";
	const where = { name: ISV };
	const filter = { where };

	return axios.get(`/ConfigurationTables?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then(response => {
			const { data } = response;
			const isv = (data.length <= 0) ? 0.15 : parseFloat(data[0].value);
			dispatch({ type: SAVE_ISV, isv: isv ? isv : 0.15 });
		});
}

export const PrintInvoice = (ids) => () => {
	if (ids.constructor !== Array)
		ids = [ids];
	return axios({
		url: `/Invoices/print`,
		method: 'POST',
		responseType: 'blob',
		data: {
			ids: ids
		}
	})
		.then((response) => {
			let filename = response.headers['cache-control'];
			let arr = filename.split(',');
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Factura ${arr[arr.length - 1]}.pdf`);
			document.body.appendChild(link);
			link.click();
		});
};

export const PreviewInvoice = (id) => {
	return axios({
		url: `/Invoices/${id}/preview`,
		method: 'GET',
		responseType: 'blob'
	})
		.then(response => response.data);
};

export const PreviewUnregisteredInvoice = (invoiceData) => {
	return axios({
		url: `/Invoices/previewUnregisteredInvoice`,
		method: 'GET',
		responseType: 'blob',
		params: { invoiceData }
	})
		.then(response => response.data);
};

export const PrintEmailedInvoice = (id) => {
	return axios({
		url: `/Invoices/${id}/preview`,
		method: 'GET',
		responseType: 'blob'
	})
		.then(response => {
			let filename = response.headers['cache-control'];
			let arr = filename.split(',');
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Factura ${arr[arr.length - 1]}.pdf`);
			document.body.appendChild(link);
			link.click();
		});
};

export const EmailInvoice = (id, emails, replyTo, message, includeDevices = false) => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;

	return axios.post(`/Invoices/${id}/sendByEmail`, { id, emails, replyTo, message, includeDevices })
		.then(() => notify(`${messages["EMAIL_SUCCESS"]}`));
}


export const AnnulInvoice = (id, reason) => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;

	let annullment = {
		status: 3,
		reasonAnnullment: reason
	};

	return axios.patch(`/Invoices/${id}`, annullment)
		.then(() => {
			notify(`${messages[DISABLE_INVOICE_SUCCESS]}`);
			dispatch({ type: DISABLE_INVOICE_SUCCESS });
		});
};

export const InvoicePaid = (id) => (dispatch) => {
	let invoice = {
		status: 1
	};

	return axios.patch(`/Invoices/${id}`, invoice)
		.then(() => {
			dispatch({ type: PAID_INVOICE_SUCCESS });
			dispatch({ type: INVOICE_PAID_FALSE });
		});
};

export const CheckOverdueInvoices = () => (dispatch) => {
	return axios.patch(`/Invoices/overdueCheck/`)
		.then(() => {
			dispatch({ type: OVERDUE_INVOICE_SUCCESS });
		});
};

export const SearchInvoices = (page, pageSize = 10, searchObject) => (dispatch) => {
	return axios({
		url: `/Invoices/searchAllInvoices`,
		method: 'GET',
		responseType: 'json',
		params: {
			searchObject,
			page,
			pageSize,
		}
	})
		.then((response) => {
			const { data } = response;
			const { invoices = [], count = 0 } = data;
			dispatch({ type: FETCH_INVOICE_COUNT, count });
			dispatch({ type: GET_INVOICES, invoices });
			dispatch({ type: UPDATE_SEARCH, search: searchObject.searchText});
			dispatch({ type: UPDATE_PAGE, page: page});
		});
};

export const SearchInvoicesbySeller = (page, pageSize = 10, searchObject) => (dispatch, getState) => {
	let state = getState();
	let sellerClientIds = state.client.sellerClients;
	return axios({
		url: `/Invoices/searchBySalesPerson`,
		method: 'GET',
		responseType: 'json',
		params: {
			searchObject,
			page,
			pageSize,
			sellerClientIds, 
		}
	})
		.then((response) => {
			const { data } = response;
			const { invoices = [], count = 0 } = data;
			dispatch({ type: FETCH_INVOICE_COUNT, count });
			dispatch({ type: GET_INVOICES, invoices });
			dispatch({ type: UPDATE_SEARCH, search: searchObject.searchText});
			dispatch({ type: UPDATE_PAGE, page: page});
		});
};

export const GetClientsPendingInvoices = (clientId) => (dispatch) => {
	let filter = {
		"include": [
			"client",
			"invoiceProducts"
		],
		"order": "createdAt DESC",
		"where": {
			"and": [
				{ "clientId": clientId },
				{
					"or": [
						{ "status": 0 },
						{ "status": 2 }
					]
				}
			]
		}
	};

	return axios.get(`/Invoices?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			dispatch({ type: GET_CLIENTS_PENDING_INVOICES, invoices: response.data });
		});
}

export const GetClientsInvoice = async (clientId) => {
	const filter = {
		where: {
			clientId,
			status: { inq: [STATUSES.ACTIVE, STATUSES.OVERDUE, STATUSES.PAID] },
			createdAt: { "between": [moment().startOf('day').subtract(6, 'months'), moment().endOf('day').toISOString()] }
		},
		fields: ['id', 'documentNumber', 'clientName', 'status'],
	};
	const response = await axios.get(`/Invoices?filter=${encodeURIComponent(JSON.stringify(filter))}`)
	return response.data;
}

export const CleanPendingInvoices = () => (dispatch) => {
	dispatch({ type: CLEAN_CLIENTS_PENDING_INVOICES });
}

export const TaxRetention = (id, isrRetention, isvRetention) => (dispatch) => {
	let invoice = {
		isrRetention: isrRetention,
		isvRetention: isvRetention
	};

	return axios.patch(`/Invoices/${id}`, invoice)
		.then(() => {
			dispatch({ type: TAX_RETENTION });
		});
};

export const IsrRetentionLetterReceived = (id, date) => (dispatch) => {
	let invoice = {
		isrReceived: 1,
		isrDate: date
	};

	return axios.patch(`/Invoices/${id}`, invoice)
		.then(() => {
			dispatch({ type: TAX_RETENTION });
		});
};

export const IsvRetentionLetterReceived = (id, date) => (dispatch) => {
	let invoice = {
		isvReceived: 1,
		isvDate: date
	};

	return axios.patch(`/Invoices/${id}`, invoice)
		.then(() => {
			dispatch({ type: TAX_RETENTION });
		});
};

export const GetTaxRetentionConstancy = () => (dispatch) => {
	let filter = {
		"order": "createdAt DESC",
		"limit": 1
	};
	return axios.get(`/TaxRetentions?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then((response) => {
			dispatch({ type: GET_TAX_RETENTION, taxRetentionConstancy: response.data[0] });
		});
};

export const AddTaxRetentionConstancy = (values) => (dispatch) => {
	return axios.post(`/TaxRetentions`, values)
		.then((response) => {
			dispatch({ type: TAX_RETENTION });
			dispatch({ type: GET_TAX_RETENTION, taxRetentionConstancy: response.data });
		});
};

export const UploadArchive = (values, name) => {
	const formData = new FormData()
	formData.append('file', values.file.file);
	formData.append('name',name)

	return axios.post( `/S3Buckets/UploadFile`, formData, { headers: { 'Content-Type': 'multipart/form-data' }, })
	.then((response)=>{return response})

}

export const GetTaxImage = (id) => (dispatch) => {
	return axios.get(getS3PublicLink(`taxes/taxes_${id}`))
		.then((response) => {
			let expires = new Date(Date.now());
			expires = new Date(expires.getTime() + (900 * 1000));
			cookie.save('image', expires.getTime(), { expires });
			dispatch({ type: TAX_RETENTION_IMAGE, url: response.data.url });
		});
};

export const CreateDepositary = (depositary) => () => {
	return axios.post(`/Depositaries`, depositary);
};

export const GetEmailSelectorExtraEmails = () => {
	const name = "INVOICE_EXTRA_EMAILS";
	const filter = { where: { name } };
	return axios.get(`/ConfigurationTables?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then(response => {
			const unparsedValue = advancedGetNestedValue("data[0].value", response);
			const extraEmails = unparsedValue ? unparsedValue.split(',') : [];
			return extraEmails.map(email => email.trim());
		});
};

export const DownloadGeneralInvoiceReport = () => {
	return axios({
		url: `/Clients/downloadGeneralInvoiceReport`,
		method: 'GET',
		responseType: 'blob'
	})
		.then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Reporte General de Facturas ${moment().format('YYYY MM DD')}.xlsx`);
			document.body.appendChild(link);
			link.click();
		});
};

export const DownloadFilteredGeneralInvoiceReport = () => {
	return axios({
		url: `/Clients/downloadFilteredGeneralInvoiceReport`,
		method: 'GET',
		responseType: 'blob'
	})
		.then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Reporte General de Facturas ${moment().format('YYYY MM DD')}.xlsx`);
			document.body.appendChild(link);
			link.click();
		});
};

export const LoadClientUnpaidInvoices = (clientId) => {
	const filter = {
		order: "createdAt DESC",
		where: {
			clientId,
			status: { inq: [STATUSES.ACTIVE, STATUSES.OVERDUE] }
		},
		fields: ['id', 'documentNumber', 'clientName', 'status', 'total', 'balance', 'createdAt', 'exchangeRate', 'paymentDate'],
		include: ['invoiceProducts']
	};
	return axios.get(`/Invoices?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then(response => response.data);
};

export const EmailAccountStatus = (clientId, invoiceIds, debitNoteIds, exchangeRate, emails, replyTo, message) => {
	return axios({
		url: `/Invoices/emailAccountStatus`,
		method: 'POST',
		params: { clientId, invoiceIds, debitNoteIds, exchangeRate, emails, replyTo, message }
	})
		.then(response => response.data);
};

export const PreviewAccountStatus = (clientId, invoiceIds, debitNoteIds, exchangeRate) => {
	return axios({
		url: `/Invoices/previewAccountStatus`,
		method: 'GET',
		responseType: 'blob',
		params: { clientId, invoiceIds, debitNoteIds, exchangeRate }
	})
		.then(response => response.data);
};

export const DownloadAccountStatus = (clientId, invoiceIds, clientName, debitNoteIds, exchangeRate) => {
	return PreviewAccountStatus(clientId, invoiceIds, debitNoteIds, exchangeRate)
		.then(response => {
			const url = window.URL.createObjectURL(new Blob([response]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Estado de Cuenta ${clientName}.pdf`);
			document.body.appendChild(link);
			link.click();
		});
};

export const DownloadMonthlyInvoiceReport = (month) => {
	const monthISO = moment(month).toISOString();
	return axios({
		url: `/Invoices/downloadMonthlyInvoiceReport`,
		method: 'GET',
		responseType: 'blob',
		params: { month: monthISO }
	})
		.then(response => {
			const filename = response.headers['cache-control'];
			const arr = filename.split(',');
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', arr[arr.length - 1]);
			document.body.appendChild(link);
			link.click();
		});
};

export const DownloadAutomaticInvoicePreview = () => {
	return axios({
		url: `/Invoices/downloadAutomaticInvoicePreview`,
		method: 'GET',
		responseType: 'blob'
	})
		.then(response => {
			const filename = response.headers['cache-control'];
			const arr = filename.split(',');
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', arr[arr.length - 1]);
			document.body.appendChild(link);
			link.click();
		});
};

export const InvoiceDevicesAreAvailableCheck = (invoiceId) => {
	return axios.get(`/InvoiceDevices/${invoiceId}/checkIfDevicesAreAvailable`)
		.then(response => response.data);
};

export const InvoiceDevicesAllHaveDescriptionsCheck = (invoiceId) => {
	return axios.get(`/InvoiceDevices/${invoiceId}/checkIfAllDevicesHaveDescriptions`)
		.then(response => response.data);
};

export const PreviewInvoiceDevicesDocument = (invoiceId) => {
	return axios({
		url: `/InvoiceDevices/${invoiceId}/downloadInvoiceDevicesDocument`,
		method: 'GET',
		responseType: 'blob'
	})
		.then(response => response.data);
};

export const DownloadInvoiceDevicesDocument = (invoiceId) => {
	return axios({
		url: `/InvoiceDevices/${invoiceId}/downloadInvoiceDevicesDocument`,
		method: 'GET',
		responseType: 'blob'
	})
		.then(response => {
			let filename = response.headers['cache-control'];
			let arr = filename.split(',');
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', arr[arr.length - 1]);
			document.body.appendChild(link);
			link.click();
		});
};

export const DownloadInvoiceAndDevicesDocuments = (invoiceId) => {
	const promises = [
		new Promise((resolve, reject) => {
			PrintEmailedInvoice(invoiceId)
				.then(() => resolve())
				.catch(() => reject());
		}),
		new Promise((resolve, reject) => {
			DownloadInvoiceDevicesDocument(invoiceId)
				.then(() => resolve())
				.catch(() => reject());
		})
	];
	return Promise.all(promises);
};

export const GetClientDevicesForInvoice = (optimusClientId = 0) => {
	return axios.get(`/InvoiceDevices/${optimusClientId}/getClientDevicesForInvoice`)
		.then(response => response.data);
};

export const GetDevicesForInvoice = (invoiceId) => {
	const filter = {
		where: { invoiceId },
		fields: ["deviceId"]
	};

	return axios.get(`/InvoiceDevices?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then(response => response.data.map(({deviceId}) => deviceId));
};

export const PreviewUnregisteredInvoiceDevicesDocument = (deviceIds = []) => {
	return axios({
		url: `/InvoiceDevices/downloadUnregisteredInvoiceDevicesDocument`,
		method: 'GET',
		responseType: 'blob',
		params: { deviceIds }
	})
		.then(response => response.data);
};

export const LoadInvoiceEditData = (invoiceId) => {
	return axios.get(`/Invoices/${invoiceId}/getInvoiceEditData`)
		.then(response => response.data);
};

export const UpdateInvoice = (invoiceId, newData) => {
	return axios.post(`/Invoices/${invoiceId}/edit`, { newData })
		.then(response => response.data);
};

export const SearchInvoiceDocumentNumbers = (docNumber) => {
	const filter = {
		order: "id ASC",
		where: { documentNumber: { like: "%" + docNumber + "%" } },
		fields: ["id", "documentNumber"],
		limit: 10,
	};

	return axios.get(`/Invoices?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then(response => response.data);
}

export const GetInvoiceRange = (initialInvoiceId, finalInvoiceId) => {
	const filter = {
		order: "id ASC",
		where: { id: { between: [initialInvoiceId, finalInvoiceId] } },
		fields: ["id", "documentNumber", "clientName", "status", "createdAt", "total", "exchangeRate"],
		limit: 500,
	};

	return axios.get(`/Invoices?filter=${encodeURIComponent(JSON.stringify(filter))}`)
		.then(response => response.data);
}

export const SendInvoiceToClient = (invoiceId, message, includeDevices = false) => {
	return axios({
		url: `/Invoices/${invoiceId}/emailToClient`,
		method: 'POST',
		params: { message, includeDevices }
	})
		.then(response => response.data);
}
