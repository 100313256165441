import React from 'react';
import PropTypes from 'prop-types';
import {getInvoicingMessages} from '../../constants/messages';
import { DatePicker, Select, Checkbox, Col, Space} from 'antd';
import {injectIntl, FormattedMessage} from 'react-intl';
import { STATUSES } from './constants';
import moment from 'moment';

let InvoicingMessages = getInvoicingMessages();

const messages = {
    ...InvoicingMessages
};

const Option = Select.Option;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

class InvoicingFilter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			status: 4,
			startDate: undefined,
			endDate: undefined,
			totalRange: false,
			taxRetention: false,
			taxRetentionConfirmation: false,
		};
	}
	
    onChangeSelect = (e) => {
		this.setState({
			status: e
		});
		let filter = {
			...this.state,
			status: e
		};
		this.props.onFilter(filter);
	}
	
	onChangeRange = (e) => {
		const startDate = e[0] ? moment(e[0]).startOf('day').toISOString() : undefined;
		const endDate = e[1] ? moment(e[1]).endOf('day').toISOString() : undefined;
		this.setState({ startDate, endDate });
		let filter = {
			...this.state,
			startDate,
			endDate
		};
		this.props.onFilter(filter);
	}

	onChangeCheckBox = () => {
		this.setState({
			totalRange: !this.state.totalRange
		});
		let filter = {
			...this.state,
			totalRange: !this.state.totalRange
		};
		this.props.onFilter(filter);
	}

	onChangeTaxRetentionCheckBox = () => {
		if(this.state.taxRetention === true){
			this.setState({ taxRetentionConfirmation: false });
		}
		this.setState({ taxRetention: !this.state.taxRetention });
		let filter = {
			...this.state,
			taxRetention: !this.state.taxRetention
		};
		this.props.onFilter(filter);
	}

	onChangeTaxRetentionConfirmationCheckBox = () => {
		if(this.state.taxRetention === true){
			this.setState({ taxRetentionConfirmation: !this.state.taxRetentionConfirmation });
			let filter = {
				...this.state,
				taxRetentionConfirmation: !this.state.taxRetentionConfirmation
			};
			this.props.onFilter(filter);
		}
	}

	render(){
		return(
			<div style={{align: 'left'}}>
				<Space direction="vertical" size="middle" style={{ display: 'flex' }}>
				<Col style={{align: 'left'}}>
					<RangePicker format={dateFormat} onChange={this.onChangeRange} style={{ align: 'left', width: '60%', 'marginRight': '6px'}}/>
					<Select onChange={this.onChangeSelect} defaultValue={4} style={{width: '35%', 'marginRight': '6px'}}>
						<Option value={4}><FormattedMessage {...messages.All} /></Option>
						<Option value={STATUSES.ACTIVE}><FormattedMessage {...messages.Active} /></Option>
						<Option value={STATUSES.PAID}><FormattedMessage {...messages.Paid} /></Option>
						<Option value={STATUSES.OVERDUE}><FormattedMessage {...messages.Overdue} /></Option>
						<Option value={STATUSES.ANNULLED}><FormattedMessage {...messages.Annulled} /></Option>
					</Select>
				</Col>
				

				
					<Col>
						<Checkbox onChange={this.onChangeTaxRetentionCheckBox}><FormattedMessage {...messages.TaxRetention} /></Checkbox>
						<Checkbox checked ={this.state.taxRetentionConfirmation} onChange={this.onChangeTaxRetentionConfirmationCheckBox} disabled={!this.state.taxRetention}><FormattedMessage {...messages.ConfirmRetentionSearch} /></Checkbox>
						<Checkbox onChange={this.onChangeCheckBox}><FormattedMessage {...messages.ExactTotal} /></Checkbox>
					</Col>
				</Space>
				
			</div>
			
		);
	}
}

InvoicingFilter.propTypes = {
	onFilter: PropTypes.func,
};

export default injectIntl(InvoicingFilter);