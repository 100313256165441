export const includeIHSS = false;

export const MEDICALDEDUCTIONS = {
    IHSS: {
        id: 1,
        name: "IHSS_DEDUCTION",
    },
    MEDICAL: {
        id: 2,
        name: "MEDICAL_EXPENSES",
    },
};

export const DEDUCTIONS = {
    INSURANCE: {
        id: 1,
        percentage: 5,
        intl: "medicalInsurance"
    },
    PHONE: {
        id: 2,
        percentage: 3,
        intl: "phone"
    },
    COOPERATIVE: {
        id: 3,
        percentage: 10,
        intl: "cooperative"
    }
};

export const calculatePercentage = (total, percentage) => (total * percentage) / 100;

export const formatPrice = (price) => {    
    if (isNaN(parseFloat(price)))
        return "NaN";
    
    let formatedPrice = parseFloat(price).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    return `L. ${formatedPrice}`;
}

export const columnTemplate = (title, dataIndex, render = undefined) => { return { title, dataIndex, key: dataIndex, render }; }