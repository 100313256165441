import React from 'react';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import AddExchangeRateForm from './AddExchangeRateForm';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getactionMessages, getExchangeRateMessages } from '../../constants/messages';
import { connect } from 'react-redux';
import { GetExchangeRate } from './actions';
let actionMessages = getactionMessages();
let ExchangeRateMessages = getExchangeRateMessages();

const messages = {
	...actionMessages,
	...ExchangeRateMessages
};

class AddExchangeRateButton extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			showExchangeRateModal: false
		}
	}

	openModal = () => {
		this.props.getExchangeRate().then(() => {
			this.setState({
				showExchangeRateModal: true
			});
		}).catch(() => {
			message.error(`${this.props.intl.formatMessage({ ...messages.errorObtainingExchangeRate })}`);
			this.setState({
				showExchangeRateModal: true
			});
		});

	}

	handleCancel = () => {
		this.setState({
			showExchangeRateModal: false
		});
	}

	render() {
		return (
            <span>
				<Button className="editable-add-btn" type="primary" onClick={this.openModal}>
					<PlusOutlined />
					<FormattedMessage {...messages.add} />
				</Button>
				<AddExchangeRateForm
					showModal={this.state.showExchangeRateModal}
					handleCancel={this.handleCancel}
				/>
			</span>
        );
	}
}

AddExchangeRateButton.propTypes = {
	intl: PropTypes.object.isRequired,
	getExchangeRate: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
	return {
		getExchangeRate: () => {
			return dispatch(GetExchangeRate());
		}
	};
};

export default connect(null, mapDispatchToProps)(injectIntl(AddExchangeRateButton));