import { getObjectInObject } from '../../utilities/util';
const moment = require('moment');
import * as HD from 'date-holidays';
const country = 'HN';
const Holidays = new HD.default();
Holidays.init(country);

export const DEVICESTATUS = {
    ACTIVE: 1,
    HIDDEN: 2,
    SUSPENDED: 3,
    CANCELLED: 4
};

export const CHECKINTYPES = {
	INVOICE: 0,
	PROFORMA: 1,
};

export const unit_icons = 1;

export const JOBTYPES = {
    INSTALLATION: {
        id: 1,
        intl: "installation",
        textColor: "black",
        backgroundColor: "#b7efff"
    },
    REVISION: {
        id: 2,
        intl: "revision",
        textColor: "black",
        backgroundColor: "#ffd0a8"
    },
    UNINSTALLATION: {
        id: 3,
        intl: "uninstallation",
        textColor: "black",
        backgroundColor: "#ffb1b1"
    },
    SWAP: {
        id: 4,
        intl: "swap",
        textColor: "black",
        backgroundColor: "#b19cd9"
    },
    ADDONS: {
        id: 5,
        intl: "addonsJobType",
        textColor: "black",
        backgroundColor: "#99ffa0"
    },
    SIMCHANGE: {
        id: 6,
        intl: "simChange",
        textColor: "black",
        backgroundColor: "#ead3f8"
    },
    TRANSFER: {
        id: 7,
        intl: "transfer",
        textColor: "black",
        backgroundColor: "#8a8583"
    },
    RECONNECTION: {
        id: 8,
        intl: "reconnection",
        textColor: "black",
        backgroundColor: "#cca483"
    },
    DEACTIVATE: {
        id: 9,
        intl: "deactivate",
        textColor: "black",
        backgroundColor: "#41AAC4"
    },
    ACTIVATE: {
        id: 10,
        intl: "activate",
        textColor: "black",
        backgroundColor: "#eed7a1"
    }
};

export const JOBSTATUS = {
	TODO: {
        id: 1,
        intl: "todo",
        iconColor: "#cdcdcd"
    },
	DOING: {
        id: 2,
        intl: "doing",
        iconColor: "#1890ff"
    },
	MISSINGWON: {
        id: 3,
        intl: "missingWON",
        iconColor: "#faad14"
    },
	DONE: {
        id: 4,
        intl: "done",
        iconColor: "#52c41a"
    },
	CANCELLED: {
        id: 5,
        intl: "cancelled",
        iconColor: "#f5222d"
    },
	ANNULLED: {
        id: 6,
        intl: "annulled",
        iconColor: "#dc00f9"
    },
    INVOICED: {
        id: 7,
        intl: "invoiced",
        iconColor: "#4b9925"
    },
    PROFORMA: {
        id: 8,
        intl: "proforma",
        iconColor: "#4b9925"
    },
};

export const CHARGETYPES = {
	BOUGHT: {
        id: 1,
        intl: "bought",
        productId: 1,
    },
	LEASED: {
        id: 2,
        intl: "leased",
        productId: 19,
    },
	REPLACEMENT: {
        id: 3,
        intl: "replacement",
        productId: 24,
    },
	PERCUT: {
        id: 4,
        intl: "perCut",
        productId: 25,
    },
	NEWCLIENT: {
        id: 5,
        intl: "newClient",
        productId: 15,
    },
    TRANSFER: {
        id: 6,
        intl: "transfer",
        productId: 16,
    },
    PREVIOUSCLIENT: {
        id: 7,
        intl: "previousClient",
        productId: 15,
    },
    UNINSTALLATION: {
        id: 8,
        intl: "uninstallation",
    },
    REVISION: {
        id: 9,
        intl: "revision",
    },
    ACTIVATE: {
        id: 10,
        intl: "activate",
    }
};

export const EDITABLETYPES = [
    JOBTYPES.INSTALLATION.id,
    JOBTYPES.TRANSFER.id,
    JOBTYPES.RECONNECTION.id,
    JOBTYPES.SWAP.id
];

export function getJobType(id) {
    let TYPE = getObjectInObject("id", id, JOBTYPES);
    if (TYPE === undefined)
        return ({
            id: -1,
            intl: "typeDefault",
            textColor: "black",
            backgroundColor: "#cdcdcd"
        });
    return TYPE;
}

export function getJobStatus(id) {
    let STATUS = getObjectInObject("id", id, JOBSTATUS);
    if (STATUS === undefined)
        return ({
            id: -1,
            intl: "statusDefault",
            iconColor: "#cdcdcd"
        });
    return STATUS;
}

export function getChargeType(id, field) {
    let TYPE = getObjectInObject(field, id, CHARGETYPES);
    if (TYPE === undefined)
        return ({
            id: -1,
            intl: "typeDefault",
            productId: 0
        });
    return TYPE;
}

function getPassedDays(date) {
    let jobDate = moment(date);
    let now = moment();
    if (date === undefined || date === null || !jobDate.isValid())
        return undefined;
    return moment.duration(now.diff(jobDate)).asDays();
}

export function pastFiveDays(date) {
    let daysPassed = getPassedDays(date);
    if (daysPassed === undefined)
        return false;
    return daysPassed > 5;
    //return new Date('2019-1-1') > new Date(date);
}

export function pastDueDate(date) {
    let daysPassed = getPassedDays(date);
    if (daysPassed === undefined)
        return false;
    return daysPassed > 0;
}

export function getYearHolidays() {
    const year = new Date().getFullYear();
    const holidays = Holidays.getHolidays(year);
    return holidays;
}

export function isHoliday (date) {
    return Holidays.isHoliday(new Date(date));
}

export function hasHolidaysInTheFuture (date) {
    const currentDate = new Date(date);
    const days = 7;
    for (let daysAdded = 0; daysAdded <= days; daysAdded++) {
        let dateToCheck = new Date(currentDate);
        dateToCheck.setDate(dateToCheck.getDate() + daysAdded);
        let check = isHoliday(dateToCheck);
        if(check) return true;
    }
    return false;
}

export function momentToDuration (value) {
    if (!value) return 0;
    const start = moment(value).startOf('day');
    const duration = Math.floor(moment.duration(value.diff(start)).asMinutes());
    return duration;
}

export function parseJobValues (values = { }) {
    const copy = { ...values };
    delete copy.comment;
    if (copy.programmedDate) copy.programmedDate = copy.programmedDate.toISOString();
    if (copy.duration) copy.duration = momentToDuration(copy.duration);
    return copy;
}