import React from 'react';
import PropTypes from 'prop-types';
import { UserDeleteOutlined } from '@ant-design/icons';
import { message, Table, Badge, Spin, Button } from 'antd';
import { injectIntl } from 'react-intl';
import { getClientMessages, getFields, getLanguageIntl } from '../../../../constants/messages';
import { GetClientOptimusAccounts } from '../../actions';
import moment from 'moment';

let clientMessages = getClientMessages(),
	intlMessages = getLanguageIntl(),
	fieldMessages = getFields();

const messages = {
	...clientMessages,
	...intlMessages,
	...fieldMessages
};

class ClientAccountsView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			accounts: [],
			showInactiveAccounts: false
		};
	}

	componentDidMount() {
		const { props, getIntl, setLoading } = this;
		const { clientId } = props;
		setLoading(true);
		GetClientOptimusAccounts(clientId)
			.then(accounts => {
				setLoading(false);
				this.setState({ accounts });
			})
			.catch(() => {
				setLoading(false);
				message.error(getIntl("getClientAccountsError"));
			});
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.setState({ isLoading });
	toggleShowInactiveAccounts = () => this.setState({ showInactiveAccounts: !this.state.showInactiveAccounts });

	formatFullDate = (unformatedDate) => {
		if (!unformatedDate) return null;
		const { getIntl } = this;
		const locale = getIntl("intl");
		return moment(unformatedDate).locale(locale).format('MMMM DD, YYYY, h:mm a');
	}

	renderTable = () => {
		const { state, getIntl, formatFullDate } = this;
		const { accounts, showInactiveAccounts } = state;
		let dataSource = [];
		const columns = [
			{
				title: getIntl("name"),
				dataIndex: 'name',
				key: 'name'
			},
			{
				title: getIntl("active"),
				dataIndex: 'active',
				key: 'active',
				render: active => <Badge status={active ? "success" : "default"} />
			},
			{
				title: getIntl("creationDate"),
				dataIndex: 'createdAt',
				key: 'createdAt',
				render: createdAt => formatFullDate(createdAt)
			},
		];
		if (showInactiveAccounts) dataSource = accounts.map(account => ({ ...account, key: account.id }));
		else {
			accounts.forEach(account => {
				const { active } = account;
				if (active) dataSource.push({ ...account, key: account.id });
			});
			columns.splice(1, 1);
		}
		return <Table dataSource={dataSource} columns={columns} pagination={false} size="small" />;
	}

	renderShowInactiveAccountsButton = () => {
		const { state, getIntl, toggleShowInactiveAccounts } = this;
		const { showInactiveAccounts } = state;
		return (
            <Button key='show' style={{ marginBottom: "10px" }} type='primary' block icon={<UserDeleteOutlined />} onClick={toggleShowInactiveAccounts}>
				{getIntl(showInactiveAccounts ? "hideInactiveAccounts" : "showInactiveAccounts")}
			</Button>
        );
	}

	fullRender = () => {
		const { state, renderTable, renderShowInactiveAccountsButton } = this;
		const { isLoading } = state;
		return (
			<Spin spinning={isLoading}>
				{renderShowInactiveAccountsButton()}
				{renderTable()}
			</Spin>
		);
	}

	render() {
		return this.fullRender();
	}
}

ClientAccountsView.propTypes = {
	intl: PropTypes.object.isRequired,
	clientId: PropTypes.number.isRequired
};

export default injectIntl(ClientAccountsView);