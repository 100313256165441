import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { message, Button, Spin } from 'antd';
import { injectIntl } from 'react-intl';
import { getPayrollMessages, getactionMessages, getFields } from '../../../../constants/messages';
import { GetDeductions } from '../../actions';
import DeductionsTable from './DeductionsTable';
import DeductionsModal from './DeductionsModal';

let payrollMessages = getPayrollMessages(),
    actionmessages = getactionMessages(),
    fieldMessages = getFields();

const messages = {
    ...payrollMessages,
    ...actionmessages,
    ...fieldMessages
};

class DeductionNames extends React.Component  {

	constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            deduction: {},
            showModal: false
        };
    }

    componentDidMount(){
        this.getRecords();
    }

	getIntl = (str) => {
		return this.props.intl.formatMessage({...messages[str]});
    }

    setLoading = (isLoading) => {
        this.setState({ isLoading });
    }

	getRecords = () => {
        const { getIntl, setLoading, props } = this;
		const { getDeductions } = props;
        setLoading(true);
		getDeductions()
		.then(() => setLoading(false))
		.catch(() => {
            setLoading(false);
			message.error(getIntl("getDeductionsError"));
		});
	}

    getColumns = () => {
        const { getIntl, renderEditButton } = this;
        return (
            [
                {
                    title: getIntl("name"),
                    dataIndex: 'name',
                    key: 'name'
                },
                {
                    title: getIntl("actions"),
                    render: (deduction) => renderEditButton(deduction)
                }
            ]
        );
    }

    renderTable = () => {
        const { state, props, getColumns } = this;
        const { isLoading } = state;
        const { deductions } = props;
        const dataSource = deductions;
        const count = dataSource.length;
        const columns = getColumns();
        return <DeductionsTable dataSource={dataSource} columns={columns} count={count} isLoading={isLoading} pageSize={count}/>
    }

    openAddModal = () => {
        this.setState({
            deduction: {},
            showModal: true
        });
    }

    openEditModal = (deduction) => {
        this.setState({
            deduction,
            showModal: true
        });
    }

    closeModals = () => {
        this.setState({
            deduction: {},
            showModal: false
        });
    }

    renderAddButton = () => {
        const { getIntl, openAddModal } = this;
        return (
            <Button type="primary" onClick={openAddModal}>
                <PlusOutlined />{getIntl("add")}
            </Button>
        ); 
    }

    renderEditButton = (deduction) => {
        const { getIntl, openEditModal } = this;
        return (
            <a onClick={() => openEditModal(deduction)}>
                <span className="icon-pencil" /> {getIntl("edit")}
            </a>
        );
    }

    renderModal = () => {
        const { state, setLoading, closeModals } = this;
        const { isLoading, deduction, showModal } = state;
        return <DeductionsModal isLoading={isLoading} deduction={deduction} showModal={showModal} setLoading={setLoading} handleCancel={closeModals}/>;
    }

    fullRender = () => {
        const { state, renderTable, renderAddButton, renderModal } = this;
        const { isLoading } = state;
        return (
            <Spin spinning={isLoading}>
                {renderModal()}
                {renderAddButton()}
                {renderTable()}
            </Spin>
        );
    }

	render(){
        return this.fullRender();
	}
}

DeductionNames.defaultProps = {
	deductions: []
};

DeductionNames.propTypes = {
	intl: PropTypes.object.isRequired,
	getDeductions: PropTypes.func.isRequired,
	deductions: PropTypes.array.isRequired
};

const mapStateToProps = (state) => {
	return {
		deductions: state.payroll.deductions
	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		getDeductions: () => dispatch( GetDeductions() )
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DeductionNames));