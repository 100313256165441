import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { message, Spin,  } from 'antd';
import { useIntl } from 'react-intl';
import { getEmployeeMessages, getDepartmentMessages, getModalMessages, getPayrollMessages } from '../../../constants/messages';
import { GetEmployeeInfo } from '../actions';
import EmployeeAddEditForm from './EmployeeAddEditForm';
import { useDispatch  } from 'react-redux';
import { GetAllDepartments } from '../../Departments/actions';
import { GetDeductions } from '../../Payroll/actions';
import "../Styles/styles.css"


let employeeMessages = getEmployeeMessages(),
	modalMessages = getModalMessages(),
	departmentMessages = getDepartmentMessages(),
	payrollMessages = getPayrollMessages();

const messages = {
	...employeeMessages,
	...modalMessages,
	...departmentMessages,
	...payrollMessages
};

const EmployeeAddEdit = ({match}) => {
	const [loading, setLoading] = useState(true);
	const [employee, setEmployee] = useState({});
	const [Dependants,setDependants] = useState([])
	const [emergencyPhones, setEmergencyPhones] = useState([]);
	const [deductions, setDeductions] = useState([]);
	const dispatch = useDispatch();
	const intl = useIntl();
	const getIntl = (str) => {
		return intl.formatMessage({...messages[str]});
	}

	useEffect(() => {
		const loadData = async() => {
			try {
				await dispatch(GetAllDepartments());
				await dispatch(GetDeductions());
				if (match.params.id !== undefined) {
				const response = await GetEmployeeInfo(match.params.id);
				setEmployee(response);
				setDependants(response.dependants);
				setEmergencyPhones(response.emergency);
				setDeductions(response.deductions);
				}
			
			} catch (error) {
				message.error(getIntl("getEmployeeInfoError"));
			}finally{
				setLoading(false);
			}
		}
		loadData();
	}, []);
	
  return (
	<div>

   		{loading === true 
			? 
			<Spin spinning={loading} size="large" className="custom-spin"/>
			: 
			<EmployeeAddEditForm 
				employeeData={employee}
				saveEmployeeData={setEmployee}
				saveDependants={setDependants} 
				dependants={Dependants}
				emergencyPhones={emergencyPhones}
				saveEmergencyPhones={setEmergencyPhones}
				deductions={deductions}
				saveDeductions = {setDeductions}
			/>
		}

	</div>
  )
}

export default EmployeeAddEdit

EmployeeAddEdit.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.shape({
		params: PropTypes.object.isRequired
	}).isRequired,
    
};