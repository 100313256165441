import React from 'react';
import PropTypes from 'prop-types';
import { Button, message } from 'antd';
import { connect } from 'react-redux';
import { ConnectToRabbit, Subscribe, Publish } from '../../../actions/RabbitAction';
import { injectIntl } from 'react-intl';
import { ApproveAndLink } from '../actions';
import { CheckUsernameExistence, CheckEmailExistance } from '../../../actions/OptimusActions';
import { getModalMessages, getClientMessages } from '../../../constants/messages';
import { REGEX } from '../../../constants/global';

let modalMessages = getModalMessages(),
	ClientMessages = getClientMessages();

const messages = {
	...modalMessages,
	...ClientMessages
};

class RabbitConnect extends React.Component {

	componentDidMount() {
		this.props.connectToRabbit();
	}

	componentWillUnmount() {
		window.client.end();
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	generateUserName = () => {
		const { clientName } = this.props;
		let cleanedName = clientName.replace(REGEX.specialCharacters, '');
		cleanedName = cleanedName.replace(/ñ/g, 'n');
		let split = cleanedName.split(" ");
		let username = "";
		for (let index = 0; index < split.length; index++) {
			const element = split[index];
			if (index === split.length - 1) {
				username += element;
			} else {
				username += element.charAt(0);
			}
		}
		return username.toLowerCase();
	}

	handleRabbitMessage = (data) => {
		const { intl, setState, clientId, approveAndLink, handleCancel } = this.props;
		let msg = JSON.parse(data);
		if (msg.error) {
			message.error(intl.formatMessage({ ...messages.createClientError }));
			setState({ loading: false });
		} else if (msg.id !== undefined) {
			window.client.end();
			const optimusClientId = msg.id;
			const activityMessage = intl.formatMessage({ ...messages.clientApproved });
			approveAndLink(clientId, optimusClientId, activityMessage)
				.then(() => {
					message.success(intl.formatMessage({ ...messages.clientLinkSuccess }));
					handleCancel();
				})
				.catch(() => {
					message.error(intl.formatMessage({ ...messages.cantLinkClients }));
					setState({ loading: false });
				});
		}
	}

	userRecursiveCheck = (username, count = 0) => {
		const { checkUsernameExistence } = this.props;
		let newUsername = username + (count !== 0 ? count : "");
		return checkUsernameExistence(newUsername)
			.then((response) => {
				if (response === 0)
					return newUsername;
				else {
					return this.userRecursiveCheck(username, count + 1);
				}
			});
	}

	newUsername = () => {
		return this.userRecursiveCheck(this.generateUserName());
	}

	emailRecursiveCheck = (index = 0) => {
		const { checkEmailExistance, emails } = this.props;
		if (index >= emails.length) {
			return -1;
		}
		let email = emails[index].email;
		return checkEmailExistance(email)
			.then((response) => {
				if (response === 0)
					return email;
				else {
					return this.emailRecursiveCheck(index + 1);
				}
			});
	}

	newEmail = () => {
		return this.emailRecursiveCheck();
	}

	generateObjectToSend = () => {
		const { intl, clientName } = this.props;
		const { newUsername, newEmail } = this;
		let username = "";
		let email = "";
		let promises = [];
		promises.push(
			new Promise(function (resolve, reject) {
				newUsername()
					.then((response) => {
						username = response;
						resolve();
					})
					.catch(() => {
						reject(intl.formatMessage({ ...messages.createClientUsernameError }));
					});
			})
		);
		promises.push(
			new Promise(function (resolve, reject) {
				newEmail()
					.then((response) => {
						if (response === -1)
							reject(intl.formatMessage({ ...messages.createClientEmailMissing }));
						email = response;
						resolve();
					})
					.catch(() => {
						reject(intl.formatMessage({ ...messages.createClientEmailError }));
					});
			})
		);
		return Promise.all(promises)
			.then(function () {
				return ({
					name: clientName,
					username: username,
					email: email
				});
			});
	}

	publishToRabbit = (data) => {
		Publish(this.props.code, JSON.stringify(data));
	}

	onClick = () => {
		const { props, getIntl, handleRabbitMessage, generateObjectToSend, publishToRabbit } = this;
		const { setState, code } = props;
		if (!code || !(/\S/.test(code))) return message.error(getIntl("tryingToCreateClientWithNoCodeError"));
		Subscribe(code, handleRabbitMessage);
		setState({ loading: true });
		generateObjectToSend()
			.then(response => publishToRabbit(response))
			.catch(error => {
				if (typeof error === 'string') message.error(error);
				else message.error(getIntl("createClientDataError"));
				setState({ loading: false });
			});
	}

	render() {
		const { intl, state } = this.props;
		const { loading } = state;
		return (
			<Button type="primary" loading={loading} onClick={this.onClick}>{intl.formatMessage({ ...messages.connect })}</Button>
		);
	}
}

RabbitConnect.propTypes = {
	intl: PropTypes.object.isRequired,
	state: PropTypes.object.isRequired,
	setState: PropTypes.func.isRequired,
	code: PropTypes.string.isRequired,
	connectToRabbit: PropTypes.func.isRequired,
	clientName: PropTypes.string.isRequired,
	emails: PropTypes.array.isRequired,
	clientId: PropTypes.number.isRequired,
	approveAndLink: PropTypes.func.isRequired,
	checkUsernameExistence: PropTypes.func.isRequired,
	checkEmailExistance: PropTypes.func.isRequired,
	handleCancel: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
	return {
		connectToRabbit: () => dispatch(ConnectToRabbit()),
		approveAndLink: (clientId, linkedClientId, activityMessage) => dispatch(ApproveAndLink(clientId, linkedClientId, activityMessage)),
		checkUsernameExistence: (username) => dispatch(CheckUsernameExistence(username)),
		checkEmailExistance: (email) => dispatch(CheckEmailExistance(email)),
	};
};

export default connect(null, mapDispatchToProps)(injectIntl(RabbitConnect));