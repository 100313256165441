import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { getModalMessages, getEmployeeMessages } from '../../../constants/messages';
import EditDeductionForm from './EditDeductionForm';

let modalMessages = getModalMessages(),
    employeeMessages = getEmployeeMessages();

const messages = {
	...modalMessages,
	...employeeMessages
};

const EditDeductionModal = ({isLoading, showModal, amount, handleSave, handleCancel}) => {
	const [value, setValue] = useState(0);
	const [form] = Form.useForm();
	const intl = useIntl();

	useEffect(() => {
		form.resetFields();
		setValue(amount);
	}, [amount]); 
	const handleOk = async () => {
		const values = await form.validateFields()
		const { amount } = values;
		handleSave(amount);
	}

	let buttons = [
		<Button
			style={{ marginLeft: 8 }}
			key="cancel"
			onClick={handleCancel}
			loading={isLoading}
		>
		  <FormattedMessage {...messages.cancel} />
		</Button>,
		
		<Button
			type="primary"
			key="submit"
			onClick={handleOk}
			loading={isLoading}
		>
		  <FormattedMessage {...messages.save} />
		</Button>
	];
	return(
		<Modal
			open={showModal}
			title={intl.formatMessage({...messages.editDeduction})}
			onOk={handleOk}
			onCancel={handleCancel}
			destroyOnClose={true}
			footer={buttons}
			maskClosable={false}
		>
			<Form form={form}>
				<EditDeductionForm amount={value} />
			</Form>
		</Modal>
	)
}

EditDeductionModal.defaultProps = {
	showModal: false,
	amount: 0,
	isLoading: false
};

EditDeductionModal.propTypes = {
    isLoading: PropTypes.bool.isRequired,
	showModal: PropTypes.bool.isRequired,
	amount: PropTypes.number.isRequired,
    handleSave: PropTypes.func.isRequired,
	handleCancel: PropTypes.func.isRequired
};
export default EditDeductionModal
