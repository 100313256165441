import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { Modal, Button, message } from 'antd';
import AddOrEditForm from './AddOrEditForm';
import { LoadingOutlined, SaveOutlined } from '@ant-design/icons';
import { getModalMessages, getLotsMessages, getUnitsMessages } from '../../constants/messages';
import { GetUnitsAvailable } from '../Units/actions';
import { AddLot } from './actions';
import { GetLots } from '../Lots/actions';

let ModalMessages = getModalMessages(),
    UnitsMessages = getUnitsMessages(),
    LotsMessages = getLotsMessages();

const messages = {
	...ModalMessages,
    ...LotsMessages,
    ...UnitsMessages,
};

const AddOrEditModal = ({onCancel, title, visible, employees}) => {
    const intl = useIntl();
	const form = useRef();
	const dispatch = useDispatch();
    const [isSaving, setSaving] = useState(false);
    const [units, setUnits] = useState([]);
    const [lots, setLots] = useState([]);
    const [isLoading, setisLoading] = useState(true);
   
    const closeModal = () => {
        setisLoading(true)
        onCancel();
	};

    useEffect(() => {
		(async () => {
            if (visible) {
                try {
                    const units = await GetUnitsAvailable();
                    setUnits(units);
                    const lots = await GetLots();
                    setLots(lots);
                    setisLoading(false);
                } catch (error) {
                    message.error(intl.formatMessage({ ...messages.getLotsError }));
                }
            }
        })();	
    }, [visible]);

    const onOk = async () => {
		try {
			setSaving(true);
            const values = await form.current.validateFields();	
            const lot = lots.filter((lot) => lot.id == values.lotId);
            await dispatch(AddLot(values, lot));
            message.success(intl.formatMessage({...messages.addUnitLotSuccess }, {id: values.lotId}));
            closeModal();
		} catch (error) {
            message.error(intl.formatMessage({...messages.addUnitLotError }));
		} finally {
			setSaving(false);
		}
	};

    let buttonGroup = [
		<Button key="cancel" onClick={closeModal}>
			<FormattedMessage {...messages.cancel}  />
		</Button>,
		<Button type="primary" key="save" onClick={onOk}>
			{isSaving ? <LoadingOutlined /> : <SaveOutlined />}
			<FormattedMessage {...messages.save}  />
		</Button>,
	];

    return (
        <Modal
            open={visible}
            title={title}
            onOk={onOk}
            onCancel={closeModal}
            destroyOnClose={true}
            footer={buttonGroup}
        >
            <div>
                <AddOrEditForm 
                    form={form}
                    loading={isLoading}
                    unitsAvailable={units}
                    existingLots={lots}
                    employeesActive={employees}
                />
            </div>
        </Modal>
    );
};

AddOrEditModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string,
    visible: PropTypes.bool,
    employees: PropTypes.array,
};

export default AddOrEditModal;
