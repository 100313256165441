import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

const DateFilter = ({ onFilter }) => {

    const onChangeRange = (dateRange) => {
        let filter = {};
        if(dateRange){
            const startDate = moment(dateRange[0]).startOf('day').toISOString();
            const endDate = moment(dateRange[1]).endOf('day').toISOString();
            filter = {startDate, endDate};
        }
		onFilter(filter);
	};

    return (
        <div>
            <RangePicker 
                format={dateFormat}
                onChange={onChangeRange} 
            />
        </div>
    )
}

DateFilter.propTypes = {
	onFilter: PropTypes.func,
};

export default DateFilter;
