import React from 'react';
import PropTypes from 'prop-types';
import {
    injectIntl,
    FormattedMessage
} from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Modal, Input, Select, message } from 'antd';
import {
    getModalMessages,
    getProformaMessages,
    getErrorMessages
} from '../../constants/messages';
import { connect } from 'react-redux';
import { DenyProforma, GetProformas } from './actions';
import Mayre from 'mayre';

const { TextArea } = Input;

let modalMessages = getModalMessages();
let proformaMessages = getProformaMessages();
let errorMessages = getErrorMessages();

const messages = {
    ...modalMessages,
    ...proformaMessages,
    ...errorMessages
};

const FormItem = Form.Item;
const Option = Select.Option;

class DenyProformaModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: 0,
            isLoading: false
        };
    }

    annullmentReasons = () => {
        return(
			<Select
				onSelect={this.handleSelectReason}
				style={{ width: '100%' }}
			>
                <Option value={0}>Problema con la impresión.</Option>
                <Option value={1}>Problema con el cliente.</Option>
                <Option value={2}>Datos erróneos.</Option>
                <Option value={3}>Otro.</Option>
			</Select>
		);
    }

    handleSelectReason = (reason) => {
        this.setState({
            selectedOption: reason
        });
    }

    handleOk = () => {
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }

            this.setState({
                isLoading: true
            });

			this.props.denyProforma(this.props.proformaId, values.reason).then(() => {
                this.props.getProformas().catch(() => {
                    message.error(`${this.props.intl.formatMessage({ ...messages.GetProformasError })}`);
                });
                this.props.closeDenyModal();
                this.setState({
                    isLoading: false
                });
			}).catch(() => {
                message.error(`${this.props.intl.formatMessage({ ...messages.DenyProformaError })}`);
                this.setState({
                    isLoading: false
                });
            });
		});
    }
    
    handleCancel = () => {
        this.props.closeDenyModal();
    }

    renderForm = () => {
        const { getFieldDecorator } = this.props.form;
        return(
            <div>
                <Form layout='vertical'>
                    <FormItem label={this.props.intl.formatMessage({ ...messages.Reason })}>
                        {getFieldDecorator('reason', {
                            rules: [{
                            required: false,
                            }]
                        })(
                            <TextArea rows={4} />
                        )}
                    </FormItem>
                    <Mayre
                        of={
                            <FormItem label="">
                                {getFieldDecorator('customReason', {
                                    rules: [{
                                    required: false,
                                    }]
                                })(
                                    <TextArea rows={4} />
                                )}
                            </FormItem>
                        }when={this.state.selectedOption === 3}
                    />
                   
                </Form>
            </div>
        );
    }

    render(){
        return(
            <Modal
                title={this.props.intl.formatMessage({ ...messages.Deny })}
                destroyOnClose
                open={this.props.showModal}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={
                    <div>
                        <Button	type="primary" size="large" loading={this.state.isLoading} onClick={this.handleOk} className="button-spacing">
                            <FormattedMessage {...messages.Deny} />
                        </Button>
                        <Button key="back" size="large" onClick={this.handleCancel}>
                            <FormattedMessage {...messages.cancel} />
                        </Button>
                    </div>
                    }
            >
                <div>
                    {this.renderForm()}
                </div>
            </Modal>
    );
    }

}

DenyProformaModal.propTypes = {
    intl: PropTypes.object.isRequired,
    showModal: PropTypes.bool.isRequired,
    closeDenyModal: PropTypes.func.isRequired,
    proformaId: PropTypes.number.isRequired,
    denyProforma: PropTypes.func.isRequired,
    getProformas: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => {
    return {
        denyProforma: (id, reason) => {
            return dispatch(DenyProforma(id, reason));
        },
        getProformas: () => {
            return dispatch(GetProformas());
        },
    };
};

export default connect(null , mapDispatchToProps)(injectIntl(Form.create()(DenyProformaModal)));