import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { injectIntl } from 'react-intl';
import { getClientMessages, getFields, getLanguageIntl } from '../../../../constants/messages';
import { GetClientProgrammedDeactivationData, SaveClientProgrammedDeactivationDate, ClearClientProgrammedDeactivation } from '../../actions'
import { getNestedValue } from '../../../../utilities/util';
import PDDatePicker from './DatePicker';
import moment from 'moment';
import AccountTable from './AccountTable';

let clientMessages = getClientMessages(),
	intlMessages = getLanguageIntl(),
	fieldMessages = getFields();

const messages = {
	...clientMessages,
	...intlMessages,
	...fieldMessages
};

class PDView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			clientData: {}
		};
	}

	componentDidMount() {
		this.loadClientData();
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);

	loadClientData = () => {
		const { props, getIntl } = this;
		const { clientId, setLoading } = props;
		setLoading(true);
		GetClientProgrammedDeactivationData(clientId)
			.then(clientData => {
				setLoading(false);
				this.setState({ clientData });
			})
			.catch(() => {
				setLoading(false);
				message.error(getIntl("getClientProgrammedDeactivationDataError"));
			});
	}

	formatFullDate = (unformatedDate) => {
		if (!unformatedDate) return undefined;
		const { getIntl } = this;
		const locale = getIntl("intl");
		return moment(unformatedDate).locale(locale).format('MMMM DD, YYYY, h:mm a');
	}

	renderTable = () => {
		const { clientData } = this.state;
		const accounts = getNestedValue('client.accounts', clientData) || [];
		const data = accounts.map(account => {
			const { id, name, disableConfirmation = {} } = account;
			const { firstDateConfirmation, dateBeforeConfirmation, sameDateConfirmation } = disableConfirmation;
			return { id, name, firstDateConfirmation, dateBeforeConfirmation, sameDateConfirmation };
		});
		return <AccountTable data={data} />;
	}

	onDateSave = (newDate) => {
		const { props, getIntl, loadClientData } = this;
		const { setSaving, setLoading, clientId } = props;
		setSaving(true);
		setLoading(true);
		const actionFunc = newDate ? SaveClientProgrammedDeactivationDate : ClearClientProgrammedDeactivation;
		actionFunc(clientId, newDate)
			.then(() => {
				setSaving(false);
				loadClientData();
			})
			.catch(() => {
				setSaving(false);
				setLoading(false);
				message.error(getIntl("updateClientProgrammedDeactivationError"));
			});
	}

	renderDatePicker = () => {
		const { state, props, onDateSave } = this;
		const { clientData } = state;
		const { isLoading } = props;
		const disableDate = getNestedValue('client.disableDate.date', clientData);
		return <PDDatePicker onSave={onDateSave} disableDate={disableDate} isLoading={isLoading} />;
	}

	fullRender = () => {
		const { state, renderTable, renderDatePicker } = this;
		const { clientData } = state;
		const disableDate = getNestedValue('client.disableDate.date', clientData);
		return (
			<div>
				{renderDatePicker()}
				{disableDate ? renderTable() : null}
			</div>
		);
	}

	render() {
		return this.fullRender();
	}
}

PDView.propTypes = {
	intl: PropTypes.object.isRequired,
	clientId: PropTypes.number.isRequired,
	setLoading: PropTypes.func.isRequired,
	setSaving: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

export default injectIntl(PDView);