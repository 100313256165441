import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Modal, Input, message, Col } from 'antd';
import { connect } from "react-redux";
import { injectIntl } from 'react-intl';
import { getInvoicingMessages, getModalMessages } from '../../constants/messages';
import React from 'react';
import { CreateDepositary } from '../Invoices/actions';
import ClientSelector from '../Invoices/clientSelector';

let invoicingMessages = getInvoicingMessages(),
	modalMessages = getModalMessages();

const messages = {
	...invoicingMessages,
	...modalMessages,
};

class AddDepositorModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			client: {}
		}
	}

	submitEntry = () => {
		this.props.form.validateFields({ force: true }, (err, values) => {
			if (err)
				return;
			this.props.createDepositary(values).then(() => {
				message.success(`${this.props.intl.formatMessage({ ...messages.createdDepositor })}`);
				this.props.handleComplete();
			}).catch(() => {
				message.error(`${this.props.intl.formatMessage({ ...messages.createDepositorError })}`);
			});
		});
	}

	updateSelectedClient = (client) => {
		this.setState({
			client: client
		});
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		return (
            <Modal
				title={`${this.props.intl.formatMessage({ ...messages.addDepositor })}`}
				destroyOnClose
				open={this.props.showModal}
				onCancel={this.props.handleComplete}
				maskClosable={false}
				footer={
					<div>
						<Button key="cancel" type="default" size="default" onClick={this.props.handleComplete}>
							{this.props.intl.formatMessage({ ...messages.cancel })}
						</Button>
						<Button key="submit" type="primary" size="default" onClick={this.submitEntry}>
							{this.props.intl.formatMessage({ ...messages.send })}
						</Button>
					</div>
				}
			>
				<Form layout='vertical'>
					<Col span={24}>
						<ClientSelector
							selectClient={this.updateSelectedClient}
							form={this.props.form}
						/>
					</Col>
					<Col span={24}>
						<Form.Item label={this.props.intl.formatMessage({ ...messages.depositor })}>
							{getFieldDecorator('name', {
								rules: [{
									required: true, message: this.props.intl.formatMessage({ ...messages.depositor }),
								}],
							})(
								<Input
									placeholder={this.props.intl.formatMessage({ ...messages.depositor })}
									prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
								/>
							)}
						</Form.Item>
					</Col>
				</Form>
			</Modal>
        );
	}
}

AddDepositorModal.propTypes = {
	intl: PropTypes.object.isRequired,
	showModal: PropTypes.bool.isRequired,
	form: PropTypes.object.isRequired,
	handleComplete: PropTypes.func.isRequired,
	createDepositary: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
	createDepositary: (searchText) => {
		return dispatch(CreateDepositary(searchText));
	}
});

export default connect(null, mapDispatchToProps)(injectIntl(Form.create()(AddDepositorModal)));