import React from 'react';
import PropTypes from 'prop-types';
import { Steps, Row, Col, Typography, message } from 'antd';
import { injectIntl } from 'react-intl';
import { getactionMessages, getAuthMessages, getModalMessages, getFields, getErrorMessages, getRegistrationMessages, getClientMessages } from '../../../constants/messages';
import { isMobile, getNestedValue } from '../../../utilities/util';
import BasicInfo from './BasicInfo';
import Telephones from './Telephones';
import Emails from './Emails';
import CustomPrices from './CustomPrices';
import { STATUS } from '../constants';
import { steps } from './FormModal';
import { GetClientTiers } from '../actions';
const moment = require('moment');

let actionMessages = getactionMessages(),
	authMessages = getAuthMessages(),
	errorMessages = getErrorMessages(),
	fieldMessages = getFields(),
	modalMessages = getModalMessages(),
	RegistrationMessages = getRegistrationMessages(),
	ClientMessages = getClientMessages();

const messages = {
	...actionMessages,
	...authMessages,
	...modalMessages,
	...fieldMessages,
	...errorMessages,
	...RegistrationMessages,
	...ClientMessages
};

const { Text } = Typography;
class ParentForm extends React.Component {

	componentDidMount() {
		const { props, getIntl, setLoading } = this;
		const { clientInfo, setState } = props;
		let client = { ...clientInfo };
		delete client.client;
		delete client.salesPerson;
		delete client.telephones;
		delete client.clientEmails;
		delete client.customPrices;
		delete client.activityLog;
		let telephones = clientInfo.telephones || [];
		let clientEmails = clientInfo.clientEmails || [];
		let customPrices = clientInfo.customPrices || [];
		if (client.automaticInvoicing === undefined || client.automaticInvoicing === null) client.automaticInvoicing = true;
		if (client.nextInvoiceMonth === undefined || client.nextInvoiceMonth === null) client.nextInvoiceMonth = moment().add(1, 'month').toISOString();
		setLoading(true);
		GetClientTiers()
			.then(clientTiers => setState({ client, telephones, clientEmails, customPrices, clientTiers, isLoading: false }))
			.catch(() => {
				setLoading(false);
				message.error(getIntl("loadClientTiersError"));
			});
	}

	getIntl = (str, values = {}) => this.props.intl.formatMessage({ ...messages[str] }, values);
	setLoading = (isLoading) => this.props.setState({ isLoading });

	changeCurrency = (isLps) => {
		const { exchangeRate, setState, state } = this.props;
		let { customPrices } = state;
		let newPrices = customPrices.map(cp => {
			return ({
				...cp,
				price: isLps ? (cp.price * exchangeRate) : (cp.price / exchangeRate)
			})
		});
		setState({ customPrices: newPrices });
	}

	getCurrentStepContent = () => {
		const { currentStep, state, setState, form, clientInfo, tags, products, exchangeRate } = this.props;
		let { client, telephones, clientEmails, customPrices } = state;
		const optimusClientId = getNestedValue("clientId", client);
		let { activityLog } = clientInfo;
		let isLps = client.isLps || false;
		let wasRejected = client.status === STATUS.REJECTED;
		let rejectedMessage;
		if (wasRejected) {
			let rejectedLog = activityLog.find((log) => {
				return log.status === STATUS.REJECTED;
			});
			rejectedMessage = rejectedLog.message;
		}
		const { BASICINFO, TELEPHONES, EMAILS, CUSTOMPRICES } = steps;
		switch (currentStep) {
			case BASICINFO:
				return <BasicInfo form={form} state={state} setState={setState} client={client} rejected={wasRejected} rejectedMessage={rejectedMessage} changeCurrency={this.changeCurrency} />
			case TELEPHONES:
				return <Telephones form={form} state={state} setState={setState} telephones={telephones} tags={tags} />
			case EMAILS:
				return <Emails form={form} state={state} setState={setState} emails={clientEmails} tags={tags} optimusClientId={optimusClientId} />
			case CUSTOMPRICES:
				return <CustomPrices form={form} state={state} setState={setState} customPrices={customPrices} isLps={isLps} products={products} exchangeRate={exchangeRate} />
			default:
				break;
		}
	}

	getCurrentStepTitle = () => {
		const { intl, currentStep } = this.props;
		const { BASICINFO, TELEPHONES, EMAILS, CUSTOMPRICES } = steps;
		switch (currentStep) {
			case BASICINFO:
				return intl.formatMessage({ ...messages.basicInfo });
			case TELEPHONES:
				return intl.formatMessage({ ...messages.clientTelephones });
			case EMAILS:
				return intl.formatMessage({ ...messages.clientEmails });
			case CUSTOMPRICES:
				return intl.formatMessage({ ...messages.customPrices });
			default:
				break;
		}
	}

	render() {
		const { intl, currentStep, moveToStep } = this.props;
		return (
			!isMobile() ?
				<Row>
					<Col className="client-steps" xs={{ span: 7 }} sm={{ span: 7 }} md={{ span: 7 }} lg={{ span: 7 }}>
						<Steps 
							progressDot 
							direction="vertical" 
							size="small" 
							initial={steps.BASICINFO} 
							current={currentStep}
							onChange={moveToStep}
							items={[
								{title: intl.formatMessage({ ...messages.basicInfo })},
								{title: intl.formatMessage({ ...messages.clientTelephones })},
								{title: intl.formatMessage({ ...messages.clientEmails })},
								{title: intl.formatMessage({ ...messages.customPrices })},
							]}
						/>
					</Col>
					<Col className="client-add-edit-content" xs={{ span: 17 }} sm={{ span: 17 }} md={{ span: 17 }} lg={{ span: 17 }}>
						{this.getCurrentStepContent()}
					</Col>
				</Row>
			:
				<Row>
					<Col className="client-steps" xs={{ span: 2 }} sm={{ span: 2 }} md={{ span: 2 }} lg={{ span: 2 }}>
						<Steps 
							items={[{},{},{},{}]}
							initial={steps.BASICINFO} 
							current={currentStep}
							onChange={moveToStep}
							direction="vertical" 
							size="small" 
						/>
					</Col>
					<Col className="client-add-edit-content" xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 22 }} lg={{ span: 22 }}>
						<Text strong>{this.getCurrentStepTitle()}</Text>
						{this.getCurrentStepContent()}
					</Col>
				</Row>
		);
	}
}

ParentForm.defaultProps = {
	clientInfo: {
		telephones: [],
		clientEmails: [],
		customPrices: [],
		activityLog: []
	}
};

ParentForm.propTypes = {
	intl: PropTypes.object.isRequired,
	form: PropTypes.object.isRequired,
	state: PropTypes.object.isRequired,
	setState: PropTypes.func.isRequired,
	currentStep: PropTypes.number.isRequired,
	clientInfo: PropTypes.object.isRequired,
	tags: PropTypes.array.isRequired,
	products: PropTypes.array.isRequired,
	exchangeRate: PropTypes.number.isRequired,
	moveToStep: PropTypes.func.isRequired
};

export default injectIntl(ParentForm);