import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'antd';

class CircleBadge extends React.Component  {
	render(){
        const { color, text, leftText } = this.props;
        if (leftText) return <span>{text} <Badge color={color}/></span>;
		return <Badge color={color} text={text}/>;
	}
}
 
CircleBadge.defaultProps = { 
    color: "#cdcdcd",
    leftText: false
};

CircleBadge.propTypes = {
    color: PropTypes.string.isRequired,
    text: PropTypes.string,
    leftText: PropTypes.bool
};

export default CircleBadge;