import axios from 'axios';
import {
	notify
} from '../../utilities/util';
import {
	FETCH_REQUEST_COUNT,
	FETCH_REQUESTS,
	ADD_REQUEST_SUCCESS,
	SET_SEARCH_REQUESTS
} from './actionTypes';

export const GetRequests = (page=1, pageSize=10) => (dispatch) => {
	let filter = {
		limit: pageSize,
		skip: (page - 1) * pageSize,
		include: ["client", "createdBy", "finishedBy", "contact"],
		order: "createdAt DESC",
		where: {}
	}; 
	return dispatch(BaseGetRequests(filter));
};

export const SearchRequests = (page=1, pageSize=10, search) => (dispatch) => {
	let filter = {
		limit: pageSize,
		skip: (page - 1) * pageSize,
		order: "createdAt DESC",
		where: {}
	}; 
	return axios({
        url: `/Requests/search`,
        method: 'GET',
		responseType: 'json',
		params: {
			filter: filter,
			searchObject: search
		}
	}).then((response) => {
		dispatch({ type: FETCH_REQUEST_COUNT, count: response.data.count});
		dispatch({ type: FETCH_REQUESTS, requests: response.data.requests})
		dispatch({type: SET_SEARCH_REQUESTS, search:search.searchText});
	});
};

export const BaseGetRequests = (filter) => (dispatch) => {
	let promises = [];
	promises.push(
		new Promise(function(resolve, reject) {
			axios.get(`/Requests/count?where=${encodeURIComponent(JSON.stringify(filter.where))}`)
			.then( (response) => {
				dispatch({ type: FETCH_REQUEST_COUNT, count: response.data.count});
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
		})
	);
	promises.push(
		new Promise(function(resolve, reject) {
			axios.get(`/Requests?filter=${encodeURIComponent(JSON.stringify(filter))}`)
			.then( (response) => {
				dispatch({ type: FETCH_REQUESTS, requests: response.data});
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
		})
	);
	return Promise.all(promises);
};

export const AddRequest = (request) => async (dispatch) => {
	const response = await axios.post(`/Requests`, request);
	dispatch({ type: ADD_REQUEST_SUCCESS });
	return response.data;
};

export const FinishRequest = (requestId) => (dispatch, getState) => {
	let state = getState();
	let messages = state.int18.messages;
	let user = state.auth.user;

	return axios.patch(`/Requests/finishRequest`, {requestId: requestId, userId: user.id}).then(() => {
		notify(`${messages[ADD_REQUEST_SUCCESS]}`);
		dispatch({ type: ADD_REQUEST_SUCCESS });
	});
};

export const notifySlack = async (requestId) => {
	await axios.post(`/Requests/notifySlack`, { requestId });
}
