import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Row } from 'antd';
import { getDashboardMessages } from '../../constants/messages';
import Mayre from 'mayre';
import { isMobile } from '../../utilities/util';
import ClientsWidget from './Widgets/Clients';
import DevicesWidget from './Widgets/Devices';
import InvoicesVsPaymentsWidget from './Widgets/InvoicesVsPayments';
import InvoiceTaxesWidget from './Widgets/InvoiceTaxes';

let dashboardMessages = getDashboardMessages();

const messages = {
	...dashboardMessages
};

class Dashboard extends React.Component {

    getIntl = (str) => this.props.intl.formatMessage({...messages[str]});

    globalWidgetsRender = () => {
        return (
            <React.Fragment>
                <ClientsWidget/>
                <DevicesWidget/>
                <InvoicesVsPaymentsWidget/>
                <InvoiceTaxesWidget/>
            </React.Fragment>
        );
    }

    desktopRender = () => {
        const { globalWidgetsRender } = this;
        return (
            <Row className="view" gutter={16} type="flex">
                {globalWidgetsRender()}
            </Row>
        );
    }

    fullRender = () => {
        const { desktopRender, globalWidgetsRender } = this;
        return (
            <Mayre
                of={globalWidgetsRender()}
                or={desktopRender()}
                when={isMobile()}
            />
        );
    }

	render() {
		return this.fullRender();
    }
}

Dashboard.propTypes = {
	intl: PropTypes.object.isRequired
};

export default injectIntl(Dashboard);