import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { message } from 'antd';
import { injectIntl } from 'react-intl';
import { getPayrollMessages } from '../../../../constants/messages';
import { GetIHSSRoofs } from '../../actions';
import { formatPrice } from '../../constants';
import DeductionsTable from './DeductionsTable';

let payrollMessages = getPayrollMessages();

const messages = {
	...payrollMessages
};

class Ihss extends React.Component  {

	constructor(props){
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    componentDidMount(){
        this.getRecords();
    }

	getIntl = (str) => {
		return this.props.intl.formatMessage({...messages[str]});
    }

    setLoading = (isLoading) => {
        this.setState({ isLoading });
    }

	getRecords = () => {
        const { getIntl, setLoading, props } = this;
		const { getIHSSRoofs } = props;
        setLoading(true);
		getIHSSRoofs()
		.then(() => setLoading(false))
		.catch(() => {
            setLoading(false);
			message.error(getIntl("getIHSSRoofsError"));
		});
	}

    getColumns = () => {
        const { getIntl } = this;
        return (
            [
                {
                    title: getIntl("name"),
                    dataIndex: 'name',
                    key: 'name'
                },
                {
                    title: getIntl("roof"),
                    dataIndex: 'roof',
                    key: 'roof',
                    render: (roof) => formatPrice(roof)
                },
                {
                    title: getIntl("percentage"),
                    dataIndex: 'percentage',
                    key: 'percentage',
                    render: (percentage) => `${percentage}%`
                }
            ]
        );
    }

    fullRender = () => {
        const { state, props, getColumns } = this;
        const { isLoading } = state;
        const { ihssRoofs } = props;
        const dataSource = ihssRoofs;
        const count = dataSource.length;
        const columns = getColumns();
        
        return <DeductionsTable dataSource={dataSource} columns={columns} count={count} isLoading={isLoading} pageSize={count}/>
    }

	render(){
        return this.fullRender();
	}
}

Ihss.defaultProps = {
	ihssRoofs: []
};

Ihss.propTypes = {
	intl: PropTypes.object.isRequired,
	getIHSSRoofs: PropTypes.func.isRequired,
	ihssRoofs: PropTypes.array.isRequired
};

const mapStateToProps = (state) => {
	return {
		ihssRoofs: state.payroll.ihssRoofs
	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		getIHSSRoofs: () => dispatch( GetIHSSRoofs() )
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Ihss));